import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ExandableComponentList } from '../../../components/hoc/expandableList';

const nonConditionStepCard = (props) => {

    const { stepData } = props

    console.log(stepData, 'step_date_values)9991', props.envVars);

    return (


        <div className="m-20 non-conditional-step">

            <div className="non-conditional-step-header">
                <span className='font-14 text-color-black'>Environment Variables</span>
            </div>

            {
                props.envVars?.length > 0 ?
                    <>
                        <div className='align-center pl-16 pr-16' style={{ height: '40px', width: '100%', display: 'flex', backgroundColor: 'whitesmoke', borderTop: '1px solid #c9c9c9' }}>
                            <div style={{ width: '50%' }}><span className='font-13'>Variable Name</span></div>
                            <div style={{ width: '50%' }}><span className='font-13 pl-10'>Variable Value</span></div>
                        </div>
                        {

                            <>
                                {
                                    props.envVars && props.envVars?.map((envVarObj, index) => {
                                        console.log(envVarObj,"envVarObjenvVarObj")
                                        let iteration_count = 3;
                                        let expandable_data = envVarObj.value ? +(envVarObj.value.length - iteration_count) : null

                                        return (

                                            <div key={index} className='align-center pl-16 pr-16' style={
                                                { 
                                                    minHeight: '40px', 
                                                width: '100%', 
                                                display: 'flex', 
                                                borderTop: '1px solid #c9c9c9' }}>
                                                <div className='env-var-table' style={{ width: '50%' }}>
                                                    <Tooltip title={envVarObj.key}>
                                                        <label className='font-13 text-color-dark-gray'>{envVarObj.key}</label>
                                                    </Tooltip>
                                                </div>

                                                <div className='env-var-table pl-10' style={
                                                    envVarObj.value && envVarObj.value.length > 0 && typeof (envVarObj.value) !== "string" ? 
                                                    { width: '50%', whiteSpace:'normal', padding:'5px' } 
                                                    : 
                                                    { width: '50%' }}>

                                                    {
                                                        envVarObj.value&& envVarObj.value.length > 0 && typeof (envVarObj.value) !== "string" ?
                                                            
                                                                <ExandableComponentList
                                                                    compoenent_list={envVarObj.value}
                                                                    variant="simple_chip_view"
                                                                    iteration_count={iteration_count}
                                                                    expandable_component={expandable_data}
                                                                />
                                                              
                                                           
                                                            :
                                                            <Tooltip title={envVarObj.value}>
                                                                <label className='font-13 text-color-dark-gray'>{envVarObj.value ? envVarObj.value : 'NA'}</label>
                                                            </Tooltip>
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        }
                    </> :

                    <div className='align-center justify-center pl-16 pr-16' style={{ height: '40px', width: '100%', display: 'flex', backgroundColor: '#f2f2f2', borderTop: '1px solid #c9c9c9' }}>
                        <span className='font-13 text-color-dark-gray'>No Environment Variables</span>

                    </div>
            }

        </div>


    )

}


export default nonConditionStepCard