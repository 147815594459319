import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import SquareAvatar from './AvatarSquare';

const InfoTab = props => {
    const classes = useStyles();
    const info_list = props.info_list;
    const selected_tab = props.selected_tab;
    const onTabChange = props.onTabChange;
    const keys = Object.keys(info_list);
    return (
        <div className={classes.root}>
            
            <TabHeadPanel>
            <div className="d-flex align-center">
                    <SquareAvatar shape = "sm-box" name= {props.selectedPodName} />
                    <span className="font-14 ml-5">
                   {props.selectedPodName}
               </span>
                </div>
                <div className="d-flex align-center">
                {
                    keys.map(key=>(
                        <div className={key == selected_tab ? "d-flex tab-bg active" : "d-flex tab-bg"} onKeyDown={()=>{}} onClick={()=>onTabChange(key)} tabIndex={0} role='button'>
                            {info_list[key].icon}
                            <p>{info_list[key].label}</p>
                            {/* <div className="chip-default chip-bg-dark-grey">{info_list[key].sub_text}</div> */}
                        </div>
                    ))
                }
                </div>
                
            </TabHeadPanel>
           
            <div className="divider"></div>
        </div>
    );
}

InfoTab.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default InfoTab;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
  }));
  const TabHeadPanel = styled('div')({
      display:'flex',
      alignItems:'center',
      fontSize:'11px',
      justifyContent:'space-between',
      paddingLeft:'15px',
      paddingRight:'15px',
      '& .MuiTabs-root':{
          position:'relative',
          zIndex:'1'
      },
      '& .MuiTabs-root button.MuiTab-root':{
          border:'1px solid #dedede',
          backgroundColor:'#fff',
          borderRadius:'4px 4px 0px 0px!important',
          margin:'0px 10px!important',
          borderBottom:'none',
          position:'relative',
          top:'1px'
      },
      '& span.MuiTab-wrapper':{
          height: 'auto!important',
          width:'auto!important',
          border: 'none!important',
          borderRadius: '0%!important',
          fontSize:'11px',
          color:'#878787',
          display:'grid',
          alignItems:'center',
          gridTemplateColumns:'auto auto',
          gap:'5px',
      },
      '& span.MuiTab-wrapper svg':{
          fontSize:'18px'
      },
      '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child':{
          marginBottom:'0px'
      },
      '& .MuiTab-root.Mui-selected .MuiTab-wrapper':{
          color:'#0096db'
      },
      '& .PrivateTabIndicator-colorSecondary-288':{
          backgroundColor:'#fff'
      },
      '& .divider':{
          position:'relative',
          bottom:'1px'
      },
      '& .MuiTab-labelIcon':{
          minHeight:'auto!important'
      },
      '& .d-flex':{
          alignItems:'center'
      },
      '& .chip-default':{
          boxShadow:'none',
          marginLeft:'5px'
      },
      '& .tab-bg':{
        backgroundColor:'#f6f4ff',
        margin:'0px 5px',
        border:'1px solid #cfdbe3',
        fontSize:'12px',
        color:'#889197',
        borderBottom:'none',
        padding:'10px 15px',
        borderRadius:'4px 4px 0px 0px',
        '&:hover':{
            cursor:'pointer'
        }
      },
      '& .tab-bg.active':{
          backgroundColor:'#fff',
          color:'#0095da',
      }
  
  })