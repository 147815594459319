import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
const NoDataAvailable = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.errorBox + " " + "no-data-yet"}>
            <div className="card-body details-na font-12 d-grid align-center" style={{ gridTemplateColumns: '1fr 120px', gap: 20 }} >
            <div>
                <p className="d-flex align-center mb-10">
                   <i className="ri-information-line font-18 text-grey-83 mr-5" />
                    <span className="font-14 text-grey-83">HPA - Horizontal Pod Autoscaler not defined</span>
                </p>
                <p className="text-grey-83 font-12">
                    The Horizontal Pod Scaler automatically scales the number of Pods in a replication controller, deployment,
                    replica set or stateful set based on observed CPU utilization or with custom metrics
                          </p>
            </div>
            <div>
                <button className="btn btn-primary-v2" onClick={() => { props.addHpa() }}>Add Default HPA</button>
            </div>
        </div>
        </div>
        
    )
}

NoDataAvailable.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default NoDataAvailable;

const useStyles = makeStyles(theme => ({
    errorBox: {
        border: '2px dashed transparent',
        padding: 14,
        borderRadius: 4,
        '&.no-data-yet': {
            borderColor: '#d7d7d7'
        }
    }
}))
