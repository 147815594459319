import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContent';
import  DialogContent  from '@mui/material/DialogContent';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '500px'
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    title: {
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: 1.2,
        marginBottom: '30px',
        padding:'4rem 0rem 2rem',
        textAlign:'center'
    },
    btnSection: {
        padding: '10px',
        backgroundColor: '#fff',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTop: '1px solid #fff',
        '& button': {
            marginLeft: '4px',
            width: '80px'
        }
    }
}));

export default function DeleteConfirmationDialogue(props) {
    const classes = useStyles();
    var open = props.open;
    const maxWidth = "sm";
    const fullWidth=true;

    return (
        <div className={classes.root}>
           
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={props.handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent >
                     <div className={classes.title}>
    Are you sure you want to delete <b>{props.name}</b> {props.type} ?
  </div>
                </DialogContent>
                <DialogActions style={{ padding: '0px' }}>
                    <div className={classes.btnSection}>
                        <button className="btn-buildpiper btn-outline-gray" onClick={props.handleClose} >Cancel</button>
                        <button className="btn-buildpiper btn-success-green" onClick={props.onDeleteRequest} >Yes</button>
                    </div>
                </DialogActions>
            </Dialog>

        </div>
    );
}
DeleteConfirmationDialogue.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }