import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip';
import Toolbar  from '@mui/material/Toolbar';
export default function DialogHeader(props) {
  const classes = useStyles();
  let params = (new URL(document.location)).searchParams;
  const application_id = props.application_id;
  const pipeline_id = params.get('pipeline_id');
  const execution_id = params.get('execution_id');
  const global_pipeline_flag = params.get('global_pipeline_flag');;
  console.log(global_pipeline_flag,"global_pipeline_flag 1111")
  // console.log('application_id  pipeline_id  execution_id', application_id, pipeline_id, execution_id);
  function getEnvName(service_data, env_id) {
    if (service_data.component_env) {
      for (let index = 0; index < service_data.component_env.length; index++) {
        if (env_id == service_data.component_env[index].id) {
          return service_data.component_env[index].project_env.name;
        }
      }
    }
  }
  console.log('line 19', props.handleClose);
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <div className="service-data">
          <div className="service-name  color-white"><b>{props.service_data.name}</b></div>
          <div className="d-flex">
            <div className="service-env" style={{ fontSize: '11px', marginRight: '3px', letterSpacing: '1px' }}>Environment Name: <b>{props.env_name ? props.env_name : getEnvName(props.service_data, props.env_id)}</b></div>
            <div className="service-env-type" style={{ fontSize: '11px', marginRight: '3px', letterSpacing: '1px' }}>Type: <b>{props.service_data.type}</b></div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}

DialogHeader.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const useStyles = makeStyles((theme) => ({

  appBar: {
    position: 'relative !important',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));