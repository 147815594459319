import React, { useEffect, useState } from 'react'
import { styled } from "@mui/system";
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { StyledRadio } from '../../../releasePackage/add/components/StylesRadio';
import { CustomLabel } from '../../../releasePackage/add/components/ConfigureChangeSet';
import Grid from '@mui/material/Grid';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { Loading } from '../../../utils/Loading';
import { ErrorComponent } from '../../../utils/Error';
import { schema } from 'normalizr';
import InputSkeleton from '../../../../components/genericComponents/Skeletons/InputSkeleton';

const DatabaseUpgradeManagement = (props) => {

    const inherits = props.inherits ? props.inherits : {};
    const database_group_list = props.database_group_list ? props.database_group_list : []
    const component_id = props.component_id;
    const component_env_id = props.component_env_id;

    const [branches, setBranches] = useState([]);

    const [state, setState] = useState({
        data: {
            database_migration_tool: 2,
        },
        error: {},
        form_data: {
            default_validations: {
                git_url: [VALIDATION_TYPE_REQUIRED],
                branch_name: [VALIDATION_TYPE_REQUIRED],
                // file_paths: [VALIDATION_TYPE_REQUIRED],
                main_branch: [],
                deployed_branch: [],
                draft_branch: [],
                // credential_id: [VALIDATION_TYPE_REQUIRED],
                versioning_path: [],
                credential_id: [],
                file_paths_build_context: [],
            },
            available_repos: [],
            available_helm_templates: [],
            error: {},
            data: {
                edit: true,
                git_url: "",
                branch_name: "",
                credential_id: null,
                file_paths: "",
                versioning_path: "",
                auto_refresh: 2,
                choose_one: 1
            }
        },
        branch_data: {
            loading: false,
            validation_error: {}
        },
        schema_list: []
    })

    const commonFunctions = getCommonFunctions(state, setState, inherits);

    const optionList = [
        { label: 'BuildPiper', value: 1, info: 'xyz' },
        { label: 'Liquibase', value: 2, info: 'xyz' }
    ]

    useEffect(() => {
        fetchRepoList();
        fetchConfiguredDatabase()
    }, []);

    console.log(database_group_list, "svbshbvhfdsd")

    useEffect(() => {
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                selected_database: database_group_list[0]?.id,
                selected_replica_id: database_group_list[0]?.replica_id
            }
        }))
    }, [database_group_list])

    console.log(state, "scvhdshyvhyd")

    useEffect(() => {
        if (state.db_configured_id) {
            setState((new_state) => ({
                ...new_state,
                data: {
                    ...new_state.data,
                    selected_database: state.db_configured_data.database,
                    schema: state.db_configured_data.schema,
                },
                form_data: {
                    ...new_state.form_data,
                    data: {
                        ...new_state.form_data.data,
                        git_url: state.db_configured_data.git_repo.id,
                        path: state.db_configured_data.path
                    }
                }
            }))
            testAndGetBranches()
        }
    }, [state.db_configured_id])



    console.log(state, "asbcdahbcghbegc")

    useEffect(() => {
        if (state.data.selected_database)
            fetchDatabaseSchemas()
    }, [state.data.selected_database])

    function fetchDatabaseSchemas() {
        let requestInfo = {
            endPoint: GenerateURL({ database_group_id: state.data.selected_database, database_id: state.data.selected_replica_id }, properties.api.database_schema),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchSchemaSuccessResponse, fetchSchemaFailedResponse);
    }

    function fetchSchemaSuccessResponse(response) {
        console.log(response, "dbchdsbchjdbvhdbv")
        setState((new_state) => ({
            ...new_state,
            schema_list: getSchemaList(response)
        }))
    }

    function fetchSchemaFailedResponse(error) {

    }

    function getSchemaList(data) {
        let list = []
        data.forEach((item) => {
            let obj = {
                id: item,
                label: item
            }
            list.push(obj)
        })

        return list;
    }

    const fetchRepoList = () => {
        let requestInfo = {
            endPoint: GenerateURL({}, properties.api.fetch_all_repo + "?all=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, FetchRepoSuccessResponse, FetchRepoFailedResponse);


    }

    const FetchRepoSuccessResponse = (response) => {
        // fetchHemlRepoList();

        let available_repos = [];
        if (response.results) {
            available_repos = response.results.map(item => {
                return { id: item.id, label: item.name + " : " + item.git_url, url: item.git_url, cred_id: item.credential_id, git_provider_id: item.git_provider_id }
            })

            if (state.form_data && state.form_data.data) {
                if (state.form_data.data.branch_name && state.form_data.data.branch_name != "") {
                    testAndGetBranches(available_repos);
                } else {

                    if (state.form_data.data.draft_branch && (state.form_data.data.draft_branch != null && state.form_data.data.draft_branch != "")) {

                        testAndGetBranches(available_repos);
                    }
                }
            }
        } else {
            available_repos = [{ label: "No Repo Available", id: null }]
        }

        setState(new_state => ({
            ...new_state,
            available_repos: available_repos
        }));

    }
    const FetchRepoFailedResponse = (error_resposne) => {
        let response = error_resposne.detail;

        setState(new_state => ({
            ...new_state,
            available_repos: [{ id: null, label: response }]
        }));
    }

    function fetchConfiguredDatabase() {
        let requestInfo = {
            endPoint: GenerateURL({ component_id: component_id, component_env_id: component_env_id }, properties.api.database_upgrade),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchConfiguredDbSuccessResponse, fetchConfiguredDbFailedResponse);
    }

    function fetchConfiguredDbSuccessResponse(response) {
        console.log(response, "sbdchdbchdbcdcbdsb")
        setState((new_state) => ({
            ...new_state,
            db_configured_data: response,
            db_configured_id: response.id
        }))
    }

    function fetchConfiguredDbFailedResponse() {

    }

    const onKeyValueHandler = (k, v) => {
        console.log(k, v, "sgbdcydvdvbgwvbghdw")
        setState((new_state) => ({
            ...new_state,
            form_data: {
                ...new_state.form_data,
                data: {
                    ...new_state.form_data.data,
                    [k]: v,
                    selected_repo_cred_id: getSelectedRepoCredId(v)
                },
                error: { ...new_state.form_data.error, [k]: "" },
                // errors: { ...new_state.form_data.errors, [k]: "" }
            }
        }));
    }

    function getSelectedRepoCredId(id) {
        const selectedRepo = state.available_repos.find(repo => repo.id === id);
        return selectedRepo ? selectedRepo.cred_id : null;
    }

    function onChangeDatabaseGroup(e) {
        let name = e.target.name;
        let value = e.target.value;
        console.log(name, value, "adbchgdavcvc")

        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [name]: value,
                selected_replica_id: database_group_list.filter(item => item.id == value)[0].replica_id
            }
        }))
    }

    function testAndGetBranches(repo_list) {
        console.log(repo_list, "acvagsdvchgadvc")
        let result = {}
        if (state.db_configured_id) {
            result = {
                valid: true
            }
        } else {
            result = ValidateDataSet({
                git_url: state.form_data.data.git_url,
            },
                {
                    git_url: [VALIDATION_TYPE_REQUIRED]
                }
            );
        }
        if (result.valid) {
            if (state.available_repos && state.available_repos.length > 0) {
                var filter_current_repo_with_id = state.available_repos.filter(item => item.id == state.form_data.data.git_url);

            } else {
                if (repo_list && repo_list.length > 0) {
                    var filter_current_repo_with_id = repo_list.filter(item => item.id == state.form_data.data.git_url);
                }
            }


            let cred_id = filter_current_repo_with_id && filter_current_repo_with_id[0] && filter_current_repo_with_id[0].cred_id ? filter_current_repo_with_id[0].cred_id : null;
            let gir_repo_id = filter_current_repo_with_id && filter_current_repo_with_id[0] && filter_current_repo_with_id[0].url ? filter_current_repo_with_id[0].url : null;

            const post_data = {
                git_url: gir_repo_id ? gir_repo_id : state.db_configured_data.git_repo.git_url,
            };
            if (state.db_configured_id) {
                post_data.credential_id = state.db_configured_data.git_repo.credential_id
            } else {
                post_data.credential_id = state.form_data.data.selected_repo_cred_id
            }
            if (!post_data.credential_id) {
                delete post_data.credential_id;
            }
            console.log(repo_list, "acbhjsdbchdsvhc")
            PostData(GenerateURL({}, properties.api.git_test), post_data, repo_list && repo_list.length > 0 ? (data) => onFetchSuccess(data, repo_list) : onFetchSuccess, onFetchFail);
            setState(new_state => ({
                ...new_state,
                branch_data: {
                    loading: true,
                }
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                form_data: {
                    ...new_state.form_data,
                    error: {
                        ...new_state.form_data.error,
                        ...result.error,
                    }
                }
            }));
        }
    }

    function onFetchSuccess(data, list) {
        console.log(data, list, "dscsbcgsdbghb")
        setState(new_state => ({
            ...new_state,
            branch_data: {
                ...new_state.branch_data,
                loading: false,
                ...data
            },
            form_data: {
                ...new_state.form_data,
                data: {
                    ...new_state.form_data.data,
                    branch_name: state.db_configured_id ? state.db_configured_data.branch : null,
                }
            }
        }));

    }

    console.log(state, "ascvbsdkjvbsjfvb")

    function onFetchFail(error) {
        setState(new_state => ({
            ...new_state,
            branch_data: {
                ...new_state.branch_data,
                loading: false,
                error: error,
            }
        }));
    }

    function getBranchList(branches) {
        const result = [];

        branches.forEach(branch => {
            result.push({
                id: branch,
                label: branch
            })
        });
        return result;
    }

    function onChangeHandler(e) {
        const key = e.target.name;
        let value = e.target.value;
        updateData(key, value);
    }

    function updateData(key, value) {
        setState(new_state => ({
            ...new_state,
            form_data: {
                ...new_state.form_data,
                data: {
                    ...new_state.form_data.data,
                    [key]: value
                },
                error: {
                    ...new_state.form_data.error,
                    [key]: ""
                }
            }
        }))
    }

    function validateAndSave() {
        // var result = ValidateDataSet(state.data, state.validations);
        var post_url;
        if (state.db_configured_id) {
            post_url = GenerateURL({ component_id: component_id, component_env_id: component_env_id, db_upgrade_id: state.db_configured_id }, properties.api.database_upgrade_edit)
        } else {
            post_url = GenerateURL({ component_id: component_id, component_env_id: component_env_id }, properties.api.database_upgrade)
        }
        console.log(post_url, "abcghadcghdcgds")
        var post_data = {
            database: state.data.selected_database,
            schema: state.data.schema,
            git_repo: state.form_data.data.git_url,
            branch: state.form_data.data.branch_name,
            path: state.form_data.data.path
        }
        if (state.db_configured_id) {
            post_data.id = state.db_configured_id
        }
        PostData(post_url, post_data, postSuccess, postFail);
        setState(new_state => ({
            ...new_state,
            data_posting: true,
        }))
    }

    function postSuccess(response) {
        console.log(response, "akdbcgdsvsg")
        setState(new_state => ({
            ...new_state,
            data_posting: false,
        }))
    }

    function postFail(error) {
        console.log(error, "akdbcgdsvsg")
        setState(new_state => ({
            ...new_state,
            data_posting: false,
        }))
    }

    console.log(state, "abcadhbchadbvhsb")

    return (
        <>
            {
                state.data_posting ?
                    <Grid container direction="column" spacing={2}>
                        <Grid item lg={6}>
                            <InputSkeleton type={'text'} />
                        </Grid>
                        <Grid item lg={6}>
                            <InputSkeleton type={'text'} />
                        </Grid>
                        <Grid item lg={12}>
                            <InputSkeleton type={'text'} />
                        </Grid>
                        <Grid item lg={12}>
                            <InputSkeleton type={'text'} />
                        </Grid>
                        <Grid item lg={6}>
                            <InputSkeleton type={'text'} />
                        </Grid>
                    </Grid>
                    :
                    <DatabaseUpgradeForm>
                        <div className='select-database-group'>
                            <div className='database-icon'>
                                <span className='ri-database-2-line' style={{ color: "#FFFFFF", fontSize: "24px" }}></span>
                            </div>
                            <div className='d-grid align-center mb-0 input-mb-0' style={{ width: '400px', marginLeft: 10, gridTemplateColumns: '115px 1fr' }}>
                                <div className="font-11">Select Database: </div>
                                <Input
                                    type="select-for-multideployment"
                                    name="selected_database"
                                    list={database_group_list}
                                    onChangeHandler={onChangeDatabaseGroup}
                                    label=""
                                    remove_default_value={true}
                                    data={state.data}
                                    error={state.error}
                                    no_tooltip={true}
                                />
                            </div>
                        </div>
                        <div className='database-migrate-form'>
                            <p className='header'>Enter Following details to Migrate Database</p>
                            {/* <div className='d-flex f-direction-column' style={{ gap: '8px', marginTop:"32px" }}>
                    <span className='font-14 font-weight-500 color-value'>Migration Management Tool</span>
                    <div style={{ height: "34px" }}>
                        <Input
                            type={"custom-radio"}
                            name={"database_migration_tool"}
                            data={state.data}
                            error={state.error}
                            list={optionList}
                            customRadio={StyledRadio}
                            customLabel={CustomLabel}
                            mandatorySign
                            onChangeHandler={commonFunctions.onChangeHandler}
                        />
                    </div>
                </div> */}
                            <Grid container style={{ marginTop: "20px" }}>
                                <Grid item lg={6}>
                                    <Input
                                        type="select"
                                        name="schema"
                                        list={state.schema_list}
                                        label="Schema"
                                        placeholder="Enter"
                                        mandatorySign
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error}
                                    />
                                </Grid>
                            </Grid>
                            <p className='git-header' style={{ marginTop: "32px" }}>GIT DETAILS</p>
                            <Grid container style={{ marginTop: "20px" }} spacing={2}>
                                <Grid item lg={10}>
                                    <div className="auto-complete-dropdown auto-complete-dropdown-42">
                                        <Input
                                            type="auto-complete-dropdown" // "select"
                                            label="Please Select the Git URL"
                                            mandatorySign
                                            id={"git_urls"}
                                            name="git_url"
                                            list={state.available_repos && state.available_repos.length > 0 ?
                                                state.available_repos : []}
                                            placeholder="Git URL"
                                            getOptionLabel={(option) => option.label}
                                            error={state.form_data.error}
                                            data={state.form_data.data}
                                            onChangeHandler={onKeyValueHandler}
                                        />
                                    </div>
                                </Grid>
                                <Grid item lg={2} style={{ display: "flex", alignItems: "center" }}>
                                    {
                                        state.branch_data.loading ?
                                            <div style={{ marginTop: '10px' }}>
                                                <Loading varient="light" />
                                            </div>
                                            :
                                            <button className="btn btn-primary" style={{ width: '100%' }} onClick={testAndGetBranches}>Load Branches</button>
                                    }
                                </Grid>
                                <Grid item lg={12}>
                                    {state.branch_data.error ?
                                        <ErrorComponent error={state.branch_data.error} />
                                        :
                                        <Input
                                            type="select"
                                            label="Select Branch"
                                            name="branch_name"
                                            placeholder="Branch"
                                            mandatorySign
                                            list={state.branch_data.branches ? getBranchList(state.branch_data.branches) : branches && branches.length > 0 ? getBranchList(branches) : []}
                                            error={state.form_data.error}
                                            data={state.form_data.data}
                                            onChangeHandler={state.branch_data.branches ? onChangeHandler : branches && branches.length > 0 ? onChangeHandler : null}
                                        />

                                    }
                                </Grid>
                                <Grid item lg={6}>
                                    <Input
                                        type="text"
                                        label="Git Path"
                                        name="path"
                                        placeholder="/xyz/abc.yaml"
                                        mandatorySign
                                        error={state.form_data.error.path ? { path: "This is required" } : []}
                                        data={state.form_data.data}
                                        onChangeHandler={onChangeHandler}
                                    />
                                </Grid>
                            </Grid>
                            <div
                                className=""
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    // background: "#f6f6f6",
                                    padding: "10px 0",
                                    marginTop: "auto",
                                    width: "100%",
                                }}
                            >
                                <button
                                    className="btn btn-submit"
                                    style={{ display: "flex", backgroundColor: "#0086FF" }}
                                    onClick={validateAndSave}
                                >
                                    Save Changes
                                </button>
                                {/* {
                            is_permitted ?
                                <button
                                    className="btn btn-submit"
                                    style={{ display: "flex" }}
                                    onClick={validateAndSave}
                                >
                                    Save &nbsp; <ArrowForwardIosIcon />
                                </button>
                                :
                                <Tooltip title="You are not allowed to perform this action">
                                    <button className="btn btn-disable cursor-not-allowed">
                                        Save
                                    </button>
                                </Tooltip>

                        } */}
                            </div>
                        </div>
                    </DatabaseUpgradeForm>
            }
        </>
    )
}

export default DatabaseUpgradeManagement

const DatabaseUpgradeForm = styled("div")({
    "& .select-database-group": {
        display: "flex",
        alignItems: "center",
        gap: "16px",

        "& .database-icon": {
            width: "48px",
            height: "48px",
            borderRadius: "50px",
            backgroundColor: "#0086FF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    },

    "& .database-migrate-form": {
        marginTop: "42px",

        "& .header": {
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "600",
            color: "#2F2F2F"
        },

        "& .git-header": {
            fontFamily: "Montserrat",
            fontSize: "12px",
            fontWeight: "700",
            color: "#2F2F2F"
        },

        "& .MuiAutocomplete-input": {
            border: "1px solid #b7b7b7 !important"
        }
    }
})