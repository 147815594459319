import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import Delete from '../../../components/genericComponents/Delete';
import GenerateURL from '../../../util/APIUrlProvider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip'
import { Link } from 'react-router-dom';
import { PostData } from '../../../util/apiInvoker';
import properties from '../../../properties/properties';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import AvatarSquare from '../../../components/genericComponents/AvatarSquare'
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';

export default function HelmListCard(props) {
    const classes = useStyles();
    const [state, setState] = useState({});
    const [showloading, setShowLoading] = useState(false)
    const prev_state = props.prev_state ? props.prev_state : null;
    const data = props.data ? props.data : null
    const apiUrl = props.apiUrl ? props.apiUrl : "";
    const refreshFun = props.refreshFun ? props.refreshFun : () => { };
    const onClickOpenDialogAndSetData = props.onClickOpenDialogAndSetData

    useEffect(() => {
        setState(new_state => ({ ...new_state, ...prev_state, }))
    }, [prev_state])
    function TestConnection(id) {

        var post_url = GenerateURL({ edit_id: id }, properties.api.test_registry_connection);
        PostData(post_url, {}, postSuccess, postFail);
        setState(new_state => ({
            ...new_state,
            loading_status: true,
        }))
    }
    function postSuccess(response) {

        setState(new_state => ({
            ...new_state,
            status: response.status,
            loading_status: false,
            show_save_msg: "success",
        }))
        refreshFun()
    }
    function postFail(response) {
        var error_msg = response

        setState(new_state => ({
            ...new_state,
            loading_status: false,
            status: response.status,
            show_save_msg: "failed",
            error_in_save: error_msg.detail
        }))
        refreshFun()
    }
    const handleClose = () => {
        setState({ ...state, show_save_msg: null, });
    };
    return (
        <>
            <div className={`${classes.commonTable} d-grid align-center`} style={{ gridTemplateColumns: '170px 210px 210px  1fr', gap: '10px' }}>

                <div className='d-flex align-center'>

                    <AvatarSquare shape="round"
                        name={data.name}
                        varient="double" />

                    <Tooltip title={data.name}>
                        <p className='font-12 ml-5 text-ellipsis-110'>{data.name}</p>
                    </Tooltip>
                </div>

                <Tooltip title={data.helm_url}>
                    <p className='font-12 text-ellipsis-200 text-anchor'>{data.helm_url}</p>
                </Tooltip>
                <Tooltip title={data.credential && data.credential.name ? data.credential.name : "N/A"}>
                    <p className='font-12 text-ellipsis-110'>{data.credential && data.credential.name ? data.credential.name : "N/A"}</p>
                </Tooltip>
                <div className='r-col7 repos-card-content' style={{ marginLeft: 'auto', marginRight: 0 }}>
                    <OptionList
                        data={{ id: data.id }}
                        refreshFun={refreshFun}
                        apiUrl={apiUrl}
                        onClickOpenDialogAndSetData={onClickOpenDialogAndSetData}
                    />
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={state.show_save_msg == "success" || state.show_save_msg == "failed"}
                onClose={handleClose}
                autoHideDuration={6000}>
                {
                    state.show_save_msg == "success" ?
                        <>
                            <Alert severity="success"  variant="filled">
                                {"Request hit successful"}
                            </Alert>
                        </> : state.show_save_msg == "failed" ?
                            <Alert severity="error"  variant="filled">
                                {state.error_in_save}
                            </Alert>

                            : null
                }
            </Snackbar>
        </>
    )
}

const ITEM_HEIGHT = 48;

const OptionList = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const data = props.data ? props.data : {};
    const edit_id = data.id ? data.id : "";
    console.log(edit_id, "fdsafnsajnfajsdf")
    const refreshFun = props.refreshFun ? props.refreshFun : () => { };
    const apiUrl = props.apiUrl ? props.apiUrl : "";
    const onClickOpenDialogAndSetData = props.onClickOpenDialogAndSetData
    console.log(apiUrl, "fdsafnsajnfajsdf")
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>  
            <button className="btn btn-transparent"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                    <i className="ri-more-2-fill"  style={{ color: '#0096db', fontSize: '2rem' }} />
            </button>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem style={{ display: "grid" }}>
                    <Link to={"#"} onClick={() => { onClickOpenDialogAndSetData(edit_id) }} className="d-flex align-center space-between font-12 " style={{color:'#505050'}}>
                        <span className="ri-edit-line"></span>&nbsp;Edit
                    </Link>

                </MenuItem>
                <MenuItem style={{ display: "grid", justifyContent: 'center' }}>
                    <Delete
                        data={{ entity_id: edit_id, name: 'helm_integration' }}
                        refresh={refreshFun}
                        varient="rp_delete"
                        is_edit_permited={true}
                        api_link={GenerateURL({ id: edit_id }, properties.api.update_helm_chart)}
                    />
                </MenuItem>
            </Menu>
        </>
    )
}
const useStyles = makeStyles({
    commonTable: {
        border: '1px solid #e6e6e6',
        borderTop: '0px',
        padding: '10px',
        '&:first-child': {
            borderTop: '1px solid #e6e6e6',
            borderRadius: '6px 6px 0px 0px'
        },
        '&:last-child': {
            borderRadius: '0px 0px 6px 6px',
        }
    }
})

export const HelmListCardSkeleton = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.commonTable} d-grid align-center`} style={{ gridTemplateColumns: '170px 210px 210px  1fr', gap: '10px' }}>

            <div className='d-flex align-center'>
                <GenericSkeleton variant={'circle'} width={48} height={48}/>
                <GenericSkeleton variant={'text'} width={100} height={18}/>
            </div>
           {Array.from({length:2}).map(x => <GenericSkeleton variant={'text'} width={100} height={18}/>)}
            
            <div className='r-col7 repos-card-content' style={{ marginLeft: 'auto', marginRight: 0 }}>
                <GenericSkeleton variant={'rect'} height={20} width={10} />
            </div>
        </div>
    )
}