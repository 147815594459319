import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter } from "react-router-dom";
import { ThemeProvider, } from "@mui/material/styles";
import theme from "./theme";
import { Loading } from "./views/utils/Loading";
import AppRoutes from "./AppRoutes";

let root;
function initializeRoutes() {
  
  const rootElement = document.getElementById("root");
  if (!rootElement) {
    throw new Error("Root element not found");
  }

  if (!root) {
    root = ReactDOM.createRoot(rootElement);
  }


  root.render(

    <Suspense fallback={
      <Loading />
    }>

      <ThemeProvider theme={theme}>
        <BrowserRouter>
        <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>

    </Suspense>

  )
}

export const GenerateRouteFromParams = (route, params) => {
  
  const keys = Object.keys(params);
  var generated_route = route;
  keys.forEach(key => {
    generated_route = generated_route.replace(":" + key, params[key]);
  });
  return generated_route;
}
export default initializeRoutes;