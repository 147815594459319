import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import { Link, useNavigate, useMatch, useResolvedPath } from "react-router-dom";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import { GenerateRouteFromParams } from '../../routes';
import { IsSuperUser } from '../../util/security';
import { isSelectedUrl } from './NavigationNew';
import { useLocation } from 'react-router-dom';
import { SimpleMenu } from './SimpleMenu';
import BreadcrumbsNew from './BreadCrumbs';
import PremiumInfo from '../../components/genericComponents/PremiumInfo';

const drawerWidth = 86;
const additionalDrawerWidth = 170;
let incWidth = false;

const premiumFeatureUrl = [ // just add premium feature data here to set alerts
  { url: '/velocity-insights', featureKey: 'Velocity_insights', featureName: 'Velocity Insight' },
]

export default function SideNavNew({ onChangeAlert, ...props }) {
  const classes = useStyles();
 
  const data = props.data;
  const mainOptions = props.mainOptions;
  console.log(mainOptions,"fsajfdjajkdfsa")
  const handleDrawerClose = props.handleDrawerClose;
  const handleDrawerOpen = props.handleDrawerOpen;
  const match = useResolvedPath().pathname;
  console.log(match,"matchmatchmatch")
  const history = useNavigate();
  console.log(history,"flksakfdksaflaks")
  const [additonalDrawer, setAdditionalDrawer] = useState(false);
  const [searchDialog, setSearchDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [subOptions, setSubOptions] = useState([]);
  const [moreOptions, setMoreOptions] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [allOptions, setAllOption] = useState([]);
  const [breadCrumbList, setBreadCrumbList] = useState([]);


  const [alertState, setAlertState] = useState({
    showStripForGlobalPlan: false,
    showStripForPremiumFeature: false,
    remainingDaysForGlobalPlan: null,
    license_data: null,
    premiumData: null,
    premiumFeatureInfo: null,
    remainingDaysForPremium: null
  })
  let featureKey = 'Velocity_insights';
  const location = useLocation();


  const currentPath = location?.pathname
  console.log(currentPath, 'cpt_0los')

  useEffect(() => {
    console.log("is this called")
    setTimeout(() => {
      const license_data = localStorage.getItem('license_data');
      if (license_data) {
        const parsedData = JSON.parse(license_data)
        const premiumFeatures = parsedData?.Premium_Features
        const premiumFeatureData = premiumFeatures?.[featureKey]
        console.log('def_00po', premiumFeatureData)

        const remainingDaysForGlobalPlan = calculateDaysUntilLicenseEnd(parsedData);
        console.log('def_00p', remainingDaysForGlobalPlan);
        const remainingDaysForPremium = calculateDaysUntilLicenseEndForPremium(premiumFeatureData);

        console.log(remainingDaysForPremium, 'feature_001')
        const accessibility = premiumFeatureData?.Accessibility
        console.log('asx_0p', accessibility)

        let premiumFeatureInfo = ''
        if (accessibility == false) {
          premiumFeatureInfo = 'not-accessible'
        }
        else {
          premiumFeatureInfo = accessibility

        }
        const typeOfAlert = checkForSuitableAlertComponent({
          license_data: parsedData,
          premiumData: premiumFeatureData,
          remainingDaysForGlobalPlan: remainingDaysForGlobalPlan,
          remainingDaysForPremium: remainingDaysForPremium,
          premiumFeatureInfo: premiumFeatureInfo
        })

        setAlertState((new_state) => ({
          ...new_state,
          license_data: parsedData,
          premiumData: premiumFeatureData,
          remainingDaysForGlobalPlan: remainingDaysForGlobalPlan,
          remainingDaysForPremium: remainingDaysForPremium,
          premiumFeatureInfo: premiumFeatureInfo,
          showStripForGlobalPlan: typeOfAlert?.globalAlert ? true : false,
          showStripForPremiumFeature: typeOfAlert?.premiumAlert ? true : false,
          featureKey: typeOfAlert?.featureKey,
          featureName: typeOfAlert?.featureName

        }))
        if (typeOfAlert?.globalAlert || typeOfAlert?.premiumAlert) {
          onChangeAlert(true)
        }
      }
    }, 200)

  }, [])
  
  console.log(currentPath, 'cpt_0los')
  const checkForSuitableAlertComponent = (data) => {

    const remainingDaysForGlobalPlan = data?.remainingDaysForGlobalPlan
    const remainingDaysForPremium = data?.remainingDaysForPremium
    console.log(data, 'data_0ppppl,m')
    let premiumFeatureData = premiumFeatureUrl?.find(({ url }) => url == currentPath)
    console.log(premiumFeatureData, 'cpr_0p')
    if (!premiumFeatureData) {
      premiumFeatureData = {}
    }
    const { url, featureKey, featureName } = premiumFeatureData

    if (url && (remainingDaysForPremium < 31 || data?.premiumFeatureInfo == "not-accessible")) {
      return {
        globalAlert: false,
        premiumAlert: true,
        featureKey: featureKey,
        featureName: featureName
      }
    }

    else {
      if (data?.license_data && remainingDaysForGlobalPlan < 31) {
        return {
          globalAlert: true,
          premiumAlert: false
        }
      }

    }

  }


  function calculateDaysUntilLicenseEnd(licenseData) {
    if (licenseData) {
      const licenseEndDate = licenseData?.License_end_date
      const endDate = new Date(licenseEndDate);
      endDate.setHours(0, 0, 0, 0); // 00:00:00.000

      // Get the current date
      const currentDate = new Date();
      console.log(currentDate, endDate, 'poll')
      // Calculate the difference in milliseconds
      const timeDifference = endDate - currentDate;
      let daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      if (daysDifference < 0) {
        return daysDifference
      }
      if (daysDifference < 1) {
        daysDifference = 0
      }
      else {
        daysDifference = Math.ceil(daysDifference)
      }

      // const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      console.log(daysDifference, 'dff_00p')
      return daysDifference;
    }
  }
  function calculateDaysUntilLicenseEndForPremium(data) {
    if (data) {
      const licenseEndDate = data?.end_date
      // Convert the input license end date string to a Date object
      const endDate = new Date(licenseEndDate);
      endDate.setHours(0, 0, 0, 0); // 00:00:00.000
      const currentDate = new Date();
      const timeDifference = endDate - currentDate;
      let daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      if (daysDifference < 0) {
        return daysDifference
      }
      if (daysDifference < 1) {
        daysDifference = 0
      }
      else {
        daysDifference = Math.ceil(daysDifference)
      }
      return daysDifference;
    }
  }

  const onCloseLicenseStrips = () => {
    setAlertState((new_state) => ({
      ...new_state,
      showStripForGlobalPlan: false
    }))
    onChangeAlert(false)
  }

  const onCloseForPremiumAlerts = () => {
    setAlertState((new_state) => ({
      ...new_state,
      premiumFeatureInfo: false
    }))
    onChangeAlert(false)
  }



  console.log("sbdjbdsjsd", data);
  const handleAddtionalDrawerOpen = () => {
    setAdditionalDrawer(true);
    handleDrawerOpen();
    //console.log("hghfgfg",additonalDrawer,incWidth);
  }

  const handleAddtionalDrawerClose = () => {
    console.log("njnsjndjs", additonalDrawer);
    setAdditionalDrawer(false);
    setSearchDialog(false);
    handleDrawerClose()
  }

  const handleMoreDialogOpen = () => {
    setSearchDialog(true);
  }
  const handleMoreDialogClose = () => {
    setSearchDialog(false);
  }

  function isPatternMatched(patternLink, link) {
    // Construct regular expression pattern dynamically
    const regexPattern = new RegExp(
      "^" +
      patternLink
        .replace(/\//g, "\\/") // Escape forward slashes
        .replace(/:\w+/g, "\\d+") + // Replace placeholders with "\\d+" (which matches digits)
      "$"
    );

    // Test if the link matches the pattern
    return regexPattern.test(link);
  }

  function moreOptionClick(option, subOptions, mainkey) {
    console.log("is this called")
    let subOptionsList = [];
    console.log("ndjnsjds", option, subOptions, selectedOption);
    let subOptionsKeys = Object.keys(subOptions);
    subOptionsKeys.forEach((key) => {
      let opt = subOptions[key];
      let paramKey = mainkey ? mainkey + "_id" : selectedOption.key + "_id";
      let params = { [paramKey]: option.id.toString() };
      let path = GenerateRouteFromParams(opt.path, params);
      console.log("oyiomnd", path, params);
      subOptionsList.push({ key: opt.key, label: opt.label, path: path });
    })
    setSubOptions(subOptionsList);
    setSelectedApp(option);
    setLastUsedAppOrClusterinLocalStrorage(option);
    handleMoreDialogClose();
    history(subOptionsList[0].path);
  }

  console.log("hghfgfg", additonalDrawer, incWidth);

  //to get the last used app and cluster from local storage
  function getLastUsedAppOrCluster(option) {
    let localStorageKey = IsSuperUser() ? 'last_used_cluster' : 'last_used_app';
    let lastUsedAppOrCluster = localStorage.getItem(localStorageKey);
    return JSON.parse(lastUsedAppOrCluster);
  }

  function optionClick(option) {
    console.log(option,"fsafklaskkflsa")
    if (option.type == "dynamic-route") {
      let lastUsedAppOrCluster = getLastUsedAppOrCluster(option);
      if (lastUsedAppOrCluster) {
        console.log("bdhbsiruiur", lastUsedAppOrCluster, option)
        setSelectedOption(option);
        //setSelectedApp(null);
        moreOptionClick(lastUsedAppOrCluster, option.subOptions, option.key);
        handleAddtionalDrawerOpen();
      } else {
        if (!selectedApp) {
          setSelectedApp(null);
          setSelectedOption(option);
          handleAddtionalDrawerOpen();
          setSubOptions([]);
          let moreOptionsList = [];
          if (data[option.key]) {
            let keys = Object.keys(data[option.key]);
            keys.forEach((ele) => {
              let ops = data[option.key][ele];
              moreOptionsList.push(ops);
            });
            setMoreOptions(moreOptionsList);
          } else {
            setMoreOptions(moreOptionsList);
          }
        } else {
          handleAddtionalDrawerOpen();
        }
      }


    } else {
      if (option.type == "direct-route") {
        if (option.newTab) {
          //open in new tab
          window.open(option.path, '_blank');
        }else{
          let selected = isSelectedUrl(match, option.path);
          setSelectedOption({ ...option, selected: selected });
          history(option.path);
          setSelectedApp(null);
          setSubOptions([]);
          handleAddtionalDrawerClose();
        }
      } else {
        let subOptionsList = [];
        let keys = Object.keys(option.subOptions);
        keys.forEach((ele) => {
          let ops = option.subOptions[ele];
          subOptionsList.push(ops);
        });
        //Redirect(option.subOptions[keys[0]].path);
        setSubOptions(subOptionsList);
        setSelectedOption(option);
        handleAddtionalDrawerOpen();
        history(subOptionsList[0].path);
        setSelectedApp(null);
        setBreadCrumbList([{ label: option.label, link: option.path }])
      }
    }
  }
  function setLastUsedAppOrClusterinLocalStrorage(option) {
    let localStorageKey = IsSuperUser() ? 'last_used_cluster' : 'last_used_app';
    localStorage.setItem(localStorageKey, JSON.stringify(option));
  }



  function setMainOptions(mainOptions) {
    console.log("dsjdhsj")
    const mainOptionKeys = Object.keys(mainOptions);
    const MainOptionsList = [];
    let isSelectedFound = false;
    console.log("dsjdhsj", mainOptions, mainOptionKeys);
    mainOptionKeys.forEach((key) => {
      let option = mainOptions[key];
      if (option.type == "direct-route") {
        let selected = isSelectedUrl(match, option.path);
        console.log("ytueijwi", option);
        if (selected) {
          setSelectedOption({ ...option, selected: selected });
          history(option.path);
          setBreadCrumbList([{ label: option.label, link: option.path }]);
          isSelectedFound = true;
          setSelectedApp(null);
          setSubOptions([])
          handleAddtionalDrawerClose()
        }
        MainOptionsList.push({ ...option, selected: selected })
      } else {
        if (option.type == "static-route") {
          // console.log("iiruieuow",option, option.type, option.subOptions);
          let tempOption = { ...option };

          let selected = option.subOptionArray.includes(match);
          tempOption = { ...tempOption, selected: selected };
          if (selected) {
            let subOptionsList = [];
            let keys = Object.keys(option.subOptions);
            keys.forEach((ele) => {
              let ops = option.subOptions[ele];
              console.log("iiruieuow", ops);
              if (match == ops.path) {
                //console.log("bdjhjds",bread_crumb_list,ops,match);
                setBreadCrumbList([{ label: ops.label, link: ops.path }])
              }
              subOptionsList.push(ops);
            });
            //bread_crumb_list.push({label: option.label,link: option.path});
            isSelectedFound = true;
            setSelectedOption(option);
            setSubOptions(subOptionsList);
            handleAddtionalDrawerOpen()
          }
          //setSelectedOption(tempOption);
          MainOptionsList.push(tempOption);

        } else {
          let tempOption = { ...option };
          let matchUrl = match.split("/");
          let selected = match.includes(option.ignore_sub_path[0]) && !isNaN(matchUrl[2]);
          //let selected = isSelectedUrl(match,)
          console.log("uiuriejjwe", matchUrl, data, selected);
          tempOption = { ...tempOption, selected: selected };
          if (selected) {
            let subOptionsList = [];
            let keys = Object.keys(option.subOptions);
            let id = matchUrl[2];
            console.log("hdjhuisw",  IsSuperUser(),data);
            let selectedClusApp = IsSuperUser() ? data?.cluster[id] : data?.application[id];
            console.log("hdjhuisw", selectedClusApp);
            let moreOptionsList = [];
            let dataKeys = Object.keys(data[option.key]);
            dataKeys.forEach((ele) => {
              let ops = data[option.key][ele];
              moreOptionsList.push(ops);
            });
            setMoreOptions(moreOptionsList);
            let subSlected = [];
            keys.forEach((key) => {
              let opt = option.subOptions[key];
              let paramKey = option.key + "_id";
              let params = { [paramKey]: selectedClusApp.id.toString() };
              let path = GenerateRouteFromParams(opt.path, params);
              if (match == path) {
                //setBreadCrumbList([{label: opt.label,link: path}])
                subSlected = [{ label: opt.label, link: path }];
              }
              console.log("oyiomnd", path, params);
              subOptionsList.push({ key: opt.key, label: opt.label, path: path });
              if (opt.entity_path) {
                let entity_opened = isPatternMatched(opt.entity_path, match);
                console.log("jhdchdsbcba", entity_opened);
                if (entity_opened) {
                  subSlected = [{ key: "", label: opt.entity_label, link: match }, { key: opt.key, label: opt.label, link: path }]
                }
              }
            })
            isSelectedFound = true;
            setBreadCrumbList([...subSlected, { label: selectedClusApp.name, link: subOptionsList[0].path }, { label: option.label, link: option.parent_path }])
            setSelectedApp(selectedClusApp);
            setSelectedOption(option);
            setSubOptions(subOptionsList);
            handleAddtionalDrawerOpen();
          }
          //setSelectedOption(tempOption);
          MainOptionsList.push(tempOption);

        }
      }
    });
    console.log("uiurene", isSelectedFound)
    if (!isSelectedFound) {
      console.log("nsdjhdj", isSelectedFound);
      setSelectedApp(null);
      setSelectedOption(null);
      setSubOptions([]);
      setBreadCrumbList([]);
      handleAddtionalDrawerClose();
    }
    return MainOptionsList;
  }
  function handleHomeClick() {
    let path = IsSuperUser() ? "/ClusterDashboard" : "/ApplicationDashboard";
    history(path);
    setSelectedOption(null);
    handleAddtionalDrawerClose();
    setSubOptions([]);
  }
  useEffect(() => {
    console.log("is this called")
    let allOptionsList = setMainOptions(mainOptions)
    setAllOption(allOptionsList);
    if (match == "/ApplicationDashboard" || match == "/ClusterDashboard") {
      setSelectedApp(null);
      setSelectedOption(null);
      setSubOptions([]);
      setBreadCrumbList([]);
    }
  }, [match])


  console.log("ndsjksj", allOptions, selectedOption);
  console.log("hdhjdshj", breadCrumbList);
  console.log('alert_00p_pm', alertState)
  return (
    <Box sx={{ display: "flex" }}>
      <div style={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: '999', transition: "margin 0.3s ease-in-out" }} className={additonalDrawer ? classes.additionalMargin : classes.defaultMargin}>
        {/* {
          alertState?.showStripForGlobalPlan &&
          <LicenseExpirationStatusComponent remainingDays={alertState?.remainingDaysForGlobalPlan}
            onClose={onCloseLicenseStrips}
            licenseData={alertState?.license_data}
            user={IsSuperUser('loading_routes') ? "super-user" : "normal-user"}
            showStrip={alertState?.showStripForGlobalPlan}
            additionalDrawer={additonalDrawer}
          />
        } */}

        {
          alertState?.showStripForPremiumFeature &&
          <PremiumInfo featureName={alertState?.featureName}
            featureKey={alertState?.featureKey}
            premiumFeatureInfo={alertState?.premiumFeatureInfo}
            remainingDays={alertState?.remainingDaysForPremium}
            onClose={onCloseForPremiumAlerts}
            data={alertState?.premiumData}
            additionalDrawer={additonalDrawer}
          />
        }
        <BreadcrumbsNew list={breadCrumbList} routes_url={match} super_admin_flag={IsSuperUser()} additionalDrawer={additonalDrawer} />
      </div>
      <Drawer
        className={classes.drawer}
        //style={{width: additonalDrawer? drawerWidth + additionalDrawerWidth : drawerWidth,}}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.permanentNav + ' d-flex f-direction-column'} style={{ zIndex: "9990" }}>
          <div className={classes.itemBox} onClick={handleHomeClick}>
            <img src='/images/BP-new.svg' alt='buildpiper' />
            <span className='font-11 font-weight-500 color-white loading nav-label' style={{ marginTop: "7px" }}>Home</span>
          </div>
          <div style={{ overflowY: "overlay" }}>


            {
              allOptions.length > 0 && allOptions.map((opt, index) => {
                if (opt.condition || opt.condition == null) {
                  return <MainOption option={opt} optionClick={optionClick} selectedOption={selectedOption} />
                } else {
                  return
                }

              })
            }

          </div>
          <div className='d-flex align-center justify-center mb-12 ' style={{ position: "relative", marginTop:'auto' }}>
            <SimpleMenu />
            {
              !additonalDrawer &&
              <div className={classes.toggleChip + ' d-flex align-center justify-center'} style={{ transform: "rotate(180deg)" }} onClick={additonalDrawer ? handleAddtionalDrawerClose : handleAddtionalDrawerOpen}>
                <span className='ri-arrow-right-s-line font-20' style={{ transform: "rotate(180deg)" }}></span>
              </div>
            }

          </div>
        </div>
        <SubOptions additionalDrawer={additonalDrawer} handleAddtionalDrawerOpen={handleAddtionalDrawerOpen} handleAddtionalDrawerClose={handleAddtionalDrawerClose} openMoreDialog={handleMoreDialogOpen} selectedOption={selectedOption} subOptions={subOptions} selectedApp={selectedApp} setBreadCrumbList={setBreadCrumbList} />

        <MoreOptionDialog open={searchDialog} onClose={handleMoreDialogClose} selectedOption={selectedOption} moreOptions={moreOptions} selectedApp={selectedApp} onClick={moreOptionClick} />
      </Drawer>
    </Box>
  );
}

const MainOption = (props) => {
  const classes = useStyles();
  const option = props.option;
  const optionClick = props.optionClick;
  const selectedOption = props.selectedOption;
  return (
    <>
      {
        option.type == "direct-route" ?
          <div className={option?.key == selectedOption?.key ? classes.itemBox + " " + classes.selectedItemBox : classes.itemBox} onClick={option.key == "cluster" ? () => { } : () => { optionClick(option) }}>
            {option.isImg ?
              <img className='nav-icon-img' src={option.icon} alt={option.label} width={20} height={20} />
              :
              <span className={option.icon + " font-20 color-white nav-icon"}></span>
            }
            <span className='font-11 font-weight-500 color-white loading line-normal nav-label'>
              {option.label.includes(' ') ?
                option.label.split(' ').map((part, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {part}
                  </React.Fragment>
                ))
                :
                option.label
              }
            </span>
          </div>

          : option.type == "static-route" ?
            <div className={option?.key == selectedOption?.key ? classes.itemBox + " " + classes.selectedItemBox : classes.itemBox} onClick={() => { optionClick(option) }} > {/*onClick={()=>{optionClick(option)}} */}
              {option.isImg ?
                <img className='nav-icon-img' src={option.isImg} alt={option.label} width={20} height={20} />
                :
                <span className={option.icon + " font-20 color-white nav-icon"}></span>
              }
              <span className='font-11 font-weight-500 color-white loading line-normal nav-label'>
                {option.label.includes(' ') ?
                  option.label.split(' ').map((part, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {part}
                    </React.Fragment>
                  ))
                  :
                  option.label
                }
              </span>
            </div>
            :
            <div className={option?.key == selectedOption?.key ? classes.itemBox + " " + classes.selectedItemBox : classes.itemBox} onClick={() => { optionClick(option) }} > {/*onClick={()=>{optionClick(option)}} */}
              {option.isImg ?
                <img className='nav-icon-img' src={option.isImg} alt={option.label} width={20} height={20} />
                :
                <span className={option.icon + " font-20 color-white nav-icon"}></span>
              }
              <span className='font-11 font-weight-500 color-white loading line-normal nav-label'>
                {option.label.includes(' ') ?
                  option.label.split(' ').map((part, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {part}
                    </React.Fragment>
                  ))
                  :
                  option.label
                }
              </span>
            </div>

      }
    </>
  );
}

const SubOptions = (props) => {
  const classes = useStyles();
  const additonalDrawer = props.additionalDrawer;
  const match = useResolvedPath().pathname;
  console.log("ksmdksjmdkas", additonalDrawer)
  const handleAddtionalDrawerOpen = props.handleAddtionalDrawerOpen;
  const handleAddtionalDrawerClose = props.handleAddtionalDrawerClose;
  const openMoreDialog = props.openMoreDialog;
  const selectedOption = props.selectedOption;
  const subOptions = props.subOptions;
  const selectedApp = props.selectedApp;
  const setBreadCrumbList = props.setBreadCrumbList;
  console.log(subOptions, "sgdvcgsdvc")
  const history = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false)


  useEffect(() => {
    console.log("is this called")
    if(selectedApp && selectedApp.name){
      setShowDropdown(true);
    }
  }, [selectedApp]);



  function subOptionLinkClick(e, item) {
    console.log("e, item", e.preventDefault(), item, selectedOption.type)
    e.preventDefault();

    if (selectedOption.type == "static-route") {
      setBreadCrumbList([{ label: item.label, link: item.path }]);
    } else {
      console.log("skjkasjkaj", item, selectedApp, selectedOption);

      setBreadCrumbList([{ label: item.label, link: item.path }, { label: selectedApp.name, link: subOptions[0].path }, { label: selectedOption.label, link: selectedOption.parent_path }])
    }

    history(item.path);
  }
  const toggleState = () => {
    setShowDropdown(!showDropdown)
  }

  return (
    <>
      {selectedOption && selectedOption.key === "cluster" ?
        <div className={additonalDrawer ? classes.additionalDrawer + " " + classes.additionalDrawerOpened : classes.additionalDrawer + " " + classes.additonalDrawerClosed} style={{ zIndex: "9990" }}>
          <div className={additonalDrawer ? 'opacity-1 head-selector' : 'opacity-0 head-selector'} style={{ gap: '5px' }}>
            <span className='font-11 font-weight-600 avtar color-nav'>{selectedOption ? selectedOption.label : "Welcome"}</span>
            <div className={classes.subOptionsList}>
              <div onClick={toggleState} className={'opt-label font-11 nowrap cursor-pointer'}>Kubernetes Cluster <span className={showDropdown ? 'font-18 ri-arrow-up-s-line d-block ml-auto' : 'font-18 ri-arrow-down-s-line d-block ml-auto'}></span></div>
            </div>
            {
              showDropdown ?
                <>
                  {selectedOption && selectedOption.moreOptions ?
                    <div className='selector' onClick={openMoreDialog}>
                      <span className='font-11 font-weight-500 text-white text-ellipsis-110' style={{ width: "85%" }}>{selectedApp ? selectedApp.name : `Select Cluster`}</span>
                      <span className='ri-arrow-right-s-line font-weight-500 text-white'></span>
                    </div>
                    : null}
                  <div className='divider'></div>
                  <div className={classes.subOptionsList}>

                    {selectedOption && subOptions.map((ele, index) => {
                      console.log("subububu", ele, selectedOption);
                      let selected = isSelectedUrl(match, ele.path)
                      return <>
                        <Link to={ele.path} key={index} className="full-width" onClick={(e) => subOptionLinkClick(e, ele)}>
                          <div className={selected ? 'opt-label font-11 selected-opt-label nowrap' : 'opt-label font-11 nowrap'}>{ele.label}</div>
                        </Link>
                      </>
                    })}

                  </div>
                </>
                : null
            }
            <div className='divider'></div>
            <Link to={"/vm-groups/list"} className="full-width">
              <div className={classes.subOptionsList}>
                <div className={'opt-label font-11 nowrap cursor-pointer'}>Virtual Machines </div>
              </div>
            </Link>
            <Link to={"/load-balancer/list"} className="full-width">
              <div className={classes.subOptionsList}>
                <div className={'opt-label font-11 nowrap cursor-pointer'}>Load Balancer </div>
              </div>
            </Link>
          </div>
          <div className={"chip"}>
            <div className='toggleChip d-flex align-center justify-center' onClick={additonalDrawer ? handleAddtionalDrawerClose : handleAddtionalDrawerOpen}>
              <span className='ri-arrow-right-s-line font-20' style={{ transform: "rotate(180deg)" }}></span>
            </div>
          </div>
        </div> :
        <div className={additonalDrawer ? classes.additionalDrawer + " " + classes.additionalDrawerOpened : classes.additionalDrawer + " " + classes.additonalDrawerClosed} style={{ zIndex: "9990" }}>
          <div className={additonalDrawer ? 'opacity-1 head-selector' : 'opacity-0 head-selector'}>
            <span className='font-11 font-weight-600 avtar color-nav'>{selectedOption ? selectedOption.label : "Welcome"}</span>
            {selectedOption && selectedOption.moreOptions ?
              <div className='selector' onClick={openMoreDialog}>
                <span className='font-11 font-weight-500 text-white text-ellipsis-110' style={{ width: "85%" }}>{selectedApp ? selectedApp.name : `Select ${selectedOption.label}`}</span>
                <span className='ri-arrow-right-s-line font-weight-500 text-white'></span>
              </div>
              : null}
            <div className='divider'></div>
            <div className={classes.subOptionsList}>

              {selectedOption && subOptions.map((ele, index) => {  //Object.keys(selectedOption.subOptions)
                console.log("subububu", ele, selectedOption);
                //let option = selectedOption.subOptions[ele];
                let selected = isSelectedUrl(match, ele.path)

                return <>
                  <Link to={ele.path} key={index} className="full-width" onClick={(e) => subOptionLinkClick(e, ele)}>
                    <div className={selected ? 'opt-label font-11 selected-opt-label nowrap' : 'opt-label font-11 nowrap'}>{ele.label}</div>
                  </Link>
                </>


              })}

            </div>
          </div>
          <div className={"chip"}>
            <div className='toggleChip d-flex align-center justify-center' onClick={additonalDrawer ? handleAddtionalDrawerClose : handleAddtionalDrawerOpen}>
              <span className='ri-arrow-right-s-line font-20' style={{ transform: "rotate(180deg)" }}></span>
            </div>
          </div>
        </div>
      }
    </>

  );
}

const MoreOptionDialog = (props) => {
  const classes = useStyles();
  const open = props.open;
  const onClose = props.onClose;
  const selectedOption = props.selectedOption;
  const moreOptions = props.moreOptions;
  const onOptClick = props.onClick;
  const selectedApp = props.selectedApp;
  const [state, setState] = useState({
    search: "",
    AppClusList: moreOptions ? [...moreOptions] : [],
  })
  useEffect(() => {
    console.log("is this called")
    setState(prevState => ({
      ...prevState,
      AppClusList: moreOptions ? [...moreOptions] : [],
    }));
  }, [moreOptions]);

  useEffect(() => {
    console.log("is this called")
    if (state.search !== '') {
      console.log(state.search, 'ssdfdfdfdfdfddd')
      const list = moreOptions;
      const searchResults = list.filter(item => item.name.toLowerCase().includes(state.search));
      setState(prevState => ({
        ...prevState,
        AppClusList: searchResults
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        AppClusList: moreOptions
      }));
    }
  }, [state.search, moreOptions]);

  const handleSearchData = (e) => {
    const value = e.target.value;

    setState(prevState => ({
      ...prevState,
      search: value
    }))

    console.log("jriejiewjw", e.target.value, state.search);
  }
  console.log("njsndjsna", selectedOption, moreOptions, selectedApp, state.AppClusList);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      className="side-nav-dialog search-card-width"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.third}>
        <div className='top-head'>
          <span className='font-11 font-weight-600 avtar color-key-00'>{selectedOption ? `Select ${selectedOption.label}` : "select"}</span>
          <span className='ri-close-fill color-icon-secondary cursor-pointer font-20' onClick={onClose}></span>
        </div>
        {/* <div className='divider'></div> */}
        <div className='search-container'>
          <span className='ri-search-2-line font-20 light-grey'></span>
          <input type='text'
            placeholder={selectedOption ? `Select ${selectedOption.label}` : ""}
            value={state?.search}
            onChange={handleSearchData}
            disabled={false}
          />
        </div>
        <div className={classes.moreOptionsList}>
          {selectedOption && selectedOption.moreOptions ?
            state.AppClusList.length > 0 ?
              state.AppClusList.map((ele, index) => {
                return <div key={index} className={selectedApp && selectedApp.id == ele.id ? 'selected-more-option opt-label font-12 color-value font-weight-500' : 'opt-label font-12 color-value font-weight-500'} onClick={() => onOptClick(ele, selectedOption.subOptions)}>
                  <Tooltip title={ele.name}>
                    <span className='text-ellipsis-200'>{ele.name}</span>
                  </Tooltip>
                  {selectedApp && selectedApp.id == ele.id ? <span className='ri-checkbox-circle-fill font-20'></span> : null}
                </div>


              })
              : <div className='d-flex align-center justify-center width-full'>No Result Found</div>

            : null}
        </div>

      </div>

    </Dialog>
  )
}


const useStyles = makeStyles((theme) => ({
  defaultMargin: {
    marginLeft: "86px",
    '@media screen and (max-width: 1440px)': {
      marginLeft: "86px",
    },
    '@media screen and (min-width: 1440px) and (max-width: 1920px)': {
      marginLeft: "97px",
    },
  },
  additionalMargin: {
    marginLeft: "256px",
    '@media screen and (max-width: 1440px)': {
      marginLeft: "256pxpx",
    },
    '@media screen and (min-width: 1440px) and (max-width: 1920px)': {
      marginLeft: "291px",
    },
  },
  permanentNav: {
    width: "86px",
    '@media screen and (max-width: 1440px)': {
      width: "86px",
    },
    '@media screen and (min-width: 1440px) and (max-width: 1920px)': {
      width: "97px",
    },
  },
  root: {
    display: 'flex',
  },
  drawer: {
    //width: incWidth? drawerWidth + additionalDrawerWidth : drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    //width: incWidth? drawerWidth + additionalDrawerWidth : drawerWidth,
    //width: "86px",
    display: "flex",
    flexDirection: "row!important",
    //justifyContent: "flex-start",
    backgroundColor: "#083771!important",//"#1E3347",
    border: "none",
    overflow: "visible!important",
    //position: "relative",
    transition: "position 0.5s ease-in-out",

  },
  additionalDrawer: {
    //width: "170px",
    background: "#011327",//"#234361",
    transition: "width 0.3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    '& .opacity-1': {
      opacity: "1",
    },
    '& .opacity-0': {
      opacity: "0",
      transitionDuration: "0.0s !important",
    },
    '& .head-selector': {
      display: "flex",
      flexDirection: "column",
      transition: "opacity 0.6s ease-in-out",
      gap: "12px",
      '& .divider': {
        height: "1px",
        background: "rgba(255, 255, 255, 0.20)",
      },
      '& .selector': {
        display: "flex",
        gap: "8px",
        border: "1px solid rgba(255, 255, 255, 0.30)",//"1px solid rgba(255, 255, 255, 0.3)",
        borderRadius: "4px",
        padding: "8px",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: "#2A3F52",
        height: "36px",
      },
    },
    '& .chip': {
      //position: "relative",
      display: "flex",
      justifyContent: "flex-end",

      '& .toggleChip': {
        width: "23px",
        height: "52px",
        borderRadius: "10px 0px 0px 10px",
        color: "#fff",
        background: "#083771",//"#1E3347",
        marginRight: "-15px",
        // position: "absolute",
        // left: "90%",
        cursor: "pointer",
        //bottom: "-180px",
        //transition: "left 0.5s ease-in-out",
        //marginTop: "-40%",
        '&:hover': {
          background: "#0086FF",
        }
      }
    },
  },
  additonalDrawerClosed: {
    width: "0",
    //display: "none",
    overflow: "hidden",
  },
  additionalDrawerOpened: {
    width: "170px",
    padding: "12px 8px 16px 8px",
    '@media screen and (max-width: 1440px)': {
      width: "170px",
    },
    '@media screen and (min-width: 1440px) and (max-width: 1920px)': {
      width: "194px",
    },
  },

  toggleChip: {
    width: "23px",
    height: "52px",
    borderRadius: "10px 0px 0px 10px",
    color: "#fff",
    background: "#083771",//"#1E3347",
    position: "absolute",
    left: "100%",
    cursor: "pointer",
    //bottom: "-180px",
    // marginTop: "160px",
    //transition: "left 0.5s ease-in-out",
    '&:hover': {
      background: "#0086FF",
    }
  },
  third: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "12px 8px 16px 8px",
    flexDirection: "column",
    gap: "12px",
    '& .top-head': {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      alignSelf: "stretch",
      borderBottom: "1px solid rgba(0, 0, 0, 0.11)"
    },
    '& .divider': {
      height: "1px",
      background: "rgba(0, 0, 0, 0.11)",
    },
    '& .search-container': {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      padding: "6px 10px",
      borderRadius: "6px",
      border: "1px solid #E4E4E4",
      background: "#F4F4F4",
      height: "32px",
      '& input': {
        border: "none",
        background: "transparent",
        padding: 0,
        '&::placeholder': {
          fontSize: "12px",
          color: "#787878",
          fontWeight: "400",
        },
        '&:focus': {
          border: "none !important",
          outline: "none !important",
        }
      }
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  itemBox: {
    display: "flex",
    width: "100%",
    height: "73px",
    padding: "10px 8px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //gap: "5px",
    background: "#083771",//"#1E3347",
    cursor: "pointer",
    '& .nav-icon-img': {
      color: "#8FA0B3",
    },
    '&:hover': {
      background: "#011327",
      '& .nav-icon': {
        color: "#FFF !important",
      },
      '& .nav-label': {
        color: "#FFF !important",
      }
    },
    '@media screen and (max-width: 1440px)': {
      height: "73px",
    },
    '@media screen and (min-width: 1440px) and (max-width: 1920px)': {
      height: "77px",
    },
  },
  selectedItemBox: {
    background: "#011327 !important",//"#234361 !important",
    borderLeft: "4px solid #0086FF",
    '& .nav-icon': {
      color: "#0086FF !important",
    },
    '& .nav-label': {
      color: "#FFF !important",
    },
    '&:hover': {
      background: "#011327 !important",//"#234361 !important",
      '& .nav-icon': {
        color: "#0086FF !important",
      },
    }
  },
  subOptionsList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    alignSelf: "stretch",
    '& .opt-label': {
      display: "flex",
      padding: "8px",
      alignItems: "center",
      gap: "6px",
      alignSelf: "stretch",
      color: "#FFFFFF",
      borderRadius: "6px",
      //width: "100%",
      "&:hover": {
        backgroundColor: "#162D42",
        color: "#FFF",
      }
    },
    '& .selected-opt-label': {
      color: "#FFFFFF !important",
      background: "#0086FF !important",
      '&:hover': {
        color: "#FFFFFF !important",
        background: "#0086FF !important",
      }
    }
  },
  moreOptionsList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    alignSelf: "stretch",
    '& .opt-label': {
      display: "flex",
      height: "36px",
      padding: "8px",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "6px",
      alignSelf: "stretch",
      cursor: "pointer",
      borderRadius: "4px",
      '&:hover': {
        backgroundColor: "#F1F6F8",
      }
    },
    '& .selected-more-option': {
      backgroundColor: "#0086FF",
      color: "#FFFFFF !important",
      '&:hover': {
        backgroundColor: "#0086FF !important",
        color: "#FFFFFF !important",
      }
    }
  },
}));
