import React from 'react'
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";
import { ExandableCategoryList, ExandableComponentList, ExpandableServiceList, ExpandableTagList } from '../../../components/hoc/expandableList';
import { DeleteData } from '../../../util/apiInvoker';
import GenerateURL from '../../../util/APIUrlProvider';
import Delete from '../../../components/genericComponents/Delete';
import properties from '../../../properties/properties';

const StabilityMatrixItem = ({ key, groupName, matrix, providers, id,}) => {
    const classes = useStyles();
   

    return (
        <>
            <div className={classes.stabilityMatrixItem}>
                <div className='item-column' style={{ width: "219px" }}>
                    <div className='profile'>{groupName.slice(0, 1)}</div>
                    <div className='name'>{groupName}</div>
                </div>
                <div className='item-column' style={{ width: "285px" }}>
                    {/* <ExpandableServiceList list={providers} iteration_count={3} /> */}
                    {/* <ExandableCategoryList compoenent_list={providers} iteration_count={3}/> */}
                    <ExpandableTagList list={providers} />
                    {/* <ExandableComponentList compoenent_list={providers} iteration_count={3} expandable_component={3}/> */}
                    {/* {providers.map((e) => <ItemCover name={e} />)} */}
                </div >
                <div className='item-column' style={{ width: "373px" }}>
                    <ExpandableTagList list={matrix} />
                    {/* <ExandableComponentList compoenent_list={matrix} iteration_count={3} expandable_component={3} /> */}

                    {/* {matrix.map((e) => <ItemCover name={e} />)} */}
                </div >
                <div className='item-column' style={{ width: "100px", gap: "1px" }} >
                    <Link to={`/edit/stability/matrix/${id}`}>
                        <button className='icon'>
                            <span className='ri-edit-line font-20 blue124D9B'></span>
                        </button>
                    </Link>
   
                    <Delete
                        // data={item}
                        // refresh={refreshFun}
                        data={{ entity_id: id, name: "metric_group" }}
                        varient="new_button"
                        api_link={GenerateURL({ "id": id }, properties.api.stability_matrix_url_delete)}
                    />
                </div >
            </div>
        </>
    )
}


export default StabilityMatrixItem;

const ItemCover = ({ name }) => <div style={{
    display: 'flex',
    padding: '6px 12px',
    alignItems: 'center',
    gap: '6px',
    borderRadius: '38px',
    border: '1px solid #E6E6E6',
    background: '#F4F4F4',
}}>
    {name}
</div >


const useStyles = makeStyles((theme) => ({

    stabilityMatrixItem: {

        display: 'flex',
        // width: '984px',
        padding: '12px 12px',
        alignItems: 'center',
        color: '#787878',
        fontSize: '12px',
        borderTop: '1px solid #F5F6F7',
        justifyContent: 'space-between',


        '& .item-column': {
            display: "flex",
            alignItems: "center",
            justifyContent: 'flex-left',
            gap: "12px",
        }
        ,
        '& .profile': {
            width: '30px',
            height: '30px',
            borderRadius: "50px",
            background: "#129E5B",
            lineHeight: '30px',
            textAlign: 'center',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '700',
            color: '#FFF'
        },
        '& .name': {
            color: '#0086FF',
            fontSize: '13px',
            fontWeight: '600'

        },
        '& .icon': {
            display: 'flex',
            padding: '8px',
            borderRadius: '6px',
            border: '1px solid #DBDBDB',
            background: '#FFF',

            "&:nth-child(1):hover": {
                background: '#0086FF',
            },

            "&:nth-child(1):hover span": {
                color: "#FFF !important"
            },
            "&:nth-child(2):hover": {
                background: '#C11212',
                color: "#FFF"
            },
            "&:nth-child(2):hover span": {
                color: "#FFF !important"
            }
        },
        '& .chip-default-square': {
            display: "flex",
            padding: "6px 12px",
            alignItems: "center",
            gap: "8px",
            borderRadius: "38px",
            border: "1 solid  #E6E6E6",
            background: "#F4F4F4",
            color: "#787878",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            alignSelf: "stretch",
            height : "27px",
            maxWidth: 'fit-content',
        },
        '& .chip-default': {
            boxShadow: 'none',
            background: "#fff"
        }

    }
}))

