import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';



const ConditionalStepCardDetail = (props) => {

  const {stepData } = props
  
  console.log('s-d-p',stepData)

  const getData = {

    "order": 5,
    "step_code": "Conditional step",
    "step_name": "Conditional step",
    "condition_value": "",
    "condition_variable": "BUILD_IMAGE_NAME",
    "is_conditional_step": true,
    "values_step_mapping": [
        {
            "steps": [
                {
                    "id": 50003,
                    "order": 0,
                    "step_code": "spider_ment",
                    "step_name": "Spidermen",
                    "customVariables": {},
                    "is_conditional_step": false,
                    "environment_variables": [
                        {
                            "key": "web 2"
                        },
                        {
                            "key": "web 1",
                            "value": "yes"
                        }
                    ]
                },
                {
                    "id": 31000,
                    "order": 1,
                    "step_code": "JIRA_INTEGRATION",
                    "step_name": "jira integration",
                    "customVariables": {},
                    "is_conditional_step": false,
                    "environment_variables": [
                        {
                            "key": "PROJECT_NAME"
                        },
                        {
                            "key": "SERVER_URL"
                        },
                        {
                            "key": "EMAIL"
                        },
                        {
                            "key": "AUTH_TOKEN"
                        }
                    ]
                },
                {
                    "id": 50003,
                    "order": 2,
                    "step_code": "spider_ment",
                    "step_name": "Spidermen",
                    "customVariables": {},
                    "is_conditional_step": false,
                    "environment_variables": [
                        {
                            "key": "web 2"
                        },
                        {
                            "key": "web 1",
                            "value": "yes"
                        }
                    ]
                }
            ],
            "value": "Condition 1"
        }
    ],
    "selected": true,
    "version": "v2"
}

  return (
    

      <div className="m-20 step-dialog-card">

        <div className="header-card">
          <button className="mr-12" style={{ height: '20px', width: '25px', backgroundColor: '#ff7800', color: 'white', border: 'none', borderRadius: '3px' }}>C</button>
          <span className='font-14 text-color-black'>Conditional Step</span>
        </div>
        <div style={{ backgroundColor: '#f5f5f5' }}>

          {
            stepData && Object.keys(stepData) && Object.keys(stepData).map((key, index) => {
              const mapping =  stepData[key]

              return (
                <div key={index} className='margin-bottom-accordian'>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore style={{ fontSize: '25px', color: '#000000a6' }} />}
                      aria-controls="panel1a-content"
                      id={key + index}
                    >
                      <div style={{display: 'flex' }}>
                      <span style={{whiteSpace: 'nowrap'}} className='font-13 text-color-dark-gray mr-5'>{'If '}</span>
                      <br/>
                      <label style={{maxWidth: '40px'}} className='font-13 text-color-dark-gray env-text-ellipsis-130 mr-5'>{stepData.condition_variable?` ${stepData.condition_variable}`:''}</label> 
                      <span style={{whiteSpace: 'nowrap'}} className='font-13 text-color-dark-gray mr-5'>=</span>                     
                      <label style={{color:'black', maxWidth : '40px'}} className='font-13 env-text-ellipsis-130 mr-5'>{` "${key}" `}</label>
  
                      <span style={{whiteSpace: 'nowrap'}} className='font-13 text-color-dark-gray'>run following steps</span> 
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block', padding: '0px' }}>
                      <>
                        {
                          mapping && mapping.map((step, order) => {

                            console.log('_ind', index)
                            return (
                              <div className='no-m-bottom-accordian'>
                                <Accordion className='accordian-job-div accordian-job-detail-div'>
                                  <AccordionSummary
                                    expandIcon={<ExpandMore style={{ fontSize: '25px', color: '#000000a6' }} />}
                                    aria-controls="panel1a-content"
                                    id={`${step.step_name + index}`}
                                  >
                                    <label className='font-13 mr-10'>Step {order + 1}: </label><label className='font-13 text-color-dark-gray'>{step.step_name}</label>
                                  </AccordionSummary>
                                  <AccordionDetails className='accordian-job-detail-div'>

                                    {


                                      step.environment_variables ?

                                        <>
                                          <div className='align-center pl-16 pr-16' style={{ height: '40px', width: '100%', display: 'flex', backgroundColor: 'whitesmoke', borderTop: '1px solid #c9c9c9' }}>
                                            <div style={{ width: '50%' }}><span className='font-13'>Variable Name</span></div>
                                            <div style={{ width: '50%' }}><span className='font-13'>Variable Value</span></div>
                                          </div>
                                          {

                                            step.environment_variables && step.environment_variables.map((envVarObj, index) => {

                                              return (

                                                <div key={index} className='align-center pl-16 pr-16' style={{ height: '40px', width: '100%', display: 'flex', borderTop: '1px solid #c9c9c9' }}>
                                                  <div className='env-var-table' style={{ width: '50%' }}><label className='font-13 text-color-dark-gray'>{envVarObj.key}</label></div>
                                                  <div className='env-var-table' style={{ width: '50%' }}><label className='font-13 text-color-dark-gray'>{envVarObj.value}</label></div>
                                                </div>
                                              )
                                            })
                                          }

                                        </> : 
                                        
                                        <div className='align-center justify-center pl-16 pr-16' style={{ height: '40px', width: '100%', display: 'flex', backgroundColor: '#f2f2f2', borderTop: '1px solid #c9c9c9' }}>
                                        <span className='font-13 text-color-dark-gray'>No Environment Variables</span>
                                        
                                      </div>
                                    }


                                  </AccordionDetails>
                                </Accordion>

                              </div>

                            )
                          })

                        }

                      </>

                    </AccordionDetails>
                  </Accordion>



                </div>
              )
            })

          }

        </div>

      </div>

    
  )

}


ConditionalStepCardDetail.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};



export default ConditionalStepCardDetail