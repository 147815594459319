import { styled } from "@mui/system";
import React, { useEffect, useRef, useState } from 'react';

const SliderTabs = ({ tabsList, selectedTabId, onChange }) => {
    const [activeTabId, setActiveTabId] = useState(null);
    const tabsRefs = useRef([]);
    const activeIndicatorRef = useRef(null);

    const handleTabClick = (id) => {
        setActiveTabId(id);
        if (onChange) onChange(id);
    };
    useEffect(() => {
        setActiveTabId(selectedTabId);
    },[selectedTabId])

    useEffect(() => {
        const activeTabIndex = tabsList.findIndex((tab) => tab.id === activeTabId);
        const activeTabElement = tabsRefs.current[activeTabIndex];
        const activeIndicator = activeIndicatorRef.current;

        if (activeTabElement && activeIndicator) {
            activeIndicator.style.width = `${activeTabElement.offsetWidth}px`;
            activeIndicator.style.height = `${activeTabElement.offsetHeight}px`;
            activeIndicator.style.transform = `translateX(${activeTabElement.offsetLeft}px)`;
        }
    }, [activeTabId, tabsList]);
    console.log("lksddddddd",activeTabId)
    return (
        <Container>
            <ActiveIndicator ref={activeIndicatorRef} />
            {tabsList.map((tab, index) => (
                <Tab
                    key={tab.id}
                    ref={(el) => (tabsRefs.current[index] = el)}
                    isActive={tab.id === activeTabId}
                    onClick={() => handleTabClick(tab.id)}
                >
                    {tab.label}
                </Tab>
            ))}
        </Container>
    );
};

export default SliderTabs;

const Container = styled('div')({
    border: '1px solid #E6E6E6',
    borderRadius: '40px',
    padding: '6px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    position: 'relative',
    overflow: 'hidden',
    width: 'fit-content',
});

const Tab = styled('span')(({ isActive }) => ({
    borderRadius: '40px',
    padding: '6px 12px',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '14px',
    color: isActive ? '#2f2f2f' : '#7f7f7f',
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1,
    whiteSpace: 'nowrap',
}));

const ActiveIndicator = styled('div')({
    position: 'absolute',
    top: 6,
    left: 0,
    backgroundColor: '#FFFFFF',
    borderRadius: '40px',
    boxShadow: '2px 4px 4px 0 rgba(0, 0, 0, 0.08)',
    transition: 'transform 0.3s ease, width 0.3s ease',
    zIndex: 0,
});
