import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Loading, LoadingText } from '../../../../../utils/Loading';
import { Input } from '../../../../../../components/genericComponents/Input';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import InvokeApi from '../../../../../../util/apiInvoker';
import { CircularProgress, Tooltip } from '@mui/material';

const LogSection = props => {
    const selected_pod = props.selected_pod;
    const env_id = props.env_id;
    const selected_pod_name = props.selected_pod_name;
    const logs = props.logs;
    const service_id = props.service_id;
    const cd_id = props.cd_id;
    console.log(logs, "lflflflf")
    const refreshTab = props.refreshTab;
    const loading_logs = props.loading_logs;
    const logs_error = props.logs_error;

    const [state, setState] = useState({ data: {}, error: {} })
    const [offset, setOffset] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true); 
    const [logsDownloading,setLogsDownloading] = useState(false);
    const logsContainerRef = useRef(null);

    console.log('dhsdjd', selected_pod);

    useEffect(() => {
        if (selected_pod) {
            getAllAvailableContainers();
        }
    }, [selected_pod])

    useEffect(() => {
        if (state.data.container_name) {
            setOffset(0);
            setInitialLoad(true);
            loadLogsForPod(0);
        }
    }, [state.data.container_name,selected_pod])

    // useEffect(()=>{
    //     const container = logsContainerRef.current;
    //     if(container){
    //         container.addEventListener('scroll',handleScroll);
    //         return () => container.removeEventListener('scroll',handleScroll);
    //     }
    // },[offset,isFetching])

    useEffect(() => {
        if (state.logs && state.logs[selected_pod_name]?.logs && initialLoad) {
            //scrollToBottom();
            setInitialLoad(false);
        }
    }, [state.logs, selected_pod_name, initialLoad]);

    // const handleScroll = () => {
    //     if(logsContainerRef.current.scrollTop === 0 && !isFetching){
    //         loadLogsForPod(offset);
    //     }
    // }

    // const scrollToBottom = () => {
    //     const container = logsContainerRef.current;
    //     if (container) {
    //         setTimeout(() => {
    //             container.scrollTop = container.scrollHeight;
    //         }, 0);
    //     }
    // };

    function getAllAvailableContainers() {
        console.log(selected_pod,"erjfgbkwelrkjgrunning")
        let container_data = selected_pod?.spec?.containers
        let init_container = selected_pod?.spec?.initContainers

        let list = []

        container_data.forEach((item) => {
            console.log(item, "sbvhsgvbhfsdhvb")
            let obj = {
                id: item.name,
                label: item.name
            }
            list.push(obj)
        });

        if(init_container && init_container.length > 0){
            init_container.forEach((item)=>{
                let obj = {
                    id: item.name,
                    label: item.name,
                }
                list.push(obj);
            });
        }

        setState((new_state) => ({
            ...new_state,
            container_list: list,
            data: {
                container_name: list[0].id
            }
        }))
    }

    function loadLogsForPod(currentOffset = 0) {
        setIsFetching(true);
        var requestInfo = {
            // endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, pod_name: selected_pod.metadata ? selected_pod.metadata.name:null, cd_id:cd_id }, properties.api.pod_log),
            endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, pod_name: selected_pod.metadata ? selected_pod.metadata.name : null }, properties.api.pod_log_widget + `?deployment_name=${cd_id}&container_name=${state.data.container_name}&offset=${currentOffset}&limit=500`), 
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (response) => handleSuccessLogsload(response,currentOffset), handleFailedLogsLoad);

        setState(new_state => ({
            ...new_state,
            loading_logs: true,
        }));
    }
    function handleFailedLogsLoad(error, exception) {
        setState(new_state => ({
            ...new_state,
            loading_logs: false,
            logs_loaded: true,
            logs_error: exception ? "Something wrong at our servers" : error,
            logs: null,
        }));
        setIsFetching(false);
    }
    function handleSuccessLogsload(response,currentOffset) {
        let logs_list = response && response?.length > 0 ? response: [];
        setState(new_state => ({
            ...new_state,
            logs_loaded: true,
            loading_logs: false,
            logs_error: null,
            logs: {
                ...new_state.logs,
                [selected_pod.metadata.name]: {
                    logs: currentOffset === 0 ? [...logs_list] : [...new_state.logs[selected_pod.metadata.name]?.logs || [],...logs_list],
                },
            }
        }));
        setOffset((prevOffset) => prevOffset + 500);
        setIsFetching(false);
    }

    function fetchAllLogs(){
        var requestInfo = {
            // endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, pod_name: selected_pod.metadata ? selected_pod.metadata.name:null, cd_id:cd_id }, properties.api.pod_log),
            endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, pod_name: selected_pod.metadata ? selected_pod.metadata.name : null }, properties.api.pod_log_widget + `?deployment_name=${cd_id}&container_name=${state.data.container_name}&all=${true}`), 
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (response) => handleSuccessAllLogs(response), handleFailedAllLogs);
        setLogsDownloading(true)
    }

    function handleSuccessAllLogs(response) {
        console.log("dsjkskjsd",response);
        const responseType = typeof (response);
        if (responseType == 'string') {
            var data = new Blob([response], { type: 'text' });
            var csvURL = window.URL.createObjectURL(data);
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', "logs" + '.txt', 'text/plain');
            tempLink.click();

        } else {
            const href = URL.createObjectURL(response);

            const a = Object.assign(document.createElement('a'), {
                href,
                style: "display:none",
                download: 'logs.zip'
            });
            document.body.appendChild(a);
            a.click();
            href.revokeObjectUrl(href)
            a.remove();
        }
        setLogsDownloading(false);
    }

    function handleFailedAllLogs(error){
        console.log("djjds",error);
        setLogsDownloading(false);
    }

    function getFullScreenElement() {
        return document.fullscreenElement
            || document.webkitFullscreenElement
            || document.mozFullscreenElement
            || document.msFullscreenElement
    }
    function toggleFullScreen() {
        if (getFullScreenElement()) {
            document.exitFullscreen();
        } else {
            document.getElementById("podsLogViewSCreen").requestFullscreen().catch(console.log)
        }
    }

    function onChangeDropdownHandler(e) {
        var key = e.target.name;
        var value = e.target.value;
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value,

            },
            error: {
                ...state.error,
                [key]: null,
            },

        });
    }

    console.log(state, "abdcgasvcgsdvcsdg")

    return (
        <div>
            {
                !loading_logs ?
                    <>

                        <HeadingSection>
                            <div className="text-setion">
                                <div className="main-heading">
                                    Logs
                                </div>
                                <div className="small-text">
                                    Displaying Latest logs on top
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className='d-grid' style={{ gridTemplateColumns: '1fr 300px', alignItems: 'baseline' }}>
                                    <div className='font-11'>Select Container:</div>
                                    <div className="input-mb-0 mb-0">
                                        <Input
                                            type="select"
                                            label=""
                                            name="container_name"
                                            list={state.container_list ? state.container_list : []}
                                            onChangeHandler={onChangeDropdownHandler} //onChangeDropdownHandler
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </div>
                                </div>
                                <div className="count-chip-section" style={{ marginLeft: "10px" }}>
                                    <button style={{ marginLeft: 'auto', display: "block" }} onClick={() =>{setOffset(0); loadLogsForPod(0);}} class="btn-round border-navy bg-clear-btn"><span class="flaticon-refresh-button-1"></span></button>
                                </div>
                            </div>
                        </HeadingSection>
                        {
                            // state.loading_logs ?
                            //     <Loading varient="light" />
                            //     :
                            <LogDiv id="podsLogViewSCreen">
                            <LogHeader>
                                POD: {selected_pod_name}
                                <div className='d-flex align-center' style={{gap:'5px'}}>
                                    <span
                                        onClick={toggleFullScreen}
                                        className={
                                            !getFullScreenElement()
                                                ? "flaticon-switch-to-full-screen-button cursor-pointer"
                                                : "flaticon-full-screen-exit cursor-pointer"
                                        }
                                    ></span>
                                    {logsDownloading ? 
                                        <StyledLoader />
                                    :
                                        <Tooltip title={"Download Logs"}>
                                            <span className='ri-download-2-line font-18 cursor-pointer' style={{color:'#ffffff',lineHeight:'1'}} onClick={fetchAllLogs}></span>
                                        </Tooltip>
                                    }
                                </div>
                            </LogHeader>
                            <div className="logbody" ref={logsContainerRef}>
                                {isFetching && initialLoad ? 
                                    <div className='d-flex align-center justify-center'> 
                                        <div className="loading-div">
                                            <LoadingText />
                                        </div>
                                    </div>
                                :
                                    <>
                                    {state.logs && state.logs[selected_pod_name] && state.logs[selected_pod_name].logs ? (
                                        state.logs[selected_pod_name].logs.length > 0 ? (
                                            <>
                                                {state.logs[selected_pod_name].logs.map((log, key) => (
                                                    log !== "" && (
                                                        <p key={key}>
                                                            <span className='' style={{ marginRight: 5, display: 'inline-block' }}>{key + 1}</span>
                                                            <span>{log}</span>
                                                        </p>
                                                    )
                                                ))}
                                                {/* Show More Button */}
                                                {isFetching && !initialLoad ? (
                                                    <div className='d-flex align-center justify-center'> 
                                                        <div className="loading-div">
                                                            <LoadingText />
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                <div className='d-flex justify-center'>
                                                    <div className="show-more-btn" onClick={() => loadLogsForPod(offset)}>
                                                        Show More
                                                    </div>
                                                </div>
                                                
                                                }
                                            </>
                                        ) : (
                                            <p>No logs to show</p>
                                        )
                                    ) : (
                                        <>
                                        <p>Logs Not Available</p>        
                                        </>
                                    )}
                                    
                                    </>
                                }
                            </div>

                        </LogDiv>
                        
                        }
                    </>
                    : <LoadingText />}
        </div>
    )
}

LogSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default LogSection;
const LogHeader = styled('div')({
    padding: '10px',
    borderBottom: '1px solid #fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff',
    fontSize: '11px',
    '& .flaticon-switch-to-full-screen-button': {
        color: '#fff'
    }
})

const LogDiv = styled('div')({
    backgroundColor: '#000',
    color: '#000',
    borderRadius: '0px 0px 8px 8px',
    '& p': {
        color: '#fff',
        fontSize: '11px'
    },
    '& .logbody': {
        padding: '10px',
        height: '250px',
        overflowY: 'scroll',
    },
    '& #podsLogViewSCreen:fullscreen .logbody': {
        height: '100%!important',
        overflowY: 'scroll',
    },
    '& .loading-div': {
        //marginLeft: '50px',
        '& .loading-text-cc': {
            justifyContent: 'left',
            height: 'auto'
        },
        '& .ring-1': {
            border: '3px dashed #fff',
            height: 5,
            width: 5,
            borderRadius: '50%'
        }
    },
    '& .show-more-btn': {
        padding:'8px 12px 8px 12px',
        backgroundColor:'#E6E6E6',
        color:'#124D9B',
        width:'fit-content',
        borderRadius:'6px',
        fontSize:'12px',
        marginTop:'8px',
        cursor:'pointer',
        height:'32px',
        borderColor:'1px solid #9DC0EE',
    }
})

const HeadingSection = styled('div')({
    margin: '10px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .main-heading': {
        fontSize: '18px',
        lineHeight: '24px',
        color: '#526174',
        
        fontWeight: 300
    },
    '& .small-text': {
        fontSize: '11px',
        lineHeight: '13px',
        color: '#999'
    },
    '& .count-chip-section': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '& .chip-large': {
        borderRadius: '4px',
        padding: '5px',
        minWidth: '50px',
        margin: '5px',
        '& .count-chip-large': {
            fontSize: '14px',
            fontWeight: 300,
            color: '#fff',
            textAlign: 'center'
        },
        '& .text-chip-large': {
            fontSize: '11px',
            fontWeight: 300,
            color: '#fff',
            textAlign: 'center'
        },
    }

})

const StyledLoader = styled(CircularProgress)({
    color: '#ffffff', // White color
    width: '18px !important', // Set width to 18px
    height: '18px !important', // Set height to 18px
});