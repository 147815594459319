import React from 'react';
import PropTypes from 'prop-types';
import AlphaThumbnail from '../../../../components/genericComponents/AlphaThumbnail';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';


const Header = ({skeleton,...props}) => {
    if(skeleton){
        return (
            
                <div className='d-flex align-center' style={{gap:"10px"}}>
                    <GenericSkeleton variant={"rect"} width={50} height={50} style={{borderRadius:"8px"}} />
                    <div>
                        <GenericSkeleton variant={"text"} width={80} style={{lineHeight:"2"}} />
                        <GenericSkeleton variant={"text"} width={150} style={{lineHeight:"1"}}/>
                    </div>
                </div>
                
           
            
        );
    }
    const pipeline = props.pipeline;
    const pipeline_instance = props.pipeline_instance;
    const application_id = props.application_id;
    const varient = props.varient;
    const classes = useStyles();
    var start_time = "";
    var end_time = "";
    var difference = "";
    var time_taken = "";
    var trigger_by = "";
    var trigger_type = "";

    if(pipeline_instance){
         start_time = new Date(pipeline_instance.created_at);
         end_time = new Date(pipeline_instance.updated_at);
         difference = end_time - start_time;
         time_taken = formatTime(difference);
         trigger_by = pipeline_instance.trigger_by ?pipeline_instance.trigger_by:"System";
         trigger_type = `${pipeline_instance.trigger_type.name}${pipeline_instance.trigger_type?.release_version ? ` - Release Package - ${pipeline_instance.trigger_type?.release_version}` : ''}`;
    }
    console.log("jdkjdk",pipeline_instance);



    return(
        <>

            {
                varient != "without_header" ?
                <Tooltip title="red" placement="bottom">
                <AlphaThumbnail 
                    thumbnailStatus={pipeline.status == "PENDING_RESUME" ? "blue"  : pipeline.status == "PENDING_APPROVAL" ?  "purple" : "green"}
                    alphabet = {pipeline.name}
                />
                </Tooltip>
                :null
            }

            <div className="text-icon-section">
                
                {
                    varient != "without_header" ?
                    <div className="service-name"> 
                        {  pipeline_instance ? 
                            <Link className=" text-width-300" to={
                                application_id ? 
                                "/application/"+application_id+"/pipeline/"+pipeline_instance.pipeline_id+"/execution/"+pipeline_instance.id
                                :
                                "/global/pipeline/"+pipeline_instance.pipeline_id+"/execution/"+ pipeline_instance.id
                            
                            } >{pipeline.name}</Link>
                            :
                            <Link  className=" text-width-300" to={
                                application_id ? 
                                "/application/"+application_id+"/pipeline/"+pipeline.id+"/edit"
                                :
                                 "/global/pipeline/"+pipeline.id+"/edit"
                            }>{pipeline.name}</Link>
                        }
                        
                    </div>:null
                }


                {
                    pipeline_instance  ?
                <div className="service-details">
                    <div className="owner-name">
                    <span className="main-text text-black"><span className="flaticon-clock-with-white-face"></span>Last Run:&nbsp;</span>
                        <span className="sub-text">{start_time.toString().substring(0,start_time.toString().indexOf("GMT"))}&nbsp;&nbsp;</span>
                        <span className="main-text text-black"><span className="flaticon-history-clock-button"></span>Duration: </span>
                        <span className="sub-text " style={{color:'#868181'}}>&nbsp; {time_taken}&nbsp;&nbsp;</span>
                        {/*<span className="owner ">Trigger: </span>
                        <span>&nbsp; {pipeline.trigger_type.name}</span> */}
                        {
                            pipeline.cron_regex ?
                            <>
                                <span className="owner text-black"><span className='ri-bar-chart-grouped-line font-16' style={{color:"#5f6e9e", marginRight:'5px',marginBottom:'4px',lineHeight:'1'}} />Frequency: &nbsp;</span>
                                <span  style={{color:'#868181'}}>&nbsp; {pipeline.cron_regex}&nbsp;&nbsp;</span>
                            </>:null
                        }
                        
                            <span className="owner text-black"><span className="flaticon-round-data-usage-symbol"></span>Retention: &nbsp;</span>
                            <span  style={{color:'#868181'}}>{pipeline.retention_execution_count}&nbsp;&nbsp;</span>
                        
                        
                        <span className="main-text text-black"><span className="flaticon-flash-on-indicator"></span>Triggered by:&nbsp;</span>
                        <span className="sub-text" style={{color:'#868181'}}>{trigger_by + " ( "+ trigger_type +" )"}  &nbsp;</span>
                    </div>
                </div>:null}
            </div>
         
        </>
    )
}

Header.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default Header;

function formatTime(time_in_miliseconds){
    var time_in_seconds = time_in_miliseconds/1000;
    if(time_in_seconds < 60){
        return Math.round(time_in_seconds) + " sec";
    }

    var time_in_minutes = time_in_seconds/60;
    if(time_in_minutes < 60){
        return Math.round(time_in_minutes) + " min";
    }

    var time_in_hour = time_in_minutes/60;
    if(time_in_hour < 60){
        return Math.round(time_in_hour) + " hr";
    }
}






const useStyles = makeStyles(theme => ({
    stageData:{
        display:'flex',
        alignItems:'center',
        lineHeight:1.5,
        '& .main-text':{
            color:'#000!important',
            fontSize: '13px',
            marginRight:'0px'
        },
        '& .sub-text':{
            color:'#949494',
            fontSize: '13px'
        },
        '& .status-font':{
            fontSize: '14px',
            textTransform:'uppercase'
        },
        '& .success':{
            color: '#62E187'
        },
        '& .success .flaticon-circle-with-check-symbol':{
            margin: '0px 4px',
            color:'#62E187'
        },
        '& .success .flaticon-circle-with-check-symbol:before':{
            fontSize:'14px!important',
            color:'#62E187'
        }
    },
    executionData:{
        display:'flex',
        alignItems:'center',
        lineHeight:1,
        '& .main-text':{
            color:'#000',
            fontSize: '12px',
            marginRight:'0px'
        },
        '& .sub-text':{
            color:'#949494',
            fontSize: '12px'
        },
        '& .sub-text .flaticon-clock-with-white-face':{
            margin:'0px!important'
        },
        '& .sub-text .flaticon-flash-on-indicator':{
            margin:'0px!important'
        },
        '& .chip-default-square':{
            color:'#fff',
            maxWidth:'170px',
            fontSize:'11px',
            margin:'0px 3px'
        }
    },
    d_Flex:{
        display:'flex',
        alignItems:'center',
    }
}))



