import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';


export const IconLink = (props) =>{
    const label = props.label;
    const url = props.url;
    const img = props.img;

    return(
        <Tooltip title={label} placement="bottom">
        <a
        href={url}
        target="_blank"
        className="btn-round btn-rnd">
            <img src={img} alt=""/>
        </a>
        </Tooltip>
    );
}

IconLink.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export const IconLinkList = (props) =>{
    const list = props.list;
    return(
        <div className="monitor-urls">
            {
                list.map((element,key)=>(
                    <IconLink key={key} label={element.label} url={element.url} img={element.img} />
                    
                ))
            }
        </div>
    );
}

IconLinkList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }