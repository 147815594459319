import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(theme => ({
    stageData:{
        display:'flex',
        alignItems:'center',
        lineHeight:1.5,
        whiteSpace:'nowrap',
        '& .main-text':{
            color:'#000',
            fontSize: '13px',
            marginRight:'0px'
        },
        '& .sub-text':{
            color:'#949494',
            fontSize: '13px',
            whiteSpace:"nowrap"
        },
        '& .status-font':{
            fontSize: '14px',
            textTransform:'uppercase',
            '& .flaticon-round-pause-button':{
                color:'#fff',
                margin:'0px 5px !important',
                '&:before':{
                    fontSize:'16px'
                }
            }
        },
        '& .success':{
            color: '#62E187'
        },
        '& .success .flaticon-circle-with-check-symbol':{
            margin: '0px 4px',
            color:'#62E187'
        },
        '& .success .flaticon-circle-with-check-symbol:before':{
            fontSize:'14px!important',
            color:'#62E187'
        },
        '& .bold-text-purple .flaticon-circle-with-check-symbol:before, .flaticon-circle-with-check-symbol:before, .flaticon-circle-with-check-symbol:after, .flaticon-circle-with-check-symbol:after':{
            fontSize:'14px!important'
        }
    },
    executionData:{
        display:'flex',
        alignItems:'center',
        lineHeight:1,
        '& .main-text':{
            color:'#000',
            fontSize: '12px',
            marginRight:'0px'
        },
        '& .sub-text':{
            color:'#949494',
            fontSize: '12px'
        },
        '& .sub-text .flaticon-flash-on-indicator':{
            margin:'0px!important'
        },
        '& .chip-default-square':{
            color:'#fff',
            maxWidth:'170px',
            fontSize:'11px',
            margin:'0px 3px'
        }
    },
    d_Flex:{
        display:'flex',
        alignItems:'center',
    }
}))


const GeneralData = (props) => {
    const classes = useStyles();
    const pipeline = props.pipeline;
    const pipeline_instance = props.pipeline_instance;

    console.log(pipeline_instance,"skjgvchdchjsd")
    
    const stage_complete_status = getStatus();

    function getStatus(){
        var instance_length = "";
        if(pipeline_instance.stage_instance.length > 0 && pipeline_instance.stage_instance.length < pipeline.stages.length){     
            instance_length = pipeline_instance.stage_instance.length-1;
        }else{
            instance_length = pipeline_instance.stage_instance.length;
        }
        return instance_length + "/" + pipeline.stages.length;
    }

    function getProgressStatusIcon(){
        switch(pipeline_instance.status){ //pipeline_status
        case "SUCCESS":
            return(
                <div className="status-chip status-chip-success"><span className="flaticon-circle-with-check-symbol"></span> Success </div>
            );
        case "FAILED":
            return(
                <div className="status-chip status-chip-danger"><span className="flaticon-cancel-button"></span> Failed</div>
                );
        case "RUNNING":
            return(
                <div className="status-chip status-chip-warning"><span className="flaticon-clock-with-white-face"></span> Running </div>
            );
        case "PENDING_APPROVAL":
            return(
                <div className="status-chip status-chip-approval"><span className="flaticon-thumb-up-button"></span> Approval Required </div>
            )
        case "PENDING_RESUME":
            return(
                <div className="status-chip status-chip-warning"><span className="flaticon-round-pause-button " style={{color:'#fff'}}></span> Waiting </div>
            )
        case "DISAPPROVED":
            return(
                <div className="status-chip status-chip-disapproved" style={{backgroundColor:'#ff8969'}}><span className="flaticon-thumb-down-button" style={{color:'#fff'}}></span> Disapproved </div>
            )
        case "SUSPENDED":
            return(
                <div className="status-chip status-chip-warning" style={{backgroundColor:'rgb(142, 142, 142)'}}><i className="ri-error-warning-line" style={{coloe:'#d3d3d4'}}></i> Suspended </div>
            )
        case "REVOKED":
            return(
                <div className='status-chip status-chip-revoked'><i className="ri-timer-2-fill" /> Revoked</div>
            )
        case "STOPPED":
            return(
                <div className='status-chip status-chip-stopped'><i className="ri-stop-line"></i> Stopped</div>
            )
        default :
            return(
                <div className="status-chip status-chip-info"><span className="flaticon-circle-with-check-symbol"></span> {pipeline_instance.status} </div>
                );
        }
    }

    return(
        
            <div className={classes.stageData}>
                <span className="main-text">Stages:&nbsp;</span>
                <span className="sub-text">{stage_complete_status + " Complete"}</span>
                <span className="main-text">&nbsp; &nbsp;Status: </span>
                {   
                   getProgressStatusIcon()
                }
                
            </div>
           
        
    )
}

GeneralData.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default GeneralData;