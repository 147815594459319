import React, { useEffect, useRef, useState } from 'react'
import { makeStyles, styled } from '@mui/styles';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';

const SearchCategoryTabs = ({label,selectedOption,onChangeHandler,list,optionsWidth}) => {
    const rootRef = useRef(null);
    // const label = props.label;
    // const list = props.list;
    // const selectDeployment = props.onChangeHandler;
    // const selectedOption = props.selectedOption;
    // const optionsWidth = props.optionsWidth;
    const selectedObject = list && list.find((ele)=>ele.id == selectedOption); 
    const [selectedItem,setSelectedItem] = useState(null);

    useEffect(() => {
        setSelectedItem(selectedObject)
    },[selectedObject])

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        console.log("ndjnks",anchorEl);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const selectItem = (item)=>{
        setSelectedItem(item);
        onChangeHandler(item.id);
        handleClose();
    }
    return (
      <>
      <Root ref={rootRef} onClick={handleClick}>
          <span className='font-12 font-weight-500 d-flex select-label'>{label || null} <span className='font-12 font-weight-500 selected-option ml-4'>{selectedItem?selectedItem.label:"Please Select"}</span> </span>
          <span className='ri-arrow-down-s-line font-16 color-tertiary line-height-normal'></span>
      </Root>
      <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
          }}
          transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
          }}
      >
              <Options style={{width: `${optionsWidth-2}px`}}>
                  {list.map((ele,i)=>{
                          {console.log(ele,selectedItem,"bsdjbhjcsbcsdb")}
                          return <Tooltip title={ele.label}>
                              <span className={ ele?.id == selectedItem?.id ? 'item font-12 d-flex align-center selected-serv text-ellipsis'  : 'item font-12 d-flex align-center text-ellipsis'} style={{width: `${optionsWidth-24}px`}} onClick={()=>selectItem(ele)}>{ele.label}</span>
                          </Tooltip>
                              
                      
                      
                  })}
              </Options>
          
      </Popover>
      </>
    )
}

export default SearchCategoryTabs

const Root = styled('div')({
    display: "flex",
    width: "100%",
    padding: "4px 6px 4px 12px",
    justifyContent: "space-between",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "6px",
    border: "1px solid #CACACA",
    background: "#FFF",
    cursor: "pointer",
    '& .select-label': {
        color: "#505050",
        textTransform: "capitalize",
        textWrap: 'nowrap',
        width: "90%",
        '& .selected-option': {
            color: "#505050",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textTransform: "none",
            lineHeight:'14px',
            width: (props)=> props.noWidth ? '' :"90%",
        },
    }
})

const Options = styled('div')({
    display: "flex",
    flexDirection: "column",
    padding: "6px 12px",
    width: "100%",
    borderRadius: "6px",
    '& .item': {
        padding: "4px 8px",
        height: "30px",
        borderRadius: "4px",
        cursor: "pointer",
        whiteSpace: "nowrap",
        color: '#787878',
        "&:hover": {
            background: "#ebe9e9",
        }
    },
    '& .selected-serv': {
        // background: "#F5FAFF",
        color:'#2F2F2F',
        fontWeight: 600,
        '&:hover': {
            background: "#F5FAFF",
        },
    },
})