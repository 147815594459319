import React, { useState, useEffect } from 'react';
import { styled } from "@mui/system";
import PageHeader from '../../../../components/PageHeader';
import Grid from '@mui/material/Grid';
import ConfiguredSettingsCard from './ConfiguredSettingsCard';
import { data } from './sampledata';
import ConfigurationDialog from '../configurationSetup/ConfigurationDialog';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import BlankPage from '../../../../components/BlankPage';
import properties from '../../../../properties/properties';
import { Loading } from '../../../utils/Loading';
import { ErrorComponent } from '../../../utils/Error';
import SearchBar from '../../../../components/SearchBar';

const ReleasePackageConfigurationListing = () => {
    const [state, setState] = useState({ open: false, error_in_loading_data: null, configuration_list: [], is_default_configured: false });
    const handleClickOpenDialog = () => {
        setState(new_state => ({
            ...new_state,
            open: true
        }))
    }
    const handleOnClickClose = () => {
        setState(new_state => ({
            ...new_state,
            open: false
        }))
    }

    useEffect(() => {
        fetchConfiguredReleaseMgmtSettings()
    }, [])



    function fetchConfiguredReleaseMgmtSettings(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.release_mgmt_config),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }

        InvokeApi(requestInfo, fetchConfiguredReleaseMgmtSettingsSuccess, fetchConfiguredReleaseMgmtSettingsFailure);


        setState(new_state => ({
            ...new_state,
            loading: true,
            error_in_loading_data: null,
            search_query_name: data ? (data.name ? data.name : "") : "",
        }));
    }

    function fetchConfiguredReleaseMgmtSettingsSuccess(data) {
        let is_default_configured = data.results.find(item => item.default);
        console.log(is_default_configured, "is_default_configured")
        setState((new_state) => ({
            ...new_state,
            configuration_list: data.results ? data.results : [],
            loading: false,
            is_default_configured: is_default_configured,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10)
                ? (Number(data.count) / 10).toFixed(0)
                : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
                    ? (Number(data.count) / 10 + 1).toFixed(0) - 1
                    : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: 1,
        }));

    }
    function fetchConfiguredReleaseMgmtSettingsFailure(error) {
        var error_msg = showErrorHandlerUpdated(error)
        setState(new_state => ({
            ...new_state,
            loading: false,
            error_in_loading_data: error_msg
        }));
    }
    const invokeSearch = (data) => {
        fetchConfiguredReleaseMgmtSettings(data);
      };
    
    console.log("dlklkdsldslk",state);
    return (
        <Root>
            {state.loading ?
                <div className="" style={{ height: "60vh", margin: "auto" }}>
                    <Loading varient="light" />
                </div>
                :
                state.error_in_loading_data ?
                    <ErrorComponent variant="msg-box" error={state.error_in_loading_data} />
                    :
                    state.configuration_list && state.configuration_list.length > 0 ?
                        <>
                            <PageHeader
                                heading="Release Package Configuration"
                                subHeading="Displaying overall summary of Release Package Configuration with BuildPiper"
                                commonDivMargin={true}
                                icon={null}
                                imgIcon="/images/release-package-config-icon.svg"
                                primaryButton={{
                                    actionType: 'onClick',
                                    action: handleClickOpenDialog,
                                    text: 'Add Configuration',
                                    buttonClass: 'btn-primary mr-0',
                                    icon: <span className="ri-add-line font-14"></span>
                                }}
                            />
                            <Grid container spacing={2}>
                                <Grid item lg={9} className="mb-24">
                                    <SearchBar
                                        search_data={state.search_data}
                                        default_filter={{ name: "name", label: "Enter name to search" }}
                                        search_call_back={invokeSearch}
                                        clear_search_callback={fetchConfiguredReleaseMgmtSettings}
                                    />
                                </Grid>
                                {
                                    state.configuration_list.map((item, index) => {
                                        if (item.default === true) {
                                            return (
                                                <Grid item lg={6}>
                                                    <ConfiguredSettingsCard
                                                        configuration_name={item.name}
                                                        data={item}
                                                        default={true}
                                                        refreshFun={fetchConfiguredReleaseMgmtSettings}
                                                        default_configuration_id={item.id}
                                                    />
                                                </Grid>

                                            );
                                        } else {
                                            return (
                                                <Grid item lg={6} key={index}>
                                                    <ConfiguredSettingsCard
                                                        default={false}
                                                        configuration_name={item.name}
                                                        data={item}
                                                        refreshFun={fetchConfiguredReleaseMgmtSettings}
                                                        default_configuration_id={null} />
                                                </Grid>
                                            );
                                        }

                                    })

                                }

                            </Grid>

                        </>
                        :
                        state?.search_query_name ?
                                <BlankPage
                                text={`No Result Found for - ${state?.search_query_name}`}
                                pageIcon={<img src={"/images/release-package-config-icon.svg"} alt=".." />}
                                primaryButton={
                                    {
                                    action: fetchConfiguredReleaseMgmtSettings,
                                    text: 'Refresh',
                                    buttonClass: 'btn-primary m-auto'
                                    }
                                }
                                />
                        :
                        <BlankPage
                            text={"Release Package Configuration"}
                            pageIcon={<img src="/images/release-package-config-icon.svg" />}
                            primaryButton={
                                {
                                    actionType: 'button',
                                    action: handleClickOpenDialog,
                                    icon: <span className="ri-add-line font-14"></span>,
                                    text: 'Add Configuration',
                                    buttonClass: 'btn-primary m-auto'
                                }
                            }
                        />
            }
            {
                state.open &&
                <ConfigurationDialog
                    handleClickOpenDialog={handleClickOpenDialog}
                    open={state.open}
                    is_default_configured={state.is_default_configured}
                    handleOnClickClose={handleOnClickClose}
                />
            }
        </Root>
    )
}
const Root = styled('div')({
    backgroundColor: '#fff',
    height: 'calc( 100vh - 70px )',
    padding: '20px',
    overflowY:'scroll'
})
export default ReleasePackageConfigurationListing