import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Input } from '../Input';

function RadioForms(props) {
    const options = props.options;
    const prev_state = props.state;
    const inherits = props.inherits;
    const [state, setState] = useState(prev_state ? prev_state : getDefaultState(options));
    const component_inherits = {
        validateForm: () => { },
        getData: () => { },
        getState: () => { },
    }
   

    useEffect(() => {
        if(prev_state)
        setState(new_state => ({
            ...new_state,
            ...prev_state
        }))
    }, [prev_state])

    function onSelectedOptionChange(selected_option) {
        setState(new_state => ({
            ...new_state,
            component_states:{
                ...new_state.component_states,
                [new_state.selected_option]:{
                    ...component_inherits.getState()
                }
            },
            selected_option: selected_option,
        }));
    }



    // Overloading
    inherits.getSelectedOption = () => {
        return state.selected_option;
    }

    inherits.getState = () => {
        console.log("======> getState",state);
        const new_state = {
            ...state,
            component_states:{
                ...state.component_states,
                [state.selected_option]:{
                    ...component_inherits.getState(),
                }
            }
        }
        console.log("======> getState new ",state);
        setState(new_state);
        return new_state;
    }

    inherits.getSelectedComponentData = () => {
        return component_inherits.getData();
    }

    inherits.validateSelectedComponent = () => {
        return component_inherits.validateForm();
    }

    console.log("RadioFormjkjkjkjk",state);
    return (
    
            <div className='card'>
                {UI_GetHeader(options, state, onSelectedOptionChange)}
                {UI_GetComponent(options, state, component_inherits)}
            </div>
        
    );
}

// UI Renderers
function UI_GetHeader(options, state, onSelectedOptionChange) {
    const classes = useStyles();
    const radio_list = GenrateRadioList(options);
    const current_value = state.selected_option;

    return (
        <div className={classes.cardHeader}>
            <Input
                type="radio"
                name="option"
                list={radio_list}
                onChangeHandler={(e) => { onSelectedOptionChange(e.target.value) }}
                data={{ option: current_value }}
                error={{}}
            />
        </div>
    );
}

function UI_GetComponent(options, state, component_inherits) {
    const Component = state.selected_option ? options[state.selected_option].component : null;
    
    return (
    
            <Component {...options[state.selected_option].props} state={state.component_states[state.selected_option]} inherits={component_inherits} />
        
    )

}


// Utils

function getDefaultState(options) {
    return {
        selected_option: GetDefaultSelectedOption(options),
        component_states: {

        }
    }
}

function GetDefaultSelectedOption(options) {
    const first_option = Object.keys(options)[0];
    return options[first_option].value
}

function GenrateRadioList(options) {
    const radio_list = [];
    Object.keys(options).forEach(option => {
        radio_list.push({
            label: options[option].label,
            value: options[option].value
        });
    });
    return radio_list;
}

RadioForms.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default RadioForms;

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        marginTop: '3rem'
    },
    cardHeader: {
        borderBottom: '1px solid #dedede',
        padding: '10px',
        backgroundColor: '#f4f4f4',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .input-component': {
            marginBottom: '0px'
        }
    },
    inputChoose: {
        '& .input-component': {
            padding: '10px'
        },
        '& .image-upload-wrap': {

            '& .file-upload-input': {
                zIndex: 99
            }
        },
        '& .drag-text': {
            textAlign: 'center',
            position: 'absolute',
            top: '42%',
            left: '44%',
            margin: '0px',
            zIndex: 0,
            '& h3': {
                padding: '0px!important'
            }
        }
    },
    gitInputChoose: {
        padding: '0px 10px',
        display: 'grid',
        gridTemplateColumns: '49% 49%',
        gap: '2%'
    },
    creddiv: {
        display: 'grid',
        gridTemplateColumns: '85% 14%',
        gap: '1%',
        alignItems: 'center',
        padding: '10px'
    },
    multidiv: {
        padding: '10px',
        display: 'grid',
        gridTemplateColumns: '85% 14%',
        gap: '1%',
        alignItems: 'center',
        '& .input-component': {
            marginBottom: '0px'
        },
        '& button': {
            color: '#fff!important',
            width: 'fit-content',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            minWidth: '120px',
            padding: '5px 12px',
            fontSize: '11px',
            boxShadow: '0px 5px 16px 0px rgba(0, 0, 0, 0.1)',
            alignItems: 'center',
            borderRadius: '24px',
            backgroundImage: 'linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)',
        },
        '& .bg-delete': {
            backgroundColor: '#f6f6f6',
            backgroundImage: 'none',
            border: '1px solid #edcaca',
            color: '#ff4a4a!important',
            boxShadow: 'none'
        }
    }
}));