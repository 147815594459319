import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { makeStyles } from '@mui/styles';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import { Input } from "../../../components/genericComponents/Input";
import { getCommonFunctions } from "../../serviceRevamp/add/ci_flow/SourceDetails";
import GenericSkeleton from "../../../components/genericComponents/Skeletons/GenericSkeleton";
import InvokeApi from "../../../util/apiInvoker";
import GenerateURL from "../../../util/APIUrlProvider";
import properties from "../../../properties/properties";
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from "../../../util/Validator";
import Toolbar  from '@mui/material/Toolbar';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});



const useStyles = makeStyles((theme) => ({
    root: {

    },
    wrapperClass: {
        '& .MuiDialog-paperWidthXl': {
            height: '100vh',
            maxHeight: '100vh',
            marginLeft: 'auto',
            marginRight: '0px',
            width: 'calc(100% - 700px)',
            borderRadius: '0px'
        }
    },
    rootClass: {
        padding: '10px!important',
        '& .input-component': {
            marginBottom: '0px'
        },
        '& .grey__chip': {
            color: '#949494',
            border: ' 1px solid #E6E6E6',
            padding: '4px 6px 4px 6px',
            fontSize: '12px',
            textWrap: 'nowrap',
            fontWeight: 700,
            borderRadius: '6px',
            backgroundColor: '#F4F4F4',
            height: '29px'
        },
        '& .mt-4': {
            marginTop: '4px'
        }
    },
    appBar: {
        position: 'static',
        backgroundColor: '#0086ff'
    },
    cardHeader: {
        padding: '15px 10px',
        backgroundColor: '#FAF9FF',
        '& .MuiCardHeader-title': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '13px',
            lineHeight: '16px',
            color: '#000000'
        }
    },
    list: {
        width: '100%',
        height: '250px',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',

        '& .loading-gif-container-two': {
            height: '205px!important'
        },
        paddingBottom: '0',
        '& .MuiListItem-root': {
            borderBottom: '1px solid #e9e9e9',
            background: '#FDFDFD',
            border: '1px solid #E9E9E9',
            borderRadius: '4px',
            width: 'calc( 100% - 20px)',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '10px',
            marginTop: '10px',
            height: '50px',
            '& .MuiTypography-root': {

            },
            '&:last-child': {
                borderBottom: '0px solid #e9e9e9',
                display: 'none',
                '&.MuiListItem-dense': {
                    paddingBottom: 'none'
                }
            }
        }
    },
    button: {
        margin: theme.spacing(0.5, 0),
        borderRadius: '20px',
        height: '40px',
        width: '40px',
        background: '#007EFF',
        color: '#fff',
        display: 'block',
        '& .MuiSvgIcon-fontSizeLarge': {
            verticalAlign: 'middle'
        },
        minWidth: '40px',
        '&:disabled': {
            background: '#dedede',
            '& .MuiSvgIcon-fontSizeLarge': {
                color: '#aaa!important'
            }
        },
        '&:hover': {
            '& .MuiSvgIcon-fontSizeLarge': {
                color: '#007EFF!important'
            }
        }
    },
    cardFooter: {
        display: 'flex',
        padding: '15px',
        borderTop: '1px solid rgb(204, 204, 204)',
        borderRadius: '0px 0px 8px 8px',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        gridTemplateColumns: 'auto auto'
    },
    buttonSec: {
        '& button': {

        }
    }
}));

const DialogToMoveSeletedStories = (props) => {
    const classes = useStyles();
    const open = props.open;
    const handleClickClose = props.handleClickClose ? props.handleClickClose : () => { };
    const path = props.path;
    const sendDataToParentComponent = props.sendDataToParentComponent ? props.sendDataToParentComponent : () => { };
    const sendDataToBulkMove = props.sendDataToBulkMove ? props.sendDataToBulkMove : () => { };
    const [advConfig, setAdvConfig] = useState(false)

    const [state, setState] = useState({
        data: {},
        error: {},
        available_states: [],
        available_sprints: []
    });
    const commonFunction = getCommonFunctions(state, setState, {});
    useEffect(() => {
        if (open) {
            fetchAdoStates()
        }
    }, [open])


    function fetchAdoStates() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.load_ado_states ),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchAdoStatesDataSuccess, fetchAdoStatesDataFailed);

        setState(new_state => ({
            ...new_state,
            loading_states: true
        }))

    }

    function fetchAdoStatesDataSuccess(data) {
        fetchAdoSprints();
        let final_state_variable = data && data.final_validation_state ? data.final_validation_state : null
        let states_list = data.states && data.states.length > 0 ? data.states : [];
        let filtered_arr = states_list.filter(item => item !== final_state_variable);
        let available_states = filtered_arr && filtered_arr.length > 0 ? filtered_arr.map(item => {

            return { label: item, id: item }


        }) : [];

        setState(new_state => ({
            ...new_state,
            available_states: available_states,
            loading_states: false,
        }));

    }
    function fetchAdoStatesDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            loading_states: false
        }))
    }

    function fetchAdoSprints() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.load_ado_sprints + "?next_sprint=" + true),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchAdoSprintsDataSuccess, fetchAdoSprintsDataFailed);

        setState(new_state => ({
            ...new_state,
            loading_states: true
        }))

    }

    function fetchAdoSprintsDataSuccess(data) {
        let sprint_list = data.sprint_list && data.sprint_list.values ? data.sprint_list.values.length > 0 ? data.sprint_list.values : [] : [];
        let filtered_list = sprint_list.filter(item => item.path !== path);
        filtered_list.unshift({ name: 'Backlog', path: 'backlog' });

        setState(new_state => ({
            ...new_state,
            available_sprints: filtered_list,
            sprints_loading: false
        }));

    }
    function fetchAdoSprintsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            loading_states: false
        }))
    }

    const validateAndSaveData = () => {
        const final_obj = { move_to: state.data.move_to }
        let validations = {
            move_to: [VALIDATION_TYPE_REQUIRED]
        }
        const result = ValidateDataSet(state.data, validations);
        if (result.valid) {
            console.log(final_obj, "final_datafinal_data", state.data)
            sendDataToParentComponent(final_obj)
        } else {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }))
        }

    }
    const validateAndSaveDataToBulkMove = () => {
        let final_list = state.available_states;
        let items_with_validations = final_list.map(item => {
            return {
                [item.label]: [VALIDATION_TYPE_REQUIRED]
            }
        })
        let validations = items_with_validations.reduce((acc, curr) => {
            return { ...acc, ...curr };
        }, {});

        var result = ValidateDataSet(state.data, validations);
        if (result.valid) {
            let final_obj = {
                "bulk_move": true,
                "current_path": path,
                "state":state.data
            }
            sendDataToBulkMove(final_obj)
        }else{
            setState(new_state => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }))
        }
    }

    return (
        <>
            {
                open ?
                    <div className={classes.root}>
                        <Dialog open={open} maxWidth="xl" fullWidth={true} className={classes.wrapperClass} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar className="d-flex align-center space-between">
                                    <p className="font-18 font-weight-600">Move Stories</p>
                                    <button className="btn btn-transparent" edge="start" color="inherit" onClick={handleClickClose} aria-label="close">
                                        <span className='ri-close-line font-24'></span>
                                    </button>
                                </Toolbar>
                            </AppBar>
                            <DialogContent className={classes.rootClass} >
                                <p className="mb-20">Select where you want to move stories</p>
                                {
                                    advConfig ?
                                        <>
                                            {
                                                state.loading_states ?
                                                    <>
                                                        <GenericSkeleton ariant={"rect"} height={"27px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                                        <GenericSkeleton ariant={"rect"} height={"34px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                                        <GenericSkeleton ariant={"rect"} height={"27px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                                        <GenericSkeleton ariant={"rect"} height={"34px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                                        <GenericSkeleton ariant={"rect"} height={"27px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                                        <GenericSkeleton ariant={"rect"} height={"34px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                                        <GenericSkeleton ariant={"rect"} height={"27px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                                        <GenericSkeleton ariant={"rect"} height={"34px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                                    </> :

                                                    state.available_states.length > 0 ?

                                                        state.available_states.map(item => {
                                                            return <div className='mb-24'>
                                                                <p className='font-14 font-weight-500 mb-12'> Where you want to move <span className='font-weight-600'>{item.label}</span> sprint items</p>
                                                                <div className='d-grid ' style={{ gridTemplateColumns: '140px 24px 180px', gap: '12px', alignItems: 'center' }}>
                                                                    <div className='grey__chip text-center'>
                                                                        {item.label}
                                                                    </div>
                                                                    <span className='ri-arrow-right-line font-24 ' style={{ color: '#787878' }}></span>
                                                                    <div>
                                                                        <Input
                                                                            type="select"
                                                                            label=""
                                                                            name={item.label}
                                                                            list={state.available_sprints.map(item => {
                                                                                return { label: item.name, id: item.path }
                                                                            })}
                                                                            onChangeHandler={commonFunction.onChangeHandler}
                                                                            data={state.data}
                                                                            error={state.error}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                        :
                                                        <p className='font-14 font-weight-500 '> Unable to load states.</p>
                                            }
                                        </>
                                        :
                                        <>

                                            {
                                                state.loading_states ?
                                                    <GenericSkeleton ariant={"rect"} height={"27px"} style={{ borderRadius: "6px", marginBottom: '12px' }} /> :
                                                    <Input
                                                        type="select"
                                                        label="Move selected stories to"
                                                        name={"move_to"}
                                                        list={state.available_sprints.map(item => {
                                                            return { label: item.name, id: item.path }
                                                        })}
                                                        onChangeHandler={commonFunction.onChangeHandler}
                                                        data={state.data}
                                                        error={state.error}
                                                    />
                                            }
                                        </>

                                }

                                <button
                                    className="btn btn-transparent font-12 font-weight-500"
                                    style={{ color: '#0086ff', }}
                                    onClick={() => { setAdvConfig(!advConfig) }}>
                                    {advConfig ? "CLOSE ADVANCE CONFIGURATION" : "ADVANCE CONFIGURATION"}
                                </button>

                            </DialogContent>
                            <div className={"d-flex align-center justify-end " + classes.cardFooter} >
                                <button className="btn btn-outline-grey btn-save-lg font-12" onClick={handleClickClose}>Close</button>
                                <button className="btn btn-primary " onClick={advConfig ? () => { validateAndSaveDataToBulkMove() } : () => { validateAndSaveData() }}>Move Items</button>
                            </div>
                        </Dialog>
                    </div >
                    : null
            }



        </>

    );
};
DialogToMoveSeletedStories.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export default DialogToMoveSeletedStories;

