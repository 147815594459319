import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import properties from '../../../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../../../util/apiInvoker';
import { Loading } from '../../../../utils/Loading';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { getDuration } from '../../../../../util/util';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Pagination from '../../../../../components/Pagination';
import ActivityStepDetails from '../../../../superAdmin/activityFramework/components/ActivityStepDetails';
import { Link } from 'react-router-dom';
import { ParameterComponent } from '../../../listing/components/HistoryPageComponents/DeployHistory';
import { useParams } from 'react-router-dom';

const JobTriggerHistoryUpdated = ({ propsData, jobData, service_data, ...props }) => {
    const classes = useStyles();
    const { component_id, sub_env_id, component_env_id } = propsData

    // service_env_type = service_env_type ? service_env_type.toLowerCase() : null
    // var service_env = service_data && service_data.service_env;
    // service_env = service_env ? service_env.toLowerCase() : null
    // var service_name = service_data && service_data.name;
    const [state, setState] = useState({
        show_loading_icon: true,
        infiniteScrollInherits: {},
        curr_page: '',
        total_page: '',
        refresh_count: 1,
        activity_list: []
    });

    const refreshComponent = props => {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,
        }));
    }

    useEffect(() => {
        jobData && propsData && fetchHistory(propsData);
    }, [jobData, props?.refresh_count, state.refresh_count, propsData]);
    console.log("skdjksjaksd", jobData)
    function fetchHistory(propsData) {

        const { job_code } = jobData;
        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {
            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.build_trigger_for_job)
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo, historyFetchSuccess, historyFetchFailure);

            setState(new_state => ({
                ...new_state,
                // search_data: data,
                // current: requestInfo.endPoint,
                show_loading_icon: true
            }));
        }
    }

    function historyFetchSuccess(data) {

        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Math.ceil(Number(data.count / 10)),
            curr_page: new_state.curr_page === '' ? 1 : new_state.curr_page
        }));
    }

    function historyFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            error: error,
            history_list: []
        }));
    }
    function fetchNextHistory(data, url) {

        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, NexthistoryFetchSuccess, NexthistoryFetchFailure);
        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            show_loading_icon: true
        }));
    }

    function NexthistoryFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            show_loading_icon: false,
            count: data.count,
            page_start: state.page_start + data.results.length,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            curr_page: new_state.curr_page + 1
        }));
    }

    function NexthistoryFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            error: error,
            history_list: []
        }));
    }

    function fetchPrevHistory(data, url) {

        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, PrevhistoryFetchSuccess, PrevhistoryFetchFailure);

        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            show_loading_icon: true
        }));
    }

    function PrevhistoryFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            show_loading_icon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            curr_page: new_state.curr_page - 1
        }));
    }

    function PrevhistoryFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            show_loading_icon: false,
            error: error,
            history_list: []
        }));
    }


    function fetchPageInfo(enteredPageNumber) {
        const { job_code } = jobData;
        var requestInfo = {
            endPoint: GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.build_trigger_for_job),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        if (enteredPageNumber > 1) {
            requestInfo.endPoint =
                requestInfo.endPoint +
                "?limit=10&offset=" +
                (enteredPageNumber - 1) * 10;
        }

        var current_page = enteredPageNumber;
        setState(new_state => ({
            ...new_state,
            current: requestInfo.endPoint,
            show_loading_icon: true
        }));
        InvokeApi(
            requestInfo,
            (response) => handleHistoryPageDataSuccessApiHit(response,current_page),
            handleHistoryPageDataFailedApiHit
        );
    }

    function handleHistoryPageDataSuccessApiHit(data, count) {
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            show_loading_icon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            curr_page: count
        }));
    }

    function handleHistoryPageDataFailedApiHit(error){
        setState(new_state => ({
            ...new_state,
            show_loading_icon: false,
            error: error,
            history_list: []
        }));
    }


    const handleChange = (e) => {
        var category_value = e.target.value
        if (category_value == "v2" || category_value == "v3") {
            setFilters(prevFilter => ({ version: category_value, }))
        } else {
            if (category_value !== '') {
                setFilters(prevFilter => ({ category: category_value }))
            }
            else {
                setFilters(prevFilter => ({ category: '' }))

            }
        }


    }

    const handleJobTrigger = () => {
        const { job_code } = jobData;
        let post_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, component_env_id: component_env_id, job_code: job_code }, properties.api.build_trigger_for_job)
        PostData(post_url, [],
            () => { fetchHistory(propsData) },
            () => { });
    }

    function getClassbyActivityStatus(activity_status, type) {
        if (type === "Revoke") {
            if (
                (activity_status && activity_status == "IN_QUEUE") ||
                (activity_status && activity_status == "RUNNING")
            )
                return "icon-btn-v1 icon-btn-outline-danger";
            else return "btn-sq-icon-primary-disabled d-flex align-center";
        } else {
            if (type === "Resume") {
                if (
                    (activity_status && activity_status == "IN_QUEUE") ||
                    (activity_status && activity_status == "RUNNING") ||
                    (activity_status && activity_status == "SUCCESS") ||
                    (activity_status && activity_status == "REVOKED")
                )
                    return "btn-sq-icon-primary-disabled d-flex align-center";
                else return "btn-sq-icon-primary d-flex align-center";
            } else {
                if (
                    (activity_status && activity_status == "IN_QUEUE") ||
                    (activity_status && activity_status == "RUNNING")
                )
                    return "btn-sq-icon-primary-disabled d-flex align-center";
                else return "btn-sq-icon-primary d-flex align-center";
            }
        }
    }

    function getActivityCurrentInfo(activity_id) {
        var requestInfo = {
            endPoint: GenerateURL({ activity_id: activity_id }, properties.api.get_current_activity_status),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, getActivityCurrentInfoSuccess, getActivityCurrentInfoFailure);
    }

    function getActivityCurrentInfoSuccess(response) {
        if (response.results.length != 0) {
            let found = false
            state.history_list.forEach(activity => {
                if (activity.activity_status.id == response.results[0].activity_status_id) {
                    activity.activity_details = response.results
                    found = true
                }
            })
            found && setState(new_state => ({
                ...new_state,
                activity_step_details: {
                    open_div: state.history_list.length > 0 ? true : false
                }
            }));
        }

    }

    function getActivityCurrentInfoFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
    }

    const emptyArr = (activity_id) => {
        state.history_list.forEach(activity => {
            if (activity.activity_details && activity.activity_details.length > 0) {
                // activity.activity_details.forEach((item, key) => {
                if (activity.id == activity_id) {
                    activity.activity_details = null;
                    setState(new_state => ({
                        ...new_state,
                        history_list: [...state.history_list],
                        activity_step_details: {
                            open_div: state.history_list.length > 0 ? true : false
                        }
                    }));
                }
                // })
            }
        })
    }

    function onRevoke(task_id) {

        let urlEndpoint = GenerateURL({}, properties.api.revoke_activity)

        let endPoint = GenerateSearchURL({ task_id: task_id }, urlEndpoint)

        PostData(endPoint, {},

            (response) => {
                setState(new_state => ({
                    ...new_state,
                    show_loading_icon: false
                }));
                refreshComponent();
            },
            (error) => {
                setState(new_state => ({
                    ...new_state,
                    error: true,
                    show_loading_icon: false
                }));
            }
        )

    }

    const callFunctionbyStatus = (activity_status, id) => {
        if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
            onRevoke(id)
    }


    function getBuildbyUser(history) {
        return (
            <p>
                {history?.trigger_by_pipeline ? (
                    <>
                        <p>Pipeline: {history.trigger_by_pipeline.pipeline.name}</p>
                        <p>Triggered by: {history.trigger_by_pipeline.trigger_by}</p>{" "}
                    </>
                ) : (
                    <p>{history.trigger_by_user}</p>
                )}
            </p>
        );
    }


    const params = useParams();


    return (
        <div className={classes.maincontainer}>
            {state.show_loading_icon ? (
                <div className={classes.historyCard} style={{ height: "300px" }} >
                    <Loading varient="light" />
                </div>
            ) : (
                <>
                    <div className={classes.historyCard}>
                        <div style={{ borderRadius: "6px", border: "1px solid #E6E6E6" }}>
                            <div className="header-tab-build">
                                <p>Type</p>
                                <p>Status</p>
                                <p>Date/Duration</p>
                                <p>Build by</p>
                                <p></p>
                            </div>
                            <div className="history-body">
                                {state.history_list.length > 0 ? (
                                    <>
                                        {state.history_list.map((history, key) => (
                                            <>
                                                <div className="body-tab-build align-center">
                                                    <Link
                                                        className="cursor-pointer text-anchor-blue font-13 font-weight-600"
                                                        to={{
                                                            pathname: "/logs",
                                                            search: `?global_task_id=${history.global_task_id}` +
                                                                `&tab_id=${history.id}` +
                                                                `&service_name=${service_data?.name}` +
                                                                `&service_env=${service_data?.service_env}` +
                                                                `&service_type=${service_data?.type}` +
                                                                `&tab_name=BUILD` +
                                                                `&num=${history?.job_number}`,
                                                        }}
                                                        target="_blank"
                                                    >
                                                        <span>{jobData?.job_name} #{history.job_number}</span>
                                                    </Link>
                                                    {/* <p><span className="cursor-pointer text-anchor-blue" onClick={() => { onLog(history.id, history.global_task_id, "Build #" + history.build_number) }}>Build#{history.build_number}</span></p> */}
                                                    <Tooltip
                                                        title={
                                                            history.activity_status &&
                                                            history.activity_status.status
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                history.activity_status &&
                                                                    history.activity_status.status == "FAILED"
                                                                    ? "new-status-chip new-failed"
                                                                    : history.activity_status &&
                                                                        history.activity_status.status == "RUNNING"
                                                                        ? "new-status-chip new-running"
                                                                        : history.activity_status &&
                                                                            history.activity_status.status == "REVOKED"
                                                                            ? "new-status-chip new-revoked"
                                                                            : history.activity_status &&
                                                                                history.activity_status.status ==
                                                                                "IN_QUEUE"
                                                                                ? "new-status-chip new-inqueue"
                                                                                : "new-status-chip new-success"
                                                            }
                                                            style={{ width: "fit-content" }}
                                                        >
                                                            {(history.activity_status && history.activity_status.status) === 'IN_QUEUE' ? 'IN QUEUE' : history.activity_status && history.activity_status.status ? history.activity_status.status : "N/A"}
                                                        </span>
                                                    </Tooltip>
                                                    {/* <p>
                            {history.activity_status
                              ? history.activity_status.status === "SUCCESS" ||
                                history.activity_status.status === "FAILED"
                                ? getDuration(
                                  new Date(
                                    history.activity_status.start_time
                                  ),
                                  new Date(history.activity_status.end_time)
                                ) == 0
                                  ? "0s"
                                  : getDuration(
                                    new Date(
                                      history.activity_status.start_time
                                    ),
                                    new Date(history.activity_status.end_time)
                                  )
                                : getDuration(
                                  new Date(
                                    history.activity_status &&
                                    history.activity_status.created_at
                                  ),
                                  new Date(history.activity_status.updated_at)
                                ) == 0
                                  ? "0s"
                                  : getDuration(
                                    new Date(
                                      history.activity_status &&
                                      history.activity_status.created_at
                                    ),
                                    new Date(history.activity_status.updated_at)
                                  )
                              : "--"}
                          </p> */}
                                                    <div>
                                                        <p>
                                                            {moment().diff(moment(history.activity_status &&
                                                                history.activity_status.created_at
                                                                ? history.activity_status.created_at
                                                                : ""), 'hours') > 8 ?
                                                                moment(
                                                                    history.activity_status &&
                                                                        history.activity_status.created_at
                                                                        ? history.activity_status.created_at
                                                                        : ""
                                                                ).format('DD-MMM-YYYY')
                                                                :
                                                                moment(
                                                                    history.activity_status &&
                                                                        history.activity_status.created_at
                                                                        ? history.activity_status.created_at
                                                                        : ""
                                                                ).fromNow()
                                                            }

                                                        </p>
                                                        <p className="color-key-78">
                                                            {history.activity_status
                                                                ? history.activity_status.status === "SUCCESS" ||
                                                                    history.activity_status.status === "FAILED"
                                                                    ? getDuration(
                                                                        new Date(
                                                                            history.activity_status.start_time
                                                                        ),
                                                                        new Date(history.activity_status.end_time)
                                                                    ) == 0
                                                                        ? "0s"
                                                                        : getDuration(
                                                                            new Date(
                                                                                history.activity_status.start_time
                                                                            ),
                                                                            new Date(history.activity_status.end_time)
                                                                        )
                                                                    : getDuration(
                                                                        new Date(
                                                                            history.activity_status.created_at &&
                                                                            history.activity_status.created_at
                                                                        ),
                                                                        new Date(history.activity_status.updated_at)
                                                                    ) == 0
                                                                        ? "0s"
                                                                        : getDuration(
                                                                            new Date(
                                                                                history.activity_status.created_at &&
                                                                                history.activity_status.created_at
                                                                            ),
                                                                            new Date(history.activity_status.updated_at)
                                                                        )
                                                                : "--"}
                                                        </p>
                                                    </div>


                                                    <p>{getBuildbyUser(history)}</p>

                                                    <div className="d-flex align-center justify-end" style={{ gap: "12px" }}>
                                                        <div className=""><ParameterComponent data={{ ...history?.key_value_pair, last_failure: 'main' }} v3={true} job_name={jobData?.job_name} /></div>
                                                        <div className="btn-group width-fitcontent" style={{ gap: "4px" }}>

                                                            <Tooltip title="View Activity Details">
                                                                <button
                                                                    className="btn-sq-icon-primary d-flex align-center"
                                                                    onClick={() => {
                                                                        getActivityCurrentInfo(
                                                                            history.activity_status &&
                                                                                history.activity_status.id
                                                                                ? history.activity_status.id
                                                                                : ""
                                                                        );
                                                                    }}
                                                                >
                                                                    {/* <span
                                      class="material-icons material-symbols-outlined"
                                      style={{ color: "#0086ff", fontSize: 20 }}
                                    >
                                      notes
                                    </span> */}
                                                                    <span className="ri-list-check-2" style={{ fontSize: 20 }}></span>
                                                                </button>
                                                            </Tooltip>

                                                            {true ? (

                                                                <Tooltip title="ReTrigger">
                                                                    <button
                                                                        className={
                                                                            history.activity_status
                                                                                ? getClassbyActivityStatus(
                                                                                    history.activity_status.status,
                                                                                    "Rebuild"
                                                                                )
                                                                                : "btn-sq-icon-primary-disabled d-flex align-center"
                                                                        }
                                                                        onClick={() => {
                                                                            handleJobTrigger(history);
                                                                        }}
                                                                    >
                                                                        {/* <span class="flaticon-flash-on-indicator"></span> */}
                                                                        <span className="ri-flashlight-line font-20"></span>
                                                                    </button>
                                                                </Tooltip>

                                                            ) : (

                                                                <Tooltip title="Rebuild">
                                                                    <button
                                                                        className={"btn-sq-icon-primary-disabled d-flex align-center"}
                                                                    >
                                                                        <span class="ri-flashlight-line font-20"></span>
                                                                    </button>
                                                                </Tooltip>

                                                            )}

                                                            <button
                                                                className={
                                                                    history.activity_status
                                                                        ? getClassbyActivityStatus(
                                                                            history.activity_status.status,
                                                                            "Resume"
                                                                        )
                                                                        : "btn-sq-icon-primary-disabled d-flex align-center"
                                                                }
                                                                onClick={
                                                                    history.activity_status
                                                                        ? () =>
                                                                            callResumeFunctionbyStatus(
                                                                                history.activity_status.status,
                                                                                history.id
                                                                            )
                                                                        : null
                                                                }
                                                                title="Resume Build"
                                                            >
                                                                {/* <SkipNextIcon
                                  style={{ fontSize: 24, color: "#0086ff" }}
                                /> */}
                                                                <span className="ri-play-circle-line font-20"></span>
                                                            </button>
                                                            <button
                                                                className={
                                                                    history.activity_status && (!history.build_by_pipeline)
                                                                        ? getClassbyActivityStatus(
                                                                            history.activity_status.status,
                                                                            "Revoke"
                                                                        )
                                                                        : "btn-sq-icon-primary-disabled d-flex align-center"
                                                                }
                                                                onClick={history.activity_status ?
                                                                    () => { callFunctionbyStatus(history.activity_status.status, history.global_task_id) }

                                                                    : null}
                                                                title={history.build_by_pipeline ? "This task has been triggered by a pipeline, please revoke the pipeline in order to revoke the task" : "Revoke Build"}
                                                            >
                                                                {/* <span class="flaticon-stop-button"></span> */}
                                                                <span className="ri-stop-circle-line font-20"></span>
                                                            </button>

                                                            <Tooltip title="workspace">
                                                                <Link
                                                                    to={{
                                                                        pathname: `/application/${params.application_id}/service/${component_id}/job/${jobData?.job_name}/workspace-v3/`,
                                                                        search: `?service_name=${service_data?.name}` +
                                                                            `&env_name=${service_data?.service_env}` +
                                                                            `&global_task_id=${history?.global_task_id}` +
                                                                            `&env_id=${sub_env_id}` +
                                                                            `&action_number=${history?.job_number}` +
                                                                            `&action_status=${history?.activity_status?.status}` +
                                                                            `&action_by=${history?.trigger_by_pipeline || history?.trigger_by_user}`,
                                                                    }}
                                                                    target="_blank"
                                                                >
                                                                    <button className="btn-sq-icon-primary d-flex align-center">

                                                                        <span className="ri-artboard-2-line font-20"></span>
                                                                    </button>
                                                                </Link>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                {(state.selected_activity_id == history.activity_status?.id) && state.activity_details_loading ?
                                                    <div className="d-flex justify-center align-center" style={{ height: "147px" }}>
                                                        <Loading varient="light" />
                                                    </div>

                                                    :
                                                    history.activity_details ? (
                                                        <div className="" style={{ width: "100%" }}>
                                                            <ActivityStepDetails
                                                                activityDetail={history.activity_details}
                                                                tableGird={false}
                                                                key={key}
                                                                logUrl={
                                                                    "/logs?global_task_id=" +
                                                                    history.global_task_id +
                                                                    "&tab_id=" +
                                                                    history.id +
                                                                    "&service_name=" +
                                                                    service_data?.name +
                                                                    "&service_env=" +
                                                                    service_data?.service_env +
                                                                    "&service_type=" +
                                                                    service_data?.type +
                                                                    "&tab_name=BUILD" +
                                                                    "&num=" +
                                                                    history?.job_number
                                                                }
                                                                prev_state={state.activity_step_details}
                                                                removeArrData={() => {
                                                                    emptyArr(history.id);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : null

                                                }

                                            </>
                                        ))}
                                    </>
                                ) : (
                                    <div className="blank-div">
                                        <div className="blank-div-text">No Data Found</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="" style={{ marginTop: "12px" }}>
                        <Pagination
                            current_page_count={
                                state.history_list ? `${state.curr_page}` : 0
                            }
                            total_count={state.total_page}
                            next={state.next}
                            previous={state.previous}
                            on_previous_click={() => {
                                fetchPrevHistory(null, state.previous);
                            }}
                            on_next_click={() => {
                                fetchNextHistory(null, state.next);
                            }}
                            on_pageNumber_click={(pageNumber) => {
                                fetchPageInfo(pageNumber);
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

JobTriggerHistoryUpdated.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default JobTriggerHistoryUpdated;

const useStyles = makeStyles((theme) => ({
    maincontainer: {
        margin: '20px',
        marginTop: "0px",
        //backgroundColor: '#fff',
        borderRadius: '8px',
        //overflow: 'hidden',
        //border: '1px solid #ebedee',
        '& #viewFullLogs': {
            marginLeft: -21
        },
        '& .search-bar': {
            border: '1px solid #DBDBDB',
            overflow: 'hidden',
            borderRadius: '6px',
            '& .input-component input': {
                height: "36px !important",
            },
            '& .input-component input:focus': {
                border: 'none!important'
            }
        },
        '& .headerul': {
            backgroundColor: '#f6f6f6',
            borderRadius: '4px 4px 0px 0px',
            borderBottom: '1px solid #ebedee'
        }
    },
    card: {
        '& .loading-gif-container-two': {
            height: '400px',
            '& .loader-bg-card-2': {
                position: 'relative',
                '& .loader': {
                    top: '0px'
                }
            }
        },
        '& .loading-overlay': {
            position: 'static'
        }
    },

    containerBox: {
        //padding: "16px 0px",
        paddingTop: "0px",
        justifyContent: "space-between",
        "& button.btn": {
            "&:hover": {
                color: "#0096db!important",
            },
        },
        "& .search-bar": {
            border: "1px solid #ebedee",
            overflow: "hidden",
            borderRadius: "8px",
            "& .input-component input:focus": {
                border: "none!important",
            },
        },
    },
    searchPosition: {
        position: "absolute",
        top: "16.8px",
        right: "8%",
        "@media screen and (min-width: 1440px) and (max-width: 1920px)": {
            right: "6%",
        }
    },
    historyCard: {
        //height: 550,
        "& .header-tab-build": {
            backgroundColor: "#FAFAFA",
            borderBottom: "1px solid #ebedee",
            gap: 8,
            fontSize: 12,
            display: "grid",
            padding: 12,
            borderTop: "1px solid #ebedee",
            gridTemplateColumns: "15% 15% 19% 19% 16% auto",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            color: "#787878",
        },
        "& .body-tab-build": {
            borderBottom: "1px solid #ebedee",
            gap: 8,
            fontSize: 12,
            display: "grid",
            padding: 8,
            gridTemplateColumns: "15% 15% 19% 19% auto",//"7% 7% 7% 8% 8% 10% 10% 10% 12% 13%",
            "& p": {
                //padding: "0px 3px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
            },
        },
        "& .btn-disabeld-flaticon": {
            "& .MuiSvgIcon-root": {
                color: "#b9b8b8 !important",
            },
        },
        "& .btn-mui-svg": {
            padding: "0px 0.5rem!important",
        },
        "& .history-body": {
            //overflowY: "scroll",
            //height: 515,
            '& > div': {
                backgroundColor: '#FFFFFF',
            },

        },
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));
const MenuListComposition = (props) => {
    const classes = useStyles1();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const service_id = props.service_id;
    const env_id = props.env_id;
    const task_id = props.task_id;
    const status = props.status;
    const [state, setState] = useState();
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <button className="btn btn-transparent"
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}>
                    <i className="ri-more-2-fill"></i>
                </button>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: 1 }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {status == "RUNNING" || status == "IN_QUEUE" ? (
                                            <MenuItem onClick={props.onRevoke}>Revoke</MenuItem>
                                        ) : null}
                                        <MenuItem onClick={props.onRebuild}>Rebuild</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
};

MenuListComposition.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}
