import React from 'react';
import ErrorPage from '../../views/ErrorPage';
import GenerateURL from '../../util/APIUrlProvider';
import useFetchPermissions from '../customHooks/useFetchPermissions';
import { useLocation, useParams } from 'react-router-dom';

const RouteWithLayout = (props) => {
  const permissions = useFetchPermissions();
  const location = useLocation();
  const params = useParams();
  const { layout: Layout, component: Component, authorization, ...rest } = props;
  return (
    <React.Fragment>
      {
        isAuthorized(params, authorization, permissions) ? (
          <Layout>
            <ErrorBoundary location={location}>
              <Component {...rest} />
            </ErrorBoundary>
          </Layout>
        ) : (
          <ErrorPage is_unauthorized={true} />
        )
      }
    </React.Fragment>
  )

};

function isAuthorized(match, authorization, permittedRoutes) {
  let authorized = false;
  if (authorization && permittedRoutes) {
    authorization.forEach((element) => {
      const url = GenerateURL(match, element.route, true);
      const types = permittedRoutes[element.type];
      if (types.includes(url)) {
        authorized = true;
      }
    });
  } else {
    authorized = true;
  }
  return authorized;
}



export default RouteWithLayout;

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  componentDidUpdate(prevProps) {
    // Reset error when the route changes
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ error: null, errorInfo: null });
    }
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="global-error">
          <h2>Something went wrong. Please try again!</h2>
        </div>
      );
    }
    return this.props.children;
  }
}
