import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

export const JiraTicket = ({jiraObject,onClick,onDelete,isLink,isFinalise,variant}) => {
    const classes = useStylesForJira({isFinalise});
    const {WorkItemType, Title : story_name, url : link,state: status} = jiraObject
    return (
      variant == "chip"? 
        <Tooltip title={story_name || ""} arrow>
          <div className={classes.resultChip}>
            {getJiraTypeIcon(WorkItemType,"16px")}
            <span className='font-12 font-weight-600 color-value text-ellipsis'>{story_name}</span>
            <span className='ri-close-line color-value-78 font-20 cursor-pointer' style={{lineHeight:'1'}} onClick={(e)=>{e.stopPropagation(); onDelete(jiraObject)}}></span>
          </div>
        </Tooltip>
      
      :
          <div className={classes.resultItem +" container-item"} onClick={onDelete || isFinalise ? ()=>{} :()=>onClick(jiraObject)}>
             <div className='d-flex align-center wrapper-class-jira' style={{gap:"12px",width:"93%"}}>
              {getJiraTypeIcon(WorkItemType)}
                {isLink ? 
                   <a href={link} target='_blank' className={`font-14 font-weight-500 text-ellipsis ${isFinalise ? 'color-icon-secondary':'color-value'}`}>
                    {story_name}
                  </a>
                : 
                <div className='jira-summary font-14 font-weight-500 text-ellipsis color-value'>
                  {story_name}
                </div>
                }
               
               
             </div>
             {!isFinalise && 
                  <div className={'greyStatus avtar'}>
                      {status}
                  </div>
                }
              {/* {onDelete && 
                <span className='font-12 font-weight-500 color-key-primary' onClick={()=>onDelete(jiraObject)}>REMOVE</span>
              } */}
          </div>
      
    );
  }
  
  export function getJiraTypeIcon(issue_type,size){
    let normalisedIssuetype = issue_type.toLowerCase();
    //const classes = useStylesForJira();
    switch(normalisedIssuetype){
      case "bug":
        return( 
              <div className={'jira-icon'} style={{backgroundColor:"#d54a4a",width:size || '24px',height: size || '24px'}}>
                <span className='ri-circle-fill font-12' style={{lineHeight:"1"}}></span>
              </div>
        );
      case "story":
        return (
          <div className={'jira-icon'} style={{backgroundColor:"#2EBE79",width:size || '24px',height: size || '24px'}}>
                <span className='ri-bookmark-fill font-12' style={{lineHeight:"1"}}></span>
          </div>
        );
      case "task":
        return (
          <div className={'jira-icon'} style={{backgroundColor:"#67aeee",width:size || '24px',height: size || '24px'}}>
                <span className='ri-check-line font-14 font-weight-700' style={{lineHeight:"1"}}></span>
          </div>
        );
      case "epic":
        return (
          <div className={'jira-icon'} style={{backgroundColor:"#904EE2",width:size || '24px',height: size || '24px'}}>
                <span className='ri-flashlight-fill font-12 font-weight-700' style={{lineHeight:"1"}}></span>
          </div>
        );
      default:
        return (
          <div className={'jira-icon'} style={{backgroundColor:"#0086FF",width:size || '24px',height: size || '24px'}}>
                <span className='ri-picture-in-picture-fill font-12 font-weight-500' style={{lineHeight:"1"}}></span>
          </div>
        );
    }
  }

  const useStylesForJira = makeStyles((theme)=>({
    searchContainer: {
      borderRadius:"6px",
      border: "1px solid #E6E6E6",
      padding: '10px 12px 10px 12px',
      borderBottomLeftRadius: (props) => props.openSearch ? "0px" : "6px",
      borderBottomRightRadius: (props) => props.openSearch ? "0px" : "6px",
      borderBottom: (props) => props.openSearch ? "none" : "1px solid #E6E6E6",
  
      "& .heading":{
        padding:"10px 12px",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        "& .input-component":{
          marginBottom:"0px !important",
        },
        "& .divider":{
          height:"30px",
          width:"1px",
          backgroundColor:"#CACACA",
          margin:"0px 8px",
        }
      },
      "& .search-container":{
        padding:"10px 12px",
        height:"40px",
        border: "1px solid #E6E6E6",
        borderLeft:"none",
        borderRight:"none",
        backgroundColor:"#FAFAFA",
        width:"100%",
        display:"flex",
        gap:"10px",
        "& input":{
          border: "none",
          background:"#FAFAFA",
          outline: "none", 
          padding:"0 !important",
          "&:focus": { 
            outline: "none !important", 
            boxShadow: "none !important",
            border: "none !important",
          },
        }
      }
    },
    inputWrapper: {
      padding:"10px 12px",
      height:"40px",
      border: "1px solid #E6E6E6",
      backgroundColor:"#FFFFFF",
      width:"100%",
      display:"flex",
      gap:"10px",
      "& input":{
        border: "none",
        background:"#FFFFFF",
        outline: "none", 
        padding:"0 !important",
        "&:focus": { 
          outline: "none !important", 
          boxShadow: "none !important",
          border: "none !important",
        },
      }
    },
    resultContainer:{
      // "& > .container-item": {
      //   borderLeft: 'none',
      //   borderRight: 'none',
      // },
      // "& > .container-item:first-child": {
      //   borderTop: "none",
      //   borderRadius: "0px",
      //   borderTopLeftRadius: "0px",
      //   borderTopRightRadius: "0px",
      // },
    
      // /* Style for the last child (direct child) */
      // "& > .container-item:last-child": {
      //   borderBottom: "none",
      //   borderTop: "none",
      //   borderTopLeftRadius: "0px",
      //   borderTopRightRadius: "0px",
      //   borderBottomLeftRadius: "4px",
      //   borderBottomRightRadius: "4px",
      // },
      "& > .container-item": {
        border:'1px solid #E6E6E6',
        borderTop: 'none',
        borderRadius:'0px',
        padding:'7px 8px 7px 8px',
      },
      "& > .container-item:first-child": {
        borderTop:'none',
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius:'0px',
        borderBottomRightRadius:'0px'
      },
    
      /* Style for the last child (direct child) */
      "& > .container-item:last-child": {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
      },
      '& .jira-summary':{
        fontSize:'12px !important',
        color:"#505050 !important",
      },
      "& .MuiLinearProgress-barColorPrimary":{
        backgroundColor:"#0086FF !important",
      }
    },
    resultItem:{
      padding:(props)=>props.isFinalise ? "12px 0px" :"12px",
      borderBottom:(props)=>props.isFinalise ? 'none' : "1px solid #E6E6E6",
      display:"flex",
      gap:"12px",
      borderRadius:"0px",
      alignItems:"center",
      cursor:"pointer",
      justifyContent:'space-between',
      "&:hover":{
        backgroundColor:(props)=>props.isFinalise ? '#FFFFFF':"#f2f2f2",
        borderColor:(props)=>props.isFinalise ? '#FFFFFF':"#f2f2f2"
      },
      '& .greyStatus':{
        padding: '4px 6px 4px 6px',
        borderRadius: "6px",
        backgroundColor: '#F4F4F4',
        color: '#949494',
        fontSize: '12px',
        fontWeight: '700',
        border: '1px solid #E6E6E6',
        textWrap: 'nowrap'
      },
      '& .jira-icon':{
          padding:'6px',
          borderRadius:'4px',
          color: '#fff',
          width:'24px',
          height:'24px',
          display:'flex',
          alignItems:'center',
          justifyContent:'center'
      }
    },
    resultChip:{
      height:'28px',
      width:'162px',
      padding:'4px 8px 4px 8px',
      alignItems: 'center',
      display:'grid',
      gridTemplateColumns: '20px 1fr 16px',
      borderRadius: '24px',
      backgroundColor:'#F4F4F4',
      '& .jira-icon':{
          padding:'6px',
          borderRadius:'4px',
          color: '#fff',
          width:'24px',
          height:'24px',
          display:'flex',
          alignItems:'center',
          justifyContent:'center'
      }
    },
    icon:{
      padding:"6px",
      borderRadius:"4px",
      color:"#ffffff",
      width:"24px",
      height:"24px",
      display:"flex",
      alignItems:'center',
      justifyContent:"center",
    },
    selectedTicketsSection: {
      "& > .container-item": {
        borderBottom:'1px solid #E6E6E6',
        borderTop: 'none',
        borderRadius:'0px',
        padding:'7px 8px 7px 8px',
      },
      "& > .container-item:first-child": {
        borderTop:'none',
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius:'0px',
        borderBottomRightRadius:'0px'
      },
    
      /* Style for the last child (direct child) */
      "& > .container-item:last-child": {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
        borderBottom: 'none !important',
      },
      '& .jira-summary':{
        fontSize:'12px !important',
        color:"#505050 !important",
      },
  
      "& $resultItem": {
        borderLeft: "none !important",
        borderRight: "none !important",
      },
    }
  }))