import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment';
import HorizontalTab from '../../../../components/wizardComponents/HorizontalTab';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';

import { ParameterComponent } from '../../../service/listing/components/HistoryPageComponents/DeployHistory';
const ActivityDetails = (props) => {
  const activity_status = props.activity_status ? props.activity_status : null;
  const activity_master_id = props.activity_master_id ? props.activity_master_id : null;
  const activity_name = props.activity_name;
  const handleClose = props.handleClose;
  const [state, setState] = useState({ activity_steps: [], open_div: false, loading: false });
  const tableGird = props.tableGird;
  const colSpan = props.colSpan ? props.colSpan : 5
  const logUrl = props.logUrl;
  const task_id = props.task_id ? props.task_id : null;
  const activity_id = props.activity_id ? props.activity_id : null
  const revokeActivity = props.revokeActivity ? props.revokeActivity : () => { }
  const sse_flag_enable = properties.sse_flag;
  const parameters = props.parameters ? props.parameters : null;
  console.log(activity_master_id,activity_name, "parametersparameters", "Multiple Deployment of Stateless app")
  var event_source = null;

  function handleOpen() {
    if (sse_flag_enable) {
      getActivityCurrentSseResponse(task_id)
    } else {
      getActivityCurrentInfo(activity_id)
    }

    setState(new_state => ({
      open_div: true
    }));
    props.handleVIewLogsTrue? props.handleVIewLogsTrue(activity_id) : null;
  }
  //sort activity detail names in reverse order
  function reverseActivityDetails(activityDetail) {
    console.log(activityDetail, 'ac_v001')
    var detailsList = activityDetail.activity_sub_task ? activityDetail.activity_sub_task.sub_task_name : activityDetail?.job_step?.name
    var listItems = []
    console.log(detailsList, 'detail_list==>')
    listItems.push(detailsList)
    return listItems;
  }
  function reverseActivityDetailsDuration(activityDetail) {
    var created_at_date = moment(activityDetail.created_at);
    var updated_at_date = moment(activityDetail.updated_at);
    var totalDuration = moment.duration(updated_at_date.diff(created_at_date))
    var seconds = totalDuration.seconds();
    var minutes = totalDuration.minutes();
    var durationList = [minutes + "m" + " " + seconds + "" + "s"]
    var listItems = []
    for (var i in durationList) {
      listItems.push(durationList[i])
    }

    return listItems;
  }


  const handleCloseCurrentTab = () => {
    props.removeArrData()
    setState(new_state => ({
      open_div: false
    }));
    props.handleVIewLogsFalse ? props.handleVIewLogsFalse(activity_id) : null;

  }
  useEffect(() => {
    if (activity_status === "RUNNING") {
      if (sse_flag_enable) {
        getActivityCurrentSseResponse(task_id)
      } else {
        getActivityCurrentInfo(activity_id)
      }

    }
    return (() => {
      state.event_source && state.event_source.close()
    })
  }, []);
  //get activity id
  function getActivityCurrentInfo(activity_id) {

    var requestInfo = {
      endPoint: GenerateURL({ activity_id: activity_id }, properties.api.get_current_activity_status),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, getActivityCurrentInfoSuccess, getActivityCurrentInfoFailure);
  }

  function getActivityCurrentInfoSuccess(response) {
    if (response.results.length != 0) {
      if (response.results && response.results.length > 0) {
        if (activity_id == response.results[0].activity_status_id) {
          setState(new_state => ({
            ...new_state,
            loading: true,
            activity_details: response.results,
          }));
        }
      }

    }
  }
  console.log('state.activity_details', state.activity_details);

  function getActivityCurrentInfoFailure(error, exception) {
    setState(new_state => ({
      ...new_state,
      error: true,
      open_div: true
    }));
  }


  function getActivityCurrentSseResponse(id) {

    var url = GenerateURL({ task_id: id }, properties.api.sse_for_activity_step_status_execution_history)
    state.event_source = new EventSource(url)
    state.event_source.addEventListener("ACTIVITY_STEP_STATUS", refresh_page_header_task_id)
    state.event_source.addEventListener("ACTIVITY_STEP_STATUS_CLOSE", handle_task_completed_task_id)
    setState(new_state => ({
      ...new_state,
      loading: true
    }))
  }
  function refresh_page_header_task_id(e) {
    var current_activity_step_data = JSON.parse(e.data);
    var current_task_id = e.lastEventId;
    if (current_activity_step_data.length != 0) {
      if (task_id == current_task_id) {
        setState(new_state => ({
          ...new_state,
          loading: true,
          activity_details: current_activity_step_data,
        }));
      }
    }
  }
  console.log('state.current_activity_step_data', state.activity_details);
  function handle_task_completed_task_id(e) {
    e.srcElement.close()
    setState(new_state => ({
      ...new_state,
      loading: false
    }))
  }

  console.log('state.activity_details>>>', state.activity_details)

  const transformDataforHorizontalTab = () => {


    let updated_arr = state.activity_details && state.activity_details.length > 0 ? state.activity_details.map((single_item, key) => {
      console.log('single_item>>> ', single_item);
      return (
        {
          order: key,
          mainText: <Link className="text-anchor-blue cursor-pointer"
            to={logUrl + "&active_tab=" + key
            } target="_blank">
            <span className="mr-5">{reverseActivityDetails(single_item)}</span><br />
            <span className="">Duration: &nbsp;{reverseActivityDetailsDuration(single_item)}</span>
          </Link>,
          status: single_item.status
        }
      )
    }) : [];
    updated_arr.reverse()
    return updated_arr;

  }

  console.log(activity_master_id,activity_name, "props_variant")
  return (
    <>
      {props.variant === "newactivityDetails" ?
        <div>
          <div className='d-flex align-center gap-4 bg-white' style={props.openId ? {position : "absolute", right : "10px", top : "-60px"} : {}}>
            <ParameterComponent
              buildParameters={parameters && parameters.build_metadata ? parameters.build_metadata : null}
              deployParameters={parameters && parameters.deploy_metadata ? parameters.deploy_metadata : null}
              variant="activity_page_new"
              newDesign={true}
              activity_master_id={activity_master_id}
            />
            <Tooltip title="View Activity Details">
              {
                activity_master_id == 12000  || activity_name == 'Multiple Deployment of Stateless app'?
                  <span className="activity-details-btn  cursor-not-allowed" >
                    <span className="ri-list-check-2 font-20"></span>
                  </span>
                  :
                  <span className={`activity-details-btn ${state?.open_div? "cursor-not-allowed" : ""}` }onClick={handleOpen}>
                    <span className="ri-list-check-2 font-20"></span>
                  </span>
              }
            </Tooltip>
            <Tooltip title="View Logs">
              {
                activity_master_id == 12000 ?
                  <span className="activity-details-btn  cursor-not-allowed" >
                    <span className="ri-file-text-line font-20"></span>
                  </span>
                  :
                  <a href={logUrl} target="_blank">
                    <span className="activity-details-btn " >
                      <span className="ri-file-text-line font-20"></span>
                    </span>
                  </a>
              }

            </Tooltip>
            <Tooltip title="Revoke Activity">
              {/* <button
                className={activity_status == "RUNNING" || activity_status == "IN_QUEUE" ? "btn btn-flaticon" : "btn btn-flaticon btn-flaticon-disabled"}
                onClick={activity_status == "RUNNING" || activity_status == "IN_QUEUE" ? () => { revokeActivity() } : () => { }}>
                <span class="material-icons">
                  stop
                </span>
              </button> */}
              <span onClick={activity_status == "RUNNING" || activity_status == "IN_QUEUE" ? () => { revokeActivity() } : () => { }} 
              className={`${activity_status == "RUNNING" || activity_status == "IN_QUEUE" ? "activity-details-btn revoke-btn" : "activity-details-btn cursor-not-allowed"}`} style={{ height: "36px", width: "36px" }}>
                <span className="ri-stop-circle-line font-20"></span>
              </span>
            </Tooltip>
          </div>


          {
            state.open_div &&

            <ActivityStepTab
            handleVIewLogsFalse={props.handleVIewLogsFalse}
            variant={props.variant}
              tableGird={tableGird}
              colSpan={colSpan}
              logUrl={logUrl}
              task_id={task_id}
              handleClose={handleClose ? handleClose : handleCloseCurrentTab}
              transformDataforHorizontalTab={transformDataforHorizontalTab}
              loading_step_status={state.loading} />
          }
        </div> : <>
          <div className="btn-group btn-icon-group width-fitcontent ml-auto" style={{ height: 'fit-content' }}>
            <ParameterComponent
              buildParameters={parameters && parameters.build_metadata ? parameters.build_metadata : null}
              deployParameters={parameters && parameters.deploy_metadata ? parameters.deploy_metadata : null}
              variant="activity_page"
              activity_master_id={activity_master_id}
            />
            <Tooltip title="View Activity Details">
              {
                activity_master_id == 12000 ?
                  <button className="btn btn-flaticon btn-flaticon-disabled" >
                    <span class="material-icons material-symbols-outlined">
                      notes
                    </span>
                  </button>
                  :
                  <button className="btn btn-flaticon" onClick={handleOpen}>
                    <span class="material-icons material-symbols-outlined">
                      notes
                    </span>
                  </button>
              }
            </Tooltip>
            <Tooltip title="View Logs">
              {
                activity_master_id == 12000 ?
                  <button className="btn btn-flaticon btn-flaticon-disabled">
                    <span class="material-icons material-symbols-outlined">
                      article
                    </span>
                  </button> :
                  <a href={logUrl} target="_blank" className="btn btn-flaticon">
                    <span class="material-icons material-symbols-outlined">
                      article
                    </span>
                  </a>
              }

            </Tooltip>
            <Tooltip title="Revoke Activity">
              <button
                className={activity_status == "RUNNING" || activity_status == "IN_QUEUE" ? "btn btn-flaticon" : "btn btn-flaticon btn-flaticon-disabled"}
                onClick={activity_status == "RUNNING" || activity_status == "IN_QUEUE" ? () => { revokeActivity() } : () => { }}>
                <span class="material-icons">
                  stop
                </span>
              </button>
            </Tooltip>
          </div>


          {
            state.open_div &&

            <ActivityStepTab
            variant={props.variant}
              tableGird={tableGird}
              colSpan={colSpan}
              logUrl={logUrl}
              task_id={task_id}
              handleClose={handleClose ? handleClose : handleCloseCurrentTab}
              transformDataforHorizontalTab={transformDataforHorizontalTab}
              loading_step_status={state.loading} />
          }
        </>
      }

    </>
  )
}
ActivityDetails.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default ActivityDetails;

const ActivityStepTab = (props) => {
  const handleClose = props.handleClose;
  const logUrl = props.logUrl;
  const transformDataforHorizontalTab = props.transformDataforHorizontalTab;
  const loading_step_status = props.loading_step_status ? props.loading_step_status : false
  const [state, setState] = useState({ event_source: null, loading_step_status: loading_step_status ? true : false, });

  // const handleOnClickHandler = (order) => {
  //   console.log('Information data_order', order);
  /* setState((prevState) => ({
    ...prevState, selectedTabOrder: order
  })) */
  // }
  // console.log('selectedTabOrder>>>', state.selectedTabOrder);
  return (
    <div className='view-details-block bg-white' style={props.variant === "newactivityDetails" ?{border : "1px solid #E6E6E6"} : {}}>
      <span>
        <div className="d-flex align-center space-between" style={props.variant === "newactivityDetails" ?{padding : "3px 10px"} : {}}>
          <p>
            <span className="font-14 " style={{ color: "#3f4b4e" }}>Activity Steps Status &nbsp;&nbsp;</span>
            <Link to={logUrl} target="_blank" className="font-11 text-anchor-blue cursor-pointer">View Logs</Link>
          </p>
          <div>
            <button className="btn btn-transparent" onClick={() => { handleClose() }}>
               <span className='ri-close-line' fontSize="large" style={{ color: "#3f4b4e" }} ></span>   
            </button>
          </div>
        </div>

        <div className='d-flex align-center' style={{ backgroundColor: '#f6f8f8' }}>
          <HorizontalTab
            tabDataList={transformDataforHorizontalTab()}
            onClickHandler={() => { }}
            variant="step_logs_static"
            tabState={
              {
                selectedTabOrder: 0
              }
            }
          />
        </div>
      </span>

    </div>
  )
}
ActivityStepTab.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}