import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';

import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from '@mui/styles';
import { ServiceHierarchy } from '../../execution/components/ExecutionCard';
import { Alert } from '@mui/material';

import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import { Loading } from '../../../utils/Loading';
import { styled } from "@mui/system";


const RetryPopup = ({ reTriggerError, resetTriggerError, ...props }) => {
    const pipeline = props.pipeline;
    const application_id = props.application_id;
    const pipeline_instance = props.pipeline_instance;
    const last_trigger = pipeline && pipeline.last_trigger ? pipeline.last_trigger : null;
    const pipeline_context_param = last_trigger.pipeline_context_param ? last_trigger.pipeline_context_param : null
    const classes = useStyles();
    const variant = props.variant ? props.variant : null;


    const [state, setstate] = useState({
        data: {},
        error: {},
        loading: false,
        isPipelineRerunable: false,
        is_pipeline_rerun_possible: null

    })
    const retryPipeline = props.retryPipeline

    const checkPipelineInstanceIsReRunable = (pipelineId) => {
        setstate(prevState => ({
            ...prevState,
            loading: true
        }))
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipelineId, pipeline_instance_id: pipeline_instance.id }, properties.api.check_pipeline_is_rerunable),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (response) => {
            setstate(prevState => ({
                ...prevState,
                loading: false,
                is_pipeline_rerun_possible: response?.is_pipeline_rerun_possible
            }))

        }, (error) => {
            setstate(prevState => ({
                ...prevState,
                loading: false
            }))
        });

        // setTimeout(() => {
        //     setstate(prevState => ({
        //         ...prevState,
        //         loading: false,
        //         isPipelineRerunable: false
        //     }))
        // }, 2000)
    }
    const handleClickOpen = () => {
        setstate({ ...state, open: true });
        checkPipelineInstanceIsReRunable(pipeline.id)
    };

    const handleClose = () => {
        setstate({ ...state, open: false, });
        if (resetTriggerError) {
            resetTriggerError()
        }
    };

    return (
        <>
            {
                pipeline && pipeline_instance?.id && <Tooltip title="Replay Pipeline">

                    {variant == "execution_history" ?
                        <ReplayPipeline onClick={handleClickOpen}>
                            <span style={{ color: '#505050', fontSize: '16px' }} className="ri-play-mini-line">
                            </span>
                            <span className='title'>Replay</span>
                        </ReplayPipeline>
                        :
                        <span>
                            <button className={"btn btn-transparent MuiIconButton-root" + " " + classes.margin}
                                onClick={handleClickOpen}
                                style={{ color: "#0086ff", border: "2px solid #0086ff " }}>
                                <i className="ri-reset-left-fill"></i>
                            </button>
                        </span>
                    }

                </Tooltip>
            }
            <Dialog
                open={state.open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="fill-details-head d-flex align-center space-between"
                    style={{ backgroundColor: 'rgb(18, 77, 155)', padding: 14 }}>
                    <p className='color-white'>Replay Pipeline</p>
                    <button className="btn btn-transparent" edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <span className='ri-close-line color-white font-18' ></span>
                    </button>
                </div>
                <div className="card " style={{ border: 'none' }}>
                    {
                        state.loading ?
                            <div className='d-flex align-center justify-center' style={{ height: '280px' }}>
                                <Loading varient="light" />
                            </div>
                            : <>
                                <div className="card-body" style={{ overflowY: "auto", height: "400px" }}>
                                    <div className="m-auto" style={{ width: '400px' }}>
                                        <i className="ri-information-line text-center mb-10 d-block m-auto" style={{ color: '#4783dc', fontSize: '60px' }} />
                                        <div className="">
                                            {
                                                state.is_pipeline_rerun_possible ?
                                                    <p className="font-16 font-weight-bold text-center mb-5">
                                                        You are about to replay the pipeline with following runtime params.
                                                    </p> : <p className="font-16 font-weight-bold text-center mb-5">
                                                        The pipeline has been edited after the execution of this pipeline. Since the pipeline structure might have changed, re-run may lead to in-consistency.
                                                    </p>
                                            }

                                        </div>

                                    </div>
                                    {
                                        state.is_pipeline_rerun_possible &&
                                        <div className='tab-data'>
                                            <div className="pd-10">
                                                <div className='card custom-card-exec-paramsn m-10'>
                                                    <div className='card-header'>
                                                        <p>
                                                            <span>Env Type</span>
                                                            <span>Env Name</span>
                                                            <span>Service Name</span>
                                                            <span>Branch Name</span>
                                                            <span>Deployment Tag</span>
                                                        </p>
                                                    </div>
                                                    <div className='card-body'>
                                                        {
                                                            pipeline_context_param ?
                                                                <ServiceHierarchy data={pipeline_context_param} /> :
                                                                <p>Pipeline context params not available</p>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                                {
                                    reTriggerError && <Alert severity="error" variant="filled" >
                                        <div className="error-div">
                                            {reTriggerError == "Not found." && 'It looks like pipeline has been edited . you can not retrigger it.'}
                                        </div>
                                    </Alert>
                                }
                                <div className="card-footer border-top pd-10 justify-end d-flex align-center">
                                    <button className="btn btn-outline-dark-grey mr-5" onClick={handleClose} >
                                        Cancel
                                    </button>
                                    {
                                        state.is_pipeline_rerun_possible &&
                                        <button className="btn btn-primary-v2" onClick={() =>
                                            retryPipeline(pipeline_instance.pipeline_id, pipeline_instance.id)}>
                                            Confirm
                                        </button>
                                    }

                                </div>
                            </>
                    }

                </div>
            </Dialog>
        </>
    )
}

RetryPopup.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default RetryPopup;

const useStyles = makeStyles((theme) => ({
    stageData: {
        display: "flex",
        alignItems: "center",
        lineHeight: 1.5,
        "& .main-text": {
            color: "#000",
            fontSize: "13px",
            marginRight: "0px",
        },
        "& .sub-text": {
            color: "#949494",
            fontSize: "13px",
        },
        "& .status-font": {
            fontSize: "14px",
            textTransform: "uppercase",
        },
        "& .success": {
            color: "#62E187",
        },
        "& .success .flaticon-circle-with-check-symbol": {
            margin: "0px 4px",
            color: "#62E187",
        },
        "& .success .flaticon-circle-with-check-symbol:before": {
            fontSize: "14px!important",
            color: "#62E187",
        },
    },
    executionData: {
        display: "flex",
        alignItems: "center",
        lineHeight: 1,
        "& .main-text": {
            color: "#000",
            fontSize: "12px",
            marginRight: "0px",
        },
        "& .sub-text": {
            color: "#949494",
            fontSize: "12px",
        },
    },
    d_Flex: {
        display: "flex",
        alignItems: "center",
    },
}));

const ReplayPipeline = styled("div")({
    width: '100px',
    height: '32px',
    padding: '8px 12px',
    borderRadius: '6px',
    background: '#F4F4F4',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: 'pointer',
    '&:hover': {
        background: '#dedcdc',
    },
    '& .title': {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '14.63px',
        textAlign: 'center',
        color: '#505050',
        textTransform: 'uppercase'
    }
});

