import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';


const PipelineAlphabetLogo = ({ alphabet }) => {
    const classes = useStyles();

    return (
        <div className={classes.alphabetLogo}>
            <div className="alphabet-label  capital-text">
                {alphabet}
            </div>
        </div>
    )
}
PipelineAlphabetLogo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default PipelineAlphabetLogo;

const useStyles = makeStyles((theme) => ({
    alphabetLogo: {
        width: '48px',
        height: '48px',
        background: 'linear-gradient(0deg, #01BB62, #01BB62)',
        borderRadius: '8px',
        border: '2px solid #FFFFFF',
        boxShadow: '0px 4px 4px 0px #0000001F',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        '& .alphabet-label': {
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '24px',
            letterSpacing: '0em',
            color:'#fff'
        }
    }
}));