import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import MoreMenu from './components/MoreMenu';
import StepEditTemplate from './step_detail/stepEdit/StepEditTemplate'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteConfirmMessage from '../jobTemplates/components/DeleteConfirmMessage'
import AddJobToJobTempalte from './addJob/AddJobToJobTempalte'
import { Input } from '../../components/genericComponents/Input';
import { getCommonFunctions } from '../serviceRevamp/add/ci_flow/SourceDetails';
import Dialog from '@mui/material/Dialog';

import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../util/Validator';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi from '../../util/apiInvoker';
import { Tooltip } from '@mui/material';

const JobTemplateCard = (props) => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const [deleteCondition, setDeleteCondition] = useState(false)

    const [nonConditionalStepState, setNonConditionalStepState] = useState({ step: null, openDialog: false })

    const [addJobDialogData, setAddJobDialogData] = useState({
        isOpen: false,
        jobTemplateData: [],
        data: {},
        error: {},
        openQueueEditDialog: false,
        validations: {
            job_code: [VALIDATION_TYPE_REQUIRED],
            job_name: [VALIDATION_TYPE_REQUIRED],
        },
    })
    const commonFunctions = getCommonFunctions(addJobDialogData, setAddJobDialogData, inherits);

    const { data,
        onClickedFunction,
        application_id,
        updatePreviousCanvasLayer,
        template_id,
        jobTemplate,
        updateJobTemplate,
        previousCanvasLayer,
        queueList,
        jobVersion
    } = props

    console.log(data,"sjhbhjsvdfshvbhdsvbhjdf")

    const history = useNavigate()

    const getRightLineConnectionRange = (currentIndex, nexCurrentIndex) => {

        let currentSelectedCardPostion = data[currentIndex] && data[currentIndex].selectedCard && data[currentIndex].selectedCard.order
        let countOfNextLayerCards = data[nexCurrentIndex].values.length
        let showRightConnectedLineFrom
        let showRightConnectedLineTill
        if (currentSelectedCardPostion + 1 > countOfNextLayerCards) {
            // showRightConnectedLineFrom = countOfNextLayerCards
            showRightConnectedLineFrom = 0
            showRightConnectedLineTill = currentSelectedCardPostion - 1
            return { showRightConnectedLineFrom: showRightConnectedLineFrom, showRightConnectedLineTill: showRightConnectedLineTill, forUpperDiv: showRightConnectedLineTill + 1 }
        }
        return null

    }

    useEffect(() => {
        getIsQueueEnabled()
    }, [])

    function getIsQueueEnabled() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.queue_enabled),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, handleSuccess, handleFail);
    }

    function handleSuccess(response) {
        setAddJobDialogData((new_state) => ({
            ...new_state,
            isQueueEnabled: response
        }))
    }

    function handleFail(error) {
        console.log(error);
    }

    const inRangeCheck = (low, high, target) => {

        return (low <= target && target <= high)
    }

    const redirectToAddStep = (item, current_index) => {
        console.log(jobVersion, data[0], jobTemplate)
        console.log(data, current_index, 'data_at_time_of_add')
        // let version;
        let { version } = jobTemplate

        console.log('version_001', version)

        let jobData = data && data[0]
        const job_code = jobData?.selectedCard?.job_code
        console.log(jobData,"jgsdvcdschjds")
        if (jobData?.selectedCard?.is_dynamic) {
            version = 'v3'
        }
        else {
            version = jobVersion ? jobVersion : version
        }

        console.log(jobData, 'j_data')
        let allLayer = {}

        let previousLayer = {}
        data && data.forEach((item, index) => {
            if (index < current_index) {
                allLayer[item.type] = item.selectedCard.order
            }
        })
        let previousSelectedLayer = data[current_index - 1]

        previousLayer['type'] = previousSelectedLayer.type

        previousLayer['index'] = previousSelectedLayer.selectedCard.order

        const layer = { allLayer: allLayer, previousLayer: previousLayer }
        console.log(version)
        if (current_index == 3)
            history(`/application/${application_id}/job-edit/${template_id}/job/${job_code}/steps`,
                     {state: { mode: 'create', layer: layer, type: 'last_layer', previous_canvas_layer: previousCanvasLayer, version: version } ,
            });

        else
            history(`/application/${application_id}/job-edit/${template_id}/job/${job_code}/steps`, 
                    {state: { mode: 'create', layer: layer, previous_canvas_layer: previousCanvasLayer, version: version } 
            });

    }

    const editConditionalStep = (conditionalStep, editable_step_index, type) => {

        if (type === 'conditional_step') {

            const job_code = data && data[0].selectedCard.job_code

            const job_order = data && data[0].selectedCard.order

            const layer = { job: job_order, step: editable_step_index }

            history(`/application/${application_id}/job-edit/${template_id}/job/${job_code}/steps`, 
                {state: { mode: 'edit', layer: layer, type: 'conditional_step', previous_canvas_layer: previousCanvasLayer } 
            });

        }

        else if (type === 'condition') {

            const job_code = data && data[0].selectedCard.job_code
            const job_order = data && data[0].selectedCard.order
            const step_order = data && data[1].selectedCard.order
            const layer = { job: job_order, step: step_order }
            history(`/application/${application_id}/job-edit/${template_id}/job/${job_code}/steps`,
                {state: { mode: 'edit', layer: layer, type: 'conditional_step', previous_canvas_layer: previousCanvasLayer } 
            });
        }
    }

    const onEditNonconditionalStep = (step, editable_step_index, type, layer_index) => {

        let layer = { layer_index: layer_index, currentStepOrder: editable_step_index }
        if (layer_index === 1) {
            var allLayer = { job: data && data[0].selectedCard.order }
            layer['allLayer'] = allLayer
        }

        else if (layer_index === 3) {

            var allLayer = {
                job: data && data[0].selectedCard.order, conditionalStep: data && data[1].selectedCard.order,
                condition: data && data[2].selectedCard.order

            }
            layer['allLayer'] = allLayer
        }
        const version = data[0].selectedCard?.is_dynamic ? 'v3' : jobTemplate?.version

        setNonConditionalStepState({ step: step, openDialog: true, layer: layer, version: version })

    }

    const onDeleteHandler = (layer_index, item_index, item, type) => {
        console.log('type', type,layer_index,item_index,)

        if (type === 'step') {
            if (layer_index == 1) {
                let wholeJobTemplate = jobTemplate
                let job_template_data = wholeJobTemplate.job_template
                let jobs = job_template_data.jobs     //jobs
                const job_order = data && data[0].selectedCard.order
                const step_order = item_index
                let updatedJobStepArray = jobs[job_order].steps
                updatedJobStepArray.splice(step_order, 1)
                const updatedSteps = getStepsInCorrectOrder(updatedJobStepArray)
                jobs[job_order].steps = updatedSteps
                wholeJobTemplate.job_template['jobs'] = jobs
                if (previousCanvasLayer && previousCanvasLayer[layer_index].selectedIndex && item_index < previousCanvasLayer[layer_index].selectedIndex) {
                    let previousLayer = previousCanvasLayer
                    previousLayer[layer_index].selectedIndex = previousCanvasLayer[layer_index].selectedIndex - 1
                    updatePreviousCanvasLayer(previousLayer)

                }
                updateJobTemplate(wholeJobTemplate)
            }

            else if (layer_index == 3) {
                let wholeJobTemplate = jobTemplate
                let job_template_data = wholeJobTemplate.job_template
                let jobs = job_template_data.jobs     //jobs            
                const job_order = data && data[0].selectedCard.order
                const step_order = data && data[1].selectedCard.order
                const conditionOrder = data && data[2].selectedCard.order
                let conditionValuesArray = jobs[job_order].steps[step_order].values_step_mapping[conditionOrder]
                let valueMappingArraySteps = conditionValuesArray.steps

                if (valueMappingArraySteps) {

                    if (valueMappingArraySteps.length === 1) {  // have only one step so will delete step and condition as well
                        setDeleteCondition(true)
                    }

                    else {
                        valueMappingArraySteps.splice(item_index, 1)
                        jobs[job_order].steps[step_order].values_step_mapping[conditionOrder].steps = valueMappingArraySteps
                        wholeJobTemplate.job_template['jobs'] = jobs
                        updateJobTemplate(wholeJobTemplate)
                    }
                }
            }
        }
        else if (type === 'job') {
            let wholeJobTemplate = jobTemplate
            console.log(previousCanvasLayer, 'pcl')
            let job_template_data = wholeJobTemplate.job_template
            let jobs = job_template_data.jobs
            if (previousCanvasLayer && previousCanvasLayer[layer_index].selectedIndex && item_index < previousCanvasLayer[layer_index].selectedIndex) {
                let previousLayer = previousCanvasLayer
                previousLayer[layer_index].selectedIndex = previousCanvasLayer[layer_index].selectedIndex - 1
                updatePreviousCanvasLayer(previousLayer)
            }
            if (jobs.length === 1) {
                updatePreviousCanvasLayer({ type: "jobs", selectedIndex: null })
            }
            jobs.splice(item_index, 1)
            job_template_data['jobs'] = jobs
            wholeJobTemplate['job_template'] = job_template_data
            updateJobTemplate(wholeJobTemplate)
        }
    }

    const isPreviousLayerCreatedConnectedLines = (current_index, previous_index) => {

        if (current_index !== 0) {
            let previousSelectedCardPostion = data[previous_index] && data[previous_index].selectedCard && data[previous_index].selectedCard.order
            let countOfCurrentLayerCards = data[current_index].values.length
            if (previousSelectedCardPostion + 1 > countOfCurrentLayerCards) {
                return true
            }
            else
                return false
        }
        else
            return false

    }

    const onDragEnd = (result) => {

        console.log('res_', result)
        const { destination, source, layer } = result
        let job_template_data = jobTemplate
        let jobs = job_template_data.job_template.jobs
        console.log('j_b_d', job_template_data)

        if (!destination || destination.index === source.index) {
            return
        }
        else if (layer === 1) {
            const job_order = data && data[0].selectedCard.order
            let selectedJob = jobs[job_order]
            let steps = selectedJob.steps
            var chnagedPostionedStep = steps[source.index]
            steps.splice(source.index, 1)
            steps.splice(destination.index, 0, chnagedPostionedStep)
            let updatedSteps = getStepsInCorrectOrder(steps)
            job_template_data.job_template.jobs[job_order].steps = updatedSteps
            // job_template_data['version']='v2'
            updateJobTemplate(job_template_data, false)
            return
        }

        else if (layer == 3) {
            let wholeJobTemplate = jobTemplate
            let job_template_data = wholeJobTemplate.job_template
            let jobs = job_template_data.jobs     //jobs            
            const job_order = data && data[0].selectedCard.order
            const step_order = data && data[1].selectedCard.order
            const conditionOrder = data && data[2].selectedCard.order
            var stepsArray = jobs[job_order].steps[step_order].values_step_mapping[conditionOrder].steps
            if (stepsArray)
                var chnagedPostionedStep = stepsArray[source.index]
            stepsArray.splice(source.index, 1)
            stepsArray.splice(destination.index, 0, chnagedPostionedStep)
            let orderedSteps = getStepsInCorrectOrder(stepsArray)
            jobs[job_order].steps[step_order].values_step_mapping[conditionOrder].steps = orderedSteps
            wholeJobTemplate.job_template['jobs'] = jobs
            updateJobTemplate(wholeJobTemplate, false)
            return
        }

    }

    const onHandleConfirmDelete = () => {

        let wholeJobTemplate = jobTemplate
        let job_template_data = wholeJobTemplate.job_template
        let jobs = job_template_data.jobs
        const job_order = data && data[0].selectedCard.order
        const step_order = data && data[1].selectedCard.order
        let allConditions = jobs[job_order].steps[step_order].values_step_mapping
        allConditions.splice(allConditions, 1)
        jobs[job_order].steps[step_order].values_step_mapping = allConditions
        wholeJobTemplate.job_template['jobs'] = jobs
        let currentCanvasLayer = previousCanvasLayer
        currentCanvasLayer[2] = { type: "conditions", selectedIndex: null }
        updatePreviousCanvasLayer(currentCanvasLayer)
        setDeleteCondition(false)
        updateJobTemplate(wholeJobTemplate)
    }

    const getStepsInCorrectOrder = (steps) => {

        const updatedSteps = steps && steps.map((step, index) => {

            return { ...step, order: index }
        })

        return updatedSteps
    }

    const handleAddJOb = () => {

        setAddJobDialogData(prevState => ({
            ...prevState,
            jobTemplateData: jobTemplate,
            open: true
        }))
    }

    const handleOnCloseAddDialog = () => {
        setAddJobDialogData(prevState => ({ ...prevState, open: false, jobTemplateData: [], editData: null }))
    }

    const updateJobTemplateFromAddDialog = (jobTemplateData) => {

        updateJobTemplate(jobTemplateData)

        handleOnCloseAddDialog()
    }
    const onEditJob = (data, editable_job_index, type, layer_index) => {

        let jobTemplateData = jobTemplate

        let template = jobTemplateData.job_template
        let jobs = template.jobs
        let jobData = jobs[editable_job_index]
        setAddJobDialogData(prevState => ({
            ...prevState,
            jobTemplateData: jobTemplate,
            open: true,
            editData: { editJobOrder: editable_job_index, data: jobData }
        }))
    }

    function onQueueNameEdit(data, editable_job_index, type, layer_index) {

        let jobTemplateData = jobTemplate

        let template = jobTemplateData.job_template
        let jobs = template.jobs
        let jobData = jobs[editable_job_index]
        setAddJobDialogData(prevState => ({
            ...prevState,
            jobTemplateData: jobTemplate,
            open: false,
            queueConfirmDialog: true,
            // queueInputOrder: editable_job_index,
            editData: { editJobOrder: editable_job_index, data: jobData },
        }))
        loadQueueList(data)
    }

    function loadQueueList(data) {
        var requestInfo = {
            endPoint: GenerateURL({ job_code: data.job_code }, properties.api.queue_list),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (response) => handleQueueListResponse(response, data), handleQueueListError);
    }

    function handleQueueListResponse(response, data) {
        setAddJobDialogData(new_state => ({
            ...new_state,
            list: getQueueList(response),
            data: {
                ...new_state,
                queue_name: data.queue_name && data.queue_name.length > 0 ? getExistingQueueName(response, data.queue_name) : null
            }
        }));
    }

    function getExistingQueueName(queue_list_data, queue_name) {
        var queue_data = getQueueList(queue_list_data);
        var existing_queue = queue_data.find((item) => {
            return item.label == queue_name
        })
        if (existing_queue) {
            return existing_queue.id;
        }
    }

    function getQueueList(data) {
        var updatedList = [];
        data.forEach((item, index) => {
            var obj = {
                id: index + 1,
                label: item.name
            }
            updatedList.push(obj);
        })

        return updatedList;
    }

    function handleQueueListError(error) {
        console.log(error)
    }


    function handleClose() {
        setAddJobDialogData((new_state) => ({
            ...new_state,
            queueConfirmDialog: false
        }))
    }



    function getPostDataQueueName() {
        var selectedQueueId = Number(addJobDialogData.data.queue_name);
        var selectedqueue = addJobDialogData.list.find((item) => {
            return item.id == selectedQueueId;
        })
        return selectedqueue.label;
    }

    function handleConfirmQueueChange() {
        let postData = { ...addJobDialogData.editData.data };
        postData.queue_name = addJobDialogData.data.queue_name
        if (postData.queue_name) {
            if (postData.queue_name.length == 0) {
                postData.queue_name = null
            }
            else {
                postData.queue_name = getPostDataQueueName();
            }
        } else {
            postData.queue_name = null
        }

        let result = ValidateDataSet(postData, addJobDialogData.validations)


        if (result.valid) {
            let jobTemplateData = addJobDialogData.jobTemplateData
            let jobTemplate = jobTemplateData.job_template
            let jobs = jobTemplate.jobs
            if (addJobDialogData.editData) {
                jobs[addJobDialogData.editData.editJobOrder] = { ...postData }
            }
            else {
                jobs.push({ ...postData, steps: [] })

            }
            jobTemplate.jobs = jobs
            jobTemplateData['job_template'] = jobTemplate
            updateJobTemplate(jobTemplateData)
        }
        else {
            setAddJobDialogData(new_state => ({
                ...new_state,
                error: result.error
            }))
        }
    }

    console.log(addJobDialogData.isQueueEnabled, "bsdjbjhsdbhbsdbbc")

    console.log(data, 'data_001_pp')

    return (
        <>
            <div className={classes.root + "" + 'job-template-canvas'} style={{ minHeight: '58vh', paddingLeft: '40px', display: 'flex', paddingTop: '20px', paddingBottom: '20px' }}>
                {
                    data && data.map((item, index) => {
                        let rightLineRange
                        let isNextLayerEmpty = true

                        if (data.length > index + 1) {
                            rightLineRange = getRightLineConnectionRange(index, index + 1)
                            isNextLayerEmpty = false
                        }

                        let isPreviousLayerAlreadyHaveRightLines = isPreviousLayerCreatedConnectedLines(index, index - 1)

                        return (
                            <>
                                {
                                    item.type == 'jobs' &&

                                    <div style={{ display: 'flex', paddingTop: '50px' }}>
                                        {
                                            <div style={{ marginTop: '-25px' }}>
                                                {/* middle div */}

                                                {
                                                    item.values && item.values.map((job, order) => {

                                                        let rightLineVertival = {}

                                                        let rightLineUpperVertival = {}

                                                        if (rightLineRange && inRangeCheck(rightLineRange.showRightConnectedLineFrom, rightLineRange.showRightConnectedLineTill, order)) {
                                                            rightLineVertival['borderRight'] = '3px solid gray'

                                                        }

                                                        if (rightLineRange && inRangeCheck(rightLineRange.showRightConnectedLineFrom, rightLineRange.forUpperDiv, order)) {
                                                            rightLineUpperVertival['borderRight'] = '3px solid gray'

                                                        }

                                                        console.log('job__', job)
                                                        let rightConnected = {};

                                                        if (job.selected)
                                                            rightConnected['borderTop'] = '3px solid gray'

                                                        let borderLeft = {}

                                                        if (jobTemplate && jobTemplate.version === 'v3') {
                                                            if (item.values.length !== order)
                                                                borderLeft['borderLeft'] = '3px solid gray'
                                                        }
                                                        else {
                                                            if (item.values.length !== order) {
                                                                borderLeft['borderLeft'] = '3px solid gray'

                                                            }
                                                        }

                                                        return (

                                                            <div style={{ display: 'flex', height: 'fit-content' }}>

                                                                <div style={{ width: '30px', height: '85px' }}>

                                                                    <div style={order == 0 ? { height: '35%' } : { height: '35%', borderLeft: '3px solid gray' }}>


                                                                    </div>

                                                                    <div style={{ borderTop: '3px solid gray', height: '65%', ...borderLeft }}>


                                                                    </div>

                                                                </div>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div
                                                                        className='pd-l-10 job-card-design d-grid align-center '
                                                                        style={{ height: '65px', width: '180px', gridTemplateColumns: '25px 85px 55px' }} >
                                                                        <button className="transparent-btn nowrap" onClick={() => onClickedFunction(job, index, item.type)}>
                                                                            {job.selected ? <i className="ri-eye-line font-18" style={{ color: '#3a8cec' }}></i> : <i className="ri-eye-off-line font-18" style={{ color: 'gray' }}></i>}
                                                                        </button>
                                                                        <label className='font-12 text-ellipsis-110' style={{ color: "#284060" }}>{job.job_name}</label>
                                                                        {/* {jobTemplate.version === 'v3' &&
                                                                            <MoreMenu type='job' onDeleteHandler={onDeleteHandler} version={jobTemplate.version} onEditHandler={onEditJob} order={order} layer_index={index} data={job} />
                                                                        } */}

                                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "5px" }}>
                                                                            {job.queue_name && job.queue_name.length > 0 ?
                                                                                <Tooltip title="Custom Queue already configured">
                                                                                    <div style={{
                                                                                        width: "25px",
                                                                                        height: "25px",
                                                                                        backgroundColor: "#82CD47",
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        borderRadius: "5px"
                                                                                    }}>
                                                                                        <p style={{ color: "white" }}>Q</p>
                                                                                    </div>
                                                                                </Tooltip>
                                                                                :
                                                                                <div></div>
                                                                            }
                                                                            <MoreMenu type='job' onDeleteHandler={onDeleteHandler} version={jobTemplate.version} onEditHandler={onEditJob} onQueueNameEdit={onQueueNameEdit} order={order} layer_index={index} data={job} isQueueEnabled={addJobDialogData.isQueueEnabled} />
                                                                        </div>

                                                                        {/* <MoreMenu getStepData={()=>{}} /> */}
                                                                    </div>

                                                                </div>

                                                                <div style={{ width: '30px', height: '85px' }}>

                                                                    <div style={order == 0 ? { height: '35%' } : { height: '35%', ...rightLineUpperVertival }}>


                                                                    </div>

                                                                    <div style={item.values.length === order + 1 ? { height: '65%', ...rightConnected } : { height: '65%', ...rightConnected, ...rightLineVertival }}>


                                                                    </div>

                                                                </div>

                                                            </div>)
                                                    })


                                                }
                                                {

                                                    item.type === 'jobs' &&
                                                    <div style={{ display: 'flex', height: 'fit-content' }}>
                                                        <div style={{ width: '30px', height: '85px' }}>

                                                            <div style={{ height: '35%', borderLeft: `${item.values.length === 0 ? 'none' : '3px solid gray'}` }}>

                                                            </div>

                                                            <div style={{ borderTop: `${item.values.length === 0 ? 'none' : '3px solid gray'}`, height: '65%' }}>

                                                            </div>

                                                        </div>
                                                        <div style={{ display: 'flex', height: 'fit-content' }}>

                                                            <div onKeyDown={() => { }} onClick={() => handleAddJOb()} className='pd-l-10 add-job-card justify-center' style={{ display: 'flex', height: '65px', width: '180px', alignItems: 'center' }} tabIndex={0} role='button' >

                                                                <button className='mr-12 job-template-add-button'>
                                                                    <i className="ri-add-line" style={{ color: '#fff', verticalAlign: "bottom" }} />
                                                                </button>
                                                                <label style={{ color: '#1074e8', cursor: 'pointer' }} className='font-12'>Add New Job</label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                }
                                            </div>

                                        }

                                    </div >
                                }
                                {
                                    item.type === 'steps' &&
                                    <div style={{ display: 'flex', paddingTop: '50px', marginTop: '-25px' }}>
                                        {/* middle */}

                                        <div>

                                            {
                                                item.values && item.values.map((step, order) => {

                                                    let rightLineVertival = {}

                                                    let rightLineUpperVertival = {}

                                                    if (rightLineRange && inRangeCheck(rightLineRange.showRightConnectedLineFrom, rightLineRange.showRightConnectedLineTill, order)) {
                                                        rightLineVertival['borderRight'] = '3px solid gray'

                                                    }

                                                    if (rightLineRange && inRangeCheck(rightLineRange.showRightConnectedLineFrom, rightLineRange.forUpperDiv, order)) {
                                                        rightLineUpperVertival['borderRight'] = '3px solid gray'

                                                    }

                                                    let leftVerticalLines = {}
                                                    if (!isPreviousLayerAlreadyHaveRightLines)
                                                        leftVerticalLines['borderLeft'] = '3px solid gray'

                                                    let rightConnected = {};
                                                    if (step.selected)
                                                        rightConnected['borderTop'] = '3px solid gray'

                                                    return (

                                                        <div style={{ display: 'flex', justifyContent: 'space-between', height: 'fit-content' }}>

                                                            <div style={{ width: '30px', height: '85px' }}>

                                                                <div style={order == 0 ? { height: '35%' } : { height: '35%', ...leftVerticalLines }}>


                                                                </div>

                                                                <div style={{ borderTop: '3px solid gray', height: '65%', ...leftVerticalLines }}>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                <div style={{ width: '30px', height: '85px' }}>

                                                    <div style={isPreviousLayerAlreadyHaveRightLines ? { height: '35%' } : { borderLeft: '3px solid gray', height: '35%' }}>


                                                    </div>

                                                    <div style={{ borderTop: '3px solid gray', height: '65%' }}>


                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        {<div>

                                            < DragDropContext onDragEnd={(result) => onDragEnd({ layer: index, ...result })}>
                                                <Droppable droppableId={`droppable_${index}`}>

                                                    {
                                                        (provided, snapshot) => (
                                                            <div {...provided.droppableProps} ref={provided.innerRef}>

                                                                {

                                                                    item.values && item.values.map((step, order) => {

                                                                        console.log('snapshot__', snapshot)
                                                                        let rightLineVertival = {}

                                                                        let rightLineUpperVertival = {}

                                                                        if (rightLineRange && inRangeCheck(rightLineRange.showRightConnectedLineFrom, rightLineRange.showRightConnectedLineTill, order)) {
                                                                            rightLineVertival['borderRight'] = '3px solid gray'

                                                                        }

                                                                        if (rightLineRange && inRangeCheck(rightLineRange.showRightConnectedLineFrom, rightLineRange.forUpperDiv, order)) {
                                                                            rightLineUpperVertival['borderRight'] = '3px solid gray'

                                                                        }

                                                                        let leftVerticalLines = {}
                                                                        if (!isPreviousLayerAlreadyHaveRightLines)
                                                                            leftVerticalLines['borderLeft'] = '3px solid gray'

                                                                        let rightConnected = {};
                                                                        if (step.selected)
                                                                            rightConnected['borderTop'] = '3px solid gray'

                                                                        return (

                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', height: 'fit-content' }}>

                                                                                {/* <div style={{ width: '30px', height: '85px' }}>

                                                                                        <div style={order == 0 ? { height: '35%' } : { height: '35%', ...leftVerticalLines }}>


                                                                                        </div>

                                                                                        <div style={{ borderTop: '3px solid gray', height: '65%', ...leftVerticalLines }}>


                                                                                        </div>

                                                                                    </div> */}


                                                                                {/* child 1*/}
                                                                                <div>
                                                                                    {
                                                                                        <Draggable key={order} draggableId={`${order}`} index={order}>
                                                                                            {(provided, snapshot) => (
                                                                                                <div

                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}

                                                                                                >
                                                                                                    <div style={{ display: 'flex' }}>
                                                                                                        {/* 
                                                                    <div style={{ width: '30px', borderTop: '1px solid', marginTop: '34px' }}>

                                                                    </div> */}
                                                                                                        <div className='pd-l-10 step-card-design d-grid space-between align-center mb-20'
                                                                                                            style={
                                                                                                                step.is_conditional_step ? { height: '65px', width: '180px', gridTemplateColumns: '25px 90px 50px' } :
                                                                                                                    { height: '65px', width: '180px', gridTemplateColumns: '110px 25px' }} >
                                                                                                            {
                                                                                                                step.is_conditional_step &&
                                                                                                                <button className="transparent-btn nowrap" onClick={() => { onClickedFunction(step, index, item.type) }}>
                                                                                                                    {step.selected ? <i className="ri-eye-line font-18" style={{ color: '#3a8cec' }} ></i> : <i className="ri-eye-off-line font-18" style={{ color: 'gray' }}></i>}

                                                                                                                </button>
                                                                                                            }
                                                                                                            <label className={step.is_conditional_step ? 'font-12 text-ellipsis-80' : 'font-12 text-ellipsis-110'} style={{ color: "#1074e8" }}>{step.step_name}</label>
                                                                                                            <div style={{ display: 'flex' }}>
                                                                                                                {step.is_conditional_step &&
                                                                                                                    <button style={{ height: '20px', width: '25px', backgroundColor: '#ff7800', color: 'white', border: 'none', borderRadius: '3px' }}>C</button>
                                                                                                                }
                                                                                                                <MoreMenu type='step' version={jobTemplate.version} onDeleteHandler={onDeleteHandler} onEditHandler={step.is_conditional_step ? editConditionalStep : onEditNonconditionalStep} order={order} layer_index={index} data={step} />

                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>

                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    }
                                                                                </div>
                                                                                {/* <div style={{ width: '30px', height: '85px' }}>

                                                                                        <div style={order == 0 ? { height: '35%' } : { height: '35%', ...rightLineUpperVertival }}>


                                                                                        </div>

                                                                                        <div style={item.values.length === order + 1 ? { height: '65%', ...rightConnected } : { height: '65%', ...rightConnected, ...rightLineVertival }}>


                                                                                        </div>

                                                                                    </div>                                                                              
                                                                                     */}
                                                                            </div>

                                                                        )
                                                                    })

                                                                }

                                                                {provided.placeholder}
                                                            </div>)

                                                    }

                                                </Droppable>




                                            </DragDropContext >

                                            {

                                                item.type === 'steps' &&

                                                <div style={{ display: 'flex', height: 'fit-content' }}>

                                                    <div onKeyDown={() => { }} onClick={() => redirectToAddStep(item, index)} className='pd-l-10 add-job-card justify-center' style={{ display: 'flex', height: '65px', width: '180px', alignItems: 'center' }} tabIndex={0} role="button" >

                                                        <button className='mr-12 job-template-add-button'>
                                                            <i className="ri-add-line" style={{ color: '#fff', verticalAlign: "bottom" }} />
                                                        </button>
                                                        <label style={{ color: '#1074e8', cursor: 'pointer' }} className='font-12'>Add New Step</label>
                                                    </div>
                                                </div>
                                            }

                                        </div>}

                                        <div>

                                            {
                                                item.values && item.values.map((step, order) => {

                                                    let rightLineVertival = {}

                                                    let rightLineUpperVertival = {}

                                                    if (rightLineRange && inRangeCheck(rightLineRange.showRightConnectedLineFrom, rightLineRange.showRightConnectedLineTill, order)) {
                                                        rightLineVertival['borderRight'] = '3px solid gray'

                                                    }

                                                    if (rightLineRange && inRangeCheck(rightLineRange.showRightConnectedLineFrom, rightLineRange.forUpperDiv, order)) {
                                                        rightLineUpperVertival['borderRight'] = '3px solid gray'

                                                    }

                                                    let leftVerticalLines = {}
                                                    if (!isPreviousLayerAlreadyHaveRightLines)
                                                        leftVerticalLines['borderLeft'] = '3px solid gray'

                                                    let rightConnected = {};
                                                    if (step.selected)
                                                        rightConnected['borderTop'] = '3px solid gray'

                                                    return (

                                                        <div style={{ display: 'flex', justifyContent: 'space-between', height: 'fit-content' }}>

                                                            <div style={{ width: '30px', height: '85px' }}>

                                                                <div style={order == 0 ? { height: '35%' } : { height: '35%', ...rightLineUpperVertival }}>


                                                                </div>

                                                                <div style={item.values.length === order + 1 ? { height: '65%', ...rightConnected } : { height: '65%', ...rightConnected, ...rightLineVertival }}>


                                                                </div>

                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                }

                                {
                                    item.type === 'conditions' &&
                                    <div style={{ display: 'flex', paddingTop: '50px' }}>

                                        <div style={{ marginTop: '-25px' }}>
                                            {/* middle */}

                                            {

                                                item.values && item.values.map((condition, order) => {

                                                    let rightLineVertival = {}

                                                    let rightLineUpperVertival = {}

                                                    if (rightLineRange && inRangeCheck(rightLineRange.showRightConnectedLineFrom, rightLineRange.showRightConnectedLineTill, order)) {
                                                        rightLineVertival['borderRight'] = '3px solid gray'

                                                    }

                                                    if (rightLineRange && inRangeCheck(rightLineRange.showRightConnectedLineFrom, rightLineRange.forUpperDiv, order)) {
                                                        rightLineUpperVertival['borderRight'] = '3px solid gray'

                                                    }

                                                    let leftVerticalLines = {}
                                                    if (!isPreviousLayerAlreadyHaveRightLines)
                                                        leftVerticalLines['borderLeft'] = '3px solid gray'

                                                    let rightConnected = {};
                                                    if (condition.selected)
                                                        rightConnected['borderTop'] = '3px solid gray'

                                                    return (

                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                            <div style={{ width: '30px', height: '85px' }}>

                                                                <div style={order == 0 ? { height: '35%' } : { height: '35%', ...leftVerticalLines }}>


                                                                </div>

                                                                <div style={{ borderTop: '3px solid gray', height: '65%', ...leftVerticalLines }}>


                                                                </div>

                                                            </div>

                                                            {/* child 1*/}
                                                            {

                                                                <div style={{ display: 'flex' }}>

                                                                    <div className='pd-l-10 condition-card-design d-grid'
                                                                        style={{ height: '65px', width: '180px', alignItems: 'center', gridTemplateColumns: '25px 110px 25px' }} >

                                                                        <button className="transparent-btn nowrap" onClick={() => { onClickedFunction(condition, index, item.type) }}>
                                                                            {condition.selected ? <i className="ri-eye-line font-18" style={{ color: '#3a8cec' }}></i> : <i class="ri-eye-off-line font-18" style={{ color: 'gray' }}></i>}
                                                                        </button>

                                                                        <label className='font-12 text-ellipsis-110' style={{ color: '#284060' }}>{condition.value}</label>
                                                                        {/* <MoreMenu /> */}
                                                                    </div>

                                                                </div>
                                                            }
                                                            <div style={{ width: '30px', height: '85px' }}>

                                                                <div style={order == 0 ? { height: '35%' } : { height: '35%', ...rightLineUpperVertival }}>


                                                                </div>

                                                                <div style={item.values.length === order + 1 ? { height: '65%', ...rightConnected } : { height: '65%', ...rightConnected, ...rightLineVertival }}>


                                                                </div>

                                                            </div>                                                                                </div>

                                                    )
                                                })

                                            }

                                            {

                                                item.type === 'conditions' &&

                                                <div style={{ display: 'flex', height: 'fit-content' }}>

                                                    <div style={{ width: '30px', height: '85px' }}>

                                                        <div style={isPreviousLayerAlreadyHaveRightLines ? { height: '35%' } : { borderLeft: '3px solid gray', height: '35%' }}>


                                                        </div>

                                                        <div style={{ borderTop: '3px solid gray', height: '65%' }}>


                                                        </div>

                                                    </div>

                                                    <div onKeyDown={() => { }} onClick={() => editConditionalStep(item, null, 'condition')} className='pd-l-10 add-job-card justify-center' style={{ display: 'flex', height: '65px', width: '180px', alignItems: 'center' }} tabIndex={0} role='button' >

                                                        <button className='mr-12 job-template-add-button'>
                                                            <i className="ri-add-line" style={{ color: 'white' }} />
                                                        </button>
                                                        <span style={{ color: '#1074e8' }} className='font-12'>Add Condition</span>
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                            </>
                        )

                    })
                }
            </div >
            <StepEditTemplate version={nonConditionalStepState?.version} getStepsInCorrectOrder={getStepsInCorrectOrder} stepData={nonConditionalStepState} jobTemplate={jobTemplate} changeParentState={setNonConditionalStepState} updateJobTemplate={updateJobTemplate} />
            <DeleteConfirmMessage onConfirm={onHandleConfirmDelete} onCancel={() => setDeleteCondition(false)} openDialog={deleteCondition} message='Are you sure want to delete this step, it will delete automatically parent condition value.'
            />
            <AddJobToJobTempalte propsData={addJobDialogData} onClose={handleOnCloseAddDialog} updateJobTemplate={updateJobTemplateFromAddDialog} queueList={queueList} version={jobTemplate} />
            {
                addJobDialogData.queueConfirmDialog &&

                <Dialog className={classes.clusterDialog} onClose={handleClose} open={addJobDialogData.queueConfirmDialog}>
                    <div className='container'>
                        <div className='topbar'>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <i className="ri-error-warning-line font-18" style={{ color: "#FFFFFF" }} ></i>
                                <p className='topbar-title'>Important Information</p>
                            </div>
                            <button className="btn btn-transparent" edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <span className='ri-close-line font-24'style={{ color: "#FFFFFF" }}></span>
                            </button>
                        </div>
                        <div className='content-box'>
                            <div className='content-top'>
                                {/* <ErrorOutlineIcon style={{ color: "#007EFF" }} fontSize='large' /> */}
                                <i className="ri-error-warning-line font-18" style={{ color: "#F79327" }}></i>
                               
                                <p className='title'>When a job is executed then a default queue is selected and if you want to run the job on a dedicated/specified queue then proceed.</p>
                            </div>
                            <div style={{ width: "100%", marginTop: "20px" }}>
                                <Input
                                    type="select"
                                    list={addJobDialogData.list}
                                    label={"Select Custom queue"}
                                    name="queue_name"
                                    mandatorySign
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                    data={addJobDialogData.data}
                                    error={addJobDialogData.error}
                                />
                            </div>
                        </div>
                        <div style={{ height: '70px', border: '1px solid #DEDEDE', alignSelf: 'end', padding: '16px' }} className='d-flex align-center space-between'>
                            <button className="btn btn-outline-grey" style={{ padding: '1rem', minWidth: "108px" }} onClick={handleClose} >Cancel</button>
                            <button className="btn btn-primary-v2" style={{ padding: '1rem', minWidth: "108px" }} onClick={handleConfirmQueueChange} >Proceed</button>
                        </div>
                    </div>
                </Dialog>
            }
        </>
    )
}

JobTemplateCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default JobTemplateCard;


const useStyles = makeStyles({
    root: {
        // margin: '20px 32px',
        // '& .card': {
        //     borderRadius: 4,
        //     marginTop: '20px'
        // }

        "& .job-queue-div": {

            "& .input-component": {
                marginBottom: "0px !important"
            }
        }


    },

    clusterDialog: {
        background: 'rgba(0,0,0, 0.3) !important',
        "& .MuiDialog-paper": {
            width: "570px",
        },
        '& .container': {
            // display: 'grid',
            // height: '330px',
            width: '100%',
            // gridTemplateRows: '70% auto',
            "& .topbar": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "58.59px",
                background: "#124D9B",
                padding: "0px 10px",
                "& .topbar-title": {
                    
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                    marginLeft: "10px",
                }
            }
        },
        '& .content-box': {
            padding: '30px',
            paddingTop: "0px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            "& .content-top": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "20px",
                "& .MuiSvgIcon-fontSizeLarge": {
                    fontSize: "45px",
                },
                "& .title": {
                    
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "26px",
                    textAlign: "center",
                    color: "#404040",
                    marginTop: "10px",
                }
            },
            '& .main-header': {
                color: '#FF7B7B',
                marginBottom: '15px'
            },
            '& .info-text': {
                marginTop: "20px",
                
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#404040",
            }
        }
    },


});

const AddButtonCard = (props) => {


    const { message } = props
    return (



        <div>

            <div style={{ display: 'flex' }} className='align-center justify-center'>
                <button>
                    <i className="ri-add-line"></i>
                </button>
                <span>message</span>
            </div>

        </div>


    )

}

AddButtonCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};


