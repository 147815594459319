import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../components/genericComponents/Input';
import { makeStyles } from '@mui/styles';
import { getCommonFunctions } from './SourceDetails';
import { Loading } from '../../../utils/Loading';
import AlertStrip from '../../../../components/AlertStrips';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const BuildX = props => {
  const prev_state = props.prev_state ? props.prev_state : null;
  const data = props.data ? props.data : null;
  const shallow_cloning = props.shallow_cloning
  const depth = props.depth
  const is_add = props.is_add;
  const [state, setState] = useState(prev_state ? prev_state : buildxDefaultState());
  const inherits = props.inherits ? props.inherits : {};
  const commonFunction = getCommonFunctions(state, setState, inherits);
  const classes = useStyles();
  const dialogClasses = useStylesForDialog()
  console.log(prev_state,data,shallow_cloning,depth,is_add,state,"avdgcvghsdvcgvsdgcv")

  useEffect(() => {
    if (is_add) {
      setState((new_state) => ({
        ...new_state,
        data: {
          ...new_state.data,
          shallow_cloning: state.data.buildx_platform_settings && shallow_cloning ? true : false,
          depth: state.data.buildx_platform_settings && shallow_cloning && depth ? depth : null,
        }
      }))
    }
  }, [state.data.buildx_platform_settings])



  function onChangeHandlerForKeyValue(key, value) {
    commonFunction.updateDataKeyVal(key, value);
  }
  console.log(data, "sdjvhgscgsvd")

  function handleShallowCloningToggle(e) {
    console.log(e.target.value, e.target.name, "handleShallowCloningToggle")
    if (!shallow_cloning) {
      setState((new_state) => ({
        ...new_state,
        show_info_flag: true
      }))
    } else {
      setState((new_state) => ({
        ...new_state,
        data: {
          ...new_state.data,
          shallow_cloning: !new_state.data.shallow_cloning
        }
      }))
    }
  }

  function handleCloseConfirmationDialog() {
    setState((new_state) => ({
      ...new_state,
      show_info_flag: false
    }))
  }

  return (
    <>
      <Dialog
        open={state.show_info_flag}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: dialogClasses.dialogPaper }}
      >
        <div className={dialogClasses.header}>
          <p>INFORMATION</p>
          <span className='ri-close-fill' onClick={handleCloseConfirmationDialog} style={{ fontSize: "24px", color: "#2F2F2F", cursor: "pointer" }}></span>
        </div>
        <DialogContent className={dialogClasses.dialogContent}>
          <div className={dialogClasses.warningIconContainer}>
            <span className='ri-information-fill' style={{ color: "#0086FF", fontSize: "32px" }}></span>
          </div>
          <Typography variant="h6" className={dialogClasses.h6Typography}>
            Shallow cloning is Disabled from the BP Settings
          </Typography>
          <Typography variant="body2" gutterBottom className={dialogClasses.body2Typography}>
            In case you want to use this setting please contact your System Admin.
          </Typography>
          <div style={{ marginTop: "24px" }}>
            {/* <Input
              type="text"
              name={state.dialog_type == "approve_all" ? "approve_all_comment" : state.selectedItem}
              label=""
              placeholder="Approval Comment"
              data={state.data}
              error={state.error}
              mandatorySign
              onChangeHandler={commonFunctions.onChangeHandler}
            /> */}
          </div>
        </DialogContent>
        <DialogActions className={dialogClasses.dialogActions}>
          {/* <Button onClick={handleCloseConfirmationDialog} className={dialogClasses.cancelButton}>
            CANCEL
          </Button> */}
          <Button onClick={handleCloseConfirmationDialog} className={dialogClasses.approveButton}>
            OK
          </Button>
        </DialogActions>

      </Dialog>
      <div className={classes.root}>
        <div className="custom-card-header">
          <Input
            type="switch"
            name="buildx_platform_settings"
            label="Advance Configurations"
            data={state.data}
            error={state.error}
            onChangeHandler={commonFunction.toggleState} />

          {
            state.data.buildx_platform_settings &&
            <div style={{ border: '1px solid #dedede', borderRadius: "4px", margin: "0 10px 10px 10px" }}>
              <Input
                type="switch"
                name="buildkit_enabled"
                label="Default Image is generated for AMD 64 Platform/OS"
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunction.toggleState} />
              {
                state.data.buildx_platform_settings && state.data.buildkit_enabled &&
                <div className="pd-10">
                  <div className="mb-15">
                    <p className="font-24">Select Platforms</p>
                    <p className="font-11">Please note we use <span className="text-anchor-blue">BuildX</span>&nbsp; to generate images for different platform to learn more read our <span>Documentation</span></p>
                  </div>
                  <div className="">
                    {
                      state.buildx_platform_list ?
                        state.buildx_platform_list.length == 0 ?
                          <AlertStrip
                            variant="info"
                            dismissible={false}
                            message={"No Platforms Available"}
                            extraClasses="mt-5"
                          />
                          :
                          <>
                            <div className={"pd-10 input-component-mb-0"} style={{ backgroundColor: "#f6f6f6" }}>
                              <Input
                                type="checkbox-list"
                                name="selected_platforms"
                                list={state.buildx_platform_list}
                                data={state.data}
                                error={state.error}
                                onChangeHandler={onChangeHandlerForKeyValue}
                              />
                            </div>
                          </>
                        :
                        state.error ?
                          <AlertStrip
                            variant="info"
                            dismissible={false}
                            message={"Sorry This functionality is not available at the moment."}
                            extraClasses="mt-5"
                          />
                          :
                          <Loading varient="light" />
                    }
                  </div>
                </div>
              }
            </div>
          }
          {
            state.data.buildx_platform_settings &&
            <div style={{ border: '1px solid #dedede', borderRadius: "4px", margin: "0 10px 10px 10px" }}>
              <Input
                type="switch"
                name="shallow_cloning"
                label="Shallow Cloning"
                data={state.data}
                error={state.error}
                onChangeHandler={handleShallowCloningToggle} />
              {
                state.data.shallow_cloning &&
                <div style={{ padding: "0 10px" }}>
                  <Input
                    type="number"
                    name="depth"
                    label="Define the depth(in numbers)"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={commonFunction.onChangeHandler} />
                </div>
              }
            </div>
          }
        </div>
      </div>
    </>
  )
}
BuildX.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default BuildX;

export function buildxDefaultState() {
  return {
    show_info_flag: false,
    data: {
      buildx_platform_settings: true,
    },
    error: {},
    buildx_platform_list: [
      {
        id: 'linux/amd64',
        label: 'linux/amd64'
      },
      {
        id: 'linux/arm64',
        label: 'linux/arm64'
      },
      {
        id: 'linux/386',
        label: 'linux/386'
      },
      {
        id: 'linux/riscv64',
        label: 'linux/riscv64'
      },
      {
        id: 'linux/ppc64le',
        label: 'linux/ppc64le'
      },
      {
        id: 'linux/s390x',
        label: 'linux/s390x'
      },
      {
        id: 'linux/arm/v7',
        label: 'linux/arm/v7'
      },
      {
        id: 'linux/arm/v6',
        label: 'linux/arm/v6'
      }
    ]
  }
}


const useStyles = makeStyles(item => ({
  root: {
    border: '1px solid #dedede'
  },
}));

const useStylesForDialog = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px",
    backgroundColor: "#FAFAFA",
    "& p": {
      
      fontSize: "16px",
      fontWeight: "600",
      color: "#2F2F2F",
    }
  },
  dialogPaper: {
    margin: 0,
    overflow: 'visible',
    width: '655px !important',
    maxWidth: '655px',
  },
  warningIconContainer: {
    width: '56px',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px',
    backgroundColor: '#F5FAFF',
    borderRadius: '6px',
    marginBottom: theme.spacing(2),
    margin: 'auto',
    border: "1px solid #DFEDFF",
    marginTop: "24px",
  },
  warningIcon: {
    color: theme.palette.error.main,
    fontSize: 50,
    marginBottom: theme.spacing(2),
  },
  h6Typography: {
    fontSize: '18px',
    fontWeight: 600,
    color: "#2F2F2F",
    marginTop: "24px",
  },
  body2Typography: {
    fontSize: '14px',
    fontWeight: 500,
    color: "#505050",
    marginTop: "6px",
  },
  dialogContent: {
    textAlign: 'center',
  },
  approveButton: {
    padding: "11px 16px",
    borderRadius: "6px",
    fontSize: '12px',
    fontWeight: 600,
    color: '#FFFFFF',
    backgroundColor: "#0086FF",
  },
  cancelButton: {
    padding: '8px 16px',
    borderRadius: '6px',
    fontSize: '12px',
    fontWeight: 600,
    color: '#2F2F2F',
  },
  dialogActions: {
    height: "72px",
    width: "100%",
    display: 'flex',
    justifyContent: 'flex-end !important',
  },
}));