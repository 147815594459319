import { Dialog, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import Slide from '@mui/material/Slide';
import { styled } from "@mui/system";
import { Link } from 'react-router-dom';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const StyledDialogPaper = styled('div')({
    backgroundColor: '#fff !important',
    width: '450px !important',
    maxWidth: '450px !important',
    marginRight: '0px',
    '& .img-checkbox-icon': {
        border: '1px solid #E8E8E8',
        borderRadius: '6px',
        width: '150px',
        height: '112px',
        overflow: 'hidden',
        '&:hover': {
            borderColor: '#124d9b',
            cursor: 'pointer',
        },
    },
});



const ConfigurationDialog = ({ open, handleOnClickClose, is_default_configured }) => {


    return (
        <>
            <Dialog
                open={open}
                onClose={handleOnClickClose}
                TransitionComponent={Transition}
                PaperProps={{
                    component: StyledDialogPaper,
                }}
                className='integrations-dialog'
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='d-flex align-center space-between' style={{
                    backgroundColor: '#0086ff',
                    padding: '16px'
                }}>
                    <p className='font-18 font-weight-600' style={{ color: '#fff' }}>Add Configuration</p>
                    <IconButton
                        onClick={handleOnClickClose}
                    >
                        <span className='ri-close-line font-24' style={{ color: '#fff' }}></span>
                    </IconButton>
                </div>
                <div className='pd-10'>
                    <p className='font-14 font-weight-600 color-primary mb-16'><span>Select Config type</span><span style={{ color: '#e53737' }}>*</span></p>
                    <div className='pd-10 d-grid' style={{ gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }}>

                        {
                            is_default_configured ?
                                <Tooltip title="A default configuration is already in place. You can update it or delete it to create a new one.">
                                    <div className='img-checkbox-icon cursor-not-allowed'>
                                        <div className='text-center' style={{ padding: '8px 38px', }}>
                                            <span className='ri-settings-4-line font-48' style={{ color: '#0086ff', lineHeight: '1.3' }}></span>
                                        </div>
                                        <div className='' style={{ backgroundColor: '#fafafa', padding: '8px' }}>
                                            <p className='font-12 font-weight-500 text-center d-flex align-center justify-center' style={{ width: '100%', color:'#0086ff' }}>Default Config</p>
                                        </div>
                                    </div>
                                </Tooltip>
                                :
                                <Link to={"/release-package/configuration/default-configuration"
                                } className='img-checkbox-icon'>
                                    <div className='text-center' style={{ padding: '8px 38px', }}>
                                        <span className='ri-settings-4-line font-48' style={{ color: '#0086ff', lineHeight: '1.3' }}></span>
                                    </div>
                                    <div className='' style={{ backgroundColor: '#fafafa', padding: '8px' }}>
                                        <p className='font-12 font-weight-500 text-center d-flex align-center justify-center' style={{ width: '100%' }}>Default Config</p>
                                    </div>
                                </Link>
                        }
                        <Link to={"/release-package/configuration/app-configuration/"} className='img-checkbox-icon'>
                            <div className='text-center' style={{ padding: '8px 38px', }}>
                                <span className='ri-checkbox-multiple-line font-48' style={{ color: '#0086ff', lineHeight: '1.3' }}></span>
                            </div>
                            <div className='' style={{ backgroundColor: '#fafafa', padding: '8px' }}>
                                <p className='font-12 font-weight-500 text-center d-flex align-center justify-center' style={{ width: '100%' }}>App Specific Config</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ConfigurationDialog;

