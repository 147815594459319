import React, { useState } from "react";
import PropTypes from 'prop-types';
import { fontSize, fontWeight, lineHeight, margin, styled, textTransform } from "@mui/system";
import { color } from "highcharts";
import GenericSkeleton from "../../../../components/genericComponents/Skeletons/GenericSkeleton";
import { Link } from "react-router-dom";
import RetryPopup from "../component/RetryPopup";
import { MenuItem, Popover, Tooltip } from "@mui/material";
import Delete from "../../../../components/genericComponents/Delete";
import GenerateURL from "../../../../util/APIUrlProvider";
import properties from "../../../../properties/properties";
import jsPDF from "jspdf";

const getDefaultState = (pipelineName) => {
    const authDataString = JSON.parse(localStorage.getItem("authData"))
    const userId = authDataString?.userDetails?.id
    console.log(userId, 'auth_data_prnmb');
    const listOfFavPipeline = localStorage.getItem('fav_pipelines')
    if (!listOfFavPipeline) {
        return false
    }
    else {
        let pipelineFavlist = JSON.parse(listOfFavPipeline)
        const userList = pipelineFavlist[userId]
        if (!userList) {
            return false
        }
        else {
            return userList.includes(pipelineName)
        }
    }

}

const PipelineCardHeader = ({
    isExpandableView,
    triggerRevoke,
    skeleton,
    triggerPipeline,
    retryPipeline,
    flag_for_feature_id,
    showFeatureTag,
    is_trigger_permited,
    is_edit_permited,
    requestForDelete,
    toggleFunction,
    handleRuntimeParamsForV3,
    variant,
    retriggerPipeline,
    ...props }) => {

    const pipeline = props.pipeline;
    const [anchorEl, setAnchorEl] = useState(null);
    const [favPipelineList, setFavPipelineList] = useState(() => getDefaultState(pipeline?.name))
    const listOfFavPipeline = localStorage.getItem('fav_pipelines')


    if (skeleton) {
        return (

            <div className='d-flex align-center' style={{ gap: "10px" }}>
                <GenericSkeleton variant={"rect"} width={50} height={50} style={{ borderRadius: "8px" }} />
                <div>
                    <GenericSkeleton variant={"text"} width={80} style={{ lineHeight: "2" }} />
                    <GenericSkeleton variant={"text"} width={150} style={{ lineHeight: "1" }} />
                </div>
            </div>

        );
    }
    const pipeline_instance = props.pipeline_instance;
    const application_id = props.application_id;
    console.log(pipeline_instance, 'pp_instance_03')
    const varient = props.varient;
    var start_time = "";
    var end_time = "";
    var difference = "";
    var time_taken = "";
    var trigger_by = "";
    var trigger_type = "";

    if (pipeline_instance) {
        start_time = new Date(pipeline_instance.created_at);
        end_time = new Date(pipeline_instance.updated_at);
        difference = end_time - start_time;
        time_taken = formatTime(difference);
        trigger_by = pipeline_instance.trigger_by ? pipeline_instance.trigger_by : "System";
        trigger_type = `${pipeline_instance.trigger_type.name}${pipeline_instance.trigger_type?.release_version ? ` - Release Package - ${pipeline_instance.trigger_type?.release_version}` : ''}`;
    }
    console.log("jdkjdk", pipeline_instance);

    let alphabet = pipeline.name
    alphabet = alphabet.toUpperCase()
    const logoLabel = alphabet[0]

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleAddedToFavList = (added) => {
        const pipelineName = pipeline.name;
        console.log(pipelineName, 'pipeline name');

        // Get the current user ID from auth data stored in localStorage
        const authData = JSON.parse(localStorage.getItem("authData"));
        const userId = authData?.userDetails?.id;
        console.log(userId, 'user id');

        // Retrieve the existing favorites object from localStorage
        // Expected to be an object where keys are user IDs and values are arrays of pipeline names
        const storedFavPipelines = localStorage.getItem('fav_pipelines');
        const favPipelines = storedFavPipelines ? JSON.parse(storedFavPipelines) : {};

        // Get the user's current list of favorite pipelines, defaulting to an empty array
        let userFavList = favPipelines[userId] || [];

        // Toggle: if pipeline is already a favorite, remove it; otherwise, add it.
        if (userFavList.includes(pipelineName)) {
            // Remove the pipeline
            userFavList = userFavList.filter(name => name !== pipelineName);
        } else {
            // Add the pipeline
            userFavList.push(pipelineName);
        }

        // Update the favorites object and save it back to localStorage
        favPipelines[userId] = userFavList;
        localStorage.setItem('fav_pipelines', JSON.stringify(favPipelines));

        // Toggle the state to trigger a re-render. 
        // (Assuming setFavPipelineList is a state setter that holds a boolean flag.)
        setFavPipelineList(prev => !prev);
    };


    console.log(favPipelineList, 'data_fplm_0ops');


    return <Card>
        <div className="left d-flex align-center">
            <Logo>
                <div className="logo-text">
                    {logoLabel}
                </div>
            </Logo>

            <div className="info-section">
                {
                    variant != 'pipeline-history' &&
                    <div className="pipeline-name">
                        {
                            pipeline_instance ?
                                <Link className=" text-width-300" to={
                                    application_id ?
                                        "/application/" + application_id + "/pipeline/" + pipeline_instance.pipeline_id + "/execution/" + pipeline_instance.id
                                        :
                                        "/global/pipeline/" + pipeline_instance.pipeline_id + "/execution/" + pipeline_instance.id

                                } >{pipeline.name}</Link>
                                :
                                <Link className=" text-width-300" to={
                                    application_id ?
                                        "/application/" + application_id + "/pipeline/" + pipeline.id + "/edit"
                                        :
                                        "/global/pipeline/" + pipeline.id + "/edit"
                                }>{pipeline.name}</Link>
                        }

                    </div>
                }

                {
                    (isExpandableView || variant == 'pipeline-history') &&
                    <div className="info-bottom d-flex">
                        <div>
                            <span style={{ color: "#505050", fontSize: '16px', marginRight: '5px' }} className="ri-calendar-check-line">

                            </span>
                            <span className="meta-info">{start_time.toString().substring(0, start_time.toString().indexOf("GMT"))}</span>
                        </div>
                        <div>
                            <span style={{ color: "#505050", fontSize: '16px', marginRight: '5px' }} className="ri-timer-line">

                            </span>
                            <span className="meta-info">{time_taken}</span>
                        </div>
                        <div>
                            <span style={{ color: "#505050", fontSize: '16px', marginRight: '5px' }} className="ri-user-3-line"></span>
                            <span className="meta-info">By {trigger_by + " ( " + trigger_type + " )"}</span>
                        </div>
                    </div>
                }

            </div>

        </div>
        <div style={{ gap: '16px' }} className="right d-flex align-center">
            {
                variant != 'pipeline-history' &&
                <div

                    style={{ display: "inline-block", cursor: 'pointer' }}
                >

                    {favPipelineList ?
                        <img onClick={() => handleAddedToFavList(false)} style={{ width: "21px", height: '21px' }} src={"/images/star.svg"} />
                        :
                        <span onClick={() => handleAddedToFavList(true)} style={{ fontSize: '20px', color: '#787878' }} className="ri-star-line">
                        </span>

                    }

                </div>
            }

            {
                variant == 'pipeline-history' &&
                <Link style={{ fontSize: '12px', color: 'rgb(0, 134, 255)', marginLeft: '5px' }} to={"/application/" + application_id + "/pipeline/" + pipeline_instance.pipeline_id + "/execution/" + pipeline_instance.id}>

                    <ViewDetails>
                        <span className="revoke-text">
                            View Details
                        </span>
                    </ViewDetails>
                </Link>
            }

            <>

                {
                    variant != 'pipeline-history' ?
                        <>
                            {pipeline.last_trigger ? (
                                pipeline.last_trigger.status == "RUNNING" ||
                                    pipeline.last_trigger.status == "IN_QUEUE" ||
                                    pipeline.last_trigger.status == "PENDING_APPROVAL" ? (
                                    <>
                                        <Revoke onClick={() =>
                                            triggerRevoke(pipeline.last_trigger.pipeline_id, pipeline_instance.id)}>
                                            <span style={{ color: '#505050', fontSize: '16px' }} className="ri-stop-circle-line">

                                            </span>

                                            <span className="revoke-text">
                                                revoke
                                            </span>
                                        </Revoke>

                                    </>
                                ) : null
                            ) : null}
                        </> :

                        <>
                            <>
                                {
                                    pipeline_instance.status == "RUNNING" || pipeline_instance.status == "IN_QUEUE" ?

                                        <Revoke onClick={() => { triggerRevoke(pipeline_instance.pipeline_id, pipeline_instance.id) }}>
                                            <span style={{ color: '#505050', fontSize: '16px' }} className="ri-stop-circle-line">

                                            </span>

                                            <span className="revoke-text">
                                                revoke
                                            </span>
                                        </Revoke>

                                        : null
                                }
                            </>
                        </>
                }

            </>
            {
                variant == 'pipeline-history' &&
                <RetryPopup
                    pipeline={pipeline}
                    application_id={application_id}
                    pipeline_instance={pipeline_instance}
                    variant="execution_history"
                    retryPipeline={retriggerPipeline}
                />

            }

            {
                variant != 'pipeline-history' && pipeline.default_run === false && <>
                    {is_trigger_permited ? (
                        <>

                            <Trigger onClick={
                                flag_for_feature_id
                                    ? showFeatureTag
                                    : () => {
                                        triggerPipeline(pipeline.id);
                                    }
                            }>
                                <span style={{ color: '#505050', fontSize: '16px' }} className="ri-play-mini-line">

                                </span>

                                <span className="revoke-text">
                                    Trigger
                                </span>
                            </Trigger>
                            {
                                pipeline && pipeline.last_trigger &&
                                <RetryPopup
                                    pipeline={pipeline}
                                    retryPipeline={retryPipeline} />
                            }

                        </>
                    ) :
                        <>
                            <Tooltip title="You are not allowed to perform this action">
                                <Trigger onClick={() => { }}
                                >
                                    <span style={{ color: '#505050', fontSize: '16px' }} className="ri-play-mini-line">

                                    </span>

                                    <span className="revoke-text">
                                        Trigger
                                    </span>
                                </Trigger>
                            </Tooltip>
                        </>

                    }

                </>
            }

            {
                variant != 'pipeline-history'
                &&
                pipeline.default_run == true
                &&
                <>
                    {is_trigger_permited ? (
                        <>

                            <RunWithParaTrigger onClick={() => {
                                pipeline?.version == 'v2' ?
                                    toggleFunction() :
                                    handleRuntimeParamsForV3()
                            }}>
                                <span style={{ color: '#505050', fontSize: '16px' }} className="ri-play-mini-line">

                                </span>

                                <span className="revoke-text">
                                    Run with Parameters
                                </span>
                            </RunWithParaTrigger>
                            {
                                pipeline && pipeline.last_trigger &&
                                <RetryPopup
                                    pipeline={pipeline}
                                    retryPipeline={retryPipeline} />
                            }

                        </>
                    ) :
                        <>
                            <Tooltip title="You are not allowed to perform this action">
                                <RunWithParaTrigger onClick={() => { }}
                                >
                                    <span style={{ color: '#505050', fontSize: '16px' }} className="ri-play-mini-line">

                                    </span>

                                    <span className="revoke-text">
                                        Run with Parameters
                                    </span>
                                </RunWithParaTrigger>
                            </Tooltip>
                        </>

                    }

                </>
            }


            {
                variant != 'pipeline-history' &&
                <>
                    <button
                        className="transparent-btn nowrap"
                        onClick={handleClick}
                    >
                        <i className="ri-more-2-fill" style={{ color: '#505050', fontSize: '20px' }}></i>
                    </button>
                    <Popover
                        anchorEl={anchorEl}
                        id="long-menu"
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: '200px',
                                width: "180px",
                                backgroundColor: "#FFF",
                            },
                        }}
                    >
                        <div>
                            <MenuItem
                                style={{ padding: '0px' }}
                            >
                                {is_edit_permited ?
                                    <Link style={{ color: 'black', width: '100%' }} to={application_id ? `/application/${application_id}/pipeline/${pipeline.id}/edit` : `/global/pipeline/${pipeline.id}/edit`}>
                                        <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>
                                            <i className="ri-edit-2-line font-15" style={{ marginRight: "8px", color: "#3696db" }}></i>

                                            <span style={{ fontSize: "14px", cursor: "pointer", color: '#4e4b4b' }}>Edit Pipeline </span>
                                        </div>
                                    </Link>
                                    :
                                    <Tooltip title="You are not allowed to perform this action">

                                        <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>
                                            <i className="ri-edit-2-line font-15" style={{ marginRight: "8px", color: "#3696db" }}></i>

                                            <span style={{ fontSize: "14px", cursor: "pointer", color: "#4e4b4b" }}>Edit Pipeline </span>
                                        </div>
                                    </Tooltip>
                                }

                            </MenuItem>
                            <MenuItem
                                style={{ padding: '0px' }}
                                onClick={() => { }}
                            >
                                <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>

                                    {
                                        is_edit_permited ?

                                            <Delete
                                                display_data_name={pipeline.name}
                                                varient="pipeline-card"
                                                data={{ entity_id: pipeline.id, name: "pipeline" }}
                                                refresh={requestForDelete}
                                                api_link={GenerateURL(
                                                    { pipeline_id: pipeline.id },
                                                    properties.api.pipeline_get_url
                                                )}
                                                is_edit_permited={is_edit_permited}
                                            />

                                            :
                                            <>
                                                <Tooltip title="You are not allowed to perform this action">
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <i
                                                            className="ri-delete-bin-line font-16"
                                                            style={{ color: "rgb(255, 137, 105)", marginRight: "4px" }}
                                                        ></i>
                                                        <label style={{ fontSize: "14px", cursor: "pointer" }}>Delete Pipeline</label>
                                                    </div>

                                                </Tooltip>
                                            </>
                                    }
                                </div>
                            </MenuItem>
                            {
                                pipeline.default_run === false &&
                                <>
                                    {is_trigger_permited ? (
                                        <>
                                            <MenuItem
                                                style={{ padding: '0px' }}
                                                onClick={() => {
                                                    pipeline?.version == 'v2' ?
                                                        toggleFunction() :
                                                        handleRuntimeParamsForV3()
                                                }}
                                            >
                                                <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%", cursor: "pointer", }}>
                                                    <span className="ri-arrow-right-double-fill" style={{ fontSize: "18px" }}></span>
                                                    <span style={{ fontSize: "12px", marginLeft: "5px" }}>Run With Parameters</span>
                                                </div>

                                            </MenuItem>

                                        </>
                                    ) : null}
                                </>
                            }



                        </div>

                    </Popover>

                </>
            }

        </div>
    </Card>
}

export default PipelineCardHeader;

PipelineCardHeader.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

PipelineCardHeader.defaultProps = {
    triggerRevoke: () => { }, // Default function
    skeleton: false, // Default boolean
    application_id: '', // Default string
    pipeline: null, // Default null
    pipeline_instance: null, // Default null
    isExpandableView: false, // Default boolean
    triggerPipeline: () => { }, // Default function
    retryPipeline: () => { }, // Default function
    flag_for_feature_id: '', // Default string
    showFeatureTag: false, // Default boolean
    is_trigger_permited: false, // Default boolean
    is_edit_permited: false, // Default boolean
    requestForDelete: () => { }, // Default function
    toggleFunction: () => { }, // Default function
    handleRuntimeParamsForV3: () => { }, // Default function,
    retriggerPipeline: () => { }
}

function formatTime(time_in_miliseconds) {
    var time_in_seconds = time_in_miliseconds / 1000;
    if (time_in_seconds < 60) {
        return Math.round(time_in_seconds) + " sec";
    }

    var time_in_minutes = time_in_seconds / 60;
    if (time_in_minutes < 60) {
        return Math.round(time_in_minutes) + " min";
    }

    var time_in_hour = time_in_minutes / 60;
    if (time_in_hour < 60) {
        return Math.round(time_in_hour) + " hr";
    }
}


const Card = styled("div")({
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .info-section': {
        '& .pipeline-name': {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '19.5px',
            'a': {
                color: '#2F2F2F',
                '&:hover': {
                    color: "#0086ff"
                }
            }
        },
        '& .info-bottom': {
            gap: '16px',
            '& .meta-info': {
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '14.63px',
                color: '#505050'
            }
        }

    }
});

const Logo = styled("div")({
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    background: '#F4F4F4',
    marginRight: '16px',
    border: '1px solid #E6E6E6',
    '& .logo-text': {
        color: '#505050',
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '48px',
        textAlign: 'center',
    }
});

const Revoke = styled("div")({
    width: '103px',
    height: '32px',
    padding: '8px 12px',
    borderRadius: '6px',
    background: '#F4F4F4',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: 'pointer',
    '&:hover': {
        background: '#dedcdc',
    },
    '& .revoke-text': {

        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '14.63px',
        textAlign: 'center',
        color: '#505050',
        textTransform: 'uppercase'
    }
});

const Trigger = styled("div")({
    width: '106px',
    height: '32px',
    padding: '8px 12px',
    borderRadius: '6px',
    background: '#F4F4F4',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: 'pointer',
    '&:hover': {
        background: '#dedcdc',
    },
    '& .revoke-text': {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '14.63px',
        textAlign: 'center',
        color: '#505050',
        textTransform: 'uppercase'
    }
});
const RunWithParaTrigger = styled("div")({
    width: '205px',
    height: '32px',
    padding: '8px 12px',
    borderRadius: '6px',
    background: '#F4F4F4',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: 'pointer',
    '&:hover': {
        background: '#dedcdc',
    },
    '& .revoke-text': {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '14.63px',
        textAlign: 'center',
        color: '#505050',
        textTransform: 'uppercase'
    }
});
const ViewDetails = styled("div")({
    width: '115px',
    height: '32px',
    padding: '8px 12px',
    borderRadius: '6px',
    background: '#F4F4F4',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: 'pointer',
    '&:hover': {
        background: '#dedcdc',
    },
    '& .revoke-text': {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '14.63px',
        textAlign: 'center',
        color: '#505050',
        textTransform: 'uppercase'
    }
});
