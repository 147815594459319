import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import '../../../../../assets/style.css';
import {Input} from '../../../../../components/genericComponents/Input';
import Grid from '@mui/material/Grid';
import ParseFile from "../../../../../util/FileParser";
import PageHeader from '../../Components/PageHeader';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1 ,
      marginTop:'3rem'  
    }
  }));


const config_list=[
  {value:true,label:"VCS(Git)"},
  {value:false,label:"File Upload"}
];

const ConfigurationManagement =(props) => {
  const classes = useStyles();
  const data = props.data;
  const error = props.error;
  const update_data_callback = props.update_data_callback;
  const onError = (error) => {
    console.log("error",error);
  }

  const onParseComplete = (file_data)=>{
    var properties_files = data.properties_files ? data.properties_files : [];
    properties_files.push(file_data);
    update_data_callback("properties_files",properties_files);
  }

  const removePropertiesFile = (i) => {
    var properties_files = data.properties_files;
    properties_files.splice(i,1);
    update_data_callback("properties_files",properties_files.length > 0 ? properties_files : null);
  }

  const handleCPUSliderChange =(e,value)=>{
    if(e.target.type === "text"){
      if(e.target.value <= 100){
        update_data_callback(e.target.name, Number(e.target.value) ? Number(e.target.value) : "");
      }
    }else{
      if(value <= 100){
        update_data_callback("cpu_threshold",Number(value) ? Number(value) : "");
      }
    }
  }

  const handleRAMSliderChange =(e,value)=>{
    if(e.target.type === "text"){
      if(e.target.value <= 100){
        update_data_callback(e.target.name, Number(e.target.value) ? Number(e.target.value) : "");
      }
    }else{
      if(value <= 100){
        update_data_callback("ram_threshold",Number(value) ? Number(value) : "");
      }
    }
  }

  const onChangeHandler = (e) => {
    switch(e.target.name){
      case "config_management":
        update_data_callback(e.target.name,!data.config_management);
        break;

      case "env_scale":
        update_data_callback(e.target.name,!data.env_scale);
        break;
      case "vcs":
        update_data_callback(e.target.name,e.target.value === 'true');
        break;
      case "properties_files":
        ParseFile(e,onParseComplete,onError);
        break;
      case "minimum_replication":
        update_data_callback(e.target.name, Number(e.target.value) ? Number(e.target.value) : "");
        break;
      case "maximum_replication":
        update_data_callback(e.target.name, Number(e.target.value) ? Number(e.target.value) : "");
        break;
      default :
        update_data_callback(e.target.name,e.target.value);
    }
  }
  
  return(
    <>
<PageHeader title="Service Configuration and Scalability" service_name={props.service_name} env_name={props.env_name} sub_env_name={props.sub_env_name} />
<div className={classes.formDiv}>
    <Grid container justify="center">
    <div className='fromClass'>
      <Grid lg={12}>
        <Grid container justify="center">
          <Grid lg={12} direction="row" style={{padding:'10px'}}>
          <div className='card'>
            <div className='heading'>
              
              <Input 
                type="switch" 
                name="config_management" 
                label="Do you manage configurations externally?"
                onChangeHandler={onChangeHandler} 
                data={data} 
                error={error}/>

            </div>

            {
              data.config_management ?
              <div className='card-body-flex'>
                <Grid container justify="center">
                  <Grid lg={12} direction="row" className="input-radio-d-flex">
                      
                      <Input 
                      type="radio" 
                      name="vcs" 
                      label="Ship your configuration files via"
                      list={config_list} 
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>

                  </Grid>
                    {
                      !data.vcs ?
                        <Grid lg={12} direction="row" className="pd-2">
                          
                          <Input
                          type="file"
                          name="properties_files"
                          label={<>Please upload the configuration files <span className="hint-text">(You can upload multiple files)</span></>}
                          onChangeHandler={onChangeHandler}
                          data={data}
                          onFileRemove={removePropertiesFile}
                          error={error}/>
                          
                          <Input
                          type="text" 
                          label="What is the Destination Path of your configuration file"
                          placeholder="path of properties files in pods(/etx/demo-app)" 
                          name="destination_path" 
                          error={error} 
                          onChangeHandler={onChangeHandler} 
                          data={data}/>

                    </Grid> : 

                    <>
                      <Grid lg={6} direction="row">
                    <div className='section'>
                          <Input
                          label="Git URL"
                          type="text" 
                          placeholder="https://github.com/opstree/Opstree-Go-WebApp.git" 
                          name="config_repo_url" 
                          error={error} 
                          onChangeHandler={onChangeHandler} 
                          data={data}/>
                      </div>
                      </Grid>
                      <Grid lg={6} direction="row">
                      <div className='section'>
                          <Input
                          label="Branch"
                          type="text" 
                          placeholder="Master" 
                          name="config_repo_branch" 
                          error={error} 
                          onChangeHandler={onChangeHandler} 
                          data={data}/>
                      </div> 
                    </Grid>
                    <Grid lg={6} direction="row">
                      <div className='section'>
                          <Input
                          type="text" 
                          label="Source path &amp; File path"
                          placeholder="etc/config.d/ot-go-webapp" 
                          name="source_path_with_file_name" 
                          error={error} 
                          onChangeHandler={onChangeHandler} 
                          data={data}/>
                      </div> 
                    </Grid> 
                    <Grid lg={6} direction="row">
                      <div className='section'>
                          <Input
                          type="text" 
                          label="Destination Path"
                          placeholder="etc/config.d/ot-go-webapp" 
                          name="destination_path" 
                          error={error} 
                          onChangeHandler={onChangeHandler} 
                          data={data}/>
                      </div> 
                    </Grid>
                    </>
                    
                    } 
                      
                </Grid>
            </div> 
              :null
            }
            </div>
            
            
          </Grid>
          <Grid lg={12} direction="row" style={{padding:'10px'}}>
          <div className='card'>
            <div className='heading'>
              <Input 
                type="switch" 
                name="env_scale" 
                label="Want your service to be auto scalable?"
                onChangeHandler={onChangeHandler} 
                data={data} 
                error={error}/>
            </div>
            {
              data.env_scale?
              <div className='card-body'>
              <Grid container justify="center">
                  <Grid lg={2} direction="row">
                    <div className='section'>
                    <Input 
                      type="text" 
                      name="minimum_replication" 
                      label="Minimum Replication"
                      min={1}
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>
                      </div>
                    </Grid>
                      <Grid lg={2} direction="row">
                      <div className='section'>
                      <Input 
                        type="text" 
                        name="maximum_replication" 
                        label="Maximum Replication"
                        onChangeHandler={onChangeHandler} 
                        data={data} 
                        error={error}/>
                      </div>
                      </Grid> 
                      <Grid lg={4} direction="row">
                        <div className='section'>
                              
                        <Input
                          type="slider"
                          name="cpu_threshold"
                          label="CPU Threshold (0-100 units)"
                          onChangeHandler={handleCPUSliderChange} 
                          data={data}
                          error={error}
                        />
                              
                        </div> 
                        </Grid>
                        <Grid lg={4} direction="row">
                        <div className='section'>
                          <Input
                            type="slider"
                            name="ram_threshold"
                            label="RAM Threshold (0-100 mb)"
                            onChangeHandler={handleRAMSliderChange}
                            data={data}
                            error={error}
                          />
                        </div> 
                      </Grid>
              </Grid>
            </div>:null

            }
        </div>
      </Grid>
            
      

        </Grid>
      </Grid>
    </div>
  </Grid> 

      
      </div> 
      
    </>
  );
};

ConfigurationManagement.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default ConfigurationManagement;


