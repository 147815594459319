import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import CommonHorizontalTab from '../../../../../../components/genericComponents/CommonHorizontalTab';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import InvokeApi from '../../../../../../util/apiInvoker';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';

const CiHooksOverview = (props) => {
    const hooks_details = props.hooks_details
    const hooks_data = props.hooks_data;
    const env_ci_hook = hooks_data.env_ci_hook;
    const command = hooks_data.command;
    const manifest_file_paths = hooks_data.manifest_file_paths;
    const action_type = hooks_data.action_type;
    const application_id = props.application_id;
    const service_id = props.service_id;
    const component_env_id = props.component_env_id;
    const ci_id = props.ci_id ? props.ci_id : null;
    const secondary_build = props.secondary_build;
    const [maskValue, SetMaskValue] = useState({ showValue: true })
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: ci_id }, properties.api.edit_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);
    const parent_ci_data = props.parent_ci_data

    useEffect(() => {
        loadValueVisibilityStatus()
    }, [])

    const handleToggle = () => {
        setState({
            ...state,
            showValue: !state.showValue,
        })
    }
    const [state, setState] = useState({
        selectedTabOrderTwo: 1,
    });


    const tabListCICD = [
        {
            order: 1,
            tabName: 'Pre Hooks'
        },
        {
            order: 2,
            tabName: 'Post Hooks'
        },
    ]

    const updateSelectedTabTwo = (selectedTabOrderTwo) => {
        setState({
            ...state,
            selectedTabOrderTwo: selectedTabOrderTwo
        });
    }

    function loadValueVisibilityStatus() {
        console.log(properties.api, 'p_1_viii')
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.getCommandHookVisibility),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        console.log('req_0001', requestInfo);
        InvokeApi(requestInfo, onSuccess, onFailure);

    }

    function onSuccess(response) {

        if (response) {
            let data = response['detail']
            setState(new_state => ({
                ...new_state,
                showValue: !data['hook_command_hidden'],
            }));
        }

    }

    function onFailure(error) {
        setState(new_state => ({
            ...new_state,
            error_msg: "Error fetching data of this template " + " " + error
        }));
    }


    return (
        <Grid item lg={6}>
            <div className="section-service-overview">
                <div className="overview-single-card kv-border">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Hook Variables
                        </p>
                        {
                            is_permitted ?
                            <Link
                            to={{
                              pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/ci/${parent_ci_data?.id}/edit`,
                              search: `?selectedTabOrder=3&selected_ci_id=${hooks_data.id}&secondary_build=${secondary_build}&parent_ci_id=${parent_ci_data?.id}`,
                            }}
                            state= {{ ...props.extraProps }}
                          >
                            <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                          </Link>
                          
                                :
                                <Tooltip title="You are not allowed to perform this action" >
                                    <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                                </Tooltip>
                        }

                    </div>

                    <div className="overview-card-value-env-var">
                        <div className="heading-key-value  d-flex space-between align-center">
                            <CommonHorizontalTab
                                tabList={tabListCICD}
                                selectedTabOrder={state.selectedTabOrderTwo}
                                updateSelectedTab={updateSelectedTabTwo} />

                            {/* <>
                                {env_ci_hook ?
                                    <span className="ml-auto d-inline-block" onClick={handleToggle}>{state.showValue ?
                                        <i className="ri-eye-line" style={{ color: '#ccc', fontSize: '20px' }} /> :
                                        < i className="ri-eye-off-line" style={{ color: '#ccc', fontSize: '20px' }} />}
                                    </span>
                                    : null}
                            </> */}
                        </div>
                        {state.selectedTabOrderTwo == 1 ?
                            <div className="env-variable-box">
                                {env_ci_hook && env_ci_hook.length > 0 ?
                                    <>
                                        {env_ci_hook.map(prehook => (
                                            prehook.action_type == "PRE" ?
                                                <>
                                                    <>
                                                        {prehook.command ? 

                                                            <p className="data-key-value-pair overview-card-value-text">
                                                                <span>Step: {prehook.order} </span>Command:&nbsp;<Tooltip placement="bottom" title={state.showValue ? prehook.command : ""}><span className={!state.showValue ? "text-security" : null}> {prehook.command}</span></Tooltip>
                                                            </p>
                                                        
                                                            : null}
                                                    </>
                                                    <>
                                                        {prehook.manifest_meta_data ?
                                                            <>
                                                                {prehook.manifest_meta_data.strategy == "GIT_MANIFEST" ?
                                                                    

                                                                        <p className="data-key-value-pair overview-card-value-text"><span>Step: {prehook.order} </span>Via Git:&nbsp;<span className="overview-card-value-text">{prehook.manifest_meta_data.manifest_file_paths}</span></p>
                                                                    
                                                                    : null}
                                                            </>
                                                            : null}
                                                    </>
                                                    <>
                                                        {prehook.manifest_meta_data ?
                                                            <>

                                                                {prehook.manifest_meta_data.strategy == "UPLOADED_MANIFEST" ?
                                                                    
                                                                        <p className="data-key-value-pair overview-card-value-text"><span>Step: {prehook.order} </span>Via File Upload:&nbsp;<span className="overview-card-value-text">{prehook.manifest_meta_data.manifest_file_paths}</span></p>
                                                                    
                                                                    : null}
                                                            </>
                                                            : null}
                                                    </>
                                                </>
                                                : null
                                        ))}
                                    </> : <p className="no-data__msg-env-var">No Data Available</p>}
                            </div> :
                            state.selectedTabOrderTwo == 2 ?
                                <div className="env-variable-box">
                                    {env_ci_hook.length > 0 ?
                                        <>
                                            {env_ci_hook.map(posthook => (
                                                posthook.action_type == "POST" ?
                                                    <>
                                                        <>
                                                            {posthook.command ?
                                                                
                                                                    <p className="data-key-value-pair overview-card-value-text"><span>Step: {posthook.order} </span>Command:&nbsp;<Tooltip placement="bottom" title={state.showValue ? posthook.command : ""}><span className={!state.showValue ? "text-security" : null}>{posthook.command}</span></Tooltip></p>
                                                                
                                                                : null}
                                                        </>
                                                        <>
                                                            {posthook.manifest_meta_data ?
                                                                <>
                                                                    {posthook.manifest_meta_data.strategy == "GIT_MANIFEST" ?
                                                                        

                                                                            <p className="data-key-value-pair overview-card-value-text"><span>Step: {posthook.order} </span>Via Git:&nbsp;<span className="overview-card-value-text">{posthook.manifest_meta_data.manifest_file_paths}</span></p>
                                                                    
                                                                        : null}
                                                                </>
                                                                : null}
                                                        </>
                                                        <>
                                                            {posthook.manifest_meta_data ?
                                                                <>
                                                                    {posthook.manifest_meta_data.strategy == "UPLOADED_MANIFEST" ?
                                                                        

                                                                            <p className="data-key-value-pair overview-card-value-text"><span>Step: {posthook.order} </span>Via File Upload:&nbsp;<span className="overview-card-value-text">{posthook.manifest_meta_data.manifest_file_paths}</span></p>
                                                                        
                                                                        : null}
                                                                </>
                                                                : null}
                                                        </>
                                                    </>
                                                    : null
                                            ))}
                                        </> : <p className="no-data__msg-env-var">No Data Available</p>}
                                </div> : null}
                    </div>
                </div>
            </div>
        </Grid>
    )
}

CiHooksOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default CiHooksOverview;