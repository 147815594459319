import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const AccessLevelStrip = (props) => {
    const access_level_data = props.access_level_data ? props.access_level_data : null;
    const handleEditData = props.handleEditData ? props.handleEditData : ()=>{}
    const handleDeleteRow = props.handleDeleteRow ?props.handleDeleteRow : ()=>{}
  return (
    <div className='single-row-access-level'>
    <div className="single-row-item">
      <div className="title-heading">
        Name
      </div>
      <div className='heading-value'>
        {access_level_data.name}
      </div>
    </div>
    <div className="single-row-item">
      <div className="title-heading">
        Ingress Type
      </div>
      <div className='heading-value'>
        {access_level_data.access_level}
      </div>
    </div>
    <div className="single-row-item">
      <div className="title-heading">
        Url
      </div>
      <div className='heading-value'>
        {access_level_data.expose_url}
      </div>
    </div>
    <div className="single-row-item">
      <div className="title-heading">
        Expose Path
      </div>
      <div className='heading-value'>
        {access_level_data.expose_path ? access_level_data.expose_path : "N/A"}
      </div>
    </div>
    <div className="single-row-item">
      <div className="title-heading">
        Port
      </div>
      <div className='heading-value'>
        {access_level_data.port ? access_level_data.port : "N/A"}
      </div>
    </div>
    <div className="single-row-item">
      <div className="title-heading">
        Target Port
      </div>
      <div className='heading-value'>
        {access_level_data.target_port ? access_level_data.target_port : "N/A"}
      </div>
    </div>
    <div className="single-row-item">
    <SimpleDialogAccessLevel 
    handleEditData = {handleEditData } 
    handleDeleteRow = {handleDeleteRow} />
    </div>
  </div>
  )
}
AccessLevelStrip.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default AccessLevelStrip;


function SimpleDialogAccessLevel(props) {

    const ITEM_HEIGHT = 48;
    const [openDialogue, setOpenDialogue] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const handleEditData = props.handleEditData ? props.handleEditData : ()=>{}
    const handleDeleteRow = props.handleDeleteRow ? props.handleDeleteRow : ()=>{}
    const handleClickOpenDialogue = () => {
        setOpenDialogue(true);
    };
    const handleCloseDialogue = () => {
        setOpenDialogue(false);
    };
    const options = [
        {
            icon: <span className="ri-edit-line"  style={{color:"#33abe2", fontSize: "24px"}}></span>,
            label: 'Edit Access Level',
            link: ""
        },
    ]
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    function deleteSucess(){
        setRefresh(true)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };
  
  
  
    return (
        <>
            <button className="btn btn-transparent d-flex align-center"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ marginLeft: 'auto', display: 'block' }}>
                    <i className="ri-more-2-fill mr-5 font-18"></i>
                    {/* <SettingsIcon fontSize="large" className="mr-5" /> Settings <KeyboardArrowDownIcon fontSize="large" className="ml-5" /> */}
            </button> 
          
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
               
                <MenuItem className="menu-item font-12 text-grey-83" onClick={handleEditData}>
                  <span className="ri-edit-line text-anchor-blue" style={{fontSize: "24px"}}></span>&nbsp;Edit
                </MenuItem>
                <MenuItem className="menu-item font-12 text-grey-83" onClick = {handleDeleteRow}>
                    <i className={`ri-delete-bin-line`} fontSize="default" style={{color:'rgb(255, 137, 105)'}}></i> &nbsp;Delete
                </MenuItem>
            </Menu>
        </>
    );
  }
  SimpleDialogAccessLevel.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }