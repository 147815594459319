import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Input } from '../../../components/genericComponents/Input';
import InputSkeleton from '../../../components/genericComponents/Skeletons/InputSkeleton';
import GenerateURL from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi from '../../../util/apiInvoker';
import { showErrorHandlerUpdated } from '../../../util/util';
import AlertStrip from '../../../components/AlertStrips';
import { Link } from 'react-router-dom';

const SelectApplicationForSpirntApprove = () => {
    const classes = useStyles();
    const [state, setState] = useState({
        data: {},
        error: {},
        loading_application: false,
        application_list: [],
        
    })
    const onChangeHandler = (e) => {
        let k = e.target.name;
        let v = e.target.value
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v
            },
            error: {
                ...new_state.error,
                [k]: ""
            }
        }))
    }

    useEffect(() => {
        fetchAllApplications()
    }, [])


    function fetchAllApplications() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.side_panel_data),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchAllApplicationsSuccess, fetchAllApplicationsFailed);

        setState(new_state => ({
            ...new_state,
            loading_application: true
        }))

    }

    function fetchAllApplicationsSuccess(data) {
        let app_list = data.map(item => {
            return { label: item.name, id: item.name }
        })
        setState(new_state => ({
            ...new_state,
            application_list: app_list,
            loading_application: false,

        }));

    }
    function fetchAllApplicationsFailed(error) {
        let error_msg = showErrorHandlerUpdated(error)
        setState(new_state => ({
            ...new_state,
            error: error_msg,
            loading_application: false
        }))
    }

  
    return (
        <div className={classes.root}>
            <div className='application-selection'>
                <div className='sq-avtar-one d-flex align-center justify-center mb-16 m-auto'
                    style={{
                        width: '56px',
                        height: '56px',
                        borderRadius: '6px',
                        backgroundColor: '#EBF5FF',

                    }}
                >
                    <img src='/images/logos/sprint-icon.svg' alt=".." />
                </div>
                <p className='font-18 font-weight-600 mb-16' style={{ color: '#505050' }}>Please select application to approve sprint</p>
                <p className='font-14 font-weight-500 mb-16 ' style={{ color: '#505050' }}>
                Buildpiper provides the means to authorize your production sprints. 
                To begin the Sprint approval procedure, select the desired application from the list or click on continue.
                </p>
                {
                    state.loading_application ?
                        <InputSkeleton type="text" height={'39px'} width={'230px'} />
                        :
                        state.application_list.length > 0 ?
                            <div className='d-grid input-mb-0' style={{ gridTemplateColumns: '1fr 100px', gap: '10px' }}>
                                <Input
                                    type="select"
                                    name="app_name"
                                    label=""
                                    select_default_value={"Select Application"}
                                    list={state.application_list}
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={onChangeHandler}
                                />
                                <Link to={"/sprint/scope?app_name=" + state.data.app_name}>
                                    <button className='btn-primary btn'>Continue</button>
                                </Link>
                            </div>
                            :
                            <div className=''>
                                <AlertStrip
                                    variant={"info"}
                                    message={"Unable to load appliation. but you can still contiue to Approve sprint."} />
                                <Link to={"/sprint/scope"}>
                                    <button className='btn btn-primary ' style={{ marginTop: '16px' }} >Continue</button>
                                </Link>

                            </div>
                }
            </div>
        </div>
    )
}

export default SelectApplicationForSpirntApprove;

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        height: 'calc(100vh - 70px)',
        '& .application-selection': {
            paddingTop: '10vh',
            width: '480px',
            textAlign: 'center',
            margin: 'auto',
            '& .alert-primary': {
                backgroundColor: '#F5FAFF',
                border: '1px solid #DFEDFF',
                textAlign: 'center',
                color: '#787878',
                padding: '10px !important'
            },
            '& .mb-16': {
                marginBottom: '16px'
            }
        }
    }
}))