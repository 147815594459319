import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { makeStyles } from '@mui/styles';
import InfoStaticPanel from '../../components/InfoStaticPanel';
import EditStepEnvVarV2 from '../../components/editStepEnvVariables/EditStepEnvVarV2';
import EditStepEnvVar from '../../components/editStepEnvVariables/EditStepEnvVar';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import { Input } from '../../../../components/genericComponents/Input';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const useStyles = makeStyles(theme => ({
    root_half_dialog: {
        '& .MuiPaper-root': {
            position: 'absolute',
            right: '0px',
            margin: '0px',
            top: '0px',
            height: '100vh',
            maxHeight: '100%',
            borderRadius: '0px',
            width: '704px',
            maxWidth: '704px',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '& .main_dialog_div': {
                display: 'grid',
                gridTemplateColumns: '323px 623px',
                backgroundColor: 'transparent',
                justifyContent: 'flex-end'
            },
            '& .main_dialog_hidded': {
                display: 'grid',
                justifyContent: 'flex-end',
                gridTemplateColumns: '650px'
            }
        },
        '& .three-forth-dialog__part_2': {
            backgroundColor: '#fff',
            height: '100vh'
        },
        '& .three-forth-dialog__part_1': {
            padding: '0px',
            backgroundColor: '#fff',
            height: '100vh',
            position: 'relative',
            overflowY:'scroll'
        }
    },
    root: {
        '& .MuiPaper-root': {
            position: 'absolute',
            right: '0px',
            margin: '0px',
            boxShadow: 'none',
            top: '0px',
            height: '100vh',
            maxHeight: '100%',
            borderRadius: '0px',
            width: '1054px',
            maxWidth: '1054px',
            backgroundColor: 'transparent',
            '& .main_dialog_div': {
                display: 'grid',
                backgroundColor: 'transparent',
                gridTemplateColumns: '350px 650px',
                backgroundColor: 'transparent',
                justifyContent: 'flex-end'
            },
            '& .main_dialog_hidded': {
                display: 'grid',
                gridTemplateColumns: '623px'
            }
        },
        '& .three-forth-dialog__part_2': {
            backgroundColor: '#fff',
            height: '100vh',
            '& .checkbox-only-divi': {
                bottom: '10px',
                position: 'absolute',
            }
        },
        '& .three-forth-dialog__part_1': {
            padding: '0px',
            backgroundColor: '#fff',
            height: '100vh',
            position: 'relative',
             overflowY:'scroll'
        }
    }
}))


export default function StepEditTemplate(props) {

    const [state, setState] = useState({});
    const { stepData, changeParentState, jobTemplate, updateJobTemplate, version } = props
    const classes = useStyles();
    const { step, openDialog, layer } = stepData
    const [data, setData] = useState({ data: {}, error: {} })
    const showInfoSection = JSON.parse(localStorage.getItem('ticketing_system_step_catalog_dialog_display_flag'));
    useEffect(() => {
        jobTemplate && !state.SupportedEnvVariables && fetchSupportedEnvVariables()
    }, [jobTemplate])

    console.log(step, 'j_b_t_001')

    const handleClose = () => {

        changeParentState({ step: null, openDialog: false, layer: null, version: null })
    }

    const handleOnSaveForEdit = (updatedStep) => {           //callback function for edit
        const { layer_index, allLayer, currentStepOrder } = layer
        let wholeJobTemplate = jobTemplate
        let job_template_data = wholeJobTemplate.job_template
        let jobs = job_template_data.jobs     //jobs
        if (layer_index === 1) {
            jobs[allLayer.job].steps[currentStepOrder] = updatedStep
            wholeJobTemplate.job_template['jobs'] = jobs
            updateJobTemplate(wholeJobTemplate)
        }

        else if (layer_index === 3) {
            jobs[allLayer.job].steps[allLayer.conditionalStep].values_step_mapping[allLayer.condition].steps[currentStepOrder] = updatedStep
            wholeJobTemplate.job_template['jobs'] = jobs
            updateJobTemplate(wholeJobTemplate)

        }

        handleClose()
    }

    const fetchSupportedEnvVariables = () => {
        let requestInfo = {
            endPoint: GenerateURL({}, properties.api.supported_env_variables),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, handleSuccess, handleFailure);

    }

    const handleSuccess = (data) => {

        setState(new_state => ({
            ...new_state,
            SupportedEnvVariables: data
        }));

    }

    const handleFailure = (error) => {
        setState(new_state => ({
            ...new_state,
            error_msg: "Error fetching data" + " " + error
        }))
    }

    function onChangeDoNotshow(e) {
        let key = e.target.name;
        let value = e.target.value;
        if (key == "dont_show_again") {
            value = !data.data.dont_show_again;
        }
        setData((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value,
            },
            error: {
                ...prevState.error,
                [key]: ''
            },
        }));
    }

    const hideInfoPanel = () => {
        console.log(data, data.data, data.data.dont_show_again, "dont_show_again")
        if (data?.data?.dont_show_again) {
            setDataToLocalStorage()
        }
        setState(new_state => ({
            ...new_state,
            hide_left_div: true
        }));
    }

    function setDataToLocalStorage() {
        localStorage.setItem('ticketing_system_step_catalog_dialog_display_flag', data.data.dont_show_again);
    }

    return (

        <Dialog
            fullWidth
            maxWidth='lg'
            open={openDialog}
            onClose={handleClose}
            className={state.hide_left_div ? classes.root_half_dialog : classes.root}
            aria-labelledby="max-width-dialog-title" TransitionComponent={Transition}>
            <div className={state.hide_left_div ? 'main_dialog_hidded' : 'main_dialog_div'}>
                <button
                    className='btn float-cancel-button'
                    style={state.hide_left_div ? { left: '0px', zIndex: 99 } : { left: '0px', zIndex: 99 }}
                    onClick={handleClose}
                >
                    <span className='ri-close-line'></span>
                </button>
                {
                    !state.hide_left_div && <>
                        <div className={"three-forth-dialog__part_2 position-relative"} style={{ boxShadow: '-20px 0px 35px 0px #0000000D inset' }}>
                            <div className='d-flex align-center space-between left-panel-header ' style={{ borderBottom: '1px solid #f1f1f1', padding: '10px 20px' }}>
                                <p>Information</p>
                                <button
                                    className='btn btn-icon-only'
                                    onClick={hideInfoPanel}

                                >
                                    <span className='ri-close-line color-icon-secondary'></span>
                                </button>
                            </div>
                            <div className='pd-10' style={{ padding: '10px 20px' }}>
                                <InfoStaticPanel version={version} step_code={step && step.step_code} />
                            </div>
                            <div className='checkbox-only-divi' style={{ padding: '10px 20px' }}>
                                <Input
                                    type="simple-checkbox"
                                    name="dont_show_again"
                                    label="Dont show this again"
                                    mandatorySign
                                    data={data.data}
                                    error={data.error}
                                    onChangeHandler={onChangeDoNotshow}
                                />
                            </div>
                        </div>
                    </>
                }

                <div className={"three-forth-dialog__part_1"} style={state.hide_left_div ? {} : { boxShadow: '0px 8px 24px 0px #0000000A' }}>

                    <div className='font-16 font-weight-500 color-white' style={{backgroundColor:'#0086ff', padding:'10px'}}>
                        {`To run "${step && step.step_name || ''}" step you need to address the following requirement`}
                    </div>
                    {
                        version === 'v3' ?
                            <EditStepEnvVar supportedEnvVariables={state.SupportedEnvVariables} version={version ? version : ''} stepForEdit={step ? { label: step.step_name, code: step.step_code, data: { ...step } } : null} onSave={handleOnSaveForEdit} onClose={handleClose} />
                            :
                            <EditStepEnvVarV2 supportedEnvVariables={state.SupportedEnvVariables} version={version ? version : ''} stepForEdit={step ? { label: step.step_name, code: step.step_code, data: { ...step } } : null} onSave={handleOnSaveForEdit} onClose={handleClose} />
                    }
                </div>
            </div>
        </Dialog>

    )
}

StepEditTemplate.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};