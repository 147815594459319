import React from "react";
import PropTypes from 'prop-types';
import { display, fontSize, fontWeight, lineHeight, styled, textTransform } from "@mui/system";
import { color } from "highcharts";
import PipelineProgressCard from "./PipelineProgressCard";
import PipelineCardService from "./PipelineCardService";
import RunningDotsComponent from "../../../service/listing/components/componentLogsViewer/RunningDotsComponent";
import PipelineExecutionHistory from "../component/PipelineExecutionHistory";
let stage = 'Stage (08/15) :'

const PipelineCardBottom = ({ isExpandableView,
    toggleExpandableView,
    reTriggerError,
    application_id,
    pipeline_current_page,
    current_page_no,
    retryPipeline,
    retriggerPipeline,
    triggerRevoke,
    variant,
    ...props }) => {

    const pipeline = props.pipeline;
    const pipeline_instance = props.pipeline_instance;
    const stage_complete_status = getStatus();

    function getStatus() {
        var instance_length = "";
        if (pipeline_instance.stage_instance.length > 0 && pipeline_instance.stage_instance.length < pipeline.stages.length) {
            instance_length = pipeline_instance.stage_instance.length - 1;
        } else {
            instance_length = pipeline_instance.stage_instance.length;
        }
        return instance_length + "/" + pipeline.stages.length;
    }

    console.log(pipeline.stages, pipeline_instance, 'data_00pslsd');

    function getProgressStatusIcon() {
        switch (pipeline_instance.status) { //pipeline_status
            case "SUCCESS":
                return (
                    <Success>
                        <span style={{ color: '#2EBE79', fontSize: '16px' }} className="ri-checkbox-circle-fill">

                        </span>
                        <span className="title">
                            Success
                        </span>
                    </Success>);
            case "FAILED":
                return (
                    <Failed>
                        <span style={{ color: '#E53737', fontSize: '16px' }} className="ri-close-circle-fill">

                        </span>
                        <span className="title">
                            failed
                        </span>
                    </Failed>);
            case "RUNNING":
                return (
                    <Running>
                        <span className="title">running</span>
                        <RunningDotsComponent pipelineCard={true} />
                    </Running>);

            case "PENDING_APPROVAL":
                return (
                    <ApprovalRequired>
                        <span style={{ color: '#9747FF', fontSize: '16px' }} className="ri-thumb-up-fill">

                        </span>
                        <span className="title">
                            approval required
                        </span>

                    </ApprovalRequired>)
            case "PENDING_RESUME":
                return (
                    <div className="status-chip status-chip-warning"><span className="flaticon-round-pause-button " style={{ color: '#fff' }}></span> Waiting </div>
                )
            case "DISAPPROVED":
                return (
                    <Disapproved>
                        <span style={{ color: '#14002E', fontSize: '16px', marginTop: '4px' }} className="ri-thumb-down-fill">

                        </span>
                        <span className="title">
                            disapproved
                        </span>
                    </Disapproved>);
            case "SUSPENDED":
                return (
                    <div className="status-chip status-chip-warning" style={{ backgroundColor: 'rgb(142, 142, 142)' }}><i className="ri-error-warning-line" style={{ coloe: '#d3d3d4' }}></i> Suspended </div>
                )
            case "REVOKED":
                return (
                    <Revoked>
                        <span style={{ color: '#E1941D', fontSize: '16px' }} className="ri-stop-circle-line">

                        </span>
                        <span className="title">
                            revoked
                        </span>
                    </Revoked>);
            case "STOPPED":
                return (
                    <div className='status-chip status-chip-stopped'><i className="ri-stop-line"></i> Stopped</div>
                )
            default:
                return (
                    <div className="status-chip status-chip-info"><span className="flaticon-circle-with-check-symbol"></span> {pipeline_instance.status} </div>
                );
        }
    }

    let stageData = ''
    let jobCount = ''
    if (pipeline?.pipeline_instance?.length > 0) {
        let currentStageIndex = pipeline?.pipeline_instance?.length - 1
        let stage = pipeline.stages[currentStageIndex]
        stageData = stage.name
        jobCount = stage.task_count
    }
    else {
        let stage = pipeline?.stages[0]
        stageData = stage?.name
        jobCount = stage?.task_count
    }

    let stageContent = `${stageData} ${jobCount} Job`

    return (

        <>
            <Card>
                <div className="d-flex space-between">

                    <div className="info-section">

                        <div className="d-flex align-center">
                            <span style={{ fontSize: '16px', color: '#787878', marginRight: '5px', marginBottom: '5px' }} className="ri-bar-chart-fill">

                            </span>
                            <span className="stage-info">
                                {`Stage (${stage_complete_status}) : `}
                            </span>
                        </div>

                        <div>
                            <span className="msg-info">
                                {stageContent}
                            </span>
                        </div>

                        {getProgressStatusIcon()
                        }

                    </div>
                    {
                        isExpandableView ?

                            <div style={{ gap: '16px' }} className="d-flex align-center">
                                {pipeline_instance && variant != 'pipeline-history' ? (
                                    <PipelineExecutionHistory
                                        reTriggerError={reTriggerError}
                                        application_id={application_id}
                                        pipeline={pipeline}
                                        newUpdate={true}
                                        pipeline_instance={pipeline_instance}
                                        revoke_pipeline={triggerRevoke}
                                        pipeline_current_page={pipeline_current_page}
                                        current_page_no={current_page_no}
                                        retry_pipeline={() =>
                                            retryPipeline(pipeline.id, pipeline.last_trigger.id)
                                        }
                                        retrigger_pipeline={
                                            () => {
                                                retriggerPipeline(pipeline.id, pipeline.last_trigger.id)
                                            }
                                        }
                                    />
                                ) : null}
                                <span onClick={toggleExpandableView} style={{ color: '#787878', fontSize: '22px', cursor: 'pointer' }} className="ri-arrow-up-s-line">

                                </span>

                            </div> :
                            <span onClick={toggleExpandableView} style={{ color: '#787878', fontSize: '22px', cursor: 'pointer' }} className="ri-arrow-down-s-line">

                            </span>
                    }
                </div>

            </Card>
            {
                isExpandableView &&
                <>
                    <PipelineProgressCard pipeline={pipeline} pipeline_instance={pipeline_instance} variant={variant} />
                    <PipelineCardService pipeline={pipeline} variant={variant} {...props} />
                </>
            }
        </>)

}

export default PipelineCardBottom;

PipelineCardBottom.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


PipelineCardBottom.defaultProps = {
    isExpandableView: false,
    triggerRevoke: () => { },
    pipeline: {},
    pipeline_instance: {},
    toggleExpandableView: () => { },
    pipeline_current_page: 1,
    components: [],
    tags: [],
    last_execution_data: null,
    sendForApproval: () => { },
    confirmForApproval: () => { },
    reTriggerError: false,
    application_id: "",
    current_page_no: 1,
    retryPipeline: () => { },
    retriggerPipeline: () => { },
}
const Card = styled("div")({
    minHeight: '48px',
    padding: '8px 20px 12px 20px',
    borderTop: '1px solid #E6E6E6',
    color: '#FFFFFF',
    '& .info-section': {
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        '& .stage-info': {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '14.63px',
            color: '#2F2F2F'
        },
        '& .msg-info': {
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '14.63px',
            color: '#2F2F2F'
        }
    }
});


const Success = styled("div")({
    background: '#E6FBEA',
    borderRadius: '6px',
    height: '26px',
    padding: '5px 6px 5px 6px',
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    '& .title': {
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '13.41px',
        color: '#2EBE79',
        textTransform: 'uppercase'
    }
})

const Failed = styled("div")({
    background: '#FFEBEB',
    borderRadius: '6px',
    height: '26px',
    padding: '5px 6px 5px 6px',
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    '& .title': {
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '13.41px',
        color: '#E53737',
        textTransform: 'uppercase'
    }
})

const ApprovalRequired = styled("div")({
    background: '#F4ECFF',
    borderRadius: '6px',
    height: '26px',
    padding: '5px 6px 5px 6px',
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    '& .title': {
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '13.41px',
        color: '#9747FF',
        textTransform: 'uppercase'
    }
})

const Running = styled("div")({
    background: '#EBF5FF',
    borderRadius: '6px',
    height: '26px',
    padding: '5px 6px 5px 6px',
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    '& .title': {
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '13.41px',
        color: '#0086FF',
        textTransform: 'uppercase'
    }
})
const Revoked = styled("div")({
    background: '#FCF6E1',
    borderRadius: '6px',
    height: '26px',
    padding: '5px 6px 5px 6px',
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    '& .title': {
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '13.41px',
        color: '#E1941D',
        textTransform: 'uppercase'
    }
})

const Disapproved = styled("div")({
    background: '#E6E6E6',
    borderRadius: '6px',
    height: '26px',
    padding: '5px 6px 5px 6px',
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    '& .title': {
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '13.41px',
        color: '#14002E',
        textTransform: 'uppercase'
    }
})



