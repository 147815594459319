import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Popover } from '@mui/material';

export const ExandableComponentList = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const compoenent_list = props.compoenent_list;
    const variant = props.variant;
    const iteration_count = props.iteration_count;
    const expandable_component = props.expandable_component;
    const extended_component_list = [];
    let new_component_list = [];

    compoenent_list.forEach((component, key) => {
        if (key < iteration_count) {
            // Render components based on the variant
            variant === "capsule_view" ?
                new_component_list.push(
                    <span className='btn input-chip-checkbox chip chip-default'
                        style={{ transition: 'all 500ms ease', backgroundColor: '#fff', color: '#0086ff', margin: '2px', paddingBottom: '2px' }}>
                        {component}
                    </span>
                )
                :
                variant === "simple_chip_view" ?
                    new_component_list.push(
                        <span
                            style={{
                                backgroundColor: '#0086ff',
                                borderRadius: '6px',
                                color: '#fff',
                                padding: '3px 5px',
                                display: 'inline-block',
                                margin: '0px 2px',
                                fontSize: '11px',
                                fontWeight: '500'
                            }}
                        >
                            {component}
                        </span>
                    )
                    :
                    variant === "joblistingview" ?
                        new_component_list.push(
                            <span className='d-flex align-center'
                                style={{
                                    transition: 'all 500ms ease',
                                    padding: "6px 8px",
                                    height: "25px",
                                    borderRadius: "38px",
                                    border: "1px solid  #E6E6E6",
                                    background: "#FAFAFA",
                                    color: "#787878"
                                }}>
                                {component}
                            </span>
                        )
                        :
                        variant === "canary-failure-services" ?
                            new_component_list.push(
                                <span className='d-flex align-center'
                                    style={{
                                        transition: 'all 500ms ease',
                                        padding: "6px 8px",
                                        height: "25px",
                                        borderRadius: "38px",
                                        border: "1px solid  #E6E6E6",
                                        background: "#FAFAFA",
                                        width:"fit-content"
                                    }}>
                                    {component}
                                </span>
                            )
                            :
                            new_component_list.push(component)
        } else {
            extended_component_list.push(component);
        }
    });

    if (compoenent_list.length > iteration_count) {
        if (variant === "joblistingview") {
            new_component_list.push(
                <>
                    <div className='d-flex align-center justify-center font-11' style={{
                        transition: 'all 500ms ease',
                        height: "25px",
                        width: "25px",
                        padding: "6px",
                        gap: "8px",
                        borderRadius: "38px",
                        border: "1px solid  #E6E6E6",
                        background: "#FAFAFA",
                        cursor: "pointer",
                        color: "#787878"
                    }} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                        {expandable_component}
                    </div>
                    <Popover
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        PaperProps={{
                            style: {
                                maxHeight: 300,
                                overflowY: 'scroll', // Ensures vertical scroll is active
                                overflowX: 'hidden',
                                scrollbarWidth: 'thin', // For Firefox
                                msOverflowStyle: 'auto', // For IE and Edge
                            },
                        }}
                    >
                        {extended_component_list.map((component, index) => (
                            <MenuItem key={index} onClick={handleClose}>{component}</MenuItem>
                        ))}
                    </Popover>
                </>
            );
        } else {
            new_component_list.push(
                <>
                    <div className="chip-default chip-blue wrapper-chip" style={{ height: 'fit-content' }} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                        {expandable_component}
                    </div>
                    <Popover
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        PaperProps={{
                            style: {
                                maxHeight: 300,
                                overflowY: 'scroll', // Ensures vertical scroll is active
                                overflowX: 'hidden',
                                scrollbarWidth: 'thin', // For Firefox
                                msOverflowStyle: 'auto', // For IE and Edge
                            },
                        }}
                    >
                        {extended_component_list.map((component, index) => (
                            <MenuItem key={index} onClick={handleClose}>{component}</MenuItem>
                        ))}
                    </Popover>
                </>
            );
        }
    }

    console.log(new_component_list, "asbxashbxghasv")

    return (
        <>
            {new_component_list.map((component, index) => {
                console.log(component, "sbxgasvghvac");
                return (
                    <React.Fragment key={index}>
                        {component}
                    </React.Fragment>
                );
            })}
        </>
    );
};

ExandableComponentList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export const ExpandableTagList = (props) => {
    const list = props.list;
    const iteration_count = props.iteration_count ? props.iteration_count : 2;

    const tags_list = [];

    list.forEach(tag => {
        tags_list.push(
            <span className="chip-default-square chip-bg-dark-grey twinkle">
                {tag}
            </span>
        );
    });

    const expandable_component = (
        <span className="chip-default-square chip-bg-dark-grey">
            +{tags_list.length - iteration_count}
        </span>
    );


    return (
        <ExandableComponentList
            compoenent_list={tags_list}
            iteration_count={iteration_count}
            expandable_component={expandable_component} />
    );
}
ExpandableTagList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export const ExpandableServiceList = (props) => {
    const list = props.list;
    const iteration_count = props.iteration_count ? props.iteration_count : 2;

    const service_list = [];

    list.forEach(service => {
        service_list.push(
            <span className={service.part_of_execution ? "chip-default-square status-sea-blue" : "chip-default-square lite-grey-chip"}>
                {service.name}
            </span>
        );
    });

    const expandable_component = (
        <span className="chip-default-square lite-grey-chip">
            +{service_list.length - iteration_count}
        </span>
    );

    return (
        <ExandableComponentList
            compoenent_list={service_list}
            iteration_count={iteration_count}
            expandable_component={expandable_component} />
    );
}

ExpandableServiceList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export const ExandableCategoryList = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    //const id = open ? 'simple-popover' : undefined;
    const compoenent_list = props.compoenent_list;
    const variant = props.variant;
    const iteration_count = props.iteration_count;
    const expandable_component = props.expandable_component;
    const extended_component_list = [];
    let new_component_list = [];
    compoenent_list.forEach((component, key) => {
        if (key < iteration_count) {

            variant === "capsule_view" ?
                new_component_list.push(
                    <span className='btn input-chip-checkbox chip chip-default'
                        style={{ transition: 'all 500ms ease', backgroundColor: '#fff', color: '#0086ff', margin: '2px', paddingBottom: '2px', borderRadius: '' }}>
                        {component}
                    </span>
                )
                :
                new_component_list.push(component)
        } else {
            extended_component_list.push(component);
        }
    });

    if (compoenent_list.length > iteration_count) {
        new_component_list.push(
            <>
                <div className="chip-default  chip-bg-color" style={{ height: 'fit-content' }} aria-controls="fade-menu" aria-haspopup="true" onKeyDown={() => { }} onClick={handleClick} tabIndex={0} role='button'>
                    {expandable_component}
                </div>
                <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    {
                        extended_component_list.map((component) => {
                            return <MenuItem onClick={handleClose}>{component}</MenuItem>
                        })
                    }
                </Menu>
            </>
        );
    }
    return (
        <>
            {
                new_component_list.map((component) => {
                    return component
                })
            }
        </>
    );
}

ExandableCategoryList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}





