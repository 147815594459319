import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Input } from "../../../../components/genericComponents/Input";
import InvokeApi from "../../../../util/apiInvoker";
import GenerateURL, { GenerateSearchURL } from "../../../../util/APIUrlProvider";
import properties from "../../../../properties/properties";
import Checkbox from "@mui/material/Checkbox";
import { getJobTemplateBasesOnService, getServicesForSelectedProject, getStepOutputVars, SideLabelForInput } from "./AddVersionThreeJobs";
import { task_type_list } from "./JobForm";
import { Loading } from "../../../utils/Loading";

function getDefaultOutputForm(){
    return {
        previous_job: null,
        previous_job_type: null,
        previous_job_service: null,
        deployment_list: null,
        deployment_name: null,
        services: null,
        step: null,
        output_var: null,
        stage: null,
        task_list: null,
        step_list: null,
        step_order: null,
        output_var_list: null,
        outputVariableMapping: false,
        is_overridden: true
    }
}

const OverrideVariablesForNonDependentJobs = ({ jobData, setParentState, independentJobData, editFlowJobStepJson,stages,job_templates }) => {
    const [state, setState] = useState({
        data: {},
        error: {},
        steps: [],
        stepsData: {},
        hoveredItem: null
    })
    // api call and local step data to create form here 
    console.log('file_001')
    console.log(jobData, independentJobData, editFlowJobStepJson, 'jd)9o2323')
    console.log("jdkdjskds",jobData);
    const [loading,setLoading] = useState(false);
    const stepData = jobData?.steps

    useEffect(() => {
        if (jobData?.steps) {
            setState((prevState) => ({
                ...prevState,
                stepsData: {},
            }))
            getDataForOverRidableView(stepData)
        }
    }, [jobData])

    // useEffect(() => {
    //     console.log(editFlowJobStepJson, 'dfhhgg_osod')
    //     if (editFlowJobStepJson?.job_step_json != undefined) {
    //         const jobData = editFlowJobStepJson?.job_json
    //         const jobStepJson = editFlowJobStepJson.job_step_json
    //         if (jobData.is_dependent == false) {
    //             let data = {}
    //             if (jobStepJson) {
    //                 Object.keys(jobStepJson)?.forEach((stepCode) => {
    //                     data[stepCode] = {}
    //                     const stepOrderData = jobStepJson[stepCode]
    //                     stepOrderData && Object.keys(stepOrderData)?.forEach(stepOrder => {
    //                         data[stepCode][stepOrder] = {}
    //                         const stepsData = stepOrderData[stepOrder]
    //                         let stepKeyDataObject = {}
    //                         stepsData?.forEach(async (step) => {
    //                             const stepInfo = step
    //                             stepInfo['error'] = {}
    //                             let constructedOutVarForm;
    //                             if(stepInfo.is_value_from_output_var){
    //                                 //handle value from previous job
    //                                 constructedOutVarForm = await getStateDataForOutputValues(stepInfo);
    //                                 stepKeyDataObject[step.key] = constructedOutVarForm;
    //                             }else{
    //                                 stepKeyDataObject[step.key] = stepInfo;
    //                             }
    //                         })
    //                         data[stepCode][stepOrder] = stepKeyDataObject
    //                     })
    //                 })
    //             }
    //             console.log(data, 'data_ppsese')
    //             setParentState(prevState => ({
    //                 ...prevState,
    //                 independentJobData: {
    //                     ...prevState.independentJobData,
    //                     data: { ...data }
    //                 }
    //             }))
    //         }
    //     }

    // }, [editFlowJobStepJson])
    useEffect(() => {
        const processJobSteps = async () => {
            setLoading(true);
            if (editFlowJobStepJson?.job_step_json) {
                const { job_json: jobData, job_step_json: jobStepJson } = editFlowJobStepJson;
                
                if (!jobData.is_dependent) {
                    const data = await generateJobStepData(jobStepJson);
                    setParentState(prevState => ({
                        ...prevState,
                        independentJobData: {
                            ...prevState.independentJobData,
                            data
                        }
                    }));
                }
            }
            setLoading(false);
        };
    
        const generateJobStepData = async (jobStepJson) => {
            const data = {};
            const stepPromises = Object.keys(jobStepJson).map(async (stepCode) => {
                const stepOrderData = jobStepJson[stepCode];
                const stepOrderPromises = Object.keys(stepOrderData).map(async (stepOrder) => {
                    const stepsData = stepOrderData[stepOrder];
                    const stepKeyDataObject = {};
                    await Promise.all(
                        stepsData.map(async (step) => {
                            step.error = {};
                            if (step.is_value_from_output_var) {
                                stepKeyDataObject[step.key] = await getStateDataForOutputValues(step);
                            } else {
                                stepKeyDataObject[step.key] = {...step, outputForm:getDefaultOutputForm()};
                            }
                        })
                    );
                    data[stepCode] = {
                        ...data[stepCode],
                        [stepOrder]: stepKeyDataObject,
                    };
                });
                await Promise.all(stepOrderPromises);
            });
    
            await Promise.all(stepPromises);
            return data;
        };
    
        processJobSteps();
    }, [editFlowJobStepJson]);
    
    

    const getDependentTaskListForEdit = (sourceValue) => {
        //fetching tasks 
        if (sourceValue != null || sourceValue != '') {
            const tasks = stages?.find(stage => stage.name == sourceValue)?.task
            const updatedTasks = tasks?.map((task, index) => {
                task['order'] = index + 1
                return task
            })
            return updatedTasks

        }

    }

    const getDependentStepListForEdit = async (taskList, selectedTask,serviceName) => {
        if (taskList) {
            let selectedJobCodeForV2;
            const selectedTaskData = taskList?.find(task => task.order == selectedTask)
            const configuredJobTemplateOfTask = selectedTaskData?.project_job_template_id
            let stepsOfdataOfConfiguredJobTemplateInTargetTask;
            let deploymentList;
            if(!configuredJobTemplateOfTask){ 
                if(serviceName === "all"){
                    const defaultJobTemplateJobs = job_templates?.find(jobTemplate => jobTemplate.default)?.job_template?.jobs;
                    let selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
                    const stepsdataOfConfiguredJobTemplateInTargetTask = defaultJobTemplateJobs?.find(job => job.job_code == selectedJobCodeForV2)?.steps;
                    stepsOfdataOfConfiguredJobTemplateInTargetTask = stepsdataOfConfiguredJobTemplateInTargetTask
                }else {
                    let { stepsOfdataOfConfiguredJobTemplate,serviceDeploymentList } = await getJobTemplateBasesOnService(selectedTaskData,selectedTaskData.project_env_id || selectedTaskData.env,serviceName);
                    stepsOfdataOfConfiguredJobTemplateInTargetTask = stepsOfdataOfConfiguredJobTemplate;
                    deploymentList = serviceDeploymentList;
                }
            }else{
                const dataOfConfiguredJobTemplateInTargetTask = job_templates?.find(jobTemplate => jobTemplate.id == configuredJobTemplateOfTask)?.job_template
                if(selectedTask.task_type_id){
                    selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
                }
                const jobsOfdataOfConfiguredJobTemplateInTargetTask = dataOfConfiguredJobTemplateInTargetTask?.jobs;
                stepsOfdataOfConfiguredJobTemplateInTargetTask = selectedJobCodeForV2? jobsOfdataOfConfiguredJobTemplateInTargetTask?.find(job => job.job_code == selectedJobCodeForV2)?.steps : jobsOfdataOfConfiguredJobTemplateInTargetTask?.find(job => job.job_code == selectedTaskData.job_code || selectedTaskData?.task_type)?.steps;  // using selectedTaskData?.task_type in case of v3 pipeline because in v3 pipeline job_code is not present
            }
            let servicesList;
            if(selectedTaskData.components && selectedTaskData.components.length >= 1){
                servicesList = await getServicesForSelectedProject(selectedTaskData,selectedTaskData?.task_type_id ? 'v2' : 'v3');
            }
            return {stepsOfdataOfConfiguredJobTemplateInTargetTask , previousJobType: selectedTaskData?.task_type_id ? 'v2' : 'v3',servicesList:servicesList,deploymentList: deploymentList}
        }
    }

    const getStateDataForOutputValues = async (stepInfo) => {
        const {key,value,outputForm,is_service_present,...rest} = stepInfo
        if (value == null) {
            return {
                is_value_from_output_var: true,
                key: key,
                value: value,
                ...rest,
                outputForm: {
                    previous_job: null,
                    previous_job_type: null,
                    previous_job_service: null,
                    deployment_list: null,
                    deployment_name: null,
                    services: null,
                    step: null,
                    output_var: null,
                    stage: null,
                    task_list: null,
                    step_list: null,
                    step_order: null,
                    output_var_list: null,
                    ouputVariableLoading: false
                }
            }
        }
        const values = value.split('.');
        const stage = values[1]; // "stage"
        const task = values[3]; // 2
        const serviceName = is_service_present ? values[4] : null; // "image-list"
        const stepCode = is_service_present ? values[5] : values[4]; // "test_step_001"
        const outputVariable = is_service_present ? values[6] : values[5]; // "output_var_2.5"
        const stepOrder = is_service_present ? values[7] : values[6];
        const deploymentName = is_service_present ? values.length > 7 ? values[8] : null : null;
        console.log("dksjksdurieuei",values);
        let taskList = null;
        let stepList = null;
        let outputVarList;
        let previous_job_type;
        let services;
        let deploymentList;
        if (stage != undefined) {
            taskList = getDependentTaskListForEdit(stage)
            if (taskList) {
                let {stepsOfdataOfConfiguredJobTemplateInTargetTask,previousJobType,servicesList,deploymentList:serviceDeploymentList} = await getDependentStepListForEdit(taskList,task,serviceName);
                stepList = stepsOfdataOfConfiguredJobTemplateInTargetTask
                // const selectedTaskData = taskList.find(task => task.order === task);
                previous_job_type = previousJobType;
                services=servicesList
                deploymentList = serviceDeploymentList
                console.log("sdjhsjh",taskList,previous_job_type,outputVarList,typeof task);
            }
            if(stepCode){
                outputVarList = await getStepOutputVars(stepCode,previous_job_type)
            }
            return {
                is_value_from_output_var: true,
                key: key,
                value: value,
                ...rest,
                outputForm: {
                    previous_job: task,
                    previous_job_type: previous_job_type,
                    previous_job_service: serviceName,
                    deployment_list: deploymentList,
                    deployment_name: deploymentName,
                    services: services,
                    step: stepCode,
                    output_var: outputVariable,
                    stage: stage,
                    task_list: taskList,
                    step_list: stepList,
                    step_order: stepOrder,
                    output_var_list: outputVarList || [],
                    ouputVariableLoading: false
                    //...outputForm,
                }
            }
        }
        else {
            return {
                is_value_from_output_var: true,
                key: key,
                value: value,
                outputForm: {
                    previous_job: null,
                    previous_job_type: null,
                    previous_job_service: null,
                    deployment_list: null,
                    deployment_name: null,
                    services: null,
                    step: null,
                    output_var: null,
                    stage: null,
                    task_list: null,
                    step_list: null,
                    step_order: null,
                    output_var_list: null,
                    outputVariableMapping: false,
                    is_overridden: true
                }
            }
        }
    }

    const getStepDataByCode = (code, order, stepDataFromTemplate) => {

        let myendpoint = GenerateURL({}, properties.api.stepCatalogs)

        var requestInfo = {
            endPoint: GenerateSearchURL({ version: 'v3', step_code: code }, myendpoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (data) => {
            console.log(data, order, 'dinbmbmb')
            let result = data?.results[0];
            result?.environment_variables?.forEach(env => {
                let key = env?.key
                let envValueInTemplate = stepDataFromTemplate?.environment_variables?.find(env => env.key == key)?.value
                env['value'] = envValueInTemplate

            })
            console.log(result, 'result_00ppl')
            console.log('xxc_00pp', stepDataFromTemplate, result)
            if (state.stepsData[code]) {
                setState(prevState => ({
                    ...prevState,
                    stepsData: {
                        ...prevState.stepsData,
                        [code]: {
                            ...prevState.stepsData[code],
                            [order]: result
                        }
                    }
                }))
            }
            else {
                setState(prevState => ({
                    ...prevState,
                    stepsData: {
                       ...prevState.stepsData,
                        [code]: {
                            [order]: data?.results[0]
                        }
                    }
                }))

                const envVarObjects = {}

                result?.environment_variables.forEach(envVariable => {
                    console.log(envVariable, 'data_001_sdds')
                    if (envVariable?.integration) {
                        const envVarObj = {
                            key: envVariable.integration_key,
                            value: envVariable?.value,
                            is_value_from_output_var: false,
                            is_overridden: false,
                            is_required: envVariable.is_required,
                            outputForm: getDefaultOutputForm(),
                        }
                        envVarObjects[envVariable.integration_key] = envVarObj
                    }
                    else {
                        const envVarObj = {
                            key: envVariable.key,
                            value: envVariable?.value,
                            is_value_from_output_var: false,
                            is_overridden: false,
                            is_required: envVariable.is_required,
                            outputForm: getDefaultOutputForm(),
                        }
                        envVarObjects[envVariable.key] = envVarObj
                    }


                })
             
                editFlowJobStepJson?.job_step_json == undefined &&  setParentState(prevState => ({ // need to dynamic check if dont exist then create a entry only
                    ...prevState,
                    independentJobData: {
                        ...prevState.independentJobData,
                        data: {
                            ...prevState.independentJobData.data,
                            [code]: {
                                [order]: {
                                    ...envVarObjects,
                                }
                            }
                        }
                    }
                }))
            }

        })
    }

    const getDataForOverRidableView = (steps) => {

        steps?.forEach((step) => {
            getStepDataByCode(step.step_code, step.order, step)
        })
    }

    const handleChange = (e, stepCodeKey, stepOrder, targetValue) => {
        const targetKey = e.target.name
        const value = targetValue == 'is_overridden' ? e.target.checked : e.target.value

        setParentState(prevState => ({
            ...prevState,
            independentJobData: {
                ...prevState.independentJobData,
                data: {
                    ...prevState.independentJobData.data,
                    [stepCodeKey]: {
                        ...prevState.independentJobData.data[stepCodeKey],
                        [stepOrder]: {
                            ...prevState.independentJobData.data[stepCodeKey][stepOrder],
                            [targetKey]: {
                                ...prevState.independentJobData.data[stepCodeKey][stepOrder][targetKey],
                                [targetValue]: value,
                                error: { [targetKey]: null }
                            }
                        }
                    }
                }
            }

        }))
    }

    const handleParentHover = (item) => {
        setState(prevState => ({
            ...prevState,
            hoveredItem: item
        }))
    };

    function fetchServicesForSelectedProject(data,selectedTask,type) {
        let stepCodeKey = data.stepCodeKey;
        let stepOrder = data.stepOrder;
        let mainKey = data.mainKey;
        let envVarData = independentJobData.data[stepCodeKey][stepOrder][mainKey];
        console.log("jdjsksks",data,selectedTask,type);
        let url = type == 'v3' ? GenerateURL({ env_id: selectedTask.env, template_id: selectedTask.project_job_template_id, job_code: selectedTask.job_code || selectedTask.task_type, entity_type: selectedTask.job_json.dependent_entity }, properties.api.get_entity_resources) : GenerateURL({ project_env_id: selectedTask.env, job_type: type }, properties.api.get_services_for_projectEnvV2)
        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, 
            (response) => {
                if (response) {
                    const result = type == 'v3' ? response : response.results
                    if (result.length > 0) {
                        let selectedTaskServices = result.filter((item) => {
                            if(selectedTask.components.includes(item.id)){
                                return item;
                            }
                        })
                        selectedTaskServices.unshift({ name: "all" });
                        envVarData['outputForm']['services'] = selectedTaskServices
                        updateIndependentJobDataForAStep(stepCodeKey,stepOrder,mainKey,envVarData);
                    }
                }
            }, (error)=>{
            console.log("kdjksjdks",error);
        });
        
    }

    const getStepCatelogData = (data,stepCode, previousJobType) => {
        let stepCodeKey = data.stepCodeKey;
        let stepOrder = data.stepOrder;
        let mainKey = data.mainKey;
        let envVarData = independentJobData.data[stepCodeKey][stepOrder][mainKey];
        console.log("jdjsksks",data);
        let myendpoint = GenerateURL({}, properties.api.stepCatalogs);
        var requestInfo = {
            endPoint: GenerateSearchURL({ step_code: stepCode, version: previousJobType || 'v3' }, myendpoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }


        InvokeApi(requestInfo,
            (response) => {
                if (response) {
                    const result = response.results
                    if (result.length > 0) {
                        const stepData = result[0]
                        const outPutVars = stepData.output_variables
                        envVarData['outputForm']['output_var_list'] = outPutVars
                        console.log("dsjhjsdhsdj",envVarData);
                        updateIndependentJobDataForAStep(stepCodeKey,stepOrder,mainKey,envVarData);
                    }
                }
            },
            (error) => {

            });

    }


    const updateIndependentJobDataForAStep = (stepCodeKey,stepOrder,envVarName,data)=>{
        setParentState((prevState) => ({
            ...prevState,
            independentJobData: {
                ...prevState.independentJobData,
                data: {
                    ...prevState.independentJobData.data,
                    [stepCodeKey]: {
                        ...prevState.independentJobData.data[stepCodeKey],
                        [stepOrder]: {
                            ...prevState.independentJobData.data[stepCodeKey][stepOrder],
                            [envVarName]: {
                                ...prevState.independentJobData.data[stepCodeKey][stepOrder][envVarName],
                                ...data
                            }
                        }
                    }
                }
            }
        }))
    }

    function fetchJobTemplateBasedOnService(data,selectedTaskData,project_env_id,service_name){
        let stepCodeKey = data.stepCodeKey;
        let stepOrder = data.stepOrder;
        let mainKey = data.mainKey;
        let envVarData = independentJobData.data[stepCodeKey][stepOrder][mainKey];
        let job_code = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
        let url = GenerateURL({ project_env_id: project_env_id, component_name: service_name }, properties.api.fetch_job_template_based_component+`?job_code=${job_code}`)
        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, 
            (response) => {
                if (response) {
                        let selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
                        const stepsOfdataOfConfiguredJobTemplateInTargetTask = response?.job_template.jobs?.find(job => job.job_code == selectedJobCodeForV2)?.steps;
                        envVarData['outputForm']['deployment_list'] = response?.deployment_names ? response.deployment_names : null;
                        envVarData['outputForm']['step_list'] = stepsOfdataOfConfiguredJobTemplateInTargetTask
                        updateIndependentJobDataForAStep(stepCodeKey,stepOrder,mainKey,envVarData);
                    }
                }
            , (error)=>{
            console.log("kdjksjdks",error);
        });
    }

    const handleChangeForOutputVariableForm = (e,data)=>{
        console.log("dhdjdjf",e,data);
        const targetKey = e.target.name
        const value = e.target.value
        let stepCodeKey = data.stepCodeKey;
        let stepOrder = data.stepOrder;
        let mainKey = data.mainKey;
        let envVarData = independentJobData.data[stepCodeKey][stepOrder][mainKey];
        if (targetKey == 'stage') {
            //fetching tasks 
            if (value != null || value != '') {
                const tasks = stages?.find(stage => stage.name == value)?.task
                const updatedTasks = tasks?.map((task, index) => {
                    task['name'] = `job-${index+1}`;
                    task['order'] = index + 1
                    return task
                })
                envVarData['outputForm'][targetKey] = value;
                envVarData['outputForm']['task_list'] = updatedTasks;
                updateIndependentJobDataForAStep(stepCodeKey,stepOrder,mainKey,envVarData);
            }
        }
        else if (targetKey == 'previous_job') {
            const taskList = envVarData['outputForm']['task_list']
            let selectedJobCodeForV2;
            envVarData['outputForm'][targetKey] = value;
            if (taskList) {
                const selectedTaskData = taskList?.find(task => task.order == value)
                const configuredJobTemplateOfTask = selectedTaskData?.project_job_template_id
                const dataOfConfiguredJobTemplateInTargetTask = job_templates?.find(jobTemplate => jobTemplate.id == configuredJobTemplateOfTask)?.job_template
                const jobsOfdataOfConfiguredJobTemplateInTargetTask = dataOfConfiguredJobTemplateInTargetTask?.jobs
                if(selectedTaskData?.task_type_id){
                    selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
                }
                const stepsOfdataOfConfiguredJobTemplateInTargetTask = selectedJobCodeForV2 ? jobsOfdataOfConfiguredJobTemplateInTargetTask?.find(job => job.job_code == selectedJobCodeForV2)?.steps : jobsOfdataOfConfiguredJobTemplateInTargetTask?.find(job => job.job_code == selectedTaskData.job_code || selectedTaskData?.task_type)?.steps // using selectedTaskData?.task_type in case of v3 pipeline because in v3 pipeline job_code is not present
                console.log("dhjshd",stepsOfdataOfConfiguredJobTemplateInTargetTask,selectedJobCodeForV2,selectedTaskData,taskList);
                envVarData['outputForm']['step_list'] = stepsOfdataOfConfiguredJobTemplateInTargetTask;
                envVarData['outputForm']['previous_job_type'] = selectedTaskData?.task_type_id ? 'v2' : 'v3';
                if(selectedTaskData?.components && selectedTaskData?.components.length >= 1){
                    fetchServicesForSelectedProject(data,selectedTaskData, selectedTaskData?.task_type_id ? 'v2' : 'v3');
                }
                console.log("hdhsjdhsj",value,envVarData,selectedTaskData,configuredJobTemplateOfTask,dataOfConfiguredJobTemplateInTargetTask,jobsOfdataOfConfiguredJobTemplateInTargetTask,stepsOfdataOfConfiguredJobTemplateInTargetTask)
                updateIndependentJobDataForAStep(stepCodeKey,stepOrder,mainKey,envVarData);
            }
        }
        else if(targetKey == 'previous_job_service'){
            const taskList = envVarData['outputForm']['task_list'];
            const selectedTaskData = taskList?.find(task => task.order == envVarData['outputForm']['previous_job']);
            if(!selectedTaskData?.project_job_template_id){
                if(value === "all"){
                    const defaultJobTemplateJobs = job_templates?.find(jobTemplate => jobTemplate.default)?.job_template?.jobs;
                    let selectedJobCodeForV2 = task_type_list.find((item) => item.id == (selectedTaskData?.task_type_id))?.job_code;
                    const stepsOfdataOfConfiguredJobTemplateInTargetTask = defaultJobTemplateJobs?.find(job => job.job_code == selectedJobCodeForV2)?.steps;
                    envVarData['outputForm']['step_list'] = stepsOfdataOfConfiguredJobTemplateInTargetTask;
                }else{
                    fetchJobTemplateBasedOnService(data,selectedTaskData,selectedTaskData.project_env_id || selectedTaskData.env,value)
                }
            }
            envVarData['outputForm'][targetKey] = value;
            updateIndependentJobDataForAStep(stepCodeKey,stepOrder,mainKey,envVarData);
        }
        else if (targetKey == 'step_order') {
            const stepList = envVarData['outputForm']['step_list']
            envVarData['outputForm'][targetKey] = value
            const selectedStepData = stepList?.find(step => step.order == value)
            const selectedStepOrder = selectedStepData?.order
            const selectedStepCode = selectedStepData?.step_code
            envVarData['outputForm']['step'] = selectedStepCode;
            envVarData['outputForm']['step_order'] = selectedStepOrder;
            const previousJobType = envVarData['outputForm']['previous_job_type'];
            console.log("sdjjdshdsj",selectedStepData,envVarData);
            // getting step_order outputvars
            getStepCatelogData(data, selectedStepCode, previousJobType)
            updateIndependentJobDataForAStep(stepCodeKey,stepOrder,mainKey,envVarData);

        }
        else if (targetKey == 'output_var') { // need to change
            console.log("dshsdjshdj",envVarData,value,targetKey);
            envVarData['outputForm'][targetKey] = value;
            updateIndependentJobDataForAStep(stepCodeKey,stepOrder,mainKey,envVarData);
        }
        else if(targetKey == 'deployment_name'){
            console.log("dshsdjshdj",envVarData,value,targetKey);
            envVarData['outputForm'][targetKey] = value;
            updateIndependentJobDataForAStep(stepCodeKey,stepOrder,mainKey,envVarData);
        }
    }


    function handleChangeToPreviousJobValue(data){
        console.log("jdjds",data);
        let stepCodeKey = data.stepCodeKey;
        let stepOrder = data.stepOrder;
        let mainKey = data.mainKey;
        let envVarData = independentJobData.data[stepCodeKey][stepOrder][mainKey];
        const isValueFromOutput = envVarData.is_value_from_output_var;
        envVarData.is_value_from_output_var = !isValueFromOutput;
        console.log("shjashja",envVarData);
        // setParentState((prevState) => ({
        //     ...prevState,
        //     independentJobData: {
        //         ...prevState.independentJobData,
        //         data: {
        //             ...prevState.independentJobData.data,
        //             [stepCodeKey]: {
        //                 ...prevState.independentJobData.data[stepCodeKey],
        //                 [stepOrder]: {
        //                     ...prevState.independentJobData.data[stepCodeKey][stepOrder],
        //                     [mainKey]: {
        //                         ...envVarData
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }))
        updateIndependentJobDataForAStep(stepCodeKey,stepOrder,mainKey,envVarData);
    }

    console.log(state, 'step_data_001-->')
    console.log(state.stepsData, 'data_00pl')
    console.log(independentJobData.data, 'ide_00p_mn');

    return (
        <div style={{ boxShadow: '0px 2px 8px #80808066', border: '1px solid #dedede', marginBottom: '20px', width: '100%' }}>
            <div>
                <div style={{ backgroundColor: '#faf9ff', height: '60px', paddingLeft: '15px', paddingRight: '15px' }} className='d-flex align-center space-between border-bottom-grid'>

                    <label className='font-15 font-weight-500 '>
                        Job Steps Environment Variables
                    </label>

                    <label className='font-13  font-weight-500' style={{ color: '#716d6d' }}>
                        Provide/Update Runtime Environment Variables
                    </label>
                </div>
                <>
                    <Grid className='pl-15 pr-15 pb-20' style={{ backgroundColor: '#ffffff', minHeight: '200px' }} container>
                        {loading ? <Loading varient="light"/>:
                            <>
                            {
                                Object.keys(state.stepsData)?.length > 0 ? Object.keys(state.stepsData)?.map((stepCodeKey, index) => {
                                    const stepOrdersObject = state.stepsData[stepCodeKey]
                                    console.log('dg_hii', index, stepOrdersObject)
                                    return (
                                        <>
                                            {
                                                stepOrdersObject && Object.keys(stepOrdersObject)?.map((stepOrder, order) => {
                                                    const stepData = stepOrdersObject[stepOrder]
                                                    return (
                                                        <>
                                                            {stepData && <Grid key={`${stepCodeKey}'_'${index}}`} style={{ paddingTop: '15px' }} item xs={12}>

                                                                <Grid style={{ height: '32px' }} key={order} item xs={12}>
                                                                    <label className='font-13 mr-12'>Step Name</label>
                                                                    <label style={{ color: 'gray' }} className='font-13'>{stepData.name ? stepData.name : stepData.code}</label>
                                                                </Grid>

                                                                <div style={{
                                                                    paddingTop: '20px', paddingBottom: '15px', border: '1px solid #dedede',
                                                                    backgroundColor: '#f8f8f8', padding: '10px 20px 5px 20px',
                                                                    borderRadius: '5px'
                                                                }}>
                                                                    {
                                                                        stepData && stepData.environment_variables.map((variable, index) => {
                                                                            const uniqueId = `${stepCodeKey}_${stepOrder}_${index}`;
                                                                            const targetStep = independentJobData?.data?.[stepCodeKey]?.[stepOrder];
                                                                            console.log("sdjksjkds",targetStep);
                                                                            const isValueFromOutputVar = targetStep?.[variable.integration_key || variable.key]?.is_value_from_output_var
                                                                            const outputVarData = targetStep?.[variable.integration_key || variable.key]?.['outputForm'];
                                                                            const taskList = targetStep?.[variable.integration_key || variable.key]?.['outputForm']?.['task_list'];
                                                                            const servicesList = targetStep?.[variable.integration_key || variable.key]?.['outputForm']?.['services'] || null;
                                                                            const deploymentList = targetStep?.[variable.integration_key || variable.key]?.['outputForm']?.['deployment_list'] || null;
                                                                            const stepList = targetStep?.[variable.integration_key || variable.key]?.['outputForm']?.['step_list'];
                                                                            const outputVarList = targetStep?.[variable.integration_key || variable.key]?.['outputForm']?.['output_var_list'];
                                                                            console.log("skjdksjksd",independentJobData,variable,stepCodeKey,stepOrder)
                                                                            //console.log("djhsjhdjs",isValueFromOutputVar,outputVarData,taskList,servicesList,stepList,outputVarList);
                                                                            return (
                                                                                <Grid key={uniqueId} item xs={12}>
                                                                                    {
                                                                                        variable.integration ?
                                                                                            <>
                                                                                                {
                                                                                                    variable.integration == 'GIT_REPO' ?
                                                                                                        <Grid container spacing={2} onMouseEnter={() => handleParentHover(uniqueId)}
                                                                                                            onMouseLeave={() => handleParentHover(null)}
                                                                                                            className="mt-20" style={{ position: 'relative',marginTop:'4px' }}>
                                                                                                            <ExtraOverridableInput
                                                                                                                checked={
                                                                                                                    independentJobData.data
                                                                                                                    && independentJobData.data[stepCodeKey] &&
                                                                                                                    independentJobData.data[stepCodeKey][stepOrder] &&
                                                                                                                    independentJobData.data[stepCodeKey][stepOrder][variable.integration_key] &&
                                                                                                                    independentJobData.data[stepCodeKey][stepOrder][variable.integration_key]['is_overridden']
                                                                                                                }
                                                                                                                name={variable.integration_key}
                                                                                                                stepCodeKey={stepCodeKey}
                                                                                                                stepOrder={stepOrder}
                                                                                                                onChange={handleChange}
                                                                                                                show={((uniqueId === state.hoveredItem) ||
                                                                                                                    independentJobData.data
                                                                                                                    && independentJobData.data[stepCodeKey] &&
                                                                                                                    independentJobData.data[stepCodeKey][stepOrder] &&
                                                                                                                    independentJobData.data[stepCodeKey][stepOrder][variable.integration_key] &&
                                                                                                                    independentJobData.data[stepCodeKey][stepOrder][variable.integration_key]['is_overridden']
                                                                                                                )}
                                                                                                            />
                                                                                                            <SideLabelForInput 
                                                                                                                isValueFromOutputVar={isValueFromOutputVar}
                                                                                                                onHandleChange={() => handleChangeToPreviousJobValue({...independentJobData?.data?.[stepCodeKey]?.[stepOrder]?.[variable.integration_key],stepCodeKey: stepCodeKey, stepOrder: stepOrder,mainKey: variable.integration_key })} 
                                                                                                                forIndependent={true}/>
                                                                                                            {isValueFromOutputVar && 
                                                                                                                <>
                                                                                                                <div className='font-12' style={{ width: '100%' }}>
                                                                                                                    {variable.integration_key}
                                                                                                                </div>
                                                                                                                <PreviousJobInputs 
                                                                                                                    data = {{
                                                                                                                        targetStep,
                                                                                                                        isValueFromOutputVar,
                                                                                                                        outputVarData,
                                                                                                                        taskList,
                                                                                                                        servicesList,
                                                                                                                        deploymentList,
                                                                                                                        stepList,
                                                                                                                        outputVarList,
                                                                                                                        stepCodeKey,
                                                                                                                        stepOrder,
                                                                                                                        stages,
                                                                                                                        mainKey: variable.integration_key,
                                                                                                                    }}
                                                                                                                    onChangeHandler={handleChangeForOutputVariableForm}
                                                                                                                />
                                                                                                                </>
                                                                                                            }
                                                                                                            <Grid item xs = {isValueFromOutputVar ? 4 : 12}>
                                                                                                                <Input
                                                                                                                    type={isValueFromOutputVar ? 'select' : 'text'}
                                                                                                                    name={isValueFromOutputVar ? 'output_var' : variable?.integration_key}
                                                                                                                    label={isValueFromOutputVar ? "Output Variable" :`${variable?.integration_key} for ${variable?.integration_key}(Integration)`}
                                                                                                                    placeholder=""
                                                                                                                    onChangeHandler={isValueFromOutputVar ? 
                                                                                                                        outputVarList ?
                                                                                                                        (e => handleChangeForOutputVariableForm(e,{...independentJobData?.data?.[stepCodeKey]?.[stepOrder]?.[variable.integration_key],stepCodeKey: stepCodeKey, stepOrder: stepOrder,mainKey: variable.integration_key }))
                                                                                                                        :
                                                                                                                        false
                                                                                                                        :
                                                                                                                        (e => handleChange(e, stepCodeKey, stepOrder, 'value'))
                                                                                                                    }
                                                                                                                    list={isValueFromOutputVar ?  outputVarList?.map((item) => ({id: item.name,label: item.name})) : null}
                                                                                                                    data={isValueFromOutputVar ? 
                                                                                                                        outputVarData
                                                                                                                    :
                                                                                                                    {
                                                                                                                        [variable.integration_key]: (independentJobData?.data
                                                                                                                            && independentJobData?.data[stepCodeKey] &&
                                                                                                                            independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                            independentJobData?.data[stepCodeKey][stepOrder][variable.integration_key]) ?
                                                                                                                            independentJobData.data[stepCodeKey][stepOrder][variable.integration_key]['value'] : null
                                                                                                                    }}
                                                                                                                    error={
                                                                                                                        (independentJobData?.data
                                                                                                                            && independentJobData?.data[stepCodeKey] &&
                                                                                                                            independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                            independentJobData?.data[stepCodeKey][stepOrder][variable.integration_key] &&
                                                                                                                            independentJobData?.data[stepCodeKey][stepOrder][variable.integration_key]['error']
                                                                                                                        ) ?

                                                                                                                            independentJobData.data[stepCodeKey][stepOrder][variable.integration_key]['error'] : {}
                                                                                                                    }
                                                                                                                    mandatorySign={
                                                                                                                        isValueFromOutputVar ? false
                                                                                                                        :
                                                                                                                        (independentJobData?.data
                                                                                                                        && independentJobData?.data[stepCodeKey] &&
                                                                                                                        independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                        independentJobData?.data[stepCodeKey][stepOrder][variable.integration_key]) ?
                                                                                                                        independentJobData.data[stepCodeKey][stepOrder][variable.integration_key]['is_required'] : false}

                                                                                                                />
                                                                                                            </Grid>

                                                                                                        </Grid> :
                                                                                                        variable.integration == "CREDENTIAL_MANAGEMENT" ?
                                                                                                            <div onMouseEnter={() => handleParentHover(uniqueId)}
                                                                                                                onMouseLeave={() => handleParentHover(null)}
                                                                                                                className="mt-20" style={{ position: 'relative' }}>
                                                                                                                <ExtraOverridableInput
                                                                                                                    checked={
                                                                                                                        independentJobData.data
                                                                                                                        && independentJobData.data[stepCodeKey] &&
                                                                                                                        independentJobData.data[stepCodeKey][stepOrder] &&
                                                                                                                        independentJobData.data[stepCodeKey][stepOrder][variable.integration_key] &&
                                                                                                                        independentJobData.data[stepCodeKey][stepOrder][variable.integration_key]['is_overridden']
                                                                                                                    }
                                                                                                                    name={variable.integration_key}
                                                                                                                    stepCodeKey={stepCodeKey}
                                                                                                                    stepOrder={stepOrder}
                                                                                                                    onChange={handleChange}
                                                                                                                    show={((uniqueId === state.hoveredItem) ||
                                                                                                                        independentJobData.data
                                                                                                                        && independentJobData.data[stepCodeKey] &&
                                                                                                                        independentJobData.data[stepCodeKey][stepOrder] &&
                                                                                                                        independentJobData.data[stepCodeKey][stepOrder][variable.integration_key] &&
                                                                                                                        independentJobData.data[stepCodeKey][stepOrder][variable.integration_key]['is_overridden']
                                                                                                                    )}
                                                                                                                />
                                                                                                                <Input
                                                                                                                    type='text'
                                                                                                                    name={variable?.integration_key}
                                                                                                                    label={`${variable?.integration_key} for ${variable?.integration_key}(Integration)`}
                                                                                                                    placeholder=""
                                                                                                                    onChangeHandler={(e => handleChange(e, stepCodeKey, stepOrder, 'value'))}
                                                                                                                    data={{
                                                                                                                        [variable.integration_key]: (independentJobData?.data
                                                                                                                            && independentJobData?.data[stepCodeKey] &&
                                                                                                                            independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                            independentJobData?.data[stepCodeKey][stepOrder][variable.integration_key]) ?
                                                                                                                            independentJobData.data[stepCodeKey][stepOrder][variable.integration_key]['value'] : null
                                                                                                                    }}
                                                                                                                    error={
                                                                                                                        (independentJobData?.data
                                                                                                                            && independentJobData?.data[stepCodeKey] &&
                                                                                                                            independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                            independentJobData?.data[stepCodeKey][stepOrder][variable.integration_key] &&
                                                                                                                            independentJobData?.data[stepCodeKey][stepOrder][variable.integration_key]['error']
                                                                                                                        ) ?
    
                                                                                                                            independentJobData.data[stepCodeKey][stepOrder][variable.integration_key]['error'] : {}
                                                                                                                    }
                                                                                                                    mandatorySign={(independentJobData?.data
                                                                                                                        && independentJobData?.data[stepCodeKey] &&
                                                                                                                        independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                        independentJobData?.data[stepCodeKey][stepOrder][variable.integration_key]) ?
                                                                                                                        independentJobData.data[stepCodeKey][stepOrder][variable.integration_key]['is_required'] : false}
    
                                                                                                                />
    
                                                                                                            </div> :
                                                                                                            <>
    
                                                                                                            </>

                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            variable.input_type === 'toggle' ?
                                                                                                <Input
                                                                                                    type='env-var-toggle'
                                                                                                    name={variable.key}
                                                                                                    label={variable.key}
                                                                                                    placeholder=""
                                                                                                    onChangeHandler={(e => handleChange(e, order, 'toggle'))}
                                                                                                    data={state.data && state.data[order] ? state.data[order][variable.key] === 'no' ? false : true : {}}
                                                                                                    error={state.error}
                                                                                                /> :
                                                                                                variable.input_type == undefined || variable.input_type === 'text' || variable.input_type === 'json'||  variable.input_type === 'textarea' ?
                                                                                                    <Grid container spacing={2} onMouseEnter={() => handleParentHover(uniqueId)}
                                                                                                        onMouseLeave={() => handleParentHover(null)}
                                                                                                        className="mt-20" style={{ position: 'relative',marginTop:'4px' }}>
                                                                                                        <ExtraOverridableInput
                                                                                                            checked={
                                                                                                                independentJobData.data
                                                                                                                && independentJobData.data[stepCodeKey] &&
                                                                                                                independentJobData.data[stepCodeKey][stepOrder] &&
                                                                                                                independentJobData.data[stepCodeKey][stepOrder][variable.key] &&
                                                                                                                independentJobData.data[stepCodeKey][stepOrder][variable.key]['is_overridden']
                                                                                                            }
                                                                                                            name={variable.key}
                                                                                                            stepCodeKey={stepCodeKey}
                                                                                                            stepOrder={stepOrder}
                                                                                                            onChange={handleChange}
                                                                                                            show={((uniqueId === state.hoveredItem) ||
                                                                                                                independentJobData.data
                                                                                                                && independentJobData.data[stepCodeKey] &&
                                                                                                                independentJobData.data[stepCodeKey][stepOrder] &&
                                                                                                                independentJobData.data[stepCodeKey][stepOrder][variable.key] &&
                                                                                                                independentJobData.data[stepCodeKey][stepOrder][variable.key]['is_overridden']
                                                                                                            )}
                                                                                                        />
                                                                                                        <SideLabelForInput isValueFromOutputVar={isValueFromOutputVar} onHandleChange={() => handleChangeToPreviousJobValue({...independentJobData?.data?.[stepCodeKey]?.[stepOrder]?.[variable.key],stepCodeKey: stepCodeKey, stepOrder: stepOrder,mainKey:variable.key})} forIndependent={true}/>
                                                                                                        {isValueFromOutputVar && 
                                                                                                                <>
                                                                                                                    <div className='font-12' style={{ width: '100%' }}>
                                                                                                                        {variable.key}
                                                                                                                    </div>
                                                                                                                    <PreviousJobInputs 
                                                                                                                        data = {{
                                                                                                                            targetStep,
                                                                                                                            isValueFromOutputVar,
                                                                                                                            outputVarData,
                                                                                                                            taskList,
                                                                                                                            servicesList,
                                                                                                                            deploymentList,
                                                                                                                            stepList,
                                                                                                                            outputVarList,
                                                                                                                            stepCodeKey,
                                                                                                                            stepOrder,
                                                                                                                            stages,
                                                                                                                            mainKey: variable.key,
                                                                                                                        }}
                                                                                                                        onChangeHandler={handleChangeForOutputVariableForm}
                                                                                                                    />
                                                                                                                </>
                                                                                                        }
                                                                                                        <Grid item xs = {isValueFromOutputVar ? 4 : 12}>
                                                                                                            <Input
                                                                                                                type={isValueFromOutputVar ? 'select' :'text'}
                                                                                                                name={isValueFromOutputVar ? 'output_var' : variable.key}
                                                                                                                label={isValueFromOutputVar ? "Output Variable" :variable.key}
                                                                                                                placeholder=""
                                                                                                                onChangeHandler={isValueFromOutputVar ? 
                                                                                                                        outputVarList ? 
                                                                                                                        (e => handleChangeForOutputVariableForm(e,{...independentJobData?.data?.[stepCodeKey]?.[stepOrder]?.[variable.key],stepCodeKey: stepCodeKey, stepOrder: stepOrder,mainKey: variable.key }))
                                                                                                                    : 
                                                                                                                    false
                                                                                                                    :
                                                                                                                    (e => handleChange(e, stepCodeKey, stepOrder, 'value'))
                                                                                                                }
                                                                                                                list={isValueFromOutputVar ?  outputVarList?.map((item) => ({id: item.name,label: item.name})) : null}
                                                                                                                data={isValueFromOutputVar ? 
                                                                                                                    outputVarData
                                                                                                                :    
                                                                                                                {
                                                                                                                    [variable.key]: (independentJobData?.data
                                                                                                                        && independentJobData?.data[stepCodeKey] &&
                                                                                                                        independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                        independentJobData?.data[stepCodeKey][stepOrder][variable.key]) ?
                                                                                                                        independentJobData.data[stepCodeKey][stepOrder][variable.key]['value'] : null
                                                                                                                }}
                                                                                                                error={
                                                                                                                    (independentJobData?.data
                                                                                                                        && independentJobData?.data[stepCodeKey] &&
                                                                                                                        independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                        independentJobData?.data[stepCodeKey][stepOrder][variable.key] &&
                                                                                                                        independentJobData?.data[stepCodeKey][stepOrder][variable.key]['error']
                                                                                                                    ) ?

                                                                                                                        independentJobData.data[stepCodeKey][stepOrder][variable.key]['error'] : {}
                                                                                                                }
                                                                                                                mandatorySign={isValueFromOutputVar ? 
                                                                                                                        false
                                                                                                                    :
                                                                                                                    (independentJobData?.data
                                                                                                                    && independentJobData?.data[stepCodeKey] &&
                                                                                                                    independentJobData?.data[stepCodeKey][stepOrder] &&
                                                                                                                    independentJobData?.data[stepCodeKey][stepOrder][variable.key]) ?
                                                                                                                    independentJobData.data[stepCodeKey][stepOrder][variable.key]['is_required'] : false}
                                                                                                            />
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    :
                                                                                                    <Input
                                                                                                        type={variable.input_type === 'toggle' ? 'select' : variable.input_type}

                                                                                                        name={variable.key}
                                                                                                        list={variable.input_type === 'select' || 'toggle' ? variable.select_dropdown_options ?

                                                                                                            variable.select_dropdown_options.split(',').map(item => ({

                                                                                                                id: item.trim(), label: item.trim()
                                                                                                            }))

                                                                                                            : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]

                                                                                                            : []
                                                                                                        }
                                                                                                        label={variable.key}
                                                                                                        placeholder=""
                                                                                                        onChangeHandler={(e => handleChange(e, order))}
                                                                                                        data={state.data && state.data[order] ? state.data[order] : {}}
                                                                                                        error={state.error}
                                                                                                    />
                                                                                    }
                                                                                </Grid>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>

                                                            </Grid>

                                                            }
                                                        </>
                                                    )

                                                })
                                            }
                                        </>
                                    )
                                })
                                    :

                                    <div className="d-flex align-center justify-center font-12 full-width">No Runtime Environment Variables</div>
                            }
                            </>
                        }


                    </Grid>
                </>
            </div>

        </div>
    )
}

export default OverrideVariablesForNonDependentJobs;


const ExtraOverridableInput = ({ show, onChange, checked, name, stepCodeKey, stepOrder }) => {
    return (
        <>
            {
                show ?
                    <div style={{ position: 'absolute', right: '165px', top: '-12px' }}>
                        <Checkbox checked={checked} onChange={(e) => onChange(e, stepCodeKey, stepOrder, 'is_overridden'
                        )} name={name} value={""} />
                        <label style={{ color: '#505050' }} className='font-12 font-weight-400'>Overridable at Runtime</label>
                    </div> : null
            }
        </>
    )
}

const PreviousJobInputs = ({data,onChangeHandler}) => {
    const {targetStep,
        isValueFromOutputVar,
        outputVarData,
        taskList,
        servicesList,
        deploymentList,
        stepList,
        outputVarList,
        stepCodeKey,
        stepOrder,
        stages} = data;
    return (
        <>
            <Grid item xs={4}>
                    <Input
                        type='select'
                        name='stage'
                        list={stages ? stages.map(item => {
                            return { id: item.name, label: item.name }
                        }) : []}
                        label='Stage'
                        placeholder=""
                        onChangeHandler={e => onChangeHandler(e, {...data})}
                        data={outputVarData}
                        error={{}}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Input
                        type='select'
                        list={taskList ? taskList.map(task => { return { id: task.order, label: `${task.name || task?.task_type} order-${task.order}` } }) : []}
                        name='previous_job'
                        label='Previous Job'
                        placeholder=""
                        onChangeHandler={taskList ? e => onChangeHandler(e, {...data}) : false}
                        data={outputVarData}
                        error={{}}
                    />
                </Grid>
                {servicesList && 
                    <Grid item xs={4}>
                        <Input
                            type='select'
                            list={servicesList ? servicesList.map(task => { return { id: task.name, label: task.name } }) : []}
                            name='previous_job_service'
                            label='Previous Job Services'
                            placeholder=""
                            onChangeHandler={servicesList ? e => onChangeHandler(e, {...data}) : false}
                            data={outputVarData}
                            error={{}}
                        />
                    </Grid>
                }
                {deploymentList && 
                    <Grid item xs={4}>
                        <Input
                            type='select'
                            list={deploymentList ? deploymentList.map(cd => { return { id: cd, label: cd } }) : []}
                            name='deployment_name'
                            label='Select Deployment'
                            placeholder=""
                            onChangeHandler={deploymentList ? e => onChangeHandler(e, {...data}) : false}
                            data={outputVarData}
                            error={{}}
                        />
                    </Grid>
                }
                <Grid item xs={4}>
                    <Input
                        type='select'
                        list={stepList ? stepList.map(step => { return { id: step.order, label: `${step.step_name} order-${step.order}` } }) : []}
                        name='step_order'
                        label='Step Name'
                        placeholder=""
                        onChangeHandler={stepList ? e => onChangeHandler(e, {...data}) : false}
                        data={outputVarData}
                        error={{}}
                    />
                </Grid>
        </>
    )
}


