import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import { Alert } from '@mui/material';

import Snackbar from '@mui/material/Snackbar';
import { Input } from '../../../../components/genericComponents/Input';
import { Loading } from '../../../utils/Loading';
import { Link } from 'react-router-dom';
import { RemoveFromArray } from '../../../../util/util';

const AddMatrixDialog = (props) => {
    const classes = useStyles();
    const open = props.open;
    const editedItem = props.editedItem ? props.editedItem : null;
    const edit_id = props.edit_id ? props.edit_id : null;
    const handleClose = props.handleClose ? props.handleClose : () => { };
    const sendDataToParent = props.sendDataToParent ? props.sendDataToParent : () => {};
    const [state, setState] = useState({
        data: {
            fail_on:'increase'
        },
        error: {},
        showLeftPanel: true,
        loading_data: false,
        available_monitoring_resource_list: [],
        credential_list: [],
        validations: {
            
        }
    })
    const [showInput, setShowInput] = useState(false);
    const [data, setData] = useState({ data: {}, error: {} })
    const commonFunctions = getCommonFunctions(state, setState, {})
    const showInfoSection = JSON.parse(localStorage.getItem('monitoring_source_dialog_display_flag'));
    console.log("monitoring_source_dialog_display_flag", showInfoSection)
    var imgMapping = {
        1000: "/images/sourceMonitoringIcons/prometheus.svg",
        2000: "/images/sourceMonitoringIcons/new_relic.svg",
        3000: "/images/sourceMonitoringIcons/data_dog_log.svg",
        4000: "/images/sourceMonitoringIcons/app_dynamic.svg",
        5000: "/images/sourceMonitoringIcons/splunk.svg",
    };
    useEffect(() => {

        fetchAllAvailableResources()


    }, [edit_id])

    useEffect(() => {
        if (editedItem) {
            // Do something with the editedItem, e.g., update the internal state
            setDataInState(editedItem);
        }
    }, [editedItem]);
    const setDataInState = (newData) => {
        setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                ...newData,
            },
            error: {}, // Optionally reset errors when setting new data
        }));
    };

    const fetchAndSetDatatoState = () => {
        var requestInfo = {
            endPoint: GenerateURL({ id: edit_id }, properties.api.monitoring_resource_update),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        // requestInfo.endPoint = requestInfo.endPoint + "?all=true";

        InvokeApi(requestInfo, fetchAndSetDatatoStateSuccess, fetchAndSetDatatoStateFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true,
        }));
    }
    const fetchAndSetDatatoStateSuccess = (response) => {

        setState(new_state => ({
            ...new_state,
            loading_data: false,
            data: {
                ...new_state.data,
                ...response,
                credential_id: response?.credential_id?.id || null
            }
        }));

    }


    console.log(state.data, "fetchAndSetDatatoStateFailure")

    const fetchAndSetDatatoStateFailure = () => {
        setState(new_state => ({
            ...new_state,
            loading_data: false,
        }));
    }


    function fetchAllAvailableResources() {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.monitoring_resource),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        // requestInfo.endPoint = requestInfo.endPoint + "?all=true";

        InvokeApi(requestInfo, allAvailableResourcesSuccess, allAvailableResourcesFailure);
        setState(new_state => ({
            ...new_state,
            default_resource_loading: true,
        }));
    }

    function allAvailableResourcesSuccess(data) {
        fetchCredentials();
        if (data && data.length > 0) {
            var available_resources = data.length > 0 ? data.map(item => ({
                id: item.id,
                label: item.name,
                imgUrl: imgMapping[item.id] || "/images/sourceMonitoringIcons/remixicon-line.svg"
            })) : []
            setState(new_state => ({
                ...new_state,
                available_monitoring_resource_list: [...available_resources, { label: 'others', id: 'other', imgUrl: "/images/sourceMonitoringIcons/remixicon-line.svg" }],
                default_resource_loading: false,
                data: {
                    ...new_state.data,
                    resource: available_resources?.[1]?.id
                }
            }))
        } else {
            setState(new_state => ({
                ...new_state,
                available_monitoring_resource_list: [{ id: '', label: 'No Secret Added yet.' }],
                default_resource_loading: false,
            }));
        }

    }
    console.log(state.data.resource, "available_monitoring_resource_list")

    function allAvailableResourcesFailure(error, exception) {

        setState(new_state => ({
            ...new_state,
            default_resource_loading: false,
            error_in_load_credentials: error
        }));

    }


    function fetchCredentials() {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.credential_all),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        requestInfo.endPoint = requestInfo.endPoint + "?all=true";

        InvokeApi(requestInfo, CredentialsFetchSuccess, CredentialsFetchFailure);
        setState(new_state => ({
            ...new_state,
            credentials_status: "LOADING",
        }));
    }

    function CredentialsFetchSuccess(data) {
        if (edit_id) {
            fetchAndSetDatatoState()
        }
        if (data && data.results) {
            var credentials = data.results.length > 0 ? data.results.map(

                item => {
                    console.log(item, "fdsanfjknsajk")
                    return {
                        id: item.id,
                        label: item.name
                    }
                }
            ) : []
            setState(new_state => ({
                ...new_state,
                credential_list: credentials,
                credentials_status: "SUCCESS",
            }))
        } else {
            setState(new_state => ({
                ...new_state,
                credential_list: [{ id: '', label: 'No Secret Added yet.' }],
                credentials_status: "SUCCESS",
            }));
        }

    }

    function CredentialsFetchFailure(error, exception) {

        setState(new_state => ({
            ...new_state,
            credentials_status: "FAILED",
            error_in_load_credentials: error
        }));

    }
    const handleCloseLeftStip = () => {
        if (data?.data?.dont_show_again) {
            setDataToLocalStorage()
        }
        setState(new_state => ({
            ...new_state,
            showLeftPanel: false
        }));
    }
    function setDataToLocalStorage() {
        localStorage.setItem('monitoring_source_dialog_display_flag', data.data.dont_show_again);
    }
    function onChangeDoNotshow(e) {
        var key = e.target.name;
        var value = e.target.value;
        if (key == "dont_show_again") {
            value = !data.data.dont_show_again;
        }
        setData((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value,
            },
            error: {
                ...prevState.error,
                [key]: '', // Reset error on change
            },
        }));
    }




    const onChangeHandlerResource = (e) => {
        const { name, value } = e.target;
        if (name === "resource" && value == "other") {
            setShowInput(true)
        }
        setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [name]: Number(value),
            },
            error: {
                ...prevState.error,
                [name]: '', // Reset error on change
            },
        }));
    };



    function validateAndSave() {
        var result = ValidateDataSet(state.data, state.validations);

        if (result.valid) {
            var basic_details = {};
            basic_details = {
                ...state.data,
            };
            console.log(basic_details,"basic_detailsbasic_details___")
            sendDataToParent(basic_details)
            
        } else {
            setState((new_state) => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }));
        }
    }
    function onSaveFail(error) {
        var error = showErrorHandlerUpdated(error);
        setState((new_state) => ({
            ...new_state,
            loading_data: false,
            show_save_msg: "failed",
            error_in_save: error,
            error_msg: error,
        }));
    }
    function onSaveSuccess(response) {
        console.log("checking statetee")

        setState((new_state) => ({
            ...new_state,
            loading_data: false,
            error_in_save: null,
            canRedirect: true,
            show_save_msg: "success",
            error_msg: null,
        }));
    }

    const handleCloseAlert = () => {
        setState({ ...state, show_save_msg: null, });
    };
    const onChangeUpdateValidation = (e) => {
        var value = e.target.value;
        var name = e.target.name;
        console.log(value, name, "show_save_msg_show_save_msg")
        if(value === "increase"){
            setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [name]: value,
                },
                error: {
                    ...prevState.error,
                    [name]: '',
                },
                validations:{
                    decrease_percentage:[],
                    critical_decrease_percentage:[],
                    increase_percentage:[VALIDATION_TYPE_REQUIRED],
                    critical_increase_percentage:[VALIDATION_TYPE_REQUIRED]
                }
            }));
        }
        if(value === "decrease"){
            setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [name]: value,
                },
                error: {
                    ...prevState.error,
                    [name]: '',
                },
                validations:{
                    decrease_percentage:[VALIDATION_TYPE_REQUIRED],
                    critical_decrease_percentage:[VALIDATION_TYPE_REQUIRED],
                    increase_percentage:[],
                    critical_increase_percentage:[]
                }
            }));
        }
        if(value === "both"){
            setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [name]: value,
                },
                error: {
                    ...prevState.error,
                    [name]: '',
                },
                validations:{
                    decrease_percentage:[VALIDATION_TYPE_REQUIRED],
                    critical_decrease_percentage:[VALIDATION_TYPE_REQUIRED],
                    increase_percentage:[VALIDATION_TYPE_REQUIRED],
                    critical_increase_percentage:[VALIDATION_TYPE_REQUIRED]
                }
            }));
        }
        
    }
    return (
        <>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                className={`${classes.root} dialog-align-corner`}
                aria-labelledby="max-width-dialog-title"
            >
                <div className='d-grid ml-auto dialog-sub-component' style={showInfoSection ? { gridTemplateColumns: '550px' } : { gridTemplateColumns: '396px 550px' }}>
                    {
                        !showInfoSection &&

                        <div className={state.showLeftPanel ? 'left-panel-dialog bg-white position-relative' : 'left-panel-dialog-down'}>
                            <div className={'d-flex align-center space-between left-panel-header pd-10'} style={{ padding: '10px 20px' }} >
                                <p>Information</p>
                                <button
                                    className='btn btn-icon-only'
                                    onClick={handleCloseLeftStip}
                                >
                                    <span className='ri-close-line color-icon-secondary'></span>
                                </button>
                            </div>
                            <div className='pd-10' style={{ padding: '10px 20px' }}>
                                <p className='font-16 font-weight-600 color-primary mb-10'>Acceptable and Max/Critical Delta</p>
                                <p className='font-12 color-icon-secondary' style={{ color: '#404040' }}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply..
                                </p>
                            </div>
                            <div className='checkbox-only-divi' style={{ padding: '10px 20px' }}>
                                <Input
                                    type="simple-checkbox"
                                    name="dont_show_again"
                                    label="Dont show this again"
                                    mandatorySign
                                    data={data.data}
                                    error={data.error}
                                    onChangeHandler={onChangeDoNotshow}
                                />
                            </div>
                        </div>
                    }
                    <div className='right-panel-dialog bg-white'>
                        <div className='pd-20 font-18 font-weight-600 color-white d-flex align-center space-between' style={{ backgroundColor: '#0086ff' }}>
                            <p>{edit_id ? "Edit" : "Add"} Matrix for analysis</p>
                            <button
                                className='btn float-cancel-button'
                                style={showInfoSection ? { left: '396px' } : state.showLeftPanel ? {} : { left: '396px' }}
                                onClick={handleClose}
                            >
                                <span className='ri-close-line'></span>
                            </button>
                        </div>
                        <div className='' style={{padding:'10px 16px'}}>
                            {
                                state.loading_data ?
                                    <div className='' style={{ height: 400 }}>
                                        <Loading varient="light" />
                                    </div>
                                    :
                                    <>
                                        {state.default_resource_loading ?
                                            <Loading varient="light" /> :
                                            <Input
                                                type="select"
                                                name="resource"
                                                label="Select Stability Matix Group"
                                                mandatorySign
                                                data={state.data}
                                                list={state.available_monitoring_resource_list}
                                                error={state.error}
                                                onChangeHandler={onChangeHandlerResource}
                                            />
                                        }

                                        <Input
                                            type="select"
                                            name="matrix"
                                            label="Select Matrix"
                                            mandatorySign
                                            data={state.data}
                                            list={[{"label": "sample one", id:'sample one'},{"label":"sample two", "id":"sample two"}]}
                                            error={state.error}
                                            onChangeHandler={commonFunctions.onChangeHandler}
                                        />
                                        <div className='font-12 font-weight-bold border-bottom mb-10' style={{ color: '#aeaeae' }}>
                                            <p className='' style={{ marginBottom: '5px' }}>Analysis Data</p>
                                        </div>


                                        <Input
                                            type="select"
                                            name="fail_on"
                                            label="Fail On"
                                            mandatorySign
                                            list={[{id:'increase',label:'Increase'},{id:'decrease',label:'Decrease'},{id:'both',label:'Both'}]}
                                            data={state.data}
                                            error={state.error}
                                            onChangeHandler={onChangeUpdateValidation}
                                        />
                                        <div className='font-14 font-weight-500 mb-15' style={{ color: '#787878' }}>
                                            Please Define Acceptable and Max/Critical (%) Delta from Baseline
                                        </div>
                                        {
                                            state.data.fail_on =="increase" || state.data.fail_on =="both" ?
                                            <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                                            <div className='input-with-icon'>
                                                <Input
                                                    type="text"
                                                    name="increase_percentage"
                                                    label="Increase Percentage"
                                                    placeholder="20"
                                                    mandatorySign
                                                    data={state.data}
                                                    error={state.error}
                                                    onChangeHandler={commonFunctions.onChangeHandlerNumber}
                                                />
                                                <div  className='cent-icon'>
                                                    %
                                                </div>
                                            </div>
                                            <div className='input-with-icon'>
                                                <Input
                                                    type="text"
                                                    name="critical_increase_percentage"
                                                    label="Critical Increase Percentage"
                                                    placeholder="30"
                                                    mandatorySign
                                                    data={state.data}
                                                    error={state.error}
                                                    onChangeHandler={commonFunctions.onChangeHandlerNumber}
                                                />
                                                <div className='cent-icon'>
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                        :null
                                        }
                                        {
                                            state.data.fail_on == "decrease" || state.data.fail_on =="both" ?
                                            <div className='d-grid' style={{ gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                                            <div className='input-with-icon'>
                                                <Input
                                                    type="text"
                                                    name="decrease_percentage"
                                                    label="Decrease Percentage"
                                                    placeholder="20"
                                                    mandatorySign
                                                    data={state.data}
                                                    error={state.error}
                                                    onChangeHandler={commonFunctions.onChangeHandlerNumber}
                                                />
                                                <div  className='cent-icon'>
                                                    %
                                                </div>
                                            </div>
                                            <div className='input-with-icon'>
                                                <Input
                                                    type="text"
                                                    name="critical_decrease_percentage"
                                                    label="Critical Decrease Percentage"
                                                    placeholder="30"
                                                    mandatorySign
                                                    data={state.data}
                                                    error={state.error}
                                                    onChangeHandler={commonFunctions.onChangeHandlerNumber}
                                                />
                                                <div className='cent-icon'>
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                        :null
                                        }
                                        

                                    </>
                            }

                        </div>
                        <div className='footer-right-panel d-flex align-center justify-end'>
                            <button className='btn btn-outline-grey font-12 font-weight-500 d-flex align-center justify-center' onClick={validateAndSave}>Cancel</button>
                            <button className='btn btn-primary font-12 font-weight-500 d-flex align-center justify-center' onClick={validateAndSave}>Add Matrix</button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={state.show_save_msg === "success" || state.show_save_msg === "failed"}
                onClose={handleCloseAlert}
                autoHideDuration={3000}>
                {
                    state.show_save_msg === "success" ?
                        <>
                            <Alert severity="success"  variant="filled">
                                {state.success_msg}
                            </Alert>
                        </> : state.show_save_msg === "failed" ?
                            <Alert severity="error"  variant="filled">
                                {state.error_in_save}
                            </Alert>

                            : null
                }
            </Snackbar>
        </>
    )
}

export default AddMatrixDialog


const useStyles = makeStyles((theme) => ({
    root: {
        '&.dialog-align-corner':{
            '& .MuiPaper-root':{
                maxWidth:'1000px'
            }
            
        },
        '& .input-with-icon':{
            position:'relative',
            '& .cent-icon':{
                width: '40px',
                height: '44px',
                backgroundColor: '#fafafa',
                position:'absolute',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                fontSize:'14px',
                fontWeight:500,
                top: '22px',
                right: '1px',
                borderRadius: '0px 4px 4px 0px',
                borderLeft: '1px solid #b7b7b7'
            }
        },
        '& .left-panel-dialog': {
            width: '396px',
            transition: 'width 5s',

            '& .left-panel-header': {
                borderBottom: '1px solid #f1f1f1'
            },
            '& .checkbox-only-divi': {
                position: 'absolute',
                bottom: '10px'
            },
        },
        '& .left-panel-dialog-down': {
            width: '0px',
            overflow: 'hidden',
            transition: `'width 5s', 'overflow 1s'`,

        },
        '& .footer-right-panel': {
            backgroundColor: '#fafafa',
            padding: '20px',
            position: 'absolute',
            bottom: '0px',
            width: '550px',

            // width:'100%',
            '& button': {
                // marginLeft: 'auto'
            }
        }
    }
}));