import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';

import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';

import { Input } from '../../../../components/genericComponents/Input';

import { Loading } from '../../../utils/Loading';

import AddLinkIssuesDialog, { addLinkIssueDefaultState } from './AddLinkIssuesDialog';
import LinkIssueListSingleRow from './LinkIssueListSingleRow';

const LinkedIssuesDialog = props => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const default_job_template = props.default_job_template;
    const jira_reference_name_list = props.jira_reference_name_list;
    const selected_conditions = props.selected_conditions
    const variant = props.variant;
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : defaultLinkedIssueState())
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    const tableActionHandler = (data) => {
        console.log(data, "ffjafdjas")
        // add_link_issues
        setState(prevState => ({
            ...prevState,
            add_link_issues: {
                ...addLinkIssueDefaultState(),
                open: true,
                data: {
                    ...data,
                    jira_reference_name: data.jira_ref_name_key
                },
                enable_issue_link_type: data.jira_ref_name_key ? true : false,
                search_enabled: true,
                issue_link: state.link_issues_list,
                update_state_fn: true,
            }
        }))
    }

    const handleDeleteRow = (data) => {
        var total_arr = state.issue_link;
        total_arr.forEach((item, key) => {

            if (item.issue_link_type == data.issue_link_type) {

                total_arr.splice(key, 1)

            }

        })
        setState(prevState => ({
            ...prevState,
            link_issues_list: [
                ...total_arr
            ],
            add_link_issues: {
                ...addLinkIssueDefaultState(),
                issue_link: [
                    ...total_arr
                ]
            }
        }))
    }
    function removeChipFromList(chip, parent_key) {

        var current_arr = state.issue_link;
        current_arr.forEach((item, parent_item_key) => {
            if (item.issue_link_type == parent_key) {
                if (item.issue.length > 0) {
                    if (item.issue.length > 1) {
                        item.issue.forEach((single_chip, key) => {
                            if (single_chip.label == chip) {
                                item.issue.splice(key, 1)
                            }
                        })
                    } else {
                        current_arr.splice(parent_item_key, 1)

                    }
                }
                setState(prevState => ({
                    ...prevState,
                    issue_link: [
                        ...current_arr
                    ],
                }))
            }
        })
    }
    const onChangeHandler = (e) => {
        commonFunctions.toggleState(e)
    }
    const fetchFinalLinkIssuesList = (link_issues_arr) => {
        var final_arr = link_issues_arr && link_issues_arr.length > 0 ? link_issues_arr : []
        setState(new_state => ({
            ...new_state,
            link_issues_list: final_arr
        }))
    }
    return (
        <div className={classes.formCard} >
            <div className={classes.custom_css}>
                <div style={{ background: '#fff', height: '44px' }} className="card-header with-btn custom-switch">
                    <div className="heading">Do you want to link Jira Issues?</div>
                    <Input
                        type="switch"
                        label=""
                        name="linked_issues"
                        data={state.data}
                        error={state.error}
                        onChangeHandler={onChangeHandler}
                    />

                </div>
            </div>

            {
                state.data.linked_issues ?
                    <div className=" h-250" style={state.link_issues_list && state.link_issues_list.length > 0 ? { minHeight: 102 } : { minHeight: 120 }}>


                        {
                            state.loading_issues_type_list ?
                                <Loading varient="light" />
                                :



                                <AddLinkIssuesDialog
                                    prev_state={state.add_link_issues}
                                    fetchFinalIssuesList={fetchFinalLinkIssuesList}
                                    jira_reference_name_list={jira_reference_name_list}
                                    inherits={state.child_inherits.add_link_issues} />

                        }
                        {
                            state.link_issues_list && state.link_issues_list.length > 0 ?
                                state.link_issues_list.map((single_list_item, order) => {
                                    return <> <LinkIssueListSingleRow
                                        key={order}
                                        data={single_list_item}
                                        handleEdit={() => { tableActionHandler(single_list_item) }}
                                        handleDelete={handleDeleteRow}
                                    />
                                    </>
                                })
                                : null
                        }

                    </div> : null
            }

        </div>
    )
}

LinkedIssuesDialog.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};


export default LinkedIssuesDialog;

export function defaultLinkedIssueState() {
    return {
        update_state_fn: false,
        data: {
            linked_issues: false,
        },
        link_issues_list: [],
        error: {},
        issue_link: [],
        child_inherits: {
            add_link_issues: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            }
        }
    }
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    root: {
        '& .h-300': {
            height: 350,
            overflowy: 'scroll'
        }
    },
    formCard: {
        backgroundColor: '#fff',
        margin: '10px',
        border: '1px solid #eaeaea'
        // '& .MuiTextField-root': {
        //     '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        //         padding: '0px 14px!important'
        //     }

        // }

    },

    cardHeader: {
        border: '1px solid #eaeaea',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    },
    cardfooter: {
        border: '1px solid #eaeaea',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    },
    custom_css: {
        '& .heading': {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '17.07px',
            color: '#505050'
        },
        '& .custom-switch': {
            borderTop: '0px solid transparent!important',
            '& .card-header': {
                border: 'none!important',
                height: '49px',
                '& .card-header': {

                }
            }
        }
    }
}));
