import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { fontSize, styled } from "@mui/system";
import Grid from '@mui/material/Grid';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import GenerateURL from '../../../../util/APIUrlProvider';
import { PostData } from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';

const AddVariableDialog = (props) => {
    const open = props.open;
    const handleClose = props.handleClose;
    const variable_list = props.variable_list;
    const inherits = props.inherits ? props.inherits : null;
    const metric_type = props.metric_type ? props.metric_type : null;
    const databaseGroupId = props.databaseGroupId ? props.databaseGroupId : null;
    const selected_database = props.selected_database ? props.selected_database : null;
    const variable_list_with_dataype = props.variable_list_with_dataype ? props.variable_list_with_dataype : null;
    const selectedVariableForEdit = props.selectedVariableForEdit ? props.selectedVariableForEdit : null;
    const addParameter = props.addParameter ? props.addParameter : null;
    const type = props.type ? props.type : null;

    let value_type_list = [
        {
            id: "INTEGER",
            label: "INTEGER"
        },
        {
            id: "BOOLEAN",
            label: "BOOLEAN"
        },
        {
            id: "STRING",
            label: "STRING"
        },
    ];

    const [state, setState] = useState({
        data: {
            // selected_value_type: "INTEGER",
        },
        error: {},
        showLeftPanel: true,
    });
    const [data, setData] = useState({
        data: {},
        error: {},
        validations: {
            display_name: [VALIDATION_TYPE_REQUIRED],
            selected_variable: [VALIDATION_TYPE_REQUIRED],
        }
    });

    console.log(data,selectedVariableForEdit,variable_list,state, "abdhbhsbh")

    useEffect(() => {
        if (state.data.selected_value_type == "INTEGER") {
            setState((new_state) => ({
                ...new_state,
                validations: {
                    ...new_state.validations,
                    minimum_range: [VALIDATION_TYPE_REQUIRED],
                    maximum_range: [VALIDATION_TYPE_REQUIRED]
                }
            }))
        }
        if (state.data.selected_value_type == "STRING") {
            setState((new_state) => ({
                ...new_state,
                validations: {
                    ...new_state.validations,
                    string_value: [VALIDATION_TYPE_REQUIRED]
                }
            }))
        }
        if (state.data.selected_value_type == "BOOLEAN") {
            setState((new_state) => ({
                ...new_state,
                validations: {
                    ...new_state.validations,
                    boolean_value: [VALIDATION_TYPE_REQUIRED]
                }
            }))
        }
    }, [state.data.selected_value_type])

    const [value, setValue] = React.useState([20, 37]);

    useEffect(() => {
        if (variable_list) {
            let list = Object.keys(variable_list);

            let option_list = [];
            list.forEach((item) => {
                let obj = {
                    id: item,
                    label: item
                };

                option_list.push(obj);
            });

            setData((new_state) => ({
                ...new_state,
                variable_list: option_list
            }));
        }
    }, []);

    // useEffect(() => {
    //     if (selectedVariableForEdit && data.variable_list) {
    //         setState((new_state) => ({
    //             ...new_state,
    //             data: {
    //                 ...new_state.data,
    //                 display_name: selectedVariableForEdit.display_name,
    //                 // selected_variable: getSelectedVariable()
    //             }
    //         }));
    //     }
    // }, [selectedVariableForEdit, data.variable_list]);

    useEffect(() => {
        if (selectedVariableForEdit && data.variable_list) {
            const {
                display_name,
                name,
                value,
                max_value,
                min_value,
                threshold,
                warning_at,
                error_at,
                data_type
            } = selectedVariableForEdit;
    
            setState((new_state) => ({
                ...new_state,
                data: {
                    ...new_state.data,
                    display_name,
                    selected_variable: name,
                    selected_value_type: data_type,
                    minimum_range: min_value,
                    maximum_range: max_value,
                    string_value: threshold,
                    boolean_value: threshold,
                    integer_value: value,
                    warning_at,
                    error_at
                }
            }));
    
            setValue([warning_at, error_at]);
        }
    }, [selectedVariableForEdit, data.variable_list]);

    useEffect(() => {
        setState((new_state) => ({
            ...new_state,
            warning_at: value[0],
            error_at: value[1]
        }));
    }, [value]);

    useEffect(() => {
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                selected_value_type: getSelectedValueType()
            }
        }));
        if (selectedVariableForEdit) {
            if (selectedVariableForEdit.data_type == "INTEGER") {
                setValue([selectedVariableForEdit.warning_at, selectedVariableForEdit.error_at]);
            }
            if (selectedVariableForEdit.data_type == "BOOLEAN" || selectedVariableForEdit.data_type == "STRING") {
                setState((new_state) => ({
                    ...new_state,
                    data: {
                        ...new_state.data,
                        threshold: selectedVariableForEdit.threshold.toUpperCase()
                    }
                }));
            }
        }
    }, [state.data.selected_variable]);

    function getSelectedValueType() {
        let value_type = variable_list_with_dataype && variable_list_with_dataype[state.data.selected_variable];
        return value_type;
    }

    const [showLoading, setShowLoading] = useState(false);
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    const showInfoSection = JSON.parse(localStorage.getItem('show_ms_other_info'));

    const handleCloseLeftStip = () => {
        if (data?.data?.dont_show_again) {
            setDataToLocalStorage();
        }
        setState(new_state => ({
            ...new_state,
            showLeftPanel: false
        }));
    };
    function setDataToLocalStorage() {
        localStorage.setItem('show_ms_other_info', data.data.dont_show_again);
    }

    function onChangeDoNotshow(e) {
        var key = e.target.name;
        var value = e.target.value;
        if (key == "dont_show_again") {
            value = !data.data.dont_show_again;
        }
        setData((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value,
            },
            error: {
                ...prevState.error,
                [key]: '', // Reset error on change
            },
        }));
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function valuetext(value) {
        return `${value}`;
    }

    const onChangeHandlerForBooleanValue = (e) => {
        console.log(e.target.name, e.target.value, "asdcvgdsvchgdsv");
        let name = e.target.name;
        let value = e.target.value;
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [name]: value,
            },
            error: {
                ...new_state.error,
                [name]: "",
            }
        }));
    }

    function validateAndContinue() {
        var result = ValidateDataSet(state.data, state.validations);
        console.log(result, "slcbjhsabvcgavds")
        if (result.valid) {
            if (type == "database_template") {
                let data = {
                    type: metric_type,
                    display_name: state.data.display_name,
                    name: state.data.selected_variable,
                    data_type: state.data.selected_value_type,
                }
                if (state.data.selected_value_type == "INTEGER") {
                    data.warning_at = state.warning_at
                    data.error_at = state.error_at
                    data.min_value = parseInt(state.data.minimum_range)
                    data.max_value = parseInt(state.data.maximum_range)
                }
                if (state.data.selected_value_type == "BOOLEAN") {
                    data.threshold = state.data.boolean_value
                }
                if (state.data.selected_value_type == "STRING") {
                    data.threshold = state.data.string_value
                }

                addParameter(data);
            } else {
                let url_temp = GenerateURL({ database_group_id: databaseGroupId, database_id: selected_database }, properties.api.add_db_metric_variable);
                let post_data = {
                    type: metric_type,
                    display_name: state.data.display_name,
                    name: state.data.selected_variable,
                    data_type: state.data.selected_value_type,
                }
                if (state.data.selected_value_type == "INTEGER") {
                    post_data.warning_at = state.warning_at
                        post_data.error_at = state.error_at
                        data.min_value = parseInt(state.data.minimum_range)
                        data.max_value = parseInt(state.data.maximum_range)
                }
                if (state.data.selected_value_type == "BOOLEAN") {
                    post_data.threshold = state.data.boolean_value
                }
                if (state.data.selected_value_type == "STRING") {
                    post_data.threshold = state.data.string_value
                }
                console.log(post_data, "avsdcgvagcvadgc");
                PostData(url_temp, post_data, handleSuccessVariableAdd, handleFailedVariableAdd);
                setState(new_state => ({
                    ...new_state,
                    loader: true,
                }));
            }
        }
    }

    function handleSuccessVariableAdd(response) {
        console.log(response, "acvgadvcvvagchd");
        setState(new_state => ({
            ...new_state,
            loader: false,
        }));
        handleClose;
    }

    function handleFailedVariableAdd(error) {
        setState(new_state => ({
            ...new_state,
            loader: false,
        }));
    }

    console.log(state, "ascbhdsbchds");

    function handleMinimunRangeInput(e) {
        const value = e.target.value;
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                minimum_range: value
            }
        }));
        // setValue([parseInt(state.data.minimum_range) + 20,parseInt(state.data.minimum_range) + 37 ])
    }

    function handleMaximumRangeInput(e) {
        const value = e.target.value;
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                maximum_range: value
            }
        }));
    }

    return (
        <StyledDialog
            fullWidth={true}
            maxWidth={'lg'}
            open={open}
            onClose={handleClose}
            className={`dialog-align-corner`}
            aria-labelledby="max-width-dialog-title"
        >
            <div className='d-grid ml-auto dialog-sub-component' style={showInfoSection ? { gridTemplateColumns: '650px' } : { gridTemplateColumns: '396px 650px' }}>
                {
                    !showInfoSection &&
                    <div className={state.showLeftPanel ? 'left-panel-dialog bg-white position-relative' : 'left-panel-dialog-down'}>
                        <div className={'d-flex align-center space-between left-panel-header pd-10'} style={{ padding: '10px 20px' }}>
                            <p>Information</p>
                            <button
                                className='btn btn-icon-only'
                                onClick={handleCloseLeftStip}
                            >
                                <span className='ri-close-line color-icon-secondary'></span>
                            </button>
                        </div>
                        <div className='pd-10' style={{ padding: '10px 20px' }}>
                            <p className='font-16 font-weight-600 color-primary mb-10'>What are Variables?</p>
                            <p className='font-12 color-icon-secondary' style={{ color: '#404040' }}>
                                TLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply..
                            </p>
                        </div>
                        <div className='checkbox-only-divi' style={{ padding: '10px 20px' }}>
                            <Input
                                type="simple-checkbox"
                                name="dont_show_again"
                                label="Dont show this again"
                                mandatorySign
                                data={data.data}
                                error={data.error}
                                onChangeHandler={onChangeDoNotshow}
                            />
                        </div>
                    </div>
                }
                <div className='right-panel-dialog bg-white'>
                    <>
                        <div className='font-18 font-weight-600 color-white d-flex align-center space-between' style={{ backgroundColor: '#0086ff', padding: '13.5px 20px' }}>
                            <p>Add Variable</p>
                            <button
                                className='btn float-cancel-button'
                                style={showInfoSection ? { left: '396px' } : state.showLeftPanel ? {} : { left: '396px' }}
                                onClick={handleClose}
                            >
                                <span className='ri-close-line'></span>
                            </button>
                        </div>
                        {
                            showLoading ?
                                <Loading varient="light" />
                                :
                                <>
                                    <div className='right-panel-form' style={{ padding: '10px 16px' }}>
                                        <Grid container spacing={2} style={{ marginTop: "20px" }}>
                                            <Grid item lg={12}>
                                                <Input
                                                    type={"text"}
                                                    name={"display_name"}
                                                    label="Display Name"
                                                    placeholder="Enter Name"
                                                    data={state.data}
                                                    error={state.error}
                                                    info="xyz"
                                                    mandatorySign
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                />
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Input
                                                    type={"select"}
                                                    name={"selected_variable"}
                                                    label="Select Variable"
                                                    placeholder=""
                                                    list={data.variable_list}
                                                    data={state.data}
                                                    error={state.error}
                                                    info="xyz"
                                                    mandatorySign
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                />
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Input
                                                    type={"select"}
                                                    name={"selected_value_type"}
                                                    label="Value Type"
                                                    placeholder=""
                                                    list={value_type_list}
                                                    data={state.data}
                                                    error={state.error}
                                                    info="xyz"
                                                    mandatorySign
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                />
                                            </Grid>
                                            {
                                                state.data.selected_value_type == "INTEGER" &&
                                                <>
                                                    <Grid item lg={6}>
                                                        <Input
                                                            name="minimum_range"
                                                            label="Specify minimum value"
                                                            varient="handle-keys"
                                                            type="number"
                                                            placeholder="Value"
                                                            data={state.data}
                                                            error={state.error}
                                                            onChangeHandler={handleMinimunRangeInput}
                                                        // onKeyPress={onKeyEnter}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6}>
                                                        <Input
                                                            name="maximum_range"
                                                            label="Specify maximum value"
                                                            varient="handle-keys"
                                                            type="number"
                                                            placeholder="Value"
                                                            data={state.data}
                                                            error={state.error}
                                                            onChangeHandler={handleMaximumRangeInput}
                                                        // onKeyPress={onKeyEnter}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                state.data.selected_value_type == "INTEGER" ?
                                                    <Grid item lg={12}>
                                                        <div className="slider">
                                                            <Typography id="range-slider" gutterBottom style={{ fontWeight: "500", fontSize: "15px" }}>
                                                                Set Threshold
                                                            </Typography>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <div style={{ display: "flex", alignItems: "center", border: "1px solid #E6E6E6", borderRadius: "4px", padding: "8px 10px", marginRight: "8px" }}>
                                                                    {value[0]}
                                                                </div>
                                                                <CustomSlider
                                                                    value={value}
                                                                    onChange={handleChange}
                                                                    valueLabelDisplay="auto"
                                                                    aria-labelledby="range-slider"
                                                                    getAriaValueText={valuetext}
                                                                    min={state.data.minimum_range ? parseInt(state.data.minimum_range) : 0}
                                                                    max={state.data.maximum_range ? parseInt(state.data.maximum_range) : 100}
                                                                />
                                                                <div style={{ display: "flex", alignItems: "center", border: "1px solid #E6E6E6", borderRadius: "4px", padding: "8px 10px", marginLeft: "8px" }}>
                                                                    {value[1]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    :
                                                    state.data.selected_value_type == "BOOLEAN" ?
                                                        <div style={{ marginLeft: "16px" }}>
                                                            <Input
                                                                type="switch"
                                                                padding="0px"
                                                                name="boolean_value"
                                                                label="Desired State"
                                                                data={state}
                                                                onChangeHandler={onChangeHandlerForBooleanValue}
                                                                error={{}}
                                                            />
                                                        </div>
                                                        :
                                                        state.data.selected_value_type == "STRING" ?
                                                            <Grid item lg={12}>
                                                                <Input
                                                                    type={"text"}
                                                                    name="string_value"
                                                                    label="Enter Value"
                                                                    placeholder="Enter value"
                                                                    data={state.data}
                                                                    error={state.error}
                                                                    info="xyz"
                                                                    mandatorySign
                                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                                />
                                                            </Grid>
                                                            :
                                                            <></>
                                            }
                                        </Grid>
                                    </div>
                                    <div className='footer-right-panel d-flex align-center justify-end' style={{ gap: "12px" }}>
                                        <button className='back-btn'>SET DEFAULT VALUE</button>
                                        <button className='btn btn-primary font-12 font-weight-500 d-flex align-center justify-center' onClick={validateAndContinue}>TEST & ADD SOURCE</button>
                                    </div>
                                </>
                        }

                    </>


                </div>
            </div>
        </StyledDialog>
    )
}

const StyledDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        position: 'absolute',
        right: 0,
        width: '1100px',
        maxWidth: '1100px',
    },
    '& .input-with-icon': {
        position: 'relative',
        '& .cent-icon': {
            width: '40px',
            height: '44px',
            backgroundColor: '#fafafa',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px',
            fontWeight: 500,
            top: '22px',
            right: '1px',
            borderRadius: '0px 4px 4px 0px',
            borderLeft: '1px solid #b7b7b7'
        }
    },
    '& .left-panel-dialog': {
        width: '396px',
        transition: 'width 5s',

        '& .left-panel-header': {
            borderBottom: '1px solid #f1f1f1'
        },
        '& .checkbox-only-divi': {
            position: 'absolute',
            bottom: '10px'
        },
    },
    '& .left-panel-dialog-down': {
        width: '0px',
        overflow: 'hidden',
        transition: `'width 5s', 'overflow 1s'`,

    },
    '& .right-panel-form': {
        padding: '10px 16px',
        maxHeight: 'calc(100% - 127px)', // Assuming the footer is 80px tall, adjust accordingly
        overflowY: 'auto',

        "& .slider": {
            width: 600
        }
    },
    '& .footer-right-panel': {
        backgroundColor: '#fafafa',
        padding: '20px',
        position: 'absolute',
        bottom: '0px',
        width: '650px',

        // width:'100%',
        '& button': {
            // marginLeft: 'auto'
        }
    },
    "& .back-btn": {
        padding: "8px 16px",
        textTransform: "uppercase",
        height: "40px",
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: "600",
        color: "#124D9B",
        borderRadius: "6px",
        border: "1px solid #9DC0EE",
        backgroundColor: "#ffffff",
        transition: "all 0.4s ease-in-out",

        "&:hover": {
            backgroundColor: "#0086FF",
            color: "#ffffff",
            borderColor: "#0086FF"
        }
    },

    "& .slider": {
        "& .input-component": {
            marginBottom: "0px"
        }
    }
});

const CustomSlider = styled(Slider)(({ value, min, max }) => {
    const percentage0 = ((value[0] - min) / (max - min)) * 100; // Percentage of `value[0]`
    const percentage1 = ((value[1] - min) / (max - min)) * 100; // Percentage of `value[1]`

    return {
        "& .MuiSlider-rail": {
            background: `linear-gradient(to right, 
          #2EBE79 0%,
          #2EBE79 ${percentage0}%, 
          #E53737 ${percentage1}%, 
          #E53737 100%)`,
        },
        "& .MuiSlider-track": {
            backgroundColor: "#E1941D !important",
        },
        "& .MuiSlider-thumb": {
            backgroundColor: "#FFFFFF !important", // Thumb background color
            boxShadow: "rgba(0, 0, 0, 0.25) !important", // Thumb shadow
            color: "#000000 !important", // Thumb color
        },
        "& .MuiSlider-valueLabel": {
            backgroundColor: "#E1941D !important", // Label background color
            color: "#FFFFFF !important", // Label text color
            "& span": {
                color: "#FFFFFF !important", // Ensure the text color inside the label is also white
                fontSize: "16px"
            },
        },
        "& .MuiSlider-valueLabelLabel": {
            color: "#FFFFFF"
        }
    };
});

export default AddVariableDialog;