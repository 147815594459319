import { styled } from '@mui/material'
import { color } from 'highcharts'
import React from 'react'

const GlobalSearchHint = ({OnDismiss}) => {

    
  return (
    <div style={{ position: 'relative', width:'291px', borderRadius: '4px',boxShadow: "0px 44px 64px 0px #00000040" }}>
        <div className="arrow" style={{
            position: 'absolute',
            top: '-8px', // Positioning the arrow above the Popper content
            right: '-8px',
            transform: 'translateX(-50%)',
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '8px solid #0086FF',
            //backgroundColor:'#0086FF'
        }}></div>
        <Image 
            src='/images/global_search.gif'
            alt='global_search'
        />
        <TextContainer>
            <span className='headerText'>
                Search Anything with global search
            </span>
            <span className='sub-info'>
                Use Search filter to Search Applications, Services, Environments, Pipelines & Infrastuctures
            </span>
            <div className='d-flex justify-end align-center'>
                <DismissBtn onClick={OnDismiss}>
                    Dismiss
                </DismissBtn>
            </div>
        </TextContainer>
    </div>
  )
}

export default GlobalSearchHint

const Image = styled('img')({
    borderRadius: '4px',
    width: '291px',
    height: '160px',
    objectFit: 'cover',
});

const TextContainer = styled('div')({
    padding: '12px',
    background: 'linear-gradient(279.02deg, #0086FF 16.66%, #005099 98.35%)',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    display:'flex',
    flexDirection:'column',
    gap:'9px',
    '& .headerText': {
        color: '#fff',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight:'19.5px',
    },
    '& .sub-info': {
        color: '#fff',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight:'18px',
    },
});

const DismissBtn = styled('span')({
    border: '1px solid #9DC0EE',
    background: '#FFFFFF',
    color: '#124D9B',
    fontSize: '12px',
    fontWeight: 600,
    cursor: 'pointer',
    textTransform:'uppercase',
    padding:'8px 16px',
    borderRadius:'6px',
    '&:hover': {
        background: '#124D9B',
        color:'#FFFFFF',
        borderColor:'#124D9B',
    }
});