import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { GetAuth } from '../../../../../../util/security';
import { Tooltip } from '@mui/material';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';
const LivenessAndReadinessOverview = props => {
    const prev_state = props.prev_state;
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const [showLoading, setShowLoading] = useState(false);
    const [state, setState] = useState({
    });
    const extraProps = props.extraProps;
    const auto_approval_flag = extraProps.auto_approval_flag;
    const application_id = extraProps.application_id;
    const service_id = extraProps.service_id;
    const show_sidecar_overview = extraProps.show_sidecar_overview;
    const env_cd_id = extraProps.env_cd_id;
    const service_env_data = extraProps.service_env_data;
    const component_env_id = extraProps.component_env_id;
    useEffect(() => {
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state])
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);

    return (
        <>
            {
                show_sidecar_overview ?
                    <div className="section-service-overview">
                        <div className="d-flex align-center space-between">
                            <div className="overview-heading">
                                Rolling Update
                            </div>
                            {is_permitted ?

                                state.status == "DRAFT" ?
                                <Link
                                to={{
                                  pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/sidecars/edit`,
                                  search: `?selectedTabOrder=6` +
                                          `&sidecar_id=${state.id}` +
                                          `&env_cd_id=${env_cd_id}` +
                                          `&service_env_data=${encodeURIComponent(service_env_data)}`,
                                }}
                              >
                                <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                              </Link>
                              :
                                    <EditDetailsConfirmationDialog
                                        application_id={application_id}
                                        service_id={service_id}
                                        auto_approval_flag={auto_approval_flag}
                                        component_env_id={component_env_id}
                                        selectedTabOrder={6}
                                        sidecar_id={state.id}
                                        env_cd_id = {env_cd_id}
                                        service_env_data = {service_env_data}
                                    />

                                : <Tooltip title="You are not allowed to perform this action" >
                                    <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                                </Tooltip>}

                        </div>
                        <div className="overview-heading">
                            Liveness
                        </div>
                        <div className="overview-card-section grid-temp-2-widthfull" style={{ marginTop: '10px', marginBottom: '10px ' }}>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Liveness ?
                                    </p>
                                    <span className={state.env_cd_liveness_probe ? "overview-chip-yes" : "overview-chip-no"} >
                                        {state.env_cd_liveness_probe ? "Yes" : "No"}
                                    </span>
                                </div>
                                <div className="overview-card-value">
                                    {state.env_cd_liveness_probe && state.env_cd_liveness_probe.probe_type === "EXEC" ?
                                        <p className="overview-card-value-text">
                                            Command : {state.env_cd_liveness_probe ? state.env_cd_liveness_probe.exec_cmd : <span className="no-data__msg ">NA</span>}
                                        </p>
                                        :
                                        <>
                                            <p className="overview-card-value-text">
                                                URL or Path : {state.env_cd_liveness_probe ? state.env_cd_liveness_probe.path : <span className="no-data__msg ">NA</span>}
                                            </p>
                                            <p className="overview-card-value-text">
                                                Port : {state.env_cd_liveness_probe ? state.env_cd_liveness_probe.port : <span className="no-data__msg ">NA</span>}
                                            </p>
                                        </>
                                    }

                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Time Delay &amp; Period
                                    </p>
                                </div>
                                <div className="overview-card-value">
                                    <p className="overview-card-value-text">
                                        <div className="grid-view-4">
                                            <p>Delay Time(sec)</p>
                                            <p>Time Period</p>
                                            <p>Timeout in</p>
                                            <p>Failure</p>
                                            <p>{state.env_cd_liveness_probe ? state.env_cd_liveness_probe.initial_delay_seconds + "s" : <span className="no-data__msg ">NA</span>}</p>
                                            <p>{state.env_cd_liveness_probe ? state.env_cd_liveness_probe.period_seconds + "s" : <span className="no-data__msg ">NA</span>}</p>
                                            <p>{state.env_cd_liveness_probe ? state.env_cd_liveness_probe.timeout_seconds + "s" : <span className="no-data__msg ">NA</span>} </p>
                                            <p>{state.env_cd_liveness_probe ? state.env_cd_liveness_probe.failure_threshold : <span className="no-data__msg ">NA</span>} </p>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="overview-heading">
                            Readiness
                        </div>

                        <div className="overview-card-section grid-temp-2-widthfull" style={{ marginTop: '10px', marginBottom: '10px ' }}>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Readiness ?
                                    </p>
                                    <span className={state.env_cd_readiness_probe ? "overview-chip-yes" : "overview-chip-no"} >
                                        {state.env_cd_readiness_probe ? "Yes" : "No"}
                                    </span>
                                </div>
                                <div className="overview-card-value">
                                    {state.env_cd_readiness_probe && state.env_cd_readiness_probe.probe_type === "EXEC" ?
                                        <p className="overview-card-value-text">
                                            Command : {state.env_cd_readiness_probe ? state.env_cd_readiness_probe.exec_cmd : <span className="no-data__msg ">NA</span>}
                                        </p>
                                        :
                                        <>
                                            <p className="overview-card-value-text">
                                                URL or Path : {state.env_cd_readiness_probe ? state.env_cd_readiness_probe.path : <span className="no-data__msg ">NA</span>}
                                            </p>
                                            <p className="overview-card-value-text">
                                                Port : {state.env_cd_readiness_probe ? state.env_cd_readiness_probe.port : <span className="no-data__msg ">NA</span>}
                                            </p>
                                        </>
                                    }

                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Time Delay &amp; Period
                                    </p>
                                </div>
                                <div className="overview-card-value">
                                    <p className="overview-card-value-text">
                                        <div className="grid-view-4">
                                            <p>Delay Time(sec)</p>
                                            <p>Time Period</p>
                                            <p>Timeout in</p>
                                            <p>Failure</p>
                                            <p>{state.env_cd_readiness_probe ? state.env_cd_readiness_probe.initial_delay_seconds + "s" : <span className="no-data__msg ">NA</span>}</p>
                                            <p>{state.env_cd_readiness_probe ? state.env_cd_readiness_probe.period_seconds + "s" : <span className="no-data__msg ">NA</span>}</p>
                                            <p>{state.env_cd_readiness_probe ? state.env_cd_readiness_probe.timeout_seconds + "s" : <span className="no-data__msg ">NA</span>} </p>
                                            <p>{state.env_cd_readiness_probe ? state.env_cd_readiness_probe.failure_threshold : <span className="no-data__msg ">NA</span>}</p>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="section-service-overview">
                        <div className="d-flex align-center space-between">
                            <div className="overview-heading">
                                Rolling Update
                            </div>
                            {is_permitted ?

                                state.status == "DRAFT" ?
                                <Link
                                to={{
                                  pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/cd/${cd_id}/edit`,
                                  search: `?selectedTabOrder=8&selected_cd_id=${extraProps?.selected_cd_data?.id}`,
                                }}
                              >
                                <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                              </Link>
                               :
                                    <EditDetailsConfirmationDialog
                                        application_id={application_id}
                                        service_id={service_id}
                                        auto_approval_flag={auto_approval_flag}
                                        component_env_id={component_env_id}
                                        selectedTabOrder={8}
                                        cd_data={prev_state}
                                        selected_cd_id = {extraProps?.selected_cd_data?.id}
                                    />

                                : <Tooltip title="You are not allowed to perform this action" >
                                    <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                                </Tooltip>}

                        </div>
                        <div className="overview-heading">
                            Liveness
                        </div>
                        <div className="overview-card-section grid-temp-2-widthfull" style={{ marginTop: '10px', marginBottom: '10px ' }}>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Liveness ?
                                    </p>
                                    <span className={state.env_cd_liveness_probe ? "overview-chip-yes" : "overview-chip-no"} >
                                        {state.env_cd_liveness_probe ? "Yes" : "No"}
                                    </span>
                                </div>
                                <div className="overview-card-value">
                                    {state.env_cd_liveness_probe && state.env_cd_liveness_probe.probe_type === "EXEC" ?
                                        <p className="overview-card-value-text">
                                            Command : {state.env_cd_liveness_probe ? state.env_cd_liveness_probe.exec_cmd : <span className="no-data__msg ">NA</span>}
                                        </p>
                                        :
                                        <>
                                            <p className="overview-card-value-text">
                                                URL or Path : {state.env_cd_liveness_probe ? state.env_cd_liveness_probe.path : <span className="no-data__msg ">NA</span>}
                                            </p>
                                            <p className="overview-card-value-text">
                                                Port : {state.env_cd_liveness_probe ? state.env_cd_liveness_probe.port : <span className="no-data__msg ">NA</span>}
                                            </p>
                                        </>
                                    }

                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Time Delay &amp; Period
                                    </p>
                                </div>
                                <div className="overview-card-value">
                                    <p className="overview-card-value-text">
                                        <div className="grid-view-4">
                                            <p>Delay Time(sec)</p>
                                            <p>Time Period</p>
                                            <p>Timeout in</p>
                                            <p>Failure</p>
                                            <p>{state.env_cd_liveness_probe ? state.env_cd_liveness_probe.initial_delay_seconds + "s" : <span className="no-data__msg ">NA</span>}</p>
                                            <p>{state.env_cd_liveness_probe ? state.env_cd_liveness_probe.period_seconds + "s" : <span className="no-data__msg ">NA</span>}</p>
                                            <p>{state.env_cd_liveness_probe ? state.env_cd_liveness_probe.timeout_seconds + "s" : <span className="no-data__msg ">NA</span>} </p>
                                            <p>{state.env_cd_liveness_probe ? state.env_cd_liveness_probe.failure_threshold : <span className="no-data__msg ">NA</span>} </p>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="overview-heading">
                            Readiness
                        </div>

                        <div className="overview-card-section grid-temp-2-widthfull" style={{ marginTop: '10px', marginBottom: '10px ' }}>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Readiness ?
                                    </p>
                                    <span className={state.env_cd_readiness_probe ? "overview-chip-yes" : "overview-chip-no"} >
                                        {state.env_cd_readiness_probe ? "Yes" : "No"}
                                    </span>
                                </div>
                                <div className="overview-card-value">
                                    {state.env_cd_readiness_probe && state.env_cd_readiness_probe.probe_type === "EXEC" ?
                                        <p className="overview-card-value-text">
                                            Command : {state.env_cd_readiness_probe ? state.env_cd_readiness_probe.exec_cmd : <span className="no-data__msg ">NA</span>}
                                        </p>
                                        :
                                        <>
                                            <p className="overview-card-value-text">
                                                URL or Path : {state.env_cd_readiness_probe ? state.env_cd_readiness_probe.path : <span className="no-data__msg ">NA</span>}
                                            </p>
                                            <p className="overview-card-value-text">
                                                Port : {state.env_cd_readiness_probe ? state.env_cd_readiness_probe.port : <span className="no-data__msg ">NA</span>}
                                            </p>
                                        </>
                                    }

                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Time Delay &amp; Period
                                    </p>
                                </div>
                                <div className="overview-card-value">
                                    <p className="overview-card-value-text">
                                        <div className="grid-view-4">
                                            <p>Delay Time(sec)</p>
                                            <p>Time Period</p>
                                            <p>Timeout in</p>
                                            <p>Failure</p>
                                            <p>{state.env_cd_readiness_probe ? state.env_cd_readiness_probe.initial_delay_seconds + "s" : <span className="no-data__msg ">NA</span>}</p>
                                            <p>{state.env_cd_readiness_probe ? state.env_cd_readiness_probe.period_seconds + "s" : <span className="no-data__msg ">NA</span>}</p>
                                            <p>{state.env_cd_readiness_probe ? state.env_cd_readiness_probe.timeout_seconds + "s" : <span className="no-data__msg ">NA</span>} </p>
                                            <p>{state.env_cd_readiness_probe ? state.env_cd_readiness_probe.failure_threshold : <span className="no-data__msg ">NA</span>}</p>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>

    )
}

LivenessAndReadinessOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default LivenessAndReadinessOverview;