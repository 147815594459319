import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import PipelineAlphabetLogo from "./PipelineAlphabetLogo";
import HorizontalTab from "../../../components/wizardComponents/HorizontalTab";
import JobParamsFormv3 from "./JobParamsFormv3";
import GenerateURL from "../../../util/APIUrlProvider";
import properties from "../../../properties/properties";
import InvokeApi, { PostData } from "../../../util/apiInvoker";
import { useNavigate } from "react-router-dom";


const PipelineRuntimeParamsUpdate = () => {
    const classes = useStyles();
    const { pipeline_id } = useParams();
    const [state, setState] = useState({
        selectedTabOrder: null,
        pipelineData: null,
        stages: null,
        stageList: [],
        loadingStages: false,
        loadingStagesJobsData: false,
        stageWiseData: {},
        runTimeParamsData: null,
        finalPostData: {}
    })

    console.log(pipeline_id, 'pip_02323l')

    const history = useNavigate()

    useEffect(() => {
        if (state.stages != null && state.runTimeParamsData != null) {
            onhanldeTabClick(0, null)
        }
    }, [state.stages, state.runTimeParamsData])

    useEffect(() => {
        if (pipeline_id != undefined) {
            getRuntimeParamsForPipeline(pipeline_id)
            getPipelineData(pipeline_id)
            getStagesData(pipeline_id)
        }

    }, [pipeline_id])

    const getRuntimeParamsForPipeline = (pipeline_id) => {
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline_id }, properties.api.get_runtime_params_for_pipeline),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (response) => {
            console.log(response, 'res_p02233232')
            setState(prevState => ({
                ...prevState,
                runTimeParamsData: response
            }))

        }, (error) => {
            console.log(error, 'err_02232323')
        });
    }

    const getStageName = (dataPath) => {

        return dataPath.split('.')[0]
    }

    const getStageDataForForms = (jobStepJson, isDependentJob) => {

        console.log(jobStepJson, isDependentJob, 'fun_ddd')
        if (isDependentJob) {
            let data = {}
            if (jobStepJson) {
                Object.keys(jobStepJson)?.forEach(serviceId => {
                    data[serviceId] = {}
                    const stepCodeObject = jobStepJson[serviceId]
                    Object.keys(stepCodeObject)?.forEach(stepCode => {
                        data[serviceId][stepCode] = {}
                        const stepOrderObject = stepCodeObject[stepCode]
                        Object.keys(stepOrderObject)?.forEach(stepOrder => {
                            data[serviceId][stepCode][stepOrder] = {}
                            const stepsData = stepOrderObject[stepOrder]
                            let stepKeyDataObject = {}
                            stepsData?.forEach(step => {
                                const stepInfo = step
                                stepInfo['error'] = {}
                                stepKeyDataObject[step.key] = stepInfo
                            })
                            data[serviceId][stepCode][stepOrder] = stepKeyDataObject
                        })
                    })
                })
            }
            return data
        }

        else {
            let data = {}
            if (jobStepJson) {
                Object.keys(jobStepJson)?.forEach((stepCode) => {
                    data[stepCode] = {}
                    const stepOrderData = jobStepJson[stepCode]
                    stepOrderData && Object.keys(stepOrderData)?.forEach(stepOrder => {
                        data[stepCode][stepOrder] = {}
                        const stepsData = stepOrderData[stepOrder]
                        let stepKeyDataObject = {}
                        stepsData?.forEach(step => {
                            const stepInfo = step
                            stepInfo['error'] = {}
                            stepKeyDataObject[step.key] = stepInfo
                        })
                        data[stepCode][stepOrder] = stepKeyDataObject
                    })
                })
            }
            return data
        }
    }

    const handleTabChangeData = (stageList, stageOrder, stageData) => {

        console.log('drrm--Ols', stageList, stageOrder, stageData)
        // return data by selectedSta
        const selectedStageName = stageList[stageOrder]?.captionText
        let dataByStage = {}
        Object.keys(stageData)?.forEach(stageNamePath => {
            const stageName = getStageName(stageNamePath)
            if (stageName == selectedStageName) {
                console.log(stageData[stageNamePath], 'dt_pkindd')
                let targetStageData = stageData[stageNamePath]
                targetStageData['selected_services'] = []
                const isDependentJob = targetStageData?.job_json?.is_dependent
                const jobStepJson = targetStageData.job_step_json
                const dataForForm = getStageDataForForms(jobStepJson, isDependentJob)
                targetStageData['data'] = dataForForm
                dataByStage[stageNamePath] = stageData[stageNamePath]
            }
        })
        return dataByStage
    }

    const onhanldeTabClick = (order, stageList, runtimeParams) => {

        if (state.stageWiseData && state.stageWiseData[order] != undefined) {
            // without saving moving from one tab to another tab
            console.log(state.selectedTabOrder, 'previous_tab')
            setState(prevState => ({
                ...prevState,
                selectedTabOrder: order,
                stageWiseData: {
                    ...prevState.stageWiseData,
                    [prevState.selectedTabOrder]: { ...prevState.finalPostData[prevState.selectedTabOrder] }
                },
            }))
        }
        else {
            const stgeData = handleTabChangeData(stageList ? stageList : state.stageList, order, runtimeParams ? runtimeParams : state.runTimeParamsData)
            console.log(stgeData, 'stage_kdldslss')
            console.log(state.stageWiseData, 'df_kflfl5')
            setState(prevState => ({
                ...prevState,
                selectedTabOrder: order,
                stageWiseData: {
                    ...prevState.stageWiseData,
                    [order]: stgeData
                },
                finalPostData: {
                    ...prevState.finalPostData,
                    [order]: stgeData
                }
            }))
        }
    }

    function getPipelineData(pipeline_id) {

        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline_id }, properties.api.pipeline_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (response) => {

            console.log(response, 'res_pons323')
            if (response) {
                setState(prevState => ({
                    ...prevState,
                    pipelineData: response
                }))
            }
        }, (error) => {
            console.log(error, 'err_errr')
        })
    }

    function getStagesData(pipeline_id) {

        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline_id }, properties.api.stages_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (response) => {

            console.log(response, 'res_02323fsf')

            if (response) {

                const listData = response.map((stage, index) => {
                    return { order: index, mainText: `Stage ${index + 1}`, captionText: stage.name }
                })

                // const listData = [{ order: 0, mainText: `Stage 1`, captionText: 'zolo-one' },
                // { order: 1, mainText: `Stage 2`, captionText: 'zolo-two' },
                // { order: 2, mainText: `Stage 3`, captionText: 'zolo-three' },
                // { order: 3, mainText: `Stage 4`, captionText: 'zolo-four' },
                // { order: 4, mainText: `Stage 5`, captionText: 'zolo-five' }]
                setState(prevState => ({
                    ...prevState,
                    stages: response,
                    stageList: listData,
                    selectedTabOrder: 0
                }))
            }
        }, (error) => {
            console.log(error, 'err_errr')
        })
    }

    const onHandleSaveAndNext = () => {

        console.log(state.stageList, 'gbbd')
        const currentChangedDataForOrder = state.stageWiseData[state.selectedTabOrder]
        const order = state.selectedTabOrder + 1
        const stgeData = handleTabChangeData(state.stageList, order, state.runTimeParamsData)
        console.log(stgeData, 'steg_data')

        if (state.selectedTabOrder == state.stageList?.length - 1) {
            setState(prevState => ({
                ...prevState,
                finalPostData: {
                    ...prevState.finalPostData,
                    [state.selectedTabOrder]: {
                        ...currentChangedDataForOrder
                    }
                }
            }))

        }
        else {
            setState(prevState => ({
                ...prevState,
                selectedTabOrder: prevState.selectedTabOrder + 1,
                stageWiseData: {
                    ...prevState.stageWiseData,
                    [prevState.selectedTabOrder + 1]: stgeData
                },
                finalPostData: {
                    ...prevState.finalPostData,
                    [state.selectedTabOrder]: {
                        ...currentChangedDataForOrder
                    }
                }
            }))
        }
    }

    const handleBackButton = () => {
        if (state.selectedTabOrder == 0) {
            history.goBack()
        }
        else
            onhanldeTabClick(state.selectedTabOrder - 1)
    }

    const handleSaveRunpipeline = () => {
        const updatedPostData = {}
        console.log(state, 'inside_dt)ddd')
        let finalPostData = state.finalPostData
        if (finalPostData[state.selectedTabOrder] == undefined || state.stageList?.length == 1) { // if user did not save and direct hit save and run pipeline to save end tab values in final post. 
            finalPostData[state.selectedTabOrder] = state?.stageWiseData[state.selectedTabOrder]
        }
        console.log(finalPostData, 'data_0001_gh')
        Object.keys(finalPostData).forEach(stageOrder => {
            const stageData = finalPostData[stageOrder]
            Object.keys(stageData).forEach(stageNamePath => {
                const stage = stageData[stageNamePath]
                console.log(stage, 'dataallP')
                let updatedStageData = { ...stage }

                const isDependentJob = stage.job_json?.is_dependent
                if (isDependentJob) {
                    let jobStepJson = updatedStageData.job_step_json
                    const dependentEntity = updatedStageData.job_json.dependent_entity

                    if (dependentEntity == 'SERVICE') {
                        const services = updatedStageData.job_json.entity_resource_data[dependentEntity]
                        const existEntity = services?.filter(service => {
                            if (updatedStageData.selected_services.includes(service.name)) {
                                return service
                            }
                        })
                        console.log(updatedStageData, existEntity, dependentEntity, 'boom boom')
                        updatedStageData.job_json.entity_resource_data[dependentEntity] = existEntity
                        delete updatedStageData.selected_services
                    }

                    Object.keys(jobStepJson)?.forEach(serviceId => {
                        const serviceOrderObje = jobStepJson[serviceId]

                        Object.keys(serviceOrderObje)?.forEach(stepCode => {

                            const stepOrderDataObj = serviceOrderObje[stepCode]
                            Object.keys(stepOrderDataObj)?.forEach(stepOrder => {
                                const stepData = stepOrderDataObj[stepOrder]
                                stepData?.forEach(envData => {
                                    const key = envData.key
                                    const alreadyValue = envData.value
                                    let value = alreadyValue
                                    if (updatedStageData.data != undefined && updatedStageData.data[serviceId][stepCode][stepOrder][key].value != undefined) {
                                        value = updatedStageData.data[serviceId][stepCode][stepOrder][key].value
                                    }
                                    envData['value'] = value
                                })
                            })
                        })
                    })
                }
                else {

                    let jobStepJson = updatedStageData.job_step_json
                    Object.keys(jobStepJson)?.forEach(stepCode => {
                        const stepOrderDataObj = jobStepJson[stepCode]
                        Object.keys(stepOrderDataObj)?.forEach(stepOrder => {
                            const stepData = stepOrderDataObj[stepOrder]
                            stepData?.forEach(envData => {
                                const key = envData.key
                                const alreadyValue = envData.value
                                let value = alreadyValue
                                if (updatedStageData.data != undefined && updatedStageData.data[stepCode][stepOrder][key].value != undefined) {
                                    value = updatedStageData.data[stepCode][stepOrder][key].value
                                }

                                envData['value'] = value
                            })
                        })
                    })
                }
                updatedPostData[stageNamePath] = updatedStageData
            })
        })
        console.log(updatedPostData, 'updff_0wewew')

        let fetchedRuntimeParams = { ...state.runTimeParamsData }
        console.log(fetchedRuntimeParams, 'dfg_ssd')
        Object.keys(updatedPostData)?.forEach(stagePathData => {
            if (fetchedRuntimeParams[stagePathData] != undefined) {
                fetchedRuntimeParams[stagePathData] = updatedPostData[stagePathData]
            }
        })
        console.log(fetchedRuntimeParams, 'data_001_ghm')
        runPipelineWithRuntimeparams(fetchedRuntimeParams)
    }

    const onPipelineRunSuccess = (response) => {
        console.log(response, 'reslpwpew')
        history.goBack()

    }
    const onPipelineFailure = (error) => {

        console.log(error, 'err-ps')
    }

    const runPipelineWithRuntimeparams = (postData) => {
        PostData(GenerateURL({ pipeline_id: pipeline_id }, properties.api.trigger_pipeline), postData, onPipelineRunSuccess, onPipelineFailure);
    }

    console.log(location, 'location_05644')
    console.log(state, 'stage0322323_ll_l')

    return (
        <div className={classes.root}>
            <div className="top-header">
                <div className="left-block">
                    <div className="mr-12">
                        <PipelineAlphabetLogo alphabet={state?.pipelineData ? state.pipelineData.name.split("")[0] : 'P'} />
                    </div>
                    <div>
                        <p className="title-label  mb-5 capital-text">
                            {state.pipelineData?.name ? state.pipelineData.name : 'Loading...'}
                        </p>
                        <p className="caption-title ">
                            Please Fill the required Parameters for runtime
                        </p>
                    </div>
                </div>

                <div className="right-block">
                    <span className="ri-circle-fill" style={{ fontSize: '13px', color: '#407BCA', marginRight: '6px', marginTop: '3px' }} />

                    <span style={{
                        color: '#505050'
                    }} className="capital-text mandatory-info font-12 font-weight-500 " >
                        Show Mandatory Only
                    </span>
                </div>
            </div>
            <HorizontalTab
                tabDataList={state.stageList}
                onClickHandler={() => { }}
                variant="pipeline-stages-params"
                tabState={
                    {
                        selectedTabOrder: state.selectedTabOrder
                    }
                }
            />
            <div className="form-container-div">
                <p className="info-title  mb-20">REQUIRED PARAMETERS FOR STAGE 3</p>
                <div className="white-contaier">
                    {/* {
                        state.stages && state.stages[state.selectedTabOrder]?.tasks?.map((item, index) => {
                            console.log(item, 'it)23232')
                            return <div style={{ width: "100%" }}>
                                <JobParamsFormv3 jobOrder={index + 1} jobName={item.name} />
                            </div>
                        })
                    } */}

                    {
                        state.selectedTabOrder != undefined && state.stageWiseData[state.selectedTabOrder] != undefined && Object.keys(state.stageWiseData[state.selectedTabOrder])?.map((stageTaskPath, index) => {
                            const jobData = state.stageWiseData[state.selectedTabOrder][stageTaskPath]
                            const pathArray = stageTaskPath.split('.')
                            const jobName = pathArray[pathArray.length - 1]
                            return <div key={stageTaskPath} style={{ width: "100%" }}>
                                <JobParamsFormv3
                                    jobData={jobData}
                                    jobOrder={index + 1}
                                    jobName={jobName}
                                    selectedTabOrder={state.selectedTabOrder}
                                    stageTaskPath={stageTaskPath}
                                    parentState={state.stageWiseData}
                                    setParentState={setState}
                                    finalPostData={state.finalPostData}
                                />
                            </div>
                        })
                    }

                    <div className="bottom-action d-flex justify-end">
                        <button onClick={handleBackButton} className="back-button-div mr-15">
                            <label onCli className="capital-text  action-label">back</label>
                        </button>
                        {
                            state.stageList?.length > 1 &&
                            <button onClick={onHandleSaveAndNext} className="save-next-div mr-15">
                                <label className="capital-text  action-label">{state.stageList?.length - 1 == state.selectedTabOrder ? 'save' : 'save & next'}</label>
                            </button>
                        }
                        {
                            state.stageList?.length - 1 == state.selectedTabOrder &&
                            <button onClick={handleSaveRunpipeline} className="save-run-pipeline mr-15  ">
                                <label className="capital-text  action-label">save & run pipeline</label>
                            </button>
                        }

                    </div>
                </div>

            </div>
        </div>
    )
}

export default PipelineRuntimeParamsUpdate;

const useStyles = makeStyles((theme) => ({
    root: {

        '& .top-header': {
            display: 'flex',
            height: '72px',
            padding: '12px 20px 12px 20px',
            background: '#FFFFFF',
            boxShadow: '0px 4px 24px 0px #0000001F',
            justifyContent: 'space-between'
        },
        '& .left-block': {
            display: 'flex',
            '& .title-label': {
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '17px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#2F2F2F',
            },
            '& .caption-title': {
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '15px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#787878',
            }
        },
        '& .right-block': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .form-container-div': {

            padding: '20px'
        },
        '& .info-title': {
            fontSize: '14px',
            fontWeight: '600',
            color: '#505050',

        },
        ' & .white-contaier': {
            background: '#fff',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            '& .bottom-action': {
                width: '100%',
                height: '72px',
                padding: '16px',
                background: '#FAFAFA',
                marginTop: 'auto',
                '& .back-button-div': {
                    width: '68px',
                    height: '40px',
                    padding: '8px 16px 8px 16px',
                    borderRadius: '6px',
                    background: '#fff',
                    border: '1px solid #9DC0EE',
                    '& .action-label': {
                        fontSize: '12px',
                        fontWeight: '500',
                        color: '#124D9B',
                        cursor: 'pointer',
                        // boxShadow: '0px 2px 1px 0px #00000040'
                    }

                },
                '& .save-next-div': {
                    width: '112px',
                    height: '40px',
                    padding: '11px 16px 11px 16px',
                    borderRadius: '6px',
                    background: '#0086FF',
                    border: 0,
                    '& .action-label': {
                        fontSize: '12px',
                        fontWeight: '500',
                        color: '#FFFFFF',
                        cursor: 'pointer',
                        // boxShadow: '0px 2px 1px 0px #00000040'
                    }
                },
                '& .save-run-pipeline': {
                    border: 0,
                    width: '169px',
                    height: '40px',
                    padding: '11px 16px 11px 16px',
                    borderRadius: '6px',
                    background: '#FEA111',
                    '& .action-label': {
                        fontSize: '12px',
                        fontWeight: '600',
                        color: '#FFFFFF',
                        cursor: 'pointer',
                        // boxShadow: '0px 2px 1px 0px #00000040'
                    }
                }
            }
        },

    }
}));