import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import { ErrorComponent } from "../../utils/Error";

import GenerateURL, { GenerateSearchURL } from "../../../util/APIUrlProvider";
import properties from "../../../properties/properties";
import InvokeApi from "../../../util/apiInvoker";
import { Link, useParams } from "react-router-dom";
import EnvCard from "./EnvCard";
import { Loading } from "../../utils/Loading";
import Pagination from "../../../components/Pagination";
import SearchBar from "../../../components/SearchBar";
import { PostData } from "../../../util/apiInvoker";
import { Tooltip } from '@mui/material';
import { GetAuth } from "../../../util/security";
import GenericSkeleton from "../../../components/genericComponents/Skeletons/GenericSkeleton";
import Delete from "../../../components/genericComponents/Delete";
import { ExandableComponentList } from "../../../components/hoc/expandableList";
import useFetchPermissions from "../../../components/customHooks/useFetchPermissions";
import PageHeader from "../../../components/PageHeader";
import PaginationTwo from "../../../components/PaginationTwo";
import BlankPage from "../../../components/BlankPage";
const EnvList = (props) => {
  const classes = useStyles();
  const permissions = useFetchPermissions();
  const [state, setState] = useState({
    open: false,
    environment_list: [],
    total_page: "",
    curr_page: "",
    compareData: {},
    count: 0,
    counter: 0,
  });

  const toggleOpen = () => {
    setState({
      ...state,
      open: !state.open,
    });
  };
  const { application_id } = useParams();
  const applicationId = application_id;
  const [showLoading, setShowLoading] = useState(false);

  var url = GenerateURL({ application_id: applicationId }, properties.api.save_env, true)
  const is_permitted = permissions?.POST.includes(url);

  console.log(is_permitted, "skdbfhdsbhfbsdfhbhsd")


  useEffect(() => {

    fetchEnvironmentInfo();
  }, [applicationId, state.counter]);

  function fetchEnvironmentInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: applicationId },
        properties.api.env_dashboard_new
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
      search_query_name: data ? (data.name ? data.name : "") : "",
    }));
    setShowLoading(true);
    InvokeApi(
      requestInfo,
      EnvironmentInfoFetchSuccess,
      EnvironmentInfoFetchFailure
    );
  }

  function EnvironmentInfoFetchSuccess(data) {
    console.log(data, "data success");
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      environment_list: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
          ? (Number(data.count) / 10 + 1).toFixed(0) - 1
          : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: 1,
    }));
    setShowLoading(false);
  }
  function EnvironmentInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
    setShowLoading(false);
  }

  function fetchPrevEnvironmentInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: applicationId },
        properties.api.env_listing
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));
    setShowLoading(true);
    InvokeApi(
      requestInfo,
      EnvironmentPrevInfoFetchSuccess,
      EnvironmentPrevInfoFetchFailure
    );
  }

  function EnvironmentPrevInfoFetchSuccess(data) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      environment_list: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
          ? (Number(data.count) / 10 + 1).toFixed(0) - 1
          : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page - 1),
    }));
    setShowLoading(false);
  }
  function EnvironmentPrevInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
    setShowLoading(false);
  }

  function fetchNextEnvironmentInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: applicationId },
        properties.api.env_listing
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));
    setShowLoading(true);
    InvokeApi(
      requestInfo,
      EnvironmentNextInfoFetchSuccess,
      EnvironmentNextInfoFetchFailure
    );
  }

  function EnvironmentNextInfoFetchSuccess(data) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      environment_list: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
          ? (Number(data.count) / 10 + 1).toFixed(0) - 1
          : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page + 1),
    }));
    setShowLoading(false);
  }

  function EnvironmentNextInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
    setShowLoading(false);
  }

  //start of page code----------------------------------------------

  const fetchPageEnvironmentInfo = (enteredPageNumber) => {
    // console.log(enteredPageNumber, "page number coming from pagination");

    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: applicationId },
        properties.api.env_listing
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&offset=" +
        (enteredPageNumber - 1) * 10;
    }

    setState((new_state) => ({
      ...new_state,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));
    setShowLoading(true);
    var current_page = enteredPageNumber;
    InvokeApi(
      requestInfo,
      (response) => {
        EnvironmentPageInfoFetchSuccess(response, current_page);
      },
      EnvironmentPageInfoFetchFailure
    );
  };

  function EnvironmentPageInfoFetchSuccess(data, count) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      environment_list: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
          ? (Number(data.count) / 10 + 1).toFixed(0) - 1
          : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: Number(count),
    }));
    setShowLoading(false);
  }

  function EnvironmentPageInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
    setShowLoading(false);
  }

  //end of page code---------------------------------------------------------------

  const invokeSearch = (data) => {
    fetchEnvironmentInfo(data);
  };

  function addToCompare(e, data) {
    if (e.target.value === true) {
      setState((new_state) => ({
        ...new_state,
        compareData: {
          ...new_state.compareData,
          [e.target.name]: data,
        },
      }));
    } else {
      var temp = state.compareData;
      delete temp[e.target.name];
      setState((new_state) => ({
        ...new_state,
        compareData: temp,
      }));
    }
  }

  if (Object.entries(state.compareData).length > 2) {
    window.alert("You cannot select more than 2 environments");
  }

  // const addEnvironmentLink = "/application/" + applicationId.toString() + "/Environment/add";
  window.localStorage.setItem("envs", JSON.stringify(state.compareData));
  console.log("env-list", state);

  function sendApprovalRequest(id) {
    var post_url = GenerateURL({}, properties.api.approval_request);

    var post_obj = {
      "name": "project_env",
      "entity_id": id
    }

    PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function sendApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_request_success_msg: msg,
    }));

  }

  function sendApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      deployment_failed_msg: error
    }));

  }

  function confirmForApproval(id) {
    var post_url = GenerateURL({}, properties.api.confirm_approval);

    var post_obj = {
      "name": "project_env",
      "entity_id": id
    }

    PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function confirmApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_confirm_success_msg: msg,
    }));
  }

  function confirmApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      approval_failed_msg: error
    }));
  }

  function refreshState() {
    setState(new_state => ({
      ...new_state,
      counter: new_state.counter + 1
    }));
  }

  function MakeExpandableComponent(data,iteration_count){ //data is array
    let expandable_component = null;
    let ui_tabs = [];
    if (data && data.length > 0) {
      data.map((job, key) => {
        ui_tabs.push(
          <div className={"chip-sq chip-sq-primary-outline"} key={job}>
            {
              
                <Tooltip title={job} arrow>
                  <p className="text-grey-83 text-ellipsis" style={{maxWidth:'65px'}}>{job}</p>
                </Tooltip>
            }

          </div>

        )
      })
      expandable_component = (
        <>
          +{data.length - iteration_count}
        </>
      );
    }

    return {ui_tabs,expandable_component};
  }
  return (
    <> 
      {state.data_loading === "SUCCESS" ? (
        <div className={classes.root}>
          {state.environment_list.length > 0 ? (
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <PageHeader
                  heading={"Environment Overview"}
                  subHeading={"Displaying overall summary of the environments attached with this project."}
                  commonDivMargin={true}
                  icon="ri-cloud-line"
                  backgroundColor='#FFFFFF'
                  primaryButton={{
                    actionType: 'link',
                    action: `/application/${application_id}/env/add`,
                    text: 'Add Environment',
                    icon: <span className="ri-add-line font-14"></span>,
                    buttonClass: 'btn-primary',
                    isPermitted: is_permitted
                  }}
                />
              </Grid>
              <Grid container spacing={4} lg={12} item>
                <Grid container spacing={2}  paddingLeft={4} paddingTop={1}>
                  <Grid item lg={5}>
                    <SearchBar
                      search_data={state.search_data}
                      default_filter={{ name: "name", label: "Name" }}
                      search_call_back={invokeSearch}
                      clear_search_callback={fetchEnvironmentInfo}
                    />
                  </Grid>
                </Grid>
                {state.environment_list.map((data) => {
                  let edit_delete_url = GenerateURL({ env_id: data && data.id }, properties.api.get_env,true);
                  let is_edit_permitted = edit_delete_url ? permissions?.POST.includes(edit_delete_url) : null;
                  let is_delete_permitted = edit_delete_url ? permissions?.DELETE.includes(edit_delete_url) : null;
                  let iteration_count = 2;
                  let { ui_tabs: env_service, expandable_component } = MakeExpandableComponent(data.component_name_list, iteration_count);
                  let { ui_tabs: env_registry, expandable_component: registry_expandable_component } = MakeExpandableComponent(data?.registry_names || [], iteration_count);

                  return (
                    <Grid item lg={4} className="pt-0">
                      <div className="card border-radius-8 border-color-default" style={{ marginTop: "24px" }}>
                        <div className="card-body pb-0" style={{ minHeight: "282px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                          <div className="header-part pd-10 border-bottom border-color-default">
                            <Link className="text-anchor-blue" to={"/application/" + application_id + "/environment/" + data.id + "/dashboard"}>
                              <p className="font-18 font-family-nunito">{data.name}</p>
                            </Link>
                          </div>
                          <div className="pr-10 pl-10">
                            <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
                              <p>Cluster:</p>
                              <p className="text-grey-83">{data.cluster ? data.cluster.name : "NA"}</p>
                            </div>
                            <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
                              <p>Type:</p>
                              <p className="badge badge-pill badge-sq badge-primary badge-ellipsis font-12 border-radius avtar" style={{ padding: '3px 5px' }}>{data.environment_master.name}</p>
                            </div>
                            <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
                              <p>Namespace:</p>
                              <p className="text-grey-83">{data.project_env_namespace ? data.project_env_namespace.namespace.name : "NA"}</p>
                            </div>
                            <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
                              <p>Vault Token:</p>
                              <p className="text-grey-83">{data.vault_access_token ? data.vault_access_token.name : "NA"}</p>
                            </div>
                            <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
                              <p>Sonar:</p>
                              <p className="text-grey-83">{data.sonar_access_token ? data.sonar_access_token.name : "NA"}</p>
                            </div>
                            <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default env-service">
                              <p>Service:</p>
                              {data.component_name_list.length > 0 ? (
                                <span className='d-flex'>
                                  <ExandableComponentList compoenent_list={env_service} iteration_count={iteration_count} expandable_component={expandable_component} />
                                </span>
                              ) : (
                                <p className="text-grey-83">NA</p>
                              )}
                            </div>
                            <div className="d-flex align-center space-between pd-5 font-12 border-color-default env-service">
                              <p>Registry:</p>
                              {data.registry_names && data.registry_names.length > 0 ? (
                                <span className='d-flex'>
                                  <ExandableComponentList compoenent_list={env_registry} iteration_count={iteration_count} expandable_component={registry_expandable_component} />
                                </span>
                              ) : (
                                <p className="text-grey-83">NA</p>
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-center justify-flexend border-top border-color-default pd-5">
                            <div className="d-flex space-between" style={{ width: "82px" }}>
                              {is_edit_permitted ? (
                                <Link className="text-anchor-blue" to={`/application/${application_id}/env/${data && data.id}/edit`}>
                                  <button className='btn-sq-icon-primary mr-5'>
                                    <span className='ri-edit-line vertical-align-super'></span>
                                  </button>
                                </Link>
                              ) : (
                                <Tooltip title="You are not allowed to perform this action">
                                  <button className='btn-sq-icon-primary mr-5' disabled>
                                    <span className='ri-edit-line vertical-align-super' style={{ color: '#818078' }}></span>
                                  </button>
                                </Tooltip>
                              )}
                              <Delete
                                display_data_name={data.name}
                                varient="env_delete"
                                data={{ entity_id: data && data.id, name: "project_env" }}
                                refresh={fetchEnvironmentInfo}
                                api_link={GenerateURL({ env_id: data && data.id }, properties.api.get_env)}
                                is_edit_permited={is_delete_permitted}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
              <div className="width-full pt-20">
                <PaginationTwo
                  total_count={state.total_page}
                  current_page_count={state.curr_page}
                  next={state.next}
                  previous={state.previous}
                  count={state.count}
                  on_previous_click={() => fetchPrevEnvironmentInfo(null, state.previous)}
                  on_next_click={() => fetchNextEnvironmentInfo(null, state.next)}
                  on_pageNumber_click={(pageNumber) => fetchPageEnvironmentInfo(pageNumber)}
                />
              </div>
            </Grid>
          ) : 
          state.search_query_name ? 
              <BlankPage
                  text={`No Env found with the name : ${state.search_query_name}`}
                  pageIcon={<span className="ri-cloud-line font-28 text-anchor-blue"></span>}
                  //action="/cluster/add/firstpage"
                  backgroundColor="#FFFFFF"
                  btnLabel={"Add Environment"}
                  primaryButton={
                      {
                          actionType: 'button',
                          action: fetchEnvironmentInfo,
                          text: 'Refresh',
                          buttonClass: 'btn-primary m-auto'
                      }
                  }
              />
          :
            <BlankPage
                  text={"You have 0 Environment associated with this application"}
                  pageIcon={<span className="ri-cloud-line font-28 text-anchor-blue"></span>}
                  //action="/cluster/add/firstpage"
                  backgroundColor="#FFFFFF"
                  btnLabel={"Add Environment"}
                  primaryButton={
                      {
                          actionType: 'link',
                          action: `/application/${application_id}/env/add` ,
                          icon: <span className="ri-add-line font-14"></span>,
                          text: 'Add Environment',
                          buttonClass: 'btn-primary m-auto',
                          isPermitted:is_permitted
                      }
                  }
              />
          }
        </div>
      ) : state.data_loading === "FAILED" ? (
        <ErrorComponent variant="error-box" error={state.error} />
      ) : (
        <EnvListPageSkeleton />
      )}
    </>
  );
};

const EnvListPageSkeleton = ()=>{
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="d-flex align-center space-between" style={{marginBottom: "16px"}}>
            <div className="main-div width-full">
              <p className="heading-primary"><GenericSkeleton variant={"text"} height={32} width={"40%"} /></p>
              <p className="sub-heading-primary">
                <GenericSkeleton variant={"text"} height={16} width={"60%"} />
              </p>
            </div>
              <GenericSkeleton variant={"rect"} height={35} width={136} style={{borderRadius:"6px"}}  />
      </div>
      <Grid container spacing={2}>
            <Grid lg={12} item>
                <Grid container spacing="2" className="search-containter" justifyContent="space-between">
                  <Grid item lg={5}>
                    <SearchBar
                      skeleton={true}
                    />
                  </Grid>

                  <Grid item lg={3} sm={12} xl={3} xs={12}>
                    <Pagination
                      skeleton={true}
                    />
                  </Grid>
                </Grid>
            </Grid>
            {Array.from({length: 3}).map((_,index)=>{
              return <EnvCard skeleton={true} />
            })}
        </Grid>
    </div>
    
  );
}

export default EnvList;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    "& .main-heading": {
      fontSize: "24px",
      fontWeight: "300",
      lineHeight: 1,
    },
    "& .search-containter": {
      marginTop: -15,
    },
    "& .img-box": {
      width: "144px",
      height: "168.5px",
      margin: "8px",
      border: "1px solid #dedede",
      borderRadius: "8px",
      backgroundColor: "#fff",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      "& .img-pos": {
        width: "auto",
        border: "none",
        height: "50px",
        display: "block",
        margin: "auto",
      },
      "& .img-box-footer": {
        height: "41px",
        display: "flex",
        padding: "8px",
        background: "#f0f3f5",
        borderTop: "2px solid #dedede",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "11px",
        color: "#16232b",
      },
    },
    "& .card": {
      marginTop: "32px",
      "& .card-header": {
        fontSize: "12px",
        height: "auto",
        backgroundColor: "#fff",
      },
      "& .pd-20": {
        padding: "10px 20px!important",
      },
      "& .with-header": {
        borderTop: "1px solid #dedede",
      },
      "& .table": {
        "& th": {
          padding: "1.25rem",
        },
        "& .td": {
          padding: "1.25rem",
        },
      },
    },
    "& .sub-header": {
      padding: "5px 10px",
      borderBottom: "1px solid #dedede",
      backgroundColor: "#f6f8f8",
      fontSize: "12px",
      "& .input-component": {
        marginBottom: "0px",
      },
    },
    "& .card-controller": {
      "& .card": {
        border: "none",
        borderRadius: "0px",
      },
      "& .makeStyles-cardHeader-228": {
        padding: "5px 10px",
      },
    },
  },
}));
