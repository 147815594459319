import React from 'react'
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';


const PromoteConfigurationOverview = (props) => {
    const target_env_details = props.target_env_details;
    const application_id = props.application_id;
    const service_id = props.service_id;
    const component_env_id = props.component_env_id;
    const ci_id = props.ci_id ? props.ci_id : null;
    const secondary_build = props.secondary_build;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: ci_id }, properties.api.edit_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);
    const parent_ci_data = props.parent_ci_data;
    const main_promote_configuration = target_env_details.target_env_details;

    return (
        <Grid item lg={6}>
            <div className="section-service-overview">
                <div className="overview-single-card kv-border">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Promote Configuration
                        </p>
                        {
                            is_permitted ?
                            <Link
                            to={{
                              pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/ci/${parent_ci_data?.id}/edit`,
                              search: `?selectedTabOrder=4&selected_ci_id=${target_env_details.id}&secondary_build=${secondary_build}&parent_ci_id=${parent_ci_data?.id}`,
                            }}
                            state= {{ ...props.extraProps }}
                          >
                            <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                          </Link>
                                :
                                <Tooltip title="You are not allowed to perform this action" >
                                    <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                                </Tooltip>
                        }

                    </div>

                    <div className="overview-card-value-env-var">
                        <div className="heading-key-value d-grid space-between align-center" style={{ gridTemplateColumns: '33% 33% 33%' }}>
                                <p>Target Env</p>
                                <p>Target Sub Env</p>
                                <p>Target Image name</p>
                                
                        </div>
                        
                        <div className="env-variable-box">
                            {!main_promote_configuration ? 
                            
                                <p className="no-data__msg-env-var" >No Data Available</p>
                            :
                                Object.keys(main_promote_configuration).map((env) => {
                                    let env_list = main_promote_configuration[env];
                                    return (
                                        env_list.map((data) => {
                                            return (
                                                <div className='d-grid space-between align-center' style={{ gridTemplateColumns: '35% 35% 30%',fontSize:'12px',color:'#787878',paddingBottom:'6px' }}>
                                                    <p>{env}</p>
                                                    <p>{data.target_sub_env}</p>
                                                    <p>{data.target_sub_env}</p>
                                                </div>
                                            )
                                        })
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default PromoteConfigurationOverview