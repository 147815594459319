import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { Input } from '../../../../components/genericComponents/Input';
import Grid from '@mui/material/Grid';
import { getCommonFunctions } from "../ci_flow/SourceDetails";
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from "../../../../util/apiInvoker";
import properties from "../../../../properties/properties";
import MultiRow from '../../../../components/genericComponents/MultiRow';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { Loading } from "../../../utils/Loading";

const EnabledComponentEmptyDir = props => {
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getEmptyDirComponentDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, props.inherits)
  const service_id = props.extraProps.service_id;
  const env_id = props.extraProps.component_env_id;
  const cd_id = props.extraProps.env_cd_id;
  const variant = props.variant ? props.variant : null;
  function getSecrets() {
    var requestInfo = {
      endPoint: GenerateURL({ service_id: Number(service_id), env_id: Number(env_id), cd_id: cd_id }, properties.api.predefined_emptydir),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    InvokeApi(requestInfo, fetchSuccessSecretListing, fetchFailSecretListing);

  }

  function fetchSuccessSecretListing(response) {
    const secrets = []

    response.forEach(element => {
      secrets.push({ label: element.name, id: element.name })
    });
    setState(new_state => ({
      ...new_state,
      secrets: secrets
    }))
  }
  function fetchFailSecretListing(error) {
    console.log("SADads")
  }

  function loadSecrets() {
    var secrets = state.secrets.length == 0 ? getSecrets() : null
  }

  useEffect(() => {
    if (variant === "sidecars") {
      loadSecrets()
    }

  }, []);



  return (
    <>
      <MultiRow RepeatableComponent={SecretBody} HeaderComponent={SecretHead} prev_state={state.secert_childs} inherits={state.child_inherits.secert_childs} zeroRowsAllowed={true}
        repeatableComponentProps={{ secrets: state.secrets, variant: variant }} />
    </>
  )
}
EnabledComponentEmptyDir.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default EnabledComponentEmptyDir

export function getEmptyDirComponentDefaultState() {
  return {
    secrets: [],
    child_inherits: {
      secert_childs: {}
    }
  };
}


const SecretBody = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const secretNamesList = props.extraProps.secrets ? props.extraProps.secrets : [];
  const variant = props.extraProps.variant
  const [state, setState] = useState(prev_state ? prev_state : getEmptyDirFormDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);


  const toggleTagState = () => {
    console.log(state.open_custom, "secretNamesList")
    var custom_tag_flag = state.open_custom;
    setState({
      ...state,
      loading_tag: true,
      name: null
    });
    setTimeout(() => {
      setState({
        ...state,
        open_custom: !state.open_custom,
        loading_tag: false,
        name: null
      });
    }, 200);
  }



  return (
    <>
      <Grid item lg={5}>
        {
          state.loading_tag ? <Loading varient="light" /> :
            <Input
              type={state.open_custom ? "text" : "select"}
              name="name"
              placeholder="Name"
              label=" "
              list={secretNamesList}
              error={state.error}
              onChangeHandler={commonFunctions.onChangeHandler}
              data={state.data}
              extraDiv={variant == "sidecars" ? <span role='button' tabIndex={0}  className="mr-0 cursor-pointer text-anchor-blue pr-0" onKeyDown={()=>{}} onClick={toggleTagState}>{state.open_custom ? "Select EmptyDir" : "Provide Custom Entry"}</span> : null}
            />
        }

      </Grid>
      <Grid item lg={5}>
        <Input
          type="text"
          placeholder="Volume Mount path"
          name="mount_path"
          label=" "
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data}
          extraDiv={variant == "sidecars" ? <span role='button' tabIndex={0}  style={{ visibility: 'hidden' }} className="mr-0 cursor-pointer text-anchor-blue pr-0" onKeyDown={()=>{}} onClick={toggleTagState}>{state.open_custom ? "Provide Custom Entry" : "Select EmptyDir"}</span> : null} />
      </Grid>
    </>
  )
}
SecretBody.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const SecretHead = props => {
  return (
    <>
      <Grid item lg={5}>
        <div className="font-12">Name</div>
      </Grid>
      <Grid item lg={5}>
        <div className="font-12">Volume Mount path</div>
      </Grid>
    </>
  )
}

export function getEmptyDirFormDefaultState() {
  return {
    data: {},
    open_custom: true,
    error: {},
    validations: {
      name: [VALIDATION_TYPE_REQUIRED],
      mount_path: [VALIDATION_TYPE_REQUIRED],
    },
  };
}