import React from 'react'
import { styled } from "@mui/system";



const Root = styled('div')({
        background:"linear-gradient(40.64deg, #07192A 24.63%, #030F1B 70.72%, #001A33 90.66%)",
        height:"100vh",
        position:"relative",
        "& .transparent-bp":{
          opacity:"0.16",
          position:"absolute",
          left:"-22%",
          bottom:"6%",
        },
        "& .staticwrap-inner": {
            padding: "24px",
            paddingTop:"74px",
            gap:"16px",
            display:"flex",
            flexDirection:"column",
            color: "#fff",
            "& p": {
                fontSize: "13px",
                lineHeight: "23px",
                marginTop: "30px",
            },
            "& .about-btn":{
              padding:"6px 12px",
              borderRadius:"4px",
              backgroundColor:"#0086ff",
              width:"69px",
              textAlign:"center",
            },
            "& .info": {
              lineHeight:"18px",
              fontSize:"12px"
            }
        },
        "& .h1": {
            fontSize: "14px",
            lineHeight: "17px",
            color: "#FEA111",
            fontWeight: "700",
            textTransform:"uppercase",
        },
})
const StaticPartConfiguration = ({mainHeading, descriptionText}) => {
    
    return (
        <Root>
            <div className="staticwrap-inner">
                <div className='d-flex f-direction-column ' style={{ gap: "12px" }}>
                    <span className='about-btn font-12 font-weight-600'>About</span>
                    <span className="h1">{mainHeading ? mainHeading :"Release Package"}</span>
                </div>

                <div className='info' style={{ color: "#ffffff" }}>
                    {
                        descriptionText ? descriptionText :
                        ` A Release Package is a bundle or collection of microservices that clients can use for
                    production deployment. It is very useful for production releases as it explicitly lists
                    various dependent and independent microservices, generates release notes, and more.
                    It also tracks the artifacts currently deployed to production and assists in production rollbacks.`
                    }
                   
                </div>
                <div className='font-12'>
                    To learn more about {mainHeading ? mainHeading : ' Release Package' } Please read
                    <a href='https://www.buildpiper.io/documentation/' target='_blank' style={{ color: "#FEA111" }}>
                        &nbsp;documentation{" "}
                    </a>
                </div>

            </div>

            <img src='/images/transparent-bp.png' className='transparent-bp' alt='transparent-bp.png' />
        </Root>
    );
};



export default StaticPartConfiguration