import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import { Input } from '../../../../components/genericComponents/Input';
import properties from '../../../../properties/properties';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import GenerateURL from '../../../../util/APIUrlProvider';
import { REQUEST_TYPE_EDIT_SERVICE } from '../index'
import InvokeApi from '../../../../util/apiInvoker';
import MaxWidthDialog from '../../../settings/credentials/components/FormDialogue';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mainDivSelected: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f7f7',
    padding: '.5rem 1.25rem',
    borderRadius: '30px 0px 0px 30px',
    margin: '20px 0px 20px 20px'
  },
  mainDiv: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '160px',
    backgroundColor: '#f0f7f7',
    padding: '.5rem 1.25rem',
    borderRadius: '30px 30px 30px 30px',
    margin: '20px'
  },
  count: {
    width: '30px',
    height: '30px',
    border: '1px solid #ccc',
    borderRadius: '50%',
    textAlign: 'center',
    paddingTop: '2px'
  },
  text: {
    fontSize: '11px',
    fontWeight: 400,
  },
  mainBodyDiv: {
    backgroundColor: '#f6f8f8',
    padding: '15px 20px',
    borderTop: '1px solid#dedede',
  },
  countBody: {
    backgroundColor: '#1d5b81',
    color: '#fff',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 800
  },
  tabBodyDivHeading: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1rem',
    borderBottom: '1px solid #ccc'
  },
  textBody: {
    fontSize: '16px',
    fontWeight: 300,
    color: '#000'
  },
  formDiv: {
    padding: '0px 0px',
    margin: '0px 0px'
  },
  InputLabel: {
    fontSize: '12px',
    fontWeight: 400,
  },
  hindText: {
    fontSize: '10px',
    color: '#666',
    fontWeight: 400,
    marginLeft: '.5rem'
  },
  stepper: {
    margin: '1rem 0rem'
  },
  subHeading: {
    fontSize: '12px',
    color: '#666',
    fontWeight: 400,
    marginBottom: '1rem'
  },
  impMakr: {
    color: 'red'
  }
}));


const ServiceDetail = (props) => {
  const classes = useStyles();
  const data = props.data;
  const error = props.error;
  const status = props.status;
  const updateData = props.update_data_callback;
  const availableLanuages = properties.languages;
  const request_type = props.request_type;
  const availableBuildStrategies = [
    {
      label: "Build once and promote",
      value: "ONCE"
    },
    {
      label: "Build for every environment",
      value: "EVERY"
    }
  ]
  const [state, setState] = useState({
    credentials_status: "SUCCESS",
    credentials: [],
    error: false
  })
  // const str = "abc's test#s";
  // console.log(str.replace(/[^a-zA-Z ]/g, ""));
  const onChangeHandler = (event) => {
    if (!(request_type == REQUEST_TYPE_EDIT_SERVICE && event.target.name == "build_strategy")) {
      if (event.target.name == "name") {
        if (!event.target.value.includes(" ")) {
          updateData(event.target.name, event.target.value.toLowerCase().replace(" "));
        }
      } else {
        updateData(event.target.name, event.target.value);
      }
    }
  }

  function fetchCredentials() {
    var endpt = GenerateURL({}, properties.api.credential_all);
   
    var requestInfo = {
      endPoint: endpt,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    requestInfo.endPoint = requestInfo.endPoint + "?all=true";

    InvokeApi(requestInfo, CredentialsFetchSuccess, CredentialsFetchFailure);

  }
  useEffect(() => {
    fetchCredentials();
  }, []);


  function getCredentialsList(credentials) {
    const new_credential_list = [];
    if (credentials) {
      credentials.forEach(credential => {
        new_credential_list.push({
          id: credential.id,
          label: credential.name
        })
      });
    }

    return new_credential_list;
  }
  function CredentialsFetchSuccess(data) {
    var credentials = getCredentialsList(data.results);
    setState(new_state => ({
      ...new_state,
      credentials: credentials,
      credentials_status: "SUCCESS",
    }));
  }
  function CredentialsFetchFailure(error, exception) {
    return error;
  }


  return (
    <>
      {status == "POST_REQUESTED" || status == "GET_REQUESTED" ?
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop> : null
      }
      <div className={classes.mainBodyDiv}>
        <FormControl fullWidth>
          <Input
            type="text"
            placeholder="demo-app"
            mandatorySign
            name="name"
            label="Give a name to your service"
            subHeading="AlphaNumeric with hyphen"
            error={error}
            onChangeHandler={onChangeHandler}
            data={data} />

          <Input
            type="imgCheckbox"
            name="type"
            mandatorySign
            label="Language"
            subHeading="Choose the language"
            list={availableLanuages}
            onChangeHandler={onChangeHandler}
            data={data}
            error={error} />

          <Input
            type="text"
            mandatorySign
            placeholder="https://github.com/demo/demo-app.git"
            name="repo_url"
            label="Repository URL"
            error={error}
            onChangeHandler={onChangeHandler}
            data={data} />
          <div style={{ display: 'grid', gridTemplateColumns: '85% 14%', gap: '1%', alignItems: 'center' }}>
            <Input
              type="select"
              name="credential_id"
              list={state.credentials}
              label="Repo Credentials"
              onChangeHandler={onChangeHandler}
              data={data}
              error={error} />

            <MaxWidthDialog refresh={fetchCredentials} />
          </div>

          <Input
            type="radio"
            name="build_strategy"
            subHeading={request_type == REQUEST_TYPE_EDIT_SERVICE ? "You can't edit this field" : null}
            label="How do you build"
            list={availableBuildStrategies}
            onChangeHandler={onChangeHandler}
            data={data}
            error={error} />
        </FormControl>
      </div>
    </>
  );
}

ServiceDetail.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default ServiceDetail;