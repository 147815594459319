import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import { getCommonFunctions, ResetComponent } from '../../ci_flow/SourceDetails';
import { Input } from '../../../../../components/genericComponents/Input';
import ProtectedIngress from './ProtectedIngress';
import PublicIngress from './PublicIngress';
import PrivateIngress from './PrivateIngress';
import GitRepo, { GitRepoDefaultState } from '../../../../../components/genericComponents/Forms/GitRepo';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../../util/Validator';
import { RemoveFromArray } from '../../../../../util/util';
import AccessLevelStrip from './AccessLevelStrip';
import FileUploadAccessLevelStrip from './FileUploadAccessLevelStrip';
import GitManifestAccessLevel from './GitManifestAccessLevel';
import FileUpload,{ FileUploadDefaultState } from '../../../../../components/genericComponents/Forms/FileUploadForCD';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const useStyles = makeStyles(() => ({
  dialog_root: {
    position: 'relative',
    '& .MuiDialog-paperScrollPaper': {
      width: '100rem',
      backgroundColor: '#fff;'
    },
    '& .MuiPaper-root': {
      maxWidth: '1000px'
    }
  },
  access_level_card: {
    borderRadius: 4,
    '& .justify-flex-start': {
      justifyContent: 'flex-start'
    },
    '& .card-header': {
      fontSize: '13px',
      color: '#464646',
      height: 'auto',
      '& .card-body': {
        padding: '10px'
      },

    },
    '& .sub-header': {
      backgroundColor: '#FEFEFE',
      borderBottom: '1px solid #EAEAEA',
      borderTop: '1px solid #EAEAEA',
      padding: 10
    },
    '& .single-row-access-level': {
      display: 'Grid',
      gridTemplateColumns: '15% 15% 15% 15% 13% 13% auto',
      gap: '10px',
      borderBottom: '1px solid #EAEAEA',
      '&:last-child': {
        borderBottom: '0px solid #EAEAEA',
      },
      '& .single-row-item': {
        padding: 10,
      },
      '& .title-heading': {
        color: '#4d4d4d',
        fontSize: '12px'
      },
      '& .heading-value': {
        color: '#9A9A9A',
        fontSize: '12px'
      }
    }
  },
  dialog: {
    '& .sq-chip': {
      backgroundColor: '#626262',
      color: '#fff',
      padding: '1px 3px',
      borderRadius: '4px',
      marginLeft: '3px'
    }
  },
  button_class: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
    '& .small-icon-button': {
      backgroundColor: '#fff',
      // marginLeft: '5px',
      border: 'none',
      boxShadow: '0.877px 1.798px 8px 0px rgba(0, 0, 0, 0.2)',
      height: '20px',
      width: '20px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#0086ff'
    }
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #C5C5C5',
    fontSize: '14px',
    fontWeight: 400,
    color: '#fff',
    backgroundColor: 'rgb(18, 77, 155)',
    padding: '10px 20px'
  },
  cardBody: {
    padding: '0px 10px',
    '& ul': {
      '&.headerul': {
        '& li': {
          width: '100%',
          textAlign: 'center',
          fontSize: '12px',
          borderBottom: '1px solid #a3a3a3',
          color: '#000',
          '&.active': {
            color: '#000',
            borderBottom: '2px solid #124D9B'
          }
        }
      }
    }
  }
}));
const MultiPort = (props) => {
  const classes = useStyles();
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  console.log(prev_state, "custom_ingress_file_upload_data")
  const extraProps = props.extraProps ? props.extraProps : { service_name: "default", project_env_name: "default", environment_master_name: "default" };
  const [state, setState] = useState(prev_state ? prev_state : multiplePortsDefaultState())


  const commonFunctions = getCommonFunctions(state, setState, inherits);
  const [value, setValue] = useState()
  const [error, setError] = useState(false)
  const columnNames = [

    {
      label: 'Name',
      width: '15%',
      grey: false,
      name: 'name'
    },
    {
      label: 'Level Type',
      width: '10%',
      grey: true,
      name: 'access_level',
    },
    {
      label: 'Expose URL',
      width: '25%',
      grey: true,
      name: 'expose_url',
    },
    {
      label: 'Expose Path',
      width: '15%',
      grey: false,
      name: 'expose_path'
    },
    {
      label: 'Expose Port',
      width: '10%',
      grey: false,
      name: 'port'
    },
    {
      label: 'Target Port',
      width: '10%',
      grey: false,
      name: 'target_port'
    }
  ]
  const option_list = [
    {
      label: 'Guided Form',
      value: "guided_form"
    },
    {
      label: 'Upload Custom Manifest file / GitOps',
      value: "manifest-handler"
    }
  ]
  useEffect(() => {

    if (prev_state) {
      setState(new_state => ({
        ...new_state,
        ...prev_state,
      }));
    }

  }, [prev_state])


  const handleClickOpen = () => {
    setState(new_state => ({
      ...new_state,
      open: true,
      single_form_data: null,
      update_state_fn: false,
      public_private: null,
      clearState: true
    }))
  }

  const handleClickClose = () => {
    setState(new_state => ({
      ...new_state,
      open: false,
      public_private: null,
      single_form_data: null,
      update_state_fn: false,
      clearState: null
    }))
  }

  const removeRow = (data) => {
    var total_arr = state.access_level_list;
    total_arr.forEach((item, key) => {

      if (item.name == data.name) {

        total_arr.splice(key, 1)

      }
    })
    setState(prevState => ({
      ...prevState,
      access_level_list: [
        ...total_arr
      ],
    }))
  }

  const editSingleData = (type, data) => {

    setState(prevState => ({
      ...prevState,
      update_state_fn: true,
      open: true,
      clearState: null,
      single_form_data: {
        ...prevState.single_form_data,
        ...data
      }
    }))
  }
  var total_private_access_level = state.access_level_list.filter(item => item.access_level == "PRIVATE")
  var total_protected_access_level = state.access_level_list.filter(item => item.access_level == "PROTECTED")
  var total_public_access_level = state.access_level_list.filter(item => item.access_level == "PUBLIC");
  var access_level_via_git_configured = state.custom_ingress_via_git
  var access_level_via_file_upload_configured = state.custom_ingress_via_file_upload;

  const ValidateAndSaveIngressManifestData = () => {
    
    var children_vaidation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
    var child_states = inherits.getState ? inherits.getState() : null;
    var custom_ingress_file_upload_data = child_states.custom_ingress.file_component;
    var custom_ingress_via_git_data = child_states.custom_ingress.git_component;
    console.log(custom_ingress_via_git_data, "custom_ingress_file_upload_data")
    if (custom_ingress_file_upload_data) {
      let fileUploadResult = ValidateDataSet(custom_ingress_file_upload_data.fileUpload,{files: [VALIDATION_TYPE_REQUIRED]});
      console.log("hbchbcnbchsa",custom_ingress_file_upload_data)
      if(fileUploadResult.valid){
        setState((new_state) => ({
          ...new_state,
          custom_ingress_via_file_upload: true,
          custom_ingress_via_git: false,
          open: false,
          show_add_access_level_form: false,
          fileUploadedData: custom_ingress_file_upload_data,
          gitComponentData: null,
          // access_level_list:[],
          error: {
            setup_access_level: ""
          },
          validations:{
            setup_access_level: [],
          }
        }))
        handleClickClose();
        RemoveFromArray(state.validations.setup_access_level, VALIDATION_TYPE_REQUIRED)
      }
    }

    if (custom_ingress_via_git_data) {
      let fileUploadResult = ValidateDataSet(custom_ingress_via_git_data.gitRepo,{git_url: [VALIDATION_TYPE_REQUIRED],
        branch_name: [VALIDATION_TYPE_REQUIRED], file_paths:  [VALIDATION_TYPE_REQUIRED]});
      if(fileUploadResult.valid){
        setState((new_state) => ({
          ...new_state,
          custom_ingress_via_git: true,
          custom_ingress_via_file_upload: false,
          open: false,
          show_add_access_level_form: false,
          fileUploadedData: null,
          gitComponentData: custom_ingress_via_git_data,
          // access_level_list:[],
          error: {
            setup_access_level: ""
          },
          validations:{
            setup_access_level:[],
          }
        }))
        handleClickClose();
        RemoveFromArray(state.validations.setup_access_level, VALIDATION_TYPE_REQUIRED)
      }
     
    }
    
  }
  console.log(state.error, "kjfkjasjkda")
  const deleteCustomFileUploadData = () => {
    setState((new_state) => ({
      ...new_state,
      data: {
        ...new_state.data,
        upload_type: "guided_form"
      },
      custom_ingress_via_file_upload: false,
      custom_ingress_via_git: false,
      open: false,
      show_add_access_level_form: true,
      fileUploadedData: null,
      custom_ingress: {
        ...getCustomIngressDefaultState(),
        file_component: null
      },
      file_component: null
    }))
  }
  const deleteCustomGitUploadData = () => {
    setState((new_state) => ({
      ...new_state,
      custom_ingress_via_file_upload: false,
      custom_ingress_via_git: false,
      open: false,
      show_add_access_level_form: true,
      gitComponentData: null,
    }))
  }
  const editFileUploadData = (data, open_dialog_false, show_dialog) => {
    console.log(data, data.data, "fkdskajjnfas")
    setState((new_state) => ({
      ...new_state,
      data: {
        upload_type: 'manifest-handler'
      },
      open: open_dialog_false ? false : true,
      custom_ingress: {
        ...getCustomIngressDefaultState(),
        id: data.data && data.data.id ? data.data.id : null,
        data: {
        
            custom_ingress_type: data.gitRepo ? 2 : data.fileUpload ? 1 : 1,
            
        },
        file_component:  data.data && data.fileUpload   ? 
                            {
                                ...getFileUploadDefaultState(),
                                data:{
                                    name: data.data &&data.data.name? data.data.name : null,
                                    protocol: "TCP",
                                    port: data.data && data.data.port? data.data.port: null,
                                    access_level: "PRIVATE",
                                    id: data.data&& data.data.id ?data.data.id :null,
                                },
                                fileUpload:data.data&& data.data.uploaded_file ? {
                                    files:[{name:data.data.uploaded_file}] 
                                } : data.fileUpload ? {
                                  ...data.fileUpload,
                                } : null
            
                            }: null,
        git_component: data.data && data.gitRepo ? 
        {
          ...getgitComponentDefaultState(),
          data:{
            name: data.data &&data.data.name? data.data.name : null,
            protocol: "TCP",
            port: data.data && data.data.port? data.data.port: null,
            access_level: "PRIVATE",
            id: data.data&& data.data.id ?data.data.id :null,
          },
          gitRepo: data.gitRepo? {
            git_url: data.gitRepo.git_url? data.gitRepo.git_url: null,
            branch_name: data.gitRepo.branch_name? data.gitRepo.branch_name: null,
            file_paths: data.gitRepo.file_paths? data.gitRepo.file_paths: null
          }: null
        }: null
        
    }

    }))
  }

  const validateAndSaveAccessLevel = () => {
    RemoveFromArray(state.validations.setup_access_level, VALIDATION_TYPE_REQUIRED)
    var children_vaidation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
    var child_states = inherits.getState ? inherits.getState() : null;

    var access_level_data = child_states && child_states.public_private;
    var private_access_level_data = access_level_data.private_access_level && access_level_data.private_access_level.data ? access_level_data.private_access_level.data : null;
    var public_access_level_data = access_level_data.public_access_level ? access_level_data.public_access_level : null
    var protected_access_level_data = access_level_data.protected_access_level ? access_level_data.protected_access_level : null;
    console.log(access_level_data,private_access_level_data,public_access_level_data,protected_access_level_data)
    if (public_access_level_data && public_access_level_data.data) {
      var final_public_access_level_data = {
        ingress_id: public_access_level_data.ingress && public_access_level_data.ingress.data ?
          public_access_level_data.ingress.data.ingress_id ? Number(public_access_level_data.ingress.data.ingress_id) : null : null,
        gateway_id: public_access_level_data.gateway && public_access_level_data.gateway.data ?
          public_access_level_data.gateway.data ? Number(public_access_level_data.ingress.data.gateway_id) : null : null,
        access_level: public_access_level_data.data && public_access_level_data.data.access_level ?
          public_access_level_data.data.access_level : null,
        expose_path: public_access_level_data.data && public_access_level_data.data.expose_path ?
          public_access_level_data.data.expose_path : null,
        expose_url: public_access_level_data.data && public_access_level_data.data.expose_url ?
          public_access_level_data.data.expose_url : null,
        name: public_access_level_data.data && public_access_level_data.data.name ?
          public_access_level_data.data.name : null,
        port: public_access_level_data.data && public_access_level_data.data.port ?
          public_access_level_data.data.port : null,
        protocol: public_access_level_data.data && public_access_level_data.data.protocol ?
          public_access_level_data.data.protocol : null,
        target_port: public_access_level_data.data && public_access_level_data.data.target_port ?
          public_access_level_data.data.target_port : null,
      }
    }
    if (protected_access_level_data && protected_access_level_data.data) {
      var final_protected_access_level_data = {
        ingress_id: protected_access_level_data.ingress && protected_access_level_data.ingress.data ?
          protected_access_level_data.ingress.data ? Number(protected_access_level_data.ingress.data.ingress_id) : null : null,
        gateway_id: protected_access_level_data.gateway && protected_access_level_data.gateway.data ?
          protected_access_level_data.gateway.data.gateway_id ? Number(protected_access_level_data.gateway.data.gateway_id) : null : null,
        access_level: protected_access_level_data.data && protected_access_level_data.data.access_level ?
          protected_access_level_data.data.access_level : null,
        expose_path: protected_access_level_data.data && protected_access_level_data.data.expose_path ?
          protected_access_level_data.data.expose_path : null,
        expose_url: protected_access_level_data.data && protected_access_level_data.data.expose_url ?
          protected_access_level_data.data.expose_url : null,
        name: protected_access_level_data.data && protected_access_level_data.data.name ?
          protected_access_level_data.data.name : null,
        port: protected_access_level_data.data && protected_access_level_data.data.port ?
          protected_access_level_data.data.port : null,
        protocol: protected_access_level_data.data && protected_access_level_data.data.protocol ?
          protected_access_level_data.data.protocol : null,
        target_port: protected_access_level_data.data && protected_access_level_data.data.target_port ?
          protected_access_level_data.data.target_port : null,
      }
    }
    var final_arr = state.access_level_list;
    console.log(final_arr)
    if (children_vaidation_result.valid) {
      if (private_access_level_data) {
        if (final_arr.length > 0) {
          let found_duplicate = final_arr.find(item => item.name == private_access_level_data.name);
          console.log(found_duplicate)
          if (!found_duplicate) {
            final_arr.push(private_access_level_data)
          }
        } else {
          final_arr.push(private_access_level_data)
        }
      }
      if (public_access_level_data) {
        if (final_arr.length > 0) {
          let found_duplicate = final_arr.find(item => item.name == final_public_access_level_data.name);
          if (!found_duplicate) {
            final_arr.push(
              final_public_access_level_data
            )
          }
        } else {
          final_arr.push(
            final_public_access_level_data
          )
        }
      }
      if (final_protected_access_level_data) {
        if (final_arr.length > 0) {
          let found_duplicate = final_arr.find(item => item.name == final_protected_access_level_data.name);
          if (!found_duplicate) {
            final_arr.push(final_protected_access_level_data)
          }
        } else {
          final_arr.push(final_protected_access_level_data)
        }
      }
      console.log(final_arr)
      setState(new_state => ({
        ...new_state,
        access_level_list: [...final_arr],
        gitComponentData: null,
        custom_ingress_via_file_upload: false,
        custom_ingress_via_git: false,
        open: false,
        fileUploadedData: null,
        error: {
          setup_access_level: ""
        }
      }))
      handleClickClose()
    } else {
      setState(new_state => ({
        ...new_state,
        error_msg: "Validation True"
      }))
    }


  }

  const updateCurrentFormObj = (current_obj) => {
    RemoveFromArray(state.validations.setup_access_level, VALIDATION_TYPE_REQUIRED)
    var children_vaidation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
    var child_states = inherits.getState ? inherits.getState() : null;
    var access_level_data = child_states && child_states.public_private;
    var private_access_level_data = access_level_data.private_access_level && access_level_data.private_access_level.data ? access_level_data.private_access_level.data : null;
    var public_access_level_data = access_level_data.public_access_level ? access_level_data.public_access_level : null
    var protected_access_level_data = access_level_data.protected_access_level ? access_level_data.protected_access_level : null;
    var final_public_access_level_data = {};
    if (public_access_level_data && public_access_level_data.data) {
      final_public_access_level_data = {
        ingress_id: public_access_level_data.ingress && public_access_level_data.ingress.data ?
          public_access_level_data.ingress.data.ingress_id ? Number(public_access_level_data.ingress.data.ingress_id) : null : null,
        gateway_id: public_access_level_data.gateway && public_access_level_data.gateway.data ?
          public_access_level_data.gateway.data ? Number(public_access_level_data.ingress.data.gateway_id) : null : null,
        access_level: public_access_level_data.data && public_access_level_data.data.access_level ?
          public_access_level_data.data.access_level : null,
        expose_path: public_access_level_data.data && public_access_level_data.data.expose_path ?
          public_access_level_data.data.expose_path : null,
        expose_url: public_access_level_data.data && public_access_level_data.data.expose_url ?
          public_access_level_data.data.expose_url : null,
        name: public_access_level_data.data && public_access_level_data.data.name ?
          public_access_level_data.data.name : null,
        port: public_access_level_data.data && public_access_level_data.data.port ?
          public_access_level_data.data.port : null,
        protocol: public_access_level_data.data && public_access_level_data.data.protocol ?
          public_access_level_data.data.protocol : null,
        target_port: public_access_level_data.data && public_access_level_data.data.target_port ?
          public_access_level_data.data.target_port : null,
      }
    } else {
      final_public_access_level_data = null
    }
    var final_protected_access_level_data = {}
    if (protected_access_level_data && protected_access_level_data.data) {
      final_protected_access_level_data = {
        ingress_id: protected_access_level_data.ingress && protected_access_level_data.ingress.data ?
          protected_access_level_data.ingress.data ? Number(protected_access_level_data.ingress.data.ingress_id) : null : null,
        gateway_id: protected_access_level_data.gateway && protected_access_level_data.gateway.data ?
          protected_access_level_data.gateway.data.gateway_id ? Number(protected_access_level_data.gateway.data.gateway_id) : null : null,
        access_level: protected_access_level_data.data && protected_access_level_data.data.access_level ?
          protected_access_level_data.data.access_level : null,
        expose_path: protected_access_level_data.data && protected_access_level_data.data.expose_path ?
          protected_access_level_data.data.expose_path : null,
        expose_url: protected_access_level_data.data && protected_access_level_data.data.expose_url ?
          protected_access_level_data.data.expose_url : null,
        name: protected_access_level_data.data && protected_access_level_data.data.name ?
          protected_access_level_data.data.name : null,
        port: protected_access_level_data.data && protected_access_level_data.data.port ?
          protected_access_level_data.data.port : null,
        protocol: protected_access_level_data.data && protected_access_level_data.data.protocol ?
          protected_access_level_data.data.protocol : null,
        target_port: protected_access_level_data.data && protected_access_level_data.data.target_port ?
          protected_access_level_data.data.target_port : null,
      }
    } else {
      final_protected_access_level_data = null
    }
    if (children_vaidation_result.valid) {
      var updated_list = state.access_level_list.map(item => {

        if (private_access_level_data) {
          if (item.name == current_obj.name) {
            return { ...item, ...private_access_level_data };
          } else {
            return item;
          }
        }
        if (final_public_access_level_data) {
          if (item.name == current_obj.name) {
            return { ...item, ...final_public_access_level_data };

          } else {
            return item;
          }
        }
        if (final_protected_access_level_data) {
          if (item.name == current_obj.name) {
            return { ...item, ...final_protected_access_level_data };

          } else {
            return item;
          }
        }

      })
      setState(new_state => ({
        ...new_state,
        update_state_fn: false,
        access_level_list: updated_list,
        error: {
          setup_access_level: ""
        },
      }))
      handleClickClose()
    } else {
    }
  }

  useEffect(() => {
    if (prev_state) {
      if ((prev_state.access_level_list && prev_state.access_level_list.length === 0)
        && (!state.custom_ingress_via_git && !state.custom_ingress_via_file_upload)
      ) {
        setState(new_state => ({
          ...new_state,
          validations: {
            setup_access_level: [VALIDATION_TYPE_REQUIRED]
          },
        }))

      } else {
        RemoveFromArray(state.validations.setup_access_level, VALIDATION_TYPE_REQUIRED)
      }
    }
  }, [prev_state])
  useEffect(() => {
    if (state.custom_ingress_via_file_upload) {
      editFileUploadData(state.fileUploadedData, true)
    } else {
      if(state.custom_ingress_via_git){
        editFileUploadData(state.gitComponentData, true)
      }
        
    }

  }, [state.custom_ingress_via_file_upload,state.custom_ingress_via_git])

  useEffect(() => {


    return () => {
      if (state.access_level_list.length === 0
        && (!state.custom_ingress_via_git && !state.custom_ingress_via_file_upload)
      ) {
        setState(new_state => ({
          ...new_state,
          validations: {
            setup_access_level: [VALIDATION_TYPE_REQUIRED]
          },
        }))

      } else {
        RemoveFromArray(state.validations.setup_access_level, VALIDATION_TYPE_REQUIRED)
      }
    }
  }, [state.access_level_list])
  const togglePublicAccessLevel = () => {
    setState((new_state) => ({
      ...new_state,
      show_public_access_level: !new_state.show_public_access_level
    }))
  }
  const togglePrivateAccessLevel = () => {
    setState((new_state) => ({
      ...new_state,
      show_private_access_level: !new_state.show_private_access_level
    }))
  }
  const toggleProtectedAccessLevel = () => {
    setState((new_state) => ({
      ...new_state,
      show_protected_access_level: !new_state.show_protected_access_level
    }))
  }
  const cancelForm = () => {
    setState((new_state) => ({
      ...new_state,
      open: false
    }))
  }
  return (
    <>
      <Grid item lg={12} direction="row"
      >

        <div className={classes.access_level_card + " card "}>
          <div className="card-header ">
            <span>Access Level</span>
            {
              !state.custom_ingress_via_file_upload && !state.custom_ingress_via_git ?
                <button className="btn btn-transparent text-anchor-blue font-12" onClick={handleClickOpen}>
                   <i className="ri-add-line"></i> Add Access Level
                </button>
                : null
            }

          </div>
          {total_public_access_level && total_public_access_level.length > 0 &&
            <>
              <div className="sub-header d-flex align-center space-between">
                <div className='d-flex align-center font-13' >
                  <span className="material-icons material-icons-outlined" style={{ color: '#63E526', verticalAlign: 'middle' }}>
                    public
                  </span>&nbsp;
                  <span style={{ color: '#6F6F6F' }}>
                    Public:
                  </span>
                  <span style={{ color: '#6F6F6F', marginLeft: 5 }}>
                    {
                      total_public_access_level && total_public_access_level.length > 0 ?
                        total_public_access_level.length <= 9 ? "0" + total_public_access_level.length : total_public_access_level.length : "0"}
                  </span>
                </div>
                <button className="btn btn-transparent text-anchor-blue" onClick={togglePublicAccessLevel}>
                  {
                    state.show_public_access_level ? <span className="ri-arrow-up-s-line" style={{ fontSize: '20px',  cursor: 'pointer' }} /> :
                    <span className="ri-arrow-down-s-line" style={{ fontSize: '20px', cursor: 'pointer' }} />
                  }
                </button>
              </div>
              <div className={state.show_public_access_level ? "section-height-100 slide-up" : "section-height-0 slide-down"}>
                {
                  total_public_access_level.map(item => (
                    <AccessLevelStrip
                      access_level_data={item}
                      handleEditData={() => { editSingleData(null, item) }}
                      handleDeleteRow={() => { removeRow(item) }}
                    />
                  ))
                }
              </div>
            </>
          }
          {total_protected_access_level && total_protected_access_level.length > 0 &&
            <>
              <div className="sub-header d-flex align-center space-between">
                <div className='d-flex align-center font-13' >
                  <span className="material-icons material-icons-outlined" style={{ color: '#FF6D6D' }}>
                    vpn_lock
                  </span>
                  &nbsp;
                  <span style={{ color: '#6F6F6F' }}>
                    Protected:
                  </span>
                  <span style={{ color: '#6F6F6F', marginLeft: 5 }}>
                    {
                      total_protected_access_level && total_protected_access_level.length > 0 ?
                        total_protected_access_level.length <= 9 ? "0" + total_protected_access_level.length : total_protected_access_level.length : "0"}
                  </span>
                </div>
                <button className="btn btn-transparent text-anchor-blue" onClick={toggleProtectedAccessLevel}>
                  {
                    state.show_public_access_level ? <span className="ri-arrow-up-s-line" style={{ fontSize: '20px',  cursor: 'pointer' }} /> :
                    <span className="ri-arrow-down-s-line" style={{ fontSize: '20px', cursor: 'pointer' }} />
                  }
                </button>
              </div>
              <div className={state.show_protected_access_level ? "section-height-100 slide-up" : "section-height-0 slide-down"}>
                {
                  total_protected_access_level.map(item => (
                    <AccessLevelStrip
                      access_level_data={item}
                      handleEditData={() => { editSingleData(null, item) }}
                      handleDeleteRow={() => { removeRow(item) }}
                    />
                  ))
                }
              </div>
            </>
          }
          {total_private_access_level && total_private_access_level.length > 0 &&
            <>
              <div className="sub-header d-flex align-center space-between">
                <div className='d-flex align-center font-13' >
                  <span className="material-icons material-icons-outlined" style={{ color: '#FF8731' }} >
                    public_off
                  </span>
                  &nbsp;
                  <span style={{ color: '#6F6F6F' }}>
                    Private:
                  </span>
                  <span style={{ color: '#6F6F6F', marginLeft: 5 }}>
                    {
                      total_private_access_level && total_private_access_level.length > 0 ?
                        total_private_access_level.length <= 9 ? "0" + total_private_access_level.length : total_private_access_level.length : "0"}
                  </span>
                </div>
                <button className="btn btn-transparent text-anchor-blue" onClick={togglePrivateAccessLevel}>
                  {
                    state.show_public_access_level ? <span className="ri-arrow-up-s-line" style={{ fontSize: '20px',  cursor: 'pointer' }} /> :
                    <span className="ri-arrow-down-s-line" style={{ fontSize: '20px', cursor: 'pointer' }} />
                  }
                </button>
              </div>
              <div className={state.show_private_access_level ? "section-height-100 slide-up" : "section-height-0 slide-down"}>
                {
                  total_private_access_level.map(item => (
                    <AccessLevelStrip
                      access_level_data={item}
                      handleEditData={() => { editSingleData(null, item) }}
                      handleDeleteRow={() => { removeRow(item) }}
                    />
                  ))
                }
              </div>
            </>
          }
          {
            state.custom_ingress_via_git ?
              <p
                className=""
                style={{ fontSize: '12px', color: '#000', marginRight: '5px' }}>

                <div className="sub-header d-flex align-center space-between">
                  <div className='d-flex align-center font-13' >
                    <span className="material-icons material-icons-outlined" style={{ color: '#FF8731' }} >
                      subtitles
                    </span>
                    &nbsp;
                    <span style={{ color: '#6F6F6F' }}>
                      Custom Ingress Via Git
                    </span>

                  </div>

                </div>
                <GitManifestAccessLevel
                  access_level_data={state.gitComponentData}
                  handleEditData={() => { editFileUploadData(state.gitComponentData) }}
                  handleDeleteRow={deleteCustomGitUploadData}
                />
              </p>
              :
              state.custom_ingress_via_file_upload ?
                <p
                  className=""
                  style={{ fontSize: '12px', color: '#000', marginRight: '5px' }}>

                  <div className="sub-header d-flex align-center space-between">
                    <div className='d-flex align-center font-13' >
                      <span className="material-icons material-icons-outlined" style={{ color: '#FF8731' }} >
                        file_upload
                      </span>
                      &nbsp;
                      <span style={{ color: '#6F6F6F' }}>
                        Custom Ingress Via File Upload
                      </span>

                    </div>

                  </div>
                  <FileUploadAccessLevelStrip
                    access_level_data={state.fileUploadedData}
                    handleEditData={() => { editFileUploadData(state.fileUploadedData) }}
                    handleDeleteRow={deleteCustomFileUploadData}
                  />
                </p>
                : null}
        </div>

        {
          state.error.setup_access_level &&
          <div className='pd-10' style={{ paddingLeft: '17px' }}>
            <Input
              type="hidden"
              name="setup_access_level"
              error={state.error}
              onChangeHandler={commonFunctions.onChangeHandler}
              data={state.data}
            />
          </div>
        }

      </Grid>

      <div className={classes.dialog}>

        <Dialog
          open={state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClickClose}
          className={classes.dialog_root + " " + " integrations-dialog"}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className={classes.cardHeader}>
            <p>Multiple Ports Support</p>
            <button className="btn btn-transparent" onClick={handleClickClose}>
              <span className='ri-close-line ' style={{ fontSize: 20, color: '#fff' }}></span>
            </button>
          </div>
          <div className={classes.cardBody}>
            <Grid container>
              <Grid item lg={12}>
                <div className='card-add-service' style={{ margin: '10px', boxShadow: 'none', paddingBottom: '0px' }}>
                  <div className='card-header input-component-mb-0'>
                    <Input
                      type="radio"
                      name="upload_type"
                      label=""
                      mandatorySign
                      list={option_list}
                      error={state.error}
                      data={state.data}
                      onChangeHandler={commonFunctions.onChangeHandler} />
                  </div>
                  {
                    console.log(state.custom_ingress, "reciveing data")
                  }
                  {
                    state.data.upload_type === "manifest-handler" ?
                      <CustomIngress
                        prev_state={state.custom_ingress}
                        resetState={state.open}
                        clearState={state.clearState}
                        inherits={state.child_inherits.custom_ingress}
                        publicPrivateServiceInherits={state.child_inherits.public_private} /> :
                      null
                  }
                  {
                    state.data.upload_type === "guided_form" ?
                      <PublicPrivateService
                        extraProps={extraProps}
                        resetState={state.open}
                        clearState={state.clearState}
                        parentComponentState={state.single_form_data}
                        validateAndSaveAccessLevel={state.update_state_fn ?
                          () => { updateCurrentFormObj(state.single_form_data) } :
                          validateAndSaveAccessLevel}
                        prev_state={state.public_private}
                        cancelForm={cancelForm}
                        inherits={state.child_inherits.public_private}
                        customIngressInherits={state.child_inherits.custom_ingress} /> :
                      null
                  }
                  {
                    state.data.upload_type === "manifest-handler" &&
                    <div
                      className='d-flex align-center space-between border-top pd-10'
                      style={{ position: '', bottom: '0px', width: '100%' }}>
                      <button className='btn btn-outline-grey' onClick={handleClickClose}>Cancel</button>
                      <button className='btn btn-submit' onClick={ValidateAndSaveIngressManifestData}>Continue</button>
                    </div>
                  }

                </div>

              </Grid>

            </Grid>
          </div>
        </Dialog>
      </div>
    </>
  )
}
MultiPort.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default MultiPort;


export function multiplePortsDefaultState() {
  return {
    open: false,
    show_add_access_level_form: true,
    data: {
      upload_type: "guided_form"
    },
    error: {},
    validations: {
      setup_access_level: [VALIDATION_TYPE_REQUIRED]
    },
    selected_tab: 2,
    access_level_list: [],
    child_inherits: {
      custom_ingress: {
        "validateForm": () => { return { valid: true } },
        "getState": () => { return {} },
        "getData": () => { return {} }
      },
      public_private: {
        "validateForm": () => { return { valid: true } },
        "getState": () => { return {} },
        "getData": () => { return {} }
      }
    }
  }
}

export function getCustomIngressDefaultState() {
  return {
    data: { custom_ingress_type: 1 },
    error: {},
    validations: {
    },
    child_inherits: {
      file_component: {
        "validateForm": () => { return { valid: true } },
        "getState": () => { return {} },
        "getData": () => { return {} }
      },
      git_component: {
        "validateForm": () => { return { valid: true } },
        "getState": () => { return {} },
        "getData": () => { return {} }
      }
    }
  }
}


const CustomIngress = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  console.log(prev_state, "lnjplnjp")
  const publicPrivateServiceInherits = props.publicPrivateServiceInherits;
  const resetState = props.resetState ? props.resetState : null;
  const clearState = props.clearState;
  const [state, setState] = useState(prev_state ? prev_state : getCustomIngressDefaultState())
  useEffect(() => {
    setState(new_state => ({ ...new_state, ...prev_state }))
  }, [prev_state])
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  useEffect(() => {
    if (clearState) {
      setState(prevState => ({
        ...prevState,
        data: {
          custom_ingress_type: 1
        },
        file_component: null,
        git_component: null,
      }))
    }
    return () => {

      setState(prevState => ({
        ...prevState,
        data: {
          custom_ingress_type: 1
        }
      }))
    }
  }, [resetState])
  return (
    <>
      <div className="card-body">
        <Grid container>
          <div className='section'>
            <Input
              type="radio"
              name="custom_ingress_type"
              label="Choose Option"
              mandatorySign
              onChangeHandler={commonFunctions.onChangeHandler}
              data={state.data}
              error={state.error}
              list={[{ label: 'via File', value: 1 }, { label: 'via Git', value: 2 }]}
            />
          </div>
        </Grid>
        {
          state.data.custom_ingress_type == 1 ?
            <FileUploadComponent publicPrivateServiceInherits={publicPrivateServiceInherits} prev_state={state.file_component} inherits={state.child_inherits.file_component} selected={state.data.custom_ingress_type == (1).toString()} /> :  
            <>
              <ResetComponent inherits={state.child_inherits.file_component} />
            </>

        }
        {state.data.custom_ingress_type == 2 ?
          <GitRepoComponent publicPrivateServiceInherits={publicPrivateServiceInherits} prev_state={state.git_component} inherits={state.child_inherits.git_component} /> :
           <ResetComponent inherits={state.child_inherits.git_component} />
          
        }
      </div>
    </>
  )
}
CustomIngress.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const FileUploadComponent = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  console.log(prev_state, "testing if ")
  const [state, setState] = useState(prev_state ? prev_state : getFileUploadDefaultState())
  useEffect(() => {
    setState(new_state => ({ ...new_state, ...prev_state }))
  }, [prev_state])
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  // const protocol_list = [{ label: "TCP", id: "TCP" }, { label: "HTTP", id: "HTTP" }]
  var publicPrivateServiceInherits = props.publicPrivateServiceInherits;
  
  function getFileUploadState() {
    var state_temp = FileUploadDefaultState();
    if (state.fileUpload) {
      state.fileUpload.files.forEach(file_name => {
        if (typeof (file_name) == "string") {
          state_temp.form_data.data.files.push({
            name: file_name
          });
        } else {
          state_temp.form_data.data.files.push(file_name);
        }
      });
    }
    return state_temp;
  }

  return (
    <div className="card-body" >
      <Grid container >
        <Grid lg={4} direction="row">
          <div className='section'>
            <Input
              type="text"
              name="name"
              label="Name"
              placeholder="Name"
              mandatorySign
              maxLength={15}
              onChangeHandler={commonFunctions.onChangeHandler}
              data={state.data}
              error={state.error}
            />
          </div>
        </Grid>

        <Grid lg={4}>
          <div className='section'>
            <Input
              type="text"
              name="protocol"
              label="Protocol"
              mandatorySign
              // list={protocol_list}
              placeholder="name"
              onChangeHandler={null}
              data={state.data}
              error={state.error} />
          </div>
        </Grid>
        <Grid lg={4} direction="row">
          <div className='section'>
            <Input
              type="text"
              name="port"
              label="Port"
              mandatorySign
              placeholder="4001"
              onChangeHandler={commonFunctions.onChangeHandler}
              data={state.data}
              error={state.error} />
          </div>
        </Grid>
      </Grid>
      <div>
        <FileUpload inherits={state.child_inherits.fileUpload} state={state.fileUpload ? state.fileUpload.files ? getFileUploadState() : null : null}
          varient="single" />
      </div>
      <ResetComponent inherits={publicPrivateServiceInherits} />
    </div>
  )
}
FileUploadComponent.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const GitRepoComponent = props => {
  var publicPrivateServiceInherits = props.publicPrivateServiceInherits;
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getgitComponentDefaultState())
  const protocol_list = [{ label: "TCP", id: 1 }, { label: "HTTP", id: 2 }]
  useEffect(() => {
    setState(new_state => ({ ...new_state, ...prev_state }))
  }, [prev_state])
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  function getGitRepoState() {
    var state_temp = GitRepoDefaultState();
    if (state.gitRepo) {
      state_temp.form_data.data = { ...state_temp.form_data.data, ...state.gitRepo };
      state_temp.branch_data.branches = state.gitRepo.branches
    }
    return state_temp;
  }

  return (
    <div>
      <Grid container >
        <Grid lg={4} direction="row">
          <div className='section'>
            <Input
              type="text"
              name="name"
              label="Name"
              placeholder="Name"
              maxLength={15}
              mandatorySign
              onChangeHandler={commonFunctions.onChangeHandler}
              data={state.data}
              error={state.error}
            />
          </div>
        </Grid>

        <Grid lg={4}>
          <div className='section'>
            <Input
              type="text"
              name="protocol"
              label="Protocol"
              mandatorySign
              placeholder="name"
              onChangeHandler={null}
              data={state.data}
              error={state.error} />
          </div>
        </Grid>
        <Grid lg={4}>
          <div className='section'>
            <Input
              type="text"
              name="port"
              label="Port"
              mandatorySign
              placeholder="4001"
              onChangeHandler={commonFunctions.onChangeHandler}
              data={state.data}
              error={state.error} />
          </div>
        </Grid>
      </Grid>
      <div>
        <GitRepo single_path={true} inherits={state.child_inherits.gitRepo} state={state.gitRepo ? getGitRepoState() : null} />
      </div>
      <ResetComponent inherits={publicPrivateServiceInherits} />
    </div>
  )
}
GitRepoComponent.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getFileUploadDefaultState() {
  return {
    data: {
      protocol: "TCP"
    },
    error: {},
    validations: {
      // name: [VALIDATION_TYPE_REQUIRED],
      // protocol: [VALIDATION_TYPE_REQUIRED],
      // port: [VALIDATION_TYPE_REQUIRED]
    },
    gitRepo: null,
    child_inherits: {
      fileUpload: {
        "validateForm": () => { return { valid: true } },
        "getState": () => { return {} },
        "getData": () => { return {} }
      }
    }
  }
}

export function getgitComponentDefaultState() {
  return {
    data: {
      protocol: "TCP"
    },
    error: {},
    validations: {
    },
    child_inherits: {
      gitRepo: {
        "validateForm": () => { return { valid: true } },
        "getState": () => { return {} },
        "getData": () => { return {} }
      },
    }
  }
}

export function getPublicPrivateDefaultState(extraProps, prev_state) {
  const service_name = extraProps ? extraProps.service_name : "";
  const env_master = extraProps ? extraProps.environment_master_name : "";
  const project_env_name = extraProps ? extraProps.project_env_name : "";
  return {
    updateFnActive: false,
    data: prev_state && prev_state.access_level ? {
      access_level: prev_state.access_level,
    } : {
      access_level: 'PRIVATE',
    },
    error: {},
    validations: {
    },
    private_access_level: prev_state && prev_state.access_level == "PRIVATE" ?
      { ...prev_state } : null,
    protected_access_level: prev_state && prev_state.access_level == "PROTECTED" ?
      { ...prev_state } : null,
    public_access_level: prev_state && prev_state.access_level == "PUBLIC" ?
      { ...prev_state } : null,
    child_inherits: {
      private_access_level: {
        "validateForm": () => { return { valid: true } },
        "getState": () => { return {} },
        "getData": () => { return {} }
      },
      protected_access_level: {
        "validateForm": () => { return { valid: true } },
        "getState": () => { return {} },
        "getData": () => { return {} }
      },
      public_access_level: {
        "validateForm": () => { return { valid: true } },
        "getState": () => { return {} },
        "getData": () => { return {} }
      }
    },

  }
}
const PublicPrivateService = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const resetState = props.resetState ? props.resetState : null;
  const parentComponentState = props.parentComponentState ? props.parentComponentState : null;
  console.log(parentComponentState, "parentComponentState")
  const customIngressInherits = props.customIngressInherits;
  const extraProps = props.extraProps;
  const clearState = props.clearState;
  console.log(clearState, "parentComponentState")
  const [state, setState] = useState(parentComponentState ?
    getPublicPrivateDefaultState(extraProps, parentComponentState) :
    getPublicPrivateDefaultState(extraProps))
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  const validateAndSaveAccessLevel = props.validateAndSaveAccessLevel ? props.validateAndSaveAccessLevel : () => { };
  const cancelForm = props.cancelForm ? props.cancelForm : () => { }
  const access_level_list = [
    {
      label: "Is It Public",
      value: "PUBLIC"
    },
    {
      label: "Is It Protected",
      value: "PROTECTED"
    },
    {
      label: "Is It Private",
      value: "PRIVATE"
    }
  ]
  useEffect(() => {

    if (parentComponentState) {
      if (parentComponentState.access_level == "PRIVATE") {
        setState(prevState => ({
          ...prevState,
          data: {
            access_level: "PRIVATE"
          },
          private_access_level: {
            ...parentComponentState
          }
        }))
      } else {
        if (parentComponentState.access_level == "PROTECTED") {
          setState(prevState => ({
            ...prevState,
            data: {
              access_level: "PROTECTED"
            },
            protected_access_level: {
              ...parentComponentState
            }
          }))
        } else {
          if (parentComponentState.access_level == "PUBLIC") {
            setState(prevState => ({
              ...prevState,
              data: {
                access_level: "PUBLIC"
              },
              public_access_level: {
                ...parentComponentState
              }
            }))
          }
        }
      }
    }
  }, [parentComponentState])

  useEffect(() => {
    if (clearState) {
      setState(prevState => ({
        ...prevState,
        data: {
          access_level: "PRIVATE"
        },
        private_access_level: null,
        protected_access_level: null,
        public_access_level: null
      }))
    }
    return () => {

      setState(prevState => ({
        ...prevState,
        data: {
          access_level: "PRIVATE"
        }
      }))
    }
  }, [resetState])

  return (
    <>
      <Grid container justify="left" style={{ marginBottom: '10px' }}>
        <Grid item lg={12} className="card-add-service-head">
          <div className="head-div">
            <h4 className='mainHeading'>Choose Access Level</h4>
            <Input
              type="radio"
              name="access_level"
              list={access_level_list}
              onChangeHandler={commonFunctions.onChangeHandler}
              data={state.data}
              error={state.error} />
          </div>
        </Grid>

        {state.data.access_level == "PRIVATE" ?
          <>
            <PrivateIngress
              inherits={state.child_inherits.private_access_level}
              prev_state={state.private_access_level}
              extraProps={extraProps}
              cancelForm={cancelForm}
              validateAndSaveAccessLevel={validateAndSaveAccessLevel} />
            <ResetComponent inherits={state.child_inherits.protected_access_level} />
            <ResetComponent inherits={state.child_inherits.public_access_level} />
          </>
          : null
        }
        {state.data.access_level == "PROTECTED" ?
          <>
            <ProtectedIngress
              inherits={state.child_inherits.protected_access_level}
              prev_state={state.protected_access_level}
              extraProps={extraProps}
              cancelForm={cancelForm}
              validateAndSaveAccessLevel={validateAndSaveAccessLevel}
            />
            <ResetComponent inherits={state.child_inherits.private_access_level} />
            <ResetComponent inherits={state.child_inherits.public_access_level} />
          </>
          : null
        }
        {
          state.data.access_level == "PUBLIC" ?
            <>
              <PublicIngress
                inherits={state.child_inherits.public_access_level}
                prev_state={state.public_access_level}
                extraProps={extraProps}
                cancelForm={cancelForm}
                validateAndSaveAccessLevel={validateAndSaveAccessLevel}
              />
              <ResetComponent inherits={state.child_inherits.protected_access_level} />
              <ResetComponent inherits={state.child_inherits.private_access_level} />
            </>
            : null
        }
        <ResetComponent inherits={customIngressInherits} />
      </Grid>

    </>
  )
}
PublicPrivateService.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
