import React from 'react'
import { makeStyles } from '@mui/styles';

const RoundAvatar = ({icon, imagePath}) => {
    const classes = useStyles();
    console.log(icon, imagePath,"icon, imagePath")
  return (
    <div className={`${classes.root_box} wrapper-class d-flex align-center justify-center`}>
        {icon ? icon : imagePath ? <img src={imagePath} className="avatar-image-1" alt=".." /> : null}
    </div>
  )
}

export default RoundAvatar

const useStyles = makeStyles(theme => ({
    root_box:{
        height:'48px',
        width: '48px',
        border: '1px solid #e6e6e6',
        borderRadius: '24px',
        '& .avatar-image-1':{
            width: '60%'
        }
    }
}))