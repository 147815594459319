import React from "react";
import PropTypes from 'prop-types';
import Editor from "@monaco-editor/react";
import YAML from "json-to-pretty-yaml";

export default function PodsContainers(props){
    const containers = props.containers;
   
    //const YAML = require('json-to-pretty-yaml');
    return(
        <div>
        <div className="pd-10">
            <p className="font-15 ">
                Pods Container
            </p>
            <p className="font-11 text-grey-b6">
            Displaying list of all the conditions related to pod
            </p>
        </div>
        {
            < Editor
            height="300px"
            width="100%"
            language="YAML"
            value={
                YAML.stringify(containers ? containers : 'No Data Available')
            }
            theme="vs-dark"
        />
        }
        </div>
    )
}

PodsContainers.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }