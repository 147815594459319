import React, { useEffect, useState } from 'react'
import { styled } from "@mui/system";
import { Input } from '../../../../components/genericComponents/Input';
import { Loading } from '../../../utils/Loading';
import AlertStrip from '../../../../components/AlertStrips';
import { showErrorHandlerUpdated } from '../../../../util/util';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';

const BPAppsIntegrations = ({
    data,
    onDataUpdate,
    error }) => {

    const [state, setState] = useState({
        data: data || {},
        error: error || {},
        apps_list:[]
    });

    useEffect(() => {
        if (JSON.stringify(state.data) !== JSON.stringify(data)) {
            onDataUpdate(state.data);
        }
    }, [state.data, data, onDataUpdate]);


    useEffect(() => {
        if (error && Object.keys(error).length > 0) {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...error
                }
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                error: {}
            }));
        }
    }, [error]);

    useEffect(() => {
        fetchAllProjects()
    }, [])
    

    function fetchAllProjects() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.side_panel_data),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchAllProjectsSuccess, fetchAllProjectsFailure);
        setState(new_state => ({
            ...new_state,
            loading: true,
            error_in_loading_data: null
        }));
    }

    function fetchAllProjectsSuccess(response) {
        const transformArr = response.map(item => {
            return {label:item.name, id: item.id }
        })
        setState((new_state) => ({
            ...new_state,
            apps_list : transformArr,
            loading: false,
            error_in_loading_data: null
        }));

    }
    function fetchAllProjectsFailure(error) {
        var error_msg = showErrorHandlerUpdated(error)
        setState(new_state => ({
            ...new_state,
            loading: false,
            error_in_loading_data: error_msg
        }));
    }

    const onChangeHandlerInput = (e) => {
        var k = e.target.name;
        var v = e.target.value
       
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
            },
            error: {
                ...new_state.error,
                [k]: "",
            }
        }));
    }
    const onKeyValueHandler = (e) => {
        var k = e.target.name;
        let v = e.target.value;
        
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v
            },
            error: { ...new_state.error, [k]: "" }
        }));
    }

    return (
        <Root>
            <p className='d-flex align-center mb-24'>
                <span className='font-20 font-weight-600'>Select Ticketing Integration and Projects</span>&nbsp;&nbsp;<span className='ri-information-line font-24 color-secondary'></span>
            </p>
            <Input
                type="text"
                name="name"
                placeholder="Enter Configuration name"
                label={"Configuration name"}
                mandatorySign
                info={"App Config Name"}
                data={state.data}
                error={state.error}
                onChangeHandler={onChangeHandlerInput}
            />
            {
                state.loading ?
                    <Loading varient="light" /> :
                    state.error_in_loading_data ?
                        <AlertStrip variant={"error"} message={state.error_in_loading_data} /> :
                        state.apps_list.length === 0 ?
                            <AlertStrip variant={"info"} message={"There are no ticketing integration available"} /> :
                            <Input
                                type="select"
                                label="Select Applications (for which you want to configure Validation)"
                                name="application"
                                list={state.apps_list}
                                mandatorySign
                                placeholder="Enter BP app name and press enter"
                                error={state.error}
                                data={state.data}
                                onChangeHandler={onChangeHandlerInput}
                            />
            }


        </Root>
    )
}
const Root = styled('div')({
    marginBottom: '24px'
})

export default BPAppsIntegrations