import React from 'react';
import PropTypes from 'prop-types';

const ServiceTypeLogo = ({ name, newUpdated }) => {

    const getColor = (name) => {
        if (newUpdated) {
            switch (name) {
                case 'dev':
                    return '#0086FF'
                case 'qa':
                    return '#7B61FF'
                case 'prod':
                    return '#EF5DBD'
            }
            return '#82a479'
        }
        else {
            switch (name) {
                case 'dev':
                    return '#30b4a6'
                case 'qa':
                    return '#82a479'
                case 'prod':
                    return '#a47983'
            }
            return '#82a479'
        }

    }
    return (
        <div style={{
            width: 'fit-content',
            padding: '10px 15px 10px 15px',
            textTransform: 'uppercase',
            color: 'white', backgroundColor: getColor(name),
            fontWeight: newUpdated ? '600' : '500',
            fontSize: '14px',
            borderRadius: '6px',
        }}>
            {name}
        </div>
    )
}

ServiceTypeLogo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export default ServiceTypeLogo;