import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  newHeight:{
    minHeight:'50px!important'
  },
  logo:{
    height:'80%!important'
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar className={classes.newHeight}>
      <RouterLink to="/" style={{display:'flex'}}>
      <img
        alt="Logo"
        className={classes.logo}
        src="/images/logos/bp-logo-new.png"
        width="80%"
      />
    </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
