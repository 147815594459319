import React from "react";
import PropTypes from 'prop-types';
import { display, fontSize, fontWeight, lineHeight, styled, textTransform } from "@mui/system";
import { color } from "highcharts";
import StageProgressDetail from "../component/StageProgressDetail";
let stage = 'stage (08/15) :'
let msg = 'QA Lead Sign Off2 Jobs'

const PipelineProgressCard = ({ isExpandableView, pipeline, pipeline_instance, variant }) => {
    return <Card>

        <StageProgressDetail
            variant={variant ? variant : 'pipeline-card-new'}
            pipeline={pipeline}
            pipeline_instance={pipeline_instance}
        />
    </Card>
}

export default PipelineProgressCard;

PipelineProgressCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

const Card = styled("div")({
    borderBottom: '1px solid #E6E6E6',
    '& .md-stepper-horizontal': {
        background: '#fff',
    }
});



