import React, { useEffect, useState } from "react";
import JobCard from "./jobCard/JobCard";
import { makeStyles } from '@mui/styles';
import JobCardServiceSummary from "./jobCard/JobCardServiceSummary";
import CommonHorizontalTab from "../../../../../components/genericComponents/CommonHorizontalTab";


const JobTemplateListingV3ServiceSummary = (props) => {
    const {
        allJobs,
        configuredJobs,
        service_data,
        filters,
        setSnackBar,
        application_id,
        selectedServiceEnv,
        wideget_deployment_name,
        masterLabel,
        component_env_id,
        component_id,
        sub_env_id,
        showOnlyConfigured,
        actions,
        fetchLastTriggeredData,
        templateName,
        templateId,
        job_code_queryString,
        getConfiguredJobs
    } = props;
    const [state, setState] = useState({
        configuredJobs: [],
        selectedTabOrder: 0,
        selectedJobData: null,
        jobCardData: {},
        refresh: 0
    })
    const classes = useStyles();
    console.log('t---000-ppppp---->')
    useEffect(() => {

        if (allJobs && configuredJobs && Object.keys(configuredJobs)?.length > 0) {
            let configuredJobsList = []
            console.log('inside_ndss_001', allJobs, configuredJobs)
            Object.keys(configuredJobs)?.map(configuredJobsCode => {
                let configuredJob = allJobs?.filter(job => job.job_code == configuredJobsCode)
                configuredJobsList = [...configuredJobsList, configuredJob]
            })

            setState(prevState => ({
                ...prevState,
                configuredJobs: configuredJobsList
            }))

        }
    }, [allJobs, configuredJobs, state.refresh])

    console.log('st_upliom', state.configuredJobs)

    const handleTabUpdate = (order) => {
        setState(prevState => ({
            ...prevState,
            selectedTabOrder: order
        }))
    }
    useEffect(() => {
        console.log(job_code_queryString, allJobs, 'ximp_01')
        if (job_code_queryString) {

            let updatedOrder
            allJobs?.forEach((job, index) => {
                if (job.job_code == job_code_queryString) {
                    updatedOrder = index
                }
            })
            console.log(updatedOrder, 'up_001')
            handleTabUpdate(updatedOrder)

        }
    }, [job_code_queryString])

    useEffect(() => {
        if (allJobs) {
            const selectedJob = allJobs[state.selectedTabOrder]
            let job_code = selectedJob.job_code
            let metaInfo = { configured: configuredJobs && configuredJobs[job_code] }
            let data = { ...selectedJob, templateName: templateName,templateId: templateId, ...metaInfo }

            setState(prevState => ({
                ...prevState,
                selectedJobData: {
                    ...prevState.selectedJobData,
                    wholeJob: selectedJob,
                    data: data

                }
            }))
        }


    }, [state.selectedTabOrder, configuredJobs])
    console.log('str_00p', allJobs);
    return (
        <div className={classes.root}>
            <div className="mt-10 mb-24">
                <CommonHorizontalTab
                    updateSelectedTab={handleTabUpdate}
                    selectedTabOrder={state.selectedTabOrder}
                    tabList={allJobs?.map((job, index) => {
                        return { tabName: job.job_code, order: index }
                    })}
                    variant="v3-advance-tabs"
                />
            </div>
           

            {
                state?.selectedJobData && Object.keys(state?.selectedJobData)?.length > 0 &&
                <>
                {console.log('t---000-ppppp---->')}
                <JobCardServiceSummary
                getConfiguredJobs={getConfiguredJobs}
                    refresh={state.refresh}
                    allJobs={allJobs}
                    service_data={service_data}
                    filters={filters}
                    wholeJob={state?.selectedJobData?.wholeJob}
                    index={state.selectedTabOrder}
                    fetchLastTriggeredData={fetchLastTriggeredData}
                    setSnackBar={setSnackBar}
                    application_id={application_id}
                    selectedServiceEnv={selectedServiceEnv}
                    wideget_deployment_name={wideget_deployment_name}
                    masterLabel={masterLabel}
                    configuredJobs={configuredJobs}
                    component_env_id={component_env_id}
                    component_id={component_id}
                    sub_env_id={sub_env_id}
                    showOnlyConfigured={showOnlyConfigured}
                    actions={actions}
                    configuredData={configuredJobs && configuredJobs[state?.selectedJobData?.wholeJob.job_code]}
                    data={state?.selectedJobData?.data}
                    job_code_queryString={job_code_queryString}
                />
                </>
            }

        </div>
    )
}

export default JobTemplateListingV3ServiceSummary;
const useStyles = makeStyles({
    root: {

    }
})
