import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Canvas from './components/Canvas';
import StageForm, { ADD_STAGE, ADD_JOB, WORKFLOW, EDIT_STAGE, EDIT_JOB } from './components/StageForm';
import properties from '../../../properties/properties';
import { Loading, LoadingContainer } from '../../utils/Loading';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import GenerateURL from '../../../util/APIUrlProvider';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import { makeStyles } from '@mui/styles';
import BasicInfo from './BasicInfo';
import { ConvertPipelineYAMLtoObj, GetServiceSpecificToPipeline } from '../../../controllers/PipelineController';

import AlertStrip from '../../../components/AlertStrips';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const Details = (props) => {
    
    const pipeline = props.pipeline;
    const application = props.application;
    const saveBasicInfoAndContinueToDetails = props.saveBasicInfoAndContinueToDetails;
    const pipeline_error = props.pipeline_error ? props.pipeline_error : null;
    const [state, setState] = useState({ stages: [], all_project_envs: [], count: 1 });
    const [loading, setLoading] = useState(false);
    const state_loded = props.state_loded;
    console.log(state, 'pp_0pspd')
    const handleBasicInfoFormOpen = props.handleBasicInfoFormOpen ? props.handleBasicInfoFormOpen : () => { }
    const classes = useStylesDialog();
    const pipeline_yaml_data = {};
    const addOrUpdateStage = (stage, index) => {
        console.log(stage, index, "index---1")
        var stages = state.stages;
        if (index || index == 0) {
            stages[index] = stage;
        } else {
            stages.push(stage);
        }
        setState(new_state => ({
            ...new_state,
            stages: stages,
            open: false,
            ui_view_varient: WORKFLOW,
            is_stage_form_dirty: true,
            count: new_state.count + 1
        }));
    }

    useEffect(() => {
        setLoading(true);
        const delayedExecution = setTimeout(() => {
            allStageInstance();
        }, 1500); // 1500 milliseconds = 1.5 seconds 
        // Clear the timeout if the component unmounts or the dependencies change                                                                                                   
        return () => clearTimeout(delayedExecution);
    }, [pipeline]);

    const allStageInstance = () => {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application.id, pipeline_id: pipeline.id }, properties.api.stages_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleResponse, handleError);
        setLoading(true)
    }


    function handleResponse(data) {
        console.log(data, "anunay___")
        if (data.length > 0) {
            var stages = [...data];
            stages = transformApiResponseToData(stages);
            setState(new_state => ({
                ...new_state,
                stages: stages,
                pipeline_id: stages[0] ? stages[0].pipeline_id : null,
            }));
        }
        fetchApplicationEnvironment();
    }

    function capitalize(str) {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function transformApiResponseToData(stages) {
        console.log(stages, "skdbvbhsvjhsbv")
        stages.forEach(stage => {
            stage.approval_questions = stage.questionnaires && stage.questionnaires.length > 0 ? true : false;
            if (stage.stage_run_condition && stage.stage_run_condition.uat_required) {
                stage.stage_run_condition.uat_required = capitalize(stage.stage_run_condition.uat_required);
            }
            stage.task = [...stage.tasks];
            stage.task.forEach(task => {
                if (task.task_run_condition && task.task_run_condition.uat_required) {
                    task.task_run_condition.uat_required = capitalize(task.task_run_condition.uat_required);
                }
               
                task.task_type = task.task_type_id;
                task.env = task.project_env_id;
                task.target_env = task.target_project_env_id;
                task.docker_cache = task.no_cache ? false : true;
                
                if (task.task_type != 1) {
                    if (task.tag == "generated" || task.tag ==  "latest") {
                        task.artifact_source = task.tag ? task.tag.toUpperCase() : "";
                    } else {
                        task.artifact_source = "TAG";
                        task.tag = task.tag
                        
                    }
                }
                if (task.task_type == 5) {
                    task.headers_key_value_pairs = Object.keys(task.headers_key_value_pairs).length === 0 ? "" : task.headers_key_value_pairs;
                }
            });
            delete stage.tasks;
        });
        return stages;
    }

    function handleError(error) {
        console.log("errnababdcbdshor", error);
        setLoading(false)
    }

    const updateStages = (stages) => {
        setState({
            ...state,
            stages
        });
    }

    function showAddStageForm() {
        setState({
            ...state,
            ui_view_varient: ADD_STAGE,
            stage_index: state.stages.length,
            open: true
        });
    }

    function editStage(index) {
        setState({
            ...state,
            ui_view_varient: EDIT_STAGE,
            stage_index: index,
            open: true,
            count: state.count + 1
        });
    }

    function showWorkflow() {
        setState({
            ...state,
            ui_view_varient: WORKFLOW,
        });
    }

    function showAddJobForm(index, name) {
        setState({
            ...state,
            ui_view_varient: ADD_JOB,
            stage_index: index,
            error: null,
            open: true,
            present_stage_name: name,
        });
    }

    function deleteStage(index, only_jobs) {
        const stages = state.stages;
        if (only_jobs) {
            stages[index].task = [];
        } else {
            stages.splice(index, 1);
        }
        setState({
            ...state,
            stages: [...stages],
            ui_view_varient: WORKFLOW,
            count: state.count + 1
        });
    }

    function editJob(stage_index, job_index) {
        setState({
            ...state,
            ui_view_varient: EDIT_JOB,
            stage_index: stage_index,
            job_index: job_index,
            open: true,
            count: state.count + 1
        });
    }

    function deleteJob(stage_index, job_index) {
        const stages = state.stages;
        const stage = stages[stage_index];
        stage.task.splice(job_index, 1);
        setState({
            ...state,
            stages: [...stages],
            ui_view_varient: WORKFLOW,
        });
    }

    const handleSuccessStagePost = (response) => {
        var stages = transformApiResponseToData(response);
        setState(new_state => ({
            ...new_state,
            stages: stages,
            active_panel: 1,
            pipeline_id: stages[0] ? stages[0].pipeline_id : null,
            stage_post_status: "POST_SUCCESS",
            error: null,
        }));
    }

    const handleFailStagePost = (error) => {
        setState(new_state => ({
            ...new_state,
            stage_post_status: "POST_FAIL",
            error: error
        }));
    }

    useEffect(() => {
        console.log(state.stage_post_status, 'allldater')
    }, [state.stage_post_status])

  
    const getYamlData = (data) => {
        console.log(data, "sajkdjsdaj")
        return data
    }
    function saveStageViaYaml(editorValue) {
        // You can also send the value to an API or store it in a database
        //   }
        const yaml_file_validations = editorValue.validateForm && editorValue.validateForm() ? editorValue.validateForm() : { valid: true };
        const yaml_pipeline_data = pipeline_yaml_data && pipeline_yaml_data.getData ? pipeline_yaml_data.getData() : null;
        var pipeline_services = GetServiceSpecificToPipeline(pipeline.components, application.components);
        var stage_obj = {};

        stage_obj = ConvertPipelineYAMLtoObj(editorValue, application.components, state.all_project_envs)

        const stages = [...state.stages];
        console.log(stage_obj, "checking stages >> ", yaml_file_validations);

        const error_stages = [];
        const error_in_yaml_upload = [];
        if (stage_obj && stage_obj.stages) {
            if (stage_obj.stages.length != 0) {
                stage_obj.stages.forEach((stage, key) => {
                    if (!(stage.task && (stage.task.length > 0))) {
                        error_in_yaml_upload.push(key + 1);
                    }
                });
            } else {

                error_in_yaml_upload.push("No stage added");
            }
            if (error_in_yaml_upload.length > 0) {
                console.log(error_in_yaml_upload, "checking stages");
                setState({
                    ...state,
                    error: "There are no jobs in this stage, " + error_in_yaml_upload,
                    error_flag: true
                })
            } else {
                setState({
                    ...state,
                    ui_view_varient: WORKFLOW,
                    stage_post_status: "POST_REQUESTED",
                    error_flag: false,
                    is_stage_form_dirty: false
                });
                transformDataForApiHit(stage_obj.stages);
                PostData(GenerateURL({ pipeline_id: state.pipeline_id ? state.pipeline_id : pipeline.id }, properties.api.stage_save_url), stage_obj.stages, handleSuccessStagePost, handleFailStagePost);
            }
        }
    }
    function saveStages() {
        console.log(state.stages, "lnjfjhdsba")
        const stages = [...state.stages]
        const error_stages = [];
        if (stages && stages.length > 0) {
            stages.forEach((stage, key) => {
                if (!(stage.task && (stage.task.length > 0))) {
                    error_stages.push(key + 1);
                }
            });
        } else {
            error_stages.push("No stage added");
        }
        if (error_stages.length > 0) {
            console.log(error_stages, "checking stages");
            setState({
                ...state,
                error: "There are no jobs in this stage, " + error_stages,
                error_flag: true
            })
        } else {
            setState({
                ...state,
                ui_view_varient: WORKFLOW,
                stage_post_status: "POST_REQUESTED",
                error_flag: false,
                is_stage_form_dirty: false
            });
            transformDataForApiHit(stages, pipeline.version);
            console.log(stages, "checking stages_ffgfgfgf")
            PostData(GenerateURL({ pipeline_id: state.pipeline_id ? state.pipeline_id : pipeline.id }, properties.api.stage_save_url), stages, handleSuccessStagePost, handleFailStagePost);


        }
    }

    function updateError(error) {
        console.log(error, "snvjdksvjkfsvfdjk")
        setState(new_state => ({
            ...new_state,
            error: error,
        }));
    }

    function fetchApplicationEnvironment() {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application.id }, properties.api.env_listing + "?all=true&pipeline=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, AppEnvironmentFetchSuccess, AppEnvironmentFetchFailure);
        setLoading(true)
    }

    function AppEnvironmentFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            all_project_envs: data.results,
        }));
        setLoading(false)
    }
    function AppEnvironmentFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
        setLoading(false)
    }


    function runPipeline(id, data) {
        var url = GenerateURL({ pipeline_id: id }, properties.api.trigger_pipeline);
        PostData(url, data ? data : {}, handleSuccesTrigger, handleFailedTrigger);

    }
    function handleSuccesTrigger(response) {
        setState(new_state => ({
            ...new_state,

        }));
    }
    function handleFailedTrigger(response) {
        setState(new_state => ({
            ...new_state,
        }))
    }
    const handleClose = () => {
        if (state.stage_post_status != "POST_REQUESTED")
            setState(prevState => {
                console.log(prevState, 'previomn')
                return { ...prevState, stage_post_status: null }
            })
    };
    const handleClickClose = () => {
        setState({ ...state, open: false })
    }
    console.log(application, "applicationapplication")


    const [errorInPipelineSave, setErrorInPipelineSave] = useState(null);
    useEffect(() => {
        console.log("setErrorInPipelineSave", pipeline_error)
        if (pipeline_error) {
            setErrorInPipelineSave(pipeline_error);
            setState(new_state => ({
                ...new_state,
                open_form: true
            }));
        }

    }, [pipeline_error]);

    function handleClickCloseDialog() {
        if (pipeline_error) {
            setErrorInPipelineSave(null)
        }
        setState(new_state => ({
            ...new_state,
            open_form: false
        }));
    }

    function handleClickOpenDialog() {
        setState({ ...state, open_form: true });
    }
    const handleSaveDataDialogClose = () => {
        setState({ ...state, open_save_data_dialog: false });
    }
    const handleSaveDataDialogOpen = () => {
        setState({ ...state, open_save_data_dialog: true });
    }
    const formDirtyFlag = (data) => {
        console.log(data, "ladfsahfha")
        setState({ ...state, is_stage_form_dirty: true });
    }
    console.log(state.stages, "stage_data__1")
    const updateStagesObj = (data) => {
        setState({ ...state, stages: data });
    }
    console.log(state, "abfhdsvjhsdhvfd")

    return (
        <>
            {
                !state_loded || loading &&
                <Loading />
            }
            <div className='mt-20' style={{ width: '100%' }}>
                {
                    state.error ?
                        <div className='mt-20' style={{ width: '100%' }}>


                            <AlertStrip variant="error" message={state.error} />
                        </div>
                        : null
                }
            </div>
            <div className="pipeline-detailed-card">
                {
                    state.stage_post_status == "POST_REQUESTED" ?
                        <LoadingContainer /> : null
                }

                {Object.keys(state.all_project_envs).length > 0 ?
                    <div className="pipeline-detailed-card-body " style={{ display: 'flex', height: '82vh', }}>
                        {
                            <>
                                <Canvas
                                    pipeline={pipeline}
                                    saveStages={saveStages}
                                    saveStageViaYaml={saveStageViaYaml}
                                    getYamlData={getYamlData}
                                    formDirtyFlag={formDirtyFlag}
                                    handleBasicInfoFormOpen={state.is_stage_form_dirty ? handleSaveDataDialogOpen : handleClickOpenDialog} //handleClickOpenDialog 
                                    application={application}
                                    services={pipeline.components}
                                    updateStages={updateStages}
                                    showAddStageForm={showAddStageForm}
                                    showAddJobForm={showAddJobForm}
                                    deleteStage={deleteStage}
                                    editStage={editStage}
                                    editJob={editJob}
                                    deleteJob={deleteJob}
                                    updateError={updateError}
                                    getConvetedStagesObj={updateStagesObj}
                                    error_in_saving_data={state.error_flag}
                                    is_stage_form_dirty={state.is_stage_form_dirty}
                                    stages={state.stages}
                                    pipeline_yaml_data={pipeline_yaml_data}
                                    all_project_envs={state.all_project_envs}
                                    runPipeline={
                                        () => runPipeline(
                                            state.pipeline_id ?
                                                state.pipeline_id :
                                                pipeline.id)}
                                    show_run_pipeline_button={state.pipeline_id}
                                    saveBasicInfoAndContinueToDetails={saveBasicInfoAndContinueToDetails}
                                    count={state.count}
                                />

                                <StageForm
                                    pipeline={pipeline}
                                    application={application}
                                    stages={state.stages}
                                    varient={state.ui_view_varient}
                                    open_add_job_dialog={state.open_add_job_dialog}
                                    open_edit_job_dialog={state.open_edit_job_dialog}
                                    stage_index={state.stage_index}
                                    job_index={state.job_index}
                                    addOrUpdateStageCallback={addOrUpdateStage}
                                    showAddJobForm={showAddJobForm}
                                    showAddStageForm={showAddStageForm}
                                    showWorkflow={showWorkflow}
                                    deleteStage={deleteStage}
                                    editStage={editStage}
                                    editJob={editJob}
                                    open={state.open}
                                    deleteJob={deleteJob}
                                    handleClickClose={handleClickClose}
                                    updateStages={updateStages}
                                    updateError={updateError}
                                    all_project_envs={state.all_project_envs}
                                    present_stage_name={state.present_stage_name}
                                />
                            </>
                        }


                    </div>
                    : <></>}

            </div>
            {
                state.open_form &&
                <Dialog
                    open={state.open_form}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClickCloseDialog}
                    className={classes.dialog_root + " " + " integrations-dialog"}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description" >
                    <div className={"d-flex align-center space-between"}
                        style={{ background: 'rgb(18, 77, 155)', padding: 12 }}>
                        <div className="color-white font-14">
                            Basic Info
                        </div>
                        <button className="btn btn-transparent" edge="start" color="inherit" onClick={handleClickCloseDialog} aria-label="close">
                            <span className='ri-close-line color-white' fontSize="large"></span>
                        </button>
                    </div>
                    <BasicInfo
                        application={application}
                        pipeline={pipeline}
                        services={application.components}
                        error={errorInPipelineSave}
                        saveBasicInfoAndContinueToDetails={saveBasicInfoAndContinueToDetails} />

                </Dialog>

            }

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={state.stage_post_status == "POST_SUCCESS" || state.stage_post_status == "POST_FAIL"}
                onClose={handleClose}
                autoHideDuration={3000}>
                {
                    state.stage_post_status == "POST_SUCCESS" ?

                        <Alert severity="success"  variant="filled">
                            {"Pipeline saved successfully"}
                        </Alert>
                        : state.stage_post_status == "POST_FAIL" ?
                            <Alert severity="error"  variant="filled">
                                {"Unable to save pipeline"}
                            </Alert>

                            : null
                }
            </Snackbar>
        </>
    );
}


Details.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};


export default Details;

const height = window.innerHeight;
const useStylesDialog = makeStyles(() => ({

    dialog_root: {
        '& .add-pipeline-card-body': {
            height: '100%'
        },
        '& .MuiDialog-paperScrollPaper': {
            width: '100rem',
            backgroundColor: '#fff;'
        },
        '& .MuiPaper-root': {
            maxWidth: '1000px'
        }
    },
    dialog: {
        width: '100%',
        '& .card-sub-header': {
            backgroundColor: '#fefefe',
            borderBottom: '1px solid #eaeaea',
            padding: '5px',
            '& .heading-with-icon': {
                color: '#484848',
                fontSize: '12px',
                '& .material-icons': {
                    // fontSize: 
                }
            }
        },
        '& .no-issues-added': {
            width: '300px',
            textAlign: 'center',
            fontSize: '12px',
            margin: 'auto',
            display: 'block',
            padding: '20px'
        },
        '& .sq-chip': {
            backgroundColor: '#626262',
            color: '#fff',
            padding: '1px 3px',
            borderRadius: '4px',
            marginLeft: '3px'
        }
    },
    button_class: {
        fontSize: '12px',
        display: 'flex',
        marginBottom: '10px',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer'
        },
        '& .small-icon-button': {
            backgroundColor: '#fff',
            marginLeft: '5px',
            border: 'none',
            boxShadow: '0.877px 1.798px 8px 0px rgba(0, 0, 0, 0.2)',
            height: '20px',
            width: '20px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#0086ff'
        }
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #C5C5C5',
        fontSize: '14px',
        fontWeight: 400,
        color: '#bfbfbf',
        backgroundColor: '#ffffff',
        padding: '10px 20px'
    },
    cardBody: {
        // padding: '20px 20px',
        '& ul': {
            '&.headerul': {
                '& li': {
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '12px',
                    borderBottom: '1px solid #a3a3a3',
                    color: '#000',
                    '&.active': {
                        color: '#000',
                        borderBottom: '2px solid #124D9B'
                    }
                }
            }
        }
    },
    card_shadow: {
        boxShadow: ' 0 3px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        color: '#6e6e6e',
        borderRadius: '25px',
        fontSize: '13px',
        width: '450px',
        height: '50px',
        padding: '15px',
        marginTop: '25px',
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
    },
    button_code: {
        backgroundColor: '#007eff',
        border: 'none',
        color: 'white',
        padding: '11px 32px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '11px',
        margin: '4px 2px',
        cursor: 'pointer',
        float: 'right',
        width: '110px',
        height: '38px',
    },
    title_block: {
        borderBottom: "1px solid #a09e9e", padding: "15px", backgroundColor: '#f4f4f4', display: 'flex', justifyContent: 'space-between',
    },
    condition_block: {
        display: 'flex', alignItems: 'center', borderBottom: "1px solid #a09e9e", borderTop: '1px solid #a09e9e', padding: "8px", backgroundColor: '#f4f4f4', justifyContent: 'space-between',
    }
}));

export function transformDataForApiHit(stages, pipelineVersion) {
    stages.forEach((stage, key) => {
        if (stage.stage_run_condition && Object.keys(stage.stage_run_condition).length > 0) {
            if (stage.stage_run_condition && stage.stage_run_condition.uat_required) {
                stage.stage_run_condition.uat_required = stage.stage_run_condition.uat_required.toLowerCase();
            }
        }
        stage.tasks = [...stage.task];
        delete stage.task;
        stage.tasks.forEach((task, task_key) => {
            console.log(task, 'task_0001')
            // let version = pipelineVersion === 'v3' ? 'v3' : 'v2';
            let version = task?.job_step_json ? 'v3' : 'v2'
            if (version === 'v3') { // for version 3 only
                if (!task.job_code) { // send job code only in case of v3 pipeline.
                    task.job_code = task.task_type
                }
                task.task_type_id = null;
                delete task.project_image_scan_scope_id
                delete task.headers_key_value_pairs
                delete task.account_id
                delete task.additional_field_key_value
                delete task.artifact_source
                delete task.comment
                delete task.deployment_type
                delete task.description
                delete task.env_key_value_pair
                delete task.epic_name
                delete task.issue_key
                delete task.issue_name
                delete task.issuetype
                delete task.target_env
                delete task.task_run_condition
                delete task.operation
                delete task.summary
                delete task.priority
                delete task.status
                delete task.snow_operation
            }

            task.order = task_key + 1;
            task.project_env_id = task.env ? Number(task.env) : null;

            if (version === 'v2') {
                if (task.task_run_condition && Object.keys(task.task_run_condition).length > 0) {
                    if (task.task_run_condition && task.task_run_condition.uat_required) {
                        task.task_run_condition.uat_required = task.task_run_condition.uat_required.toLowerCase();
                    }
                }
                task.name = "job_" + task.order;
                task.task_type_id = Number(task.task_type);
               
                if (task.task_type_id == 1) {
                    task.no_cache = task.docker_cache ? false : true
                }
                if (task.task_type_id == 2 || task.task_type_id == 3) {
                    if (task.artifact_source == "GENERATED" ||  task.artifact_source == "LATEST") {
                        task.tag = task.artifact_source ? task.artifact_source.toLowerCase() : "";
                    }else{
                        task.tag = task.tag
                    }
                }

                if (task.task_type_id == 3) {
                    task.target_project_env = Number(task.target_env)
                }
                if (task.task_type_id != 2) {
                    delete task.deployment_type;
                    delete task.deployment_status_check;
                    delete task.validate;
                    delete task.pod_shift_percentage;
                    delete task.component_env_deployment_name_mapping;
                }
                //new
                if (task.task_type_id != 5) {
                    delete task.method
                    delete task.url
                    delete task.headers_key_value_pairs
                    delete task.pipeline_suspend
                    delete task.request_timeout
                    delete task.response_update_context_param
                }
                if (task.task_type_id != 1) {
                    delete task.latest_enable;
                    delete task.no_cache;
                }
                if (task.task_type_id != 12 && task.task_type_id != 13) {
                    if (!task.tag) {
                        delete task.tag
                    }
                }
                if (task.task_type_id == 5) {
                    task.components = [];
                    delete task.artifact_source;
                    delete task.tag;
                    delete task.project_env_id;

                }
                if (task.task_type_id == 10) {
                    task.components = [];
                    // delete task.artifact_source;
                    delete task.tag;
                    delete task.project_env_id;
                }
                if (task.task_type_id == 1) {
                    if (!task.project_ci_scope_id) {
                        delete task.project_ci_scope_id
                    }
                    if (!task.project_image_scan_scope_id) {
                        delete task.project_image_scan_scope_id
                    }
                }

                //new
                if (task.task_type_id != 10) {
                    if (task.task_type_id != 16) {
                        delete task.issue_key
                    }
                    delete task.operation
                    delete task.summary
                    delete task.description
                    delete task.issuetype
                    delete task.priority
                    delete task.account_id
                    delete task.status
                    delete task.comment
                    delete task.epic_name
                    delete task.additional_field_key_value
                    delete task.issue_name
                }
                //new
                if (task.task_type_id != 11) {
                    delete task.rollback_version
                }
                //new
                if (task.task_type_id != 12) {
                    delete task.env_key_value_pair
                }
                //new
                if (task.task_type_id != 13) {
                    delete task.suspend_pipeline
                }
                if (task.task_type_id != 17){
                    delete task.attach_operation;
                }
                if (task.task_type_id == 6) {
                    delete task.tag
                }
                if (task.task_type_id == 7) {
                    delete task.tag
                }
                if (task.task_type_id == 16) {
                    task.components = [];

                    delete task.tag;
                }
                if (task.task_type_id == 14) {
                    delete task.tag
                }
                if (task.task_type_id == 15) {
                    delete task.tag
                }
                if (task.task_type_id == 17) {
                    task.components = [];
                    delete task.tag;
                }
            }

            // if(task.task_type_id == 12 && task.env_key_value_pair){
            //     task.env_key_value_pair = JSON.parse(task.env_key_value_pair);
            // }else{
            //     delete task.env_key_value_pair 
            // }
            delete task.task_type;
            delete task.env;

        });
        stage.order = key + 1
    });
}