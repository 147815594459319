import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useParams, Link, useLocation, useNavigate , Navigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi, { PostData } from '../../util/apiInvoker';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../util/Validator';
import { Loading } from '../utils/Loading';
import { ErrorComponent } from '../utils/Error';
import CommonHorizontalTab from '../../components/genericComponents/CommonHorizontalTab';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ConvertJobTemplateObjtoYAML } from '../../controllers/PipelineController';
import { Input } from '../../components/genericComponents/Input';
import { getCommonFunctions } from '../serviceRevamp/add/ci_flow/SourceDetails';
import 'remixicon/fonts/remixicon.css';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import Slide from '@mui/material/Slide';
import moment from 'moment';
import JobTemplateCardCopy2805 from '../jobTemplates/JobTemplateCardCopy2805';
import Delete from '../../components/genericComponents/Delete';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const EditGlobalJobTemplate = props => {

    let transformed_job_template = {}

    const classes = useStyles();

    const location = useLocation();
    const history = useNavigate();

    const [previousStateLayers, setPreviousStateLayers] = useState(location && location.state ? location.state.previous_canvas_layer : null) // hold layer copy of canvas

    const { template_id } = useParams();


    const [dataForRendered, setDataForRendered] = useState()

    const inherits = {};

    const [templateNameEdit, setTemplateNameEdit] = useState(false)
    const [state, setState] = useState({
        data: {},
        error: {},
        selectedTab: 1,
        job_list: {},
        open: false,
        openJob: false,
        job_template_versions: [],
        edit_enabled: false,
        showDialog: false,
        validations: {
            name: [VALIDATION_TYPE_REQUIRED],

        },
        canRedirect: false,
        redirected_versions_open: false,
        redirected_actual_template_id: null,
        redirected_template_active: null,
    });
    const [jobTemplateData, setJobTemplateData] = useState({ jobTemplateData: null, showLoading: false })
    let redirected_versions_open = location?.state?.openVersions || false;
    const redirected_actual_template_id = location.state?.actual_template_id || null
    const redirected_template_version = location.state?.template_version || null

    const commonFunctions = getCommonFunctions(state, setState, inherits)

    useEffect(()=>{
        if (location.state) {
            const { openVersions, actual_template_id, template_active, ...rest } = location.state;
            console.log("location.state", location.state);
            setState((prevState) => ({
                ...prevState,
                redirected_versions_open: openVersions || false,
                redirected_actual_template_id: actual_template_id || null,
                redirected_template_active: template_active || null,
            }));
        }
    },[location.state])
    console.log("jsdhdgf",redirected_versions_open, location, location.state);

    useEffect(()=>{
        if(state.redirected_actual_template_id){
            loadTemplateVersions(state.redirected_actual_template_id)
        }

    },[state.redirected_actual_template_id])

    useEffect(() => {
        
            if (state.selectedVersioingTab) {
                loadTemplate(state.selectedVersioingTab, true);
            } else {
                if (template_id) {
                    loadTemplate(template_id);
                }
            }    
    }, [template_id, state.selectedVersioingTab]);

    useEffect(() => {

        if (dataForRendered) {

            const currentLayerStructure = dataForRendered && dataForRendered.map(layer => {

                return { type: layer.type, selectedIndex: layer.selectedCard ? layer.selectedCard.order : null }
            })


            setPreviousStateLayers(currentLayerStructure)
        }

    }, [dataForRendered])


    console.log(state, "gbgrgw =======>")



    function loadTemplate(template_id, showDataLoading) {
        var requestInfo = {
            endPoint: GenerateURL({ template_id: template_id }, properties.api.get_single_global_job_template),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        showDataLoading != false && setState(new_state => ({
            ...new_state,
            data_loading: true,
            error_msg: null
        }));

        InvokeApi(requestInfo, handleResponseTemplate, handleErrorTemplate);

    }

    function handleResponseTemplate(response) {
        setState(new_state => ({
            ...new_state,
            job_template_data: response,
            data_loading: false,
            error_msg: null,
            data: {
                ...new_state,
                name: response.name,
                // queue_name : response?.job_template?.queue_name
            }
        }));

        if (previousStateLayers)
            setStateForCanvas(response.job_template, previousStateLayers)
        else
            transformData(response.job_template)
    }

    function handleErrorTemplate(error) {
        setState(new_state => ({
            ...new_state,
            error_msg: "Error fetching data of this template " + " " + error,
            data_loading: false
        }));
    }


    const transformData = (jobTemplate) => {
        console.log(jobTemplate,"dkjsjkdjsjobTemplate")
        let firstTimeData = []

        let firstJob

        let jobObject = {}

        jobTemplate.jobs.forEach((item, index) => {

            jobObject[item.job_code] = item.steps
        })

        let selectedJOb
        if(dataForRendered){
            let selectedJob = dataForRendered[0].selectedCard;
            selectedJOb=selectedJob;
            firstJob = selectedJob.job_code;
        }
        
        let jobs = jobTemplate.jobs.map((item, index) => {
            if(selectedJOb){
                if(item.job_code == selectedJOb.job_code){
                    return { ...item, job_name: item.job_name, order: index, selected: true };
                }
                return { ...item, job_name: item.job_name, order: index, selected: false }
            }else{
                if (index == 0) {

                    firstJob = item.job_code
                    selectedJOb = { ...item, order: index }
    
                    return { ...item, job_name: item.job_name, order: index, selected: true }
                }
            }
            
            return { ...item, job_name: item.job_name, order: index, selected: false }
        })

        firstTimeData.push({ type: 'jobs', values: [...jobs], selectedCard: selectedJOb })

        let steps = jobObject[firstJob]

        let stepsData

        if (steps && steps.length > 0) {

            stepsData = steps.map((item, index) => {

                return { ...item, step_name: item.step_name, order: index, selected: false }
            })
        }

        if (stepsData) {

            firstTimeData.push({ type: 'steps', values: [...stepsData] })

        }
        else if (firstTimeData[0].values != 0) {
            firstTimeData.push({ type: 'steps', values: [] })
        }

        setDataForRendered([...firstTimeData])
    }

    const onClickedHandle = (selectedCardData, index, type) => {
        console.log(selectedCardData,index,type,"jhfjgsdfyus")
        let currentData = dataForRendered.slice(0, index + 1)

        let secondTimeClicked = selectedCardData && selectedCardData.selected

        if (secondTimeClicked) {

            let currentRendered = [...dataForRendered]
            const lastElement = currentRendered.length
            currentRendered.splice(index + 1, lastElement)
            currentRendered[index].selectedCard = null
            if (currentRendered[index].values && currentRendered[index].values[selectedCardData.order])
                currentRendered[index].values[selectedCardData.order].selected = false

            setDataForRendered(currentRendered)
        }

        else {
            if (type === 'jobs') {

                let prevJobsData = currentData[index]

                // updating selected sign

                let updatedNewSelectedJobs = prevJobsData && prevJobsData.values && prevJobsData.values.map(job => {

                    if (selectedCardData.job_name === job.job_name && selectedCardData.order === job.order) {

                        return { ...job, selected: true }
                    }

                    else {
                        return { ...job, selected: false }
                    }

                })

                currentData[index].selectedCard = selectedCardData
                currentData[index].values = updatedNewSelectedJobs         // changing only clicked index layer

                let steps = selectedCardData && selectedCardData.steps && selectedCardData.steps.map((step, index) => {

                    return { ...step, step_name: step.step_name, order: index, selected: false }
                })

                currentData.push({ type: 'steps', values: steps })

            }

            else if (type === 'steps') {

                let prevStepData = currentData[index]

                let updatedNewSelectedStep = prevStepData && prevStepData.values && prevStepData.values.map(step => {

                    if (selectedCardData.step_name === step.step_name && selectedCardData.order === step.order) {

                        return { ...step, selected: true }
                    }

                    else {
                        return { ...step, selected: false }
                    }

                })
                currentData[index].selectedCard = selectedCardData
                currentData[index].values = updatedNewSelectedStep         // changing only clicked index layer

                let conditions = selectedCardData && selectedCardData.values_step_mapping.map((item, index) => {

                    return { ...item, value: item.value, order: index }
                })

                currentData.push({ type: 'conditions', values: conditions, selected: false })

            }

            else if (type === 'conditions') {

                let prevConditionData = currentData[index]

                let updatedNewConditionStep = prevConditionData && prevConditionData.values && prevConditionData.values.map(condition => {

                    if (selectedCardData.value === condition.value && selectedCardData.order === condition.order) {

                        return { ...condition, selected: true }
                    }

                    else {
                        return { ...condition, selected: false }
                    }

                })

                currentData[index].selectedCard = selectedCardData
                currentData[index].values = updatedNewConditionStep         // changing only clicked index layer

                let steps = selectedCardData && selectedCardData.steps.map((item, index) => {

                    return { ...item, step_name: item.step_name, order: index, selected: false }
                })

                currentData.push({ type: 'steps', values: [...steps] })

            }
            setDataForRendered(currentData)


        }
    }

    const setStateForCanvas = (jobTemplate, previousStateLayers) => {        // this function prepare data to render previous canvas after operations

        let dataForCanvas = []

        previousStateLayers && previousStateLayers.forEach((layer, index) => {

            if (index === 0 && layer.type == 'jobs') {

                let selectedJob
                let jobs = jobTemplate.jobs.map((job, index) => {

                    if (layer.selectedIndex == index) {

                        selectedJob = { ...job, order: index }

                        return { ...job, job_name: job.job_name, order: index, selected: true }
                    }
                    return { ...job, job_name: job.job_name, order: index, selected: false }
                })

                dataForCanvas.push({ type: 'jobs', values: [...jobs], selectedCard: selectedJob })

            }

            else if (index == 1 && layer.type == 'steps') {

                let selectedStep

                let stepsOfPreviousJobs = dataForCanvas[index - 1].selectedCard.steps.map((step, index) => {

                    if (index == layer.selectedIndex && step.is_conditional_step === true) {

                        selectedStep = { ...step, order: index }
                        return { ...step, step_name: step.step_name, order: index, selected: true }

                    }

                    else {

                        return { ...step, step_name: step.step_name, order: index, selected: false }
                    }

                })

                stepsOfPreviousJobs && dataForCanvas.push({ type: 'steps', values: [...stepsOfPreviousJobs], selectedCard: selectedStep })

            }

            else if (index == 2 && layer.type == 'conditions') {

                if (dataForCanvas[index - 1] && dataForCanvas[index - 1].selectedCard) {
                    let value_step_mapping = dataForCanvas[index - 1].selectedCard && dataForCanvas[index - 1].selectedCard.values_step_mapping

                    let selectedCondition

                    let conditions = value_step_mapping && value_step_mapping.map((condition, index) => {

                        if (layer.selectedIndex == index) {
                            selectedCondition = { ...condition, order: index }
                            return { ...condition, value: condition.value, order: index, selected: true }
                        }
                        else {
                            return { ...condition, value: condition.value, order: index, selected: false }

                        }
                    })

                    conditions && dataForCanvas.push({ type: 'conditions', values: [...conditions], selectedCard: selectedCondition })
                }

            }

            else if (index == 3 && layer.type == 'steps') {

                if (dataForCanvas[index - 1] && dataForCanvas[index - 1].selectedCard) {

                    let selectedStep
                    let steps = dataForCanvas[index - 1].selectedCard.steps
                    let stepsOfPreviousConditions = steps && steps.map((step, index) => {

                        return { ...step, step_name: step.step_name, order: index, selected: false }

                    })
                    steps && stepsOfPreviousConditions && dataForCanvas.push({ type: 'steps', values: [...stepsOfPreviousConditions], selectedCard: selectedStep })


                }

            }
        })
        setDataForRendered(dataForCanvas)
        setState(prevState => ({ ...prevState }))

    }

    const updateSelectedTab = (selectedTabOrder) => {
        setState({ ...state, selectedTab: selectedTabOrder, });
    }
    const yaml = ConvertJobTemplateObjtoYAML(state.job_template_data)

    const updateJobTemplate = (jobTemplate, showLoading) => {
        setJobTemplateData({ showLoading: showLoading, jobTemplateData: jobTemplate })
        transformData(jobTemplate.job_template)
        setState(prev_state=>({
            ...prev_state,
            job_template_data: {...jobTemplate}

        }))


    }

    const updateScuccesWithoutLoading = (version_update) => {
        console.log(version_update,"sbchjbsdchjbsdhbc")
        if(version_update){
            loadTemplateVersions(version_update.id);
            closeActiveVersionDialog()
        }
        setState(new_state => ({
            ...new_state,
            error_msg: null,
            showActivateVersionDialog : false,
        }))

        // loadTemplate(template_id, false);

    }

    function updateSuccess(response) {
            console.log(response)
            loadTemplateVersions(response.id);
        
        setState(new_state => ({
            ...new_state,
            error_msg: null,
            showActivateVersionDialog  : false,
        }))

        loadTemplate(template_id);

    }

    function updateFail(response) {
        setState(new_state => ({
            ...new_state,
            error_msg: response.toString()
        }))
    }

    const editIconClicked = () => {
        if (state.job_template_data && state.job_template_data.name) {
            let templateName = state.job_template_data.name
            setState(prevState => ({
                ...prevState,
                error: {},
                data: {
                    ...prevState.data,
                    name: templateName
                }
            }))
            setTemplateNameEdit(true)
        }

    }

    const handleSubmitData = () => {

        let result = ValidateDataSet(state.data, state.validations)

        if (result.valid) {

            let jobTemplate = state.job_template_data

            jobTemplate['name'] = state.data.name

            if (state.data.queue_name) {
                if (state.data.queue_name == "") {
                    jobTemplate.job_template['queue_name'] = null
                } else {
                    jobTemplate.job_template.queue_name = state.data.queue_name
                }
            } else {
                jobTemplate.job_template.queue_name = null
            }

            updateJobTemplate(jobTemplate, false)

            setTemplateNameEdit(false)
        }

        else {

            setState(prevState => ({
                ...prevState,
                error: result.error
            }))
        }
    }

    function onQueueNameChange(e) {
        const value = e.target.value
        const fieldName = e.target.name

        var selectedQueueName;

        state.list.forEach((item) => {
            if (Number(item.id) == Number(value)) {
                selectedQueueName = item.label;
            }
        })

        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                queue_name: selectedQueueName
            }
        }))
    }
    // 
    const transformedDataForVersioningTab = (response) => {
        let finalArr = [];
        if (response && response.length > 0) {
            response.forEach(item => {
                finalArr.push({ order: item.id, tabName: "Version: " + item.template_version, active: item.active, created_at: moment(item.created_at).format('DD-MM-YYYY, HH:mm:ss') })
            })
        }
        return finalArr
    }
    function loadTemplateVersions(id) {
        console.log(id,state,"dsfkjs")
        var requestInfo = {
            endPoint: GenerateURL({ template_id: typeof(id) == Number ? id : template_id }, properties.api.get_global_template_versions),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setState(new_state => ({
            ...new_state,
            loading_versions: true,
            error_msg: null,
        }));

        InvokeApi(requestInfo, (respone) => handleTemplateVersionsResponse(respone,id), handleTemplateVersionsError);
        

    }

    function handleTemplateVersionsResponse(response,id) {
        console.log("ionsResponse", response,id)
        let active_version = response.filter(item => item.active == true)
        if(id){
            active_version = response.filter(item => item.id == id)
        }
        console.log("ionsResponse", active_version)
        const transformedData = transformedDataForVersioningTab(response)
        if(state.redirected_actual_template_id){
            const { openVersions, actual_template_id, template_version, ...rest } = location.state;
            history({
                ...location,
                state: { ...rest }
            });
        }
        setState(new_state => ({
            ...new_state,
            job_template_versions: transformedData,
            loading_versions: false,
            error_msg: null,
            selectedVersioingTab: new_state.redirected_actual_template_id ? new_state.redirected_actual_template_id : active_version[0] && active_version[0].id  ? active_version[0].id : null,
            show_active_version_button: true,
            redirected_actual_template_id: null,
            redirected_versions_open: false,
            redirected_template_active: null,
        }));

        // if (previousStateLayers)
        //     setStateForCanvas(response.job_template, previousStateLayers)
        // else
        //     transformData(response.job_template)
    }

    function handleTemplateVersionsError(error) {
        setState(new_state => ({
            ...new_state,
            error_msg: "Error fetching versions of this template " + " " + error,
            loading_versions: false
        }));
    }

    function RefreshForDelete() {
        if(state?.job_template_data?.active){
            setState((prevState) => ({
                ...prevState,
                canRedirect:true,
            }))
        }else{
            loadTemplateVersions();
        }
    }
    const toggleEdit = () => {
        setState(new_state => ({
            ...new_state,
            edit_enabled: !new_state.edit_enabled
        }));
    }

    const toggleCancel = () => {
        loadTemplate(template_id)
        setState(new_state => ({
            ...new_state,
            edit_enabled: !new_state.edit_enabled
        }));
    }

    const handleSaveTemplatePopup = () => {
        setState(new_state => ({
            ...new_state,
            showDialog: true
        }));
    }
    const [selected, setSelected] = useState(0);

    const handleClick = (index) => {
        setSelected(index);
    };
    const checkAndUpdateFn = (selected_option) => {
        console.log(selected_option, jobTemplateData,"selected_option")
        if (selected_option === 0) {
            let update_job_template_url = GenerateURL({ template_id: state.selectedVersioingTab? state.selectedVersioingTab : template_id }, properties.api.create_new_global_template_version)

            if (jobTemplateData.showLoading == false) {
                PostData(update_job_template_url, jobTemplateData.jobTemplateData && jobTemplateData.jobTemplateData ? jobTemplateData.jobTemplateData:  state.job_template_data ? state.job_template_data  : {}, updateScuccesWithoutLoading, updateFail, false, true);
            }
            else {
                PostData(update_job_template_url,  jobTemplateData.jobTemplateData && jobTemplateData.jobTemplateData ? jobTemplateData.jobTemplateData:  state.job_template_data ? state.job_template_data  : {}, updateSuccess, updateFail,  false, true);
            }
        } else {
            let update_job_template_url = GenerateURL({ template_id: state.selectedVersioingTab? state.selectedVersioingTab : template_id }, properties.api.get_single_global_job_template)

            if (jobTemplateData.showLoading == false) {
                PostData(update_job_template_url,  jobTemplateData.jobTemplateData && jobTemplateData.jobTemplateData ? jobTemplateData.jobTemplateData:  state.job_template_data ? state.job_template_data  : {}, updateScuccesWithoutLoading, updateFail,  false, true);
            }
            else {
                PostData(update_job_template_url,  jobTemplateData.jobTemplateData && jobTemplateData.jobTemplateData ? jobTemplateData.jobTemplateData:  state.job_template_data ? state.job_template_data  : {}, updateSuccess, updateFail, false, true);
            }
        }
        loadTemplate(state.selectedVersioingTab);
        closeDialog();
        setState(new_state => ({
            ...new_state,
            edit_enabled: false
        }));
    }
    console.log(jobTemplateData, "jobTemplateDatajobTemplateData")
    const activateCurrentVersion = () => {
        // global_template_version_active
        console.log(jobTemplateData, "jobTemplateDatajobTemplateData")
        let update_job_template_url = GenerateURL({ template_id: state.selectedVersioingTab ? state.selectedVersioingTab : state.job_template_data.id }, properties.api.global_template_version_active)

        if (jobTemplateData.showLoading == false) {
            PostData(update_job_template_url, state.job_template_data, ()=>{updateScuccesWithoutLoading("active_version")}, updateFail, false, true);
        }
        else {
            PostData(update_job_template_url, state.job_template_data, () => {updateSuccess("active_version")}, updateFail, false, true);
        }
        loadTemplateVersions(state.selectedVersioingTab)
        // closeActiveVersionDialog(true);
        
    }
    const closeDialog = () => {

        setState(new_state => ({
            ...new_state,
            showDialog: false
        }));
    }
    const closeActiveVersionDialog = (close_from_save) => {

        setState(new_state => ({
            ...new_state,
            showActivateVersionDialog: false
        }));
       
    }
    const updateSelectedVersioingTab = (selectedTabOrder, active_status) => {
        console.log(selectedTabOrder,state.job_template_versions,"selectedVersioingTab")
        redirected_versions_open = false;
        let selected_job_template_data = state.job_template_versions && state.job_template_versions.find((item)=>{
            if(item.order == selectedTabOrder){
                return item
            }
        })
        setState(new_state => ({
            ...new_state,
            selectedVersioingTab: selectedTabOrder,
            selectedVersioningTabName : selected_job_template_data.tabName && selected_job_template_data.tabName,
            show_active_version_button: active_status
        }));
    }
    const getStateAndActiveVersion = () => {

        setState(new_state => ({
            ...new_state,
            showActivateVersionDialog: true
        }));
    }

    const closeAllVersion = () => {
        setState((new_state)=>({
            ...new_state,
            job_template_versions: [],
        }))
    }

    console.log("sdvbcsdvcvsdhg", dataForRendered );
    
    return (
        <>
            {state.canRedirect ? <Navigate to={`/global/job/templates`} /> : null}
            {
                state.data_loading ? <Loading />
                    :
                    state.error_msg ?
                        <ErrorComponent variant="error-box" error={state.error_msg} />
                        :
                        <div className='' style={!state.edit_enabled ? 
                        state.job_template_versions.length > 0 ? 
                        { display: 'grid', gridTemplateColumns: '1fr 300px', backgroundColor: '#fff',  } : 
                        { backgroundColor: '#fff',  } : 
                        { backgroundColor: '#fff', }}>
                            <div className={classes.root}>
                                <Grid style={{}} container alignItems="center" justifyContent="space-between" lg={12}>
                                    {
                                        !templateNameEdit ?
                                            <div className='d-flex align-center'>
                                                <div>
                                                    <div className='mr-3' style={{ display: "flex" }}>
                                                        <p style={{ fontSize: '16px', fontWeight: '600', color: '#2f2f2f' }}>
                                                            {state.job_template_data && state.job_template_data.name ? state.job_template_data.name : 'Job Template Name'}
                                                            {/* <BorderColorIcon onClick={editIconClicked} className="cursor-pointer" style={{ fontSize: 20, color: '#4794eb' }} /> */}
                                                        </p>
                                                    </div>
                                                    {/* <p className="sub-heading">to understand more about job template please read the <a href="https://www.opstree.com/buildpiper/documentation/" target="_blank" className="text-anchor-blue">Documentation</a></p> */}
                                                </div>
                                                <div className='d-flex align-center ml-5'>
                                                    <div className='version-chip mr-3'>
                                                        Version: {state.job_template_data && state.job_template_data.template_version ? state.job_template_data.template_version : null} &nbsp; <span style={{ color: '#C1E2FF' }}>|</span> &nbsp; <span className='ri-checkbox-circle-fill' style={{ fontSize: '18px', verticalAlign: 'middle' }}></span>
                                                    </div>
                                                    {
                                                        state.edit_enabled ?
                                                            <div style={{ justifyContent: 'flex-end', backgroundColor: 'transparent', border:'none' }} >
                                                                {
                                                                    state.edit_enabled &&
                                                                    <button className='btn btn-primary mr-5' onClick={handleSaveTemplatePopup}>SAVE</button>
                                                                }
                                                                <button className="btn btn-outline-grey border-none" onClick={toggleCancel} style={{border:'none'}}>CANCEL</button>
                                                            </div>
                                                            :
                                                            <button className='btn btn-outline-primary ml-3 mr-5' onClick={toggleEdit}>
                                                                Edit
                                                            </button>
                                                    }

                                                    {state.show_active_version_button ?
                                                        null :
                                                        state.show_active_version_button == null || state.show_active_version_button == undefined ?
                                                            null :
                                                            state.edit_enabled ? 
                                                            null :
                                                            state.job_template_data && state.job_template_data.active?
                                                            null:
                                                            <button className='btn btn-primary mr-5' onClick={getStateAndActiveVersion}>
                                                                Activate Version
                                                            </button>
                                                    }

                                                </div>
                                                {!state.edit_enabled && 
                                                    <OptionList
                                                    loadVersionsTab={loadTemplateVersions}
                                                    RefreshForDelete={RefreshForDelete}
                                                    id={state.selectedVersioingTab ? state.selectedVersioingTab : template_id}
                                                    name={state.selectedVersioningTabName ? state.selectedVersioningTabName : ""}
                                                />
                                                }
                                                
                                            </div>

                                            :
                                            <div className='align-center justify-center' style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '30px' }} className='remove-margin-from-input'>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        mandatorySign
                                                        placeholder="Enter Name"
                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                        data={state.data}
                                                        error={state.error}
                                                    />

                                                </div>
                                                <button onClick={handleSubmitData} class="btn btn-submit mr-12">Save</button>
                                                <button onClick={() => setTemplateNameEdit(false)} className="btn btn-outline-grey">Cancel</button>
                                            </div>

                                    }


                                    <div>
                                        <CommonHorizontalTab
                                            tabList={[{ tabName: "Canvas", order: 1 }, { tabName: "YAML", order: 2 }]}
                                            selectedTabOrder={state.selectedTab}
                                            variant="capsule"
                                            updateSelectedTab={updateSelectedTab}
                                        />
                                    </div>
                                </Grid>
                                {/* card */}
                                <div className="mt-20" style={{ borderTop: '1px solid #dedede',  borderBottom:'1px solid rgb(222, 222, 222)', position:'relative' }}>
                                    {
                                        state.selectedTab === 1 ?
                                            <JobTemplateCardCopy2805
                                                previousCanvasLayer={previousStateLayers}
                                                updatePreviousCanvasLayer={setPreviousStateLayers}
                                                data={dataForRendered}
                                                edit_enabled={state.edit_enabled}
                                                updateJobTemplate={updateJobTemplate}
                                                jobTemplate={state.job_template_data}
                                                onClickedFunction={onClickedHandle}
                                                template_id={template_id}
                                                queueList={state.list} />
                                            :
                                            <div className='card-c-body' style={{ minHeight: '745px', overflow: 'auto' }}>
                                                <SyntaxHighlighter language="yaml" style={atomOneDark} >
                                                    {yaml}
                                                </SyntaxHighlighter>
                                            </div>
                                    }
                                    <div style={{ justifyContent: 'flex-end', backgroundColor: '#fff', position:'absolute', bottom:0, width:'100%' }} className="card-footer">
                                        
                                        <Link to={'/global/job/templates'}><button className="btn btn-outline-grey">Back</button></Link>
                                    </div>
                                </div>
                            </div>
                            {
                                !state.edit_enabled ?
                                    state.job_template_versions.length > 0 ?
                                        <div style={{ backgroundColor: '#F5FAFF', padding: "10px 0px" }}>
                                            <div className='d-flex space-between align-center'>
                                                <p style={{ fontSize: 14, color: '#2f2f2f', fontWeight: 600,padding:"0px 10px" }}>All Versions</p>
                                                <span className='ri-close-fill font-18 color-value cursor-pointer' onClick={closeAllVersion} ></span>
                                            </div>
                                            
                                            <div className='sidebar-job-temp'>
                                            <CommonHorizontalTab
                                                tabList={state.job_template_versions}
                                                selectedTabOrder={state.selectedVersioingTab}
                                                variant="v3-versioning-tabs"
                                                updateSelectedTab={updateSelectedVersioingTab}
                                            /> 
                                            </div>
                                        </div>
                                        : null : null
                            }

                        </div>
            }

            {/* <StepDetail/> */}
            <Dialog
                aria-labelledby="max-width-dialog-title"
                TransitionComponent={Transition}
                open={state.showDialog}
            >
                <div className='' style={{}}>
                    <div className='font-16 font-weight-600 color-header-new d-flex align-center space-between' style={{ backgroundColor: "#fafafa", padding: '8px 16px' }}>
                        <p>CAUTION</p>
                        <button className="btn btn-transparent" onClick={closeDialog}>
                            <span className='font-18 color-header-new'>&times;</span>
                        </button>
                    </div>
                    <div className='' style={{ backgroundColor: '#fff', padding: "24px 16px", display: 'grid', gridTemplateColumns: "1fr 1fr", gap: '10px' }}>
                        <div
                            className=''
                            style={{
                                backgroundColor: selected === 0 ? '#F5FAFF' : '#fff',
                                padding: '12px',
                                border: selected === 0 ? '1px solid #dfedff' : '1px solid #e6e6e6',
                                borderRadius: 6,
                                maxWidth: '308px',
                                cursor: 'pointer'
                            }}
                            onClick={() => handleClick(0)}
                        >
                            <div className='d-flex align-center space-between mb-20' style={selected === 0 ? { height: 56, width: 56, borderRadius: 6, backgroundColor: '#fff', border: '1px solid #dfedff', padding: 10 } : { height: 56, width: 56, borderRadius: 6, backgroundColor: '#F5FAFF', border: '1px solid #dfedff', padding: 10 }}>
                                <span className='ri-file-copy-line font-32' style={{ color: '#0086FF' }}></span>
                            </div>
                            <div>
                                <p className='font-16 font-weight-600 mb-10' style={selected === 0 ? { color: '#0086ff' } : {}}>Create a New Version</p>
                                <span className='font-12 font-weight-500 color-subheader-new'>Changes made will create a new version of this template which you can test and activate later.</span>
                            </div>
                        </div>
                        <div
                            className='color-header-new'
                            style={{
                                backgroundColor: selected === 1 ? '#F5FAFF' : '#fff',
                                padding: '12px',
                                border: selected === 1 ? '1px solid #dfedff' : '1px solid #e6e6e6',
                                borderRadius: 6,
                                maxWidth: '308px',
                                cursor: 'pointer'
                            }}
                            onClick={() => handleClick(1)}
                        >
                            <div className='d-flex align-center space-between mb-20' style={selected === 1 ? { height: 56, width: 56, borderRadius: 6, backgroundColor: '#FFF', border: '1px solid #dfedff', padding: 10 } : { height: 56, width: 56, borderRadius: 6, backgroundColor: '#F5FAFF', border: '1px solid #dfedff', padding: 10 }}>
                                <span className='ri-edit-2-line font-32' style={{ color: '#0086FF' }}></span>
                            </div>
                            <div>
                                <p className='font-16 font-weight-600 mb-10' style={selected === 1 ? { color: '#0086ff' } : { color: '#2f2f2f' }}>Override current version</p>
                                <span className='font-12 font-weight-500 color-subheader-new'>The changes that you make will immediately be reflected across all services in which this template is selected.</span>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-center justify-end' style={{ backgroundColor: '#FAFAFA', padding: "8px 16px" }}>
                        <button className='btn btn-outline-grey' onClick={closeDialog}>Cancel</button>
                        <button className='btn btn-primary' onClick={() => { checkAndUpdateFn(selected) }}>Save</button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                aria-labelledby="max-width-dialog-title"
                TransitionComponent={Transition}
                open={state.showActivateVersionDialog}
            >
                <div className='' style={{}}>
                    <div className='font-16 font-weight-600 color-header-new d-flex align-center space-between' style={{ backgroundColor: "#fafafa", padding: '8px 16px' }}>
                        <p>CAUTION</p>
                        <button className="btn btn-transparent" onClick={closeActiveVersionDialog}>
                            <span className="ri-close-line font-18 color-header-new"></span>
                        </button>
                    </div>
                    <div className='' style={{ backgroundColor: '#fff', padding: "24px 16px", }}>
                        <div
                            className=''
                            style={{
                                backgroundColor: '#fff',
                                padding: '12px',
                                margin: 'auto',
                                borderRadius: 6,
                                maxWidth: '308px',
                                cursor: 'pointer',
                                textAlign: 'center'
                            }}
                        >
                            <div className='d-flex align-center mb-20' style={{ height: 56, width: 56, borderRadius: 6, backgroundColor: '#fff', border: '1px solid #dfedff', padding: 10, margin: 'auto',justifyContent:"center" }}>
                                <span className='ri-info-i' style={{ color: '#0086FF', fontSize:"32px"}}></span>
                            </div>
                            <div>
                                <p className='font-16 font-weight-600 mb-10' style={{ color: '#0086ff' }}>Are you sure you want to activate this version? </p>
                                <span className='font-12 font-weight-500 color-subheader-new'>Activating this version means all the entities which are using these template will switch to this version.</span>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-center justify-end' style={{ backgroundColor: '#FAFAFA', padding: "8px 16px" }}>
                        <button className='btn btn-outline-grey mr-5' onClick={closeActiveVersionDialog}>Cancel</button>
                        <button className='btn btn-primary' onClick={activateCurrentVersion}>Save</button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default EditGlobalJobTemplate;
const ITEM_HEIGHT = 48;
const OptionList = (props) => {
    const url = props.url;
    const is_permitted = props.is_permitted;
    const id = props.id;
    const name = props.name;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const data = props.data ? props.data : {};
    const loadVersionsTab = props.loadVersionsTab;
    const RefreshForDelete = props.RefreshForDelete;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    console.log(name,"sdbsdvcsdv")


    return (
        <div>
            <button className="btn btn-transparent"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                    <span className='ri-more-2-fill' style={{ fontSize: "20px", }}></span>
            </button>
            <Popover
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <ul style={{ padding: '10px' , listStyle:'none'}}>
                    {/* <li className='mb-10'>
                        <Link to={`/global/job/template/edit`} className="d-flex text-anchor-blue">
                            <span className='d-flex align-center' style={{ gap: "7px" }}><BorderColorIcon style={{ color: '#505050' }} /><span style={{ marginRight: "7px", color: '#505050', fontSize: '12px', fontWeight: '500' }}>Edit</span></span>
                        </Link>
                    </li> */}
                    <li className='mb-10'>
                        <div onClick={()=>{loadVersionsTab();handleClose();}} className="d-flex text-anchor-blue cursor-pointer">
                            <span className='d-flex align-center' style={{ gap: "7px" }}><span className='ri-file-history-line' style={{ color: '#505050' }}></span><span style={{ marginRight: "7px", color: '#505050', fontSize: '12px', fontWeight: '500' }}>Version History</span></span>
                        </div>
                    </li>
                    <li className=''>
                        <Delete
                              varient='new_ui_versioning'
                              display_data_name={name}
                              data={{ entity_id: id, name:  "project_job_templates" }}
                              refresh={RefreshForDelete}
                              api_link={GenerateURL({template_id: id}, properties.api.get_single_global_job_template)}
                            />
                      
                    </li>
                </ul>

            </Popover>
        </div>
    )
}


const useStyles = makeStyles({
    '@global': {
        'html, body, #root': {
        backgroundColor: '#fff !important',
        }
    },
    root: {
        margin: '20px',
        marginTop: 0,
        paddingTop: '20px',
        background:'#fff',
        '& .card': {
            borderRadius: 4,
            marginTop: '20px'
        }
    },


    connectedLines: {

        borderTop: '1px solid'
    }
});






