import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import ResultItem, { ResultItemSkeleton } from './ResultItem';
import { useDebounce } from '../../../hooks/useDebounce';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';
import properties from '../../../properties/properties';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';
import withSearchResultAndLink from './withSearchResultAndLink';
import ApplicationResultItem from './ApplicationResultItem';
import FeatureResultItem from './FeatureResultItem';
import { ErrorComponent } from '../../utils/Error';
import { NoResultFound } from './ResultsWindow';
import { AdvancedFeaturesArray, FeaturesArray, searchTabs } from '../constants';

const SearchResultComponent = {
    features: withSearchResultAndLink(FeatureResultItem),
}

const FeatureResultWindow = ({searchQuery,searchEntity,onChangeHandler,onClose}) => {
    const selectedEntityLabel = searchTabs.find((tab)=>tab.id == searchEntity).label;
    const debouncedValue = useDebounce(searchQuery, 400);
    const [searchedQuery,setSearchedQuery] = useState("");
    const [searchResults,setSearchResults] = useState([]);
    const [advanceFeatureResults,setAdvanceFeatureResults] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');
    const [appList,setAppList] = useState([]);
    const [openedFeature,setOpenedFeature] = useState(null);

    useEffect(()=>{
        setSearchResults(FeaturesArray);
        setAdvanceFeatureResults(AdvancedFeaturesArray);
    },[])

    useEffect(() => {
        if(debouncedValue && debouncedValue?.length > 0){
            let result = FeaturesArray.filter((feature)=>{
                return feature.label.toLowerCase().includes(debouncedValue.toLowerCase());
            });
            let resultAdvanceFeature = AdvancedFeaturesArray.filter((feature)=>{
                return feature.label.toLowerCase().includes(debouncedValue.toLowerCase());
            });
            setSearchResults([...result|| [],...resultAdvanceFeature|| []]);
        }else{
            setSearchResults(FeaturesArray);
            setAdvanceFeatureResults(AdvancedFeaturesArray);
            setSearchedQuery('');
        }
    },[debouncedValue])

    useEffect(() => {
        if(searchEntity){
            fetchAllApplications();
            setSearchedQuery('');
        }
    },[searchEntity])

    function fetchAllApplications(){
        let requestInfo = {
            endPoint: GenerateURL({entity:'project',query:''},properties.api.global_search+"&all=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        setLoading(true);
        InvokeApi(requestInfo, 
            (response)=>{
                let result = response?.results
                setAppList(result);
                setLoading(false);
                setSearchResults(FeaturesArray);
            },
            (error)=>{
                let errorResponse = error?.details || JSON.stringify(error);
                setError(errorResponse);
                setLoading(false);
            }
        );
    }


    return (
        <Root>
            <ResultList>
                {error && error?.length > 0 ? 
                    <ErrorComponent
                        error={error || "Something went wrong"}
                    />
                : 
                <>
                    <div className='list-wrapper'>
                        {loading ? 
                            <ResultsContainer>
                                {Array.from({length:6   }).map((_)=>{
                                    return <ResultItemSkeleton />
                                })}
                            </ResultsContainer>
                        : 
                        searchResults?.length > 0 ?
                            <ScrollDiv>
                                {debouncedValue?.length > 0 ?
                                    <>
                                        <div className='search-heading mb-10'>
                                            {selectedEntityLabel}
                                        </div>
                                        <ResultsContainer>
                                            {searchResults.map((result,index)=>{
                                                const ResultComponent = SearchResultComponent?.[searchEntity];
                                                return <ResultComponent openedFeature={openedFeature} setOpenedFeature={setOpenedFeature} appList={appList} searchedQuery={searchedQuery} searchEntity={searchEntity} selectedEntityLabel={selectedEntityLabel} result={result} onClose={onClose} />
                                            })}
                                        </ResultsContainer>
                                    </> 
                                
                                :
                                    <>
                                        <div className='search-heading mb-10'>
                                            {selectedEntityLabel}
                                        </div>
                                        <ResultsContainer>
                                            {searchResults.map((result,index)=>{
                                                const ResultComponent = SearchResultComponent?.[searchEntity];
                                                return <ResultComponent openedFeature={openedFeature} setOpenedFeature={setOpenedFeature} appList={appList} searchedQuery={searchedQuery} searchEntity={searchEntity} selectedEntityLabel={selectedEntityLabel} result={result} onClose={onClose} />
                                            })}
                                        </ResultsContainer>
                                        
                                        <div className='search-heading mb-10 mt-20'>
                                            {"Advance Features"}
                                        </div>
                                        <ResultsContainer>
                                            {advanceFeatureResults.map((result)=>{
                                                const ResultComponent = SearchResultComponent?.[searchEntity];
                                                return <ResultComponent openedFeature={openedFeature} setOpenedFeature={setOpenedFeature} appList={appList} searchedQuery={searchedQuery} searchEntity={searchEntity} selectedEntityLabel={selectedEntityLabel} result={result} onClose={onClose} />
                                            })}
                                        </ResultsContainer>
                                    
                                    </>
                                }
                            </ScrollDiv>
                            :
                            <NoResultFound selectedEntityLabel={selectedEntityLabel} searchedQuery={searchedQuery} />
                        }
                    </div>
                </>}
            </ResultList>
        </Root>
    )
}

export default FeatureResultWindow;

const Root = styled('div')({
    width:'100%',
    //height:'433px',
    maxHeight:'505px',
    backgroundColor: "#FFFFFF",
    display:'grid',
    // gridTemplateColumns:'155px 1fr',
    gap:'38px',
    marginTop:'20px'
})

const SearchTabs = styled('div')(({searchEntity}) => ({
    display:'flex',
    flexDirection:'column',
    gap:'5px',
    '& .tab':{
        padding:'6px 8px',
        borderRadius:'6px',
        color:'#787878',
        cursor:'pointer',
        fontSize:'12px',
        lineHeight:'14px',
        '&.selected':{
            backgroundColor:'#F4F4F4',
            color:'#2F2F2F',
            fontWeight:'500',
            border:'1px solid #E6E6E6'
        },
        "&:hover":{
            backgroundColor:'#F4F4F4',
        }
    }
}))

const ResultList = styled('div')({
    display:'grid',
    gridTemplateRows:'auto 1fr',
    //gap:'12px',
    '& .search-text':{
        fontSize:'12px',
        lineHeight:'14.63px',
        color:'#505050',
        fontWeight:'500',
        marginBottom:'16px'
    },
    '& .highlight':{
        color:'#2F2F2F',
        marginBottom:'16px'
    },
    '& .search-heading':{
        fontSize:'14px',
        lineHeight:'17px',
        color:'#000000',
        fontWeight:'500',
        //marginTop:'16px',
    },

})

const ResultsContainer = styled('div')({
    display:'flex',
    flexDirection:'column',
    border:'1px solid #E6E6E6',
    borderRadius:'6px',
    marginRight:'6px',
})

const ScrollDiv = styled('div')({
    overflowY:'auto',
    maxHeight:'360px',
    '&::-webkit-scrollbar':{
        width:'3px !important',
        borderRadius:'30px !important',
        marginLeft:'10px'
    },
    '&::-webkit-scrollbar-track':{
        backgroundColor:'#E6E6E6 !important',
        borderRadius: '30px !important',
    },
    "&::-webkit-scrollbar-thumb":{
        backgroundColor:'#0086FF !important',
        borderRadius:'30px !important',
    },
});
