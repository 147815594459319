import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Input } from '../../components/genericComponents/Input';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../util/Validator';
import { getCommonFunctions } from '../serviceRevamp/add/ci_flow/SourceDetails';
import GenerateURL from '../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../util/apiInvoker';
import properties from '../../properties/properties';
import { Tooltip } from '@mui/material';
import useFetchPermissions from '../../components/customHooks/useFetchPermissions';

const manifest_kind_list = ["Binding", "ConfigMap", "Endpoints", "Event", "LimitRange", "PersistentVolumeClaim", "Pod", "PodTemplate",
    "ReplicationController", "ResourceQuota", "Secret", "ServiceAccount", "Service", "ControllerRevision",
    "DaemonSet", "Deployment", "ReplicaSet", "StatefulSet", "Application", "AppProject",
    "LocalSubjectAccessReview", "HorizontalPodAutoscaler", "CronJob", "Job", "Lease", "EndpointSlice", "Event",
    "Ingress", "ServiceIdler", "IstioOperator", "PodMetrics", "Alertmanager", "PodMonitor", "Prometheus",
    "PrometheusRule", "ServiceMonitor", "DestinationRule", "EnvoyFilter", "Gateway", "ServiceEntry", "Sidecar",
    "VirtualService", "WorkloadEntry", "WorkloadGroup", "Ingress", "NetworkPolicy", "BlockDeviceClaim",
    "BlockDevice", "CStorBackup", "CStorCompletedBackup", "CStorPoolInstance", "CStorRestore",
    "CStorVolumeClaim", "CStorVolumePolicy", "CStorVolumeReplica", "CStorVolume", "RunTask", "UpgradeTask",
    "PodDisruptionBudget", "RoleBinding", "Role", "AuthorizationPolicy", "PeerAuthentication",
    "RequestAuthentication", "VolumeSnapshot"]

const ManifestPlaceholderForm = ({ parentComponentState, resetParentState, ...props }) => {
    const classes = useStyles();
    const [state, setState] = useState({

        data: {
        },
        error: {},
        validations: {
            kind_type: [VALIDATION_TYPE_REQUIRED],
            placeholder: [VALIDATION_TYPE_REQUIRED],
            value: [VALIDATION_TYPE_REQUIRED],
            key_with_position: [VALIDATION_TYPE_REQUIRED]
        }
    })

    useEffect(() => {
        fetchPlaceholders();
    }, [])


    useEffect(() => {

        if (parentComponentState && parentComponentState.data.id) {
            if (parentComponentState.data['placeholder'])

                setState(prevState => ({
                    ...prevState,
                    data: { ...parentComponentState.data },
                    textField: false
                }))

            else
                setState(prevState => ({
                    ...prevState,
                    data: { ...parentComponentState.data },
                    textField: true
                }))
        }
    }, [parentComponentState])

    const inherits = props.inherits ? props.inherits : {}


    const commonFunctions = getCommonFunctions(state, setState, inherits)

    const application_id = props.application_id

    const fetchDataAfterSubmit = props.fetchDataAfterSubmit

    const fetchPlaceholders = () => {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_placeholders),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);

    }

    const handleSuccessApiHit = (data) => {

        setState(new_state => ({
            ...new_state,
            placeholders: data.results
        }));

    }

    const handleFailedApiHit = (error) => {
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
    }

    const toggleState = () => {
        setState({
            ...state,
            textField: !state.textField
        });
    }
    const toggleStateDropdown = () => {
        setState({
            ...state,
            drop_down: !state.drop_down
        });
    }


    function validateAndSave() {

        let updatedValidations = { ...state.validations }

        let formData = { ...state.data }

        if (state.textField) {
            delete updatedValidations.placeholder

            formData = { ...formData, placeholder: null }
        }

        else {
            delete updatedValidations.value

            formData = { ...formData, value: null }
        }

        let result = ValidateDataSet(formData, updatedValidations)

        let post_url = ""
        let placeholder_data

        if (result.valid) {

            if (state.data.id) {   //update

                post_url = GenerateURL({ application_id: application_id, id: state.data.id }, properties.api.update_manifest_placeholder)
                placeholder_data = { ...formData, project_id: application_id }
            }
            else {      //create
                post_url = GenerateURL({ application_id: application_id }, properties.api.save_manifest_placeholder)
                placeholder_data = [{ ...formData, project_id: application_id }]
            }

            PostData(post_url, placeholder_data, postSuccess, postFail);
        }
        else {
            setState(new_state => ({
                ...new_state,
                error: result.error
            }))
        }
    }

    function postSuccess(response) {

        setState(new_state => ({
            ...new_state,
            show_loading_icon: false,
            data_load_failure: false,
            post_success: true,
            data: {}
        }))
        resetState()
        fetchDataAfterSubmit()

    }

    function postFail(response) {
        setState(new_state => ({
            ...new_state,
            error_null: response.toString(),
            show_loading_icon: false,
            data_load_failure: true,
        }))
    }

    const resetState = () => {
        if (state.textField)

            setState((prevState) => ({

                ...prevState,
                data: { kind_type: state.drop_down ? '' : null, value: '', key_with_position: '' }
            }))

        else

            setState((prevState) => ({

                ...prevState,
                data: { placeholder: '', kind_type: state.drop_down ? '' : null, key_with_position: '' }
            }))
        resetParentState()
    }

    var create_manifest_url = GenerateURL({ application_id: application_id }, properties.api.save_manifest_placeholder, true);
    const is_permitted = useFetchPermissions()?.POST.includes(create_manifest_url);
    return (
        
            <div className='mt-20'>
                <Grid
                    className={`pr-15 pl-15 ${classes.cardHeader}`}
                    container
                    style={{ height: '40px' }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >

                    <span className='font-13'>Add Manifest Placeholder</span>
                    <div style={{ display: 'flex' }}>
                    </div>

                </Grid>

                <div className={classes.formCard}>
                    <Grid className='mb-5' container alignItems="center" spacing={2}>

                        <Grid item lg={6}>
                            <Input
                                type={state.drop_down ? "text" : "auto-complete-dropdown"}
                                name="kind_type"
                                mandatorySign
                                list={manifest_kind_list.length > 0 ?
                                    manifest_kind_list.map(item => {
                                        return {
                                            'id': item, 'label': item
                                        }
                                    })
                                    : []}
                                placeholder="Please Select"
                                label="Manifest Kind"
                                getOptionLabel={(option) => option.label}
                                default_css={true}
                                onChangeHandler={state.drop_down ? commonFunctions.onChangeHandler : commonFunctions.onKeyValueChangeHandler}
                                data={state.data}
                                error={state.error}
                                extraDiv={
                                    <button
                                        className="btn btn-clear mr-0 btn-link text-anchor-blue pr-0"
                                        style={{ padding: 0, height: 'auto' }}
                                        onClick={toggleStateDropdown}
                                    >
                                        {state.drop_down ? "Choose Value" : "Provide Custom Value"}</button>}
                            />
                        </Grid>

                        <Grid item lg={6}>
                            <Input
                                type="text"
                                name="key_with_position"
                                list={[]}
                                mandatorySign
                                placeholder=""
                                label="Placeholder Path"
                                onChangeHandler={commonFunctions.onChangeHandler}
                                data={state.data}
                                error={state.error}
                            />
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            type={state.textField ? "text" : "select"}
                            name={state.textField ? "value" : "placeholder"}
                            mandatorySign
                            list={state.placeholders ? state.placeholders.map(placeholder => {
                                return {
                                    'id': placeholder.format, 'label': placeholder.format
                                }

                            }) : []}
                            placeholder="Please Enter"
                            label="Placeholder Value"
                            onChangeHandler={commonFunctions.onChangeHandler}
                            data={state.data}
                            error={state.error}
                            extraDiv={<button className="btn btn-clear mr-0 btn-link text-anchor-blue pr-0" onClick={toggleState}>{state.textField ? "Choose Value" : "Provide Custom Value"}</button>}
                        />
                    </Grid>

                </div>
                <Grid
                    className={`pr-15 pl-15  border-top  border-radius-0 ${classes.cardHeader}`}
                    container
                    style={{ height: '40px' }}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <div className="d-flex">
                        <button className="transparent-btn nowrap mr-10" style={{ color: 'black' }} onClick={resetState}>
                              <span className='ri-close-line font-18' style={{ color: 'black' }}></span>
                            Clear
                        </button>
                        {
                            is_permitted ?
                                <button className="transparent-btn nowrap" onClick={validateAndSave}>
                                    <i className="ri-save-line" style={{ color: 'blue' }} /> Save
                                </button>
                                :
                                <Tooltip title="You are not allowed to perform this action">
                                    <button className="btn btn-disable cursor-not-allowed">
                                        <i className='ri-save-line' style={{ color: 'blue' }} /> Save
                                    </button>
                                </Tooltip>

                        }
                    </div>
                </Grid>
            </div>

        

    )

}

ManifestPlaceholderForm.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default ManifestPlaceholderForm

const useStyles = makeStyles(theme => ({

    formCard: {
        backgroundColor: '#dcd8d885',
        paddingBottom: '16px',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingTop: '4px',
        '& .MuiTextField-root': {
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                padding: '0px 14px!important'
            }

        }

    },

    cardHeader: {
        border: '1px solid #eaeaea',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    }
}))

