import React, { useState } from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import { GetAuth, IsSuperUser } from '../../util/security';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SwitchUser from './SwitchUser';
import { Input } from '../../components/genericComponents/Input';
import { getCommonFunctions } from '../../views/serviceRevamp/add/ci_flow/SourceDetails';
import { VALIDATION_TYPE_EMAIL, VALIDATION_TYPE_REQUIRED } from '../../util/Validator';

import { ValidateDataSet } from '../../util/Validator';
import properties from '../../properties/properties';
import GenerateURL from '../../util/APIUrlProvider';
import { PostData } from '../../util/apiInvoker';
import { Loading } from '../../views/utils/Loading'
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import 'remixicon/fonts/remixicon.css'

export function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [versionDailog, setVersionDialog] = React.useState(false);
  const classes = useStyles();
  const user_data = GetAuth();
  const company_logo_width = properties.logo_width;
  const company_name = properties.company_name.toLowerCase();
  const react_version = properties.release_version;
  const mysql_version = properties.mysql_version;
  const redis_version = properties.redis_version;
  const public_api_version = properties.public_api_version;
  const deploy_api_version = properties.deploy_api_version;
  const scheduled_api_version = properties.scheduled_api_version;
  const bp_agent_version = properties.bp_agent_version;

  const navigate = useNavigate(); // ✅ Hook is inside the component

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //var gitSourceIntegration = "/credentials";
  console.log("ansamx", user_data.name.split(' '));
  function getInitials(fullName) {
    const words = fullName.split(' ');
    const firstNameInitial = words[0].charAt(0).toUpperCase();
    const lastNameInitial = words.length > 1 ? words[words.length - 1].charAt(0).toUpperCase() : '';
    const initials = `${firstNameInitial}${lastNameInitial}`;
    return initials;
  }
  //const addCiLink = "/application/" +"3" + "/ci/add";
  const docker_registry_id = 1;
  const company_logo = properties.company_logo ? properties.company_logo : null
  const release_notes_url = 'https://www.buildpiper.io/release-notes/'
  return (
    <div className={classes.root}>
      <div>
        <div style={{ display: "flex", alignItems: "center", marginTop: '12px' }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
          <div className='user-company-details' style={company_logo ? {} : {}} > {/*width: '180px', paddingLeft:'20px', borderRadius:'25px' --- false condition */}
            {/* {company_logo ? 
            <div className='company-logo'>
              <img src={company_logo} style={company_logo_width ?  { height: "18px", width: company_logo_width } : { height: "18px", width: '103px' }} alt='..' />
            </div>
            : null} */}
            <div className='user-details'>
              {/* <p className='user-name text-ellipsis-109'>{user_data.name}</p>
              <p className='user-type'>{user_data.is_superuser ? "Super Admin" : "User"}</p> */}
              <div className='user-avatar nav-user-avatar'>
                <Avatar src="" className="avatar-user">{getInitials(user_data.name)}</Avatar> {/*/images/avatar-example.jpg"*/}
                <Avatar
                  alt="user-pic"
                  src="/images/avatar-hover.svg"
                  className="avatar-image"
                />
              </div>
            </div>

          </div>
          {/* <div className='notification' style={{ marginLeft: "20px" }}>
                    <img src="/images/notification-3-line.svg" style={{ width: "24px", height: "24px" }} />
                </div> */}
        </div>


      </div>
      <Menu
        id="simple-menu"
        className="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "12px",
          },
        }}
      >
        <div className='new-profile-settings '>
          <div className='profile-section-bg '>
            <div className='user-avatar'>
              <Avatar src="" style={{ border: "2px solid #FEA111", fontSize: "16px", backgroundColor: "#fff", color: "#2f2f2f", fontWeight: "600", width: '50px', height: '50px' }} className="avatar-user">{getInitials(user_data.name)}</Avatar>
            </div>
            <div className='user-details'>
              <p className='user-name font-14 font-weight-600 color-value'>{user_data.name}</p>
              <p className='user-type' style={{ marginBottom: "2px" }}>
                <span className='color-key font-12 font-weight-500'>Role:&nbsp;</span>
                <span className='color-value font-12 font-weight-500'>{user_data.is_superuser ? "Super Admin" : "User"}</span>
              </p>
              <p className='user-type'>
                <span className='color-key font-12 font-weight-500'>Email:&nbsp;</span>
                <span className='color-value font-12 font-weight-500'>{user_data.email}</span>
              </p>
            </div>
          </div>
          <SwitchUser />
          <div className='' style={{ height: '1px', backgroundColor: '#e6e6e6', width: '100%', margin: '6px 0px' }}></div>
          {
            IsSuperUser() ?
              <>

                <MenuItem className="profile-nav-item" onClick={handleClose}>
                  <RouterLink to={`/integration/containerRegistries`} class="d-flex align-center text-color-grey-mid">
                    <span className='ri-key-line font-20'></span>
                    <span className="font-12 ml-8">Container Registry</span>
                  </RouterLink>
                </MenuItem>

                <MenuItem onClick={() => {
                  handleClose();
                  navigate("/system-settings");
                }} className="profile-nav-item" >
                  <span className='ri-settings-3-line font-20 text-color-grey-mid'></span>
                  <span className="font-12 ml-8 text-color-grey-mid">System Settings</span>
                </MenuItem>

                <MenuItem className="profile-nav-item" onClick={handleClose}>
                  <RouterLink to="/role-groups" class="d-flex align-center text-color-grey-mid">
                    <span className='ri-group-line font-20'></span>
                    <span className="font-12 ml-8">Manage External User Groups</span>
                  </RouterLink>
                </MenuItem>

              </>
              :
              null
          }
          <RouterLink to="/health" >
            <MenuItem className="profile-nav-item" onClick={handleClose}>
              <span className='ri-apps-line font-20'></span>
              <span className="font-12 ml-8">Job Executors</span>
            </MenuItem>
          </RouterLink>
          <RouterLink to="/secrets/list" >
            <MenuItem className="profile-nav-item" onClick={handleClose}>
              <span className='ri-key-line font-20'></span>
              <span className="font-12 ml-8">Integration Credentials</span>
            </MenuItem>
          </RouterLink>
          <RouterLink to="/activity" >
            <MenuItem className="profile-nav-item" onClick={handleClose}>
              <span className='ri-task-line font-20'></span>
              <span className="font-12 ml-8">Job Execution History</span>
            </MenuItem>
          </RouterLink>

          <a target={"_blank"} href={release_notes_url}>
            <MenuItem className="profile-nav-item" onClick={handleClose}>
              <span className='ri-alarm-warning-line font-20'></span>
              <span className="font-12 ml-8">Latest Release Notes</span> <span className="bullet blue" style={{ position: 'static', marginLeft: '3px' }}></span>

            </MenuItem>
          </a>
          <RouterLink to="/initiateLogOut">
            <MenuItem className="logout-btn profile-nav-item" onClick={handleClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M3.3335 15.0001H5.00016V16.6667H15.0002V3.33341H5.00016V5.00008H3.3335V2.50008C3.3335 2.27907 3.42129 2.06711 3.57757 1.91083C3.73385 1.75455 3.94582 1.66675 4.16683 1.66675H15.8335C16.0545 1.66675 16.2665 1.75455 16.4228 1.91083C16.579 2.06711 16.6668 2.27907 16.6668 2.50008V17.5001C16.6668 17.7211 16.579 17.9331 16.4228 18.0893C16.2665 18.2456 16.0545 18.3334 15.8335 18.3334H4.16683C3.94582 18.3334 3.73385 18.2456 3.57757 18.0893C3.42129 17.9331 3.3335 17.7211 3.3335 17.5001V15.0001ZM5.00016 9.16675H10.8335V10.8334H5.00016V13.3334L0.833496 10.0001L5.00016 6.66675V9.16675Z" fill="#C11212" />
              </svg>
              <span className="font-12 ml-8">Sign Out</span>
            </MenuItem>
          </RouterLink>

          <div className={classes.version_root}>
            <Tooltip title={react_version ? react_version : "N/A"}>
              <p className="font-12 font-weight-500 text-left text-ellipsis-200 text-center color-key-gray mt-10">Build Version: {react_version ? react_version : "N/A"}</p>
            </Tooltip>

            <span className='detail font-12 font-weight-500 color-key-primary cursor-pointer mt-10' onClick={() => { setVersionDialog(true) }} role='button' tabIndex={0} onKeyDown={() => { }} >Version Details</span>
            <VersionDialog open={versionDailog} onClose={() => { setVersionDialog(false) }} />
          </div>


        </div>
      </Menu>
    </div>
  );
}


export function DialogHeader(props) {
  return (

    <p className="font-20">Invite Users</p>

  )
}

export function DialogBody(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    data: { user_emails: [] },
    error: {},
    validations: {
      email: [VALIDATION_TYPE_EMAIL, VALIDATION_TYPE_REQUIRED],
      name: [VALIDATION_TYPE_REQUIRED]
    }
  })
  const inherits = {}
  const commonFunctions = getCommonFunctions(state, setState, inherits)

  function inviteUserSubmit() {
    var result = ValidateDataSet(state.data, state.validations);
    var post_url = GenerateURL({}, properties.api.multiple_super_admin_add)
    if (result.valid) {
      var post_data = {
        ...state.data,
      }
      PostData(post_url, post_data, postSuccess, postFail);
      setState(new_state => ({
        ...new_state,
        post_success: "Loading",
      }))

    } else {
      setState(new_state => ({
        ...new_state,
        error: result.error
      }))
    }
  }

  function postSuccess(response) {
    setState(new_state => ({
      ...new_state,
      post_success: "Success",
    }))
  }

  function postFail(response) {
    setState(new_state => ({
      ...new_state,
      error_null: response.toString(),
      post_success: "Failed",
    }))
  }

  return (

    <div className={classes.dialogBody}>
      {
        state.post_success == "Loading" ?
          <Loading varient="light" />
          :
          state.post_success == "Success" ?
            <div className={classes.mainMsg} >
              <div className="checkmark">
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "100px" }} viewBox="0 0 161.2 161.2">
                  <circle className="path" fill="none" stroke="#FCC400" strokeWidth="4" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1" />
                  <path className="path" fill="none" stroke="#FCC400" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" d="M113 52.8l-38.9 55.6-25.9-22" />
                  <circle className="spin" fill="none" stroke="#555555" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.2175,12.2175" cx="80.6" cy="80.6" r="73.9" />
                </svg>
              </div>
              Invitation sent successfully.
            </div>

            :
            <>
              <Input
                type="text"
                name="name"
                label="Name"
                data={state.data}
                error={state.error}
                mandatorySign
                onChangeHandler={commonFunctions.onChangeHandler}
              />
              <Input
                type="text"
                name="email"
                label="Email"
                data={state.data}
                error={state.error}
                mandatorySign
                onChangeHandler={commonFunctions.onChangeHandler}
              />
              <div className="d-flex space-between">
                <button style={{ visibility: 'hidden' }}></button>
                <button className="btn btn-submit" onClick={inviteUserSubmit}>Invite Users</button>
              </div>
            </>
      }
    </div>

  )
}


function VersionDialog(props) {
  const open = props.open;
  const onClose = props.onClose;
  const classes = useStyles()
  const react_version = properties.release_version;
  const mysql_version = properties.mysql_version;
  const redis_version = properties.redis_version;
  const public_api_version = properties.public_api_version;
  const deploy_api_version = properties.deploy_api_version;
  const scheduled_api_version = properties.scheduled_api_version;
  const bp_agent_version = properties.bp_agent_version;

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { width: '655px', height: 'fit-content' } }}>
      <div className={classes.dialogContainer}>
        <div className={'dialogHeader'}>
          <span className='font-16 font-weight-600'>VERSION DETAILS</span>
          <span className='ri-close-fill font-24 cursor-pointer font-weight-500 color-value' onClick={onClose} role='button' tabIndex={0} onKeyDown={() => { }}></span>
        </div>
        <div className={'dialogBody'}>
          <div className='versionTable'>
            <div className='tableHeader'>
              <span>
                Type
              </span>
              <span>Version</span>
            </div>
            <div className='version-row'>
              <div className='d-flex align-center'>
                <span className='ri-pencil-ruler-2-line font-20 color-icon-secondary mr-8'></span>
                <span className='color-icon-secondary font-12 font-weight-600'>Front_end</span>
              </div>
              <div className='d-flex align-center'>
                <span className='color-icon-secondary font-12 font-weight-500'>{react_version ? react_version : "N/A"}</span>
              </div>
            </div>
            <div className='version-row'>
              <div className='d-flex align-center'>
                <span className='ri-table-line font-20 color-icon-secondary mr-8'></span>
                <span className='color-icon-secondary font-12 font-weight-600'>mySQL</span>
              </div>
              <div className='d-flex align-center'>
                <span className='color-icon-secondary font-12 font-weight-500'>{mysql_version ? mysql_version : "N/A"}</span>
              </div>
            </div>


            <div className='version-row'>
              <div className='d-flex align-center'>
                <span className='ri-settings-4-line font-20 color-icon-secondary mr-8'></span>
                <span className='color-icon-secondary font-12 font-weight-600'>Redis</span>
              </div>
              <div className='d-flex align-center'>
                <span className='color-icon-secondary font-12 font-weight-500'>{redis_version ? redis_version : "N/A"}</span>
              </div>
            </div>
            <div className='version-row'>
              <div className='d-flex align-center'>
                <span className='ri-group-line font-20 color-icon-secondary mr-8'></span>
                <span className='color-icon-secondary font-12 font-weight-600'>Public API</span>
              </div>
              <div className='d-flex align-center'>
                <span className='color-icon-secondary font-12 font-weight-500'>{public_api_version ? public_api_version : "N/A"}</span>
              </div>
            </div>
            <div className='version-row'>
              <div className='d-flex align-center'>
                <span className='ri-rocket-2-line font-20 color-icon-secondary mr-8'></span>
                <span className='color-icon-secondary font-12 font-weight-600'>Deploy API</span>
              </div>
              <div className='d-flex align-center'>
                <span className='color-icon-secondary font-12 font-weight-500'>{deploy_api_version ? deploy_api_version : "N/A"}</span>
              </div>
            </div>
            <div className='version-row'>
              <div className='d-flex align-center'>
                <span className='ri-timer-line font-20 color-icon-secondary mr-8'></span>
                <span className='color-icon-secondary font-12 font-weight-600'>Scheduled API</span>
              </div>
              <div className='d-flex align-center'>
                <span className='color-icon-secondary font-12 font-weight-500'>{scheduled_api_version ? scheduled_api_version : "N/A"}</span>
              </div>
            </div>
            <div className='version-row'>
              <div className='d-flex align-center'>
                <span className='ri-code-s-slash-fill font-20 color-icon-secondary mr-8'></span>
                <span className='color-icon-secondary font-12 font-weight-600'>Bp Agent</span>
              </div>
              <div className='d-flex align-center'>
                <span className='color-icon-secondary font-12 font-weight-500'>{bp_agent_version ? bp_agent_version : "N/A"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    borderRight: '0px!important',
    '& .simple-menu': {
      width: '250px',
      '& .MuiMenu-list': {
        paddingBottom: '0px!important'
      },
      '& .MuiListItem-root': {
        '& a:visited': {
          color: '#0086ff'
        },
      }
    }
  },
  '& .material-icons': {
    color: 'rgb(254, 157, 17) '
  },
  mainMsg: {
    fontSize: '24px',
    padding: '20px 0px',
    fontWeight: 300,
    lineHeight: 1.2,
    textAlign: 'center'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsCount: {
    fontSize: '9px',
    backgroundColor: '#ff5252',
    padding: '.25rem',
    borderRadius: '50%',
    position: 'absolute',
    bottom: '30px',
    right: '0px',
    height: '10px',
    width: '10px'
  },
  MuiAvatarColorDefault: {
    color: ' #fff',
    backgroundColor: '#6d84a1 ',
    fontWeight: '600'
  },
  bellIcon: {
    backgroundColor: '#6d84a1',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
  },
  newHeight: {
    minHeight: '50px!important'
  },
  logo: {
    height: '80%!important'
  },
  avatarColor: {
    color: '#fff',

  },
  avatarRoot: {
    width: "30px",
    height: "30px",
    display: "flex",
    // overflow: "hidden",
    position: "relative",
    fontWeight: 600,
    backgroundColor: '#0086ff',
    fontSize: "1.15rem",
    alignItems: "center",
    flexhSrink: "0",
    lineHeight: "1",
    userSelect: "none",
    borderRadius: "4px",
    justifyContent: "center",
    '&:before': {
      content: '""',
      height: '10px',
      width: '10px',
      borderRadius: '5px',
      backgroundColor: 'red',
      position: 'absolute',
      top: '-5px',
      right: '-5px'
    }
  },
  userDetails: {
    padding: '15px',
    textAlign: 'center',
    '& .username': {
      fontSize: '16px',
      lineHeight: '22px',
      color: '#000',
      // marginBottom: '10px',
      fontWeight: '400',
      width: '160px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'center',
      margin: 'auto'
    },
    '& .other-details': {
      fontSize: '12px',
      width: '250px'
    },
    '& .text-lite-gray': {
      color: '#9d9d9d'
    }
  },
  footer: {
    padding: '8px',
    textAlign: 'center',
  },
  heading: {
    backgroundColor: '#284060',
    color: '#fff',
    fontSize: '12px',
    lineHeight: '1.4',
    padding: '10px'
  },
  dialogBody: {
    padding: '25px'
  },
  version_root: {
    borderTop: '1px solid #E6E6E6',
    marginTop: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .detail': {
      '&:hover': {
        textDecoration: 'underline'
      }

    }
  },
  dialogContainer: {
    //width: '655px',
    height: '467px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    '& .dialogHeader': {
      padding: "16px",
      display: "flex",
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#FAFAFA !important',
    },
    '& .dialogBody': {
      padding: "24px 16px 24px 16px",
      height: "391px !important",
      overflowY: "scroll",
      '& .versionTable': {
        borderRadius: "6px",
        border: "1px solid #CACACA",
        '& .tableHeader': {
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          display: "grid",
          gridTemplateColumns: "30% 70%",
          borderBottom: "1px solid #E6E6E6",
          padding: '8px 12px 8px 12px',
        },
        '& .version-row': {
          backgroundColor: "#FFF",
          borderBottomLeftRadius: "6px",
          borderBottomRightRadius: "6px",
          display: "grid",
          // gridTemplateColumns: "25% 37.5% 37.5%",
          gridTemplateColumns: "30% 70%",
          borderBottom: "1px solid #CACACA",
          padding: "16px 12px 16px 12px",
        }
      }
    }
  }
}));