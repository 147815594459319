import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { Tooltip } from '@mui/material';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';

const VersioningDetailsOverview = (props) => {
    const prev_state = props.prev_state;
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const [showLoading, setShowLoading] = useState(false);
    const [state, setState] = useState({
    });
    const extraProps = props.extraProps;
    const application_id = extraProps.application_id;
    const service_id = extraProps.service_id;
    const component_env_id = extraProps.component_env_id;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);

    useEffect(() => {
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state])
    console.log(prev_state,"___11111")
    return (
        <div className=" section-service-overview overview-card-section grid-temp-3-widthfull">
            <div className="overview-single-card">
                        <div className="overview-card-heading-section">
                            <div className="text__box">
                                <p className="overview-card-value-text text-uppercase">
                                    Versioning Details
                                            </p>
                                {/* <p className="no-data__msg ">NA</p> */}

                            </div>
                            {is_permitted?
                        
                        state.status == "DRAFT" ? 
                        <Link
                        to={{
                          pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/cd/${cd_id}/edit`,
                          search: `?selectedTabOrder=10&selected_cd_id=${extraProps?.selected_cd_data?.id}`,
                        }}
                      >
                        <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                      </Link>
                      :
                        <EditDetailsConfirmationDialog
                        application_id={application_id}
                        service_id = {service_id}
                        component_env_id = {component_env_id}
                        selectedTabOrder={10}
                        cd_data={prev_state}
                        selected_cd_id = {extraProps?.selected_cd_data?.id}
                        />
                   
                   :  <Tooltip title="You are not allowed to perform this action" > 
                     <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                    </Tooltip>}
                        </div>
                        <div className="overview-card-value-access" style={{ gap: '0px 20px' }}>
                        <p className="overview-card-value-text">
                                Git Url:&nbsp;
                                {
                                    state.versioning_repo && state.versioning_repo.git_url? 
                                    <span className="overview-card-value-text">
                                            {state.versioning_repo.git_url}
                                    </span>
                                    : <span className="no-data__msg ">N/A</span>
                                }
                            </p>
                            <p className="overview-card-value-text">
                                Draft Branch URL:&nbsp;
                                {
                                    state.draft_branch? 
                                    <span className="overview-card-value-text">
                                            {state.draft_branch}
                                    </span>
                                    : <span className="no-data__msg ">N/A</span>
                                }
                            </p>
                            <p className="overview-card-value-text">
                                Main Branch URL:&nbsp;
                                {
                                    state.main_branch? 
                                    <span className="overview-card-value-text">
                                            {state.main_branch}
                                    </span>
                                    : <span className="no-data__msg ">N/A</span>
                                }
                            </p>
                            <p className="overview-card-value-text">
                                Deployed Branch URL:&nbsp;
                                {
                                    state.deployed_branch? 
                                    <span className="overview-card-value-text">
                                            {state.deployed_branch}
                                    </span>
                                    : <span className="no-data__msg ">N/A</span>
                                }
                            </p>
                            <p className="overview-card-value-text">
                                Manifest Auto Apply:&nbsp;
                                {
                                    state.auto_refresh? 
                                    <span className="overview-card-value-text">
                                            Yes
                                    </span>
                                    : <span className="overview-card-value-text">
                                    No
                            </span>
                                }
                            </p>
                            <p className="overview-card-value-text">
                            Versioning Path :&nbsp;
                                {
                                    state.versioning_path? 
                                    <span className="overview-card-value-text">
                                            {state.versioning_path}
                                    </span>
                                    : <span className="no-data__msg ">N/A</span>
                                }
                            </p>
                        </div>
                    </div>
        </div>
    )
}

VersioningDetailsOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default VersioningDetailsOverview
