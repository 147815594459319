import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import ResultItem, { ResultItemSkeleton } from './ResultItem';
import { useDebounce } from '../../../hooks/useDebounce';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';
import properties from '../../../properties/properties';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';
import withSearchResultAndLink from './withSearchResultAndLink';
import ApplicationResultItem from './ApplicationResultItem';
import FeatureResultItem from './FeatureResultItem';
import { ErrorComponent } from '../../utils/Error';
import CommonHorizontalTab from '../../../components/genericComponents/CommonHorizontalTab';
import IntegrationResultItem from './IntegrationResultItem';
import { searchTabs } from '../constants';

const SearchResultComponent = {
    service: withSearchResultAndLink(ResultItem),
    pipeline: withSearchResultAndLink(ResultItem),
    project: withSearchResultAndLink(ApplicationResultItem),
    features: withSearchResultAndLink(FeatureResultItem),
    job_template: withSearchResultAndLink(ResultItem),
    integration:withSearchResultAndLink(IntegrationResultItem),
    env: withSearchResultAndLink(ResultItem),
}


const ResultsWindow = ({searchQuery,searchEntity,onChangeHandler,onClose}) => {
    const selectedEntityLabel = searchTabs.find((tab)=>tab.id == searchEntity).label;
    const selectedEntitySubTabs = searchTabs.find((tab)=>tab.id == searchEntity)?.subTabs;
    const debouncedValue = useDebounce(searchQuery, 400);
    const [searchedQuery,setSearchedQuery] = useState("");
    const [searchResults,setSearchResults] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');
    const [openedFeature,setOpenedFeature] = useState(null);
    const [selectedSubTabOrder, setSelectedSubTabOrder] = useState(
        Number(localStorage.getItem(`${searchEntity}_selectedSubTabOrder`)) || 0
    );
    const [selectedSubTab,setSelectedSubTab] = useState(null);


    useEffect(()=>{
        if(debouncedValue && debouncedValue?.length > 0){
            fetchSearchResults(debouncedValue);
        }else{
            setSearchedQuery('');
        }
    },[debouncedValue])

    useEffect(() => {
        if (selectedEntitySubTabs?.length > 0) {
            const storedOrder = Number(localStorage.getItem(`${searchEntity}_selectedSubTabOrder`));
            if (storedOrder >= 0 && storedOrder < selectedEntitySubTabs.length) {
                setSelectedSubTab(selectedEntitySubTabs[storedOrder]);
                setSelectedSubTabOrder(storedOrder);
            } else {
                setSelectedSubTab(selectedEntitySubTabs[0]);
                setSelectedSubTabOrder(0);
            }
        }
    }, [selectedEntitySubTabs]);

    useEffect(() => {
        if (searchEntity && searchQuery?.length > 0) {
            if (selectedEntitySubTabs?.length > 0) {
                if (selectedSubTab) {
                    fetchSearchResults(searchQuery);
                }
            } else {
                fetchSearchResults(searchQuery);
            }
        } else {
            setSearchResults([]);
        }
    },[searchEntity,selectedSubTab])

    function fetchSearchResults(query){
        setLoading(true);
        let entityType = selectedEntitySubTabs?.length > 0 ? selectedSubTab?.id : searchEntity;
        if(!entityType){
            return;
        }
        let requestInfo = {
            endPoint: GenerateURL({entity:entityType,query:query},properties.api.global_search+"&all=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        setSearchedQuery(query);
        InvokeApi(requestInfo,handleFetchSuccess,handleFetchError);
    }

    function handleFetchSuccess(response){
        let result = response?.results
        setSearchResults(result);
        setLoading(false);
    }

    function handleFetchError(error){
        let errorResponse = error?.detail || JSON.stringify(error);
        setError(errorResponse);
        setLoading(false);
    }

    const updateSelectedTab = (tab_order) => {
        setSelectedSubTabOrder(tab_order);
        setSelectedSubTab(selectedEntitySubTabs[tab_order]);
        localStorage.setItem(`${searchEntity}_selectedSubTabOrder`, tab_order);
    }

    return (
        <Root>
            <ResultList>
                {error && error?.length > 0 ? 
                    <ErrorComponent
                        error={error || "Something went wrong"}
                    />
                : 
                <>
                    {selectedEntitySubTabs && selectedEntitySubTabs?.length > 0 && 
                        <div className='sub-tab-list'>
                            <CommonHorizontalTab
                                tabList={selectedEntitySubTabs.map((tab,index) => ({tabName:tab.label,order: index}))}
                                selectedTabOrder={selectedSubTabOrder}
                                updateSelectedTab={updateSelectedTab}
                                variant = 'gray-round-corner-tab-slim'
                            />
                        </div>
                    }
                    <div>
                        
                        
                            {loading ? 
                                <div className='search-text'>
                                    <GenericSkeleton animation={'wave'} height={15} style={{borderRadius:'4px'}} />
                                </div>
                            : 
                                searchedQuery?.length>0 && searchResults?.length > 0 ? 
                                    <div className='search-text'>
                                        <span className='highlight'>{searchResults.length}</span> Results found for "<span className='highlight'>{searchedQuery}</span>" in {selectedEntitySubTabs?.length > 0 ? selectedSubTab?.label : selectedEntityLabel}
                                    </div>
                                : null
                            }
                        
                        <div className='search-heading'>
                            {selectedEntitySubTabs?.length > 0 ? selectedSubTab?.label : selectedEntityLabel}
                        </div>
                    </div>
                    <div className='list-wrapper'>
                        {loading ? 
                            <ResultsContainer>
                                {Array.from({length:6   }).map((_)=>{
                                    return <ResultItemSkeleton />
                                })}
                            </ResultsContainer>
                        : 
                        searchResults?.length > 0 ?
                            <ScrollDiv>
                                <ResultsContainer>
                                    {searchResults.map((result)=>{
                                        const ResultComponent = SearchResultComponent?.[searchEntity];
                                        return <ResultComponent 
                                                    openedFeature={openedFeature} 
                                                    setOpenedFeature={setOpenedFeature} 
                                                    searchedQuery={searchedQuery} 
                                                    searchEntity={searchEntity} 
                                                    selectedEntityLabel={selectedEntityLabel} 
                                                    result={result} 
                                                    onClose={onClose} 
                                                    selectedSubTab={selectedSubTab}
                                                />
                                    })}
                                </ResultsContainer>
                            </ScrollDiv>
                            :
                            <NoResultFound selectedEntityLabel={selectedEntityLabel} searchedQuery={searchedQuery} />
                        }
                    </div>
                </>}
            </ResultList>
        </Root>
    )
}

export const NoResultFound = ({selectedEntityLabel,searchedQuery}) => {
    return (
        <NoResult>
            <span className={`${searchedQuery?.length > 0 ? "ri-emotion-sad-line" : "ri-search-2-line"} color-tertiary font-24 line-height-normal`}></span>
            <span className='font-12 font-weight-500 color-tertiary'>
                {searchedQuery?.length > 0 ? 
                <>
                <span>
                    Sorry, no result found for
                </span>
                <span className='highlight'> "{searchedQuery}" </span>
                <span>
                    in {selectedEntityLabel}
                </span>
                
                </>
                :
                    "Please Enter Your Search Query"
                }
            </span>
        </NoResult>
    )
}

export default ResultsWindow;

const Root = styled('div')({
    width:'100%',
    //height:'433px',
    maxHeight:'505px',
    backgroundColor: "#FFFFFF",
    display:'grid',
    // gridTemplateColumns:'155px 1fr',
    gap:'38px',
    marginTop:'20px'
})


const ResultList = styled('div')({
    display:'grid',
    gridTemplateRows:'auto 1fr',
    gap:'12px',
    '& .search-text':{
        fontSize:'12px',
        lineHeight:'14.63px',
        color:'#505050',
        fontWeight:'500',
        marginBottom:'16px',
        marginTop:'8px',
    },
    '& .highlight':{
        color:'#2F2F2F',
        marginBottom:'16px'
    },
    '& .search-heading':{
        fontSize:'14px',
        lineHeight:'17px',
        color:'#000000',
        fontWeight:'500',
        //marginTop:'16px',
    },
    '& .sub-tab-list':{
        display:'flex',
        width:'100%',
        gap:'8px',
        overflowX:'auto',
        '&::-webkit-scrollbar':{
            display:'none'
        }
    }

})

const ResultsContainer = styled('div')({
    display:'flex',
    flexDirection:'column',
    border:'1px solid #E6E6E6',
    borderRadius:'6px',
    marginRight:'6px',
})

const ScrollDiv = styled('div')({
    overflowY:'auto',
    maxHeight:'360px',
    '&::-webkit-scrollbar':{
        width:'3px !important',
        borderRadius:'30px !important',
        marginLeft:'10px'
    },
    '&::-webkit-scrollbar-track':{
        backgroundColor:'#E6E6E6 !important',
        borderRadius: '30px !important',
    },
    "&::-webkit-scrollbar-thumb":{
        backgroundColor:'#0086FF !important',
        borderRadius:'30px !important',
    },
});

const NoResult = styled('div')({
    '& .highlight':{
        color:'#2F2F2F',
    },
    border:'1px solid #E6E6E6',
    padding:'14px 12px',
    backgroundColor:'#FFFFFF',
    display:'flex',
    alignItems:'center',
    justifyContent:"center",
    flexDirection:'column',
    borderRadius:'6px',
    gap:'8px',
    height:'126px'
})