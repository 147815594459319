import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';

function SendForApprovalConfirmationDialog(props) {
    const confirmApproval = props.confirmApproval;
    console.log(confirmApproval,"confirmApprovalconfirmApproval")
    const [state, setstate] = useState({
        data: {},
        error: {},
        loading: false,

    })
    const variant = props.variant ? props.variant : null;
    const audit_card_data = props.audit_card_data ? props.audit_card_data : null
    const handleClickOpen = () => {
        setstate({ ...state, open: true });
    };

    const handleClose = () => {
        setstate({ ...state, open: false, });
    };

    console.log(audit_card_data,"confirm approval =============>")

    return (
        <>
            {
                variant === "new_button"?
                audit_card_data?.status === "DRAFT_PENDING" ? 
                <span className='chip-v1 default-outline-chip-v1 btn-progess-animate button-processing mr-30'>DRAFT IN PROGESS</span> :
                audit_card_data?.status === "DRAFT" ? 
                <button onClick={handleClickOpen} className='chip-v1 default-outline-chip-v1 mr-30'>SEND FOR APPROVAL</button> :
                audit_card_data?.status === "DRAFT_FAILED" ?
                <button onClick={handleClickOpen} className='chip-v1 default-outline-chip-v1  mr-30'>RE-SEND FOR DRAFT</button> : //button-processing
                audit_card_data?.status === "APPROVAL_REQUESTED_PENDING" ? 
                <span className='chip-v1 default-outline-chip-v1 btn-progess-animate button-processing mr-30'>SENDING FOR APPROVAL</span> : 
                audit_card_data?.status === "APPROVAL_REQUESTED" ? 
                <button onClick={handleClickOpen}  className='chip-v1 default-outline-chip-v1 mr-30'>APPROVE REQUEST</button> : 
                audit_card_data?.status === "APPROVAL_REQUESTED_FAILED" ? 
                <button onClick={handleClickOpen} className='chip-v1 default-outline-chip-v1  mr-30'>RE-SEND FOR APPROVAL</button> :
                audit_card_data?.status === "APPROVED_FAILED" ? 
                <button onClick={handleClickOpen} className='chip-v1 default-outline-chip-v1  mr-30'>RE-APPROVE</button> :
                <button onClick={handleClickOpen} className='icon-btn-v1 icon-btn-outline-default'><span className='ri-edit-line'></span></button>
                :
                <button className="btn btn-round-v2" onClick={handleClickOpen}><ThumbUpAltOutlinedIcon className="text-success font-16" style={{ color: '#69df9b' }} /></button>
            }
            
            <Dialog
                open={state.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="card ">
                    <div className="card-body d-flex" style={{ height: 300 }}>
                        <div className="m-auto" style={{ width: '400px' }}>
                            <i className="ri-information-line"  style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                            <div className="">
                                {confirmApproval ? <p className="font-16 font-weight-bold text-center mb-5">Are you sure you want to confirm approval</p> : <p className="font-16 font-weight-bold text-center mb-5">Are you sure you want to send this details for approval</p>}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer border-top pd-10 justify-end d-flex align-center">
                        <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="btn btn-primary-v2" onClick={confirmApproval ? () => { props.confirmForApproval(audit_card_data.id) } : () => { props.sendForApproval(audit_card_data.id)}}>
                            Confirm
                        </button>
                    </div>
                </div>
            </Dialog>

        </>
    )
}

SendForApprovalConfirmationDialog.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default SendForApprovalConfirmationDialog
