import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%', // Ensure dropdown has space
  },
}));

const icon = <span style={{ fontSize: 'small' }} />;
const checkedIcon = <span style={{ fontSize: 'small' }} />;

export default function MultiSelectInput({
  list = [],
  name,
  onChangeHandler,
  value = [],
  placeholder = 'Enter Issue Name',
}) {
  const classes = useStyles();

  // Map selected IDs to option objects
  const selectedOptions = value
    .map(id => list.find(item => item.id === id))
    .filter(Boolean);

  const handleChange = (e, newValue) => {
    const updatedIds = newValue.map(item => item.id);
    onChangeHandler(name, updatedIds);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        options={list}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        value={selectedOptions}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
      />
    </div>
  );
}

MultiSelectInput.propTypes = {
  list: PropTypes.array,
  name: PropTypes.string,
  onChangeHandler: PropTypes.func,
  value: PropTypes.array,
  placeholder: PropTypes.string,
};
