import React from 'react';
import { makeStyles } from '@mui/styles';


const SidePanelQuestionnaire = () => {
  const classes = useStyles();
  return (
    <div className={classes.staticwrap} style={{ backgroundColor: '#051939', height: '100%' }}>
      <div className='staticwrap-inner'>
        <h1 className='h1'> Stage Approval Questions</h1>
        <p>
        While approving a stage, approval related questions can be setup via this functionality. Setup questions will appear while approver tries to approve the stage and the  response will be captured in BuildPiper. For compliance needs if there is need to push the answers to a field in JIRA, that integration is also possible.
          <br></br> <br></br>
          {/* To learn more about this feature <br /> */}
          {/* please read <a href='' style={{ color: '#dd8e15' }}>Documentation</a> */}
        </p>
      </div>
    </div>
  )
}
export default SidePanelQuestionnaire
const useStyles = makeStyles({
  staticwrap: {
    '& .staticwrap-inner': {
      padding: '215px 30px 0 40px',
      color: '#fff',
      '& p': {
        fontSize: '13px',
        lineHeight: '23px',
        marginTop: '30px',
      }
    },
    '& .h1': {
      fontSize: '25px',
      lineHeight: '36px',
      color: '#ffffff',
      fontWeight: '300',
      position: 'relative',

      '&:before': {
        content: '""',
        borderBottom: '1px solid #000',
        position: 'absolute',
        borderLeft: 'none',
        bottom: '0',
        left: 0,
        display: 'inline-block',
        width: '40px',
        height: '4px',
        background: '#2e2af3',
      }
    },
  }
})

