import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
const LinkIssueListSingleRow = (props) => {
    const classes = useStyles();
    const data = props.data ? props.data : null;
    const key = props.key? props.key : null
  return (
    <div className = {classes.card_root} key={key}>
        <div className='section-issue-type'>
            <span className='section-issue-type-heading'>
                Issue Type
            </span>
            <span className='section-issue-type-value'>
                {data.issue_link_type ? data.issue_link_type : "N/A"}
            </span>
        </div>
        <div className='section-issue-type'>
            <span className='section-issue-type-heading'>
                Reference Name
            </span>
            <span className='section-issue-type-value'>
            {data.jira_ref_name_key ? data.jira_ref_name_key : "N/A"}
            </span>
        </div>
        <div className='section-issue-type'>
            <span className='section-issue-type-heading'>
                Issues Linked Tickets
            </span>
            <span className='section-issue-type-value'>
            {data.issue &&data.issue.length > 0  ? data.issue.map(item => {
                return <><span>{item}</span><span className='comma'>,&nbsp;</span></>
            }) : "N/A"}
            </span>
        </div>
        <div className='section-issue-type'>
            <LongMenu 
            handleDelete={props.handleDelete}
            handleEdit={props.handleEdit}
             />
        </div>
    </div>
  )
}

LinkIssueListSingleRow.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};


export default LinkIssueListSingleRow

const ITEM_HEIGHT = 48;
function LongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div>
        <button className="btn btn-transparent"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}>
            <i className="ri-more-2-fill"  style={{color:'#0096db', fontSize:'2rem'}}  />
        </button>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 'fit-content',
              padding: '0px 10px'
            },
          }}
        >
            <MenuItem onClick={handleClose} style={{display:"grid", padding:'0px'}}>
                <button className='btn btn-link' style={{color:'#878787'}} onClick={props.handleEdit}>
                    <i className="ri-edit-line style text-anchor-blue" style={{color:'#0096db', fontSize:'16px', verticalAlign: 'text-bottom'}} />&nbsp;Edit issue
                </button>
            </MenuItem>
            <div className="seprator" style={{margin: 0}}></div>
            <MenuItem onClick={handleClose}  style={{display:"grid", padding:'0px'}}>
                <button className='btn btn-link' style={{color:'#878787'}} onClick={props.handleDelete}>
                <i className={`ri-delete-bin-line`} style={{ color: '#ff8969',fontSize:'16px', verticalAlign: 'text-bottom' }}></i>&nbsp;Delete issue
                </button>
            </MenuItem>
        </Menu>
      </div>
    );
  }

  LongMenu.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };
  


const useStyles = makeStyles((theme) => ({
    card_root: {
        display:'grid',
        gridTemplateColumns:'25% 25% 40% auto',
        gap: 20,
        backgroundColor: '#fff',
        borderBottom: '1px solid #eaeaea',
        padding: '15px',
        '&:nth-child(even)':{
            backgroundColor: '#fcfcfc',
        },
        '&:last-child':{
            borderBottom: '0px solid #eaeaea',
        },
        '& .section-issue-type':{
            fontSize: '12px',
            '& .section-issue-type-heading':{
                color: '#4c4c4c',
                display: 'block'
            },
            '& .section-issue-type-value':{
                color: '#9a9a9a',
                display: 'block'
            },
            '& .comma':{
                '&:last-child':{
                    display:'none'
                }
            }
        }
    },
    
}));