import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import StepDetailShow from './StepDetailShow';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function StepDetail(props) {
    const {stepData, onClose}= props
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        onClose()
        setOpen(true)
    }

    const handleClickClose = () => {
        setOpen(false)        
    }

    return (
        
        <div style={{display: 'flex'}} className="dialog-left">
                <i className="ri-eye-line" style={{marginRight: '8px'}}></i>
                <span style={{cursor : 'pointer'}} className="font-12" onKeyDown={()=>{}} onClick={handleClickOpen} role="button" tabIndex={0}>
                    View Step</span>
                <Dialog 
                className="dialog-left"
                style={{marginLeft:'auto'}}
                open={open}
                onClose={handleClickClose}
                aria-labelledby="max-width-dialog-title" TransitionComponent={Transition}>

                <div className='pl-15 pr-15 align-center space-between'style={{ height : '50px',display: 'flex', background: '#3e7bfa'}}>
                    
                  <span className='font-15' style={{color:'#fff'}}>Step : Slack Notification</span>
                  <button className="btn btn-transparent"onClick={() => handleClickClose()} aria-label="close" style={{color:'#fff'}} >
                        <span className='ri-close-line font-24' style={{fontWeight: '500'}}></span>
                    </button>
                </div>

                <div>
                    <StepDetailShow stepData={stepData}/>
                </div>
            </Dialog>
               
        </div>
    )
}


StepDetail.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };