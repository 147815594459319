import React, { useState } from 'react';
import Popover from '@mui/material/Popover';

const VMGroupDisplay = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const vmGroupNames = data.vm_group ? data.vm_group.map(item => item.virtual_group_name) : [];
  const displayNames = vmGroupNames.slice(0, 2);
  console.log(displayNames, "displayNames")
  const remainingCount = vmGroupNames.length - 2;
  const remainingNames = vmGroupNames.slice(2);

  return (
    <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
      <p>VM Group:</p>
      <p className="text-grey-83">
        {vmGroupNames.length > 0 ? (
          <>
            {displayNames.map((name, index) => (
              <>
                <span key={index} className="chip chip-primary font-12 font-weight-500 mr-3">
                  {name}
                </span>
                {/* {index < displayNames.length - 1 && ', '} */}
              </>
            ))}
            {remainingCount > 0 && (
              <>
                <span onClick={handleClick} className="chip chip-primary font-12 font-weight-500" style={{ cursor: 'pointer', }}>+{remainingCount}</span>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div className='pd-20'>
                    {remainingNames.map((name, index) => (
                      <>
                        <div key={index} className={ "chip chip-primary font-12 font-weight-500 mb-5"} style={{width:'fit-content'}}>
                          {name}
                        </div>
                        {/* {index < displayNames.length - 1 && ', '} */}
                      </>
                    ))}
                  </div>
                  {/* <Typography sx={{ p: 2 }}>{remainingNames.join(', ')}</Typography> */}
                </Popover>
              </>
            )}
          </>
        ) : (
          'NA'
        )}
      </p>
    </div>
  );
};

export default VMGroupDisplay;
