import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';

const CanaryListingHeader = props=>{
    const classes = useStyles();
    const application_id = props.application_id
    return(
        // <div className="d-flex align-center space-between">
        //     <div className="main-div">
        //         <p className="heading-primary">Canary Templates</p>
        //         <p className="sub-heading">
        //             To understand more about Canary Templates please read the <a href="https://www.opstree.com/buildpiper/documentation/" target="_blank" className="text-anchor-blue">Documentation</a>
        //         </p>
        //     </div>
        //     <Link to={"/application/" + application_id + "/canary-template/add"} className="btn btn-primary">New Canary Templates</Link>
        // </div>
        <PageHeader
            heading={"Canary Templates"}
            subHeading={
            <> To understand more about Canary Templates please read the{" "}
                <a
                href="https://www.buildpiper.io/documentation/"
                target="_blank"
                >
                Documentation
                </a></>
            }
            commonDivMargin={true}
            //icon="ri-flashlight-fill"
            backgroundColor='#FFFFFF'
            primaryButton={{
                actionType:'link',
                action:"/application/" + application_id + "/canary-template/add",
                text:'Add Canary Template',
                icon: <span className="ri-add-line font-14"></span>,    
                buttonClass: 'btn-primary'
            }}
        />
    )
}

CanaryListingHeader.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const useStyles = makeStyles({
  
});

export default CanaryListingHeader;