import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import '../../../../../assets/style.css'
import FormControl from '@mui/material/FormControl';
import properties from '../../../../../properties/properties';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Input } from "../../../../../components/genericComponents/Input";
import PageHeader from '../../Components/PageHeader';
const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1 ,
      marginTop:'3rem'  
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    mainDivSelected:{
      display:'flex', 
      alignItems:'center', 
      backgroundColor:'#fff', 
      padding:'.5rem 1.25rem', 
      borderRadius:'30px 0px 0px 30px',
      margin:'20px 0px 20px 20px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    mainDiv:{
      display:'flex', 
      alignItems:'center',
      maxWidth:'160px', 
      backgroundColor:'#fff', 
      padding:'.5rem 1.25rem', 
      borderRadius:'30px 30px 30px 30px',
      margin:'20px'
    },
    count:{
      width: '30px',
      height: '30px',
      border:'1px solid #ccc',
      borderRadius:'50%',
      textAlign:'center',
      paddingTop:'2px'
    },
    text:{
      fontSize:'11px',
      fontWeight:400,
    },
    mainBodyDiv:{
      backgroundColor: '#fff',
      padding:'15px 20px',
      borderRadius:'8px 8px 0px 0px', 
      borderTop:'1px solid#dedede',
      borderLeft:'1px solid#dedede',
      borderRight:'1px solid#dedede',
    },
    countBody:{
      backgroundColor:'#1d5b81',
      color:'#fff',
      width: '30px',
      height: '30px',
      borderRadius:'50%',
      textAlign:'center',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      fontWeight:800
    },
    tabBodyDivHeading:{
      display:'flex', 
      alignItems:'center',
      paddingBottom:'1rem',
      borderBottom:'1px solid #ccc' 
    },
    textBody:{
      fontSize:'16px',
      fontWeight:300,
      color:'#000'
    },
    formDiv:{
      padding:'0px 0px',
      margin:'0px 0px'
    },
    InputLabel:{
      fontSize: '12px',
      fontWeight:400,
    },
    hindText:{
      fontSize:'10px',
      color:'#666',
      fontWeight:400,
      marginLeft:'.5rem'
    },
    stepper:{
      margin:'1rem 0rem'
    },
    subHeading:{
      fontSize:'12px',
      color:'#666',
      fontWeight:400,
      marginBottom:'1rem'
    },
    impMakr:{
      color: 'red'
    },
    mBlank:{
      '& .input-component .error-message':{
        marginLeft:'10px!important'
      }
    }
    
  }));


const available_roles=properties.roles;

const RoleMaping =(props) => {
  const classes = useStyles();
  const data = props.data;
  const error = props.error;
  const update_data_callback = props.update_data_callback
  const status = props.status;
  const roles = props.roles;
  const state_roles = getDataForRoles(data);

  function getRoleCodeFromId(id){
    var role_code="";
    roles.forEach(role => {
      if(role.id == id){
        role_code = role.role_master.code;
      }      
    });
    return role_code;
  }

  function getDataForRoles(data){
    var new_value = [];
    if(data.roles){
      data.roles.forEach(role=>{
        if(role.access){
          new_value.push(getRoleCodeFromId(role.project_role_id));
        }
      });
    }
    return {roles:new_value};
  }

  const onChangeHandler = (key,value) => {
    if(!(value.length > 0)){
      update_data_callback(key,null);
    }else{
      var new_value = [];
      roles.forEach(role=>{
        new_value.push({
          project_role_id:role.id,
          access:value.includes(role.role_master.code)
        });
      });
      update_data_callback(key,new_value);
    }
  }

  return(
    <>
    { status == "POST_REQUESTED"? 
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop> : null
      }
    <PageHeader title="Define role base access to your service" service_name={props.service_name} env_name={props.env_name} sub_env_name={props.sub_env_name} />
    <div className={classes.formDiv}>
    <div>
      <FormControl fullWidth>
        <Grid conatiner>
          <Grid lg={12} item direction="row"  className="card-add-service">
          <Grid lg={12} direction="row" className="card-add-service-head">
             <div className=" formTag">
                <h4 className='mainHeading'>Users in the selected roles will have access to this environment</h4>
             </div>
              </Grid>
            <div className={classes.mBlank}  style={{display:'flex', marginBottom:'1.5rem'}}>
                <Input 
                type="imgCheckbox" 
                name="roles"
                list={available_roles} 
                onChangeHandler={onChangeHandler} 
                data={state_roles} 
                error={error}
                varient="multicheck"
                />

            </div>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  </div> 
  </> 
  );
};
  
RoleMaping.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default RoleMaping;
  