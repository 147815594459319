import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../../../components/genericComponents/Input';

import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

import AddMoreValue from '../../../../../../components/genericComponents/inputs/AddMoreValue';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../../../util/Validator';
import { ErrorComponent } from '../../../../../utils/Error';

function NetworkSection(props) {
    const classes = useStyles();
    const zones = props.zones;
    const zone_private_subnet_inherits = getInheritsForZones(zones);
    const inherits = props.inherits;
    const prev_state = props.prev_state;
    const priv_cidr_inherits = {}
    const [state, setState] = useState(prev_state ? prev_state : {
        data: {
            vpc: "",
            subnets: {},
            access: "private"
        }, error: {}
    });

    function onChangeHandler(e) {
        const k = e.target.name;
        const v = e.target.value;
        updateData(k, v);
    }

    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
            },
            error: {
                ...new_state.error,
                [k]: "",
            }
        }));
    }

    function updatePublicCidrForZone(zone, e) {
        const v = e.target.value;
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                subnets: {
                    ...new_state.data.subnets,
                    [zone]: {
                        public_subnet: v
                    }
                }
            },
            error: {},
        }));
    }

    // Overridings
    inherits.validateForm = () => {
        const validations = {
            vpc: [VALIDATION_TYPE_REQUIRED],
            subnets: [VALIDATION_TYPE_REQUIRED],
            access: [VALIDATION_TYPE_REQUIRED],
            pub_cidr: state.data.access == "public" ? [VALIDATION_TYPE_REQUIRED] : [],
            priv_cidr: state.data.access == "public" ? [] : [VALIDATION_TYPE_REQUIRED]
        }

        const data = state.data;
        data.priv_cidr = priv_cidr_inherits.getData ? priv_cidr_inherits.getData() : "";
        const subnets = state.data.subnets;
        var is_error_in_subnets = false;
        var prestine_zone = [];
        zones.forEach(zone => {
            if (!subnets[zone]) {
                prestine_zone.push(zone);
                is_error_in_subnets = true;
            } else {
                if (subnets[zone].public_subnet) {
                    //TODO continue to validate for private subnets.
                    const priv_zone_result = zone_private_subnet_inherits[zone].validateForm();
                    const priv_zone_data = zone_private_subnet_inherits[zone].getData();

                    console.log("priv_zone_data", priv_zone_data);
                    if (priv_zone_data.length == 0) {
                        priv_zone_result.valid = false;
                    }

                    if (!priv_zone_result.valid) {
                        is_error_in_subnets = true;
                    } else {
                        subnets[zone].private_subnet = zone_private_subnet_inherits[zone].getData();
                    }

                } else {
                    subnets[zone].error = {
                        public_subnet: "It is a Required Field"
                    };
                    is_error_in_subnets = true;
                }
            }
        });
        const result = ValidateDataSet(data, validations);
        const priv_cidr_data = priv_cidr_inherits.getData ? priv_cidr_inherits.getData() : [];
        if (data.access != "public") {
            const priv_cidr_result = priv_cidr_inherits.validateForm();
            if (!priv_cidr_result.valid) {
                result.valid = false;
            } else {
                if (priv_cidr_data.length == 0) {
                    result.valid = false;
                    result.error = result.error ? result.error : {}
                    result.error.priv_cidr = "It is required"
                }
            }
        }
        if (!result.valid || is_error_in_subnets) {
            if (is_error_in_subnets) {
                result.error = result.error ? result.error : {};
                result.error.subnets = "Please fill out the details for All Zones";
                setState(new_state => ({
                    ...new_state,
                    data: {
                        ...new_state.data,
                        subnets: {
                            ...subnets
                        },
                        priv_cidr: priv_cidr_data
                    },
                    error: {
                        ...result.error,
                    }
                }))
            } else {
                setState(new_state => ({
                    ...new_state,
                    data: {
                        ...new_state.data,
                        subnets: {
                            ...subnets
                        }
                    },
                    error: {
                        ...result.error,
                    }
                }))
            }
        } else {
            setState(new_state => ({
                ...new_state,
                data: {
                    ...new_state.data,
                    subnets: {
                        ...subnets
                    }
                },
                error: {}
            }))
        }
        return {
            valid: (result.valid && !is_error_in_subnets)
        }
    }

    inherits.getState = () => {
        const result = {
            ...state,
            error: {}
        }
        return result;
    }

    console.log("network section", state);

    return (
        <>
            <div className={classes.formDiv}>
                <Grid container justify="center">
                    <div className='fromClass'>
                        <Grid item lg={12}>
                            <Grid container justify="center">
                                <Grid item lg={12}>
                                    <div className='card mb-10' style={{ boxShadow: 'none' }}>
                                        <div className='card-header'>
                                            <p className="heading">Add VPC Details<span className="sub-heading">(Please enter VPC CIDR or VPC ID)</span></p>
                                        </div>
                                        <div className="card-body">
                                            <div className="section">
                                                <Input
                                                    type="text"
                                                    mandatorySign
                                                    label="Please Provide VPC CIDR/ID"
                                                    placeholder="Enter VPC CIDR"
                                                    name="vpc"
                                                    error={state.error}
                                                    onChangeHandler={onChangeHandler}
                                                    data={state.data} />
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item lg={12} direction="row">
                                    <div className='card mb-10' style={{ boxShadow: 'none' }}>
                                        <div className='card-header'>
                                            <p className="heading">Add Subnet<span className="sub-heading">
                                            (Please Provide atleast 1 Public, 1 Private Subnet CIDR or ID for the AWS Zones)
                                                </span></p>
                                            {
                                                state.error.subnets ?
                                                    <ErrorComponent error={state.error.subnets} />
                                                    : null
                                            }
                                        </div>
                                        {
                                            zones.map((zone, key) => (
                                                <>
                                                    <div className={classes.subHeader} style={{ borderTop: '0px' }}>
                                                        <span className="text-black">Zone {key + 1}: {zone}</span>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="section">
                                                            <Grid container spacing="2">
                                                                <Grid item lg={12}>
                                                                    <Input
                                                                        type="text"
                                                                        mandatorySign
                                                                        label="Public Subnet CIDR/IP"
                                                                        placeholder="subnet ip"
                                                                        name="pub_subnet"
                                                                        error={{ pub_subnet: state.data.subnets[zone] ? state.data.subnets[zone].error ? state.data.subnets[zone].error.public_subnet : "" : "" }}
                                                                        onChangeHandler={(e) => { updatePublicCidrForZone(zone, e) }}
                                                                        data={{ pub_subnet: state.data.subnets[zone] ? state.data.subnets[zone].public_subnet : "" }} />
                                                                </Grid>
                                                                <Grid item lg={12}>
                                                                    <div className="card">
                                                                        <div className='card-header'>
                                                                            <div className='heading'>
                                                                                {"Private Subnet CIDR/ID"}
                                                                            </div>
                                                                        </div>
                                                                        <div className="addmore">
                                                                            <AddMoreValue values={state.data.subnets[zone] ? state.data.subnets[zone].private_subnet : []} inherits={zone_private_subnet_inherits[zone]} />
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                </Grid>
                                <Grid item lg={12} direction="row">
                                    <div className='card mb-10' style={{ boxShadow: 'none' }}>
                                        <div className='card-header'>
                                            <p className="heading">Please Select Cluster Access</p>
                                        </div>
                                        <div className="card-body">
                                            <div className="section">
                                                <Grid container spacing="2">
                                                    <Grid item lg={12}>
                                                        <Input
                                                            type="radio"
                                                            name="access"
                                                            data={state.data}
                                                            error={state.error}
                                                            label=""
                                                            onChangeHandler={onChangeHandler}
                                                            list={[{ label: "Public Access", value: "public" }, { label: <><span>Protected Access</span><span className="text-green">&nbsp;(Recommended)</span></>, value: "private" }]}
                                                        />

                                                        {
                                                            state.data.access == "public" ?
                                                                <div className="grid-input">
                                                                    <div className="inputLabel">CIDR</div>
                                                                    <Input
                                                                        type="text"
                                                                        mandatorySign
                                                                        placeholder="Enter CIDR"
                                                                        name="pub_cidr"
                                                                        error={state.error}
                                                                        onChangeHandler={onChangeHandler}
                                                                        data={state.data} />
                                                                </div>
                                                                :
                                                                <>
                                                                    {
                                                                        state.error.priv_cidr ?
                                                                            <ErrorComponent error={state.error.priv_cidr} />
                                                                            :
                                                                            null
                                                                    }

                                                                    <AddMoreValue values={state.data.priv_cidr} inherits={priv_cidr_inherits} />
                                                                </>

                                                        }
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </>
    );
}
NetworkSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default NetworkSection;

function getInheritsForZones(zones) {
    const return_obj = {};
    zones.forEach(zone => {
        return_obj[zone] = {};
    });
    return return_obj;
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: 32,
        paddingTop: '32px',
        
    },
    MainHeading: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#000',
        lineHeight: '24px'
    },
    SubHeading: {
        fontSize: '11px',
        color: '#9d9d9d',
        lineHeight: '15px'
    },
    mainBodyDiv: {
        backgroundColor: '#f6f8f8',
        padding: '15px 20px',
        borderTop: '1px solid#dedede',
    },
    subHeader: {
        backgroundColor: '#fcfcfc',
        fontSize: '12px',
        lineHeight: 2,
        padding: '5px 10px',
        borderBottom: '1px solid #dedede',
        borderTop: '1px solid #dedede',
        color: '#9a9a9a'
    },
    formDiv: {
        padding: '30px 15px',
        '& .addmore':{
            '& .addmore-cont':{
                borderRadius:'0px',
                border:'none'
            }
        },
        '& .d-flex': {
            '& .input-component': {
                width: '86%'
            },
        },
        '& .mb-input-0': {
            '& .input-component': {
                marginBottom: '0px'
            }
        }
    }
}))