import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';

const ConfigSecretOverview = (props) => {
    const prev_state = props.prev_state;
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const [showLoading, setShowLoading] = useState(false);
    const [state, setState] = useState({
        showValue: false
    });
    const extraProps = props.extraProps;
    const application_id = extraProps.application_id;
    const service_id = extraProps.service_id;
    const auto_approval_flag = extraProps.auto_approval_flag;
    const show_sidecar_overview = extraProps.show_sidecar_overview;
    const env_cd_id = extraProps.env_cd_id;
    const component_env_id = extraProps.component_env_id;
    const service_env_data = extraProps.service_env_data;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);
    const handleToggle = () => {
        setState({
            ...state,
            showValue: !state.showValue,
        })
    }
    useEffect(() => {
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state])
    console.log("svsajdbmsdnb", state);
    return (
        <>
            {
                show_sidecar_overview ?
                    <div className="section-service-overview" style={{ border: '0px', padding: '10px' }}>
                        <div className="d-flex align-center space-between">
                            <div className="overview-heading">
                                ConfigMap and Secrets
                            </div>

                            {is_permitted ?

                                state.status == "DRAFT" ?
                                <Link
                                to={{
                                  pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/sidecars/edit/`,
                                  search: `?env_cd_detail_id=${env_cd_id}` +
                                          `&selectedTabOrder=2` +
                                          `&sidecar_id=${state.id}` +
                                          `&env_cd_id=${env_cd_id}` +
                                          `&service_env_data=${encodeURIComponent(service_env_data)}`,
                                }}
                              >
                                <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                              </Link>
                               :
                                    <EditDetailsConfirmationDialog
                                        application_id={application_id}
                                        service_id={service_id}
                                        auto_approval_flag={auto_approval_flag}
                                        component_env_id={component_env_id}
                                        selectedTabOrder={2}
                                        sidecar_id={state.id}
                                        env_cd_id={env_cd_id}
                                        service_env_data={service_env_data}
                                    />

                                : <Tooltip title="You are not allowed to perform this action" >
                                    <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                                </Tooltip>}

                        </div>
                        <div className="overview-card-section grid-template-3" style={{ gridTemplateColumns: '1fr 1fr' }}>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        ConfigMap
                                    </p>
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value">
                                        <p>ConfigMap Name</p>
                                        <p>Volume Mount Path</p>
                                    </div>
                                    <div className="env-variable-box">
                                        {state.env_cd_init_container_configmap && state.env_cd_init_container_configmap.length > 0 ?
                                            <>
                                                {state.env_cd_init_container_configmap.map(config => (
                                                    config.mount_path != null &&
                                                    <div className="data-key-value-pair">
                                                        <p>{config.name}</p>
                                                        <p>{config.mount_path}</p>
                                                    </div>
                                                ))}
                                            </>
                                            : <p className="no-data__msg-env-var" >No Data Available</p>}
                                        {/*  */}


                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Secrets
                                    </p>
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value">
                                        <p>Secret Name</p>
                                        <p>Volume Mount Path</p>
                                    </div>
                                    <div className="env-variable-box">
                                        {state.env_cd_init_container_secret && state.env_cd_init_container_secret.length > 0 ?
                                            <>
                                                {state.env_cd_init_container_secret.map(secret => (
                                                    secret.mount_path != null &&
                                                    <div className="data-key-value-pair">
                                                        <p>{secret.name}</p>
                                                        <p>{secret.mount_path}</p>
                                                    </div>
                                                ))}
                                            </>
                                            : <p className="no-data__msg-env-var" >No Data Available</p>}

                                        {/*  */}


                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Empty Directory
                                    </p>
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value">
                                        <p>Name</p>
                                        <p>Volume Mount Path</p>
                                    </div>
                                    <div className="env-variable-box">
                                        {state.env_cd_init_container_empty_dir && state.env_cd_init_container_empty_dir.length > 0 ?
                                            <>
                                                {state.env_cd_init_container_empty_dir.map(secret => (
                                                    <div className="data-key-value-pair">
                                                        <p>{secret.name}</p>
                                                        <p>{secret.mount_path}</p>
                                                    </div>
                                                ))}
                                            </>
                                            : <p className="no-data__msg-env-var" >No Data Available</p>}




                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Hostpath
                                    </p>
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value" style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                                        <p>Name</p>
                                        <p>Hostpath</p>
                                        <p>Volume Mount Path</p>
                                    </div>
                                    <div className="env-variable-box">
                                        {state.env_cd_init_container_host_path && state.env_cd_init_container_host_path.length > 0 ?
                                            <>
                                                {state.env_cd_init_container_host_path.map(secret => (
                                                    <div className="data-key-value-pair" style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                                                        <p>{secret.name}</p>
                                                        <p>{secret.host_path}</p>
                                                        <p>{secret.mount_path}</p>
                                                    </div>
                                                ))}
                                            </>
                                            : <p className="no-data__msg-env-var" >No Data Available</p>}




                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        PVCs
                                    </p>
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value">
                                        <p>PVC Name</p>
                                        <p>PVC Path</p>
                                    </div>
                                    <div className="env-variable-box">
                                        {state.env_cd_init_container_volume_mount && state.env_cd_init_container_volume_mount.length > 0 ?
                                            <>
                                                {state.env_cd_init_container_volume_mount.map(single_pvc => (
                                                    <div className="data-key-value-pair">
                                                        <p>{single_pvc.name}</p>
                                                        <p>{single_pvc.mount_path}</p>
                                                    </div>
                                                ))}
                                            </>
                                            : <p className="no-data__msg-env-var" >No Data Available</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Entry Command &amp; Command Args
                                    </p>
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value">
                                        {
                                            state.command ?
                                                <p>Command</p>
                                                :
                                                <p>Command Args</p>
                                        }
                                    </div>
                                    <div className="env-variable-box">
                                        {
                                            state.command &&
                                            <div className="data-key-value-pair">
                                                <p>{state.command}</p>
                                            </div>
                                        }
                                        {
                                            state.cmd_args &&
                                            <div className="data-key-value-pair">
                                                <p>{state.cmd_args}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="section-service-overview" style={{ border: '0px', padding: '10px' }}>
                        <div className="d-flex align-center space-between">
                            <div className="overview-heading">
                                ConfigMap and Secrets
                            </div>

                            {is_permitted ?

                                state.status == "DRAFT" ?
                                <Link
                                to={{
                                  pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/cd/${cd_id}/edit`,
                                  search: `?selectedTabOrder=3`,
                                }}
                              >
                                <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                              </Link>
                               :
                                    <EditDetailsConfirmationDialog
                                        application_id={application_id}
                                        service_id={service_id}
                                        auto_approval_flag={auto_approval_flag}
                                        component_env_id={component_env_id}
                                        selectedTabOrder={3}
                                        cd_data={prev_state}
                                        selected_cd_id={extraProps?.selected_cd_data?.id}
                                    />

                                : <Tooltip title="You are not allowed to perform this action" >
                                    <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                                </Tooltip>}

                        </div>
                        <div className="overview-card-section grid-template-3" style={{ gridTemplateColumns: '1fr 1fr' }}>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        ConfigMap
                                    </p>
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value">
                                        <p>ConfigMap Name</p>
                                        <p>Volume Mount Path</p>
                                    </div>
                                    <div className="env-variable-box">
                                        {state.env_cd_configmap && state.env_cd_configmap.length > 0 ?
                                            <>
                                                {state.env_cd_configmap.map(config => (
                                                    config.mount_path != null &&
                                                    <div className="data-key-value-pair">
                                                        <p>{config.name}</p>
                                                        <p>{config.mount_path}</p>
                                                    </div>
                                                ))}
                                            </>
                                            : <p className="no-data__msg-env-var" >No Data Available</p>}
                                        {/*  */}


                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Secrets
                                    </p>
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value">
                                        <p>Secret Name</p>
                                        <p>Volume Mount Path</p>
                                    </div>
                                    <div className="env-variable-box">
                                        {state.env_cd_secret && state.env_cd_secret.length > 0 ?
                                            <>
                                                {state.env_cd_secret.map(secret => (
                                                    secret.mount_path != null &&
                                                    <div className="data-key-value-pair">
                                                        <p>{secret.name}</p>
                                                        <p>{secret.mount_path}</p>
                                                    </div>
                                                )

                                                )}
                                            </>
                                            : <p className="no-data__msg-env-var" >No Data Available</p>}

                                        {/*  */}


                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        ConfigMap Keys And Subpath
                                    </p>
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value" >
                                        <p>ConfigMap Name</p>
                                        <p>Key : Subpath</p>

                                    </div>
                                    <div className="env-variable-box">
                                        {state.env_cd_configmap_keys && state.env_cd_configmap_keys.length > 0 ?
                                            <>
                                                {state.env_cd_configmap_keys.map(configmap => (
                                                    configmap.name != null &&
                                                    <div className="data-key-value-pair" style={{ height: "fit-content" }} > {/*style={{ gridTemplateColumns: "30% 30% 30%" }}*/}
                                                        <p>{configmap.name}</p>
                                                        {configmap.sub_path_key_value.map((keypath, index) => (

                                                                <span style={index != 0 ? { marginLeft: "105%" } : {}}>
                                                                    <p>{keypath.name} : {keypath.sub_path}</p>
                                                                </span>



                                                            ))}

                                                        </div>
                                                )

                                                )}
                                            </>
                                            : <p className="no-data__msg-env-var" >No Data Available</p>}

                                        {/*  */}


                                    </div>
                                </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Secret Keys And Subpath
                                    </p>
                                </div>
                                <div className="overview-card-value-env-var">
                                    <div className="heading-key-value" >
                                        <p>Secret Name</p>
                                        <p>Key : Subpath</p>

                                    </div>
                                    <div className="env-variable-box">
                                        {state.env_cd_secret_keys && state.env_cd_secret_keys.length > 0 ?
                                            <>
                                                {state.env_cd_secret_keys.map(secret => (
                                                    secret.name != null &&
                                                    <div className="data-key-value-pair" style={{ height: "fit-content" }} > {/*style={{ gridTemplateColumns: "30% 30% 30%" }}*/}
                                                        <p>{secret.name}</p>
                                                        {secret.sub_path_key_value.map((keypath, index) => (

                                                            <span style={index != 0 ? { marginLeft: "105%" } : {}}>
                                                                <p>{keypath.name} : {keypath.sub_path}</p>
                                                            </span>



                                                            ))}

                                                        </div>
                                                )

                                                )}
                                            </>
                                            : <p className="no-data__msg-env-var" >No Data Available</p>}

                                        {/*  */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </>
    )
}

ConfigSecretOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
  

export default ConfigSecretOverview;