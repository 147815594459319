import React from "react";
import PropTypes from 'prop-types';
import moment from'moment';



export default function DeploymentConditions(props){
   var deployment_yaml = props.deployment_yaml?props.deployment_yaml:{}
   var replicaStatus = deployment_yaml.status
   var conditions = deployment_yaml.status ? deployment_yaml.status.conditions : []
   
    return(
        <div>
        <div className="pd-10">
            <p className="font-11 d-flex align-center space-between">
           <div>
           <p className="font-15 ">
                Deployment Conditions
            </p>
            <p className="font-11 text-grey-b6">
            Displaying list of all the conditions related to new deployent
            </p>
           
           </div>
           {
                replicaStatus ?
                <div className="d-flex align-center justify-flexend">
            <span className="text-grey-83 mr-5">Total Replicas: </span><span className=" mr-5 square-box bg-round-grey text-grey-83">{replicaStatus.replicas}</span>
            <span className="text-grey-83 mr-5">Ready Replicas: </span><span className=" mr-5 square-box bg-round-grey text-grey-83">{replicaStatus.readyReplicas}</span>
            <span className="text-grey-83 mr-5">Available Replicas: </span><span className=" mr-5 square-box bg-round-grey text-grey-83">{replicaStatus.availableReplicas}</span>
            <span className="text-grey-83 mr-5">Unavailable Replicas: </span><span className=" mr-5 square-box bg-round-grey text-grey-83">{replicaStatus.unavailableReplicas}</span>
            </div>
            :null
            }
            </p>
        </div>
        <table className="table table-responsive pd-10">
            <tr >
                <th className="text-grey-83">Type</th>
                <th className="text-grey-83">Status</th>
                <th className="text-grey-83">Reason</th>
                <th className="text-grey-83">Message</th>
                <th className="text-grey-83">Last Transition</th>
            </tr>
            {
                conditions && conditions.length > 0?
                conditions.map(condition => (
                    <tr>
                        <td>{condition.type}</td>
                        <td>{
                        condition.status == "True" && condition.reason === "FailedCreate" ?  <div className=""><span className="ri-close-line color-danger v-align-sub" style={{ fontSize: '24px' }} ></span>False</div> :
                        condition.status == "True"?  
                        <div className=""><span className="ri-checkbox-circle-fill color-success v-align-sub font-16"  /> {condition.status}</div>
                        : 
                        <div className=""> <span className="ri-close-line color-danger v-align-sub" style={{ fontSize: '16px' }} ></span> {condition.status}</div>}
                        </td>
                        <td>
                            <div className="text-width-300">
                            {condition.reason}
                            </div>
                           
                        </td>
                        <td>
                            <div className="text-width-300 text-ellipsis-multiline">
                            {condition.message}
                            </div>
                        </td>
                        <td>{moment(condition.lastTransitionTime).fromNow()}</td>
                    </tr>
                ))
                :
                <tr>
                   
                    <td colSpan="5">
                    <div className="text-center" style={{height: 200, display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                    <p className="font-13 text-grey-85">No Deployments initiated for this service yet!!</p>
                </div>
                    </td>

                </tr>
               
            }
           
        </table>
        </div>
    )
}

DeploymentConditions.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }