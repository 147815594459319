import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";
import StabilityMatrixItem from './components/StabilityMatrixItem';
// import AddMonitoringSource from './AddMonitoringSource';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi, { DeleteData } from '../../util/apiInvoker';
import AdvanceSearchFilterCombo from '../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo';
import { color } from 'highcharts';
import Grid from '@mui/material/Grid';;
import { useNavigate } from 'react-router-dom';
import { Loading } from '../utils/Loading';

const StabilityMatrixList = () => {
    const history = useNavigate();

    const defaultFilters = ["provider"];
    const [state, setState] = useState({
        current_activity_list: [],
        activity_id: "",
        data: {},
        stabilityMatrixList: [],
        error: null,
        loading: true,
        infiniteScrollInherits: {},
        refresh_count: 1,
        total_page: "",
        curr_page: 1,
        activity_list: [],
        open_div: false,
        show_refresh_button: false,
        no_activity_to_show: false,
        moreAdvFilterList: ["provider"],
        advFilters: {
            provider: [],
            project_name: [],
            env_master: [],
            user_id: [],
            status: [],
            agent: []
        },
        resetCount: 0,
        selectedInQueueRunningJobs: [],
        checkedAllButton: false,
        openRevokeDialog: false,
        selected_category: "none",
    });
    const resetFilterData = {
        provider: [],
        project_name: [],
        env_master: [],
        user_id: [],
        status: [],
        agent: []
    }

    useEffect(() => {
        fetchStabilityMatrixList()
    }, []);

    function fetchStabilityMatrixList() {
        const apiUrl = properties.api.stability_matrix_url;
        let requestInfo = {
            endPoint: GenerateURL({}, apiUrl),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
    }

    function handleSuccessApiHit(response) {
        console.log(response, "response")

        setState((prevState) => ({
            ...prevState,
            stabilityMatrixList: response,
            loading: false
        }));
    }

    function handleFailedApiHit(error) {
        console.log(error, "response")
    }


    const filterDataParse = (list) => {
        console.log(list, 'list_022323_23lll')
        const updatedList = list?.map(item => {
            return { 'label': item.activity_name, 'value': item.code, ...item }
        })
        return updatedList
    }

    const filterDataParseForEnvMaster = (list) => {

        const updatedList = list?.map(item => {
            return { 'label': item.name, 'value': item.name, ...item }
        })
        return updatedList
    }

    const filterDataParseForApp = (list) => {
        console.log('lit_02332', list)
        const updatedList = list?.map(item => {
            return { 'label': item.name, 'value': item.name, ...item }
        })
        return updatedList
    }

    const filterDataParseForUsers = (data) => {
        console.log(data, 'data_2232322')
        if (data) {
            let list = []
            if (Array.isArray(data)) {
                list = data
            }
            else {
                list = data.results
            }
            const updatedList = list?.map(item => {
                return { 'label': item.name, 'value': item.id, ...item }
            })
            return updatedList
        }

    }
    const filterDataParseForAgents = (data) => {
        console.log(data, 'data_2232322')
        if (data) {
            let list = []
            if (Array.isArray(data)) {
                list = data
            }
            else {
                list = data.results
            }
            const updatedList = list?.map(item => {
                return { 'label': item, 'value': item }
            })
            return updatedList
        }

    }
    //fetch data for all the activities and after searchbar enter
    function fetchActivitiesInfo(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_activities_details),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        //new code

        const updateUrl = generateFilterContainURL(requestInfo.endPoint, state.advFilters, state.selectedStatus, data)
        requestInfo.endPoint = updateUrl;

        if (url) {
            requestInfo.endPoint = url;
        }

        setState((new_state) => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            data: {
                status: [],
            },
            loading: true,
            search_query_name: data
                ? data.activity_name
                    ? data.activity_name
                    : ""
                : "",
            data: state.selectedStatus ? {
                ...new_state.data,
                status: [...updatedSelectedStatusArr]
            } :
                {
                    ...new_state.data,
                    status: []
                },
        }));

        InvokeApi(requestInfo,
            (response) => ActivityInfoFetchSuccess(response, data, url),
            ActivityInfoFetchFailure);
    }

    const isFilterActive = (filterKey, list) => {

        const isFound = list?.find(name => filterKey == name)

        return isFound;
    }

    function generateFilterContainURL(baseURL, listDict, activeFilters) {
        var searchParams = ''
        const filtersKeyArray = Object.keys(listDict)
        let i = 0
        let advFilterApplied = false
        const activeFilterList = activeFilters ? activeFilters : state.moreAdvFilterList
        filtersKeyArray.forEach(key => {

            const arrayFilterValues = listDict[key];
            if (arrayFilterValues.length > 0 && isFilterActive(key, activeFilterList)) {
                if (i != 0) {
                    searchParams += '&'
                }
                advFilterApplied = true
                var arrayString = arrayFilterValues;
                const stringKey = `${key}=${arrayString}`
                searchParams += stringKey
                i++;
            }
        })

        const finalUrl = baseURL + '?' + searchParams.toString()

        return searchParams != '' ? finalUrl : baseURL
    }


    function fetchActivitiesForListFilter(listFilterData, activeFilterList) {

        let baseURL = GenerateURL({}, properties.api.get_activities_details)

        const resultURL = generateFilterContainURL(baseURL, listFilterData, activeFilterList);

        let requestInfo = {
            endPoint: resultURL,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        setState((new_state) => ({
            ...new_state,
            loading: true,
        }));
        InvokeApi(requestInfo,
            (response) => {
                setState((new_state) => ({
                    ...new_state,
                    current: requestInfo.endPoint,
                    activity_id: response.id,
                    curr_page: 1,
                    error: false,
                    count: response.count,
                    activity_list: response.results,
                    no_activity_to_show:
                        response.results && response.results.length > 0 ? false : true,
                    next: response.next ? properties.api.baseURL + response.next : null,
                    previous: response.previous
                        ? properties.api.baseURL + response.previous
                        : null,
                    loading: false,
                    total_page: Math.ceil(Number(response.count / 10)),
                    no_activity_to_show:
                        response.results && response.results.length > 0 ? false : true,
                }));
            },
            ActivityInfoFetchFailure);
    }

    function ActivityInfoFetchSuccess(response, data, url) {
        if (sse_flag_enable) {
            activity_status_sse();
        }
        if (sse_flag_enable) {
            setState((new_state) => ({
                ...new_state,
                activity_id: response.id,
                count: response.count,
                // activity_list: response.results,
                next: response.next ? properties.api.baseURL + response.next : null,
                previous: response.previous
                    ? properties.api.baseURL + response.previous
                    : null,
                loading: false,
                no_activity_to_show:
                    response.results && response.results.length > 0 ? false : true,
                total_page: Math.ceil(Number(response.count / 10)),
            }));
        } else {
            setState((new_state) => ({
                ...new_state,
                activity_id: response.id,
                count: response.count,
                activity_list: response.results,
                next: response.next ? properties.api.baseURL + response.next : null,
                previous: response.previous
                    ? properties.api.baseURL + response.previous
                    : null,
                loading: false,
                error: false,
                curr_page: url ? new_state.curr_page : 1,
                no_activity_to_show:
                    response.results && response.results.length > 0 ? false : true,
                total_page: Math.ceil(Number(response.count / 10)),
            }));
        }
    }


    function ActivityInfoFetchFailure(error, exception) {
        setState((new_state) => ({
            ...new_state,
            error: error,
            loading: false,
        }));
    }
    const moreFilterData = [
        { label: "Provider", value: "provider" },
        { label: "Enviornment Type", value: "env_master" },
        { label: "App", value: "project_name" },
        { label: "Users", value: "user_id" },
        { label: "Status", value: "status" },
        { label: "Agent", value: "worker_name" },
    ]
    const advanceFilterJson = {

        'env_master': {
            staticList: false,
            labelName: 'Environment Type',
            uniqueId: 'env_master_adv_2',
            searchVariable: 'name',
            getFetchUrl: properties.api.env_master,
            filterDataPraseFunction: filterDataParseForEnvMaster
        },
        'provider': {
            staticList: false,
            labelName: 'Provider',
            uniqueId: 'provider_adv_1',
            searchVariable: 'activity_own_name',
            getFetchUrl: properties.api.activity_master,
            filterDataPraseFunction: filterDataParse
        },
        'project_name': {
            staticList: false,
            uniqueId: 'project_name_adv_3',
            labelName: 'App',
            searchVariable: 'name',
            getFetchUrl: properties.api.side_panel_data,
            filterDataPraseFunction: filterDataParseForApp
        },
        'user_id': {
            staticList: false,
            uniqueId: 'user_id_adv_4',
            labelName: 'Users',
            searchVariable: 'name',
            getFetchUrl: properties.api.user_list,
            searchUrl: properties.api.user_list,
            filterDataPraseFunction: filterDataParseForUsers
        },
        'status': {
            staticList: [
                { label: "Success", value: "SUCCESS" },
                { label: "Failed", value: "FAILED" },
                { label: "Running", value: "RUNNING" },
                { label: "In Queue", value: "IN_QUEUE" },
                { label: "Revoked", value: "REVOKED" }
            ],
            uniqueId: 'status_id_adv_5',
            labelName: 'Status',
            searchVariable: null,
            getFetchUrl: null,
            filterDataPraseFunction: null
        },
        'worker_name': {
            staticList: false,
            uniqueId: 'agents_id_adv_6',
            labelName: 'Agents',
            searchVariable: 'worker_name',
            getFetchUrl: properties.api.get_all_Workers,
            searchUrl: null,
            filterDataPraseFunction: filterDataParseForAgents
        }
    }


    const addFiltersToUrl = (filterName, filterValue) => {
        console.log("thsjks", filterName, filterValue);
        let urlSearchParams = new URLSearchParams(location.search);
        const allFilters = [
            "env_master",
            "project_name",
            "provider",
            "user_id",
            "status",
            "worker_name",
        ]

        if (filterName == 'all_delete') {
            urlSearchParams = ''
        } else {
            if (filterName == 'adv_search') {
                allFilters.forEach(value => {
                    if (!filterValue.includes(value)) {
                        urlSearchParams.delete(value)
                    }
                })
            }

            if (filterValue?.length == 0) {
                urlSearchParams.delete(filterName)
            }
            else {
                if (urlSearchParams.has(filterName)) {
                    urlSearchParams.set(filterName, filterValue.join(','));
                } else {
                    urlSearchParams.append(filterName, filterValue.join(','));
                }
            }
        }
        history({ pathname: location.pathname, search: urlSearchParams.toString() });
    }
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);



    const onUpdateHandle = (uniqueId, updatedList) => {

        let updatedKey = ''
        if (uniqueId === 'more-button-adv-0') {
            addFiltersToUrl('adv_search', updatedList)
            if (updatedList?.length == 0) {
                resetAdvFilter()
            }
            else {
                // const alreadyAddedFilters = state.advFilters
                // Object.keys(alreadyAddedFilters)?.forEach(filterName => {
                //   if (!updatedList.includes(filterName)) {
                //     alreadyAddedFilters[filterName] = []
                //   }
                // })
                setState(prevState => ({
                    ...prevState,
                    moreAdvFilterList: updatedList,
                    // advFilters: { ...alreadyAddedFilters }
                }))
                // fetchActivitiesForListFilter(alreadyAddedFilters)
            }
        }
        else {
            if (uniqueId == 'provider_adv_1') {
                updatedKey = 'provider'

            }
            if (uniqueId == 'project_name_adv_3') {
                updatedKey = 'project_name'
            }
            if (uniqueId == 'env_master_adv_2') {
                updatedKey = 'env_master'
            }
            if (uniqueId == 'user_id_adv_4') {
                updatedKey = 'user_id'
            }
            if (uniqueId == 'status_id_adv_5') {
                updatedKey = 'status'
            }
            if (uniqueId == 'agents_id_adv_6') {
                updatedKey = 'worker_name'
            }

            setState(prevState => ({
                ...prevState,
                advFilters: {
                    ...prevState.advFilters,
                    [updatedKey]: updatedList
                }
            }))
            addFiltersToUrl(updatedKey, updatedList)
            const advFilters = state.advFilters
            advFilters[updatedKey] = updatedList
            fetchActivitiesForListFilter(advFilters)
        }
    }


    const resetAdvFilter = () => {

        fetchActivitiesForListFilter(resetFilterData)
        addFiltersToUrl('all_delete')
        setState(prevState => ({
            ...prevState,
            moreAdvFilterList: defaultFilters,
            advFilters: resetFilterData,
            resetCount: prevState.resetCount + 1
        }))

        // will call here normal function
    }
    const handleClickOpen = () => {
        setOpen(true);

    };



    // const handleClose = () => {
    //     setOpen(false);
    // };
    // useEffect(() => {
    //     fetchSource()
    // }, [])

    // function fetchSource() {
    //     var requestInfo = {
    //         endPoint: GenerateURL(
    //             {},
    //             properties.api.monitoring_resource_get
    //         ),
    //         httpMethod: "GET",
    //         httpHeaders: { "Content-Type": "application/json" },
    //     };
    //     InvokeApi(
    //         requestInfo,
    //         fetchSourceDataSuccess,
    //         fetchSourceDataFailed
    //     );

    //     setstate((new_state) => ({
    //         ...new_state,
    //         save_data_loading: true,
    //     }));
    // }
    // function fetchSourceDataSuccess(data) {
    //     setstate((new_state) => ({
    //         ...new_state,
    //         save_data_loading: false,
    //         list: data.results

    //     }));
    // }
    // function fetchSourceDataFailed(error) {
    //     setstate((new_state) => ({
    //         ...new_state,
    //         error: error,
    //         save_data_loading: false,
    //     }));
    // }
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        if (urlSearchParams.size != 0) {
            let moreAdvFilter = defaultFilters
            let advFilters = {}
            urlSearchParams?.forEach((value, key) => {
                if (value) {
                    if (key == 'adv_search') {
                        let advValue = value
                        let updatedList = advValue.split(',')
                        updatedList = [...moreAdvFilter, ...updatedList]
                        moreAdvFilter = [...new Set(updatedList)]
                    }
                    else {
                        let filterValue = value
                        const updatedList = filterValue.split(',')
                        advFilters[key] = updatedList
                    }
                }
            })
            setState(prevState => ({
                ...prevState,
                moreAdvFilterList: moreAdvFilter,
                advFilters: { ...advFilters }
            }))
            fetchActivitiesForListFilter(advFilters, moreAdvFilter)
        }
        else {
            fetchActivitiesInfo()
        }
    }, []);


    const deleteStabilityMatrixItem = (id) => {
        console.log(id, "saveUrl")
        var saveUrl = GenerateURL({ "id": id }, properties.api.stability_matrix_url_delete);
        const data = state.stabilityMatrixList.find((ele, index) => ele.id == id)
        console.log(saveUrl, "saveUrl")
        DeleteData(saveUrl, data, handleDeleteSuccess, handleSaveFailure, true)
    }
    function handleDeleteSuccess(response) {
        console.log(response, "response")
    }

    function handleSaveFailure(error) {
        console.log(error, "error")
    }


    return (
        <div className={classes.root}>
            <CommonHeaderHandler
                heading={"Stability Matrix Group"}
                subheading={<><span>To understand more on Step Catalogs please read the </span><Link className="" to={"#"}> Documentation</Link></>}
                heading_icon={"/public/images/stability_matrix_icon.svg"}
                btnLink={true}
                btnLinkUrl={"/add/stability/matrix"}
                btnTitle={"Add Stability Matrix"}
                handleButtonAction={handleClickOpen}
            />

            <div className='filter'>
                <Grid container style={{ marginLeft: "20px" }}>
                    {
                        state.moreAdvFilterList?.map(filterName => {
                            return (
                                <div key={filterName} className="ad-more-search mb-20">
                                    <AdvanceSearchFilterCombo
                                        reset={state.resetCount}
                                        selectedCheckBoxes={state.advFilters[filterName]}
                                        searchVariable={advanceFilterJson[filterName]['searchVariable']}
                                        staticList={advanceFilterJson[filterName]['staticList']}
                                        uniqueId={advanceFilterJson[filterName]['uniqueId']}
                                        labelName={advanceFilterJson[filterName]['labelName']}
                                        searchUrl={advanceFilterJson[filterName]['searchUrl']}
                                        onUpdate={onUpdateHandle}
                                        getFetchUrl={advanceFilterJson[filterName]['getFetchUrl']}
                                        filterDataPraseFunction={advanceFilterJson[filterName]['filterDataPraseFunction']}
                                    />
                                </div>
                            )
                        })
                    }
                    <div className="ad-more-search mb-20">
                        <AdvanceSearchFilterCombo
                            selectedCheckBoxes={state.moreAdvFilterList}
                            reset={state.resetCount}
                            staticList={moreFilterData}
                            autoClosedAfterSelection={true}
                            onUpdate={onUpdateHandle}
                            variant='more-button'
                            uniqueId='more-button-adv-0' />
                    </div>

                    <div onClick={resetAdvFilter} className="pl-15 ml-20 d-flex align-center justify-center cursor-pointer" style={{ marginLeft: "-50px", borderLeft: '1px solid #dedede', height: '40px' }}>
                        <label style={{ color: '#595353' }} className="font-13 cursor-pointer"><div className='clear-filters'>
                            <span className='ri-filter-3-line font-20 color0086FF'></span>
                            <Link>
                                <p>CLEAR FILTERS</p>
                            </Link>
                        </div>
                        </label>
                    </div>
                </Grid>

                {/* <Link >
                    <button className='more-btn btn btn-primary font-12 font-weight-500 d-flex align-center justify-center'><span class="ri-add-line font-18"></span>More</button>
                </Link> */}

            </div>

            <div className='stability-matrix-list'>
                <div className='stability-matrix-header'>
                    <div style={{ width: "219px" }}>GROUP NAME</div>
                    <div style={{ width: "285px" }}>PROVIDERS</div>
                    <div style={{ width: "373px" }}>MATRIX</div>
                    <div style={{ width: "100px" }}>ACTIONS</div>
                </div>
                <div style={{ padding: state.loading ? "20px" : "" }}>
                    {state.loading ? <Loading varient='light' /> :
                        <>
                            {state.stabilityMatrixList && state.stabilityMatrixList.map((e, i) => {
                                return (
                                    <StabilityMatrixItem
                                        item={e}
                                        key={i}
                                        groupName={e.name}
                                        providers={e.metric_list.map((item, index) => item.provider.name)}
                                        matrix={e.metric_list.map((item, index) => item.name)}
                                        id={e.id}
                                        deleteStabilityMatrixItem={deleteStabilityMatrixItem}
                                    />

                                )
                            })}
                        </>}
                </div>
            </div>
        </div>
    )
}

export default StabilityMatrixList;


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px',

        '& .filter': {
            display: 'flex',
            gap: '6px'
        },
        '& .dropdown': {
            width: '145px',
            height: '40px'

        },
        '& .more-btn': {
            width: '90px',
            height: '40px'

        },
        '& .verticle-line': {
            width: '1px',
            height: '40px',
            background: '#CACACA'
        },
        '& .clear-filters': {
            display: "flex",
            width: "150px",
            alignItems: "center",
            gap: '4px',

            "& p": {
                position: 'relative',

                "&::after": {
                    content: "''",
                    display: 'block',
                    width: '0',
                    height: '2px',
                    background: '#0086FF',
                    transition: 'width .3s',
                    position: 'absolute',
                    bottom: '0',
                },
                "&:hover::after": {
                    width: "100%",
                },
            },
        },
        '& .stability-matrix-list': {
            marginTop: "20px",
            borderRadius: '6px',
            border: '1px solid var(--GREY-SCALE-GREY-200, #E6E6E6)',
            background: '#FFF',
            // width: 'fit-content'
        },
        '& .stability-matrix-header': {
            display: 'flex',
            // width: '984px',
            padding: '12px',
            alignItems: 'flex- start',
            color: '#787878',
            fontSize: '12px',
            fontWeight: '500',
            justifyContent: 'space-between',

        },

    },


}));

export const CommonHeaderHandler = props => {
    const classes = useStylesCommonHeader();
    const heading = props.heading ? props.heading : null;
    const subheading = props.subheading ? props.subheading : null;
    const heading_icon = props.heading_icon ? props.heading_icon : null;
    const bg_color = props.bg_color ? props.bg_color : classes.iconBg;
    const btnLink = props.btnLink ? props.btnLink : null;
    const btnLinkUrl = props.btnLinkUrl ? props.btnLinkUrl : null;
    const btnTitle = props.btnTitle ? props.btnTitle : null;
    const handleButtonAction = props.handleButtonAction ? props.handleButtonAction : () => { }
    return (
        <div className={classes.root + " d-flex align-center space-between mb-20"}>
            <div className='d-flex align-center'>
                {
                    heading_icon ? <div className={`${bg_color} bg-icon d-flex align-center justify-center`}><span className='ri-shield-star-line font-26 color-white'></span></div> : null
                }
                <div className="ml-5">
                    <p className='font-18 font-weight-500 color-content-dark'>{heading}</p>
                    <p className='sub-heading-primary'>{subheading}</p>
                </div>
            </div>
            {
                btnLink ?
                    <Link to={btnLinkUrl}>
                        <button className='btn btn-primary font-12 d-flex align-center justify-center text-transform-uppercase font-weight-600'><span class="ri-add-line font-18"></span> {btnTitle}</button>
                    </Link>
                    :
                    <Link to='/source/add_stability'>
                        <button onClick={handleButtonAction} className='btn btn-primary font-12 font-weight-600 d-flex align-center justify-center text-transform-uppercase'>
                            <span class="ri-add-line font-18"></span> {btnTitle}
                        </button>
                    </Link>
            }
        </div>
    )
}



const useStylesCommonHeader = makeStyles((theme) => ({
    root: {
        '& .bg-icon': {
            height: 48,
            width: 48,
            borderRadius: 24,
            boxShadow: '0 4px 4px 0px rgba(0, 134, 255, 0.12)'
        }
    },
    iconBg: {
        backgroundColor: '#0086ff'
    }
}));