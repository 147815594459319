import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import { Loading } from '../../../utils/Loading';
import Grid from '@mui/material/Grid';;
import DynamicFilterField from './DynamicFilterField';

export const snowStatesList = [
    { label: "New", id: "-5" },
    { label: "Assess", id: "-4" },
    { label: "Authorize", id: "-3" },
    { label: "Scheduled", id: "-2" },
    { label: "Implement", id: "-1" },
    { label: "Review", id: "0" },
    { label: "Closed", id: "3" },
    { label: "Canceled", id: "4" }
];

export default function UpdateSnowTicketStatus({ pipelineVars, 
    jiraIssueKeys, 
    jobStepOrderOutputVars,
    dynamicValidationFormError,
    setParentState, 
    parentState, ...props }) {
    const inherits = props.inherits;
    const prev_state = props.prev_state;
    const jira_reference_key_list = props.jira_reference_key_list ? props.jira_reference_key_list : [];
    const get_run_conditions_function = props.get_run_conditions_function;
    const issue_keys_list = [...get_run_conditions_function().jira_id_reference_keys];
    jira_reference_key_list.forEach(element => {
        issue_keys_list.push({ label: element.label, id: element.label })
    });
    const default_job_template = props.default_job_template ? props.default_job_template : null;
    const [state, setState] = useState(getUpdateSnowTicketDefaultState(prev_state,issue_keys_list));
    useEffect(() => {
       if(state.data.change_state == "-4"){
        fetchAssignmentGroupList();
       }

    }, [state.data.change_state])

    function fetchAssignmentGroupList() {
        var requestInfo = {
            endPoint: GenerateURL({ }, properties.api.assignment_grp_list) + "?job_template_id=" + default_job_template,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchAssignmentGroupListSuccess, fetchAssignmentGroupListFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function fetchAssignmentGroupListSuccess(response) {
        console.log("ejdhweunew",response)
        let assignment_groups = response.map((item)=>({label: item.name, id: item.sys_id}))
        setState(new_state => ({
            ...new_state,
            assignmentGroups: assignment_groups,
            loading_data: false,
            validations: {
                ...prev_state.validations,
                assignment_groups:[VALIDATION_TYPE_REQUIRED],
            }
        }));

    }
    function fetchAssignmentGroupListFailure(error) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
            error: error_msg,
            loading_data: false
        }));
    }

    
  



    const commonFunctions = getCommonFunctions(state, setState, inherits);
    console.log('state_data_oo1', prev_state)
   
    


    console.log("jdsjhsaj",state,jobStepOrderOutputVars);
    return (
        <>
        {state.data.loading ? //state.data.loading  
        <div style={{paddingBottom: "14px"}}>
            <Loading varient="light" />
        </div>
         :
            <Grid container spacing={2} style={{padding:"9px"}}>      
                    <Grid lg={12}>
                        <DynamicFilterField
                            jiraIssueKeys={jiraIssueKeys}
                            pipelineVars={pipelineVars}
                            jobStepOrderOutputVars={jobStepOrderOutputVars}
                            setParentState={setState}
                            parentState={state}
                            prev_state={prev_state}
                            dynamicValidationFormError={dynamicValidationFormError}
                            setTopParentState={setParentState}
                            topParenState={parentState}
                            showDynamicFeild= {true}
                        >
                            <Input
                                type="select"
                                name="issue_key"
                                list={issue_keys_list}  // get_run_conditions_function().jira_id_reference_keys
                                label="Select Snow Id Refrence key"
                                placeholder="Snow id refrence key"
                                mandatorySign
                                onChangeHandler={commonFunctions.onChangeHandler}
                                data={state.data}
                                error={state.error} />
                        </DynamicFilterField>
                    </Grid>
                    <Grid lg={12}>
                        <Input 
                            type="select"
                            name="change_state"
                            list={snowStatesList}
                            label={"Select State"}
                            mandatorySign
                            onChangeHandler={commonFunctions.onChangeHandler}
                            data={state.data}
                            error={state.error}
                        />
                    </Grid>
                    {
                        state.data.change_state == "-4"  && 
                        <Grid lg={12}>
                            {state.loading_data ? 
                                <Loading varient="light" />
                            :
                            
                            <Input 
                                type="select"
                                name="assignment_groups"
                                list={state.assignmentGroups}
                                label={"Select Assignment Groups"}
                                mandatorySign
                                onChangeHandler={commonFunctions.onChangeHandler}
                                data={state.data}
                                error={state.error}
                            />
                            }
                    </Grid>
                    }
                    
        </Grid>
        }
            
        </>
        
    )
}

UpdateSnowTicketStatus.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


function getUpdateSnowTicketDefaultState(prev_state,issue_keys_list) {
    console.log(prev_state,issue_keys_list, "fdsafjknjkjknkjkjsd")
    return {
        loading_data: false,
        data: prev_state ? {
            issue_key: prev_state.issue_key,
            change_state: prev_state.change_state ? prev_state.change_state : null,
            assignment_groups: prev_state.assignment_groups ? prev_state.assignment_groups : null,
        } : {
            issue_key: issue_keys_list.length > 0 ? issue_keys_list[0].id : null
        },
        error: {},
        dynamic_fields:{},
        snowMadatoryField: [],
        validations: {
            change_state: [VALIDATION_TYPE_REQUIRED],
            issue_key: [VALIDATION_TYPE_REQUIRED],
            
        },
    };
}
