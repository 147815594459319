import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';

const SprintDropDown = ({ itemList, selectedSprintData, sendSelectedRowToParent }) => {
  
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const [state, setState] = useState({
        search: "",
        itemList: [],
    })
    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            itemList: itemList
        }))
    }, [itemList])
    useEffect(() => {
        if (state.search !== '') {

            const list = state.itemList;

            const searchResults = list.filter(item => item.name.startsWith(state.search));
            setState(prevState => ({
                ...prevState,
                itemList: searchResults
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                itemList: itemList
            }));
        }
    }, [state.search]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const selectRow = (item) => {
        sendSelectedRowToParent(item)
    }
    const handleSearchData = (e) => {
        const value = e.target.value;

        setState(prevState => ({
            ...prevState,
            search: value
        }))

    }
    // {
    //     "id": "491aec39-9d91-40fe-b778-42eca748a47d",
    //     "name": "sprint 4",
    //     "path": "priyanshichauhan\\sprint 4",
    //     "startDate": "2024-07-29T00:00:00Z",
    //     "finishDate": "2024-08-23T00:00:00Z",
    //     "state": "current"
    // }
    return (
        <>
            <div className={classes.root} onClick={handleClick} style={open ? { borderColor: "#0086FF" } : {}} >

                <span className='d-flex align-center font-14 font-weight-500'>
                    <span className='' style={{color:'#787878'}}>Sprint:&nbsp;</span>
                    {selectedSprintData ?
                        <>
                            <span className='d-block mr-3'>{selectedSprintData.name}</span> 
                            <span className='d-flex align-center'>{selectedSprintData.state == "current" ? <span className='' style={{color:'#0086ff'}}>
                                <span className='ri-checkbox-circle-fill font-14'></span><span className='font-12 font-weight-700'>Active</span>
                            </span> : selectedSprintData.state == "past" ? <span style={{color:'#fea111'}}>
                            <span className='ri-checkbox-circle-fill font-14'></span><span className='font-12 font-weight-700'>Past</span>
                            </span> : <span className='' style={{color:'#2ebe79'}}>
                            <span className='ri-checkbox-circle-fill font-14'></span><span className='font-12 font-weight-700'>Future</span>
                            </span> }</span>
                        </>
                        :
                        "Select Sprint"}</span>
                <span className='ri-arrow-down-s-line line-height-normal font-20 color-icon=secondary'></span>
            </div>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{ style: { overflow: 'visible', marginTop: "10px", borderRadius: "6px", } }}
            >
                <div className={classes.popover}>
                    <div className='search-container'>
                        <div>
                            <input
                                className='search-input'
                                name='search'
                                placeholder='Search'
                                value={state?.search}
                                onChange={handleSearchData}
                                disabled={false}
                            />
                        </div>

                        <span className='ri-search-2-line font-20' style={{ color: "#CACACA" }}></span>
                    </div>
                    <div className='font-weight-500 font-14 d-flex align-center space-between' style={{ padding: '3px 10px', width: '100%', color: '#949494' }}>
                        Sprints
                    </div>
                    {
                        state.itemList.map(item => {

                            return <div
                                className='font-weight-500 font-14 d-flex align-center space-between row-hover'
                                onClick={() => { selectRow(item);  handleClose();}}
                                style={{ padding: '7px 10px', width: '100%' }}>
                                <span className=''>{item.name}</span>
                                <span className={'text-uppercase font-12'} style={item.state == "past" ? { color: "#fea111" } : item.state == "future" ? { color: '#2ebe79' } : { color: '#0086ff' }}>{item.state}</span>
                            </div>
                        })
                    }
                </div>
            </Popover>
        </>
    )
}

export default SprintDropDown;

SprintDropDown.propTypes = {
    prev_state: PropTypes.object,
    inherits: PropTypes.any,
};

const useStyles = makeStyles((theme) => ({
    root: {
        //background: "red",
        display: "flex",
        padding: "8px 10px 8px 16px",
        alignItems: "center",
        gap: "12px",
        justifyContent: 'space-between',
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        cursor: "pointer",
        '& .selected-label': {
            display: "flex",
            alignItems: "center",
            gap: "3px",
            width: "80%"
        },
        '& .selected-env': {
            display: "inline-block",
            width: "100%",
            fontWeight: "500",
            color: "#2F2F2F",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        '& .env-type': {
            display: "inline-flex",
            padding: "4px 8px",
            alignItems: "flex-start",
            borderRadius: "4px",
            gap: "10px",
            //fontSize: "10px",
            fontWeight: "700",
            color: "#FFF",
            width: "fit-content",
        },

        '&:hover': {
            borderColor: "#bebebe",
        }
    },
    popover: {
        //width: "100%",
        height: '280px',
        overflowY: 'hidden',
        display: "flex",
        width: "330px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "5px",
        borderRadius: "6px",
        border: "1px solid #F4F4F4",
        background: "#FFF",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
        '&:hover': {
            overflowY: 'scroll',
        },
        '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b7b3b3 !important',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f0eeee!important',
            boxShadow: 'none!important',
            '-webkit-box-shadow': 'none!important'
        },

        '& .row-hover': {
            marginLeft: '-1px',
            position: 'relative',
            '&:hover': {
                background: '#F5FAFF',
                '&:before': {
                    content: '" "',
                    height: '35px',
                    width: '4px',
                    position: 'absolute',
                    left: '0px',
                    backgroundColor: '#0086ff'
                }
            },

        },
        '& .search-container': {
            display: "flex",
            padding: "10px 12px",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            width: "100%",
            height: "40px",
            marginRight: '0px',
            borderBottom: "1px solid #E6E6E6",
            '& .search-input': {
                border: "none",
                padding: "0",

                '&:focus': {
                    border: "none !important",
                    outline: "none !important",
                }
            },
        }
    }
}))