import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';;
import '../../pipeline/pipeline-styles.css';
import BasicInfo from './BasicInfo';
import { Loading, LoadingContainer, LoadingText } from '../../utils/Loading';
import Exception from '../../Exception';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import properties from '../../../properties/properties';
import GlobalPipelineDetails from './GlobalPipelineDetails';
import { useParams } from 'react-router-dom';


export var trigger_type_code_maping = {
    SCM_POLL: {
        "id": 1,
        "name": "SCM Poll",
        "code": "SCM_POLL",
    },
    SCHEDULE: {
        "id": 2,
        "name": "Scheduled",
        "code": "SCHEDULED",
    },
    MANUAL: {
        "id": 3,
        "name": "Manual",
        "code": "MANUAL",
    },
    WEBHOOKS: {
        "id": 4,
        "name": "Web hooks",
        "code": "WEBHOOKS",
    }

};


const AddGlobalPipeline = props => {
   
    const { application_id, pipeline_id } = useParams();
    
    const [state, setState] = useState({ loaded: true, active_panel: 0, componentLoading:false, application: {
        id:null,
        pipelines:[],
        components:[]
    } });

    useEffect(() => {
        // requestApplication();
        if (pipeline_id) {
            requestPipeline();
        }
    }, [application_id, pipeline_id]);

    function requestPipeline() {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application_id, pipeline_id: pipeline_id }, properties.api.pipeline_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handlePipelineResponse, handleError);
        setState(new_state => ({
            ...new_state,
            pipline_loading: false,
            loaded: false,
            active_panel: 0
        }));

    }
    const handlePipelineResponse = (data) => {
        requestAllPipelines();
        setState(new_state => ({
            ...new_state,
            loaded: true,
            pipeline: data,
            active_panel: 1
        }));
    }

    function requestApplication() {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application_id }, properties.api.all_services_listing_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleResponse, handleError);
        setState(new_state => ({
            ...new_state,
            // loaded: false,
            pipline_loading: false,
            active_panel: 0
        }));
    }

    const handleResponse = (data) => {

        setState(new_state => ({
            ...new_state,
            // loaded: Number(pipeline_id) ? false : true,
            loaded: getCorrectStateOfLoaded(pipeline_id,new_state.loaded),
            application: {
                ...new_state.application,
                id: application_id,
                components: data,
            },
            componentLoading:true
        }));
    }
    const handleError = (error) => {
        setState({
            ...state,
            loaded: true,
            error: error.detail,
            componentLoading:true
        });
    }

    const getCorrectStateOfLoaded = (pipeline_id,currentLoadedState)=>{

        if(pipeline_id!=undefined){
            if(currentLoadedState){
                return true
            }
            else{
                return false
            }
        }
        return true
    }

    function requestAllPipelines() {
        var requestInfo = {
            endPoint: GenerateURL({  }, properties.api.global_recent_pipeline),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, requestAllPipelineshandleResponse, requestAllPipelineshandleResponsehandleError);
        setState(new_state => ({
            ...new_state,
            // loaded: false,
            pipline_loaded: false,
            active_panel: 1
        }));
    }

    const requestAllPipelineshandleResponse = (data) => {
        setState(new_state => ({
            ...new_state,
            loaded: true,
            pipline_loaded: true,
            application: {
                ...new_state.application,
                pipelines: data.results,
            }
        }));
    }

    const requestAllPipelineshandleResponsehandleError = (error) => {
        setState({
            ...state,
            loaded: true,
            pipline_loaded: true,
            error: error.detail
        });
    }

    function transformDataToApiRequest(data) {
        const result = {
            ...data
        }

        const trigger_type_id = trigger_type_code_maping[data.trigger].id;
        result.trigger_type_id = trigger_type_id;
        delete result.trigger;

        if (trigger_type_id == 3 || trigger_type_id == 4) {
            result.cron_regex = null;
        }

        result.project_id = Number(application_id);
        return result;
    }


    function saveBasicInfoAndContinueToDetails(data) {

        const result = transformDataToApiRequest(data);
        var url = data.id ? GenerateURL({ pipeline_id: data.id }, properties.api.global_pipeline_update) : GenerateURL({ application_id: application_id }, properties.api.global_pipeline_save)  ;
        PostData(url, result, handleSuccessPipelinePost, handleFailPipelinePost);
        setState(new_state => ({
            ...new_state,
            pipeline: { ...data },
            pipeline_post_status: "POST_REQUESTED"
        }));
    }

    const handleSuccessPipelinePost = (response) => {
        const pipeline = response;
        pipeline.trigger = pipeline.trigger_type_id == 3 ? "MANUAL" : pipeline.trigger_type_id == 4 ? "WEBHOOKS" :
            pipeline.trigger_type_id == 2 ? "SCHEDULE" : "SCM_POLL";
        setState(new_state => ({
            ...state,
            active_panel: 1,
            pipeline_post_status: "POST_SUCCESS",
            pipeline: { ...state.pipeline, ...response }
        }));
    }

    const handleFailPipelinePost = (error) => {
        setState(new_state => ({
            ...new_state,
            pipeline_post_status: "POST_FAIL",
            pipeline_error: error
        }));
    }


    const Body = () => {
        return (
            <Grid container className="pd-20">
                {
                    state.pipeline_post_status == "POST_REQUESTED" ?
                        <Loading /> :


                        state.active_panel == 0 ?
                            <>

                                <Grid item lg={12} >
                                    <Heading />
                                </Grid>

                                <Grid item lg={12} >
                                    <div className="card">
                                        <BasicInfo 
                                        application={state.application} 
                                        pipeline={state.pipeline} 
                                        error={state.pipeline_error} 
                                        services={state.application.components} 
                                        saveBasicInfoAndContinueToDetails={saveBasicInfoAndContinueToDetails} />
                                    </div>
                                </Grid>
                            </>
                            :
                            !state.loaded ?
                                <div className='m-auto' style={{ height: 400 }}>
                                    <Loading />
                                </div>

                                :
                                <GlobalPipelineDetails
                                    pipeline={state.pipeline}
                                    application={state.application}
                                    app_id = {application_id}
                                    state_loded={state.loaded}
                                    saveBasicInfoAndContinueToDetails={saveBasicInfoAndContinueToDetails}
                                />
                }
            </Grid>
        );
    };
    console.log(state,'state_00spdpds')
    return (
        <>

            {
                !state.loaded ?
                    <Loading  /> :
                    state.error ?
                        <Exception /> :
                        (!(state.application?.components && state.application?.components.length) > 0  && state.componentLoading) ?

                            <div className="main-container-error" style={{ height: '70vh' }}>
                                <div className="svg">
                                    <div class="servicePanel">
                                        <div class="blank-div">
                                            <div class="blank-div-text">No Service to create pipeline for</div>

                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <Body />
            }

        </>
    )
}

const Heading = () => {
    return (
        <div className="main-div">
            <p className="main-heading">Setup Pipeline</p>
            <p className="sub-heading">Displaying overall summary of Services associated with</p>
        </div>

    );
}

const Overview = () => {
    return (
        <div className="section-overview-pipeline">
            <div className="overview-heading-pipeline">
                Overview
            </div>
            <div className="normal-text">
                Pipelines helps you automate steps in your software delivery process, such as initiating code builds, running automated tests, and deploying to a staging or production environment.
            </div>
            <div className="normal-text">
                Pipeline execution is triggered by a source code repository. A change in code triggers a webhook to Buildpiper which runs the corresponding pipeline. Other common triggers include automatically scheduled or user-initiated workflows.
            </div>
            <div className="normal-text">
                Pipelines are configured by placing a pipeline.yml file in the root of your git repository. The yaml syntax is designed to be easy to read and expressive so that anyone viewing the repository can understandd the workflow.
            </div>
        </div>
    );
}

export default AddGlobalPipeline;