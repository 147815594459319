import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../../components/genericComponents/Input';
import Grid from '@mui/material/Grid';;

import InvokeApi from "../../../../util/apiInvoker";
import properties from "../../../../properties/properties";
import GenerateURL from "../../../../util/APIUrlProvider";

import { getCommonFunctions, ResetComponent } from '../ci_flow/SourceDetails';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';

import MultiRow from '../../../../components/genericComponents/MultiRow';
const EnvVar = (props) => {
  const extraProps = props.extraProps;
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const type = props.type ? props.type : null;
  const [state, setState] = useState(prev_state ? prev_state : getEnvVarDefaultState())
  const project_env_id = extraProps.project_env_id ? extraProps.project_env_id : null;
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  useEffect(() => {
    setState(new_state => ({ ...new_state, ...prev_state }))
  }, [prev_state])

  console.log(state, 'state_inside_001');
  return (
    <>
      {
        type == "sidecar" ?
          <div className='sidecar-edit-input-div'>
            <div className="formTag card-add-service-head" style={{ borderTop: '1px solid #e7e7eb', borderRadius: '0px', marginBottom: '0px' }}>
              <h4 className='mainHeading'>Define runtime environment variables</h4>
            </div>
            <div>
              <Grid lg={12} direction="row" style={{ backgroundColor: '#fff' }}>
                <div className=''>
                  <div className="">
                    <div className="card">
                      <Input
                        type="switch"
                        name="deploy_variable_raw_input"
                        label="Define Raw Key Value Pairs"
                        onChangeHandler={commonFunctions.toggleState}
                        data={state.data}
                        error={state.error}
                        enabledComponent={<EnabledComponentRawInput prev_state={state.env_var_raw_input} inherits={state.child_inherits.env_var_raw_input} />}
                        disabledComponent={<ResetComponent inherits={state.child_inherits.env_var_raw_input} />}
                      />
                    </div>
                    <div className="card">
                      <Input
                        type="switch"
                        name="deploy_variable_configMap"
                        label="Define from ConfigMap"
                        onChangeHandler={commonFunctions.toggleState}
                        data={state.data}
                        error={state.error}
                        enabledComponent={<EnabledComponentConfigMap project_env_id={project_env_id} extraProps={extraProps} prev_state={state.env_var_configMap} inherits={state.child_inherits.env_var_configMap} />}
                        disabledComponent={<ResetComponent inherits={state.child_inherits.env_var_configMap} />}
                      />
                    </div>
                    <div className="card">
                      <Input
                        type="switch"
                        name="deploy_variable_secret"
                        label="Define from Secrets"
                        onChangeHandler={commonFunctions.toggleState}
                        data={state.data}
                        error={state.error}
                        enabledComponent={<EnabledComponentSecret project_env_id={project_env_id} extraProps={extraProps} prev_state={state.env_var_secret} inherits={state.child_inherits.env_var_secret} />}
                        disabledComponent={<ResetComponent inherits={state.child_inherits.env_var_secret} />}
                      />
                    </div>
                  </div>

                </div>
              </Grid>
            </div>
            {/* <Grid lg={12} direction="row" style={{ marginTop: '20px' }} >
        <EditorKeyValues inherits={key_value} data={state.env_variable ? state.env_variable : []} update_data_callback={updateData} field_name={"env_variable"} />
      </Grid> */}
          </div>
          :
          <div className='ci-cd-edit-input-div'>
            <div className="formTag card-add-service-head" style={{ borderTop: '1px solid #e7e7eb', borderRadius: '0px', marginBottom: '0px' }}>
              <h4 className='mainHeading'>Define runtime environment variables</h4>
            </div>
            <div>
              <Grid lg={12} direction="row" style={{ backgroundColor: '#fff' }}>
                <div className=''>
                  <div className="">
                    <div className="card">
                      <Input
                        type="switch"
                        name="deploy_variable_raw_input"
                        label="Define Raw Key Value Pairs"
                        onChangeHandler={commonFunctions.toggleState}
                        data={state.data}
                        error={state.error}
                        enabledComponent={<EnabledComponentRawInput prev_state={state.env_var_raw_input} inherits={state.child_inherits.env_var_raw_input} />}
                        disabledComponent={<ResetComponent inherits={state.child_inherits.env_var_raw_input} />}
                      />
                    </div>
                    <div className="card">
                      <Input
                        type="switch"
                        name="deploy_variable_configMap"
                        label="Define from ConfigMap"
                        onChangeHandler={commonFunctions.toggleState}
                        data={state.data}
                        error={state.error}
                        enabledComponent={<EnabledComponentConfigMap project_env_id={project_env_id} extraProps={extraProps} prev_state={state.env_var_configMap} inherits={state.child_inherits.env_var_configMap} />}
                        disabledComponent={<ResetComponent inherits={state.child_inherits.env_var_configMap} />}
                      />
                    </div>
                    <div className="card">
                      <Input
                        type="switch"
                        name="deploy_variable_secret"
                        label="Define from Secrets"
                        onChangeHandler={commonFunctions.toggleState}
                        data={state.data}
                        error={state.error}
                        enabledComponent={<EnabledComponentSecret project_env_id={project_env_id} extraProps={extraProps} prev_state={state.env_var_secret} inherits={state.child_inherits.env_var_secret} />}
                        disabledComponent={<ResetComponent inherits={state.child_inherits.env_var_secret} />}
                      />
                    </div>
                  </div>

                </div>
              </Grid>
            </div>
            {/* <Grid lg={12} direction="row" style={{ marginTop: '20px' }} >
        <EditorKeyValues inherits={key_value} data={state.env_variable ? state.env_variable : []} update_data_callback={updateData} field_name={"env_variable"} />
      </Grid> */}
          </div>
      }
    </>
  )
}
EnvVar.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default EnvVar;

const EnabledComponentRawInput = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getEnabledComponentRawInputDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  const updateSelectedTab = (selectedTabOrder) => {
    setState({ ...state, selectedTabOrder: selectedTabOrder });

  }


  return (
    <div className='card-body-flex'>
      <div className="grid-container with-header input-cont" style={{ backgroundColor: '#fff', }}>


      </div>

      <>
        <MultiRow RepeatableComponent={RawInputBody} HeaderComponent={RawInputHeader} prev_state={state.multi_row} inherits={state.child_inherits.multi_row} zeroRowsAllowed={true} />
      </>

    </div>

  )
}
EnabledComponentRawInput.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const EnabledComponentConfigMap = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  //const project_env_id = props.project_env_id;
  const [state, setState] = useState(prev_state ? prev_state : getEnabledComponentConfigMapDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  const updateSelectedTab = (selectedTabOrder) => {
    setState({ ...state, selectedTabOrder: selectedTabOrder });

  }
  const cluster_id = props.extraProps.cluster_id;
  const namespace_name = props.extraProps.namespace_name

  function getConfigMaps() {

    var requestInfo = {
      endPoint: GenerateURL({ cluster_id:cluster_id, namespace_name:namespace_name  }, properties.api.allConfigmaps),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    requestInfo.endPoint = requestInfo.endPoint + "?all=true";
    InvokeApi(requestInfo, fetchSuccessConfigListing, fetchFailConfigListing);
  }

  function fetchSuccessConfigListing(response) {
    const ConfigMapNames = []
    if (response.items && response.items.length == 0) {
      setState(new_state => ({
        ...new_state,
        error: { ...new_state.error, config_name: "No Config Map available in the Namespace" }
      }))
    } else {
      response.items.forEach(element => {
        ConfigMapNames.push({ label: element.metadata.name, id: element.metadata.name })
      });
      setState(new_state => ({
        ...new_state,
        ConfigMapNames: ConfigMapNames
      }))
    }
  }

  function fetchFailConfigListing(error) {
    setState(new_state => ({
      ...new_state,
      error: { ...new_state.error, config_name: error.detail }
    }))
  }
  function loadConfigMap() {
    var configMaps = state.configMapNames.length == 0 ? getConfigMaps() : null
  }
  useEffect(() => {
    loadConfigMap()
  }, []);


  return (
    <div className='card-body-flex'>
      <div className="grid-container with-header input-cont" style={{ backgroundColor: '#fff', }}>


      </div>

      <>
        <MultiRow repeatableComponentProps={{ configMapNames: state.ConfigMapNames }} RepeatableComponent={ConfigInputBody} HeaderComponent={ConfigInputHeader} prev_state={state.configMap_envvar} inherits={state.child_inherits.configMap_envvar} zeroRowsAllowed={true} />
      </>

    </div>

  )
}
EnabledComponentConfigMap.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const EnabledComponentSecret = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getEnabledComponentSecretDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  const updateSelectedTab = (selectedTabOrder) => {
    setState({ ...state, selectedTabOrder: selectedTabOrder });

  }
  const project_env_id = props.project_env_id
  const cluster_id = props.extraProps.cluster_id;
  const namespace_name = props.extraProps.namespace_name

  function getSecrets() {

    var requestInfo = {
      endPoint: GenerateURL({cluster_id:cluster_id, namespace_name:namespace_name  }, properties.api.allSecrets),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    requestInfo.endPoint = requestInfo.endPoint + "?all=true";
    InvokeApi(requestInfo, fetchSuccessSecretListing, fetchFailSecretListing);

  }

  function fetchSuccessSecretListing(response) {
    const secrets = []
    response.items.forEach(element => {
      secrets.push({ label: element.metadata.name, id: element.metadata.name })
    });
    setState(new_state => ({
      ...new_state,
      secrets: secrets
    }))
  }
  function fetchFailSecretListing(error) {
    console.log("SADads")
  }

  function loadSecrets() {
    var secrets = state.secrets.length == 0 ? getSecrets() : null
  }

  useEffect(() => {
    loadSecrets()
  }, []);


  return (
    <div className='card-body-flex'>
      <div className="grid-container with-header input-cont" style={{ backgroundColor: '#fff', }}>


      </div>
      <>
        <MultiRow repeatableComponentProps={{ secrets: state.secrets }} RepeatableComponent={SecretInputBody} HeaderComponent={SecretInputHeader} prev_state={state.secert_envvar} inherits={state.child_inherits.secert_envvar} zeroRowsAllowed={true} />
      </>

    </div>

  )
}
EnabledComponentSecret.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export function getEnabledComponentRawInputDefaultState() {
  return {
    data: {},
    error: {},
    validations: {},
    child_inherits: {
      multi_row: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}

export function getEnabledComponentConfigMapDefaultState() {
  return {
    configMapNames: [],
    data: {},
    error: {},
    validations: {},
    child_inherits: {
      configMap_envvar: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}

export function getEnabledComponentSecretDefaultState() {
  return {
    secrets: [],
    data: {},
    error: {},
    validations: {},
    child_inherits: {
      secert_envvar: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}

export function getEnvVarDefaultState() {
  return {
    data: {
    },
    error: {},
    validations: {},
    child_inherits: {
      env_var_raw_input: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      env_var_configMap: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      env_var_secret: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    },
  };
}

function RawInputHeader() {
  return (
    <>
      <Grid item lg={5}>
        Key
      </Grid>
      <Grid item lg={5}>
        Value
      </Grid>
    </>
  )
}

const RawInputBody = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getRawInputBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  console.log("state---props env var", props)

  return (
    <>
      <Grid item lg={5}>
        <Input
          type="text"
          placeholder="Name"
          name="env_key"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>

      <Grid item lg={5}>
        <Input
          type="text"
          placeholder="Value"
          name="env_value"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>


    </>
  )
}
RawInputBody.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export function getRawInputBodyDefaultState() {
  return {
    data: {},
    error: {},
    validations: {
      env_key: [VALIDATION_TYPE_REQUIRED],
      env_value: [VALIDATION_TYPE_REQUIRED],
    },
  };
}

function ConfigInputHeader() {
  return (
    <>
      <Grid item lg={2}>
        ConfigMap Name
      </Grid>
      <Grid item lg={3}>
        Service Key
      </Grid>

      <Grid item lg={3}>
        ConfigMap key
      </Grid>
      <Grid item lg={3}>

      </Grid>

    </>
  )
}

const ConfigInputBody = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const configMapNames = props.extraProps.configMapNames ? props.extraProps.configMapNames : [];
  const [state, setState] = useState(prev_state ? prev_state : getConfigInputBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);



  return (
    <>
      <Grid item lg={2}>
        <Input
          type="select"
          list={configMapNames}
          name="configMap_name"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
      <Grid item lg={3}>
        <Input
          type="text"
          placeholder="Key"
          name="configMap_service_key"
          error={state.error}
          onChangeHandler={!state.data.as_env_var ? commonFunctions.onChangeHandler : null}
          data={state.data.as_env_var ? {} : state.data} />
      </Grid>


      <Grid item lg={3}>
        <Input
          type="text"
          placeholder="configMap Key"
          name="configMap_key"
          error={state.error}
          onChangeHandler={!state.data.as_env_var ? commonFunctions.onChangeHandler : null}
          data={state.data} />

      </Grid>
      <Grid item lg={3}>
        <Input
          type="simple-checkbox"
          name="as_env_var"
          label="Upload file as env var"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>

    </>
  )
}
ConfigInputBody.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export function getConfigInputBodyDefaultState() {
  return {
    data: {
      as_env_var: false
    },
    error: {},
    validations: {
      configMap_service_key: [],
      configMap_name: [VALIDATION_TYPE_REQUIRED],
      configMap_key: [],
    },
  };
}

function SecretInputHeader() {
  return (
    <>
      <Grid item lg={3}>
        Secret Name
      </Grid>
      <Grid item lg={2}>
        Service Key
      </Grid>

      <Grid item lg={3}>
        Secret Key
      </Grid>
      <Grid item lg={3}>
      </Grid>
    </>
  )
}
const SecretInputBody = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const secretNamesList = props.extraProps.secrets ? props.extraProps.secrets : [];
  const [state, setState] = useState(prev_state ? prev_state : getSecretInputBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  console.log("state---props env var", props)

  return (
    <>
      <Grid item lg={2}>
        <Input
          type="select"
          list={secretNamesList}
          name="secret_name"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />

      </Grid>

      <Grid item lg={3}>
        <Input
          type="text"
          placeholder="Key"
          name="secret_service_key"
          error={state.error}
          onChangeHandler={!state.data.as_env_var ? commonFunctions.onChangeHandler : null}
          data={state.data} />
      </Grid>
      <Grid item lg={3}>
        <Input
          type="text"
          placeholder="Secret Key"
          name="secret_key"
          error={state.error}
          onChangeHandler={!state.data.as_env_var ? commonFunctions.onChangeHandler : null}
          data={state.data} />
      </Grid>
      <Grid item lg={3}>
        <Input
          type="simple-checkbox"
          name="as_env_var"
          label="Upload file as env var"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>

    </>
  )
}
SecretInputBody.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export function getSecretInputBodyDefaultState() {
  return {
    data: {},
    error: {},
    validations: {
      secret_service_key: [],
      secret_name: [VALIDATION_TYPE_REQUIRED],
      secret_key: []
    },
  };
}
