import React, { useState } from 'react'
import PropTypes from 'prop-types';
import AlphaThumbnail from '../../../../components/genericComponents/AlphaThumbnail'
import Delete from '../../../../components/genericComponents/Delete';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Zoom from "@mui/material/Zoom";
import { useNavigate } from 'react-router-dom';
import useFetchPermissions from '../../../../components/customHooks/useFetchPermissions';


const TopSummary = ({ isMenuOpen, handleMenuClick, onCloseMenu, anchorEl, selectedServiceEnv, afterDeleteEnvRefresh, handleToggle, ...props }) => {
    const [open, setOpen] = useState(false)
    const topSummaryData = props.data;
    const serviceName = topSummaryData.serviceName;
    const appName = topSummaryData.appName;
    var masterEnvList = [];
    const service_id = props.service_id
    const envs = topSummaryData.envs;
    const edit = props.edit;
    const application_id = props.application_id;
    const handleOpenAddEnvDialog = props.handleOpenAddEnvDialog;
    var masterEnvList = [];
    const permissions = useFetchPermissions()
    var url = GenerateURL({ service_id: service_id }, properties.api.service_basic_details_get_url, true);
    const is_permitted = permissions?.POST.includes(url);

    const editEnvUrl = GenerateURL({ service_id: service_id }, properties.api.env_data_post_url, true);
    const is_env_permitted = permissions?.POST.includes(editEnvUrl);
    const history = useNavigate();
    if (envs) {
        Object.keys(envs).forEach((key) => {
            var env = envs[key];
            masterEnvList.push(
                <>
                    <span>{env.name} &nbsp;</span>
                    <span className="text__box-main">Sub Env:&nbsp;</span>
                    {Object.keys(env.sub_env).length}
                    <span>&nbsp;</span>
                </>
            )
        })
    }
    console.log(isMenuOpen, 'dg_pp')

    console.log('xmp_0mkl', selectedServiceEnv)
    return (
        <div style={{ padding: '10px 24px 10px 12px' }} className="d-flex align-center space-between">
            <div className="heading-section-env">
                <AlphaThumbnail smallSize="small" variant={"double"} alphabet={serviceName} thumbnailStatus="green" />
                <div className="text-icon-section">
                    <div className="d-flex align-center">
                        <div style={{//styleName: Captions/Caption1-Bold;
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '17.07px',
                            textAlign: 'left',
                            color: '#2F2F2F',
                            textTransform: 'uppercase'
                        }} className="lh-12 mr-5">
                            {/* {serviceName} */}
                            {serviceName.length > 30 ?
                                <Tooltip title={serviceName.toString()} TransitionComponent={Zoom}>
                                    <span>{serviceName.substring(0, 30) + "..."}</span>
                                </Tooltip>

                                :
                                serviceName
                            }
                        </div>
                    </div>
                    <div className="d-flex align-center">
                        <span className="font-13 text-grey-83">Project:&nbsp;</span>
                        <span className="font-13 text-grey-b6">{appName}</span>
                    </div>
                </div>
            </div>
            {
                is_permitted &&
                <>
                    <div style={{ position: 'absolute', right: '16px', top: '15px' }} className="d-flex align-centre">
                        <button
                            className="transparent-btn nowrap"
                            onClick={handleMenuClick}
                        >
                            <i className="ri-more-2-fill" style={{ color: 'black', fontSize: '24px' }}></i>
                        </button>
                    </div>
                    <Popover
                        anchorEl={anchorEl}
                        id="long-menu"
                        keepMounted
                        open={isMenuOpen}
                        onClose={onCloseMenu}
                        PaperProps={{
                            style: {
                                maxHeight: '200px',
                                width: "180px",
                                backgroundColor: "#f0f7f7",
                            },
                        }}
                    >
                        <div>
                            <MenuItem
                                style={{ padding: '0px' }}
                                onClick={() =>{handleToggle(); onCloseMenu();}}
                            >
                                <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>
                                <i className="ri-edit-2-line font-15" style={{ marginRight: "8px", color: "#3696db" }}></i>
                                    
                                    <label style={{ fontSize: "14px", cursor: "pointer" }}>Edit Service </label>
                                </div>
                            </MenuItem>
                            <MenuItem
                                style={{ padding: '0px' }}
                                onClick={() => { }}
                            >
                                <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>

                                    {
                                        is_permitted ?

                                            <Delete
                                                display_data_name={serviceName}
                                                varient="service-summary"
                                                data={{ entity_id: service_id, name: "component" }}
                                                refresh={() => { props.refresh() }}
                                                api_link={GenerateURL({ service_id: service_id }, properties.api.service_basic_details_update_url)}
                                                is_edit_permited={is_permitted}
                                            />
                                            :
                                            <>
                                                <Tooltip title="You are not allowed to perform this action">
                                                    <button className="btn btn-transparent btn-with-icon btn-round">
                                                    <i className="ri-delete-bin-line" style={{ color: '#818078' }} ></i>
                                                       
                                                    </button>
                                                </Tooltip>
                                            </>
                                    }
                                </div>
                            </MenuItem>
                            <MenuItem
                                style={{ padding: '0px' }}
                                // onClick={() => {
                                //     history.push({
                                //         pathname: "/application/" + application_id + "/service/" + service_id + "/env/edit",
                                //         state: { edit: true, env_id: selectedServiceEnv?.id }
                                //     })
                                // }}
                                onClick={() =>{handleOpenAddEnvDialog(); onCloseMenu();}}
                            >
                                {
                                    is_env_permitted ?
                                        <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>
                                            <i className="ri-edit-2-line font-15" style={{ marginRight: "8px", color: "#3696db" }}></i>
                                            <label style={{ fontSize: "14px", cursor: "pointer" }}>Edit Environment </label>
                                        </div>
                                        :
                                        <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>

                                            <Tooltip title="You are not allowed to perform this action" >
                                                {/* <CreateIcon style={{ color: '#818078' }} /> */}
                                                <button className='btn-sq-icon-primary'>
                                                    <span className='ri-edit-line vertical-align-super' style={{ color: '#818078' }}></span>
                                                </button>
                                            </Tooltip>
                                        </div>
                                }

                            </MenuItem>
                            <MenuItem
                                style={{ padding: '0px' }}
                                onClick={() => { }}
                            >
                                <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>

                                    {
                                        is_env_permitted ?

                                            <Delete
                                                varient="service-summary-env"
                                                display_data_name={selectedServiceEnv ? selectedServiceEnv?.project_env?.name : ""}
                                                data={{ entity_id: selectedServiceEnv?.id, name: "component_env" }}
                                                fontSize="font-18"
                                                refresh={() => { afterDeleteEnvRefresh(service_id) }}
                                                api_link={GenerateURL({ service_id: service_id, env_id: selectedServiceEnv?.id }, properties.api.env_data_update_url)}
                                            />
                                            :
                                            <>
                                                <Tooltip title="You are not allowed to perform this action">
                                                    <button className="btn btn-transparent btn-with-icon btn-round">
                                                        <i className="ri-delete-bin-line" style={{ color: '#818078' }} ></i>
                                                         
                                                    </button>
                                                </Tooltip>
                                            </>
                                    }
                                </div>

                            </MenuItem>
                        </div>

                    </Popover>

                </>

            }

        </div >
    )
}

TopSummary.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export default TopSummary