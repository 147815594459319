import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import SearchBar from '../../../../components/SearchBar';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useParams } from "react-router-dom";
import EditLabels from '../components/EditLabels';
import properties from '../../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import { Loading } from '../../../utils/Loading';
import DeleteConfirmationDialogue from '../../../settings/credentials/components/DeleteConfirmationDialogue';
import ExandableKeyValues from '../../../../components/hoc/ExpanadleKeyValues';
import PageHeader from '../../../../components/PageHeader';
import PaginationTwo from '../../../../components/PaginationTwo';
import SliderTabs from '../../../../components/genericComponents/SliderTabs';


const NamespaceList = props => {
    const classes = useStyles();
    const [spacing, setSpacing] = React.useState(2);
    const { cluster_id } = useParams();
    const dropdwn = [
        {
            id: 1,
            label: "show all"
        },
        {
            id: 1,
            label: "kube-master-6c8d"
        },
        {
            id: 1,
            label: "kube-master-6c8d"
        },
    ]

    const [showLoading, setShowLoading] = useState(false);
    const [type,setType] = useState("kube");
    const [state, setState] = useState({
        namespaces: [],
        namespacesOfBp:[],
        namespace_resource_quota: [],
        namespace_info: {}

    })


    useEffect(() => {
        setShowLoading(true);
        fetchNamespaces();
        fetchBPManagedNamespaces();
    }, [])

    useEffect(() => {
        let type = localStorage.getItem("namespace_source");
        if(!type || type == 'null'){
            localStorage.setItem('namespace_source',"kube");
        }else{
            setType(type);
        }
    },[])


    function handleSuccessApiHit(data) {
        setState(new_state => ({
            ...new_state,
            namespaces: new_state.namespaces.length > 0 ? [...new_state.namespaces, ...data.items] : data.items,
            namespace_info: data,
            load_more: false,
            loadingNamespaces: false, 
        }));
        setShowLoading(false);

    }

    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            loadingNamespaces: false, 
        }))
        setShowLoading(false);
    }

    function fetchNamespaces(data, load_more) {

        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_namespaces_listing),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (load_more) {
            requestInfo.endPoint = GenerateSearchURL({ pagination_token: state.namespace_info.metadata.continue }, requestInfo.endPoint);
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
            console.log("search", requestInfo.endPoint)
        }

        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);

        setState(new_state => ({
            ...new_state,
            load_more: load_more,
            namespaces: !load_more ? [] : new_state.namespaces,
            search_data: data,
            current: requestInfo,
            loadingNamespaces: true, 
        }))
    }

    function fetchBPManagedNamespaces(data){
        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_bp_namespaces_listing),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }
        // setState((prevState) => ({...prevState,loadingNamespaces: true}))
        InvokeApi(requestInfo, handleSuccessBpManaged, handleFailedBpManaged);
    }

    function handleSuccessBpManaged(data){
        const results = data?.results || [];

        setState(prevState => ({
            ...prevState,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            page_start: prevState.page_start + data.results.length,
            total_page: Math.ceil(Number(data.count / 10)),
            curr_page: 1,
            namespacesOfBp: results,    
            //loadingNamespaces: false
          }))
        //   setShowLoading(false);
    }

    function handleFailedBpManaged(error){
        console.log(error)
        setState((prevState) => ({...prevState,loadingNamespaces: false}))
        setShowLoading(false);
    }

    function fetchBPManagedNamespacesNext(url){
        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_bp_namespaces_listing),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (url) {
            requestInfo.endPoint = url;
        }
        setState((prevState) => ({...prevState,loadingNamespaces: true}))
        InvokeApi(requestInfo, 
            (data) => {
                setState(prevState => ({
                    ...prevState,
                    count: data.count,
                    next: data.next ? properties.api.baseURL + data.next : null,
                    previous: data.previous ? properties.api.baseURL + data.previous : null,
                    total_page: Math.ceil(Number(data.count / 10)),
                    curr_page: Number(prevState.curr_page + 1),
                    namespacesOfBp: data?.results || [],    
                    loadingNamespaces: false  
                }))
                // setShowLoading(false);
            }, handleFailedBpManaged);

    }

    function fetchBPManagedNamespacesPrev(url){
        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_bp_namespaces_listing),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (url) {
            requestInfo.endPoint = url;
        }
        setState((prevState) => ({...prevState,loadingNamespaces: true}))
        InvokeApi(requestInfo, 
            (data) => {
                setState(prevState => ({
                    ...prevState,
                    count: data.count,
                    next: data.next ? properties.api.baseURL + data.next : null,
                    previous: data.previous ? properties.api.baseURL + data.previous : null,
                    total_page: Math.ceil(Number(data.count / 10)),
                    curr_page: Number(prevState.curr_page - 1),
                    namespacesOfBp: data?.results || [],   
                    loadingNamespaces: false     
                }))
                // setShowLoading(false);
            }, handleFailedBpManaged);

    }

    function fetchBPManagedNamespacesPage(enteredPageNumber){
        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_bp_namespaces_listing),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (enteredPageNumber > 1) {
            requestInfo.endPoint =
              requestInfo.endPoint +
              "?limit=10&offset=" +
              (enteredPageNumber - 1) * 10;
        }
        setState((prevState) => ({...prevState,loadingNamespaces: true}))
        InvokeApi(requestInfo, 
            (data) => {
                setState(prevState => ({
                    ...prevState,
                    count: data.count,
                    next: data.next ? properties.api.baseURL + data.next : null,
                    previous: data.previous ? properties.api.baseURL + data.previous : null,
                    total_page: Math.ceil(Number(data.count / 10)),
                    curr_page: Number(enteredPageNumber),
                    namespacesOfBp: data?.results || [],  
                    loadingNamespaces: false         
                }));
                // setShowLoading(false);
            }, handleFailedBpManaged);

    }


    const invokeSearch = (data) => {
        console.log("invokeSearch", data);
        setType('kube');
        fetchNamespaces(data);
    }
    console.log("hello", state)
    function fetchNamespaceResouceQuota() {
        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_namespace_resource_quota),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setShowLoading(true);
        InvokeApi(requestInfo, handleSuccessApiHit1, handleFailedApiHit1);
    }

    function handleSuccessApiHit1(data) {
        setState(new_state => ({
            ...new_state,
            namespace_resource_quota: data.items
        }));
        setShowLoading(false);
    }

    function handleFailedApiHit1(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
        setShowLoading(false);
    }

    let labels_list = [];
    const iteration_count = 2;
    let labels_list2 = [];


    function get_list(labels, labels2, list_type) {
        labels_list = [];
        labels_list2 = [];
        if (labels) {
            Object.keys(labels).forEach(label => {
                labels_list.push(
                    {
                        key: label,
                        value: labels[label]
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label + "=" + labels[label]}>
                        <div className="pod-lable">
                            {label + "=" + labels[label]}
                        </div>
                    </Tooltip>
                );
            });
        }
        if (labels2) {
            labels2.forEach(label => {
                labels_list.push(
                    {
                        key: label.env_key,
                        value: label.env_value
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label.env_key + "=" + label.env_value}>
                        <div className="pod-lable">
                            {label.env_key + "=" + label.env_value}
                        </div>
                    </Tooltip>
                );
            });
        }
        if (list_type == "1") {
            return labels_list;

        }
        if (list_type == "2") {
            return labels_list2;

        }
        return [];
    }

    const options = [{label:'Kubernetes',id:'kube'},{label:'BP Managed',id:'bp'}]
    const OnTabChange = (tabId) => {
        localStorage.setItem("namespace_source",tabId);
        setType(tabId);
    }
    return (
        <div className={classes.root}>
            {
                showLoading ? <Loading /> : null
            }
             <PageHeader
                heading="Namespaces Available"
                subHeading="Displaying overall summary of Namespaces associated with this Cluster"
                imgIcon="/images/header_icons/namspace-icon.svg"
                icon={null}
                primaryButton={{
                    actionType: 'link',
                    action: "/cluster/" + cluster_id + "/namespace/add",
                    icon: <span className="ri-add-line font-14"></span>,
                    text: 'Add Namespace',
                    buttonClass: 'btn-primary'
                }}
                secondaryButton={{
                    actionType: 'onClick',
                    action: () => { fetchNamespaces(null, false) },
                    icon: <span className="ri-restart-line font-14" style={{fontWeight: '500'}}></span>,
                    text: 'Refresh',
                    buttonClass: 'btn-outline-primary'
                }}
                commonDivMargin={true}
            />
            <Grid container spacing={2} >
                    <Grid item lg={8}>
                        <SearchBar
                            search_data={state.search_data}
                            default_filter={{ name: "name", label: "Name" }}
                            search_call_back={invokeSearch}
                            clear_search_callback={fetchNamespaces}
                        />
                    </Grid>
                    <Grid item lg={4} style={{paddingRight:'0px'}} alignContent='center'>
                        <div className='d-flex align-center justify-end cursor-pointer' style={{marginRight:'-16px'}}>
                            <SliderTabs tabsList={options} selectedTabId={type} onChange={OnTabChange} />
                        </div>
                    </Grid>
                {state.loadingNamespaces && !showLoading ? <div className='d-flex justify-center align-center width-full' style={{height:"40vh"}}><Loading varient="light" /> </div>: 
                    type === "kube" ?
                        state.namespaces.length > 0 ?
                            <>
                                <Grid item lg={12} >
                                    <div className={classes.card}>
                                        

                                        <table className="table table-responsive table-stripped">
                                            <thead>
                                                <tr>


                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Annotations</th>
                                                <th>Labels</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                state.namespaces.map(namespace => (
                                                    <tr>
                                                        <td>
                                                            <Tooltip placement="bottom" title={namespace.metadata.name}>
                                                                <Link to={"/cluster/" + cluster_id + "/namespace/" + namespace.metadata.name + "/detail"}> <span >{namespace.metadata.name}</span></Link>
                                                            </Tooltip>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-center">
                                                                {namespace.status.phase == "Active" ? <i className="ri-checkbox-circle-fill" style={{ color: '#69e09c', fontSize: '14px' }} /> : <i className='ri-close-line' style={{ fontSize: '15px', color: '#ffce25' }} />}<span className='font-weight-500 color-tertiary'>&nbsp;{namespace.status.phase}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {namespace.metadata.annotations || namespace.annotations ? <ExandableKeyValues
                                                                compoenent_list={get_list(namespace.metadata.annotations, namespace.annotations, 2)}
                                                                iteration_count={iteration_count}
                                                                isLabel={false}
                                                                labels_list={get_list(namespace.metadata.annotations, namespace.annotations, 1)}
                                                                expandable_component={
                                                                    <>
                                                                        +{labels_list.length - iteration_count}
                                                                    </>
                                                                } />
                                                                : <p>No Annotations</p>}
                                                        </td>
                                                        <td>
                                                            {namespace.metadata.labels || namespace.labels ? <ExandableKeyValues
                                                                compoenent_list={get_list(namespace.metadata.labels, namespace.labels, 2)}
                                                                iteration_count={iteration_count}
                                                                isLabel={true}
                                                                labels_list={get_list(namespace.metadata.labels, namespace.labels, 1)}
                                                                expandable_component={
                                                                    <>
                                                                        +{labels_list.length - iteration_count}
                                                                    </>
                                                                } />
                                                                : <p>No Labels</p>}
                                                        </td>
                                                        <td>
                                                        </td>
                                                    </tr>))}
                                        </tbody>
                                    </table>
                                    {
                                        state.namespace_info ? <div className={classes.cardFooter} style={state.namespace_info.metadata.continue ? { display: 'block', textAlign: 'center' } : { display: 'none' }}>
                                            {state.load_more ? <Loading varient="light" /> :
                                                state.namespace_info.metadata.continue ?
                                                    <button className="btn btn-transparent btn-grey-navi hover-state" onClick={() => { fetchNamespaces(null, true) }}><span style={{color:"#546e7a"}}><i className="ri-loop-left-fill" style={{ fontSize: '2.5 rem', fontWeight: '800' }}></i>&nbsp;Load More</span></button>
                                                   : null
                                            }
                                        </div> : null
                                    }

                                    </div>
                                </Grid>

                            </>
                            :
                            <NoNamespaceFound />
                    :
                        state.namespacesOfBp.length > 0 ?
                            <>
                                <Grid item lg={12} >
                                    <div className={classes.card}>
                                        

                                        <table className="table table-responsive table-stripped">
                                            <thead>
                                                <tr>


                                                    <th>Name</th>
                                                    <th>Status</th>
                                                    <th>Annotations</th>
                                                    <th>Labels</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    state.namespacesOfBp.map(namespace => (
                                                        <tr>
                                                            <td>
                                                                <Tooltip placement="bottom" title={namespace.name}>
                                                                    <Link to={"/cluster/" + cluster_id + "/namespace/" + namespace.name + "/detail"}> <span >{namespace.name}</span></Link>
                                                                </Tooltip>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-center">
                                                                    {namespace.status == "READY" ? <i className="ri-checkbox-circle-fill" style={{ color: '#69e09c', fontSize: '14px' }} /> : <i className='ri-close-line' style={{ fontSize: '15px', color: '#ffce25' }} />}<span className='font-weight-500 color-tertiary'>&nbsp;{namespace.status}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {namespace.annotations && namespace.annotations?.length > 0 ? <ExandableKeyValues
                                                                    compoenent_list={get_list(null, namespace.annotations, 2)}
                                                                    iteration_count={iteration_count}
                                                                    isLabel={false}
                                                                    labels_list={get_list(null, namespace.annotations, 1)}
                                                                    expandable_component={
                                                                        <>
                                                                            +{labels_list.length - iteration_count}
                                                                        </>
                                                                    } />
                                                                    : <p>No Annotations</p>}
                                                            </td>
                                                            <td>
                                                                {namespace.labels && namespace.labels?.length > 0 ? <ExandableKeyValues
                                                                    compoenent_list={get_list(null, namespace.labels, 2) }
                                                                    iteration_count={iteration_count}
                                                                    isLabel={true}
                                                                    labels_list={get_list(null, namespace.labels, 1)}
                                                                    expandable_component={
                                                                        <>
                                                                            +{labels_list.length - iteration_count}
                                                                        </>
                                                                    } />
                                                                    : <p>No Labels</p>}
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>))}
                                            </tbody>
                                        </table>

                                        <PaginationTwo 
                                            current_page_count={state.curr_page}
                                            total_count={state.total_page}
                                            count={state.count}
                                            next={state.next}
                                            previous={state.previous}
                                            on_previous_click={() => {
                                              fetchBPManagedNamespacesPrev(state.previous)
                                            }}
                                            on_next_click={() => {
                                              fetchBPManagedNamespacesNext(state.next);
                                            }}
                                            on_pageNumber_click={(pageNumber) => {
                                              fetchBPManagedNamespacesPage(pageNumber);
                                            }}
                                        />

                                    </div>
                                </Grid>

                            </>
                            :
                            <NoNamespaceFound />
                }
            </Grid>
        </div >
    )
}

export default NamespaceList;


const ITEM_HEIGHT = 48;
export function LongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const application_id = props.application_id;
    const onDeleteRequest = () => {

        var saveUrl = GenerateURL({ namespace_id: props.id }, properties.api.get_namespace)
        setTimeout(() => {
            handleCloseDeleteDialogue();
        }, 400);
    }
  
    const handleCloseDeleteDialogue = () => {
        props.refresh();
        setDeleteOpen(false);
    };

    const [deleteOpen, setDeleteOpen] = useState(false);

    function onDeleteHandler() {
        setAnchorEl(null);
        setDeleteOpen(true);
    }

    return (
        <div className="m-auto text-center" >
            <button className="btn btn-transparent"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                >
                <i className="ri-more-2-fill"  style={{ color: '#0096db', fontSize: '2rem' }} />
            </button>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>
                    <span className="hover-content-link"><i className="ri-eye-line" style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;View Details</span>
                </MenuItem>
                <div className="divider"></div>
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

                    <span className="hover-content-link">
                        <i className="ri-edit-line" style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;<EditLabels />
                    </span>
                </MenuItem>


                <div className="divider"></div>
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>
                <i className={`ri-delete-bin-line font-16`} style={{ color: '#0096db'}}  onClick={onDeleteHandler} ></i>&nbsp;Delete

                </MenuItem>

            </Menu>
            <DeleteConfirmationDialogue onDeleteRequest={onDeleteRequest} open={deleteOpen} handleClose={handleCloseDeleteDialogue}
                name={props.name} type="namespace" />
        </div>
    );
}
LongMenu.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


const NoNamespaceFound = () => {
    const { cluster_id } = useParams();
    return (
        <div className="main-container-error" style={{ height: '60vh', margin: 'auto' }}>
            <div className="svg">
                <div className="servicePanel">
                    <div className="blank-div">
                        <div className="blank-div-text">
                            No Namespace associated with this cluster
                </div>
                        <div>
                            <Link to={"/cluster/" + cluster_id + "/namespace/add"} className="btn btn-submit">
                                <div >Add Namespace</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px',
        backgroundColor:'#fff',
        height: 'calc(100vh - 70px)',
    },
    card: {
        
        background: '#fff',
        overflowY: 'auto',
        minHeight: '500px',
        '& .pod-lable': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        '& .table':{
            '& th':{
                borderBottom: '1px solid #e6e6e6 !important',
                color:'#2f2f2f',
                fontSize:'12px',
                fontWeight:600
            }
        },
    },
    cardHeader: {
        borderRadius: '8px 8px 0px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: "#fff",
        fontSize: '13px',
        alignItems: 'center',
        padding: '10px 15px',
        '& .input-component': {
            minWidth: '120px',
            marginBottom: '0px'
        },

    },
    cardFooter: {
        backgroundColor: '#fff',
        borderRadius: ' 0px 0px 8px 8px',
        display: 'flex',
        padding: '10px 15px',
        borderTop: '1px solid #d5d5d5',
        justifyContent: 'flex-end',
        '& .btn-submit': {
            backgroundColor: '#3696db',
            color: '#fff',
            marginLeft: 'auto'
        }
    }
}));
