import React, { useState } from 'react';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import DynamicFilterField from './DynamicFilterField';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import Grid from '@mui/material/Grid';;
import { Loading } from '../../../utils/Loading';
import { makeStyles } from '@mui/styles';


const CreatePR = ({ pipeline, 
    pipelineVars, 
    jiraIssueKeys, 
    jobStepOrderOutputVars,
    dynamicValidationFormError,
    setParentState, 
    parentState,
    ...props }) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state;
    const jira_reference_key_list = props.jira_reference_key_list ? props.jira_reference_key_list : [];
    const get_run_conditions_function = props.get_run_conditions_function;
    const issue_keys_list = [...get_run_conditions_function().jira_id_reference_keys];
    const default_job_template = props.default_job_template;
    jira_reference_key_list.forEach(element => {
        issue_keys_list.push({ label: element.label, id: element.label })
    });
    const ticketing_system_project_list = props.ticketing_system_project_list ? props.ticketing_system_project_list : [];
    const [state, setState] = useState(getCreatePRDefaultState(prev_state, issue_keys_list))
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const provider_list = [
        {
            label: "GitHub",
            value: "GitHub"
        },
        {
            label: "BitBucket",
            value: "bitbucket"
        },
        {
            label: "Gitlab",
            value: "Gitlab",
        }
    ]
    console.log("jcndjnjfr",state);
  return (
    <>
    <Input
                type="select"
                name="ticketing_project"
                mandatorySign
                list={ticketing_system_project_list}
                label="Available Projects"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error}
                jiraOperation={true}
                jiraOperationInfo={"Please Select an Option"}
            />
                                        <Input
                                        type="radio"
                                        name="provider"
                                        label="Select your provider"
                                        list={provider_list}
                                        mandatorySign
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error} />
                                        {/* select for release jira ticket*/}
                                        <DynamicFilterField
                                            jiraIssueKeys={jiraIssueKeys}
                                            pipelineVars={pipelineVars}
                                            jobStepOrderOutputVars={jobStepOrderOutputVars}
                                            setParentState={setState}
                                            parentState={state}
                                            prev_state={prev_state}
                                            dynamicValidationFormError={dynamicValidationFormError}
                                            setTopParentState={setParentState}
                                            topParenState={parentState}
                                        >
                                            <Input
                                                type="select"
                                                name="release_issue_key"
                                                list={issue_keys_list}  // get_run_conditions_function().jira_id_reference_keys
                                                label="Select Release Jira Ticket Reference"
                                                placeholder="Jira id refrence key"
                                                mandatorySign
                                                onChangeHandler={commonFunctions.onChangeHandler}
                                                data={state.data}
                                                error={state.error} />
                                        </DynamicFilterField>

                                         {/* select for release jira list for creating PR*/}
                                        <DynamicFilterField
                                            jiraIssueKeys={jiraIssueKeys}
                                            pipelineVars={pipelineVars}
                                            jobStepOrderOutputVars={jobStepOrderOutputVars}
                                            setParentState={setState}
                                            parentState={state}
                                            prev_state={prev_state}
                                            dynamicValidationFormError={dynamicValidationFormError}
                                            setTopParentState={setParentState}
                                            topParenState={parentState}
                                        >
                                            <Input
                                                type="select"
                                                name="jira_issue_list"
                                                list={issue_keys_list}  // get_run_conditions_function().jira_id_reference_keys
                                                label="Select Mergeable Jira List Reference"
                                                placeholder="Jira id refrence key"
                                                mandatorySign
                                                onChangeHandler={commonFunctions.onChangeHandler}
                                                data={state.data}
                                                error={state.error} />
                                        </DynamicFilterField>
                                        <Input          
                                        type="text"
                                        name="destination_branch"
                                        label={"Enter Destination Branch"}
                                        mandatorySign
                                        placeholder={"Destination Branch..."}
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error} />
                                        
    </>
  )
}

function getCreatePRDefaultState(prev_state, issue_keys_list) {
    return {
        data: prev_state ? {
            //issue_key: prev_state.issue_key,
            release_issue_key: prev_state.release_issue_key ? prev_state.release_issue_key : null,
            pr_issue_key: prev_state.pr_issue_key ? prev_state.pr_issue_key : null,
            provider: prev_state.provider ? prev_state.provider : "bitbucket",
            destination_branch: prev_state.destination_branch ? prev_state.destination_branch : null,
            ticketing_project: prev_state.ticketing_project ? prev_state.ticketing_project : null
        } : {
            provider: "bitbucket",
        },
        error: {},
        dynamic_fields:{},
        validations: {
            //issue_key: [VALIDATION_TYPE_REQUIRED],
            provider: [VALIDATION_TYPE_REQUIRED],
            release_issue_key: [VALIDATION_TYPE_REQUIRED],
            destination_branch: [VALIDATION_TYPE_REQUIRED]
            // pr_issue_key: [VALIDATION_TYPE_REQUIRED],
        },
    };
}

export default CreatePR;