import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useParams } from 'react-router-dom';
import useFetchPermissions from '../../components/customHooks/useFetchPermissions';
import { Dialog, Menu, MenuItem, Tooltip } from '@mui/material';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import { ExandableComponentList } from '../../components/hoc/expandableList';
import SquareAvatar from '../../components/genericComponents/AvatarSquare';
import Delete from '../../components/genericComponents/Delete';

const DatabaseTemplateCard = props => {
    const classes = useStyles();
    const permisisons = useFetchPermissions();
    const data = props.data;
    const template_id = data.id;
    const { application_id } = useParams();
    var db_template_list = [];
    let selected_job_template_id = props.selected_job_template_id;
    var expandable_component = null;
    const iteration_count = 4;
    console.log(template_id, "sdvdsbvhsbvhdfs")
    const changeDefaultJobTemplate = props.changeDefaultJobTemplate ? props.changeDefaultJobTemplate : () => { }
    if (data.global_parameters.length > 0 || data.performance_parameters.length > 0) {
        data.global_parameters && data.global_parameters.length > 0 && data.global_parameters.forEach((item, key) => {
            db_template_list.push(
                <Tooltip title={item ? item : "N/A"}>
                    <div className={"chip-sq chip-sq-primary-outline"} key={item ? item : "N/A"}>
                        <Link >{item ? item : "N/A"}</Link>
                    </div>
                </Tooltip>
            )
        })
        data.performance_parameters && data.performance_parameters.length > 0 && data.performance_parameters.forEach((item, key) => {
            db_template_list.push(
                <Tooltip title={item ? item : "N/A"}>
                    <div className={"chip-sq chip-sq-primary-outline"} key={item ? item : "N/A"}>
                        <Link >{item ? item : "N/A"}</Link>
                    </div>
                </Tooltip>
            )
        })
        expandable_component = (
            <>
                +{db_template_list.length - iteration_count}
            </>
        );
    }

    const [openDialog, setOpenDialog] = useState(false);


    const handleCheckBox = (e) => {
        if (e.target.checked) {
            localStorage.setItem("openSetDefaultBox", "false")
        } else {
            localStorage.setItem("openSetDefaultBox", "true")
        }
    }


    useEffect(() => {

    });


    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const handleSetAsDefault = (dataId) => {
        if (localStorage.getItem("openSetDefaultBox") === "false") {
            changeDefaultJobTemplate(data?.id)
            return;
        } else {
            setOpenDialog(true);
        }
    }
    const handleSetAsDefaultYes = () => {
        changeDefaultJobTemplate(data?.id)
    }

    // var url = GenerateURL({ application_id: application_id }, properties.api.edit_db_template, true)
    // const is_permitted = permissions?.POST.includes(url);

    // let is_edit_permitted = url ? permissions?.POST.includes(url) : null
    // let is_delete_permitted = url ? permissions?.DELETE.includes(url) : null

    let is_edit_permitted = true;
    let is_delete_permitted = true;

    return (
        <>
            <div className='d-flex'>
                <div className={classes.root + " " + "p-12 d-flex f-direction-column align-flex-start bg-white"} key={props.key}>
                    <div className="jobcardTop d-flex align-center space-between width-full">
                        <div className="jobcardtitledesc">
                            <div className="font-18" style={{ display: "flex", alignItems: "center" }}>
                                <div className="mt-5">
                                    <SquareAvatar shape="db-box" varient="double" name={data.name} />
                                </div>
                                <Link
                                    to={`/application/${application_id}/database-templates/details/${template_id}`}
                                    state={{ template_id: template_id, data : data }} // Pass state as a separate prop
                                >
                                    <h3 style={{ marginLeft: '8px' }}>{data.name}</h3>
                                </Link>
                            </div>
                        </div>
                        {/* <div className='jobcardmenubar'>
                            <div className="d-flex align-center" style={{ gap: '8px' }}>
                                <OptionList
                                    url={url}
                                    is_permitted={is_permitted}
                                    data={data}
                                    refreshFun={props.refreshFn}
                                    apiUrl={properties.api.fetch_single_template_data}
                                    application_id={application_id}
                                />
                            </div>
                        </div> */}
                    </div>
                    {/* ---------this is the all jobs------- */}
                    <div className='joblistingjobs d-flex align-start f-direction-column' >
                        <h4>Configured Variables:</h4>
                        <div className='d-flex align-center' style={{ gap: "8px" }}>
                            <ExandableComponentList compoenent_list={db_template_list} iteration_count={iteration_count} expandable_component={expandable_component} variant={"joblistingview"} newStyle={""} />
                        </div>
                    </div>

                    {/*--------------- this is the middle bar line ----------- */}
                    <div className="jobListingBottomBar">
                        <div className='jobmiddelbarline'>

                        </div>
                        <div className='jobcardbottomfetails d-flex'>
                            <div className="d-flex align-center justify-flexend border-color-default pd-5">

                                <div className="d-flex space-between" style={{ gap: "14px" }}>
                                    {
                                        is_edit_permitted ?
                                            <Link className="text-anchor-blue" to={`/application/${application_id}/database-templates/edit/${template_id}`}>
                                                <span className='ri-edit-line vertical-align-super' style={{ fontSize: "20px" }}></span>
                                            </Link>
                                            :
                                            <Tooltip title="You are not allowed to perform this action">
                                                <span className='ri-edit-line vertical-align-super' style={{ fontSize: "20px" }}></span>
                                            </Tooltip>

                                    }
                                    <Delete
                                        display_data_name={data.name}
                                        varient="onlyIconWithoutWrap"
                                        data={{ entity_id: data && data.id, name: "database_metric_template" }}
                                        refresh={props.refreshFn}
                                        api_link={GenerateURL({ db_template_id : data.id }, properties.api.db_metric_template_edit_delete)}
                                        is_edit_permited={is_delete_permitted}
                                    />
                                    <span className='ri-file-copy-line font-18' style={{ color: "#787878", marginTop: "2px" }}></span>
                                </div>
                            </div>

                            {/* this is the buttons default tag and setDefault BTN */}
                            {selected_job_template_id == data.id ? <div className='job-default-template cursor-pointer' style={{
                                border: "1px solid  #FEA111",
                                background: "#FEA111",
                                color: "#fff"
                            }}>Default Template</div> : <div className='job-default-template cursor-pointer' onClick={() => handleSetAsDefault()}>Set as Default</div>}
                        </div>
                    </div>
                </div >
            </div>

            <Dialog open={openDialog}
                className={classes.muiSetDefaultDialog}
                onClose={handleCloseDialog}>
                <div className={classes.setDefaultDialog}>
                    <div className='w-100 align-center display-inline-flex space-between gap-8' style={{ padding: "11px 16px", background: "#fafafa" }}>
                        <div className='d-flex align-center justify-flex-start' style={{ gap: 24, }}>
                            <div className='font-weight-600 text-transform-uppercase word-wrap-break font-16' style={{ color: '#2F2F2F' }}>Caution</div>
                        </div>
                        <span className='ri-close-fill font-24 cursor-pointer' onClick={handleCloseDialog}>

                        </span>
                    </div>
                    <div className='setdefmiddlebar d-flex align-center justify-center f-direction-column w-100'>
                        <div className="display-flex align-center justify-center" style={{ padding: "12px", width: "56px", height: "56px", border: "1px solid #DFEDFF", background: "#F5FAFF", borderRadius: "6px" }}>
                            {/* <img src={properties?.tools?.groupIcon?.img ? properties?.tools?.groupIcon?.img : ""} >

                            </img> */}
                            <span className='ri-alert-fill' style={{ fontSize: "32px", color: "#0086FF" }}></span>
                        </div>
                        <div className='d-flex align-center f-direction-column mt-20' >
                            <div>
                                <div className='font-18 font-weight-600 word-wrap-break' style={{ color: '#2F2F2F' }}>Set as Default Template?</div>
                            </div>
                            <div>
                                <div className='font-14 font-weight-500 word-wrap-break text-align-center mt-12' style={{ color: '#505050', lineHeight: "1.5", width: "384px", height: "42px" }}>you’re going to set <b className="text-transform-uppercase"> “{data.name}” </b> as<br />Default Template. Are you Sure?</div>
                            </div>
                        </div>
                    </div>
                    <div className='setdefbottombar w-100 d-flex align-center space-between'>
                        <div className='d-flex align-center gap-8'>
                            <input type='checkbox' style={{ width: "18px", height: "18px", border: "1px solid #FFF !important" }} onChange={handleCheckBox} />
                            <div className='font-weight-400 font-14 word-wrap-break' style={{ color: '#5C5C5C' }}>Don’t show this message again</div>
                        </div>
                        <div className='d-flex align-center gap-8'>
                            <button className='display-inline-flex align-center justify-center gap-5' onClick={handleCloseDialog}>
                                <span className='font-12 font-weight-600 word-wrap-break text-transform-uppercase text-align-center' >Cancel</span>
                            </button>
                            <button className='display-inline-flex align-center justify-center gap-5 border-none' onClick={() => {
                                handleSetAsDefaultYes();
                                handleCloseDialog();
                            }} style={{ height: "40px", width: "169px", background: '#0086FF', borderRadius: "6px" }}>
                                <span className='font-12 font-weight-600 word-wrap-break text-transform-uppercase color-white font-family-v1' >Yes, Set as Default!</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog >
        </>
    )
}

// this is the option list component
const ITEM_HEIGHT = 48;
const OptionList = (props) => {
    const url = props.url;
    const is_permitted = props.is_permitted;
    const application_id = props.application_id;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const data = props.data ? props.data : {};

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <button className="btn btn-transparent"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <span className='ri-more-2-fill' style={{ fontSize: "20px" }}></span>
            </button>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem style={{ display: "grid" }}>
                    {
                        is_permitted &&
                        <Delete
                            // varient="new_button"
                            is_edit_permited={is_permitted}
                            display_data_name={data.name}
                            data={{ entity_id: data.id, name: "project_job_templates" }}
                            refresh={props.refreshFun}
                            api_link={GenerateURL({ project_id: application_id, temp_id: data.id }, properties.api.remove_template)}
                        />
                    }
                </MenuItem>
                <MenuItem style={{ display: "grid", justifyContent: 'center' }}>
                    {
                        is_permitted ?
                            <Link to={`/application/${application_id}/job-template/${data.id}/edit`} className="d-flex text-anchor-blue">
                                <span className='d-flex align-center' style={{ gap: "7px" }}><span className="ri-edit-line"></span><span style={{ marginRight: "7px" }}>Edit</span></span>
                            </Link>
                            :
                            <Tooltip title="You are not allowed to perform this action" >
                                <span className='d-flex align-center' style={{ gap: "7px" }}><span className="ri-edit-line"></span><span style={{ marginRight: "7px" }}>Edit</span></span>
                            </Tooltip>
                    }
                </MenuItem>
            </Menu>
        </>
    )
}
const useStyles = makeStyles({

    muiSetDefaultDialog: {
        "& .MuiDialog-paperScrollPaper": {
            width: "inherit",
        },
        "& .MuiDialog-paper": {
            overflow: "hidden"
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "655px"
        },
    },
    setDefaultDialog: {
        width: "655px",
        height: "330px",

        "& .setdefmiddlebar": {
            padding: "24px 16px",
            height: "200px"


        },

        "& .setdefbottombar": {
            height: "75",
            padding: "16px",
            background: "rgb(250, 250, 250)",

            "& button": {
                "&:nth-child(1)": {
                    background: "white",
                    height: "40px",
                    width: "84px",
                    borderRadius: "6px",
                    border: "1px solid #9DC0EE",
                    color: '#124D9B',
                    "&:hover": {
                        background: "#0086FF",
                        color: "#FFF !important"
                    }
                },
                "&:nth-child(2)": {
                    "&:hover": {
                        background: "#03234D !important"
                    }
                }
            }
        }
    },
    root: {
        // height: "190px",
        width: "100%",
        position: 'relative',
        overflow: 'visible',
        gap: "20px",
        flex: "1 0 0",
        padding: "12px",
        borderRadius: "6px",
        background: "#FFF",
        boxShadow: "0px 9px 8px 0px rgba(0, 0, 0, 0.06)",
        display: "flex",
        justifyContent: "space-between",

        "& a:visited": {
            color: "#787878 !important"
        },

        "& .jobcardTop": {
            height: "30px",
            "& .jobcardtitledesc": {
                "& h3": {
                    color: "#2F2F2F",
                    fontSize: "14px",
                    fontWeight: 600,
                }
            },

        },

        "& .joblistingjobs": {
            margin: "15px 0",
            gap: "8px",

            "& h4": {
                color: "#787878",

                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
            },
        },

        "& .jobListingBottomBar": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            alignSelf: "stretch",
            flexDirection: "column",
            gap: "12px",

            "& .jobmiddelbarline": {
                height: "1px",
                backgroundColor: "#E3E3E3",
                width: "100%",
            },

            "& .jobcardbottomfetails": {
                width: "100%",
                dislay: "flex",
                alignItems: "center",
                justifyContent: "space-between",


                "& .jobcardusername": {
                    display: "flex",
                    padding: "6px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6px",
                    borderRadius: "5px",
                    background: "#EBF5FF",
                    color: "#0086FF",

                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal"
                },

                "& .job-default-template": {
                    display: "flex",
                    width: "132px",
                    height: "36px",
                    padding: "6px 8px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "6px",
                    border: "1px solid  #E6E6E6",
                    color: "#505050",

                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",

                    "&:hover": {
                        background: "#FEA111",
                        color: "#fff",
                    }
                }
            }
        }

    },

    rootCard: {
        // marginLeft: "30px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between",
        // width : "215px",

        "& p": {
            color: "#000",
            fontSize: "13px",
        },

        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
    },
});

export default DatabaseTemplateCard;