import React from 'react';
import PropTypes from 'prop-types';
import './stepperPipeline.css'
import { makeStyles } from '@mui/styles';
const StepperRevampPipelineCard = (props) => {
    const steps = props.steps;

    console.log("steps_0pssdsds", steps);

    const classes = useStyles();

    function getIcon(status, isCurrent) {

        switch (status) {
            case "success":
                return (
                    <>
                        {
                            isCurrent ?
                                <div className="md-step-circle success" style={{ border: '3px solid #1A794C' }}>
                                    <i className="stepper-icon ri-checkbox-circle-fill font-18" style={{ color: '#2EBE79', lineHeight: "28px" }} />
                                </div> :
                                <div className="md-step-circle success-circle">
                                    <i className="stepper-icon ri-checkbox-circle-fill font-18" style={{ color: '#2EBE79', display: 'none', lineHeight: "28px" }} />
                                </div>
                        }

                    </>

                );
            case "skipped":
                return (
                    <div className="md-step-circle skipped">
                        <i className="ri-git-branch-line font-18" style={{ color: '#52bfe7', lineHeight: "28px" }}></i>
                    </div>
                );
            case "failed":
                return (
                    <div style={{ border: "3px solid #E53737" }} className="md-step-circle fail">
                        <i className="ri-close-circle-fill font-18" style={{ color: '#E53737', lineHeight: "28px" }}></i>
                    </div>
                );
            case "progress":
                return (
                    <div style={{ border: '3px solid #0086FF' }} className="md-step-circle running">
                        <i className="ri-time-fill font-18" style={{ color: '#0086FF', lineHeight: "28px" }} ></i>
                    </div>
                );
            case "pending":
                return (
                    <div style={{ border: '3px solid #9747FF' }} className="md-step-circle approval-pending">
                        <i className="ri-thumb-up-fill font-18" style={{ color: '#9747FF', lineHeight: "28px" }} />
                    </div>
                );
            case "waiting":
                return (
                    <div className="md-step-circle ">
                        <i className="ri-pause-circle-line font-18" style={{ color: '#0086ff', lineHeight: "28px" }} ></i>
                    </div>
                );
            case "disapproved":
                return (
                    <div style={{ border: '3px solid #14002E', background: "#F4F4F4" }} className="md-step-circle">
                        <i className="ri-thumb-down-fill font-18" style={{ color: '#14002E', lineHeight: "28px" }}></i>
                    </div>
                );
            case "suspended":
                return (
                    <div className="md-step-circle " style={{ border: '3px solid rgb(142,142,142)' }}>
                        <i className="ri-file-info-line font-18" style={{ color: 'rgb(142,142,142)', lineHeight: "28px" }}></i>

                    </div>
                );
            case "stopped":
                return (
                    <div className="md-step-circle " style={{ border: '3px solid #e9797e' }}>
                        <i className="ri-stop-line font-18" style={{ color: '#e9797e', lineHeight: "28px" }}></i>
                    </div>
                );
            case "revoked":
                return (
                    <div style={{ display: 'flex', alignItems: 'center', border: '3px solid #E1941D', justifyContent: 'center' }} className="md-step-circle revoke">
                        <i style={{ color: "#E1941D", marginRight: "1px", lineHeight: "28px" }} className="ri-stop-circle-fill font-18" ></i>
                    </div>
                );
            default:
                return (
                    <div className="md-step-circle ">
                        <span style={{ fontSize: '13px', color: '#000', textAlign: 'center', position: 'relative', bottom: '6px' }}> </span>
                    </div>
                );
        }
    }

    function getLeftBar(status, opacity) {
        switch (status) {
            case "success":
                return (
                    <div style={{ opacity: opacity, borderTopColor: "#2EBE79" }}
                        className="md-step-bar-left success"></div>
                );
            case "skipped":
                return (
                    <div style={{ opacity: opacity }} className="md-step-bar-left skipped"></div>
                );
            case "failed":
                return (
                    <div style={{ opacity: opacity, borderTopColor: '#E53737' }} className="md-step-bar-left fail"></div>
                );
            case "progress":
                return (
                    <div style={{ opacity: opacity, borderTopColor: '#0086FF' }} className="md-step-bar-left running"></div>
                );
            case "pending":
                return (
                    <div style={{ opacity: opacity }} className="md-step-bar-left purple"></div>
                );
            case "waiting":
                return (
                    <div style={{ opacity: opacity }} className="md-step-bar-left blue"></div>
                );
            case "revoked":
                return (
                    <div style={{ opacity: opacity, borderTopColor: "#E1941D" }} className="md-step-bar-left"></div>

                );
            case "disapproved":
                return (
                    <div style={{ opacity: opacity, borderTopColor: "#14002E" }} className="md-step-bar-left fail"></div>
                );
            case "suspended":
                return (
                    <div className="md-step-bar-left" style={{ opacity: opacity }}></div>
                );
            default:
                return (
                    <div style={{ opacity: opacity }} className="md-step-bar-left "></div>
                );
        }
    }

    function getRightBar(status, opacity) {
        switch (status) {
            case "success":
                return (
                    <div style={{ opacity: opacity, borderTopColor: "#2EBE79" }} className="md-step-bar-right success"></div>
                );
            case "skipped":
                return (
                    <div style={{ opacity: opacity }} className="md-step-bar-right skipped"></div>
                );
            case "failed":
                return (
                    <div style={{ opacity: opacity, borderTopColor: '#E53737' }} className="md-step-bar-right fail"></div>
                );
            case "progress":
                return (
                    <div style={{ opacity: opacity, borderTopColor: '#0086FF' }} className="md-step-bar-right running"></div>
                );
            case "pending":
                return (
                    <div style={{ opacity: opacity }} className="md-step-bar-right purple"></div>
                );
            case "waiting":
                return (
                    <div style={{ opacity: opacity }} className="md-step-bar-right blue"></div>
                );
            case "revoked":
                return (
                    <div style={{ opacity: opacity, borderTopColor: "#E1941D" }} className="md-step-bar-right"></div>

                );
            case "disapproved":
                return (
                    <div style={{ opacity: opacity, borderTopColor: "#14002E" }} className="md-step-bar-right fail"></div>
                );
            case "suspended":
                return (
                    <div className="md-step-bar-right" style={{ borderTopColor: 'rgb(142, 142, 142)', opacity: opacity }}></div>
                );
            default:
                return (
                    <div style={{ opacity: opacity }} className="md-step-bar-right "></div>
                );
        }
    }

    console.log(steps, 'data_0ppsdsd')
    let currentStageIndex = steps?.length - 1;
    for (let index = 0; index < steps.length; index++) {
        const element = steps[index];
        if (element.status == undefined && index !== 0) {
            currentStageIndex = index - 1;
            break;
        }
    }

    let currentStep = steps[currentStageIndex]
    console.log(currentStep, 'data_0psdsds_0psd')

    function calculateOpacity(index, listLength, minOpacity = 0.4, maxOpacity = 0.8) {
        if (listLength <= 1) {
            return maxOpacity; // Default to maxOpacity for single-item lists
        }
        return minOpacity + (index / (listLength - 1)) * (maxOpacity - minOpacity);
    }
    return (
        <div className={classes.root}>
            <div style={{ width: '100%', overflow: 'auto', background: 'white', justifyContent: steps.length < 4 ? 'center' : 'flex-start' }} className={"md-stepper-horizontal-rmp container"} >
                {
                    steps.map((step, index) => {
                        let rightBarOpacity = calculateOpacity(index, steps?.length)
                        let leftBarOpacity = calculateOpacity(index == 0 ? index : index - 1, steps?.length)
                        let foundCurrentIndexRight = false
                        if (index >= currentStageIndex) {
                            foundCurrentIndexRight = true
                        }
                        return (
                            <div style={{ width: '300px', flex: '0 0 auto' }} className="md-step active" >

                                {getIcon(step.status, currentStageIndex == index)}
                                <div style={{ color: currentStageIndex == index ? '#505050' : '#787878' }} className="md-step-title">
                                    {step.main_text}<br />
                                    <span style={{ color: '#787878', fontWeight: '500' }}>{step.sub_text}</span>
                                </div>

                                {
                                    index > currentStageIndex ?
                                        <>
                                            {step.status ? getLeftBar(null, 0.8) : getLeftBar(null, 0.8)}
                                        </> :
                                        <>{step.status ? getLeftBar(currentStep.status, leftBarOpacity) : getLeftBar(step.status, leftBarOpacity)}</>

                                }


                                {
                                    foundCurrentIndexRight || index > currentStageIndex ? <>
                                        {step.status ? getRightBar(null, 0.8) : getRightBar(null, 0.8)}

                                    </> :
                                        <>
                                            {step.status ? getRightBar(currentStep.status, rightBarOpacity) : getRightBar(step.status, rightBarOpacity)}

                                        </>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>

    )
}

StepperRevampPipelineCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default StepperRevampPipelineCard;

const useStyles = makeStyles({
    root: {
        width: '100%',
        '& .container': {
            display: 'flex',
            width: "100%",
            overflow: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '0',
            },
            '&::-webkit-scrollbar-thumb': {
                display: 'none',
            },
            '&::-webkit-scrollbar-track': {
                display: 'none', /* For hiding scrollbar track */
            },
        },
    }
});
