import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getEnvDeployVarsWithCategories } from '../../../../../serviceRevamp/add/cd_flow/CdInfo';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';

const LabelsAnnotationsOverview = (props) => {
    const prev_state = props.prev_state;
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const [showLoading, setShowLoading] = useState(false);
    const [state, setState] = useState({
        env_cd_deploy_variable: [],
        showValue: false,
        showConfigValue: false,
        showSecretValue: false
    });
    const extraProps = props.extraProps;
    const application_id = extraProps.application_id;
    const auto_approval_flag = extraProps.auto_approval_flag;
    const service_id = extraProps.service_id;
    const component_env_id = extraProps.component_env_id;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);
    const handleToggle = () => {
        setState({
            ...state,
            showValue: !state.showValue,
        })
    }
    const handleToggleConfig = () => {
        setState({
            ...state,
            showConfigValue: !state.showConfigValue,
        })
    }
    const handleToggleSecret = () => {
        setState({
            ...state,
            showSecretValue: !state.showSecretValue
        })
    }
    useEffect(() => {
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state])
    var envVarCategories = getEnvDeployVarsWithCategories(state.env_cd_deploy_variable);
    return (
        <div className="section-service-overview" style={{ border: '0px', padding: '10px' }}>
            <div className="d-flex align-center space-between">
                <div className="overview-heading">
                    Labels &amp; Annotations
                </div>
                {is_permitted ?

                    state.status == "DRAFT" ?
                        <Link
                            to={{
                                pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/cd/${cd_id}/edit`,
                                search: `?selectedTabOrder=9&selected_cd_id=${extraProps?.selected_cd_data?.id}`,
                            }}
                        >
                            <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                        </Link>
                        :
                        <EditDetailsConfirmationDialog
                            application_id={application_id}
                            service_id={service_id}
                            auto_approval_flag={auto_approval_flag}
                            component_env_id={component_env_id}
                            selectedTabOrder={9}
                            cd_data={prev_state}
                            selected_cd_id={extraProps?.selected_cd_data?.id}
                        />

                    : <Tooltip title="You are not allowed to perform this action" >
                        <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                    </Tooltip>}

            </div>
            <div className="overview-card-section grid-template-3">
                <div className="overview-single-card">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Labels
                        </p>


                    </div>
                    <div className="overview-card-value-env-var">
                        <div className="heading-key-value">
                            <p>Key</p>
                            <p>Value</p>
                        </div>
                        <div className="env-variable-box">

                            {state.labels ?
                                <>
                                    {state.labels.map(item => (
                                        <div className="data-key-value-pair">
                                            <p>{item.env_key}</p>
                                            <p>

                                                {item.env_value}


                                            </p>
                                        </div>
                                    ))}
                                </> : <p className="no-data__msg-env-var" >No Data Available</p>}
                        </div>
                    </div>
                </div>
                <div className="overview-single-card">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Label Annotations
                        </p>


                    </div>
                    <div className="overview-card-value-env-var">
                        <div className="heading-key-value">
                            <p>Key</p>
                            <p>Value</p>
                        </div>
                        <div className="env-variable-box">

                            {state.label_selectors ?
                                <>
                                    {state.label_selectors.map(item => (
                                        <div className="data-key-value-pair">
                                            <p>{item.env_key}</p>
                                            <p>

                                                {item.env_value}


                                            </p>
                                        </div>
                                    ))}
                                </> : <p className="no-data__msg-env-var" >No Data Available</p>}
                        </div>
                    </div>
                </div>
                <div className="overview-single-card">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Annotations
                        </p>


                    </div>
                    <div className="overview-card-value-env-var">
                        <div className="heading-key-value">
                            <p>Key</p>
                            <p>Value</p>
                        </div>
                        <div className="env-variable-box">

                            {state.annotations ?
                                <>
                                    {state.annotations.map(item => (
                                        <div className="data-key-value-pair">
                                            <p>{item.env_key}</p>
                                            <p>

                                                {item.env_value}


                                            </p>
                                        </div>
                                    ))}
                                </> : <p className="no-data__msg-env-var" >No Data Available</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

LabelsAnnotationsOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default LabelsAnnotationsOverview