import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Input } from '../../../../components/genericComponents/Input';
import Grid from '@mui/material/Grid';
import { getCommonFunctions, ResetComponent } from '../ci_flow/SourceDetails';
import { ValidateDataSet } from '../../../../util/Validator';
import Priorities from './Priorities';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const TolerationsAndPriority = (props) => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const extraProps = props.extraProps ? props.extraProps : {};
    const component_env_id = extraProps.component_env_id ? extraProps.component_env_id : null;
    const service_id = extraProps.service_id ? extraProps.service_id : null;
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : getTolerationsAndPriorityDefaultState())
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    
    useEffect(() => {
        setState(new_state => ({ ...new_state, ...prev_state }))
    }, [prev_state])

  

    return (
        <div className={`${classes.root} ci-cd-edit-input-div`}>
            <Grid container className="card-add-service">
                <Grid item lg={12} direction="row" className="card-add-service-head">
                    <div className="formTag">
                        <h4 className='mainHeading'>Tolerations And Priority</h4>
                    </div>
                </Grid>
                <Grid container spacing="2">
                    <Grid lg={12} item >
                        <div className='card'>
                            <div className='heading'>
                                <Input
                                    type="switch"
                                    name="tolerations"
                                    label="Setup Tolerations?"
                                    onChangeHandler={commonFunctions.toggleState}
                                    data={state.data}
                                    error={state.error}
                                    enabledComponent={<Tolerations prev_state={state.tolerations_data} inherits={state.child_inherits.tolerations_data} />}
                                    disabledComponent={<ResetComponent inherits={state.child_inherits.tolerations_data} />}
                                />
                            </div>

                        </div>
                        <div className='card'>
                            <div className='heading'>
                                <Input
                                    type="switch"
                                    name="priority"
                                    label="Setup Priority"
                                    onChangeHandler={commonFunctions.toggleState}
                                    data={state.data}
                                    error={state.error}
                                    enabledComponent={<Priorities 
                                        prev_state={state.priorities_data}
                                        component_env_id={component_env_id}
                                        service_id={service_id}
                                        inherits={state.child_inherits.priorities_data} />}
                                    disabledComponent={<ResetComponent inherits={state.child_inherits.priorities_data} />}
                                />
                            </div>
                        </div>
                    </Grid>

                </Grid>

            </Grid>
        </div>
    )
}
TolerationsAndPriority.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
export default TolerationsAndPriority

export function getTolerationsAndPriorityDefaultState() {
    return {
        data: {
            tolerations: false,
            priority: false
        },
        error: {},
        validations: {
        },
        priority_class_list:[],
        child_inherits: {
            tolerations_data: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            priorities_data: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        }
    };
}

const dialog_styles = makeStyles(() => ({
    dialog_root: {
        position: 'relative',
        '& .MuiDialog-paperScrollPaper': {
            width: '100rem',
            backgroundColor: '#fff;'
        },
        '& .MuiPaper-root': {
            maxWidth: '1000px'
        }
    },
    empty_msg: {
        width: '400px',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        margin: 'auto'
    },
    access_level_card: {
        borderRadius: 4,
        '& .justify-flex-start': {
            justifyContent: 'flex-start'
        },
        '& .card-header': {
            fontSize: '13px',
            color: '#464646',
            height: 'auto',
            '& .card-body': {
                padding: '10px'
            },

        },
        '& .sub-header': {
            backgroundColor: '#FEFEFE',
            borderBottom: '1px solid #EAEAEA',
            borderTop: '1px solid #EAEAEA',
            padding: 10
        },
        '& .single-row-access-level': {
            display: 'Grid',
            gridTemplateColumns: '15% 15% 15% 15% 13% 13% auto',
            gap: '10px',
            borderBottom: '1px solid #EAEAEA',
            '&:last-child': {
                borderBottom: '0px solid #EAEAEA',
            },
            '& .single-row-item': {
                padding: 10,
            },
            '& .title-heading': {
                color: '#4d4d4d',
                fontSize: '12px'
            },
            '& .heading-value': {
                color: '#9A9A9A',
                fontSize: '12px'
            }
        }
    },
    dialog: {
        '& .sq-chip': {
            backgroundColor: '#626262',
            color: '#fff',
            padding: '1px 3px',
            borderRadius: '4px',
            marginLeft: '3px'
        }
    },
    button_class: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer'
        },
        '& .small-icon-button': {
            backgroundColor: '#fff',
            // marginLeft: '5px',
            border: 'none',
            boxShadow: '0.877px 1.798px 8px 0px rgba(0, 0, 0, 0.2)',
            height: '20px',
            width: '20px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#0086ff'
        }
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #C5C5C5',
        fontSize: '14px',
        fontWeight: 400,
        color: '#fff',
        backgroundColor: 'rgb(18, 77, 155)',
        padding: '10px 20px'
    },
    cardBody: {
        padding: '0px 10px',
        '& ul': {
            '&.headerul': {
                '& li': {
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '12px',
                    borderBottom: '1px solid #a3a3a3',
                    color: '#000',
                    '&.active': {
                        color: '#000',
                        borderBottom: '2px solid #124D9B'
                    }
                }
            }
        }
    },
    toleration_card: {
        '& .toleration-card-header': {
            fontSize: '12px',
            display: 'grid',
            gridTemplateColumns: '20% 10% 20% 20% 10% 20%',
            borderBottom: '2px solid #000',
            padding: '5px 0px',
            '& p': {
                textAlign: 'center'
            }
        },
        '& .toleration-card-Body': {
            fontSize: '12px',
            color: '#838383',
            '& p': {
                textAlign: 'center'
            },
            '& .single-row-toleration': {
                display: 'grid',
                gridTemplateColumns: '20% 10% 20% 20% 10% 20%',
                borderBottom: '1px solid #dedede',
                padding: '5px 0px',
                alignItems: 'center'
            },
            '& .add-more-row': {
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .btn': {
                    color: '#3e73ec !important'
                }
            }
        }
    }
}));
function Tolerations(props) {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const classes = dialog_styles();
    const [state, setState] = useState(prev_state ? prev_state : getTolerationsDefaultState())
    const commonFunctions = getCommonFunctions(state, setState, inherits);
   
    const handleClickOpen = () => {
        setState((new_state) => ({
            ...new_state,
            open: true
        }))
    }

    const handleClickClose = () => {
        setState((new_state) => ({
            ...new_state,
            open: false
        }))
    }
    const operators = [
        {
            id: "In",
            label: '='
        },
        {
            id: "NotIn",
            label: '≠'
        },
        {
            id: "Lt",
            label: '<'
        },
        {
            id: "Gt",
            label: '>'
        },
        {
            id: "Exists",
            label: 'Exists'
        },
        {
            id: "DoesNotExist",
            label: 'Does Not Exist'
        },
    ]
    useEffect(() => {
      if(state.update_state_fn){
        setState(prevState => ({
            ...prevState,
            update_state_fn: true,
            open: true,
            clearState: null,
            data: {
                ...state.single_form_data
            }
        }))
      }
    
    }, [state.single_form_data])
    
    const handleEditData = (data) => {
        console.log(data,"fkjasjfja")
        setState(prevState => ({
            ...prevState,
            update_state_fn: true,
            open: true,
            clearState: null,
            single_form_data: {
                ...prevState.single_form_data,
                ...data
            }
        }))
    }
    const handleDeleteRow = (data) => {
        var total_arr = state.tolerations;
        total_arr.forEach((item, key) => {

            if (item.key == data.key) {

                total_arr.splice(key, 1)

            }
        })
        setState(prevState => ({
            ...prevState,
            tolerations: [
                ...total_arr
            ],
        }))
    }
    const validateAndSaveFn = () => {
        var result = ValidateDataSet(state.data, state.validations);
        if (result.valid) {
            var tolerations_list = state.tolerations
            if (tolerations_list.length > 0) {
                let found_duplicate = tolerations_list.find(item => item.key == state.data.key);
                if (!found_duplicate) {
                    tolerations_list.push(state.data);
                    setState((new_state) => ({
                        ...new_state,
                        open: false,
                        tolerations: [...tolerations_list],
                        data:{
                            key: "",
                            effect: "",
                            toleration_seconds: "",
                            value:"",
                            operator:""
                        }
                    }))
                } else {
                    setState((new_state) => ({
                        ...new_state,
                        error: {
                            key: "Key already exists"
                        }
                    }))
                }
            } else {
                tolerations_list.push(state.data);
                setState((new_state) => ({
                    ...new_state,
                    open: false,
                    tolerations: [...tolerations_list],
                    data:{
                        key: "",
                        effect: "",
                        toleration_seconds: "",
                        value:"",
                        operator:""
                    }
                }))
            }

        } else {
            setState((new_state) => ({
                ...new_state,
                error: result.error
            }))
        }
    }
    const updateCurrentObjFn = (current_obj) => {
        
        var result = ValidateDataSet(state.data, state.validations);
        if (result.valid) {
            let tolerations_list = state.tolerations.map(item => {
                if (item.key == current_obj.key) {
                    return {
                        ...item,
                        key : state.data.key,
                        operator : state.data.operator,
                        value : state.data.value,
                        effect: state.data.effect,
                        toleration_seconds: state.data.toleration_seconds
                    }
                } 
                else {
                    return {
                        ...item
                    }
                }
            })
            setState((new_state) => ({
                ...new_state,
                open: false,
                tolerations: [...tolerations_list],
                update_state_fn: false,
                data:{
                    key: "",
                    effect: "",
                    toleration_seconds: "",
                    value:"",
                    operator:""
                }
            }))
        } else {
            setState((new_state) => ({
                ...new_state,
                error: result.error
            }))
        }
    }
   
    return (
        <>
            <div className='card-body' style={{ padding: '10px 10px 0px' }}>
                {
                    state.tolerations.length > 0 ?
                        <div className={`toleration-card ${classes.toleration_card}`}>
                            <div className="toleration-card-header">
                                <p>Key</p>
                                <p>Operators</p>
                                <p>Value</p>
                                <p>Effect</p>
                                <p>Seconds</p>
                                <p>Actions</p>
                            </div>
                            <div className="toleration-card-Body">
                                {state.tolerations.map((item, key) => {
                                    return <div className='single-row-toleration' key={key}>
                                        <p>{item.key}</p>
                                        <p>{item.operator}</p>
                                        <p>{item.value}</p>
                                        <p>{item.effect}</p>
                                        <p>{item.toleration_seconds}</p>
                                        <div className='d-flex align-center justify-center'>
                                        <button className="btn btn-transparent" onClick={()=>{handleEditData(item)}}>
                                            <span className="ri-edit-line text-anchor-blue" style={{fontSize: "24px"}}></span> 
                                        </button>
                                        <button className="btn btn-transparent" onClick={()=>{handleDeleteRow(item)}}>
                                            <i className={`ri-delete-bin-line`} style={{ color: 'rgb(255, 137, 105)' }} />
                                        </button>
                                        </div>
                                    </div>
                                })
                                }
                                <div className='add-more-row'>
                                    <button className='btn btn-link d-flex align-center justify-center text-center' onClick={handleClickOpen}><i className="ri-add-line"></i> Add more</button>
                                </div>
                            </div>

                        </div>
                        :
                        <div className={`empty-toleration-msg pd-20 ${classes.empty_msg}`}>
                            <p className='font-12 mb-20 text-center'>Tolerations Not Configured yet!!</p>
                            <button onClick={handleClickOpen} className='btn btn-primary-v2  d-flex align-center justify-center text-center'> <i className="ri-add-line"></i> Add Tolerations</button>
                        </div>
                }
                <Dialog
                    open={state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClickClose}
                    className={classes.dialog_root + " " + " integrations-dialog"}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className={classes.cardHeader}>
                        <p>Add Tolerations</p>
                        <button className="btn btn-transparent" onClick={handleClickClose}>
                            <span className='ri-close-line ' style={{ fontSize: 20, color: '#fff' }}></span>
                        </button>
                    </div>
                    <div className={classes.cardBody}>
                        <Grid container>
                            <Grid item lg={12}>
                                <div className='card-add-service' style={{ margin: '10px', boxShadow: 'none', paddingBottom: '0px' }}>
                                    <div className="card-header input-component-mb-0">
                                        <p>
                                            <span className="font-13 ">Configure Tolerations&nbsp;</span>
                                            <span className="font-11 text-grey-72">(You can Configure multiple tolerations)</span>
                                        </p>
                                    </div>
                                    <div className='card-body pd-10'>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4}>
                                                <Input
                                                    type="text"
                                                    name="key"
                                                    label="Key"
                                                    placeholder="Enter key"
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error} />
                                            </Grid>
                                            <Grid item lg={4}>
                                                <Input
                                                    type="select"
                                                    name="operator"
                                                    label="Select Operator"
                                                    list={operators}
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error} />
                                            </Grid>
                                            <Grid item lg={4}>
                                                <Input
                                                    type="text"
                                                    name="value"
                                                    label="Value"
                                                    placeholder="Enter Value"
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error} />
                                            </Grid>
                                            <Grid item lg={6}>
                                                <Input
                                                    type="text"
                                                    name="effect"
                                                    label="Effect"
                                                    placeholder="Enter Value"
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error} />
                                            </Grid>
                                            <Grid item lg={6}>
                                                <Input
                                                    type="text"
                                                    name="toleration_seconds"
                                                    label="Toleration Seconds"
                                                    placeholder="10"
                                                    onChangeHandler={commonFunctions.onChangeHandlerNumberTwo}
                                                    data={state.data}
                                                    error={state.error} />
                                            </Grid>
                                        </Grid>
                                    </div>


                                    <div
                                        className='d-flex align-center space-between border-top pd-10'
                                        style={{ position: '', bottom: '0px', width: '100%' }}>
                                        <button className='btn btn-outline-grey' onClick={handleClickClose}>Cancel</button>
                                        <button className='btn btn-submit' onClick={
                                            state.update_state_fn ? () => { updateCurrentObjFn(state.single_form_data) } :
                                                validateAndSaveFn

                                        }>Continue</button>
                                    </div>

                                </div>

                            </Grid>

                        </Grid>
                    </div>
                </Dialog>
            </div>
        </>
    )
}
Tolerations.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
export function getTolerationsDefaultState() {
    return {
        data: {
        },
        error: {},
        tolerations: [],
        validations: {
        }
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px',
        '& > .card-add-service': {
            borderRadius: '0px',
            border: '0px'
        },
        '& .card-add-service-head': {
            borderTop: '1px solid #e7e7eb',
            borderRadius: '0px',
        },
        '& > .card': {
            backgroundColor: '#f6f8f8',
            borderRadius: '0px',
            '& .card-footer': {
                backgroundColor: '#fff',
                justifyContent: 'flex-end'
            },
            '& .card ': {
                marginBottom: '15px',
                boxShadow: 'none',
                '& .input-component': {
                    marginBottom: '0px'
                }
            }
        }
    }
}))


function SimpleDialogAccessLevel(props) {

    const ITEM_HEIGHT = 48;
    const [openDialogue, setOpenDialogue] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const handleEditData = props.handleEditData ? props.handleEditData : () => { }
    const handleDeleteRow = props.handleDeleteRow ? props.handleDeleteRow : () => { }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    function deleteSucess() {
        setRefresh(true)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <>
            <button className="btn btn-transparent d-flex align-center"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ marginLeft: 'auto', display: 'block' }}>
                    <i className="ri-more-2-fill mr-5 font-18" ></i>
                    {/* <SettingsIcon fontSize="large" className="mr-5" /> Settings <KeyboardArrowDownIcon fontSize="large" className="ml-5" /> */}
            </button>

            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >

                <MenuItem className="menu-item font-12 text-grey-83" onClick={handleEditData}>
                    <span className="ri-edit-line text-anchor-blue" style={{fontSize:"24px"}}></span> &nbsp;Edit
                </MenuItem>
                <MenuItem className="menu-item font-12 text-grey-83" onClick={handleDeleteRow}>
                    <i className={`ri-delete-bin-line`} fontSize="default" style={{ color: 'rgb(255, 137, 105)' }}></i> &nbsp;Delete
                </MenuItem>
            </Menu>
        </>
    );
}
SimpleDialogAccessLevel.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }