import React from 'react';
import PropTypes from 'prop-types';
import '../assets/ErrorStyleSheet.css';
import { IsSuperUser } from '../util/security';

const ErrorPage = (props) => {

  const is_unautherized= props.is_unauthorized;
console.log(props,"propspropspropsprops")
    return (
      <div className="main-container-error" style={{ height: 'calc(100vh - 110px)' }}>
        <div className="svg">
          <h1>{is_unautherized ? 401 : 404} </h1>
          <div className="contant_box_404">
              <h3 className="h2">
              {is_unautherized ? "Unauthorized" : "Look like you're lost"}
              </h3>
              <p>{is_unautherized ? "You are not authorized for this route!" : "the page you are looking is not avaible!"}</p>
            <a href={IsSuperUser()?"/ClusterDashboard":"/ApplicationDashboard"} className="main-nav" style={{color:'#fff'}}>Go to Dashboard</a>
          </div>
      </div>
     
  
</div>
)
}

ErrorPage.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};


export default ErrorPage;