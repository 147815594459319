import React from 'react';
import Grid from '@mui/material/Grid';;
import { makeStyles } from '@mui/styles'; 
import BasicInfo from './BasicInfo';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
const AddClusterFlow = props => {
    const { cluster_id } = useParams();
    const classes = useStyles();
    return (
        <>
            <Grid container className="pd-20" justifyContent='center' style={{ backgroundColor: '#fff', height:'calc( 100vh - 70px )' }}>
                <div className={classes.main}>
                    <BasicInfo cluster_id={cluster_id} />
                </div>
            </Grid >
        </>
    )
}
export const Heading = () => {
    return (
        <>
            <PageHeader
                heading="Onboard your kubernetes Cluster"
                imgIcon={"/images/cluster-head.svg"}
                icon={null}
                subHeading={<>
                    Onboarding an existing cluster involves registering the existing cluster with BuildPiper.<br />To register the cluster we need the kube-config Yaml file along with the ability to communicate with the cluster.&nbsp;
                    <Link to={"/cluster-permissions"}>
                        <span className='font-12 btn-lite-blue font-weight-500'>View required Permissions</span>
                    </Link>
                </>}
                primaryButton={null}
                commonDivMargin={true}
            />
        </>
    );
}

export default AddClusterFlow;

const useStyles = makeStyles(theme => ({
    main: {
        //backgroundColor: "#FFFFFF",
    },
    heading: {
        display: "flex",
        gap: "12px",
        padding: "24px 20px 26px 20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "6px",
    },
}))