import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { Tooltip } from '@mui/material';
import InvokeApi from '../../../../../../util/apiInvoker';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';

const DeploymentStrategyOverview = (props) => {
    const prev_state = props.prev_state;
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const [showLoading, setShowLoading] = useState(false);
    const [state, setState] = useState({
        canary_data: []
    });
    const extraProps = props.extraProps;
    const application_id = extraProps.application_id;
    const service_id = extraProps.service_id;
    const auto_approval_flag = extraProps.auto_approval_flag;
    const component_env_id = extraProps.component_env_id;
    useEffect(() => {
        fetchCanaryTemplatesInfo();
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state, application_id])
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);


    function fetchCanaryTemplatesInfo() {

        var requestInfo = {
            endPoint: GenerateURL({ application_id: application_id }, properties.api.canary_template_listing),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, canaryTemplatesInfoFetchSuccess, canaryTemplatesInfoFetchFailure);

        setState(new_state => ({
            ...new_state,
            data_loading: true,
        }));
    }

    function canaryTemplatesInfoFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            data_loading: false,
            canary_data: data.results
        }));
    }
    function canaryTemplatesInfoFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            data_loading: false,
            error_msg: error,
        }));
    }
    return (
        <div className="section-service-overview">
            <div className="d-flex align-center space-between">
                <div className="overview-heading">
                    Rolling Update
                </div>
                {is_permitted ?

                    state.status == "DRAFT" ?
                        <Link
                            to={{
                                pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/cd/${cd_id}/edit`,
                                search: `?selectedTabOrder=8`,
                            }}
                        >
                            <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                        </Link>
                        :
                        <EditDetailsConfirmationDialog
                            application_id={application_id}
                            service_id={service_id}
                            auto_approval_flag={auto_approval_flag}
                            component_env_id={component_env_id}
                            selectedTabOrder={8}
                            cd_data={prev_state}
                            selected_cd_id={extraProps?.selected_cd_data?.id}
                        />

                    : <Tooltip title="You are not allowed to perform this action" >
                        <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                    </Tooltip>}

            </div>
            <div className="overview-single-card" style={{ marginTop: '15px', marginBottom: '15px ' }}>
                <div className="overview-card-heading-section">
                    <div className="text__box">
                        <p className="overview-card-value-text text-uppercase">
                            Deployment Strategy: Rolling Update
                        </p>
                        {/* <p className="no-data__msg ">NA</p> */}

                    </div>
                </div>
                <div className="overview-card-value-access" style={{ gap: '0px 20px' }}>
                    <p className="overview-card-value-text">
                        Max Surge:&nbsp;

                        {state.env_cd_deployment_strategy ?
                            <span className="overview-card-value-text">
                                {state.env_cd_deployment_strategy.max_surge}
                            </span> :
                            <span className="no-data__msg ">N/A</span>}

                    </p>
                    <p className="overview-card-value-text">
                        Max Unavailable:&nbsp;

                        {state.env_cd_deployment_strategy ?
                            <span className="overview-card-value-text">
                                {state.env_cd_deployment_strategy.max_unavailable}
                            </span> :
                            <span className="no-data__msg ">N/A</span>}

                    </p>
                    <p className="overview-card-value-text">
                        Revision History Limit:&nbsp;
                        {state.env_cd_deployment_strategy ?
                            <span className="overview-card-value-text">
                                {state.env_cd_deployment_strategy.revision_history_limit}
                            </span> :
                            <span className="no-data__msg ">N/A</span>}
                    </p>
                    <p className="overview-card-value-text">
                        Termination Grace Period:&nbsp;
                        {state.env_cd_deployment_strategy ?
                            <span className="overview-card-value-text">
                                {state.env_cd_deployment_strategy.termination_grace_period}
                            </span> :
                            <span className="no-data__msg ">N/A</span>}
                    </p>
                    <p className="overview-card-value-text">
                        Progress Deadline:&nbsp;
                        {state.env_cd_deployment_strategy ?
                            <span className="overview-card-value-text">
                                {state.env_cd_deployment_strategy.progress_deadline}
                            </span> :
                            <span className="no-data__msg ">N/A</span>}
                    </p>
                    <p className="overview-card-value-text">
                        Minimum Ready:&nbsp;
                        {state.env_cd_deployment_strategy ?
                            <span className="overview-card-value-text">
                                {state.env_cd_deployment_strategy.minimum_ready}
                            </span> :
                            <span className="no-data__msg ">N/A</span>}
                    </p>
                    <p className="overview-card-value-text">
                        Deployment Rollback Validation:&nbsp;
                        {state.deployment_rollback_validation ?
                            <span className="overview-card-value-text">
                                Enabled
                            </span> :
                            <span className="overview-card-value-text">
                                Disabled
                            </span>}
                    </p>
                </div>
                {
                    state.env_cd_deployment_strategy && state.env_cd_deployment_strategy.release_template_id ?
                        <>
                            <div className="pd-10 bg-f7-fc d-flex align-center space-between border-top border-bottom">
                                <span className="font-12" style={{ color: '#666' }}>
                                    Canary Deployment
                                </span>
                                <span className={state.env_cd_deployment_strategy && state.env_cd_deployment_strategy.release_template_id ? "overview-chip-yes" : "overview-chip-no"} >
                                    {state.env_cd_deployment_strategy && state.env_cd_deployment_strategy.release_template_id ? "Yes" : "No"}
                                </span>
                            </div>
                            {state.env_cd_deployment_strategy && state.env_cd_deployment_strategy.release_template_id ?
                                state.canary_data.map(item => (
                                    item.id == state.env_cd_deployment_strategy.release_template_id ?
                                        <div className="card box-shadow-none" style={{ border: 'none' }}>
                                            <div className="card-header font-12 border-bottom bg-f7-fc" style={{ paddingBottom: 10, borderTop: 'none' }}>
                                                <span>Template Name: <span className="text-grey-72">{item.name}</span></span>
                                            </div>
                                            <div className="card-body">
                                                <div className="canary_template_row">
                                                    {item.phase_release_template.map(elem => (
                                                        <span className="row_canary">
                                                            <div className="bar-grid">
                                                                <p className="font-12">{elem.name}</p>
                                                                <p className="" style={{ fontSize: 34, fontWeight: 300, color: '#004085' }}>{elem.traffic_shift_to_new_deployment_in_pct}%</p>
                                                            </div>
                                                            <div className="arrow_icon">
                                                                <ArrowForwardIcon style={{ color: "#fea010", fontSize: 24 }} />
                                                            </div>
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div> : null
                                ))
                                : <div className="d-flex align-center " style={{ coloe: '#666', height: 120, justifyContent: 'center' }}>
                                    <p className="font-12 text-center">No Data Available</p></div>}
                        </>
                        : null
                }
            </div>
        </div>
    )
}

DeploymentStrategyOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default DeploymentStrategyOverview;