import React, { createContext, useContext, useState } from 'react'

export const ReleasePackageContext = createContext();


export const ReleasePackageContextProvider = ({children}) => {
    const [typeTag,setTypeTag] = useState({});
    const [skipable,setSkippable] = useState(false);
    const [selectedApp,setSelectedApp] = useState(null);
    const [appList,setAppList] = useState({});
    const [configureChangeSet,setConfigureChangeSet] = useState([]);
    const [releaseVersion,setReleaseVersion] = useState(null)
    const [completeConfigureChangeSetData,setCompleteConfigureChangeSetData] = useState([]);

    const resetContext = () => {
        setTypeTag({});
        setSkippable(false);
        setSelectedApp(null);
        setAppList({});
        setConfigureChangeSet([]);
        setReleaseVersion(null);
        setCompleteConfigureChangeSetData([]);
    };

    return (
        <ReleasePackageContext.Provider value={{ 
            typeTag,
            setTypeTag,
            skipable,
            setSkippable,
            configureChangeSet,
            setConfigureChangeSet,
            selectedApp,
            setSelectedApp,
            appList,
            setAppList,
            releaseVersion,
            setReleaseVersion,
            completeConfigureChangeSetData,
            setCompleteConfigureChangeSetData,
            resetContext
            }}>
            {children}
        </ReleasePackageContext.Provider>
    )
}

export const useReleasePackageContext = ()=>{
    return useContext(ReleasePackageContext);
}
