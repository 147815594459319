import React, { useEffect, useState } from 'react'
import { styled } from "@mui/system";
import { Link, Navigate, useParams } from 'react-router-dom';
import GenerateURL, { GenerateSearchURL } from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi from '../../util/apiInvoker';
import SetAsDefaultDialog from '../jobTemplates/components/SetAsDefaultDialog';
import { ValidateDataSet } from '../../util/Validator';
import { Alert, Snackbar } from '@mui/material';
import SearchBar from '../../components/SearchBar';
import DatabaseTemplateCard from './DatabaseTemplateCard';
import Pagination from '../../components/Pagination';

const DatabaseTemplateListing = () => {
    const [state, setState] = useState({
        data_loading: "SUCCESS",
        count: 1,
        db_metric_template_listing: [],
        total_page: "",
        curr_page: "",
        refresh_count: 1,
        add_db_template: false,
        child_inherits: {
            update_default_job_template: {
                validateForm: () => {
                    return { valid: true };
                },
                getState: () => {
                    return {};
                },
                getData: () => {
                    return {};
                },
            },
        },
        default_template_created: false
    });
    const [filter, setFilter] = useState({ name: "" });
    const [defaultTemplate, setDefaultTemplate] = useState(null);
    const { application_id } = useParams();
    useEffect(() => {
        fetchDbMetricTemplatesInfo(true);
    }, [application_id, state.count, filter, state.refresh_count]);

    function fetchDbMetricTemplatesInfo(isLoadingNeeded) {
        let myEndPoint = GenerateURL(
            { application_id: application_id },
            properties.api.db_metric_template_listing
        );

        var requestInfo = {
            endPoint: GenerateSearchURL(filter, myEndPoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        InvokeApi(
            requestInfo,
            dbTemplatesInfoFetchSuccess,
            dbTemplatesInfoFetchFailure
        );

        isLoadingNeeded && setState((new_state) => ({
            ...new_state,
            data_loading: "LOADING",
        }));
    }

    function dbTemplatesInfoFetchSuccess(data) {
        let default_template = data.results.find((template) => template.default == true);
        setDefaultTemplate(default_template);
        setState((new_state) => ({
            ...new_state,
            data_loading: "SUCCESS",
            db_metric_template_listing: data.results,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Math.ceil(Number(data.count / 10)),
            curr_page: 1,
        }));
    }
    function dbTemplatesInfoFetchFailure(error, exception) {
        setState((new_state) => ({
            ...new_state,
            data_loading: "FAILED",
            error: error,
        }));
    }

    // prev db template listing fetch

    function fetchPrevDbMetrciInfo(data, url) {
        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        setState((new_state) => ({
            ...new_state,
            current: requestInfo.endPoint,
            data_loading: "LOADING",
        }));
        InvokeApi(
            requestInfo,
            DbMetricPrevInfoFetchSuccess,
            DbMetricPrevInfoFetchFailure
        );
    }

    function DbMetricPrevInfoFetchSuccess(data) {
        let default_template = data.results.find((template) => template.default == true);
        setDefaultTemplate(default_template);
        setState((new_state) => ({
            ...new_state,
            data_loading: "SUCCESS",
            db_metric_template_listing: data.results,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            page_start: state.page_start - data.results.length,
            curr_page: Number(state.curr_page - 1),
        }));
    }

    function DbMetricPrevInfoFetchFailure(error, exception) {
        setState((new_state) => ({
            ...new_state,
            data_loading: "FAILED",
            error: true,
        }));
    }

    //start of page code----------------------------------------------

    function fetchPageDbMetricTemplateInfo(enteredPageNumber) {
        let myEndPoint = GenerateURL(
            { application_id: application_id },
            properties.api.add_job_template
        );

        var requestInfo = {
            endPoint: GenerateSearchURL(filter, myEndPoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        if (enteredPageNumber > 1) {
            requestInfo.endPoint =
                requestInfo.endPoint +
                "?limit=10&name=&offset=" +
                (enteredPageNumber - 1) * 10;
        }

        setState((new_state) => ({
            ...new_state,
            current: requestInfo.endPoint,
            data_loading: "LOADING",
        }));

        var current_page = enteredPageNumber;

        InvokeApi(
            requestInfo,
            (response) => {
                DbTemplatePageInfoFetchSuccess(response, current_page)
            }
            ,
            DbTemplatePageInfoFetchFailure
        );
    }

    function DbTemplatePageInfoFetchSuccess(data, count) {
        let default_template = data.results.find((template) => template.default == true);
        setDefaultTemplate(default_template);
        setState((new_state) => ({
            ...new_state,
            data_loading: "SUCCESS",
            db_metric_template_listing: data.results,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            page_start: state.page_start - data.results.length,
            curr_page: Number(count)
        }));
    }

    function DbTemplatePageInfoFetchFailure(error, exception) {
        setState((new_state) => ({
            ...new_state,
            data_loading: "FAILED",
            error: true,
        }));
    }

    //end of page code------------------------------------------------

    function fetchNextDbMetricTemplateInfo(data, url) {
        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        InvokeApi(
            requestInfo,
            DbTemplateNextInfoFetchSuccess,
            DbTemplateNextInfoFetchFailure
        );

        setState((new_state) => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            data_loading: "LOADING",
        }));
    }

    function DbTemplateNextInfoFetchSuccess(data) {
        let default_template = data.results.find((template) => template.default == true);
        setDefaultTemplate(default_template);
        setState((new_state) => ({
            ...new_state,
            data_loading: "SUCCESS",
            db_metric_template_listing: data.results,
            next: data.next ? properties.api.baseURL + data.next : null,
            page_start: state.page_start + data.results.length,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            curr_page: Number(new_state.curr_page + 1),
        }));
    }
    function DbTemplateNextInfoFetchFailure(error, exception) {
        setState((new_state) => ({
            ...new_state,
            data_loading: "FAILED",
            error: true,
        }));
    }

    function handleAddDatabaseMetricTemplate() {
        setState((new_state) => ({
            ...new_state,
            add_db_template: true,
        }))
    }

    const handleSearchChange = (filter_name) => {
        setFilter(filter_name);
        setState((new_state) => ({
            ...new_state,
            search_query_name: filter_name
                ? filter_name.name
                    ? filter_name.name
                    : ""
                : "",
        }));
    };

    const createDefaultTemplate = () => {
        const defaultTemplateUrl = GenerateURL({ application_id: application_id }, properties.api.create_default_job_template)

        PostData(defaultTemplateUrl, {},
            (response) => {
                console.log(response, 'create_response_jtb')
                setState(prevState => ({
                    ...prevState,
                    default_template_created: true
                }))
                fetchJobTemplatesInfo(false);
            },
            (error) => {
                console.log(error, 'error_jbt_ssd');
            }
        )
    }

    function ValidateAndSave(template_id) {
        var result = ValidateDataSet(state.data, state.validations);
        var child_states = inherits.getState ? inherits.getState() : null;
        var children_vaidation_result = inherits.validateForm
            ? inherits.validateForm()
            : { valid: true };
        var update_default_temp_child = child_states.update_default_job_template;
        var post_url = GenerateURL(
            { application_id: application_id, template_id: template_id },
            properties.api.edit_template
        );

        if (result.valid && children_vaidation_result) {

            var selected_job_object = state.db_metric_template_listing?.find(job => job.id == template_id)

            var job_template_data = {
                ...selected_job_object,
                default: true,
            };

            PostData(post_url, job_template_data, (job_template_data) => { postSuccess(job_template_data) }, postFail);

            setState((new_state) => ({
                ...new_state,
                //data_loading: "LOADING",
            }));
        }
    }

    const onCloseAlert = () => {
        setState(prevState => ({
            ...prevState,
            default_template_created: false
        }))
    }

    function refresh() {
        setState((new_state) => ({
            ...new_state,
            count: new_state.count + 1,
        }));
    }

    function sendApprovalRequest(id) {
        var post_url = GenerateURL({}, properties.api.approval_request);

        var post_obj = {
            "name": "project_job_templates",
            "entity_id": id
        }

        PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
        setState(new_state => ({
            ...new_state,
            loading: true,
            error_msg: null,
            open: true
        }));
    }

    function sendApprovalRequestSuccess(response) {

    }

    function sendApprovalRequestFailed(error) {

    }

    function confirmForApproval(id) {
        var post_url = GenerateURL({}, properties.api.confirm_approval);

        var post_obj = {
            "name": "project_job_templates",
            "entity_id": id
        }

        PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
        setState(new_state => ({
            ...new_state,
            loading: true,
            error_msg: null,
            open: true
        }));
    }

    function confirmApprovalRequestSuccess(response) {
        refreshState()
        var msg = response.detail
        setState(new_state => ({
            ...new_state,
            approval_confirm_success_msg: msg,
        }));
    }

    function confirmApprovalRequestFailed(error) {
        var error = error.toString()
        setState(new_state => ({
            ...new_state,
            approval_failed_msg: error
        }));
    }

    const setDefault = (data) => {
        console.log(data);
    }

    function postSuccess(job_template_data) {
        //refresh();
        setDefaultTemplate(job_template_data);
        setState((new_state) => ({
            ...new_state,
            data_loading: "SUCCESS",
            canRedirect: true,
            error_msg: null,
        }));
    }

    function postFail(response) {
        setState((new_state) => ({
            ...new_state,
            error_msg: response.toString(),
            //data_loading: "FAILED",
        }));
    }


    return (
        <>
            {
                state.add_db_template &&
                <Navigate
                    to={`/application/${application_id}/database-templates/add/`}
                    state={{}}
                />
            }
            <DbMetricTemplate>
                <div className='header'>
                    <div className='left-part'>
                        <div className='icon-div'>
                            <span className='ri-database-2-line' style={{ color: "#0086FF", fontSize: "24px" }}></span>
                        </div>
                        <div className='header-detail'>
                            <p style={{ fontFamily: "Montserrat", fontSize: "18px", fontWeight: "600", lineHeight: "21.94px", color: "#505050" }}>DB Metric Templates <span className='ri-information-line' style={{ color: "#505050", fontSize: "20px", fontWeight: "400" }}></span></p>
                        </div>
                    </div>
                    <div className='right-part'>
                        <button className='btn btn-primary' onClick={handleAddDatabaseMetricTemplate}><span className='ri-add-line' style={{ marginRight: "4px" }}></span>ADD TEMPLATES</button>
                    </div>
                </div>
                {state.db_metric_template_listing &&
                    state.db_metric_template_listing.length > 0 ?
                    <>
                        <div className="w-100 d-flex align-center space-between mb-24" style={{ height: "40px" }}>
                            <div style={{ width: "37%", }}>
                                <SearchBar
                                    search_data={filter}
                                    default_filter={{
                                        name: "name",
                                        label: "Search for db metric templates",
                                    }}
                                    search_call_back={handleSearchChange}
                                    clear_search_callback={() => setFilter({ name: "" })}
                                />
                            </div>
                            {/* <div>
                            <SetAsDefaultDialog
                                varient={"jobtemplatelisting"}
                                onCreateDefaultTemplate={createDefaultTemplate}
                                application_id={application_id}
                                changeDefaultJobTemplate={ValidateAndSave}
                                inherits={state.child_inherits.update_default_job_template}
                                template_list={
                                    state.db_metric_template_listing ? state.db_metric_template_listing : []
                                }
                            />
                        </div> */}
                        </div>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={state.default_template_created}
                            onClose={onCloseAlert}
                            autoHideDuration={2000}>
                            <Alert severity="success" variant="filled">
                                {"Default project job templates created if not exists"}
                            </Alert>
                        </Snackbar>
                    </>
                    : null}
                {
                    state.db_metric_template_listing && state.db_metric_template_listing.length > 0 ?
                        (
                            <div className="w-100" style={{
                                gap: "16px", display: "grid",
                                gridTemplateColumns: "1fr 1fr"
                            }}>
                                {state.db_metric_template_listing.map((item, index) => (
                                    <DatabaseTemplateCard
                                        data={item}
                                        refreshFn={refresh}
                                        sendForApproval={sendApprovalRequest}
                                        selected_job_template_id={defaultTemplate?.id}
                                        confirmForApproval={confirmForApproval}
                                        key={index} setDefault={setDefault}
                                        changeDefaultJobTemplate={ValidateAndSave}
                                    />
                                ))}
                            </div>

                        )
                        :
                        state.search_query_name ? (
                            <div
                                className="main-container-error"
                                style={{ height: "60vh", margin: "auto" }}
                            >
                                <div className="svg">
                                    <div className="servicePanel">
                                        <div className="blank-div">
                                            <div className="blank-div-text">
                                                No Templates found with this name :{" "}
                                                {state.search_query_name}
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => setFilter({ name: "" })}
                                                    className="btn btn-submit"
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="main-container-error"
                                style={{ height: "60vh", margin: "auto" }}
                            >
                                <div className="svg">
                                    <div className="servicePanel">
                                        <div className="blank-div">
                                            <div className="blank-div-text">
                                                No Templates associated with this Application
                                            </div>
                                            <div>
                                                <Link
                                                    to={
                                                        "/application/" + application_id + "/job-template/add"
                                                    }
                                                    className="btn btn-submit"
                                                >
                                                    <div>Add DB Metric Template</div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                {state.db_metric_template_listing &&
                    state.db_metric_template_listing.length > 0 ?
                    <div className="mb-24">
                        <Pagination
                            current_page={state.curr_page}
                            current_page_count={state.curr_page}
                            total_count={state.total_page}
                            next={state.next}
                            previous={state.previous}
                            on_previous_click={() => {
                                fetchPrevDbMetrciInfo(null, state.previous);
                            }}
                            on_next_click={() => {
                                fetchNextDbMetricTemplateInfo(null, state.next);
                            }}
                            on_pageNumber_click={(pageNumber) => {
                                fetchPageDbMetricTemplateInfo(pageNumber);
                            }}
                        />
                    </div> : null}

            </DbMetricTemplate>
        </>
    )
}

export default DatabaseTemplateListing

const DbMetricTemplate = styled("div")({
    padding: '20px 20px 0px 20px',
    // marginTop: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    "& .header": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& .left-part": {
            display: "flex",
            alignItems: "center",
            gap: "16px",

            "& .icon-div": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ffffff",
                height: "56px",
                width: "56px",
                borderRadius: "8px",
            }
        }
    },


})