import React, { useEffect, useState } from 'react';
import '../assets/ErrorStyleSheet.css';
import { IsSuperUser } from '../util/security';
import { useLocation } from 'react-router-dom';


const ErrorPage = props => {

    const location = useLocation();
    const [state, setState] = useState({ redirect: false })
    
    useEffect(() => {
        // if (location.pathname === '/permission-not-found') {
        //     setState({ redirect: true })
        //     console.log(props, 'ErrorPageNotFound component rendered');
        // }
    }, [location]);
    return (
        
            
            <div className="main-container-error" style={{ height: 'calc(100vh - 110px)' }}>
                <div className="svg">
                    <h1>{403}</h1>
                    <div className="contant_box_404">
                        <h3 className="h2">
                            {"Permission Not Found"}
                        </h3>
                        <p>It seems you don't have permission to view this page.<br />please contanct to super admin for more details.</p>
                        <a href={IsSuperUser() ? "/ClusterDashboard" : "/ApplicationDashboard"} className="main-nav" style={{ color: '#fff' }}>Go to Dashboard</a>
                    </div>
                </div>
            </div>
        
    )
}

export default ErrorPage;