import React, { useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import '../../../../../assets/style.css';
import {Input} from '../../../../../components/genericComponents/Input';
import Grid from '@mui/material/Grid';;
import PageHeader from '../../Components/PageHeader';
import ParseFile from "../../../../../util/FileParser";
const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1 ,
      marginTop:'3rem'  
    }
  }));


const access_level_list = [
  {
    label:"Is It Public",
    value:"PUBLIC"
  },
  {
    label:"Is It Protected",
    value:"PROTECTED"
  },
  {
    label:"Is It Private",
    value:"PRIVATE"
  }
];

const Networking =(props) => {
  const classes = useStyles();
  const data = props.data;
  const error = props.error;
  const update_data_callback = props.update_data_callback;
  const cert_list = props.cert_list;
  const private_access_name=props.private_access_name;
  const [state,setState] = useState({prestine:true})
  data.expose_url = !state.prestine ? data.expose_url : data.expose_url ? data.expose_url: private_access_name;
  
  console.log("data",data);

  const onParseComplete = (file_data)=>{
    update_data_callback("custom_ingress_manifest",file_data);
  }

  const onError = (error) => {
    console.log("error",error);
  }

  const onChangeHandler = (e) => {
    switch(e.target.name){
      case "custom_ingress_enabled":
        update_data_callback(e.target.name,!data.custom_ingress_enabled);
        break;
      case "custom_ingress_manifest":
        ParseFile(e,onParseComplete,onError);
        break;
      case "tls_cert_enabled":
        if(data.access_level !="PRIVATE"){
          update_data_callback(e.target.name,!data.tls_cert_enabled);
        }
        break;
      case "tls_cert_detail_id":
        update_data_callback(e.target.name,Number(e.target.value));
        break;
      case "access_level":
        if(e.target.value == "PRIVATE"){
          update_data_callback("tls_cert_enabled",false);
        }
        update_data_callback(e.target.name,e.target.value);
        break;
      default :
        update_data_callback(e.target.name,e.target.value);
    }
    setState({
      prestine:false
    })
  }

    return(
      <>
      <PageHeader title="Service Access Level Configuration" service_name={props.service_name} env_name={props.env_name} sub_env_name={props.sub_env_name} /> 
      <div className={classes.formDiv}>

      <Grid container >
            <Grid lg={12} direction="row">
            <div className='card' style={{margin:'1.5rem 0rem', boxShadow:'none'}}>
            <div className='heading' >
            <Input 
                type="switch" 
                name="custom_ingress_enabled" 
                label="Do you have custom ingress manifest?"
                onChangeHandler={onChangeHandler} 
                data={data} 
                error={error}/>
            </div>
              {
                data.custom_ingress_enabled ? 
                <div className="card-body" >
                  <div style={{padding:'8px'}}>
                      <Input
                    type="file"
                    name="custom_ingress_manifest"
                    label="Upload your ingress manifest"
                    onChangeHandler={onChangeHandler}
                    data={data}
                    error={error}/>
                  </div>
                
                <Grid lg={12} direction="row">
                    <div className='section'>
                        <Input 
                        type="text"
                        name="default_port" 
                        label="Port"
                        mandatorySign
                        placeholder="4001"
                        onChangeHandler={onChangeHandler} 
                        data={data} 
                        error={error}/>
                    </div>
                  </Grid>
                </div>
                :null
                
              }
            </div> 
            </Grid>
                
          </Grid>

        <Grid container justify="center">
            <div className='fromClass'>
            <Grid lg={12}>
            
            {
              !data.custom_ingress_enabled ? 
              <Grid container justify="center"  className="card-add-service">
              <Grid lg={12} direction="row" className="card-add-service-head">
              <div className="head-div">
                <h4 className='mainHeading'>Choose Access Level 
                </h4>
                <Input 
                type="radio" 
                name="access_level" 
                list={access_level_list} 
                onChangeHandler={onChangeHandler} 
                data={data} 
                error={error}/>
              </div>
            </Grid> 
                  {data.access_level !== "PRIVATE" ?
                  <>
                  <Grid lg={4} direction="row">
                    <div className='section'>
                      <Input
                        type="text" 
                        placeholder="URL Format: service name/env name/sub env name" 
                        name="expose_url" 
                        mandatorySign
                        label="What is the URL" 
                        error={error} 
                        onChangeHandler={onChangeHandler} 
                        data={data}/>
                    </div>
                  </Grid>
                  <Grid lg={4} direction="row">
                    <div className='section'>
                      <Input
                      type="text" 
                      placeholder="Add the path to be exposed"
                      mandatorySign 
                      name="expose_path" 
                      label="What is the Expose Path?" 
                      error={error} 
                      onChangeHandler={onChangeHandler} 
                      data={data.expose_path ? data:{expose_path:""}}/>
                    </div>
                  </Grid>
                  <Grid lg={4} direction="row">
                    <div className='section'>
                        <Input 
                        type="text"
                        name="default_port" 
                        label="Port"
                        mandatorySign
                        placeholder="4001"
                        onChangeHandler={onChangeHandler} 
                        data={data} 
                        error={error}/>
                    </div>
                  </Grid>
                  
                  </>:<>
                  <Grid lg={8} direction="row">
                    <div className='section'>
                      <Input
                        type="text"
                        name="expose_url"
                        label="Service is accessible privately via following URL:"
                        error={error} 
                        mandatorySign
                        onChangeHandler={onChangeHandler} 
                        data={data}/>
                    </div>
                  </Grid>
                  <Grid lg={4} direction="row">
                    <div className='section'>
                        <Input 
                        type="text"
                        name="default_port" 
                        label="Port"
                        mandatorySign
                        placeholder="4001"
                        onChangeHandler={onChangeHandler} 
                        data={data} 
                        error={error}/>
                    </div>
                  </Grid>
                  
                  </>
                }
            </Grid>:null
            }

            {
              !data.custom_ingress_enabled ? 
              <Grid container justify="left">
                  <Grid lg={12} >
                      <div className="section-m-t-b">
                      <div className='card' style={{boxShadow:'none'}}>
                    <Input 
                      type="switch" 
                      name="tls_cert_enabled" 
                      label="Is Transport Layer Security (TLS) Required?"
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>
                    <Grid lg={12} direction="row">
                    {
                      data.tls_cert_enabled ?
                      <div className="card-body">
                        <Input 
                        type="select" 
                        name="tls_cert_detail_id" 
                        label="Choose Certificate"
                        list={cert_list} 
                        onChangeHandler={onChangeHandler} 
                        data={data} 
                        error={error}/>
                        </div>:null      
                    }
                        </Grid>
                        </div>
                        </div>
                  </Grid>
                    </Grid>  :null
            }

        </Grid>
            </div>
        </Grid>
    </div> 
    </>
    );
  };
  
  Networking.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

  export default Networking;
  