import React, { useEffect, useState } from 'react'
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import DynamicFilterField from './DynamicFilterField';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import Grid from '@mui/material/Grid';;
import properties from '../../../../properties/properties';
import InputSkeleton from '../../../../components/genericComponents/Skeletons/InputSkeleton';

const DownloadDocuments = ({pipelineVars, 
    jiraIssueKeys, 
    jobStepOrderOutputVars,
    dynamicValidationFormError,
    setParentState, 
    parentState,...props}) => {

    const inherits = props.inherits;
    const prev_state = props.prev_state;
    const [state, setState] = useState(getDownloadDocumentsDefaultState(prev_state));

    const commonFunctions = getCommonFunctions(state, setState, inherits);

    function fetchJiraIntegration(){
        const requestInfo = {
          endPoint: GenerateURL(
            {},
            properties.api.ticketing_system+"?all=true"
          ),
          httpMethod: "GET",
          httpHeaders: { "Content-Type": "application/json" },
        }
        setState((prev_state)=>({
          ...prev_state,
          loading: true,
        }))
        InvokeApi(requestInfo, fetchJiraIntegrationSuccess, fetchJiraIntegrationFailed);
    }
    function fetchJiraIntegrationSuccess(response){
        console.log("kjjdsh",response);
        // let integrationTypesList = [
        //   ...new Map(response.map(item => [item.integration_type, {
        //       label: item.integration_type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
        //       id: item.integration_type
        //   }])).values()
        // ];
        
        let integrationList = response.map((item) =>{
          return {...item,label:item.integration_name};
        })
        setState((prev_state)=>({
          ...prev_state,
          integrationList: integrationList,
          loading: false,
        }))
    }
    
    function fetchJiraIntegrationFailed(error){
        console.log("sdjhdsj",error);
        setState((prev_state)=>({
            ...prev_state,
            loading: false,
        }))
    }

    useEffect(()=>{
        fetchJiraIntegration();
    },[])

    useEffect(() => {
        if(state.data.integration_id){
            getTicketingProjects(state.data.integration_id)
        }
    },[state.data.integration_id])

    function onChangeHandlerForKeyValue(key, value) {
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }

    function onChangeHandler(e){
        let key = e.target.name;
        let value = e.target.value;
        let data = {};
        if(key === "integration_id"){
            let integration = state.integrationList.find((integration) => integration.id == value);
            data["integration_type"] = integration.integration_type;
        }
        data[key] = value;
        setState((prev_state) =>({
            ...prev_state,
            data: {
                ...prev_state.data,
                ...data,
            },
            error: {
                ...prev_state.error,
                [key]: null,
            }
        }));

    }

    function getTicketingProjects(id){
        const requestInfo = {
            endPoint: GenerateURL(
              {},
              properties.api.load_jira_projects+`?id=${id}`
            ),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        }
        setState((prev_state)=>({
            ...prev_state,
            loading: true,
        }))
        InvokeApi(requestInfo,getTicketingProjectsSuccess,getTicketingProjectsFailed);
    }

    function getTicketingProjectsSuccess(response){
        console.log("dsjshdj",response);
        let result = response['PROJECT_LISTS']?.map((item) => ({id:item.name,label:item.name})) || [];
        
        setState((prev_state) => ({
            ...prev_state,
            integrationProjectList: result,
            loading: false,
            validations:{
                ...prev_state.validations,
                integration_project: result?.length > 0 ? [VALIDATION_TYPE_REQUIRED] : [],
            }
        }))
    }

    function getTicketingProjectsFailed(error){
        setState((prev_state) => ({
            ...prev_state,
            integrationProjectList: [],
            loading: false,
        }))
    }

    console.log("sksjsdkj",state,prev_state);

    return (
        <Grid container spacing={2} style={{padding:"6px"}}>
            <Grid item lg="12">
                {state.loading ? 
                    <InputSkeleton type={"text"} width={"100%"} height={32} style={{borderRadius: '6px'}}  />
                :   
                    <>
                    <Input
                        type="select"
                        name="integration_id"
                        list={state.integrationList || []}  // get_run_conditions_function().jira_id_reference_keys
                        label="Select Ticketing"
                        mandatorySign
                        onChangeHandler={onChangeHandler}
                        data={state.data}
                        error={state.error} />

                        <Input 
                            type="select"
                            name="integration_project"
                            list={state.integrationProjectList || []}  // get_run_conditions_function().jira_id_reference_keys
                            label="Select Project"
                            mandatorySign
                            onChangeHandler={state?.integrationProjectList ? onChangeHandler : null}
                            data={state.data}
                            error={state.error}
                        />
                        </>
                }
            </Grid>
            <Grid item lg="12">
                <Input
                    type="tagged-input"
                    placeholder="Add a file name"
                    label="Enter file names"
                    //paramsHeading="(Enter file name and press enter)"
                    name="file_names"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={onChangeHandlerForKeyValue}
                    extraDiv={<div className='color-icon-secondary font-12 line-height-1'>{"(Enter file name and press enter)"}</div>}
                />
            </Grid>
            <Grid item lg="12">
                <DynamicFilterField
                    jiraIssueKeys={jiraIssueKeys}
                    pipelineVars={pipelineVars}
                    jobStepOrderOutputVars={jobStepOrderOutputVars}
                    setParentState={setState}
                    parentState={state}
                    prev_state={prev_state}
                    dynamicValidationFormError={dynamicValidationFormError}
                    setTopParentState={setParentState}
                    topParenState={parentState}
                    showDynamicFeild= {true}
                >
                    <Input
                        type="select"
                        name="issue_list"
                        list={[]}  // get_run_conditions_function().jira_id_reference_keys
                        label="Select Tickets"
                        placeholder="Refrence key"
                        mandatorySign
                        onChangeHandler={commonFunctions.onChangeHandler}
                        data={state.data}
                        error={state.error} />
                </DynamicFilterField>
            </Grid>
        </Grid>
    )
}

export default DownloadDocuments;

function getDownloadDocumentsDefaultState(prev_state){
    return {
        data: prev_state ? {
            integration_id: prev_state.integration_id,
            file_names: prev_state?.file_names || [],
            integration_type: prev_state?.integration_type,
            integration_project: prev_state.integration_project,
        } : {
            integration_id: null,
            integration_type: null,
            file_names: [],
            integration_project: null,
        },
        error: {},
        snowMadatoryField: [],
        dynamic_fields:{},
        validations: {
            issue_list: [VALIDATION_TYPE_REQUIRED],
            integration_id: [VALIDATION_TYPE_REQUIRED],
            integration_project: []
        },
    };
}