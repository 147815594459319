import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Grid from "@mui/material/Grid";
import TopSummary from "./components/TopSummary";
import properties from "../../../properties/properties";
import SetupSummary from "./components/SetupSummary";
import HealthCheck from "./components/HealthCheck/HealthCheck";
import "./style.css";
import "../../../assets/fonts/flaticon.css";
import InvokeApi from "../../../util/apiInvoker";
import { Navigate, Redirect, useLocation } from "react-router-dom";
import GenerateURL from "../../../util/APIUrlProvider";
import { Loading } from "../../utils/Loading";
import { LoadingContainer } from "../../utils/Loading";
import { ErrorComponent } from "../../utils/Error";
import AddMsDialog from "../listing/AddMsDialog";
import EnvAndUserGroupNew from "../../serviceRevamp/components/EnvAndUserGroupNew";
import queryString from 'query-string';
import { useParams } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    backgroundColor: "#fff",
    position: "relative",
    border: '1px solid #dedede',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
    borderRadius: '8px',
    "& .top-level-header": {
      fontSize: "12px",
      padding: "0px 15px",
      backgroundColor: "#f4f4f4",
      borderBottom: "1px solid #dedede",
      "& .d-grid": {
        gridTemplateColumns: "120px 1fr",
      },
      "& .headerul": {
        justifySelf: "center",
      },
    },
    "& .subheader-cont": {
      display: "grid",
      gridTemplateColumns: "1fr 50%",
    },
    "& .card-sub-header": {
      padding: "10px 0px 0px",
    },
    "& .label-for-multiselect": {
      marginLeft: "10px",
      marginRight: "10px",
      "&:first-child": {
        marginLeft: "0px",
      },
    },
    "& .main-heading": {
      fontSize: "13px",
      marginBottom: "10px",
      fontWeight: 400,
    },
    "& .new-overview-card": {
      "& .loader-bg-card-2": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "20rem",
        position: "absolute",
      },
    },
  },
  heading: {
    margin: "1.5rem 0rem",
  },
  mainHeading: {
    fontSize: "24px",
    fontWeight: 300,
    lineHeight: 1.5,
  },
  subHeading: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#666",
    lineHeight: 1,
  },
}));

var topSummaryData = {};

const ServiceDashboardNew = (props) => {
  const location = useLocation();
  const service_data = props?.location?.state;
  var parsedQueryString = queryString.parse(location?.search);
  console.log(service_data, "parsedQueryString");
  var environment_id_querystring = parsedQueryString.env_id;
  let job_code_queryString = parsedQueryString.job_code
  const show_deployment_status = parsedQueryString && parsedQueryString.deployment_status ? true : false;
  var show_deploy_details = parsedQueryString.deploy_details ? parsedQueryString.deploy_details : false;
  // console.log('line 93', parsedQueryString, pipeline_id, execution_id);
  const { service_id, application_id } = useParams();
  console.log(service_id, application_id, "__parsedQueryString__")
  const [state, setState] = useState({
    selectedTabOrder: 1,
    loaded: false,
    data: null,
    selectedServiceEnv: null,
    post_refresh_count:1
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const updateSelectedTab = (selectedTabOrder) => {
    setState((new_state) => ({ ...new_state, selectedTabOrder: selectedTabOrder }));
  };
  var environments = {};

  useEffect(() => {
    setState({
      selectedTabOrder: 1,
      loaded: false,
      data: null,
      selectedServiceEnv: null,
      post_refresh_count:1
    });
    getService();
  },[location.pathname])

  useEffect(() => {
    console.log("running_useeffect")
    getService();
  }, [state.post_refresh_count]);


  function getService() {
    var requestInfo = {
      endPoint: GenerateURL(
        { service_id: service_id },
        properties.api.service_basic_details_get_url
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    InvokeApi(requestInfo, handleGetServiceSuccess, handleGetServiceFailure);
  }
  const handleGetServiceSuccess = (data) => {
    setServiceResponse(data)
    topSummaryData.serviceName = data.name;
    topSummaryData.appName = data.project.name;
    topSummaryData.healthStatus = "ok";
    fetchEnvList(data.id);

    setState((new_state) => ({
      ...new_state,
      loaded: true,
      topSummaryData: topSummaryData,
      serviceId: data.id,
    }));
  };

  const handleGetServiceFailure = (error) => {
    setState((new_state) => ({
      ...new_state,
      error: error,
    }));
  };

  console.log(state.error, "sbdbfdsfdsvgcvsdvcsd")

  function fetchEnvList(service_id) {
    var requestInfo = {
      endPoint: GenerateURL(
        { service_id: service_id },
        properties.api.env_data_post_url + "?all=true"
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    InvokeApi(requestInfo, onEnvListFetchSuccess, onEnvListFetchFail);
    setState((new_state) => ({
      ...new_state,
      loaded: false,
    }))
  }

  function onEnvListFetchSuccess(response) {
    var environmentsMap = getMasterEnvWiseServiceEnvList(response);
    var masterAndServiceEnvs = getMasterEnvAndServiceEnvTabList(environmentsMap);
    var default_master_service_env_tab = getDefaultMasterEnvAndEnvTabOrder(
      masterAndServiceEnvs.serviceEnvironmentsTabList,
      environment_id_querystring
    );
    console.log('masterAndServiceEnvs____', default_master_service_env_tab)

    setState((new_state) => ({
      ...new_state,
      loaded: true,
      data: {
        ...new_state.data,
        serviceTabsForMasterEnv: masterAndServiceEnvs.serviceEnvironmentsTabList,
        environmentsMap: environmentsMap,
        masterEnvTabList: masterAndServiceEnvs.masterEnvTablist,
        selectedServiceEnv: default_master_service_env_tab.selected_service_env,
        environment_id_querystring: environment_id_querystring,
        show_deploy_details: show_deploy_details,
        default_master_env_tab_order:
          default_master_service_env_tab.master_env_tab_order,
        default_service_env_tab_order:
          default_master_service_env_tab.environment_tab_order,

      },
      serviceData: response,
    }));
    handleClose()
  }
  function onEnvListFetchFail(error) {
    setState((new_state) => ({
      ...new_state,
      loaded: true,
      error: error,
    }));
    handleClose();
  }


  function getServiceAndSelectedEnv() {
    var details = {
      applicationId: application_id,
      serviceId: state.serviceId,
      service_id: service_id,
      application_id: application_id,
    };
    return details;
  }
  const classes = useStyles();
  const addEnvLink =
    "/application/" +
    application_id +
    "/service/" +
    state.serviceId +
    "/env/add";
  const [canRedirect, setRedirect] = useState(false);
  function onDeletesuccess() {
    setRedirect(true);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const setselectedServiceEnv = (data) => {
    setState(prevState => ({
      ...prevState,
      selectedServiceEnv: data
    }))
  }

  console.log(state.selectedServiceEnv, 'mnp_00psssl')
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddEnvDialog, setOpenAddEnvDialog] = useState(false)
  const [openEditEnvDialog, setEditAddEnvDialog] = useState(false)
  const [addedServiceResponse, setServiceResponse] = useState(null)
  const [variant, setVariant] = useState('ADD');
  const handleToggle = () => {
    setOpenDialog(!openDialog)
  }
  const refreshState = () => {
    getService();
    handleToggle();
  }
  const refreshStatefn = () => {
    setState(prevState => ({
      ...prevState,
      post_refresh_count: prevState.post_refresh_count+1
    }))
  }
  const handleOpenAddEnvDialog = ()=>{
    setOpenAddEnvDialog(true)
  }
  const handleOpenAddEnvDialogOnly = ()=>{
    setEditAddEnvDialog(true)

  }
  const handleCloseAddEnvDialog = () => {
    setOpenAddEnvDialog(false)
  }
  const handleCloseEditEnvDialog = () => {
    setEditAddEnvDialog(false)
  }
  
  return (
    <>
      {canRedirect ? (
        <Navigate to={"/application/" + application_id + "/service/listing"} />
      ) : state.loaded ? (
        <div className={classes.root}>
          <Grid container>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <TopSummary
                anchorEl={anchorEl}
                isMenuOpen={open}
                handleMenuClick={handleClick}
                onCloseMenu={handleClose}
                refresh={onDeletesuccess}
                handleToggle={handleToggle}
                afterDeleteEnvRefresh={fetchEnvList}
                handleOpenAddEnvDialog={handleOpenAddEnvDialogOnly}
                data={topSummaryData}
                application_id={application_id}
                service_id={state.serviceId}
                selectedServiceEnv={state.selectedServiceEnv}
              />
            </Grid>
            <>
              {state.data ? (
                Object.keys(state.data.environmentsMap).length == 0 ? (
                  <NoEnvAvailableForMonitoring 
                  addEnvLink={addEnvLink}
                  handleClickOpen={handleOpenAddEnvDialog} />
                ) : (
                  <SetupSummary
                    setselectedServiceEnv={setselectedServiceEnv}
                    refresh={fetchEnvList}
                    post_refresh_count={state.post_refresh_count}
                    handleOpenAddEnvDialog={handleOpenAddEnvDialog}
                    single_service_data={service_data}
                    job_code_queryString={job_code_queryString}
                    show_deployment_status={show_deployment_status}
                    top_summary_data={topSummaryData}
                    serviceSummaryData={state.topSummaryData}
                    selectedEnvDetails={getServiceAndSelectedEnv()}
                    service_data={state.serviceData}
                    data={state.data}

                  />
                )
              ) : (
                <Loading />
              )}
            </>
          </Grid>
          {
            openDialog &&
            <AddMsDialog
            variant="edit_mode"
            application_id={application_id}
            handleClose={handleToggle}
            open={openDialog}
            handleOpenAddEnvDialog={handleOpenAddEnvDialog}
            component_id={service_id}
            refreshState={refreshState}
          />
          }
          {
            openAddEnvDialog &&
            <EnvAndUserGroupNew
            application_id={application_id}
            handleClose={handleCloseAddEnvDialog}
            open={openAddEnvDialog}
            componentData={addedServiceResponse}
            refreshState={refreshStatefn}
            edit={false}
          />
          }
          {
            openEditEnvDialog &&
            <EnvAndUserGroupNew
            application_id={application_id}
            handleClose={handleCloseEditEnvDialog}
            open={openEditEnvDialog}
            componentData={addedServiceResponse}
            refreshState={refreshStatefn}
            env_id={state.selectedServiceEnv?.id}
            edit={true}
          />
          }
          
        </div>
      ) :
        state.error ? (
          <ErrorComponent error={state.error} variant='error-box' />
        ) : (
          <LoadingContainer />
        )
      }
    </>
  );
};

ServiceDashboardNew.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const MonitoringDetails = (props) => {
  const selectedEnvDetails = props.selectedEnvDetails;
  var envId = selectedEnvDetails.envId;
  const addEnvLink =
    "/application/" +
    selectedEnvDetails.applicationId +
    "/service/" +
    selectedEnvDetails.serviceId +
    "/env/add";
  return (
    // <>{envId ?
    <>
      {/*<PerformanceMatrix data={props.data} /> */}
      <div className="detailedcard__footer">
        <HealthCheck
          data={props.data1}
          selectedEnvDetails={props.selectedEnvDetails}
        />
      </div>
      {/* </> : <NoEnvAvailableForMonitoring addEnvLink={addEnvLink} />  }*/}
    </>
  );
};

MonitoringDetails.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const NoEnvAvailableForMonitoring = (props) => {
  const addEnvLink = props.addEnvLink;
  const handleClickOpen = props.handleClickOpen
  return (
    <Grid container justify="center">
      <Grid
        item
        lg={12}
        className="d-flex align-center justify-center"
        style={{ width: "400px", height: "60vh" }}
      >
        <div className="card overviewCard-blank" style={{ width: "400px" }}>
          <div className="overviewCard__body-blank">
            <div className="blank-div">
              <div className="blank-div-text">No Environment Found</div>
              <button onClick={handleClickOpen}>
                <i className="ri-add-line"></i>
                Add Environment
              </button>
            </div>
          </div>
        </div>
      </Grid>
     
    </Grid>
  );
};

NoEnvAvailableForMonitoring.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default ServiceDashboardNew;

function getMasterEnvWiseServiceEnvList(envs) {
  var environments = {};
  envs.forEach((env) => {
    const master_env_id = env.project_env.environment_master_id;
    environments[master_env_id] = environments[master_env_id]
      ? environments[master_env_id]
      : [];
    environments[master_env_id] = [...environments[master_env_id], env];
  });
  return environments;
}

function getMasterEnvAndServiceEnvTabList(environmentsMap) {
  var masterEnvTablist = [];
  var serviceEnvironmentsTabList = {};
  Object.keys(environmentsMap).forEach((masterEnvIndex) => {
    masterEnvTablist = addMasterEnvTab(
      environmentsMap[masterEnvIndex],
      masterEnvTablist,
      masterEnvIndex
    );
    serviceEnvironmentsTabList[masterEnvIndex] = getServiceEnvTabList(
      environmentsMap[masterEnvIndex]
    );
  });

  return {
    masterEnvTablist: masterEnvTablist,
    serviceEnvironmentsTabList: serviceEnvironmentsTabList,
  };
}



function getDefaultMasterEnvAndEnvTabOrder(
  environments_tab_order,
  environment_id_querystring
) {
 
  var response = {
    master_env_tab_order: null,
    environment_tab_order: 0,
    selected_service_env: null,
  };
  var master_env_tab_order = Object.keys(environments_tab_order);
  if (master_env_tab_order && master_env_tab_order.length != 0) {
    var sortedMasterEnvTabOrder = Object.keys(environments_tab_order).sort((a, b) => a - b);
    var response = {
      master_env_tab_order: sortedMasterEnvTabOrder[0],
      environment_tab_order: 0,
      selected_service_env:
        environments_tab_order[sortedMasterEnvTabOrder[0]][0].service_env,
    };
    if (environment_id_querystring) {
      sortedMasterEnvTabOrder.forEach((sortedMasterEnvTabOrder) => {
        var environments_tabs_for_master_env =
          environments_tab_order[sortedMasterEnvTabOrder];
        environments_tabs_for_master_env.forEach((environment_tab, index) => {
          if (environment_tab.service_env.id == environment_id_querystring) {
            response = {
              master_env_tab_order: sortedMasterEnvTabOrder,
              environment_tab_order: index,
            };
            response.selected_service_env =
              environments_tab_order[sortedMasterEnvTabOrder][
                index
              ].service_env;
          }
        });
      });
    }
  }

  return response;
}

function addMasterEnvTab(environments, masterEnvTablist, masterEnvIndex) {
  masterEnvTablist = [
    ...masterEnvTablist,
    {
      tabName: properties.envs_map[masterEnvIndex].label,
      order: masterEnvIndex,
      total_sub_env: environments.length,
    },
  ];

  return masterEnvTablist;
}

function getServiceEnvTabList(environments) {
  var serviceEnvTabs = [];
  var count = 0;
  environments.reverse().forEach((environment) => {
    serviceEnvTabs = [
      ...serviceEnvTabs,
      {
        tabName: environment.project_env.name,
        order: count++,
        service_env: environment,
      },
    ];
  });
  return serviceEnvTabs;
}
