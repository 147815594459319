import React, { useEffect, useState } from 'react'
import { styled } from '@mui/styles'
import { useLocation } from 'react-router-dom';
import SearchBar from '../../components/SearchBar';
import CommonHorizontalTab from '../../components/genericComponents/CommonHorizontalTab';
import { error } from 'highcharts';
import { Checkbox } from '@mui/material';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi, { PostData } from '../../util/apiInvoker';

const DatabaseMetricTemplateDetails = () => {
    const location = useLocation();
    const { template_id , data } = location.state || {};

    const [state, setState] = useState({
        data: {},
        error: {},
        selectedMetricTab: 1,
        open_global_metrices: [],
        open_performance_metrices: [],
        selected_global_metrices: [],
        selected_performance_metrices: [],
        performance_metrices : [],
        global_metrices : []
    })

    const [filter, setFilter] = useState({ name: "" });
    console.log(data, "kvhgcvghhgvhg")

    const viewMetricOptions = [
        {
            order: 1,
            tabName: 'Gobal Metrics'
        },
        {
            order: 2,
            tabName: 'Performance Indicators Metrics '
        },
    ]

    useEffect(() => {
        fetchTemplateDetails()
    }, [template_id])

    function fetchTemplateDetails() {
        let url_temp = GenerateURL({ db_template_id: template_id }, properties.api.db_metric_template_edit_delete);
        let requestInfo = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onTemplateDetailsFetchSuccess, onTemplateDetailsFetchFail)
    }

    function onTemplateDetailsFetchSuccess(response) {
        setState((new_state) => ({
            ...new_state,
            template_data: response,
            global_metrices: response.global_parameters,
            performance_metrices: response.performance_parameters
        }))
    }

    function onTemplateDetailsFetchFail(error) {

    }

    function updateSelectedMetricDetailsTab(tabOrder) {
        setState((new_state) => ({
            ...new_state,
            selectedMetricTab: tabOrder,
        }))
    }

    function handleOpenMetricDetail(name, type) {
        if (type == "global") {
            setState((new_state) => ({
                ...new_state,
                open_global_metrices: [...new_state.open_global_metrices, name]
            }))
        }
        if (type == "performance") {
            setState((new_state) => ({
                ...new_state,
                open_performance_metrices: [...new_state.open_performance_metrices, name]
            }))
        }
    }

    function handleCloseMetricDetail(name, type) {
        if (type == "global") {
            let updated_arr = []
            state.open_global_metrices.forEach((metric) => {
                if (metric !== name) {
                    updated_arr.push(metric)
                }
            })
            setState((new_state) => ({
                ...new_state,
                open_global_metrices: updated_arr
            }))
        }
        if (type == "performance") {
            let updated_arr = []
            state.open_performance_metrices.forEach((metric) => {
                if (metric !== name) {
                    updated_arr.push(metric)
                }
            })
            setState((new_state) => ({
                ...new_state,
                open_performance_metrices: updated_arr
            }))
        }
    }

    const handleSearchChange = (filter_name) => {
        setFilter(filter_name);
        setState((new_state) => ({
            ...new_state,
            search_query_name: filter_name
                ? filter_name.name
                    ? filter_name.name
                    : ""
                : "",
        }));
    };

    function handleGlobalMetricSelect(item) {
        setState((new_state) => {
            const selected_global_metrices = new_state.selected_global_metrices.includes(item.name)
                ? new_state.selected_global_metrices.filter(metric => metric !== item.name)
                : [...new_state.selected_global_metrices, item.name];
            return {
                ...new_state,
                selected_global_metrices,
            };
        });
    }

    function handlePerformanceMetricSelect(item) {
        setState((new_state) => {
            const selected_performance_metrices = new_state.selected_performance_metrices.includes(item.name)
                ? new_state.selected_performance_metrices.filter(metric => metric !== item.name)
                : [...new_state.selected_performance_metrices, item.name];
            return {
                ...new_state,
                selected_performance_metrices,
            };
        });
    }

    function handleCheckboxSelect(item, type) {
        setState((new_state) => {
            let selectedMetrics;
            if (type === "global") {
                selectedMetrics = new_state.selected_global_metrices.includes(item.name)
                    ? new_state.selected_global_metrices.filter(metric => metric !== item.name)
                    : [...new_state.selected_global_metrices, item.name];
            } else if (type === "performance") {
                selectedMetrics = new_state.selected_performance_metrices.includes(item.name)
                    ? new_state.selected_performance_metrices.filter(metric => metric !== item.name)
                    : [...new_state.selected_performance_metrices, item.name];
            }
            return {
                ...new_state,
                [type === "global" ? "selected_global_metrices" : "selected_performance_metrices"]: selectedMetrics,
            };
        });
    }

    function handleSelectAllGlobalMetrics() {
        setState((new_state) => {
            const allSelected = new_state.global_metrices.every(metric => new_state.selected_global_metrices.includes(metric.name));
            const updatedSelectedGlobalMetrics = allSelected
                ? []
                : new_state.global_metrices.map(metric => metric.name);
            return {
                ...new_state,
                selected_global_metrices: updatedSelectedGlobalMetrics,
            };
        });
    }

    function handleSelectAllPerformanceMetrics() {
        setState((new_state) => {
            const allSelected = new_state.performance_metrices.every(metric => new_state.selected_performance_metrices.includes(metric.name));
            const updatedSelectedPerformanceMetrics = allSelected
                ? []
                : new_state.performance_metrices.map(metric => metric.name);
            return {
                ...new_state,
                selected_performance_metrices: updatedSelectedPerformanceMetrics,
            };
        });
    }

    function validateAndSave() {
        let url_temp = GenerateURL({ db_template_id: template_id }, properties.api.db_metric_template_edit_delete);
        let post_data = {
            name: data.name,
            database_type: data.database_type,
            performance_parameters: state.performance_metrices,
            global_parameters: state.global_metrices
        }
        console.log(post_data, "dbhsbhcvbdshbvds")
        PostData(url_temp, post_data, handleSuccessTemplateAdd, handleFailedTemplateAdd);
        setState(new_state => ({
            ...new_state,
            loader: true,
        }));
    }

    function handleSuccessTemplateAdd(response) {
        console.log("aghvcgadsvc", response)
        setState((new_state) => ({
            ...new_state,
            loader: false,
        }))
    }

    function handleFailedTemplateAdd(error) {
        setState((new_state) => ({
            ...new_state,
            loader: false,
        }))
    }

    function handleDeleteSelectedGlobalParameters() {
        setState((new_state) => {
            const updatedGlobalMetrics = new_state.global_metrices.filter(metric => !new_state.selected_global_metrices.includes(metric.name));
            return {
                ...new_state,
                global_metrices: updatedGlobalMetrics,
                selected_global_metrices: [],
            };
        });
    }
    
    function handleDeleteSelectedPerformanceParameters() {
        setState((new_state) => {
            const updatedPerformanceMetrics = new_state.performance_metrices.filter(metric => !new_state.selected_performance_metrices.includes(metric.name));
            return {
                ...new_state,
                performance_metrices: updatedPerformanceMetrics,
                selected_performance_metrices: [],
            };
        });
    }

    function removeChanges(){
        setState((new_state) => ({
            ...new_state,
            selected_performance_metrices: [],
            selected_global_metrices : []
        }))
    }

    return (
        <DatabaseTemplateDetails>
            <div className='header'>
                <div className='left-part'>
                    <div className='icon-div'>
                        <span className='ri-database-2-line' style={{ color: "#0086FF", fontSize: "24px" }}></span>
                    </div>
                    <div className='header-detail'>
                        <p style={{ fontFamily: "Montserrat", fontSize: "14px", fontWeight: "500", color: "#505050" }}>{/* {data.name} */}template 1</p>
                        <p style={{ fontFamily: "Montserrat", fontSize: "18px", fontWeight: "600", lineHeight: "21.94px", color: "#505050" }}>DB Metric Templates <span className='ri-information-line' style={{ color: "#505050", fontSize: "20px", fontWeight: "400" }}></span></p>
                    </div>
                </div>
                <div className='right-part'>
                    <span className='ri-delete-bin-line' style={{ color: "#505050", fontSize: "18px" }}></span>
                    <button className='btn' onClick={removeChanges}>CANCEL</button>
                    <button className='btn btn-primary' onClick={validateAndSave}>SAVE SETTINGS</button>
                </div>
            </div>
            <div className="w-100 d-flex align-center space-between mb-24" style={{ height: "40px" }}>
                <div style={{ width: "37%", }}>
                    <SearchBar
                        search_data={filter}
                        default_filter={{
                            name: "name",
                            label: "Search for db metric templates",
                        }}
                        search_call_back={handleSearchChange}
                        clear_search_callback={() => setFilter({ name: "" })}
                    />
                </div>
            </div>
            <div className='main-body'>
                <CommonHorizontalTab
                    selectedTabOrder={state.selectedMetricTab}
                    tabList={viewMetricOptions}
                    updateSelectedTab={updateSelectedMetricDetailsTab}
                    variant={"scrollable_div"}
                />
                <div class="cards" style={{ marginTop: "12px" }}>
                    {
                        state.selectedMetricTab == 1 ?
                            <>
                                <div className='select-all-global' style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                                    <div class="checkbox">
                                        <Checkbox
                                            checked={state.global_metrices?.every(metric => state.selected_global_metrices.includes(metric.name))}
                                            onChange={handleSelectAllGlobalMetrics}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div class="title" style={{ fontWeight: "700", color: "#787878" }}>Metrics</div>
                                    <div style={{ fontWeight: "700", color: "#787878" }}>({state.selected_global_metrices.length}/{state.global_metrices?.length})</div>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "12px" , cursor:"pointer"}} onClick={handleDeleteSelectedGlobalParameters}>
                                        <span className='ri-delete-bin-line' style={{ color: "#2588E3", fontSize: "18px" }}></span>
                                        <span style={{ color: "#2588E3", fontWeight: "600", fontSize: "12px", }}>Delete</span>
                                    </div>
                                </div>
                                {
                                    state.global_metrices && state.global_metrices.length > 0 && state.global_metrices.map((item, index) => {
                                        return (
                                            <div className="container">
                                                <div className='top-part'>
                                                    <div className='left-part'>
                                                        <div class="checkbox">
                                                            <Checkbox
                                                                checked={state.selected_global_metrices.includes(item.name)}
                                                                onChange={() => handleCheckboxSelect(item, "global")}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </div>
                                                        <div className='metric-number'>{index + 1}</div>
                                                        <div className='divider-line'></div>
                                                        <div class="title">{item.name}</div>
                                                    </div>
                                                    {
                                                        state.open_global_metrices.includes(item.name) ?
                                                            <div className='right-part'>
                                                                <span className='ri-arrow-up-s-line' style={{ fontSize: "24px", color: "#505050" }} onClick={() => handleCloseMetricDetail(item.name, "global")}></span>
                                                            </div>
                                                            :
                                                            <div className='right-part'>
                                                                <span className='ri-arrow-down-s-line' style={{ fontSize: "24px", color: "#505050" }} onClick={() => handleOpenMetricDetail(item.name, "global")}></span>
                                                            </div>
                                                    }
                                                </div>
                                                {
                                                    state.open_global_metrices.includes(item.name) ?
                                                        item.data_type == "INTEGER" ?
                                                            <div className='bottom-part'>
                                                                <div className='detail-header'>
                                                                    <span>Name</span>
                                                                    <span>Type</span>
                                                                    <span>Minimum value</span>
                                                                    <span>Maximum Value</span>
                                                                    <span style={{ display: "flex", alignItems: "center" }}><span className='ri-alert-line' style={{ color: "#E1941D", fontSize: "16px", marginRight: "4px" }}></span>Threshold Warning at</span>
                                                                    <span style={{ display: "flex", alignItems: "center" }}><span className='ri-error-warning-line' style={{ color: "#E53737", fontSize: "16px", marginRight: "4px" }}></span>Threshold Error at</span>
                                                                </div>
                                                                <div className='detail-body'>
                                                                    <span>{item.name}</span>
                                                                    <div className='data-type-tab'>
                                                                        {item.data_type.toLowerCase()}
                                                                    </div>
                                                                    <span>{item.min_value}</span>
                                                                    <span>{item.max_value}</span>
                                                                    <span>{item.warning_at}</span>
                                                                    <span>{item.error_at}</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            item.data_type == "STRING" ?
                                                                <div className='bottom-part'>
                                                                    <div className='detail-header'>
                                                                        <span>Name</span>
                                                                        <span>Type</span>
                                                                        <span>Threshold</span>
                                                                    </div>
                                                                    <div className='detail-body'>
                                                                        <span>{item.name}</span>
                                                                        <div className='data-type-tab'>
                                                                            {item.data_type.toLowerCase()}
                                                                        </div>
                                                                        <span>{item.threshold}</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='bottom-part'>
                                                                    <div className='detail-header'>
                                                                        <span>Name</span>
                                                                        <span>Type</span>
                                                                        <span>Threshold</span>
                                                                    </div>
                                                                    <div className='detail-body'>
                                                                        <span>{item.name}</span>
                                                                        <div className='data-type-tab'>
                                                                            {item.data_type.toLowerCase()}
                                                                        </div>
                                                                        <span>{item.threshold}</span>
                                                                    </div>
                                                                </div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </>
                            :
                            <>
                                <div className='select-all-performance' style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                                    <div class="checkbox">
                                        <Checkbox
                                            checked={state.performance_metrices?.every(metric => state.selected_performance_metrices.includes(metric.name))}
                                            onChange={handleSelectAllPerformanceMetrics}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div class="title" style={{ fontWeight: "700", color: "#787878" }}>Metrics</div>
                                    <div style={{ fontWeight: "700", color: "#787878" }}>({state.selected_performance_metrices.length}/{state.performance_metrices?.length})</div>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "12px", cursor:"pointer" }} onClick={handleDeleteSelectedPerformanceParameters}>
                                        <span className='ri-delete-bin-line' style={{ color: "#2588E3", fontSize: "18px" }}></span>
                                        <span style={{ color: "#2588E3", fontWeight: "600", fontSize: "12px", }}>Delete</span>
                                    </div>
                                </div>
                                {
                                    state.performance_metrices && state.performance_metrices.length > 0 && state.performance_metrices.map((item, index) => {
                                        return (
                                            <div className="container">
                                                <div className='top-part'>
                                                    <div className='left-part'>
                                                        <div class="checkbox">
                                                            <Checkbox
                                                                checked={state.selected_performance_metrices.includes(item.name)}
                                                                onChange={() => handleCheckboxSelect(item, "performance")}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </div>
                                                        <div className='metric-number'>1</div>
                                                        <div className='divider-line'></div>
                                                        <div class="title">{item.name}</div>
                                                    </div>
                                                    {
                                                        state.open_performance_metrices.includes(item.name) ?
                                                            <div className='right-part'>
                                                                <span className='ri-arrow-up-s-line' style={{ fontSize: "24px", color: "#505050" }} onClick={() => handleCloseMetricDetail(item.name, "performance")}></span>
                                                            </div>
                                                            :
                                                            <div className='right-part'>
                                                                <span className='ri-arrow-down-s-line' style={{ fontSize: "24px", color: "#505050" }} onClick={() => handleOpenMetricDetail(item.name, "performance")}></span>
                                                            </div>
                                                    }
                                                </div>
                                                {
                                                    state.open_performance_metrices.includes(item.name) &&
                                                    <div className='bottom-part'>
                                                        <div className='detail-header'>
                                                            <span>Name</span>
                                                            <span>Type</span>
                                                            <span>Minimum value</span>
                                                            <span>Maximum Value</span>
                                                            <span>Threshold Warning at</span>
                                                            <span>Threshold Error at</span>
                                                        </div>
                                                        <div className='detail-body'>
                                                            <span>{item.name}</span>
                                                            <div className='data-type-tab'>
                                                                {item.data_type}
                                                            </div>
                                                            <span>{item.min_value}</span>
                                                            <span>{item.max_value}</span>
                                                            <span>{item.warning_at}</span>
                                                            <span>{item.error_at}</span>
                                                        </div>
                                                    </div>

                                                }
                                            </div>
                                        )
                                    })
                                }
                            </>

                    }
                </div>
            </div>
        </DatabaseTemplateDetails>
    )
}

export default DatabaseMetricTemplateDetails


const DatabaseTemplateDetails = styled("div")({
    padding: '20px 20px 0px 20px',
    // marginTop: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    backgroundColor: "#ffffff",

    "& .header": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& .left-part": {
            display: "flex",
            alignItems: "center",
            gap: "16px",

            "& .icon-div": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#0086FF14",
                height: "56px",
                width: "56px",
                borderRadius: "8px",
            }
        },

        "& .right-part": {
            display: "flex",
            alignItems: "center",
            gap: "12px",
        }
    },

    "& .main-body": {
        "& .container": {
            // maxWidth: "600px",
            border: "1px solid #E6E6E6",
            borderRadius: "6px",
            padding: "10px 12px",
            marginTop: "10px",

            "& .top-part": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",


                "& .left-part": {
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",

                    "& .metric-number": {
                        color: "#787878",
                        fontSize: "12px",
                        fontWeight: "500"
                    },

                    "& .divider-line": {
                        width: "2px",
                        backgroundColor: "#E6E6E6",
                        height: "100%",
                    },

                    "& .title": {
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#2F2F2F"
                    }
                }
            },

            "& .bottom-part": {

                "& .detail-header": {
                    padding: "12px 10px",
                    display: "grid",
                    gridTemplateColumns: "200px 130px 150px 150px 200px 200px",
                    borderBottom: "1px solid #E6E6E6",

                    "& span": {
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#2F2F2F",
                    }
                },

                "& .detail-body": {
                    margin: "8px 10px 0 10px",
                    display: "grid",
                    gridTemplateColumns: "200px 130px 150px 150px 200px 200px",
                    alignItems: "center",

                    "& span": {
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#505050",
                    },

                    "& .data-type-tab": {
                        border: "1px solid #E6E6E6",
                        backgroundColor: "#E6E6E6",
                        width: "fit-content",
                        borderRadius: "6px",
                        padding: "4px 6px",
                        fontWeight: "600",
                        fontSize: "12px"
                    }
                }
            }

        },
        "& .header": {
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
        },
        "& .header .checkbox": {
            marginRight: "10px",
        },
        "& .header .title": {
            fontWeight: "bold",
        },
        "& .table": {
            width: "100%",
            borderCollapse: "collapse",
        },
        "& .table th, .table td": {
            padding: "10px",
            textAlign: "left",
            borderBottom: "1px solid #ddd",
        },
        "& .table th": {
            backgroundColor: "#f2f2f2",
        },
        "& .threshold": {
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
        },
        "& .threshold span": {
            color: "#ff9800",
            fontWeight: "bold",
        },
        "& .error": {
            color: "#f44336",
            fontWeight: "bold",
        }
    }

})