import { Autocomplete, Grid, TextField } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GenerateURL from '../../../util/APIUrlProvider';

const FeatureResultItem = ({ searchedQuery, appList, entityObj, onClose,openedFeature,setOpenedFeature }) => {
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        data: { app: null },
        error: {},
    });
    const [generatedLink,setGeneratedLink] = useState(null);

    useEffect(() => {
        if(openedFeature) {
            if(openedFeature != entityObj.id){
                handleClose();
            }   
        }
    },[openedFeature])
    console.log("ldsls;dsl;s",openedFeature)
    function toggleOpen(e) {
        console.log("luiuiuiui",e)
        e.stopPropagation(); // Prevents triggering parent click events
        setOpen(prev => !prev);
        const featureId = e.currentTarget?.dataset?.value;
        setOpenedFeature(featureId);
    }

    function handleClose() {
        setOpen(false);
    }

    function onChangeHandler(event, newValue) {
        console.log("Selected App:", newValue);
        setState(prevState => ({
            ...prevState,
            data: { ...prevState.data, app: newValue }, // Update selected app
            error: { ...prevState.error, selected_app: '' },
        }));

        const link = newValue ? GenerateURL({application_id:newValue.id},entityObj?.path,true) : null;
        setGeneratedLink(link);
    }

    const Content = (
            <>
                <div className='main-head d-flex space-between align-center width-full cursor-pointer' data-value={entityObj?.id} onClick={toggleOpen}>
                    <div className='app-name'>
                        {entityObj?.customIcon ? 
                            entityObj.customIcon :
                            <span className={`${entityObj?.icon} font-16 line-height-normal color-tertiary`}></span>
                        }
                        <span className='font-12 font-weight-600 color-primary'>{entityObj?.label}</span>
                    </div>
                    {!entityObj?.link && (
                        <span 
                            className={`toggle-icon ${open ? 'ri-close-line' : 'ri-arrow-right-s-line'}`} 
                        ></span>
                    )}
                </div>

                {/* Expandable Section with AutoComplete */}
                {!entityObj?.link &&
                    <div className={`app-selector ${open ? 'open' : ''}`}>
                        <span className='font-12 font-500 color-primary'>Select the application in which you want to create a {entityObj?.entity}</span>
                        <div className='grid-div'>
                            <div >
                                <Autocomplete
                                    options={appList || []} 
                                    getOptionLabel={(option) => option.name} 
                                    value={state.data.app} 
                                    onChange={onChangeHandler}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params}
                                            variant="outlined"
                                            placeholder='Search Applications'
                                            sx={{
                                                '&.MuiTextField-root': {
                                                    '&:hover': {
                                                        border: 'none !important', 
                                                    },
                                                },
                                                '& input': {
                                                    height: '32px',
                                                    padding: '5px 10px', 
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'inherit !important',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {generatedLink ? 
                                <Link
                                    to={generatedLink}
                                    onClick={onClose}
                                    className="btn btn-primary font-12 font-weight-600 d-flex align-center justify-center text-transform-uppercase" style={{ height: "34px",textWrap:'nowrap',paddingTop:'9px',textAlign:'center' }}
                                >
                                    Get Started
                                </Link>
                            
                            :
                                <button
                                    className="btn btn-primary font-12 font-weight-600 d-flex align-center justify-center text-transform-uppercase" style={{ height: "34px",textWrap:'nowrap',padding:'0px' }}
                                >
                                    Get Started
                                </button>   
                            }
                        </div>

                    </div>
                }
            </>
    );

    return entityObj?.link ? (
        <LinkRoot to={entityObj.link} onClick={onClose}>  
            {Content}
        </LinkRoot>
    ) : (
        <Root className='item' open={open}>
            {Content}
        </Root>
    );
};

export default FeatureResultItem;

const Root = styled('div')(({open})=>({
    padding: '12px',
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #E6E6E6',
    flexDirection: 'column',
    transition: 'background-color 0.3s ease-in-out',
    backgroundColor:open ? '#F5FAFF' : '#FFFFFF',
    '& .main-head': {
        height: '24px',
    },
    '&:hover': {
        backgroundColor: '#FAFAFA',
        borderRadius: '6px',
        '& .option-box': {
            display: 'inline-block',
        },
    },
    '& .app-name': {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
    },
    '&:first-child':{
        borderTopLeftRadius:'6px',
        borderTopRightRadius:'6px',
    },
    '&:last-child': {
        borderBottom: "none !important",
        borderBottomLeftRadius:'6px',
        borderBottomRightRadius:'6px',
    },

    /* App Selector Transition */
    '& .app-selector': {
        display:'flex',
        flexDirection:'column',
        gap:'12px',
        maxHeight: '0px',
        overflow: 'hidden',
        transition: 'max-height 0.4s ease-in-out, opacity 0.3s ease-in-out',
        opacity: 0,
        '& .grid-div':{
            display:'grid',
            gridTemplateColumns:'200px 1fr',
            justifyContent:'space-between',
            gap:'20px',
        }
    },
    '& .app-selector.open': {
        maxHeight: '82px',
        opacity: 1,
        marginTop: '20px'
    },

    '& .toggle-icon': {
        fontSize: '24px',
        color: '#949494',
        cursor: 'pointer',
        display: 'inline-block',
        transition: 'transform 0.3s ease-in-out',
    },
    '& .toggle-icon.ri-close-line': {
        transform: 'rotate(180deg)',
    }
}));

const LinkRoot = styled(Link)(({open})=>({
    padding: '12px',
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #E6E6E6',
    flexDirection: 'column',
    transition: 'background-color 0.3s ease-in-out',
    backgroundColor:open ? '#F5FAFF' : '#FFFFFF',
    '& .main-head': {
        height: '24px',
    },
    '&:hover': {
        backgroundColor: '#FAFAFA',
        borderRadius: '6px',
        '& .option-box': {
            display: 'inline-block',
        },
    },
    '& .app-name': {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
    },
    '&:first-child':{
        borderTopLeftRadius:'6px',
        borderTopRightRadius:'6px',
    },
    '&:last-child': {
        borderBottom: "none !important",
        borderBottomLeftRadius:'6px',
        borderBottomRightRadius:'6px',
    },

    /* App Selector Transition */
    '& .app-selector': {
        display:'flex',
        flexDirection:'column',
        gap:'12px',
        maxHeight: '0px',
        overflow: 'hidden',
        transition: 'max-height 0.4s ease-in-out, opacity 0.3s ease-in-out',
        opacity: 0,
        '& .grid-div':{
            display:'grid',
            gridTemplateColumns:'200px 1fr',
            justifyContent:'space-between',
            gap:'20px',
        }
    },
    '& .app-selector.open': {
        maxHeight: '82px',
        opacity: 1,
        marginTop: '20px'
    },

    '& .toggle-icon': {
        fontSize: '24px',
        color: '#949494',
        cursor: 'pointer',
        display: 'inline-block',
        transition: 'transform 0.3s ease-in-out',
    },
    '& .toggle-icon.ri-close-line': {
        transform: 'rotate(180deg)',
    }
}));
