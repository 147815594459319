import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HorizontalTab from '../wizardComponents/HorizontalTab';
import HorizontalTabNewUI from '../wizardComponents/HorizontalTabNewUI';

const defaultWizardJson = [
    {
        order: 1,
        mainText: "DefaultPanel",
        body: DefaultPanel
    },
    {
        order: 2,
        mainText: "DefaultPanel",
        body: DefaultPanel
    },
    {
        order: 3,
        mainText: "DefaultPanel",
        body: DefaultPanel
    }

];


export const StepWizard = (props) => {
    console.log(props,"props")
    let wizardJson = props.dataJson ? props.dataJson : defaultWizardJson;
    const stepper_variant = props.stepper_variant ? props.stepper_variant : null;
    const Stepper = props.horizontalTab ? props.horizontalTab : HorizontalTab;
    const Footer = props.footer ? props.footer : DefaultFooter
    const indexedDataJson = arrayToIndexedObject(wizardJson);
    const childInherits = {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const isListing = props.isListing ? props.isListing : false;
    const extraProps = props.extraProps ? props.extraProps : null;
    const style = props.style?props.style:{}
    const type = props.type ? props.type : null;
    const child_build = props.child_build;
    const child_build_data = props.child_build_data;
    const application_id = props.application_id;
    const parentPath = props.parentPath ? props.parentPath : null;
    const cancelButtonUrl = props.cancelButtonUrl ? props.cancelButtonUrl:()=>{};
    const shallow_cloning = props.shallow_cloning;
    const depth = props.depth;
    const is_add=props.is_add;
    const [state, setState] = useState(prev_state ? prev_state : {
        panels: {},
        selectedTabOrder: props.currentTab ? props.currentTab : 1,
    })

    console.log(child_build_data,"agatftt ===========>")
    console.log(extraProps,"agatftt ===========>")

    useEffect(() => {
        setState(new_state => ({ ...new_state, ...prev_state }))
    }, [prev_state])

    function getActivePanel() {
        const Component = indexedDataJson[state.selectedTabOrder].body;
        return <Component extraProps={extraProps} inherits={childInherits} prev_state={state.panels[state.selectedTabOrder]} total_state={state.panels} type={type} child_build={child_build} child_build_data={child_build_data} application_id={application_id} shallow_cloning={shallow_cloning} depth={depth} is_add={is_add} />
    }

    const moveNext = () => {
        const selected_tab_order = state.selectedTabOrder;
        console.log(selected_tab_order,wizardJson.length,childInherits, "wizarddatataaa")
        let result = childInherits.validateForm ? childInherits.validateForm() ? childInherits.validateForm() : { valid: true } : { valid: true };
        // childInherits.getAPIObject();
        console.log(selected_tab_order,wizardJson.length,childInherits, "wizarddatataaa")
        if (result.valid) {
            if (state.selectedTabOrder != wizardJson.length) {  

                let currentTabOrder = state.selectedTabOrder

                setState(new_state => ({
                    ...new_state,
                    selectedTabOrder: (selected_tab_order + 1),
                    panels: {
                        ...new_state.panels,
                        [selected_tab_order]: childInherits.getState ? childInherits.getState() : {}
                    }
                }));
                props.sendTabChangeEvent && props.sendTabChangeEvent(currentTabOrder + 1)
                props.currentTabData && props.currentTabData(childInherits.getState ? childInherits.getState() : {});
            }

            else {
                setState(new_state => ({
                    ...new_state,
                    selectedTabOrder: selected_tab_order,
                    panels: {
                        ...new_state.panels,
                        [selected_tab_order]: childInherits.getState ? childInherits.getState() : {}
                    }
                }));
                return true;
            }
        }
    }

    const moveBack = () => {
        const selected_tab_order = state.selectedTabOrder;
        if (state.selectedTabOrder != 1) {
            setState(new_state => ({
                ...new_state,
                selectedTabOrder: (selected_tab_order - 1),
                panels: {
                    ...new_state.panels,
                    [selected_tab_order]: childInherits.getState ? childInherits.getState() : {}
                }
            }));
        }
    }
    const onSubmit = () => {
        let valid = moveNext();
        if (valid) {
            let complete_panel_object = {
                ...state.panels,

                [state.selectedTabOrder]: childInherits.getState ? childInherits.getState() : {}

            }
            if (props.onSubmit) props.onSubmit(complete_panel_object);
        }
    }
    // const onSubmitResetTab = () => {
    //     let valid = moveNext();
    //     if (valid) {
    //         let complete_panel_object = {
    //             ...state.panels,

    //             [state.selectedTabOrder]: childInherits.getState ? childInherits.getState() : {}

    //         }
    //         if (props.onSubmitResetTab) props.onSubmitResetTab(complete_panel_object);
    //         setState(new_state => ({
    //             ...new_state,
    //             selectedTabOrder: 2,
               
    //         }));
    //     }
    // }
    console.log("wizarddatataaa", state)
    console.log("wizarddatataaa",childInherits)
    function onClickHandler(order) {
        setState(new_state => ({
            ...new_state,
            selectedTabOrder: order,

        }));
    }
    return (
        <>
            {
            stepper_variant == "new_ui" ? 
            <div className='md-stepper-wrapper'>
            <HorizontalTabNewUI onClickHandler={onClickHandler} tabDataList={wizardJson} tabState={{ selectedTabOrder: state.selectedTabOrder }} />
            </div>
            :
            isListing ? 
            
            <HorizontalTab variant={props.step_logs ? "step_logs" : "edit_mode"} onClickHandler={onClickHandler} tabDataList={wizardJson} tabState={{ selectedTabOrder: state.selectedTabOrder }} /> :
            
                stepper_variant == "new_ui" ?
                <div className='md-stepper-wrapper'>
                    <Stepper style={style} tabDataList={wizardJson} tabState={{ selectedTabOrder: state.selectedTabOrder }} />
                </div>
                :
                <Stepper style={style} tabDataList={wizardJson} tabState={{ selectedTabOrder: state.selectedTabOrder }} />
            
                
            }

            {getActivePanel()}
            {!isListing ?
                <Footer moveNext={moveNext} moveBack={moveBack} wizardJson={wizardJson} selectedTabOrder={state.selectedTabOrder} onSubmit={onSubmit} variant={stepper_variant} cancelButtonUrl={cancelButtonUrl} parentPath={parentPath} total_state={state.panels} type={type}/>
                : null}

        </>
    )
}

function arrayToIndexedObject(wizardJson) {
    let indexedObject = {};
    wizardJson.forEach(element => {
        indexedObject[element.order] = element
    });
    return indexedObject;
}

function DefaultFooter(props) {
    const moveBack = props.moveBack;
    const moveNext = props.moveNext;
    const onSubmit = props.onSubmit;
    const wizardJson = props.wizardJson;
    const variant = props.variant;
    const cancelButtonUrl = props.cancelButtonUrl ? props.cancelButtonUrl : () => {}
    console.log(variant,"variantvariant")
    return (
        <div className={  variant =="policy-template-wizard" ? 
        "policy-template-footer space-between" :
        variant == "new_ui" ? 
        "card-footer justify-end" :
        "card-footer space-between"}>
            {
                variant == "new_ui" ?
                <>
                <button className={ "btn btn-transparent mr-3"} onClick={cancelButtonUrl}>Cancel</button>
                <button className={props.selectedTabOrder == 1 ? "btn btn-outline-grey btn-disabled mr-3" : "btn btn-outline-primary mr-3"} onClick={props.selectedTabOrder == 1 ? ()=>{} : moveBack}>Back</button>
                </>
                : 
                props.selectedTabOrder == 1 ?
                <div>
                </div>
                :
                <>
                <button className="btn btn-outline-grey" onClick={moveBack}>Back</button>
                </>
            }
            
            {
                wizardJson.length == props.selectedTabOrder ?
                    <button className="btn btn-primary mr-0" onClick={onSubmit}>{"Submit"}</button>
                    :
                    <button className="btn btn-primary mr-0" onClick={moveNext}>{"Continue"}</button>
            }
        </div>
    )
}

DefaultFooter.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

function DefaultPanel() {
    return <p className="error-msg-service-card"> DefaultPanel , dataJson props isnt recived, please check!  </p>
}


StepWizard.defaultProps = {
    sendTabChangeEvent: null
}

StepWizard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
    dataJson: PropTypes.any,
    horizontalTab: PropTypes.any,
    footer: PropTypes.any,
    prev_state: PropTypes.any,
    isListing: PropTypes.any,
    extraProps: PropTypes.any,
    style: PropTypes.any,
    type: PropTypes.any,
    currentTab: PropTypes.any,
    sendTabChangeEvent: PropTypes.any,
    currentTabData: PropTypes.any,
    onSubmit: PropTypes.any,
   
}
