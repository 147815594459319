import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import JiraStoriesAccordion from './JiraStoriesAccordion';
import GenerateURL from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';
import { Link, useLocation } from 'react-router-dom';
import StepProgressDetailed from '../../../components/genericComponents/StepProgressDetailed';
import PaginationTwo from '../../../components/PaginationTwo';
import DialogToMoveSeletedStories from './DialogToMoveSeletedStories';
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        height: 'calc( 100vh - 70px )',
        overflow: 'auto',
        padding: '20px',
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0px'
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'auto'
        },
        '& .MuiAccordionSummary-root': {
            borderBottom: '1px solid #e6e6e6'
        },
        '& .btn-outline-primary': {
            border: '2px solid #0086ff',

        },
        '& .mt-4': {
            marginTop: '4px'
        }
    }
}))



const MoveSprintTask = (props) => {
    const classes = useStyles();
    const location = useLocation();
    let parsedQueryString = queryString.parse(location.search);
    const path = parsedQueryString.path ? parsedQueryString.path : null;
    
    const sprint_name = parsedQueryString.sprint ? parsedQueryString.sprint : null;

    const [state, setState] = useState({
        completed_items_list: [],
        incompleted_items_list: [],
        available_sprints: [],
        uncheck_all_tickets: false
    });
    useEffect(() => {
        fetchAdoSprintWorkItems(path)
    }, [ path])

    function fetchAdoSprintWorkItems(path, pageNumber) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.ado_work_item_fetch + "?load_all=" + true + "&path=" + path),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (pageNumber) {
            requestInfo.endPoint = GenerateURL({}, properties.api.ado_work_item_fetch + "?load_all=" + true + "&path=" + path + "&page=" + pageNumber)
        }

        InvokeApi(requestInfo, fetchAdoSprintWorkItemsDataSuccess, fetchAdoSprintWorkItemsDataFailed);

        setState(new_state => ({
            ...new_state,
            loading_workitems: true
        }))

    }

    function fetchAdoSprintWorkItemsDataSuccess(data) {
        fetchAdoSprints();
        let work_items_list = data && data.issues_dict ? data.issues_dict.values && data.issues_dict.values.length > 0 ? data.issues_dict.values : [] : [];
        let final_validation_state = data.issues_dict && data.issues_dict.final_validation_state ? data.issues_dict.final_validation_state : null
        let filter_completed_task = work_items_list.filter(
            (item) => item.state === final_validation_state
        );

        let filter_not_completed_task = work_items_list.filter(item => item.state != final_validation_state);
        setState(new_state => ({
            ...new_state,
            completed_items_list: filter_completed_task,
            incompleted_items_list: filter_not_completed_task,
            loading_workitems: false,
            count: data.issues_dict.count,
            current_page_count: data.issues_dict.page,
            total_count: data.issues_dict.total_pages
        }));

    }
    const handlePageNumberClick = (pageNumber) => {

        if (path) {
            fetchAdoSprintWorkItems(path, pageNumber);
        }

    };
    function fetchAdoSprintWorkItemsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            loading_workitems: false
        }))
    }
    function fetchAdoSprints() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.load_ado_sprints + "?next_sprint=" + true),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchAdoSprintsDataSuccess, fetchAdoSprintsDataFailed);

        setState(new_state => ({
            ...new_state,
            sprints_loading: true
        }))

    }

    function fetchAdoSprintsDataSuccess(data) {
        let sprint_list = data.sprint_list && data.sprint_list.values ? data.sprint_list.values.length > 0 ? data.sprint_list.values : [] : [];
        let filtered_list = sprint_list.filter(item => item.path !== path);
        filtered_list.unshift({ name: 'Backlog', path: 'backlog' });

        setState(new_state => ({
            ...new_state,
            available_sprints: filtered_list,
            sprints_loading: false
        }));

    }
    function fetchAdoSprintsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            sprints_loading: false
        }))
    }



    const validateAndSaveDataAndSendtoSingleState = (data) => {
        console.log(data, "validateAndSaveDataAndSendtoSingleState");
        const incomplete_work_items_list = state.incompleted_items_list;
        const workItemsList = incomplete_work_items_list.map(item => {
            return { [item.id]: data.move_to && data.move_to !== "backlog" ? data.move_to : "backlog" }
        });

        let final_data = {
            "selected_bulk_move": true,
        }
        const transformedObject = (arr) => {
            return arr.reduce((acc, item) => {
                const key = Object.keys(item)[0];
                acc[key] = item[key];
                return acc;
            }, {});
        };

        if (data.move_to !== "backlog") {
            final_data.sprints = transformedObject(workItemsList)
        } else {
            final_data.backlog = transformedObject(workItemsList)
        }
        var url = GenerateURL({}, properties.api.ado_move_work_items);

        PostData(url, final_data, postDataSuccess, postDataFailed);
        setState(new_state => ({
            ...new_state,
            loading_workitems: true
        }))
        handleClickClose();
    }
    const validateAndBulkMoveItems = (data) => {

        var url = GenerateURL({}, properties.api.ado_move_work_items);

        PostData(url, data, postDataSuccess, postDataFailed);
        setState(new_state => ({
            ...new_state,
            loading_workitems: true
        }))
        handleClickClose();
    }
    const validateAndSaveData = (data) => {

        var url = GenerateURL({}, properties.api.ado_move_work_items);

        PostData(url, data, postDataSuccess, postDataFailed);
        setState(new_state => ({
            ...new_state,
            loading_workitems: true
        }))

    }
    const postDataSuccess = (response) => {
        setState(new_state => ({
            ...new_state,
            loading_workitems: false,
            task_id: response.task_id
        }))
    }

    const postDataFailed = (error) => {
        let error_msg = showErrorHandlerUpdated(error);
        setState(new_state => ({
            ...new_state,
            task_id: null,
            loading_workitems: false,
            error_msg: error_msg
        }))
    }
    const handleRefresh = () => {
        console.log("checking if fn calling")
        setState(new_state => ({
            ...new_state,
            task_id: null,

        }))
        fetchAdoSprintWorkItems(path)
    }
    const handleClickOpen = () => {
        setState(new_state => ({
            ...new_state,
            open: true,
            uncheck_all_tickets: false,
        }))
    }
    const handleClickClose = () => {
        console.log("handleClickClose")
        // unCheckAllTickets();
        setState(new_state => ({
            ...new_state,
            open: false,
            uncheck_all_tickets: true
        }))

    }
    const unCheckAllTickets = () => {

        setState(new_state => ({
            ...new_state,
            uncheck_all_tickets: true
        }))

    }
   
    return (
        <div className={classes.root}>
            {
                state.loading_workitems ?
                    <>
                        <GenericSkeleton variant={"rect"} height={"27px"} style={{ borderRadius: "6px" }} />
                        <div className='' style={{ marginBottom: '24px' }}></div>
                        <GenericSkeleton variant={"rect"} height={"48px"} style={{ borderRadius: "6px" }} />
                        <div style={{ marginBottom: '6px' }}></div>
                        <GenericSkeleton variant={"rect"} height={"48px"} style={{ borderRadius: "6px" }} />
                    </> :
                    <>
                        {
                            state.task_id ?
                                <StepProgressDetailed
                                    task_id={state.task_id}
                                    placeholders={{ path: path }}
                                    refresh={handleRefresh}
                                    triggerBuildDeploy={handleRefresh}
                                    variant="SAME_PAGE_REDIRECT"
                                    btnLabel="Continue"
                                    type="new" />
                                :
                                <>
                                    <p className='font-18 font-weight-600 mb-24 mt-4'>Please move incomplete stories from this sprint to either future sprint or to backlog before finalizing this sprint.</p>
                                    <JiraStoriesAccordion
                                        title={"Completed Work Items that can be Released"}
                                        itemList={state.completed_items_list}
                                        variant="completed"
                                        sprint_list={state.available_sprints}
                                        sendDataToPost={() => { }}
                                        path={path}
                                        openByDefault={state.completed_items_list.length > 0 ? true : false} />
                                    <JiraStoriesAccordion
                                        title={"Incomplete work items"}
                                        variant="in_completed"
                                        sprint_list={state.available_sprints}
                                        path={path}
                                        sprint_name={sprint_name}
                                        resetUncheckAllTickets={unCheckAllTickets}
                                        uncheck_all_tickets = {state.uncheck_all_tickets}
                                        handleClickOpenDialog={handleClickOpen}
                                        sendDataToPost={validateAndSaveData}
                                        itemList={state.incompleted_items_list}
                                        
                                        openByDefault={true} />

                                </>
                        }


                    </>
            }
            {
                state.incompleted_items_list && state.incompleted_items_list.length === 0 ?
                    <div className='d-flex align-center justify-end mb-24 mt-10'>
                        <Link to={"/sprint/scope?sprint="+sprint_name}>
                            <button className='btn btn-transparent'>Cancel</button>
                        </Link>
                        <Link to={"/release-package/add?path="+path}>
                        <button className='btn btn-primary'>Create Release Package</button>
                        </Link>
                    </div> :
                    null
            }

            <PaginationTwo
                count={state.count}
                total_count={state.total_count}
                current_page={state.current_page_count}
                on_pageNumber_click={handlePageNumberClick}
            />
            


            <DialogToMoveSeletedStories
                open={state.open}
                handleClickClose={handleClickClose}
                sendDataToParentComponent={validateAndSaveDataAndSendtoSingleState}
                sendDataToBulkMove={validateAndBulkMoveItems}
                path={path}
            />
        </div>
    )
}

export default MoveSprintTask