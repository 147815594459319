import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import GenerateURL from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';
import { Input } from '../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import StepProgressDetailed from '../../../components/genericComponents/StepProgressDetailed';
import { ValidateDataSet } from '../../../util/Validator';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        height: 'calc( 100vh - 70px )',
        overflow: 'auto',
        padding: '20px',
        position: 'relative',
        '& .input-component': {
            marginBottom: '0px'
        },
        '& .btn-outline-primary': {
            border: '2px solid #0086ff',

        },
        '& .grey__chip': {
            color: '#949494',
            border: ' 1px solid #E6E6E6',
            padding: '4px 6px 4px 6px',
            fontSize: '12px',
            textWrap: 'nowrap',
            fontWeight: 700,
            borderRadius: '6px',
            backgroundColor: '#F4F4F4',
            height: '29px'
        },
        '& .mt-4': {
            marginTop: '4px'
        }
    }
}))

const BulkMoveSprintTask = (props) => {
    const classes = useStyles();
    const location = useLocation();
    let parsedQueryString = queryString.parse(location.search);
    const path = parsedQueryString.path ? parsedQueryString.path : null;
    const app_name = parsedQueryString.app_name ? parsedQueryString.app_name : null;
    const [state, setState] = useState({
        data: {},
        error: {},
        validations: {},
        available_states: [],
        available_sprints: []
    })
    const commonFunction = getCommonFunctions(state, setState, {})

    useEffect(() => {
        fetchAdoStates()
    }, [])

    function fetchAdoStates() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.load_ado_states + "?app_name=" + app_name),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchAdoStatesDataSuccess, fetchAdoStatesDataFailed);

        setState(new_state => ({
            ...new_state,
            loading_states: true
        }))

    }

    function fetchAdoStatesDataSuccess(data) {
        fetchAdoSprints();
        let final_state_variable = data && data.final_validation_state ? data.final_validation_state : null
        let states_list = data.states && data.states.length > 0 ? data.states : [];
        let filtered_arr = states_list.filter(item => item !== final_state_variable);
        let available_states = filtered_arr && filtered_arr.length > 0 ? filtered_arr.map(item => {
          
                return { label: item, id: item }
            
            
        }) : [];


        setState(new_state => ({
            ...new_state,
            available_states: available_states,
            loading_states: false,
        }));

    }
    function fetchAdoStatesDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            loading_states: false
        }))
    }

    function fetchAdoSprints() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.load_ado_sprints + "?app_name=" + app_name+ "&next_sprint=" + true),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchAdoSprintsDataSuccess, fetchAdoSprintsDataFailed);

        setState(new_state => ({
            ...new_state,
            loading_states: true
        }))

    }

    function fetchAdoSprintsDataSuccess(data) {
        let sprint_list = data.sprint_list && data.sprint_list.values ? data.sprint_list.values.length > 0 ? data.sprint_list.values : [] : [];

        sprint_list.unshift({ name: 'Backlog', path: 'backlog' });

        setState(new_state => ({
            ...new_state,
            available_sprints: sprint_list,
            loading_states: false
        }));

    }
    function fetchAdoSprintsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            loading_states: false
        }))
    }
   
    const validateAndSaveData = () => {
        var result = ValidateDataSet(state.data, state.validations);
        console.log(result, "fsjkfdjsajfjska")
        if (result.valid) {
            let final_obj = {
                "app_name": app_name,
                "bulk_move": true,
                "current_path": path,
                "state":state.data
            }
            var url = GenerateURL({}, properties.api.ado_move_work_items);

            PostData(url, final_obj, postDataSuccess, postDataFailed);

            setState(new_state => ({
                ...new_state,
                loading_states: true
            }))
        } else {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }))
        }

    }
    const postDataSuccess = (response) => {
        setState(new_state => ({
            ...new_state,
            loading_workitems: false,
            task_id: response.task_id
        }))
    }

    const postDataFailed = (error) => {
        let error_msg = showErrorHandlerUpdated(error);
        setState(new_state => ({
            ...new_state,
            task_id: null,
            loading_workitems: false,
            error_msg: error_msg
        }))
    }
    const handleRefresh = () => {
        
        setState(new_state => ({
            ...new_state,
            task_id: null,

        }))
        fetchAdoStates()
    }

    return (
        <div className={classes.root}>
            {
                state.task_id ?
                    <StepProgressDetailed 
                    task_id={state.task_id} 
                    placeholders={{app_name:app_name, path:path}} 
                    refresh={handleRefresh} 
                    btnLabel="Continue" 
                    triggerBuildDeploy={handleRefresh}
                    variant={"SAME_PAGE_REDIRECT"}
                    vari
                    type="new" />
                    :
                    <>
                        <p className='font-18 font-weight-600 mb-24 mt-4'> Select the Sprint/Backlog for stories with corresponding state</p>
                        {
                            state.loading_states ?
                                <>
                                    <GenericSkeleton ariant={"rect"} height={"27px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                    <GenericSkeleton ariant={"rect"} height={"34px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                    <GenericSkeleton ariant={"rect"} height={"27px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                    <GenericSkeleton ariant={"rect"} height={"34px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                    <GenericSkeleton ariant={"rect"} height={"27px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                    <GenericSkeleton ariant={"rect"} height={"34px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                    <GenericSkeleton ariant={"rect"} height={"27px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                    <GenericSkeleton ariant={"rect"} height={"34px"} style={{ borderRadius: "6px", marginBottom: '12px' }} />
                                </> :

                                state.available_states.length > 0 ?

                                    state.available_states.map(item => {
                                        return <div className='mb-24'>
                                            <p className='font-14 font-weight-500 mb-12'> Where you want to move <span className='font-weight-600'>{item.label}</span> sprint items</p>
                                            <div className='d-grid ' style={{ gridTemplateColumns: '140px 24px 180px', gap: '12px', alignItems: 'center' }}>
                                                <div className='grey__chip text-center'>
                                                    {item.label}
                                                </div>
                                                <span className='ri-arrow-right-line font-24 ' style={{ color: '#787878' }}></span>
                                                <div>
                                                    <Input
                                                        type="select"
                                                        label=""
                                                        name={item.label}
                                                        list={state.available_sprints.map(item => {
                                                            return { label: item.name, id: item.path }
                                                        })}
                                                        onChangeHandler={commonFunction.onChangeHandler}
                                                        data={state.data}
                                                        error={state.error}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    :
                                    <p className='font-14 font-weight-500 '> Unable to load states.</p>
                        }
                        <div className='d-flex align-center flex-end' style={{ justifyContent: 'flex-end', width: '100%', position: 'absolute', bottom: 10, right: 10 }}>
                            <Link to={`/finalize/sprint-scope?app_name=${app_name}&path=${path}`}>
                            <button className='btn btn-transparent'>Cancel</button>
                            </Link>
                            <button className='btn btn-primary' onClick={validateAndSaveData}>Move</button>
                        </div>
                    </>}
        </div>
    )
}

export default BulkMoveSprintTask