import React from 'react';
import { makeStyles } from '@mui/styles';

const TopHeaderPremium = ({onClose}) => {

    const classes = useStyles();
    
    
    return (

        <div className={classes.top} style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        }
        }
        >
            <span  onClick={onClose}style={{position:'absolute',top:'15px', right:'15px', cursor:'pointer'}} className='ri-close-line font-20'></span>
            <div
                className='d-flex'>
                <img style={{
                    marginRight: '10px',
                    width: '92.55px',
                    height: '45.7px',

                }} src='/images/BP-new.svg' alt='buildpiper' />
                <div className='d-flex' style={{
                    flexDirection: 'column',
                    alignItems: 'baseline'
                }}>
                    <div className='bp-text'>
                        buildpiper
                    </div>
                    <div className='premium-op'>
                        premium
                    </div>
                </div>
            </div>

            <div className='p-text-info'>
                Switch to Premium to experience the Difference
            </div>
        </div>

    )
}

export default TopHeaderPremium;

const useStyles = makeStyles({
    top: {
        height: '143px',
        padding: '24px 16px 24px 16px',
        background: 'linear-gradient(88.57deg, #F5FAFF 35.54%, #FFE9C8 97.96%)',
        borderBottom: '1px solid #D0D0D0',
        borderTopLeftRadius:'12px',
        borderTopRightRadius:'12px',
        width: '100%',
        '& .p-text-info': {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
            textAlign: 'center',
            color: '#000000',
        },
        '& .bp-text': {
            fontSize: '23.38px',
            fontWeight: '700',
            lineHeight: '28.5px',
            textAlign: 'center',
            color: '#000000',
            textTransform: 'uppercase',
        },
        '& .premium-text': {
            fontSize: '15.59px',
            fontWeight: 700,
            lineHeight: '19px',
            textAlign: 'center',
            textTransform: 'uppercase',
        }
    },
});

