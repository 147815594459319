import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { Loading } from '../../../utils/Loading';
import { useLocation } from 'react-router-dom';
import MonitoringDialogue from '../../../service/detail/components/MonitoringDialogue';
import { ErrorComponent } from '../../../utils/Error';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';

const CanaryAnalysisReport = (props) => {
    const location = useLocation();
    const navigationState = location.state;

    const classes = useStyles();
    const chartRef = useRef(null);
    const {
        canary_analysis_data,
        canary_analysis_deployment_meta_data,
        complete_data,
        deploy_name,
        application_id,
        pipeline_id,
        pipeline_instance_id,
        canary_dep_name,
        selected_canary_dep_option,
        component_env_id,
        component_id,
        metric_data_name_arr,
        warmup_delay_duration,
        is_global_pipeline
    } = navigationState || {};
    // const data = props?.location.state?.canary_analysis_data;
    // const complete_data = props?.location?.state?.complete_data;
    // const deploy_name = props?.location?.state?.deploy_name;
    // const application_id = props?.location?.state?.application_id;
    // const pipeline_id = props?.location?.state?.pipeline_id;
    // const pipeline_instance_id = props?.location.state.pipeline_instance_id;
    // const canary_dep_name = props.location.state.canary_dep_name;
    // const selected_canary_dep_option = props.location.state.selected_canary_dep_option;
    // const component_env_id = props.location.state.component_env_id;
    // const component_id = props.location.state.component_id;
    // const metric_data_name_arr = props.location.state.metric_data_name_arr
    // const warmup_delay_duration = props.location.state.warmup_delay_duration
    // const canary_analysis_deployment_meta_data = props.location.state.canary_analysis_deployment_meta_data
    const inherits = props.inherits ? props.inherits : {};
    // const is_global_pipeline = props.location.state.is_global_pipeline
    console.log(component_id, component_env_id, "dbvgedfvgfd")
    const [state, setState] = useState({
        selectedTabOrder: 0,
        canaryData: {},
        counter: 0,
        data: {},
        error: {}
    })

    const commonFunctions = getCommonFunctions(state, setState, inherits);

    console.log(canary_analysis_deployment_meta_data, "sdfhsdbchjsdcsdcdhb")

    useEffect(() => {
        // fetchCanaryMetricData()
        setState((new_state) => ({
            ...new_state,
            canary_dep_name: getCanaryDeploymentName(state.data.deployment_name),
            dep_name_for_api_hit: getDepNameForApiHit(state.data.deployment_name)
        }))
    }, [state.counter, state.data.deployment_name])

    function getDepNameForApiHit(substr) {
        return metric_data_name_arr.find(item => item.includes(substr));
    }

    console.log(state, selected_canary_dep_option, "sdhvcsdghvbhf")

    useEffect(() => {
        fetchCanaryMetricData()
    }, [state.counter, state.dep_name_for_api_hit])

    useEffect(() => {
        let deployment_list = []
        metric_data_name_arr && metric_data_name_arr.forEach((item) => {
            let obj = {
                id: getCanaryDeploymentName(item),
                label: getCanaryDeploymentName(item)
            }

            deployment_list.push(obj);
        })

        setState((new_state) => ({
            ...new_state,
            deployment_list: deployment_list,
            canary_dep_name: canary_dep_name,
            data: {
                ...new_state.data,
                deployment_name: canary_dep_name,
            }
        }))
        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.chart.reflow();
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial reflow on mount
        if (chartRef.current) {
            chartRef.current.chart.reflow();
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    function getCanaryDeploymentName(data) {
        const parts = data.split('.');
        const middleParts = parts.slice(1, -1);
        return middleParts.join('-');
    }

    useEffect(() => {
        getCurrentMetricData();
        // setState((new_state) => ({
        //     ...new_state,
        //     current_metric_data: getCurrentMetricData()
        // }))
    }, [state.canaryData, state.selectedTabOrder])

    function fetchCanaryMetricData() {
        let myendpoint = GenerateURL({ task_id: complete_data.id, service_deployment_key: state.dep_name_for_api_hit ? state.dep_name_for_api_hit : deploy_name }, properties.api.canary_metric_data);

        var requestInfo = {
            endPoint: myendpoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setState(prevState => ({
            ...prevState,
            loading: true
        }))

        InvokeApi(requestInfo, chartDataFetchSuccess, chartDataFetchFailure);
    }

    function chartDataFetchSuccess(response) {
        console.log(response, "sbvhbfvhbfdhvbfd")
        setState((new_state) => ({
            ...new_state,
            canaryData: response,
            metric_tab_list: getMetricTabs(response),
            metrices_count: Object.keys(response).length,
            // loading: false,
        }))
        fetchMetaData()

    }

    function chartDataFetchFailure(error) {
        setState((new_state) => ({
            ...new_state,
            error: error,
            loading: false,
        }))
    }

    function fetchMetaData() {
        let myendpoint = GenerateURL({ task_id: complete_data.id, service_deployment_key: state.dep_name_for_api_hit ? state.dep_name_for_api_hit : deploy_name }, properties.api.canary_meta_data);

        var requestInfo = {
            endPoint: myendpoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, metaDataFetchSuccess, metaDataFetchFailure);

        setState(prevState => ({
            ...prevState,
            loading: true
        }))
    }

    function metaDataFetchSuccess(response) {
        console.log(response, "sbvhbfvhbfdhvbfdbdshbvhg")
        setState((new_state) => ({
            ...new_state,
            canary_meta_data: response,
            loading: false,
        }))
    }

    function metaDataFetchFailure(error) {
        setState((new_state) => ({
            ...new_state,
            error: error,
            loading: false,
        }))
    }

    function getMetricTabs(response) {
        let data = response
        let metrics = Object.keys(data)

        let tabs = []
        metrics.forEach((element, index) => {
            let obj = {
                tabName: element,
                order: index,
                status: data[element].status
            }

            tabs.push(obj)
        })

        return tabs;
    }

    function getCurrentMetricData() {
        let selectedTab = state.metric_tab_list && state.metric_tab_list.find((item) => item.order == state.selectedTabOrder)
        let selectedTabName = selectedTab?.tabName
        let metrices = Object.keys(state.canaryData)
        console.log(state.canaryData, selectedTabName, metrices, "sdnfsdhbjhdsbhjcbsdh")
        let selected_metric = metrices.find((item) => item.includes(selectedTabName))
        let selected_metric_data = state.canaryData[selected_metric]

        setState((new_state) => ({
            ...new_state,
            selected_metric_data: selected_metric_data,
            metric_graph_data: getMetricGraphData(selected_metric_data),
            start_time: getAnalysisStartTime(selected_metric_data),
            end_time: getAnalysisEndTime(selected_metric_data)
        }))
    }

    function getAnalysisStartTime(data) {
        console.log(data, "sbvjsdvbhss")
        let time = data && data.metric_data && data.metric_data[0] && data.metric_data[0].date
        let formatted_time = getDateWithTime(time)
        return formatted_time;
    }

    function getAnalysisEndTime(data) {
        let time = data && data.metric_data && data.metric_data[data.metric_data.length - 1] && data.metric_data[data.metric_data.length - 1].date
        let formatted_time = getDateWithTime(time)
        return formatted_time;
    }

    function getEquallySpacedData(data, numItems) {
        const totalItems = data.length;

        if (totalItems <= numItems) {
            return data;  // If there are fewer or equal items than requested, return all data
        }

        // Include the first and last objects
        const result = [data[0]];

        // Calculate the spacing between items
        const interval = (totalItems - 2) / (numItems - 2); // -2 to exclude the first and last objects

        for (let i = 1; i < numItems - 1; i++) {
            const index = Math.round(interval * i);
            result.push(data[index]);
        }

        // Add the last object
        result.push(data[totalItems - 1]);

        return result;
    }

    function addMinutesToTime(startTime) {
        // Split the time string into hours, minutes, and seconds
        let [hours, minutes, seconds] = startTime.split(":").map(Number);

        // Add 2 minutes
        minutes += warmup_delay_duration;

        // Handle overflow for minutes
        if (minutes >= 60) {
            minutes -= 60;
            hours += 1;
        }

        // Handle overflow for hours (in case you want to wrap to 00 after 23 hours)
        if (hours >= 24) {
            hours = 0;
        }

        // Format the result back to "HH:mm:ss" format
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }



    function getMetricGraphData(selected_metric_data) {
        let data = selected_metric_data?.metric_data
        let new_data;
        if (data) {
            new_data = getEquallySpacedData(data, 20);
        }
        console.log(data, "sgvhjhjbfkhvbhfd")
        let canary_data = []
        let baseline_data = []
        let baseline_threshold_data = []
        let baseline_critical_threshold_data = []
        let categories = []

        let updated_data;

        if (new_data) {
            new_data && new_data.forEach((item) => {
                canary_data.push(Number(item.canary_metric_value))
                baseline_data.push(Number(item.baseline_metric_value))
                baseline_threshold_data.push(Number(item.baseline_threshold))
                baseline_critical_threshold_data.push(Number(item.baseline_critical_threshold))
                categories.push(getCustomTime(item.date))
            })
            let startTime = getCustomTime(new_data[0]?.date);
            let minutesAfterStart = addMinutesToTime(startTime)
            let minutesIndex = categories.findIndex(category => new Date(category) >= minutesAfterStart);

            if (minutesIndex === -1) {
                // If not found, calculate exact position based on the time difference
                let firstCategoryTime = new Date(`1970-01-01T${categories[0]}Z`).getTime();
                let lastCategoryTime = new Date(`1970-01-01T${categories[categories.length - 1]}Z`).getTime();
                let twoMinutesTime = new Date(`1970-01-01T${minutesAfterStart}Z`).getTime();

                let totalTimeRange = lastCategoryTime - firstCategoryTime;
                let relativePosition = (twoMinutesTime - firstCategoryTime) / totalTimeRange;

                // Calculate the exact value for the x-axis based on the time range
                minutesIndex = relativePosition * (categories.length - 1);
            }

            console.log(startTime, "sjvbdcgsdvcsd")
            console.log(minutesAfterStart, "sjvbdcgsdvcsd")
            console.log(minutesIndex, "sjvbdcgsdvcsd")

            updated_data = {
                chart: {
                    type: 'spline',

                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    ...data.xAxis,
                    categories: [...categories],
                    plotLines: [{
                        color: '#808080', // Set a different color for the vertical line
                        width: 2,
                        value: minutesIndex, // Index of the x-axis where the line should be drawn
                        dashStyle: 'Dash',
                        // label: {
                        //     text : "warmup duration ends",
                        //     align: 'left',
                        //     style: {
                        //         color: '#FF5733'
                        //     }
                        // }
                    }]
                },
                series: [{
                    name: 'Canary',
                    color: "#0086ff",
                    data: [...canary_data]
                }, {
                    name: 'Baseline',
                    data: [...baseline_data],
                    color: "#2EBE79",
                }, {
                    name: 'Baseline Threshold',
                    color: "#FEA11180",
                    data: [...baseline_threshold_data],
                    // dashStyle: 'longdash'
                }, {
                    name: 'Baseline Critical Threshold',
                    color: "#E53737",
                    data: [...baseline_critical_threshold_data],
                    // dashStyle: 'longdash'
                },
                {
                    name: 'Warmup duration ends',
                    type: 'line',
                    color: '#808080', // Match the color of the vertical line
                    marker: { enabled: false }, // No marker
                    enableMouseTracking: false, // Disable hover and interaction for this series
                    showInLegend: true, // Show it in the legend
                    data: [] // No actual data points for this series
                }],
            }

        }

        console.log(canary_data, baseline_data, categories, "sgvhjhjbfkhvbhfd")

        return updated_data;

    }

    function getCustomTime(timestamp) {
        console.log(timestamp, "sbvfbvjhfhjvfvfhdh");

        // Create a Date object from the ISO string
        const date = new Date(timestamp);

        // Get the components of the time in UTC
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

        // Format the time as "HH:MM:SS"
        return `${hours}:${minutes}:${seconds}`;
    }

    function getDateWithTime(timestamp) {
        console.log(timestamp, "sbvfbvjhfhjvfvfhdh")
        // Create a Date object from the ISO string
        const date = new Date(timestamp);

        // Get the components of the date in UTC
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');

        // Format the date as "YYYY-MM-DD HH:MM"
        return `${year}-${month}-${day} ${hours}:${minutes}`
    }

    console.log(state, "fsgvjfdhvhfdhvf")

    const updateStateHandler = (tab_order) => {
        setState(new_state => ({
            ...new_state,
            selectedTabOrder: tab_order,
        }));
    }

    function handleDataRefresh() {
        setState((new_state) => ({
            ...new_state,
            counter: new_state.counter + 1
        }))
    }

    console.log(state, "sdhvbsdhbvhjsbvf")

    function getCanaryDeploymentName(data) {
        if (data) {
            const parts = data.split('.');
            const middleParts = parts.slice(1, -1);
            return middleParts.join('-');
        }
    }

    function getDeploymentList(metric_data_name_arr) {
        let list = []
        metric_data_name_arr && metric_data_name_arr.forEach((name) => {
            list.push({ id: name, label: name })
        })

        console.log(list, "sdbvhjbsdvhshv")

        return list;
    }

    return (
        <div className={classes.root}>
            {
                is_global_pipeline ?
                    <Link to={
                        {
                            pathname: "/global/pipeline/" + pipeline_id + "/execution/" + pipeline_instance_id,
                        }
                    }
                    >
                        <div className='back-button'>
                            <span className='ri-arrow-left-line' style={{ color: "#0086FF" }}></span>
                            <p style={{ marginLeft: "4px", fontFamily: "Montserrat", fontSize: "12px", fontWeight: "500" }}>GO BACK</p>
                        </div>
                    </Link>
                    :
                    <Link to={
                        {
                            pathname: "/application/" + application_id + "/pipeline/" + pipeline_id + "/execution/" + pipeline_instance_id,
                        }
                    }
                    >
                        <div className='back-button'>
                            <span className='ri-arrow-left-line' style={{ color: "#0086FF" }}></span>
                            <p style={{ marginLeft: "4px", fontSize: "12px", fontWeight: "500", fontFamily: "Montserrat" }}>GO BACK</p>
                        </div>
                    </Link>

            }
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className='font-16 font-weight-500' style={{ color: '#2f2f2f' }}>
                        {state.canary_dep_name} <span className='metric-chip'>{state.metrices_count} METRICES</span>
                    </div>
                </div>
                <div className='ml-auto' style={{ display: "flex", alignItems: "center" }}>
                    <div className='d-grid align-center mb-0 input-mb-0' style={{ width: '400px', marginLeft: 10, gridTemplateColumns: '115px 1fr' }}>
                        <div className="font-11">Select Deployment: </div>
                        <Input
                            type="select-for-multideployment"
                            name="deployment_name"
                            list={state.deployment_list}
                            onChangeHandler={commonFunctions.onChangeHandler}
                            label=""
                            remove_default_value={true}
                            data={state.data}
                            error={state.error}
                        />
                    </div>
                    <Link style={{ marginLeft: "15px" }} target="_blank" to={{
                        pathname: `/deployment-analytics`,
                        search: `application_id=${encodeURIComponent(application_id)}&service_id=${encodeURIComponent(canary_analysis_deployment_meta_data.baseline_deployment_metadata.service_id)}&env_id=${encodeURIComponent(canary_analysis_deployment_meta_data.baseline_deployment_metadata.service_env_id)}&env_name=${encodeURIComponent(canary_analysis_deployment_meta_data.baseline_deployment_metadata.application_env)}&service_data=${encodeURIComponent(JSON.stringify({ name: canary_analysis_deployment_meta_data.baseline_deployment_metadata.service, type: canary_analysis_deployment_meta_data.baseline_deployment_metadata.environment_master }))}&all_available_deployments=${encodeURIComponent(JSON.stringify(state.deployment_list))}&selected_dep_name=${encodeURIComponent(state.data.deployment_name)}&type=${encodeURIComponent("canary")}`,
                        // search: `application_id=${application_id}&service_id=${canary_analysis_deployment_meta_data.baseline_deployment_metadata.service_id}&env_id=${canary_analysis_deployment_meta_data.baseline_deployment_metadata.service_env_id}&env_name=${canary_analysis_deployment_meta_data.baseline_deployment_metadata.application_env}&service_name=${canary_analysis_deployment_meta_data.baseline_deployment_metadata.service}&env_name=${canary_analysis_deployment_meta_data.baseline_deployment_metadata.environment_master}&selected_dep_name=${state.data.deployment_name}&type=${"canary"}`
                    }}>
                        <button className="btn-sq-icon-primary d-flex align-center" >
                            <span className="ri-airplay-line vertical-align-super"></span>
                        </button>
                    </Link>
                    <Tooltip title="Refresh data">
                        <span className='ri-refresh-line' style={{ fontSize: "24px", cursor: "pointer", marginLeft: "15px" }} onClick={handleDataRefresh}></span>
                    </Tooltip>
                </div>
            </div>
            {
                <div className="canary_analysis_card" style={{ marginTop: "20px" }}>
                    {
                        state.loading ?
                            // <Loading />
                            <CanaryAnalysisReportSkeleton />
                            :
                            Object.keys(state.canaryData).length > 0 ?
                                <>
                                    <div className='canary_analysis_card_header'>
                                        <div className='d-flex align-center font-12 space-between ' style={{ width: '100%' }}>
                                            <p style={{ color: '#505050' }}>METRICS</p>
                                            <p style={{ color: '#505050' }}>RESULTS</p>
                                        </div>
                                        <p className='ml-auto font-12'>
                                            <span className='key' style={{ color: '#787878' }}>Start Time: </span>
                                            <span className='value' style={{ color: '#2f2f2f' }}>{state.start_time}</span>&nbsp;&nbsp;
                                            {/* {
                                            state.selected_metric_data && state.selected_metric_data.status == "FAILED" || state.selectedTabName.status == "SUCCESS" &&
                                            <>
                                                <span className='key' style={{ color: '#787878' }}>End Time: </span>
                                                <span className='value' style={{ color: '#2f2f2f' }}>{state.end_time}</span>
                                            </>
                                        } */}
                                        </p>
                                    </div>
                                    <div className='canary_analysis_card_body' style={{ width: '100%' }}>
                                        <div className='canary_analysis_card_body_particals'>
                                            <CommonHorizontalTab
                                                tabList={state.metric_tab_list ? state.metric_tab_list : []}
                                                variant="canary_analysis_report_tab"
                                                selectedTabOrder={state.selectedTabOrder}
                                                updateSelectedTab={updateStateHandler}
                                            />
                                        </div>
                                        <div style={{ backgroundColor: "#FAFAFA" }}>
                                            <div className='header-section mb-20' style={{ backgroundColor: "#FAFAFA", marginLeft: "20px" }}>
                                                <div style={{ borderRight: '1px solid #E6E6E6', marginRight: '20px' }}>
                                                    <p className='font-12 font-weight-600' style={{ color: "#2EBE79" }}>BASELINE</p>
                                                    <div className='font-12 d-flex align-center' >
                                                        <span className='key' style={{ color: '#787878' }}>Artifact: </span>
                                                        <Tooltip title={state.canary_meta_data?.baseline_deployment_metadata?.deployment_artifact}>
                                                            <span className='value text-ellipsis-200' style={{ /* color: '#2EBE79', */ display: "inline-block" }}>{state.canary_meta_data?.baseline_deployment_metadata?.deployment_artifact}</span>
                                                        </Tooltip>

                                                    </div>
                                                </div>
                                                <div style={{ marginRight: '20px' }}>
                                                    <p className='font-12 font-weight-600' style={{ color: "#0086ff" }}>CANARY</p>
                                                    <div className='font-12 d-flex align-center' >
                                                        <span className='key' style={{ color: '#787878' }}>Artifact: </span>
                                                        <Tooltip title={state.canary_meta_data?.canary_deployment_metadata?.deployment_artifact}>
                                                            <span style={{/*  color: '#0086ff', */ display: "inline-block" }} className='value text-ellipsis-200'> {state.canary_meta_data?.canary_deployment_metadata?.deployment_artifact}</span>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ marginLeft: "20px" }}>
                                                <HighchartsReact highcharts={Highcharts} options={state.metric_graph_data ? state.metric_graph_data : {}} /* ref={chartRef} */ />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                // <ErrorComponent variant="message-box" error="" />
                                <div className='no-data-view'>
                                    <div className='logo-div'>
                                        <span className='ri-database-2-fill' style={{ color: "#CACACA", height: "45.13px", width: "42.75px" }}></span>
                                    </div>
                                    <p>Please Wait...</p>
                                    <p>The analysis has just started. Data not generated yet.</p>
                                </div>
                    }
                </div>

            }
        </div>
    )
}

//state.metric_graph_data ? state.metric_graph_data : { }

export default CanaryAnalysisReport

const useStyles = makeStyles(() => ({
    root: {
        padding: 20,

        "& .back-button": {
            display: "flex",
            alignItems: "center",
            color: "#0086FF",
        },
        '& .metric-chip': {
            backgroundColor: '#0086ff',
            color: '#fff',
            borderRadius: '4px',
            padding: '4px 6px',
            fontSize: 12,
            fontWeight: 500,
        },
        '& .header-section': {
            backgroundColor: '#fff',
            padding: '20px',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            borderRadius: 4
        },
        '& .canary_analysis_card': {
            minHeight: "400px",
            borderRadius: '4px',
            background: '#FFF',
            boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.08)",

            "& .no-data-view": {
                // border: "1px solid #E6E6E6",
                borderRadius: "6px",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",

                "& .logo-div": {
                    marginTop: "50px",
                    width: "109px",
                    height: "109px",
                    borderRadius: "50%",
                    backgroundColor: "#F4F4F4",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                }

            }
        },
        '& .canary_analysis_card_header': {
            borderBottom: '1px solid #E5E5E5',
            backgroundColor: '#F5FAFF',
            padding: '12px',
            display: 'grid',
            gridTemplateColumns: '200px 1fr'
        },
        '& .canary_analysis_card_body': {
            display: 'grid',
            gridTemplateColumns: '235px 1fr'
        },
        '& .canary_analysis_card_body_particals': {
            height: '100%',
            overflowY: 'auto',
            borderRight: "1px solid #E5E5E5"
        },
        '& .success_chip_particals': {
            fontSize: '12px',
            fontWeight: '600',
            color: '#129E5B',
            backgroundColor: '#E6FBEA',
            borderRadius: '4px',
            padding: '2px 4px'
        },
        '& .failed_chip_particals': {
            fontSize: '12px',
            fontWeight: '600',
            color: '#C11212',
            backgroundColor: '#FFEBEB',
            borderRadius: '4px',
            padding: '2px 4px'
        },
        '& .canary_analysis_card_body_particals_row': {
            cursor: 'pointer',
            '&.active': {
                backgroundColor: '#DFEDFF',
                '&:hover': {
                    backgroundColor: '#DFEDFF'
                }
            },
            '&:hover': {
                backgroundColor: '#fafafa'
            }
        },
        "& .no-data-info": {
            fontFamily: "Monsterrat",
            fontSize: '16px',
            fontWeight: '500',
            color: "rgb(47, 47, 47)"
        }
    }
}));


const CanaryAnalysisReportSkeleton = () => {
    return (
        <>
            <div className='canary_analysis_card_header'>
                <div className='d-flex align-center font-12 space-between' style={{ width: '100%' }}>
                    <p style={{ color: '#505050' }}>METRICS</p>
                    <p style={{ color: '#505050' }}>RESULTS</p>
                </div>
                <p className='ml-auto font-12 d-flex align-center' style={{ gap: '4px' }}>
                    <span className='key' style={{ color: '#787878' }}>Start Time: </span>
                    <GenericSkeleton variant='text' width='60px' height='25px' />
                    {/* 
                    {
                        state.selected_metric_data && state.selected_metric_data.status === "FAILED" || 
                        state.selectedTabName.status === "SUCCESS" &&
                        <>
                            <span className='key' style={{ color: '#787878' }}>End Time: </span>
                            <span className='value' style={{ color: '#2f2f2f' }}>{state.end_time}</span>
                        </>
                    } 
                    */}
                </p>
            </div>

            <div className='canary_analysis_card_body' style={{ width: '100%' }}>
                <div className='canary_analysis_card_body_particals'>
                    <GenericSkeleton variant='rect' width='100%' height='100%' rootStyle={{ height: '100%' }} />
                </div>

                <div style={{ backgroundColor: "#FAFAFA" }}>
                    <div className='header-section mb-20' style={{ backgroundColor: "#FAFAFA", marginLeft: "20px" }}>
                        <div style={{ borderRight: '1px solid #E6E6E6', marginRight: '20px' }}>
                            <GenericSkeleton variant='rect' width='100%' height='36px' />
                        </div>
                        <div style={{ borderRight: '1px solid #E6E6E6', marginRight: '20px' }}>
                            <GenericSkeleton variant='rect' width='100%' height='36px' />
                        </div>
                        {/* 
                        <div>
                            <p className='text-anchor-blue font-12 font-weight-600'>THRESHOLD</p>
                            <div className='font-12'>
                                <span className='key' style={{ color: '#787878' }}>Marginal: </span>
                                <span className='value' style={{ color: '#2f2f2f' }}>75</span>
                                &nbsp;&nbsp;
                                <span className='key' style={{ color: '#787878' }}>Pass: </span>
                                <span className='value' style={{ color: '#2f2f2f' }}>95</span>
                            </div>
                        </div> 
                        */}
                    </div>

                    <div style={{ marginLeft: "40px" }}>
                        <GenericSkeleton variant='rect' width='100%' height='400px' />
                    </div>
                </div>
            </div>
        </>
    );
};

