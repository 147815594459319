import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { FileUploadDefaultState } from '../../../../components/genericComponents/Forms/FileUpload';

const JiraReferenceIds = ({ parentComponentState, resetParentState, ...props }) => {
    const classes = useStyles();
    const prev_state = props.prev_state ? props.prev_state : null;
    
    const [state, setState] = useState(parentComponentState ? defaultStateJiraIds(parentComponentState) : defaultStateJiraIds())
        


    useEffect(() => {

        if (parentComponentState) {
            if(parentComponentState.id){
                setState(prevState => ({
                    ...prevState,
                    data: { ...parentComponentState },
                    
                }))
            }
            else{
                setState(prevState => ({
                    ...prevState,
                    data: { ...parentComponentState },
                }))
            }   
        }
    }, [parentComponentState])

    const inherits = props.inherits ? props.inherits : {}


    const commonFunctions = getCommonFunctions(state, setState, inherits)

    const application_id = props.application_id

    const fetchDataAfterSubmit = props.fetchDataAfterSubmit



    const resetState = () => {
        if (state.data.jira_id_validation)

            setState((prevState) => ({

                ...prevState,
                data: {
                    jira_reference_key: '',
                },
            }))

        else

            setState((prevState) => ({

                ...prevState,
                data: {
                    jira_reference_key: '',
                },
            }))
        resetParentState()
    }
    function getFileUploadState() {
        var state_temp = FileUploadDefaultState();
        if (state.fileUpload) {
            state.fileUpload.files.forEach(file_name => {
                if (typeof (file_name) == "string") {
                    state_temp.form_data.data.files.push({
                        name: file_name
                    });
                } else {
                    state_temp.form_data.data.files.push(file_name);
                }
            });
        }
        return state_temp;
    }
    const validateAndSaveData = () => {
       props.validateAndSave();
       resetState()
    }
    return (
        <>
            <div className='mt-20'>
                <Grid
                    className={`pr-15 pl-15 bg-fc-grey border-top border-bottom border-radius-0 ${classes.cardHeader}`}
                    container
                    style={{ height: '40px' }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >

                    <label className='font-13 ' style={{color: '#585858'}}>Give details of ticket required?</label>
                    <button className="transparent-btn nowrap" onClick={()=>{props.closeForm()}}>
                         <span className='ri-close-line font-24' style={{ color: 'black' }}></span>
                    </button>

                </Grid>

                <div className={classes.formCard}>
                    <Grid className='mb-5' container alignItems="center" >

                        <Grid item lg={12} className="pr-10 pl-10">
                            
                         <Input
                                type="text"
                                name="jira_reference_key"
                                placeholder="ex: (pod-release-tick)"
                                mandatorySign
                                label="Name the ticket category "
                                onChangeHandler={commonFunctions.onChangeHandler}
                                data={state.data}
                                error={state.error}
                            />
                        </Grid>
                       
                    </Grid>
                </div>
                <Grid
                    className={`pr-15 pl-15 bg-fc-grey border-top  border-radius-0 ${classes.cardHeader}`}
                    container
                    style={{ height: '40px' }}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <div className="d-flex">
                        <button className="transparent-btn nowrap mr-10" style={{ color: 'black' }} onClick={resetState}>
                            <span className='ri-close-line font-24' style={{ color: 'black' }}></span>
                            Clear
                        </button>
                        <button className="transparent-btn nowrap" onClick={validateAndSaveData}>
                            <i className='ri-save-line' style={{ color: 'blue' }} /> Save
                        </button>
                    </div>

                </Grid>

            </div>

        </>

    )

}
export default JiraReferenceIds

function defaultStateJiraIds (prev_state) {
    return  {
        data: prev_state ? {
            ...prev_state
        }:
        {
            jira_id_validation: false
        },
        final_data_arr: [],
        error: {},
        validations: {
            jira_reference_key: [VALIDATION_TYPE_REQUIRED],
        },
        // fileUpload: {},
        // child_inherits: {
        //     fileUpload: {
        //         "validateForm": () => { return { valid: true } },
        //         "getState": () => { return {} },
        //         "getData": () => { return {} }
        //     }
        // }
    }
}

const useStyles = makeStyles(theme => ({

    formCard: {
        backgroundColor: '#fff',
        paddingBottom: '10px',
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingTop: '4px',
        '& .mb-00':{
            marginTop: '20px',
            '& .input-component':{
                marginBottom: '0px',}
        },
        '& .full-width-switch':{
            width: '100%',
            "& .card-header":{
                backgroundColor:'#fcfcfc',
                height:'auto',
                borderTop: '1px solid #dedede',
                '& .heading':{
                    color: '#585858',
                }
            }
        },
        '& .pd-0': {
            '& .pd-10': {
                padding: '0px!important'
            }
        },
        '& .input-with-static-value':{
            border:'1px solid #b7b7b7',
            height: '42px',
            gridTemplateColumns:'75px 1fr', 
            overflow:'hidden',  
            borderRadius:'4px',
            '& .input-component':{
                marginBottom: '0px',
                '& label':{
                    display:'none'
                },
                '& input':{
                    border:'none',
                    '&:focus':{
                        border: 'none!important',
                        borderRadius: '0!important'
                    }
                }
            }
            
        }
    },

    cardHeader: {
        // border: '1px solid #eaeaea',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    }
}))

