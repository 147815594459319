import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CommonHorizontalTab from '../../../../../../components/genericComponents/CommonHorizontalTab';
import PodEvent from './PodEvent';
import PodMatrix, { SectionTitle, MainDiv } from './PodMatrix';
import PodsConditions from './PodsConditions';
import moment from 'moment';
import PodsContainers from './PodsContainers';
import LogSection from '../../deployment/components/LogSection';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import InvokeApi from '../../../../../../util/apiInvoker';
export default function PodDetailedCard(props) {
    const selected_pod = props.selected_pod ? props.selected_pod : "";
    const service_id = props.service_id;
    const env_id = props.env_id;
    const cd_id = props.cd_id;
    const [state, setState] = useState({
        selectedTab: 1,
        pod_event_data: null,
        logs_arr: []
    })
    const pod_matrix = props.pod_matrix && props.pod_matrix.length > 0 ? props.pod_matrix.find(item => item.deployment_name == cd_id).monitoring.infra : null;
    console.log(cd_id, props.pod_matrix, "hdudcdbcbdhdc")
    const updateSelectedTab = (selectedTab) => {
        setState(new_state => ({
            ...new_state,
            selectedTab: selectedTab,
        }));
    }

    var list2 = [
        {
            order: 1,
            tabName: 'Conditions'
        },
        {
            order: 2,
            tabName: 'Events'
        },

        {
            order: 3,
            tabName: 'Containers'
        },
        {
            order: 4,
            tabName: 'Logs'
        }
    ]
    useEffect(() => {
        loadPodEvents();

    }, [selected_pod?.metadata?.name]);

    function loadPodEvents() {
        var requestInfo = {
            // endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, cd_id: cd_id, pod_name: selected_pod.metadata ? selected_pod.metadata.name : null  }, properties.api.pod_event),
            endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, pod_name: selected_pod.metadata ? selected_pod.metadata.name : null }, properties.api.pod_event_widget + `?deployment=${cd_id}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, loadPodEventsSuccess, loadPodEventsError);
    }

    function loadPodEventsSuccess(response) {
        console.log(response, "sdnckjsdnjks")
        setState(new_state => ({
            ...new_state,
            pod_event_data: response.items,
            pods_event_data_loaded: true
        }));
        // loadLogsForPod();
    }


    function loadPodEventsError(error) {
        var error_message = "Pods Events not loaded"
        error_message = error_message + error.toString()
        setState(new_state => ({
            ...new_state,
            pods_event_data_loaded: true,
            pod_event_error: error_message
        }));
    }

    console.log(selected_pod, "fsakjdfjsajfsa")

    return (

        <div className="card pod-card ">

            <div className="card-header font-12">
                <span className="">POD Name: </span>
                <span className="text-grey-b6">{selected_pod ? selected_pod.metadata.name : 'Not Available'}</span>
            </div>
            <div className="card-body">
                <div className="d-flex  space-between">
                    <div className="pd-10">
                        <p className="font-11 text-grey-b6 mb-10">Pod Status:
                            {selected_pod ?

                                <>
                                    {selected_pod.pod_status.status == "Pending" ?
                                        <span className="font-11 color-dark d-inline-flex align-center gap-5"><i className="ri-checkbox-circle-fill font-15 color-pending v-align-middle" /> {selected_pod.pod_status.status}</span> :
                                        selected_pod.pod_status.status == "Ready" ?
                                            <span className="font-11 color-dark d-inline-flex align-center gap-5"><i className="ri-checkbox-circle-fill font-15 color-success v-align-middle" /> {selected_pod.pod_status.status}</span> :
                                            selected_pod.pod_status.status == "Terminating" ?
                                                <span className="font-11 color-dark d-inline-flex align-center gap-5"><i className="ri-checkbox-circle-fill font-15 color-success v-align-middle" /> {selected_pod.pod_status.status}</span> :
                                                selected_pod.pod_status.status == "Failed" ?
                                                    <span className="font-11 color-dark d-inline-flex align-center gap-5"><i className="ri-checkbox-circle-fill font-15 color-Danger v-align-middle" /> {selected_pod.pod_status.status}</span> :
                                                    selected_pod.pod_status.status == "Succeeded" ?
                                                        <span className="font-11 color-dark d-inline-flex align-center gap-5"><i className="ri-checkbox-circle-fill font-15 color-success v-align-middle" /> {selected_pod.pod_status.status}</span> :
                                                        selected_pod.pod_status.status == "Unknown" ?
                                                            <span className="font-11 color-dark d-inline-flex align-center gap-5"><i className="ri-checkbox-circle-fill font-15 v-align-middle" style={{ color: '#c294f1' }} /> {selected_pod.pod_status.status}</span> :
                                                            selected_pod.pod_status.status == "Containers_Not_Ready" ?
                                                                <span className="font-11 color-dark d-inline-flex align-center gap-5"><i className="ri-checkbox-circle-fill font-15 v-align-middle" style={{ color: '#FFCB2E' }} /> {selected_pod.pod_status.status}</span> :
                                                                <span className="font-11 color-dark d-inline-flex align-center gap-5"> <span className="ri-close-circle-fill font-15 v-align-middle" style={{ color: '#FF6961' }} ></span>{selected_pod.pod_status.status}</span>
                                    }
                                </>

                                : <span className="font-11 color-dark">N/A</span>
                            }
                        </p>
                        <p className="font-11"><span className="color-dark">Created: </span> <span className="text-grey-b6">{selected_pod ? moment(selected_pod.metadata.creationTimestamp).fromNow() : "N/A"}</span></p>

                        <p className="font-11"><span className="color-dark">POD Uptime: </span> <span className="text-grey-b6">{selected_pod ? moment(selected_pod.status.startTime).fromNow() : "N/A"}</span></p>

                        <p className="font-11"><span className="color-dark">Status: </span> <span className="text-grey-b6">{selected_pod.pod_status ? selected_pod.pod_status.status : "N/A"}</span></p>
                        <p className="font-11"><span className="color-dark">Message: </span> <span className="text-grey-b6">{selected_pod.pod_status ? selected_pod.pod_status.message : "N/A"}</span></p>

                    </div>
                    <div className="pd-10">


                        {pod_matrix ?
                            <PodMatrix pod_matrix={pod_matrix} />
                            :
                            <MainDiv>
                                <SectionTitle>Pod Matrix</SectionTitle>
                                <div className="progress-container height-12 font-12">
                                    No Data Available
                                </div>
                            </MainDiv>
                        }



                    </div>
                    <div className="pd-10 max-width-400">
                        <div className="color-dark font-11">Labels: </div>
                        <div className="chip-default grey">
                            pod-template-hash=66d6977db6
                        </div>
                        <div className="chip-default grey">
                            pod-hash-hash=9988434db32
                        </div>
                        <div className="chip-default grey">
                            k8s-app=kube-dns
                        </div>
                        <div className="chip-default grey">
                            pod-hash-hash=9988434db32
                        </div>
                    </div>
                </div>
                <div className="bottom-panel">
                    <CommonHorizontalTab
                        tabList={list2}
                        selectedTabOrder={state.selectedTab}
                        updateSelectedTab={updateSelectedTab}
                    />
                    <div style={{ minHeight: 200 }}>
                        {
                            state.selectedTab == 2 &&
                            <PodEvent events={state.pod_event_data} />
                        }
                        {
                            state.selectedTab == 1 &&
                            <PodsConditions conditions={selected_pod ? selected_pod.status.conditions : null} />
                        }
                        {
                            state.selectedTab == 3 &&
                            <PodsContainers containers={selected_pod ? selected_pod.status.containerStatuses : null} />
                        }
                        {
                            state.selectedTab == 4 &&
                            <LogSection
                                // loading_logs={state.loading_logs}
                                selected_pod={selected_pod}
                                selected_pod_name={selected_pod && selected_pod.metadata ? selected_pod.metadata.name : "No Pods Available"}
                                service_id={service_id}
                                cd_id={cd_id}
                                env_id={env_id}
                            // logs={state.logs}
                            // refreshTab={loadLogsForPod}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

PodDetailedCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}