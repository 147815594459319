import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

//import { Input } from '../../../../components/genericComponents/Input';

import GitRepo, { GitRepoDefaultState } from '../../../../components/genericComponents/Forms/GitRepo';
import { ValidateDataSet } from '../../../../util/Validator';

import { Loading } from '../../../utils/Loading';
import BuildX from './BuildX';
import { Input } from '../../../../components/genericComponents/Input';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { getListableArray } from '../../../../util/util';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '3rem'
  },
}));



const SourceDetails = props => {
  const data = props.data;
  const roles = props.roles;
  const classes = useStyles();
  const extraProps = props.extraProps;
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const child_build = props.child_build
  const application_id = props.application_id
  const shallow_cloning = props.shallow_cloning
  const depth = props.depth;
  const is_add=props.is_add;
  const [state, setState] = useState(getDefaultSourceDetailsState(extraProps))
  const [showLoading, setShowLoading] = useState(false)
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  console.log(child_build, "bjhadbhcjsdvsdjhcvds")

  useEffect(() => {
    fetchJobTemplatesList()
  }, [])


  useEffect(() => {
    if (state?.job_template_list?.length > 0 && state.job_template_version) {
      const v2Templates = state.job_template_list.filter(tempalte => tempalte.version === 'v2')
      setState(prevState => ({
        ...prevState,
        fileterdByVersionTemplates: v2Templates
      }))
    }

    // support for default job template auto selected applied.
    const default_template = state.job_template_list?.find(tempalte => tempalte.default == true)
    console.log(default_template, "svsvvbfvsbfv")
    if (default_template && !prev_state) {
      setState((new_state) => ({
        ...new_state,
        data: {
          ...new_state.data,
          project_job_template_id: default_template.id
        }
      }))
    }
  }, [state.job_template_list])

  function fetchJobTemplatesList() {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.get_all_job_template + "?all=true"
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    InvokeApi(
      requestInfo,
      fetchJobTemplatesListSuccess,
      fetchJobTemplatesListFailure
    );
    setShowLoading(true)
  }
  function fetchJobTemplatesListSuccess(response) {

    setState((new_state) => ({
      ...new_state,
      job_template_list: response?.results ? response.results : [],
    }));
    setShowLoading(false)
  }
  function fetchJobTemplatesListFailure(error) {
    var error_msg = error.toString();
    setState((new_state) => ({
      ...new_state,
      error: error_msg,
    }));
    setShowLoading(false)
  }

  console.log(state.job_template_list, "sbvdsbvhjsdvhj")

  useEffect(() => {
    console.log(prev_state, child_build, "sjvhfbvhfbvjhfbv")
    if (prev_state) {
      if (child_build) {
        getPrevStateForChildBuild(prev_state, setState)
      }
      else {
        console.log("anunay")
        setState(prev_state)
      }
    }
    setShowLoading(true)
    setTimeout(() => {
      setShowLoading(false)
    }, 200);
  }, [prev_state])

  console.log(state, "sdbcjscsdbcbsd")

  function getPrevStateForChildBuild(data, setState) {
    console.log(data, "dsnjhdsbjbvhshdv")
    var child_data = { ...data };
    // var parts = data.data && data.data.label && data.data.label.split("-");
    // var parts_second_copy = data.data && data.data.label && data.data.label.split("-");
    // parts_second_copy && parts_second_copy.pop();
    // parts_second_copy = parts_second_copy && parts_second_copy.join("-");
    // child_data.data = { ...child_data.data, label: parts && parts[parts.length - 1], image_name: parts_second_copy };
    // console.log(child_data, "dsnjhdsbjbvhshdv");
    // return child_data;
    setState(child_data);
  }


  function getGitRepoState() {
    var state_temp = GitRepoDefaultState();
    if (Object.keys(state.gitRepo).length != 0) {
      console.log(state.gitRepo, "fdsadfsafsd")
      state_temp.form_data.data = { ...state_temp.form_data.data, ...state.gitRepo };
      state_temp.branch_data.branches = state.gitRepo.branches ? [...state.gitRepo.branches] : []
    }
    return state_temp;
  }


  // useEffect(() => {
  //   setShowLoading(true);
  //   // setState(new_state => ({ ...new_state, ...prev_state }))
  //   setTimeout(() => {
  //     setShowLoading(false)
  //   }, 200);
  // }, [prev_state])

  console.log(state, "smdbcsdhbcjhbsdchjbsd")
  return (

    <div className="card  pd-cont">

      <Grid item lg={12} direction="row" className="card-add-service-head" style={{ borderRadius: '0px', borderTop: '1px solid #dedede' }}>
        <div className="formTag">
          <h4 className='mainHeading'>Source Details</h4>
        </div>
      </Grid>
      {
        showLoading ? <Loading varient="light" /> :

          <div className='ci-cd-edit-input-div'>
            <Grid container className="pd-10" alignItems="center">
              <Grid item lg={12} >
                <GitRepo id="gitRepo" inherits={state.child_inherits["gitRepo"]} state={state.gitRepo ? getGitRepoState() : null} single_path={true} />
              </Grid>
              {/* <Grid item lg={12}>

                <Input
                  type="text"
                  placeholder="imagename"
                  name="image_name"
                  mandatorySign
                  label="Image Name"
                  error={state.error}
                  onChangeHandler={commonFunctions.onChangeHandler}
                  data={state.data} />
                <Input
                  type="text"
                  placeholder="Enter args"
                  name="custom_parameters"
                  // mandatorySign
                  label="Extra args"
                  error={state.error}
                  onChangeHandler={commonFunctions.onChangeHandler}
                  data={state.data} />

            </Grid> */}
              {
                child_build &&
                <>
                  <Grid item lg={12}>
                    <Input
                      type="text"
                      placeholder="imagename"
                      name="image_name"
                      mandatorySign
                      label="Image Name"
                      error={state.error}
                      onChangeHandler={commonFunctions.onChangeHandler}
                      data={state.data}
                    />
                  </Grid>
                  {/* <Grid item lg={6} className='pd-10'>
                    <Input
                      type="text"
                      placeholder="label"
                      name="label"
                      mandatorySign
                      label="Provide Label for secondary build"
                      error={state.error}
                      onChangeHandler={commonFunctions.onChangeHandler}
                      data={state.data} />
                  </Grid> */}
                  <Grid item lg={12}>
                    <Input
                      type="select"
                      placeholder=""
                      list={getListableArray(state.fileterdByVersionTemplates)}
                      name="project_job_template_id"
                      label="Select job template for secondary build"
                      error={state.error}
                      onChangeHandler={commonFunctions.onChangeHandler}
                      data={state.data}
                    />
                  </Grid>
                </>
              }
              <Grid item lg={12}>
                <BuildX
                  inherits={state.child_inherits.build_x}
                  prev_state={state.build_x}
                  data={state.data}
                  shallow_cloning={shallow_cloning}
                  depth={depth}
                  is_add={is_add}
                />
              </Grid>
            </Grid>
          </div>
      }
    </div >
  )
}
SourceDetails.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default SourceDetails;

export const getDefaultSourceDetailsState = (extraProps) => {
  console.log(extraProps, "dbhbvhjfbbf")
  const service_name = extraProps ? extraProps.service_name : "";
  const env_master = extraProps ? extraProps.environment_master_name : "";
  const project_env_name = extraProps ? extraProps.project_env_name : "";
  return {
    data: {
      image_name: service_name ? (service_name + "/" + env_master + "/" + project_env_name) : "",
    },
    job_template_version: 'v2',
    error: {},
    child_inherits: {
      "gitRepo": {},
      "build_x": {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    },
    validations: {
    }
  }
}

export const getCommonFunctions = (state, setState, inherits) => {

  const handleMultiSelect = (selectedKey, selectedValue) => {
    // console.log('line 120', selectedKey, selectedValue);
    const selected_options = [];
    for (let i = 0; i < selectedValue.length; i++) {
      selected_options.push(selectedValue[i]);
    }
    // console.log('line 120', selected_options);
    updateData(selectedKey, selected_options);
  };

  const onChangeHandler = (e) => {
    const k = e.target.name;
    const v = e.target.value;
    updateData(k, v);
  }

  const onChangeHandlerSmallInput = (e) => {
    var k = e.target.name;
    var v = e.target.value
    v = v.replace(/\W+(?!$)/g, '-');
    v = v.replace(/\W$/, '-');
    v = v.replace('_', '-').toLowerCase();
    updateData(k, v);
  }
  const onChangeHandlerWithHyphenDahs = (e) => {
    var k = e.target.name;
    var v = e.target.value
    v = v.replace(" ", '-');
    v = v.replace("_", '-');
    v = v.replace(/\W$/, '-').toLowerCase();
    updateData(k, v);
  }

  const toggleState = (e) => {
    console.log(state, e.target.name, "yyy")
    const name = e.target.name;
    setState(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        [name]: !state.data[name],
      }
    }));
  }

  const updateDataKeyVal = (k, v) => {
    var value = v;
    if (v == 0) {
      value = v.toString()
    }
    setState(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        [k]: v,
      },
      error: {
        ...new_state.error,
        [k]: ""
      }
    }));
  }
  const updateData = (k, v) => {
    setState(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        [k]: v,
      },
      error: {
        ...new_state.error,
        [k]: ""
      }
    }));
  }

  const updateMultipleData = (new_data_elements_object, remove_error_key) => {
    setState(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        ...new_data_elements_object,
      },
      error: remove_error_key ? {
        ...new_state.error,
        [remove_error_key]: ""
      } : {
        ...new_state.error,
      }
    }));
  }

  const updateUserGroupData = (new_data_elements_object, remove_error_key) => {
    console.log(new_data_elements_object, "new_data_elements_object")
    setState(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        ...new_data_elements_object,
      },
      error: remove_error_key ? {
        ...new_state.error,
        [remove_error_key]: ""
      } : {
        ...new_state.error,
      }
    }));
  }

  const onChangeHandlerNumber = (e) => {
    updateData([e.target.name], Number(e.target.value) ? Number(e.target.value) : "")
  }

  const onChangeHandlerNumberTwo = (e) => {
    updateData([e.target.name], Number(e.target.value) ? Number(e.target.value) == "" ? null : Number(e.target.value) : null)
  }


  const onKeyValueChangeHandler = (k, v) => {
    updateData(k, v);
  }
  inherits.validateForm = () => {
    const allDynamicValidations = getDynamicValidations();
    const completeListOfValidations = { ...state.validations, ...allDynamicValidations }
    console.log(state, "sjkjkasj")
    const result = ValidateDataSet(state.data, state.validations, state.existing_key_list)
    if (!result.valid) {
      console.log(result.error, "thth")
      setState(new_state => ({
        ...new_state,
        error: { ...result.error }
      }));
    }
    const childValidationStatus = validateChildComponents()
    return { valid: result.valid && childValidationStatus }
  }
  inherits.validateChildFormOnly = () => {
    const childValidationStatus = validateChildComponents()
    return { valid: childValidationStatus }
  }
  function validateChildComponents() {
    var result = true
    if (state.child_inherits) {
      Object.keys(state.child_inherits).forEach(childKey => {
        var childResult = state.child_inherits[childKey].validateForm().valid;
        result = childResult && result
      })
    }
    return result;
  }

  inherits.getState = () => {
    const result = {
      ...state,
      ...getChildrenState()
    }
    return result;
  }

  inherits.getAPIObject = () => {
    console.log(state.data, "lolo")
  }

  function getChildrenState() {
    const childStates = {}
    if (state.child_inherits) {
      Object.keys(state.child_inherits).forEach(childKey => {
        if (childKey == "gitRepo" || childKey == "fileUpload" || childKey == "key_value") {
          childStates[childKey] = state.child_inherits[childKey].getData()
        } else {
          childStates[childKey] = state.child_inherits[childKey].getState();
          console.log(state.child_inherits, "state.child_inherits")
        }
      })
    }
    return childStates;
  }

  function getDynamicValidations() {
    var allDynamicValidations = {};
    if (state.dynamicValidations) {
      state.dynamicValidations.forEach(dynamicValidation => {
        if (dynamicValidation.possible_values.includes(state.data[dynamicValidation.data_key])) {
          allDynamicValidations = { ...allDynamicValidations, ...dynamicValidation.validations };
        }
      })
    }
    return allDynamicValidations;
  }

  return {
    "updateData": updateData,
    "onChangeHandler": onChangeHandler,
    "onChangeHandlerSmallInput": onChangeHandlerSmallInput,
    "toggleState": toggleState,
    "onChangeHandlerWithHyphenDahs": onChangeHandlerWithHyphenDahs,
    "onChangeHandlerNumber": onChangeHandlerNumber,
    "onChangeHandlerNumberTwo": onChangeHandlerNumberTwo,
    "validateChildComponents": validateChildComponents,
    "updateDataKeyVal": updateDataKeyVal,
    "onKeyValueChangeHandler": onKeyValueChangeHandler,
    "updateMultipleData": updateMultipleData,
    "updateUserGroupData": updateUserGroupData,
    'handleMultiSelect': handleMultiSelect,

  }

}

export const ResetComponent = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const [state, setState] = useState({
    data: {},
    error: {},
    validations: {
    }
  })
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  inherits.getState = () => { return null }
  inherits.getData = () => { return [] }
  return (<></>)
}
ResetComponent.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}