import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import './CustomSnackbar.css'; // Place styles here

const CustomSnackbar = ({ open, onClose, severity, message }) => {
    const renderContent = () => {
        switch (severity) {
            case 'error':
                return (
                    <div>
                        <div className="d-grid mb-6" style={{ gridTemplateColumns: '40px 1fr auto', alignItems: 'center', gap: '10px' }}>
                            <div
                                className="d-flex align-center justify-center"
                                style={{
                                    backgroundColor: '#FFEAEA',
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '50%',
                                }}
                            >
                                <span className="ri-alert-line font-20" style={{ color: '#E95454' }}></span>
                            </div>
                            <p className="font-14" style={{ margin: 0, color: '#E95454', fontWeight: '600' }}>Failed to save configuration</p>
                            <button
                                className="btn btn-transparent mr-0"
                                onClick={onClose}
                                style={{ border: 'none', background: 'none', cursor: 'pointer', padding: '0px' }}
                            >
                                <span className="ri-close-line font-20 color-primary"></span>
                            </button>
                        </div>
                        <p className="font-14 font-weight-400 color-primary" style={{ lineHeight: '17px' }}>{message}</p>
                    </div>
                );
            case 'success':
                return (
                    <div>
                        <div className="d-grid mb-6" style={{ gridTemplateColumns: '40px 1fr auto', alignItems: 'center', gap: '10px' }}>
                            <div
                                className="d-flex align-center justify-center"
                                style={{
                                    backgroundColor: '#EDFFEA',
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '50%',
                                }}
                            >
                                <span className="ri-check-line font-20" style={{ color: '#52D376' }}></span>
                            </div>
                            <p className="font-14" style={{ margin: 0, color: '#52D376', fontWeight: '600' }}>Configuration Created Successfully</p>
                            <button
                                className="btn btn-transparent mr-0"
                                onClick={onClose}
                                style={{ border: 'none', background: 'none', cursor: 'pointer', padding: '0px' }}
                            >
                                <span className="ri-close-line font-20 color-primary"></span>
                            </button>
                        </div>
                        <p className="font-14 font-weight-400 color-primary" style={{ lineHeight: '17px' }}>{message}</p>
                    </div>
                );
            default:
                return <p>{message}</p>;
        }
    };

    return (
        <Snackbar
            open={open}
            className="snackbar-wrapper"
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert
                severity={severity}
                icon={null} // Removes the default icon
                classes={{ root: 'snackbar-custom-alert' }}
            >
                {renderContent()}
            </Alert>
        </Snackbar>
    );
};

// Define prop types
CustomSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    severity: PropTypes.oneOf(['error', 'success', 'warning', 'info']).isRequired,
    message: PropTypes.string.isRequired,
};

export default CustomSnackbar;
