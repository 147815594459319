import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import MoreMenu from '../../../jobTemplates/components/MoreMenu';
import { makeStyles } from '@mui/styles';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData, SubscribeToApi } from '../../../../util/apiInvoker';
import { getListableArray } from '../../../../util/util';


const ManageCanary = ({ open, handleCloseDialog, /* canary_analysis_deployment_data */ requestInstanceData, next_task, ...props }) => {

    const [state, setState] = useState(
        {
            data: {},
            error: {},
            exceptional_approval_services: [],
            openExceptionDialog: false,
            openRollbackDialog: false,
            rollback_selected_services: [],
            dialog_type: null,
            confirmationPage: false,
            viewReport: false,
            success_services_to_next_phase: []
        }
    )
    const { pipeline_id, pipeline_instance_id, stage_instance_id, task_instance_id, task } = props;
    const classes = useStylesForMonitoringDialog();
    const dialogClasses = useStyles()
    const inherits = props.inherits ? props.inherits : {};
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    useEffect(() => {
        fetchCanaryTemplate()
    }, [])

    useEffect(() => {
        SubscribeToApi(GenerateURL({
            pipeline_id: pipeline_id,
            pipeline_instance_id: pipeline_instance_id,
            stage_instance_id: stage_instance_id,
            task_instance_id: task_instance_id,
        }, properties.api.task_service_details));
    }, [])

    useEffect(() => {
        let list = state.success_services_to_next_phase
        let updated_list = []
        list.forEach((item) => {
            if (!state.rollback_selected_services.includes(item)) {
                updated_list.push(item)
            }
        })
        setState((new_state) => ({
            ...new_state,
            success_services_to_next_phase: updated_list,
        }))
    }, [state.rollback_selected_services])

    function fetchCanaryTemplate() {
        let myendpoint = GenerateURL({}, properties.api.canary_template_list);

        var requestInfo = {
            endPoint: myendpoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setState(prevState => ({
            ...prevState,
            // loading: true
        }))

        InvokeApi(requestInfo, fetchCanaryTemplateSuccess, fetchCanaryTemplateFailed);
    }

    function fetchCanaryTemplateSuccess(response) {
        setState((new_state) => ({
            ...new_state,
            canary_template_data: response.results,
            canary_option_list: getListableArray(response.results),
            // loading: false,
        }))
    }

    function fetchCanaryTemplateFailed(error) {
        setState((new_state) => ({
            ...new_state,
            loading: false,
            error: error
        }))
    }

    useEffect(() => {
        if (task.task_type.id == 15) {
            getServiceDetails()
        }
    }, [task])

    function getServiceDetails() {
        var requestInfo = {
            endPoint: GenerateURL({
                pipeline_id: pipeline_id,
                pipeline_instance_id: pipeline_instance_id,
                stage_instance_id: stage_instance_id,
                task_instance_id: task.instance.id,
            }, properties.api.task_service_details),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setState((new_state) => ({
            ...new_state,
            loaded: false,
        }))
        InvokeApi(requestInfo, handleResponse, handleError);
    }

    function handleResponse(response) {
        console.log(response, "abcjadbcdscdsg")
        console.log(response.canary_analysis_task_instance[0].canary_analysis_deployment_meta_data, "sdbjhdsbhbdshb")
        // if (data.status == "RUNNING" || data.status == "IN_QUEUE" || data.status == "PENDING_RESUME") {
        //     setTimeout(getServiceDetails, 2000);
        // }
        let list = response.canary_analysis_task_instance && response.canary_analysis_task_instance.length > 0 && response.canary_analysis_task_instance[0].canary_analysis_deployment_meta_data
        let service_list = Object.keys(list)
        let updated_list = []
        service_list.forEach((item) => {
            if (list[item].status == "SUCCESS") {
                updated_list.push(item)
            }
        })
        setState(new_state => ({
            ...new_state,
            loaded: true,
            service_task_data: response,
            canary_analysis_deployment_data: list,
            canary_task_instance_id: response.canary_analysis_task_instance[0].id,
            success_services_to_next_phase: updated_list,
            data: {
                ...new_state.data,
                canary_analysis_template: response.canary_analysis_task_instance && response.canary_analysis_task_instance.length > 0 && response.canary_analysis_task_instance[0].task_data?.canary_analysis_template_id,
                analysis_duration: response.canary_analysis_task_instance && response.canary_analysis_task_instance.length > 0 && response.canary_analysis_task_instance[0].canary_analysis_job_manager_data?.analysis_duration ? response.canary_analysis_task_instance[0].canary_analysis_job_manager_data.analysis_duration : response.canary_analysis_task_instance && response.canary_analysis_task_instance.length > 0 && response.canary_analysis_task_instance[0].task_data?.canary_analysis_duration,
                analysis_frequency: response.canary_analysis_task_instance && response.canary_analysis_task_instance.length > 0 && response.canary_analysis_task_instance[0].canary_analysis_job_manager_data?.analysis_frequency ? response.canary_analysis_task_instance[0].canary_analysis_job_manager_data.analysis_frequency : response.canary_analysis_task_instance && response.canary_analysis_task_instance.length > 0 && response.canary_analysis_task_instance[0].task_data?.analysis_interval,
            }
        }));
    }

    function handleError(error) {
        // setState({
        //     ...state,
        //     loaded: false,
        //     error: error
        // });
    }

    function handleOpenExceptionConfirmationDialog(item) {
        setState((new_state) => ({
            ...new_state,
            openExceptionDialog: true,
            selectedItem: item,
        }))
    }

    function handleCloseConfirmationDialog() {
        setState((new_state) => ({
            ...new_state,
            openExceptionDialog: false,
            dialog_type: null,
        }))
    }

    function handleAddForExceptionalApproval() {
        let service = state.selectedItem
        setState((new_state) => ({
            ...new_state,
            exceptional_approval_services: [...new_state.exceptional_approval_services, state.selectedItem],
            openExceptionDialog: false,
        }))
    }

    function handleRemoveMarkForException(item) {
        let list = state.exceptional_approval_services

        function removeItem(array, itemToRemove) {
            return array.filter(element => element !== itemToRemove);
        }

        let updatedList = removeItem(list, item)

        console.log(updatedList, "sdvsdbvhjshsd")

        setState((new_state) => ({
            ...new_state,
            exceptional_approval_services: updatedList
        }))
    }

    function handleRemoveFromRollback(item) {
        let list = state.rollback_selected_services

        function removeItem(array, itemToRemove) {
            return array.filter(element => element !== itemToRemove);
        }

        let updatedList = removeItem(list, item)

        console.log(updatedList, "sdvsdbvhjshsd")

        setState((new_state) => ({
            ...new_state,
            rollback_selected_services: updatedList
        }))
    }

    function onRollbackSelect(item) {
        setState((new_state) => ({
            ...new_state,
            openRollbackDialog: true,
            selectedRollbackItem: item,
        }))
    }

    function handleAddForRollbackApproval() {
        setState((new_state) => ({
            ...new_state,
            rollback_selected_services: [...new_state.rollback_selected_services, state.selectedRollbackItem],
            openRollbackDialog: false,
        }))
    }

    function handleAllServiceApproval() {
        let serviceList = state.canary_analysis_deployment_data && Object.keys(state.canary_analysis_deployment_data)
        let list_for_exception = []
        serviceList.forEach((item) => {
            if (state.canary_analysis_deployment_data[item].status == "FAILED") {
                list_for_exception.push(item)
            }
        })

        setState((new_state) => ({
            ...new_state,
            exceptional_approval_services: list_for_exception,
            openExceptionDialog: false,
        }))
    }

    function handleOpenApproveAllDialog() {
        setState((new_state) => ({
            ...new_state,
            openExceptionDialog: true,
            dialog_type: "approve_all"
        }))
    }

    function handleConfirmation() {
        setState((new_state) => ({
            ...new_state,
            confirmationPage: true,
        }))
    }

    function handleMoveBack() {
        setState((new_state) => ({
            ...new_state,
            confirmationPage: false,
        }))
    }

    function validateAndSave(type) {
        setState(new_state => ({
            ...new_state,
            loading: true,
        }))
        var post_data = {
            canary_analysis_template: Number(state.data.canary_analysis_template),
            analysis_duration: Number(state.data.analysis_duration),
            analysis_frequency: Number(state.data.analysis_frequency),
            deployment_list: [],
            revoke_pipeline: type == "rollback" ? true : false,
        }

        Object.keys(state.canary_analysis_deployment_data).map((item) => {
            let obj = {
                deployment_name: item,
                rollback: type == "rollback" ? true : state.rollback_selected_services.includes(item) ? true : false,
                baseline_deploy_history_id: state.canary_analysis_deployment_data[item].baseline_deployment_metadata.deploy_history,
                canary_deploy_history_id: state.canary_analysis_deployment_data[item].canary_deployment_metadata.deploy_history,
                exceptional_approval: type == "rollback" ? false : state.exceptional_approval_services.includes(item) ? true : false,
                Baseline_artifact: state.canary_analysis_deployment_data[item].baseline_deployment_metadata.deployment_artifact,
                status: state.canary_analysis_deployment_data[item].status,
                service_deployment_key: item,
                comment: state.data[item] ? state.data[item] : state.data.rollback_all_comment ? state.data.rollback_all_comment : state.data.approve_all_comment && state.canary_analysis_deployment_data[item].status == "FAILED" ? state.data.approve_all_comment : null,
            }

            post_data.deployment_list.push(obj)
        })

        var post_url = GenerateURL({ pipeline_id: pipeline_id, pipeline_instance_id: pipeline_instance_id, stage_instance_id: stage_instance_id, task_instance_id: task_instance_id, canary_task_id: state.canary_task_instance_id }, properties.api.canary_next_phase);

        PostData(post_url, post_data, postSuccess, postFail);
    }

    function postSuccess(response) {
        setState(new_state => ({
            ...new_state,
            data_post: "SUCCESS",
            show_save_msg: "success",
            loading: false,
        }))
        handleCloseDialog();
        requestInstanceData();
    }

    function postFail(error) {
        setState(new_state => ({
            ...new_state,
            loading: false,
            data_post: "FAILED",
            show_save_msg: "failed",
            error_in_save: error
        }))
    }

    function onViewReportSelect(item) {
        setState((new_state) => ({
            ...new_state,
            viewReport: true
        }))
    }

    function handleCloseRollbackDialog() {
        setState((new_state) => ({
            ...new_state,
            openRollbackDialog: false,
            rollback_dialog_type: null,
        }))
    }

    function handleRollbackAndRevokeAll() {
        setState((new_state) => ({
            ...new_state,
            openRollbackDialog: true,
            rollback_dialog_type: "rollback_all",
        }))
    }

    console.log(state, "sbdfydhsbhjsdbf")

    return (
        <>
            {/* {
                state.viewReport &&
                <Navigate to={{
                    pathname: "/application/" + application_id + "/pipeline/" + pipeline_id + "/execution/" + pipeline_instance_id + "/analysis/report",
                    state: {
                        canary_analysis_data: component_task_instance && component_task_instance.canary_analysis_metric_summary_data[data],
                        complete_data: component_task_instance,
                        deploy_name: data,
                        application_id: application_id,
                        pipeline_id: pipeline_id,
                        pipeline_instance_id: pipeline_instance_id,
                        canary_dep_name: getCanaryDeploymentName(data)
                    }
                }} />
            } */}
            {/* Dialog for exception approval to single service and all service */}
            <Dialog
                open={state.openExceptionDialog}
                onClose={handleCloseConfirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: dialogClasses.dialogPaper }}
            >
                <div className={dialogClasses.header}>
                    <p>CAUTION</p>
                    <span className='ri-close-fill' onClick={handleCloseConfirmationDialog} style={{ fontSize: "24px", color: "#2F2F2F", cursor: "pointer" }}></span>
                </div>
                <DialogContent className={dialogClasses.dialogContent}>
                    <div className={dialogClasses.warningIconContainer}>
                        <span className='ri-alert-fill' style={{ color: "#E53737", fontSize: "32px" }}></span>
                    </div>
                    <Typography variant="h6" className={dialogClasses.h6Typography}>
                        {state.dialog_type == "approve_all" ? "Give Exceptional Approval to all service?" : "Give Exceptional Approval to this service?"}
                    </Typography>
                    <Typography variant="body2" gutterBottom className={dialogClasses.body2Typography}>
                        {state.dialog_type == "approve_all" ? "Your are about to provide exceptional approval for all the failed services to proceed with canary." : `You are about to provide exceptional approval for '${state.selectedItem}' to proceed with canary.`}
                    </Typography>
                    <div style={{ marginTop: "24px" }}>
                        <Input
                            type="text"
                            name={state.dialog_type == "approve_all" ? "approve_all_comment" : state.selectedItem}
                            label=""
                            placeholder="Approval Comment"
                            data={state.data}
                            error={state.error}
                            mandatorySign
                            onChangeHandler={commonFunctions.onChangeHandler}
                        />
                    </div>
                </DialogContent>
                <DialogActions className={dialogClasses.dialogActions}>
                    <Button onClick={handleCloseConfirmationDialog} className={dialogClasses.cancelButton}>
                        CANCEL
                    </Button>
                    <Button onClick={state.dialog_type == "approve_all" ? handleAllServiceApproval : handleAddForExceptionalApproval} className={dialogClasses.approveButton}>
                        {state.dialog_type == "approve_all" ? "YES, APPROVE ALL!" : "YES, APPROVE!"}
                    </Button>
                </DialogActions>

            </Dialog>
            {/* Dialog for rollback single service and all service */}
            <Dialog
                open={state.openRollbackDialog}
                onClose={handleCloseRollbackDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: dialogClasses.dialogPaper }}
            >
                <div className={dialogClasses.header}>
                    <p>CAUTION</p>
                    <span className='ri-close-fill' onClick={handleCloseRollbackDialog} style={{ fontSize: "24px", color: "#2F2F2F", cursor: "pointer" }}></span>
                </div>
                <DialogContent className={dialogClasses.dialogContent}>
                    <div className={dialogClasses.warningIconContainer}>
                        <span className='ri-alert-fill' style={{ color: "#FEA111", fontSize: "32px" }}></span>
                    </div>
                    <Typography variant="h6" className={dialogClasses.h6Typography}>
                        {state.rollback_dialog_type == "rollback_all" ? "Rollback and Revoke Pipeline?" : "Mark this service for Rollback?"}
                    </Typography>
                    <Typography variant="body2" gutterBottom className={dialogClasses.body2Typography}>
                        {state.rollback_dialog_type == "rollback_all" ? "Your are about to Rollback all the services to baseline and revoke the pipeline" : `You are about to mark '${state.selectedRollbackItem}' for rollback to baseline.`}
                    </Typography>
                    <div style={{ marginTop: "24px" }}>
                        <Input
                            type="text"
                            name={state.rollback_dialog_type == "rollback_all" ? "rollback_all_comment" : state.selectedRollbackItem}
                            label=""
                            placeholder="Approval Comment"
                            data={state.data}
                            error={state.error}
                            mandatorySign
                            onChangeHandler={commonFunctions.onChangeHandler}
                        />
                    </div>
                </DialogContent>
                <DialogActions className={dialogClasses.dialogActions}>
                    <Button onClick={handleCloseRollbackDialog} className={dialogClasses.cancelButton}>
                        CANCEL
                    </Button>
                    <Button onClick={state.rollback_dialog_type == "rollback_all" ? () => validateAndSave("rollback") : handleAddForRollbackApproval} className={dialogClasses.approveButton}>
                        {state.rollback_dialog_type == "rollback_all" ? "YES! ROLLBACK AND REVOKE" : "YES, ROLLBACK!"}
                    </Button>
                </DialogActions>

            </Dialog>
            {/* Main dialog */}
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                className="integrations-dialog-for-canary canary_card_dialog_width"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        margin: "0",
                        overflow: "visible",
                        maxWidth: "850px"
                    },
                }}
                TransitionComponent={Slide} // Use Slide transition for the dialog
                transitionDuration={500} // Adjust the duration of the transition
                TransitionProps={{
                    direction: 'left', // Slide from right to left
                }}
            >
                <div className={classes.root}>
                    <div className='heading'>
                        <span className='font-18 font-weight-600'>Manually Approve Canary Analysis to continue Pipeline for next Phase</span>
                        <button onClick={handleCloseDialog}><span className='ri-close-fill font-24'></span></button>
                    </div>
                    {
                        state.confirmationPage ?
                            <div className='service-list-body'>
                                {
                                    state.exceptional_approval_services.length > 0 &&
                                    <div className='next-phase-list'>
                                        <div className='next-phase-header'>
                                            <p>Services Marked For Next Phase</p>
                                            <div className='flag-next-phase'>
                                                <span className='ri-checkbox-circle-line' style={{ color: "#0086FF", fontSize: "16px", marginRight: "8px" }}></span>
                                                MARKED FOR NEXT PHASE
                                            </div>
                                        </div>
                                        <div className='next-phase-body'>
                                            {
                                                state.exceptional_approval_services.map((item) => (
                                                    <div className='service-chips'>{item}</div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    state.rollback_selected_services.length > 0 &&
                                    <div className='next-phase-list' style={{ marginTop: "24px" }}>
                                        <div className='next-phase-header'>
                                            <p>Services Marked For Rollback</p>
                                            <div className='flag-next-phase' style={{ backgroundColor: "#FCF6E1", color: "#E1941D", border: "none" }}>
                                                <span className='ri-refresh-line' style={{ color: "##E1941D", fontSize: "16px", marginRight: "8px" }}></span>
                                                MARKED FOR ROLLBACK
                                            </div>
                                        </div>
                                        <div className='next-phase-body'>
                                            {
                                                state.rollback_selected_services.map((item) => (
                                                    <div className='service-chips'>{item}</div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                }
                                {
                                    state.success_services_to_next_phase.length > 0 &&
                                    <div className='next-phase-list' style={{ marginTop: "24px" }}>
                                        <div className='next-phase-header'>
                                            <p>Remaining Passed services moving to next phase</p>
                                            <div className='flag-next-phase' style={{ backgroundColor: "#E6FBEA", color: "#2EBE79", border: "none" }}>
                                                {/* <span className='ri-refresh-line' style={{ color: "##E1941D", fontSize: "16px", marginRight: "8px" }}></span> */}
                                                Passed Services
                                            </div>
                                        </div>
                                        <div className='next-phase-body'>
                                            {
                                                state.success_services_to_next_phase.map((item) => (
                                                    <div className='service-chips'>{item}</div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                }
                            </div>
                            :
                            <div className='form-body'>
                                <Grid container spacing={2}>
                                    <Grid item lg={12}>
                                        <Input
                                            type="select"
                                            name="canary_analysis_template"
                                            mandatorySign
                                            label="Canary Analysis Template"
                                            list={state.canary_option_list ? state.canary_option_list : []}
                                            onChangeHandler={null}
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </Grid>
                                    <Grid item lg={6}>
                                        <Input
                                            type="text"
                                            name="analysis_duration"
                                            mandatorySign
                                            label="Analysis Duration(in minutes)"
                                            onChangeHandler={next_task ? null : commonFunctions.onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </Grid>
                                    <Grid item lg={6}>
                                        <Input
                                            type="text"
                                            name="analysis_frequency"
                                            mandatorySign
                                            label="Analysis Frequency(in minutes)"
                                            onChangeHandler={next_task ? null : commonFunctions.onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </Grid>
                                </Grid>
                                <div className='manage-service'>
                                    <div className='header'>
                                        <p>MANANGE SERVICES FOR NEXT PHASE</p>
                                        <div onClick={next_task ? null : handleOpenApproveAllDialog} style={next_task ? { cursor: "pointer", color: "#787878" } : { cursor: "pointer" }}>APPROVE ALL</div>
                                    </div>
                                    <div className='service-listing'>
                                        {
                                            state.canary_analysis_deployment_data && Object.keys(state.canary_analysis_deployment_data).map((item) =>
                                            (
                                                <div className='single-service'>
                                                    <div className='left-div'>
                                                        <p>{item}</p>
                                                        {
                                                            state.canary_analysis_deployment_data[item].status == "FAILED" ?
                                                                <div className='new-status-chip new-failed' style={{ marginLeft: "12px" }}>{state.canary_analysis_deployment_data[item].status}</div>
                                                                :
                                                                <div className='new-status-chip new-success' style={{ marginLeft: "12px" }}>{state.canary_analysis_deployment_data[item].status}</div>
                                                        }

                                                    </div>
                                                    {
                                                        state.exceptional_approval_services.includes(item) ?
                                                            <div className='marked-for-next-phase-flag'>
                                                                <p>MARKED FOR NEXT PHASE</p>
                                                                <span className='ri-close-circle-line' style={{ fontSize: "17px", marginLeft: "5px", cursor: "pointer" }} onClick={() => handleRemoveMarkForException(item)}></span>
                                                            </div>
                                                            :
                                                            state.rollback_selected_services.includes(item) ?
                                                                <div className='marked-for-rollback-flag'>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <span className='ri-refresh-line' style={{ fontSize: "20px" }}></span>
                                                                        <p style={{ marginLeft: "12px" }}>MARKED FOR ROLLBACK</p>
                                                                    </div>
                                                                    <span className='ri-close-circle-line' style={{ fontSize: "17px", marginLeft: "5px", cursor: "pointer" }} onClick={() => handleRemoveFromRollback(item)}></span>
                                                                </div>
                                                                :
                                                                state.canary_analysis_deployment_data[item].status == "FAILED" ?
                                                                    next_task ?
                                                                        <></>
                                                                        :
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <div className='exceptional-approval-button' onClick={() => handleOpenExceptionConfirmationDialog(item)}>
                                                                                GIVE EXCEPTIONAL APPROVAL
                                                                            </div>
                                                                            <MoreMenu type="canary" item={item} onRollbackSelect={onRollbackSelect} onViewReportSelect={onViewReportSelect} />
                                                                        </div>
                                                                    :
                                                                    next_task ?
                                                                        <></>
                                                                        :
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <MoreMenu type="canary" item={item} onRollbackSelect={onRollbackSelect} onViewReportSelect={onViewReportSelect} />
                                                                        </div>

                                                    }
                                                </div>
                                            )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                    {
                        state.confirmationPage ?
                            <div className='form-footer'>
                                <button className='rollback-revoke-button' onClick={handleMoveBack}>BACK</button>
                                {
                                    state.loading ?
                                        <button className='continue-button btn-disable' onClick={validateAndSave}>PROCEED</button>
                                        :
                                        <button className='continue-button' onClick={validateAndSave}>PROCEED</button>
                                }

                            </div>
                            :
                            <div className='form-footer'>
                                {
                                    next_task ?
                                        <button className='rollback-revoke-button btn-disable' style={{ cursor: "not-allowed" }}>ROLLBACK AND REVOKE PIPELINE</button>
                                        :
                                        <button className='rollback-revoke-button' onClick={handleRollbackAndRevokeAll}>ROLLBACK AND REVOKE PIPELINE</button>
                                }
                                {state.exceptional_approval_services.length > 0 || state.rollback_selected_services.length > 0 ?
                                    next_task ?
                                        <button className='continue-button btn-disable' style={{ cursor: "not-allowed" }}>CONTINUE</button>
                                        : <button className='continue-button' onClick={handleConfirmation}>CONTINUE</button>
                                    :
                                    next_task ?
                                        <button className='continue-button btn-disable' style={{ cursor: "not-allowed" }}>CONFIRM</button>
                                        : <button className='continue-button' onClick={validateAndSave}>CONFIRM</button>}
                            </div>

                    }
                </div>
            </Dialog>

        </>
    )
}


export default React.memo(ManageCanary);

const useStylesForMonitoringDialog = makeStyles((theme) => ({
    root: {
        "& .MuiDialog-paperScrollPaper": {
            width: "804px !important",
            maxWidth: "850px",
        },

        '& .heading': {
            display: "flex",
            padding: "16px",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            background: "#0086FF",
            color: "#FFF",
            position: "relative",

            '& button': {
                display: "flex",
                width: "54px",
                //height: "54px",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flexShrink: "0",
                borderRadius: "6px 0px 0px 6px",
                background: "#C11212",
                border: "none",
                color: "#fff",
                position: "absolute",
                top: "0",
                left: "-54px",
            }
        },

        "& .form-body": {
            padding: "20px 16px",

            "& .manage-service": {
                marginTop: "24px",

                "& .header": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",

                    "& p": {

                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#787878",
                    },

                    "& div": {

                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#0086FF"
                    }
                },

                "& .service-listing": {
                    marginTop: "10px",
                    border: "1px solid #DFDEDE",
                    borderRadius: "4px",


                    "& .single-service": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "64px",
                        padding: "16px",
                        borderBottom: "1px solid #E6E6E6",

                        "& .left-div": {
                            display: "flex",
                            alignItems: "center",

                            "& p": {

                                fontSize: "12px",
                                fontWeight: "600",
                                color: "#505050"
                            }
                        },

                        "& .exceptional-approval-button": {
                            border: "1px solid #9DC0EE",
                            borderRadius: "6px",
                            padding: "8px 12px",

                            fontSize: "12px",
                            fontWeight: "600",
                            marginRight: "12px",
                            color: "#124D9B",
                            cursor: "pointer",
                        },

                        "& .exceptional-approval-button:hover": {
                            backgroundColor: "#0086FF",
                            color: "#ffffff"
                        },

                        "& .marked-for-next-phase-flag": {
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid #DFEDFF",
                            borderRadius: "6px",
                            padding: "8px 12px",

                            fontSize: "12px",
                            fontWeight: "600",
                            marginRight: "12px",
                            backgroundColor: "#F5FAFF",
                            color: "#0086FF",
                        },
                        "& .marked-for-rollback-flag": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "1px solid #FFE3B8",
                            borderRadius: "6px",
                            padding: "6px 12px",

                            fontSize: "12px",
                            fontWeight: "600",
                            marginRight: "12px",
                            backgroundColor: "#FCF6E1",
                            color: "#E1941D",
                            minWidth: "250px"
                        }
                    }
                }
            }
        },

        "& .service-list-body": {
            padding: "20px 16px",

            "& .service-chips": {
                padding: "8px 15px",
                borderRadius: "30px",
                border: "1px solid #CACACA",
                backgroundColor: "#FAFAFA",

                fontSize: "12px",
                fontWeight: "600",
                color: "#505050",
            },

            "& .next-phase-list": {
                padding: "12px 16px",
                borderRadius: "10px",
                border: "1px solid #E6E6E6",

                "& .next-phase-header": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "& p": {

                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#787878",
                    },

                    "& .flag-next-phase": {
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #DFEDFF",
                        borderRadius: "6px",
                        padding: "6px 12px",

                        fontSize: "11px",
                        fontWeight: "600",
                        backgroundColor: "#F5FAFF",
                        color: "#0086FF",
                    }
                },

                "& .next-phase-body": {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "16px",
                }

            }
        },
        "& .form-footer": {
            position: "absolute",
            bottom: "0",
            height: "72px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "16px",
            width: "100%",

            "& .rollback-revoke-button": {
                padding: "8px 16px",
                borderRadius: "6px",
                border: "1px solid #9DC0EE",
                color: '#124D9B',

                fontSize: "12px",
                fontWeight: "600",
                backgroundColor: "#ffffff",
            },

            "& .continue-button": {
                padding: "8px 16px",
                borderRadius: "6px",
                border: "1px solid #9DC0EE",
                color: '#ffffff',

                fontSize: "12px",
                fontWeight: "600",
                backgroundColor: "#0086FF",
                marginLeft: "10px",
            },
            "& .continue-button:hover": {

            }
        }
    },
}))

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
        backgroundColor: "#FAFAFA",
        "& p": {

            fontSize: "16px",
            fontWeight: "600",

        }
    },
    dialogPaper: {
        margin: 0,
        overflow: 'visible',
        width: '655px !important',
        maxWidth: '655px',
    },
    warningIconContainer: {
        width: '56px',
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
        backgroundColor: '#FFEBEB',
        borderRadius: '6px',
        marginBottom: theme.spacing(2),
        margin: 'auto',
    },
    warningIcon: {
        color: theme.palette.error.main,
        fontSize: 50,
        marginBottom: theme.spacing(2),
    },
    h6Typography: {

        fontSize: '18px',
        fontWeight: 600,
        color: "#2F2F2F",
        marginTop: "24px",
    },
    body2Typography: {

        fontSize: '14px',
        fontWeight: 500,
        color: "#505050",
        marginTop: "6px",
    },
    dialogContent: {
        textAlign: 'center',
    },
    approveButton: {
        padding: "11px 16px",
        borderRadius: "6px",

        fontSize: '12px',
        fontWeight: 600,
        color: '#FFFFFF',
        backgroundColor: "#0086FF",
    },
    cancelButton: {
        padding: '8px 16px',
        borderRadius: '6px',
        // opacity: 0,

        fontSize: '12px',
        fontWeight: 600,
        color: '#2F2F2F',
    },
    dialogActions: {
        height: "72px",
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));