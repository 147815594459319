import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles'; 
import { Popover } from '@mui/material'

const message1 = { //action queue build
    main: 'a Build is running',
    info: 'Currently a Build is running, you can put the build request in Queue.'
}
const message2 = { //action none
    main: 'Max Queue depth is reached',
    info: 'Max Queue depth is reached. New builds cannot be queued. Please try after some time.'
}
const message3 = { //action (revoke & trigger) and queue
    main: 'a Build is running',
    info: 'You can Queue your build or revoke all running and queued builds and trigger your build.'
}
const message4 = { //action (queue  & trigger)
    main: 'Max Queue depth is reached',
    info: 'You can revoke all running and queued builds and trigger your build.'
}
const message5 = {
    main: 'Ready to Build',
    info: 'You can trigger build or send it to queue.'
}


const BuildFlowActionAlert = ({ buidFlowData, onTrigger, onClose, }) => {

    const [state, setState] = useState({
        open: false,
        queueBuildAction: false,
        triggerAndRevoke: false,
        infoDiv: true,
        message: null,

    })

    const REVOKE_AND_TRIGGER = 'REVOKE_AND_TRIGGER'
    const SEND_TO_QUEUE = 'SEND_TO_QUEUE'
    const classes = useStyles();
    const open = Boolean(state.open);
    const id = open ? 'simple-popover' : undefined;
  
    useEffect(() => {
        if (buidFlowData) {
            const possibleActions = buidFlowData?.possibleActions
            let is_build_running = buidFlowData?.parallelBuildData?.is_build_running;
            let queueBuildAction = false
            let triggerAndRevoke = false
            let infoDiv = false
            let message = {}
            if (possibleActions?.length == 0) {
                infoDiv = true
                message = message2
            }
            else {
                possibleActions?.forEach(action => {
                    if (action == REVOKE_AND_TRIGGER) {
                        triggerAndRevoke = true
                    }
                    if (action == SEND_TO_QUEUE) {
                        queueBuildAction = true
                    }
                });

                if (triggerAndRevoke == true && queueBuildAction == true) {
                    console.log('message3');
                    message = message3
                }
                else if (triggerAndRevoke == true) {
                    console.log('message4');
                    message = message4
                }
                else if (queueBuildAction == true && !is_build_running){
                    console.log('message5');
                    message = message5
                }
                else if (queueBuildAction == true ) {
                    console.log('message1');
                    message = message1
                }
               
                console.log("chejcing",queueBuildAction == true && !is_build_running);
            }

            setState(prevState => ({
                ...prevState,
                open: true,
                queueBuildAction: queueBuildAction,
                triggerAndRevoke: triggerAndRevoke,
                infoDiv: infoDiv,
                message: { ...message }
            }))
        }
        else {
            setState(prevState => ({
                ...prevState,
                open: false
            }))
        }
    }, [buidFlowData])

    const correctImagePath = state.infoDiv ? "/images/settings-3-line.png" : "/images/clock-logo.png"

    return (

        // <Dialog className={classes.root} onClose={onClose} open={state.open}>

            
        // </Dialog>//className='container'
        <Popover
                open={state.open}
                onClose={onClose}
                id={id}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 250, left:1300  }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}

                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
            >
        <div className={classes.root}>
                <div className='content-box'>
                    <div style={{width: '100%',display:'flex',justifyContent:'flex-end'}}>
                        <span className='ri-close-line font-24 cursor-pointer' onClick={()=>onClose()} style={{cursor:'pointer'}}></span>   
                    </div>
                    <div className='img-container d-flex align-center justify-center mb-20'>
                        <img src={correctImagePath} alt="" />
                    </div>

                    <div className='font-14 main-header upper-case'>
                        {state?.message?.main}
                    </div>
                    <div className='info-container'>
                        <span className='font-13 info-text'>
                            {state?.message?.info}
                        </span>
                    </div>
                </div>
                <div style={{ width: "100%", height: '70px',gap: '8px' }} className='d-flex align-center justify-center'>
                    {state?.queueBuildAction &&
                        <div role="button" tabIndex={0} onKeyDown={()=>{}} onClick={() => onTrigger(SEND_TO_QUEUE)} className='queue-button d-flex align-center justify-center '>
                            <span className='queue-text  font-12 font-weight-600 upper-case'>Queue Build</span>
                        </div>
                    }
                    {state?.infoDiv &&
                        <div className='info-button d-flex align-center justify-center'>
                            <span className='meta-text font-12 font-weight-600 upper-case'>Please Wait</span>
                        </div>
                    }
                    {state?.triggerAndRevoke &&
                        <div role="button" tabIndex={0} onKeyDown={()=>{}} onClick={() => onTrigger(REVOKE_AND_TRIGGER)} className='revoke-button d-flex align-center justify-center'>
                            <span className='revoke-text  font-12 font-weight-600 upper-case'>Revoke & trigger</span>
                        </div>
                    }
                </div>
            </div>
            </Popover>
    )
}

BuildFlowActionAlert.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
  

export default BuildFlowActionAlert;

const useStyles = makeStyles({
    root: {
        //background: 'rgba(0,0,0, 0.3) !important',
        background: '#fff',
        width: '330px',
        // zIndex: 100,
        // position: 'fixed',
        borderRadius: '6px',
        border: '1px solid #F4F4F4',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        '& .container': {
            width: '100%',
            
        },
        '& .img-container': {
            background: '#0086FF',
            height: '35px',
            width: '35px',
            borderRadius: '50%',
        },

        '& .content-box': {
            padding: '20px 16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '0px'
        },
        '& .info-container': {
            textAlign: 'center'
        },

        '& .upper-case': {
            textTransform: 'uppercase'
        },
        '& .main-header': {
            marginBottom: '6px',
            fontWeight: '900',
            lineHeight: '17px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#0086FF',
            
        },
        '& .info-text': {
            fontWeight: 500,
            lineHeight: '18px',
            letterSpacing: '0em',
            textAlign: 'center',
            
            // color: '#787878'
        },
        '& .queue-button': {
            width: '140px',
            height: '32px',
            padding: '11px 16px 11px 16px',
            borderRadius: '6px',
            gap: '6px',
            background: '#0086FF',
            cursor: 'pointer'
        },
        '& .queue-text': {
            color: '#FFFFFF',
            lineHeight: '15px',
        },
        '& .revoke-button': {
            width: 'fit-content',
            height: '32px',
            padding: '8px 16px 8px 16px',
            borderRadius: '6px',
            gap: '6px',
            background: '#fff',
            border: '1px solid #C11212',
            cursor: 'pointer'
        },
        '& .revoke-text': {
            color: '#C11212',
            lineHeight: '15px',

        },
        '& .info-button': {
            width: '118px',
            height: '32px',
            padding: '11px 16px 11px 16px',
            borderRadius: '6px',
            gap: '6px',
            background: '#EBF5FF',
        },
        '& .meta-text': {
            color: '#0086FF',
            lineHeight: '15px'
        }
    }
});