import React, { useEffect, useState } from 'react'
import { styled } from "@mui/system";
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { Loading } from '../../../utils/Loading';
import AlertStrip from '../../../../components/AlertStrips';
import InputSkeleton from '../../../../components/genericComponents/Skeletons/InputSkeleton';
import { showErrorHandlerUpdated } from '../../../../util/util';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';

const ApprovalCommentRequirement = ({ data, onDataUpdate, selected_ticketing, selected_ticketing_project }) => {
    const [state, setState] = useState({
        data: data || {
            comment_validation_required: 'yes'
        },
        error: {}
    })
    const commonFunctions = getCommonFunctions(state, setState, {})

    useEffect(() => {
        fetchAllUserGroups();
    }, [])



    useEffect(() => {
        if (JSON.stringify(state.data) !== JSON.stringify(data)) {
            onDataUpdate(state.data);
        }
    }, [state.data, data, onDataUpdate]);


    const fetchAllUserGroups = (data, url) => {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.save_user_group) + "?all=true",
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState(new_state => ({
            ...new_state,
            loading: true,
        }));
    }
    function handleSuccessApiHit(data) {

        let list = data.results.map(item => { return { label: item.name, id: item.id } })
        setState(new_state => ({
            ...new_state,
            all_user_groups: list,
            loading: false,
        }));
    }
    function handleFailedApiHit(error) {
        let error_msg = showErrorHandlerUpdated(error);
        setState(new_state => ({
            ...new_state,
            error_msg: error_msg,
            loading: false
        }))
    }

    const onKeyValueHandler = (e) => {
        const k = e.target.name;
        let v = e.target.value;
    
        console.log("onKeyValueHandler Debugging:", { key: k, value: v });
    

        if (Array.isArray(v) && v.length > 0 && typeof v[0] === "string") {
            v[0] = v[0].replace(" (New Label)", "");
        }
    
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,  
            },
            error: { ...new_state.error, [k]: "" },
        }));
    }
    const onEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            fetchJiraUserList(state.data[e.target.name])
        }
    }


    const fetchJiraUserList = (user) => {
        console.log(user, "sdbcjgvgsdvc");

        if (user) {
            if (user.length > 0) {
                if (user.length > 2) {
                    var requestInfo = {
                        endPoint: GenerateURL({}, properties.api.fetch_jira_users) + "?ticketing_name=" + selected_ticketing + "&project=" + selected_ticketing_project + "&user=" + user,
                        httpMethod: "GET",
                        httpHeaders: { "Content-Type": "application/json" }
                    }
                    InvokeApi(requestInfo, (data) => handleJiraUserListSuccess(data), (error) => handleJiraUserListFailed(error));
                    setState(new_state => ({
                        ...new_state,
                        user_loading: true,
                    }));
                } else {

                    setState(state => ({
                        ...state,

                        error: {
                            ...state.error,
                            message: "Please enter atleast first three characters of name"
                        }
                    }))

                }
            } else {
                setState(state => ({
                    ...state,

                    error: {
                        ...state.error,
                        message: "Please enter atleast first three characters of name"
                    }
                }))
            }


        } else {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    message: "please enter atleast first three characters of name"
                }
            }));
        }


    }

    console.log(state, "sbvjhdsbcbsdhb")

    function handleJiraUserListSuccess(data) {

        var jira_user_list = data.user;
        if (jira_user_list.length > 0) {
            setState(new_state => ({
                ...new_state,
                user_object: {
                    ...new_state.user_object, [`user_jira_user_list`]: jira_user_list,
                    [`user_show_user_dropdown`]: true,
                    [`user_no_jira_user_found`]: false,
                    [`user_loading_jira_user_list`]: false
                },
                user_loading: false

            }));
        } else {
            setState(new_state => ({
                ...new_state,
                user_object: {
                    ...new_state.user_object, [`user_jira_user_list`]: jira_user_list,
                    [`user_no_user_found`]: "user not found with these initials",
                    [`user_show_user_dropdown`]: true,
                    [`user_loading_jira_user_list`]: false
                },
                user_loading: false
            }));
        }

    }

    function handleJiraUserListFailed(error) {
        setState(new_state => ({
            ...new_state,
            user_loading: false,
            user_object: {},
            error: {
                ...new_state.error,
                message: error
            }
        }))
    }

    const onChangeHandlerSearchInput = e => {
        const k = e.target.name;
        const v = e.target.value;

        commonFunctions.onChangeHandler(e)

    }
    function getListForUsers() {
        const arr = []
        if (state.user_object) {
            Object.keys(state.user_object).forEach((item, index) => {
                const obj = { id: state.user_object[item], label: state.user_object[item] }
                arr.push(obj)
            })
        }

        console.log(arr, "dkbcbhsdsdbhjcbdsbjhc")

        return arr;
    }
    const onKeyValueChangeHandlerForApprovalUser = (k, v) => {
        console.log("fsadjfajs", k, v);
        setState({
            ...state, data: { ...state.data, [k]: v },
            error: { ...state.error, [k]: null }
        });
    }

    return (
        <Root>
            <p className='d-flex align-center mb-24'>
                <span className='font-20 font-weight-600'>Is Approval Comment Validation required?</span>&nbsp;&nbsp;<span className='ri-information-line font-24 color-secondary'></span>
            </p>

            <Input
                type="radio"
                name="comment_validation_required"
                list={[{ label: "Yes", value: 'yes' }, { label: "No", value: 'no' }]}
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
            />
            {
                state.data.comment_validation_required === "yes" &&
                <div className='' style={{ border: '1px solid #e6e6e6', borderRadius: '6px', padding: '14px 16px' }}>
                    <p className='font-weight-700 font-14 color-secondary mb-24'>Configure approval comment validation</p>
                    <Input
                        type="text"
                        placeholder="Enter Pattern"
                        name="comment_regex"
                        label={"Comment Pattern/regular expression"}
                        data={state.data}
                        error={state.error}
                        onChangeHandler={commonFunctions.onChangeHandler}
                    />
                    {
                        state.loading ?
                        <GenericSkeleton variant={"text"} width={"100%"} height={"40px"} />
                            : state.error_msg ?
                                <AlertStrip message={state.error_msg} variant={"error"} />
                                :
                                <div className="auto-complete-dropdown auto-complete-dropdown-42 auto-complete-dropdown-ticketing">
                                <Input
                                    type="auto-complete-freesolo-v2"
                                    label="Share with user groups"
                                    id={"read_user_groups1"}
                                    name="read_user_groups"
                                    list={state.all_user_groups}
                                    freeSolo={true}
                                    mandatorySign
                                    allowNewItems={false}
                                    placeholder="Enter user group name and press enter"
                                    getOptionLabel={(option) => option.label}
                                    error={state.error}
                                    data={state.data}
                                    onChangeHandler={onKeyValueHandler}
                                />
                                </div>
                    }
 {
                        state.loading ?
                        <GenericSkeleton variant={"text"} width={"100%"} height={"40px"} />
                            : state.error_msg ?
                                <AlertStrip message={state.error_msg} variant={"error"} />
                                :
                                <div className="auto-complete-dropdown auto-complete-dropdown-42 auto-complete-dropdown-ticketing">
                                <Input
                                type="auto-complete-freesolo-v2"
                                label="Allow edit for user groups"
                                id={"write_user_groups1"}
                                name="write_user_groups"
                                list={state.all_user_groups}
                                freeSolo={true}
                                mandatorySign
                                allowNewItems={false}
                                placeholder="Enter user group name and press enter"
                                getOptionLabel={(option) => option.label}
                                error={state.error}
                                data={state.data}
                                onChangeHandler={onKeyValueHandler}
                            />
                            </div>
                    }
                    
                    <div>
                        <span style={{ fontSize: '12px', color: '#828282' }}>
                            Search the user here.</span><span style={{ fontSize: '11px', color: '#828282' }}>&nbsp;(Please enter atleast first three characters of name)</span>
                        <SearchBox>

                            <Input
                                type="text"
                                varient="handle-keys"
                                placeholder="Search User"
                                name="search_text"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={onChangeHandlerSearchInput}
                                onKeyPress={(e) => { onEnterKeyPress(e) }}
                            />
                            <button className='btn btn-searchbar' onClick={() => { fetchJiraUserList(state.data.search_text) }}>
                                <span class="material-icons material-symbols-outlined">
                                    search
                                </span>
                            </button>
                        </SearchBox>
                        {
                            state.user_loading ?
                            <GenericSkeleton variant={"text"} width={"100%"} height={"40px"} /> :
                                state.user_object?.[`user_show_user_dropdown`] ?
                                <div className="auto-complete-dropdown auto-complete-dropdown-42 auto-complete-dropdown-ticketing">
                                        <Input
                                            type="auto-complete-freesolo"
                                            label="Select Users For Approval"
                                            id={"selected_jira_users1"}
                                            name="aproval_user_list"
                                            list={
                                                state.user_object?.[`user_jira_user_list`].map(item => {
                                                    return { label: item, id: item }
                                                })}
                                            freeSolo={true}
                                            mandatorySign
                                            allowNewItems={false}
                                            placeholder="Enter user group name and press enter"
                                            getOptionLabel={(option) => option.label}
                                            error={state.error}
                                            data={state.data}
                                            onChangeHandler={onKeyValueHandler}
                                        />
    </div>

                                    : state.user_object?.[`user_no_jira_user_found`] ?
                                        <AlertStrip
                                            message={typeof (state.user_object?.[`user_error_msg_jira_list`]) === "string" ? state.user_object?.[`user_error_msg_jira_list`] : "Jira Users not found in this Job Template"}
                                            variant="error" />
                                        :
                                        state.user_object?.[`user_no_user_found`] ?
                                            <AlertStrip
                                                message={state.user_object?.[`user_no_user_found`]}
                                                variant="error" />
                                            :
                                            <div className="auto-complete-dropdown auto-complete-dropdown-42 auto-complete-dropdown-ticketing">
                                            <Input
                                                type="auto-complete-freesolo"
                                                label="Select Users For Approval"
                                                id={"selected_jira_users1"}
                                                name="aproval_user_list"
                                                list={
                                                    state.user_object?.[`user_jira_user_list`].map(item => {
                                                        return { label: item, id: item }
                                                    })}
                                                freeSolo={true}
                                                mandatorySign
                                                allowNewItems={false}
                                                placeholder="Enter user group name and press enter"
                                                getOptionLabel={(option) => option.label}
                                                error={state.error}
                                                data={state.data}
                                                onChangeHandler={onKeyValueHandler}
                                            />
                                            </div>

                        }
                    </div>
                </div>
            }

        </Root>
    )
}
const Root = styled('div')({
    marginBottom: '24px',
    '& .if-yes-div': {
        '&::last-child': {

        },
        '& .input-component': {
            marginBottom: '0px'
        },
        '& .row-data-ticket': {
            borderBottom: '1px solid #e6e6e6',
            padding: '10px',
            '&:last-child': {
                borderBottom: '0px'
            }
        }
    }
})

const SearchBox = styled('div')({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 60px',
    border: '1px solid #b7b7b7',
    marginBottom: '15px',
    borderRadius: '4px',
    '& .input-component': {
        marginBottom: '0!important',
        borderRight: '1px solid #b7b7b7',
        '& input': {
            border: 'none',
            '&:focus': {
                outline: 'none',
                border: '0px!important'
            },
            '&:disabled': {
                cursor: 'not-allowed'
            }
        }
    },
    '& .btn': {
        marginRight: 0,
        padding: '.3rem 1rem'
    }
})

export default ApprovalCommentRequirement