import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { Link, useParams } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import Editor from "@monaco-editor/react";
import { ExandableComponentList } from '../../../../components/hoc/expandableList';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import HealthStatusRoundMeter from '../../../../components/genericComponents/HealthStatusRoundMeter';
import Annotations from '../../Ingress/list/components/Annotations';
import Tooltip from '@mui/material/Tooltip';
import ExandableKeyValues from '../../../../components/hoc/ExpanadleKeyValues';
import Delete from '../../../../components/genericComponents/Delete';
import { Loading } from '../../../utils/Loading';
const NamespaceDetails = props => {
    const [isEditorReady, setIsEditorReady] = useState(false);
    const valueGetter = useRef();
    const classes = useStyles();
    const [state, setState] = useState({
        selectedTabOrder: 1, namespaces: {},
        namespace_resource_quota: [], list: []
    });
    const YAML = require('json-to-pretty-yaml');


    const list = [
        {
            label: 'CPU',
            percentage: 50,
            color: '#ff8969'
        },
        {
            label: 'Memory',
            percentage: 10,
            color: '#69e09c'
        },
        {
            label: 'Disk',
            percentage: 10,
            color: '#69e09c'
        },
    ]
    const tablist = [
        {
            tabName: "Overview",
            order: 1
        },
        {
            tabName: "Describe Namespace (YAML)",
            order: 2
        }
    ]
    const updateSelectedTab = (selectedTabOrder) => {
        setState({ ...state, selectedTabOrder: selectedTabOrder });
    }
    function handleEditorDidMount(_valueGetter) {
        setIsEditorReady(true);
        valueGetter.current = _valueGetter;
    }

    function handleShowValue() {
        alert(valueGetter.current());
    }
    const { cluster_id, namespace_name } = useParams();



    const dropdwn = [
        {
            id: 1,
            label: "show all"
        }
    ]
    const [showLoading, setShowLoading] = useState(false);


    useEffect(() => {
        fetchNamespaces();
        fetchNamespaceResouceQuota();
    }, [])


    function handleSuccessApiHit(data) {
        setState(new_state => ({
            ...new_state,
            namespaces: data,
            yaml_namespace: YAML.stringify(data)
        }));

        // const data1 = YAML.stringify(data);
        // console.log("hello jello",data1)
        //  var result= ValidateDataSet({namespaces:data},{namespaces:[VALIDATION_TYPE_YAML_FILE])
        setShowLoading(false);

    }

    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
        setShowLoading(false);
    }

    function fetchNamespaces(data, url) {

        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id, namespace_name: namespace_name }, properties.api.get_namespace_info),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setShowLoading(true);
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);


    }


    function fetchNamespaceResouceQuota() {
        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id, namespace_name: namespace_name }, properties.api.get_namespace_summary),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setShowLoading(true);
        InvokeApi(requestInfo, handleSuccessApiHit1, handleFailedApiHit1);
    }

    function handleSuccessApiHit1(data) {
        setState(new_state => ({
            ...new_state,
            namespace_resource_quota: data,

        }));
        setShowLoading(false);

    }
    console.log("hello ji", state)
    function handleFailedApiHit1(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
        setShowLoading(false);
    }

    let labels_list = [];
    const iteration_count = 1;
    let labels_list2 = [];


    function get_list(labels, list_type) {

        labels_list = [];
        //const iteration_count = 2;
        labels_list2 = [];
        if (labels) {
            labels.forEach(label => {
                labels_list.push(
                    {
                        key: label.metadata.name,
                        value: ""
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label.metadata.name}>
                        <div className="pod-lable">
                            {label.metadata.name}
                        </div>
                    </Tooltip>
                );
            })
        }

        if (list_type == "1") {
            return labels_list;

        }
        if (list_type == "2") {
            return labels_list2;

        }
        return [];
    }


    return (
        <div className={classes.root}>
            {
                showLoading ? <Loading /> : null
            }
            <div className="main-div d-flex-top">
                <div className="">
                    <p className="main-heading">Namespace Details</p>
                    <p className="sub-heading">Displaying Overall summary of your Namespace</p>
                </div>
                <div className="">
                    {/* <Link to={"/cluster/"+cluster_id+"/namepace/add"}> <button className="btn-add-primary">
                        <span className="icon-round flaticon-add-plus-button"></span>
                        Add Namespace
                    </button></Link> */}
                    {state.namespaces ? state.namespaces.db_info ?
                        <>
                            <Link to={"/cluster/" + cluster_id + "/namespace/" + state.namespaces.db_info.id + "/edit"}><button className="btn btn-submit">
                                Edit Namespace
                            </button></Link>
                            <Delete
                                display_data_name={state.namespaces.metadata.name}
                                data={{ entity_id: state.namespaces.db_info.id, name: "namespace" }}
                                refresh={fetchNamespaces}
                                varient="Button"
                                api_link={GenerateURL({ cluster_id: cluster_id, namespace_id: state.namespaces.db_info.id }, properties.api.get_namespace)}
                            />

                        </>
                        : null : null}
                </div>
            </div>
            <div className={classes.mainBodyDiv}>
                <CommonHorizontalTab
                    style={{ borderBottom: '1px solid #dedede' }}
                    tabList={tablist}
                    selectedTabOrder={state.selectedTabOrder}
                    updateSelectedTab={updateSelectedTab} />
                {state.selectedTabOrder == 1 ?
                    <div className={classes.subBoby}>
                        <InnerBox>
                            {
                                state.namespaces.status ?
                                    <InnerBoxData>
                                        <div className="align-self-top">
                                            <span className={classes.smallText}>Namespace Info</span>
                                            <div>
                                                <div className="d-flex">
                                                    <span className="text-black">Name:&nbsp;</span><span className="text-gray ">{state.namespaces.metadata.name}</span>
                                                </div>
                                                <div className="d-flex">
                                                    <span className="text-black">Status:&nbsp;</span><span className="text-gray d-flex align-center">{state.namespaces.status.phase} {state.namespaces.status.phase == "Active" ? <i className="ri-checkbox-circle-fill" style={{ color: '#69e09c', fontSize: '14px' }} /> : <i className='ri-close-line' style={{ fontSize: '15px', color: '#ffce25' }} />}</span>
                                                </div>
                                                <div className="d-flex">
                                                    <span className="text-black" style={{ whiteSpace: 'nowrap' }}>Default Pull Secret:&nbsp;</span>

                                                    {
                                                        state.namespaces.secrets.items.length > 0 ? <ExandableComponentList
                                                            compoenent_list={get_list(state.namespaces.secrets.items, 2)}
                                                            iteration_count={iteration_count}
                                                            isDF={true}
                                                            labels_list={get_list(state.namespaces.secrets.items, 1)}
                                                            expandable_component={
                                                                <>
                                                                    +{labels_list.length - iteration_count}
                                                                </>
                                                            } />
                                                            : <p>NA</p>}



                                                </div>

                                                <div className="d-flex">

                                                    <span className="text-black">Annotations:&nbsp;</span>
                                                    <span className="text-blue-anchor" ><Annotations annotations={state.namespaces.metadata.annotations ? Object.keys(state.namespaces.metadata.annotations) : null} anno_ui={state.namespaces.annotations ? state.namespaces.annotations : null} /></span>
                                                </div>
                                                <div className="d-flex">
                                                    <span className="text-black">Namespace Isolation:&nbsp;</span><span className="text-gray d-flex align-center">{state.namespaces.metadata.network_policy ? state.namespaces.metadata.network_policy.items.length > 0 ? "Enabled " : "Disabled" : "Disabled"} </span>
                                                </div>

                                            </div>
                                        </div>
                                    </InnerBoxData> : null

                            }

                            <InnerBoxData>
                                <div className="align-self-top">
                                    <span className={classes.smallText}>Resource Utilization</span>
                                    {
                                        state.namespace_resource_quota.limits ? <><h4>Limits</h4><TabTwo>
                                            <HealthStatusRoundMeter data={state.namespace_resource_quota.limits} />
                                        </TabTwo> <h4>Requests</h4><TabTwo>
                                                <HealthStatusRoundMeter data={state.namespace_resource_quota.requests} />
                                            </TabTwo> </> : <p>Not found</p>
                                    }
                                </div>
                            </InnerBoxData>
                            {
                                state.namespaces.status ?
                                    <InnerBoxData >

                                        <div>
                                            <span className={classes.smallText} style={{ paddingLeft: '15px' }}>Labels</span>
                                            {(state.namespaces.metadata || state.namespaces.labels) ? <UI_ExpandComponent data={state.namespaces.metadata ? state.namespaces.metadata.labels : ""} data_ui={state.namespaces.labels} /> : null}

                                        </div>
                                    </InnerBoxData> : null

                            }




                        </InnerBox>
                    </div >
                    :
                    <>
                        {/* <button onClick={handleShowValue} disabled={!isEditorReady}>
                            Show value
      </button> */}

                        {/* <Editor
                            height="90vh"
                            language="javascript"
                            options={{
                                minimap: {
                                    enabled: false,
                                },
                                fontSize: 18,
                                cursorStyle: "block",
                                wordWrap: "on",
                            }}
                            defineTheme={{
                                themeName: "my-theme",
                                themeData: {
                                    colors: {
                                        "editor.background": "#000000",
                                    },
                                },
                            }}
                            value={state.yaml_namespace ? state.yaml_namespace : "//console.log(hello world)"}
                            editorDidMount={handleEditorDidMount}
                        /> */}
                    </>
                }
            </div >
        </div >
    )
}

export default NamespaceDetails;

function Labels(props) {
    const labels = props.labels ? props.label : null;
    return (

        <div className="label-box">
            {labels ?
                Object.keys(labels).map(label => (
                    <Tooltip title={label + "=" + labels[label]}><div className="pod-lable">
                        {label + "=" + labels[label]}            </div></Tooltip>

                )) : null
            }

        </div>
    )
}
Labels.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function UI_ExpandComponent(props) {
    const data = props.data;
    const data_list = [];
    const iteration_count = 4;
    const data2 = props.data_ui ? props.data_ui : [];
    const list_2 = [];
    // Object.keys(data).forEach(anno => {
    //   data_list.push(
    //     <Tooltip placement="bottom" title={anno + "=" + data[anno]}>
    //       <div className="pod-lable">
    //         {anno + "=" + data[anno]}
    //       </div>
    //     </Tooltip>

    //   );
    // })

    // data2.forEach(dat => (
    //   data_list.push(
    //     <Tooltip placement="bottom" title={dat.env_key + "=" + dat.env_value}>
    //       <div className="pod-lable">
    //         {dat.env_key + "=" + dat.env_value}
    //       </div>
    //     </Tooltip>

    //   )
    // ))


    let labels_list = [];

    let labels_list2 = [];


    function get_list(labels, labels2, list_type) {
        labels_list = [];
        labels_list2 = [];
        if (labels) {
            Object.keys(labels).forEach(label => {
                labels_list.push(
                    {
                        key: label,
                        value: labels[label]
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label + "=" + labels[label]}>
                        <div className="pod-lable">
                            {label + "=" + labels[label]}
                        </div>
                    </Tooltip>
                );
            });


        }

        if (labels2) {
            labels2.forEach(label => {
                labels_list.push(
                    {
                        key: label.env_key,
                        value: label.env_value
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label.env_key + "=" + label.env_value}>
                        <div className="pod-lable">
                            {label.env_key + "=" + label.env_value}
                        </div>
                    </Tooltip>
                );
            });



        }
        if (list_type == "1") {
            return labels_list;

        }
        if (list_type == "2") {
            return labels_list2;

        }
        return [];
    }


    return (
        <>
            <ExandableKeyValues
                compoenent_list={get_list(data, data2, 2)}
                iteration_count={iteration_count}
                labels_list={get_list(data, data2, 1)}
                isLabel={true}
                expandable_component={
                    <>
                        +{labels_list.length - iteration_count}
                    </>
                } />
        </>
    );
}
UI_ExpandComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: 32,
        '& .cluster-head-data': {
            "& .cluster-name": {
                color: '#000!important'
            }
        },
    },
    mainBodyDiv: {
        border: ' 1px solid #dedede',
        boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        overflow: 'hidden',
        '& .headerul': {
            borderBottom: '1px solid #dedede',
            padding: '15px 20px 0px'
        },
        '& .expensionPanel': {
            border: 'none',
            backgroundColor: 'transparent',
            margin: '0px!important',
            '& .expensionPanelHead': {
                display: 'flex'
            }
        },
        '& .expensionPanelBody': {
            height: '100%',
            '& .head': {
                padding: '10px'
            }
        },
        '& .expensionPanelTab': {
            position: 'relative',
            top: '2px'
        }
    },
    subBoby: {
        backgroundColor: '#fff',
    },
    smallText: {
        fontSize: '16px',
        color: '#000',
        lineHeight: '2',
    }
}))


const InnerSubBox = styled('div')({
    backgroundColor: '#fcfcfc',
    border: '1px solid #dedede',
    borderRadius: '4px',
})
const InnerBoxData = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fcfcfc',
    border: '1px solid #dedede',
    padding: '10px',
    borderRadius: '4px',
    fontSize: '12px',
    overflow: 'hidden',
    '& .text-gray': {
        color: '#9e9e9e'
    }
})
const TabTwo = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .progress-container': {
        padding: '0px'
    },
    '& .CircularProgressbar': {
        '& .CircularProgressbar-trail': {
            strokeWidth: '8px'
        },
        '& .CircularProgressbar-path': {
            strokeWidth: '8px'
        }
    }
})

const Boxes = styled('div')({
    backgroundColor: '#fff',
    margin: '10px',
    display: 'grid',
    gap: '10px',
    gridTemplateColumns: '49.5% 49.5%',
    justifyContent: 'space-between',
    '& .header-part': {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #dedede',
        width: '100%',
        '& .heading-area': {
            marginLeft: '5px'
        },
        '& .mainheading': {
            fontSize: '18px',
            lineHeight: '1.2'
        },
        '& .subheading': {
            fontSize: '12px',
            color: '#989898',
            lineHeight: '1.2'
        }
    },
    '& .body-part': {
        display: 'grid',
        padding: '10px'
    },
    '& .template-ingress': {
        gridTemplateColumns: '50% 50%'
    },
    '& .template-resources': {
        gridTemplateColumns: '33% 33% 32%',
        gap: '10px'
    }
})
const InnerBox = styled('div')({
    backgroundColor: '#fff',
    margin: '10px',
    display: 'grid',
    gap: '10px 15px',
    gridTemplateColumns: '33% 32% 32%',
    '& .pd-top-20': {
        padding: '20px 0px'
    },
    '& .nodes-data': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        padding: '5px 0px',
        lineHeight: '14px',
        '& .width-fixed': {
            width: '92px',
            display: 'inline-block'
        },
        '& .text-color-grey': {
            color: '#989898',
            marginLeft: '3px'
        },
        '& .text-color-blue': {
            color: '#0086ff',
            marginLeft: '3px'
        }
    },
    '& .text-black': {
        fontSize: '12px'
    },
    '& .k8s-version': {
        fontSize: '11px',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0px',
        '& .flaticon-circle-with-check-symbol': {
            '&:before': {
                fontSize: '14px!important'
            },
            '&:after': {
                fontSize: '14px'
            }
        },
        '& img': {
            width: '36px',
            height: '36px',
            display: 'block',
        }
    }
})


const ITEM_HEIGHT = 48;
export function LongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const application_id = props.application_id;
    return (
        <div>

            <span aria-label="more"
                role='button' tabIndex={0}
                aria-controls="long-menu"
                aria-haspopup="true" onKeyDown={()=>{}} onClick={handleClick} className="text-blue-anchor" style={{ fontSize: '12px' }}>20</span>

            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>
                    <div className="pod-lable">
                        Pod-template-jardafd-8112
                    </div>
                </MenuItem>
            </Menu>
        </div>
    );
}
LongMenu.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
