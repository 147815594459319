import React, { useState, useCallback, useMemo } from "react";
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getJiraTypeIcon } from "./AddJiraStories";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

const AddedJiraTicket = ({ onClose, onJiraTicketClick, searchType, selectedTickets, onJiraTicketRemove, path }) => {
    const { application_id } = useParams();
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 400); // Debounced search input for better performance
    const [hoveredTicket, setHoveredTicket] = useState(null);
    
    const classes = useStylesForJira({ selectedTickets });

    // Handle search query change
    const handleSearchQueryChange = useCallback((e) => {
        setSearchQuery(e.target.value);
    }, []);

    // Memoized filtered tickets based on search query
    const filteredTickets = useMemo(() => {
        if (!debouncedSearchQuery) return selectedTickets;
        return selectedTickets.filter((ticket) =>
            ticket.story_name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
        );
    }, [debouncedSearchQuery, selectedTickets]);

    return (
        <div className={classes.root}>
            <div className="header d-flex f-direction-column">
                <span className="font-16 font-weight-600 color-value">Added Ticketing Items</span>
                {selectedTickets?.length <= 0 && (
                    <span className="font-14 font-weight-500 color-icon-secondary" style={{ marginBottom: '19px' }}>
                        Add Ticketing Items from the left Table
                    </span>
                )}
            </div>

            {selectedTickets?.length > 0 && (
                <div className={classes.inputWrapper}>
                    <span className='ri-search-2-line font-20' style={{ lineHeight: '1', color: '#CACACA' }}></span>
                    <input
                        type='text'
                        placeholder='Search'
                        name='search_query'
                        value={searchQuery}
                        onChange={handleSearchQueryChange}
                    />
                </div>
            )}

            <div className={classes.resultContainer}>
                {filteredTickets.length > 0 ? (
                    <div className={classes.resultTable}>
                        <div className="table-header">
                            <span className="font-12 font-weight-600 color-value">Name</span>
                            <span className="font-12 font-weight-600 color-value">Status</span>
                        </div>
                        {filteredTickets.map((jiraObject) => {
                            const { issue_type, story_name, status,valid } = jiraObject;
                            const selected = hoveredTicket === story_name;

                            return (
                                <div
                                    key={story_name}
                                    className={`${classes.resultItem} container-item`}
                                    onMouseEnter={() => setHoveredTicket(story_name)}
                                    onMouseLeave={() => setHoveredTicket(null)}
                                >
                                    <div className='d-flex align-center' style={{ gap: "12px" }}>
                                        {getJiraTypeIcon(issue_type)}
                                        <Tooltip title={story_name} arrow>
                                            <div className='jira-summary font-14 font-weight-500 text-ellipsis color-value'>
                                                {story_name}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="d-flex align-center justify-end" style={{ gap: '4px' }}>
                                        {selected ? (
                                            <div className={'blueBtn avtar'} onClick={() => { setHoveredTicket(null); onJiraTicketRemove(jiraObject); }}>
                                                Remove
                                            </div>
                                        ) : (
                                            <>
                                                <>
                                                    <Tooltip title={status} arrow>
                                                        <div className={`${valid ? "validBtn" : "greyStatus"} text-ellipsis avtar`}>{status}</div>
                                                    </Tooltip>
                                                    <div className={`${valid ? "validBtn" : "InValidBtn"} text-ellipsis avtar`}>{valid ? "Valid" : "InValid"}</div>
                                                </>
                                            </>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="no-stories">
                        <div className='d-flex align-center f-direction-column' style={{ gap: "12px" }}>
                            <div className={classes.iconContainer}>
                                <span className='ri-inbox-line font-28'></span>
                            </div>
                            <span className='font-14 font-weight-600 color-key-78'>No Ticketing Items Found</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

AddedJiraTicket.propTypes = {
    onClose: PropTypes.func.isRequired,
    onJiraTicketClick: PropTypes.func.isRequired,
    selectedTickets: PropTypes.array.isRequired,
    onJiraTicketRemove: PropTypes.func.isRequired,
};

export default AddedJiraTicket;

const useStylesForJira = makeStyles((theme) => ({
    searchContainer: {
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        padding: '10px 12px 10px 12px',
        borderBottomLeftRadius: (props) => props.openSearch ? "0px" : "6px",
        borderBottomRightRadius: (props) => props.openSearch ? "0px" : "6px",
        borderBottom: (props) => props.openSearch ? "none" : "1px solid #E6E6E6",

        "& .heading": {
            padding: "10px 12px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .input-component": {
                marginBottom: "0px !important",
            },
            "& .divider": {
                height: "30px",
                width: "1px",
                backgroundColor: "#CACACA",
                margin: "0px 8px",
            }
        },
        "& .search-container": {
            padding: "10px 12px",
            height: "40px",
            border: "1px solid #E6E6E6",
            borderLeft: "none",
            borderRight: "none",
            backgroundColor: "#FAFAFA",
            width: "100%",
            display: "flex",
            gap: "10px",
            "& input": {
                border: "none",
                background: "#FAFAFA",
                outline: "none",
                padding: "0 !important",
                "&:focus": {
                    outline: "none !important",
                    boxShadow: "none !important",
                    border: "none !important",
                },
            }
        }
    },
    inputWrapper: {
        padding: "10px 12px",
        height: "40px",
        //border: "1px solid #E6E6E6",
        backgroundColor: "#FFFFFF",
        width: "100%",
        display: "flex",
        gap: "10px",
        borderRadius: '6px',
        "& input": {
            border: "none",
            background: "#FFFFFF",
            outline: "none",
            padding: "0 !important",
            "&:focus": {
                outline: "none !important",
                boxShadow: "none !important",
                border: "none !important",
            },
        }
    },
    resultContainer: {
        // "& > .container-item": {
        //   borderLeft: 'none',
        //   borderRight: 'none',
        // },
        // "& > .container-item:first-child": {
        //   borderTop: "none",
        //   borderRadius: "0px",
        //   borderTopLeftRadius: "0px",
        //   borderTopRightRadius: "0px",
        // },

        // /* Style for the last child (direct child) */
        // "& > .container-item:last-child": {
        //   borderBottom: "none",
        //   borderTop: "none",
        //   borderTopLeftRadius: "0px",
        //   borderTopRightRadius: "0px",
        //   borderBottomLeftRadius: "4px",
        //   borderBottomRightRadius: "4px",
        // },
        
    },
    resultItem: {
        padding: "12px",
        // borderLeft:"none",
        // borderRight:"none",
        display: "grid",
        gridTemplateColumns:'56% 1fr',
        gap: "12px",
        //borderRadius: "8px",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: 'space-between',
        "&:hover": {
            backgroundColor: (props) => props.isFinalise ? '#FFFFFF' : "#f2f2f2",
            borderColor: (props) => props.isFinalise ? '#FFFFFF' : "#f2f2f2"
        },
        '& .greyStatus': {
            padding: '4px 6px 4px 6px',
            borderRadius: "6px",
            backgroundColor: '#F4F4F4',
            color: '#949494',
            fontSize: '12px',
            fontWeight: '700',
            border: '1px solid #E6E6E6',
            textWrap: 'nowrap',
            maxWidth:'96px'
        },
        '& .InValidBtn':{
            padding: '4px 6px 4px 6px',
            borderRadius: "6px",
            backgroundColor: '#626262',
            color: '#FFFFFF',
            fontSize: '12px',
            fontWeight: '700',
            border: '1px solid #E6E6E6',
            textWrap: 'nowrap',
            maxWidth: '96px'
        },
        '& .validBtn':{
            padding: '4px 6px 4px 6px',
            borderRadius: "6px",
            backgroundColor: '#E6FBEA',
            color: '#2EBE79',
            fontSize: '12px',
            fontWeight: '700',
            //border: '1px solid #E6E6E6',
            textWrap: 'nowrap',
            maxWidth: '96px'
        },
        '& .blueBtn':{
            padding: '4px 6px 4px 6px',
            borderRadius: "6px",
            backgroundColor:"#0086FF",
            color:"#FFFFFF",
            fontSize: '12px',
            fontWeight: '700',
        }
    },
    resultChip: {
        height: '28px',
        width: '162px',
        padding: '4px 8px 4px 8px',
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: '20px 1fr 16px',
        borderRadius: '24px',
        backgroundColor: '#F4F4F4'
    },
    icon: {
        padding: "6px",
        borderRadius: "4px",
        color: "#ffffff",
        width: "24px",
        height: "24px",
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
    },
    selectedTicketsSection: {
        "& > .container-item": {
            borderBottom: '1px solid #E6E6E6',
            borderTop: 'none',
            borderRadius: '0px',
            padding: '7px 8px 7px 8px',
        },
        "& > .container-item:first-child": {
            borderTop: 'none',
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px'
        },

        /* Style for the last child (direct child) */
        "& > .container-item:last-child": {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            borderBottom: 'none !important',
        },
        '& .jira-summary': {
            fontSize: '12px !important',
            color: "#505050 !important",
        },

        "& $resultItem": {
            borderLeft: "none !important",
            borderRight: "none !important",
        },
    },
    root: {
        maxHeight: '437px',
        padding: '16px 0px 0px 0px',
        border: '1px solid #E6E6E6',
        backgroundColor: '#FFFFFF',
        borderRadius: '6px',
        '& .header': {
            padding: '0px 16px 0px 16px',
        },
        '& .no-stories': {
            height: '354px',
            display: 'grid',
            placeItems: 'center',
            backgroundColor: '#FAFAFA',
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
        }
    },
    iconContainer: {
        width: "56px",
        height: "56px",
        borderRadius: "8px",
        backgroundColor: "#F4F4F4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#787878",
    },
    resultTable: {
        height:'354px',
        minHeight: '354px',
        overflowY: 'auto',
        '& .table-header': {
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            padding: '12px 16px',
            border: '1px solid #E6E6E6',
            borderLeft: 'none',
            borderRight: 'none',
            position:'sticky',
            top:'0',
            backgroundColor:'#FFFFFF',
        },
        "& > .container-item": {
            // display: 'flex',
            // justifyContent: 'space-between',/* Ensures the status stays at the end */
            // alignItems: 'center',
            borderBottom: '1px solid #E6E6E6',
            padding: '7px 8px',
        },
        "& > .container-item:first-child": {
            borderTop: 'none',
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px'
        },

        /* Style for the last child (direct child) */
        "& > .container-item:last-child": {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
        },
        '& .jira-summary': {
            fontSize: '12px !important',
            color: "#505050 !important",
            flexGrow: 1,
            flexShrink: 1,
            
        },
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#0086FF !important",
        }
    }
}))