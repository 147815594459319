import React from 'react';
import PropTypes from 'prop-types';
const Stepper = (props) => {
    const steps=props.steps;

    console.log("steps",steps);
    
    function getIcon(status){
       
        switch(status){
            case "success":
                return(
                    <div className="md-step-circle success">
                        <i className="ri-check-line font-18" style={{color:'#62E187'}} />
                    </div>
                );
            case "skipped":
                return(
                    <div className="md-step-circle skipped">
                        <i className="ri-git-branch-line font-18" style={{color:'#52bfe7'}}></i>
                    </div>
                );
            case "failed":
                return(
                    <div className="md-step-circle fail">
                        <i className="ri-error-warning-line font-16" style={{color:'#ff8969'}}></i>
                    </div>
                );
            case "progress":
                return(
                    <div className="md-step-circle yellow">
                        <i className="ri-timer-2-line font-16" style={{color:'#ffc436'}} ></i>
                    </div>
                );
            case "pending":
                return(
                    <div className="md-step-circle revoked">
                        <i className="ri-thumb-up-line font-16"  style={{ color:'#c294f1'}} />
                    </div>
                );
            case "waiting":
                return(
                    <div className="md-step-circle ">
                        <i className="ri-pause-circle-line font-18" style={{color:'#0086ff'}} ></i>
                    </div>
                );
            case "disapproved":
                return(
                    <div className="md-step-circle fail">
                        <i className="ri-thumb-down-line font-18" style={{color:'rgb(241, 136, 104)'}}></i>
                    </div>
                );
            case "suspended":
                return(
                    <div className="md-step-circle " style={{border:'3px solid rgb(142,142,142)'}}>
                        <i className="ri-file-info-line font-18" style={{color:'rgb(142,142,142)'}}></i>
                     
                    </div>
                );
            case "stopped":
                return(
                    <div className="md-step-circle " style={{border:'3px solid #e9797e'}}>
                        <i className="ri-stop-line font-18" style={{ color:'#e9797e'}}></i>
                    </div>
                );
            default:
                return(
                    <div className="md-step-circle ">
                        <span style={{fontSize:'13px', color:'#000', textAlign:'center', position:'relative', bottom:'6px'}}> </span>
                    </div>
                );
        }
    }

    function getLeftBar(status){
        switch(status){
            case "success":
                return(
                    <div className="md-step-bar-left success"></div>
                );
            case "skipped":
                return(
                    <div className="md-step-bar-left skipped"></div>
                );
            case "failed":
                return(
                    <div className="md-step-bar-left fail"></div>
                );
            case "progress":
                return(
                    <div className="md-step-bar-left yellow"></div>
                );
            case "pending":
                return(
                    <div className="md-step-bar-left purple"></div>
                );
            case "waiting":
                return(
                    <div className="md-step-bar-left blue"></div>
                );
            case "disapproved":
                return(
                    <div className="md-step-bar-left fail"></div>
                );
            case "suspended":
                return(
                    <div className="md-step-bar-left" style={{borderTopColor:'rgb(142, 142, 142)'}}></div>
                );
            default:
                return(
                    <div className="md-step-bar-left "></div>
                );
        }
    }

    function getRightBar(status){
        switch(status){
            case "success":
                return(
                    <div className="md-step-bar-right success"></div>
                );
            case "skipped":
                return(
                    <div className="md-step-bar-right skipped"></div>
                );
            case "failed":
                return(
                    <div className="md-step-bar-right fail"></div>
                );
            case "progress":
                return(
                    <div className="md-step-bar-right yellow"></div>
                );
            case "pending":
                return(
                    <div className="md-step-bar-right purple"></div>
                );
            case "waiting":
                return(
                    <div className="md-step-bar-right blue"></div>
                );
            case "disapproved":
                return(
                    <div className="md-step-bar-right fail"></div>
                );
            case "suspended":
                return(
                    <div className="md-step-bar-right" style={{borderTopColor:'rgb(142, 142, 142)'}}></div>
                );
            default:
                return(
                    <div className="md-step-bar-right "></div>
                );
        }
    }
    
    return(
        <div className="md-stepper-horizontal" >
            {
                steps.map(step =>(
                    <div className="md-step active " >
                        {getIcon(step.status)}
                        <div className="md-step-title">
                            {step.main_text}<br />
                            <span>{step.sub_text}</span>
                        </div>
                        {getLeftBar(step.status)}
                        {getRightBar(step.status)}
                    </div>
                ))
            } 
        </div>
    )
}

Stepper.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default  Stepper ;
