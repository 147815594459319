import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import properties from '../../../../../properties/properties';
import GenerateURL, { GET_Request_Info } from '../../../../../util/APIUrlProvider';
import { Loading } from '../../../../utils/Loading';
import InvokeApi from '../../../../../util/apiInvoker';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Delete from '../../../../../components/genericComponents/Delete';
import { TempBodyRowTempTwo } from './KedaSection';


function InfraMonitoringSection(props) {
  const cluster_id = props.cluster_id;

  const [state, setState] = useState({
    loaded: false,
    openDetailView: false
  });

  useEffect(() => {
    fetchMonitoringInfo();
  }, [cluster_id]);

  function fetchMonitoringInfo() {
    const request_info = GET_Request_Info(GenerateURL({ cluster_id: cluster_id }, properties.api.get_all_infra_monitoring));
    InvokeApi(request_info, onMonitoringFetchSuccess, onMonitoringFetchFail);
  }

  function onMonitoringFetchSuccess(data) {
    setState(new_state => ({
      ...new_state,
      data: data,
      openDetailView: data?.length > 0 ? true : false,
      loaded: true,
    }));
  }

  function onMonitoringFetchFail(error) {
    setState(new_state => ({
      ...new_state,
      loaded: true,
      error: error,
    }));
  }
  const toggleDetailView = () => {
    setState(prevState => ({
      ...prevState,
      openDetailView: !prevState.openDetailView
    }))
  }

  return (
    <>
      <div className="header-part d-flex align-center " style={ state.openDetailView ? { borderBottom: '1px solid #dedede', justifyContent: 'space-between', padding: '0px 5px 0px 0px' } : {borderBottom: 'none'}}>
        <div className="heading-area setup-summary d-flex align-center space-between width-full">
          <div>
            <p className="font-12 font-weight-500 color-primary">Infra Monitoring</p>
            <p className="font-12 font-weight-500 color-secondary">Displaying list of configured Infra Monitoring tools</p>
          </div>
          <div className="right-content" >
            <button className="btn btn-transparent">
              {
                state.openDetailView ?
                <span className='ri-arrow-up-s-line font-24 color-tertiary' style={{fontWeight: "500"}} onClick={toggleDetailView}></span>
                :
                <span className='ri-arrow-down-s-line font-24 color-tertiary' style={{fontWeight: "500"}} onClick={toggleDetailView}></span>
              }
            </button>
          </div>

        </div>
      </div>
      {
        state.openDetailView &&  state.loaded ?
              <>
                {
                  state.data.length > 0 ?

                  <div className="d-flex align-center justify-end pr-20 border-bottom height-40">
                      <Link to={"/cluster/" + cluster_id + "/monitoring/infra/" + (state.data[0] ? state.data[0].id : 1) + "/edit"}>
                      <button className="btn btn-transparent">
                         <span className='ri-edit-2-line font-20 color-tretiary'style={{fontWeight: "500"}}></span>
                      </button>
                      </Link>
                      <Delete
                        display_data_name={state.data[0].name}
                        data={{ entity_id: state.data[0].id, name: "infra_monitoring" }}
                        refresh={fetchMonitoringInfo}
                        varient="onlyIconNew"
                        api_link={GenerateURL({ cluster_id: cluster_id, monitoring_id: state.data[0] ? state.data[0].id : 1 }, properties.api.get_infra_monitoring)}
                      />
                    </div> :

                    null
                }
              </>
              :
              null
      }
      {
        state.openDetailView &&
        <div className="d-flex align-center" style={{ display: 'grid', gridTemplateColumns: ' 33.3% 33.3% 1fr' }}>
          {
            !state.loaded ?
              <Loading varient="light" />
              :
              <>
                {
                  state.data.length > 0 ?
                    <>
                      {
                        state.data.map(tool => (
                          <>
                            <div className="sub-box-one-summary" style={{ width: '100%' }}>
                              <div className="sub-header-setup-summary">
                                <p>Grafana</p>
                              </div>
                              <TempBodyRowTempTwo2>
                                <div className="icon-box">
                                  <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                                    <img src="/images/resources_logos/grafana-logo.svg" alt=".." />
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex"><span className="text-black">Status:&nbsp;</span>
                                    <>{
                                      tool.status == "READY" ?
                                        <><span className="text-gray">Ready</span><i className="ri-checkbox-circle-fill" style={{ color: '#69e09c' }} /></>
                                        :
                                        <><span className="text-gray">Not Ready</span><i className='ri-close-line' style={{ fontSize: '18px', color: '#ff8969' }} /></>
                                    } </>
                                  </div>
                                  <div className="d-flex">
                                    <span className="text-black">URL:&nbsp;</span>
                                    <a className="text-blue-anchor text-ellipsis" target="_blank" href={tool.grafana_url}>{tool.grafana_url} </a>
                                  </div>
                                  <div className="d-flex">
                                    <span className="text-black">Username:&nbsp;</span>
                                    <span className="text-gray text-ellipsis">{tool.grafana_username} </span>
                                  </div>
                                  <div className="d-flex">
                                    <span className="text-black">Password:&nbsp;</span>
                                    <span className="text-gray" >*******</span>
                                  </div>
                                </div>
                              </TempBodyRowTempTwo2>
                            </div>
                            <div className="sub-box-one-summary" style={{ width: '100%' }}>
                              <div className="sub-header-setup-summary">
                                <p>Prometheus</p>
                              </div>
                              <TempBodyRowTempTwo2>
                                <div className="icon-box">
                                  <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                                    <img src="/images/resources_logos/propmetheus-logo.svg" alt=".." />
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex"><span className="text-black">Status:&nbsp;</span>
                                    <>{
                                      tool.status == "READY" ?
                                        <><span className="text-gray">Ready</span><i className="ri-checkbox-circle-fill" style={{ color: '#69e09c' }} /></>
                                        :
                                        <><span className="text-gray">Not Ready</span><i className='ri-close-line' style={{ fontSize: '18px', color: '#ff8969' }} /></>
                                    } </>
                                  </div>
                                  <div className="d-flex">
                                    <span className="text-black">URL:&nbsp;</span>
                                    <a className="text-blue-anchor text-ellipsis" target="_blank" href={tool.prometheus_url}>{tool.prometheus_url} </a>
                                  </div>
                                </div>
                              </TempBodyRowTempTwo2>
                            </div>
                            <div className="sub-box-one-summary" style={{ width: '100%' }}>
                              <div className="sub-header-setup-summary">
                                <p>Alert Manager</p>
                              </div>
                              <TempBodyRowTempTwo2>
                                <div className="icon-box">
                                  <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                                    <img src="/images/resources_logos/alert-manager-logo.svg" alt=".." />
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex"><span className="text-black">Status:&nbsp;</span>
                                    <>{
                                      tool.status == "READY" ?
                                        <><span className="text-gray">Ready</span><i className="ri-checkbox-circle-fill" style={{ color: '#69e09c' }} /></>
                                        :
                                        <><span className="text-gray">Not Ready</span><i className='ri-close-line' style={{ fontSize: '18px', color: '#ff8969' }} /></>
                                    } </>
                                  </div>
                                  <div className="d-flex">
                                    <span className="text-black">URL:&nbsp;</span>
                                    <a className="text-blue-anchor text-ellipsis" target="_blank" href={tool.alert_manager_url}>{tool.alert_manager_url} </a>
                                  </div>
                                </div>
                              </TempBodyRowTempTwo2>
                            </div>
                          </>
                        ))
                      }
                    </>
                    :
                    <>
                      <div className="sub-box-one-summary" style={{ width: '100%' }}>
                        
                        <TempBodyRowTempTwo>
                          <div className="icon-box">
                            <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                              <img src="/images/resources_logos/grafana-logo.svg" alt=".." />
                            </div>
                          </div>
                          <div>
                            <Link to={"/cluster/" + cluster_id + "/monitoring/infra/add"} >
                              <button className='btn btn-outline-primary d-flex align-center mr-0'>
                                <span className='ri-settings-4-line font-20'></span>&nbsp;<span>Configure Grafana</span>
                              </button>
                            </Link>
                          </div>
                        </TempBodyRowTempTwo>
                      </div>

                      <div className="sub-box-one-summary" style={{ width: '100%' }}>
                       
                        <TempBodyRowTempTwo>
                          <div className="icon-box">
                            <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                              <img src="/images/resources_logos/propmetheus-logo.svg" alt=".." />
                            </div>
                          </div>
                          <div>
                            <Link to={"/cluster/" + cluster_id + "/monitoring/infra/add"} >
                              <button className='btn btn-outline-primary d-flex align-center mr-0'>
                                <span className='ri-settings-4-line font-20'></span>&nbsp;<span>Configure Prometheus</span>
                              </button>
                            </Link>
                          </div>
                        </TempBodyRowTempTwo>
                      </div>

                      <div className="sub-box-one-summary" style={{ width: '100%' }}>
                        
                        <TempBodyRowTempTwo>
                          <div className="icon-box">
                            <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                              <img src="/images/resources_logos/alert-manager-logo.svg" alt=".." />
                            </div>
                          </div>
                          <div>
                            <Link to={"/cluster/" + cluster_id + "/monitoring/infra/add"} >
                              <button className='btn btn-outline-primary d-flex align-center mr-0'>
                                <span className='ri-settings-4-line font-20'></span>&nbsp;<span>Configure Alert Manager</span>
                              </button>
                            </Link>
                          </div>
                        </TempBodyRowTempTwo>
                      </div>
                    </>
                }
              </>
          }
        </div>
      }

    </>
  );
}
InfraMonitoringSection.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


export default InfraMonitoringSection;

const ITEM_HEIGHT = 48;

export function MoreOption(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const application_id = props.application_id;
  return (
    <div>
      <button className="btn btn-transparent"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        >
          <i className="ri-more-2-fill"  style={{ color: '#0096db', fontSize: '2rem' }} />
      </button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
          },
        }}
      >
        <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

          <Link to="/namespaces">
            <span className="hover-content-link" ><i className='ri-add-line' style={{ color: '#9e9e9e', fontSize: '16px' }} />&nbsp;Add Ingress</span>
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
MoreOption.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const TempBodyRow = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
  gap: '10px',
  padding: '30px 10px',
  borderBottom: '1px solid #dedede',
  alignItems: 'flex-start',
  overflow: 'hidden',
  backgroundColor: '#fcfcfc',
  display: 'grid',
  gridTemplateColumns: '20% 40% 1fr',
  justifyContent: 'space-between',
  '& .d-flex': {
    overflow: 'hidden',
  },
  '&:last-child': {
    border: 'none'
  },
  '& .bullet': {
    position: 'relative',
    top: '3px',
    '&.purple': {
      backgroundColor: '#245dff!important'
    }
  },
  '& .createdat': {
    fontSize: '10px',
    color: '#878787'
  },
  '& .fre-text': {
    fontSize: '11px',
    color: '#000',
    '& .uses-text': {
      fontSize: '9px',
      color: '#878787'
    }
  },
  '& .status-font': {
    color: '#fff',
    width: '40%',
    '& .flaticon-circle-with-check-symbol': {
      '&:before': {
        fontSize: '14px!important'
      }
    }
  },
  '& div': {
    overflow: 'hidden',
    color: '#9e9e9e'
  },
  '& .url': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})

const TempBodyRowTempTwo2 = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
  gap: '10px',
  padding: '20px 10px',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: '#fff',
  display: 'grid',
  gridTemplateColumns: '30% 1fr',
  justifyContent: 'space-between',
  '& .public-icon-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .text-gray': {
    color: '#9e9e9e'
  }
})