import React, { useEffect, useState, useMemo } from 'react';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

import { Loading, LoadingText } from '../../../utils/Loading';
import { GenericValidator, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { CopyToClipboard, getDuration } from '../../../../util/util';
import ActivityStepDetails from '../../../superAdmin/activityFramework/components/ActivityStepDetails';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import { ParameterComponent } from './HistoryPageComponents/DeployHistory';
import RunningDotsComponent from './componentLogsViewer/RunningDotsComponent';
import { useParams } from 'react-router-dom';
import SelectWithInsideLabel from '../../../../components/genericComponents/inputs/SelectWithInsideLabel';
import useFetchPermissions from '../../../../components/customHooks/useFetchPermissions';

const LatestDeployStripNew = props => {
    const classes = useStyles();
    const type = props.type;
    const service_id = props.service_id;
    const env_id = props.env_id;
    const workspace_base_url = properties.workspace_url;
    const service_data = props.service_data;
    const env_name = props.env_name;
    const top_summary_data = props.top_summary_data;
    const openDialog = props.openDialog;
    const params = useParams();
    const masterLabel = props.masterLabel;
    const env_details = props.env_details;
    const application_id = params.application_id;
    const env_name_service = props.service_env_name;
    const deployment_list = props.deployment_list ? props.deployment_list : [];
    const is_env_down = props.is_env_down ? props.is_env_down : null;
    console.log(params, env_name, top_summary_data, service_data, 'params_ssddsd');
    var event_source = null
    var component_env = service_data && service_data.component_env ? service_data.component_env.map(x =>
        (x.project_env && x.project_env.name)
    ) : []
    component_env = component_env ? component_env.map(item => (item)) : null
    var service_name = service_data.name ? service_data.name : top_summary_data ? top_summary_data.service_name : null;

    var service_env = service_data.env ? service_data.env : top_summary_data ? top_summary_data.env_name : null;
    service_env = service_env ? service_env.toLowerCase() : null;


    var service_env_type = service_data.type ? service_data.type : top_summary_data ? top_summary_data.type : null;
    service_env_type = service_env_type.toLowerCase();
    var revokeUrlPath = properties.api.env_deploy_revoke_url
    var refresh_count = props.refresh_count;
    const [state, setState] = useState({
        history_list: [],
        activity_list: [],
        event_source: null,
        data: {},
        error: {},
        all_available_deployments: []
    });
    const [openMonitoring, setOpenMonitoring] = useState(false)
    const deployId = state.last_deploy && state.last_deploy.id ? state.last_deploy.id : null
    const deploy_status = state?.last_deploy?.activity_status?.status
    const deploy_by_user = useMemo(() => getBuildByUserName(state.last_deploy), state.last_deploy)
    const image = state?.last_deploy?.image_name
    const deploy_number = state?.last_deploy?.deploy_number

    const workspaceParams = { action_number: deploy_number, action_status: deploy_status, action_by: deploy_by_user, image: image }

    useEffect(() => {
        if (deployId) {
            removeArrData()
        }
    }, [deployId])

    useEffect(() => {
        if (deployment_list && deployment_list.length > 0) {
            // fetchRecentBuildDeployData(deployment_list[0].id ? deployment_list[0].id : null);
            // setState(new_state => ({
            //   ...new_state,
            //   all_available_deployments: deployment_list,
            //   data: {
            //     latest_deployment: deployment_list[0].id ? deployment_list[0].id : null
            //   }
            // }))

            console.log(deployment_list, "deploykjfd")
            let all_available_deployments_for_monitoring = deployment_list.map((item) => ({ label: item.label, id: item.label }))
            if (refresh_count == 1) {
                fetchRecentBuildDeployData(deployment_list[0].id ? deployment_list[0].id : null);
                setState((new_state) => ({
                    ...new_state,
                    all_available_deployments: deployment_list,
                    all_available_deployments_for_monitoring: all_available_deployments_for_monitoring,
                    data: {
                        ...new_state.data,
                        latest_deployment: deployment_list[0].id ? deployment_list[0].id : null,
                        latest_deployment_name: deployment_list[0].label ? deployment_list[0].label : null,
                    }
                }))
            } else {
                fetchRecentBuildDeployData(state.data.latest_deployment ? state.data.latest_deployment : null);
                setState(new_state => ({
                    ...new_state,
                    all_available_deployments: deployment_list,
                    all_available_deployments_for_monitoring: all_available_deployments_for_monitoring,
                    data: {
                        ...new_state.data,
                        latest_deployment: new_state.data.latest_deployment,
                        latest_deployment_name: new_state.data.latest_deployment_name,
                    }
                }))
            }
        }



    }, [env_id, refresh_count])

    // if(refresh_count == 1){
    //   fetchRecentBuildDeployData(deployment_list[0].id ? deployment_list[0].id : null);
    //   setState(new_state => ({
    //     ...new_state,
    //     all_available_deployments: deployment_list,
    //     data: {
    //       latest_deployment: deployment_list[0].id ? deployment_list[0].id : null
    //     }
    //   }))
    // }else{
    //   fetchRecentBuildDeployData(state.data.latest_deployment? state.data.latest_deployment : null);
    //   setState(new_state => ({
    //     ...new_state,
    //     all_available_deployments: deployment_list,
    //     data: {
    //       latest_deployment:  new_state.data.latest_deployment,
    //     }
    //   }))
    // }

    // useEffect(() => {
    //   if(deployment_list && deployment_list[0] && state.last_deploy && state.last_deploy[0]){
    //     var find_selected_deployment = state.last_deploy.find(item => item.)
    //     setState(new_state => ({
    //       ...new_state,
    //       all_available_deployments: deployment_list,
    //       data: {
    //         latest_deployment: deployment_list[0].id?deployment_list[0].id : null
    //       }
    //     }))  }

    // }, [state.last_deploy])


    function getEnvNamefromId() {
        var env_name = "";
        if (service_data && service_data.component_env) {
            service_data.component_env.map(item => {
                if (item.id == env_id) {
                    env_name = item.project_env.name
                }
            })
        }
        return env_name;
    }
    var service_env_name = service_env ? service_env : getEnvNamefromId()
    function fetchRecentBuildDeployData(id) {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_ci_cd_data_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (res) => { onfetchRecentBuildDeployDataSuccess(res, id) }, onfetchRecentBuildDeployDataFail);
        setState((new_state) => ({
            ...new_state,
            loading: true
        }));
    }

    function onfetchRecentBuildDeployDataSuccess(response, id) {
        console.log(response, id, "find_selected_deployment_data")
        if (response.last_deploy) {
            var find_selected_deployment_data = response.last_deploy.find(item => item.env_cd_detail_id === Number(id));
        }

        console.log(find_selected_deployment_data, "find_selected_deployment_data")
        setState((new_state) => ({
            ...new_state,
            ci_cd_data: response,
            in_queue_data: response.deploy_in_queue_data,
            last_build: response.last_build,
            last_deploy: find_selected_deployment_data,
            last_promote: response.last_promote,
            all_last_deploy: response.last_deploy,
            loading: false
        }));

    }

    function onfetchRecentBuildDeployDataFail(response) {
        setState((new_state) => ({
            ...new_state,
            error: response,
            loading: false
        }));
    }

    // useEffect(() => {
    //   if(state.data.latest_deployment){
    //     fetchRecentBuildDeployData();
    //   }


    // }, [type, env_id, refresh_count, state.data.latest_deployment])








    function onRevoke(task_id) {
        var cd_id = state.data.latest_deployment;
        console.log(cd_id, "flkdsakfas")
        var url_temp = GenerateURL({ service_id: service_id, env_id: env_id, cd_id: cd_id, id: task_id }, revokeUrlPath);
        var fetchOptions = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        PostData(url_temp, {}, handleSuccessRevoke, handleFailedRevoke);
        setState((new_state) => ({
            ...new_state, history_info_status: "REQUESTED",
        }));
    }
    function handleSuccessRevoke(respone) {
        setState((new_state) => ({
            ...new_state,
            history_info_status: "SUCCESS",
            loading: false,
            trigger: "revokedsuccess"
        }));
        props.refresh();
    }
    function handleFailedRevoke(error) {
        setState((new_state) => ({
            ...new_state,
            history_info_status: "SUCCESS",
            error: true,
            loading: false,
            triger: "revokedfailed"
        }));
    }

    function validateAndTrigger(type) {
        var errors = GenericValidator(state.deploy_tag, [VALIDATION_TYPE_REQUIRED])
        if (errors) {
            setState({
                ...state,
                errors: {
                    ...state.errors,
                    deploy_tag: errors,
                }
            });
        } else {
            var data = {
                tag: state.last_deploy && state.last_deploy.deploy_tag ? state.last_deploy.deploy_tag : "latest",
                deployment_status_check: state.last_deploy && state.last_deploy.deployment_status_check ? true : false
            }
            var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_deploy_request_url);
            PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);
        }

        setState({
            ...state,
            refresh_count: state.refresh_count + 1,
        });
    }
    function successBuildTriggerCallBack(response) {

        setState({
            ...state,
            trigger: "success",
            deploy_tag: "",
            redeploy_status: "Redeployment Triggered Successfully",
            deployment_status_check: false,
        });
        props.refresh()
    }

    function failedBuildTriggerCallBack(error) {
        setState({
            ...state,
            trigger: "failed",
            redeploy_status: "Redeployment Not Triggered",
            error_msg: error
        });
    }
    function validateAndReTriggerDeploy(data) {
        console.log(data, "fdsfasjk")
        var errors = GenericValidator(data.deploy_tag, [VALIDATION_TYPE_REQUIRED])
        var cd_id = data && data.env_cd_detail_id ? data.env_cd_detail_id : null
        if (errors) {
            setState((prev_state) => ({
                ...prev_state,
                errors: {
                    ...prev_state.errors,
                    deploy_tag: errors,
                }
            }));
        } else {
            var data = {
                tag: data && data.deploy_tag ? data.deploy_tag : "",
                deployment_status_check: data && data.deployment_status_check ? true : false,
                init_tag : data.deploy_args_key_value.init_tag ? data.deploy_args_key_value.init_tag : false,
            }
            if (data && cd_id) {
                var endPoint = GenerateURL({ service_id: props.service_id, env_id: props.env_id, cd_id: cd_id }, properties.api.env_single_deploy_request_url);
            } else {
                var endPoint = GenerateURL({ service_id: props.service_id, env_id: props.env_id }, properties.api.env_deploy_request_url);
            }

            PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);
        }
    }

    function getDeployedByUser(history) {
        return (
            <>{history && history.deploy_by_pipeline ?
                // <div className='text-ellipsis-twoline'>
                //     <p>Pipeline: {history.deploy_by_pipeline.pipeline.name}</p>
                //     <p>Triggered by: {history.deploy_by_pipeline.trigger_by}</p> </div>


                <p>{history && history.deploy_by_pipeline.trigger_by}</p>
                : <p className='text-ellipsis-110'>{history && history.deploy_by_user}</p>
            }
            </>
        )

    }

    //a function for disable or enable the btn
    function getClassbyActivityStatus(activity_status, type) {
        if (type == "Revoke") {
            if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
                return "icon-btn-v1 icon-btn-outline-danger"
            else
                return "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"
        } else {
            if (type === "Resume") {
                if ((activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING") || activity_status && activity_status == "SUCCESS")
                    return "btn-sq-icon-primary d-flex align-center mr-5"
                else
                    return "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"
            } else {
                if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
                    return "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"
                else
                    return "btn-sq-icon-primary d-flex align-center mr-5"
            }

        }
    }
    function callFunctionbyStatus(activity_status, id) {
        if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
            onRevoke(id)
    }

    function onResume(id) {
        var url_temp = GenerateURL({ service_id: service_id, env_id: env_id, id: id }, properties.api.deploy_resume);
        var fetchOptions = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        PostData(url_temp, {}, handleSuccessResume, handleFailedResume);

    }


    function handleSuccessResume(respone) {

        setState((new_state) => ({
            ...new_state,
            show_loading_icon: false,
            trigger: "success",
            rebuild_status: "Redeploy Scheduled Successfully"
        }));
    }

    function handleFailedResume(error) {
        setState((new_state) => ({
            ...new_state,
            error: true,
            show_loading_icon: false,
            trigger: "success",
            rebuild_status: error
        }));
    }

    function callResumeFunctionbyStatus(activity_status, id) {
        if ((activity_status != "IN_QUEUE" || activity_status != "RUNNING") || activity_status != "SUCCESS")
            onResume(id)
    }

    // const [open_div, setOpenDiv] = useState(false)

    const removeArrData = () => {
        setState((new_state) => ({
            ...new_state,
            activity_list: [],
            activity_step_details: {
                open_div: new_state.activity_list.length > 0 ? true : false
            },
            // data : {
            //     ...new_state.data,
            //     latest_deployment: deployment_list[0].id ? deployment_list[0].id : null,
            //     latest_deployment_name: deployment_list[0].label ? deployment_list[0].label : null,
            // },
            open_div: false
        }));
        console.log("dksdksjkdj", state);
    }
    function getActivityCurrentInfo(activity_id) {
        var requestInfo = {
            endPoint: GenerateURL({ activity_id: activity_id }, properties.api.get_current_activity_status),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, getActivityCurrentInfoSuccess, getActivityCurrentInfoFailure);


    }

    function getActivityCurrentInfoSuccess(response) {
        if (response && response.results.length > 0) {
            let orderedList = response.results.map((step,index) => ({...step,order:index}));
            setState((new_state) => ({
                ...new_state,
                activity_list: orderedList,
                activity_step_details: {
                    open_div: true
                },
                open_div: true
            }));

        } else {
            setState((new_state) => ({
                ...new_state,
                activity_list: [],
                trigger: "noActivityAvailable",
                redeploy_status: "ActivityList Not Available yet please try again later...",
                activity_step_details: {
                    open_div: false
                }
            }));
        }

    }
    function getActivityCurrentInfoFailure(error, exception) {
        setState((new_state) => ({
            ...new_state,
            error: true
        }));
    }

    var url_deploy = GenerateURL({ service_id: props.service_id, env_id: props.env_id }, properties.api.env_deploy_request_url, true);
    const is_permitted_deploy = useFetchPermissions()?.POST.includes(url_deploy);


    var status = state.history_list.map(item => (item.status));
    function handleClose() {
        setState((prev_state) => ({
            ...prev_state,
            trigger: null
        }));
    }
    const onHandleBuildRefresh = (type) => {
        props.refresh()
        removeArrData()
        getActivityCurrentInfo(state.last_deploy && state.last_deploy?.activity_status ? state.last_deploy?.activity_status?.id ? state.last_deploy?.activity_status?.id : "" : "")
    }
    console.log(state.activity_list, 'deploye_activity_details')

    function getBuildByUserName(history) {
        console.log(history, 'hshhshshs');
        if (history) {

            if (history.deploy_by_pipeline) {
                return history.deploy_by_pipeline.trigger_by
            }

            else
                return history.deploy_by_user

        }
    }

    console.log(getBuildByUserName(state.last_deploy), 'hereh_data_now');

    console.log("State Last Deploy", state.last_deploy);

    function getDeployDurationForInfoMessage(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        console.log("diffInMs", diffInMs)
        return diffInMs;
    }
    function onChangeHandler(e) {
        var key = e.target.name;
        var value = e.target.value;
        console.log(key, value, state.all_last_deploy, "onChangeHandler");
        var find_selected_deployment_data = state.all_last_deploy.find(item => item.env_cd_detail_id == value);
        console.log(find_selected_deployment_data, "onChangeHandler", state.all_last_deploy)

        setState(new_state => ({
            ...new_state,
            last_deploy: find_selected_deployment_data,
            data: {
                ...new_state.data,
                [key]: value
            },
            error: {
                [key]: ""
            }
        }));
    }
    function selectDeployment(deployment) {
        var find_selected_deployment_data = state.all_last_deploy.find(item => item.env_cd_detail_id == deployment);
        let selected_deployment = deployment_list.find(item => item.id == deployment);
        console.log("dkjdskjks", find_selected_deployment_data);
        setState(new_state => ({
            ...new_state,
            last_deploy: find_selected_deployment_data,
            data: {
                ...new_state.data,
                latest_deployment: deployment,
                latest_deployment_name: selected_deployment.label
            },
            error: {
                ...new_state.error,
                latest_deployment: null,
            }
        }));
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickClosePopover = () => {
        setAnchorEl(null);
    };

    const OpenPopover = Boolean(anchorEl);
    const id = OpenPopover ? 'simple-popover' : undefined;


    function getBtnBasedOnType() {
        switch (type) {
            case "Deploy":
                return (
                    <>
                        {state.last_deploy && state.last_deploy.activity_status ?
                            <span>
                                <div className="round-btn-group d-flex align-center justify-center">
                                    <>
                                        <Tooltip title="View Activity Details">

                                            <button className={`btn-sq-icon-primary d-flex align-center mr-5 ${state?.open_div ? "btn-disabled-new" : ""}`}
                                                onClick={() => {
                                                    state?.open_div ? null : getActivityCurrentInfo(state.last_deploy && state.last_deploy?.activity_status ? state.last_deploy?.activity_status?.id ? state.last_deploy?.activity_status?.id : "" : "")
                                                }}  >

                                                <span className='ri-list-check-2'>

                                                </span>
                                            </button>
                                        </Tooltip>
                                    </>
                                    {
                                        is_permitted_deploy ?
                                            <>
                                                <Tooltip title="Redeploy">
                                                    {
                                                        is_env_down ?
                                                            <button

                                                                className={state.last_deploy.activity_status.status ? getClassbyActivityStatus(state.last_deploy.activity_status.status, "Redeploy") : ""}
                                                                onClick={handleClickOpenPopover}
                                                            >
                                                                <span class="ri-upload-2-line vertical-align-super" >

                                                                </span>
                                                            </button>
                                                            :
                                                            <button

                                                                className={state.last_deploy.activity_status.status ? getClassbyActivityStatus(state.last_deploy.activity_status.status, "Redeploy") : "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}
                                                                onClick={
                                                                    state.last_deploy.activity_status.status ?
                                                                        state.last_deploy.activity_status.status != "IN_QUEUE" && state.last_deploy.activity_status.status != "RUNNING" ? () => { validateAndReTriggerDeploy(state.last_deploy) } : () => { } : () => { }}
                                                            >
                                                                <span class="ri-upload-2-line vertical-align-super">

                                                                </span>
                                                            </button>}
                                                </Tooltip>
                                            </>
                                            :
                                            <>
                                                <Tooltip title="Redeploy">
                                                    <button
                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}>
                                                        <span class="ri-upload-2-line vertical-align-super">

                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </>
                                    }

                                    <>

                                        <Link target="_blank" to={{
                                            pathname: `/deployment-analytics`,
                                            search: `application_id=${application_id}&service_id=${service_id}&service_name=${top_summary_data ? top_summary_data.service_name : service_name}&env_id=${env_id}&cd_id=${state.data.latest_deployment_name}&env_name=${env_name}&env_type=${top_summary_data ? top_summary_data.type : "DEPLOY"}`
                                        }}
                                        >
                                            <button className="btn-sq-icon-primary d-flex align-center" >
                                                <span className="ri-airplay-line vertical-align-super"></span>
                                            </button>
                                        </Link>
                                    </>

                                    {
                                        state.last_deploy && state.last_deploy.deploy_by_pipeline ||
                                            state.last_deploy.activity_status.status && state.last_deploy.activity_status.status != "FAILED" ?
                                            <>
                                                <Tooltip title="Resume">
                                                    <button
                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}
                                                    >
                                                        <span class="ri-play-circle-line">

                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </> :
                                            <>
                                                <Tooltip title="Resume">
                                                    <button

                                                        className={state.last_deploy.activity_status && state.last_deploy.activity_status.status ? getClassbyActivityStatus(state.last_deploy.activity_status.status, "Resume") : "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}

                                                        onClick={state.last_deploy.activity_status && state.last_deploy.activity_status.status ? () => callResumeFunctionbyStatus(state.last_deploy.activity_status.status, state.last_deploy.id) : null}
                                                    >
                                                        <span class="ri-play-circle-line">

                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </>
                                    }

                                    {
                                        // =-----add new button here------------
                                        <>
                                            <Tooltip title="Workspace" >
                                                <Link
                                                    to={
                                                        "/application/" +
                                                        application_id +
                                                        "/service/" +
                                                        service_id +
                                                        "/deploy/" +
                                                        state.last_deploy.id +
                                                        "/workspace/?service_name=" +
                                                        service_name +
                                                        "&env_name=" +
                                                        env_name +
                                                        "&deploy_id=" +
                                                        state.last_deploy.env_cd_detail_id + "&global_task_id=" + state.last_deploy.global_task_id + "&env_id=" + env_id + "&action_number=" + workspaceParams.action_number +
                                                        "&action_status=" + workspaceParams.action_status + "&action_by=" + deploy_by_user + "&image=" + workspaceParams.image
                                                    }

                                                    target='_blank'

                                                >
                                                    <button className="btn-sq-icon-primary d-flex align-center mr-5" >
                                                        <span
                                                            class="ri-artboard-2-line"

                                                        >

                                                        </span>
                                                    </button>
                                                </Link>
                                            </Tooltip>
                                        </>
                                    }
                                    {
                                        state.last_deploy.activity_status.status ?

                                            <>
                                                <Tooltip title={state.last_deploy.deploy_by_pipeline ? "This task has been triggered by a pipeline, please revoke the pipeline in order to revoke the task" : "Revoke"}>
                                                    <button
                                                        className={state.last_deploy.activity_status && state.last_deploy.activity_status.status && (!state.last_deploy.deploy_by_pipeline) ? getClassbyActivityStatus(state.last_deploy.activity_status.status, "Revoke") : "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}


                                                        onClick={state.last_deploy.activity_status.status == "IN_QUEUE" || state.last_deploy.activity_status.status == "RUNNING" && (!state.last_deploy.build_by_pipeline) ? () => callFunctionbyStatus(state.last_deploy.activity_status.status, state.last_deploy.id) : () => { }}

                                                    >
                                                        <span class="ri-stop-circle-line" >

                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </> :
                                            <>
                                                <Tooltip title="Revoke Build">
                                                    <button
                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 revoke-hover"}

                                                    >
                                                        <span class="ri-stop-circle-line" style={{ color: "#C11212" }}>

                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </>
                                    }
                                </div>
                            </span> : <span></span>
                        }

                    </>
                );
            default:
                return null;
        }
    }


    function getRightOrderedStep(activityList){
        let rightOrderedList = new Array({length: activityList.length});

        activityList.forEach(step => {
            rightOrderedList[step.order] = step;
        })
        
        return rightOrderedList;

    }

    console.log("dchjbhbwa", state.last_deploy);
    return (
        <>
            {
                state.all_available_deployments && state.all_available_deployments.length > 1 ?
                    <div className="d-flex align-center space-between w-100 mb-20" style={{ background: "white", padding: "10px 16px", borderRadius: "6px", boxShadow: "0px 4px 4px 0px #00000014" }}>
                        <p className="font-15">Select Multiple Deployment</p>
                        {/* <p className="font-11">Select Deployments: </p> */}
                        <div className='d-flex align-center' style={{ width: '49%' }}>
                            <div className='' style={{ width: '100%' }}>
                                {/* <Input
                                    type="select"
                                    style={{ height: "40px", fontWeight: "400" }}
                                    list={state.all_available_deployments}
                                    data={state.data}
                                    error={state.error}
                                    label="Select Deployments"
                                    name="latest_deployment"
                                    onChangeHandler={onChangeHandler}
                                /> */}
                                <SelectWithInsideLabel list={state.all_available_deployments}
                                    onChangeHandler={selectDeployment} label={"Deployment: "}
                                    SelectedDeployment={state.data.latest_deployment}
                                    name="latest_deployment" optionsWidth={460} />
                            </div>
                        </div>
                    </div>
                    : null
            }

            {state.last_deploy && state.last_deploy.activity_status ?
                state.loading ? <LoadingText /> :
                    <div style={{ background: "#F4F4F4", gap: "20px", marginBottom: "60px" }} className='d-flex align-start f-direction-column'>

                        {/* ---------------------Last Deploy Status-------------------- */}
                        {state?.last_deploy?.status &&
                            <div className={classes.allDetails}>
                                <h5 className='progressdatatitle'>Last Deploys Details</h5>
                                <div className={classes.card} style={{ height: state.activity_list.length > 0 ? "282px" : "128px" }}>
                                    {/* --cardtop-- */}
                                    <div className={classes.cardTop}>
                                        <div className='cardTopMainContainer'>
                                            <div className="cardTopLeft d-flex align-center" style={{ gap: "10px" }}>
                                                <span className='cardtitle'>
                                                    <a href={"/logs?global_task_id=" + state.last_deploy.global_task_id + "&tab_id=" + state.last_deploy.id + "&service_name=" + service_name + "&service_env=" + env_name_service + "&service_type=" + service_env_type + "&tab_name=DEPLOY" + "&num=" + state.last_deploy.deploy_number + "&service_id=" + service_id + "&env_id=" + env_id + "&cd_id=" + state?.last_deploy?.env_cd_detail_id} target="_blank">

                                                        <span className="cursor-pointer ">Deploy #{state?.last_deploy?.deploy_number}</span>
                                                    </a>
                                                </span>
                                                <span className={`new-status-chip ${state?.last_deploy?.activity_status?.status === "RUNNING" ? "new-running" : state?.last_deploy?.activity_status?.status === "FAILED" ? "new-failed" : state?.last_deploy?.activity_status?.status === "SUCCESS" ? "new-success" : state?.last_deploy?.activity_status?.status === "IN_QUEUE" ? "new-inqueue" : "new-revoked"}`} >
                                                    {state.last_deploy.activity_status.status}
                                                    {state?.last_deploy?.activity_status?.status === "RUNNING" ? <div>
                                                        <RunningDotsComponent isFromEventSidePannel={true} />
                                                    </div> : null}
                                                </span>
                                                <span className='cardtimer'>
                                                    <span className='ri-time-line'></span>
                                                    <span>
                                                        {state.last_deploy.activity_status && state.last_deploy.activity_status.status != "RUNNING" ? getDuration(new Date(state.last_deploy.activity_status && state.last_deploy.activity_status.start_time), new Date(state.last_deploy.activity_status && state.last_deploy.activity_status.end_time)) == 0 || getDuration(new Date(state.last_deploy.activity_status.start_time && state.last_deploy.activity_status.start_time), new Date(state.last_deploy.activity_status.end_time && state.last_deploy.activity_status.end_time)) > 0 ? "0s" : getDuration(new Date(state.last_deploy.activity_status.start_time && state.last_deploy.activity_status.start_time), new Date(state.last_deploy.activity_status.end_time)) : getDuration(new Date(state.last_deploy.activity_status.start_time), new Date())}
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="cardTopRight d-flex align-center" style={{ gap: "12px" }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                    <div className="cardTopRightuserName d-flex align-center">
                                                        <span className='ri-user-3-line' style={{ fontSize: "16px" }}></span>
                                                        <span>{getDeployedByUser(state.last_deploy)}</span>
                                                    </div>
                                                    <div className="cardTopRightDate">
                                                        {moment(state.last_deploy.created_at).fromNow()}
                                                    </div>

                                                </div>
                                                <div className="cardTopRightBtn" onClick={() => onHandleBuildRefresh(type)}>
                                                    <span className='ri-refresh-line cursor-pointer d-flex align-center' style={{ fontSize: "20px" }}></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cardtopmiddleBar">
                                        </div>
                                    </div>

                                    <div className={classes.activityDetalis} style={{ display: state.activity_list.length > 0 ? "inline" : "none" }}>
                                        {
                                            state.activity_list.length > 0 ?
                                                <ActivityStepDetails
                                                    activityDetail={getRightOrderedStep(state.activity_list)}
                                                    removeArrData={() => { removeArrData() }}
                                                    prev_state={{ "open_div": true }}
                                                    tableGridNew={true}
                                                    colSpan={9}
                                                    loading_step_status={state.loading_step_status}
                                                    task_id={state.last_deploy.global_task_id}
                                                    logUrl={"/logs?global_task_id=" + state.last_deploy.global_task_id +
                                                        "&tab_id=" + state.last_deploy.id +
                                                        "&service_name=" + service_name +
                                                        "&service_env=" + service_env +
                                                        "&service_type=" + service_env_type +
                                                        "&service_id=" + service_id + "&env_id=" + env_id + "&cd_id=" + state?.last_deploy?.env_cd_detail_id
                                                    }
                                                />

                                                : null
                                        }
                                    </div>

                                    <div className={classes.cardBottom}>
                                        <div className="cardBottomLeft">
                                            <div> <span>Artifact :  </span>
                                                <Tooltip title={state?.last_deploy?.deploy_tag} placement='top'>
                                                    <span className='artifact'>{state?.last_deploy?.deploy_tag}</span>
                                                </Tooltip>
                                            </div>
                                            {state.last_deploy.deploy_tag && <CopyToClipboard icon={"ri-file-copy-line"} data={state.last_deploy.deploy_tag} />}
                                        </div>
                                        <div className="cardBottomRight">
                                            {/* <ParameterComponent
                                                buildParameters={state?.last_deploy?.build_metadata}
                                                deployParameters={state?.last_deploy?.deploy_args_key_value}
                                            /> */}
                                            <ParameterComponent
                                                buildParameters={state?.last_deploy?.build_metadata}
                                                deployParameters={{ ...state?.last_deploy?.deploy_args_key_value, "Agent Name": state?.last_deploy?.activity_status?.task_executor }}
                                            />

                                            <span>
                                                {getBtnBasedOnType()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>}



                        {/* ----------------------------------When it is In Queue------------------------------- */}
                        {(state.in_queue_data && state.in_queue_data.length > 0) &&
                            <div className={classes.allDetails}>
                                <h5 className='progressdatatitle'>Queued Deploys Details</h5>
                                {/* mapping through the array------- */}
                                {state.in_queue_data?.reverse().map((item, index) => (
                                    <div className={classes.card} style={{ height: state.activity_list.length > 0 ? "282px" : "128px" }} key={index}>
                                        <div className={classes.cardTop}>
                                            <div className='cardTopMainContainer'>
                                                <div className="cardTopLeft d-flex align-center" style={{ gap: "10px" }}>
                                                    <span className='cardtitle'>
                                                        <a href={"/logs?global_task_id=" + item?.global_task_id + "&tab_id=" + item?.id + "&service_name=" + service_name + "&service_env=" + env_name_service + "&service_type=" + service_env_type + "&tab_name=DEPLOY" + "&num=" + item?.deploy_number + "&service_id=" + service_id + "&env_id=" + env_id + "&cd_id=" + item?.env_cd_detail_id} target="_blank">

                                                            <span className="cursor-pointer ">Deploy #{item?.deploy_number}</span>
                                                        </a>
                                                    </span>
                                                    <span className='new-status-chip new-queue' style={{ background: "#FCF6E1", color: "#FEA111" }}>
                                                        {item?.activity_status?.status}
                                                    </span>
                                                    <span className='cardtimer'>
                                                        <span className='ri-time-line'></span>
                                                        <span>
                                                            {item?.activity_status && item?.activity_status.status != "RUNNING" ? getDuration(new Date(item?.activity_status && item?.activity_status.start_time), new Date(item?.activity_status && item?.activity_status.end_time)) == 0 || getDuration(new Date(item?.activity_status.start_time && item?.activity_status.start_time), new Date(item?.activity_status.end_time && item?.activity_status.end_time)) > 0 ? "0s" : getDuration(new Date(item?.activity_status.start_time && item?.activity_status.start_time), new Date(item?.activity_status.end_time)) : getDuration(new Date(item?.activity_status.start_time), new Date())}
                                                        </span>
                                                    </span>
                                                    <button className='getInfoBtn d-flex align-center'>
                                                        GET INFORMATION
                                                    </button>
                                                </div>
                                                <div className="cardTopRight d-flex align-center" style={{ gap: "12px" }}>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                        <div className="cardTopRightuserName d-flex align-center">
                                                            <span className='ri-user-3-line' style={{ fontSize: "16px" }}></span>
                                                            <span>{getDeployedByUser(item?.last_deploy)}</span>
                                                        </div>
                                                        <div className="cardTopRightDate">
                                                            {moment(item?.created_at).fromNow()}
                                                        </div>
                                                    </div>
                                                    <div className="cardTopRightBtn" onClick={() => onHandleBuildRefresh(type)}>
                                                        <span className='ri-refresh-line cursor-pointer d-flex align-center' style={{ fontSize: "20px" }}></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cardtopmiddleBar">
                                            </div>
                                        </div>
                                        <div className={classes.activityDetalis} style={{ display: state?.activity_list.length > 0 ? "inline" : "none" }}>
                                            {

                                                state.activity_list.length > 0 ?
                                                    <ActivityStepDetails
                                                        activityDetail={item.activity_list}
                                                        removeArrData={() => { removeArrData() }}
                                                        prev_state={{ "open_div": true }}
                                                        tableGridNew={true}
                                                        colSpan={9}
                                                        loading_step_status={item?.loading_step_status}
                                                        task_id={item?.global_task_id}
                                                        logUrl={"/logs?global_task_id=" + item?.global_task_id +
                                                            "&tab_id=" + item?.id +
                                                            "&service_name=" + service_name +
                                                            "&service_env=" + service_env +
                                                            "&service_type=" + service_env_type +
                                                            "&service_id=" + service_id + "&env_id=" + env_id + "&cd_id=" + item?.env_cd_detail_id
                                                        }
                                                    />

                                                    : null
                                            }
                                        </div>
                                        <div className={classes.cardBottom}>
                                            <div className="cardBottomLeft">
                                                <div> <span>Artifact :      </span>
                                                    <Tooltip title={item?.deploy_tag} placement='top'>
                                                        <span className='artifact'>{item?.deploy_tag} </span>
                                                    </Tooltip>
                                                </div>
                                                {item?.deploy_tag && <CopyToClipboard icon={"ri-file-copy-line"} data={item?.deploy_tag} />}
                                            </div>
                                            <div className="cardBottomRight">

                                                {/* <ParameterComponent buildParameters={state.last_deploy.build_args_key_value && state.last_deploy.build_args_key_value ? state.last_deploy.build_args_key_value : null}
                               deployParameters={state.last_deploy[0].deploy_args_key_value && state.last_deploy[0].deploy_args_key_value ? state.last_deploy[0].deploy_args_key_value : null}
                             
                              activity_master_id={state.component_id}
                          /> */}
                                                {/* <ParameterComponent
                                                    buildParameters={state?.last_deploy?.build_metadata}
                                                    deployParameters={state?.last_deploy?.deploy_args_key_value}
                                                /> */}
                                                <ParameterComponent
                                                    buildParameters={item?.build_metadata}
                                                    deployParameters={{ ...item?.deploy_args_key_value, "Agent Name": item?.activity_status?.task_executor }}
                                                />

                                                <span>
                                                    <>
                                                        {item && item?.activity_status ?
                                                            <span>
                                                                <div className="round-btn-group d-flex align-center justify-center">
                                                                    <>
                                                                        <Tooltip title="View Activity Details">

                                                                            <button className="btn-sq-icon-primary d-flex align-center mr-5"
                                                                                onClick={() => {
                                                                                    getActivityCurrentInfo(item && item?.activity_status ? item?.activity_status?.id ? item?.activity_status?.id : "" : "")
                                                                                }}  >

                                                                                <span className='ri-list-check-2'>

                                                                                </span>
                                                                            </button>
                                                                        </Tooltip>
                                                                    </>
                                                                    {
                                                                        is_permitted_deploy ?
                                                                            <>
                                                                                <Tooltip title="Redeploy">
                                                                                    {
                                                                                        is_env_down ?
                                                                                            <button

                                                                                                className={item?.activity_status.status ? getClassbyActivityStatus(item?.activity_status.status, "Redeploy") : ""}
                                                                                                onClick={handleClickOpenPopover}
                                                                                            >
                                                                                                <span class="ri-flashlight-line" >

                                                                                                </span>
                                                                                            </button>
                                                                                            :
                                                                                            <button

                                                                                                className={item?.activity_status.status ? getClassbyActivityStatus(item?.activity_status.status, "Redeploy") : "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}
                                                                                                onClick={
                                                                                                    item?.activity_status.status ?
                                                                                                        item?.activity_status.status != "IN_QUEUE" && item?.activity_status.status != "RUNNING" ? () => { validateAndReTriggerDeploy(item) } : () => { } : () => { }}
                                                                                            >
                                                                                                <span class="ri-flashlight-line">

                                                                                                </span>
                                                                                            </button>}
                                                                                </Tooltip>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Tooltip title="Redeploy">
                                                                                    <button
                                                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}>
                                                                                        <span class="ri-flashlight-line">

                                                                                        </span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </>
                                                                    }

                                                                    <>


                                                                        <Link target="_blank" to={{
                                                                            pathname: `/deployment-analytics`,
                                                                            search: `application_id=${application_id}&service_id=${service_id}&service_name=${top_summary_data ? top_summary_data.service_name : service_name}&env_id=${env_id}&cd_id=${state.data.latest_deployment_name}&env_name=${env_name}&env_type=${top_summary_data ? top_summary_data.type : "DEPLOY"}`
                                                                        }}
                                                                        >
                                                                            <button className="btn-sq-icon-primary d-flex align-center" >
                                                                                <span className="ri-airplay-line vertical-align-super"></span>
                                                                            </button>
                                                                        </Link>
                                                                    </>

                                                                    {
                                                                        item && item?.deploy_by_pipeline ||
                                                                            item?.activity_status.status && item.activity_status.status != "FAILED" ?
                                                                            <>
                                                                                <Tooltip title="Resume">
                                                                                    <button
                                                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}
                                                                                    >
                                                                                        <span class="ri-play-circle-line">

                                                                                        </span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </> :
                                                                            <>
                                                                                <Tooltip title="Resume">
                                                                                    <button

                                                                                        className={item?.activity_status && item?.activity_status.status ? getClassbyActivityStatus(item?.activity_status.status, "Resume") : "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}

                                                                                        onClick={item?.activity_status && item?.activity_status.status ? () => callResumeFunctionbyStatus(item?.activity_status.status, item?.id) : null}
                                                                                    >
                                                                                        <span class="ri-play-circle-line">

                                                                                        </span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </>
                                                                    }

                                                                    {
                                                                        // =-----add new button here------------
                                                                        <>
                                                                            <Tooltip title="Workspace" >
                                                                                <Link
                                                                                    to={
                                                                                        "/application/" +
                                                                                        application_id +
                                                                                        "/service/" +
                                                                                        service_id +
                                                                                        "/deploy/" +
                                                                                        item?.id +
                                                                                        "/workspace/?service_name=" +
                                                                                        service_name +
                                                                                        "&env_name=" +
                                                                                        env_name +
                                                                                        "&deploy_id=" +
                                                                                        item?.env_cd_detail_id + "&global_task_id=" + item?.global_task_id + "&env_id=" + env_id + "&action_number=" + workspaceParams.action_number +
                                                                                        "&action_status=" + workspaceParams.action_status + "&action_by=" + deploy_by_user + "&image=" + workspaceParams.image
                                                                                    }

                                                                                    target='_blank'

                                                                                >
                                                                                    <button className="btn-sq-icon-primary d-flex align-center mr-5" >
                                                                                        <span
                                                                                            class="ri-artboard-2-line"

                                                                                        >

                                                                                        </span>
                                                                                    </button>
                                                                                </Link>
                                                                            </Tooltip>
                                                                        </>
                                                                    }
                                                                    {
                                                                        item?.activity_status.status ?

                                                                            <>
                                                                                <Tooltip title={item?.deploy_by_pipeline ? "This task has been triggered by a pipeline, please revoke the pipeline in order to revoke the task" : "Revoke"}>
                                                                                    <button
                                                                                        className={item?.activity_status && item?.activity_status.status && (!item?.deploy_by_pipeline) ? getClassbyActivityStatus(item?.activity_status.status, "Revoke") : "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}


                                                                                        onClick={item?.activity_status.status == "IN_QUEUE" || item?.activity_status.status == "RUNNING" && (!item?.build_by_pipeline) ? () => callFunctionbyStatus(item?.activity_status.status, item?.id) : () => { }}

                                                                                    >
                                                                                        <span class="ri-stop-circle-line" >

                                                                                        </span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </> :
                                                                            <>
                                                                                <Tooltip title="Revoke Build">
                                                                                    <button
                                                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 revoke-hover"}

                                                                                    >
                                                                                        <span class="ri-stop-circle-line" style={{ color: "#C11212" }}>

                                                                                        </span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </span> : <span></span>
                                                        }

                                                    </>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        }
                        <Popover
                            id={id}
                            open={OpenPopover}
                            anchorEl={anchorEl}
                            onClose={handleClickClosePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div className="popover-content" style={{ padding: '20px', width: '450px', }}>

                                <div className='' style={{ width: '100%', position: 'relative' }}>
                                    <button className="btn btn-transparent btn-icon" style={{ position: "absolute", right: '10px', top: '0px' }} onClick={handleClickClosePopover}><i className='ri-close-line font-18' style={{ color: '#0086ff', }} /></button>
                                    <i className="ri-information-line" style={{ color: '#0086ff', width: '100%' }} className='font-48 mb-20 text-center' />
                                    <p className='font-18 font-weight-bold text-center mb-5' style={{ color: '#0086ff', width: '100%' }}>ENVIRONMENT IS OFFLINE</p>
                                    <p className='font-12 font-weight-400 text-center' style={{ color: '#787878', width: '100%' }}>Please note you cannot trigger deploy as the envrionment is offline now.
                                        Please contact your team administrator to get the environment online.</p>
                                </div>

                            </div>
                        </Popover>
                        <div className='z-index-top'>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(state.trigger === "success" || state.trigger === "failed" || state.trigger === "revokedsuccess" || state?.trigger === "revokedfailed" || state.trigger == "noActivityAvailable")}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                sx={{
                                    zIndex: 1400,
                                    '& .MuiPaper-root': {
                                        backgroundColor: (theme) =>
                                            state.trigger === "success" || state.trigger === "revokedsuccess"
                                                ? theme.palette.success.main
                                                : state.trigger === "failed" || state.trigger === "revokedfailed"
                                                    ? theme.palette.error.main
                                                    : theme.palette.info.main,
                                        width: 'auto !important',
                                        boxShadow: 3,
                                        borderRadius: 1,
                                    },
                                }}
                            >
                                {state.trigger === "success" ? (
                                    <Alert
                                        severity="success"
                                        variant="filled"
                                        onClose={handleClose}
                                        sx={{
                                            width: '100%',
                                            '& .MuiAlert-icon': {
                                                fontSize: '2rem', // Adjust icon size
                                            },
                                            '& .MuiAlert-message': {
                                                fontSize: '12px',
                                            },
                                            '& .MuiAlert-action': {
                                                marginRight: 0, // Ensure close button aligns properly
                                            },
                                        }}
                                    >
                                        {state.rebuild_status ? state.rebuild_status : null}
                                    </Alert>
                                ) : state.trigger === "failed" ? (
                                    <Alert
                                        severity="error"
                                        variant="filled"
                                        onClose={handleClose}
                                        sx={{
                                            width: '100%',
                                            '& .MuiAlert-icon': {
                                                fontSize: '2rem', // Adjust icon size
                                            },
                                            '& .MuiAlert-message': {
                                                fontSize: '12px',
                                            },
                                            '& .MuiAlert-action': {
                                                marginRight: 0, // Ensure close button aligns properly
                                            },
                                        }}
                                    >
                                        {type + " SCHEDULE FAILED"}
                                        <div className="error-div">
                                            {state.rebuild_status ? state.rebuild_status : null}
                                        </div>
                                    </Alert>
                                ) : state.trigger === "revokedsuccess" ? (
                                    <Alert
                                        severity="error"
                                        variant="filled"
                                        onClose={handleClose}
                                        sx={{
                                            width: '100%',
                                            '& .MuiAlert-icon': {
                                                fontSize: '2rem', // Adjust icon size
                                            },
                                            '& .MuiAlert-message': {
                                                fontSize: '12px',
                                            },
                                            '& .MuiAlert-action': {
                                                marginRight: 0, // Ensure close button aligns properly
                                            },
                                        }}
                                    >
                                        {"Revoked Successfully"}
                                    </Alert>
                                ) : state.trigger === "revokedfailed" ? (

                                    <Alert
                                        severity="error"
                                        variant="filled"
                                        onClose={handleClose}
                                        sx={{
                                            width: '100%',
                                            '& .MuiAlert-icon': {
                                                fontSize: '2rem', // Adjust icon size
                                            },
                                            '& .MuiAlert-message': {
                                                fontSize: '12px',
                                            },
                                            '& .MuiAlert-action': {
                                                marginRight: 0, // Ensure close button aligns properly
                                            },
                                        }}
                                    >
                                        {"Revoke Failed"}
                                    </Alert>
                                ) :
                                    state.trigger === "noActivityAvailable" ? (

                                        <Alert
                                            severity="info"
                                            variant="filled"
                                            onClose={handleClose}
                                            sx={{
                                                width: '100%',
                                                '& .MuiAlert-icon': {
                                                    fontSize: '2rem', // Adjust icon size
                                                },
                                                '& .MuiAlert-message': {
                                                    fontSize: '12px',
                                                },
                                                '& .MuiAlert-action': {
                                                    marginRight: 0, // Ensure close button aligns properly
                                                },
                                            }}
                                        >
                                            No Activity Details Available yet
                                        </Alert>
                                    ) :
                                        null}
                            </Snackbar>
                        </div>
                    </div > : state.loading ? <LoadingText /> : <div class="blank-div-text" style={{ width: "100%", textAlign: "center" }}>No Data Found</div>
            }
        </>

    )
}
export default LatestDeployStripNew;


const useStyles = makeStyles((theme) => ({

    allDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "18px",
        alignSelf: "stretch",
        borderRadius: "4px",
        gap: "10px",

        '& .progressdatatitle': {
            color: " #505050",

            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal"
        }

    },
    activityDetalis: {
        height: "150px",
        width: "100%",
        border: "1px solid #E6E6E6",
        padding: "8px",
        borderRadius: "3px",
    },
    card: {
        height: "128px",
        width: "100%",
        display: "flex",
        padding: "16px 16px 20px 20px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "12px",
        alignSelf: "stretch",
        borderRadius: "4px",
        background: "#FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.08)"
    },
    cardTop: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch",

        "&  .cardTopMainContainer": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            "& .cardTopLeft": {
                display: "flex",

                "& .cardtitle": {
                    color: "#0086FF",

                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal"

                },
                "& .cardtimer": {
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    color: "#787878",

                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal"
                },

                "& .getInfoBtn": {
                    height: "28px",
                    width: "146px",
                    borderRadius: "6px",
                    padding: "8px 12px 8px 12px",
                    border: "1px solid #FEA111",
                    color: "#FEA111",
                    background: "#FFFBEB",
                    fontSize: "12px",
                    fontWeight: "600",
                    textTransform: "uppercase",
                    cursor: "pointer",
                    "&:hover": {
                        background: "#FEA111",
                        color: "#FFF"
                    }

                }
            },

            "& .cardTopRight": {



                "& .cardTopRightuserName": {
                    gap: "4px",
                    lineHeight: "14.63px",
                    fontSize: "12px",
                    color: "#787878"
                },
                "& .cardTopRightDate": {
                    lineHeight: "14.63px",
                    fontSize: "12px",
                    color: "#787878"
                },

                "& .cardTopRightBtn": {
                    cursor: "pointer",
                    padding: "1px 6px",
                    borderRadius: " 7px",
                    border: "1px solid 	rgba(128, 128, 128, 0)",
                    "&:hover": {
                        border: "1px solid 	#E6E6E6",
                    }
                }
            }
        },
        "& .cardtopmiddleBar": {
            width: "100%", height: "1px", background: "#E6E6E6"
        }


    },
    cardBottom: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
        "& .cardBottomLeft": {
            display: "flex",
            alignItems: "center",
            gap: "3px",
            lineHeight: "14.63px",
            fontSize: "12px",
            color: "#787878"
        },

        "& .cardBottomRight": {
            display: "flex",
            alignItems: "center",
            gap: "5px",

        },


    },
    bottomBar: {
        padding: "16px",
        width: "100%",
        height: "72px",
        background: "#FFF"
    }



}));