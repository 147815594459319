import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import properties from '../../../../../properties/properties';
import InvokeApi from '../../../../../util/apiInvoker';
import GenerateURL, { GET_Request_Info } from '../../../../../util/APIUrlProvider';
import { Loading } from '../../../../utils/Loading';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Delete from '../../../../../components/genericComponents/Delete';
import { TempBodyRowTempTwo } from './KedaSection';

function ResourceSection(props) {
  const cluster_id = props.cluster_id;
  const cluster_summary_data = props.cluster_summary_data;
  const dr_cluster_data = cluster_summary_data.dr_cluster;
  const [state, setState] = useState({
    loaded: false,
    openDetailView: false
  });

  useEffect(() => {
    fetchResourcesInfo();
  }, [cluster_id]);

  function fetchResourcesInfo() {
    const request_info = GET_Request_Info(GenerateURL({ cluster_id: cluster_id }, properties.api.get_cluster_resources));
    InvokeApi(request_info, onResourceFetchSuccess, onResourceFetchFail);
  }

  function onResourceFetchSuccess(data) {
    setState(new_state => ({
      ...new_state,
      data: data,
      openDetailView: data?.length > 0 ? true : false,
      loaded: true,
      filtered_result: filterResourceData(data)
    }));
  }

  function filterResourceData(data) {
    const result = {
      sonar: null,
      vault: null,
    }

    data.forEach(resource => {
      if (resource.resource.code == "SONAR") {
        result.sonar = resource;
      } else {
        result.vault = resource;
      }
    });

    return result;
  }

  function onResourceFetchFail(error) {
    setState(new_state => ({
      ...new_state,
      loaded: true,
      error: error,
    }));
  }
  console.log("hello", state)
  var url = state.filtered_result ? state.filtered_result.sonar ? state.filtered_result.sonar.url : null : null;

  const toggleDetailView = () => {
    setState(prevState => ({
      ...prevState,
      openDetailView: !prevState.openDetailView
    }))
  }

  return (
    <>
      <div className="header-part" style={ state.openDetailView ? { borderBottom: '1px solid #dedede' } : {borderBottom: 'none'}}>
        <div className="heading-area setup-summary d-flex align-center space-between">
          <div>
            <p className="font-14 font-weight-500 color-primary">Cluster Resources</p>
            <p className="font-12 font-weight-500 color-secondary">Manage external access to the services in your cluster</p>
          </div>
          <div className="right-content" >
          <button className="btn btn-transparent">
              {
                state.openDetailView ?
                <span className='ri-arrow-up-s-line font-24 color-tertiary' style={{fontWeight: "500"}} onClick={toggleDetailView}></span>
                :
                <span className='ri-arrow-down-s-line font-24 color-tertiary' style={{fontWeight: "500"}} onClick={toggleDetailView}></span>
              }
            </button>
          </div>
        </div>
      </div>

      {state.openDetailView && <div className="d-flex align-center" style={{ display: 'grid', gridTemplateColumns: ' 33.3% 33.3% 1fr' }}>


        {
          !state.loaded ?
            <>

              <div className="loading-parent-div">
                <Loading varient="light" />
              </div>
            </>
            :
            <>
              {
                state.filtered_result ? <>
                  {state.filtered_result.sonar ?

                    <div className="sub-box-one-summary" style={{ width: '100%', borderBottom: '1px solid #dedede' }}>
                      <div className="sub-header-setup-summary" style={{ minHeight: '39px' }}>
                        <p>Sonar</p>
                        <div>
                          <Link to={"/cluster/" + cluster_id + "/sonar/" + state.filtered_result.sonar.id + "/edit"}><button className="btn btn-transparent"><i className="ri-edit-line" style={{ fontSize: '18px', color: '#0086ff' }} /></button></Link>
                          <Delete
                            display_data_name={""}
                            data={{ entity_id: state.filtered_result.sonar.id, name: "cluster_resource" }}
                            refresh={fetchResourcesInfo}
                            api_link={GenerateURL({ cluster_id: cluster_id, resource_id: state.filtered_result.sonar.id }, properties.api.get_cluster_resource)}
                          />
                        </div>

                      </div>
                      <TempBodyRowTempTwo2>
                        <div className="icon-box">
                          <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                            <img src="/images/resources_logos/sonar-logo.svg" alt=".." />
                          </div>
                        </div>
                        <div>
                          <div className="d-flex"><span className="text-black">Status:&nbsp;</span>
                            <>{
                              state.filtered_result.sonar.status == "READY" ?
                                <><span className="text-gray">Ready</span><i className="ri-checkbox-circle-fill" style={{ color: '#69e09c' }} /></>
                                :
                                <><span className="text-gray">Not Ready</span> <i className='ri-close-line' style={{ fontSize: '18px', color: '#ff8969' }} /></>
                            } </>
                          </div>
                          <div className="d-flex">
                            <span className="text-black">URL:&nbsp;</span>
                            <a className="text-blue-anchor text-ellipsis" href={state.filtered_result.sonar.url} target="_blank">{state.filtered_result.sonar.url} </a>
                          </div>
                        </div>
                      </TempBodyRowTempTwo2>
                    </div>
                    :
                    <div className="sub-box-one-summary" style={{ width: '100%', borderBottom: '1px solid #dedede' }}>

                      <TempBodyRowTempTwo>
                        <div className="icon-box">
                          <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                            <img src="/images/resources_logos/sonar-logo.svg" alt=".." />
                          </div>
                        </div>
                        <div className="blank-section">

                          <Link to={"/cluster/" + cluster_id + "/sonar/add"} >
                            <button className='btn btn-outline-primary d-flex align-center mr-0'>
                              <span className='ri-settings-4-line font-20'></span>&nbsp;<span>Configure Sonar</span>
                            </button>
                          </Link>
                        </div>
                      </TempBodyRowTempTwo>
                    </div>
                  }

                  {state.filtered_result.vault ?
                    <>
                      <div className="sub-box-one-summary" style={{ width: '100%', borderBottom: '1px solid #dedede' }}>
                        <div className="sub-header-setup-summary" style={{ minHeight: '39px' }}>
                          <p>Vault</p>
                          {state.filtered_result.vault ? <div>

                            <Link to={"/cluster/" + cluster_id + "/vault/" + state.filtered_result.vault.id + "/edit"}><button className="btn btn-transparent"><i className="ri-edit-line" style={{ fontSize: '18px', color: '#0086ff' }} /></button></Link>
                            <Delete
                              display_data_name={""}
                              data={{ entity_id: state.filtered_result.vault.id, name: "cluster_resource" }}
                              refresh={fetchResourcesInfo}
                              api_link={GenerateURL({ cluster_id: cluster_id, resource_id: state.filtered_result.vault.id }, properties.api.get_cluster_resource)}
                            />
                          </div> : null}

                        </div>
                        <TempBodyRowTempTwo2>
                          <div className="icon-box">
                            <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                              <img src="/images/resources_logos/valut.svg" alt=".." />
                            </div>
                          </div>
                          <div>
                            <div className="d-flex"><span className="text-black">Status:&nbsp;</span>
                              <>{
                                state.filtered_result.vault.status == "READY" ?
                                  <><span className="text-gray">Ready</span><i className="ri-checkbox-circle-fill" style={{ color: '#69e09c' }} /></>
                                  :
                                  <><span className="text-gray">Not Ready</span><i className='ri-close-line' style={{ fontSize: '18px', color: '#ff8969' }} /></>
                              } </>
                            </div>
                            <div className="d-flex">
                              <span className="text-black">URL:&nbsp;</span>
                              <a className="text-blue-anchor text-ellipsis" target="_blank" href={state.filtered_result.vault.url}>{state.filtered_result.vault.url}</a>
                            </div>
                          </div>
                        </TempBodyRowTempTwo2>
                      </div>
                      <div className="sub-box-one-summary" style={{ width: '100%', borderBottom: '1px solid #dedede' }}>
                        <div className="sub-header-setup-summary" style={{ minHeight: '39px' }}>
                          <p>Consul</p>
                        </div>
                        <TempBodyRowTempTwo2>
                          <div className="icon-box">
                            <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                              <img src="/images/resources_logos/consul-logo.svg" alt=".." />
                            </div>
                          </div>
                          <div>
                            <div className="d-flex"><span className="text-black">Status:&nbsp;</span>
                              <>{
                                state.filtered_result.vault.status == "READY" ?
                                  <><span className="text-gray">Ready</span><i className="ri-checkbox-circle-fill" style={{ color: '#69e09c' }} /></>
                                  :
                                  <><span className="text-gray">Not Ready</span><i className='ri-close-line' style={{ fontSize: '18px', color: '#ff8969' }} /></>
                              } </>
                            </div>
                            <div className="d-flex">
                              <span className="text-black">URL:&nbsp;</span>
                              <a className="text-blue-anchor text-ellipsis" target="_blank" href={state.filtered_result.vault.key_value.consul_url}>{state.filtered_result.vault.key_value.consul_url} </a>
                            </div>
                          </div>
                        </TempBodyRowTempTwo2>
                      </div>
                    </>
                    :
                    <>
                      <div className="sub-box-one-summary" style={{ width: '100%', borderBottom: '1px solid #dedede' }}>

                        <TempBodyRowTempTwo>
                          <div className="icon-box">
                            <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                              <img src="/images/resources_logos/valut.svg" alt=".." />
                            </div>
                          </div>
                          <div className="blank-section">
                            <Link to={"/cluster/" + cluster_id + "/vault/add"} >
                              <button className='btn btn-outline-primary d-flex align-center mr-0'>
                                <span className='ri-settings-4-line font-20'></span>&nbsp;<span>Configure Vault </span>
                              </button>
                            </Link>
                          </div>
                        </TempBodyRowTempTwo>
                      </div>
                      <div className="sub-box-one-summary" style={{ width: '100%', borderBottom: '1px solid #dedede' }}>

                        <TempBodyRowTempTwo>
                          <div className="icon-box">
                            <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                              <img src="/images/resources_logos/consul-logo.svg" alt=".." />
                            </div>
                          </div>
                          <div className="blank-section">
                            <Link to={"/cluster/" + cluster_id + "/vault/add"} >
                            <button className='btn btn-outline-primary d-flex align-center mr-0'>
                                      <span className='ri-settings-4-line font-20'></span>&nbsp;<span>Configure Consul</span>
                                    </button>
                            </Link>
                          </div>
                        </TempBodyRowTempTwo>
                      </div>
                      {
                        !cluster_summary_data.is_dr_cluster ?
                          dr_cluster_data ?
                            <div className='dr-cluster-box' style={{ borderRight: '1px solid #dedede' }}>
                              <div className="icon-box">
                                <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4', display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  <img src="/images/logos/DR_cluster_logo.png" alt=".." style={{ width: '60px' }} />
                                </div>
                              </div>
                              <div className="dr-cluster-data" style={{ marginLeft: "20px" }}>
                                <p>(DR) Disaster Recovery Cluster</p>
                                <p> Cluster Name: <span>{dr_cluster_data.name}</span> </p>
                                <p>Status: <span>{dr_cluster_data.is_dr_cluster_active == true ? "Active" : "Inactive"} </span>
                                  <Link
                                    to={"/cluster/" + dr_cluster_data.id + "/detail?cluster_id=" + cluster_summary_data.id + "&cluster_name=" + cluster_summary_data.name + "&dr_cluster=" + cluster_summary_data.is_dr_cluster_active}
                                  >
                                    View Settings</Link></p>
                              </div>
                            </div>
                            :
                            <div className="sub-box-one-summary" style={{ width: '100%', borderRight: '1px solid #dedede', }}>

                              <TempBodyRowTempTwo>
                                <div className="icon-box">
                                  <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                                    <img src="/images/logos/Kubernetes_Logo.png" alt=".." style={{ width: '60px' }} />
                                  </div>
                                </div>
                                <div className="blank-section">
                                  <Link to={"/cluster/" + cluster_id + "/dr/cluster/onboard"} >
                                    <button className='btn btn-outline-primary d-flex align-center mr-0'>
                                      <span className='ri-settings-4-line font-20'></span>&nbsp;<span>Configure DR Cluster</span>
                                    </button>
                                  </Link>
                                </div>
                              </TempBodyRowTempTwo>
                            </div>
                          :
                          null
                      }
                    </>
                  }
                </>
                  : null}
            </>

        }
      </div>}

    </>
  );
}
ResourceSection.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default ResourceSection;

const ITEM_HEIGHT = 48;
export function MoreOption(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const application_id = props.application_id;
  return (
    <div>
      <button className="btn btn-transparent"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        >
          <i className="ri-more-2-fill"  style={{ color: '#0096db', fontSize: '2rem' }} />
      </button>
      
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
          },
        }}
      >
        <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

          <Link to="/namespaces">
            <span className="hover-content-link"><i className='ri-add-line' style={{ color: '#9e9e9e', fontSize: '16px' }} />&nbsp;Add Resource</span>
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
MoreOption.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const TempBodyRow = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
  gap: '10px',
  padding: '30px 10px',
  borderBottom: '1px solid #dedede',
  alignItems: 'flex-start',
  overflow: 'hidden',
  backgroundColor: '#fcfcfc',
  display: 'grid',
  gridTemplateColumns: '20% 40% 1fr',
  justifyContent: 'space-between',
  '& .d-flex': {
    overflow: 'hidden',
  },
  '&:last-child': {
    border: 'none'
  },
  '& .bullet': {
    position: 'relative',
    top: '3px',
    '&.purple': {
      backgroundColor: '#245dff!important'
    }
  },
  '& .createdat': {
    fontSize: '10px',
    color: '#878787'
  },
  '& .fre-text': {
    fontSize: '11px',
    color: '#000',
    '& .uses-text': {
      fontSize: '9px',
      color: '#878787'
    }
  },
  '& .status-font': {
    color: '#fff',
    width: '40%',
    '& .flaticon-circle-with-check-symbol': {
      '&:before': {
        fontSize: '14px!important'
      }
    }
  },
  '& div': {
    overflow: 'hidden',
    color: '#9e9e9e'
  },
  '& .url': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})

const TempBodyRowTempTwo2 = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
  gap: '10px',
  padding: '20px 10px',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: '#fff',
  display: 'grid',
  gridTemplateColumns: '30% 1fr',
  justifyContent: 'space-between',
  '& .public-icon-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .text-gray': {
    color: '#9e9e9e'
  }
})