import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import { ErrorComponent } from '../../utils/Error';

import { Input } from '../../../components/genericComponents/Input';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails'
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';






const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddJobToJobTempalte = ({ propsData, onClose, updateJobTemplate, queueList, version }) => {

    const [state, setState] = useState({

        data: {
            is_dependent: false,
        },
        error: {},
        validations: {
            job_code: [VALIDATION_TYPE_REQUIRED],
            job_name: [VALIDATION_TYPE_REQUIRED],
        },
        post_error: null,
        supportedServiceList: []
    })

    useEffect(() => {
        fetchSupporetedEntityList()

        if (propsData.editData) {
            setState(prevState => ({
                ...prevState,
                data: { ...propsData.editData.data }
            }))
        }
    }, [propsData])

    const commonFunctions = getCommonFunctions(state, setState, {})

    const handleClose = () => {
        setState({
            data: {
                is_dependent: false,
                job_code: "",
                job_name: "",
            },
            error: {},
            validations: {
                job_code: [VALIDATION_TYPE_REQUIRED],
                job_name: [VALIDATION_TYPE_REQUIRED],
            },
            post_error: null,
            supportedServiceList: []
        })
        onClose()
    }

    const validateOnSaveChanges = () => {

        // new code

        let postData = state.data;
        console.log(postData, "dfgvbhfdhf")
        // if (postData.queue_name) {
        //     if (postData.queue_name === "") {
        //         postData.queue_name = null
        //     }
        // } else {
        //     postData.queue_name = null
        // }


        let result = ValidateDataSet(postData, state.validations)

        if (result.valid) {
            let jobTemplateData = propsData.jobTemplateData
            let jobTemplate = jobTemplateData.job_template
            let jobs = jobTemplate.jobs
            if (version?.version == 'v2') {
                postData['is_dynamic'] = true
            }
            console.log(postData, 'pst_001plmn')
            if (propsData.editData) {
                jobs[propsData.editData.editJobOrder] = { ...postData }
            }
            else {
                jobs.push({ ...postData, steps: [] })

            }
            jobTemplate.jobs = jobs
            jobTemplateData['job_template'] = jobTemplate
            updateJobTemplate(jobTemplateData)
        }
        else {
            setState(new_state => ({
                ...new_state,
                error: result.error
            }))
        }
    }

    // function onQueueNameChangeHandler(e){
    //     const value = e.target.value
    //     const fieldName = e.target.name

    //     var selectedQueueName;

    //     queueList.forEach((item) => {
    //         if(Number(item.id) == Number(value)){
    //             selectedQueueName = item.label;
    //         }
    //     })

    //     setState((new_state) => ({
    //         ...new_state,
    //         data : {
    //             ...new_state.data,
    //             queue_name : selectedQueueName
    //         }
    //     }))
    // }

    console.log(state, 'job_state_in_for')

    const fetchSupporetedEntityList = () => {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_supporeted_job_entity),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (response) => {
            console.log(response, 'res_p02233232')
            setState(prevState => ({
                ...prevState,
                supportedServiceList: response
            }))
        }, (error) => {
            console.log(error, 'err_02232323')
        });
    }

    // console.log(propsData?.jobTemplateData?.version == "v2" )
    console.log(version, 'pp_Ooppp')
    console.log(state.data, 'bb_00p')

    const handleDependentChange = (e) => {
        console.log(e.target.value, 'set_00p')
        const value = e?.target?.value == 'true' ? true : false
        console.log(value, 'ex_00p')
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                is_dependent: value,
                dependent_entity: !value ? null : prevState.data?.dependent_entity
            }
        }))
    }
    console.log(propsData, 'pp_00sd')

    const handleJobNameOutOfFocus = () => {
        console.log('called_fun')
        if (!state?.data?.job_code || state?.data?.job_code == '') {
            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    job_code: prevState.data.job_name?.replace(" ","_")
                    
                },
                error: {
                    ...prevState.error,
                    job_code: null
                }
            }))
        }
    }
    const handleOnBlurEvent = (event) => {
        console.log('got_blue', event.target)
        if (event.target) {
            handleJobNameOutOfFocus();
        }
    }


    return (
        <Dialog
            className="add-job-to-template"
            open={propsData.open}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
            TransitionComponent={Transition}
            keepMounted
        >
            <div /* style={{ height: "400px" }} */>

                {state.post_error ?
                    <ErrorComponent variant="error-box" error={state.post_error} />
                    :
                    <div>
                        <div className='pl-15 pr-15 align-center space-between' style={{ height: '50px', display: 'flex', background: '#3e7bfa', marginBottom: '30px' }}>

                            <span className='font-15' style={{ color: '#fff' }}>Add Job</span>

                            <button className="btn btn-transparent" onClick={handleClose} aria-label="close" style={{ color: '#fff' }}>
                                <span className='ri-close-line font-24'></span>
                            </button>
                        </div>
                        <Grid style={{ paddingLeft: '15px', paddingRight: '15px' }} container>
                            {
                                <Grid item lg={12}>
                                    <Input
                                        type="text"
                                        name="job_name"
                                        mandatorySign
                                        onBlurFun={handleOnBlurEvent}
                                        placeholder=""
                                        label="Job Name"
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error}
                                    />
                                </Grid>
                            }
                            {
                                <Grid item lg={12}>
                                    <Input
                                        type="text"
                                        name="job_code"
                                        mandatorySign
                                        placeholder=""
                                        label="Job Code"
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error}
                                    />
                                </Grid>
                            }
                            <Grid item lg={12}>
                                <Input
                                    type="select"
                                    name="is_dependent"
                                    list={[{ id: true, label: 'Yes' }, { id: false, label: 'No' }]}
                                    mandatorySign
                                    placeholder=""
                                    label="Is this job entity specific?"
                                    onChangeHandler={handleDependentChange}
                                    data={state.data}
                                    error={state.error}
                                />
                            </Grid>
                            {
                                (state.data?.is_dependent == 'true' || state.data?.is_dependent == true) &&
                                <Grid item lg={12}>
                                    <Input
                                        type="select"
                                        name="dependent_entity"
                                        list={state.supportedServiceList?.map(service => {
                                            return { id: service.code, label: service.code }
                                        })}
                                        mandatorySign
                                        placeholder=""
                                        label="Dependent Entities"
                                        onChangeHandler={commonFunctions.onChangeHandler}
                                        data={state.data}
                                        error={state.error}
                                    />
                                </Grid>

                            }
                            {/* {
                                propsData?.jobTemplateData?.version == "v2" &&
                                <Grid item lg={12}>
                                    <Input
                                        type="text"
                                        name="job_type"
                                        mandatorySign
                                        placeholder=""
                                        label="Job Type"
                                        onChangeHandler={""}
                                        data={state.data}
                                        error={state.error}
                                    />
                                </Grid>
                            } */}
                            {/* <Grid item lg={12}>
                                <Input
                                    type="select"
                                    name="queue_name"
                                    list={queueList}
                                    // mandatorySign
                                    placeholder=""
                                    label="Queue Name"
                                    onChangeHandler={onQueueNameChangeHandler}
                                    data={state.data}
                                    error={state.error}
                                />
                            </Grid> */}
                        </Grid>

                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '15px', paddingRight: '15px', marginTop: '20px', marginBottom: "20px" }}>

                            <button onClick={handleClose}

                                className="btn btn-outline-grey">Close</button>

                            <button className="btn btn-submit" onClick={validateOnSaveChanges} style={{ display: 'flex' }}>Save &nbsp;</button>
                        </div>

                    </div>
                }
            </div>
        </Dialog>
    );
}


AddJobToJobTempalte.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default AddJobToJobTempalte



