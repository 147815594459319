import React, { useRef, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import '../../../../../assets/style.css';
import { Input } from '../../../../../components/genericComponents/Input';
import Grid from '@mui/material/Grid';;
import PageHeader from '../../Components/PageHeader';
import KeyValue from "../../../../../components/genericComponents/inputs/KeyValues";
import MaxWidthDialog from "../../../../settings/credentials/components/FormDialogue";
import InvokeApi from "../../../../../util/apiInvoker";
import properties from "../../../../../properties/properties";
import GenerateURL from "../../../../../util/APIUrlProvider";
// import Editor from "@monaco-editor/react";




const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginTop: '3rem'
  },

}));

const BasicInfo = (props) => {
  const classes = useStyles();
  const data = props.data;
  const error = props.error;
  const update_data_callback = props.update_data_callback;
  const cluster_list = props.cluster_list;
  const namespace_list = props.namespace_list;
  const build_strategy = props.build_strategy;
  const env = props.env;
  const update_cluster_id = props.update_cluster_id;
  const [value, setValue] = useState(`// Copy Paste Environment Variables here in the format given below 
env_name =default 
branch_name = master
dockerfile_path =./ Dockerfile
expose_url = emp.buildpiper.in
expose_path = /`)

  let data_editor = value;

  console.log("error=", error);
  console.log("data=", data);

  const [isEditorReady, setIsEditorReady] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [state, setState] = useState({
    editor_enabled: false, credentials: []
  })
  const valueGetter = useRef();
  function handleEditorDidMount(_valueGetter) {
    setIsEditorReady(true);
    valueGetter.current = _valueGetter;
  }

  // function handleShowValue() {
  //   // 
  //   data_editor = valueGetter.current()
  //   let s = data_editor.trim();
  //   if (s.slice(0, 2) == "//" || s.slice(0, 2) == "") {
  //     s = s.substring(s.indexOf('\n') + 1);
  //   }
  //   let data_array = []
  //   s.split(/\r\n|\r|\n/).forEach(hi => (
  //     data_array.push({
  //       key: hi.substring(0, hi.indexOf('=')).trim(),
  //       value: hi.substring(hi.indexOf('=') + 1).trim(),
  //       env_key: hi.substring(0, hi.indexOf('=')).trim(),
  //       env_value: hi.substring(hi.indexOf('=') + 1).trim()
  //     })

  //   ));
  //   console.log("hello1212", data_array)
  //   setDataArray(data_array)
  // }
  function handleShowValue(e) {
    data_editor = valueGetter.current()
    let s = data_editor.trim();
    let data_array = []
    s.split(/\r\n|\r|\n/).forEach(hi => (
      hi.length > 0 ?
        data_array.push({
          key: hi.substring(0, hi.indexOf('=')).trim(),
          value: hi.substring(hi.indexOf('=') + 1).trim(),
          env_key: hi.substring(0, hi.indexOf('=')).trim(),
          env_value: hi.substring(hi.indexOf('=') + 1).trim()
        }) : null

    ));
    console.log("hello1212", data_array)
   // update_data_callback("env_build_variable", data_array)
    setDataArray(data_array)
    
    //   update_data_callback(props.field_name, data_array);
    if (e == "e") {
      console.log("hello989898989898989898", data_array)
      return data_array
    }
  }


  useEffect(() => {
    setDataArray(getBuildKV(data.env_build_variable));
    tabDatatoEditor(getBuildKV(data.env_build_variable));


  }, [])

  function tabDatatoEditor(data) {
    let value_temp = ``;
    data.forEach(d => (
      value_temp += d.key + "=" + d.value + "\n"

    ))
    setValue(value_temp);
    console.log("hello data", value_temp)
  }

  console.log("hello0900", dataArray);
  const onChangeHandler = (e) => {
    switch (e.target.name) {
      case "cluster_id":
        update_cluster_id(Number(e.target.value));
        update_data_callback(e.target.name, Number(e.target.value));
        update_data_callback("namespace_id", "");
        break;
      case "env_build_variable_enabled":
        update_data_callback(e.target.name, !data[e.target.name]);
        break;
      case "ci_enabled":
        update_data_callback(e.target.name, !data.ci_enabled);
        break;
      case "namespace_id":
        update_data_callback(e.target.name, Number(e.target.value));
        break;
      default:
        update_data_callback(e.target.name, e.target.value);
    }
  }

  function updateBuildKV(data) {

    if (data) {
      data.forEach(element => {
        element["env_key"] = element.key;
        element["env_value"] = element.value;
      });
    }
    tabDatatoEditor(data);
    update_data_callback("env_build_variable", data);
  }



  function getBuildKV(data) {
    if (data) {
      data.forEach(element => {
        element["key"] = element.env_key;
        element["value"] = element.env_value;
      });
    }
    return data;
  }
  // const[state,setState]= useState({
  //   
  // });
  function fetchCredentials() {

    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.credential_all),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    requestInfo.endPoint = requestInfo.endPoint + "?credential_type=ACCESS_TOKEN";

    InvokeApi(requestInfo, CredentialsFetchSuccess, CredentialsFetchFailure);

  }
  useEffect(() => {
    fetchCredentials();
  }, []);


  function getCredentialsList(credentials) {
    const new_credential_list = [];
    if (credentials) {
      credentials.forEach(credential => {
        new_credential_list.push({
          id: credential.id,
          label: credential.name
        })
      });
    }

    return new_credential_list;
  }
  function CredentialsFetchSuccess(data) {
    console.log("data coming api ", data)
    var credentials = getCredentialsList(data.results);
    setState(new_state => ({
      ...new_state,
      credentials: credentials,
      credentials_status: "SUCCESS",
    }));
  }
  function CredentialsFetchFailure(error, exception) {
    console.log("CredentialsFetchFailure", error);

  }


  function switchStrategy() {
    if (state.editor_enabled) {
      handleShowValue();
    }
    setState(new_state => ({
      ...new_state,
      editor_enabled: !new_state.editor_enabled,
    }));
  }



  return (
    <>
      <PageHeader title="Basic Information" service_name={props.service_name} env_name={props.env_name} sub_env_name={props.sub_env_name} />

      <div className={classes.formDiv}>
        <Grid container justify="center">
          <div className='fromClass'>
            <Grid lg={12}>
              <Grid container justify="center" className="card-add-service">
                <Grid lg={12} direction="row" className="card-add-service-head">
                  <div className="formTag">
                    <h4 className='mainHeading'>containerization Details</h4>
                  </div>
                </Grid>
                <Grid container justify="center" className="card-add-service-body">
                  <Grid lg={6} direction="row">
                    <div className='section'>
                      <Input
                        type="select"
                        mandatorySign
                        name="cluster_id"
                        label="Select Cluster"
                        list={cluster_list}
                        onChangeHandler={onChangeHandler}
                        data={data}
                        error={error} />
                    </div>
                  </Grid>
                  <Grid lg={6} direction="row">
                    <div className='section'>
                      <Input
                        type="select"
                        name="namespace_id"
                        mandatorySign
                        list={namespace_list}
                        label="Select Namespace"
                        subHeading="(Chosen Namespace should have minimum following resources- requested and limit memory 3-5Gi and requested and limit  CPU 3-5Core)"
                        onChangeHandler={onChangeHandler}
                        data={data}
                        error={error} />
                    </div>
                  </Grid>
                  <Grid lg={6} direction="row">
                    <div className='section'>
                      {
                        build_strategy == "ONCE" && env != "dev" ?
                          null :
                          <Input
                            type="text"
                            placeholder="master"
                            name="branch_name"
                            label="Branch"
                            mandatorySign
                            error={error}
                            onChangeHandler={onChangeHandler}
                            data={data} />
                      }
                    </div>
                  </Grid>
                  <Grid lg={6} direction="row">
                    <div className='section'>
                      {
                        build_strategy == "ONCE" && env != "dev" ?
                          null :
                          <Input
                            type="text"
                            placeholder="./Dockerfile"
                            name="dockerfile_path"
                            mandatorySign
                            label="Docker File Path"
                            error={error}
                            onChangeHandler={onChangeHandler}
                            data={data} />

                      }
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid lg={12} direction="row" style={{ marginTop: '20px' }} >
                <div className='card'>
                  <div className='heading'>
                    <Input
                      type="switch"
                      name="env_build_variable_enabled"
                      label="Do you have a build environment variable?"
                      onChangeHandler={onChangeHandler}
                      data={data}
                      error={error} />
                  </div>
                  
                    {
                      data.env_build_variable_enabled ? <>
                        <div className="SubHeader d-flex align-center" style={{ backgroundColor: "#f8f8f8", padding: "5px", borderBottom: "1px solid #dedede", justifyContent: "space-between " }} >
                          <p className="sub-heading-text">{
                            state.editor_enabled ? "Editor can be used to manage the properties values easily. Switch to field view to easily manage the entered data." : "Use Field view to manage small number of variables. Use Editor view in case you want to copy paste or manage large number of variables"}</p>
                          <div className="sub-heading-text" onKeyDown={()=>{}} onClick={switchStrategy} role='button' tabIndex={0}><button className="btn-save btn-save-primary btn-save-lg" style={{ cursor: 'pointer' }}>{
                            state.editor_enabled ? " Switch to Field View" : " Switch to Editor View"}</button></div>
                        </div>
                       {/* { state.editor_enabled ?<div className="sub-heading-text  d-flex align-center"  style={{ backgroundColor: "#f8f8f8", padding: "5px", borderBottom: "1px solid #dedede", justifyContent: "space-between " }} onClick={switchStrategy} >
                         <p className="sub-heading-text">You need to save the editor data manually by tapping save editor data button.(Format for writing is : key=value)</p><button className="btn-save btn-save-primary" style={{ cursor: 'pointer' }}>
                          Save Editor Data  </button></div>
                        :null} */}
                        {

                          !state.editor_enabled ?
                            data.env_build_variable_enabled ?
                              <KeyValue data={dataArray} callback={updateBuildKV} />
                              : null
                            :
                            data.env_build_variable_enabled ?
                              <>
                                {/* <button onClick={handleShowValue} disabled={!isEditorReady}>
                              Update Data
                    </button> */}

                                {/* <Editor
                                  height="200px"
                                  language="javascript"
                                  value={value}
                                  editorDidMount={handleEditorDidMount}
                                /> */}
                              </> : null
                        }
                      </> : null
                    }

                  
                  {
                    // {getBuildKV(data.env_build_variable)}
                    // {data.env_build_variable.length > 0 ? getBuildKV(data.env_build_variable) : dataArray} data_e ditor={dataArray}
                  }
                  {/* {
                    data.env_build_variable_enabled ?
                      <KeyValue data={dataArray} callback={updateBuildKV} />
                      : null
                  }
                  {data.env_build_variable_enabled ?
                    <>
                      <button onClick={handleShowValue} disabled={!isEditorReady}>
                        Update Data
                    </button>

                      <Editor
                        height="30vh"
                        language="javascript"
                        value={value}
                        editorDidMount={handleEditorDidMount}
                      />
                    </> : null
                  }
 */}

                </div>
              </Grid>


              <Grid lg={12} direction="row" style={{ padding: '10px 0px', marginBottom: '10px 0px' }}>
                <div className='card'>
                  <div className='heading'>
                    <Input
                      type="switch"
                      name="ci_enabled"
                      label="Want CI in your service?"
                      onChangeHandler={onChangeHandler}
                      data={data}
                      error={error} />
                  </div>

                  {
                    data.ci_enabled ?
                      <div className='card-body'>
                        <Grid container>
                          <Grid lg={6} direction="row">
                            <div className='section'>
                              <Input
                                type="text"
                                name="sonar_project_key"
                                label="Sonar Project Key"
                                onChangeHandler={onChangeHandler}
                                data={data}
                                error={error} />
                            </div>
                          </Grid>
                          <Grid lg={6} direction="row">
                            <div className='section' style={{ display: 'grid', gridTemplateColumns: '70% auto', gap: '1%', alignItems: 'center' }}>

                              <Input
                                type="select"
                                name="sonar_credential_id"
                                label="Sonar Token"
                                list={state.credentials}
                                onChangeHandler={onChangeHandler}
                                data={data}
                                error={error} />
                              <MaxWidthDialog isSonar={true} refresh={fetchCredentials} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      : null
                  }

                </div>
              </Grid>


            </Grid>
          </div>
        </Grid>
      </div>

    </>
  );
};


BasicInfo.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};


export default BasicInfo;
