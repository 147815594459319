import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';

const CiEnvVarOverview = (props) => {
    const env_details = props.env_details
    const env_var = props.env_var;
    const env_ci_build_variable = env_var.env_ci_build_variable;
    const application_id = props.application_id;
    const service_id = props.service_id;
    const component_env_id = props.component_env_id;
    const ci_id = props.ci_id ? props.ci_id : null;
    const secondary_build = props.secondary_build;
    const parent_ci_data = props.parent_ci_data;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: ci_id }, properties.api.edit_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);
    const [value, SetValue] = useState({ showValue: true })
    const handleToggle = () => {
        setState({
            ...state,
            showValue: !state.showValue,
        })
    }
    const [state, setState] = useState({
        selectedValue: 1,
    });

    return (
        
            <div className="section-service-overview">
                <div className="overview-single-card">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Build Env Variables
                        </p>
                        {is_permitted ?
                            <Link
                            to={{
                              pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/ci/${parent_ci_data?.id}/edit`,
                              search: `?selectedTabOrder=2&selected_ci_id=${env_details.id}&secondary_build=${secondary_build}&parent_ci_id=${parent_ci_data?.id}`,
                            }}
                            state={{ ...props.extraProps }}
                          >
                            <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                          </Link>
                          
                            : <Tooltip title="You are not allowed to perform this action" >
                                <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                            </Tooltip>}
                    </div>
                    <p className="overview-card-value-text" style={{ paddingLeft: "10px" }}>
                        Sonar Project Key:&nbsp;

                        {
                            env_var.sonar_project_key ?
                                <span className="overview-card-value-text">
                                    {env_var.sonar_project_key}
                                </span> : <span className="no-data__msg ">NA</span>
                        }
                    </p>
                    <div className="overview-card-value-env-var" style={{ paddingTop: '0px', paddingBottom: '0px', height: '120px' }}>
                        <div className="heading-key-value d-grid space-between align-center" style={{ gridTemplateColumns: '1fr 1fr 20px' }}>
                            <p>Key</p>
                            <p>Value</p>
                            
                                {env_ci_build_variable ?
                                    <span className="ml-auto d-inline-block" onKeyDown={()=>{}} onClick={handleToggle} role='button' tabIndex={0}>{state.showValue ?
                                        <i className="ri-eye-line" style={{ color: '#ccc', fontSize: '20px' }} /> :
                                        < i className="ri-eye-off-line" style={{ color: '#ccc', fontSize: '20px' }} />}
                                    </span>
                                    : null}
                            
                        </div>
                        {state.selectedValue == 1 ?
                            <div className="env-variable-box">
                                {env_var.env_ci_build_variable && env_var.env_ci_build_variable[0] ?
                                    env_var.env_ci_build_variable.map(item => (
                                        <div className="data-key-value-pair">
                                            <p>{item.env_key}</p>
                                            {item.env_value ?
                                                
                                                    <p className="overview-card-value-text">
                                                        <Tooltip placement="bottom" title={state.showValue ? item.env_value : ""}>
                                                            <span className={!state.showValue ? "text-security" : null}> {item.env_value}</span>
                                                        </Tooltip>
                                                    </p>
                                                
                                                : null}
                                        </div>
                                    ))
                                    : <p className="no-data__msg-env-var" >No Data Available</p>
                                }
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        
    )
}

CiEnvVarOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default CiEnvVarOverview;