import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { Loading } from '../../../utils/Loading';
import GroupComponentJira from './GroupComponentJira';


const RunParamsCustomFields = ({ issueKey, prevState, application_id, ...props }) => {
    console.log('custom filed form-->', prevState, props, issueKey);
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const custom_fields_key_value = props.custom_fields_key_value;
    const add_custom_fields = props.add_custom_fields;
    const jira_data_types = props.jira_data_types;
    const showAdditionalField = props.showAdditionalField;
    const allStagesAdditionalFileds = props.allStagesAdditionalFileds ? props.allStagesAdditionalFileds : [];
    const flipAndOpenAdditionalFiledsForm = props.flipAndOpenAdditionalFiledsForm ? props.flipAndOpenAdditionalFiledsForm : () => { }
    const all_templates = props.all_templates;
    const handleClose = props.handleClose;
    const variant = "";
    const [inputList, setInputList] = useState([

    ]);


    console.log(application_id, 'all_stsdsdsd')

    useEffect(() => {
        let customFiledsList = []
        allStagesAdditionalFileds.map(item => {
            if (item['issue_key'] == issueKey && item.additional_field_key_value && item.additional_field_key_value.length > 0) {
                item.additional_field_key_value.map(jira_item => {
                    if (jira_item["over-ridable"]) {
                        customFiledsList.push({ ...jira_item, "issue_key": item.issue_key })
                    }
                })
            }
        })
        setInputList(customFiledsList)

        if (prevState) {  //setting edit
            setState((oldState) => ({
                ...oldState,
                data: {
                    ...prevState
                }
            }))
        }
        else {
            setting_default_answer(customFiledsList);
        }
        fetchJobTemplatesList()
    }, [allStagesAdditionalFileds, prevState])


    function getListForTypeGroup(jobTemplate) {

        console.log('called_ksdksdsd', jobTemplate)

        setState(prevState => ({
            ...prevState,
            additionalGroupFieldsLoading: true
        }))
        var requestInfo = {
            endPoint: GenerateURL({ job_template_id: jobTemplate?.id }, properties.api.get_jira_additional_group_list),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (fetchedData) => {
            console.log(fetchedData, 'fdfccc')
            setState(prevState => ({
                ...prevState,
                additionalGroupFields: fetchedData?.detail.map(item => { return { id: item, label: item } }),
                additionalGroupFieldsLoading: false
            }))
        }, (error) => {
            setState(prevState => ({
                ...prevState,
                additionalGroupFieldsLoading: false
            }))
        })

    }

    function fetchJobTemplatesList() {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application_id }, properties.api.add_job_template),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchJobTemplatesListSuccess, fetchJobTemplatesListFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function fetchJobTemplatesListSuccess(response) {
        var find_default_job_template = response.results.find(item => item.default == true);

        setState(new_state => ({
            ...new_state,
            default_job_template: find_default_job_template,
            loading_data: false
        }));
        getListForTypeGroup(find_default_job_template)

    }
    function fetchJobTemplatesListFailure(error) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
            error: error_msg,
            loading_data: false
        }));
    }

    console.log(inputList, "input_listinput_list")
    const [state, setState] = useState({
        open: false,
        fullWidth: true,
        maxWidth: 'md',
        data: {},
        error: {},
        jira_additional_fields: {},

    })
    const commonFunctions = getCommonFunctions(state, setState, inherits);


    const setting_default_answer = (inputList) => {
        var duplicate_questionnaires = inputList;
        var question_arr = {};
        var validations_arr = {}
        if (duplicate_questionnaires && duplicate_questionnaires.length > 0) {
            duplicate_questionnaires.forEach(item => {
                console.log(item, 'itemddsdsd0')
                question_arr[item.id] = item.data_type === "checkbox" && item.value ? item.value.map(sub_item => { return { id: sub_item, label: sub_item } }) : item.value
                if (item.mandatory)
                    validations_arr[item.id] = [VALIDATION_TYPE_REQUIRED]
            })
            setState(new_state => ({
                ...new_state,
                data: {
                    ...question_arr,
                    ...new_state.data
                },
                validations: {
                    ...validations_arr,
                    ...new_state.validations
                }
            }));

        }
    }
    // useEffect(() => {
    //   console.log(inputList,'input_list_called_sddssd');
    //   if(inputList?.length>0){

    //   }

    // }, [inputList])


    function pushDataToArray() {
        var result = ValidateDataSet(state.data, state.validations);
        if (result.valid) {
            console.log(state.data, inputList, "Fsfsfsfsaffas__")
            var final_data = {
            };
            for (var key in state.data) {
                if (state.data.hasOwnProperty(key)) {
                    // Find the corresponding object in data_two based on key
                    // var correspondingObject = inputList.find(obj => obj.id.toString() === key);

                    // Add the object to final_data under the specified issue_key
                    // if (correspondingObject) {
                    //     var issueKey = correspondingObject.issue_key;
                    //     // if (!final_data[issueKey]) {
                    //     //     final_data[issueKey] = {};
                    //     // }
                    //     final_data[issueKey][key] = state.data[key];
                    // }
                    if (Array.isArray(state.data[key]))// for checkbox type only
                    {
                        const updateData = state.data[key]?.map(value => {
                            return value.label
                        })
                        final_data[key] = updateData
                    }
                    else
                        final_data[key] = state.data[key];
                }
            }
            props.postFinalData(final_data, issueKey)
            props.flipCloseAndRetainState();

        } else {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...result.error
                }
            }));
            console.log(state.data, result.error, "data_failed_to_post")
        }
    }

    function onChangeHandlerForKeyValue(key, value) {
        commonFunctions.updateDataKeyVal(key, value);
    }
    const onKeyValueChangeHandler = (k, v) => {
        setState({
            ...state, data: { ...state.data, [k]: v },
            error: { ...state.error, [k]: null }
        });
    }

    console.log(state, prevState, 'inside_form')

    console.log(inputList, 'indggg_ss', state.data)
    return (
        <>{
            issueKey &&
            <div className='additional_filed_cs' style={{ padding: '20px', backgroundColor: '#ededed', minHeight: 'calc(100% - 120px)', borderRadius: '5px', display: 'block' }}>
                <div style={{ backgroundColor: '#fff', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                    <div className={`d-flex align-center border-bottom space-between `} style={{ height: '50px', backgroundColor: '#fafafa', padding: '15px', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                        <span style={{ color: '#6d6a6a' }} className='font-14 mr-5'>Overridden Additional Jira Fileds</span>
                        <button className="btn btn-transparent" edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <span className='ri-close-line 'style={{ color: 'rgb(109, 106, 106)' }} fontSize="large" ></span>
                        </button>
                    </div>
                    <div className={classes.root}>

                        {
                            inputList?.length > 0 ?
                                <>
                                    <div className="pl-10 h-250 pr-10 " style={state.loading_data ? { minHeight: '128px', display: 'flex' } : null}>

                                        <Grid container alignItems="center" className="">
                                            {
                                                inputList && inputList.length > 0 ?
                                                    <>
                                                        <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={6}>
                                                            <p>Fields</p>
                                                        </Grid>
                                                        <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={6}>
                                                            <p>Value</p>
                                                        </Grid>
                                                    </>
                                                    :
                                                    <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={12}>
                                                        <p>Add your comment</p>
                                                    </Grid>}
                                            <Grid className=" pd-10 font-12" item xs={12}>
                                                {
                                                    inputList && inputList.length > 0 ? inputList.map((variable, index) => {
                                                        return (
                                                            <Grid container className="border-bottom">
                                                                <Grid key={index} item xs={6} className="d-flex align-center"
                                                                    style={
                                                                        variable.data_type === 'checkbox' ?
                                                                            { height: '120px', padding: '10px' } :
                                                                            variable.data_type === "people" ?
                                                                                { minHeight: '80px', maxHeight: '300px', padding: '10px' } :
                                                                                { height: '80px', padding: '10px' }}>
                                                                    <p className="font-12">{variable.key}&nbsp;<span className="font-12" style={{ color: 'red' }}>*</span></p>
                                                                </Grid>
                                                                <Grid key={index} item xs={6} className=""
                                                                    style={

                                                                        variable.data_type === 'people' ? { minHeight: '80px', maxHeight: '300px', padding: '10px' } :
                                                                            { height: '80px', padding: '10px' }}>
                                                                    {
                                                                        variable.data_type === "people" ?
                                                                            <>

                                                                                <Grid item lg={12}>
                                                                                    <span style={{ fontSize: '12px', color: '#828282' }}>
                                                                                        Search the user here.</span><span style={{ fontSize: '11px', color: '#828282' }}>&nbsp;(Please enter atleast first three characters of name)</span>
                                                                                    <div className={classes.searchBar}>

                                                                                        <Input
                                                                                            type="text"
                                                                                            varient="handle-keys"
                                                                                            placeholder="Search User"
                                                                                            name={`user_${variable.id}`}
                                                                                            data={state.data}
                                                                                            error={state.error}
                                                                                            onChangeHandler={onChangeHandlerSearchInput}
                                                                                            onKeyPress={(e, template_id) => { onEnterKeyPress(e, variable.job_template, variable.id) }}
                                                                                        />
                                                                                        <button className='btn btn-searchbar' onClick={() => { fetchJiraUserList(variable.job_template, state.data[`user_${variable.id}`], variable.id) }}>
                                                                                            <span class="material-icons material-symbols-outlined">
                                                                                                search
                                                                                            </span>
                                                                                        </button>
                                                                                    </div>
                                                                                </Grid>

                                                                                {
                                                                                    state.user_object[`user_${variable.id}_loading_jira_user_list`] ?
                                                                                        <Loading varient="light" /> :
                                                                                        state.user_object[`user_${variable.id}_show_user_dropdown`] ?
                                                                                            state.user_object[`user_${variable.id}_jira_user_list`] && state.user_object[`user_${variable.id}_jira_user_list`].length > 0 ?
                                                                                                <Grid item lg={12}>
                                                                                                    <Input
                                                                                                        type="select"
                                                                                                        name={variable.id}
                                                                                                        mandatorySign
                                                                                                        list={
                                                                                                            state.user_object[`user_${variable.id}_jira_user_list`].map(item => {
                                                                                                                return { label: item, id: item }
                                                                                                            })}
                                                                                                        label="Available Jira Users"
                                                                                                        placeholder="Jira Users"
                                                                                                        onChangeHandler={selectJiraUserHandler}
                                                                                                        data={state.data}
                                                                                                        error={state.error} />
                                                                                                </Grid>
                                                                                                : state.user_object[`user_${variable.id}_no_user_found`] ?
                                                                                                    <AlertStrip
                                                                                                        message={state.user_object[`user_${variable.id}_no_user_found`]}
                                                                                                        variant="error" /> : null
                                                                                            : state.user_object[`user_${variable.id}_no_jira_user_found`] ?
                                                                                                <AlertStrip
                                                                                                    message={typeof (state.user_object[`user_${variable.id}_error_msg_jira_list`]) === "string" ? state.user_object[`user_${variable.id}_error_msg_jira_list`] : "Jira Users not found in this Job Template"}
                                                                                                    variant="error" />
                                                                                                :
                                                                                                state.user_object[`user_${variable.id}_no_user_found`] ?
                                                                                                    <AlertStrip
                                                                                                        message={state.user_object[`user_${variable.id}_no_user_found`]}
                                                                                                        variant="error" />
                                                                                                    :
                                                                                                    <Grid item lg={12}>


                                                                                                    </Grid>
                                                                                }
                                                                            </>
                                                                            :
                                                                            variable.data_type === "group" ?
                                                                                <>
                                                                                    <GroupComponentJira variable={variable}
                                                                                        data={state.data}
                                                                                        error={state.error}
                                                                                        setParentState={setState}
                                                                                        jobTemplateId={state.default_job_template}
                                                                                        additionalGroupFields={state.additionalGroupFields}
                                                                                        additionalGroupFieldsLoading={state.additionalGroupFieldsLoading}
                                                                                    />
                                                                                </>
                                                                                :

                                                                                <>
                                                                                    <div className='auto-dropdown-with-checkbox-wrapper'>
                                                                                        <Input
                                                                                            type={variable.data_type === 'toggle' ? 'select' : variable.data_type === "checkbox" ? "select-with-checkbox" : variable.data_type}
                                                                                            mandatorySign={variable.mandatory}
                                                                                            name={variable.id}
                                                                                            list={variable.data_type === 'select' ?
                                                                                                variable.default_values ?
                                                                                                    variable.default_values.map(item => ({

                                                                                                        id: item, label: item
                                                                                                    }))
                                                                                                    : []
                                                                                                :
                                                                                                variable.data_type === 'checkbox' ?
                                                                                                    variable.default_values ?

                                                                                                        variable.default_values.map(item => ({

                                                                                                            id: item, label: item
                                                                                                        }))
                                                                                                        : []

                                                                                                    : variable.data_type === 'toggle' ? [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]
                                                                                                        : []
                                                                                            }
                                                                                            // label={variable.key}
                                                                                            placeholder=""
                                                                                            onChangeHandler={variable.data_type === 'checkbox' ? onKeyValueChangeHandler : commonFunctions.onChangeHandler}
                                                                                            data={state.data}
                                                                                            error={state.error}
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                    }

                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    }) : null
                                                }
                                            </Grid>
                                            {variant !== "only-view" ? <div className='border-bottom'></div> : null}
                                        </Grid>

                                    </div>
                                    {state.loading_data ?
                                        null :
                                        <div className="d-flex align-center justify-end pd-10 ">
                                            <button className="btn btn-outline-grey" onClick={handleClose}>Cancel</button>
                                            <button className="btn btn-submit" onClick={pushDataToArray}>Save</button>
                                        </div>
                                    }
                                </> :
                                <div style={{ height: "100%", width: "100%" }}>
                                    <label>No Data</label>
                                </div>
                        }


                    </div>
                </div>
            </div>
        }
        </>
    )
}

RunParamsCustomFields.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };


export default RunParamsCustomFields;


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    root: {
        '& .h-300': {
            height: 350,
            overflowy: 'scroll'
        }
    }
}));


