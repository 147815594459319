import React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #b7b7b7',
    overflow: 'hidden',
    borderRadius: '4px',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  '& .MuiAutocomplete-inputRoot[class*="Mui-focused"] .MuiInputBase-input': {

    border: '0px!important',
  },
}));

const Root = styled('div')({
  border: '1px solid #b7b7b7',
  overflow: 'hidden',
  borderRadius: '4px',


})

const AutoCompleteWithMultipleSelect = ({ list = [], selectedValues = [], onChange, placeholder, allowNewItems = true }) => {
  console.log(selectedValues, "selectedValuesselectedValues")
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label || '',
    filter: (options, params) => {
      console.log(options, params, "createFilterOptions")
      const filtered = createFilterOptions({ matchFrom: 'start', stringify: (option) => option.label })(options, params);
      const { inputValue } = params;
      const isExisting = options.some(option => inputValue.toLowerCase() === option.label.toLowerCase());
      if (allowNewItems) {
        if (inputValue !== '' && !isExisting) {
          filtered.push(`New Label: ${inputValue}`);
        }
      }

      return filtered;
    },
  });

  const handleOnChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Root>
      <Autocomplete
        multiple
        id="tags-filled"
        options={list.map((option) => option.label)}
        value={selectedValues} // Assuming selectedValues directly represents the selected labels
        onChange={handleOnChange}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <div className='material-ui-chip-wrapper'>
              <Chip size='small'

                label={option}
                {...getTagProps({ index })}
                deleteIcon={<span className='ri-close-line font-24 color-white' style={{
                  backgroundColor: 'transparent',
                  fill: '#fff'
                }}></span>} />
            </div>

          ))
        }
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
        filterOptions={(options, params) => {
          const filtered = options.filter((option) => option.toLowerCase().includes(params.inputValue.toLowerCase()));

          // Check if the user's input corresponds to a part of any option
          if (allowNewItems) {
            if (params.inputValue !== '' && !filtered.includes(params.inputValue)) {
              filtered.push(`${params.inputValue} (New Label)`);
            }
          }


          return filtered;
        }}
      />
    </Root>
  );
};

AutoCompleteWithMultipleSelect.propTypes = {
  list: PropTypes.array,
  selectedValues: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default AutoCompleteWithMultipleSelect;
