import React, { useEffect, useState } from 'react'
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { JiraTicket } from './components/JiraTicket';
import SprintDropDown from './components/SprintDropDown';
import SearchBar from '../../components/SearchBar';
import { Input } from '../../components/genericComponents/Input';
import properties from '../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../util/apiInvoker';
import InputSkeleton from '../../components/genericComponents/Skeletons/InputSkeleton';
import GenericSkeleton from '../../components/genericComponents/Skeletons/GenericSkeleton';
import PaginationTwo from '../../components/PaginationTwo';
import { Link, useLocation } from 'react-router-dom';
import StepProgressDetailed from '../../components/genericComponents/StepProgressDetailed';
import { showErrorHandlerUpdated } from '../../util/util';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

const SprintApprove = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    data: {},
    error: {},
    available_sprints: [],
    selected_active_sprint: null,
    sprint_work_items: [],
    available_states: [],
    current_sprint_path: null,
    sprint_approval_in_progress: null,
    loading_workitems: true,
    error_in_loading_data: null
  })
  const [postAprroveData, setPostApproveData] = useState(null);
  const history = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  const sprint_name = parsedQueryString.sprint ? decodeURIComponent(parsedQueryString.sprint) : null;
  console.log(parsedQueryString.sprint,sprint_name, "sprint_nameslafdllfsa" )
  const [statusArr, setStatusArr] = useState({
    loading_status: true,
    completion_count: null
  });




  function fetchAdoStatusCount() {
    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.total_state_count),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, fetchAdoStatusCountSuccess, fetchAdoStatusCountFailed);

    setStatusArr(new_state => ({
      ...new_state,
      loading_status: true,
      error_in_loading_data: null
    }))

  }

  function fetchAdoStatusCountSuccess(data) {

    setStatusArr(new_state => ({
      ...new_state,
      loading_status: false,
      completion_count: data.states_count
    }))

  }
  function fetchAdoStatusCountFailed(error) {
    let error_msg = showErrorHandlerUpdated(error)
    setStatusArr(new_state => ({
      ...new_state,
      loading_status: false,
      error_in_loading_data: error_msg
    }))

  }
  useEffect(() => {
    fetchAdoSprints()
    return () => {
      localStorage.removeItem("sprint_to_be_approved")
    }
  }, [])


  useEffect(() => {

    if (postAprroveData && postAprroveData.task_status) {

      if (postAprroveData.task_status == "IN_PROGRESS") {
        setTimeout(getSprintApprovedStatus, 5000);
      } else {
        fetchAdoSprints()
      }
    }
  }, [postAprroveData]);

  function fetchAdoSprints(COMING_FROM_LOADING) {

    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.load_ado_sprints),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, (response) => { fetchAdoSprintsDataSuccess(response, COMING_FROM_LOADING) }, fetchAdoSprintsDataFailed);

    setState(new_state => ({
      ...new_state,
      sprints_loading: true,
      error_in_loading_data: null,
      sprint_approval_in_progress: null
    }))

  }

  function fetchAdoSprintsDataSuccess(data, COMING_FROM_LOADING) {
    let sprint_list = data.sprint_list && data.sprint_list.values ? data.sprint_list.values.length > 0 ? data.sprint_list.values : [] : [];
    let sprint_went_to_approve = JSON.parse(localStorage.getItem('sprint_to_be_approved'));
    let find_current_sprint;
    if (sprint_name) {
      find_current_sprint = sprint_list.find(item => item.name == sprint_name);
    } else {
      if (sprint_went_to_approve && sprint_went_to_approve.name) {
        find_current_sprint = sprint_list.find(item => item.name == sprint_went_to_approve.name);
      } else {
        find_current_sprint = sprint_list.find(item => item.state == "current");
      }
    }


    if (find_current_sprint) {
      fetchAdoSprintWorkItems(find_current_sprint.path)
    }
    if (find_current_sprint.approved) {
      getSprintApprovedStatus(null, find_current_sprint.name, true);
    }
    setState(new_state => ({
      ...new_state,
      available_sprints: sprint_list,
      selected_active_sprint: find_current_sprint,
      current_sprint_path: find_current_sprint.path,
      sprints_loading: false,
      sprint_approval_in_progress: COMING_FROM_LOADING ? true : false
    }));

  }
  function fetchAdoSprintsDataFailed(error) {
    let errror_msg = showErrorHandlerUpdated(error)
    setState(new_state => ({
      ...new_state,
      error_in_loading_data: errror_msg,
      sprints_loading: false
    }))
  }


  function getSprintApprovedStatus(COMING_FROM_LOADING, sprint_name, do_not_call_useeffect) {
    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.sprint_approve),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    if (sprint_name) {
      requestInfo.endPoint = GenerateURL({}, properties.api.sprint_approve + "?sprint_name=" + sprint_name)
    } else {
      if (state.selected_active_sprint && state.selected_active_sprint.name) {
        requestInfo.endPoint = GenerateURL({}, properties.api.sprint_approve + "?sprint_name=" + state.selected_active_sprint.name)
      }
    }

    InvokeApi(requestInfo, COMING_FROM_LOADING ? (response) => { getSprintApprovedStatusSuccess(response, COMING_FROM_LOADING) } : (res) => { getSprintApprovedStatusSuccess(res, null, do_not_call_useeffect) }, getSprintApprovedStatusFailed);

    setState(new_state => ({
      ...new_state,
      sprints_loading: true,
      task_id: null,
      error_in_loading_data: null
    }))

  }

  function getSprintApprovedStatusSuccess(response, COMING_FROM_LOADING, do_not_call_useeffect) {
    if (COMING_FROM_LOADING) {

      fetchAdoSprints()
    }
    setState(new_state => ({
      ...new_state,
      sprint_approval_in_progress: response && response.task_status ? response.task_status != "" ? response.task_status : null : null,
      sprints_loading: false,
      approver_user_name: response.approver_user_name,
      approver_user_group: response.approver_user_group,
    }))

    if (!do_not_call_useeffect) {
      setPostApproveData(response);
    }


  }
  function getSprintApprovedStatusFailed(error) {
    let error_msg = showErrorHandlerUpdated(error)
    setPostApproveData(error)
    setState(new_state => ({
      ...new_state,
      error_in_loading_data: error_msg
    }))

  }

  function fetchAdoSprintWorkItems(path, page, searchQuery) {
    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.ado_work_items + "?all=" + true + "&path=" + path),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    if (page) {
      requestInfo.endPoint = GenerateURL({}, properties.api.ado_work_items + "?all=" + true + "&path=" + path + "&page=" + page)
    }
    if (searchQuery) {
      requestInfo.endPoint = GenerateSearchURL(searchQuery, GenerateURL({}, properties.api.ado_work_items)) + "&path=" + path;
    }
    InvokeApi(requestInfo, fetchAdoSprintWorkItemsDataSuccess, fetchAdoSprintWorkItemsDataFailed);

    setState(new_state => ({
      ...new_state,
      loading_workitems: true,
      search_data: searchQuery,
      search_query_name: searchQuery && searchQuery.query ? searchQuery.query : null,
      error_in_loading_data: null
    }))

  }

  function fetchAdoSprintWorkItemsDataSuccess(data) {
    fetchAdoStatusCount();
    fetchAdoStates();
    let work_items_list = data && data.issues_dict ? data.issues_dict.values && data.issues_dict.values.length > 0 ? data.issues_dict.values : [] : [];
    let general_data = data && data.issues_dict ? data && data.issues_dict : null;
    setState(new_state => ({
      ...new_state,
      sprint_work_items: work_items_list,
      loading_workitems: false,
      total_count: general_data.total_pages ? general_data.total_pages : null,
      count: general_data.count,
      current_page_count: general_data.page
    }));

  }
  function fetchAdoSprintWorkItemsDataFailed(error) {
    let error_msg = showErrorHandlerUpdated(error)
    setState(new_state => ({
      ...new_state,
      error_in_loading_data: error_msg,
      loading_workitems: false
    }))
  }


  const onChangeHandler = (e) => {
    let key = e.target.name;
    let value = e.target.value;

    let data = { [key]: value }

    fetchAdoSprintWorkItems(state.selected_active_sprint.path, null, data)
    setState(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        [key]: value
      },
      error: {
        ...new_state.error,
        [key]: ''
      }
    }))
  }
  const setSelectedRow = (data) => {
   
    if (data) {
      fetchAdoSprintWorkItems(data.path)
    }
    if(sprint_name){
      history({
        pathname: window.location.pathname,
        search: '',
      });
    }
    

    setState(new_state => ({
      ...new_state,
      selected_active_sprint: data,
      sprint_approval_in_progress: null
    }));
  }

  const calculateProgress = (tasks) => {

    const totalTasks = tasks.total_count;
    const final_validation_state = tasks.final_validation_state;
    const completedTasks = tasks[final_validation_state] ? tasks[final_validation_state] : 0;
    const inProgressTasks = tasks.doing_count ? tasks.doing_count : 0;
    const completedPercentage = completedTasks === 0 ? 0 : (completedTasks / totalTasks) * 100;
    const inProgressPercentage = (inProgressTasks / totalTasks) * 100;
    return { totalTasks, completedPercentage, inProgressPercentage };
  };

  const progress = statusArr.completion_count ? calculateProgress(statusArr.completion_count) : null;

  const handlePageNumberClick = (pageNumber) => {
    if (state.selected_active_sprint) {
      fetchAdoSprintWorkItems(state.selected_active_sprint.path, pageNumber);
    }

  };

  function fetchAdoStates() {
    var requestInfo = {
      endPoint: GenerateURL({}, properties.api.load_ado_states),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    InvokeApi(requestInfo, fetchAdoStatesDataSuccess, fetchAdoStatesDataFailed);

    setState(new_state => ({
      ...new_state,
      loading_states: true,
      error_in_loading_data: null
    }))

  }

  function fetchAdoStatesDataSuccess(data) {

    let available_states = data.states && data.states.length > 0 ? data.states.map(item => {
      return { label: item, id: item }
    }) : [];
    available_states.unshift({ label: 'All', id: 'all' });

    setState(new_state => ({
      ...new_state,
      available_states: available_states,
      loading_states: false,
      data: {
        ...new_state.data,
        ['state']: 'all'
      }
    }));

  }
  function fetchAdoStatesDataFailed(error) {
    let error_msg = showErrorHandlerUpdated(error)
    setState(new_state => ({
      ...new_state,
      error_in_loading_data: error_msg,
      loading_states: false
    }))
  }


  const approveSprintAndContinue = () => {

    var url = GenerateURL({}, properties.api.sprint_approve);
    let finalObj = {
      path: state.selected_active_sprint && state.selected_active_sprint.path ? state.selected_active_sprint.path : null,
      sprint_name: state.selected_active_sprint && state.selected_active_sprint.name ? state.selected_active_sprint.name : null
    }

    localStorage.setItem('sprint_to_be_approved', JSON.stringify(state.selected_active_sprint))
    PostData(url, finalObj, postDataSuccess, postDataFailed);
    setState(new_state => ({
      ...new_state,
      sprints_loading: true,
      loading_workitems: true
    }))

  }
  const postDataSuccess = (response) => {

    setState(new_state => ({
      ...new_state,
      sprints_loading: false,
      loading_workitems: false,
      task_id: response.task_id,
    }))

  }

  const postDataFailed = (error) => {
    let error_msg = showErrorHandlerUpdated(error);
    setState(new_state => ({
      ...new_state,
      task_id: null,
      sprints_loading: false,
      loading_workitems: false,
      error_in_loading_data: error_msg
    }))
  }
  const invokeSearch = (data) => {
    fetchAdoSprintWorkItems(state.selected_active_sprint.path, null, data)
  }
  return (
    <div className={state.error_in_loading_data ? "d-flex align-center justify-center " + classes.root : classes.root} >
      {state.task_id ?
        <StepProgressDetailed
          hideHintText={true}
          skipButtonRequired={true}
          task_id={state.task_id}
          placeholders={{}}
          variant="SAME_PAGE_REDIRECT"
          handleClickToSkip={
            () => {
              fetchAdoSprints("COMING_FROM_LOADING");
              getSprintApprovedStatus("COMING_FROM_LOADING")
            }
          }
          refresh={() => {
            fetchAdoSprints(null)

          }}
          btnLabel="Continue"
          type="new" /> :
        state.error_in_loading_data ?
          <div className='' style={{ margin: 'auto', maxWidth: '500px', textAlign: 'center' }} >
            <p className='mb-15'>{state.error_in_loading_data}</p>
            <button className='btn btn-primary' onClick={() => { fetchAdoSprints() }}>Retry</button>
          </div> :
          <>
            <div className='page-header d-flex align-center space-between mb-20'>
              <div className='d-flex align-center'>
                <div className='sq-avtar-one d-flex align-center justify-center mr-10'
                  style={{
                    width: '56px',
                    height: '56px',
                    borderRadius: '6px',
                    backgroundColor: '#EBF5FF'
                  }}
                >
                  <img src='/images/logos/sprint-icon.svg' alt=".." />
                </div>
                {
                  state.sprints_loading ?
                    <>
                      <InputSkeleton type="text" height={'26px'} width={'160px'} />
                      <div className='' style={{ marginBottom: '4px', width: '160px' }}></div>

                    </> :
                    <div>
                      <div className='d-flex align-center'>
                        <p className='font-16 font-weight-600 color-primary mr-10'>
                          {
                            state.selected_active_sprint && state.selected_active_sprint.name ? state.selected_active_sprint.name : "N/A"
                          }
                        </p>
                        <span className='font-12 font-weight-700' style={
                          state.selected_active_sprint && state.selected_active_sprint.approved ?
                            { color: '#0086ff', backgroundColor: '#F5FAFF', padding: '3px 6px', border: '1px solid #DFEDFF', borderRadius: '5px' }
                            :
                            { color: '#949494', backgroundColor: '#f4f4f4', padding: '3px 6px', border: '1px solid #DFEDFF', borderRadius: '5px' }
                        }>
                          {
                            state.selected_active_sprint && state.selected_active_sprint.approved ? "APPROVED" : "UNAPPROVED"
                          }


                        </span>
                      </div>
                      <div className='d-flex align-center'>
                        {
                          state.selected_active_sprint &&
                          <SprintDateDisplay sprint={state.selected_active_sprint} />
                        }
                        &nbsp;
                        {
                          state.selected_active_sprint && state.selected_active_sprint.approved &&
                          <p className='font-12 font-weight-500 color-tertiary'> {state.approver_user_group ? "Approved by user group: " + state.approver_user_group : state.approver_user_name ? "Approved by: " + state.approver_user_name : null}</p>
                        }

                      </div>
                    </div>
                }

              </div>
              {
                state.sprints_loading ?
                  <InputSkeleton type="text" height={'39px'} width={'230px'} />
                  :
                  <SprintDropDown
                    itemList={state.available_sprints}
                    selectedSprintData={state.selected_active_sprint}
                    sendSelectedRowToParent={setSelectedRow}
                  />}
            </div>
            <div className='mb-12 d-grid' style={{ border: '1px solid #e6e6e6', borderRadius: '6px', padding: '12px', gridTemplateColumns: '1fr 1fr', gap: '50px' }}>
              {
                statusArr.loading_status ?
                  <>
                    <div className='' style={{ width: '450px' }}>
                      <div className='d-flex align-center space-between mb-8' style={{ width: '100%' }}>
                        <GenericSkeleton variant={"rect"} width={"48px"} height={18} count={1} style={{ borderRadius: "6px" }} />
                        <GenericSkeleton variant={"rect"} width={"90px"} height={18} count={1} style={{ borderRadius: "6px", marginLeft: 'auto', }} />
                      </div>
                      <GenericSkeleton variant={"rect"} width={"100%"} height={8} count={1} rootStyle={{ marginLeft: 'auto', }} style={{ borderRadius: "6px" }} />
                    </div>

                  </> :
                  <ProgressBar
                    completed={progress.completedPercentage}
                    inProgress={progress.inProgressPercentage}
                    totalTasks={progress.totalTasks}
                  />
              }

              {/* <div className='font-12 text-right'>
            <span className='' style={{color:'#787878'}}>Approved on:</span> <span className='' style={{color:'#2f2f2f'}}>14 Aug</span>
            <div className='' style={{color:'#505050'}}>By Siddharth</div>
        </div> */}

              {
                statusArr.loading_status ?
                  <GenericSkeleton variant={"rect"} width={"135px"} height={35} count={1} rootStyle={{ marginLeft: 'auto', }} style={{ borderRadius: "6px" }} /> :
                  state.selected_active_sprint && state.selected_active_sprint.approved ?

                    <Link to={"/finalize/sprint-scope?sprint=" + state.selected_active_sprint.name + "&path=" + state.selected_active_sprint.path} className="ml-auto d-block">

                      <button className='btn btn-primary mr-0 text-uppercase' style={{ width: 'fit-content' }}>
                        Finalize / Close
                      </button>
                    </Link>
                    :
                    state.sprint_approval_in_progress || postAprroveData && postAprroveData.task_status === "IN_PROGRESS" ?
                      <span className='chip-v1 d-block ml-auto default-outline-chip-v1 btn-progess-animate button-processing'>SPRINT APPROVAL IN-PROGRESS</span>
                      :
                      state.sprint_work_items.length === 0 ?
                        <>
                          <Tooltip title={`This sprint doesn't contains any work items. please add work items to approve the sprint`}>
                            <button
                              className='btn btn-disabled mr-0 text-uppercase ml-auto d-block cursor-not-allowed'
                              style={{ width: 'fit-content' }}
                              onClick={() => { }}
                            >
                              Approve Sprint
                            </button>
                          </Tooltip>

                        </>
                        :
                        <button
                          className='btn btn-primary mr-0 text-uppercase ml-auto d-block'
                          style={{ width: 'fit-content' }}
                          onClick={approveSprintAndContinue}
                        >
                          Approve Sprint
                        </button>
              }


            </div>

            <div className='d-grid align-center space-between search-div-wrapper' style={{ gridTemplateColumns: '300px 180px', width: '100%' }}>
              <SearchBar
                search_data={state.search_data}
                default_filter={{ name: "query", label: "Search by title or workitem id and hit enter" }}
                search_call_back={invokeSearch}
                clear_search_callback={() => { fetchAdoSprintWorkItems(state.selected_active_sprint.path) }}
              />
              <div className='input-mb-0'>
                <Input
                  type="select-with-label"
                  label=""
                  list={state.available_states}
                  name={"state"}
                  data={state.data}
                  error={state.error}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>

            <p className='font-12 font-weight-500 mb-12 color-primary'>Stories Involved</p>
            {
              state.loading_workitems ?
                <div
                  className='parent_div_jiras mb-24' >

                  {
                    Array.from({ length: 10 }).map((_, index) => {
                      return (
                        <div className="bg-white bg-white" style={{ height: 84, padding: "24px 12px", borderBottom: "1px solid #E6E6E6" }}>

                          <GenericSkeleton variant={"rect"} width={"33%"} height={40} count={3} rootStyle={{ gap: "20px", flexDirection: "row" }} style={{ borderRadius: "6px" }} />

                        </div>);
                    })
                  }
                </div>
                :
                <div
                  className='parent_div_jiras mb-24'
                  style={
                    state.sprint_work_items.length === 0 && state.search_query_name ?
                      { display: 'flex', alignItems: 'center', justifyContent: 'center' } :
                      state.sprint_work_items.length === 0 ?
                        { display: 'flex', alignItems: 'center', justifyContent: 'center' } : {}}>


                  {state.sprint_work_items.length === 0 && state.search_query_name ?
                    <p className='font-14 font-primary'>Unable to find ticket with this name or id: {state.search_query_name}</p>
                    :
                    state.sprint_work_items.length > 0 ?
                      state.sprint_work_items.map(item => {
                        return <JiraTicket jiraObject={item} onClick={() => { }} onDelete={() => { }} />
                      })
                      :
                      <p className='font-14 font-primary'>No work items added in this sprint yet!!</p>
                  }
                </div>
            }
            <PaginationTwo
              count={state.count}
              total_count={state.total_count}
              current_page={state.current_page_count}
              on_pageNumber_click={handlePageNumberClick}
            />
          </>
      }
    </div>
  )
}

export default SprintApprove;

const SprintDateDisplay = ({ sprint }) => {

  const formatDateRange = (sprint) => {
    const formatDate = (dateString) => {
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-GB', options);
    };

    const startDate = formatDate(sprint.startDate);
    const finishDate = formatDate(sprint.finishDate);

    return `${startDate} - ${finishDate}`;
  };

  return (
    <p className='font-12 font-weight-500 color-tertiary'>
      {formatDateRange(sprint)}
    </p>
  );
};



const ProgressBar = ({ completed, inProgress, totalTasks }) => {
  const classes = useStylesBar();
  const totalCompleted = Math.round(completed);

  const totalInProgress = Math.round(inProgress);
  return (
    <div className={classes.root}>
      <div className='d-flex align-center space-between mb-8'>
        <span className='font-12 color-secondary font-weight-600'>{totalTasks} stories</span>
        <span className='font-12 color-secondary font-weight-600'>{totalCompleted}% Completed</span>
      </div>
      <div className={"custom-progress-bar"}>
        <div style={{ backgroundColor: '#2EBE79', width: `${completed}%`, height: '8px' }}></div>
        <div style={{ backgroundColor: '#0086ff', width: `${inProgress}%`, height: '8px' }}></div>
      </div>
    </div>
  );
};


const useStylesBar = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .custom-progress-bar': {
      width: '100%',
      height: '8px',
      backgroundColor: '#f4f4f4',
      borderRadius: '8px',
      overflow: 'hidden',
      display: 'flex'
    }
  }
}))




const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    padding: '20px',
    height: 'calc( 100vh - 70px )',
    '& .search-div-wrapper': {
      marginBottom: '10px',
      '& .wrapper-for-search': {
        border: '1px solid #b7b7b7'
      }
    },
    '& .parent_div_jiras': {
      border: '1px solid rgb(230, 230, 230)',
      borderRadius: '8px',
      height: '40vh',
      overflowY: 'hidden',
      position: 'relative',
      [theme.breakpoints.up('xl')]: {
        '@media (max-width: 1920px) and (max-height: 999px)': {
          height: '50vh',
        },
      },
      '&:hover': {
        overflowY: 'scroll',
      },
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#b7b3b3 !important',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#f0eeee!important',
        boxShadow: 'none!important',
        '-webkit-box-shadow': 'none!important'
      },
    }
  }
}))