import React, { useEffect, useState, useMemo } from 'react';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

import { LoadingText } from '../../../utils/Loading';
import { CopyToClipboard, getDuration } from '../../../../util/util';
import ActivityStepDetails from '../../../superAdmin/activityFramework/components/ActivityStepDetails';
import Snackbar from '@mui/material/Snackbar';
import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import { makeStyles, styled } from '@mui/styles';
import { ParameterComponent } from './HistoryPageComponents/DeployHistory';
import RunningDotsComponent from './componentLogsViewer/RunningDotsComponent';
import useFetchPermissions from '../../../../components/customHooks/useFetchPermissions';
import { Alert } from '@mui/material';

const CurrentProgressDataNew = props => {

    const classes = useStyles();
    const type = props.type;
    const service_id = props.service_id;
    const env_id = props.env_id;
    const application_id = props.application_id;
    const service_data = props.service_data;
    const env_name = props.env_name;
    const top_summary_data = props.top_summary_data;
    const env_name_service = props.service_env_name;
    const recent_history = props.recent_history ? props.recent_history : null;
    const is_env_down = props.is_env_down ? props.is_env_down : null;
    const serviceEnv = props.serviceEnv
    const ci_data = props.ci_data;
    const branch_with_commit_sha = props.branch_with_commit_sha
    var component_env = service_data && service_data.component_env ? service_data.component_env.map(x =>
        (x.project_env && x.project_env.name)
    ) : []
    component_env = component_env ? component_env.map(item => (item)) : null
    var service_name = service_data.name ? service_data.name : top_summary_data ? top_summary_data.service_name : null;

    var service_env = service_data.env ? service_data.env : top_summary_data ? top_summary_data.env_name : null;
    service_env = service_env ? service_env.toLowerCase() : null;

    var service_env_type = service_data.type ? service_data.type : top_summary_data ? top_summary_data.type : null;
    service_env_type = service_env_type.toLowerCase();
    var revokeUrlPath = type == "BUILD" ? properties.api.env_build_revoke_url : type == "DEPLOY" ? properties.api.env_deploy_revoke_url : properties.api.env_promote_revoke_url;
    var refresh_count = props.refresh_count;
    const [state, setState] = useState({
        history_list: [],
        activity_list: []
    });


    const buildId = state.last_build && state.last_build.id ? state.last_build.id : null
    const build_status = state?.last_build?.activity_status?.status
    const build_by_user = useMemo(() => getBuildByUserName(state.last_build), state.last_build)
    const image = state?.last_build?.build_tag
    const build_number = state?.last_build?.build_number

    const workspaceParams = { action_number: build_number, action_status: build_status, action_by: build_by_user, image: image }

    function getEnvNamefromId() {
        var env_name = "";
        if (service_data && service_data.component_env) {
            service_data.component_env.map(item => {
                if (item.id == env_id) {
                    env_name = item.project_env.name
                }
            })
        }
        return env_name;
    }
    var service_env_name = service_env ? service_env : getEnvNamefromId()
    function fetchRecentBuildDeployData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_ci_cd_data_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setState(new_state => ({
            ...new_state,
            loading: true
        }));
        InvokeApi(requestInfo, onfetchRecentBuildDeployDataSuccess, onfetchRecentBuildDeployDataFail);

    }

    function onfetchRecentBuildDeployDataSuccess(response) {
        console.log(response, "it is a response");
        if (buildId) {
            removeArrData()
        }
        setState(new_state => ({
            ...new_state,
            ci_cd_data: response,
            last_build: response.last_build,
            in_queue_data: response.build_in_queue_data,
            last_deploy: response.last_deploy,
            last_promote: response.last_promote,
            loading: false
        }));
        if (response.last_build[0].activity_status && response.last_build[0].activity_status.id) {
            getActivityCurrentInfo(response.last_build[0].activity_status.id)
        }

    }

    function onfetchRecentBuildDeployDataFail(response) {
        setState(new_state => ({
            ...new_state,
            error: response,
            loading: false
        }));
    }

    useEffect(() => {
        fetchRecentBuildDeployData();
    }, [type, env_id, refresh_count])


    function onRevoke(task_id) {
        var url_temp = GenerateURL({ service_id: service_id, env_id: env_id, id: task_id }, revokeUrlPath);
        var fetchOptions = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        PostData(url_temp, {}, handleSuccessRevoke, handleFailedRevoke);
        setState(new_state => ({
            ...new_state, history_info_status: "REQUESTED",
        }));
    }
    function handleSuccessRevoke(respone) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            loading: false
        }));
        props.refresh();
    }
    function handleFailedRevoke(error) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            error: true,
            loading: false
        }));
    }

    function getBuildbyUser(history) {
        return (
            <>{history && history.build_by_pipeline ?
                // <div className='text-ellipsis-twoline'>
                //     <p>Pipeline: {history.build_by_pipeline.pipeline.name}</p>
                //     <p>Triggered by: {history.build_by_pipeline.trigger_by}</p> </div>
                <div className='text-ellipsis-twoline'>
                    {/* <p> {history.build_by_pipeline.pipeline.name}</p> */}
                    <p>{history.build_by_pipeline.trigger_by}</p>
                </div>
                : <p className='text-ellipsis-110'>{history?.build_by_user}</p>
            }</>
        )

    }

    function getBuildByUserName(history) {
        console.log(history, 'hshhshshs');
        if (history) {

            if (history.build_by_pipeline) {
                return history.build_by_pipeline.trigger_by
            }

            else
                return history.build_by_user

        }
    }

    // console.log(getBuildByUserName(state.last_build), 'hereh_data_now')

    function getCommitSha() {
        let parent_ci_info = ci_data && ci_data.find((item) => {
            if (item.type_label == null) {
                return item
            }
        })
        let default_branch_name = parent_ci_info?.branch_name
        let commit_sha_obj;
        commit_sha_obj = branch_with_commit_sha && branch_with_commit_sha.find((item) => {
            if (item[default_branch_name]) {
                return item[default_branch_name]
            }
        })
        let commit_sha = commit_sha_obj && Object.values(commit_sha_obj)[0]
        return commit_sha;
    }

    function validateAndTrigger(type, item) {
        console.log(type, item)
        var data = {
            custom_tag: item && item.build_args_key_value ? item.build_args_key_value.custom_tag : null,
            branch_name: item && item.build_args_key_value ? item.build_args_key_value.branch_name : null,
            no_cache: item && item.build_args_key_value ? item.build_args_key_value.no_cache : null,
            commit_sha: getCommitSha()
        }
        if (!data.custom_tag) {
            delete data.custom_tag;
        }
        if (!data.branch_name) {
            delete data.branch_name;
        }
        var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url);
        PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);

        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,
            activity_list: [],
            loading: true,
            activity_step_details: {
                open_div: false
            },
        }));
    }
    function successBuildTriggerCallBack(response) {
        console.log("jdksjkds", state);
        setState(new_state => ({
            ...new_state,
            trigger: "success",
            rebuild_status: "Current tag build scheduled",
            tag: "",
            branch_name: "",
            no_cache: false,
        }));
        props.refresh()
    }

    function failedBuildTriggerCallBack(error) {
        console.log(error, "fdsajjfasjk")
        setState(new_state => ({
            ...new_state,
            trigger: "failed",
            rebuild_status: error.detail ? error.detail : null
        }));
    }

    function getClassbyActivityStatus(activity_status, type) {

        if (type == "Revoke") {
            if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
                return "icon-btn-v1 icon-btn-outline-danger"
            else
                return "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"
        } else {
            if (type === "Resume") {

                if ((activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING") || activity_status && activity_status == "SUCCESS")

                    return "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"
                else
                    return "btn-sq-icon-primary d-flex align-center mr-5"
            } else {

                if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")

                    return "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"
                else
                    return "btn-sq-icon-primary d-flex align-center mr-5"
            }

        }
    }
    function callFunctionbyStatus(activity_status, id) {
        console.log(activity_status, id, "sjdnzkhbcbshjhbsvhs")
        if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
            onRevoke(id)
    }

    function onResume(id) {
        var url_temp = GenerateURL({ service_id: service_id, env_id: env_id, id: id }, properties.api.build_resume);
        var fetchOptions = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        PostData(url_temp, {}, handleSuccessResume, handleFailedResume);

    }


    function handleSuccessResume(respone) {

        setState(new_state => ({
            ...new_state,
            show_loading_icon: false,
            trigger: "success",
            rebuild_status: "Rebuild Scheduled Successfully"
        }));
    }

    function handleFailedResume(error) {
        setState(new_state => ({
            ...new_state,
            error: true,
            show_loading_icon: false,
            trigger: "failed",
            rebuild_status: error.detail
        }));
    }

    function callResumeFunctionbyStatus(activity_status, id) {

        if ((activity_status != "IN_QUEUE" || activity_status != "RUNNING") || activity_status != "SUCCESS")

            onResume(id)
    }



    function getActivityCurrentInfo(activity_id) {
        var requestInfo = {
            endPoint: GenerateURL({ activity_id: activity_id }, properties.api.get_current_activity_status),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (response) => getActivityCurrentInfoSuccess(response, activity_id), getActivityCurrentInfoFailure);


    }

    function getActivityCurrentInfoSuccess(response, activity_id) {
        console.log(response, "sbvhbsvbshhbs")
        if (response && response.results.length > 0) {
            let orderedList = response.results.map((step, index) => ({ ...step, order: index }))
            setState(new_state => ({
                ...new_state,
                activity_list: orderedList,//[...response.results],
                activity_step_details: {
                    open_div: true
                },
                selected_activity_id: activity_id
            }));
        }
    }
    function getActivityCurrentInfoFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
    }
    const removeArrData = () => {
        setState(new_state => ({
            ...new_state,
            activity_list: [],
            activity_step_details: {
                open_div: state.activity_list.length > 0 ? true : false
            }
        }));
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickClosePopover = () => {
        setAnchorEl(null);
    };

    const OpenPopover = Boolean(anchorEl);
    const id = OpenPopover ? 'simple-popover' : undefined;

    const onHandleBuildRefresh = (type, item) => {
        props.refresh()
        removeArrData()
        getActivityCurrentInfo(item && item.activity_status ? item.activity_status.id ? item.activity_status.id : "" : "")

    }

    var url_build = GenerateURL({ service_id: props.service_id, env_id: props.env_id }, properties.api.env_build_request_url, true);
    const is_permitted_build = useFetchPermissions()?.POST.includes(url_build);


    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }

    // this function is used to get the button based on the type of the build
    function getBtnBasedOnType(item) {
        console.log(item, "skdbhbjchbsjbhdc")
        switch (type) {
            case "BUILD":
                return (
                    <>
                        {item && item.activity_status ?
                            <span>
                                <div className="round-btn-group d-flex align-center justify-center">
                                    <>
                                        <Tooltip title="View Activity Details">

                                            <button className="btn-sq-icon-primary d-flex align-center mr-5"
                                                onClick={() => { getActivityCurrentInfo(item && item.activity_status ? item.activity_status.id ? item.activity_status.id : "" : "") }} >
                                                <span className='ri-list-check-2'>

                                                </span>
                                            </button>
                                        </Tooltip>
                                    </>
                                    {
                                        is_permitted_build && !item.type_label ?
                                            <>
                                                <Tooltip title="Rebuild">
                                                    {
                                                        is_env_down ?
                                                            <button
                                                                className={item.activity_status.status ? getClassbyActivityStatus(item.activity_status.status, "Rebuild") : null}
                                                                onClick={handleClickOpenPopover}
                                                            >
                                                                <span class="ri-flashlight-line" >

                                                                </span>
                                                            </button>
                                                            :
                                                            <button

                                                                className={item.activity_status.status ? getClassbyActivityStatus(item.activity_status.status, "Rebuild") : null}
                                                                onClick={
                                                                    item.activity_status.status ?
                                                                        item.activity_status.status == "FAILED" || item.activity_status.status == "SUCCESS" || item.activity_status.status == "REVOKED" ? () => { validateAndTrigger("BUILD", item) } : () => { } : () => { }}
                                                            >
                                                                <span class="ri-flashlight-line">

                                                                </span>
                                                            </button>}
                                                </Tooltip>
                                            </>
                                            :
                                            <>
                                                <Tooltip title="Rebuild">
                                                    <button
                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}>
                                                        <span class="ri-flashlight-line">

                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </>
                                    }
                                    {
                                        item && item.build_by_pipeline ||
                                            (item.activity_status.status && item.activity_status.status != "FAILED") ?
                                            <>
                                                <Tooltip title="Resume">
                                                    <button
                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}
                                                    >
                                                        <span class="ri-play-circle-line">

                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </> :
                                            <>
                                                <Tooltip title="Resume">
                                                    <button
                                                        className={item.activity_status.status ? getClassbyActivityStatus(item.activity_status.status, "Resume") : "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}
                                                        // className='btn-sq-icon-primary d-flex align-center mr-5'
                                                        onClick={item.activity_status.status ? () => callResumeFunctionbyStatus(item.activity_status.status, item.id) : null}
                                                    >
                                                        <span class="ri-play-circle-line">

                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </>
                                    }

                                    {
                                        <>
                                            <Tooltip title="Workspace">
                                                <Link


                                                    to={{
                                                        pathname: `/application/${application_id}/service/${service_id}/build/${item.id}/workspace/`,
                                                        search: `?service_name=${service_name}` +
                                                            `&env_name=${env_name}` +
                                                            `&global_task_id=${item.global_task_id}` +
                                                            `&env_id=${env_id}` +
                                                            `&action_number=${workspaceParams.action_number}` +
                                                            `&action_status=${workspaceParams.action_status}` +
                                                            `&action_by=${build_by_user}` +
                                                            `&image=${workspaceParams.image}` +
                                                            `&build_number=${item.build_number}`+
                                                            `&child=${item.type_label ? true : false}`
                                                    }}
                                                    // state={workspaceParams}                            
                                                    target='_blank'

                                                >
                                                    <button className="btn-sq-icon-primary d-flex align-center mr-5">
                                                        <span
                                                            class="ri-artboard-2-line"

                                                        >

                                                        </span>
                                                    </button>
                                                </Link>
                                            </Tooltip>
                                        </>
                                    }
                                    {
                                        item.activity_status.status ?

                                            <>
                                                <Tooltip title={item.build_by_pipeline ? "This task has been triggered by a pipeline, please revoke the pipeline in order to revoke the task" : "Revoke Build"}>
                                                    <button
                                                        className={item.activity_status.status && (!item.build_by_pipeline) ? getClassbyActivityStatus(item.activity_status.status, "Revoke") : "btn-sq-icon-primary d-flex align-center mr-5 revoke-btn btn-disabled-new"}
                                                        // className='btn-sq-icon-primary d-flex align-center mr-5 revoke-btn'
                                                        onClick={item.activity_status.status == "IN_QUEUE" || item.activity_status.status == "RUNNING" && (!item.build_by_pipeline) ? () => callFunctionbyStatus(item.activity_status.status, item.id) : () => { }}

                                                    >
                                                        <span class="ri-stop-circle-line" >

                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </> :
                                            <>
                                                <Tooltip title="Revoke Build">
                                                    <button
                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 revoke-hover btn-disabled-new"}

                                                    >
                                                        <span class="ri-stop-circle-line" style={{ color: "#C11212" }}>

                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </>
                                    }
                                </div>
                            </span> : <span></span>
                        }

                    </>
                );
            default:
                return null;
        }
    }


    function getRightOrderedStep(activityList) {
        let rightOrderedList = new Array({ length: activityList.length });

        activityList.forEach(step => {
            rightOrderedList[step.order] = step;
        })

        return rightOrderedList;

    }
    console.log("kdlkdslslds", state);

    return (
        <>
            {/* {
                state.last_build?.length > 0 &&
                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <span onClick={fetchRecentBuildDeployData} className='ri-refresh-line cursor-pointer d-flex align-center' style={{ fontSize: "20px" }}></span>
                </div>

            } */}
            {
                state.loading ? <LoadingText /> :
                    <div style={{ background: "#F4F4F4", gap: "24px", marginBottom: "60px" }} className='d-flex align-start f-direction-column'>


                        {/* ---------------------Last Build Details-------------------- */}

                        <div className={classes.allDetails}>
                            {
                                state.last_build?.length > 0 &&
                                <h5 className='progressdatatitle'>Last Builds Details</h5>
                            }
                            {console.log(state.last_build, "msbcjgsdcgvsdvcjs")}
                            {state.last_build && state.last_build.length > 0 && state.last_build.map((item) => {
                                console.log(item, item.activity_status ? item.activity_status : null, item.activity_status.status, "avbcgavgdv")
                                return (
                                    item.status &&
                                    <div className={classes.card} style={{ height: "fit-content" }}>
                                        {/* --cardtop-- */}
                                        <div className={classes.cardTop}>
                                            <div className='cardTopMainContainer'>
                                                <div className="cardTopLeft d-flex align-center" style={{ gap: "10px" }}>
                                                    <span className='cardtitle'>
                                                        <a href={"/logs?global_task_id=" + item.global_task_id +
                                                            "&tab_id=" + item.id +
                                                            "&service_name=" + service_name +
                                                            "&service_env=" + service_env +
                                                            "&service_type=" + service_env_type +
                                                            "&tab_name=BUILD" +
                                                            "&num=" + item.build_number} target="_blank">
                                                            <span className="cursor-pointer ">Build #{item?.build_number}</span>
                                                        </a>
                                                    </span>
                                                    <span className={`new-status-chip ${item?.activity_status?.status === "RUNNING" ? "new-running" : item?.activity_status?.status === "FAILED" ? "new-failed" : item?.activity_status?.status === "SUCCESS" ? "new-success" : "new-inqueue"}`} >
                                                        {item?.activity_status?.status}
                                                        {item?.activity_status?.status == "RUNNING" ?
                                                            <div>
                                                                <RunningDotsComponent isFromEventSidePannel={true} />
                                                            </div> : null
                                                        }
                                                    </span>
                                                    <span className='cardtimer'>
                                                        <span className='ri-time-line'></span>
                                                        <span>
                                                            {item.activity_status.status && item.activity_status.status != "RUNNING" ? getDuration(new Date(item.activity_status.start_time && item.activity_status.start_time), new Date(item.activity_status.end_time && item.activity_status.end_time)) == 0 ? "0s" : getDuration(new Date(item.activity_status && item.activity_status.start_time), new Date(item.activity_status.end_time && item.activity_status.end_time)) : getDuration(new Date(item.activity_status.start_time && item.activity_status.start_time), new Date())}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="cardTopRight d-flex align-center" style={{ gap: "12px" }}>
                                                    <div className='d-flex align-center' style={{ gap: "5px" }}>
                                                        <div className="cardTopRightuserName d-flex align-center">
                                                            <span className='ri-user-3-line' style={{ fontSize: "16px" }}></span>
                                                            <span>{getBuildbyUser(item)}</span>
                                                        </div>
                                                        <div className="cardTopRightDate">
                                                            {moment(item.activity_status.start_time && item.activity_status.start_time).fromNow()}
                                                        </div>
                                                    </div>
                                                    <div className="cardTopRightBtn" onClick={() => onHandleBuildRefresh(type, item)}>
                                                        <span className='ri-refresh-line cursor-pointer d-flex align-center' style={{ fontSize: "20px" }}></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cardtopmiddleBar">
                                            </div>
                                        </div>
                                        {
                                            state.activity_list.length > 0 && state.selected_activity_id == item.activity_status?.id &&
                                            <div className={classes.activityDetalis} style={{ display: state.activity_list.length > 0 ? "flex" : "none" }}>
                                                <ActivityStepDetails
                                                    activityDetail={state.activity_list}
                                                    removeArrData={() => { removeArrData() }}
                                                    prev_state={{ "open_div": true }}
                                                    tableGird={true}
                                                    colSpan={9}
                                                    loading_step_status={state.loading_step_status}
                                                    task_id={item.global_task_id}
                                                    logUrl={"/logs?global_task_id=" + item.global_task_id +
                                                        "&tab_id=" + item.id +
                                                        "&service_name=" + service_name +
                                                        "&service_env=" + service_env +
                                                        "&service_type=" + service_env_type
                                                    }
                                                />
                                            </div>
                                            // <div className={classes.activityDetalis} style={{ display: state.activity_list.length > 0 ? "inline" : "none" }}>
                                            //     {state.activity_list.length > 0 && state.selected_activity_id == item.activity_status?.id ?
                                            //         <ActivityStepDetails
                                            //             activityDetail={getRightOrderedStep(state.activity_list)}
                                            //             removeArrData={() => { removeArrData() }}
                                            //             prev_state={{ "open_div": true }}
                                            //             tableGird={true}
                                            //             colSpan={9}
                                            //             loading_step_status={state.loading_step_status}
                                            //             task_id={item.global_task_id}
                                            //             logUrl={"/logs?global_task_id=" + item.global_task_id +
                                            //                 "&tab_id=" + item.id +
                                            //                 "&service_name=" + service_name +
                                            //                 "&service_env=" + service_env +
                                            //                 "&service_type=" + service_env_type
                                            //             }
                                            //         />
                                            //         : null}

                                            // </div>
                                        }
                                        <div className={classes.cardBottom}>
                                            <div className="cardBottomLeft">
                                                <div> <span>Artifact :    </span>
                                                    <Tooltip title={item.build_tag} placement='top'>
                                                        <span className='artifact'>{item.build_tag}</span>
                                                    </Tooltip>
                                                </div>
                                                {item.build_tag && <CopyToClipboard icon={"ri-file-copy-line"} data={item.build_tag} />}
                                                <div style={{ marginLeft: "15px" }}>
                                                    <span>Image Name :    </span>
                                                    {
                                                        item.type_label ?
                                                            <span className='artifact'>{item.type_label}</span>
                                                            :
                                                            <span className='artifact'>{serviceEnv?.image_name}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="cardBottomRight">


                                                <ParameterComponent
                                                    buildParameters={{ build_parameters: { ...item.build_args_key_value }, commit_sha: item?.commit_sha, commit_message: item?.commit_message }}
                                                    deployParameters={state?.last_deploy[0]?.deploy_args_key_value}
                                                />

                                                <span>
                                                    {getBtnBasedOnType(item)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                            }
                        </div>

                        {/* --------------when it is FAILED----------- */}
                        {
                            state.last_build && state.last_build.length > 0 && state.last_build.map((item) => {
                                return (
                                    item?.status === "FAILED" &&
                                    <div className={classes.allDetails} style={{ display: "none" }}>
                                        <h5 className='progressdatatitle'> Failed Build Details</h5>
                                        <div className={classes.card} style={{ height: state.activity_list.length > 0 ? "282px" : "128px" }}>
                                            {/* --cardtop-- */}
                                            <div className={classes.cardTop}>
                                                <div className='cardTopMainContainer'>
                                                    <div className="cardTopLeft d-flex align-center" style={{ gap: "10px" }}>
                                                        <span className='cardtitle'>
                                                            <a href={"/logs?global_task_id=" + item.global_task_id +
                                                                "&tab_id=" + item.id +
                                                                "&service_name=" + service_name +
                                                                "&service_env=" + service_env +
                                                                "&service_type=" + service_env_type +
                                                                "&tab_name=BUILD" +
                                                                "&num=" + item.build_number} target="_blank">
                                                                <span className="cursor-pointer ">Build #{item.build_number}</span>
                                                            </a>
                                                        </span>
                                                        <span className='new-status-chip new-failed'>
                                                            {item.activity_status.status == 'IN_QUEUE' ? 'IN QUEUE' : item.activity_status.status}
                                                        </span>
                                                        <span className='cardtimer'>
                                                            <span className='ri-time-line'></span>
                                                            <span>
                                                                {item.activity_status.status && item.activity_status.status != "RUNNING" ? getDuration(new Date(item.activity_status.start_time && item.activity_status.start_time), new Date(item.activity_status.end_time && item.activity_status.end_time)) == 0 ? "0s" : getDuration(new Date(item.activity_status && item.activity_status.start_time), new Date(item.activity_status.end_time && item.activity_status.end_time)) : getDuration(new Date(item.activity_status.start_time && item.activity_status.start_time), new Date())}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="cardTopRight d-flex align-center" style={{ gap: "12px" }}>
                                                        <div className='d-flex align-center' style={{ gap: "5px" }}>
                                                            <div className="cardTopRightuserName d-flex align-center" >
                                                                <span className='ri-user-3-line' style={{ fontSize: "16px" }}></span>
                                                                <span>{getBuildbyUser(item)}</span>
                                                            </div>
                                                            <div className="cardTopRightDate">
                                                                {moment(item.activity_status.start_time && item.activity_status.start_time).fromNow()}
                                                            </div>

                                                        </div>
                                                        <div className="cardTopRightBtn" onClick={() => onHandleBuildRefresh(type, item)}>
                                                            <span className='ri-refresh-line cursor-pointer d-flex align-center' style={{ fontSize: "20px" }}></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cardtopmiddleBar"></div>
                                            </div>

                                            <div className={classes.activityDetalis} style={{ display: state.activity_list.length > 0 ? "flex" : "none" }}>
                                                {

                                                    state.activity_list.length > 0 ?

                                                        <ActivityStepDetails
                                                            activityDetail={getRightOrderedStep(state.activity_list)}
                                                            removeArrData={() => { removeArrData() }}
                                                            prev_state={{ "open_div": true }}
                                                            tableGird={true}
                                                            colSpan={9}
                                                            loading_step_status={state.loading_step_status}
                                                            task_id={item.global_task_id}
                                                            logUrl={"/logs?global_task_id=" + item.global_task_id +
                                                                "&tab_id=" + item.id +
                                                                "&service_name=" + service_name +
                                                                "&service_env=" + service_env +
                                                                "&service_type=" + service_env_type
                                                            }
                                                        />

                                                        : null
                                                }
                                            </div>

                                            <div className={classes.cardBottom}>
                                                <div className="cardBottomLeft">
                                                    <div> <span>Artifact :    </span>
                                                        <Tooltip title={item.build_tag} placement='top'>
                                                            <span className='artifact'>{item.build_tag}</span>
                                                        </Tooltip>

                                                    </div>
                                                    {item.build_tag && <CopyToClipboard icon={"ri-file-copy-line"} data={item.build_tag} />}
                                                </div>
                                                <div className="cardBottomRight">

                                                    {/* <ParameterComponent buildParameters={state.last_build.build_args_key_value && state.last_build.build_args_key_value ? state.last_build.build_args_key_value : null}
                                                 deployParameters={state.last_deploy[0].deploy_args_key_value && state.last_deploy[0].deploy_args_key_value ? state.last_deploy[0].deploy_args_key_value : null}
                                               
                                                activity_master_id={state.component_id}
                                            /> */}
                                                    <ParameterComponent
                                                        buildParameters={state?.last_deploy[0]?.build_metadata}
                                                        deployParameters={state?.last_deploy[0]?.deploy_args_key_value}
                                                    />

                                                    <span>
                                                        {getBtnBasedOnType(item)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }


                        {/* ----------------------------------When it is In Queue------------------------------- */}
                        {state.in_queue_data && state.in_queue_data.length > 0 && (
                            <div className={classes.allDetails}>
                                <h5 className='progressdatatitle'>Queued Builds Detail</h5>
                                {/* mapping through the IN_QUEUE_DATA------ */}
                                {state?.in_queue_data?.reverse().map((item, index) => (
                                    <div className={classes.card} style={{ height: state?.activity_status ? "282px" : "128px" }} key={index}>
                                        <div className={classes.cardTop}>
                                            <div className="cardTopMainContainer">
                                                <div className="cardTopLeft d-flex align-center" style={{ gap: "10px" }}>
                                                    <span className='cardtitle'>
                                                        <a href={"/logs?global_task_id=" + item?.global_task_id +
                                                            "&tab_id=" + item?.id +
                                                            "&service_name=" + service_name +
                                                            "&service_env=" + service_env +
                                                            "&service_type=" + service_env_type +
                                                            "&tab_name=BUILD" +
                                                            "&num=" + item?.build_number} target="_blank">
                                                            <span className="cursor-pointer ">Build #{item?.build_number}</span>
                                                        </a>
                                                    </span>
                                                    <span className='new-status-chip new-queue' style={{ background: "#FCF6E1", color: "#FEA111" }}>
                                                        {/* {state.last_build.activity_status.status == 'IN_QUEUE' ? 'IN QUEUE' : state.last_build.activity_status.status} */}
                                                        {item?.activity_status?.status}
                                                    </span>
                                                    <button className='getInfoBtn d-flex align-center'>
                                                        GET INFORMATION
                                                    </button>
                                                </div>
                                                <div className="cardTopRight d-flex align-center" style={{ gap: "12px" }}>
                                                    <div className='d-flex align-center' style={{ gap: "5px" }}>
                                                        <div className="cardTopRightuserName d-flex align-center">
                                                            <span className='ri-user-3-line' style={{ fontSize: "16px" }}></span>
                                                            {/* <span>{getBuildbyUser(state.last_build)}</span> */}
                                                            <span>{item?.build_by_user}</span>
                                                        </div>
                                                        <div className="cardTopRightDate">
                                                            {moment(item?.activity_status.start_time && item?.activity_status.start_time).fromNow()}
                                                        </div>

                                                    </div>
                                                    <div className="cardTopRightBtn" onClick={() => onHandleBuildRefresh(type, item)}>
                                                        <span className='ri-refresh-line cursor-pointer d-flex align-center' style={{ fontSize: "20px" }}></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cardtopmiddleBar"></div>
                                        </div>
                                        <div className={classes.activityDetalis} style={{ display: state?.activity_status ? "flex" : "none" }}>
                                            {
                                                item.action_status ?
                                                    <ActivityStepDetails
                                                        activityDetail={getRightOrderedStep(state.activity_list)}
                                                        removeArrData={() => { removeArrData() }}
                                                        prev_state={{ "open_div": true }}
                                                        tableGird={true}
                                                        colSpan={9}
                                                        loading_step_status={state.loading_step_status}
                                                        task_id={item.global_task_id}
                                                        logUrl={"/logs?global_task_id=" + item.global_task_id +
                                                            "&tab_id=" + item.id +
                                                            "&service_name=" + service_name +
                                                            "&service_env=" + service_env +
                                                            "&service_type=" + service_env_type
                                                        }
                                                    />

                                                    : null
                                            }
                                        </div>
                                        <div className={classes.cardBottom}>
                                            <div className="cardBottomLeft">
                                                <div> <span>Artifact :    </span>
                                                    <Tooltip title={item?.build_tag} placement='top'>
                                                        <span className='artifact'>{item?.build_tag}</span>
                                                    </Tooltip>
                                                </div>
                                                {item?.build_tag && <CopyToClipboard icon={"ri-file-copy-line"} data={item?.build_tag} />}
                                            </div>
                                            <div className="cardBottomRight">
                                                <ParameterComponent
                                                    buildParameters={state?.last_deploy[0]?.build_metadata}
                                                    deployParameters={state?.last_deploy[0]?.deploy_args_key_value}
                                                />

                                                <span>
                                                    <>
                                                        {item && item.activity_status ?
                                                            <span>
                                                                <div className="round-btn-group d-flex align-center justify-center">
                                                                    <>
                                                                        <Tooltip title="View Activity Details">

                                                                            <button className="btn-sq-icon-primary d-flex align-center mr-5"
                                                                                onClick={() => { getActivityCurrentInfo(item.activity_status.id ? item.activity_status.id : "") }} >
                                                                                <span className='ri-list-check-2'>

                                                                                </span>
                                                                            </button>
                                                                        </Tooltip>
                                                                    </>
                                                                    {
                                                                        is_permitted_build ?
                                                                            <>
                                                                                <Tooltip title="Rebuild">
                                                                                    {
                                                                                        is_env_down ?
                                                                                            <button
                                                                                                className={item.activity_status.status ? getClassbyActivityStatus(item.activity_status.status, "Rebuild") : null}
                                                                                                onClick={handleClickOpenPopover}
                                                                                            >
                                                                                                <span class="ri-flashlight-line" >

                                                                                                </span>
                                                                                            </button>
                                                                                            :
                                                                                            <button

                                                                                                className={item?.activity_status.status ? getClassbyActivityStatus(item?.activity_status.status, "Rebuild") : null}
                                                                                                onClick={
                                                                                                    item?.activity_status.status ?
                                                                                                        item?.activity_status.status == "FAILED" || item?.activity_status.status == "SUCCESS" || item.activity_status.status == "REVOKED" ? () => { validateAndTrigger("BUILD", item) } : () => { } : () => { }}
                                                                                            >
                                                                                                <span class="ri-flashlight-line">

                                                                                                </span>
                                                                                            </button>}
                                                                                </Tooltip>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Tooltip title="Rebuild">
                                                                                    <button
                                                                                        className={"btn-disabeld-new"}>
                                                                                        <span class="ri-flashlight-line">

                                                                                        </span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </>
                                                                    }
                                                                    {
                                                                        item.build_by_pipeline ||
                                                                            (item?.activity_status.status && item?.activity_status.status != "FAILED") ?
                                                                            <>
                                                                                <Tooltip title="Resume">
                                                                                    <button
                                                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 btn-disabeld-new"}
                                                                                    >
                                                                                        <span class="ri-play-circle-line">

                                                                                        </span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </> :
                                                                            <>
                                                                                <Tooltip title="Resume">
                                                                                    <button
                                                                                        className={item?.activity_status.status ? getClassbyActivityStatus(item?.activity_status.status, "Resume") : "btn-sq-icon-primary d-flex align-center mr-5 btn-disabled-new"}
                                                                                        // className='btn-sq-icon-primary d-flex align-center mr-5'
                                                                                        onClick={item?.activity_status.status ? () => callResumeFunctionbyStatus(item?.activity_status.status, item.id) : null}
                                                                                    >
                                                                                        <span class="ri-play-circle-line">

                                                                                        </span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </>
                                                                    }

                                                                    {
                                                                        <>
                                                                            <Tooltip title="Workspace">
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: `/application/${application_id}/service/${service_id}/build/${item?.id}/workspace/`,
                                                                                        search: new URLSearchParams({
                                                                                            service_name: service_name,
                                                                                            env_name: env_name,
                                                                                            global_task_id: item?.global_task_id,
                                                                                            env_id: env_id,
                                                                                            action_number: workspaceParams.action_number,
                                                                                            action_status: workspaceParams.action_status,
                                                                                            action_by: build_by_user,
                                                                                            image: workspaceParams.image
                                                                                        }).toString()
                                                                                    }}
                                                                                    target='_blank'
                                                                                >
                                                                                    <button className="btn-sq-icon-primary d-flex align-center mr-5">
                                                                                        <span
                                                                                            class="ri-artboard-2-line"

                                                                                        >

                                                                                        </span>
                                                                                    </button>
                                                                                </Link>
                                                                            </Tooltip>
                                                                        </>
                                                                    }
                                                                    {
                                                                        item?.activity_status.status ?

                                                                            <>
                                                                                <Tooltip title={item?.build_by_pipeline ? "This task has been triggered by a pipeline, please revoke the pipeline in order to revoke the task" : "Revoke Build"}>
                                                                                    <button
                                                                                        className={item.activity_status.status && (!item.build_by_pipeline) ? getClassbyActivityStatus(item?.activity_status.status, "Revoke") : "btn-sq-icon-primary d-flex align-center mr-5 revoke-btn btn-disabled-new"}
                                                                                        // className='btn-sq-icon-primary d-flex align-center mr-5 revoke-btn'
                                                                                        onClick={item?.activity_status.status == "IN_QUEUE" || item?.activity_status.status == "RUNNING" && (!item?.build_by_pipeline) ? () => callFunctionbyStatus(item.activity_status.status, item.id) : () => { }}
                                                                                    >
                                                                                        <span class="ri-stop-circle-line" >

                                                                                        </span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </> :
                                                                            <>
                                                                                <Tooltip title="Revoke Build">
                                                                                    <button
                                                                                        className={"btn-sq-icon-primary d-flex align-center mr-5 revoke-hover btn-disabled-new"}

                                                                                    >
                                                                                        <span class="ri-stop-circle-line" style={{ color: "#C11212" }}>

                                                                                        </span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </span> : <span></span>
                                                        }

                                                    </>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>)}


                        {/* -----------------------------------When it is in SUCCESS------------------------------------- */}
                        {/* {state.last_build.status === "SUCCESS" &&
                            <div className={classes.allDetails} >
                                <h5 className='progressdatatitle'>Completed Builds Details</h5>

                                <div className={classes.card} style={{ marginBottom: "20px", height: state.activity_list.length > 0 ? "282px" : "128px" }}>
                                    <div className={classes.cardTop}>
                                        <div className="cardTopMainContainer">
                                            <div className="cardTopLeft d-flex align-center" style={{ gap: "10px" }}>
                                                <span className='cardtitle'>
                                                    <a href={"/logs?global_task_id=" + state.last_build.global_task_id +
                                                        "&tab_id=" + state.last_build.id +
                                                        "&service_name=" + service_name +
                                                        "&service_env=" + service_env +
                                                        "&service_type=" + service_env_type +
                                                        "&Tab_name=BUILD" +
                                                        "&num=" + state.last_build.build_number} target="_blank">
                                                        <span className="cursor-pointer ">Build #{state.last_build.build_number}</span>
                                                    </a>
                                                </span>
                                                <span className='new-status-chip new-success' style={{ background: "#E6FBEA", color: "#129E5B" }}

                                                >
                                                    {state.last_build.activity_status.status == 'IN_QUEUE' ? 'IN QUEUE' : state.last_build.activity_status.status}
                                                </span>
                                                <span className='cardtimer'>
                                                    <span className='ri-time-line'></span>
                                                    <span>
                                                        {state.last_build.activity_status.status && state.last_build.activity_status.status != "RUNNING" ? getDuration(new Date(state.last_build.activity_status.start_time && state.last_build.activity_status.start_time), new Date(state.last_build.activity_status.end_time && state.last_build.activity_status.end_time)) == 0 ? "0s" : getDuration(new Date(state.last_build.activity_status && state.last_build.activity_status.start_time), new Date(state.last_build.activity_status.end_time && state.last_build.activity_status.end_time)) : getDuration(new Date(state.last_build.activity_status.start_time && state.last_build.activity_status.start_time), new Date())}
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="cardTopRight d-flex align-center" style={{ gap: "12px" }}>
                                                <div className='d-flex align-center' style={{ gap: "5px" }}>
                                                    <div className="cardTopRightuserName d-flex align-center">
                                                        <span className='ri-user-3-line' style={{ fontSize: "16px" }}></span>
                                                        <span>{getBuildbyUser(state.last_build)}</span>
                                                    </div>
                                                    <div className="cardTopRightDate">
                                                        {moment(state.last_build.activity_status.start_time && state.last_build.activity_status.start_time).fromNow()}
                                                    </div>

                                                </div>
                                                <div className="cardTopRightBtn" onClick={() => onHandleBuildRefresh(type)}>
                                                    <span className='ri-refresh-line cursor-pointer d-flex align-center' style={{ fontSize: "20px" }}></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cardtopmiddleBar"></div>
                                    </div>
                                    <div className={classes.activityDetalis} style={{ display: state.activity_list.length > 0 ? "flex" : "none" }}>
                                        {

                                            state.activity_list.length > 0 ?

                                                <ActivityStepDetails
                                                    style={{}}
                                                    activityDetail={state.activity_list}
                                                    removeArrData={() => { removeArrData() }}
                                                    prev_state={{ "open_div": true }}
                                                    tableGird={true}
                                                    colSpan={9}
                                                    loading_step_status={state.loading_step_status}
                                                    task_id={state.last_build.global_task_id}
                                                    logUrl={"/logs?global_task_id=" + state.last_build.global_task_id +
                                                        "&tab_id=" + state.last_build.id +
                                                        "&service_name=" + service_name +
                                                        "&service_env=" + service_env +
                                                        "&service_type=" + service_env_type
                                                    }
                                                />

                                                : null
                                        }
                                    </div>

                                    <div className={classes.cardBottom}>
                                        <div className="cardBottomLeft">
                                            <div> <span>Artifact :    </span>
                                                <Tooltip title={state.last_build.build_tag} placement='top'>
                                                    <span className='artifact'>{state.last_build.build_tag}</span>
                                                </Tooltip>
                                            </div>
                                            {state.last_build.build_tag && <CopyToClipboard icon={"ri-file-copy-line"} data={state.last_build.build_tag} />}
                                        </div>
                                        <div className="cardBottomRight">

                                            <ParameterComponent
                                                buildParameters={state?.last_deploy[0]?.build_metadata}
                                                deployParameters={state?.last_deploy[0]?.deploy_args_key_value}
                                            />


                                            <span>
                                                {getBtnBasedOnType(item)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } */}

                        <Popover
                            id={id}
                            open={OpenPopover}
                            anchorEl={anchorEl}
                            onClose={handleClickClosePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div className="popover-content" style={{ padding: '20px', width: '450px', }}>

                                <div className='' style={{ width: '100%', position: 'relative' }}>
                                    <button className="btn btn-transparent btn-icon" style={{ position: "absolute", right: '10px', top: '0px' }} onClick={handleClickClosePopover}><i className='ri-close-line font-18' style={{ color: '#0086ff', }} /></button>
                                    <i className="ri-information-line font-48 mb-20 text-center" style={{ color: '#0086ff', width: '100%' }} />
                                    <p className='font-18 font-weight-bold text-center mb-5' style={{ color: '#0086ff', width: '100%' }}>ENVIRONMENT IS OFFLINE</p>
                                    <p className='font-12 font-weight-400 text-center' style={{ color: '#787878', width: '100%' }}>Please note you cannot trigger build as the envrionment is offline now.
                                        Please contact your team administrator to get the environment online.</p>
                                </div>

                            </div>
                        </Popover>
                        <div className='z-index-top'>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(state.trigger === "success" || state.trigger === "failed")}
                                onClose={handleClose}
                                sx={{
                                    zIndex: 1400,
                                    '& .MuiPaper-root': {
                                        backgroundColor: (theme) =>
                                            state.trigger === "success"
                                                ? theme.palette.success.main
                                                : state.trigger === "failed"
                                                    ? theme.palette.error.main
                                                    : theme.palette.background.default,
                                        width: 'auto !important',
                                        boxShadow: 3,
                                        borderRadius: 1,
                                    },
                                }}
                            >
                                {state.trigger === "success" ? (
                                    <Alert
                                        severity="success"
                                        variant="filled"
                                        onClose={handleClose}
                                        sx={{
                                            width: '100%',
                                            '& .MuiAlert-icon': {
                                                fontSize: '2rem', // Adjust icon size
                                            },
                                            '& .MuiAlert-message': {
                                                fontSize: '12px',
                                            },
                                            '& .MuiAlert-action': {
                                                marginRight: 0, // Ensure close button aligns properly
                                            },
                                        }}
                                    >
                                        {state.rebuild_status ? state.rebuild_status : null}
                                    </Alert>
                                ) : state.trigger === "failed" ? (
                                    <Alert
                                        severity="error"
                                        variant="filled"
                                        onClose={handleClose}
                                        sx={{
                                            width: '100%',
                                            '& .MuiAlert-icon': {
                                                fontSize: '2rem', // Adjust icon size
                                            },
                                            '& .MuiAlert-message': {
                                                fontSize: '12px',
                                            },
                                            '& .MuiAlert-action': {
                                                marginRight: 0, // Ensure close button aligns properly
                                            },
                                        }}
                                    >
                                        {type + " SCHEDULE FAILED"}
                                        <div className="error-div">
                                            {state.rebuild_status ? state.rebuild_status : null}
                                        </div>
                                    </Alert>
                                ) : null}
                            </Snackbar>
                        </div>
                    </div>
            }
        </>

    )
}
export default CurrentProgressDataNew;


const useStyles = makeStyles((theme) => ({

    allDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "18px",
        alignSelf: "stretch",
        borderRadius: "4px",
        gap: "10px",

        '& .progressdatatitle': {
            color: " #505050",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal"
        }

    },
    activityDetalis: {
        height: "150px",
        width: "100%",
        display: "flex",
        padding: "12px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch",
        borderRadius: "3px",
        border: "1px solid  #E6E6E6"
    },
    card: {
        height: "128px",
        width: "100%",
        display: "flex",
        padding: "16px 16px 20px 20px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "12px",
        alignSelf: "stretch",
        borderRadius: "4px",
        background: "#FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.08)"
    },
    cardTop: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch",

        "&  .cardTopMainContainer": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            "& .cardTopLeft": {
                display: "flex",

                "& .cardtitle": {
                    color: "#0086FF",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal"

                },
                "& .cardtimer": {
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    color: "#787878",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal"
                },

                "& .getInfoBtn": {
                    height: "28px",
                    width: "146px",
                    borderRadius: "6px",
                    padding: "8px 12px 8px 12px",
                    border: "1px solid #FEA111",
                    color: "#FEA111",
                    background: "#FFFBEB",
                    fontSize: "12px",
                    fontWeight: "600",
                    textTransform: "uppercase",
                    cursor: "pointer",

                    "&:hover": {
                        background: "#FEA111",
                        color: "#FFF"
                    }

                }
            },

            "& .cardTopRight": {



                "& .cardTopRightuserName": {
                    gap: "4px",
                    lineHeight: "14.63px",
                    fontSize: "12px",
                    color: "#787878"
                },
                "& .cardTopRightDate": {
                    lineHeight: "14.63px",
                    fontSize: "12px",
                    color: "#787878"
                },

                "& .cardTopRightBtn": {
                    cursor: "pointer",
                    padding: "1px 6px",
                    borderRadius: " 7px",
                    border: "1px solid 	rgba(128, 128, 128, 0)",
                    "&:hover": {
                        border: "1px solid 	#E6E6E6",
                    }
                }
            }
        },
        "& .cardtopmiddleBar": {
            width: "100%", height: "1px", background: "#E6E6E6"
        }


    },
    cardBottom: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
        "& .cardBottomLeft": {
            display: "flex",
            alignItems: "center",
            gap: "3px",
            lineHeight: "14.63px",
            fontSize: "12px",
            color: "#787878"
        },

        "& .cardBottomRight": {
            display: "flex",
            alignItems: "center",
            gap: "5px",
            // "& .smaple": {
            //     height: "36px",
            //     width: "91px",
            //     padding: "10px",
            //     fontSize: "12px",
            //     lineHeight: "14.63px",
            //     color: "#505050",
            //     border: "1px solid #E6E6E6",
            //     borderRadius: "5px",
            //     background: "transparent",
            //     color: "#505050",
            //     fontWeight: "500"
            // }
        },


    },
    bottomBar: {
        padding: "16px",
        width: "100%",
        height: "72px",
        background: "#FFF"
    }



}));    