import { useEffect, useRef } from "react";

const useSSEConnection = (isSSEActivated, is_tab_hidden, url, eventName, onUpdateSseFunction) => {
    console.log(url, 'url_view_123');

    const eventSourceRef = useRef(null); // Use ref instead of state

    useEffect(() => {
        if (!isSSEActivated || is_tab_hidden) {
            // Close connection if SSE is disabled or tab is hidden
            closeSSE();
        } else if (!eventSourceRef.current || eventSourceRef.current.readyState === 2) {
            // Create SSE only if it's not active
            loadSSE();
        }

        return () => {
            closeSSE(); // Cleanup on unmount
        };
    }, [isSSEActivated, is_tab_hidden]);

    function loadSSE() {
        if (eventSourceRef.current) {
            console.log("Closing existing eventSource before creating a new one");
            eventSourceRef.current.close();
        }

        const celeryEventSource = new EventSource(url);
        eventSourceRef.current = celeryEventSource;

        celeryEventSource.addEventListener(eventName, handlePipelineData);
    }

    const handlePipelineData = (e) => {
        let data = JSON.parse(e.data);
        onUpdateSseFunction(data);
        console.log('data_received', data);

    };

    console.log(eventSourceRef.current, 'event_source_outside')

    const closeSSE = () => {
        console.log(eventSourceRef.current, 'event_source_03');
        if (eventSourceRef.current?.readyState === 1) {
            console.log(eventSourceRef,'event_source_03_od');
            eventSourceRef.current.close();
            eventSourceRef.current = null;
        }
    };

    const createSSE = () => {
        if (isSSEActivated && (!eventSourceRef.current || eventSourceRef.current.readyState === 2)) {
            loadSSE();
        }
    };

    return { closeSSE, createSSE };
};

export default useSSEConnection;
