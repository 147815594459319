import { createTheme } from '@mui/material/styles';
import palette from './palette';
import overrides from './overrides';

const theme = createTheme({
  palette,
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif', 
  },
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  components:{
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '12px', 
          lineHeight:'15px',
          borderBottom: '1px solid #e6e6e6'
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px !important', 
          backgroundColor: '#234361 !important',
          fontFamily: "'Montserrat', sans-serif !important",
          padding: "8px 10px 8px 10px !important",
        },
        arrow: {
          color: '#234361 !important',
        }
      }
    },
  }
});

export default theme;