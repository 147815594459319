import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from "../../../serviceRevamp/add/ci_flow/SourceDetails";
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import { ErrorComponent } from '../../../utils/Error';

export default function CloneEnv(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const list = props.list;
    const variant = props.variant ? props.variant : null;
    const all_deployment_list = props.all_deployment_list ? props.all_deployment_list : [];
    const service_id = props.service_id;
    console.log(all_deployment_list, list, service_id, "all_deployment_list1111")
    console.log(service_id, "all_deployment_list1111")
    const [state, setState] = useState(prev_state ? prev_state : {
        data: {
        },
        error: {},
        validations: {
            env_clone: [VALIDATION_TYPE_REQUIRED]
        },
        list: [],
        allConfiguredCd:[],
        fetchError:false
    })

  
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    function onYesHandler() {
        const result = ValidateDataSet(state.data, state.validations)
        if (!result.valid) {
            setState(new_state => ({
                ...new_state,
                error: { ...result.error }
            }));
        }
        else {
            if(variant != "cloing_ci"){
                props.onCloneHandler(state.data.env_clone, state.data.env_deployment_clone)
            }else{
                props.onCloneHandler(state.data.env_clone, state.approved_ci_data_id)
            }
            
        }
    }


    useEffect(() => {
            if(state.data.env_clone!=undefined){
                if(variant!="cloing_ci"){
                    fetchCdData()
                }
                else{
                    fetchEnvCi()
                }
            }
    }, [state.data.env_clone])


    function fetchCdData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: state.data.env_clone }, properties.api.save_cd),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchCdDataSuccess, onFetchCdDataFail)
       
    }

    function onFetchCdDataSuccess(response) {
        console.log("component called ==========>")
       
        setState(new_state => ({
            ...new_state,
            allConfiguredCd: response && response.length > 0 ? response : [],
            fetchError:false
        }));
    }

    function onFetchCdDataFail(error) {
        setState(new_state => ({
            ...new_state,
            fetchError:error
        }));
    }

    console.log(state.data.env_clone,"adagdagdf =========>")

    function fetchEnvCi() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: state.data.env_clone }, properties.api.save_ci),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchEnvCiDataSuccess, onFetchEnvCiDataFail)
    }


    function onFetchEnvCiDataSuccess(response) {
        console.log(response, "rtyudfgcv ================>")

        setState(new_state => ({
            ...new_state,
            ci_data: response,
            approved_ci_data_id : getApprovedCiDataId(response)
        }));
    }

    function onFetchEnvCiDataFail() {
       
    }

    function getApprovedCiDataId(ci_data){
        var ci_id;
        ci_data && ci_data.length > 0 && ci_data.forEach((item) => {
            ci_id = item.id
        })
        return ci_id;
    }

    function fetchEnvCd() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: state.data.env_clone }, properties.api.save_cd),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchEnvCdDataSuccess, onFetchEnvCdDataFail)
    }


    function onFetchEnvCdDataSuccess(response) {
        console.log(response, "rtyudfgcv ================>")

        setState(new_state => ({
            ...new_state,
            cd_data: response,
            approved_cd_data_id : getApprovedCDDataId(response)
        }));
    }

    function onFetchEnvCdDataFail() {
       
    }

    function getApprovedCDDataId(cd_data){
        var cd_id;
        
        cd_data && cd_data.length > 0 && cd_data.forEach((item) => {
            cd_id = item.id
        })
        return cd_id;
    }

    console.log(state,"env clone ============>")

    return (
        <div className={classes.root}>
            <div className="card border-none">
                <div className="card-header border-none bg-white" style={{ borderTop: 'none' }}>
                    {/* <p>Do you want to clone from any existing env</p> */}
                    
                </div>
                {
                    state.fetchError?
                    <ErrorComponent error={state.fetchError}/> : <>
                       <div className="card-body border-none pd-10 mb-22">
                    {
                        variant == "cloing_ci" ?
                            <>
                                <Input
                                    type="select"
                                    label="You can clone from these availaible Envs."
                                    name="env_clone"
                                    data={state.data}
                                    list={list ? list : []}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                />
                            </>
                            :
                            <>
                                <Input
                                    type="select"
                                    label="You can clone from these availaible Envs."
                                    name="env_clone"
                                    data={state.data}
                                    list={list ? list : []}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                />
                                <Input
                                    type="select"
                                    label="Deployment"
                                    name="env_deployment_clone"
                                    data={state.data}
                                    list={state.allConfiguredCd?.map(item=>{
                                        return { label: item.deployment_name, id: item.id }
                                    })}
                                    // list={all_deployment_list && all_deployment_list.length > 0 ? all_deployment_list.map(item => {
                                    //     return { label: item.label, id: item.deployment_id }
                                    // }) : []}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                />
                            </>
                    }

                </div>
                <div className="card-footer justify-flexend border-none btn-section bg-white">
                    <button className="btn btn-outline-grey" onClick={() => { props.onCloneHandler() }}> Cancel </button>
                    <button className="btn btn-primary" onClick={onYesHandler}> Next </button>
                </div>
                    </>
                }
             
            </div>
        </div>
    );
}
CloneEnv.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


const useStyles = makeStyles(theme => ({
    root: {
        '& .card': {
            textAlign: 'left',
            boxShadow: 'none',
            minWidth: '600px',
            '& .card-header': {
                paddingBottom: '10px!important',
                fontSize: '13px'
            },
            '& button': {
                fontSize: '12px',
                color: '#546e7a',
                display: 'inherit',
                lineHeight: 'auto',
                border: 'none',
                height: 'auto',
                width: "auto",
                // backgroundColor: 'transparent',
                margin: 'inherit',
                borderRadius: '4px'
            }
        },
    },
}))