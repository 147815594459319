import React, { useEffect, useState, useRef } from 'react'
import { styled } from "@mui/system";
import { Input } from '../../../../components/genericComponents/Input';
import { Loading } from '../../../utils/Loading';
import AlertStrip from '../../../../components/AlertStrips';

const IntegrationAndProject = ({
    data,
    onDataUpdate,
    all_ticketing_integrations,
    ticketing_projects,
    loading_ticketing_integrations,
    loading_ticketing_integrations_projects,
    error_msg_in_loading_projects,
    error_msg_in_integration_settings,
    error,
    loadRelevantProjects,
    loadAdoRelatedStates,
    app_config,
    integration_type,
    clearErrorState, ...props }) => {
console.log(ticketing_projects,"ticketing_projectsticketing_projects",loading_ticketing_integrations_projects)
    const [state, setState] = useState({
        data: data || {},
        error: error || {},
        all_ticketing_integrations: [],
    });
    const previousData = useRef(data);
    useEffect(() => {
        if (JSON.stringify(previousData.current) !== JSON.stringify(data)) {
            setState(prevState => ({
                ...prevState,
                data: data,
            }));
            previousData.current = data;
        }
    }, [data]);

    useEffect(() => {
        console.log(data, previousData.current, state.data, "fdjkasdfsajkfjds");
        if (JSON.stringify(previousData.current) !== JSON.stringify(state.data)) {
            onDataUpdate(state.data);
            previousData.current = state.data;
        }
    }, [state.data, onDataUpdate]);


    useEffect(() => {
        if (error && Object.keys(error).length > 0) {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...error
                }
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                error: {}
            }));
        }
    }, [error]);





    const onChangeHandler = (e) => {
        const k = e.target.name;
        const v = e.target.value;
        let filtered_data = all_ticketing_integrations.find(item => Number(item.id) === Number(v));
        console.log(filtered_data,"filtered_datafiltered_data")
        // if(filtered_data && filtered_data.type === "jira"){
            loadRelevantProjects(v);
        // }
        
        clearErrorState(k)
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
                selected_integration: filtered_data && filtered_data.label ? filtered_data.label : "N/A",
                ['project']:''
            },
            error: {}
        }));
    }

    const onChangeHandlerProjects = (e) => {
        const k = e.target.name;
        const v = e.target.value;

        let filtered_project = ticketing_projects.find(item => item.id === v)
        clearErrorState(k);
        if(integration_type === "azure_devops"){
            loadAdoRelatedStates(state.data.selected_integration, filtered_project.label)
        }
        
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
                selected_project: filtered_project && filtered_project.label,

            },
            error: {}
        }));
    }
    const onChangeHandlerInput = (e) => {
        var k = e.target.name;
        var v = e.target.value
        v = v.replace(/\W+(?!$)/g, '-');
        v = v.replace(/\W$/, '-');
        v = v.replace('_', '-').toLowerCase();
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
            },
            error: {
                ...new_state.error,
                [k]: "",
            }
        }));
    }

    return (
        <Root>
            <p className='d-flex align-center mb-24'>
                <span className='font-20 font-weight-600'>Select Ticketing Integration and Projects</span>&nbsp;&nbsp;<span className='ri-information-line font-24 color-secondary'></span>
            </p>
            {
                !app_config &&
                <Input
                type="text"
                name="name"
                placeholder="Enter Name"
                label={"Name"}
                mandatorySign
                info={"Jira Integration"}
                data={state.data}
                error={state.error}
                onChangeHandler={onChangeHandlerInput}
            />

            }
           
            {
                loading_ticketing_integrations ?
                    <Loading varient="light" /> :
                    error_msg_in_integration_settings ?
                        <AlertStrip variant={"error"} message={error_msg_in_integration_settings} /> :
                        all_ticketing_integrations.length === 0 ?
                            <AlertStrip variant={"info"} message={"There are no ticketing integration available"} /> :
                            <Input
                                type="select"
                                list={all_ticketing_integrations}
                                name="integration"
                                label={"Ticketing Integration"}
                                info={"Jira Integration"}
                                data={state.data}
                                error={state.error}
                                mandatorySign
                                onChangeHandler={onChangeHandler}
                            />
            }

            {

                loading_ticketing_integrations_projects ?
                    <Loading varient="light" /> :
                    error_msg_in_loading_projects ?
                        <AlertStrip variant={"error"} message={error_msg_in_loading_projects} /> :
                        <Input
                            type="select"
                            list={ticketing_projects}
                            name="project"
                            label={"Project"}
                            mandatorySign
                            info={"Jira Integration"}
                            data={state.data}
                            error={state.error}
                            onChangeHandler={ticketing_projects && ticketing_projects.length === 0 ? null : onChangeHandlerProjects}
                        />
            }

        </Root>
    )
}
const Root = styled('div')({
    marginBottom: '24px'
})

export default IntegrationAndProject