import React, { useState } from 'react';
import { SwitchToSuperAdminRole, SwitchToNormalUserRole, IsUserActualSuperUser, IsSuperUser, GetPermitedURLsAfterSwitch } from '../../util/security';
import { Navigate, useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';

const SwitchUser = () => {
    const [redirectData, setRedirectData] = useState(false); 
    const navigate =  useNavigate()
    const handleSwitchToUserPortal = () => {
        SwitchToNormalUserRole();
        GetPermitedURLsAfterSwitch();
        setRedirectData(true); 
    };

    const handleSwitchToAdminPortal = () => {
        SwitchToSuperAdminRole();
        GetPermitedURLsAfterSwitch();
        setRedirectData(true); 
    };

    const isSuperAdmin = IsUserActualSuperUser();
    const isAdminMode = IsSuperUser();

    if (redirectData) {
        return navigate('/')
    }

    return (
        <>
        {
            redirectData ? 
            navigate('/') 
            :
            <div className="font-family-v1 font-12 font-weight-500">
            <div
                style={{
                    color: isSuperAdmin && isAdminMode ? '#0086ff' : '#2EBE79',
                    fontSize: '11px',
                    fontWeight: 600,
                    backgroundColor: isSuperAdmin && isAdminMode ? '#EBF5FF' : '#E6FBEA',
                    display: 'inline-block',
                    borderRadius: '30px',
                    padding: '4px 8px',
                    marginBottom: '6px',
                }}
            >
                {isSuperAdmin && isAdminMode ? 'Super Admin' : 'User Portal'}
            </div>

            {isSuperAdmin && isAdminMode ? (
                <button
                    className="btn btn-transparent d-flex align-center"
                    style={{ padding: '0px' }}
                    onClick={handleSwitchToUserPortal}
                >
                    <span className="ri-refresh-line font-20 color-primary"></span>
                    <span className="font-12 font-weight-500"> &nbsp;Switch to User Portal</span>
                </button>
            ) : isSuperAdmin ? (
                <button
                    className="btn btn-transparent d-flex align-center"
                    style={{ padding: '0px' }}
                    onClick={handleSwitchToAdminPortal}
                >
                    <span className="ri-refresh-line font-20 color-primary"></span>
                    <span className="font-12 font-weight-500"> &nbsp;Switch to Super Admin</span>
                </button>
            ) : (
                <Tooltip title="The user doesn't have super admin access.">
                    <button
                        className="btn btn-transparent d-flex align-center"
                        style={{
                            padding: '0px',
                            color: '#2f2f2f',
                            border: 'none',
                            cursor: 'not-allowed',
                        }}
                        disabled
                    >
                        <span className="ri-refresh-line font-20 color-primary"></span>
                        <span className="font-12 font-weight-500"> &nbsp;Switch to Super Admin</span>
                    </button>
                </Tooltip>
            )}
        </div>
        }
        </>
        
    );
};

export default SwitchUser;
