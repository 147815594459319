import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
export const ErrorComponent = (props) => {
    const classes = useStyles();
    var error = props.error ? props.error : {};
    var url = props.url ? props.url : {};
    var url_name = props.url_name ? props.url_name : {}
    var blank_note = props.blank_note ? props.blank_note : {};
    const variant = props.variant ? props.variant : null;
    let status
    if (typeof (error) === "object") {
        status = error.status ? error.status : null
        error = error.error ? error.error : error;
    }

    console.log(status, 'status_of_error')
    return (
        <>
            {
                status == '403' ?
                    <>
                        {

                            (variant === 'error-box' || variant === 'msg-box') ?
                                <div className={classes.root}>
                                    <div className={classes.errorCard + " " + "border-red"}>
                                        <div>
                                            {typeof (error) == "object" ? null : <h1 className="error-msg-service-card">{error ? error.toString() : "Something Went Wrong"}</h1>}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='error-msg-403'>
                                    {typeof (error) == "object" ? null : <h1 className="error-msg">{error ? error.toString() : "Something Went Wrong"}</h1>}
                                </div>
                        }

                    </>

                    :
                    variant === "error-box" ?
                        <div className={classes.root}>
                            <div className={classes.errorCard}>
                                <div>
                                    <p className="common-error"> "Oh!! We regret the inconvenience!! Something is not ok, please refresh after sometime or contact the administrator!!" </p>
                                    {typeof (error) == "object" ? null : <h1 className="error-msg-service-card">{error ? error.toString() : "Something Went Wrong"}</h1>}
                                </div>
                            </div>
                        </div>
                        :
                        variant === "message-box" ?
                            <div className={classes.root}>
                                <div className={classes.errorCard}>
                                    <div>
                                        <p className="common-error">The analysis has just started.Data not generated yet.Please wait...</p>
                                    </div>
                                </div>
                            </div>
                            : variant == "msg-box" ?
                                <div className={classes.root}>
                                    <div className={classes.errorCard + " " + "border-red"}>
                                        <div>
                                            {typeof (error) == "object" ? null : <h1 className="error-msg-service-card">{error ? error.toString() : "Something Went Wrong"}</h1>}
                                        </div>
                                    </div>
                                </div>
                                :
                                variant == "blank-box" ?
                                    <div className="main-container-error" style={{ height: '60vh', margin: 'auto' }}>
                                        <div className="svg">
                                            <div className="servicePanel">
                                                <div className="blank-div">
                                                    <div className="blank-div-text">
                                                        {blank_note}
                                                    </div>
                                                    <div>
                                                        <Link to={url} className="btn btn-submit">
                                                            <div >{url_name}</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {
                                            typeof (error) == "object" ?
                                                <>{Array.isArray(error) ?
                                                    <>
                                                        {error.map(error_element => (
                                                            <> {"  ** " + error_element.toString()}</>
                                                        ))

                                                        }
                                                    </> : null}</>
                                                : <h1 className="error-msg-service-card">{error ? error.toString() : "Something Went Wrong"}</h1>
                                        }
                                    </>
            }
        </>
    );
}
ErrorComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorCard: {
        width: '350px',
        height: '250px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        border: '1px solid #dedede',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .common-error': {
            fontSize: '14px',
            lineHeight: 1.2,
            textAlign: 'left',
            marginBottom: '10px'
        },
        '& .error-msg-service-card': {
            fontSize: '12px',
            backgroundColor: 'transparent',
            border: '0px',
            lineHeight: 1.2,
            textAlign: 'left',
            wordBreak: 'break-all'
        }
    }
}))