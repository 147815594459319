import React from 'react'
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import Delete from '../../../../components/genericComponents/Delete';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
const ConfiguredSettingsCard = (props) => {
    const data = props.data ? props.data : null;
    const configuration_name = props.configuration_name ? props.configuration_name : null;
    const default_configuration = props.default;
    const default_configuration_id = props.default_configuration_id;
    const refreshFun = props.refreshFun
    console.log(data, "datadatadatadatadatadata")
    return (
        <ConfigurationCard>
            <div className='d-flex align-center space-between'>
                <div className='color-secondary d-flex align-center'>
                    <span className='ri-settings-3-line font-20 '></span>
                    <p className='font-weight-700 font-16' style={{ marginLeft: '8px' }}>{configuration_name}</p>
                </div>
                {
                    default_configuration ?
                        <span className='chip chip-primary-outline font-weight-700 font-12'>DEFAULT</span>
                        :
                        <span className='chip chip-success-outline font-weight-700 font-12'>APP CONFIG</span>
                }
            </div>
            <div className='' style={{ height: '1px', backgroundColor: '#E3E3E3', margin: '16px 0px' }}></div>
            <div className='d-flex align-center space-between'>
                <p className='font-14 font-weight-500 color-tertiary mb-16'>
                    Issue status Validation:
                </p>
                <p className='font-14 font-weight-600 color-tertiary'>
                   {Object.keys(data.issue_type_validation).length > 0 ? "Yes" : "No"} 
                </p>
            </div>
            <div className='d-flex align-center space-between'>
                <p className='font-14 font-weight-500 color-tertiary'>
                    Approval Comment Validation:
                </p>
                <p className='font-14 font-weight-600 color-tertiary'>
                {Object.keys(data.approve_comment_validation).length > 0 ? "Yes" : "No"} 
                </p>
            </div>
            <div className='' style={{ height: '1px', backgroundColor: '#E3E3E3', margin: '16px 0px' }}></div>
            <div className='d-flex align-center justify-end'>
                {
                    default_configuration ?
                        <Link to={"/release-package/update/default-configuration/" + default_configuration_id + "/"} >
                            <button className='btn' style={{ padding: '' }}><span className='ri-edit-line font-20 color-tertiary'></span></button>
                        </Link>
                        :
                        <Link to={"/release-package/update/app-configuration/" + data.id + "/"} >
                            <button className='btn' style={{ padding: '' }}><span className='ri-edit-line font-20 color-tertiary'></span></button>
                        </Link>
                }
                <Delete
                    varient={"rp_config_delete"}
                    data={{ entity_id: data.id, name: 'application_config' }}
                    refresh={refreshFun}
                    api_link={GenerateURL({ edit_id: data.id }, properties.api.release_mgmt_config_edit)}
                />
                {/* <button className='btn mr-0'><span className='ri-delete-bin-7-line font-20 color-tertiary'></span></button> */}
            </div>
        </ConfigurationCard>
    )
}

const ConfigurationCard = styled('div')({
    border: '1px solid #e6e6e6',
    borderRadius: '6px',
    padding: '16px 12px',
    '& .chip': {
        padding: '2px 4px'
    },
    '& .btn': {
        padding: '5px 3px',
        '&:hover': {
            backgroundColor: '#f4f4f4',
            borderRadius: '14px',
            cursor: 'pointer',
            color: '#2f2f2f'
        }
    }
})
export default ConfiguredSettingsCard