import React, { useEffect, useState } from 'react'
import { styled } from "@mui/system";
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { getJiraTypeIcon } from '../../../sprintView/components/JiraTicket';
import AlertStrip from '../../../../components/AlertStrips';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';
import InputSkeleton from '../../../../components/genericComponents/Skeletons/InputSkeleton';


const IssueTypeValidation = ({ data,
    onDataUpdate,
    jira_status_list,
    jira_issue_type,
    loading_issue_type,
    loading_available_status,
    error_in_loading_issue_type,
    error_in_loading_status,
    integration_type,
    loading_ado_issues_and_status,
    error_in_loading_ado_issues,
    ado_issue_type_and_status,
}) => {

    console.log(ado_issue_type_and_status,"ado_issue_type_and_status")
    const [state, setState] = useState({
        data: data || {
            issue_type_validation: 'no'
        },
        error: {}
    })
    const commonFunctions = getCommonFunctions(state, setState, {})

    useEffect(() => {
        if (JSON.stringify(state.data) !== JSON.stringify(data)) {
            onDataUpdate(state.data);
        }
    }, [state.data, data, onDataUpdate]);

    console.log(integration_type, "integration_typeintegration_type")
    return (
        <Root>
            <p className='d-flex align-center mb-24'>
                <span className='font-20 font-weight-600'>Is Issue type status validation required?</span>&nbsp;&nbsp;<span className='ri-information-line font-24 color-secondary'></span>
            </p>

            <Input
                type="radio"
                list={[{ label: "Yes", value: 'yes' }, { label: "No", value: 'no' }]}
                name="issue_type_validation"
                label={""}
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
            />
            {state.data.issue_type_validation === "yes" ?
                integration_type === "azure_devops" ?
                    loading_ado_issues_and_status ?
                        <>
                            <div className='' style={{ border: '1px solid #e6e6e6', borderRadius: '6px' }}>
                                <div className=''>
                                    <div className='font-weight-700 color-secondary' style={{ padding: '16px 10px', borderBottom: '1px solid #e6e6e6' }}>
                                        Configure valid status for release
                                    </div>
                                    <div className='if-yes-div'>
                                        {Array.from({ length: 5 }).map((_, index) => {
                                            return (
                                                <div className='d-grid row-data-ticket' style={{ gridTemplateColumns: '200px 1fr', gap: '20px' }}>
                                                    <div className='d-grid align-center'>
                                                        <GenericSkeleton variant={"text"} width={"100%"} />
                                                    </div>
                                                    <div className='mb-0'>
                                                        <InputSkeleton type={"text"} width={"100%"} style={{ borderRadius: "4px" }} />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        error_in_loading_ado_issues ?
                            <AlertStrip variant={'error'} message={error_in_loading_ado_issues ? error_in_loading_ado_issues : "Unkown Error"} />
                            :
                            ado_issue_type_and_status && Object.keys(ado_issue_type_and_status).length > 0 ?
                                <>
                                    <div className='' style={{ border: '1px solid #e6e6e6', borderRadius: '6px' }}>
                                        <div className=''>
                                            <div className='font-weight-700 color-secondary' style={{ padding: '16px 10px', borderBottom: '1px solid #e6e6e6' }}>
                                                Configure valid status for release
                                            </div>
                                            <div className='if-yes-div'>
                                                {
                                                    Object.keys(ado_issue_type_and_status).map(item => {
                                                        return (
                                                            <div className='d-grid row-data-ticket' style={{ gridTemplateColumns: '200px 1fr', gap: '20px' }}>
                                                                <div className='d-grid align-center ' style={{ gap: '10px', gridTemplateColumns: '24px 1fr' }}>
                                                                    {getJiraTypeIcon(item, '24px')} <span className='font-12 font-weight-600'>{item}</span>
                                                                </div>
                                                                <div className='mb-0'>
                                                                    <Input
                                                                        type="select"
                                                                        list={ado_issue_type_and_status[item] && ado_issue_type_and_status[item].length > 0 ? ado_issue_type_and_status[item].map(single_status => {
                                                                            return {id: single_status, label: single_status}
                                                                        }) : []}
                                                                        name={item}
                                                                        label={""}
                                                                        data={state.data}
                                                                        error={state.error}
                                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <AlertStrip variant={'error'} message={"Unable to fetch the issues. please try again later"} />
                    :
                    loading_issue_type || loading_available_status ?
                        <div className='' style={{ border: '1px solid #e6e6e6', borderRadius: '6px' }}>
                            <div className=''>
                                <div className='font-weight-700 color-secondary' style={{ padding: '16px 10px', borderBottom: '1px solid #e6e6e6' }}>
                                    Configure valid status for release
                                </div>
                                <div className='if-yes-div'>
                                    {Array.from({ length: 5 }).map((_, index) => {
                                        return (
                                            <div className='d-grid row-data-ticket' style={{ gridTemplateColumns: '200px 1fr', gap: '20px' }}>
                                                <div className='d-grid align-center'>
                                                    <GenericSkeleton variant={"text"} width={"100%"} />
                                                </div>
                                                <div className='mb-0'>
                                                    <InputSkeleton type={"text"} width={"100%"} style={{ borderRadius: "4px" }} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        : error_in_loading_issue_type || error_in_loading_status ?
                            <AlertStrip variant={'error'} message={error_in_loading_issue_type ? error_in_loading_issue_type : error_in_loading_status ? error_in_loading_status : "Unkown Error"} />
                            :
                            <div className='' style={{ border: '1px solid #e6e6e6', borderRadius: '6px' }}>
                                <div className=''>
                                    <div className='font-weight-700 color-secondary' style={{ padding: '16px 10px', borderBottom: '1px solid #e6e6e6' }}>
                                        Configure valid status for release
                                    </div>
                                    <div className='if-yes-div'>
                                        {
                                            jira_issue_type.map(item => {
                                                return (
                                                    <div className='d-grid row-data-ticket' style={{ gridTemplateColumns: '200px 1fr', gap: '20px' }}>
                                                        <div className='d-grid align-center ' style={{ gap: '10px', gridTemplateColumns: '24px 1fr' }}>
                                                            {getJiraTypeIcon(item.label, '24px')} <span className='font-12 font-weight-600'>{item.label}</span>
                                                        </div>
                                                        <div className='mb-0'>
                                                            <Input
                                                                type="select"
                                                                list={jira_status_list}
                                                                name={item.label}
                                                                label={""}
                                                                data={state.data}
                                                                error={state.error}
                                                                onChangeHandler={commonFunctions.onChangeHandler}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                : null
            }


        </Root>
    )
}
const Root = styled('div')({
    marginBottom: '24px',
    '& .if-yes-div': {
        '&::last-child': {

        },
        '& .input-component': {
            marginBottom: '0px'
        },
        '& .row-data-ticket': {
            borderBottom: '1px solid #e6e6e6',
            padding: '10px',
            '&:last-child': {
                borderBottom: '0px'
            }
        }
    }
})

export default IssueTypeValidation