import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { Loading } from '../../utils/Loading';
import AlertStrip from '../../../components/AlertStrips';
import { Input } from '../../../components/genericComponents/Input';
import GenerateURL from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi from '../../../util/apiInvoker';
import Card from '@mui/material/Card';
import InputSkeleton from '../../../components/genericComponents/Skeletons/InputSkeleton';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';

const DeployComponent = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const application_id = props.application_id ? props.application_id : null
    const service_id = props.service_id ? props.service_id : null;
    const [state, setState] = useState({
        open: false,
        data: {
            deploy_tag: "",
            deployment_type: 1,
        },
        error: {},
        validations: {
            deploy_tag: [VALIDATION_TYPE_REQUIRED],
            deployment_name : [VALIDATION_TYPE_REQUIRED]
        },
        deploy_tag_list: [],
        cd_data_loading: false,
        error_in_loading_cd_data: null,
        deploy_tags_loading: false,
        error_in_loading_deploy_tags: null,
        available_deployments: []
    })

    const strategy_list = [{ value: 1, label: 'Rolling' }, { value: 2, label: 'Canary' }]
    var commonFunctions = getCommonFunctions(state, setState, inherits);
    function toggleState() {
        setState({
            ...state,
            open: !state.open,
        });
    }
    useEffect(() => {
        fetchCdData()

    }, [application_id, service_id])

    function fetchCdData() {
        var service_component_id = props.getServiceEnvComponentId(service_id)
        var requestInfo = {
            endPoint: GenerateURL({ service_id: Number(service_id), component_env_id: service_component_id }, properties.api.save_cd),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchCdDataSuccess, onFetchCdDataFail)
        setState(new_state => ({
            ...new_state,
            cd_data_loading: true,
            error_in_loading_cd_data: null
        }))
    }


    function onFetchCdDataSuccess(response) {
        testAndGetTags(service_id, response[0] && response[0].id)
        var cd_data = response && response[0] ? response[0] : null;
        var all_deployment_list = response.map(element => {
            return { label: element.deployment_name, id: element.deployment_name }
        });

        setState(new_state => ({
            ...new_state,
            check_if_template_selected: cd_data ? cd_data.env_cd_deployment_strategy && cd_data.env_cd_deployment_strategy.release_template_id ? true : false : false,
            cd_data_loading: false,
            error_in_loading_cd_data: cd_data ? null : "CD Details are not configured for this service",
            available_deployments: all_deployment_list
        }))
    }

    function onFetchCdDataFail(error) {
        var error_msg = error.toString();
        setState(new_state => ({
            ...new_state,
            cd_data_loading: false,
            error_in_loading_cd_data: error_msg,
        }));
    }
    function testAndGetTags(service_id, id) {
        var service_component_id = props.getServiceEnvComponentId(service_id)
        const placeholders = {
            service_id: service_id,
            env_id: service_component_id,
            cd_id: id
        }
        var requestInfo = {
            endPoint: GenerateURL(placeholders, properties.api.env_build_tags_list_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessTagsDataLoad, handleFailedTagsDataLoad);
        setState({
            ...state,
            deploy_tags_loading: true,
            error_in_loading_deploy_tags: null
        });
    }

    function handleSuccessTagsDataLoad(response) {
        setState(new_state => ({
            ...new_state,
            deploy_tag_list: getSelectedServiceDeployTags(response.tags),
            deploy_tags_loading: false,
            error_in_loading_deploy_tags: null
        }))
    }

    function handleFailedTagsDataLoad(error) {
        var error_msg = error.toString();
        setState(new_state => ({
            ...new_state,
            deploy_tags_loading: false,
            error_in_loading_deploy_tags: error_msg,
        }));
    }
    function getSelectedServiceDeployTags(data) {
        var deploy_tags = []
        data.map(tag => {
            deploy_tags.push({ id: tag, label: tag })
        })
        return deploy_tags
    }
    console.log("error_msg", state.error)
    function onChangeHandlerForKeyValue(key, value) {
        console.log("jbdfjbdjfd",key,value);
        if (key == "deployment_name") {
            value.forEach((id, index) => {
                console.log(id, "fsdjajjasf")
                value[index] = id;
            });
        }
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value,
            },
            error: { ...state.error, [key]: "" }
        });
    }
    console.log(state.available_deployments, "state.available_deployments")
    console.log("jdsjndj", state.data);
    return (
        <> {/* state.cd_data_loading */}
            {state.cd_data_loading ?
                <div style={{ height:"fit-content" }} >
                     <div className="section-input section-input-1-1 width-full pd-10" style={{ gridTemplateColumns: '280px 280px 280px', alignItems : "flex-end" }}>
                                <div className="d-grid align-center">
                                    <InputSkeleton type={"text"} style={{borderRadius:"6px"}}/>
                                </div>
                                <div className='d-flex f-direction-column' style={{gap:"4px"}}>
                                    <GenericSkeleton variant={"text"} width={"40%"} height={17} />
                                    <Card className='d-flex f-direction-column' style={{gap:"8px",border:"1px solid #dedede",height:"fit-content",padding:"8px"}}>
                                        {Array.from({length: 2}).map((_,index)=>{
                                            return <InputSkeleton type={"checkbox"} width={"100%"} style={{borderRadius:"6px"}} />
                                        })}
                                    </Card>
                                </div>
                            </div>
                </div>
                :
                state.error_in_loading_cd_data ?
                    <AlertStrip
                        variant="error"
                        dismissible={false}
                        message={state.error_in_loading_cd_data}
                        extraClasses="ml-10 mr-10" />
                    :
                    


                        <div className="section-input section-input-1-1 width-full pd-10" style={{ gridTemplateColumns: '280px  280px 1fr' }}>
                            {
                                state.check_if_template_selected ?
                                    <div className="" style={{ padding: '10px 0px' }}>
                                        <Input
                                            type={"radio"}
                                            label="Deploy Strategy: "
                                            name="deployment_type"
                                            list={strategy_list}
                                            data={state.data}
                                            error={state.error}
                                            onChangeHandler={commonFunctions.onChangeHandler}
                                        />
                                    </div>
                                    : null
                            }
                            { //state.deploy_tags_loading
                                state.deploy_tags_loading ?
                                    <div>
                                        <InputSkeleton type={"text"} style={{borderRadius:"6px"}}/>
                                    </div> :

                                    state.error_in_loading_deploy_tags == "Failed" || state.deploy_tag_list.length === 0 ?
                                        <Input
                                            type="text"
                                            label="tag"
                                            name="deploy_tag"
                                            placeholder="Enter value"
                                            data={state.data}
                                            error={state.error}
                                            onChangeHandler={commonFunctions.onChangeHandler}
                                        />
                                        :
                                        <div className="d-grid align-center">
                                            <Input
                                                type={state.open ? "text" : "auto-complete-dropdown"}
                                                label="tag"
                                                id={"deploy_tag_1"}
                                                name="deploy_tag"
                                                placeholder={"Enter value"}
                                                getOptionLabel={(option) => option.label}
                                                list={state.deploy_tag_list}
                                                data={state.data}
                                                error={state.error}
                                                onChangeHandler={state.open ? commonFunctions.onChangeHandler : commonFunctions.onKeyValueChangeHandler}
                                                extraDiv={<button className="btn btn-clear mr-0 btn-link text-anchor-blue pr-0" onClick={toggleState}>{state.open ? "Choose Tag" : "Provide Custom Tag"}</button>}
                                            />
                                        </div>
                            }
                            <Input
                                type="checkbox"
                                name="deployment_name"
                                label="Available Deployments"
                                mandatorySign
                                varient="inner_component"
                                data={state.data}
                                error={state.error}
                                list={state.available_deployments && state.available_deployments.length > 0 ? state.available_deployments : null}
                                onChangeHandler={onChangeHandlerForKeyValue}
                            />
                            {/* <Input
                                    type="switch"
                                    name="deployment_status_check"
                                    label="Wait for rollout deployment"
                                    data={state.data}
                                    onChangeHandler={commonFunctions.toggleState}
                                    error={state.error}
                                /> */}
                        </div>

                    

            }
        </>

    )
}

DeployComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
export default DeployComponent
