export const searchTabs = [
    { id: 'features', label: 'Features' },
    { id: 'project', label: 'Applications' },
    { id: 'service', label: "Services" },
    { id: 'pipeline', label: 'Pipelines' },
    { id: 'env', label: "Environments"},
    {id: 'job_template',label:'Job Templates'},
    {
        id: 'integration',
        label: 'Integrations',
        subTabs: [
            { id: 'repo', label: 'Repo Integration'},
            { id: 'secret', label: 'Secrets',icon: 'ri-lock-password-line' },
            { id: 'ticketing', label: 'Ticketing System' },
            { id:'git_event',label:'Git Events'},
            { id:'helm_repo',label:'Helm Repo Integration' },
        ],
    }
]

export const redirectionLinks = {
    service: 'application/${application_id}/service/${service_id}/detail',
    pipeline: 'application/${application_id}/pipeline/listing?name_exact=${pipeline_name}',
    global_pipeline: 'global/pipeline/listing?name_exact=${pipeline_name}',
    job_template: 'application/${application_id}/job-templates?name=${job_template_name}',
    global_job_template: 'global/job/templates?name=${job_template_name}',
    repo:'repo/list?name=${repo_name}',
    secret: 'secrets/list?name=${secret_name}',
    helm_repo: 'helm/integration/list?name=${helm_name}',
    git_event: 'eventlistener/list?name=${event_name}',
    ticketing: 'tickets/list?name=${ticketing_name}',
    env: 'application/${application_id}/environment/${env_id}/dashboard'
}


export const FeaturesArray = [
    {
        id:'service',
        entity:'Service',
        label:'Create a Service',
        icon: 'ri-settings-3-line',
        appDependent: true,
        path: 'application/${application_id}/service/listing?open_dialog=true'
    },
    {
        id:'pipeline',
        entity:'Pipeline',
        label:'Create a Pipeline',
        customIcon: 
        <img src='/images/pipeline_new.png' alt='pipeline' />
        ,
        appDependent: true,
        path: 'application/${application_id}/pipeline/add',
    },
    {
        id:'env',
        entity:'Environment',
        label:'Create an Environment',
        icon: 'ri-cloud-line',
        appDependent: true,
        path: 'application/${application_id}/env/add',
    },
    {
        id:'job_template',
        entity:'Job Template',
        label:'Create a Job Template',
        icon: 'ri-file-paper-line',
        appDependent: true,
        path: 'application/${application_id}/job-template/add',
    },
    {
        id:'release_package',
        entity:'Release Package',
        label:'Create A Release Package',
        icon: 'ri-inbox-line',
        appDependent: false,
        link: "/release-package/add",
    },
]

export const AdvancedFeaturesArray = [
    {
        id: 'global_pipeline',
        entity: 'Global Pipeline',
        label: 'Create a Global Pipeline',
        customIcon: <img src='/images/pipeline_new.png' alt='pipeline' />,
        appDependent: false,
        link: '/global/pipeline/add'
    },
    {
        id: 'global_job_template',
        entity: 'Global Job Template',
        label: 'Create a Global Job Template',
        icon: 'ri-file-paper-line',
        appDependent: false,
        link: '/global/job/template/add'
    },
    {
        id: 'repo_integration',
        entity: 'Repo Integration',
        label: 'Integrate a Repo',
        icon: 'ri-ship-2-line',
        appDependent: false,
        link: '/repo/list?open=true'
    },
    {
        id: 'ticketing_system',
        entity: 'Ticketing System',
        label: 'Integrate a Ticketing System',
        customIcon: <img src="/images/logos/ticketing-system.svg" width={'16px'} style={{ filter: "invert(43%) sepia(0%) saturate(0%) hue-rotate(181deg) brightness(94%) contrast(85%)"}} alt="Ticketing System"/>, 
        appDependent: false,
        link: '/tickets/list?open=true'
    },
    {
        id: 'secret',
        entity: 'Secret',
        label: 'Create a Secret',
        icon: 'ri-lock-line',
        appDependent: false,
        link: '/secrets/add'
    },
    {
        id: 'helm_repo',
        entity: 'Helm Repo',
        label: 'Integrate a Helm Repo',
        icon: 'ri-cpu-line',
        appDependent: false,
        link: '/helm/integration/list?open=true'
    },
    {
        id: 'git_event',
        entity: 'Git Event',
        label: 'Create a Event Listner',
        customIcon: <img src="/images/Webhook-selected.svg" width={'16px'} style={{ filter: "invert(43%) sepia(0%) saturate(0%) hue-rotate(181deg) brightness(94%) contrast(85%)"}} alt="Git Event"/>,
        appDependent: false,
        link: '/eventlistener/add'
    },
];