import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/system';

import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../components/genericComponents/Input';
import ServiceTypeLogo from './component/ServiceTypeLogo';
import { ValidateDataSet } from '../../../util/Validator';
import { Loading } from '../../../views/utils/Loading';
import { Grid, Tooltip } from '@mui/material';



// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const setDefaultState = (parentState) => {
    let state = { data: {}, error: {}, validations: {}, provided_inputs: [] }

    // if (parentState && parentState.current_target_service) {
    //     state['service_data'] = parentState['type_service_branch_data'][parentState.current_target_service]
    // }
    return state
}

const BranchChangeDialog = ({ onClose, setBranchSaveSuccess, parentState, dialogState, setDialog, ciFetchErrors, setParentState, pipeline_basic_data, branchRequired }) => {
    const [data, setData] = useState(setDefaultState(parentState))

    console.log(data, "sdljknkjsdbhkcbdshkbcvs")
    console.log(parentState, "sdljknkjsdbhkcbdshkbcvs")

    useEffect(() => {
        let currentServiceData = parentState['type_service_branch_data'][parentState.current_target_service]
        let formData = {

        }
        let validations = {}
        let given_inputs = []


        console.log(currentServiceData, "zjkbchjbchjdsb")
        let editedData = normalizeDeploymentNameForEdit(parentState['change_service_branch_final_data'][parentState.current_target_service])
        // editedData={}
        console.log(editedData, "sdkbbhchjbdshc")
        currentServiceData && Object.keys(currentServiceData).forEach(subEnv => {

            formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`] = []
            formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.no_cache`] = false

            if (editedData) {
                if (editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`]) {
                    formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`] =
                        editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`]
                    given_inputs.push(`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`)

                }
                if (editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.version`]) {
                    formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.version`] =
                        editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.version`]
                }
                if (editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`]) {
                    console.log('settng_data_for_edit_data', `${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`)
                    formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`] =
                        editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`]
                }
                if (editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.no_cache`]) {
                    formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.no_cache`] =
                        editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.no_cache`]
                }
            }
            if (currentServiceData[subEnv].default_brnach) {
                validations[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`]
                    = []
            }
        })

        setData(prevState => ({
            ...prevState,
            data: formData,
            validations: validations,
            provided_inputs: [...given_inputs]

        }))

    }, [parentState.type_service_branch_data])

    console.log(data.data, "bdmfbdhbfhfbhjfb")

    useEffect(() => {
        setData(prevState => ({
            ...prevState,
            error: { ...ciFetchErrors }
        }))

    }, [ciFetchErrors])
    ///env_build_tags_list_url
    const serviceData = parentState['type_service_branch_data'][parentState.current_target_service]
    useEffect(() => {
        if (branchRequired) {
            getIsCustomBranch();
        }
    }, [data.data])

    const normalizeDeploymentNameForEdit = (dataObj = {}) => {
        const newObj = { ...dataObj };
        Object.keys(newObj).forEach(key => {
          if (key.endsWith("deploy.deployment_name") && Array.isArray(newObj[key])) {
            newObj[key] = newObj[key].map(item =>
              typeof item === "object" && item.id ? item.id : item
            );
          }
        });
        return newObj;
      };

      

    const handleOnSaveClick = () => {

        let result = ValidateDataSet(data.data, data.validations);
        if (result.valid) {
            var all_keys = Object.keys(data.data)
            if (all_keys && all_keys.length > 0) {
                all_keys.forEach(item => {
                  const value = data.data[item];
              
                  if (value === "") {
                    delete data.data[item];
                  }
              
                  // Normalize deployment_name list to [{ id, label }]
                  if (item.endsWith("deploy.deployment_name") && Array.isArray(value)) {
                    data.data[item] = value.map(val =>
                      typeof val === "string"
                        ? { id: val, label: val }
                        : val
                    );
                  }
                });
              }
              
            const existingComponentError = { ...parentState.componentListError }
            existingComponentError[parentState.current_target_service] = false
            // checking if all services has branches 
            let allserviceBranchSatisfied = true
            Object.keys(existingComponentError)?.forEach(serviceName => {
                if (existingComponentError[serviceName]) {
                    allserviceBranchSatisfied = false
                }
            })
            console.log(data);
            if (!allserviceBranchSatisfied) {

                setParentState(prevState => ({
                    ...prevState,
                    change_service_branch_final_data: {
                        ...prevState.change_service_branch_final_data,
                        [parentState.current_target_service]: { ...data.data }
                    },
                    edited_daya_by_services: {
                        ...prevState.edited_daya_by_services,
                        [parentState.current_target_service]: { ...data.data }
                    },
                    componentListError: { ...existingComponentError }

                }))

            }
            else {
                let error = { ...parentState.error }
                error.components = null
                setParentState(prevState => ({
                    ...prevState,
                    change_service_branch_final_data: {
                        ...prevState.change_service_branch_final_data,
                        [parentState.current_target_service]: { ...data.data }
                    },
                    edited_daya_by_services: {
                        ...prevState.edited_daya_by_services,
                        [parentState.current_target_service]: { ...data.data }
                    },
                    componentListError: { ...existingComponentError },
                    error: { ...error }

                }))
            }
            console.log(data,"fsdjkfjsadfsjak");
            onClose()
            setBranchSaveSuccess(true)
        }
        else {
            setData(prevState => ({
                ...prevState,
                error: { ...prevState.error, ...result.error }
            }))
        }
    }


    const commonFunctions = getCommonFunctions(data, setData, {});

    const onKeyValueHandler = (e) => {
        const k = e.target.name;
        let v = e.target.value;
    
        console.log("onKeyValueHandler Debugging:", { key: k, value: v });
    

        if (Array.isArray(v) && v.length > 0 && typeof v[0] === "string") {
            v[0] = v[0].replace(" (New Label)", "");
        }
    
        setData(prevState => ({
            ...prevState,
            data: { ...prevState.data, [k]: v },
            error: { ...prevState.error, [k]: null }
        }));
    };

    const onKeyValueChangeHandler = (k, v) => {
        setData(prevState => ({
            ...prevState,
            data: { ...prevState.data, [k]: v },
            error: { ...prevState.error, [k]: null }
        }));
    }
    function onBranchChangeHandler(event, service_data, input_name) {
        const key = event.target.name;
        let commit_sha_key = getCommitShaKey(key)
        const value = event.target.value;
        
        if (value) {
            if (!data.provided_inputs.includes(key))
                setData((new_state) => ({
                    ...new_state,
                    provided_inputs: [...new_state.provided_inputs, key],
                    data: {
                        ...new_state.data,
                        [commit_sha_key]: getBranchCommitSha(value, service_data)
                    }
                }))
        } else {
            var updated_arr = []
            data.provided_inputs.forEach((item) => {
                if (item != key) {
                    updated_arr.push(item)
                }
            })
            setData((new_state) => ({
                ...new_state,
                provided_inputs: [...updated_arr],
                data: {
                    ...new_state.data,
                    [commit_sha_key]: getBranchCommitSha(value, service_data)
                }
            }))
        }

        setData((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [key]: value,
            },
            selected_service_data: service_data,
            selected_input_name: input_name
        }));

    }
    function getIsCustomBranch() {

        console.log(data.data, serviceData, "skfbdkshfckdsbcjhbdsc")

        var is_any_feild_custom = false;

        var all_subEnvs_branches = []

        var all_keys = []

        serviceData && Object.keys(serviceData).forEach((subEnv) => {
            console.log(subEnv, "sdfbsdhbchjbdsc")
            var obj = {}
            obj[`${serviceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`] = serviceData[subEnv].branches
            console.log(obj, "sdfbsdhbchjbdsc")
            all_subEnvs_branches.push(obj);
            all_keys.push(`${serviceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`)
        })

        all_keys.forEach((key) => {

            var branches_list;

            all_subEnvs_branches.forEach((item) => {
                if (item[key]) {
                    branches_list = item[key]
                }
            })

            console.log(branches_list, "dsbfjbsdhjbsdcbds")

            if (branches_list && !branches_list.includes(data.data[key])) {
                is_any_feild_custom = true;
            }

            setData((new_state) => ({
                ...new_state,
                custom_branch_value: is_any_feild_custom,
            }))
        })

        console.log(is_any_feild_custom, "skfbdkshfckdsbcjhbdsc")

        setData((new_state) => ({
            ...new_state,
            custom_branch_value: is_any_feild_custom,
        }))

    }

    function isAllValuesProvided() {
        if (serviceData && Object.keys(serviceData).length == data.provided_inputs.length) {
            return true;
        } else {
            return false;
        }
    }

    function getBranchType(service_data, input_name) {
        let branch_list = []

        if (service_data.branches) {
            service_data.branches.forEach(branch => {
                branch_list.push(branch)
            })
        }

        var is_custom;

        if (data.data[input_name]) {
            for (let i = 0; i < branch_list.length; i++) {
                if (branch_list[i] == data.data[input_name]) {
                    is_custom = false;
                    break;
                } else {
                    is_custom = true;
                }
            }
        }

        return is_custom;
    }


    function handleInputTypeChange(input_name) {
        delete data.data[input_name]

        if (data.provided_inputs.includes(input_name)) {
            var updated_arr = []

            data.provided_inputs.forEach((item) => {
                if (item != input_name) {
                    updated_arr.push(item)
                }
            })

            setData((new_state) => ({
                ...new_state,
                provided_inputs: updated_arr
            }))
        }
    }

    console.log("jdnfdjn", data);

    function getBranchCommitSha(value, list) {
        console.log(value, list)
        if (list && list.branch_with_commit_sha) {
            for (let obj of list.branch_with_commit_sha) {
                console.log(obj)
                for (let [key, val] of Object.entries(obj)) {
                    console.log(key, val)
                    if (key === value) {
                        return val;
                    }
                }
            }
        } else {
            return null;
        }
    }

    function getCommitShaKey(input) {

        // Split the input string by dots
        let parts = input.split('.');

        // Replace the last segment with the new segment
        parts[parts.length - 1] = "commit_sha";

        // Join the parts back together
        return parts.join('.');
    }

    function onChangeHandler(e, data) {
        let name = e.target.name
        let commit_sha_name = getCommitShaKey(name)
        let value = e.target.value
        console.log(name, value, data, "sdbvcgsjdvcgsdvcds")

        setData((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [name]: value,
                [commit_sha_name]: getBranchCommitSha(value, data),
            }
        }))
    }

    console.log(pipeline_basic_data, 'data_0pppmsgh', pipeline_basic_data && pipeline_basic_data.manual_deploy_tag_select)
    return (
        <Dialog
            className="dialog-wrapper-pipeline integrations-dialog action_card_dialog_width"
            open={true}
            maxWidth={"md"}
            // TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            sx={{
                "& .MuiDialog-paper": {
                    overflow: "visible",
                    height: "100vh",
                    transition: "0.2s all ease-in-out",
                },
            }}
        >
            <button
                onClick={onClose}
                className='btn float-cancel-button'
                style={{ left: "-54px" }}
            >
                <span className='ri-close-line'></span>
            </button>

            <div className='card-header-new'>
                <div className='d-flex align-center space-between' style={{ width: '100%' }}>
                    <div className='d-flex align-center'>
                        <span className='font-18 ml-5 d-inline-block font-weight-600' style={{ color: '#fff', lineHeight: "21.94px" }}>
                            {`Change Branch for ${parentState.current_target_service ? parentState.current_target_service : 'Service'}`}                             </span>
                    </div>
                </div>
            </div>

            <>
                <div className='d-flex' style={{
                    height: '100%',
                    overflow: 'auto',
                    gap: '32px',
                    padding: '70px 15px',
                    flexDirection: 'column', background: '#fff'
                }}>                        {

                        serviceData ?
                            Object.keys(serviceData).map((subEnvData, index) => {
                                console.log(serviceData[subEnvData], "fsddfsaf")
                                // var complete_operation = false
                                let isBranchloading = true
                                let isTextboxSelected = false
                                let isTagsloading = true
                                let isDeploymentsLoading = true
                                let isTagsTextboxSelected = false
                                let fieldName = `${serviceData[subEnvData]['master']['name']}.${parentState.current_target_service}.build.branch`
                                let fieldNameTag = `${serviceData[subEnvData]['master']['name']}.${parentState.current_target_service}.deploy.version`
                                let fieldDeploymentName = `${serviceData[subEnvData]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`
                                let fieldNameNoCache = `${serviceData[subEnvData]['master']['name']}.${parentState.current_target_service}.build.no_cache`

                                if (serviceData[subEnvData] && serviceData[subEnvData].branches) {
                                    isBranchloading = false
                                    if (!serviceData[subEnvData].branches.includes(data.data[fieldName])) {
                                        isTextboxSelected = false
                                    }
                                }
                                else if (data.error[fieldName]) {
                                    isBranchloading = false
                                }
                                if (serviceData[subEnvData] && serviceData[subEnvData].tags) {
                                    isTagsloading = false
                                    if (!serviceData[subEnvData].tags.includes(data.data[fieldName])) {
                                        isTagsTextboxSelected = true
                                    }

                                    // Assuming this is an asynchronous operation, you can use a Promise
                                    // const includesOperation = new Promise((resolve, reject) => {
                                    //     // Assuming serviceData[subEnvData].tags is an array
                                    //     if (!serviceData[subEnvData].tags.includes(data.data[fieldName])) {
                                    //         // If the condition is true, resolve the promise
                                    //         isTagsTextboxSelected = true
                                    //         resolve();
                                    //     } else {
                                    //         reject();
                                    //     }
                                    // });

                                    // // Using the promise then method to update anunay after the includes operation is completed
                                    // includesOperation.then(() => {
                                    //     complete_operation = true;
                                    // }).catch(() => {
                                    //     complete_operation = true;
                                    // })

                                } else if (data.error[fieldNameTag] || !serviceData[subEnvData].tags) {

                                    isTagsloading = false
                                }
                                if (serviceData[subEnvData] && serviceData[subEnvData].deployment_name_list) {

                                    isDeploymentsLoading = false
                                } else if (data.error[fieldDeploymentName] || serviceData[subEnvData].deployment_name_list) {


                                    isDeploymentsLoading = false
                                }
                                return (
                                    <FormContainer key={index}>
                                        <div className='d-flex align-center space-between' style={{ width: "100%" }}>
                                            <div style={{ gap: '16px' }} className='d-flex align-center'>
                                                <ServiceTypeLogo name={serviceData[subEnvData]['master']['name']} newUpdated={true} />
                                                <div>
                                                    <div>
                                                        <div className='env-name' style={{ color: '#484848' }} >{subEnvData}</div>
                                                        <div className='env-caption'>
                                                            Selected Env
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            <div style={{ gap: '8px' }} className='d-flex align-center input-component-dcoker-mb-0'>
                                                <div className='docker-filed'>
                                                    Docker Cache
                                                </div>
                                                <Input
                                                    type="only-switch"
                                                    label=""
                                                    name={fieldNameNoCache}
                                                    data={data.data}
                                                    error={data.error}
                                                    onChangeHandler={commonFunctions.toggleState} />
                                            </div>
                                        </div>


                                        <Grid container spacing={2} xs={12}>
                                            <Grid item xs={6}>

                                                {
                                                    isBranchloading ?
                                                        <div><Loading varient="light" /></div>
                                                        :
                                                        <>
                                                            <div style={{ position: "relative" }}>

                                                                <Input
                                                                    type='dual-input'
                                                                    // isFirstTimeDropdown={!isTextboxSelected}
                                                                    isFirstTimeDropdown={branchRequired ? data.data[fieldName] ? !getBranchType(serviceData[subEnvData], fieldName) : true : !isTextboxSelected}
                                                                    label='Branch Change'
                                                                    placeholder="master"
                                                                    name={fieldName}
                                                                    list={serviceData[subEnvData].branches && serviceData[subEnvData].branches.map(branch => {
                                                                        return { id: branch, label: branch }
                                                                    })}
                                                                    // list={getBranchesList(serviceData[subEnvData].branches && serviceData[subEnvData].branches)}
                                                                    data={data.data}
                                                                    error={data.error}
                                                                    onChangeHandler={branchRequired ? (e) => onBranchChangeHandler(e, serviceData[subEnvData], fieldName) : /* commonFunctions.onChangeHandler */ (e) => onChangeHandler(e, serviceData[subEnvData])}
                                                                    onInputTypeChange={branchRequired ? () => handleInputTypeChange(fieldName) : () => { }}
                                                                />
                                                            </div>
                                                            {
                                                                branchRequired &&
                                                                data.data[fieldName] && getBranchType(serviceData[subEnvData], fieldName) &&
                                                                <p style={{ color: "#E95454", fontSize: "12px", position: "absolute" }}>The custom branch provided does not exist in the loaded list of branches</p>
                                                            }
                                                            {data.error[fieldName] &&
                                                                <Tooltip title={data.error[fieldName]}>
                                                                    <p className='font-11 text-ellipsis-150 ' style={{ color: '#cc0033' }}>{data.error[fieldName]}</p>
                                                                </Tooltip>
                                                            }
                                                        </>
                                                }

                                            </Grid>

                                            <Grid item xs={6}>
                                                {
                                                    pipeline_basic_data.manual_deploy_tag_select &&
                                                    <div>

                                                        {
                                                            isTagsloading ?
                                                                <div><Loading varient="light" /></div>
                                                                :
                                                                <>
                                                                    <Input
                                                                        type='dual-input'
                                                                        isFirstTimeDropdown={isTagsTextboxSelected}
                                                                        label='Deploy Tag'
                                                                        placeholder="Enter Custom Tag"
                                                                        name={fieldNameTag}
                                                                        list={serviceData[subEnvData].tags && serviceData[subEnvData].tags.map(tag => {
                                                                            return { id: tag, label: tag }
                                                                        })}
                                                                        data={data.data}
                                                                        error={data.error}
                                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                                    />
                                                                    {data.error[fieldNameTag] && <p className='font-11 ' style={{ color: '#cc0033' }}>{data.error[fieldNameTag]}</p>}
                                                                </>
                                                        }

                                                    </div>
                                                }

                                            </Grid>

                                            <Grid item xs={12}>
                                              
                                                {
                                                    isDeploymentsLoading ?
                                                        <div><Loading varient="light" /></div>
                                                        :
                                                        <>
                                                            <div className="auto-complete-dropdown auto-complete-dropdown-42 auto-complete-dropdown-ticketing">
                                                                <Input
                                                                    type="auto-complete-freesolo-v2"
                                                                    label="Select Deployment"
                                                                    id={"fieldDeploymentName1"}
                                                                    name={fieldDeploymentName}
                                                                    list={serviceData[subEnvData].deployment_name_list && serviceData[subEnvData].deployment_name_list?.length > 0 ?
                                                                        serviceData[subEnvData].deployment_name_list : []}
                                                                    freeSolo={false}
                                                                    mandatorySign
                                                                    count={10}
                                                                    allowNewItems={false}
                                                                    placeholder="Enter keyword to search deployment"
                                                                    getOptionLabel={(option) => option.label}
                                                                    data={data.data}
                                                                    error={data.error}
                                                                    onChangeHandler={onKeyValueHandler}
                                                                />
                                                            </div>
                                                          
                                                        </>
                                                }

                                            </Grid>
                                        </Grid>

                                    </FormContainer>

                                )
                            })
                            // : <p className='font-12 color-red'>Data Not Available</p>
                            : <p className='font-12 color-red'></p>


                    }
                </div>

                {
                    branchRequired ?
                        isAllValuesProvided() ?
                            data.custom_branch_value ?
                                <div className="pd-10 pl-20 pr-10 border-top d-flex align-center space-between">
                                    <p style={{ fontSize: "14px", color: "#787878" }}>Note : <span style={{ color: "#2F2F2F" }}>The custom branch selected does not exist in the loaded list of branches. <span style={{ color: "#E95454" }}>Do you want to continue.</span></span></p>
                                    <button style={{ width: '100px', backgroundColor: "#E95454" }} className="btn btn-red" onClick={handleOnSaveClick}> Continue</button>
                                </div>
                                :
                                <div className="pd-10 pl-20 pr-10 border-top d-flex align-center space-between">
                                    <button style={{ width: '100px' }} className="btn btn-outline-grey" onClick={() => { onClose() }}>Close</button>
                                    <button style={{ width: '100px' }} className="btn btn-primary" onClick={handleOnSaveClick}> Save</button>
                                </div>
                            :
                            <div className="pd-10 pl-20 pr-10 border-top d-flex align-center space-between">
                                <button style={{ width: '100px' }} className="btn btn-outline-grey" onClick={() => { onClose() }}>Close</button>
                                <Tooltip title="You have not provided branch values">
                                    <button className="btn  btn-disable cursor-not-allowed">Save</button>
                                </Tooltip>
                                {/* <button style={{ width: '100px' }} className="btn btn-primary" onClick={handleOnSaveClick}> Save</button> */}
                            </div>
                        :
                        <div className="pd-10 pl-20 pr-10 border-top d-flex align-center space-between">
                            <button style={{ width: '100px' }} className="btn btn-outline-grey" onClick={() => { onClose() }}>Close</button>
                            <button style={{ width: '100px' }} className="btn btn-primary" onClick={handleOnSaveClick}> Save</button>
                        </div>
                }

            </>


        </Dialog>
    )
}

BranchChangeDialog.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export default BranchChangeDialog;


const FormContainer = styled('div')(({ colorIconScheme }) => {

    return {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        // height: '241px',
        gap: '20px',
        padding: '16px',
        borderRadius: '6px',
        border: '1px solid #CACACA',
        background: '#fff',
        boxShadow: '0px 4px 4px 0px #00000014',
        '& .env-name': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '21px',
            color: '#2F2F2F',
        },
        '& .env-caption': {
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '18px',
            color: '#505050',
        },
        '& .docker-filed': {
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '18px',
            color: '#2F2F2F',
        }
    }
});