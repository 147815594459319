import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Topbar from '../components/Topbar';
import { useNavigate,useLocation } from 'react-router-dom'
import ProfileNavigation from '../components/ProfileNavigation';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    paddingLeft: 240,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  rootpd: {
    paddingTop: 56,
    height: '100%',
    paddingLeft: 70,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }
}));

const ProflieLayout = (props) => {
  const classes = useStyles();
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  return (
  
          <div className={classes.root}>
            <Topbar />
            <ProfileNavigation  />
            <main className={classes.content}>
              
              {children}
              
            </main>
          </div>
    
  );
};

ProflieLayout.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


export default ProflieLayout;