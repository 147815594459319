import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from "../../../serviceRevamp/add/ci_flow/SourceDetails";
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import { ErrorComponent } from '../../../utils/Error';
import { Tooltip } from '@mui/material';

const CloneV3Jobs = ({headingText,...props}) => {
    const classes = useStyles();
        const [open, setOpen] = React.useState(false);
        const inherits = props.inherits ? props.inherits : {};
        const prev_state = props.prev_state ? props.prev_state : null;
        const list = props.list;
        const service_id = props.service_id;
        console.log(service_id, list,"all_deployment_list1111")
        const [state, setState] = useState(prev_state ? prev_state : {
            data: {
            },
            error: {},
            validations: {
                env_clone: [VALIDATION_TYPE_REQUIRED]
            },
            list: [],
            allConfiguredCd:[],
            fetchError:false
    })
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    function onYesHandler() {
        const result = ValidateDataSet(state.data, state.validations)
        if (!result.valid) {
            setState(new_state => ({
                ...new_state,
                error: { ...result.error }
            }));
        }
        else {
            
            props.onCloneHandler(state.data.env_clone)
            
        }
    }
    return (
        <div className={classes.root}>
            <div className="card border-none">
                <div className="card-header border-none bg-white" style={{ borderTop: 'none' }}>
                    {/* <p>Do you want to clone from any existing env</p> */}
                    {headingText &&
                    <h2 className='color-secondary font-weight-600 font-24'>{headingText}</h2>
                    }
                </div>
                {
                    state.fetchError?
                    <ErrorComponent error={state.fetchError}/> : <>
                    <div className="card-body border-none pd-10 mb-22">
                    
                            <>
                                <Input
                                    type="select"
                                    label="You can clone from these availaible Envs."
                                    name="env_clone"
                                    data={state.data}
                                    list={list ? list : []}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                    info={"Job Template should be same in both environment for possible cloning"}
                                />
                            </>
                            

                </div>
                <div className="card-footer justify-flexend border-none btn-section bg-white">
                    <Tooltip title={"Do not want to Clone?"} arrow>
                        <button className="btn btn-outline-grey" onClick={() => { props.onCloneHandler()}}> Cancel </button>
                    </Tooltip>
                    <button className="btn btn-primary" onClick={onYesHandler}> Next </button>
                </div>
                    </>
                }
            
            </div>
        </div>
    )
}

export default CloneV3Jobs;

const useStyles = makeStyles(theme => ({
    root: {
        '& .card': {
            textAlign: 'left',
            boxShadow: 'none',
            minWidth: '600px',
            '& .card-header': {
                paddingBottom: '10px!important',
                fontSize: '13px'
            },
            '& button': {
                fontSize: '12px',
                color: '#546e7a',
                display: 'inherit',
                lineHeight: 'auto',
                border: 'none',
                height: 'auto',
                width: "auto",
                // backgroundColor: 'transparent',
                margin: 'inherit',
                borderRadius: '4px'
            }
        },
    },
}))