import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import { getCommonFunctions } from '../../../../../../serviceRevamp/add/ci_flow/SourceDetails';
import { ErrorComponent } from '../../../../../../utils/Error';
import { Loading } from '../../../../../../utils/Loading';



export default function ConfirmationDialog(props) {
    const classes = useStyles();
    const hpa_data = props.hpa_data ? props.hpa_data : {}
    const extraProps = props.extraProps?props.extraProps:null
    const inherits = props.inherits ? props.inherits : {}
    const [state, setstate] = useState({
        data: {},
        error: {},
        loading: false,
       
    })
    const commonFunctions = getCommonFunctions(state, setstate, inherits);
    const handleClickOpen = () => {
        setstate({ ...state, open: true });
    };

    const handleClose = () => {
        setstate({ ...state, open: false, });
    };
    


    return (
        <>
                          
            <button className="btn btn-flaticon" onClick={ handleClickOpen}><span className="flaticon-upload-button"></span></button>
                <Dialog
                    open={state.open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {
                        state.loading ?
                            <div className="card-body d-flex" style={{ height: 300 }}>
                                <Loading varient="light" />
                            </div>
                            :
                            state.error_msg ? <ErrorComponent error={state.error_msg} variant="msg-box" />
                                :
                                <div className="card ">
                                    <div className="card-body d-flex" style={{ height: 300 }}>
                                        <div className="m-auto" style={{ width: '400px' }}>
                                            <i className="ri-information-line"  style={{ color: '#4783dc', fontSize: '60px' }} className="text-center mb-10 d-block m-auto" />
                                            <div className="">
                                                <p className="font-16 font-weight-bold text-center mb-5">Are you sure you want to switch new HPA</p>
                                                <p className="font-12 color-danger text-center">Please note the current HPA settings applied on kubernetes will be deleted with the new HPA settings</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top pd-10 justify-end d-flex align-center">
                                        <button className="btn btn-outline-dark-grey" onClick={handleClose}>
                                            Cancel
                    </button>
                                        <button className="btn btn-primary-v2" onClick={props.validateAndApplyHPA}>
                                            Confirm
                    </button>
                                    </div>
                                </div>
                    }

                </Dialog>

        </>
    );
}

ConfirmationDialog.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const useStyles = makeStyles(theme => ({
    anchorText: {
        fontSize: '11px',
        color: '#42a6e0',
        lineHeight: 1,
        cursor: 'pointer',
        marginLeft: '10px'
    }
}));