import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { FormatTime } from '../../../../util/util';
import { Link } from 'react-router-dom';

const JiraJobExecutionCard = props => {
    const jira_task_instance = props.jira_task_instance;
    const { pipeline_id, pipeline_instance_id, stage_instance_id, task_instance_id } = props;
    const selected_component_task_id = props.selected_component_task_id;
    const update_selected_component_task = props.update_selected_component_task;
    const open = selected_component_task_id == jira_task_instance.id;
    const showLogsCallback = props.showLogs;
    const stage = props.stage;

    const start_time = new Date(jira_task_instance.created_at);
    const end_time = new Date(jira_task_instance.updated_at);
    const diff = end_time - start_time;
    const time_taken = FormatTime(diff);


    const toggleSectionOpen = () => {
        update_selected_component_task(!open ? jira_task_instance.id : null);
    }

    function getProgressStatusIcon() {
        switch (jira_task_instance.status) {
            case "SUCCESS":
                return (
                    <div className="circle-status circle-status-approved"><div className="flaticon-check-symbol"></div></div>
                );
            case "FAILED":
                return (
                    <div className="circle-status circle-status-failed"><div className="flaticon-round-info-button"></div></div>
                );
            case "RUNNING":
                return (
                    <div className="circle-status circle-status-pending"><div className="flaticon-clock-with-white-face"></div></div>
                );
            default:
                return (
                    <div className="circle-status circle-status-pending"><div className="flaticon-clock-with-white-face"></div></div>
                );
        }
    }

    function showLogs() {

        const path_arr = [stage ? stage.name : "", props.task.name ? props.task.name : "", jira_task_instance.jira_task_details.name,];
        showLogsCallback(jira_task_instance.id, path_arr, jira_task_instance.global_task_id);
    }
    console.log("   ", jira_task_instance);
    return (
        <ServiceExecution>
            <div className="vertical-border-service-card" ></div>
            <div className="vertical-border-one" style={open ? { height: '11%' } : { height: '50%' }}></div>
            <span className="border-stage-card">
                {getProgressStatusIcon()}
            </span>
            <Header>
                {/* <div className="flaticon-check-symbol"></div> */}
                <Link
                    to={`${jira_task_instance.jira_url}/browse/${jira_task_instance.jira_issue_key}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="service"
                >
                    {jira_task_instance.jira_issue_key
                        ? jira_task_instance.jira_issue_key
                        : jira_task_instance.jira_task_details.issue_key
                            ? jira_task_instance.jira_task_details.issue_key
                            : jira_task_instance.jira_task_details.dynamic_fields.jira_issue_list
                                ? jira_task_instance.jira_task_details.dynamic_fields.jira_issue_list.value
                                : "NA"}
                    <i className="ri-link" 
                        style={{
                            color: "#fff",
                         
                            marginLeft: "5px",
                            transform: "rotate(-40deg)",
                        }}
                    />
                </Link>
                <Link
                    to={{
                        pathname: "/logs",
                        search: `?global_task_id=${
                            jira_task_instance?.global_task_id || jira_task_instance?.logs_task_id || ""
                        }&status=${jira_task_instance.status || ""}&tab_id=${
                            jira_task_instance.id || ""
                        }`,
                    }}
                    title="View Logs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flaticon-google-drive-file"
                />
                <div className={jira_task_instance.status == "SUCCESS" ? "status-div green-text" : jira_task_instance.status == "FAILED" ? "status-div red-text" : jira_task_instance.status == "IN_QUEUE" ? "status-div inqueue-text" : jira_task_instance.status == "REVOKED" ? "status-div purple-text" : jira_task_instance.status == "STOPPED" ? "status-div red-text" : "status-div yellow-text"}>&nbsp;{jira_task_instance.status}&nbsp;</div>
                <div className="duration"> {time_taken} </div>
                {
                    open ?
                        <div className="flaticon-expand-arrow" onKeyDown={() => { }} onClick={toggleSectionOpen} role="button" tabIndex={0}></div> :
                        <div className="flaticon-expand-button" onKeyDown={() => { }} onClick={toggleSectionOpen} role="button" tabIndex={0}></div>
                }
            </Header>

            {
                open ?
                    <Body>
                        {
                            jira_task_instance.jira_task_details.operation == "create" &&
                            <>
                                <div className="steps-list">
                                    <div className="service">Issue Key:</div>
                                    <div className="status-div green-text text-ellipsis-200">&nbsp; {jira_task_instance.jira_task_details.issue_key}</div>
                                </div>
                                <div className="steps-list">
                                    <div className="service">Summary:</div>
                                    <div className="status-div green-text text-ellipsis-200">&nbsp; {jira_task_instance.jira_task_details.summary}</div>
                                </div>
                            </>
                        }
                        {
                            jira_task_instance.jira_task_details.operation == "status_update" &&
                            <>
                                <div className="steps-list">
                                    <div className="service">Issue Key:</div>
                                    <div className="status-div green-text text-ellipsis-200">&nbsp; {jira_task_instance.jira_task_details.issue_key}</div>
                                </div>
                                <div className="steps-list">
                                    <div className="service">Status:</div>
                                    <div className="status-div green-text text-ellipsis-200">&nbsp; {jira_task_instance.jira_task_details.status}</div>
                                </div>
                            </>
                        }
                        {
                            jira_task_instance.jira_task_details.operation == "add_comment" &&
                            <>
                                <div className="steps-list">
                                    <div className="service">Issue Key:</div>
                                    <div className="status-div green-text text-ellipsis-200">&nbsp; {jira_task_instance.jira_task_details.issue_key}</div>
                                </div>
                                <div className="steps-list">
                                    <div className="service">Comment:</div>
                                    <div className="status-div green-text text-ellipsis-200">&nbsp; {jira_task_instance.jira_task_details.comment}</div>
                                </div>
                            </>
                        }
                    </Body> : null
            }
        </ServiceExecution>
    )
}


JiraJobExecutionCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};


export default JiraJobExecutionCard;



const ServiceExecution = styled('div')({
    width: '324px',
    borderRadius: '4px',
    display: 'block',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#4652a2',
    marginLeft: '5rem',
    marginRight: '4rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    position: 'relative',
    '&:last-child > .vertical-border-service-card': {
        display: 'none'
    },
    '&:only-child .main-vertical-div-pipeline > .vertical-border-pipeline': {

    },
    '&:last-child > .vertical-border-service-card:last-child': {
        display: 'none'
    },
    '& .vertical-border-service-card': {
        top: '24px',
        left: '-30px',
        width: '3px',
        height: '104%',
        position: 'absolute',
        backgroundColor: '#666'
    },
    '&:only-child > .vertical-border-one': {
        display: 'block!important',
        height: '40%',
    },
    '& .vertical-border-one': {
        top: '-11px',
        left: '-30px',
        width: '3px',
        height: '50%',
        position: 'absolute',
        backgroundColor: '#666',
    },
    '& .border-stage-card': {
        height: '3px',
        backgroundColor: '#666',
        width: '30px',
        position: 'absolute',
        left: '-28px',
        top: '18px'
    },
    '& .circle-status': {
        height: '32px',
        width: '32px',
        border: '3px solid #666',
        position: 'relative',
        left: '-20px',
        bottom: '19px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .circle-status-approved': {
        border: '3px solid #61e187'
    },
    '& .circle-status-approved .flaticon-check-symbol:before': {
        fontSize: '12px!important',
        color: '#61e187'
    },
    '& .circle-status-pending': {
        border: '3px solid #ffbf00'
    },
    '&  .circle-status-pending .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#ffbf00'
    },
    '& .circle-status-failed': {
        border: '3px solid #ff8969'
    },
    '&  .circle-status-failed .flaticon-round-info-button:before': {
        fontSize: '16px!important',
        color: '#ff8969'
    },
})
const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    "& .flaticon-google-drive-file": {
        color: '#fff',
        cursor: 'pointer',
        margin: '0px 8px'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .status-div': {
        fontSize: '12px',
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'
    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        transition: 'all .3s ease',
    },
    '& .flaticon-expand-arrow:before, .flaticon-expand-button:before': {
        fontSize: '12px!important'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    },
    '& .flaticon-expand-button': {
        justifySelf: 'flex-end',
        cursor: 'pointer',
        marginLeft: 'auto',
        color: '#fff',
        transition: 'all .3s ease',
    }
})

const Body = styled('div')({
    backgroundColor: '#5e6798',
    fontSize: '12px',
    color: '#fff',
    width: '100%',
    '& .steps-list': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        borderBottom: '1px solid #6b74a8'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .status-div': {
        fontSize: '12px'
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'


    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto'
    },
    '& .flaticon-expand-arrow:before': {
        fontSize: '12px!important'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    }
})
const Footer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    fontSize: '12px',
    color: '#fff',
    textAlign: 'center',
    justifyContent: 'center',
    '& .artifact-name': {
        color: '#b5bbd9',
        display: 'none'
    }
})