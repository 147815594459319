export const getHighlightedText = (text, highlight) => {
    if (!highlight) return text; 
    
    const parts = text.split(new RegExp(`(${highlight})`, 'gi')); 
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ textDecoration: 'normal',fontWeight:'700' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
};

export function formatText(text) {
    return text
      .toLowerCase() 
      .split('_') 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
      .join('_');
}