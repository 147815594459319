import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import EditableTable from '../../components/genericComponents/EditableTable';
import { useParams } from 'react-router-dom';
import GenerateURL from '../../util/APIUrlProvider';
import InvokeApi from '../../util/apiInvoker';
import properties from '../../properties/properties';
import ManifestPlaceholderForm from './ManifestPlaceholderForm';
import { Loading } from '../utils/Loading';
import { ErrorComponent} from '../utils/Error';


const columnNames = [

    {
        label: 'Manifest Kind',
        width: '25%',
        grey: false,
        name: 'kind_type'
    },
    {
        label: 'Placeholder Path',
        width: '35%',
        grey: true,
        name: 'key_with_position'
    },
    {
        label: 'Placeholder Value',
        grey: true,
        width: '40%',
        name: 'placeholder',
        alternateName: 'value'
    }

]

const ManifestPlaceholder = () => {

    const classes = useStyles();

    const [state, setState] = useState({

        data: {
        },
        error: null,
        showLoadingIcon: false
    }
    )
    const { application_id } = useParams()

    const endPointObj = { application_id: application_id }

    const apiUrl = properties.api.save_manifest_placeholder

    const apiUrlForUpdate = properties.api.update_manifest_placeholder

    useEffect(() => {
        fetchManifestPlaceholderLists()
    }, [])


    const fetchManifestPlaceholderLists = () => {
        let requestInfo = {
            endPoint: GenerateURL(endPointObj, apiUrl),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState(newState => ({
            ...newState,
            showLoadingIcon: true
        }))

    }

    const handleSuccessApiHit = (data) => {

        setState(prevState => ({
            ...prevState,
            manifestplaceholders_list: data.length !== 0 ? data : null,
            showLoadingIcon: false
        }));
    }

    const handleFailedApiHit = (error) => {
        console.log('inside_failed', error)
        setState(prevState => ({
            ...prevState,
            error: error,
            showLoadingIcon: false
        }))
    }

    const tableActionHandler = (type, data) => {

        setState(prevState => ({
            ...prevState,
            data: { ...data }
        }))
    }

    const resetParentState = () => {

        setState(prevState => ({

            ...prevState,
            data: {}
        }))
    }

    console.log('p_state', state)

    return (
        <div className={classes.root}>
            <div className={classes.headerDiv + " mb-20"}>
                <p className={classes.mainHeading}>Auto replacement of Manifest Placeholders</p>

                <p className={classes.subHeading}>Displaying overall summary of manifest placeholder</p>
            </div>
            <div className={classes.cardBody}>
                {
                    state.showLoadingIcon ?
                        <Loading varient='light' /> :
                        state.error ?
                            <ErrorComponent variant="msg-box" error={state.error} />
                            :
                            <>
                                <EditableTable columnNames={columnNames} actionButton={true}
                                    apiFetchedData={state.manifestplaceholders_list}
                                    tableActionHandler={tableActionHandler}
                                    fetchAfterAction={fetchManifestPlaceholderLists}
                                    endPointObj={endPointObj}
                                    apiUrl={apiUrlForUpdate}
                                />

                                <ManifestPlaceholderForm
                                    application_id={application_id}
                                    fetchDataAfterSubmit={fetchManifestPlaceholderLists}
                                    parentComponentState={state}
                                    resetParentState={resetParentState} />
                            </>
                }

            </div>
        </div>)

}


export default ManifestPlaceholder


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: 20,
        '& .input-with-btn': {
            '& input': {
                border: '0px!important'
            }
        }
    },

    cardBody: {
        backgroundColor: '#fff',
        padding: '20px 15px',
        '& .input-component': {
            marginBottom: '0px'
        },
        '& .box-file-upload': {
            padding: '10px'
        }
    },


    headerDiv: {
    },

    mainHeading: {
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: 1.4,
        //paddingBottom: '8px'
        color: "#2F2F2F",
    },

    subHeading: {
        color: '#666',
        fontSize: '12px',
        fontWeight: '300',
        lineHeight: 1
    }
}))