import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
// import { makeStyles } from '@mui/styles';
import { Input } from '../../../../components/genericComponents/Input';
import Grid from '@mui/material/Grid';
import { getCommonFunctions, ResetComponent } from "../ci_flow/SourceDetails";
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from "../../../../util/apiInvoker";
import properties from "../../../../properties/properties";
import MultiRow from '../../../../components/genericComponents/MultiRow';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import EnabledComponentEmptyDir from "./EnabledComponentEmptyDir";
import EnabledComponentHostpath from "./EnabledComponentHostpath";
import { RemoveFromArray } from "../../../../util/util";
import MultiRowCopy from "../../../../components/genericComponents/MutiRowCopy";
// const useStyles = makeStyles(() => ({
//   root: {
//     flexGrow: 1,
//     marginTop: '3rem'
//   }
// }));

const ConfigSecret = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const extraProps = props.extraProps;
  const project_env_id = extraProps.project_env_id ? extraProps.project_env_id : null;
  const type = props.type ? props.type : null;
  const [state, setState] = useState(prev_state ? prev_state : type == "sidecar" ? getConfigSecretDefaultSidecarState() : getConfigSecretDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  useEffect(() => {
    setState(new_state => ({ ...new_state, ...prev_state }))
  }, [prev_state])


  return (
    <>
      {
        type == "sidecar" ?
          <div className="sidecar-edit-input-div">
            <div className="formTag card-add-service-head" style={{ borderTop: '1px solid #e7e7eb', borderRadius: '0px', marginBottom: '0px' }}>
              <h4 className='mainHeading'>Configuration &amp; Secrets</h4>
            </div>
            <Grid container justify="center" style={{ backgroundColor: '#fff' }}>
              <div className='fromClass'>
                <Grid item lg={12}>
                  <Grid container justify="center">
                    <Grid lg={12} direction="row" style={{ padding: '0px', }}>
                      <div className='card card-cont' style={{ margin: '0px', borderRadius: '0px', border: '0px' }}>

                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="configMap_file_path"
                          label="Define from ConfigMap"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentConfigMap project_env_id = {project_env_id} extraProps={extraProps} prev_state={state.configMap_file_childs} inherits={state.child_inherits.configMap_file_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.configMap_file_childs} />}
                        />
                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="configMap_sub_path"
                          label="Mount ConfigMap key on subpath"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentConfigMapSubPath project_env_id={project_env_id} extraProps={extraProps} prev_state={state.configMap_sub_childs} inherits={state.child_inherits.configMap_sub_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.configMap_sub_childs} />}
                        />
                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="secret_file_path"
                          label="Define from Secrets"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentSecret project_env_id = {project_env_id} extraProps={extraProps} prev_state={state.secret_file_childs} inherits={state.child_inherits.secret_file_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.secret_file_childs} />}
                        />
                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="secret_sub_path"
                          label="Mount Secret key on subpath"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentSecretMapSubPath project_env_id={project_env_id} extraProps={extraProps} prev_state={state.secret_sub_childs} inherits={state.child_inherits.secret_sub_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.secret_sub_childs} />}
                        />
                      </div>

                      {/* <div className="card">
                        <Input
                          type="switch"
                          name="define_empty_dir"
                          label="Define Empty Directory"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentEmptyDir
                            extraProps={extraProps}
                            prev_state={state.empty_dir_childs}
                            inherits={state.child_inherits.empty_dir_childs}
                            variant="sidecars" />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.empty_dir_childs} />}
                        />
                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="define_hostpath"
                          label="Define Hostpath"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentHostpath
                            extraProps={extraProps}
                            prev_state={state.host_path_childs}
                            inherits={state.child_inherits.host_path_childs}
                            variant="sidecars" />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.host_path_childs} />}
                        />
                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="pvc_file_path"
                          label="Bind PVCs"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentPVC extraProps={extraProps} prev_state={state.pvc_file_childs} inherits={state.child_inherits.pvc_file_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.pvc_file_childs} />}
                        />
                      </div>

                      <div className="card">
                        <Input
                          type="switch"
                          name="entrypoint_command_args"
                          label="Do you have custom entry point (Command)"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentCommandAndCommandArgs
                            extraProps={extraProps}
                            prev_state={state.command_and_command_args}
                            inherits={state.child_inherits.command_and_command_args}

                            />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.command_and_command_args} />}
                        />
                      </div> */}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>
          :
          <div className="ci-cd-edit-input-div">
            <div className="formTag card-add-service-head" style={{ borderTop: '1px solid #e7e7eb', borderRadius: '0px', marginBottom: '0px' }}>
              <h4 className='mainHeading'>Configuration &amp; Secrets</h4>
            </div>
            <Grid container justify="center" style={{ backgroundColor: '#fff' }}>
              <div className='fromClass'>
                <Grid item lg={12}>
                  <Grid container justify="center">
                    <Grid lg={12} direction="row" style={{ padding: '0px', }}>
                      <div className='card card-cont' style={{ margin: '0px', borderRadius: '0px', border: '0px' }}>

                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="configMap_file_path"
                          label="Define from ConfigMap"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentConfigMap project_env_id = {project_env_id} extraProps={extraProps} prev_state={state.configMap_file_childs} inherits={state.child_inherits.configMap_file_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.configMap_file_childs} />}
                        />
                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="configMap_sub_path"
                          label="Mount ConfigMap key on subpath"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentConfigMapSubPath project_env_id = {project_env_id} extraProps={extraProps} prev_state={state.configMap_sub_childs} inherits={state.child_inherits.configMap_sub_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.configMap_sub_childs} />}
                        />
                      </div>
                      <div className="card">
                        <Input
                          type="switch"
                          name="secret_file_path"
                          label="Define from Secrets"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentSecret project_env_id = {project_env_id} extraProps={extraProps} prev_state={state.secret_file_childs} inherits={state.child_inherits.secret_file_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.secret_file_childs} />}
                        />
                      </div>
                      
                      <div className="card">
                        <Input
                          type="switch"
                          name="secret_sub_path"
                          label="Mount Secret key on subpath"
                          onChangeHandler={commonFunctions.toggleState}
                          data={state.data}
                          error={state.error}
                          enabledComponent={<EnabledComponentSecretMapSubPath project_env_id={project_env_id} extraProps={extraProps} prev_state={state.secret_sub_childs} inherits={state.child_inherits.secret_sub_childs} />}
                          disabledComponent={<ResetComponent inherits={state.child_inherits.secret_sub_childs} />}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>

      }

    </>
  );
};

ConfigSecret.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default ConfigSecret;


export function getPvcComponentDefaultState() {
  return {
    pvcs: [],
    child_inherits: {
      pvc_childs: {}
    }
  };
}


const EnabledComponentPVC = props => {
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getPvcComponentDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, props.inherits);

  const service_id = props.extraProps.service_id;
  const component_env_id = props.extraProps.component_env_id

  function getPvcs() {

    var requestInfo = {
      endPoint: GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.pvc_list),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    // requestInfo.endPoint = requestInfo.endPoint + "?all=true";
    InvokeApi(requestInfo, fetchSuccessPVCSListing, fetchFailpvcsListing);

  }

  function fetchSuccessPVCSListing(response) {
    console.log(response, "fdsaklmkflsakl")
    var pvcs_list = []
    if (response.length > 0) {
      response.map(element => {
        pvcs_list.push({ label: element, id: element })
      });
      console.log(pvcs_list, "fdsaklmkflsakl")
      setState(new_state => ({
        ...new_state,
        pvcs: pvcs_list
      }))
    }

    console.log(state.pvcs, "fdsaklmkflsakl")
  }
  function fetchFailpvcsListing(error) {
    console.log("SADads")
  }

  function loadPvcs() {
    var pvcs = state.pvcs.length == 0 ? getPvcs() : null
  }

  useEffect(() => {
    loadPvcs()
  }, []);

  return (
    <>
      <MultiRow RepeatableComponent={PVCsFormBody} HeaderComponent={PVCsHead} prev_state={state.pvc_childs} inherits={state.child_inherits.pvc_childs} zeroRowsAllowed={true}
        repeatableComponentProps={{ pvcs: state.pvcs }} />
    </>
  )
}

EnabledComponentPVC.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const PVCsHead = props => {
  return (
    <>
      <Grid item lg={5}>
        <div className="font-12">Select Volume Name</div>
      </Grid>
      <Grid item lg={5}>
        <div className="font-12">Volume Mount path</div>
      </Grid>
    </>
  )
}
export function getPVSsBodyDefaultState() {
  return {
    data: {},
    error: {},
    validations: {
      pvc_name: [VALIDATION_TYPE_REQUIRED],
      pvc_path: [VALIDATION_TYPE_REQUIRED],
    },
  };
}
const PVCsFormBody = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const pvcs_list = props.extraProps.pvcs ? props.extraProps.pvcs : [];
  const [state, setState] = useState(prev_state ? prev_state : getPVSsBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);



  return (
    <>
      <Grid item lg={5}>
        <Input
          type="select"
          list={pvcs_list}
          name="pvc_name"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
      <Grid item lg={5}>
        <Input
          type="text"
          placeholder="Volume Mount path"
          name="pvc_path"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
    </>
  )
}

export const getConfigMapSubPathDefaultState = () => {
  return {
    configMapNames: [],
    child_inherits: {
      configMap_SubPath_childs: {}
    }
  };
}

export const getSecretSubPathDefaultState = () => {
  return {
    secretMapNames: [],
    child_inherits: {
      secret_SubPath_childs: {}
    }
  };
}

const EnabledComponentConfigMapSubPath = props => {
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getConfigMapSubPathDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, props.inherits)
  const cluster_id = props.extraProps.cluster_id;
  const project_env_id = props.project_env_id
  const namespace_name = props.extraProps.namespace_name
  function getConfigMaps() {

    var requestInfo = {
      endPoint: GenerateURL({ cluster_id:cluster_id, namespace_name:namespace_name }, properties.api.allConfigmaps),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    requestInfo.endPoint = requestInfo.endPoint + "?all=true";
    InvokeApi(requestInfo, fetchSuccessConfigListing, fetchFailConfigListing);
  }

  function fetchSuccessConfigListing(response) {
    console.log(response, "fsjadjsa")
    const ConfigMapNames = []
    if (response.items && response.items.length == 0) {
      setState(new_state => ({
        ...new_state,
        error: { ...new_state.error, config_name: "No Config Map available in the Namespace" }
      }))
    } else {
      response.items.forEach(element => {
        ConfigMapNames.push({ label: element.metadata.name, id: element.metadata.name })
      });
      setState(new_state => ({
        ...new_state,
        ConfigMapNames: ConfigMapNames
      }))
    }
  }

  function fetchFailConfigListing(error) {
    setState(new_state => ({
      ...new_state,
      error: { ...new_state.error, config_name: error.detail }
    }))
  }
  function loadConfigMap() {
    var configMaps = state.configMapNames.length == 0 ? getConfigMaps() : null
  }
  useEffect(() => {
    loadConfigMap()
  }, []);

  return (
    <>
      <MultiRowCopy RepeatableComponent={ConfigMapKeyBody} HeaderComponent={ConfigMapKeyHeder} prev_state={state.configMap_SubPath_childs} inherits={state.child_inherits.configMap_SubPath_childs} zeroRowsAllowed={true}
        repeatableComponentProps={{ configMapNames: state.ConfigMapNames, cluster_id: cluster_id, namespace_name: namespace_name }} headerText={"Config Map"} addText={"Another ConfigMap"} borderBottom={true} />
    </>
  )
}

const EnabledComponentSecretMapSubPath = props => {
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getSecretSubPathDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, props.inherits)
  const cluster_id = props.extraProps.cluster_id;
  const project_env_id = props.project_env_id 
  const namespace_name = props.extraProps.namespace_name

  function getSecrets() {

    var requestInfo = {
      endPoint: GenerateURL({ cluster_id: cluster_id, namespace_name: namespace_name }, properties.api.allSecrets),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    requestInfo.endPoint = requestInfo.endPoint + "?all=true";
    InvokeApi(requestInfo, fetchSuccessSecretListing, fetchFailSecretListing);

  }

  function fetchSuccessSecretListing(response) {
    const secrets = []
    if (response.items && response.items.length == 0) {
      setState(new_state => ({
        ...new_state,
        error: { ...new_state.error, secret_name: "No Secrets available in the Namespace" }
      }))
    } else {
      response.items.forEach(element => {
        secrets.push({ label: element.metadata.name, id: element.metadata.name })
      });
      setState(new_state => ({
        ...new_state,
        secrets: secrets
      }))
    }


  }
  function fetchFailSecretListing(error) {
    setState(new_state => ({
      ...new_state,
      error: { ...new_state.error, secret_name: error.detail }
    }))
  }



  useEffect(() => {
    getSecrets();
  }, []);

  return (
    <>
      <MultiRowCopy RepeatableComponent={SecretKeyBody} HeaderComponent={SecretKeyHeder} prev_state={state.secret_SubPath_childs} inherits={state.child_inherits.secret_SubPath_childs} zeroRowsAllowed={true}
        repeatableComponentProps={{ secrets: state.secrets, cluster_id: cluster_id, namespace_name: namespace_name }} headerText={"Secret"} addText={"Aonther Secret"} borderBottom={true} />
    </>
  )
}



const EnabledComponentConfigMap = props => {
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getConfigMapDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, props.inherits)
  const cluster_id = props.extraProps.cluster_id;
  const project_env_id = props.project_env_id
  const namespace_name = props.extraProps.namespace_name
  function getConfigMaps() {

    var requestInfo = {
      endPoint: GenerateURL({ cluster_id:cluster_id, namespace_name:namespace_name }, properties.api.allConfigmaps),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    requestInfo.endPoint = requestInfo.endPoint + "?all=true";
    InvokeApi(requestInfo, fetchSuccessConfigListing, fetchFailConfigListing);
  }

  function fetchSuccessConfigListing(response) {
    console.log(response, "fsjadjsa")
    const ConfigMapNames = []
    if (response.items && response.items.length == 0) {
      setState(new_state => ({
        ...new_state,
        error: { ...new_state.error, config_name: "No Config Map available in the Namespace" }
      }))
    } else {
      response.items.forEach(element => {
        ConfigMapNames.push({ label: element.metadata.name, id: element.metadata.name })
      });
      setState(new_state => ({
        ...new_state,
        ConfigMapNames: ConfigMapNames
      }))
    }
  }

  function fetchFailConfigListing(error) {
    setState(new_state => ({
      ...new_state,
      error: { ...new_state.error, config_name: error.detail }
    }))
  }
  function loadConfigMap() {
    var configMaps = state.configMapNames.length == 0 ? getConfigMaps() : null
  }
  useEffect(() => {
    loadConfigMap()
  }, []);

  return (
    <>
      <MultiRow RepeatableComponent={ConfigMapBody} HeaderComponent={ConfigMapHead} prev_state={state.configMap_childs} inherits={state.child_inherits.configMap_childs} zeroRowsAllowed={true}
        repeatableComponentProps={{ configMapNames: state.ConfigMapNames }} />
    </>
  )
}
EnabledComponentConfigMap.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const EnabledComponentEntryPoint = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getEnabledComponentEntryPointDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits)

  return (
    <>
      <Grid item lg={12} className="pd-10">
        <Input
          type="password"
          name="entrypoint"
          label="Enter Your Command"
          placeholder="Enter Your Command"
          data={state.data}
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
        />
      </Grid>
    </>
  )
}
EnabledComponentEntryPoint.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getEnabledComponentEntryPointDefaultState() {
  return {
    data: {},
    error: {},
    validations: {
      entrypoint: [VALIDATION_TYPE_REQUIRED],
    }
  };
}
const EnabledComponentCommandAndCommandArgs = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getEnabledComponentCommandAndCommandArgsDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  useEffect(() => {
    console.log(state, prev_state ,"lallslallal")
    setState(new_state => ({ 
      ...new_state, 
      ...prev_state,
      validations:{
        ...new_state.validations,
        command:prev_state?.data.cmd_args ? []: [VALIDATION_TYPE_REQUIRED],
        cmd_args: prev_state?.data.command ? [] : [VALIDATION_TYPE_REQUIRED]
      }
    }))
  }, [prev_state])
  
console.log(state, prev_state ,"lallslallal")
  const onChangeHandlerAndRemoveValidation = (e) =>{
    var key = e.target.name;
    var value = e.target.value;
    if(key === "command" && (value != undefined && value !="" && value!=null)){
      RemoveFromArray(state.validations.cmd_args, VALIDATION_TYPE_REQUIRED)
    }
    if(key === "cmd_args"){
      RemoveFromArray(state.validations.command, VALIDATION_TYPE_REQUIRED)
    }
    commonFunctions.onChangeHandler(e)
  }
  return (
    <>
      <Grid item lg={12} className="pd-10">
        <Input
          type="password"
          name="command"
          label="Enter Command"
          placeholder="Enter Command"
          data={state.data}
          error={state.error}
          onChangeHandler={onChangeHandlerAndRemoveValidation}
        />
        <Input
          type="password"
          name="cmd_args"
          label="Enter Command Args"
          placeholder="Enter Args"
          data={state.data}
          error={state.error}
          onChangeHandler={onChangeHandlerAndRemoveValidation}
        />
      </Grid>
    </>
  )
}
EnabledComponentCommandAndCommandArgs.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getEnabledComponentCommandAndCommandArgsDefaultState() {
  return {
    data: {},
    error: {},
    validations: {
      command: [VALIDATION_TYPE_REQUIRED],
      cmd_args: [VALIDATION_TYPE_REQUIRED],
    }
  };
}



const EnabledComponentSecret = props => {
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getSecretComponentDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, props.inherits)
  const cluster_id = props.extraProps.cluster_id;
  const namespace_name = props.extraProps.namespace_name
  const project_env_id = props.project_env_id
  function getSecrets() {

    var requestInfo = {
      endPoint: GenerateURL({ cluster_id:cluster_id, namespace_name:namespace_name}, properties.api.allSecrets),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    requestInfo.endPoint = requestInfo.endPoint + "?all=true";
    InvokeApi(requestInfo, fetchSuccessSecretListing, fetchFailSecretListing);

  }

  function fetchSuccessSecretListing(response) {
    const secrets = []
    if (response.items && response.items.length == 0) {
      setState(new_state => ({
        ...new_state,
        error: { ...new_state.error, secret_name: "No Secrets available in the Namespace" }
      }))
    } else {
      response.items.forEach(element => {
        secrets.push({ label: element.metadata.name, id: element.metadata.name })
      });
      setState(new_state => ({
        ...new_state,
        secrets: secrets
      }))
    }
    
    
  }
  function fetchFailSecretListing(error) {
    setState(new_state => ({
      ...new_state,
      error: { ...new_state.error, secret_name: error.detail }
    }))
  }

  function loadSecrets() {
    var secrets = state.secrets.length == 0 ? getSecrets() : null
  }

  useEffect(() => {
    loadSecrets()
  }, []);

  return (
    <>
      <MultiRow RepeatableComponent={SecretBody} HeaderComponent={SecretHead} prev_state={state.secert_childs} inherits={state.child_inherits.secert_childs} zeroRowsAllowed={true}
        repeatableComponentProps={{ secrets: state.secrets }} />
    </>
  )
}

EnabledComponentSecret.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const ConfigMapBody = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const configMapNames = props.extraProps.configMapNames ? props.extraProps.configMapNames : [];
  const [state, setState] = useState(prev_state ? prev_state : getConfigMapBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);


  return (
    <>
      <Grid item lg={5}>
        <Input
          type="select"
          list={configMapNames}
          name="configMap_name"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
      <Grid item lg={5}>
        <Input
          type="text"
          placeholder="Volume Mount path"
          error={state.error}
          name="configMap_mount_path"
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>

    </>
  )
}

const ConfigMapKeyBody = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const configMapNames = props.extraProps.configMapNames ? props.extraProps.configMapNames : [];
  const cluster_id = props.extraProps.cluster_id;
  const namespace_name = props.extraProps.namespace_name
  const [state, setState] = useState(prev_state ? prev_state : getConfigMapKeyDefaultState()) //getConfigMapBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  function getConfigMapKeys() {
    var requestInfo = {
      endPoint: GenerateURL({ configmap_name: state.data.comfigMap_for_subpath }, properties.api.get_configmap_keys),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    requestInfo.endPoint = requestInfo.endPoint + `?cluster_id=${cluster_id}&namespace_name=${namespace_name}`;
    InvokeApi(requestInfo, getConfigMapKeySuccess, getConfigMapKeyFailed);
  }

  function getConfigMapKeySuccess(response) {
    console.log("sajbhdajdhb", response)

    if (response && response.length > 0) {
      let configMapKeys = [];
      response.forEach((item) => {
        configMapKeys.push({ label: item, id: item })
      })
      setState((new_state) => ({
        ...new_state,
        data: {
          ...new_state.data,
          configMapKeys: configMapKeys,
        }
      }))
    } else {
      setState(new_state => ({
        ...new_state,
        error: { ...new_state.error, configMap_key: "No Config Map available in the Namespace" }
      }))
    }
  }

  function getConfigMapKeyFailed(error) {
    console.log("sajbhdajdhb", error)
    setState(new_state => ({
      ...new_state,
      error: { ...new_state.error, configMap_key: error.detail }
    }))
  }

  function onChangeHandler(e) {
    const k = e.target.name;
    const v = e.target.value;
    setState(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        [k]: v,
      },
      error: {
        ...new_state.error,
        [k]: ""
      }
    }));
    //getConfigMapKeys();
  }
  useEffect(() => {
    if (state.data.comfigMap_for_subpath) {
      getConfigMapKeys();
    }
  }, [state.data.comfigMap_for_subpath])

  return (
    <>
      <Grid item lg={11}>
        <Input
          type="select"
          list={configMapNames}
          label="Select CongifMap"
          name="comfigMap_for_subpath"
          error={state.error}
          onChangeHandler={onChangeHandler}
          data={state.data} />
      </Grid>

      <MultiRowCopy RepeatableComponent={ConfigMapKeyNSubPathBody} HeaderComponent={ConfigMapKeyNSubPathHeader} prev_state={state.configMap_keys_child} inherits={state.child_inherits.configMap_keys_child} zeroRowsAllowed={true}
        repeatableComponentProps={{ configMapKeys: state.data.configMapKeys }} variant="with-header-component" addText={"Another Key"} />
    </>
  );
}

const SecretKeyBody = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const secrets = props.extraProps.secrets ? props.extraProps.secrets : [];
  const cluster_id = props.extraProps.cluster_id;
  const namespace_name = props.extraProps.namespace_name
  const [state, setState] = useState(prev_state ? prev_state : getSecretKeyDefaultState()) //getConfigMapBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  function getSecretMapKeys() {
    var requestInfo = {
      endPoint: GenerateURL({ secret_name: state.data.secret_for_subpath }, properties.api.get_secrets_keys),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    requestInfo.endPoint = requestInfo.endPoint + `?cluster_id=${cluster_id}&namespace_name=${namespace_name}`;
    InvokeApi(requestInfo, getSecretKeySuccess, getSecretKeyFailed);
  }

  function getSecretKeySuccess(response) {
    console.log("sajbhdajdhb", response)

    if (response && response.length > 0) {
      let SecretKeys = [];
      response.forEach((item) => {
        SecretKeys.push({ label: item, id: item })
      })
      setState((new_state) => ({
        ...new_state,
        data: {
          ...new_state.data,
          secretKeys: SecretKeys,
        }
      }))
    } else {
      setState(new_state => ({
        ...new_state,
        error: { ...new_state.error, configMap_key: "No Config Map available in the Namespace" }
      }))
    }
  }

  function getSecretKeyFailed(error) {
    console.log("sajbhdajdhb", error)
    setState(new_state => ({
      ...new_state,
      error: { ...new_state.error, Secret_for_subpath: error.detail }
    }))
  }

  function onChangeHandler(e) {
    const k = e.target.name;
    const v = e.target.value;
    setState(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        [k]: v,
      },
      error: {
        ...new_state.error,
        [k]: ""
      }
    }));
    //getSecretMapKeys();
  }
  useEffect(() => {
    if (state.data.secret_for_subpath) {
      getSecretMapKeys();
    }
  }, [state.data.secret_for_subpath])

  return (
    <>
      <Grid item lg={11}>
        <Input
          type="select"
          list={secrets}
          label="Select Secret"
          name="secret_for_subpath"
          error={state.error}
          onChangeHandler={onChangeHandler}
          data={state.data} />
      </Grid>

      <MultiRowCopy RepeatableComponent={SecretKeyNSubPathBody} HeaderComponent={SecretKeyNSubPathHeader} prev_state={state.secret_keys_child} inherits={state.child_inherits.secret_keys_child} zeroRowsAllowed={true}
        repeatableComponentProps={{ secretKeys: state.data.secretKeys }} variant="with-header-component" addText={"Another Key"} />
    </>
  );
}

const ConfigMapKeyNSubPathBody = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  //const configMapNames = props.extraProps.configMapNames ? props.extraProps.configMapNames : [];
  const configMapKeys = props.extraProps.configMapKeys ? props.extraProps.configMapKeys : [];
  const [state, setState] = useState(prev_state ? prev_state : getConfigMapKeyNSubPathBody()) //getConfigMapBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  return (
    <>
      <Grid item lg={6}>
        <Input
          type="select"
          list={configMapKeys}
          name="configMap_key"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
      <Grid item lg={5}>
        <Input
          type="text"
          placeholder="Enter Sub Path"
          error={state.error}
          name="sub_path"
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
    </>
  );
}

const ConfigMapKeyNSubPathHeader = (props) => {
  return (
    <>
      <Grid item lg={6}>
        <div className="font-12">Config Map Key</div>
      </Grid>
      <Grid item lg={6}>
        <div className="font-12">Sub Path</div>
      </Grid>
    </>
  );

}

const SecretKeyNSubPathBody = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  //const configMapNames = props.extraProps.configMapNames ? props.extraProps.configMapNames : [];
  const secretKeys = props.extraProps.secretKeys ? props.extraProps.secretKeys : [];
  const [state, setState] = useState(prev_state ? prev_state : getSecretKeyNSubPathBody()) //getConfigMapBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  return (
    <>
      <Grid item lg={6}>
        <Input
          type="select"
          list={secretKeys}
          name="secret_key"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
      <Grid item lg={5}>
        <Input
          type="text"
          placeholder="Enter Sub Path"
          error={state.error}
          name="sub_path"
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
    </>
  );
}

const SecretKeyNSubPathHeader = (props) => {
  return (
    <>
      <Grid item lg={6}>
        <div className="font-12">Secret Key</div>
      </Grid>
      <Grid item lg={6}>
        <div className="font-12">Sub Path</div>
      </Grid>
    </>
  );

}

const ConfigMapKeyHeder = () => {
  return (
    <>
      <Grid item lg={5}>
        <div className="font-12">ConfigMap</div>
      </Grid>
    </>
  )
}
const SecretKeyHeder = () => {
  return (
    <>
      <Grid item lg={5}>
        <div className="font-12">Secrets</div>
      </Grid>
    </>
  )
}
ConfigMapBody.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const SecretBody = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const secretNamesList = props.extraProps.secrets ? props.extraProps.secrets : [];
  const [state, setState] = useState(prev_state ? prev_state : getSecretBodyDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  console.log(secretNamesList,"secretNamesList")

  return (
    <>
      <Grid item lg={5}>
        <Input
          type="select"
          list={secretNamesList}
          name="secret_name"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
      <Grid item lg={5}>
        <Input
          type="text"
          placeholder="Volume Mount path"
          name="secret_mount_path"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </Grid>
    </>
  )
}
SecretBody.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const ConfigMapHead = props => {

  return (
    <>
      <Grid item lg={5}>
        <div className="font-12">Select ConfigMap Name</div>
      </Grid>
      <Grid item lg={5}>
        <div className="font-12">Volume Mount path</div>
      </Grid>
    </>
  )
}
ConfigMapHead.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const SecretHead = props => {
  return (
    <>
      <Grid item lg={5}>
        <div className="font-12">Select Secret Name</div>
      </Grid>
      <Grid item lg={5}>
        <div className="font-12">Volume Mount path</div>
      </Grid>
    </>
  )
}
SecretHead.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getSecretBodyDefaultState() {
  return {
    data: {},
    error: {},
    validations: {
      secret_name: [VALIDATION_TYPE_REQUIRED],
      secret_mount_path: [VALIDATION_TYPE_REQUIRED],
    },
  };
}

export function getConfigMapBodyDefaultState() {
  return {
    data: {},
    error: {},
    validations: {
      configMap_name: [VALIDATION_TYPE_REQUIRED],
      configMap_mount_path: [VALIDATION_TYPE_REQUIRED],
    },
  };
}

export function getConfigMapKeyDefaultState() {
  return {
    data: {},
    error: {},
    child_inherits: {
      configMap_keys_child: {}
    },
    validation: {
      comfigMap_for_subpath: [VALIDATION_TYPE_REQUIRED],
      //sub_path: [VALIDATION_TYPE_REQUIRED],
    }
  }
}

export function getSecretKeyDefaultState() {
  return {
    data: {},
    error: {},
    child_inherits: {
      secret_keys_child: {}
    },
    validation: {
      secret_for_subpath: [VALIDATION_TYPE_REQUIRED],
      //sub_path: [VALIDATION_TYPE_REQUIRED],
    }
  }
}

export function getConfigMapKeyNSubPathBody() {
  return {
    data: {},
    error: {},
    validation: {
      configMap_key: [VALIDATION_TYPE_REQUIRED],
      sub_path: [VALIDATION_TYPE_REQUIRED],
    }
  }
}

export function getSecretKeyNSubPathBody() {
  return {
    data: {},
    error: {},
    validation: {
      secret_key: [VALIDATION_TYPE_REQUIRED],
      sub_path: [VALIDATION_TYPE_REQUIRED],
    }
  }
}


export function getSecretComponentDefaultState() {
  return {
    secrets: [],
    child_inherits: {
      secert_childs: {}
    }
  };
}


export function getConfigMapDefaultState() {
  return {
    configMapNames: [],
    child_inherits: {
      configMap_childs: {}
    }
  };
}

export function getConfigSecretDefaultState() {
  return {
    data: {},
    error: {},
    validations: {},
    child_inherits: {
      configMap_file_childs: {},
      secret_file_childs: {},
      configMap_sub_childs: {},
      secret_sub_childs: {},
    },
  };
}

export function getConfigSecretDefaultSidecarState() {
  return {
    data: {},
    error: {},
    validations: {},
    child_inherits: {
      configMap_file_childs: {},
      secret_file_childs: {},
      // entry_point_childs: {},
      configMap_sub_childs: {},
      secret_sub_childs: {},
      // pvc_file_childs: {},
      // command_and_command_args:{},
      // empty_dir_childs:{},
      // host_path_childs:{}
    },
  };
}

export function configDataParser(data) {
  var final_array = new Array();
  Object.keys(data).forEach(config => {
    if (config == "data" || config == "child_inherits" || config == 'show_view' || config == "count") { }
    else {
      final_array = [...final_array, {
        name: data[config].data.configMap_name,
        mount_path: data[config].data.configMap_mount_path
      }]

    }

  })
  return final_array;
}