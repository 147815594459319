import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom'
import NewReleaseStrip from '../../components/genericComponents/NewReleaseStrip';

const AppBreadcrumb = (props) => {
    const classes = useStyles();
    const list = props.list.reverse();
    const userSettings = props.userSettings;
    //const companyName = properties.company_name;
    //const companyLogo = properties.company_logo;
    const routes_url = props.routes_url;
    const super_admin_flag = props.super_admin_flag
    let url_name = routes_url.path ? routes_url.path : "Dashboard";
    console.log(url_name, super_admin_flag, "Fdsajfjsa")


    // const [state, setState] = useState();
    // useEffect(() => {
    //     fetchIstioData();
    // }, [])

    // function fetchIstioData() {
    //     let requestInfo = {
    //         endPoint: GenerateURL({}, properties.api.account_setting_url),
    //         httpMethod: "GET",
    //         httpHeaders: { "Content-Type": "application/json" }
    //     }
    //     setState(new_state => ({
    //         ...new_state,
    //         show_loading_icon: true
    //     }));
    //     InvokeApi(requestInfo, handleIstioDataSuccessApiHit, handleIstioDataFailedApiHit);

    // }
    // function handleIstioDataSuccessApiHit(data) {
    //     console.log(data, "welocom")
    //     localStorage.setItem('super_admin', JSON.stringify(data));
    //     setState(new_state => ({
    //         ...new_state,
    //         istio_data: data.results,

    //         show_loading_icon: false
    //     }));

    // }

    // function handleIstioDataFailedApiHit(error) {
    //     setState(new_state => ({
    //         ...new_state,
    //         error: error,
    //     }))
    // }


    function get_current_url_label(url) {
        switch (url) {
            case "/health":
                return (
                    { label: "Health", url: url }
                );
            case "/activity":
                return (
                    { label: "Activity", url: url }
                );
            case "/credentials":
                return (
                    { label: "Credentails", url: url }
                );
            case "/integration/containerRegistries":
                return (
                    { label: "Container Registries", url: url }
                );
            case "/secrets/list":
                return (
                    { label: "Secrets List", url: url }
                );
            case "/tickets/list":
                return (
                    { label: "Tickets List", url: url }
                );
            case "/role-groups":
                return (
                    { label: "Roles Group", url: url }
                );
            case "/system-settings":
                return (
                    { label: "BP System Global Settings ", url: url }
                );
            default:
                return (
                    <div className="md-step-bar-left "></div>
                );
        }
    }
    return (
        <div className={classes.root}>
            <div className="d-flex align-center">
                <div className="company-logo-breadcrumb">
                    <img src={"/images/clients_logos/buildpiper-logo-black.png"} alt={"BuildPiper"} />
                    {/* <><span className="flaticon-city-buildings-silhouette"></span> <span className="companyName">{"BuildPiper"}</span></> */}

                </div>
                <span className="flaticon-right-arrow-forward"></span>
                {
                    list.length > 0 ?
                        list.map((element, key) => (
                            <>
                                {console.log(element, "dsfjkajk")}
                                {
                                    element.disable_click ?
                                        <span className={classes.breadcrumbLink}>{element.label}</span> :
                                        <>
                                            {element.link ?

                                                <Link to={element.link} className={classes.breadcrumbLink}>{element.label}</Link>
                                                :
                                                <span className={classes.breadcrumbLink}>{element.label}</span>
                                            }

                                        </>

                                }
                                {
                                    !(key + 1 == list.length) ?
                                        <span className=" flaticon-right-arrow-forward"></span> : null
                                }
                            </>
                        ))
                        : <>
                            <Link to={super_admin_flag ? "/ClusterDashboard" : "/ApplicationDashboard"} className={classes.breadcrumbLink}>{"Dashboard"}</Link>
                            {
                                get_current_url_label(url_name).label &&
                                <>
                                    <span className=" flaticon-right-arrow-forward"></span>
                                    <Link to={get_current_url_label(url_name).url} className={classes.breadcrumbLink}>{get_current_url_label(url_name).label}</Link>
                                </>
                            }

                        </>
                }
            </div>
            {
                userSettings == true ?
                    <a className="d-flex align-center " style={{ color: '#124d9b', fontSize: '14px', justifySelf: 'flex-end' }} href="/ClusterDashboard"><span className="mr-5">Back to application</span> <ArrowForwardIcon style={{ color: '#124d9b', fontSize: '18px' }} /></a>
                    : null
            }
            {/* <NewReleaseFlag /> */}
            <NewReleaseStrip />
        </div>
    )
}

AppBreadcrumb.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default AppBreadcrumb



const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.13)',
        width: '100%',
        display: 'flex',
        position: 'fixed',
        height: '45px',
        alignItems: 'center',
        padding: '0px 24px',
        backgroundColor: '#fff',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        top: '50px',
        left: '0px',
        zIndex: 999,
        '& .company-logo-breadcrumb': {
            color: '#FE9C11',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& img': {
                width: '150px'
            },
            '& .companyName': {
                fontSize: '12px',
                color: '#707780'
            },
            '& .flaticon-city-buildings-silhouette': {
                color: '#FE9C11',
                '&:before': {
                    fontSize: '24px!important',
                    color: '#FE9C11',
                }
            }
        },
        '& [class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after': {
            color: '#000',
            backgroundColor: 'transparent'
        },
        '& .icon-settings':{
            
            '& #slide' : {
                position: "absolute",
                left: "-100px",
                width: "100px",
                height: "100px",
                background: "blue",
                transition: '1s'
            }
        }
        
        
    },
    logo: {
        height: '30px',
        paddingRight: '15px'
    },
    lineHeignt: {
        lineHeight: '0px'
    },
    breadcrumbLink: {
        fontSize: '13px',
        lineHeight: 1,
        color: '#0086ff',
        margin: '0px 15px',
    },
    serviceName: {
        display: 'flex',
        alignItems: 'center'
    }
}))
