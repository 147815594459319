import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles"
import { Input } from '../../components/genericComponents/Input';
import { getCommonFunctions } from '../serviceRevamp/add/ci_flow/SourceDetails';
import { Link } from 'react-router-dom';
import GenerateURL from '../../util/APIUrlProvider';
import properties from '../../properties/properties';
import InvokeApi, { PostData } from '../../util/apiInvoker';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../util/Validator';
import { Loading } from '../utils/Loading';
import { RemoveFromArray, showErrorHandlerUpdated } from '../../util/util';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';


const AddMonitoringSource = ({ open, handleClose, edit_id, refreshFn }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        data: {},
        error: {},
        showLeftPanel: true,
        loading_data: false,
        available_monitoring_resource_list: [],
        credential_list: [],
        validations: {
            resource: [VALIDATION_TYPE_REQUIRED],
            resource_name:[],
            monitoring_url: [VALIDATION_TYPE_REQUIRED],
            credential_id: [VALIDATION_TYPE_REQUIRED]
        }
    })
    const [showInput, setShowInput] = useState(false);
    const [data, setData] = useState({data:{},error:{}})
    const commonFunctions = getCommonFunctions(state, setState, {})
    const showInfoSection = JSON.parse(localStorage.getItem('monitoring_source_dialog_display_flag'));
    console.log("monitoring_source_dialog_display_flag",showInfoSection)
    var imgMapping = {
        1000: "/images/sourceMonitoringIcons/prometheus.svg",
        2000: "/images/sourceMonitoringIcons/new_relic.svg",
        3000: "/images/sourceMonitoringIcons/data_dog_log.svg",
        4000: "/images/sourceMonitoringIcons/app_dynamic.svg",
        5000: "/images/sourceMonitoringIcons/splunk.svg",
    };
    useEffect(() => {

        fetchAllAvailableResources()


    }, [edit_id])


    const fetchAndSetDatatoState = () => {
        var requestInfo = {
            endPoint: GenerateURL({ id: edit_id }, properties.api.monitoring_resource_update),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        // requestInfo.endPoint = requestInfo.endPoint + "?all=true";

        InvokeApi(requestInfo, fetchAndSetDatatoStateSuccess, fetchAndSetDatatoStateFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true,
        }));
    }
    const fetchAndSetDatatoStateSuccess = (response) => {
       
        setState(new_state => ({
            ...new_state,
            loading_data: false,
            data: {
                ...new_state.data,
                ...response,
                credential_id: response?.credential_id?.id || null
            }
        }));

    }


    console.log(state.data,"fetchAndSetDatatoStateFailure")
   
    const fetchAndSetDatatoStateFailure = () => {
        setState(new_state => ({
            ...new_state,
            loading_data: false,
        }));
    }


    function fetchAllAvailableResources() {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.monitoring_resource),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        // requestInfo.endPoint = requestInfo.endPoint + "?all=true";

        InvokeApi(requestInfo, allAvailableResourcesSuccess, allAvailableResourcesFailure);
        setState(new_state => ({
            ...new_state,
            default_resource_loading: true,
        }));
    }

    function allAvailableResourcesSuccess(data) {
        fetchCredentials();
        if (data && data.length > 0) {
            var available_resources = data.length > 0 ? data.map(item => ({
                id: item.id,
                label: item.name,
                imgUrl: imgMapping[item.id] || "/images/sourceMonitoringIcons/remixicon-line.svg"
            })) : []
            //{ label: 'others', id: 'other', imgUrl: "/images/sourceMonitoringIcons/remixicon-line.svg" }
            setState(new_state => ({
                ...new_state,
                available_monitoring_resource_list: [...available_resources],
                default_resource_loading: false,
                data: {
                    ...new_state.data,
                    resource: available_resources?.[1]?.id
                }
            }))
        } else {
            setState(new_state => ({
                ...new_state,
                available_monitoring_resource_list: [{ id: '', label: 'No Secret Added yet.' }],
                default_resource_loading: false,
            }));
        }

    }
    console.log(state.data.resource, "available_monitoring_resource_list")

    function allAvailableResourcesFailure(error, exception) {

        setState(new_state => ({
            ...new_state,
            default_resource_loading: false,
            error_in_load_credentials: error
        }));

    }


    function fetchCredentials() {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.credential_all),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        requestInfo.endPoint = requestInfo.endPoint + "?all=true";

        InvokeApi(requestInfo, CredentialsFetchSuccess, CredentialsFetchFailure);
        setState(new_state => ({
            ...new_state,
            credentials_status: "LOADING",
        }));
    }

    function CredentialsFetchSuccess(data) {
        if(edit_id){
            fetchAndSetDatatoState()
        }
        if (data && data.results) {
            var credentials = data.results.length > 0 ? data.results.map(

                item => {
                    console.log(item, "fdsanfjknsajk")
                    return {
                        id: item.id,
                        label: item.name
                    }
                }
            ) : []
            setState(new_state => ({
                ...new_state,
                credential_list: credentials,
                credentials_status: "SUCCESS",
            }))
        } else {
            setState(new_state => ({
                ...new_state,
                credential_list: [{ id: '', label: 'No Secret Added yet.' }],
                credentials_status: "SUCCESS",
            }));
        }

    }

    function CredentialsFetchFailure(error, exception) {

        setState(new_state => ({
            ...new_state,
            credentials_status: "FAILED",
            error_in_load_credentials: error
        }));

    }
    const handleCloseLeftStip = () => {
        if(data?.data?.dont_show_again){
            setDataToLocalStorage()
        }
        setState(new_state => ({
            ...new_state,
            showLeftPanel: false
        }));
    }
    function setDataToLocalStorage() {
        localStorage.setItem('monitoring_source_dialog_display_flag', data.data.dont_show_again);
    }
    function onChangeDoNotshow(e){
        var key = e.target.name;
        var value = e.target.value;
        if (key == "dont_show_again") {
            value = !data.data.dont_show_again;
        }
        setData((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value,
            },
            error: {
                ...prevState.error,
                [key]: '', // Reset error on change
            },
        }));
    }
  
  


    const onChangeHandlerResource = (e) => {
        const { name, value } = e.target;
        if (name === "resource" && value == 6000) {
            setShowInput(true)
            state.validations.resource_name.push(VALIDATION_TYPE_REQUIRED)
        }else{
            setShowInput(false)
            RemoveFromArray(state.validations.resource_name, VALIDATION_TYPE_REQUIRED)
        }
        setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [name]: Number(value),
            },
            error: {
                ...prevState.error,
                [name]: '', 
            },
        }));
    };



    function validateAndSave() {
        var result = ValidateDataSet(state.data, state.validations);

        if (edit_id) {
            var post_url = GenerateURL(
                { id: edit_id },
                properties.api.monitoring_resource_update
            );
        } else {
            var post_url = GenerateURL({}, properties.api.monitoring_resource_get);
        }

        if (result.valid) {
            var basic_details = {};
            basic_details = {
                ...state.data,
            };
            if(state.data.resource_name){
                basic_details.resource = state.data.resource_name;
            }else{
                delete state.data.resource_name
            }
            PostData(post_url, basic_details, onSaveSuccess, onSaveFail);
            setState((new_state) => ({
                ...new_state,
                loading_data: true,
                error_msg: null,
            }));
           
           
        } else {
            setState((new_state) => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }));
        }
    }
    function onSaveFail(error) {
        var error = showErrorHandlerUpdated(error);
        setState((new_state) => ({
            ...new_state,
            loading_data: false,
            show_save_msg: "failed",
            error_in_save: error,
            error_msg: error,
            data:{
                ...new_state.data,
                resource_name:null,
                credential_id:null,
                monitoring_url:""
            }
        }));
    }
    function onSaveSuccess(response) {
        
        handleClose();
        refreshFn();
        setState((new_state) => ({
            ...new_state,
            loading_data: false,
            error_in_save: null,
            canRedirect: true,
            show_save_msg: "success",
            error_msg: null,
        }));
    }

    const handleCloseAlert = () => {
        setState({ ...state, show_save_msg: null, });
    };


    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={`${classes.root} dialog-align-corner`}
            >
                <div className='d-grid ml-auto dialog-sub-component' style={showInfoSection ? { gridTemplateColumns: '450px' } : { gridTemplateColumns: '396px 450px' }}>
                        {
                            !showInfoSection &&
                        
                    <div className={state.showLeftPanel ? 'left-panel-dialog bg-white position-relative' : 'left-panel-dialog-down'}>
                        <div className={'d-flex align-center space-between left-panel-header pd-10'} style={{ padding: '10px 20px' }} >
                            <p>Information</p>
                            <button
                                className='btn btn-icon-only'
                                onClick={handleCloseLeftStip}
                            >
                                <span className='ri-close-line color-icon-secondary'></span>
                            </button>
                        </div>
                        <div className='pd-10' style={{ padding: '10px 20px' }}>
                            <p className='font-16 font-weight-600 color-primary mb-10'>What are Monitoring Tools?</p>
                            <p className='font-12 color-icon-secondary' style={{ color: '#404040' }}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply..
                            </p>
                        </div>
                        <div className='checkbox-only-divi' style={{ padding: '10px 20px' }}>
                            <Input
                                type="simple-checkbox"
                                name="dont_show_again"
                                label="Dont show this again"
                                mandatorySign
                                data={data.data}
                                error={data.error}
                                onChangeHandler={onChangeDoNotshow}
                            />
                        </div>
                    </div>
}
                    <div className='right-panel-dialog bg-white'>
                        <div className='pd-20 font-18 font-weight-600 color-white d-flex align-center space-between' style={{ backgroundColor: '#0086ff' }}>
                            <p>{edit_id ? "Edit":"Add"} Monitoring Source</p>
                            <button
                                className='btn float-cancel-button'
                                style={ showInfoSection ? { left: '396px' }: state.showLeftPanel ? {} : { left: '396px' }}
                                onClick={handleClose}
                            >
                                <span className='ri-close-line'></span>
                            </button>
                        </div>
                        <div className='pd-10'>
                            {
                                state.loading_data ?
                                    <div className='' style={{height: 400}}>
                                    <Loading varient="light" />
                                    </div>
                                     :
                                    <>
                                        {state.default_resource_loading ?
                                            <Loading varient="light" /> :
                                            <Input
                                                type="select-with-thumbnail"
                                                name="resource"
                                                label="Select Source"
                                                mandatorySign
                                                data={state.data}
                                                list={state.available_monitoring_resource_list}
                                                error={state.error}
                                                onChangeHandler={onChangeHandlerResource}
                                            />
                                        }
                                        {
                                            showInput &&
                                            <Input
                                                type="text"
                                                name="resource_name"
                                                label="Enter Source Name"
                                                placeholder="Kibana"
                                                mandatorySign
                                                data={state.data}
                                                error={state.error}
                                                onChangeHandler={commonFunctions.onChangeHandler}
                                            />
                                        }
                                        <Input
                                            type="text"
                                            name="monitoring_url"
                                            label="URL"
                                            placeholder="https://www.demo.grafana.com/"
                                            mandatorySign
                                            data={state.data}
                                            error={state.error}
                                            onChangeHandler={commonFunctions.onChangeHandler}
                                        />
                                        <Input
                                            type="select"
                                            name="credential_id"
                                            list={state.credential_list}
                                            label="Select Secrets"
                                            placeholder="test-username"
                                            mandatorySign
                                            onChangeHandler={commonFunctions.onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                            extraDiv={
                                                <Link className='text-anchor-blue d-flex align-center' to="/secrets/add"><span className='ri-add-line'></span> New Secret</Link>
                                            }
                                        />
                                    </>
                            }

                        </div>
                        <div className='footer-right-panel'>
                            <button className='btn btn-primary font-12 font-weight-500 d-flex align-center justify-center' onClick={validateAndSave}>Test &amp; Add Source</button>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={state.show_save_msg === "success" || state.show_save_msg === "failed"}
                onClose={handleCloseAlert}
                autoHideDuration={3000}>
                {
                    state.show_save_msg === "success" ?
                        <>
                            <Alert severity="success"  variant="filled">
                                {state.success_msg}
                            </Alert>
                        </> : state.show_save_msg === "failed" ?
                            <Alert severity="error"  variant="filled">
                                {state.error_in_save}
                            </Alert>

                            : null
                }
            </Snackbar>
        </>
    )
}

export default AddMonitoringSource


const useStyles = makeStyles((theme) => ({
    root: {
        '& .left-panel-dialog': {
            width: '396px',
            transition: 'width 5s',

            '& .left-panel-header': {
                borderBottom: '1px solid #f1f1f1'
            },
            '& .checkbox-only-divi': {
                position: 'absolute',
                bottom: '10px'
            },
        },
        '& .left-panel-dialog-down': {
            width: '0px',
            overflow: 'hidden',
            transition: `'width 5s', 'overflow 1s'`,

        },
        '& .footer-right-panel': {
            backgroundColor: '#fafafa',
            padding: '20px',
            position: 'absolute',
            bottom: '0px',
            width: '450px',
            // width:'100%',
            '& button': {
                marginLeft: 'auto'
            }
        }
    }
}));
