import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { Redirect, Link } from 'react-router-dom';
import { VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY, ValidateDataSet } from '../../../util/Validator';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import { Input } from '../../../components/genericComponents/Input';
import AlertStrip from '../../../components/AlertStrips';
import { GetPermitedURLs } from '../../../util/security';
import properties from '../../../properties/properties';
import { Loading } from '../../utils/Loading';

const AddMsDialog = (props) => {
    const classes = useStyles();
    const open = props.open;
    const application_id = props.application_id ? props.application_id : null
    const component_id = props.component_id ? props.component_id : null;
    const handleClose = props.handleClose ? props.handleClose : () => { };
    const refreshState = props.refreshState ? props.refreshState : () => { };
    const handleOpenAddEnvDialog = props.handleOpenAddEnvDialog ? props.handleOpenAddEnvDialog : () => { }
    const variant = props.variant
    const [showLoading, setShowLoading] = useState(false);
    const [canRedirect, setRedirect] = useState(false);
    const [state, setState] = useState({
        data: {
            build_strategy: "EVERY",
        },
        error: {},
        showLeftPanel: true,
        validations: {
            name: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY],
            build_strategy: [VALIDATION_TYPE_REQUIRED],
        },
        availableBuildStrategies: [
            {
                label: "Build once and promote",
                value: "ONCE"
            },
            {
                label: "Build for every environment",
                value: "EVERY"
            }
        ],
        service_id: null

    })
    const [showInput, setShowInput] = useState(false);
    const [data, setData] = useState({ data: {}, error: {} })
    const commonFunctions = getCommonFunctions(state, setState, {})
    const showInfoSection = JSON.parse(localStorage.getItem('show_ms_other_info'));


    console.log(props.variant, "jhdbhbshdbv")
    useEffect(() => {
        fetchSystemSettingsData()
        if (component_id)
            getService();

    }, [component_id]);


    function getBussinessFnList() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.bussiness_fn + "?all=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };
        setShowLoading(true);
        InvokeApi(requestInfo, getBussinessFnListSuccess, getBussinessFnListFailed);
    }
    const getBussinessFnListSuccess = (data) => {
        let list = data.results.map(item => { return { label: item.name, id: item.id } })
        setShowLoading(false);
        setState(new_state => ({
            ...new_state,
            business_fn_list: list
        }))
    }
    const getBussinessFnListFailed = (data) => {

        setShowLoading(false);
    }

    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setShowLoading(true);

    }

    function fetchSystemSettingsDataSuccess(data) {
        var business_fn_key = data.find(item => item.key == "BUSINESS FUNCTION REQUIRED FOR SERVICES"); // BUSINESS_FUNCTION
        console.log(business_fn_key, "business_fn_key")
        if (business_fn_key && business_fn_key.value && business_fn_key.value == "true") {
            getBussinessFnList()
        }
        setState(new_state => ({
            ...new_state,
            business_fn_key: business_fn_key,
        }));
        setShowLoading(false);
    }
    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }

    function getService() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: component_id }, properties.api.service_basic_details_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };
        setShowLoading(true);
        InvokeApi(requestInfo, handleGetServiceSuccess, () => { setShowLoading(false); });
    }
    const handleGetServiceSuccess = (data) => {
        setShowLoading(false);
        let business_fn_list = data.business_function.map(item => { return item.name })
        setState(new_state => ({
            ...new_state,
            ...data,
            data: {
                name: data.name,
                build_strategy: data.build_strategy,
                external_name: data.external_name ? data.external_name : "",
                business_function: business_fn_list
            }
        }))
    }

    const handleCloseLeftStip = () => {
        if (data?.data?.dont_show_again) {
            setDataToLocalStorage()
        }
        setState(new_state => ({
            ...new_state,
            showLeftPanel: false
        }));
    }
    function setDataToLocalStorage() {
        localStorage.setItem('show_ms_other_info', data.data.dont_show_again);
    }
    function onChangeDoNotshow(e) {
        var key = e.target.name;
        var value = e.target.value;
        if (key == "dont_show_again") {
            value = !data.data.dont_show_again;
        }
        setData((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value,
            },
            error: {
                ...prevState.error,
                [key]: '', // Reset error on change
            },
        }));
    }

    const handleCloseAlert = () => {
        setState({ ...state, show_save_msg: null, });
    };

    function validateAndSave() {

        var result = ValidateDataSet(state.data, state.validations);
        var post_url = GenerateURL({ application_id: application_id }, properties.api.service_basic_details_post_url)
        if (component_id) {
            post_url = GenerateURL({ service_id: props.component_id }, properties.api.service_basic_details_get_url)
        }
        if (result.valid) {
            var post_data = {
                project_id: Number(application_id),
                ...state.data
            }
            post_data = {
                ...post_data,
                repo_url: "NA"
            }
            if(state.business_fn_key && state.business_fn_key.value && state.business_fn_key.value == "true" ){

            }else{
                post_data.business_function =[]
            }
            setShowLoading(true);
            PostData(post_url, post_data, postSuccess, postFail);
        }
        else {
            setState(new_state => ({
                ...new_state,
                error: result.error
            }))
        }
    }
    function postSuccess(response) {
        setShowLoading(false);
        console.log(response, "pipi")
        setState(new_state => ({
            ...new_state,
            service_id: response.id,
            success_msg: component_id ? "Changes Updated" : "Microservice with the name of " + response.name + " added",
            show_save_msg: "success"
        }))
        GetPermitedURLs();
        // setRedirect(true);
        handleClose();
        if (variant !== "edit_mode") {
            handleOpenAddEnvDialog(response)
        }
        if (component_id) {
            refreshState()
        }
    }
    function postFail(response) {
        setShowLoading(false);
        setState(new_state => ({
            ...new_state,
            error: response,
            show_save_msg: "failed",
            error_in_save: "Unable to save data."
        }))
    }


    const onKeyValueHandler = (e) => {
        var k = e.target.name;
        let v = e.target.value;
        console.log(k, v, e, 'st_plm___vavsaastq')
        if (Array.isArray(v) && v.length > 0) {
            v[0] = v[0].replace(" (New Label)", "");
        }
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v
            },
            error: { ...new_state.error, [k]: "" }
        }));
    }

    return (
        <>
            {
                canRedirect ?
                    component_id ? <Navigate to={"/application/" + application_id + "/service/" + component_id + "/detail"} /> :
                        <Navigate to={"/application/" + application_id + "/service/" + state.service_id + "/env/add"} /> : null
            }
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                className={`${classes.root} dialog-align-corner`}
                aria-labelledby="max-width-dialog-title"
            >
                <div className='d-grid ml-auto dialog-sub-component' style={showInfoSection ? { gridTemplateColumns: '650px' } : { gridTemplateColumns: '396px 650px' }}>
                    {
                        !showInfoSection &&

                        <div className={state.showLeftPanel ? 'left-panel-dialog bg-white position-relative' : 'left-panel-dialog-down'}>
                            <div className={'d-flex align-center space-between left-panel-header pd-10'} style={{ padding: '10px 20px' }} >
                                <p>Information</p>
                                <button
                                    className='btn btn-icon-only'
                                    onClick={handleCloseLeftStip}
                                >
                                    <span className='ri-close-line color-icon-secondary'></span>
                                </button>
                            </div>
                            <div className='pd-10' style={{ padding: '10px 20px' }}>
                                <p className='font-16 font-weight-600 color-primary mb-10'>Add Micro Service</p>
                                <p className='font-12 color-icon-secondary' style={{ color: '#404040' }}>
                                    BuildPiper's innovative approach places microservices at the forefront. Our platform provides a comprehensive suite of tools and workflows designed to streamline the development, deployment, and monitoring of microservices, setting us apart from traditional pipeline-centric solutions.
                                </p>
                            </div>
                            <div className='checkbox-only-divi' style={{ padding: '10px 20px' }}>
                                <Input
                                    type="simple-checkbox"
                                    name="dont_show_again"
                                    label="Dont show this again"
                                    mandatorySign
                                    data={data.data}
                                    error={data.error}
                                    onChangeHandler={onChangeDoNotshow}
                                />
                            </div>
                        </div>
                    }
                    <div className='right-panel-dialog bg-white'>
                        <>
                            <div className='font-18 font-weight-600 color-white d-flex align-center space-between' style={{ backgroundColor: '#0086ff', padding: '13.5px 20px' }}>
                                <p>{component_id ? "Edit" : "Add"} Micro Service</p>
                                <button
                                    className='btn float-cancel-button'
                                    style={showInfoSection ? { left: '396px' } : state.showLeftPanel ? {} : { left: '396px' }}
                                    onClick={handleClose}
                                >
                                    <span className='ri-close-line'></span>
                                </button>
                            </div>
                            {
                                state.error_msg ? <AlertStrip message={state.error_msg} /> : null
                            }
                            {
                                showLoading ?
                                    <Loading varient="light" />
                                    :
                                    <>
                                        <div className='' style={{ padding: '10px 16px' }}>
                                            <div style={{ paddingBottom: (state?.error?.name != undefined && state?.error?.name != '') ? '20px' : '0px' }} className="">
                                                <Input
                                                    type="text"
                                                    placeholder="demo-app"
                                                    mandatorySign
                                                    name="name"
                                                    label="Give a name to your service"
                                                    subHeading="AlphaNumeric with hyphen"
                                                    error={state.error}
                                                    onChangeHandler={commonFunctions.onChangeHandlerSmallInput}
                                                    data={state.data} />
                                            </div>
                                            {
                                                state.business_fn_key && state.business_fn_key.value && state.business_fn_key.value == "true" ?
                                                    <div className="auto-complete-dropdown auto-complete-dropdown-42 auto-complete-dropdown-ticketing">
                                                        <Input
                                                            type="auto-complete-freesolo" // "select"
                                                            label="Business Funtions"
                                                            id={"businessfn1"}
                                                            name="business_function"
                                                            list={state.business_fn_list}
                                                            freeSolo={true}
                                                            placeholder="Enter Business Function and press enter"
                                                            getOptionLabel={(option) => option.label}
                                                            error={state.error}
                                                            data={state.data}
                                                            onChangeHandler={onKeyValueHandler}
                                                        />
                                                    </div>
                                                    : null
                                            }
                                            <div className="">
                                                <Input
                                                    type="radio"
                                                    mandatorySign
                                                    name="build_strategy"
                                                    list={state.availableBuildStrategies}
                                                    label="How do you build"
                                                    error={state.error}
                                                    data={state.data}
                                                    onChangeHandler={commonFunctions.onChangeHandler} />
                                            </div>

                                        </div>
                                        <div className='footer-right-panel d-flex align-center justify-end' style={{gap:"5px"}}>
                                            <button className='btn btn-outline-grey font-12 font-weight-500 d-flex align-center justify-center' onClick={handleClose}>Cancel</button>
                                            <button className='btn btn-primary font-12 font-weight-500 d-flex align-center justify-center' onClick={validateAndSave}>Save &amp; Continue</button>
                                        </div>
                                    </>
                            }

                        </>


                    </div>
                </div>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={state.show_save_msg === "success" || state.show_save_msg === "failed"}
                onClose={handleCloseAlert}
                autoHideDuration={3000}>
                {
                    state.show_save_msg === "success" ?
                        <>
                            <Alert severity="success"  variant="filled">
                                {state.success_msg}
                            </Alert>
                        </> : state.show_save_msg === "failed" ?
                            <Alert severity="error"  variant="filled">
                                {state.error_in_save}
                            </Alert>

                            : null
                }
            </Snackbar>
        </>
    )
}

export default AddMsDialog


const useStyles = makeStyles((theme) => ({
    root: {
        '&.dialog-align-corner': {
            '& .MuiPaper-root': {
                maxWidth: '1100px'
            }

        },
        '& .input-with-icon': {
            position: 'relative',
            '& .cent-icon': {
                width: '40px',
                height: '44px',
                backgroundColor: '#fafafa',
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 500,
                top: '22px',
                right: '1px',
                borderRadius: '0px 4px 4px 0px',
                borderLeft: '1px solid #b7b7b7'
            }
        },
        '& .left-panel-dialog': {
            width: '396px',
            transition: 'width 5s',

            '& .left-panel-header': {
                borderBottom: '1px solid #f1f1f1'
            },
            '& .checkbox-only-divi': {
                position: 'absolute',
                bottom: '10px'
            },
        },
        '& .left-panel-dialog-down': {
            width: '0px',
            overflow: 'hidden',
            transition: `'width 5s', 'overflow 1s'`,

        },
        '& .footer-right-panel': {
            backgroundColor: '#fafafa',
            padding: '20px',
            position: 'absolute',
            bottom: '0px',
            width: '650px',

            // width:'100%',
            '& button': {
                // marginLeft: 'auto'
            }
        }
    }
}));