import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MainHeader from './components/MainHeader';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import CommonHorizontalTab from '../../../components/genericComponents/CommonHorizontalTab';
import { useParams } from 'react-router-dom';
import Events from '../components/Events';
import Conditions from '../components/Conditions';
import properties from '../../../properties/properties';
// import Editor from "@monaco-editor/react";
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';
import HealthStatusRoundMeter from '../../../components/genericComponents/HealthStatusRoundMeter';
import { Loading } from '../../utils/Loading';
import Tooltip from '@mui/material/Tooltip';
import Annotations from '../Ingress/list/components/Annotations';
import ExandableKeyValues from '../../../components/hoc/ExpanadleKeyValues';
import LogSection from '../../service/detail/components/deployment/components/LogSection';

function PodsDetails() {
    const classes = useStyles();
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [state, setState] = useState({ loading_tabs: false, selectedTab: "MasterNodes", selectedTabOrder: 1, selectedTabTwo: "Containers", podsInfo: {}, podsLogs: "hello" });
    const YAML = require('json-to-pretty-yaml');
    function handleEditorDidMount(_valueGetter) {
        setIsEditorReady(true);
        valueGetter.current = _valueGetter;
    }
    const valueGetter = useRef();

    const changeTab = (tab) => {
        setState({
            ...state,
            selectedTab: tab,
            selectedTabTwo: tab
        })
    }
    const changeTabTwo = (tab) => {
        setState({
            ...state,
            selectedTabTwo: tab
        })
        if (tab == "Logs") {
            onLog();
        }
    }


    const { cluster_id, namespace_name, pod_name } = useParams();
    const [showLoading, setShowLoading] = useState(false);

    const [viewLogs, setViewLogs] = useState(false);

    function onLog() {
        var endpt = "";
        endpt = GenerateURL({ cluster_id: cluster_id, namespace_name: namespace_name, pod_name: pod_name }, properties.api.get_pods_logs)
        // setShowLoading(true);
        fetchPodsLogs(endpt);


    }

    function closeLogs() {
        setViewLogs(false);
    }
    console.log("hello", state, "ASdsadsad", viewLogs)

    function getSelectedTabTwo() {

        switch (state.selectedTabTwo) {
            case "Containers":
                if (state.podsContainers)
                    return <Container podInfo={state.podsContainers} />
                else
                    return <Container podInfo={[]} />
            case "Conditions":
                if (state.podsInfo.metadata)
                    return <Conditions nodeInfo={state.podsInfo.status.conditions} />
                else
                    return <Conditions nodeInfo={[]} />
            case "Logs":

                return viewLogs ? <LogSection 
                loading_logs={showLoading} 
                selected_pod_name={"Pod"} 
                variant="CLUSTER_POD"
                logs={state.podsLogs? state.podsLogs: null } /> : null

            case "Events": return <Events api_url={GenerateURL({ cluster_id: cluster_id, namespace_name: namespace_name, pod_name: pod_name }, properties.api.get_pods_events)} />
            case "Volumes": return <Editor
                height="50vh"
                language="javascript"
                value={state.yaml_namespace ? "Volumes:" + YAML.stringify(state.podsInfo.spec.volumes) : "//console.log(hello world)"}
                editorDidMount={handleEditorDidMount}
                minimap={{ enabled: false }}
                automaticLayout={true}
                scrollBeyondLastLine={false}
            />
            case "Tolerations": return <Editor
                height="50vh"
                language="javascript"
                value={state.yaml_namespace ? "Tolerations:" + YAML.stringify(state.podsInfo.spec.tolerations) : "//console.log(hello world)"}
                editorDidMount={handleEditorDidMount}
            />

            default:
                return <h1>Hi</h1>
        }
    }

    const list = [
        {
            label: 'CPU',
            percentage: 50,
            color: '#ff8969'
        },
        {
            label: 'Memory',
            percentage: 10,
            color: '#69e09c'
        },
        {
            label: 'Disk',
            percentage: 10,
            color: '#69e09c'
        },
    ]
    const tablist = [
        {
            tabName: "Overview",
            order: 1
        },
        {
            tabName: "Describe Pods (YAML)",
            order: 2
        }

    ]
    const updateSelectedTab = (selectedTabOrder) => {
        {
            if (selectedTabOrder == "Logs") {
                fetchPodsLogs();
            }
        }
        setState({ ...state, selectedTabOrder: selectedTabOrder });
    }

    useEffect(() => {
        fetchPodsInfo();

        fetchPodsHealth();
    }, [])


    function handleSuccessApiHit(data) {
        setState(new_state => ({
            ...new_state,
            podsInfo: data,
            podsContainers: parseContainerData(data),
            yaml_namespace: YAML.stringify(data)
        }));

        setShowLoading(false);

    }

    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
        setShowLoading(false);
    }


    function fetchPodsInfo(data, url) {

        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id, namespace_name: namespace_name, pod_name: pod_name }, properties.api.get_pods_info),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setShowLoading(true);
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);


    }

    function parseContainerData(data) {
        if (data.spec.containers) {
            let array = data.spec.containers;
            let array2 = data.status.containerStatuses ? data.status.containerStatuses : [1, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
            let containers = [];
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                const element2 = array2[index];
                var container = {
                    name: element.name,
                    status: element2.ready ? element2.ready : "NA",
                    restartCount: element2.restartCount >= 0 ? element2.restartCount : "NA",
                    env: element.env,
                    volumeMounts: element.volumeMounts,
                    image: element.image,
                    livenessProbe: element.livenessProbe ? true : false,
                    readinessProbe: element.readinessProbe ? true : false

                }
                containers.push(container);

            }
            return containers;
        }
    }

    function handleSuccessApi(data) {
        console.log(data,"fdasfsafsaf")
        setViewLogs(true);
        setState(new_state => ({
            ...new_state,
            podsLogs: data,
            loading_tabs: false

        }));



    }

    function handleFailedApiHit1(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            loading_tabs: false
        }))
        setViewLogs(false);

    }

    function fetchPodsLogs(data, url) {

        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id, namespace_name: namespace_name, pod_name: pod_name }, properties.api.get_pods_logs),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setState(
            new_state => ({
                ...new_state,
                loading_tabs: true
            })
        )
        InvokeApi(requestInfo, handleSuccessApi, handleFailedApiHit1, false, true);
        console.log(state, "podslogs")

    }

    function handleSuccessApiHit2(data) {
        setState(new_state => ({
            ...new_state,
            podsHealth: data,

        }));

        setShowLoading(false);

    }

    function handleFailedApiHit2(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
        setShowLoading(false);
    }

    function fetchPodsHealth(data, url) {

        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id, namespace_name: namespace_name, pod_name: pod_name }, properties.api.get_pods_health),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setShowLoading(true);
        InvokeApi(requestInfo, handleSuccessApiHit2, handleFailedApiHit2);


    }

    return (
        <div className={classes.root}>
            {
                showLoading ? <Loading /> : null
            }
            <div className="main-div d-flex-top">
                <MainHeader name={pod_name} namespace_name={namespace_name} cluster_id={cluster_id}
                />
            </div>
            <div className={classes.mainBodyDiv}>
                <div className="d-flex align-center b-none" style={{ justifyContent: 'space-between', borderBottom: '1px solid #dedede' }}>
                    <CommonHorizontalTab
                        style={{ borderBottom: '1px solid #dedede' }}
                        tabList={tablist}
                        selectedTabOrder={state.selectedTabOrder}
                        updateSelectedTab={updateSelectedTab} />
                    <button onClick={() => { fetchPodsInfo() }} className="btn-round border-navy bg-clear-btn" style={{ justifySelf: "flex-end" }}><span className="flaticon-refresh-button-1"></span></button>
                </div>
                <div className={classes.subBoby}>
                    <InnerBox>
                        {/* <ViewLogs closeLogs={closeLogs} logs={state.podsLogs ? state.podsLogs : "No Logs Found"} />  */}
                        <InnerBoxData>
                            <div>
                                <span className={classes.smallText}>Pod Info</span>
                                <NodeData data={state.podsInfo ? state.podsInfo : null} />
                            </div>
                            <div>
                                <span className={classes.smallText}>Pod Health</span>

                                <TabTwo>{
                                    state.podsHealth ? <HealthStatusRoundMeter data={state.podsHealth} />
                                        : <p className={classes.smallText}>No ResouceQuota found</p>}

                                </TabTwo>
                            </div>
                            <div>
                                <span className={classes.smallText} style={{ paddingLeft: '15px' }}>Labels</span>
                                {state.podsInfo.metadata ?
                                    <UI_ExpandComponent data={state.podsInfo.metadata.labels} />
                                    //  <Labels labels={state.podsInfo.metadata.labels} /> 
                                    : null}

                            </div>                        </InnerBoxData>
                    </InnerBox>
                    {state.selectedTabOrder == 1 ?
                        <div className="expensionPanel" style={{ margin: '10px', padding: '0px', overflow: 'hidden' }}>

                            <div className="expensionPanelHead">

                                <span role='button' tabIndex={0} style={{cursor: "pointer"}} className={state.selectedTabTwo == "Conditions" ? "expensionPanelTab width-fitcontent active" : "expensionPanelTab width-fitcontent"} onKeyDown={()=>{}} onClick={() => {changeTabTwo("Conditions")}} >Conditions</span>
                                <span role='button' tabIndex={0} style={{cursor: "pointer"}} className={state.selectedTabTwo == "Containers" ? "expensionPanelTab width-fitcontent active" : "expensionPanelTab width-fitcontent"} onKeyDown={()=>{}} onClick={() => {changeTabTwo("Containers")}} >Containers</span>
                                <span role='button' tabIndex={0} style={{cursor: "pointer"}} className={state.selectedTabTwo == "Events" ? "expensionPanelTab width-fitcontent active" : "expensionPanelTab width-fitcontent"} onKeyDown={()=>{}} onClick={() => {changeTabTwo("Events")}} >Events</span>
                                <span role='button' tabIndex={0} style={{cursor: "pointer"}} className={state.selectedTabTwo == "Tolerations" ? "expensionPanelTab width-fitcontent active" : "expensionPanelTab width-fitcontent"} onKeyDown={()=>{}} onClick={() => {changeTabTwo("Tolerations")}} >Tolerations</span>
                                <span role='button' tabIndex={0} style={{cursor: "pointer"}} className={state.selectedTabTwo == "Volumes" ? "expensionPanelTab width-fitcontent active" : "expensionPanelTab width-fitcontent"} onKeyDown={()=>{}} onClick={() => {changeTabTwo("Volumes")}} >Volumes</span>
                                {state.podsContainers && state.podsContainers.length == 1 && <span role='button' tabIndex={0} style={{cursor: "pointer"}} className={state.selectedTabTwo == "Logs" ? "expensionPanelTab width-fitcontent active" : "expensionPanelTab width-fitcontent"} onKeyDown={()=>{}} onClick={() => {changeTabTwo("Logs")}} >Logs</span>}
                            </div>

                            <div className="expensionPanelBody logs-context" >
                                
                                {
                                    state.loading_tabs ?
                                        <div className="" style={{ marginTop: '100px' }}> <Loading varient="light" /></div>
                                        : <>{getSelectedTabTwo()}</>
                                }


                            </div>
                        </div>
                        :

                        // <Editor
                        //     height="90vh"
                        //     language="javascript"
                        //     options={{
                        //         minimap: {
                        //             enabled: false,
                        //         },
                        //         fontSize: 18,
                        //         cursorStyle: "block",
                        //         wordWrap: "on",
                        //     }}
                        //     defineTheme={{
                        //         themeName: "my-theme",
                        //         themeData: {
                        //             colors: {
                        //                 "editor.background": "#000000",
                        //             },
                        //         },
                        //     }}
                        //     value={state.yaml_namespace ? state.yaml_namespace : "//console.log(hello world)"}
                        //     editorDidMount={handleEditorDidMount}
                        // />
                        <div/>
                    }
                    
                </div>
            </div>
        </div>
                            
    )
}

export default PodsDetails;



function NodeData(props) {
    const pod = props.data ? props.data : null;
    return (
        <div className="nodes-data">
            {
                pod.spec ?

                    <div className="data" style={{ display: 'flex', flexDirection: 'column' }}>

                        <div className="margin-top-bottom-3px">
                            <span className="text-color-black">Node:</span>
                            <span className="text-color-grey">{pod.spec.nodeName}</span>
                        </div>
                        <div className="margin-top-bottom-3px d-flex align-center">
                            <span className="text-color-black">Tolerations:&nbsp;</span>
                            {/* <span className="text-color-blue">{pod.spec.tolerations.length}</span> */}
                            <Annotations tolerations={pod.spec.tolerations}></Annotations>
                        </div>

                        <div className="margin-top-bottom-3px">
                            <span className="text-color-black">Service Account </span>
                            <span className="text-color-grey text-elipsses">{pod.spec.serviceAccountName}</span>
                        </div>
                        {/* <div className="margin-top-bottom-3px">
                            <span className="text-color-black">Volumes </span>
                            <span className="text-color-grey">{pod.spec.volumes.length}</span>
                        </div> */}
                        {/* <div>
                            <span className="text-color-black">Volume Secret </span>



                            <span className="text-color-grey">{pod.spec.volumes[pod.spec.volumes.length - 1].secret.secretName}</span>
                        </div> */}
                        {/* <div>
                            <span className="text-color-black">Secrets Default Mode </span>
                            <span className="text-color-grey">{pod.spec.volumes[pod.spec.volumes.length - 1].secret.defaultMode}</span>
                        </div> */}


                    </div> : null
            }

        </div>
    )
}
NodeData.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function UI_ExpandComponent(props) {
    const data = props.data;
    const data_list = [];
    const iteration_count = 4;
    const data2 = props.data_ui ? props.data_ui : [];
    const list_2 = [];

    let labels_list = [];

    let labels_list2 = [];


    function get_list(labels, labels2, list_type) {
        labels_list = [];
        labels_list2 = [];
        if (labels) {
            Object.keys(labels).forEach(label => {
                labels_list.push(
                    {
                        key: label,
                        value: labels[label]
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label + "=" + labels[label]}>
                        <div className="pod-lable">
                            {label + "=" + labels[label]}
                        </div>
                    </Tooltip>
                );
            });


        }

        if (labels2) {
            labels2.forEach(label => {
                labels_list.push(
                    {
                        key: label.env_key,
                        value: label.env_value
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label.env_key + "=" + label.env_value}>
                        <div className="pod-lable">
                            {label.env_key + "=" + label.env_value}
                        </div>
                    </Tooltip>
                );
            });



        }
        if (list_type == "1") {
            return labels_list;

        }
        if (list_type == "2") {
            return labels_list2;

        }
        return [];
    }


    return (
        <>
            <ExandableKeyValues
                compoenent_list={get_list(data, data2, 2)}
                iteration_count={iteration_count}
                labels_list={get_list(data, data2, 1)}
                isLabel={true}
                expandable_component={
                    <>
                        +{labels_list.length - iteration_count}
                    </>
                } />
        </>
    );
}
UI_ExpandComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


function Labels(props) {
    const labels = props.labels ? props.labels : null;
    return (

        <div className="label-box">
            {labels ?
                Object.keys(labels).map(label => (
                    <Tooltip title={label + "=" + labels[label]}><div className="pod-lable">
                        {label + "=" + labels[label]}            </div></Tooltip>

                )) : null
            }

        </div>
    )
}
Labels.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function Container(props) {
    const podInfo = props.podInfo;
    return (
        <>
            <div className="head" style={{ margin: "10px 0px" }}>
                <div className="heading-section-service">
                    <div className="main-heading">Container</div>
                    <div className="sub-heading-text">Displaying realtime view of container</div>
                </div>
            </div>
            <table className="table table-responsive baseline" style={{ minHeight: '250px' }}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Health Check</th>
                        <th>Restart Count</th>
                        <th>Image</th>
                        <th>Env. Variables</th>
                        <th colSpan="3" className="text-left">Volume Mounts</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th scope="col" className="width-150-fix">Name</th>

                        <th scope="col" className="width-150-fix">Path</th>
                        <th scope="col" className="width-150-fix">ReadOnly</th>
                    </tr>

                </thead>

                <tbody>
                    {
                        podInfo ? podInfo.map(pod => (
                            <tr>
                                <td>{pod.name}</td>
                                <td>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <>
                                            {pod.status == true ? <>
                                                <i className="ri-checkbox-circle-fill" style={{ fontSize: '18px', color: pod.status == true ? '#69e09c' : '#ff8969' }} />&nbsp;<div>{pod.status ? pod.status == "NA" ? "Pending" : "Ready" : "Pending"}</div>
                                            </> :
                                                <>
                                                    <i className='ri-close-line' style={{ fontSize: '18px', color: '#ff8969' }} />&nbsp;<div>{pod.status ? pod.status == "NA" ? "Pending" : "Ready" : "Pending"}</div>
                                                </>
                                            }
                                        </>
                                    </span>
                                </td>
                                <td>
                                    <div>
                                        <span className="d-flex align-center"> {
                                            pod.livenessProbe ? <span className="bullet green p-relative"></span> : <span className="bullet red p-relative"></span>
                                        }
                                            <span className="msg">Liveness</span></span>
                                    </div>
                                    <div>
                                        <span className="d-flex align-center"> {
                                            pod.readinessProbe ? <span className="bullet green p-relative"></span> : <span className="bullet red p-relative"></span>
                                        }
                                            <span className="msg">Readiness</span></span>
                                    </div>
                                </td>

                                <td>
                                    {/*  */}
                                    <span>{pod.restartCount}</span>
                                </td>
                                <td style={{ maxWidth: '250px' }}>
                                    <Tooltip placement="bottom" title={pod.image}>
                                        <div className="text-ellipsis-200">{pod.image}</div>
                                    </Tooltip>

                                </td>


                                <td style={{ maxWidth: '250px', whiteSpace: 'break-spaces' }}>
                                    {/* {
                                        pod.env ? pod.env.map(mount => (

                                            <div className="chip-default chip-blue">{mount.name}</div>


                                        )) : null
                                    } */}
                                    <UI_ExpandComponent data_ui2={pod.env ? pod.env : ""} />
                                </td>

                                <td>
                                    {
                                        pod.volumeMounts ? pod.volumeMounts.map(mount => (
                                            <Tooltip placement="bottom" title={mount.name}>
                                                <div className="text-ellipsis-200">{mount.name}</div>
                                            </Tooltip>



                                        )) : null
                                    }
                                </td>

                                <td>
                                    {
                                        pod.volumeMounts ? pod.volumeMounts.map(mount => (
                                            <Tooltip placement="bottom" title={mount.mountPath}>
                                                <div className="text-ellipsis-200">{mount.mountPath}</div>
                                            </Tooltip>

                                        )) : null
                                    }
                                </td>
                                <td>
                                    {
                                        pod.volumeMounts ? pod.volumeMounts.map(mount => (

                                            <div>{mount.readOnly ? "TRUE" : "FALSE"}</div>


                                        )) : null
                                    }
                                </td>
                            </tr>
                        )) : null
                    }

                </tbody>
            </table>

        </>
    )
}
Container.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

// function UI_ExpandComponent(props) {
//     const data = props.data ? props.data : null;
//     const data_list = [];
//     const iteration_count = 4;
//     const data2 = props.data_ui ? props.data_ui : [];
//     if (data) Object.keys(data).forEach(anno => {
//         data_list.push(
//             <Tooltip placement="bottom" title={anno + "=" + data[anno]}>
//                 <div className="pod-lable">
//                     {anno + "=" + data[anno]}
//                 </div>
//             </Tooltip>
//         );
//     })


//     data2.forEach(dat => (
//         data_list.push(
//             <Tooltip placement="bottom" title={dat.env_key + "=" + dat.env_value}>
//                 <div className="pod-lable">
//                     {dat.env_key + "=" + dat.env_value}
//                 </div>
//             </Tooltip>

//         )
//     ))
//     const data3 = props.data_ui2 ? props.data_ui2 : [];
//     data3.forEach(dat => (
//         data_list.push(
//             <div className="pod-lable">
//                 {dat.name}
//             </div>
//         )
//     ))
//     return (
//         <>
//             <ExandableComponentList
//                 compoenent_list={data_list}
//                 iteration_count={iteration_count}
//                 expandable_component={data ?
//                     <>
//                         +{Object.keys(data).length - iteration_count}
//                     </>
//                     : <>
//                         +{data3.length - iteration_count}
//                     </>
//                 }
//             />
//         </>
//     );
// }

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: 32,
        '& .cluster-head-data': {
            "& .cluster-name": {
                color: '#000!important'
            }
        },
        '& .main-div': {
            padding: '16px 0px'
        }
    },
    mainBodyDiv: {
        border: ' 1px solid #dedede',
        boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        overflow: 'hidden',
        '& .headerul': {
            borderBottom: '0px solid #dedede',
            padding: '15px 20px 0px'
        },
        '& .expensionPanel': {
            border: 'none',
            backgroundColor: 'transparent',
            margin: '0px!important',
            overflowX: 'auto',
            overflowY: 'hidden',
            minHeight: '250px!important',
            '& .expensionPanelHead': {
                display: 'flex'
            }
        },
        '& .expensionPanelBody': {
            height: '100%',
            position: 'relative',
            overflowX: 'auto',
            '& #viewFullLogs': {
                top: '0px!important'
            },
            '& .head': {
                padding: '10px'
            }
        },
        '& .expensionPanelTab': {
            position: 'relative',
            top: '0px'
        }
    },
    subBoby: {
        backgroundColor: '#fff',
        '& .logs-window': {
            height: '170px'
        }
    },
    smallText: {
        fontSize: '13px',
        color: '#878787',
        lineHeight: '2',
        textTransform: 'uppercase'
    }
}))

const MainContainer = styled('div')({
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '1px solid #dedede',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
    marginBottom: '2rem',
    '& .head': {
        padding: '0px 10px'
    }
})
const InnerBoxData = styled('div')({
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '350px 350px 1fr',

})
const TabTwo = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .progress-container': {
        padding: '0px'
    },
    '& .CircularProgressbar': {
        '& .CircularProgressbar-trail': {
            strokeWidth: '8px'
        },
        '& .CircularProgressbar-path': {
            strokeWidth: '8px'
        }
    }
})
const InnerBox = styled('div')({
    backgroundColor: '#fcfcfc',
    borderBottom: '1px solid #dedede',
    padding: '15px',
    marginBottom: '30px',
    '& .pd-top-20': {
        padding: '20px 0px'
    },
    '& .nodes-data': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        padding: '5px 0px',
        lineHeight: '14px',
        '& .width-fixed': {
            width: '92px',
            display: 'inline-block'
        },
        '& .text-color-grey': {
            color: '#989898',
            marginLeft: '3px'
        },
        '& .text-color-blue': {
            color: '#0086ff',
            marginLeft: '3px'
        }
    },
    '& .text-black': {
        fontSize: '12px'
    },
    '& .k8s-version': {
        fontSize: '11px',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0px',
        '& .flaticon-circle-with-check-symbol': {
            '&:before': {
                fontSize: '14px!important'
            },
            '&:after': {
                fontSize: '14px'
            }
        },
        '& img': {
            width: '36px',
            height: '36px',
            display: 'block',
        }
    }
})

const SquareBox = styled('div')({
    borderRadius: '8px',
    padding: '10px',
    height: '50px',
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#dedede',
    fontSize: '18px',
    fontWeight: '700',
    color: '#fff',
    marginRight: '5px'
})