import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { Editor } from '@monaco-editor/react';
// import MonacoEditor from 'react-monaco-editor';

const SampleYamlDialog = ({ title, dialog_heading, value, handleCopy, ref_props }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({});
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <>
      <button style={{ margin: '5px' }} className="btn btn-outline-primary" onClick={handleClickOpen}>
        {title ? title : "Sample YAML"}
      </button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <div className='d-flex align-center space-between pd-10' style={{ backgroundColor: 'rgb(54, 54, 54)' }}>
          <p className='font-12' style={{ color: '#fff' }}>
            {dialog_heading}
          </p>
          <div className='d-flex align-center justify-flexend'>
          <button className="btn btn-transparent" onClick={handleCopy}>
            <span className="material-icons material-symbols-outlined font-24" style={{ color: '#fff' }}>
              content_copy
            </span>
          </button>

          <button className="btn btn-transparent" onClick={handleClose}>
            <span className="material-icons material-symbols-outlined font-24" style={{ color: '#fff' }}>
              close
            </span>
          </button>
          </div>
        </div>
        <div className="pd-10" style={{ backgroundColor: '#1e1e1e', height: '300px' }}>
          <Editor
            height="300px"
            language="yaml"
            ref={ref_props}
            value={value}
            options={{
              minimap: {
                enabled: false,
              },
              fontSize: 12,
              cursorStyle: "block",
              wordWrap: "on",
            }}
            theme="vs-dark"
          />
        </div>
      </Dialog>
    </>
  )
}

SampleYamlDialog.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default SampleYamlDialog