import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Prerequisite from "./Prerequisite";
import BasicInfo from "./BasicInfo";
import Networking from "./Networking";
import ResourceQuota from "./ResourceQuota";
import ConfigurationManagement from "./ConfigurationManagement";
import EnvVar from "./EnvVar";
import LivelinessProbe from "./LivelinessProbe";
import RoleMaping from "./RoleMaping";
import HorizontalTab from "../../../../../components/wizardComponents/HorizontalTab";
import InvokeApi from '../../../../../util/apiInvoker';
import GenerateURL from '../../../../../util/APIUrlProvider';
import properties from '../../../../../properties/properties';
import { ClusterDataParser } from "../../../../../util/APIParser";
import Manifest from '../CDDetails/Manifest';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '3rem'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  mainDivSelected: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '.5rem 1.25rem',
    borderRadius: '30px 0px 0px 30px',
    margin: '20px 0px 20px 20px'
  },
  mainDiv: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '160px',
    backgroundColor: '#fff',
    padding: '.5rem 1.25rem',
    borderRadius: '30px 30px 30px 30px',
    margin: '20px'
  },
  count: {
    width: '30px',
    height: '30px',
    border: '1px solid #ccc',
    borderRadius: '50%',
    textAlign: 'center',
    paddingTop: '2px'
  },
  text: {
    fontSize: '11px',
    fontWeight: 400,
  },
  mainBodyDiv: {
    backgroundColor: '#f6f8f8',
    padding: '15px 20px',
    borderTop: '1px solid#dedede',
  },
  countBody: {
    backgroundColor: '#1d5b81',
    color: '#fff',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 800
  },
  tabBodyDivHeading: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1rem',
    borderBottom: '1px solid #ccc'
  },
  textBody: {
    fontSize: '16px',
    fontWeight: 300,
    color: '#000'
  },
  formDiv: {
    padding: '0px 0px',
    margin: '0px 0px'
  },
  InputLabel: {
    fontSize: '12px',
    fontWeight: 400,
  },
  hindText: {
    fontSize: '10px',
    color: '#666',
    fontWeight: 400,
    marginLeft: '.5rem'
  },
  stepper: {
    margin: '1rem 0rem'
  },
  subHeading: {
    fontSize: '12px',
    color: '#666',
    fontWeight: 400,
    marginBottom: '1rem'
  },
  impMakr: {
    color: 'red'
  }
}));



const panel_list_2 = [
  { order: 3, mainText: "Basic Details" },
  { order: 4.5, mainText: "Manifest Upload" },
  { order: 9, mainText: "User Permission" }
];

const panel_list = [
  { order: 3, mainText: "Basic Details" },
  { order: 4, mainText: "Resource Quota" },
  { order: 5, mainText: "Access Level" },
  { order: 6, mainText: "Configuration and Scaling" },
  { order: 7, mainText: "Environment Variables" },
  { order: 8, mainText: "Liveliness and Readiness" },
  { order: 9, mainText: "User Permission" }
];

const CDDetails = (props) => {
  const classes = useStyles();
  const active_panel_order = props.active_panel_order;
  const application_id = props.application_id;
  const service_name = props.service_name;
  const env_name = props.env_name;
  const sub_env_name = props.sub_env_name;
  const is_manifest = props.is_manifest
  const [state, setState] = useState({
    cluster: {},
    namespace: {},
    tls_cert: {},
  });

  useEffect(() => {
    var requestInfo = {
      endPoint: GenerateURL({ application_id: application_id }, properties.api.application_cluster_detail_url),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);

    requestInfo.endPoint = GenerateURL({ application_id: application_id }, properties.api.application_role_detail_url);
    InvokeApi(requestInfo, handleSuccessRoleApiHit, handleFailedApiHit);
  }, [application_id]);


  // Api callback handlers
  const handleSuccessApiHit = (response) => {
    var data = ClusterDataParser(response);
    setState(new_state => ({
      ...new_state,
      ...data,
    }));
  }

  const handleSuccessRoleApiHit = (response) => {
    setState(new_state => ({
      ...new_state,
      roles: response,
    }));
  }

  const handleFailedApiHit = (error) => {
    console.log("==== > ", error);
  }

  // State level change handlers
  const handleClusterChange = (cluster_id) => {
    setState({
      ...state,
      selected_cluster_id: cluster_id
    });
  }

  // Helper for UI renderings
  const getActivePanel = () => {
    var cluster_list = getClusterList(state.cluster);
    var namespace_list = getNamespaceList(state.selected_cluster_id ? state.cluster[state.selected_cluster_id].project_cluster_namespace : [], state.namespace);
    var tls_list = getTLSList(state.selected_cluster_id ? state.cluster[state.selected_cluster_id].tls_cert : [], state.tls_cert);
    var private_access_name = service_name + "-" + env_name + "-" + sub_env_name;

    switch (active_panel_order) {
      case 2:
        return <Prerequisite {...props} />
      case 3:
        if (!state.selected_cluster_id && props.data.cluster_id) {
          setState({
            ...state,
            selected_cluster_id: props.data.cluster_id
          })
        }
        return <BasicInfo {...props} env={env_name} cluster_list={cluster_list} namespace_list={namespace_list} update_cluster_id={handleClusterChange} />;
      case 4:
        return <ResourceQuota {...props} />
      case 4.5:
        return <Manifest {...props} />
      case 5:
        return <Networking {...props} cert_list={tls_list} private_access_name={private_access_name} />
      case 6:
        return <ConfigurationManagement {...props} />
      case 7:
        return <EnvVar {...props} />
      case 8:
        return <LivelinessProbe {...props} />
      case 9:
        return <RoleMaping roles={state.roles}  {...props} />
      default:
        return <h1>404 Tab Not found</h1>
    }
  }

  return (
    <div className={classes.mainBodyDiv}>
      <div className={classes.formDiv}>
        {
          active_panel_order >= 3 ?
            <HorizontalTab tabDataList={is_manifest ? panel_list_2 : panel_list} tabState={{ selectedTabOrder: active_panel_order }} />
            : null
        }
        {getActivePanel()}
      </div>
    </div>
  );
};

CDDetails.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default CDDetails;


// Utils for use
function getClusterList(cluster_obj) {
  var cluster_list = [];
  Object.keys(cluster_obj).forEach((key) => {
    cluster_list.push({
      id: key,
      label: cluster_obj[key].name
    });
  });
  return cluster_list;
}

function getNamespaceList(namespace_ids, namespace_obj) {
  var namespace_list = [];
  Object.keys(namespace_obj).forEach((key) => {
    if (namespace_ids.includes(Number(key))) {
      namespace_list.push({
        id: key,
        label: namespace_obj[key].name
      });
    }
  });
  return namespace_list;
}

function getTLSList(tls_ids, tls_obj) {
  var tls_list = [];
  Object.keys(tls_obj).forEach((key) => {
    if (tls_ids.includes(Number(key))) {
      tls_list.push({
        id: key,
        label: tls_obj[key].name
      });
    }
  });
  return tls_list;
}