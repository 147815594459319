import React, { useEffect, useState, useRef } from 'react'
import { styled } from "@mui/system";
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';

const PickStories = ({ data, onDataUpdate }) => {
    const [state, setState] = useState({
        data: data || { pick_from: 'release' }, 
        error: {}
    });
    const previousData = useRef(data);


    const commonFunctions = getCommonFunctions(state, setState, {})

    useEffect(() => {
        if (JSON.stringify(previousData.current) !== JSON.stringify(data)) {
            setState(prevState => ({
                ...prevState,
                data: data,
            }));
            previousData.current = data;
        }
    }, [data]);

    useEffect(() => {
        if (JSON.stringify(previousData.current) !== JSON.stringify(state.data)) {
            onDataUpdate(state.data);
            previousData.current = state.data;
        }
    }, [state.data, onDataUpdate]);
  return (
    <Root>
            <p className='d-flex align-center mb-24'>
                <span className='font-20 font-weight-600'>From where do you want to pick stories from?</span>&nbsp;&nbsp;<span className='ri-information-line font-24 color-secondary'></span>
            </p>
            <Input 
                type="radio"
                list={[{ label: "Release", value: 'release' }, { label: "Sprint", value: 'sprint' }]}
                name="pick_from"
                label={""}
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
            />
        </Root>
  )
}
const Root = styled('div')({
    marginBottom: '24px'
})

export default PickStories