import React, { useEffect, useState } from 'react'
import { getCommonFunctions } from '../../../../serviceRevamp/add/ci_flow/SourceDetails';
import Grid from '@mui/material/Grid';
import { Input } from '../../../../../components/genericComponents/Input';

const WidgetEnvVariableForm = ({ extraProps, ...props }) => {

    console.log(extraProps, props, "abcshbcjzbczxhc")

    console.log('p_ev_dssds', extraProps)
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState({
        loading: false,
        type: "widget_panel",
        data: {},
        error: {},
        validations: {},
        widget_data: {

        },
        getDefaultState: null
    })

    const inherits = props.inherits ? props.inherits : {};
    console.log(inherits, 'inherits_data_000233');
    const commonFunctions = getCommonFunctions(state, setState, inherits)

    useEffect(() => {
        getStateForEdit()
    }, [extraProps])

    function getStateForEdit() {
        console.log(extraProps, "sdvchdscsgcs")
        var name = getWidgetTypeValue()
        var widget_data = getWidgetData(name);
        console.log(widget_data,"svbcjsdchjsfvhjfs")
        var configuration_keys = widget_data && Object.keys(widget_data?.configuration_data);
        var first_key = configuration_keys && configuration_keys[0]
        var description = widget_data && widget_data.configuration_data[first_key].description
        console.log(description,"shbvhjbshfvbhv")
        setState((new_state) => ({
            ...new_state,
            data: {
                widget_type: getWidgetTypeValue()
            },
            selected_wizard_code: name,
            widget_data_type: getDataType(name),
            [name]: getWidgetTypeValue(name),
            widget_data: {
                [name]: extraProps[name],
            },
            wizard_description: widget_data?.description,
            wizard_input_description : description,
        }))
    }

    function getWidgetTypeValue(name) {
        // let data_type = getDataType(name)
        console.log(name, "sdbchsdbhcvsbvbfs")
        var keys = Object.keys(extraProps)

        var selected_key = keys.find((item) =>
            item != "apiFetchedStepEnvVariables"
        )
        if (name) {
            let widget_data_type = getDataType(name)
            console.log(widget_data_type, "bczdbchdbsc")
            var value = extraProps[name]
            let output;
            if (widget_data_type == "list") {
                console.log("running_this")
                output = getValueInStrings(value)
            } else {
                output = value;
            }
            return output;
        }
        else {
            return selected_key?.toString();
        }
    }

    function getWidgetData(name) {
        console.log(name,extraProps.apiFetchedStepEnvVariables, "dsbcsdbhcbdshbchsd")
        var api_data = extraProps.apiFetchedStepEnvVariables
        var selected_widget_data;
        if(api_data){
            extraProps.apiFetchedStepEnvVariables.forEach((item) => {
                if(item.code == name){
                    selected_widget_data = item;
                }
            })
        }

        console.log(selected_widget_data,"sdbhbsdbchsdbcjhsd")

        return selected_widget_data;
        // var keys = Object.keys(extraProps)

        // var selected_key = keys.find((item) =>
        //     item != "apiFetchedStepEnvVariables"
        // )
        // var step_data;
        // if (data) {
        //     step_data = extraProps.apiFetchedStepEnvVariables.find((item) => {
        //         item.code == selected_key
        //     })
        // }

        // console.log(step_data,"svcsdgvsghdbv")
        // console.log(selected_key,extraProps?.apiFetchedStepEnvVariables,"shbdcbsdbchdsbcjh")

        // var data;

        // if(extraProps.apiFetchedStepEnvVariables){
        //     data = extraProps.apiFetchedStepEnvVariables.find((item) => {
        //         item.code ==  selected_key
        //     })
        // }

        // console.log(data,"shbdcbsdbchdsbcjh")
    }

    function getValueInStrings(value) {
        console.log(value, "sdbchsdbhcvsbvbfs")

        if (value.length == 0) {
            return "";
        } else {
            let result = '';
            for (let i = 0; i < value.length; i++) {
                result += value[i];
                if (i < value.length - 1) {
                    result += ',';
                }
            }
            console.log("jcjcnjdxnjd", result);
            return result.toString();
        }
    }

    console.log(state, "sdvkbsdjbhcjvbs")

    const getWidgetList = () => {
        const list = []

        extraProps && extraProps.apiFetchedStepEnvVariables && extraProps.apiFetchedStepEnvVariables.forEach(element => {
            var obj = {
                id: element.code,
                label: element.code
            }

            list.push(obj)
        });

        return list;

    }

    function onWidgetTypeChangeHandler(e) {
        var name = e.target.name
        var value = e.target.value

        var data = extraProps.apiFetchedStepEnvVariables
        var data_type;
        data.forEach((item) => {
            if (item.code == value) {
                let config_data = item.configuration_data
                let key = Object.keys(config_data)[0]
                data_type = config_data[key].data_type
            }
        })

        var selected_wizard = data.find((item) => {
            return item.code == value
        })
        var code = selected_wizard?.code
        var config_data_keys = selected_wizard && Object.keys(selected_wizard.configuration_data)
        var example_key = config_data_keys && config_data_keys[0]
        var example_data = selected_wizard?.configuration_data[example_key]
        var example = example_data?.example
        var wizard_description = selected_wizard?.description
        var example_description = example_data?.description

        console.log(selected_wizard, "sdhgvhgsdvcgsds")

        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [name]: value,
            },
            widget_data_type: data_type,
            selected_wizard_code: code,
            selected_wizard_example: example,
            wizard_description: wizard_description,
            wizard_input_description: example_description
        }))
    }

    console.log(state, "sdbhcjhsdbcjbhsd")

    function getDataType(name) {
        console.log(name, extraProps, "jvhfsdvbhsfvb")
        let extra = extraProps.apiFetchedStepEnvVariables;
        console.log("jbsdbjsd", extra);
        var output;
        extra && extra.forEach((item) => {
            if (item.code == name) {
                console.log(item.configuration_data, "ancfdsjckhsdbhcdsb")
                var keys = Object.keys(item?.configuration_data)
                var data = item.configuration_data[keys[0]]
                output = data.data_type
            }
        })

        console.log(output, "afchagdcgda")

        return output;
    }

    console.log(state, "hzvdcjgsdvcjhsd")

    function onChangeWidgetValue(e) {
        let name = e.target.name
        let value = e.target.value

        setState((new_state) => ({
            ...new_state,
            [name]: value,
            widget_data: {
                ...new_state.widget_data,
                [name]: getWidgetValue(value)
            }
        }))
    }

    function getWidgetValue(value) {
        if (state.widget_data_type == "list") {
            return value.split(",").map(word => word.trim());
        } else {
            return value;
        }
    }

    console.log(state, "hgdcvzjdvcdshdfvbdf")


    return (
        <div className='ci-v3-input-div widget-input-div' style={{
            padding: '40px 35px 40px 35px',
            // height: 'calc(100% - 157px)',
            minHeight: '300px',
            borderTop: '1px solid #dddddd',
            backgroundColor: '#f8f8f8',
        }}>
            {
                state && state.loading ?
                    <div style={{ display: 'flex' }}>
                        <Loading varient="light" />
                    </div>
                    :
                    <Grid container>
                        <Grid item xs={12}>
                            <Input
                                type='select'
                                name="widget_type"
                                mandatorySign
                                list={getWidgetList()}
                                label="Choose widget type"
                                placeholder=""
                                onChangeHandler={onWidgetTypeChangeHandler}
                                data={state.data}
                                error={state.error}
                            />
                        </Grid>
                        {
                            state.data.widget_type &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                               <i className="ri-information-line" style={{ color: "#407BCA", width: "20px", height: "20px" }} />
                                <p style={{ color: "#407BCA", marginLeft: "5px" }}>{state.wizard_description}</p>
                            </div>
                        }
                        {
                            state.data.widget_type &&
                            <Grid container style={{ marginTop: "20px" }}>
                                <Grid item xs={12}>
                                    <Input
                                        type='text'
                                        name={state.selected_wizard_code}
                                        mandatorySign
                                        label="Enter widget value"
                                        placeholder=""
                                        onChangeHandler={onChangeWidgetValue}
                                        data={state}
                                        error={state.error}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {
                            state.data.widget_type &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                               <i className="ri-information-line" style={{ color: "#407BCA", width: "20px", height: "20px" }} />
                                <p style={{ color: "#407BCA", marginLeft: "5px" }}>{state.wizard_input_description}</p>
                            </div>
                        }
                    </Grid>
            }
        </div>
    )
}

export default WidgetEnvVariableForm