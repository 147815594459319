import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Popper } from "@mui/material";
import { styled } from '@mui/styles';
import SearchCategoryTabs from './SearchCategoryTabs';
import ResultsWindow from './ResultsWindow';
import GlobalSearchHint from './GlobalSearchHint';
import ReactGA from 'react-ga4';
import FeatureResultWindow from './FeatureResultWindow';
import { searchTabs } from '../constants';

const GlobalSearch = ({ positionChange }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    //const [selectedSearchTab,setSelectedSearchTab] = useState(searchTabs[0].id);
    const [searchQuery, setSearchQuery] = useState('');
    const [showPopper, setShowPopper] = useState(false);
    const searchIconRef = useRef(null);
    const [selectedSearchTab, setSelectedSearchTab] = useState(
        localStorage.getItem("selectedSearchTab") || searchTabs[0].id
    );
    useEffect(() => {
        localStorage.setItem("selectedSearchTab", selectedSearchTab);
    }, [selectedSearchTab]);

    useEffect(() => {
        const hasSeenPopper = localStorage.getItem('hasSeenGlobalSearch');
        if (!hasSeenPopper || hasSeenPopper === "false" || hasSeenPopper === "null") {
            setShowPopper(true);
            localStorage.setItem('hasSeenGlobalSearch', false);
        }
    }, []);


    const OnDismiss = () => {
        localStorage.setItem('hasSeenGlobalSearch', true);
        setShowPopper(false);
    }
    const handleOpenDialog = () => {
        OnDismiss();
        setDialogOpen(true);

        if (ReactGA.ga && typeof ReactGA.event === "function") {
            ReactGA.event({
                category: "Global Search",
                action: "Opened Global Search",
                label: "User clicked on global search",
            });
        } else {
            console.warn("Google Analytics (GA4) is not initialized. Skipping event tracking.");
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const OnChangeSearchTab = (tab) => {
        setSelectedSearchTab(tab);
        //setSearchQuery('');
    };

    const handleSearchQueryChange = (e) => {
        let value = e.target.value;
        setSearchQuery(value);
    };

    const clearSearch = () => {
        setSearchQuery('');

    }
    console.log("sklklaklakal", showPopper)
    return (
        <>
            <span ref={searchIconRef} className='ri-search-2-line font-24 color-tertiary line-height-normal cursor-pointer' onClick={() => { handleOpenDialog() }}></span>
            {/* Popper - Positioned below the search icon */}
            {showPopper && searchIconRef.current && (
                <Popper id={'spring-popper'} sx={{ zIndex: 1500, marginTop: '20px !important' }} open={showPopper} anchorEl={searchIconRef.current} placement="bottom-end"
                disablePortal={true}
                modifiers={[
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: 'window',
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['top-start', 'bottom-start'],
                        },
                    },
                ]}
                >
                    <GlobalSearchHint OnDismiss={OnDismiss} />
                </Popper>
            )}
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                PaperProps={{
                    sx: {
                        position: "absolute",
                        left: "auto",
                        top: '70px',
                        width: "629px", // Adjust width as needed
                        maxWidth: "629px", // Max width for better UI
                        //border: '1px solid #CACACA',
                        borderRadius: '10px',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        overflow: 'visible',
                    },
                }}
            >
                <DialogTitle className='d-flex space-between align-center' sx={{ padding: '14px 20px',backgroundColor:'#124D9B',borderRadius:'8px',borderBottomLeftRadius:'0px',borderBottomRightRadius:'0px',color:'#FFFFFF' }}>
                    <span className='font-16 font-weight-600 color-white line-height-normal'>Global Search</span>
                    <span className='ri-close-fill font-20 color-white cursor-pointer line-height-normal' onClick={() => { handleCloseDialog() }}></span>
                </DialogTitle>
                <Root>
                    <SearchTabs searchEntity={selectedSearchTab}>
                        {searchTabs.map((tab) => {
                            let selected = selectedSearchTab == tab.id;
                            return <div className={`tab ${selected ? "selected" : '' }`} onClick={selected ? ()=>{} : ()=>{OnChangeSearchTab(tab.id)}}>
                                {tab.label}
                            </div>
                        })}
                    </SearchTabs>
                    <div>
                        <SearchBoxContainer>
                            {/* <SearchCategoryTabs list={searchTabs} selectedOption={selectedSearchTab} onChangeHandler={OnChangeSearchTab} optionsWidth={130} /> */}
                            <InputWrapper>
                                <span className='ri-search-2-line font-16 color-tertiary line-height-normal mb-2'></span>
                                <InputSearch type='text' placeholder='Search' value={searchQuery} onChange={handleSearchQueryChange} />
                            </InputWrapper>
                            {searchQuery.length > 0 &&
                                <span className='ri-close-fill font-20 color-tertiary cursor-pointer' onClick={() => { clearSearch() }}></span>
                            }
                        </SearchBoxContainer>
                        {selectedSearchTab == 'features' ? 
                            <FeatureResultWindow onClose={handleCloseDialog} searchQuery={searchQuery} searchEntity={selectedSearchTab} onChangeHandler={OnChangeSearchTab} />
                        :
                        
                            <ResultsWindow onClose={handleCloseDialog} searchQuery={searchQuery} searchEntity={selectedSearchTab} onChangeHandler={OnChangeSearchTab} />
                        }
                    </div>
                </Root>
            </Dialog>
        </>
    )
}

export default GlobalSearch;

const SearchBoxContainer = styled('div')({
    border: '1px solid #CACACA',
    padding: '6px 10px 6px 7px', 
    height: '32px', 
    borderRadius: '6px',
    display: 'grid',
    gridTemplateColumns: '1fr 20px',
    gap: '20px',
    alignItems: 'center',
    '& .ri-close-fill': {
      lineHeight: '0',
    },
    '&:focus-within': {
      borderColor: '#0086FF',
    },
});
  
const InputWrapper = styled('div')({
position: 'relative',
width: '100%',
display: 'flex',
alignItems: 'center', 
height: '18px', 
'& .ri-search-2-line': {
    marginBottom: '0', 
    fontSize: '16px',
},
});
  
const InputSearch = styled('input')({
    border: 'none',
    outline: 'none',
    width: '100%',
    height: '16px', 
    paddingLeft: '6px',
    fontSize: '14px', 
    '&::placeholder': {
        color: '#787878',
        fontSize: '14px',
        fontWeight: 400,
    },
    '&:focus': {
        outline: 'none !important',
        border: 'none !important',
    },
});

const SearchTabs = styled('div')(({searchEntity}) => ({
    display:'flex',
    flexDirection:'column',
    gap:'5px',
    '& .tab':{
        padding:'6px 8px',
        borderRadius:'6px',
        color:'#787878',
        cursor:'pointer',
        fontSize:'12px',
        lineHeight:'14px',
        '&.selected':{
            backgroundColor:'#F4F4F4',
            color:'#2F2F2F',
            fontWeight:'500',
            border:'1px solid #E6E6E6'
        },
        "&:hover":{
            backgroundColor:'#F4F4F4',
        }
    }
}))

const Root = styled('div')({
    width:'100%',
    //height:'433px',
    //maxHeight:'433px',
    padding:'16px 20px',
    backgroundColor: "#FFFFFF",
    display:'grid',
    gridTemplateColumns:'155px 1fr',
    gap:'38px',
    borderBottomLeftRadius:'8px',
    borderBottomRightRadius:'8px',
})