import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { getCommonFunctions } from '../../../../../serviceRevamp/add/ci_flow/SourceDetails';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import InvokeApi, { PostData, DeleteData } from '../../../../../../util/apiInvoker';
import properties from '../../../../../../properties/properties';
import { Loading } from '../../../../../utils/Loading';

import { ErrorComponent } from '../../../../../utils/Error';

import StepProgressDetailed from '../../../../../../components/genericComponents/StepProgressDetailed';

import AddAutoScalingForm from './AutoScalingComponents/AddAutoScalingForm';
import OverviewCardAutoScaling from './AutoScalingComponents/OverviewCardAutoScaling';

import DefaultHpaOverview from './AutoScalingComponents/DefaultHpaOverview';

import DeployPossibleHpa from './AutoScalingComponents/DeployPossibleHpa';
import { showErrorHandlerUpdated } from '../../../../../../util/util';
const Autoscaling = (props) => {
  const classes = useStyles();
  const inherits = props.inherits ? props.inherits : {};
  const cd_data = props.cd_data ? props.cd_data : null
  const extraProps = props.extraProps ? props.extraProps : "";
  const [state, setState] = useState(getResourceQuotaDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  console.log(cd_data, "cd id in =========>")


  useEffect(() => {
    fetchAutoScalingData()
  }, [extraProps.service_id, extraProps.component_env_id, state.count, cd_data])

  function refreshState() {
    setState(new_state => ({
      ...new_state,
      count: new_state.count + 1,
      task_id: null
    }));
  }

  function fetchAutoScalingData() {
    var requestInfo = {
      endPoint: GenerateURL({ service_id: extraProps.service_id, component_env_id: extraProps.component_env_id, cd_id: cd_data[0].id }, properties.api.save_autoscaling),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, fetchAutoScalingSuccess, fetchAutoScalingFail);
    setState(new_state => ({
      ...new_state,
      loading_hpa_data: true,
      error_in_loading_hpa_data: false
    }));
  }

  function fetchAutoScalingSuccess(response) {
    var data_arr = response.reverse()
    setState(new_state => ({
      ...new_state,
      hpa_data: data_arr,
      loading_hpa_data: false,
      error_in_loading_hpa_data: false
    }))
  }

  function fetchAutoScalingFail(response) {
    var error_msg = response.toString()
    setState(new_state => ({
      ...new_state,
      hpa_data: null,
      loading_hpa_data: false,
      error_in_loading_hpa_data: true,
      error_msg: "Unable to load data due to this reason: " + " " + error_msg
    }));
  }
  function handleCloseForm() {
    setState(new_state => ({
      ...new_state,
      showAddHpaForm: false,
      add_default_hpa: false
    }));
  }
  function validateAndApplyHPA(id) {
    var post_url = GenerateURL({ service_id: extraProps.service_id, component_env_id: extraProps.component_env_id, cd_id: cd_data[0].id,  id :  id}, properties.api.apply_time_based_default_hpa)

    var post_data = {
      env_cd_detail_id: cd_data ? cd_data[0].id : ""
    }
    PostData(post_url, post_data, onSaveSuccess, onSaveFail);
    setState(new_state => ({
      ...new_state,
      loading: false,
      // error_msg: "Unable to add HPA due to following reasons:" + " " + error,
      open: true,
      hpa_deployment: "failed",
      // deployment_failed_msg: error,
      task_id: null
    }));

  }
  function onSaveSuccess(response) {
    var msg = response.detail

    setState(new_state => ({
      ...new_state,
      loading: false,
      error_msg: null,
      open: false,
      hpa_deployment: "success",
      deployment_success_msg: msg,
      task_id: response.task_id
    }));

  }

  function onSaveFail(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      loading: false,
      error_msg: "Unable to add HPA due to following reasons:" + " " + error,
      open: true,
      hpa_deployment: "failed",
      deployment_failed_msg: error,
      task_id: null
    }));

  }
  function onSaveSuccess(response) {
    var msg = response.detail

    setState(new_state => ({
      ...new_state,
      loading: false,
      error_msg: null,
      open: false,
      hpa_deployment: "success",
      deployment_success_msg: msg,
      task_id: response.task_id
    }));

  }
  function validateAndDeleteHPA(id) {
    var post_url = GenerateURL({ service_id: extraProps.service_id, component_env_id: extraProps.component_env_id, cd_id: cd_data[0].id, id:id }, properties.api.apply_time_based_default_hpa)

    var post_data = {
      env_cd_detail_id: cd_data && cd_data[0] ? cd_data[0].id : ""
    }
    DeleteData(post_url, post_data, onDeleteSuccess, onDeleteFail);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: false
    }))
  }
  function onDeleteFail(error) {
    var error_msg = showErrorHandlerUpdated(error)
    console.log(error_msg,error,"fdsfsafsaf")
    setState(new_state => ({
      ...new_state,
      loading: false,
      error_msg: error_msg,
      open: false,
      task_id: null
    }));

  }
  function onDeleteSuccess(response) {
    refreshState();
    setState(new_state => ({
      ...new_state,
      loading: false,
      error_msg: null,
      open: false,
      task_id: response.task_id
    }));

  }
  function validateAndApplyHPACurrentCardHpa(id,findValidHPA) {
    var post_url = GenerateURL({ service_id: extraProps.service_id, component_env_id: extraProps.component_env_id, cd_id: cd_data[0]&& cd_data[0].id, id: id }, properties.api.apply_hpa);
    var basic_details = {
      env_cd_detail_id: cd_data[0].id ? cd_data[0].id : "",
      valid_hpa: findValidHPA ? findValidHPA : false,
    };
    PostData(post_url, basic_details, onCurrentHpaHitSuccess, onCurrentHpaHitFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }
  function onCurrentHpaHitFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      loading: false,
      error_msg: "Unable to add HPA due to following reasons:" + " " + error,
      open: true,
      hpa_deployment: "failed",
      deployment_failed_msg: error
    }));

  }
  function onCurrentHpaHitSuccess(response) {
    var msg = response.detail
    refreshState();
    setState(new_state => ({
      ...new_state,
      loading: false,
      error_msg: null,
      open: true,
      hpa_deployment: "success",
      deployment_success_msg: msg,
      task_id: response.task_id
    }));

  }

  function sendApprovalRequest(id) {
    var post_url = GenerateURL({}, properties.api.approval_request);

    var post_obj = {
      "name": "env_cd_scale",
      "entity_id": id
    }

    PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function sendApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_request_success_msg: msg,
    }));

  }

  function sendApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      deployment_failed_msg: error
    }));

  }

  function refreshState() {
    console.log("function running")
    setState(new_state => ({
      ...new_state,
      count: new_state.count + 1
    }));
  }

  function confirmForApproval(id) {
    var post_url = GenerateURL({}, properties.api.confirm_approval);

    var post_obj = {
      "name": "env_cd_scale",
      "entity_id": id
    }

    PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function confirmApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_confirm_success_msg: msg,
    }));
  }

  function confirmApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      approval_failed_msg: error
    }));
  }

  function refreshStateAfterDeploy(){
    fetchAutoScalingData()
    setState(new_state => ({
      ...new_state,
      task_id: null
    }));
  }
  return (
    <>
      {
        state.loading_hpa_data ?
          <div className="card-body" style={{ height: 350 }}>
            <Loading varient="light" />
          </div>
          :
          state.error_in_loading_hpa_data ?
            <ErrorComponent error={state.error_msg} variant="msg-box" />
            :
            state.task_id ?
              <StepProgressDetailed
                variant="SAME_PAGE_REDIRECT"
                logs_on_new_tab={true}
                task_id={state.task_id}
                placeholders={{ application_id: extraProps.application_id, component_id: extraProps.service_id }} 
                refresh={refreshStateAfterDeploy}
              /> :

              <div className="card-with-edit-service">
                <HeadingSection
                  handleCloseForm={handleCloseForm}
                  Component={
                    state.hpa_data && state.hpa_data.length > 0 ?
                      
                        <DeployPossibleHpa
                          extraProps={extraProps}
                          refreshFn={refreshState}
                          validateAndDeleteHpa={validateAndDeleteHPA}
                          validateAndApplyHPA={validateAndApplyHPACurrentCardHpa}
                          hpa_data={state.hpa_data}
                          error_msg={state.error_msg ? state.error_msg: null}
                        />
                      
                      : null
                  }
                />
                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
                  <button
                    className="btn btn-with-icon btn-round"
                    onClick={refreshState}
                  >
                    <span className="flaticon-refresh-button-1"></span>
                  </button>
                </div>
                <div className={classes.root + " " + "card-body-with-edit-service pd-10"}>
                  <Grid container className="card-add-service" spacing="2">
                    {
                      state.hpa_data && state.hpa_data.length > 0 ?
                        <>
                          {
                            state.hpa_data.map(item => (
                              item.default ?
                                <Grid lg={12} item>
                                  <DefaultHpaOverview
                                    extraProps={extraProps}
                                    validateAndApplyHPA={validateAndApplyHPACurrentCardHpa}
                                    refreshFn={refreshState}
                                    hpa_data={item}
                                    cd_data={cd_data}
                                    sendForApproval={sendApprovalRequest}
                                    confirmForApproval={confirmForApproval}
                                  />
                                </Grid>
                                : null))
                          }
                        </> :
                        <Grid lg={12} item>
                          <AddAutoScalingForm
                            prev_state={state.hpa_form_data}
                            extraProps={extraProps}
                            cd_data={cd_data}
                            inherits={state.child_inherits.hpa_form_data}
                            refreshFn={refreshState}
                            variant="DEFAULT_HPA"
                          />
                        </Grid>}
                    {
                      state.hpa_data && state.hpa_data.length > 0 ?
                        <>
                          <div className="d-flex align-center space-between bg-fa-grey border-top border-bottom pd-10 mb-20" style={{ width: '100%' }}>
                            <p className="font-12   ">Time based HPA</p>
                            <AddAutoScalingForm
                              prev_state={state.hpa_form_data}
                              extraProps={extraProps}
                              cd_data={cd_data}
                              inherits={state.child_inherits.hpa_form_data}
                              refreshFn={refreshState}
                              variant=""
                            />
                          </div>
                          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "3px" , width : "100%" }}>
                            <button
                              className="btn btn-with-icon btn-round"
                              onClick={refreshState}
                            >
                              <span className="flaticon-refresh-button-1"></span>
                            </button>
                          </div>
                          {
                            state.hpa_data.length == 1 ?
                              <div
                                className="text-center alert alert-primary border-style-dashed bg-white m-auto"
                                style={{ width: '400px' }}>
                                <div className="text-box">
                                  <p className="main-text  font-14"> Time based HPA not added</p>
                                </div>
                              </div>
                              :

                              state.hpa_data.map(item => (
                                !item.default ?
                                  <Grid lg={12} item>
                                    <OverviewCardAutoScaling
                                      extraProps={extraProps}
                                      refreshFn={refreshState}
                                      hpa_data={item}
                                      validateAndApplyHPA={validateAndApplyHPACurrentCardHpa}
                                      cd_data={cd_data}
                                      sendForApproval={sendApprovalRequest}
                                      confirmForApproval={confirmForApproval}
                                    />
                                  </Grid>
                                  : null))

                          }
                        </>
                        :
                        null
                    }
                  </Grid>
                </div>
              </div>
      }
    </>
  )
}

Autoscaling.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


export default Autoscaling;

export function getResourceQuotaDefaultState() {
  return {
    hpa_data: null,
    error_in_loading_hpa_data: false,
    show_form: false,
    show_msg: false,
    showAddHpaForm: false,
    replace_button: false,
    default_hpa: false,
    task_id: null,
    count: 1,
    data: {},
    error: {},
    child_inherits: {
      hpa_form_data: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .input-with-btn': {
      border: '0px'
    },
    '& .card': {
      boxShadow: 'none',
      '& .heading': {
        '& .card-header': {
          paddingBottom: 10,
          borderTop: 0,
          borderBottom: '1px solid #dedede'
        },
      }
    },
    padding: '0px',
    '& > .card-add-service': {
      borderRadius: '0px',
      border: '0px'
    },
    '& .card-add-service-head': {
      borderTop: '1px solid #e7e7eb',
      borderRadius: '0px',
    },
    '& > .card': {
      backgroundColor: '#f6f8f8',
      borderRadius: '8px',
      border: '1px',
      '& .card-footer': {
        backgroundColor: '#fff',
        justifyContent: 'flex-end'
      },
      '& .card ': {
        marginBottom: '15px',
        boxShadow: 'none',
        '& .input-component': {
          marginBottom: '0px'
        }
      },

    }
  }
}))


const HeadingSection = (props) => {
  return (
    <div className="card-header-with-edit-service d-flex align-center space-between pd-10">
      <div>
        <p className="font-14 font-weight-300 lh-12">
          HPA - Horizontal POD Autoscaler Settings
        </p>
        <p className="font-12 text-grey-83">
          To learn more about auto scaling please Read the <a className="text-anchor-blue" href="https://www.opstree.com/buildpiper/documentation/" target="_blank">Documentation</a>
        </p>
      </div>
      <div>
        {props.Component}
      </div>
    </div>
  )
} 

HeadingSection.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}