import React,{useState} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CustomizedProgressBar from '../deployment/components/CustomizedProgressBar';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
// import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';

// const AutoPlaySwipeableViews = SwipeableViews;
export default function MultiChart (props){
    const chart_data = props.chart_data
    const series_list = chart_data.series_list
    const replicasets =chart_data.replicasets
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const handleStepChange = (step) => {
        setActiveStep(step);
      };
    const new_replicaset_chart_data=series_list[series_list.length-1]
    const new_replicaset=replicasets[replicasets.length-1]
    var colors = ['#211c4f', '#ffb738', '#69e09c', '#e9797e']
    const options1 = {
        chart: {
            type: 'pie',
            renderTo: 'container',
          },
          colors:new_replicaset_chart_data.colors,
          title: {
            verticalAlign: 'middle',
            floating: true,
            text: null,
          },
          credits: {
            enabled: false
        }, 
          plotOptions: {
              
            pie: {
                size:350,
                innerSize: '80%',
                dataLabels: {
                enabled: false
            },
            }
          },
      
          series: new_replicaset_chart_data.series,
    };
   
    return(
        
            <div className="d-flex align-center space-between">
                <OldReplicaSetCharts series_list={series_list} replicasets={replicasets}/>
                <div style={{ width: 210 }} >
                <p className="font-16 nowrap">Deployment  {new_replicaset.metadata.annotations.deployment_number?new_replicaset.metadata.annotations.deployment_number.replace("$","#"):null} <span className="text-grey-b6">In Progress..</span></p> 
                    <p className="font-13 text-center mb-10">{new_replicaset.status.readyReplicas?new_replicaset.status.readyReplicas:0} of {new_replicaset.spec.replicas} pods ready</p>
                    <CustomizedProgressBar />
                </div>
                <div className={classes.root}>
                    {/* <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    > */}
                        <div>
                            <div className="p-relative" >
                                <HighchartsReact highcharts={Highcharts} options={options1} />
                                <div className="multi-hightchart-center-data not-found-data p-absolute d-flex align-center justify-center">
                                    <div className="text-center pd-20 ">
                                        <div className="font-14 color-dark mb-5">New Replica Set</div>
                                        <div className="round-div m-auto pods-status not-found-state mb-5">
                                           {new_replicaset.status.readyReplicas?new_replicaset.status.readyReplicas:0}
                                        </div>
                                        <p className="font-12 text-grey-83">PODS READY</p>
                                    </div>
                                </div>
                            </div>

                            <div className="gen-data">
                                <span className="font-11 text-grey-83">Artifact: </span><span className="font-11 text-grey-b6">{new_replicaset.metadata.annotations.deployment_tag} </span>
                                <div className="font-11">
                                    <span className="font-11 text-grey-83">Namespace: </span><span className="font-11 text-grey-b6">{new_replicaset.metadata.namespace} </span>
                                    <span className="font-11 text-grey-83">Deployed : </span><span className="font-11 text-grey-b6">{moment(new_replicaset.metadata.creationTimestamp).fromNow()}</span>
                                </div>
                            </div>
                            <div style={{ height: 22 }}></div>
                        </div>

                    {/* </SwipeableViews> */}
                </div>
            </div>
           
        
    )
}

MultiChart.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


function OldReplicaSetCharts(props) {
  const series_list=props.series_list
  const replicasets = props.replicasets
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(series_list.length-2);
  const options_array = getOptions()
  
  const maxSteps = series_list.length-1;

  const handleNext = () => { 
    if(activeStep<maxSteps-1){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if(activeStep>0){
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  console.log("jkjska",series_list)
  function getOptions(){
    var options_array =[]
    series_list.forEach((series,index) => {
      if(index != series_list.length-1){
        options_array.push(
          {  
            chart: {
                type: 'pie',
                renderTo: 'container',
                
              },
              colors:series.colors,
              title: {
                verticalAlign: 'middle',
                floating: true,
                text: null,
              },
              tooltip: {
                useHTML: true,
                style: {
                    'z-index': 9999999
                }
            },
              credits: {
                enabled: false
            },
              plotOptions: {
                  
                pie: {
                    size:350,
                  innerSize: '80%',
                  dataLabels: {
                    enabled: false
                },
                }
              },
              series: series.series,
          }
        )
  
      }
      })
      
    return options_array
  }
  
  return (
   
    <div className={classes.root}>
  
      {/* <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >       */}
      {options_array && options_array.map((option,index)=>(
        <div>
        <div className="p-relative">
            <HighchartsReact highcharts={Highcharts} options={option} />
            <div className="multi-hightchart-center-data success-data p-absolute d-flex align-center justify-center">
                <div className="text-center pd-20 ">
                    <div className="font-14 color-dark mb-5">Old Replica Set</div>
                    <div className="round-div m-auto pods-status success-state mb-5">
                       {replicasets[activeStep].status.readyReplicas?replicasets[activeStep].status.readyReplicas:0}
                    </div>
                    <p className="font-12 text-grey-83">PODS Ready</p>
                </div>
            </div>
        </div>
        <div className="gen-data">
                <span className="font-11 text-grey-83">Artifact: </span><span className="font-11 text-grey-b6">{replicasets[index].metadata.annotations.deployment_tag} </span>
                <div className="font-11">
                <span className="font-11 text-grey-83">Namespace: </span><span className="font-11 text-grey-b6">{replicasets[index].metadata.namespace}  </span>
                <span className="font-11 text-grey-83">Deployed On: </span><span className="font-11 text-grey-b6">{moment(replicasets[index].metadata.creationTimestamp).fromNow()}</span>
                </div>
            </div>
        </div>))}
      {/* </AutoPlaySwipeableViews> */}
   
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        
        onClick={() =>{}}
        activeStep={activeStep}
        nextButton={
          null
        }
        backButton={
          null
        }
      />
    </div>
  );
}

OldReplicaSetCharts.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}




const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 400,
      flexGrow: 1,
      '& .MuiMobileStepper-root':{
          backgroundColor: '#fff'
      },
      '& .multi-hightchart-center-data':{
          left:'24%'
      },
      '& .MuiMobileStepper-dot':{
          borderRadius:0,
          width:15,
          height:5,
          '&:hover':{
              cursor: 'pointer'
          }
      },
      '& .MuiMobileStepper-dots':{
          margin:'auto'
      }
    },
  }));