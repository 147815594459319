import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import SetAsDefaultDialog from './SetAsDefaultDialog';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import PageHeader from '../../../components/PageHeader';

const JobListingHeader = props => {
    const classes = useStyles();
    const application_id = props.application_id;
    const template_list = props.template_list;
    const changeDefaultJobTemplate = props.changeDefaultJobTemplate;
    const onCreateDefaultTemplate = props.onCreateDefaultTemplate;
    const inherits = props.inherits ? props.inherits : {}
    const [state, setState] = useState({
        data: {}, error: {}, child_inherits: {
            update_default_job_template: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        }
    })

    return (
        <PageHeader 
            heading={"Global job Templates"}
            subHeading={" Displaying all global job templates"}
            commonDivMargin={true}
            icon="ri-file-paper-line"
            backgroundColor='#FFFFFF'
            primaryButton={null}
        />
    )
}
const useStyles = makeStyles({

});

export default JobListingHeader;


