import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles"
import { Input } from '../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { Link } from 'react-router-dom';
import GenerateURL from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../util/Validator';
import { Loading } from '../../utils/Loading';
import { RemoveFromArray, showErrorHandlerUpdated } from '../../../util/util';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';


const AddHelmIntegration = ({ open, handleClose, edit_id, refreshFn }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        data: {},
        test_connection_flag: false,
        is_form_dirty: false,
        error: {},
        showLeftPanel: true,
        loading_data: false,
        available_monitoring_resource_list: [],
        credential_list: [],
        validations: {
            name: [VALIDATION_TYPE_REQUIRED],
            helm_url: [VALIDATION_TYPE_REQUIRED]
        }
    })
    const [showInput, setShowInput] = useState(false);
    const [data, setData] = useState({ data: {}, error: {} })
    const commonFunctions = getCommonFunctions(state, setState, {})
    const showInfoSection = JSON.parse(localStorage.getItem('heml_char_dialog_display_flag'));

    useEffect(() => {
        fetchCredentials();
        if (edit_id) {
            fetchAndSetDatatoState()
        }
    }, [edit_id])



    function fetchCredentials() {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.credential_all),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        requestInfo.endPoint = requestInfo.endPoint + "?all=true";

        InvokeApi(requestInfo, CredentialsFetchSuccess, CredentialsFetchFailure);
        setState(new_state => ({
            ...new_state,
            credentials_status: "LOADING",
        }));
    }

    function CredentialsFetchSuccess(data) {

        if (data && data.results) {
            var credentials = data.results.length > 0 ? data.results.map(

                item => {
                    console.log(item, "fdsanfjknsajk")
                    return {
                        id: item.id,
                        label: item.name
                    }
                }
            ) : []
            setState(new_state => ({
                ...new_state,
                credential_list: credentials,
                credentials_status: "SUCCESS",

            }))
        } else {
            setState(new_state => ({
                ...new_state,
                credential_list: [{ id: '', label: 'No Secret Added yet.' }],
                credentials_status: "SUCCESS",
            }));
        }

    }

    function CredentialsFetchFailure(error, exception) {

        setState(new_state => ({
            ...new_state,
            credentials_status: "FAILED",
            error_in_load_credentials: error
        }));

    }


    const fetchAndSetDatatoState = () => {
        var requestInfo = {
            endPoint: GenerateURL({ id: edit_id }, properties.api.update_helm_chart),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchAndSetDatatoStateSuccess, fetchAndSetDatatoStateFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true,
        }));
    }
    const fetchAndSetDatatoStateSuccess = (response) => {

        setState(new_state => ({
            ...new_state,
            loading_data: false,
            data: {
                ...new_state.data,
                ...response,
            }
        }));
    }

    const fetchAndSetDatatoStateFailure = () => {
        setState(new_state => ({
            ...new_state,
            loading_data: false,
        }));
    }



    const handleCloseLeftStip = () => {
        if (data?.data?.dont_show_again) {
            setDataToLocalStorage()
        }
        setState(new_state => ({
            ...new_state,
            showLeftPanel: false
        }));
    }
    function setDataToLocalStorage() {
        localStorage.setItem('heml_char_dialog_display_flag', data.data.dont_show_again);
    }
    function onChangeDoNotshow(e) {
        var key = e.target.name;
        var value = e.target.value;
        if (key == "dont_show_again") {
            value = !data.data.dont_show_again;
        }
        setData((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value,
            },
            error: {
                ...prevState.error,
                [key]: '', // Reset error on change
            },
        }));
    }

    function testConnectionData() {
        var result = ValidateDataSet(state.data, state.validations);
        var post_url = GenerateURL({}, properties.api.test_helm);
        if (result.valid) {
            var basic_details = {};
            basic_details = {
                "helm_url": state.data["helm_url"],
                "credential_id": state.data["credential_id"]
            };

            PostData(post_url, basic_details, onTestConnectionSuccess, onTestConnectionFail);
            setState((new_state) => ({
                ...new_state,
                loading_data: true,
                error_msg: null,
            }));


        } else {
            setState((new_state) => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }));
        }
    }

    function onTestConnectionFail(error) {
        console.log(error, "Fdsjfnsjnajfjksajfdsa")
        var error = showErrorHandlerUpdated(error);
        setState((new_state) => ({
            ...new_state,
            loading_data: false,
            show_save_msg: "failed",
            error_in_save: error,
            test_connection_flag: false,
            error_msg: error,
            data: {
                ...new_state.data,
            }
        }));
    }
    function onTestConnectionSuccess(response) {
        console.log(response, "Fdsjfnsjnajfjksajfdsa")
        var msg = showErrorHandlerUpdated(response && response[0] ? response[0] : response);
        setState((new_state) => ({
            ...new_state,
            loading_data: false,
            error_in_save: null,
            canRedirect: true,
            test_connection_flag: true,
            show_save_msg: "success",
            error_msg: null,
            success_msg: msg
        }));
    }

    function validateAndSave() {
        var result = ValidateDataSet(state.data, state.validations);
        if (edit_id) {
            var post_url = GenerateURL(
                { id: edit_id },
                properties.api.update_helm_chart
            );
        } else {
            var post_url = GenerateURL({}, properties.api.get_post_helm_chart);
        }
        if (result.valid) {
            var basic_details = {};
            basic_details = {
                ...state.data,
            };

            PostData(post_url, basic_details, onSaveSuccess, onSaveFail);
            setState((new_state) => ({
                ...new_state,
                loading_data: true,
                error_msg: null,
            }));


        } else {
            setState((new_state) => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }));
        }
    }
    function onSaveFail(error) {
        console.log(error, "Fdsjfnsjnajfjksajfdsa")
        var error = showErrorHandlerUpdated(error);
        setState((new_state) => ({
            ...new_state,
            loading_data: false,
            show_save_msg: "failed",
            error_in_save: error,
            error_msg: error,
            test_connection_flag: false,
            data: {
                ...new_state.data,
                resource_name: null,
                credential_id: null,
                monitoring_url: ""
            }
        }));
    }
    function onSaveSuccess(response) {

        handleClose();
        refreshFn();
        setState((new_state) => ({
            ...new_state,
            loading_data: false,
            error_in_save: null,
            canRedirect: true,
            test_connection_flag: false,
            show_save_msg: "success",
            error_msg: null,
        }));
    }
    const handleCloseAlert = () => {
        setState((prevState) => ({
          ...prevState,
          show_save_msg: null, // Clear the alert status
        }));
      };
    const onChangeHandler = (e) => {
        var v = e.target.value;
        var k = e.target.name;
        if (state.test_connection_flag && state.is_form_dirty) {
            setState((new_state) => ({
                ...new_state,
                test_connection_flag: false
            }));
        }
        setState((new_state) => ({
            ...new_state,
            is_form_dirty: true
        }));

        commonFunctions.onChangeHandler(e);
    }


    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={`${classes.root} dialog-align-corner`}
            >
                <div className='d-grid ml-auto dialog-sub-component' style={showInfoSection ? { gridTemplateColumns: '450px' } : { gridTemplateColumns: '396px 450px' }}>
                    {
                        !showInfoSection &&
                        <div className={state.showLeftPanel ? 'left-panel-dialog bg-white position-relative' : 'left-panel-dialog-down'}>
                            <div className={'d-flex align-center space-between left-panel-header pd-10'} style={{ padding: '10px 20px' }} >
                                <p>Information</p>
                                <button
                                    className='btn btn-icon-only'
                                    onClick={handleCloseLeftStip}
                                >
                                    <span className='ri-close-line color-icon-secondary'></span>
                                </button>
                            </div>
                            <div className='pd-10' style={{ padding: '10px 20px' }}>
                                {/* Added Correct information about helm repo */}
                                <div className='mb-10'>
                                    <p className='font-16 font-weight-600 color-primary mb-10'>What is a Helm Repository?</p>
                                    <p className='font-12 color-icon-secondary' style={{ color: '#404040' }}>
                                        A Helm repository is a location where Helm charts are stored and shared.
                                        It serves as a central place where charts can be published, searched, and retrieved.
                                        Repositories can be hosted on various platforms, including cloud storage services, web servers,
                                        or dedicated Helm repository services.
                                    </p>
                                </div>
                                <div className='mb-10'>
                                    <span className='font-12 font-weight-600 color-primary mb-10'>Public Helm Repositories: </span>
                                    <p className='font-12 color-icon-secondary ' style={{ color: '#404040', display: "inline" }}>
                                        The repositories are publicly accessible and often contain a wide range of commonly used
                                        Helm charts. Examples include the official Helm stable repository, Bitnami, and Artifact Hub.
                                    </p>
                                </div>
                                <div>
                                    <span className='font-12 font-weight-600 color-primary mb-10'>Private Helm Repositories: </span>
                                    <p className='font-12 color-icon-secondary ' style={{ color: '#404040', display: "inline" }}>
                                        The restricted repositories used within organizations to share internal Helm charts.
                                    </p>
                                </div>
                            </div>
                            <div className='checkbox-only-divi' style={{ padding: '10px 20px' }}>
                                <Input
                                    type="simple-checkbox"
                                    name="dont_show_again"
                                    label="Dont show this again"
                                    mandatorySign
                                    data={data.data}
                                    error={data.error}
                                    onChangeHandler={onChangeDoNotshow}
                                />
                            </div>
                        </div>
                    }
                    <div className='right-panel-dialog bg-white'>
                        <div className='pd-20 font-18 font-weight-600 color-white d-flex align-center space-between' style={{ backgroundColor: '#0086ff' }}>
                            <p>{edit_id ? "Edit" : "Add"} HELM Repo</p>
                            <button
                                className='btn float-cancel-button'
                                style={showInfoSection ? { left: '396px' } : state.showLeftPanel ? {} : { left: '396px' }}
                                onClick={handleClose}
                            >
                                <span className='ri-close-line'></span>
                            </button>
                        </div>
                        {
                            state.loading_data ?
                                <Loading varient="light" />
                                :
                                <>
                                    <div className='pd-10'>
                                        <Input
                                            type="text"
                                            name="name"
                                            label="Enter Repo Name"
                                            placeholder="helm repo name"
                                            mandatorySign
                                            data={state.data}
                                            error={state.error}
                                            onChangeHandler={commonFunctions.onChangeHandler}
                                        />
                                        <Input
                                            type="select"
                                            name="credential_id"
                                            list={state.credential_list}
                                            label="Select Secrets"
                                            placeholder="test-username"
                                            onChangeHandler={onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                            extraDiv={
                                                <Link className='text-anchor-blue d-flex align-center' to="/secrets/add"><i className="ri-add-line"></i> New Secret</Link>
                                            }
                                        />
                                        <Input
                                            type="text"
                                            name="helm_url"
                                            label="Enter Helm URL"
                                            placeholder="your helm url goes here"
                                            mandatorySign
                                            onChangeHandler={onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </div>
                                    <div className='footer-right-panel'>
                                        <button
                                            className='btn btn-primary font-12 font-weight-500 d-flex align-center justify-center'
                                            onClick={state.test_connection_flag ? validateAndSave : testConnectionData}
                                        >{state.test_connection_flag ? "Save" : "Test Connection"}</button>
                                    </div>
                                </>
                        }

                    </div>
                </div>
            </Dialog >
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={state.show_save_msg === "success" || state.show_save_msg === "failed"}
                onClose={handleCloseAlert}
                autoHideDuration={3000}
            >
                {state.show_save_msg === "success" ? (
                    <Alert severity="success" variant="filled">{state.success_msg}</Alert>
                ) : state.show_save_msg === "failed" ? (
                    <Alert severity="error" variant="filled">{state.error_in_save}</Alert>
                ) : null}
            </Snackbar>
        </>
    )
}
export default AddHelmIntegration
const useStyles = makeStyles((theme) => ({
    root: {
        '& .left-panel-dialog': {
            width: '396px',
            transition: 'width 5s',
            '& .left-panel-header': {
                borderBottom: '1px solid #f1f1f1'
            },
            '& .checkbox-only-divi': {
                position: 'absolute',
                bottom: '10px'
            },
        },
        '& .left-panel-dialog-down': {
            width: '0px',
            overflow: 'hidden',
            transition: `'width 5s', 'overflow 1s'`,
        },
        '& .footer-right-panel': {
            backgroundColor: '#fafafa',
            padding: '20px',
            position: 'absolute',
            bottom: '0px',
            width: '450px',
            // width:'100%',
            '& button': {
                marginLeft: 'auto'
            }
        }
    }
}));
