import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { Tooltip } from '@mui/material';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';

const AccessLevelOverview = props => {
    const prev_state = props.prev_state;
    console.log(prev_state, "rtyufghn===============>")
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const [showLoading, setShowLoading] = useState(false);
    const [state, setState] = useState({
    });
    const extraProps = props.extraProps;
    const auto_approval_flag = extraProps.auto_approval_flag;
    const application_id = extraProps.application_id;
    const service_id = extraProps.service_id;
    const component_env_id = extraProps.component_env_id;
    const show_sidecar_overview = extraProps.show_sidecar_overview;
    const env_cd_id = extraProps.env_cd_id;

    const service_env_data = extraProps.service_env_data;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_cd, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);

    console.log(is_permitted, "skbdbscjhbsdcjbzgcvsgdz")

    useEffect(() => {
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state])


    console.log(state, 'st_ppsdsd')
    return (
        <>
            {
                show_sidecar_overview ?
                    <div className="section-service-overview">
                        <div className="d-flex align-center space-between">
                            <div className="overview-heading">
                                Access Level
                            </div>
                            {is_permitted ?

                                state.status == "DRAFT" ?
                                <Link
                                to={{
                                  pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/sidecars/edit`,
                                  search: `?selectedTabOrder=1` +
                                          `&sidecar_id=${state.id}` +
                                          `&env_cd_id=${env_cd_id}` +
                                          `&service_env_data=${encodeURIComponent(service_env_data)}`,
                                }}
                              >
                                <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                              </Link>
                               :
                                    <EditDetailsConfirmationDialog
                                        application_id={application_id}
                                        cd_data={prev_state}
                                        service_id={service_id}
                                        auto_approval_flag={auto_approval_flag}
                                        component_env_id={component_env_id}
                                        selectedTabOrder={1}
                                        sidecar_id={state.id}
                                        env_cd_id={env_cd_id}
                                        service_env_data={service_env_data}
                                    />

                                : <Tooltip title="You are not allowed to perform this action" >
                                    <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                                </Tooltip>}


                        </div>

                        <div className="overview-card-section grid-temp-3-widthfull">
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <div className="text__box">
                                        <p className="overview-card-value-text text-uppercase">
                                            Deployment Details
                                        </p>
                                        {/* <p className="no-data__msg ">NA</p> */}

                                    </div>
                                </div>
                                <div className="overview-card-value-access" style={{ gap: '0px 20px' }}>
                                    {/* <p className="overview-card-value-text">
                                        Service Name:&nbsp;
                                        <span className="overview-card-value-text">
                                            {state.name}
                                        </span>
                                        <span className="no-data__msg "></span>
                                    </p> */}
                                    <p className="overview-card-value-text">Container Name:&nbsp;<span className="overview-card-value-text">{state.name}</span><span className="no-data__msg "></span></p>
                                    {/* <p className="overview-card-value-text">
                                        Deployment Name:&nbsp;
                                        <span className="overview-card-value-text">
                                            {state.deployment_name}
                                        </span>
                                        <span className="no-data__msg "></span>

                                    </p> */}
                                    {/* <p className="overview-card-value-text">
                                        Desired Replication:&nbsp;
                                        <span className="overview-card-value-text">
                                            {state.desired_replication}
                                        </span>
                                        <span className="no-data__msg "></span>

                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="section-service-overview">
                        <div className="d-flex align-center space-between">
                            <div className="overview-heading">
                                Access Level
                            </div>
                            {is_permitted ?

                                state.status == "DRAFT" ?
                                <Link
                                to={{
                                  pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/cd/${cd_id}/edit`,
                                  search: `?selectedTabOrder=1`,
                                }}
                              >
                                <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                              </Link>
                               :
                                    <EditDetailsConfirmationDialog
                                        application_id={application_id}
                                        cd_data={prev_state}
                                        service_id={service_id}
                                        auto_approval_flag={auto_approval_flag}
                                        component_env_id={component_env_id}
                                        selectedTabOrder={1}
                                        selected_cd_id={extraProps?.selected_cd_data?.id}
                                    />

                                : <Tooltip title="You are not allowed to perform this action" >
                                    <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                                </Tooltip>}


                        </div>

                        <div className="overview-card-section grid-temp-3-widthfull">
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <div className="text__box">
                                        <p className="overview-card-value-text text-uppercase">
                                            Deployment Details
                                        </p>
                                        {/* <p className="no-data__msg ">NA</p> */}

                                    </div>
                                </div>
                                <div className="overview-card-value-access" style={{ gap: '0px 20px' }}>
                                    <p className="overview-card-value-text">Service Name:&nbsp;<span className="overview-card-value-text">{state.service_name}</span><span className="no-data__msg "></span></p>
                                    {/* <p className="overview-card-value-text">
                                        Image Name:&nbsp;
                                        <span className="overview-card-value-text">
                                            {state.image_name}
                                        </span>
                                        <span className="no-data__msg "></span>
                                    </p> */}
                                    <p className="overview-card-value-text">Deployment Name:&nbsp;<span className="overview-card-value-text">{state.deployment_name}</span><span className="no-data__msg "></span></p>
                                    <p className="overview-card-value-text">
                                        Desired Replication:
                                        {
                                            state.desired_replication ?
                                                <span className="overview-card-value-text">
                                                    {state.desired_replication}
                                                </span> :
                                                <span className="no-data__msg "></span>
                                        }
                                    </p>
                                    <p className="overview-card-value-text">Image Pull Policy:&nbsp;<span className="overview-card-value-text">{state.image_pull_policy}</span><span className="no-data__msg "></span></p>
                                </div>
                            </div>
                            {
                                !state.custom_ingress_manifest ?
                                    <div className="overview-single-card">
                                        <div className="overview-card-heading-section">
                                            <div className="text__box">
                                                <div className="text__box-main font-11">Access Level:&nbsp;</div>

                                                {/* <p className="overview-card-value-text text-uppercase">
                            {state.access_level == "PRIVATE" ? "Private" : state.access_level == "PUBLIC" ? "Public" : "Protected"}
                        </p> */}
                                                {/* <p className="no-data__msg ">NA</p> */}

                                            </div>
                                        </div>
                                        <div className="overview-card-value-env-var">
                                            <div className="heading-key-value"
                                                style={{ gridTemplateColumns: '14% 12% 7% 7% 32% auto' }}
                                            >
                                                <p>Name</p>
                                                <p>Type</p>
                                                <p>Port</p>
                                                <p>Target </p>
                                                <p>Expose Url</p>
                                                <p>Expose path</p>
                                            </div>


                                            <div className="env-variable-box">
                                                {state.env_cd_access_detail && state.env_cd_access_detail.length > 0 ?
                                                    <>
                                                        {state.env_cd_access_detail.map(item => (
                                                            <div className="data-key-value-pair"
                                                                style={{ gridTemplateColumns: '14% 12% 7% 7% 32% auto' }}>
                                                                <Tooltip title={item.name}>
                                                                    <p>{item.name}</p>
                                                                </Tooltip>
                                                                <Tooltip title={item.access_level}>
                                                                    <p>{item.access_level}</p>
                                                                </Tooltip>
                                                                <Tooltip title={item.port ? item.port : "N/A"}>
                                                                    <p>{item.port ? item.port : "N/A"}</p>
                                                                </Tooltip>
                                                                <Tooltip title={item.target_port ? item.target_port : "N/A"}>
                                                                    <p>{item.target_port ? item.target_port : "N/A"}</p>
                                                                </Tooltip>
                                                                <Tooltip title={item.expose_url ? item.expose_url : "N/A"}>
                                                                    <p>{item.expose_url ? item.expose_url : "N/A"}</p>
                                                                </Tooltip>
                                                                <Tooltip title={item.expose_path ? item.expose_path : "N/A"}>
                                                                    <p>{item.expose_path ? item.expose_path : "N/A"}</p>
                                                                </Tooltip>
                                                            </div>
                                                        ))}
                                                    </>
                                                    : <p className="no-data__msg-env-var" >No Data Available</p>}
                                                {/*  */}


                                            </div>

                                        </div>

                                    </div>
                                    :
                                    state.custom_ingress_manifest ?
                                        <div className="overview-single-card">
                                            <div className="overview-card-heading-section">
                                                <p className="overview-card-heading">
                                                    Ingress Manifest
                                                </p>
                                                <span className="overview-chip-yes">
                                                    Yes
                                                </span>
                                            </div>
                                            <div className="overview-card-value-access">
                                                <p className="overview-card-value-text">strategy:&nbsp;{state.custom_ingress_manifest.manifest_meta_data ? <span className="overview-card-value-text">{state.custom_ingress_manifest.manifest_meta_data.strategy}</span> : <span className="no-data__msg ">No Data Available</span>}</p>
                                                {state.custom_ingress_manifest.manifest_meta_data && state.custom_ingress_manifest.manifest_meta_data.manifest_git_repo ?
                                                    <p className="overview-card-value-text">Git Url:&nbsp;<span className="overview-card-value-text">{state.custom_ingress_manifest.manifest_meta_data.manifest_git_repo.git_url ? state.custom_ingress_manifest.manifest_meta_data.manifest_git_repo.git_url : <span className="no-data__msg ">No Data Available</span>}</span></p>
                                                    : null
                                                }

                                                {state.custom_ingress_manifest.manifest_meta_data && state.custom_ingress_manifest.manifest_meta_data.manifest_git_repo ?
                                                    <p className="overview-card-value-text">Git Branch:&nbsp;<span className="overview-card-value-text">
                                                        {state.custom_ingress_manifest.manifest_meta_data.manifest_git_repo_branch ?
                                                            state.custom_ingress_manifest.manifest_meta_data.manifest_git_repo_branch :
                                                            state.custom_ingress_manifest.manifest_meta_data.manifest_git_repo &&
                                                                state.custom_ingress_manifest.manifest_meta_data.manifest_git_repo.branch_name ?
                                                                state.custom_ingress_manifest.manifest_meta_data.manifest_git_repo.branch_name
                                                                : <span className="no-data__msg ">No Data Available</span>}
                                                    </span>

                                                    </p>
                                                    : null
                                                }
                                                <p className="overview-card-value-text">Name:&nbsp;{state.env_cd_access_detail && state.env_cd_access_detail[0] ? <span className="overview-card-value-text">{state.env_cd_access_detail[0].name ? state.env_cd_access_detail[0].name : "N/A"}</span> : <span className="no-data__msg ">No Data Available</span>}</p>
                                                <p className="overview-card-value-text">Port:&nbsp;{state.env_cd_access_detail && state.env_cd_access_detail[0] ? <span className="overview-card-value-text">{state.env_cd_access_detail[0].port ? state.env_cd_access_detail[0].port : "N/A"}</span> : <span className="no-data__msg ">No Data Available</span>}</p>
                                                <p className="overview-card-value-text">Manifest File Name:&nbsp;<span className=" Y7">{state.custom_ingress_manifest.manifest_meta_data.manifest_file_paths[0]}</span></p>{/* <p className="no-data__msg ">NA</p> */}</div></div> : null}</div>
                    </div>
            }

        </>
    )
}


AccessLevelOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default AccessLevelOverview;