import React, { useEffect, useState } from 'react'
import { border, borderBottom, borderRadius, borderTop, fontSize, margin, padding, styled, width } from "@mui/system";
import { Grid, Tooltip } from '@mui/material';
import { getListableArray } from '../../util/util';
import properties from '../../properties/properties';
import GenerateURL from '../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../util/apiInvoker';
import { getCommonFunctions } from '../serviceRevamp/add/ci_flow/SourceDetails';
import { VALIDATION_TYPE_REQUIRED } from '../../util/Validator';
import { Input } from '../../components/genericComponents/Input';
import MoreOptionMenu from '../../components/genericComponents/MoreOptionMenu';
import AddVariableDialog from '../integrations/DatabaseManagement/add/AddVariableDialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, Navigate, useParams } from 'react-router-dom';

const AddDatabaseMetricTemplate = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const { application_id, edit_id } = useParams();
    const [state, setState] = useState({
        data: {},
        error: {},
        addedGlobalParameterList: [],
        addedPerformanceParameterList: [],
        openAddVariable: false,
        openedSectionId: "global-variables",
        validations: {
            database_type: [VALIDATION_TYPE_REQUIRED],
        },
        database_type_list: [],
        redirectToListing: false,
    })

    const commonFunctions = getCommonFunctions(state, setState, inherits);

    useEffect(() => {
        fetchDatabaseTypes();
    }, [])

    useEffect(() => {
        if (edit_id) {
            fetchTemplateDetails()
        }
    }, [edit_id])

    useEffect(() => {
        if (edit_id && state.database_type_list && state.data.database_type) {
            setState((new_state) => ({
                ...new_state,
                selected_database_type_name: getDatabaseTypeName(state.data.database_type)
            }))
        }
    }, [state.data.database_type, state.database_type_list])

    useEffect(() => {
        if (state.selected_database_type_name) {
            fetchGobalParametersList()
            fetchPerformanceParametersList()
        }
    }, [state.selected_database_type_name])

    function fetchDatabaseTypes() {
        let url_temp = GenerateURL({}, properties.api.database_type);
        let requestInfo = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onListFetchSuccess, onListFetchFail)
    }

    function onListFetchSuccess(response) {
        console.log(response, "vashgfcghavfd")
        setState((new_state) => ({
            ...new_state,
            database_type_list: getListableArray(response)
        }))
    }

    function onListFetchFail(error) {

    }

    function fetchGobalParametersList() {
        let url_temp = GenerateURL({ database_type_id: state.selected_database_type_name }, properties.api.template_supported_global_parameters);
        let requestInfo = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onGlobalListFetchSuccess, onGlobalListFetchFail)
    }

    function onGlobalListFetchSuccess(response) {
        setState((new_state) => ({
            ...new_state,
            global_parameter_list: response
        }))
    }

    function onGlobalListFetchFail(error) {

    }

    function fetchPerformanceParametersList() {
        let url_temp = GenerateURL({ database_type_id: state.selected_database_type_name }, properties.api.template_supported_performance_parameters);
        let requestInfo = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onPerformanceListFetchSuccess, onPerformanceListFetchFail)
    }

    function onPerformanceListFetchSuccess(response) {
        console.log(response, "acvcgfdvcgvd")
        setState((new_state) => ({
            ...new_state,
            performance_parameter_list: response
        }))
    }

    function onPerformanceListFetchFail(error) {

    }

    function fetchTemplateDetails() {
        let url_temp = GenerateURL({ db_template_id: edit_id }, properties.api.db_metric_template_edit_delete);
        let requestInfo = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onTemplateDetailsFetchSuccess, onTemplateDetailsFetchFail)
    }

    function onTemplateDetailsFetchSuccess(response) {
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state,
                database_type: response.database_type,
                template_name: response.name
            },
            addedGlobalParameterList: response.global_parameters,
            addedPerformanceParameterList: response.performance_parameters,
        }))
    }

    function onTemplateDetailsFetchFail(error) {

    }

    function getDisplayName(name) {
        console.log(first)
        return name
            .split('_') // Split the string by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the words with a space
    }

    function handleOpenAddVariableDialog(type) {
        setState((new_state) => ({
            ...new_state,
            openAddVariable: true,
            metric_type: type == "global" ? "GLOBAL_PARAMETER" : "PERFORMANCE_PARAMETER",
            variable_list: type == "global" ? state.global_parameter_list : state.performance_parameter_list,
            // variable_list_with_dataype: type == "global" ? state.global_parameter_with_datatypes : state.performance_parameter_with_datatypes
        }))
    }

    function handleOpenSection(type) {
        setState((new_state) => ({
            ...new_state,
            openedSectionId: type
        }))
    }

    function handleCloseAddVariable() {
        setState((new_state) => ({
            ...new_state,
            openAddVariable: false,
            selectedVariableForEdit: null,
        }))
    }

    function changeDatabaseTypeHandler(e) {
        let value = e.target.value

        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                database_type: value,
            },
            selected_database_type_name: getDatabaseTypeName(value)
        }))
    }

    function getDatabaseTypeName(database_type_id) {
        console.log(state.database_type_list, database_type_id, "asvcdgcsvc")
        var selected_database = state.database_type_list.find((item) => {
            return item.id == Number(database_type_id)
        })

        return selected_database.label;
    }

    function addParameter(data) {
        console.log(data, "asbcgdavcgdvs")
        if (data.type == "GLOBAL_PARAMETER") {
            if (state.edit_type == "template_edit") {
                let new_arr = []
                state.addedGlobalParameterList.forEach((item) => {
                    if (item.name != data.name) {
                        new_arr.push(item)
                    }
                })
                setState((new_state) => ({
                    ...new_state,
                    addedGlobalParameterList: [...new_arr, data],
                    openAddVariable: false,
                    edit_type: null,
                }))
            } else {
                let isAlreadyAdded = getGlobalMetricIsAdded(data)
                if (!isAlreadyAdded) {
                    setState((new_state) => ({
                        ...new_state,
                        addedGlobalParameterList: [...new_state.addedGlobalParameterList, data],
                        openAddVariable: false,
                    }))
                }
            }
        }
        if (data.type == "PERFORMANCE_PARAMETER") {
            if (state.edit_type == "template_edit") {
                let new_arr = []
                state.addedPerformanceParameterList.forEach((item) => {
                    if (item.name != data.name) {
                        new_arr.push(item)
                    }
                })
                setState((new_state) => ({
                    ...new_state,
                    addedPerformanceParameterList: [...new_arr, data],
                    openAddVariable: false,
                    edit_type: null,
                }))
            } else {
                let isAlreadyAdded = getPerformanceMetricIsAdded(data)
                if (!isAlreadyAdded) {
                    setState((new_state) => ({
                        ...new_state,
                        addedPerformanceParameterList: [...new_state.addedPerformanceParameterList, data],
                        openAddVariable: false,
                    }))
                }
            }
        }
    }

    function getGlobalMetricIsAdded(data) {
        let included = false;
        state.addedGlobalParameterList.forEach((item) => {
            if (item.name == data.name) {
                included = true;
            }
        })

        return included;
    }

    function getPerformanceMetricIsAdded(data) {
        let included = false;
        state.addedPerformanceParameterList.forEach((item) => {
            if (item.name == data.name) {
                included = true;
            }
        })

        return included;
    }

    function handleRemoveMetric(name, type) {
        console.log(name, type, "asdbgvgcgsdvcsdgh")
        let updated_arr = [];
        if (type == "global") {
            state.addedGlobalParameterList.forEach((item) => {
                if (item.name != name) {
                    updated_arr.push(item)
                }
            })
            setState((new_state) => ({
                ...new_state,
                addedGlobalParameterList: updated_arr
            }))
        } else {
            state.addedPerformanceParameterList.forEach((item) => {
                if (item.name != name) {
                    updated_arr.push(item)
                }
            })
            setState((new_state) => ({
                ...new_state,
                addedPerformanceParameterList: updated_arr
            }))
        }


        console.log("jsdhcbdhsbvsbh", updated_arr)
    }

    function validateAndSave() {
        let url_temp = GenerateURL({}, properties.api.db_metric_template_listing);
        if(edit_id){
            url_temp = GenerateURL({db_template_id : edit_id}, properties.api.db_metric_template_edit_delete);
        }
        let post_data = {
            name: state.data.template_name,
            database_type: state.data.database_type,
            performance_parameters: state.addedPerformanceParameterList,
            global_parameters: state.addedGlobalParameterList
        }
        console.log(post_data, "dbhsbhcvbdshbvds")
        PostData(url_temp, post_data, handleSuccessTemplateAdd, handleFailedTemplateAdd);
        setState(new_state => ({
            ...new_state,
            loader: true,
        }));
    }

    function handleSuccessTemplateAdd(response) {
        console.log("aghvcgadsvc", response)
        setState((new_state) => ({
            ...new_state,
            redirectToListing: true,
        }))
    }

    function handleFailedTemplateAdd(error) {

    }

    function handleEditMetric(metric, type, edit_type) {
        setState((new_state) => ({
            ...new_state,
            openAddVariable: true,
            metric_type: type == "global" ? "GLOBAL_PARAMETER" : "PERFORMANCE_PARAMETER",
            variable_list: type == "global" ? state.global_parameter_list : state.performance_parameter_list,
            selectedVariableForEdit: metric,
            edit_type: edit_type,
        }))
    }

    console.log(state, "adkbcjhdsbchsdvb")

    return (
        <>
            {
                state.redirectToListing &&
                <Navigate
                    to={`/application/${application_id}/database-templates`}
                />
            }
            {
                state.openAddVariable &&
                <AddVariableDialog
                    open={state.openAddVariable}
                    handleClose={handleCloseAddVariable}
                    inherits={inherits}
                    variable_list={state.variable_list}
                    variable_list_with_dataype={state.variable_list}
                    metric_type={state.metric_type}
                    // databaseGroupId={databaseGroupId}
                    // selected_database={selected_database}
                    selectedVariableForEdit={state.selectedVariableForEdit}
                    addParameter={addParameter}
                    type="database_template"
                />
            }
            <AddDatabaseTemplate>
                <div className='header'>
                    <div className='left-part'>
                        <div className='icon-div'>
                            <span className='ri-database-2-line' style={{ color: "#0086FF", fontSize: "24px" }}></span>
                        </div>
                        <div className='header-detail'>
                            <p style={{ fontFamily: "Montserrat", fontSize: "18px", fontWeight: "600", lineHeight: "21.94px", color: "#2F2F2F" }}>{edit_id ? "Edit DB Metric Template" : "Create DB Metric Template"} <span className='ri-information-line' style={{ color: "#505050", fontSize: "20px", fontWeight: "400" }}></span></p>
                        </div>
                    </div>
                </div>
                <div className='main-body'>
                    <div style={{ fontFamily: "Montserrat", fontSize: "16px", fontWeight: "600", color: "#2F2F2F" }}>Please provide the following details</div>
                    <Grid container spacing={2} style={{ marginTop: "14px" }}>
                        <Grid item lg={6}>
                            <Input
                                type={"text"}
                                name="template_name"
                                label="Enter Database Template Name"
                                placeholder="Enter Name"
                                data={state.data}
                                error={state.error}
                                info="Please specify the name of the Database Group"
                                mandatorySign
                                onChangeHandler={commonFunctions.onChangeHandler}
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <Input
                                type="select"
                                label="Database Type"
                                name="database_type"
                                placeholder=""
                                mandatorySign
                                list={state.database_type_list}
                                error={state.error}
                                data={state.data}
                                info="Please select a database type from the list. We currently support non-relational databases only"
                                onChangeHandler={changeDatabaseTypeHandler}
                            />
                        </Grid>
                    </Grid>
                    <div className='metric-body'>
                        <div className='database-global-variables'>
                            <div className='section-headers'>
                                Global Metrics Variable Configuration
                            </div>
                            {
                                <div className='section-body'>
                                    {
                                        state.addedGlobalParameterList && state.addedGlobalParameterList.length > 0 ?
                                            <div className='bottom-part'>
                                                <div className='detail-header'>
                                                    <span>Name</span>
                                                    <span>Type</span>
                                                    <span></span>
                                                </div>
                                                {
                                                    state.addedGlobalParameterList.map((metric, index) => {
                                                        return (
                                                            <div className='detail-body' style={{ borderBottom: "1px solid #E6E6E6" }}>
                                                                <span>{metric.display_name}</span>
                                                                <div className='data-type-tab'>
                                                                    {metric.data_type.toLowerCase()}
                                                                </div>
                                                                <div className="d-flex justify-flexend" style={{ gap: "14px" }}>
                                                                    <span className='ri-edit-line vertical-align-super' style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => handleEditMetric(metric, "global", "template_edit")}></span>
                                                                    <span className='ri-delete-bin-7-line' style={{ color: "#E53737", fontSize: "20px", cursor: "pointer" }} onClick={() => handleRemoveMetric(metric.name, "global")}></span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className='' style={{ padding: "16px 0 16px 20px" }}>
                                                    <button className='btn btn-cancel-blue' style={{ cursor: "pointer" }} onClick={() => handleOpenAddVariableDialog("global")}>
                                                        <span className='ri-add-line'></span>Add
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            <div className='add-variable-card'>
                                                <p style={{ fontFamily: "Montserrat", fontWeight: "600", fontSize: "14px", color: "#2F2F2F" }}>No Variable Added</p>
                                                {
                                                    state.data.database_type ?
                                                        <button className='btn btn-cancel-blue' style={{ marginTop: "16px", cursor: "pointer" }} onClick={() => handleOpenAddVariableDialog("global")}>
                                                            <span className='ri-add-line'></span>Add
                                                        </button>
                                                        :
                                                        <Tooltip title="Select database type to add variables">
                                                            <button className='btn btn-cancel-blue btn-disable' style={{ marginTop: "16px", cursor: "not-allowed" }} onClick={() => handleOpenAddVariableDialog("global")}>
                                                                <span className='ri-add-line'></span>Add
                                                            </button>
                                                        </Tooltip>
                                                }
                                            </div>

                                    }
                                </div>
                            }
                        </div>
                        <div className='database-global-variables' style={{ marginTop: "32px" }}>
                            <div className='section-headers'>
                                Performance Metrics variable configuration
                            </div>
                            {
                                <div className='section-body'>
                                    {
                                        state.addedPerformanceParameterList && state.addedPerformanceParameterList.length > 0 ?
                                            <div className='bottom-part'>
                                                <div className='detail-header'>
                                                    <span>Name</span>
                                                    <span>Type</span>
                                                    <span></span>
                                                </div>
                                                {
                                                    state.addedPerformanceParameterList.map((metric, index) => {
                                                        return (
                                                            <div className='detail-body' style={{ borderBottom: "1px solid #E6E6E6" }}>
                                                                <span>{metric.display_name}</span>
                                                                <div className='data-type-tab'>
                                                                    {metric.data_type.toLowerCase()}
                                                                </div>
                                                                <div className="d-flex justify-flexend" style={{ gap: "14px" }}>
                                                                    <span className='ri-edit-line vertical-align-super' style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => handleEditMetric(metric, "performance")}></span>
                                                                    <span className='ri-delete-bin-7-line' style={{ color: "#E53737", fontSize: "20px", cursor: "pointer" }} onClick={() => handleRemoveMetric(metric.name, "performance")}></span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className='' style={{ padding: "16px 0 16px 20px" }}>
                                                    <button className='btn btn-cancel-blue' style={{ cursor: "pointer" }} onClick={() => handleOpenAddVariableDialog("performance")}>
                                                        <span className='ri-add-line'></span>Add
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            <div className='add-variable-card'>
                                                <p style={{ fontFamily: "Montserrat", fontWeight: "600", fontSize: "14px", color: "#2F2F2F" }}>No Variable Added</p>
                                                {
                                                    state.data.database_type ?
                                                        <button className='btn btn-cancel-blue' style={{ marginTop: "16px", cursor: "pointer" }} onClick={() => handleOpenAddVariableDialog("performance")}>
                                                            <span className='ri-add-line'></span>Add
                                                        </button>
                                                        :
                                                        <Tooltip title="Select database type to add variables">
                                                            <button className='btn btn-cancel-blue btn-disable' style={{ marginTop: "16px", cursor: "not-allowed" }} onClick={() => handleOpenAddVariableDialog("performance")}>
                                                                <span className='ri-add-line'></span>Add
                                                            </button>
                                                        </Tooltip>
                                                }
                                            </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-end" style={{ marginBottom: "20px" }}>
                    <Link to=""><button className="btn btn-transparent" style={{ marginRight: '5px' }}>Close</button></Link>
                    <button className="btn btn-primary mr-0" onClick={validateAndSave}>CREATE</button>
                </div>
            </AddDatabaseTemplate>
        </>
    )
}

export default AddDatabaseMetricTemplate

const AddDatabaseTemplate = styled("div")({
    padding: '20px 20px 0px 20px',
    // marginTop: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    background: "#FFFFFF",

    "& .header": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& .left-part": {
            display: "flex",
            alignItems: "center",
            gap: "16px",

            "& .icon-div": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#F5FAFF",
                height: "56px",
                width: "56px",
                borderRadius: "8px",
                border: "1.5px solid #DFEDFF"
            }
        }
    },

    "& .main-body": {
        marginTop: "24px",
    },

    "& .metric-body": {
        marginTop: "24px",

        "& .section-headers": {
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "600",
            color: "#505050",
            marginLeft: "16px"
        },

        "& .section-body": {
            // display: "flex",
            // flexWrap: "wrap",
            // alignItems: "center",
            // gap: "16px",
            marginTop: "12px",

            // display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
            // padding: "10px 12px",
            // borderBottom: "1px solid #E6E6E6",

            "& .change-detected": {
                display: "flex",
                padding: "4px 6px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "6px",
                backgroundColor: "#FCF6E1",
                color: "#E1941D",
                fontSize: "11px",
                fontWeight: "700"
            },

            "& .bottom-part": {

                "& .detail-header": {
                    padding: "12px 10px",
                    display: "grid",
                    gridTemplateColumns: "200px 130px 1fr",
                    borderBottom: "1px solid #E6E6E6",

                    "& span": {
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#2F2F2F",
                    }
                },

                "& .detail-body": {
                    padding: "12px 10px",
                    display: "grid",
                    gridTemplateColumns: "200px 130px 1fr",
                    alignItems: "center",

                    "& span": {
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#505050",
                    },

                    "& .data-type-tab": {
                        border: "1px solid #E6E6E6",
                        backgroundColor: "#E6E6E6",
                        width: "fit-content",
                        borderRadius: "6px",
                        padding: "4px 6px",
                        fontWeight: "600",
                        fontSize : "12px"
                    }
                }
            },

            "& .entity-table": {
                minWidth: 650,

                "& .table-body": {
                    borderTop: "1px solid #E6E6E6"
                }
            },

            "& .MuiTableContainer-root": {
                marginTop: "10px",
                borderRadius: "12px",
                border: "1px solid #E6E6E6"
            },

            "& .MuiTableBody-root": {
                border: "1px solid #505050"
            },

            "& .MuiTableRow-head": {
                backgroundColor: "#ffffff",
                padding: "12px"
            },

            "& .MuiTableCell-head": {
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: "600",
                color: "#505050"
            },

            "& .MuiTableCell-body": {
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: "600",
                color: "#2F2F2F"
            },

            "& .MuiTableBody-root": {
                backgroundColor: "#ffffff",
                padding: "12px"

            },

            '& .no-change-tab': {
                display: "flex",
                padding: "5px 8px",
                width: "fit-content",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "6px",
                background: "#F4F4F4",
                color: "#505050",
            },

            "& .section-card": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid #E6E6E6",
                borderRadius: "6px",
                width: "295px",
                minHeight: "96px",

                "& .left-part": {
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px"
                }
            },

            "& .add-variable-card": {
                width: "100%",
                // minHeight: "98px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "24px 20px",
                border: "1px solid #E6E6E6",
                backgroundColor: "#FAFAFA",
                borderRadius: "6px",
                margin: "0 16px"
            }
        },

        "& .database-global-variables": {
            border: "1px solid #E6E6E6",
            borderRadius: "6px",
            padding: "16px 0",
        }
    },

})