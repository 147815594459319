import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const RouteWithMinimalLayout = ({ layout: Layout, component: Component }) => {
  const location = useLocation(); // Access current route

  return (
    <Layout>
      <Component location={location} />
    </Layout>
  );
};

RouteWithMinimalLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithMinimalLayout;
