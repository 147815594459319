import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Input } from "../Input";

const KeyValue = (props) =>{
    const [state,setState] = useState({data : props.data ? props.data :[]});
    const isListing=props.isListing?props.isListing:false
    const callback=props.callback;
    const field_name = props.field_name ? props.field_name:null;
    function addBlankRow() {
        let data = []
        if (state.data) { data = [...state.data]; }
        else { }
        data.push({});
        setState({
            ...state,
            data: data
        });
    }

    function removeRow(i) {
        const data = [...state.data];
        data.splice(i, 1);
        updateStateAndCallBack(data);
        setState({
            ...state,
            data: data
        });
    }

    useEffect(() => {
        //  let data_combined=(props.data).concat(props.data_editor)
        setState(new_state => ({
            ...new_state,
            data: props.data
        }))
        if (!isListing)
            callback(props.data);
    }, [props.data])
    function onKeyChange(e, i) {
        const data = [...state.data];
        const element = { ...data[i] };
        element.key = e.target.value;
        data[i] = element;
        updateStateAndCallBack(data);
    }

    function updateStateAndCallBack(data) {
        setState({
            ...state,
            data: data
        });
        callback(data);
    }

    function onValueChange(e, i) {
        const data = [...state.data];
        const element = { ...data[i] };
        element.value = e.target.value;
        data[i] = element;
        updateStateAndCallBack(data);
    }

    return (
        <>


            <div className="grid-container with-header">
                <div className="section">
                    Key
            </div>
                <div>
                    Value
            </div>
                <div>
                    {!isListing ? <button className="transparent-btn" onClick={addBlankRow}>
                        <i className="ri-add-line"></i> Add Row
            </button> : null}
                </div>
            </div>
            <div className={"fixed-height-with-scroll"}>
                { state.data?
                    state.data.map((pair, i) => (
                        <div className="grid-container pd-2">
                            <div>
                                <Input
                                    type="text"

                                    placeholder="Name"
                                    name="key"
                                    error={{}}
                                    onChangeHandler={!isListing ? (e) => { onKeyChange(e, i) } : false}
                                    data={pair}
                                    />
                            </div>
                            <div>
                                <Input
                                    type={field_name=="annotations" ? "textarea":"text"}

                                    placeholder="Value"
                                    name="value"

                                    error={{}}
                                    onChangeHandler={!isListing ? (e) => { onValueChange(e, i) } :false}
                                    data={pair} />
                            </div>
                            { !isListing ? <div>
                                <button className="delete-btn" onClick={() => { removeRow(i) }}>
                                <i className={`ri-delete-bin-line font-18`}></i>
                                    </button>
                            </div> : null}
                        </div>)):null
                }
            </div>
        </>
    );
}

KeyValue.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default KeyValue;