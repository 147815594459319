import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { GenerateSearchURL, GET_Request_Info } from '../../../util/APIUrlProvider';
import InvokeApi from '../../../util/apiInvoker';
import { Loading } from '../../utils/Loading';
import { makeStyles } from '@mui/styles';
import { ErrorComponent } from '../../utils/Error';
import Checkbox from '@mui/material/Checkbox';


const Events = props => {

  const api_url = props.api_url;
  const classes = useStyles();
  const [state, setState] = useState({
    loaded: false,
    events_visible: ["normal", "warning", "critical"]
  });

  useEffect(() => {
    fetchEvents();
  }, [api_url]);

  function fetchEvents(load_more) {

    var requestInfo = GET_Request_Info(api_url);
    if (load_more) {
      if (state.events.metadata.continue) {
        requestInfo.endPoint = GenerateSearchURL({ pagination_token: state.events.metadata.continue }, requestInfo.endPoint);
        InvokeApi(requestInfo, onFetchSuccess, onFetchFail);
      }
    } else {
      InvokeApi(requestInfo, onFetchSuccess, onFetchFail);
      setState(new_state => ({
        ...new_state,
        loaded: false,
        events: null,
        filtered_events: null,
        error: "",
      }))
    }

  }

  function onFetchSuccess(data) {

    function getFomatedData(prev_data) {
      return {
        ...data,
        items: [...prev_data.items, ...data.items]
      }
    }

    setState(new_state => ({
      ...new_state,
      loaded: true,
      events: new_state.events ? getFomatedData(new_state.events) : data,
      filtered_events: filterEvents(new_state.events ? getFomatedData(new_state.events) : data),
      error: "",
      events_visible: ["normal", "warning", "critical"]
    }));
  }

  function onFetchFail(error) {
    setState(new_state => ({
      ...new_state,
      loaded: true,
      error: error,
    }));
  }

  function filterEvents(data) {

    const result = {
      normal: [],
      warning: [],
      critical: [],
    }
    data.items.forEach(item => {
      result[item.type.toLowerCase()].push(item);
    });

    return result;
  }



  function getEvents(event_filter) {
    let events_temp = [...state.events.items];
    if (event_filter) {
      events_temp = [...state.filtered_events[event_filter]]
    }
    return (
      <>
        {
          events_temp.length > 0 ?
            <>
              {
                events_temp.map(event => (
                  <UI_EventDetail event={event} />
                ))
              }
            </>
            :
            null
        }
      </>
    )
  }
  function setEventVisibility(event_type) {
    setState(new_state => ({
      ...new_state,
      events_visible: event_type
    }));
  }
  return (
    <>
      {
        state.loaded ?
          state.error ?
            <ErrorComponent error={state.error} />
            :
            <>
              <div className="head" style={{ margin: '10px 0px' }}>
                <UI_Heading filtered_events={state.filtered_events} setEventVisibility={setEventVisibility} />
                <button onClick={() => { fetchEvents() }} className="btn-round border-navy bg-clear-btn" style={{ justifySelf: "flex-end" }}><span className="flaticon-refresh-button-1"></span></button>
              </div>
              <UI_ListHeading />
              <Body>
                {state.loaded ?
                  state.events_visible ? state.events_visible.map(event => (
                    getEvents(event)
                  )) : null
                  : <Loading varient="light" />
                }
              </Body>
              {
                state.events.metadata.continue ?
                  <div className={classes.cardFooter} style={{ display: 'block', textAlign: 'center' }}>
                    <button className="btn btn-transparent btn-grey-navi hover-state load-more" onClick={() => { fetchEvents(true) }}>
                    <span style={{color:"#546e7a"}}><i className="ri-loop-left-fill" style={{ fontSize: '2.5 rem', fontWeight: '800' }}></i>&nbsp;Load More</span>
                    </button>
                  </div>
                  :
                  <div className={classes.cardFooter} style={{ display: 'block', textAlign: 'center' }}>
                    <span className="nothing-to-load">No More Items</span>
                  </div>
              }
            </>
          :
          <Body>
            <Loading varient="light" />
          </Body>

      }
    </>
  )
}
Events.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default Events;

function UI_Heading(props) {
  const filtered_events = props.filtered_events;
  const [Event, SetEvent] = useState({
    warning: true,
    normal: true,
    critical: true
  });

  function setBg(eventType) {
    console.log("hello222222", eventType)
    SetEvent(eventType)
    props.setEventVisibility(eventType);
  }
  function handleCheck(e) {
    SetEvent(ev => ({
      ...ev,
      [e]: !ev[e]
    }))


    var myArray = [];

    function RemoveFalseAndTransformToArray(myObj) {
      console.log("hello99999", myObj)
      for (var key in myObj) {
        if (myObj[key] === false) {
          delete myObj[key];
        } else {
          myArray.push(key);
        }
      }
      console.log("hello2323", myArray)
      return myArray;
    }

    props.setEventVisibility(
      RemoveFalseAndTransformToArray({
        ...Event, [e]: !Event[e]
      })
    );
  }

  console.log("hello11", Event)
  return (
    <>

      <div className="heading-section-service">
        <div className="main-heading">Events</div>
        <div className="sub-heading-text">Displaying list of all the events</div>
      </div>
      <div className="icon-section">
        <div class="btn-group btn-grp-handle" data-toggle="buttons">
          <lable className="label-for-multiselect">
            <span role='button' tabIndex={0} onKeyDown={()=>{}} onClick={() => { handleCheck("critical") }}>Critical</span>
            <span className={!Event.critical ? "count-chip count-chip-danger-outline count-chip-lg count-chip-lg-fix" : "count-chip count-chip-danger count-chip-lg count-chip-lg-fix"} style={{ position: 'relative' }}>
              <Checkbox name="critical" onChange={() => { handleCheck("critical") }} defaultChecked={Event.critical} className='btn-group MuiIconButton-root' />
              <span className="text-of-chip">{filtered_events.critical.length}</span></span>
          </lable>
          <lable className="label-for-multiselect">
            <span role='button' tabIndex={0} onKeyDown={()=>{}}  onClick={() => { handleCheck("warning") }}>Warning</span>
            <span className={!Event.warning ? "count-chip count-chip-pending-outline count-chip-lg count-chip-lg-fix" : "count-chip count-chip-pending count-chip-lg count-chip-lg-fix"} style={{ position: 'relative' }}>
              <Checkbox name="warning" onChange={() => { handleCheck("warning") }} defaultChecked={Event.warning} className='btn-group MuiIconButton-root'/>
              <span className="text-of-chip">{filtered_events.warning.length}</span>
            </span>
          </lable>
          <lable className="label-for-multiselect">
            <span role='button' tabIndex={0} onKeyDown={()=>{}} onClick={() => { handleCheck("normal") }}>Normal</span>
            <span className={ !Event.normal ? "count-chip count-chip-success-outline count-chip-lg count-chip-lg-fix" : "count-chip count-chip-success count-chip-lg count-chip-lg-fix"} style={{ position: 'relative' }}>
              <Checkbox name="normal" onChange={() => { handleCheck("normal") }} defaultChecked={Event.normal} className='btn-group MuiIconButton-root' />
              <span className="text-of-chip">{filtered_events.normal.length}</span>
            </span>
          </lable>

        </div>
        <div className="top-level-data" style={{ display: 'none' }}>
          <span role='button' tabIndex={0}  className="text-color-blue" onKeyDown={()=>{}} onClick={() => { setBg("critical") }}>
            Critical
            {
              Event == "critical" ?
                <span className={"count-chip count-chip-lg count-chip count-chip-danger count-chip-lg"}>{filtered_events.critical.length}</span> :
                <span className={"count-chip count-chip-lg count-chip count-chip-danger-outline count-chip-lg"}>{filtered_events.critical.length}</span>
            }

          </span>
          <span role='button' tabIndex={0}  className="text-color-blue" onKeyDown={()=>{}} onClick={() => { setBg("warning") }}>
            Warning
            {
              Event == "warning" ?
                <span className={"count-chip count-chip-lg count-chip count-chip-pending count-chip-lg"}>{filtered_events.warning.length}</span> :
                <span className={"count-chip count-chip-lg count-chip count-chip-pending-outline count-chip-lg"}>{filtered_events.warning.length}</span>
            }

          </span>
          {/* <span className="text-color-blue">
            Low
              <span className="count-chip count-chip-info count-chip-lg">{filtered_events.normal.length}</span>
          </span> */}

          <span role='button' tabIndex={0}  className="text-color-blue" onKeyDown={()=>{}} onClick={() => { setBg("normal") }}>
            Normal
            {
              Event == "normal" ?
                <span   className={"count-chip count-chip-lg count-chip count-chip-success count-chip-lg"}>{filtered_events.normal.length}</span> :
                <span   className={"count-chip count-chip-lg count-chip count-chip-success-outline count-chip-lg"}>{filtered_events.normal.length}</span>
            }

          </span>

        </div>
      </div>
    </>
  );
}
UI_Heading.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

function UI_ListHeading(props) {

  return (
    <TempHeader>
      <span>Event Type</span>
      <span>Events</span>
      <span>Reason</span>
      <span>Count</span>
      <span>Last Triggered at</span>
      <span>Object Namespace</span>
      <span>Object Name</span>
    </TempHeader>
  );
}

function UI_EventDetail(props) {
  const event = props.event;
  console.log(event, "events")
  return (
    <TempBodyRow>
      <div className={event.type == "Normal" ? "count-chip count-chip-success" : event.type == "Warning" ? "count-chip count-chip-pending" : event.type == "Low" ? "count-chip count-chip-info" : "count-chip count-chip-danger"}>{event.type}</div>
      <Tooltip placement="bottom" title={event.message}>
        <div className="name">{event.message}</div>
      </Tooltip>
      <Tooltip placement="bottom" title={event.reason}>
        <div className="name">{event.reason}</div>
      </Tooltip>
      <div className="name">{event.count}</div>
      <div className="name">{event.lastTimestamp ? (new Date(event.lastTimestamp)).toString().substring(4, 21) + " IST" : "-"}</div>
      <div className="name">{event.involvedObject.namespace?event.involvedObject.namespace:"-"}</div>
      <Tooltip placement="bottom" title={event.involvedObject.name?event.involvedObject.name:"-"}>
        <div className="name">{event.involvedObject.name?event.involvedObject.name:"-"}</div>
      </Tooltip>

    </TempBodyRow>
  );
}
UI_EventDetail.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '32px',
  },
  card: {
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
    background: '#fff',
    border: '1px solid #d5d5d5',
    overflow: 'hidden',
    position: 'relative',
    height: '550px',
    '& .chip-default': {
      width: 'fit-content'
    },
  },
  cardHeader: {
    borderRadius: '8px 8px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: "#fff",
    fontSize: '13px',
    alignItems: 'center',
    padding: '10px 15px',
    '& .input-component': {
      minWidth: '120px',
      marginBottom: '0px'
    },

  },
  cardBody: {
    padding: '20px 15px',
    backgroundColor: '#f6f8f8',
    '& .input-component': {
      marginBottom: '15px'
    },
    '& .with-dropdown': {
      display: 'grid',
      gridTemplateColumns: 'auto 120px',
      gap: '10px',
      alignItems: 'center',

    }
  },
  cardFooter: {
    backgroundColor: '#fff',
    borderRadius: ' 0px 0px 8px 8px',
    display: 'flex',
    padding: '10px 15px',
    borderTop: '1px solid #d5d5d5',
    justifyContent: 'flex-end',
    width: '100%',
    bottom: '0px',
    '& .btn-submit': {
      backgroundColor: '#3696db',
      color: '#fff',
      marginLeft: 'auto'
    }
  }
}));

const Body = styled('div')({
  height: '250px',
  overflowY: 'scroll',
  position: 'relative'
})
const TempHeader = styled('div')({
  display: 'grid',
  gridTemplateColumns: '8% 22% 12% 6% 15% 10% 20% ',
  fontSize: '11px',
  gap: '10px',
  padding: '10px',
  borderBottom: '2px solid #000',
  alignItems: 'center',
  '& span': {
    padding: '0px 2px'
  }
})
const TempBodyRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '8% 22% 12% 6% 15% 10% 20%',
  fontSize: '11px',
  padding: '10px',
  gap: '10px',
  borderBottom: '1px solid #dedede',
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '& .name': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  '&:last-child': {
    border: 'none'
  },
  '& .createdat': {
    fontSize: '10px',
    color: '#878787'
  },
  '& .fre-text': {
    fontSize: '11px',
    color: '#000',
    '& .uses-text': {
      fontSize: '9px',
      color: '#878787'
    }
  },
  '& .status-font': {
    color: '#fff',
    width: '50%',
    '& .flaticon-circle-with-check-symbol': {
      '&:before': {
        fontSize: '14px!important'
      }
    }
  },
  '& div': {
    padding: '0px 2px',
    color: '#9e9e9e'
  },
  '& .url': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})
