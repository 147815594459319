import React from "react";
import { makeStyles } from '@mui/styles';
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard } from "../../../../../../util/util";


const MoreDetailsPageServiceCardV3 = ({
    anchorEl,
    onClose,
    onClick,
    genratedOutputVariables,

}) => {

    const classes = useStyles();
    const open = Boolean(anchorEl);
    console.log(open, "openopenopen")
    let vars = [
        { 'one': 'just values' },
        { 'one': 'just values' }
    ]

    const commit_sha = 'noting..'
    console.log(genratedOutputVariables, 'genrated_opp_001');
    return (
        <>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}

            // PaperProps={{ style: { overflow: 'visible' } }}
            >
                <div className={classes.root}>
                    {
                        genratedOutputVariables?.length > 0 ? genratedOutputVariables?.map(genratedData => {
                            return (
                                <div className='d-flex align-center'>
                                    <span className='font-12 font-weight-500 color-key-78 '>{genratedData?.key}: &nbsp;</span>
                                    <Tooltip title={genratedData?.value ? genratedData.value : "N/A"} placement="bottom">
                                        <span className=" font-12 font-weight-500 color-value text-wrap">{genratedData?.value ? genratedData.value.substring(0, 30) + "..." : "N/A"} </span>
                                    </Tooltip>
                                    {genratedData?.value && <CopyToClipboard data={genratedData?.value} />}
                                </div>
                            )
                        }) :

                            <div style={{ width: "100%" }} className='d-flex align-center justify-center'>
                                <span className="font-12 font-weight-500">Not Found</span>
                            </div>
                    }

                </div>
            </Popover>
        </>

        // </div>

    )
}

export default MoreDetailsPageServiceCardV3;

const useStyles = makeStyles(theme => ({
    root: {
        display: "inline-flex",
        padding: "12px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        background: "#FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        width: "200px",
    }
}));