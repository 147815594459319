import { makeStyles } from '@mui/styles';
import React, { memo, useEffect, useState, useRef } from 'react'
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { Input } from '../../../../components/genericComponents/Input';

const ArtifactBlock = ({service,addServiceData,selectedMasterEnv}) => {
    const classes = styles();
    const [hoveredArtifact, setHoveredArtifact] = useState(null); 
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [artifactList, setArtifactList] = useState([]);
    const [error, setError] = useState(null);
    const serviceName = service.component_name;
    const prevComponentEnvId = useRef(service.component_env_id);

    const loadComponentArtifacts = () => {
        let requestInfo = {
            endPoint: GenerateURL({},properties.api.load_tags + `?service=${serviceName}&component_env_id=${service.component_env_id}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };
        setLoading(true);
        InvokeApi(requestInfo, loadComponentArtifactsSuccess, loadComponentArtifactsFailed);
    };

    const loadComponentArtifactsSuccess = (response) => {
        let artifactList = response.tags.map(item => {
            return { id: item, label: item };
        });
        
        setData((prevData) => ({
            ...prevData,
            default_artifact: false,
            artifact: service?.artifact ? service.artifact : '',
        }));
        setError((error) => ({
            ...error,
            artifact: ''
        }));
        setArtifactList(artifactList);
        setLoading(false);
    };

    const loadComponentArtifactsFailed = (error) => {
        let error_msg = error.toString();
        if(Array.isArray(error)){
            error_msg = 'No Artifact Found';
        }
        setError((error) => ({
            ...error,
            mainError: error_msg,
        }));
        setLoading(false);
    };

    const onKeyValueHandler = (k, v) => {
        setData((prevData) => ({
            ...prevData,
            [k]: v,
        }));
    };

    useEffect(() => {
        setData({});
        setArtifactList([]);
        setError(null);
    }, [selectedMasterEnv]);

    useEffect(() => {
        addServiceData(service, data);
    }, [data]);

    useEffect(() => {
        if (service.component_env_id && (!service?.artifact || (!service?.artifact?.length > 0))) {
            loadComponentArtifacts();
            prevComponentEnvId.current = service.component_env_id;  // Update the ref value
        }
    }, [service.component_env_id]);

    useEffect(()=>{
        setError((prevError)=>({
            ...prevError,
            ...service?.errors
        }))
        
    },[service?.errors])

    console.log("hdsjhsjhsd", service,error);
    return (
        <div className='d-flex align-center' style={{gap:"10px",position:'relative'}}
            onMouseEnter={() => setHoveredArtifact(true)}
            onMouseLeave={() => setHoveredArtifact(false)}
        >
            {error && error.mainError ? 
                <div className={classes.errorBox}>
                    <span>{error.mainError}</span>
                    <span className='ri-close-fill font-20 cursor-pointer' style={{ lineHeight: '1' }} onClick={() => setError(null)}></span>
                </div>
            :
                artifactList.length > 0 ? 
                    <div className={classes.autoInput} style={{ width: '90%' }}>
                        <Input
                            type="auto-complete-dropdown"
                            //id={"git_urlsZ"}
                            name="artifact"
                            list={artifactList.length > 0 ? artifactList : []}
                            getOptionLabel={(option) => option.label}
                            error={error}
                            data={data}
                            onChangeHandler={onKeyValueHandler}
                        />
                    </div>
                    :
                    loading ? 
                        <GenericSkeleton width={'90%'} height={37} style={{ borderRadius: '4px' }} />
                    :
                    <>
                    <div className={classes.defaultBox}>
                        <span className='font-14 font-weight-400 color-value'>{service?.artifact || 'Please Select Artifact'}</span>
                        {hoveredArtifact && 
                            <span className='ri-edit-2-line font-20 color-key-78 cursor-pointer' style={{ lineHeight: '1' }} onClick={() => loadComponentArtifacts()}></span>
                        }
                    </div>
                    {error?.artifact && 
                        <span className={classes.error}>{error?.artifact}</span>
                    }
                    </>
            }
            
        </div>
        
    )
};

export default memo(ArtifactBlock);

const styles = makeStyles((theme)=>({
    defaultBox:{
        width:'90%',
        padding:'8px 12px 8px 12px',
        borderRadius:'4px',
        backgroundColor:'#FAFAFA',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        textWrap:'nowrap',
    },
    errorBox: {
        width:'90%',
        padding:'8px 12px 8px 12px',
        borderRadius:'4px',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        color: "#BA0000",
        border: '1px solid #FBE6E6',
        background: '#FFF3F3',
    },
    autoInput:{
        '& .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: "9.5px 4px !important",
        },
        '& .input-component':{
            marginBottom: '0px !important',
        },
          
        '& .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: "10.2px 4px !important",
        },
        '& .input-component .MuiAutocomplete-endAdornment':{
            top:'calc(50% - 12px)',
            position:'absolute !important',
            right: '0 !important'
        }
    },
    error:{
        color: '#cc0033',
        display: 'inline-block',
        fontSize: '12px',
        position: 'absolute',
        lineHeight: '15px',
        margin: '5px 0 0',
        bottom:'-15px',
    }
}));
