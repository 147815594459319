import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';;
import { Input } from '../../../components/genericComponents/Input';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const OverridableVariablesDialouge = ({
    serviceIdForDialouge,
    open,
    job_name,
    onClose,
    jobData,
    parentState,
    setParentState,
    stageTaskPath,
    selectedTabOrder,
    ...props }) => {
    const [state, setState] = useState({
        data: {},
        error: {},
    })
    const classes = useStyles();
    const data = {}

    const actualJobData = jobData?.job_step_json[serviceIdForDialouge] ? jobData.job_step_json[serviceIdForDialouge] : null
    console.log(jobData, actualJobData, state, 'job_data_03223')

    useEffect(() => {
        console.log(jobData, 'dg_kll')
        setState(prevState => ({
            ...prevState,
            data: jobData.data
        }))

    }, [jobData, serviceIdForDialouge])

    const handleChange = (e, stepCodeKey, stepOrder, targetValue) => {
        const targetKey = e.target.name
        const value = e.target.value
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                [serviceIdForDialouge]: {
                    ...prevState.data[serviceIdForDialouge],
                    [stepCodeKey]: {
                        ...prevState.data[serviceIdForDialouge][stepCodeKey],
                        [stepOrder]: {
                            ...prevState.data[serviceIdForDialouge][stepCodeKey][stepOrder],
                            [targetKey]: {
                                ...prevState.data[serviceIdForDialouge][stepCodeKey][stepOrder][targetKey],
                                [targetValue]: value
                            }
                        }
                    }
                }
            }
        }))
    }

    const onHandleSave = () => {
        setParentState(prevState => ({
            ...prevState,
            stageWiseData: {
                ...prevState.stageWiseData,
                [selectedTabOrder]: {
                    ...prevState.stageWiseData[selectedTabOrder],
                    [stageTaskPath]: {
                        ...prevState.stageWiseData[selectedTabOrder][stageTaskPath],
                        data: {
                            ...state.data
                        }
                    }
                }
            }
        }))
        onClose()
    }
    console.log(actualJobData, 'acj_oosdsdsd')

    console.log(state, 'state_litno')
    return (
        <Dialog
            fullScreen
            className="runtimeparams-v3-env"
            open={open}
            onClose={() => { }}
            TransitionComponent={Transition}
        // keepMounted
        >
            <div className={classes.containerBox}>
                <div className='header space-between'>
                    <label className='heading font-14  font-weight-500'>Job Steps Environment Variables</label>
                    <button className="btn btn-transparent" onClick={() => onClose()}>
                        <span className='ri-close-line ' style={{ color: "#fff", fontSize: '22px' }}  ></span>
                    </button>
                </div>
            </div>
            <>
                <Grid className='pl-15 pr-15 pb-20' style={{ backgroundColor: '#ffffff' }} container>

                    {
                        actualJobData ? Object.keys(actualJobData)?.map((stepCodeKey, index) => {
                            const stepOrdersObject = actualJobData[stepCodeKey]

                            return (
                                <>
                                    {
                                        Object.keys(stepOrdersObject)?.map((stepOrder, order) => {
                                            const stepData = stepOrdersObject[stepOrder]

                                            return (
                                                <Grid key={order} style={{ paddingTop: '15px' }} item xs={12}>

                                                    <Grid style={{ height: '32px' }} key={order} item xs={12}>
                                                        <label className='font-13 mr-12'>Step Name</label>
                                                        <label style={{ color: 'gray' }} className='font-13'>{stepCodeKey}</label>
                                                    </Grid>

                                                    <div style={{
                                                        paddingTop: '20px', paddingBottom: '15px', border: '1px solid #dedede',
                                                        backgroundColor: '#f8f8f8', padding: '10px 20px 5px 20px',
                                                        borderRadius: '5px'
                                                    }}>
                                                        {
                                                            stepData && stepData.map((variable, index) => {
                                                                console.log(stepData, 'step_001_jk')
                                                                return (
                                                                    <Grid key={index} item xs={12}>
                                                                        {
                                                                            variable.integration ?
                                                                                <>
                                                                                    {
                                                                                        variable.integration_values?.map(integrationObj => {

                                                                                            return (<>

                                                                                                {
                                                                                                    Object.keys(integrationObj)?.map(integrationKey => {
                                                                                                        const integrationData = integrationObj[integrationKey]
                                                                                                        console.log(integrationData, 'integ_2332323')
                                                                                                        return (
                                                                                                            <>
                                                                                                                {integrationData?.map(integration => {
                                                                                                                    return (
                                                                                                                        integration.key === 'GIT_BRANCH' ?
                                                                                                                            <Input
                                                                                                                                type='text'
                                                                                                                                name={integrationKey}
                                                                                                                                label={`${integration.key} for ${integrationKey}(Integration)`}
                                                                                                                                placeholder=""
                                                                                                                                onChangeHandler={(e => handleChange(e, order))}
                                                                                                                                data={state.data && state.data[order] ? state.data[order] : {}}
                                                                                                                                error={state.error}
                                                                                                                            /> : null
                                                                                                                    )
                                                                                                                })}
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </>)

                                                                                        })
                                                                                    }
                                                                                </>
                                                                                :

                                                                                variable.input_type == undefined || variable.input_type === 'text' || variable.input_type === 'json'|| variable.input_type=='textarea'|| variable.input_type=='password' ?
                                                                                    <Input
                                                                                        type='text'
                                                                                        name={variable.key}
                                                                                        label={variable.key}
                                                                                        placeholder=""
                                                                                        onChangeHandler={(e => handleChange(e, stepCodeKey, stepOrder, 'value'))}
                                                                                        data={{
                                                                                            [variable.key]: (state.data &&
                                                                                                state.data[serviceIdForDialouge]
                                                                                                && state.data[serviceIdForDialouge][stepCodeKey] &&
                                                                                                state.data[serviceIdForDialouge][stepCodeKey][stepOrder] &&
                                                                                                state.data[serviceIdForDialouge][stepCodeKey][stepOrder][variable.key]) ?
                                                                                                state.data[serviceIdForDialouge][stepCodeKey][stepOrder][variable.key]['value'] : null
                                                                                        }}
                                                                                        error={state.error}
                                                                                    />

                                                                                    :
                                                                                    <Input
                                                                                        type={variable.input_type === 'toggle' ? 'select' : variable.input_type}

                                                                                        name={variable.key}
                                                                                        list={variable.input_type === 'select' || 'toggle' ? variable.select_dropdown_options ?

                                                                                            variable.select_dropdown_options.split(',').map(item => ({

                                                                                                id: item.trim(), label: item.trim()
                                                                                            }))

                                                                                            : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]

                                                                                            : []
                                                                                        }
                                                                                        label={variable.key}
                                                                                        placeholder=""
                                                                                        onChangeHandler={(e => handleChange(e, stepCodeKey, stepOrder, 'value'))}
                                                                                        data={{
                                                                                            [variable.key]: (state.data &&
                                                                                                state.data[serviceIdForDialouge]
                                                                                                && state.data[serviceIdForDialouge][stepCodeKey] &&
                                                                                                state.data[serviceIdForDialouge][stepCodeKey][stepOrder] &&
                                                                                                state.data[serviceIdForDialouge][stepCodeKey][stepOrder][variable.key]) ?
                                                                                                state.data[serviceIdForDialouge][stepCodeKey][stepOrder][variable.key]['value'] : null
                                                                                        }} error={state.error}
                                                                                    />
                                                                        }
                                                                    </Grid>
                                                                )
                                                            })
                                                        }

                                                    </div>

                                                </Grid>
                                            )

                                        })
                                    }
                                </>
                            )
                        })
                            :

                            <div className="d-flex align-center justify-center font-12 full-width">No Runtime Environment Variables</div>
                    }

                </Grid>
            </>
            <div className={classes.bottomAction}>
                <button onClick={() => onClose()} className="back-button-div mr-15">
                    <label className="capital-text  action-label">back</label>
                </button>
                <button onClick={onHandleSave} className="save-next-div mr-15">
                    <label className="capital-text  action-label">save</label>
                </button>

            </div>
        </Dialog>
    );
}

export default OverridableVariablesDialouge

const useStyles = makeStyles((theme) => ({
    containerBox: {
        '& .header': {
            height: '60px',
            background: '#124d9b',
            display: 'flex',
            alignItems: 'center',

            padding: '14px',
            '& .heading': {
                color: '#fff',
            }
        },

    },
    bottomAction: {
        width: '100%',
        height: '72px',
        padding: '16px',
        background: '#FAFAFA',
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'flex-end',
        '& .back-button-div': {
            width: '68px',
            height: '40px',
            padding: '8px 16px 8px 16px',
            borderRadius: '6px',
            background: '#fff',
            border: '1px solid #9DC0EE',
            '& .action-label': {
                fontSize: '12px',
                fontWeight: '500',
                color: '#124D9B',
                cursor: 'pointer',
                // boxShadow: '0px 2px 1px 0px #00000040'
            }
        },
        '& .save-next-div': {
            width: '112px',
            height: '40px',
            padding: '11px 16px 11px 16px',
            borderRadius: '6px',
            background: '#0086FF',
            border: 0,
            '& .action-label': {
                fontSize: '12px',
                fontWeight: '500',
                color: '#FFFFFF',
                cursor: 'pointer',
                // boxShadow: '0px 2px 1px 0px #00000040'
            }
        }
    }

}));



