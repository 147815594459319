import Radio from "@mui/material/Radio";
import { makeStyles } from "@mui/styles";
import React from 'react'
import clsx from 'clsx';

const useRadioStyles = makeStyles((theme) => ({
    root: {
      // '&:hover': {
      //   backgroundColor: 'transparent',
      // },
      "& .MuiFormControlLabel-label": { 
      fontSize: theme.typography.fontSize[12], 
      
        },
    },
    
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      // 'input:hover ~ &': {
      //   backgroundColor: '#ebf1f5',
      // },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#0086ff',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      // 'input:hover ~ &': {
      //   backgroundColor: '#106ba3',
      // },
    },
  }));
  
export function StyledRadio(props) {
    const classes = useRadioStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }