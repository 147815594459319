import React, { useEffect, useState } from 'react';
import { IsSuperUser, RemoveLoginCreds } from "../util/security";
import { Loading } from './utils/Loading';
import { ErrorComponent } from './utils/Error';
import { useLocation, useNavigate } from 'react-router-dom';

export const RedirectToProdEnv = () => {
    const [state, setState] = useState({
        loading: false,
        redirect: false,
        error: false
    });

    const location = useLocation();
    const history = useNavigate();
    console.log(location,'location_of_redirect_page')
    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            loading: true
        }))
        if(location.state==='from beta popup'){
            initiateLogout()
        }
        else{
            let pathname="/applicationdashboard"
            if(IsSuperUser()){
                pathname = "/ClusterDashboard"
            }
            history(pathname)
        }
    }, [])

    const handleLogOutResponse = (data) => {
        let productionUrl = process?.env?.PRODUCTION_URL 
        productionUrl && window.location.assign(productionUrl)
    }

    const handleLogOutFailure = (error) => {
        setState({
            ...state,
            error: error
        })
    }

    function initiateLogout() {
        RemoveLoginCreds(handleLogOutResponse, handleLogOutFailure);
    }

    return (<>
        {
            state.error ?
                <ErrorComponent variant="error-box" error={state.error} /> :
                <div className='mt-50'>
                    {state.loading && <Loading varient="light" />}
                    <div className='d-flex justify-center'><p1 className='font-14'>Redirecting...</p1></div>
                </div>
        }
    </>
    );
}

