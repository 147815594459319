import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import GenerateURL from '../../../util/APIUrlProvider';
import { formatText, getHighlightedText } from '../utils';

const IntegrationResultItem = ({searchedQuery,link,appName,name,entityObj,onClose,selectedSubTab}) => {
    return (
        <Root to={link} onClick={onClose} className='item'>
            <div className='app-name'>
                <span className='font-12 font-weight-600 color-primary'>{getHighlightedText(name,searchedQuery)}</span>
            </div>
            <div className='right-block'>
                {entityObj?.type && <span className='font-12 font-weight-500 color-tertiary'>{formatText(entityObj.type)}</span>}
                {selectedSubTab?.icon && <span className={`${selectedSubTab.icon} font-16 color-tertiary line-height-normal`}></span>}
                {entityObj?.connected !== undefined ? 
                    entityObj.connected ? 
                        <span className='ri-checkbox-circle-line font-16 color-success line-height-normal'></span>
                    :
                    <span className='ri-close-circle-line font-16 color-E1941D line-height-normal'></span>
                : null}
            </div>
        </Root>
  )
}

export default IntegrationResultItem;

const Root = styled(Link)({
    padding:'12px',
    display:'flex',
    gap:'4.5px',
    height:'40px',
    borderBottom:'1px solid #E6E6E6',
    justifyContent:'space-between',
    alignItems:'center',
    '&:hover':{
        backgroundColor:'#FAFAFA',
        borderRadius:'6px',
        '& .option-box':{
            display:'inline-block',
        },
    },
    '& .app-name':{
        display:'flex',
        alignItems:'center',
        gap:'6px',
    },
    '& .option-box':{
        width:'fit-content',
        display:'none',
    },
    '&:last-child':{
        borderBottom: "none !important",
    },
    '& .right-block':{
        display:'flex',
        alignItems:'center',
        gap:'6px',
    }
});