import React from 'react';
import PropTypes from 'prop-types';
import { RemoveFromArray } from '../../../util/util';

const TagInput = (props) => {
    const tag_list = props.tag_list? props.tag_list:[];
    const onChangeHandler = props.onChangeHandler;
    const name=props.name;
    //const [clear,setClear] = useState(false);

    

    const handleKeyPress = (e) =>{
        if (e.key === 'Enter' || e.key === 'Space') {
            if((!tag_list.includes(e.target.value.trim())) && e.target.value.trim().length > 1){
                tag_list.push(e.target.value.trim());
            }
            e.target.value = "";
            onChangeHandler(name,tag_list);
        }
    }
    
    const removeTag = (tag) =>{
        RemoveFromArray(tag_list,tag); 
        onChangeHandler(name,tag_list);
    }

    return (
        <div className="tags-input">
           
                {
                    tag_list.map(tag => (
                        <div className="black-chip chip-wrapper-primary">
                            <span className="text-part">{tag}</span>
                            <button onClick={()=>removeTag(tag)} className="icon-part"><i className="ri-close-line" ></i></button>
                        </div>
                    ))
                }
                {
                    tag_list.length > 0?  <div className="seprator"></div> :null
                }
            
           
            <textarea onKeyPress={handleKeyPress} placeholder={props.placeholder} style={{resize:'none', marginTop:'10px'}} rows={1} /> 
        </div>
    );
}

TagInput.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default TagInput;