import React,{useState} from 'react';
import PropTypes from 'prop-types';
import {Doughnut} from 'react-chartjs-2';
import { styled } from '@mui/system';

import Tooltip from '@mui/material/Tooltip';
import ReplicationToolBar from '../../../listing/components/ReplicationToolBar';

const CurrentDeployment = props => {
  const data = props.data;
  const pods_data = props.pods_data;
  const pods = Object.keys(pods_data);
  const deployed_on = (new Date(data.get.metadata.creation_timestamp)).toString();
  const artifact = pods_data[pods[0]] ? ( pods_data[pods[0]].spec.containers[0].image).split(":")[1] : "";
  const namespace = data.get.metadata.namespace;
  const max_count = data.get.spec.replicas;
  const min_count = "N/A";
  const current_count = pods.length;
  const changeSelectedPod = props.changeSelectedPod;
  const open_by_default = props.open_by_default;
  const service_id = props.service_id;
  const env_id = props.env_id;
  const [show,setShow] = useState(true);
 



  const chartData = genrateChartData(pods);

  function callbackFromReplicationToolBar(status){
    setShow(status == "close");
  }

return(
    <MainDiv>
      <div className="recent-deployment-cart-section">
            <div className="content-center-chart">
            <div className="bottom-data">
                <p className="heading-main">Current Deployment</p>
                <div className="bottom-section">
                    <div className="artifact-name">
                      <p>Artifact</p>
                      <Tooltip title={artifact} placement="bottom">
                      <div className="chip-default artifact-name-blue">{artifact}</div>
                      </Tooltip>
                    </div>
                    <div className="bottom-section-data">
                        <div>
                          <span className="main-text">Namespace:</span>
                          <span className="sub-text">&nbsp;{namespace}</span>
                        </div>
                        <div>
                        <span className="main-text">Deployed on:</span>
                        <span className="sub-text">&nbsp;{deployed_on.substring(0,deployed_on.toString().indexOf("GMT"))}</span>
                        </div>
                    </div>
                </div>
          </div>
      
              <div className="chart-with-content">
              
              <Doughnut
              data={chartData}
              
              options={{
                responsive: true,
                cutoutPercentage: 85,
                layout: { padding: 0},
                segmentShowStroke: false,
                legend:{
                  display:false,
                  position:'right',
                  
                },
                tooltips: {
                    enabled: true,
                    mode: 'index',
                    intersect: true,
                    titleFontSize:14,
                    titleFontColor: '#fff',
                  }
                }}
              />
              
            
            <div className="content-1" style={show ? {bottom:'7rem'} : {bottom:'5rem'}}>
              {
                show ?
                <p className="left-pods-count" id="edit">{pods.length}</p>:null
              }
            
            <ReplicationToolBar varient={"no-header"} callback={callbackFromReplicationToolBar} service_id={service_id} env_id={env_id} replication_bar={{current:pods.length}} />
          </div>

              </div>
            
              <Pods>
                  <p className="heading">Pods Available</p>
                  <div className="max-height-200">
                  {
                     pods.map((pod,index)=>(
                      <PodsNameList>
                        <div className="list-count">
                        {index+1}
                        </div>
                        <Tooltip title={pod}  placement="bottom">
                        <a href="#podsection" className="podname" onClick={()=>changeSelectedPod(pod)}>{pod}</a>
                        </Tooltip>
                        
                    </PodsNameList>
                    ))
                  }
                  </div>
              </Pods>
            
            </div>

          </div>
          </MainDiv> 
      )   

  }

  CurrentDeployment.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
export default CurrentDeployment;

function genrateChartData(pods){
  const data = {
    label: 'Pods',
    backgroundColor: [],
    data: []
  }

  pods.forEach(element => {
    data.backgroundColor.push('#51cbbb');
    data.data.push(85)
  });

  return {
    labels: pods,
    datasets: [
      data
    ]
  }

}

const MainDiv = styled('div')({
  display:'flex',
  alignItems:'center',
  justifyContent:'space-between',
  paddingBottom: '20px',
  '& .recent-deployment-cart-section':{
      width:'100%'
  },

 '& .content-center-chart':{
  display:'grid',
  gridTemplateColumns:'25% 1fr 25%',
  alignItems:'center',
  width:'100%',
  padding:'0px 20px',
  justifyContent:'space-between',
 },
 '& .content-1':{
   '& #main-div':{
    flexDirection:'column',
   },
  '& .data-div':{
    flexDirection:'column',
   },
   '& .btn-div':{
    width:'fit-content',
    display:'flex',
      margin:'auto',
      '& button':{
        marginLeft:'0px!important',
        border:'none',
        backgroundColor:'transparent',
        color:'#54b4f4',
        marginleft:'10px!important',
        display:'block',
        fontSize:'11px',
        fontWeight:'bold',
        '&:only-child':{
          margin:'0px!important'
        },
        '&:nth-child(2)':{
          marginLeft:'10px!important'
        }
      }
   },
   '& .slider-with-input':{
     width:'40%',
     margin:'auto',
     '& .input-component':{
      marginBottom:'.5rem'
     },
     '& .input-component .d-flex':{
       display:'block!important',
       '& .input-with-btn':{
         width:'50px',
         margin:'auto'
       }
     }
   }
 },
 '& .content-1 #circular-slider':{
  position:'absolute!important',
  left:'205px',
  top:'17px',
  width:'90px!important',
  height:'90px!important'
 },
 '& .bottom-section':{
     display:'block'
 }
 ,'& .bottom-section .artifact-name':{
     margin:'0px 0px!important'
 },
 '& .bottom-section-data':{
     margin: '0px'
 }

})

  export const Pods = styled('div')({
      '& p.heading':{
          fontSize:'16px',
          color:'#526174'
      }

  })
  export const PodsNameList = styled('div')({
      display:'flex',
      alignItems:'center',
      padding:'10px 0px',
      borderBottom:'1px solid #eee',
      '& .list-count':{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          backgroundColor:'#99aebd',
          height:'24px',
          width:'24px',
          borderRadius:'50%',
          color:'#fff',
          fontSize:'12px',
          marginRight:'.5rem'
      },
      '& media and screen(min-width: 1440px)':{
        '& a.podname':{
          width:'210px'
        }
      },
      '& a.podname':{
          cursor:'pointer',
          fontSize:'11px',
          fontWeight:300,
          color:'#0096db',
          border:'none',
          backgroundColor:'#fff',
          width:'150px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
      }
  })