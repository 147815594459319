import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import ManifestHandler from '../../../../components/genericComponents/Forms/ManifestHandler';
import { Input } from '../../../../components/genericComponents/Input';
import { styled } from '@mui/system';

import { ValidateDataSet, VALIDATION_TYPE_POSITIVE_NUMBER, VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY } from '../../../../util/Validator';
import { useParams } from 'react-router-dom';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import { Loading } from '../../../utils/Loading';

import { ClusterName } from '../../../../util/util';

import EditorKeyValues from '../../../../components/genericComponents/inputs/EditorKeyValues';
import StepProgressDetailed from '../../../../components/genericComponents/StepProgressDetailed';
import { ErrorComponent } from '../../../utils/Error';
import { getListableArray } from '../../../../util/util'
import PageHeader from '../../../../components/PageHeader';
import { Link } from 'react-router-dom';
import DependencyDialog from '../../../../components/genericComponents/DependencyDialog';
const AddNamespaces = props => {
    const classes = useStyles();
    const [spacing, setSpacing] = React.useState(2);
    const { cluster_id, namespace_id } = useParams();
    const [state, setState] = useState(props.state ? props.state : {
        bp_ui_enabled: "guided",
        bp_managed_state: {},
        manifest_handler_state: {},
        registry_list: [],
        showLoading: false,
        data: {
            cluster_id: Number(cluster_id),
            name: "",
            registries: [],
            registries_response: []
        }
        ,
        error: {},
        validations: {
            name: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY],
            registries: [VALIDATION_TYPE_REQUIRED]
        }
    });

    const bp_managed_form_inherits = {};
    const manifest_handler_inherits = {};

    const validateForm = () => {
        const result = ValidateDataSet(state.data, state.validations);
        if (!result.valid) {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }));
        }
        return {
            valid: result.valid,
            error: {
                ...result.error,
            }
        };

    }

    function switchStrategy(e) {
        const value = e.target.value;
        onBpUiEnabled(value);
        setState(new_state => ({
            ...new_state,
            bp_ui_enabled: value,
        }));
    }
    function onBpUiEnabled(flag) {
        setState(new_state => ({
            ...new_state,
            bp_ui_enabled: flag == "guided"
        }));
    }

    function onChangeHandler(e) {
        const key = e.target.name;
        const value = e.target.value;

        if (key == "network_policy_enable") {
            updateData(key, !state.data[key]);
        } else {
            updateData(key, value);
        }
    }
    function onChangeHandler(e) {
        const key = e.target.name;
        const value = e.target.value;

        if (key == "istio_enabled") {
            updateData(key, !state.data[key]);
        } else {
            updateData(key, value);
        }
    }
    function onChangeHandlerForKeyValue(key, value) {
        if (key == "registries") {
            value.forEach((id, index) => {
                value[index] = Number(id);
            });
        }
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }
    const onChangeHandlerSmallInput = (e) => {
        var k = e.target.name;
        var v = e.target.value
        v = v.replace(/\W+(?!$)/g, '-');
        v = v.replace(/\W$/, '-');
        v = v.replace('_', '-').toLowerCase();
        updateData(k, v);
    }
    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v
            },
            error: {
                ...new_state.error,
                [k]: ""
            }
        }))
    }

    function validateAndSaveData() {

        const result1 = validateForm();
        const result = state.bp_ui_enabled == "custom" ? manifest_handler_inherits.validateForm() : bp_managed_form_inherits.validateForm();
        console.log(result, "lnjjfdsaj")
        if (result1.valid && result.valid) {
            var data = state.bp_ui_enabled == "custom" ? manifest_handler_inherits.getData() : bp_managed_form_inherits.getData();
            data = state.bp_ui_enabled == "custom" ? { manifest_meta_data: data } : data;
            var post_data = {
                ...state.data,
                ...data
            }

            function clean(obj) {
                for (var propName in obj) {
                    if (obj[propName] === null || obj[propName] === undefined) {
                        delete obj[propName];
                    }
                }
            }

            if (post_data.manifest_meta_data) {
                clean(post_data.manifest_meta_data)
            }
            if (namespace_id) {
                PostData(GenerateURL({ cluster_id: Number(cluster_id), namespace_id: Number(namespace_id) }, properties.api.get_namespace), post_data, postSuccess, postFail);
            } else
                PostData(GenerateURL({ cluster_id: Number(cluster_id) }, properties.api.save_namespace), post_data, postSuccess, postFail);

            setState(new_state => ({
                ...new_state,
                showLoading: true
            }))
        }
    }
    const [TaskID, setTaskID] = useState("not");
    function postSuccess(response) {
        setTaskID(response.task_id);
        setState(new_state => ({
            ...new_state,
            namespace_id: response.id,
            showLoading: false
        }))
    }

    function postFail(error) {
        console.log("sakkals",error)
        setState(new_state => ({
            ...new_state,
            showLoading: false,
            ...(error?.edit_possible === false 
                ? {edit_error : {...error}}
                : { error_all: error }) 
        }));
    }

    useEffect(() => {

        if (namespace_id) {
            fetchEditData();
        }
        fetchSupportedRegistryList();
    }, [cluster_id, namespace_id]);


    function fetchSupportedRegistryList() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.nexus_setup),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSupportedRegistryListSuccess, fetchSupportedRegistryListFail)
        setState(new_state => ({
            ...new_state,
            showLoading: true
        }))
    }

    function fetchSupportedRegistryListSuccess(response) {

        setState(new_state => ({
            ...new_state,
            registry_list: response.results && response.results.length > 0 ? response.results : [],
            showLoading: false
        }))
    }

    function fetchSupportedRegistryListFail(response) {
        setState(new_state => ({
            ...new_state,
            error: JSON.stringify(response),
            showLoading: false
        })
        )
    }

    function fetchEditData() {

        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id, namespace_id: namespace_id }, properties.api.get_namespace),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onEditFetchSuccess, onEditFetchFail);
        setState(new_state => ({
            ...new_state,
            showLoading: true
        }))
    }

    function onEditFetchSuccess(response) {

        console.log('response_', response);
        const bp_flag = response.manifest_meta_data ? "custom" : "guided";
        var res_arr = getResponseArr(response);

        console.log('after_response==>', res_arr);

        setState(new_state => ({
            ...new_state,
            namespace_data: response,
            showLoading: false,
            data: {
                ...new_state.data,
                name: response.name,
                registries: res_arr
            },
            bp_ui_enabled: bp_flag
        }))
    }
    function getResponseArr(data) {
        var final_arr = new Array();
        if (data.registries && data.registries.length > 0) {
            data.registries.forEach(item => {
                final_arr.push(item.id)
            })
        }
        return final_arr;
    }
    function onEditFetchFail(response) {
        setState(new_state => ({
            ...new_state,
            error: JSON.stringify(response),
            showLoading: false
        })
        )
    }
    function handleRefresh() {
        setTaskID("not")
    }

    function setErrorNull(setState) {
        setTimeout(() => {
            setState(prevState => ({
                ...prevState,
                error_all: null
            }))
        }, 3500);
    }

    useEffect(() => {
        if (state.error_all) {
            setErrorNull(setState);
        }
    }, [state.error_all]);

    const clearDependencyError = () => {
        setState((prevState) => {
            const { edit_error, ...rest } = prevState; 
            return {
                ...rest,
            };
        });
    }

    console.log("prev_sttatre", state.registry_list, state.registries_response)

    console.log(state, 'st_add_nm')
    return (
        <div className={classes.root}>
            <Grid conatiner>
                {TaskID == "not" ? <>
                    <Grid item lg={12}>

                        {
                            state.showLoading ?
                                <Loading /> : null
                        }
                        {
                            state.error_all ? <> <ErrorComponent error={state.error_all.toString()} /></> : null
                        }
                        <PageHeader
                            heading={`${namespace_id ? "Edit" : "Add"} NameSpace of your Kubernetes Clusters`}
                            subHeading={`Follow these simple steps to ${namespace_id ? "Edit" : "Add"} Namespaces to your cluster.`}
                            imgIcon="/images/header_icons/namspace-icon.svg"
                            primaryButton={null}
                            commonDivMargin={true}
                        />

                    </Grid>
                    <Grid item lg={12}>
                        <div >


                            <Grid container spacing={2}>
                                <Grid item lg={12}>
                                    <Input
                                        type="text"
                                        mandatorySign
                                        placeholder="Namespace Name"
                                        label="Namespace Name"
                                        name="name"
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={onChangeHandlerSmallInput} />
                                </Grid>

                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item lg={12}>
                                    <Input
                                        type="checkbox"
                                        mandatorySign
                                        label="Select Registry"
                                        subHeading="(You can choose multiple)"
                                        name="registries"
                                        list={state.registry_list && state.registry_list.length > 0 ? getListableArray(state.registry_list) : []}
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={state.registry_list ? onChangeHandlerForKeyValue : () => { }} />
                                </Grid>

                            </Grid>
                            <>
                                {!state.showLoading ?
                                    <>


                                        <Grid item lg={12} md={12} xs={12} className='wrapper-for-flex' >
                                            <Input
                                                type="radio"
                                                mandatorySign
                                                label="Choose Namespace Setup Strategy"
                                                name="bp_ui_enabled"
                                                list={[{ label: "Guided Form(Recommended)", value: "guided" }, { label: "Upload Custom Manifest File / GitOps", value: "custom" }]}
                                                data={{ bp_ui_enabled: state.bp_ui_enabled }}
                                                error={{ bp_ui_enabled: "" }}
                                                onChangeHandler={switchStrategy} />
                                        </Grid>

                                        {
                                            state.bp_ui_enabled == "guided" ?
                                                <BP_UI namespace_data={state.namespace_data} inherits={bp_managed_form_inherits} />
                                                :
                                                <div className="class-controller">
                                                    <ManifestHandler manifest_meta_data={state.namespace_data ? state.namespace_data.manifest_meta_data : null} inherits={manifest_handler_inherits} />
                                                </div>
                                        }

                                    </>

                                    : null
                                }
                            </>

                            <div className='d-flex align-center justify-end'>
                                <Link to={"/cluster/" + cluster_id + "/namespace/list"} >
                                    <button className="btn btn-transparent mr-5">Cancel</button>
                                </Link>
                                <button onClick={validateAndSaveData} className="btn btn-primary mr-0">{namespace_id ? "Save Details" : "Apply Manifest"}</button>
                            </div>
                        </div>

                    </Grid>
                </> :
                    <StepProgressDetailed task_id={TaskID} placeholders={{ cluster_id: cluster_id, namespace_id: state.namespace_id }} refresh={handleRefresh} variant="cluster_onboarding" />
                }
                {
                    state?.edit_error?.dependencies ? 
                        <DependencyDialog
                            dependency_data={state?.edit_error?.dependencies}
                            dialog_open={true}
                            action_name={'edit'}
                            entity_name={'Namespace'}
                            clearDependencyError={clearDependencyError}
                        />
                    : null
                }
            </Grid>
        </div>
    )
}
AddNamespaces.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default AddNamespaces;

const BP_UI = (props) => {
    console.log('pr_nm', props)
    const inherits = props.inherits;
    const namespace_data = props.namespace_data;
    console.log('nameSpace_data', namespace_data);
    const unit_list = [
        {
            id: 1,
            label: 'Gi',
            value: "Gi"
        },
        {
            id: 2,
            label: 'Mi',
            value: "Mi"
        }
    ]
    const key_value_inherits = [{}, {}];
    const key_value_inherits1 = {};
    const namespace_memory = namespace_data ? namespace_data.requests_memory_quota ? namespace_data.requests_memory_quota : "" : "";
    const namespace_unit = namespace_data ?
        namespace_data.requests_memory_quota ?
            namespace_memory.substring(namespace_memory.length - 2) == "Gi" ?
                "1" :
                "2" :
            "1" :
        "1";
    const namespace_memory2 = namespace_data ? namespace_data.limits_memory_quota ? namespace_data.limits_memory_quota : null : null;

    const namespace_unit2 = namespace_data ? namespace_data.limits_memory_quota ? namespace_memory2.substring(namespace_memory2.length - 2) == "Gi" ? "1" : "2" : "1" : "1";
    const [state, setState] = useState({
        data: {
            requests_memory_quota: 1,
            unit: "1",
            requests_cpu_quota: 1,
            limits_memory_quota: 1,
            limits_cpu_quota: 1,
            unit2: "1",
            network_policy_enable: false,
            resource_quota_enable: true,

        },
        error: {},
        validations: {
            requests_memory_quota: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_POSITIVE_NUMBER],
            requests_cpu_quota: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_POSITIVE_NUMBER],
            unit: [VALIDATION_TYPE_REQUIRED],
            limits_memory_quota: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_POSITIVE_NUMBER],
            limits_cpu_quota: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_POSITIVE_NUMBER],
            unit2: [VALIDATION_TYPE_REQUIRED]

        }
    });

    useEffect(() => {
        if (props.namespace_data) {
            setState(prev_state => ({
                ...prev_state,
                data: {
                    requests_memory_quota: namespace_data.requests_memory_quota ? namespace_memory.substring(0, namespace_memory.length - 2) : 1,
                    unit: namespace_unit,
                    requests_cpu_quota: namespace_data.requests_cpu_quota ? namespace_data.requests_cpu_quota : 1,
                    unit2: namespace_unit2,
                    limits_cpu_quota: namespace_data.limits_cpu_quota ? namespace_data.limits_cpu_quota : 1,
                    limits_memory_quota: namespace_data.limits_memory_quota ? namespace_memory2.substring(0, namespace_memory.length - 2) : 1,
                    labels_enabled: namespace_data.labels.length > 0 ? true : false,
                    annotations_enabled: namespace_data.annotations.length > 0 ? true : false,
                    annotations: GetKV(namespace_data.annotations),
                    labels: GetKV(namespace_data.labels),
                    network_policy_enable: namespace_data.network_policy_enable,
                    resource_quota_enable: namespace_data.requests_cpu_quota ? true : false,
                    istio_enabled: namespace_data.istio_enabled ? true : false,
                }
            }))
        }
    }, [props.namespace_data])


    function onChangeHandler(e) {
        const key = e.target.name;
        const value = e.target.value;
        if (key == "network_policy_enable") {
            updateData(key, !state.data[key]);
        }
        else if (key == "resource_quota_enable") {
            updateData(key, !state.data[key]);
        }
        else if (key == "istio_enabled") {
            updateData(key, !state.data[key])
        }
        else if (["annotations_enabled", "labels_enabled"].includes(key)) {
            updateData(key, !state.data[key]);
        } else {
            updateData(key, value);
        }
    }

    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
            },
        }));



    }

    inherits.validateForm = () => {
        const local_form_result = ValidateDataSet(state.data, state.validations);
        let temp_error = {};
        const data_temp = state.data;
        //||(data_temp.annotations_enabled&&data_temp.annotations.length==0)||(data_temp.labels_enabled&&data_temp.labels.length==0)
        if (!local_form_result.valid || (Number(data_temp.requests_cpu_quota) > Number(data_temp.limits_cpu_quota)) || (Number(data_temp.requests_memory_quota) > Number(data_temp.limits_memory_quota))) {
            local_form_result.valid = false;

            if (state.data.requests_cpu_quota > state.data.limits_cpu_quota) {
                temp_error = {
                    limits_cpu_quota: "Limits must be greater than request"
                }
            }
            if (state.data.requests_memory_quota > state.data.limits_memory_quota) {
                temp_error = {
                    ...temp_error,
                    limits_memory_quota: "Limits must be greater than request"
                }
            }
            if (data_temp.annotations_enabled && data_temp.annotations.length == 0) {
                temp_error = {
                    ...temp_error,
                    annotations_enabled: "Annotations are empty"
                }
            }
            if (data_temp.labels_enabled && data_temp.labels.length == 0) {
                temp_error = {
                    ...temp_error,
                    labels_enabled: "Labels are empty"
                }
            }

            setState(new_state => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...local_form_result.error,
                    ...temp_error
                }
            }));
        }
        return {
            valid: local_form_result.valid,
            error: {
                ...local_form_result.error,
                ...temp_error
            }
        };
    }
    inherits.getState = () => {
        return state;
    }

    inherits.getData = () => {
        var data = {
            network_policy_enable: state.data.network_policy_enable,
            istio_enabled: state.data.istio_enabled
        }
        if (state.data.resource_quota_enable) {
            var unit_temp = state.data.unit == "1" ? "Gi" : "Mi";
            var unit_temp2 = state.data.unit2 == "1" ? "Gi" : "Mi";
            var resource_quota = {
                requests_memory_quota: state.data.requests_memory_quota + "" + unit_temp,
                requests_cpu_quota: state.data.requests_cpu_quota,
                limits_cpu_quota: state.data.limits_cpu_quota,
                limits_memory_quota: state.data.limits_memory_quota + "" + unit_temp2
            }
        }
        else {
            data.requests_memory_quota = null
            data.requests_cpu_quota = null
            data.limits_cpu_quota = null
            data.limits_memory_quota = null
        }
        data = { ...data, ...resource_quota };
        if (state.data.annotations_enabled) {
            let data_temp = key_value_inherits[0].getData();

            data.annotations = [];
            data_temp.forEach(anno => {
                data.annotations.push({
                    id: anno.id,
                    env_key: anno.key,
                    env_value: anno.value,
                });
            });
        }

        if (state.data.labels_enabled) {
            data.labels = [];

            let data_temp = key_value_inherits1.getData();
            data_temp.forEach(anno => {
                data.labels.push({
                    id: anno.id,
                    env_key: anno.key,
                    env_value: anno.value,
                });
            });
        }

        return data;
    }

    console.log(state, 'placeholder_state_001')
    return (
        <>
            <Grid item lg={12}>
                <div className="border-controller card mb-15">
                    <Input
                        type="switch"
                        name="istio_enabled"
                        label={<>Enable Istio Injector <span className="font-10 text-color-grey"></span></>}
                        mandatorySign
                        data={state.data}
                        info={"(Once you enable istio injector for a namespace, you will be able to use istio gateways for service routing configuration. Please note that you will be unable to use Nginx ingress.)"}
                        error={state.error}
                        onChangeHandler={onChangeHandler}
                    />
                </div>
            </Grid>
            <Grid item lg={12}>
                <div className='border-controller card mb-15'>
                    <Input
                        type="switch"
                        name="resource_quota_enable"
                        label="Add Resource Quota"
                        data={state.data}
                        error={state.error}
                        onChangeHandler={onChangeHandler}
                    />
                    {
                        state.data.resource_quota_enable ?
                            <div className="pd-10">
                                <Grid container spacing={2}>
                                    <Grid item lg={5} >
                                        <Input
                                            mandatorySign
                                            type="number"
                                            placeholder="Request Memory Quota"
                                            name="requests_memory_quota"
                                            label="Request Memory Quota"
                                            onChangeHandler={onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </Grid>
                                    <Grid item lg={2} >
                                        <Input
                                            mandatorySign
                                            type="select"
                                            label="Unit"
                                            name="unit"
                                            list={unit_list}
                                            onChangeHandler={onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </Grid>
                                    <Grid item lg={5}>
                                        <Input
                                            mandatorySign
                                            type="number"
                                            placeholder="Request CPU Quota"
                                            label="Request CPU Quota (in cores)"
                                            name="requests_cpu_quota"
                                            onChangeHandler={onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </Grid>
                                    <Grid item lg={5} >
                                        <Input
                                            mandatorySign
                                            type="number"
                                            placeholder="Limit Memory Quota"
                                            name="limits_memory_quota"
                                            label="Limit Memory Quota"
                                            onChangeHandler={onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </Grid>
                                    <Grid item lg={2} >
                                        <Input
                                            mandatorySign
                                            type="select"
                                            label="Unit"
                                            name="unit2"
                                            list={unit_list}
                                            onChangeHandler={onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </Grid>
                                    <Grid item lg={5}>
                                        <Input
                                            mandatorySign
                                            type="number"
                                            placeholder="Limit CPU Quota"
                                            label="Limit CPU Quota (in cores)"
                                            name="limits_cpu_quota"
                                            onChangeHandler={onChangeHandler}
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            : null}
                </div>


            </Grid>
            <Grid item lg={12} md={12} xs={12} >
                <div className='border-controller card mb-15'>
                    <Input
                        type="switch"
                        name="network_policy_enable"
                        label="Enable Namespace Isolation"
                        data={state.data}
                        error={state.error}
                        onChangeHandler={onChangeHandler}
                    />
                </div>
            </Grid>
            <Grid item lg={12} md={12} xs={12} >
                <div className="card" style={{ marginBottom: '15px' }}>
                    <div className='heading'>
                        <Input
                            type="switch"
                            name="annotations_enabled"
                            label="Add Annotations"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                    </div>
                    {
                        state.data.annotations_enabled ?
                            <div>
                                <EditorKeyValues inherits={key_value_inherits[0]} data={state.data.annotations ? state.data.annotations : [{ key: "", value: "", env_key: "", env_value: "" }]} update_data_callback={updateData} field_name={"annotations"} />
                            </div> :
                            null
                    }

                </div>
            </Grid>
            <Grid item lg={12} md={12} xs={12} >
                <div className="card" style={{ marginBottom: '15px' }}>
                    <div className='heading'>
                        <Input
                            type="switch"
                            name="labels_enabled"
                            label="Add Labels"
                            onChangeHandler={onChangeHandler}
                            data={state.data}
                            error={state.error} />
                    </div>
                    {
                        state.data.labels_enabled ?
                            <EditorKeyValues inherits={key_value_inherits1} data={state.data.labels ? state.data.labels : [{ key: "", value: "", env_key: "", env_value: "" }]} update_data_callback={updateData} field_name={"labels"} /> : null
                    }
                </div>
            </Grid>
        </>

    )
}
BP_UI.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

function GetKV(data) {
    const kv = [];
    data.forEach(env => {
        kv.push({
            key: env.env_key,
            value: env.env_value,
            id: env.id,
            env_key: env.env_key,
            env_value: env.env_value,
        })
    });
    return kv;
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '20px',
        backgroundColor: '#fff',
        position: 'relative',
        height: '100%',
        '& .wrapper-for-flex': {
            flexDirection: 'row'
        }
    },
    card: {
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        background: '#f9f9f9',
        border: '1px solid #d5d5d5',
        overflow: 'hidden',
        '& .box-file-upload': {
            padding: '10px'
        },
        '& .d-flex': {
            justifyContent: 'space-between',
            // borderBottom: '1px solid #d5d5d5d5'
        },
        '& .SubHeader': {
            backgroundColor: '#f6f6f6',
            height: '50px',
            padding: '0px 15px',
            margin: '15px -15px 15px',
            borderTop: '1px solid #dedede',
            borderBottom: '1px solid #dedede',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    cardHeader: {
        borderRadius: '8px 8px 0px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: "#fbfbfb",
        fontSize: '13px',
        padding: '10px 15px',
    },
    cardsubHeader: {
        borderRadius: '8px 8px 0px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: "#fbfbfb",
        fontSize: '13px',
        padding: '0px 15px',
        alignSelf: 'center',
        '& .input-component': {
            marginBottom: '15px'
        }
    },
    cardBody: {
        padding: '20px 15px',
        backgroundColor: '#f6f8f8',
        '& .input-component': {
            marginBottom: '15px'
        },
        '& .with-dropdown': {
            display: 'grid',
            gridTemplateColumns: 'auto 120px',
            gap: '10px',
            alignItems: 'center',

        }
    },
    cardFooter: {
        backgroundColor: '#fff',
        borderRadius: ' 0px 0px 8px 8px',
        display: 'flex',
        padding: '10px 15px',
        borderTop: '1px solid #d5d5d5',
        justifyContent: 'flex-end',
        '& .btn-submit': {
            backgroundColor: '#3696db',
            color: '#fff',
            marginLeft: 'auto'
        }
    }
}))
export const CommonHeader = styled('div')({
    backgroundColor: ' #f4f4f4',
    borderRadius: ' 8px 8px 0px 0px',
    display: 'flex',
    padding: '15px',
    justifyContent: ' space-between',
    color: '#666',
    fontSize: '12px',
    borderBottom: '1px solid #dedede',
    '& p': {
        fontSize: '12px',
        color: '#666',
        lineHeight: 1,
    }
})
