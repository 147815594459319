import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import '../../../../../assets/style.css';
import {Input} from '../../../../../components/genericComponents/Input';
import Grid from '@mui/material/Grid';;
import PageHeader from '../../Components/PageHeader';
const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1 ,
      marginTop:'3rem'  
    }
  }));
const ResourceQuota =(props) => {
  const classes = useStyles();
  const data = props.data;
  const error = props.error;
  const update_data_callback = props.update_data_callback;
  const onChangeHandler = (e) => {
    update_data_callback(e.target.name,Number(e.target.value) ? Number(e.target.value) : "");
  }
    return(
      <>
      <PageHeader title="Allocate Resource Quota" service_name={props.service_name} env_name={props.env_name} sub_env_name={props.sub_env_name} />
     <div className={classes.formDiv}>
        <Grid container justify="center">
            <div className='fromClass'>
            <Grid lg={12}>
            <Grid container justify="center" className="card-add-service">
              <Grid lg={12} direction="row" className="card-add-service-head">
                <div className="formTag">
                  <h4 className='mainHeading'>Set Resource Quota</h4>
                </div>
              </Grid>
              <Grid container  className="card-add-service-body">
                <Grid lg={6} direction="row">
                  <div className='section'>
                    <Input
                      type="text" 
                      name="default_cpu" 
                      mandatorySign
                      label="CPU Quota (units)" 
                      error={error}
                      onChangeHandler={onChangeHandler} 
                      data={data}/>
                  </div>  
              </Grid>
                <Grid lg={6} direction="row">
                  <div className='section'>
                    <Input
                      type="text" 
                      mandatorySign
                      name="default_ram" 
                      label="RAM Quota (mb)" 
                      error={error}
                      onChangeHandler={onChangeHandler} 
                      data={data}/>
                  </div>  
              </Grid>
              <Grid lg={6} direction="row" >
                <div className='section'>
                    <Input 
                    type="text"
                    mandatorySign
                    name="desired_replication" 
                    label="Desired Replication"
                    onChangeHandler={onChangeHandler} 
                    data={data} 
                    error={error}/>
                </div> 
              </Grid>
              </Grid>  
            </Grid>      
        </Grid>
            </div>
        </Grid>
    </div> 
      </>
    );
  };

  ResourceQuota.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };
  export default ResourceQuota;
  