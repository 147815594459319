import React, { useEffect, useRef, useState } from 'react'
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../components/genericComponents/Input';
import MultiRow from '../../../components/genericComponents/MultiRow';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';;
import { VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';
// import Editor from '@monaco-editor/react';
import Dialog from '@mui/material/Dialog';

import GenerateURL from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi from '../../../util/apiInvoker';
import { showErrorHandlerUpdated } from '../../../util/util';

const AddSingleRowForm = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getAddSingleRowFormDefaultState());
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  const style = {
    display: "grid",
    gridTemplateColumns: "20% 20% 20% 28% 12%",
    width: "100%",
    gridColumnGap: "23px",
    padding: "1.2rem"
  }
  return (
    <>
      <MultiRow
        HeaderComponent={InitCommandHeaderSection}
        view_mode={false}
        variant="Button_Placement_bottom"
        style={style}
        RepeatableComponent={AddSingleRowFormInput}
        prev_state={state.add_matrix}
        inherits={state.child_inherits.add_matrix} />
    </>
  )
}

export default AddSingleRowForm;



export const getAddSingleRowFormDefaultState = () => {
  return {
    data: {},
    error: {},
    validations: {
    },
    child_inherits: {
      add_matrix: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  }
}

const InitCommandHeaderSection = () => {
  return (
    <>
      <Grid item lg={11} className="">
        <div style={{ padding: '5px 10px', display: 'none' }}>
          Init Commands
        </div>
      </Grid>
    </>
  )
}

const AddSingleRowFormInput = (props) => {
  const classes = useStylesInputSection();
  const inherits = props.inherits;
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getAddMoreMountFileDefaultState());
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  const NAN_STRATEGIES = [
    { 'label': 'DEFAULT', id: 1 },
    { 'label': 'REPLACE WITH ZERO', id: 1 },
  ];

console.log(state.data, "state.data")

  useEffect(() => {
    fetchSource()
  }, [])

  function fetchSource() {
    var requestInfo = {
      endPoint: GenerateURL(
        {},
        properties.api.monitoring_resource_get + "?all=true"
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    InvokeApi(
      requestInfo,
      fetchSourceDataSuccess,
      fetchSourceDataFailed
    );

    setState((new_state) => ({
      ...new_state,
      save_data_loading: true,
    }));
  }
  function fetchSourceDataSuccess(response) {
    let resource_list = response.results && response.results.length > 0 ? response.results.map(item => { return { label: item.monitoring_url, id: item.id } }) : [];
    let monitoring_resources = response.results && response.results.length > 0 ? response.results.map(item => ({ label: item.monitoring_resource.name, id: item.monitoring_resource.id })) : [];

    console.log(monitoring_resources, resource_list, "resource_listresource_list")
    setState((new_state) => ({
      ...new_state,
      save_data_loading: false,
      resource_list: resource_list,
      monitoring_resources: monitoring_resources
    }));
  }
  function fetchSourceDataFailed(error) {
    let error_in_loading = showErrorHandlerUpdated(error)
    setState((new_state) => ({
      ...new_state,
      error_in_loading: error_in_loading,
      save_data_loading: false,
    }));
  }
  return (
    < >
      {/* <Grid item lg={2}> */}
      <div className={classes.mb_none}>
        <Input
          type="text"
          mandatorySign
          name="name"
          label=""
          style={{ height: "40px", border: "1 solid #D8D8D8", }}
          placeholder="Enter Name"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </div>
      {/* </Grid> */}
      {/* <Grid item lg={2}> */}
      <div className={classes.mb_none}
        style={{
          display: "flex",
          border: "1px solid #D8D8D8",
          alignItems: "center",
          gap: "5px",
          padding: "0px 10px",
          borderRadius: "4px",
        }}>
        {
          // state.save_data_loading ?
          //   <Loading varient="light" /> :
          //   state.error_in_loading ?
          //     <AlertStrip message={state.error_in_loading} /> :
          <>
            <img src={properties.tools.metrics.img} alt='provider' style={{ height: "16px", width: "16px" }} />
            <Input
              type="select"
              mandatorySign
              name="provider"
              width100={true}
              style={{
                height: "40px",
                border: "none",
                outline: "none",
              }}
              label=""
              placeholder="Select Provider"
              // list={state.monitoring_resources}
              list={NAN_STRATEGIES}
              error={state.error}
              onChangeHandler={commonFunctions.onChangeHandler}
              data={state.data} />

          </>
        }
      </div>
      {/* </Grid> */}
      {/* <Grid item lg={3}> */}
      <div className={classes.mb_none}>
        <Input
          type="select"
          mandatorySign
          name="nan_strategy"
          label=""
          placeholder="Select Provider"
          list={NAN_STRATEGIES}
          style={{ height: "40px", border: "1 solid #D8D8D8", }}
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
      </div>
      {/* </Grid> */}
      {/* <Grid item lg={4}> */}
      <div className={classes.mb_none + " d-flex align-center input-with-editor space-between"}>
        <Input
          type="text"
          mandatorySign
          name="matrix_url"
          label=""
          placeholder="Enter Query"
          style={{ height: "40px", border: "none !important", }}
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data} />
        <EditorDialog />
      </div>
      {/* </Grid> */}

    </>
  )
}




function getAddMoreMountFileDefaultState() {
  return {
    data: {

    },
    resource_list: [],
    error_in_loading: null,
    error: {},
    validations: {
      name: [VALIDATION_TYPE_REQUIRED],
      provider: [VALIDATION_TYPE_REQUIRED],
      nan_strategy: [VALIDATION_TYPE_REQUIRED],
      matrix_url: [VALIDATION_TYPE_REQUIRED],
    }
  }
}
const useStylesInputSection = makeStyles(theme => ({
  file_upload: {
    '& .image-upload-wrap': {
      height: '42px',
      marginTop: 0
    },
    '& .file-upload-input::before': {
      padding: '5px',
      marginLeft: 10
    },
    '& .box-file-upload': {
      padding: '0px!important',
      '& .input-component': {
        marginBottom: '0px',
        '&>.d-flex': {
          '& span': {
            display: 'none'
          }
        }
      }
    }
  },
  mb_none: {
    '&.input-with-editor': {
      border: "1 solid #D8D8D8",
      position: 'relative',
      borderRadius: '4px',


      '& .input-component': {
        width: '100%',
        "& select": {
          border: "none !important",
          outline: "none !important",
          "&:focus": {
            border: "none !important",
            outline: "none !important",
          },
        },

        '& input': {
          // border: 'none !important',
          '&:focus': {
            // border: '0px solid #4671C6 !important',
            borderRadius: '4px !important',
            boxShadow: 'none !important',
            outline: 'none !important'
          }
        }
      }
    },
    '& .input-component': {
      marginBottom: '0px',


    }

  }
}));

const EditorDialog = (props) => {
  const valueGetter = useRef();
  const [value, setValue] = useState("api/v1/infra/${infra_id}/ns/${ns_id}/");
  let data_editor = value;
  function handleEditorDidMount(_valueGetter) {
    valueGetter.current = _valueGetter;
  }
  const classes = useStylesDialog();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <button
        className='variable-list-button mr-5'
        onClick={handleClickOpen}
        style={{ position: 'absolute', right: '5px' }}
      >Open Editor</button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div className={classes.root}>
          <div className='variable_list_header d-flex align-center space-between'>
            <p>Enter Query</p>
            <button className="btn btn-transparent" onClick={handleClose} >
              <span className='ri-close-line font-24'></span>
            </button>
          </div>
          {/* <Editor
            height="200px"
            language="query"
            value={value}
            options={{
              minimap: {
                enabled: false,
              },
              fontSize: 14,
              // cursorStyle: "block",
              wordWrap: "on",
            }}
            defineTheme={{
              themeName: "vs-dark",
              themeData: {
                colors: {
                  "editor.background": "#000ff",
                },
              },
            }}
            editorDidMount={handleEditorDidMount}
          /> */}
          <div className='d-flex aling-center justify-end border-top pd-10'>
            <button className='btn btn-outline-grey'>Cancel</button>
            <button className='btn btn-submit'>Save</button>
          </div>
        </div>
      </Dialog>
    </>


  )
}
const useStylesDialog = makeStyles((theme) => ({
  root: {
    minHeight: 300,
    '& .variable_list_header': {
      backgroundColor: '#fafafa',
      padding: '16px',
      color: '#2f2f2f',
      fontSize: '16px',
      fontWeight: 600
    },
    '& .accordion_summary': {
      background: ' linear-gradient(180deg, #FFF 0%, #F5F5F5 100%)'
    },
    '& .Accordion_Details': {
      padding: '10px',
      width: '100%',
      display: 'block',
      '& .table_accordion_detail': {
        width: '100%',
        borderRadius: '8px 8px 0px 0px',
        border: '1px solid  #E6E6E6',
        background: '#F5FAFF',
        display: 'grid',
        gridTemplateColumns: '180px 180px 1fr',
        gap: 20,
        padding: 10
      },
      '& .table_accordion_body': {
        width: '100%',
        borderRadius: '0px 0px 8px 8px',
        border: '1px solid  #E6E6E6',
        background: '#fff',
        display: 'grid',
        fontSize: '12px',
        color: '#787878',
        gridTemplateColumns: '180px 180px 1fr',
        gap: 20,
        padding: 10
      }
    }
  },

}));