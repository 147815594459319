import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const JobListingHeader = (props)=>{
    const classes = useStyles();
    const application_id = props.application_id;
    const template_list = props.template_list;
    const changeDefaultJobTemplate = props.changeDefaultJobTemplate;
    const onCreateDefaultTemplate = props.onCreateDefaultTemplate;
    const inherits = props.inherits ? props.inherits : {}
    const [state, setState] = useState({
        data: {}, error: {}, child_inherits: {
            update_default_job_template: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        }
    })

    return (
        <div className="d-flex align-start f-direction-column mb-24 gap-5 w-100" style={{height: "41px"}}>

            <p className="font-18 font-style-normal font-weight-500 line-height-normal color-header-new" >Job Templates</p>
            <p className="font-12 font-style-normal font-weight-500 line-height-normal color-subheader-new" >
                To understand more about Job Templates please read the <a href="https://www.buildpiper.io/documentation/" target="_blank" className="text-anchor-blue">Documentation</a>
            </p>
            <div>
                {/* <SetAsDefaultDialog 
                onCreateDefaultTemplate={onCreateDefaultTemplate}
                application_id={application_id}
                changeDefaultJobTemplate = {changeDefaultJobTemplate}
                inherits = {state.child_inherits.update_default_job_template}
                template_list={template_list}
                /> */}

                {/* <Link to={"/application/" + application_id + "/job-template/add"} className="btn btn-submit">New Job Templates</Link> */}
            </div>

        </div>
    )
}

JobListingHeader.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const useStyles = makeStyles({

});

export default JobListingHeader;


