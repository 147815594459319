import React from 'react';
import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    //width:"100%",
    flexDirection: 'column',
    gap: theme.spacing(1),
    flex: 1,
  },
  
}));

const GenericSkeleton = ({ variant, width, height, count, animation,style,rootStyle }) => {
  const classes = useStyles();
  console.log("ureiueie",width,height,variant);
  const skeletons = Array.from({ length: count }).map((_, index) => (
    <Skeleton
      key={index}
      variant={variant}
      width={width}
      height={height}
      animation={animation}
      className={classes.skeleton}
      style={style}
    />
  ));

  return <div className={classes.root} style={rootStyle}>{skeletons}</div>;
};

GenericSkeleton.propTypes = {
  variant: PropTypes.oneOf(['text', 'rect', 'circle']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  count: PropTypes.number,
  animation: PropTypes.oneOf(['pulse', 'wave', false]),
};

GenericSkeleton.defaultProps = {
  variant: 'rect',
  width: '100%',
  height: "32px",
  count: 1,
  animation: 'pulse',
};

export default GenericSkeleton;
