import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContent';
import { DialogContent } from '@mui/material';

import { Tooltip } from '@mui/material';

export default function Annotations(props) {
  const [open, setOpen] = React.useState(false);
  const [spacing, setSpacing] = React.useState(2);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const taints = props.taints ? props.taints : []
  const tolerations = props.tolerations ? props.tolerations : [];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const anno = props.annotations ? props.annotations : null;
  const data2 = props.anno_ui ? props.anno_ui : [];
  const ip = props.data_ip ? props.data_ip : []
  return (
    <div>
      <span role='button' tabIndex={0}  className="text-blue-anchor" onKeyDown={()=>{}} onClick={handleClickOpen}>
        {tolerations.length > 0 ? tolerations.length : null}
        {
          anno ? Object.keys(anno).length + data2.length : null
        }
        {
          ip.length > 0 ? ip.length : null
        }
        {
          taints.length > 0 ? taints.length : null
        }
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex  align-center space-between dialogue-header">
          <h1 className="title-dialogue">{tolerations.length > 0 ? "Tolerations" : null}
            {anno ? "Annotations" : null
            }
            {
              ip.length > 0 ? "Whitelisted IPs" : null
            }
            {
              taints.length > 0 ? "Taints" : null
            }
          </h1>
          <button className="btn btn-transparent" onClick={handleClose}>
            <span className='ri-close-line' style={{fontsize: '20px' }}  ></span>
          </button>
        </div>
        <DialogContent style={{ minHeight: '300px' }}>
          <div className="pods-full-width" style={{ display: 'flex', flexWrap: 'wrap' }}>
            {anno ?
              Object.keys(anno).map(label => (
                <Tooltip title={label + "=" + anno[label]}><div className="pod-lable">
                  {label + "=" + anno[label]}            </div></Tooltip>

              ))
              : null
            }           {
              data2.length > 0 ?
                data2.map(dat => (
                  <Tooltip title={dat.env_key + "=" + dat.env_value}>

                    <div className="pod-lable">
                      {dat.env_key + "=" + dat.env_value}
                    </div>
                  </Tooltip>

                )) : null
            }

            {
              tolerations.map(tol => (
                <Tooltip title={tol.key}>

                  <div className="pod-lable">
                    {tol.key}
                  </div></Tooltip>
              ))
            }
            {
              ip.map(i => (
                <Tooltip title={i.ip_address}>
                  <div className="pod-lable">
                    {i.ip_address}
                  </div></Tooltip>
              ))
            }
            {
              taints.map(dat => (
                <Tooltip title={dat.key + "=" + dat.effect}>
                  <div className="pod-lable">
                    {dat.key + "=" + dat.effect}
                  </div></Tooltip>
              )

              )
            }

          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
Annotations.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}