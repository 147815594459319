import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import JobTemplateListingV3ServiceCard from './JobTemplateListingV3ServiceCard';
import JobTemplateListingV3ServiceSummary from './JobTemplateListingV3ServiceSummary';

const JobListingForTemplates = ({ handleJobSelect,
    setSnackBar,
    jobListing,
    configuredJobs,
    handleConfigure,
    selectedJobTemplate,
    application_id,
    selectedServiceEnv,
    wideget_deployment_name,
    masterLabel,
    component_env_id,
    component_id,
    sub_env_id,
    showOnlyConfigured,
    service_data,
    editData,
    variant,
    service_id,
    env_id,
    job_code_queryString,
    getConfiguredJobs,
    ...props }) => {

    const [supportJobType, setSupportJobType] = useState(getDefaultSupportedJobs())
    const [updatedJobListingForSlider, setUpdatedJobListingForSlider] = useState(null)
    const [state, setState] = useState({

        filters: { selectedTabOrder: 1, job_name: '' }
    })
    const version = props.version ? props.version : 'v2'
    const templateData = selectedJobTemplate && selectedJobTemplate.data
    const templateName = templateData.name

    console.log('t---000-ppppp---->', templateData)
    useEffect(() => {
        if (jobListing) {
            let arrayForSlider = []
            let temp_array = []
            jobListing.forEach(job => {
                if (temp_array.length === 3) {
                    arrayForSlider = [...arrayForSlider, temp_array]
                    temp_array = []
                }
                if (showOnlyConfigured || state.filters.selectedTabOrder === 2) {
                    let job_code = job.job_code
                    let isConfigured = configuredJobs && configuredJobs[job_code]
                    if (isConfigured) {
                        temp_array.push(job)
                    }
                }

                else if (state.filters.selectedTabOrder === 3) {
                    let job_code = job.job_code
                    let isConfigured = configuredJobs && configuredJobs[job_code]
                    if (!isConfigured) {
                        temp_array.push(job)
                    }
                }
                else {
                    temp_array.push(job)
                }
            })

            if (temp_array.length > 0) {
                arrayForSlider = [...arrayForSlider, temp_array]
            }
            console.log(temp_array, 'temp_arrY_in_end')
            console.log(arrayForSlider, 'final_slider_array_001');
            setUpdatedJobListingForSlider(arrayForSlider);
            setCurrent(0);
        }
    }, [jobListing, showOnlyConfigured, configuredJobs, state.filters.selectedTabOrder])

    useEffect(() => {
        console.log('filters_for_jobs', state.filters)
    }, [state.filters])

    const useStyles = makeStyles({
        root: {
            maxWidth: 400,
            flexGrow: 1,
        },
    });

    useEffect(() => {

        if (jobListing) {
            let supportedJob = getDefaultSupportedJobs(jobListing)
            setSupportJobType(supportedJob)
        }
    }, [jobListing])

    const [current, setCurrent] = useState(0)

    const ref = useRef(null)

    const desired = e => {
        setCurrent(Number(e.target.id))
    }

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.style.transition = 'all 0.2s ease-in-out'
            ref.current.style.transform = `translateX(-${current}00%)`
        }

    }, [current])

    const classes = useStyles();

    console.log(jobListing, 'c_200001', configuredJobs, 'item_001dddd')

    const handleTabClick = (order) => {
        setState(prevState => ({
            ...prevState,
            filters: { ...prevState.filters, selectedTabOrder: order }
        }))
    }

    const handleChangeForSearch = (data) => {

        console.log(data, 'data_hfdshsld')
        setState(prevState => ({
            ...prevState,
            filters: { ...prevState.filters, job_name: data.job_name }
        }))
    }

    const resetSearch = () => {
        setState(prevState => ({
            ...prevState,
            filters: { ...prevState.filters, job_name: '' }
        }))
    }
    console.log(updatedJobListingForSlider, 'dsndsknsd')

    console.log(service_data, 'service_datasdsdsd');

    console.log(jobListing, showOnlyConfigured, configuredJobs, state.filters.selectedTabOrder, 'x_mojo')
    console.log(supportJobType, 'sj_pl_mn')
    return (
        <>
            {
                variant == 'service-card' ?

                    <JobTemplateListingV3ServiceCard
                        allJobs={jobListing}
                        configuredJobs={configuredJobs}
                        service_data={service_data}
                        filters={state.filters.selectedTabOrder}
                        fetchLastTriggeredData={true}
                        setSnackBar={setSnackBar}
                        application_id={application_id}
                        selectedServiceEnv={selectedServiceEnv}
                        wideget_deployment_name={wideget_deployment_name}
                        masterLabel={masterLabel}
                        component_env_id={component_env_id}
                        component_id={component_id}
                        sub_env_id={sub_env_id}
                        showOnlyConfigured={showOnlyConfigured}
                        actions={{
                            handleConfigure: handleConfigure
                        }}
                        supportJobType={supportJobType}
                        templateName={templateName}
                        service_id={service_id}
                        env_id={env_id}
                    />
                    :
                    variant == 'summary-view' ?
                        <JobTemplateListingV3ServiceSummary
                            allJobs={jobListing}
                            configuredJobs={configuredJobs}
                            service_data={service_data}
                            filters={state.filters.selectedTabOrder}
                            fetchLastTriggeredData={true}
                            setSnackBar={setSnackBar}
                            application_id={application_id}
                            selectedServiceEnv={selectedServiceEnv}
                            wideget_deployment_name={wideget_deployment_name}
                            masterLabel={masterLabel}
                            component_env_id={component_env_id}
                            component_id={component_id}
                            sub_env_id={sub_env_id}
                            showOnlyConfigured={showOnlyConfigured}
                            actions={{
                                handleConfigure: handleConfigure
                            }}
                            supportJobType={supportJobType}
                            templateName={templateName}
                            templateId={templateData.id}
                            job_code_queryString={job_code_queryString}
                            getConfiguredJobs={getConfiguredJobs}
                        />
                        :
                        null
            }
        </>
    )
}

JobListingForTemplates.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default JobListingForTemplates

const getDefaultSupportedJobs = (list) => {

    console.log(list, 'list_')
    let supportedJob =
    {
        build_job: {
            label: "Build Job",
            activity: 'RUNNING',
            selected: false,
            required: true,
            job_code: 'build_job',
            img_url: "/images/icons-custom-job-templates/build_job.png",
            icon_name: 'flaticon-flash-on-indicator'
        },
        deploy_job: {
            label: "Deploy Job",
            activity: 'IN_QUEUE',
            required: false,
            selected: false,
            job_code: 'deploy_job',
            img_url: "/images/icons-custom-job-templates/deploy_job.png",
            icon_name: 'flaticon-upload-button',
            configured: true
        },

        promote_job: {
            label: "Promote Job",
            activity: 'SUCCESS',
            required: false,
            selected: false,
            job_code: 'promote_job',
            icon_name: 'flaticon-forward-arrow',
            img_url: "/images/icons-custom-job-templates/promote_job.png",
            configured: true
        },
        rollback_job: {
            label: "Rollback Job",
            activity: 'REVOKED',
            required: false,
            selected: false,
            job_code: 'rollback_job',
            img_url: "/images/icons-custom-job-templates/rollback_job.png",
            configured: true
        },
        api_call_job: {
            label: "API Call Job",
            activity: 'FAILED',
            required: false,
            selected: false,
            job_code: 'api_call_job',
            img_url: "/images/icons-custom-job-templates/apicall_job.png",
            configured: false
        },
        jira_integration_job: {
            selected: false,
            required: false,
            label: "Jira Ticket Job",
            job_code: 'jira_integration_job',
            img_url: "/images/icons-custom-job-templates/deploy_job.png",
            configured: false

        },

        deploy_configmaps_job: {
            selected: false,
            required: false,
            activity: 'FAILED',
            label: "Deploy ConfigMaps Job",
            job_code: 'deploy_configmaps_job',
            img_url: "/images/icons-custom-job-templates/deploy_config_map_jobs.png",
            configured: false
        },
        deploy_secrets_job: {
            selected: false,
            required: false,
            label: "Deploy Secrets Job",
            job_code: 'deploy_secrets_job',
            img_url: "/images/icons-custom-job-templates/secrets_jobs.png",
            configured: false
        }
    }

    list && list.forEach(job => {
        if (!supportedJob[job.job_code]) {
            supportedJob = {
                ...supportedJob, [job.job_code]:
                {
                    selected: false, required: false, label: job.job_name,
                    job_code: job.job_code, img_url: "/images/icons-custom-job-templates/build_job.png",
                    configured: false
                }
            }
        }
    })
    return [supportedJob]
}

const useStyles = makeStyles({
    container: {
        '& .card': {
            // display: 'flex',
            // justifyContent: 'center',
            backgroundColor: '#fff',
            borderRadius: '8px',
            height: '135px',
            width: '200px',
            margin: 'auto',
            border: '1px solid white',
        },
        '& .overlay-wrap': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '20px',
            paddingRight: '20px',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: '#545454',
            top: '0',
            left: '0',
            content: "''",
            opacity: 0
        },
        '& .round-icons': {
            cursor: 'pointer',
            marginRight: '4px',
            marginLeft: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '3px solid #ffffff',
            color: '#ffffff',
            width: '40px',
            height: '40px',
            borderRadius: '30px',
        },
        '&:hover': {
            '& .overlay-wrap': {
                opacity: '0.8',
                // cursor: 'pointer',
            }
        },
        '& .bg-grey': {
            height: '50px',
            width: '50px',
            backgroundColor: '#f0f2fb',
            borderRadius: '35px'
        }
    }
})

const GetJobStatus = ({ data }) => {

    console.log(data, 'dt_')
    const required = <div className="justify-center" style={{ display: 'flex', border: '1px solid grren', backgroundColor: '#0096ff', color: 'white', padding: '2px 10px 2px 10px', borderBottomRightRadius: '8px' }}>
        <span style={{ fontSize: '12px' }} >Required</span>
    </div>

    const configured = <div className="justify-center" style={{ display: 'flex', border: '1px solid grren', backgroundColor: '#4ec478', color: 'white', borderBottomRightRadius: '8px', padding: '2px 10px 2px 10px' }}>
        <span style={{ fontSize: '12px' }} >Configured</span>
    </div>

    const notConfigured = <div className="justify-center" style={{ display: 'flex', border: '1px solid grren', backgroundColor: '#a2adb5', color: 'white', borderBottomRightRadius: '8px', padding: '2px 10px 2px 10px' }}>
        <span style={{ fontSize: '12px' }} >Not Configured</span>
    </div>

    const GetElement = ({ data }) => {

        if (data.required)
            return required
        else if (data.configured)
            return configured

        else
            return notConfigured
    }
    GetElement.propTypes = {
        ...PropTypes.objectOf(PropTypes.any),
      }

    return (
        <GetElement data={data} />
    )

}

GetJobStatus.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


