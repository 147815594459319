import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import {Input} from '../../../../../components/genericComponents/Input';
import properties from '../../../../../properties/properties';
import Grid from '@mui/material/Grid';;
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { REQUEST_TYPE_ADD_SERVICE, REQUEST_TYPE_ADD_ENV } from "../../";
import '../../style.css'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1 ,
    marginTop:'3rem'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mainDivSelected:{
    display:'flex', 
    alignItems:'center', 
    backgroundColor:'#fff', 
    padding:'.5rem 1.25rem', 
    borderRadius:'30px 0px 0px 30px',
    margin:'20px 0px 20px 20px'
  },
  mainDiv:{
    display:'flex', 
    alignItems:'center',
    maxWidth:'160px', 
    backgroundColor:'#fff', 
    padding:'.5rem 1.25rem', 
    borderRadius:'30px 30px 30px 30px',
    margin:'20px'
  },
  count:{
    width: '30px',
    height: '30px',
    border:'1px solid #ccc',
    borderRadius:'50%',
    textAlign:'center',
    paddingTop:'2px'
  },
  text:{
    fontSize:'11px',
    fontWeight:400,
  },
  mainBodyDiv:{
    backgroundColor: '#f0f7f7',
    padding:'15px 20px',
    borderRadius:'8px 8px 0px 0px', 
    borderTop:'1px solid#dedede',
    borderLeft:'1px solid#dedede',
    borderRight:'1px solid#dedede',
  },
  countBody:{
    backgroundColor:'#1d5b81',
    color:'#fff',
    width: '30px',
    height: '30px',
    borderRadius:'50%',
    textAlign:'center',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontWeight:800
  },
  tabBodyDivHeading:{
    display:'flex', 
    alignItems:'center',
    paddingBottom:'1rem',
    borderBottom:'1px solid #ccc' 
  },
  textBody:{
    fontSize:'16px',
    fontWeight:300,
    color:'#000'
  },
  formDiv:{
    padding:'0px 0px',
    margin:'0px 0px'
  },
  InputLabel:{
    fontSize: '12px',
    fontWeight:400,
  },
  hindText:{
    fontSize:'10px',
    color:'#666',
    fontWeight:400,
    marginLeft:'.5rem'
  },
  stepper:{
    margin:'1rem 0rem'
  },
  subHeading:{
    fontSize:'12px',
    color:'#666',
    fontWeight:400,
    marginBottom:'1rem'
  },
  impMakr:{
    color: 'red'
  },
  }));



const Prerequisite =(props) => {    
    const classes = useStyles();
    const build_strategy=props.build_strategy;
    const request_type = props.request_type;
    const data = props.data;
    const error = props.error;
    const status = props.status;
    const update_data_callback = props.update_data_callback;
    const availableEnvs=build_strategy == "ONCE" && request_type == REQUEST_TYPE_ADD_SERVICE? [properties.envs[0]] : properties.envs;
    const env_list = props.env_list;
    console.log("999999999",env_list,request_type);
    if(env_list && request_type == REQUEST_TYPE_ADD_ENV){
      env_list.forEach(element => {
        element.label = element.env_master.name+"->"+element.env_name ;
      });
    }

    const onChangeHandler = (e) => {
      if(e.target.name=="env_name"){
        if(!e.target.value.includes(" ")){
          update_data_callback(e.target.name,e.target.value.toLowerCase());  
        }
      }else{
        update_data_callback(e.target.name,e.target.value); 
      }
    }
    return(
      <>
      <h3 className="panel-heading">Let's Add an environment to you microservice</h3>
      { status == "GET_REQUESTED"? 
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop> : null
      }
          <FormControl fullWidth>
            
            <Input 
            type="imgCheckbox" 
            name="env_master" 
            label="1. Choose Environment Type"
            mandatorySign
            list={availableEnvs} 
            onChangeHandler={onChangeHandler} 
            data={data} 
            error={error}/>

            <Grid lg={6}>
              <Input
              type="text" 
              placeholder="Name Should be &ldquo;Alpha Numeric&rdquo; and minimum 3 characters required" 
              name="env_name" 
              label="2. Give a name to your enviroment" 
              error={error} 
              onChangeHandler={onChangeHandler} 
              data={data}/>
            </Grid>
            {
              env_list && request_type == REQUEST_TYPE_ADD_ENV ?
              <Grid lg={6}>
                <Input 
                type="select"
                name="env_clone" 
                list={env_list}
                label="3. You can clone from theese availaible Envs."
                onChangeHandler={onChangeHandler} 
                data={data} 
                error={error}/>
              </Grid>
              :null
            }
            

          </FormControl>
      </>
    );
  };


  Prerequisite.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};
  
  export default Prerequisite;
  