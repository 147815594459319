import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CommonHorizontalTab from '../../../../../../components/genericComponents/CommonHorizontalTab';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { GetAuth } from '../../../../../../util/security';
import { Tooltip } from '@mui/material';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';

const AffinityAntiAffinityOverview = props => {
    const prev_state = props.prev_state;
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const [showLoading, setShowLoading] = useState(false);
    const [state, setState] = useState({
        env_cd_node_affinity: [],
        env_cd_pod_affinity: [],
        panel_list: [
            {
                tabName: "Prefrred",
                order: 1
            },
            {
                tabName: "Required",
                order: 2
            },
        ],
        selectedTabOrder: 1,
        selectedTabOrderNode: 1,
        selectedTabOrderPod: 1
    });
    const extraProps = props.extraProps;
    const application_id = extraProps.application_id;
    const service_id = extraProps.service_id;
    const auto_approval_flag = extraProps.auto_approval_flag;
    const component_env_id = extraProps.component_env_id;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);
    useEffect(() => {
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state])


    const updateSelectedTab = (selectedTabOrder) => {
        setState({ ...state, selectedTabOrder: selectedTabOrder });
    }
    const updateSelectedTabNode = (selectedTabOrder) => {
        setState({ ...state, selectedTabOrderNode: selectedTabOrder });
    }
    const updateSelectedTabPod = (selectedTabOrder) => {
        setState({ ...state, selectedTabOrderPod: selectedTabOrder });
    }
    return (

        <div className="section-service-overview">
            <div className="d-flex align-center space-between">
                <div className="overview-heading">
                    Node Affinity / Pod Affinity
                </div>
                {is_permitted ?

                    state.status == "DRAFT" ?
                        <Link
                            to={{
                                pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/cd/${cd_id}/edit`,
                                search: `?selectedTabOrder=6`,
                            }}
                        >
                            <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                        </Link>
                        :
                        <EditDetailsConfirmationDialog
                            application_id={application_id}
                            service_id={service_id}
                            auto_approval_flag={auto_approval_flag}
                            component_env_id={component_env_id}
                            selectedTabOrder={6}
                            cd_data={prev_state}
                            selected_cd_id={extraProps?.selected_cd_data?.id}
                        />

                    : <Tooltip title="You are not allowed to perform this action" >
                        <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                    </Tooltip>}



            </div>

            <div className="overview-card-section grid-template-3">
                <div className="overview-single-card">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Node Affinity
                        </p>
                        {/* {
                                state.env_cd_node_affinity &&
                                    state.env_cd_node_affinity.length > 0 ?
                                    <span className="overview-chip-yes">
                                       Yes
                                    </span>
                                    : <span className="overview-chip-no">
                                        No
                                    </span>
                            } */}
                    </div>
                    <div className="horizontaltab-modifier">
                        <CommonHorizontalTab
                            tabList={state.panel_list}
                            selectedTabOrder={state.selectedTabOrderNode}
                            updateSelectedTab={updateSelectedTabNode}

                        />
                    </div>
                    <div className="overview-card-value-env-var">
                        <div className="heading-key-value three-grd">
                            <p>Key</p>
                            <p>Operator</p>
                            <p>Value</p>
                        </div>
                        {state.selectedTabOrderNode == 2 ?
                            <div className="env-variable-box">
                                {state.env_cd_node_affinity[0] ?
                                    <>
                                        {state.env_cd_node_affinity.map(node => (

                                            node.affinity_choice == "REQUIRED" &&
                                            node.key_value_with_operator.map(hello => (
                                                hello && <div className="data-key-value-pair three-grd">
                                                    <p>{hello.key}</p>
                                                    <p>{hello.operator}</p>
                                                    <p>{hello.value}</p>
                                                </div>
                                            ))

                                        ))
                                        }
                                    </>
                                    :
                                    <p className="no-data__msg-env-var" >No Data Available</p>
                                }
                            </div>

                            :
                            <div className="env-variable-box">
                                {state.env_cd_node_affinity[0] ?

                                    <div className="env-variable-box">
                                        {state.env_cd_node_affinity.map(node => (

                                            node.affinity_choice == "PREFERRED" &&
                                            node.key_value_with_operator.map(hello => (
                                                hello && <div className="data-key-value-pair three-grd">
                                                    <p>{hello.key}</p>
                                                    <p>{hello.operator}</p>
                                                    <p>{hello.value}</p>
                                                </div>
                                            ))

                                        ))
                                        }
                                    </div>


                                    : <p className="no-data__msg-env-var" >No Data Available</p>}
                            </div>
                        }
                    </div>
                </div>
                <div className="overview-single-card">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Service Affinity
                        </p>

                        {/* <span className="overview-chip-no">
                                No
                            </span> */}
                    </div>
                    <div className="horizontaltab-modifier">
                        <CommonHorizontalTab
                            tabList={state.panel_list}
                            selectedTabOrder={state.selectedTabOrderPod}
                            updateSelectedTab={updateSelectedTabPod}

                        />
                    </div>
                    <div className="overview-card-value-env-var">
                        <div className="heading-key-value">
                            <p>Service Name</p>
                            <p></p>
                        </div>
                        {state.selectedTabOrderPod == 2 ?
                            <>
                                {state.env_cd_pod_affinity[0] ?

                                    <div className="env-variable-box">
                                        <div className="data-key-value-pair" style={{ display: 'block' }}>
                                            {state.env_cd_pod_affinity.map(pod => (
                                                pod && pod.affinity_type && pod.affinity_choice == "REQUIRED" && <p>
                                                    <span className="color-dark-grey chip-overview">{pod.component.name}</span>

                                                </p>
                                            ))
                                            }
                                        </div>
                                    </div>


                                    :
                                    <p className="no-data__msg-env-var" >No Data Available</p>

                                }
                            </>

                            :
                            <>
                                {state.env_cd_pod_affinity[0] ?

                                    <div className="env-variable-box">
                                        <div className="data-key-value-pair" style={{ display: 'block' }}>
                                            {state.env_cd_pod_affinity.map(pod => (
                                                pod && pod.affinity_type && pod.affinity_choice == "PREFERRED" && <p>
                                                    <span className="color-dark-grey chip-overview">{pod.component.name}</span>

                                                </p>
                                            ))
                                            }
                                        </div>
                                    </div>


                                    :
                                    <p className="no-data__msg-env-var" >No Data Available</p>

                                }
                            </>
                        }
                    </div>
                </div>
                <div className="overview-single-card">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Service Anti Affinity
                        </p>
                        {/* {
                                state.env_cd_pod_affinity &&
                                    state.env_cd_pod_affinity.length > 0 ?
                                    state.env_cd_pod_affinity.includes
                                    <span className="overview-chip-no">
                                        No
                                    </span>
                                    : <span className="overview-chip-yes">
                                        Yes
                                    </span>
                            } */}
                    </div>
                    <div className="horizontaltab-modifier">
                        <CommonHorizontalTab
                            tabList={state.panel_list}
                            selectedTabOrder={state.selectedTabOrder}
                            updateSelectedTab={updateSelectedTab}

                        />
                    </div>
                    <div className="overview-card-value-env-var">
                        <div className="heading-key-value three-grd">
                            <p>Service Name</p>
                        </div>
                        {state.selectedTabOrder == 2 ?
                            <>
                                {state.env_cd_pod_affinity[0] ?

                                    <div className="env-variable-box">
                                        <div className="data-key-value-pair" style={{ display: 'block' }}>
                                            {state.env_cd_pod_affinity.map(pod => (
                                                pod && !pod.affinity_type && pod.affinity_choice == "REQUIRED" && <p>
                                                    <span className="color-dark-grey chip-overview">{pod.component.name}</span>

                                                </p>
                                            ))
                                            }
                                        </div>
                                    </div>


                                    :
                                    <p className="no-data__msg-env-var" >No Data Available</p>

                                }
                            </>

                            :
                            <>
                                {state.env_cd_pod_affinity[0] ?

                                    <div className="env-variable-box">
                                        <div className="data-key-value-pair" style={{ display: 'block' }}>
                                            {state.env_cd_pod_affinity.map(pod => (
                                                pod && !pod.affinity_type && pod.affinity_choice == "PREFERRED" && <p>
                                                    <span className="color-dark-grey chip-overview">{pod.component.name}</span>

                                                </p>
                                            ))
                                            }
                                        </div>
                                    </div>


                                    :
                                    <p className="no-data__msg-env-var" >No Data Available</p>

                                }
                            </>}
                    </div>

                </div>

            </div>

        </div>

    )
}

AffinityAntiAffinityOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default AffinityAntiAffinityOverview;