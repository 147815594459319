import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { styled } from "@mui/system";

const Root = styled("div")({
  border: "1px solid #b7b7b7",
  overflow: "hidden",
  borderRadius: "4px",
});

const AutoCompleteDropdownV2 = ({
  list = [], // Example: [{ id: 1, label: "React" }, { id: 2, label: "Vue" }]
  selectedValues = [], // Example: [1, 2]
  onChange,
  setting,
  onTextClick,
  placeholder,
  count = 3, // Number of items to display before "+X More"
  useFreeSolo = false, // Flag to enable/disable free solo input
}) => {
  console.log("Selected Values:", selectedValues);
  console.log("Dropdown List:", list);

  // State to track whether extra tags are visible
  const [showAll, setShowAll] = useState(false);

  // ✅ Convert IDs to Labels using a Map (Optimized)
  const idToLabelMap = new Map(list.map((item) => [item.id, item.label]));
  const mapIdsToLabels = (ids) => ids.map((id) => idToLabelMap.get(id) || id);

  // ✅ Add "All" Option to the List (Dropdown Only)
  const allOption = { id: "all", label: "All" };
  const updatedList = [allOption, ...list];

  // ✅ Handles Selection Changes
  const handleOnChange = (event, newValue) => {
    if (newValue.includes("All")) {
      // If "All" is selected, toggle select/deselect all
      onChange(selectedValues.length === list.length ? [] : list.map((item) => item.id));
    } else {
      // Otherwise, map selected labels to IDs
      const selectedIds = newValue.reduce((acc, label) => {
        const matchedOption = list.find((opt) => opt.label === label);
        if (matchedOption) {
          acc.push(matchedOption.id);
        }
        return acc;
      }, []);

      onChange(selectedIds);
    }
  };

  // ✅ Remove tag by ID properly
  const handleDelete = (tagToDelete) => {
    onChange(selectedValues.filter((id) => {
      const label = idToLabelMap.get(id) || id; // Convert ID to label
      return label !== tagToDelete; // Compare against label
    }));
  };

  const handleOnTextClick = (e, data) => {
    const selectedData = list?.find(item => item.label == data)
    console.log(selectedData, 'data_0sddssd')
    onTextClick(selectedData)
  }

  return (
    <Root>
      <Autocomplete
        multiple
        id="tags-filled"
        options={updatedList.map((option) => option.label)} // Pass labels as options
        value={mapIdsToLabels(selectedValues)} // Convert stored IDs back to labels
        onChange={handleOnChange}
        freeSolo={useFreeSolo} // Enable or disable free input
        disableClearable={!useFreeSolo} // Prevents clearing if FreeSolo is off
        renderTags={(value, getTagProps) => {
          const displayedTags = showAll ? value : value.slice(0, count);
          const hiddenCount = value.length - count;

          return (
            <>
              {displayedTags.map((optionLabel, index) => {
                const optionId = list.find((opt) => opt.label === optionLabel)?.id || optionLabel;

                return (
                  <div
                    key={`${optionId}-${index}`} // Ensure unique keys
                    {...getTagProps({ index })}
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      padding: "4px 12px",
                      backgroundColor: "#F5FAFF",
                      border: "1px solid #DFEDFF",
                      color: "#0086FF",
                      fontSize: "12px",
                      fontWeight: "600",
                      borderRadius: "16px",
                      gap: "6px",
                      cursor: "pointer",
                      margin: "4px",
                    }}
                  >
                    {optionLabel}
                    {
                      setting && <Tooltip title="Change Branch and tag">
                        <span onClick={(e) => handleOnTextClick(e, optionLabel)} style={{
                          color: '#0086FF', fontSize: '16px', cursor: 'pointer', fontWeight: "500",
                        }} className="ri-settings-4-line">
                        </span>
                      </Tooltip>
                    }
                    <span
                      className="ri-close-line font-16"
                      style={{ color: "#0086FF", marginLeft: "6px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(optionLabel);
                      }}
                    >
                    </span>
                  </div>
                );
              })}

              {/* Show "+X More" button if there are hidden tags */}
              {hiddenCount > 0 && (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    padding: "4px 12px",
                    backgroundColor: "#DFEDFF",
                    border: "1px solid #0086FF",
                    color: "#0086FF",
                    fontSize: "12px",
                    fontWeight: "600",
                    borderRadius: "16px",
                    cursor: "pointer",
                    margin: "4px",
                  }}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? "− Collapse" : `+${hiddenCount} More`}
                </div>
              )}
            </>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
        filterOptions={(options, params) => {
          const filtered = options.filter((option) =>
            option.toLowerCase().includes(params.inputValue.toLowerCase())
          );

          // Only allow new items if `useFreeSolo` is enabled
          if (useFreeSolo && params.inputValue !== "" && !filtered.includes(params.inputValue)) {
            filtered.push(params.inputValue);
          }

          return filtered;
        }}
      />
    </Root>
  );
};

AutoCompleteDropdownV2.propTypes = {
  list: PropTypes.array, // Expecting [{ id: 1, label: "React" }]
  selectedValues: PropTypes.array, // Expecting [1, 2] (IDs)
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  count: PropTypes.number, // Maximum tags to display before "+X More"
  useFreeSolo: PropTypes.bool, // Flag to enable or disable free solo mode
};

export default AutoCompleteDropdownV2;
