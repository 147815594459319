import React from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";



const ITEM_HEIGHT = 48;
export default function MoreOptionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const OptionList = props.OptionList;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //const application_id = props.application_id;
  const onClickHandler = (elem) => {
    if (elem.text == "View Logs") {
      elem.onClick(props.id, props.task_id, props.action_identifier)
    }
    else {
      elem.onClick(props.id)
    }
  }
  function performDelete(component) {
    const Delete = component;
    return Delete
  }
  return (
    <div>
      <button className="btn btn-transparent"
        aria-label="more"
        aria-controls="long-menu-add-project"
        aria-haspopup="true"
        onClick={handleClick}>
        <i className="ri-more-2-fill font-18" style={{color:'#0096db'}}/>
      </button>
      <Menu
        id="long-menu-add-project"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
          },
        }}
      >
        {OptionList.map(elem => (
          <MenuItem onClick={handleClose} style={{ display: "grid" }}>
            {elem.url && <Link className="d-flex text-anchor-blue cursor-pointer" to={elem.url ? props.name ? elem.url + props.name + "/edit" : elem.url : "#"} >
              <span className='d-flex align-center' style={{ gap: "7px" }}><span className={`${elem.icon}  color-secondary `}></span><span className='font-12 font-weight-500 color-secondary ' style={{ marginRight: "7px" }}>{elem.text}</span></span>
            </Link>}

            {
              elem.onClick && <div className="menu-cont" onKeyDown={() => { }} onClick={() => { onClickHandler(elem) }} tabIndex={0} role="button">{elem.icon} &nbsp;{elem.text}</div>
            }
            {
              elem.text == "Delete" ? <> {elem.component}</> : null

              // performDelete(elem.component)
            }
          </MenuItem>
        ))}

      </Menu>
    </div>
  );
}

MoreOptionMenu.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
