import React from 'react';
import { makeStyles } from '@mui/styles';
import TopHeaderPremium from './TopHeaderPremium';
import { color } from 'highcharts';

const FinalStageQuery = ({onClose}) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TopHeaderPremium onClose={onClose} />
            <div className='middle'>
                <div className='check-container'>
                    <span style={{ color: '#0086FF', fontSize: '38px' }} className='ri-checkbox-circle-fill'>
                    </span>
                </div>
                <div className='thank-text'>
                    Thank you!
                </div>
                <div className='info-text'>
                    Sales team will contact you within 24 working hours
                </div>
            </div>
            <div  className='bottom'>
                <button onClick={()=>onClose()} className='con-sales'>
                    <div className='con-text'>
                        Okay
                    </div>
                </button>
            </div>
        </div>
    )
}

export default FinalStageQuery;

const useStyles = makeStyles({
    root: {
        width: '100%',
        boxShadow: '0px 8px 24px 0px #0000000A',
        background: '#FFFFFF',
        borderRadius: '12px',
        '& .middle': {
            padding: '24px 16px',
            borderBottom: '1px solid #D0D0D0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .check-container': {
                width: '69.71px',
                height: '69.71px',
                padding: '12px',
                borderRadius: '8.57px',
                border: '1.43px',
                background: '#F5FAFF',
                border: '1.43px solid #DFEDFF',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                marginBottom:'24px'
            },
            '& .thank-text': {
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '29.26px',
                textAlign: 'left',
                color: '#2F2F2F',
                marginBottom:'15px'
            },
            '& .info-text': {
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '19.5px',
                textAlign: 'left',
                color: '#2F2F2F',
            },

        },
        '& .bottom': {
            height: '72px',
            padding: '16px 20px 16px 20px',
            '& .con-sales': {
                float: 'right',
                width: '67px',
                height: '40px',
                borderRadius: '7px',
                background: '#0086FF',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .con-text': {
                color: '#fff',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '14.63px',
                textAlign: 'left',
                textTransform: 'uppercase',
                marginLeft: '5px'
            }
        },
        '& .premium-op': {
            color: '#FDAF36',
            fontSize: '15.59px',
            fontWeight: '700',
            lineHeight: '19px',
            textAlign: 'center',
            textTransform: 'uppercase'
        }
    }
});

