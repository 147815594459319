import React from 'react';
import PropTypes from 'prop-types';
import properties from '../properties/properties';
import { GetCluster } from './GlobalVars';
import { EDIT_JOB } from '../views/pipeline/add/components/StageForm';
import moment from 'moment';
import GenerateURL from './APIUrlProvider';
import { PostData } from './apiInvoker';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';


export function RemoveFromArray(arr) {
    console.log('from_remove_function', arr)

    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr?.length) {
        what = a[--L];
        while ((ax = arr?.indexOf(what)) !== -1) {
            arr?.splice(ax, 1);
        }
    }
    return arr;
}

export function validateEmailAdressFormat(emailAddress) {
    var emailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    if (emailAddress && emailRegex.test(emailAddress)) {
        return true
    }
    return false;
}
export function validateJsonObjFormat(jsonObj) {
    try {
        JSON.parse(jsonObj);
    } catch (e) {
        return false;
    }

    return true;

}

export const EnvLabel = (env_id) => {
    var envs = properties.envs;
    var label = "";
    envs.forEach(env => {
        if (env.id == env_id) {
            label = env.label;
        }
    });
    return label;
}


export const ClusterName = (cluster_id) => {

    return (GetCluster(cluster_id)) ? (GetCluster(cluster_id)).name : "";
}

export const GetInfraProviderId = (infra_label) => {
    var infras = properties.infra_provider;
    var id = "";
    infras.forEach(infra => {
        if (infra.label == infra_label) {
            id = infra.id;
        }
    });
    return id;
}

export const EnvId = (env_label) => {
    var envs = properties.envs;
    var id = 0;
    envs.forEach(env => {
        if (env.label.toLocaleLowerCase() == env_label.toLocaleLowerCase()) {
            id = env.id;
        }
    });
    return id;
}

export function FormatTime(time_in_miliseconds) {
    var time_in_seconds = time_in_miliseconds / 1000;
    if (time_in_seconds < 60) {
        return Math.round(time_in_seconds) + " sec";
    }

    var time_in_minutes = time_in_seconds / 60;
    if (time_in_minutes < 60) {
        return Math.round(time_in_minutes) + " min";
    }

    var time_in_hour = time_in_minutes / 60;
    if (time_in_hour < 24) {
        return Math.round(time_in_hour) + " hr";
    }
}

export function GetTaskTypeIdFromString(task_type) {
    switch (task_type.toLowerCase()) {
        case "build":
            return 1;
        case "deploy":
            return 2;
        case "promote":
            return 3;
        case "api_call":
            return 5;
        case "configmaps":
            return 6 ;
        case "secrets":
            return 7;
        case "jira":
            return 10;
        case "rollback":
            return 11;
        case "integration":
            return 12;
        case "pipeline_invoke":
            return 13;
        case "delay_job":
            return 14
        case "canary_analysis":
            return 15
    }
}

export function GetTaskTypeStringFromId(task_type_id) {
    console.log(task_type_id, "skjvbhsvbhfshfb")
    switch (task_type_id) {
        case 1:
            return "build";
        case 2:
            return "deploy";
        case 3:
            return "promote";
        case 5:
            return "api_call";
        case 6:
            return "configmaps";
        case 7:
            return "secrets";
        case 10:
            return "jira";
        case 11:
            return "rollback";
        case 12:
            return "integration";
        case 13:
            return "pipeline_invoke";
        case 14:
            return "delay_job";
        case 15:
            return "canary_analysis";
        case 17:
            return "attach_documents";
        case 18:
            return "database_upgrade";
        case 16:
            return "snow_integration_job";
    }
}

export function getListableArray(arrayList) {
    console.log(arrayList, "fdsafsafa")
    var listableElements = [];
    if (arrayList && arrayList.length != 0) {
        arrayList.forEach(element => {
            listableElements.push({
                id: element.id,
                label: element.name
            });
        });
    }
    console.log(listableElements)
    return listableElements;
}
export const getLimitFromUrl = (url) => {
    if(url){
        const queryParams = new URL(url).searchParams;
        return Number(queryParams.get('limit')) || 10;
    }
    return 10;
}
export function parseResourceQuotaForReact(resource_quota) {
    var new_request_quota = { id: resource_quota.id }

    if (resource_quota.requests_memory_quota) {
        var requests_memory_quota = resource_quota.requests_memory_quota
        new_request_quota.requests_memory_quota = requests_memory_quota.substring(0, requests_memory_quota.length - 2)
        new_request_quota.requests_memory_quota_unit = requests_memory_quota.substring(requests_memory_quota.length - 2) == "Gi" ? "1" : "2"
    }

    if (resource_quota.limits_memory_quota) {
        var limits_memory_quota = resource_quota.limits_memory_quota
        new_request_quota.limits_memory_quota = limits_memory_quota.substring(0, limits_memory_quota.length - 2)
        new_request_quota.limits_memory_quota_unit = limits_memory_quota.substring(limits_memory_quota.length - 2) == "Gi" ? "1" : "2"
    }

    if (resource_quota.requests_cpu_quota) {
        var requests_cpu_quota = resource_quota.requests_cpu_quota
        new_request_quota.requests_cpu_quota = requests_cpu_quota.substring(0, requests_cpu_quota.length - 1)
    }

    if (resource_quota.limits_cpu_quota) {
        var limits_cpu_quota = resource_quota.limits_cpu_quota
        new_request_quota.limits_cpu_quota = limits_cpu_quota.substring(0, limits_cpu_quota.length - 1)
    }

    return new_request_quota
}

export function sortReplicasets(replicasets, sortOrder) {
    var comparator = sortOrder == "latest_first" ? -1 : 1

    if (replicasets.length < 2) {
        return replicasets;
    } else {
        replicasets.sort((replicaset1, replicaset2) => {
            var revision1 = parseInt(replicaset1.metadata.annotations["deployment.kubernetes.io/revision"])
            var revision2 = parseInt(replicaset2.metadata.annotations["deployment.kubernetes.io/revision"])
            if (revision1 == revision2) {
                var generation1 = parseInt(replicaset1.metadata.generation)
                var generation2 = parseInt(replicaset2.metadata.generation)
                return generation1 > generation2 ? comparator : -1 * comparator
            }
            return revision1 > revision2 ? comparator : -1 * comparator
        })
    }
    replicasets[replicasets.length - 1].latest_replicaset = true
    return replicasets;
}

export function getPodStatusColor(pod) {
    if (pod.pod_status) {
        var status = pod.pod_status.status
        if (status == "Ready") return "green"
        if (["Failed", "Succeeded", "Unknown"].includes(status)) return "grey"
        if (status == "Error_In_Container") return "red"
        if (status == "Containers_Not_Ready") return "yellow"
        if (status == "Terminating") return "blue"

    } else {
        return "grey"
    }
    return "grey"
}

export function getPodStatus(pod) {
    var pod_status = { status: "unknown", message: "unknown" }
    var podPhase = pod.status.phase

    //Possible value of PodPhase: Running, Pending, Failed, Succeeded, Unknown
    if (["Failed", "Succeeded", "Unknown"].includes(podPhase)) {
        pod_status = { status: podPhase, message: "Please refer to Events and Pod YAML for more details" }
        pod.pod_status = pod_status
        return
    }

    if (podPhase == "Running" && pod.metadata.deletionTimestamp) {
        pod_status = { status: "Terminating", message: "Pod is scheduled for Termination" }
        pod.pod_status = pod_status
        return
    }
    if (podPhase == "Running") {
        var podReadyCondition = getPodConditionForContionType(pod, 'Ready')
        if (podReadyCondition.status == "True") {
            pod_status = { status: "Ready", message: "Pod is healthy and running" }
            pod.pod_status = pod_status
            return
        }
    }

    if (podPhase == "Pending" && pod.status.conditions.length == 1 && pod.status.conditions[0].reason == "Unschedulable") {
        pod_status = { status: "Insufficient Space, cannot create pods", message: pod.status.conditions[0].message, containers_status: null }
        pod.pod_status = pod_status
        return
    }

    var containers_ready_condition = getPodConditionForContionType(pod, 'ContainersReady')
    if (containers_ready_condition && containers_ready_condition.status == "False") {
        var containers_status = getContainersStatus(pod.status.containerStatuses)
        pod_status = { status: containers_status.error_found ? "Error_In_Container" : "Containers_Not_Ready", message: "Refer to Container Statuses for Details", containers_status: containers_status }
        pod.pod_status = pod_status
    }

}

function getContainersStatus(containers_status) {
    var processed_container_status = { error_found: false }
    if (containers_status) {
        containers_status.forEach(container_status => {
            var container_name = container_status.name
            if (!container_status.ready) {
                if (container_status.state.running) {
                    handleTerminatedLastState(container_status, processed_container_status, container_name)
                } else if (container_status.state.waiting) {
                    handleWaitingStatus(container_status, processed_container_status, container_name)
                } else if (container_status.state.terminated) {
                    processed_container_status[container_name] = { status: "Terminated", message: "Container In Terminated State" }
                }
            } else {
                processed_container_status[container_name] = { status: "Ready", message: "Ready" }
            }
        })
    }
    return processed_container_status
}

function handleTerminatedLastState(container_status, processed_container_status, container_name) {

    if (container_status.last_state && container_status.last_state.terminated) {
        var terminated_data = container_status.last_state.terminated;

        var data_handled = false;
        if (terminated_data.exit_code == 137 && terminated_data.reason == "OOMKilled") {
            var status = "Exit Code: 137 OOMKilled";
            var message = "Out of memory can be a reason for failure and container restart. Please tweak resource quota."
            processed_container_status[container_name] = { status: status, message: message, err_code: "OOMKilled", restart_count: container_status.restart_count };
            processed_container_status.error_found = true
            data_handled = true
        }

        if (terminated_data.exit_code == 1) {
            var status = "Exit Code: 1 - Could be due Application Error";
            var message = "Please refer to container logs and pod events to check why application is crashing"
            processed_container_status[container_name] = { status: status, message: message, error_code: "EXIT_CODE_1", restart_count: container_status.restart_count };
            processed_container_status.error_found = true
            data_handled = true
        }

        if (terminated_data.exit_code == 137 && terminated_data.reason != "OOMKilled") {
            var status = "Exit Code: 137 Reason:" + terminated_data.reason;
            var message = "Out of memory can be a reason for failure and container restart. Please tweak resource quota."
            processed_container_status[container_name] = { status: status, message: message, restart_count: container_status.restart_count };
            processed_container_status.error_found = true
            data_handled = true
        }

        //This is catch all section, specific checks should be above this section
        if (!data_handled) {
            var status = terminated_data.exit_code ? "Exit Code: " + terminated_data.exit_code : "No Exit Code. "
                + terminated_data.reason ? "Reason: " + terminated_data.reason : "Termination Reason Unknown"
            var message = terminated_data.message ? "Message: " + terminated_data.message : "No Message Available"
            processed_container_status[container_name] = { status: status, message: message, restart_count: container_status.restart_count };
        }
        //No logic should go here, all checks should be above the if(!data_handled) check

    } else {
        processed_container_status[container_name] = { status: "Unknown", message: "Unknown", restart_count: container_status.restart_count };
    }
}


function handleWaitingStatus(container_status, processed_container_status, container_name) {
    var waiting_data = container_status.state.waiting
    if (waiting_data) {
        var data_handled = false
        if (waiting_data.reason == "ImagePullBackOff" || waiting_data.reason == "ErrImagePull") {
            var status = waiting_data.reason + " :Unable to pull Image"
            var message = waiting_data.message ? waiting_data.message : "No Message available"
            processed_container_status[container_name] = { status: status, message: message, error_code: "IMAGE_PULL_ERROR", restart_count: container_status.restart_count };
            processed_container_status.error_found = true
            data_handled = true
        }
        if (waiting_data.reason == "CrashLoopBackOff") {
            var status = "CrashLoopBackOff: Application Failure or Liveness,Readiness Probe Failure"
            var message = waiting_data.message ? waiting_data.message : "No Message available"
            processed_container_status[container_name] = { status: status, message: message, error_code: "CONTAINER_CRASH", restart_count: container_status.restart_count }
            processed_container_status.error_found = true
            data_handled = true
        }
        if (!data_handled) {
            var status = waiting_data.reason ? "Waiting container to be ready: " + waiting_data.reason : "Waiting for container to start: Status unknown"
            var message = waiting_data.message ? "" + waiting_data.message : "Pleae refer to events to see reason for Waiting state"
            processed_container_status[container_name] = { status: status, message: message, restart_count: container_status.restart_count }
        }
    }
}

function getPodConditionForContionType(pod, conditionType) {
    var podCondition = null
    conditionType = conditionType ? conditionType : 'Ready'
    pod.status.conditions.forEach(condition => {
        if (condition.type == conditionType) {
            podCondition = condition
        }
    })
    return podCondition
}

export function getReplicasetName(pod) {
    var name = null
    pod.metadata.ownerReferences.forEach(owner_reference => {
        if (owner_reference.kind = "ReplicaSet") {
            name = owner_reference.name
        }
    })
    return name
}

export function getStageTaskRunConditionsList(current_job_index, stages, stage_index) {
    var conditions = []
    if (stages) {
        for (let loop_stage_index = 0; loop_stage_index <= stage_index; loop_stage_index++) {
            var stage = stages[loop_stage_index]
            if (loop_stage_index == stage_index) {
                for (let job_index = 0; job_index < current_job_index; job_index++) {
                    var condition_display = "Was Job " + stage.task[job_index].name + " of Stage " + stage.name + " Successful"
                    var condition = "stage." + stage.name + ".job." + stage.task[job_index].name + ".success"
                    conditions.push({ label: condition_display, id: condition })
                }
            } else {
                if (stage.task) {
                    for (let job_index = 0; job_index < stage.task.length; job_index++) {
                        var condition_display = "Was Job " + stage.task[job_index].name + " of Stage " + stage.name + " Successful"
                        var condition = "stage." + stage.name + ".job." + stage.task[job_index].name + ".success"
                        conditions.push({ label: condition_display, id: condition })
                    }
                }
            }
        }
    }
    return conditions;
}
export function getStageRunConditionsAndJiraRefList(stages, stage_index, current_stage_name) {

    if (stages && stage_index > 0) {
        var prev_stage_index = stage_index - 1
        var no_of_jobs_prev_stage = stages[prev_stage_index] && stages[prev_stage_index].task ? stages[prev_stage_index].task.length : 0
        return getRunConditionsAndJiraRefList(no_of_jobs_prev_stage, stages, prev_stage_index, EDIT_JOB, current_stage_name)
    }

    return { conditions: [], jira_id_reference_keys: [],snow_id_reference_keys:[] }
}
export function getRunConditionsAndJiraRefList(current_job_index, stages, stage_index, varient, current_stage_name) {
    console.log("jnsjna",current_job_index, stages, stage_index, varient, current_stage_name);
    var conditions = [];
    var jira_id_reference_keys = [];
    var snow_id_reference_keys = [];
    if (stages) {
        for (let loop_stage_index = 0; loop_stage_index <= stage_index; loop_stage_index++) {
            var stage = stages[loop_stage_index]
            if (loop_stage_index == stage_index && varient == EDIT_JOB) {
                for (let loop_job_index = 0; loop_job_index < current_job_index; loop_job_index++) {
                    addConditionsAndJiraReferenceKey(loop_job_index, stage, conditions, jira_id_reference_keys,snow_id_reference_keys ,current_stage_name)
                }
            } else {
                if (stage.task) {
                    for (let job_index = 0; job_index < stage.task.length; job_index++) {
                        addConditionsAndJiraReferenceKey(job_index, stage, conditions, jira_id_reference_keys,snow_id_reference_keys ,current_stage_name)
                    }
                }
            }
        }
    }
    return { conditions: conditions, jira_id_reference_keys: jira_id_reference_keys ,snow_id_reference_keys:snow_id_reference_keys };
}

function getJobName(job_index) {
    return "job_" + (job_index + 1)
}


function addConditionsAndJiraReferenceKey(job_index, stage, conditions, jira_id_reference_keys,snow_id_reference_keys, current_stage_name) {
    console.log("djsjsjdnjs",job_index, stage, conditions, jira_id_reference_keys, current_stage_name);
    var job_name = stage.task[job_index].name ? stage.task[job_index].name : getJobName(job_index)
    var ticketing_project = stage.task[job_index].ticketing_project ? stage.task[job_index].ticketing_project : null;
    var condition_display = "Was Job " + job_name + " of Stage " + stage.name + " Successful"
    var condition = "stage." + stage.name + ".job." + job_name + ".success"
    var stage_condition = "Was stage " + stage.name + " approved"
    var stage_condition_id = "stage." + stage.name + ".approved"
    conditions.push({ label: condition_display, id: condition }, { label: stage_condition, id: stage_condition_id })
    if (stage.task && stage.task[job_index].task_type == 10 && stage.task[job_index].operation == "create") {//create is task type for create JIRA
       
        var jira_reference_key = "stage." + stage.name + ".job." + job_name + ".jira_id.key";
        if(ticketing_project){
            jira_reference_key  = jira_reference_key + ".project."+ticketing_project
        }
            
        if (jira_reference_key.includes(current_stage_name)) {

        } else {
            jira_id_reference_keys.push({ label: jira_reference_key, id: jira_reference_key })
        }
    }

    if(stage.task && stage.task[job_index].task_type == 16 && stage.task[job_index].snow_operation == "snow_create"){
        var snow_reference_key = "stage." + stage.name + ".job." + job_name + ".servicenow_id.key"
        console.log("ndjsjdnesjer",snow_reference_key.includes(current_stage_name),snow_reference_key);
        if (snow_reference_key.includes(current_stage_name)) {

        } else {
            snow_id_reference_keys.push({ label: snow_reference_key, id: snow_reference_key })
        }
    }
}
export var CopyToClipboard = (props) => {
    const data = props.data ? props.data : "";
    const variant = props.variant ? props.variant : null;

    // Function to handle copy with fallback
    const handleCopy = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(data)
                .then(() => {
                    console.log("Text copied to clipboard!");
                    // You can add any success feedback logic here, like a toast or tooltip
                })
                .catch(err => {
                    console.error("Failed to copy text: ", err);
                });
        } else {
            // Fallback for older browsers
            const textArea = document.createElement("textarea");
            textArea.value = data;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const successful = document.execCommand('copy');
                const msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    };

    return (
        <>
            {
                variant === "COPY_BUTTON" ? 
                    <button 
                        onClick={handleCopy} 
                        className='integration-checklist__copy-button btn btn-outline-primary d-flex align-center space-between copybutton'>
                        <span className='ri-file-copy-line font-16'></span>&nbsp;COPY TEXT
                    </button>
                :
                    <div className="copy-to-clipboard">
                        <button 
                            onClick={handleCopy} 
                            className="integration-checklist__copy-button" 
                            style={{ display: props.display }}>
                            <span className="ri-file-copy-line font-18" style={{color:'#0086ff'}}></span>
                        </button>
                    </div>
            }
        </>
    );
};

CopyToClipboard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

//date1 = current date ,date2 = date of creation of activity(created_at)
export function getStartedDuration(date1, date2) {

    const diffInMs = Math.abs(date2 - date1);
    var remainingSecs = (diffInMs / 1000);

    var day = Math.trunc(remainingSecs / (24 * 3600));
    var hr = Math.trunc(remainingSecs / 3600);
    var mins = Math.trunc((remainingSecs - (hr * 3600)) / 60);
    var sec = Math.trunc(remainingSecs - (hr * 3600) - (mins * 60));
    var remainingHoursInDay = Math.abs((new Date(date1).getHours() - (new Date(date2).getHours())));
    var remainingMinutes;
    //var remainingMinutes = new Date(date1).getMinutes() - (new Date(date2).getMinutes());
    var sentence = "";
    var curHour = new Date(date1).getHours();
    var activityCreatedHours = new Date(date2).getHours();
    if (activityCreatedHours > curHour) {
        remainingHoursInDay = 24 - (activityCreatedHours - curHour);
    }
    var currMinutes = new Date(date1).getMinutes();
    var activityCreatedMinutes = new Date(date2).getMinutes();
    if (currMinutes > activityCreatedMinutes) {
        remainingMinutes = currMinutes - activityCreatedMinutes;
    } else {
        remainingMinutes = activityCreatedMinutes - currMinutes;
    }

    if (day) {
        if (hr > 24) {
            sentence = sentence.concat(`${day} d `);
        }
        if (remainingHoursInDay > 0) {
            sentence = sentence.concat(`${remainingHoursInDay} h `);
        }
        if (remainingMinutes > 0) {
            sentence = sentence.concat(`${remainingMinutes} m `);
        }
        if (sec > 0) {
            sentence = sentence.concat(`${sec} s `);
        }
    } else {
        if (hr > 0) {
            sentence = sentence.concat(`${hr} h `);
        }
        if (mins > 0) {
            sentence = sentence.concat(`${mins} m `);
        }
        if (sec > 0) {
            sentence = sentence.concat(`${sec} s `);
        }
    }
    return sentence;

}
export function getDuration(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    console.log("diffInMs", diffInMs)
    if (diffInMs > 1) {
        var remainingSecs = (diffInMs / 1000);
        var hr = Math.trunc(remainingSecs / 3600);
        var mins = Math.trunc((remainingSecs - (hr * 3600)) / 60);
        var sec = Math.trunc(remainingSecs - (hr * 3600) - (mins * 60));
        var sentence = "";

        if (hr > 0) {
            sentence = sentence.concat(`${hr} h `);
        }
        if (mins > 0) {
            sentence = sentence.concat(`${mins} m `);
        }
        if (sec > 0) {
            sentence = sentence.concat(`${sec} s `);
        }
        if(sentence.length > 0){
            return sentence;
        }else{
            return '0s';
        }
       
    } else {
        return diffInMs + " s"
    }


    // var sentence = `${hr} h ${mins} m ${sec} s`

}

export function reverseActivityDetails(activityDetail) {
    console.log(activityDetail, 'ac_v001')
    var detailsList = activityDetail.activity_sub_task ? activityDetail.activity_sub_task.sub_task_name : activityDetail.job_step.name
    var listItems = []
    console.log(detailsList, 'detail_list==>')
    listItems.push(detailsList)
    return listItems;
}
export function reverseActivityDetailsDuration(activityDetail) {
    var created_at_date = moment(activityDetail.created_at);
    var updated_at_date = moment(activityDetail.updated_at);
    var totalDuration = moment.duration(updated_at_date.diff(created_at_date))
    var seconds = totalDuration.seconds();
    var minutes = totalDuration.minutes();
    var durationList = [minutes + "m" + " " + seconds + "" + "s"]
    var listItems = []
    for (var i in durationList) {
        listItems.push(durationList[i])
    }
    return listItems;
}

const getCorrectAgentName = (name) => {

    switch (name) {
        case 'admin_deploy_api':
            return 'Admin Deploy Api';
        case 'admin_public_api':
            return 'Admin Public Api';
        case 'bp_agent_build':
            return 'Build Agent';
        case 'bp_agent_deploy':
            return 'Deploy Agent';
        case 'deploy_api':
            return 'Deploy API';
        case 'public_api':
            return 'Public API';
        case 'scheduler':
            return 'Scheduler';
        default:
            return name;
    }

}
export function evaluateAgentStatus(data, target) {

    const bpStatus = localStorage.getItem('bp_agent_status')
    // console.log(bpStatus,"sdbnksdbchbsdh")
    let sourcData
    if (bpStatus) {
        const bpAgentStatus = JSON.parse(bpStatus);
        console.log(bpAgentStatus, "sdbnksdbchbsdh")
        if (target === 'build' || target === 'deploy') {
            let isBuildAgentDown = false;
            let isDeployAgentDown = false;
            let build_agents_health = [];
            // suspense
            Object.keys(bpAgentStatus).forEach((key, index) => {
                let agentData = bpAgentStatus[key];
                let nameOfAgent = key;
                let indexOfPartition = nameOfAgent.indexOf('@');
                let updatedString = nameOfAgent.substring(0, indexOfPartition);
                if (updatedString === 'bp_agent_build') {
                    build_agents_health.push(agentData['health_status'])
                }
                if (agentData['health_status'] === 'unhealthy' || agentData['health_status'] === 'suspense') {
                    let nameOfAgent = key;
                    let indexOfPartition = nameOfAgent.indexOf('@');
                    let updatedString = nameOfAgent.substring(0, indexOfPartition);
                    // if (updatedString === 'bp_agent_build') {
                    //     isBuildAgentDown = true;
                    // }
                    if (updatedString === 'bp_agent_deploy') {
                        isDeployAgentDown = true;
                    }
                }
            })

            if (build_agents_health.includes("healthy")) {
                isBuildAgentDown = false;
            }

            switch (target) {
                case 'build':
                    return { type: isBuildAgentDown ? 'unHealthy' : 'healthy', names_of_down_agents: 'Build Agent' };
                case 'deploy':
                    return { type: isDeployAgentDown ? 'unHealthy' : 'healthy', names_of_down_agents: 'Deploy Agent' };
            }

        }
        else {
            if (data) {
                sourcData = data?.integration_health_status

            }
            else {
                sourcData = bpAgentStatus
            }
            if (sourcData) {
                console.log(sourcData, 'source_data_0023')
                let isUnhealthy = false;
                let isInSuspense = false;
                let namesOfDownAgents = '';
                let countOfDownAgents = 0;
                let countOfAgentsInSuspense = 0;
                let countOfAllAgents = Object.keys(sourcData).length
                let nameOfAgentsInSuspense = ''

                Object.keys(sourcData).forEach((key, index) => {
                    let agentData = sourcData[key];
                    if (agentData['health_status'] === 'unhealthy') {
                        isUnhealthy = true;
                        countOfDownAgents++;
                        let nameOfAgent = key;
                        let indexOfPartition = nameOfAgent.indexOf('@');
                        let updatedString = nameOfAgent.substring(0, indexOfPartition);
                        updatedString = getCorrectAgentName(updatedString);
                        if (index === countOfAllAgents - 1) {
                            namesOfDownAgents += updatedString
                        }
                        else
                            namesOfDownAgents += updatedString + ", "
                    }
                })

                if (!isUnhealthy) {
                    Object.keys(sourcData).forEach((key, index) => {
                        let agentData = sourcData[key];
                        if (agentData['health_status'] === 'suspense') {
                            isInSuspense = true;
                            countOfAgentsInSuspense++;
                            let nameOfAgent = key;
                            let indexOfPartition = nameOfAgent.indexOf('@');
                            let updatedString = nameOfAgent.substring(0, indexOfPartition);
                            updatedString = getCorrectAgentName(updatedString);
                            if (index === countOfAllAgents - 1) {
                                namesOfDownAgents += updatedString
                            }
                            else
                                namesOfDownAgents += updatedString + ", "
                        }
                    })
                }

                if (countOfDownAgents == countOfAllAgents) {
                    console.log('condition working==>');
                    namesOfDownAgents = 'All'
                }

                if (isUnhealthy) {
                    return { type: 'unHealthy', names_of_down_agents: namesOfDownAgents }

                }

                else if (isInSuspense) {
                    return { type: 'inSuspense', names_of_down_agents: namesOfDownAgents }
                }

                else {
                    return { type: 'healthy' }
                }
            }

        }
    }
}


export const SnackbarComponent = (props) => {
    const request_hit_status = props.request_hit_status ? props.request_hit_status : null;
    const handleClose = props.handleClose ? props.handleClose : () => { };
    const failed_request_hit_msg = props.failed_request_hit_msg ? props.failed_request_hit_msg : "Unable to save data";
    const request_failed_generic_msg = failed_request_hit_msg['detail'] ? failed_request_hit_msg['detail'] : null;
    const request_failed_generic_error = failed_request_hit_msg['error'] ? failed_request_hit_msg['error'] : null;
    const success_request_hit_msg = props.success_request_hit_msg ? props.success_request_hit_msg : "Data Save Successfully";
    const autoHideDuration = props.autoHideDuration ? props.autoHideDuration : 6000;
    return (
        <Snackbar style={{ marginTop: '-56px', }}
            anchorOrigin={{
                vertical: 'top', horizontal: 'right'
            }}
            open={request_hit_status === "success" || request_hit_status === "failed"}
            onClose={handleClose}
            TransitionComponent={'SlideTransition'}
            autoHideDuration={autoHideDuration}>
            {
                request_hit_status === "success" ?

                    <Alert severity="success" variant="filled">
                        <span style={{ color: '#fff' }} >{success_request_hit_msg}</span>
                        &nbsp;&nbsp;&nbsp;<span onKeyDown={() => { }} onClick={handleClose} tabIndex={0} role='button'><i className="ri-close-line" style={{ marginBottom: '-3px', color: '#fff' }} /></span>
                    </Alert>
                    : request_hit_status === "failed" ?
                        <Alert severity="error" variant="filled">
                            <div className="error-div d-flex align-center space-between">

                                {request_failed_generic_msg ?
                                    request_failed_generic_msg :
                                    request_failed_generic_error ?
                                        request_failed_generic_error :
                                        failed_request_hit_msg ?
                                            typeof (failed_request_hit_msg) != "object" ?
                                                <span>{failed_request_hit_msg}</span> :
                                                <span>{
                                                    Object.keys(failed_request_hit_msg).map(item => {
                                                        return <>
                                                            <span>{item}:</span>
                                                            <span>{
                                                                failed_request_hit_msg[item] && failed_request_hit_msg[item].length > 0 ?
                                                                    failed_request_hit_msg[item].map(single_err => {
                                                                        return <span>{single_err}</span>
                                                                    }) : "Unable to save data"}</span><br />
                                                        </>
                                                    })}
                                                </span>
                                            : null
                                }
                                &nbsp;&nbsp;&nbsp;<span onKeyDown={() => { }} onClick={handleClose} tabIndex={0} role='button'><i className="ri-close-line" style={{ marginBottom: '-3px', color: '#fff' }} /></span>
                            </div>
                        </Alert>

                        : null
            }
        </Snackbar>

    )
}


SnackbarComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export const showErrorHandler = (error_data) => {
    var errorMessage = '';
    for (var key in error_data) {
        var errorArray = error_data[key];
        for (var i = 0; i < errorArray.length; i++) {
            var errorItem = errorArray[i];
            if (Array.isArray(errorItem)) {
                errorMessage += key + ': ' + errorItem.join('. ') + ' ';
            } else if (typeof errorItem === 'object') {
                for (var nestedKey in errorItem) {
                    var nestedErrors = errorItem[nestedKey];
                    errorMessage += key + ': ' + nestedKey + ': ' + nestedErrors.join('. ') + ' ';
                }
            } else if (typeof errorItem === 'string') {
                errorMessage += key + ': ' + errorItem + ' ';
            }
        }
    }
    return errorMessage;
}

export const getStartEndDateAccordingToTimeOrder = (order) => {

    let startDate = null
    let today = new Date()

    if (order == 1) {

        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
    }

    else if (order == 2) {
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
    }

    else if (order == 3) {
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
    }

    else if (order == 4) {
        startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())

    }

    let endDate = moment(new Date(today.getFullYear(), today.getMonth(), today.getDate())).format('YYYY-MM-DD')

    return {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: endDate
    }
}
export const showErrorHandlerUpdated = (error_data) => {
    console.log(error_data, "Fdsjfnsjnajfjksajfdsa")
    const errorMessageArray = [];
    console.log(error_data, "error_data")
    if (typeof error_data === "string") {
        return error_data;
    } else {
        for (const key in error_data) {

            const errorItem = error_data[key];

            if (key === "url" && typeof errorItem === "string") {
                // Return only the content of the "error" key if "url" is present
                return error_data["error"] || '';
            } else {
                if (Array.isArray(errorItem)) {
                    errorMessageArray.push(`${key}: ${errorItem.join('. ')}`);
                } else if (typeof errorItem === 'object') {
                    for (const nestedKey in errorItem) {
                        const nestedErrors = errorItem[nestedKey];
                        errorMessageArray.push(`${key}: ${nestedKey}: ${nestedErrors.join('. ')}`);
                    }
                } else if (typeof errorItem === 'string') {
                    console.log(errorItem, "errorMessageArray")
                    errorMessageArray.push(`${key}: ${errorItem}`);
                }
            }
        }
    }
    return errorMessageArray.join(' ');
}

export function closeLogsFromBackend(requestId) {

    if (requestId) {
        let postData = { request_id: requestId, status: false };

        const endPoint = GenerateURL({}, properties.api.closed_sse_from_backend);

        PostData(endPoint, postData, (response) => {

            if (response) {
                console.log(response, 'resp_23322323')
            }
        },
            (error) => { console.log('dsssa444', error) });
    }
}

export function formatDateTime(dateTimeString,format) {
    if(!dateTimeString){
        return "NA";
    }
    const formattedDateTime = moment(dateTimeString).format(format || "MMM DD, YYYY hh:mm A");
    return formattedDateTime;
  }
