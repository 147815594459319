import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import GenerateURL from '../../../util/APIUrlProvider';

const applicationOptionsList = [
    {
        key: "service",
        label: "Service Overview",
        link: "application/${application_id}/service/listing",
        icon: null,
    },
    {
        key: "pipeline",
        label: "Pipeline Overview",
        link: "application/${application_id}/pipeline/listing",
        icon: null,
    },
    {
        key: "env",
        label: "Environment Overview",
        link: "application/${application_id}/env/list",
        icon: null,
    },
    {
        key: "jobtemplates",
        label: "Job Templates",
        link: "application/${application_id}/job-templates",
        icon: null,
    },
    {
        key: "build_deploy",
        label: "Build / Deploy",
        link: "application/${application_id}/build_deploy",
        icon: null,
    },
    {
        key: "canarytemplates",
        label: "Canary Templates",
        link: "application/${application_id}/canary-templates",
        icon: null,
    },
    {
        key: "manifest_placeholders",
        label: "Manifest Placeholder",
        link: "application/${application_id}/manifest-placeholder",
        icon: null,
    }
]

const ApplicationResultItem = ({searchedQuery,appName,name,entityObj,onClose}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const getHighlightedText = (text, highlight) => {
          if (!highlight) return text; 
          
          const parts = text.split(new RegExp(`(${highlight})`, 'gi')); 
          return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={index} style={{ textDecoration: 'normal',fontWeight:'700' }}>
                {part}
              </span>
            ) : (
              part
            )
          );
    };
      return (
            <Root className='item'>
                <div className='app-name'>
                    <span className='ri-apps-line font-16 line-height-normal color-tertiary'></span>
                    <span className='font-12 font-weight-500 color-primary'>{getHighlightedText(name,searchedQuery)}</span>
                </div>
                <div className='option-box' style={{display:`${open ? "inline-block": ""}`}}>
                    <OptionList application_id={entityObj.id} handleParentClose={onClose} anchorEl={anchorEl} open={open} handleClick={handleClick} handleClose={handleClose} />
                </div>
            </Root>
      )
}

const OptionList = ({open,anchorEl,handleClick,handleClose,application_id,handleParentClose}) => {
    
    return (
        <>
            <span onClick={handleClick} style={{verticalAlign:'bottom'}} className='cursor-pointer color-link font-12 font-weight-500'>
                GO TO <span className='ri-arrow-right-s-line font-16 color-link'></span>
            </span>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                {applicationOptionsList.map((obj,_)=> {
                    let link = GenerateURL({application_id:application_id},obj.link,true);
                    return (
                        <Link to={link} onClick={handleParentClose}>
                            <MenuItem style={{ display: "grid",padding:'6px 8px' }}>
                                    <span className='font-12 font-weight-500 color-tertiary'>{obj.label}</span>
                            </MenuItem>
                        </Link>
                    )
                })}
            </Menu>
        </>
    )
}

export default ApplicationResultItem;

const Root = styled('div')({
    padding:'12px',
    display:'flex',
    gap:'4.5px',
    height:'40px',
    borderBottom:'1px solid #E6E6E6',
    justifyContent:'space-between',
    alignItems:'center',
    '&:hover':{
        backgroundColor:'#FAFAFA',
        borderRadius:'6px',
        '& .option-box':{
            display:'inline-block',
        },
    },
    '& .app-name':{
        display:'flex',
        alignItems:'center',
        gap:'6px',
    },
    '& .option-box':{
        width:'fit-content',
        display:'none',
    },
    '&:last-child':{
        borderBottom: "none !important",
    }
});