import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { Input } from '../../../../components/genericComponents/Input';
import Grid from '@mui/material/Grid';
import { getCommonFunctions } from "../ci_flow/SourceDetails";
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from "../../../../util/apiInvoker";
import properties from "../../../../properties/properties";
import MultiRow from '../../../../components/genericComponents/MultiRow';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { Loading } from "../../../utils/Loading";

const EnabledComponentHostpath = props => {
  const prev_state = props.prev_state ? props.prev_state : null
  const [state, setState] = useState(prev_state ? prev_state : getHostpathComponentDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, props.inherits)
  const service_id = props.extraProps.service_id;
  const env_id = props.extraProps.component_env_id;
  const cd_id = props.extraProps.env_cd_id;
  const variant = props.variant ? props.variant : null;
  function getSecrets() {

    var requestInfo = {
      endPoint: GenerateURL({ service_id: Number(service_id), env_id: Number(env_id), cd_id: cd_id }, properties.api.predefined_hostpath),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    InvokeApi(requestInfo, fetchSuccessSecretListing, fetchFailSecretListing);

  }

  function fetchSuccessSecretListing(response) {
    const secrets = []
    response.forEach(element => {
      secrets.push({ label: element.name, id: element.name, mount_path: element.mount_path, host_path: element.host_path })
    });
    setState(new_state => ({
      ...new_state,
      secrets: secrets
    }))
  }
  function fetchFailSecretListing(error) {
    console.log("SADads")
  }

  function loadSecrets() {
    var secrets = state.secrets.length == 0 ? getSecrets() : null
  }

  useEffect(() => {
    if (variant === "sidecars") {
      loadSecrets()
    }

  }, []);


  return (
    <>
      <MultiRow RepeatableComponent={SecretBody} HeaderComponent={SecretHead} prev_state={state.hostpath_form_childs} inherits={state.child_inherits.hostpath_form_childs} zeroRowsAllowed={true}
        repeatableComponentProps={{ secrets: state.secrets, variant: variant }} />
    </>
  )
}
EnabledComponentHostpath.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default EnabledComponentHostpath


export function getHostpathComponentDefaultState() {
  return {
    secrets: [],
    child_inherits: {
      hostpath_form_childs: {}
    }
  };
}

const SecretBody = props => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const secretNamesList = props.extraProps.secrets ? props.extraProps.secrets : [];
  const variant = props.extraProps.variant
  const [state, setState] = useState(prev_state ? prev_state : getHostPathFormDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  console.log(props.extraProps, "fdasfafssa")
  const toggleTagState = () => {
    console.log(state.open_custom, "secretNamesList")
    var custom_tag_flag = state.open_custom;
    if (custom_tag_flag) {
      setState({
        ...state,
        loading_tag: true,
        freez_input: false,
        name: null
      });
      setTimeout(() => {
        setState({
          ...state,
          freez_input: false,
          open_custom: !state.open_custom,
          loading_tag: false,
          name: null
        });
      }, 200);
    } else {
      setState({
        ...state,
        loading_tag: true,
        freez_input: false,
        name: null
      });
      setTimeout(() => {
        setState({
          ...state,
          open_custom: !state.open_custom,
          loading_tag: false,
          freez_input:false,
          name: null
        });
      }, 200);
    }

  }

  const onSelectChangeSetValue = (e) => {
    var key = e.target.name;
    var value = e.target.value;
    var find_key = secretNamesList.find(item => item.label === value);

    setState(new_state => ({
      ...new_state,
      freez_input: true,
      data: {
        ...new_state.data,
        [key]: value,
        host_path: find_key.host_path,
        mount_path: find_key.mount_path
      },
      error:{
        ...state.error,
        [key]: null,
      }
    }));
    commonFunctions.onChangeHandler(e);
  }
  return (
    <>
      <Grid item lg={3}>
        {
          state.loading_tag ? <Loading varient="light" /> :
            <Input
              type={variant == "sidecars" ? state.open_custom ? "text" : "select" : "text"}
              name="name"
              placeholder="Name"
              list={secretNamesList}
              label=" "
              error={state.error}
              onChangeHandler={variant == "sidecars" ? state.open_custom ? commonFunctions.onChangeHandler : onSelectChangeSetValue : commonFunctions.onChangeHandler}
              data={state.data}
              extraDiv={variant == "sidecars" ? <span role='button' tabIndex={0}  className={
                "mr-0 cursor-pointer text-anchor-blue pr-0"
              } onKeyDown={()=>{}} onClick={toggleTagState}>{state.open_custom ? "Select Hostpath" : "Provide Custom Hostpath"}</span> : null}
            />}
      </Grid>
      <Grid item lg={3}>
        <Input
          type="text"
          name="host_path"
          placeholder="Host Path"
          error={state.error}
          label=" "
          onChangeHandler={state.freez_input ? null : commonFunctions.onChangeHandler}
          data={state.data}
          extraDiv={variant == "sidecars" ? <span role='button' tabIndex={0}  style={{ visibility: 'hidden' }} className="mr-0 cursor-pointer text-anchor-blue pr-0" onKeyDown={()=>{}} onClick={toggleTagState}>{state.open_custom ? "Select EmptyDir" : "Provide Custom Hostpath"}</span> : null} />
      </Grid>
      <Grid item lg={3}>
        <Input
          type="text"
          placeholder="Volume Mount path"
          name="mount_path"
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          data={state.data}
          label=" "
          extraDiv={variant == "sidecars" ? <span role='button' tabIndex={0}  style={{ visibility: 'hidden' }} className="mr-0 cursor-pointer text-anchor-blue pr-0" onKeyDown={()=>{}} onClick={toggleTagState}>{state.open_custom ? "Provide Custom Entry" : "Select EmptyDir"}</span> : null} />
      </Grid>
    </>
  )
}
SecretBody.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const SecretHead = props => {
  return (
    <>
      <Grid item lg={3}>
        <div className="font-12">Name</div>
      </Grid>
      <Grid item lg={3}>
        <div className="font-12">Host Path</div>
      </Grid>
      <Grid item lg={3}>
        <div className="font-12">Volume Mount path</div>
      </Grid>
    </>
  )
}

export function getHostPathFormDefaultState() {
  return {
    data: {},
    open_custom: true,
    error: {},
    validations: {
      name: [VALIDATION_TYPE_REQUIRED],
      mount_path: [VALIDATION_TYPE_REQUIRED],
    },
  };
}