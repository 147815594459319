import { Snackbar, Tooltip } from  '@mui/material';
import { styled } from '@mui/system';
import React, { useRef, useState, useEffect } from 'react';


export const CustomSnackar = ({
  open,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  }, 
  handleClose = () => {} ,
  message=null, severity=null, subMessage=null, autoHideDuration=3000 }) => {
  const [openAlert,setOpenAlert] = useState(false);
  const [severityState,setSeverityState]= useState("");
  useEffect(() => {
    if(open){
      setSeverityState(severity);
      setOpenAlert(true);
    }else{
      setOpenAlert(false)
    }
  },[open,severity])

  const defaultCloseHandler = () => {
    if (handleClose) {
      setOpenAlert(false);
      handleClose();
    }
  };

  return (
        <Snackbar
          anchorOrigin={anchorOrigin}
          open={openAlert}
          onClose={defaultCloseHandler}
          //className="z-index-top"
          autoHideDuration={autoHideDuration}
          sx={{
            "&.MuiSnackbar-root .MuiPaper-root": {
              width: "auto !important",
              height:'auto !important',
              maxWidth: "598px",
              backgroundColor: "transparent",
              boxShadow: 'none',
              padding:'0px !important'
            }
          }}
          message={<CustomizedAlert message={message} severity={severityState} moreInfo={subMessage} onClose={defaultCloseHandler}/>}
          />
  )
}

const CustomizedAlert = ({ severity,message,moreInfo, onClose = ()=>{} }) => {
  const mainTextRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    if (mainTextRef.current) {
      const { offsetWidth, scrollWidth } = mainTextRef.current;
      setIsEllipsisActive(scrollWidth > offsetWidth);
    }
  }, []);
  // Helper function for colors
    function getColorsBasedOnSeverity(severity) {
        const colors = {
        success: { color: "#129E5B", background: "#EFFFF3", border: "#129E5B",icon:'ri-check-fill' },
        failed: { color: "#E95454", background: "#FFEAEA", border: "#E95454" ,icon:'ri-alert-line'},
        info: { color: "#0056D2", background: "#E3F2FD", border: "#0056D2" ,icon: "ri-information-line"},
        warning: { color: "#F57C00", background: "#FFF8E1", border: "#F57C00" ,icon:'ri-alert-line'},
        default: { color: "#626262", background: "#F4F4F4", border: "#A0A0A0", icon:'ri-alert-line'},
        };
    
        return colors[severity] || colors.default;
    }
  const colorIconScheme = getColorsBasedOnSeverity(severity);
  return ( 
    <AlertDiv colorIconScheme={colorIconScheme}>
      <div className="d-grid align-center p-relative header-content" style={moreInfo ? {
        gridTemplateColumns:'32px 1fr 32px 32px'
      } : { gridTemplateColumns:'32px 1fr 32px'}}>
        <div className="d-flex align-center justify-center icon-container">
          <span
            className={`${colorIconScheme.icon} font-24`}
            style={{ lineHeight: '1', marginBottom: '2px' }}
          ></span>
        </div>
        {isEllipsisActive ? (
          <Tooltip title={message} arrow>
            <span
              ref={mainTextRef}
              className="font-14 font-weight-600 main-text text-ellipsis"
            >
              {message}
            </span>
          </Tooltip>
        ) : (
          <span
            ref={mainTextRef}
            className="font-14 font-weight-600 main-text text-ellipsis"
          >
            {message}
          </span>
        )}
        {onClose &&
          <span className="ri-close-fill font-24 close-btn cursor-pointer" onClick={onClose}></span>
        }
      </div>
      {moreInfo && 
        <span className="sub-desc font-14 font-weight-400 color-icon-secondary">
          {moreInfo}
        </span>
      }
    </AlertDiv>
  );
};

export default CustomizedAlert;

const AlertDiv = styled('div')(({ colorIconScheme }) => {
  const { color, background, border } = colorIconScheme;

  return {
    padding: '20px 16px',
    borderRadius: '10px',
    borderLeft: `5px solid ${border}`,
    maxWidth: '598px',
    width:'100%',
    boxShadow: '0px 20px 34px 0px rgba(0, 0, 0, 0.16)',
    zIndex: '999',
    background:'#ffffff',
    '& .header-content': {
      gap: '4px',
      '& .main-text': {
        color: color,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
      },
      '& .icon-container': {
        width: '32px !important',
        height: '32px',
        background: background,
        borderRadius: '50%',
        color: color,
      },
      '& .close-btn': {
        color: '#3A3A3A',
        lineHeight: '1',
      },
    },
    '& .sub-desc': {
      display: 'inline-block',
      lineHeight: '21px',
      marginTop: '6px',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
  };
});
