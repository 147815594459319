import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { useParams, Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import CanaryListingHeader from "./components/ListingHeader";
import CanaryTemplateCard from "./components/CanaryTemplateCard";
import GenerateURL, { GenerateSearchURL } from "../../util/APIUrlProvider";
import properties from "../../properties/properties";
import InvokeApi from "../../util/apiInvoker";
import { Loading } from "../utils/Loading";
import { ErrorComponent } from "../utils/Error";
import SearchBar from "../../components/SearchBar";
import Pagination from "../../components/Pagination";

const CanaryTemplatesListing = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    loading: false,
    canary_temp_data: false,
    total_page: "",
    curr_page: "",
  });
  const { application_id } = useParams();
  useEffect(() => {
    fetchCanaryTemplatesInfo();
  }, [application_id]);

  //On load function----------------------------------------------

  function fetchCanaryTemplatesInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.canary_template_listing
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }

    InvokeApi(
      requestInfo,
      canaryTemplatesInfoFetchSuccess,
      canaryTemplatesInfoFetchFailure
    );

    setState((new_state) => ({
      ...new_state,
      loading: true,
      search_data: data,
    }));
  }

  function canaryTemplatesInfoFetchSuccess(data) {
    setState((new_state) => ({
      ...new_state,
      loading: false,
      canary_temp_data: true,
      canary_template_listing: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
        ? (Number(data.count) / 10 + 1).toFixed(0) - 1
        : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: 1,
    }));
  }

  function canaryTemplatesInfoFetchFailure(error, exception) {
    console.log(error,'error_ms232323')

    
    setState((new_state) => ({
      ...new_state,
      loading: false,
      canary_temp_data: false,
      error_msg: error,
    }));
  }

  //Prev function---------------------------------------------------------------------------------------

  function fetchPrevCanaryTemplatesInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.canary_template_listing
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      loading: true,
      search_data: data,
    }));
    InvokeApi(
      requestInfo,
      PrevCanaryTemplatesInfoFetchSuccess,
      PrevCanaryTemplatesInfoFetchFailure
    );
  }

  function PrevCanaryTemplatesInfoFetchSuccess(data) {
    setState((new_state) => ({
      ...new_state,
      loading: false,
      canary_temp_data: true,
      canary_template_listing: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
        ? (Number(data.count) / 10 + 1).toFixed(0) - 1
        : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page - 1),
    }));
  }

  function PrevCanaryTemplatesInfoFetchFailure(error, exception) {
    var error_msg = JSON.stringify(error);
    setState((new_state) => ({
      ...new_state,
      loading: false,
      canary_temp_data: true,
      error_msg: error_msg + " " + "Please refresh the page and try again",
    }));
  }

  //Next function-------------------------------------------------

  function fetchNextCanaryTemplatesInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.canary_template_listing
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      loading: true,
      search_data: data,
    }));
    InvokeApi(
      requestInfo,
      NextCanaryTemplatesInfoFetchSuccess,
      NextCanaryTemplatesInfoFetchFailure
    );
  }

  function NextCanaryTemplatesInfoFetchSuccess(data) {
    setState((new_state) => ({
      ...new_state,
      loading: false,
      canary_temp_data: true,
      canary_template_listing: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
        ? (Number(data.count) / 10 + 1).toFixed(0) - 1
        : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page + 1),
    }));
  }

  function NextCanaryTemplatesInfoFetchFailure(error, exception) {
    var error_msg = JSON.stringify(error);
    setState((new_state) => ({
      ...new_state,
      loading: false,
      canary_temp_data: true,
      error_msg: error_msg + " " + "Please refresh the page and try again",
    }));
  }

  //page function ----------------------------------------------------------

  function fetchPageCanaryTemplatesInfo(enteredPageNumber) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.canary_template_listing
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&offset=" +
        (enteredPageNumber - 1) * 10;
    }

    var current_page = enteredPageNumber;

    InvokeApi(
      requestInfo,
      (response) => {
        PageCanaryTemplatesInfoFetchSuccess(response, current_page);
      },
      PageCanaryTemplatesInfoFetchFailure
    );
  }

  function PageCanaryTemplatesInfoFetchSuccess(data, count) {
    setState((new_state) => ({
      ...new_state,
      loading: false,
      canary_temp_data: true,
      canary_template_listing: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
        ? (Number(data.count) / 10 + 1).toFixed(0) - 1
        : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: Number(count),
    }));
  }

  function PageCanaryTemplatesInfoFetchFailure(error, exception) {
    var error_msg = JSON.stringify(error);
    setState((new_state) => ({
      ...new_state,
      loading: false,
      canary_temp_data: true,
      error_msg: error_msg + " " + "Please refresh the page and try again",
    }));
  }

  //-------------------------------------------------------------------------

  const invokeSearch = (data) => {
    console.log(data, "invoke search");
    fetchCanaryTemplatesInfo(data);
  };


  console.log('stat4===>23233',state)
  return (
    <>
      {state.loading ? (
        <Loading />
      ) : state.canary_temp_data ? (
        <div className={classes.root}>
          <CanaryListingHeader application_id={application_id} />
          {state.count == 0 ? null : 
            <div className = {classes.searchBar}>
            <Grid container className="search-containter">
              <Grid item lg={8}>
                <SearchBar
                  search_data={state.search_data}
                  default_filter={{ name: "name", label: "Name" }}
                  search_call_back={invokeSearch}
                  clear_search_callback={fetchCanaryTemplatesInfo}
                />
              </Grid>
              <Grid item lg={4} sm={12} xl={3} xs={12}>
                <Pagination
                  total_count={state.total_page}
                  current_page_count={state.curr_page}
                  next={state.next}
                  previous={state.previous}
                  on_previous_click={() => {
                    fetchPrevCanaryTemplatesInfo(null, state.previous);
                  }}
                  on_next_click={() => {
                    fetchNextCanaryTemplatesInfo(null, state.next);
                  }}
                  on_pageNumber_click={(pageNumber) => {
                    fetchPageCanaryTemplatesInfo(pageNumber);
                  }}
                />
              </Grid>
            </Grid>
          </div>
          }
          

          {state.canary_template_listing &&
          state.canary_template_listing.length > 0 ? (
            <Grid container spacing="2">
              {state.canary_template_listing.map((item) => (
                <Grid item lg={4}>
                  <CanaryTemplateCard data={item} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <div
              className="main-container-error"
              style={{ height: "60vh", margin: "auto" }}
            >
              <div className="svg">
                <div className="servicePanel">
                  <div className="blank-div">
                    <div className="blank-div-text">
                      No Canary Templates associated with this Application
                    </div>
                    <div>
                      <Link
                        to={
                          "/application/" +
                          application_id +
                          "/canary-template/add"
                        }
                        className="btn btn-primary"
                        style={{width: "fit-content"}}
                      >
                        <div>Add Canary Template</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : state.error_msg ? (
        <ErrorComponent variant="error-box" error={state.error_msg} />
      ) : null}
    </>
  );
};
const useStyles = makeStyles({
  root: {
    margin: "20px",
    //padding: "15px",
  },
  searchBar :{
    paddingBottom : "15px"
  }
});

export default CanaryTemplatesListing;
