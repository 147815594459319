import React, { useEffect, useState } from 'react';
import { styled } from "@mui/system";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import IntegrationAndProject from './IntegrationAndProject';
import PickStories from './PickStories';
import IssueTypeValidation from './IssueTypeValidation';
import ApprovalCommentRequirement from './ApprovalCommentRequirement';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { showErrorHandlerUpdated } from '../../../../util/util';
import { Link, useParams, Navigate } from "react-router-dom";
import AlertStrip from '../../../../components/AlertStrips';
import BPAppsIntegrations from './BPAppsIntegrations';

import CustomSnackbar from '../../../../components/genericComponents/CustomSnackbar';
// Styled components
const Root = styled('div')({
    width: '100%',
    '& .MuiStepper-root': {
        padding: '0px !important',

        '& .Mui-disabled': {
            backgroundColor: '#fff !important'
        },
        '& .MuiStepContent-root': {
            marginLeft: '20px',
            borderImage: 'url(/images/dashed-border.png) 12',
            paddingLeft: '43px'

        },
        '& .MuiStepConnector-vertical': {
            marginLeft: '20px',
            visibility: 'hidden',
            display: 'none',

        },
        '& .MuiStepLabel-labelContainer': {
            padding: '12px',
            borderRadius: '6px',
            border: '0px solid #e6e6e6',

        },
        '& .MuiStepConnector-active': {
            '& .MuiStepLabel-labelContainer': {
                padding: '0!important',
                borderRadius: '6px',
                border: '0px solid #e6e6e6!important',

            }
        },
        '& .MuiStep-completed': {
            '& .MuiStepLabel-labelContainer': {
                padding: '12px',
                borderRadius: '6px',
                border: '1px solid #e6e6e6',

            }
        },
        '& .Mui-disabled': {
            backgroundColor: '#fff!important',
            '& .MuiStepLabel-labelContainer': {
                padding: '12px',
                borderRadius: '6px',
                border: '1px solid #e6e6e6',

            }
        },
    }
});


const ActionsContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const CustomStepIcon = ({ active, completed, icon }) => {
    return (
        <>
            {
                completed ?
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 42,
                            height: 42,
                            borderRadius: '50%',
                            backgroundColor: '#2EBE79',
                            border: '5px solid #E6FBEA'
                        }}
                        className='font-18 font-weight-600'
                    >
                        <span className='ri-check-line font-15' style={{ color: '#fff' }}></span>
                    </div>
                    :
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 42,
                            height: 42,
                            borderRadius: '50%',
                            backgroundColor: active ? '#F5FAFF' : '#fff',
                            color: '#0086ff',
                            border: '5px solid #DFEDFF'
                        }}
                        className='font-18 font-weight-600'
                    >
                        {icon}
                    </div>
            }
        </>

    );
};
const CustomStepLabel = styled(StepLabel)({
    color: '#2F2F2F',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    '& .MuiStepLabel-label': {
        color: '#2F2F2F',
        fontSize: '14px !important',
        fontWeight: '600 !important',
    },
    '& .MuiStepLabel-active': {
        color: '#2F2F2F',
        fontSize: '14px !important',
        fontWeight: '600 !important',
    },
    '& .MuiStepLabel-completed': {
        color: '#2f2f2f',
        fontSize: '14px !important',
        fontWeight: '600 !important',
    },
});

const BpAppsConfigHeader = ({ appIntegrationData }) => {
    console.log(appIntegrationData, "appIntegrationData")
    return (
        <div className='d-flex align-center space-between'>
            <span>SELECT APPLICATIONS (Configured)</span>
            <div className='d-flex align-center'>
                <span className='d-block font-12 font-weight-600 mr-3' style={{ backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '3px 6px', }}>
                    <span className='' style={{ color: '#949494' }}>Config Name:</span>  <span style={{ color: '#626262' }} >{appIntegrationData && appIntegrationData["name"]}</span>
                </span>
                <span className='d-block font-12 font-weight-600' style={{ backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '3px 6px' }}>
                    <span className='' style={{ color: '#949494' }}>Applications:</span>  <span style={{ color: '#626262' }}>{appIntegrationData && appIntegrationData["application_name"]}</span>
                </span>
            </div>
        </div>
    )
}

const IntegrationAndProjectHeader = ({ integrationProjectData }) => {
    console.log(integrationProjectData, "integrationProjectData")
    return (
        <div className='d-flex align-center space-between'>
            <span>INTEGRATION & PROJECTS (Configured)</span>
            <div className='d-flex align-center'>
                <span className='d-block font-12 font-weight-600 mr-3' style={{ backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '3px 6px', }}>
                    <span className='' style={{ color: '#949494' }}>Ticketing System:</span>  <span style={{ color: '#626262' }} >{integrationProjectData && integrationProjectData["selected_integration"]}</span>
                </span>
                <span className='d-block font-12 font-weight-600' style={{ backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '3px 6px' }}>
                    <span className='' style={{ color: '#949494' }}>Ticketing System Project:</span>  <span style={{ color: '#626262' }}>{integrationProjectData && integrationProjectData["selected_project"]}</span>
                </span>
            </div>
        </div>
    )
}

const PickStoriesHeader = ({ pickStoriesData }) => {
    return (
        <div className='d-flex align-center space-between'>
            <span>PICK STORIES (Configured)</span>
            <div className='d-flex align-center'>
                <span className='d-block font-12 font-weight-600 mr-3' style={{ backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '3px 6px', }}>
                    <span className='' style={{ color: '#949494' }}>Pick From:</span>  <span style={{ color: '#626262' }} >{pickStoriesData && pickStoriesData["pick_from"]}</span>
                </span>
            </div>
        </div>
    )
}

const IssueTypeValidationHeader = ({ stepData }) => {
    return (
        <div className='d-flex align-center space-between'>
            <span>ISSUE TYPE VALIDATION (Configured)</span>
            <div className='d-flex align-center'>
                <span className='d-block font-12 font-weight-600 mr-3' style={{ backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '3px 6px', }}>
                    <span className='' style={{ color: '#949494' }}>Issue Type Validations:</span>  <span style={{ color: '#626262' }} >Configured</span>
                </span>
            </div>
        </div>
    )
}
const ApprovalCommentRequirementHeader = ({ approvalCommentData }) => {
    return (
        <div className='d-flex align-center space-between'>
            <span>APPROVAL COMMENT REQUIREMENT (Configured))</span>
            <div className='d-flex align-center'>
                {
                    approvalCommentData ?
                        approvalCommentData["read_user_groups"] && approvalCommentData["read_user_groups"].length > 0 ?
                            <span className='d-block font-12 font-weight-600 mr-3' style={{ backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '3px 6px', }}>
                                <span className='' style={{ color: '#949494' }}>Read User Group:</span>  <span style={{ color: '#626262' }} >Configured</span>
                            </span>
                            : null : null
                }
                {
                    approvalCommentData ?
                        approvalCommentData["write_user_groups"] && approvalCommentData["write_user_groups"].length > 0 ?
                            <span className='d-block font-12 font-weight-600 mr-3' style={{ backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '3px 6px', }}>
                                <span className='' style={{ color: '#949494' }}>Write User Group:</span>  <span style={{ color: '#626262' }} >Configured</span>
                            </span>
                            : null : null
                }
            </div>
        </div>
    )
}


function getSteps(stepData) {
    console.log(stepData, "fkdsjkfjsadfja")
    let integrationProjectData = stepData.integrationProjectData ? Object.keys(stepData.integrationProjectData).length === 5 ? true : false : false;
    let pickStoriesData = stepData.pickStoriesData ? Object.keys(stepData.pickStoriesData).length === 1 ? true : false : false;
    let issueTypeValidationData = stepData.issueTypeValidationData ? Object.keys(stepData.issueTypeValidationData).length > 1 ? true : false : false;
    let approvalCommentData = stepData.approvalCommentData ? Object.keys(stepData.approvalCommentData).length > 1 ? true : false : false;
    return [
        integrationProjectData ? <IntegrationAndProjectHeader integrationProjectData={stepData.integrationProjectData} /> : 'INTEGRATION & PROJECTS',
        pickStoriesData ? <PickStoriesHeader pickStoriesData={stepData.pickStoriesData} /> : 'PICK STORIES',
        issueTypeValidationData ? <IssueTypeValidationHeader issueTypeValidationData={stepData.issueTypeValidationData} /> : 'ISSUE TYPE VALIDATION',
        approvalCommentData ? <ApprovalCommentRequirementHeader approvalCommentData={stepData.approvalCommentData} /> : 'APPROVAL COMMENT REQUIREMENT'
    ];
}

function getAppsConfigSteps(stepData) {
    console.log(stepData, "fdsfsjakfjksaf")
    let bpAppsConfigurationData = stepData.appIntegrationData ? Object.keys(stepData.appIntegrationData).length === 3 ? true : false : false;
    let integrationProjectData = stepData.integrationProjectData ? Object.keys(stepData.integrationProjectData).length === 5 ? true : false : false;
    let pickStoriesData = stepData.pickStoriesData ? Object.keys(stepData.pickStoriesData).length === 1 ? true : false : false;
    let issueTypeValidationData = stepData.issueTypeValidationData ? Object.keys(stepData.issueTypeValidationData).length > 1 ? true : false : false;
    let approvalCommentData = stepData.approvalCommentData ? Object.keys(stepData.approvalCommentData).length > 1 ? true : false : false;
    return [
        bpAppsConfigurationData ? <BpAppsConfigHeader appIntegrationData={stepData.appIntegrationData} /> : 'SELECT APPLICATIONS',
        integrationProjectData ? <IntegrationAndProjectHeader integrationProjectData={stepData.integrationProjectData} /> : 'INTEGRATION & PROJECTS',
        pickStoriesData ? <PickStoriesHeader pickStoriesData={stepData.pickStoriesData} /> : 'PICK STORIES',
        issueTypeValidationData ? <IssueTypeValidationHeader issueTypeValidationData={stepData.issueTypeValidationData} /> : 'ISSUE TYPE VALIDATION',
        approvalCommentData ? <ApprovalCommentRequirementHeader approvalCommentData={stepData.approvalCommentData} /> : 'APPROVAL COMMENT REQUIREMENT'
    ];
}



export default function ConfigurationStepperForm() {
    const { configuration_type, id } = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const [integrationType, setIntegrationType] = useState(null)
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'success', // or 'error'
        message: '',
    });

    const [state, setState] = useState({
        all_ticketing_integrations: [],
        ticketing_projects: [],
        jira_issue_type: [],
        jira_status_list: [],
        error_in_saving_data: null,
        error_in_loading_ado_issues:null,
        loading_ado_status_project: false,
        ado_issue_type_and_status:null
    })
    const [stepData, setStepData] = useState({
        appIntegrationData: null,
        integrationProjectData: null,
        pickStoriesData: null,
        issueTypeValidationData: null,
        approvalCommentData: null
    });

    const [stepDataError, setStepDataError] = useState({
        appIntegrationData: null,
        integrationProjectData: null,
        pickStoriesData: null,
        issueTypeValidationData: null,
        approvalCommentData: null
    });
    const steps = getSteps(stepData);
    const appConfigSteps = getAppsConfigSteps(stepData)

    useEffect(() => {
        if (configuration_type === "default-configuration") {
            if (activeStep === 0) {
                fetchTickingSystems();
            }
        } else {
            if (activeStep === 0) {
                fetchTickingSystems();
            }
        }

    }, [activeStep])



    function getEditableData(id, response) {
        var requestInfo = {
            endPoint: GenerateURL({ edit_id: id }, properties.api.release_mgmt_config_edit),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (res) => { getEditableDataSuccess(res, response) }, getEditableDataFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function getEditableDataSuccess(response, all_tickets) {
        const  results  = all_tickets; // { results } changed to result
        console.log(all_tickets,"fdsfsfsafsa")
        if (response.default === true) {
            // Default configuration handling
            console.log(all_tickets,"fdsfsfsafsa")
            const configuredTicketingIntegration = results?.find(item => item.id === response.integration);

            if (response.integration) {
                loadJiraProject(response.integration, response.project);
            }

            setStepData({
                integrationProjectData: {
                    selected_integration: configuredTicketingIntegration?.integration_name || null,
                    selected_project: response.project || null,
                    name: response.name || null,
                    integration: response.integration || null,
                    project: response.project || null
                },
                pickStoriesData: {
                    pick_from: response.pick_from || null
                },
                issueTypeValidationData: {
                    issue_type_validation: Object.keys(response.issue_type_validation).length > 0 ? "yes" : "no",
                    ...response.issue_type_validation
                },
                approvalCommentData: {
                    comment_validation_required: Object.keys(response.approve_comment_validation || {}).length > 0 ? "yes" : "no",
                    comment_regex: response.approve_comment_validation?.comment_regex || "",
                    read_user_groups: response.approve_comment_validation?.read_user_groups || [],
                    write_user_groups: response.approve_comment_validation?.write_user_groups || [],
                    aproval_user_list: response.approve_comment_validation?.aproval_user_list || [],
                }
            });
        } else {
            // Non-default configuration handling
            const configuredTicketingIntegration = results?.find(item => item.id === response.integration);

            if (response.integration) {
                loadJiraProject(response.integration, response.project);
            }

            setStepData({
                appIntegrationData: {
                    name: response.name || null,
                    application: response.application || null,
                    application_name: response.applicaiton_name || null
                },
                integrationProjectData: {
                    selected_integration: configuredTicketingIntegration?.integration_name || null,
                    selected_project: response.project || null,
                    name: response.name || null,
                    integration: response.integration || null,
                    project: response.project || null
                },
                pickStoriesData: {
                    pick_from: response.pick_from || null
                },
                issueTypeValidationData: {
                    issue_type_validation: Object.keys(response.issue_type_validation).length > 0 ? "yes" : "no",
                    ...response.issue_type_validation
                },
                approvalCommentData: {
                    comment_validation_required: Object.keys(response.approve_comment_validation || {}).length > 0 ? "yes" : "no",
                    comment_regex: response.approve_comment_validation?.comment_regex || "",
                    read_user_groups: response.approve_comment_validation?.read_user_groups || [],
                    write_user_groups: response.approve_comment_validation?.write_user_groups || [],
                    aproval_user_list: response.approve_comment_validation?.aproval_user_list || [],
                   
                }
            });
        }
    }




    function getEditableDataFailure(error) {

    }

    function fetchTickingSystems() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.ticketing_system + "?all=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchTickingSystemsSuccess, fetchTickingSystemsFailure);
        setState(new_state => ({
            ...new_state,
            load_jira_integration: true
        }));
    }

    function fetchTickingSystemsSuccess(response) {

        if (id && configuration_type) {
            getEditableData(id, response)
        }
        var all_ticketing_system_arr = []

        response.map(item => {
            all_ticketing_system_arr.push({ label: item.integration_name, id: item.id, type: item.integration_type })
        })
        setState(new_state => ({
            ...new_state,
            all_ticketing_integrations: all_ticketing_system_arr && all_ticketing_system_arr.length > 0 ? all_ticketing_system_arr : [],
            load_jira_integration: false,
        }));

    }
    function fetchTickingSystemsFailure(error, exception) {
        let error_msg = showErrorHandlerUpdated(error);
        setState(new_state => ({
            ...new_state,
            load_jira_integration: false,
            error: true,
            error_msg_in_integration_settings: error_msg
        }));
    }


    function loadJiraProject(id, selected_project) {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.load_jira_projects),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        requestInfo.endPoint = requestInfo.endPoint + "?id=" + id;

        InvokeApi(requestInfo, (res) => { jiraProjectsFetchSuccess(res, selected_project) }, jiraProjectsFetchFailure);

        setState(new_state => ({
            ...new_state,
            load_jira_project: true,
        }));



    }
    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };


    function jiraProjectsFetchSuccess(data, selected_project) {
       
        let ticketing_projects = data.PROJECT_LISTS && data.PROJECT_LISTS.length > 0 ? data.PROJECT_LISTS.map(item => {
            return { label: item.name, id: item.id }
        }) : []
        if (selected_project) {
            var selected_project_obj = ticketing_projects.find(item => item.label === selected_project)
        }
        setState(new_state => ({
            ...new_state,
            load_jira_project: false,
            error_loading_in_project: null,
            ticketing_projects: ticketing_projects
        }));
        if (selected_project) {
            setStepData(prevState => ({
                ...prevState,
                integrationProjectData: {
                    ...prevState.integrationProjectData,
                    project: selected_project_obj.id
                }
            }));
        }



    }

    function jiraProjectsFetchFailure(error) {
        let error_msg = showErrorHandlerUpdated(error);
        setState(new_state => ({
            ...new_state,
            load_jira_project: false,
            error_loading_in_project: error_msg
        }));

    }


    const handleNext = () => {
        let steps_length = configuration_type === "default-configuration" ? steps.length - 1 : appConfigSteps.length - 1;
        console.log(steps_length, "steps_length", activeStep, activeStep === steps_length)
        if (activeStep === steps_length) {
            validateAndSave();
        } else {
            if (activeStep === 1) {
                let validations = {
                    integration: [VALIDATION_TYPE_REQUIRED],
                    project: [VALIDATION_TYPE_REQUIRED]
                }
                let result = ValidateDataSet(stepData.integrationProjectData, validations);
                if (result.valid) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    setStepDataError(newStepItem => ({
                        ...newStepItem,
                        integrationProjectData: {
                            ...newStepItem.integrationProjectData,
                            ...result.error
                        },
                        pickStoriesData: null,
                        issueTypeValidationData: null,
                        approvalCommentData: null
                    })

                    )
                }
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    function dataTransformDefault(inputData) {
        console.log(inputData, "inputData during default transformation");
        const { issueTypeValidationData } = inputData;
    
        let transformedIssueTypeValidation = {};
        if (issueTypeValidationData.issue_type_validation === "yes") {
            if (integrationType === "azure_devops") {
                transformedIssueTypeValidation = Object.entries(issueTypeValidationData)
                    .filter(
                        ([key, value]) =>
                            key !== "issue_type_validation" &&
                            state.ado_issue_type_and_status[key] &&
                            state.ado_issue_type_and_status[key].includes(value)
                    )
                    .reduce((acc, [key, value]) => {
                        acc[key] = value;
                        return acc;
                    }, {});
            } else if (integrationType === "jira") {
               
                const validJiraIssueTypes = state.jira_issue_type.map((item) => item.id);
                transformedIssueTypeValidation = Object.entries(issueTypeValidationData)
                    .filter(([key]) => key !== "issue_type_validation" && validJiraIssueTypes.includes(key))
                    .reduce((acc, [key, value]) => {
                        acc[key] = value; 
                        return acc;
                    }, {});
            }
        }
    
       
        const supportedKeys = integrationType === "azure_devops"
            ? Object.keys(state.ado_issue_type_and_status)
            : state.jira_issue_type.map((item) => item.id);
    
        transformedIssueTypeValidation = Object.entries(transformedIssueTypeValidation)
            .filter(([key]) => supportedKeys.includes(key))
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
    
        return {
            name: inputData.integrationProjectData.name,
            issue_type_validation: transformedIssueTypeValidation,
            default: true,
            project: inputData.integrationProjectData.selected_project,
            approve_comment_validation: inputData.approvalCommentData.comment_validation_required === "yes"
                ? {
                    comment_regex: inputData.approvalCommentData.comment_regex,
                    read_user_groups: inputData.approvalCommentData.read_user_groups,
                    write_user_groups: inputData.approvalCommentData.write_user_groups,
                    aproval_user_list: inputData.approvalCommentData.aproval_user_list,
                }
                : {},
            pick_from: inputData.pickStoriesData.pick_from,
            application: null,
            integration: Number(inputData.integrationProjectData.integration),
        };
    }
    

    function dataTransform(inputData) {
        console.log(inputData, "inputData during transformation");
        const { issueTypeValidationData } = inputData;
    
        let transformedIssueTypeValidation = {};
        if (inputData.issueTypeValidationData.issue_type_validation === "yes") {
            if (integrationType === "azure_devops") {
                // Filter and retain only valid keys and values for Azure DevOps
                transformedIssueTypeValidation = Object.entries(issueTypeValidationData)
                    .filter(
                        ([key, value]) =>
                            key !== "issue_type_validation" &&
                            state.ado_issue_type_and_status[key] &&
                            state.ado_issue_type_and_status[key].includes(value)
                    )
                    .reduce((acc, [key, value]) => {
                        acc[key] = value;
                        return acc;
                    }, {});
            } else if (integrationType === "jira") {
                // Filter and retain only valid keys for Jira
                const validJiraIssueTypes = state.jira_issue_type.map((item) => item.id);
                transformedIssueTypeValidation = Object.entries(issueTypeValidationData)
                    .filter(([key]) => key !== "issue_type_validation" && validJiraIssueTypes.includes(key))
                    .reduce((acc, [key, value]) => {
                        acc[key] = value; // Value is retained as Jira statuses aren't checked in this example
                        return acc;
                    }, {});
            }
        }
    
        // Remove keys not supported by the new integration type
        const supportedKeys = integrationType === "azure_devops"
            ? Object.keys(state.ado_issue_type_and_status)
            : state.jira_issue_type.map((item) => item.id);
    
        transformedIssueTypeValidation = Object.entries(transformedIssueTypeValidation)
            .filter(([key]) => supportedKeys.includes(key))
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
    
        return {
            name: inputData.appIntegrationData.name,
            issue_type_validation: transformedIssueTypeValidation,
            default: inputData.appIntegrationData.application ? false : true,
            project: inputData.integrationProjectData.selected_project,
            approve_comment_validation: inputData.approvalCommentData.comment_validation_required === "yes"
                ? {
                    comment_regex: inputData.approvalCommentData.comment_regex,
                    read_user_groups: inputData.approvalCommentData.read_user_groups,
                    write_user_groups: inputData.approvalCommentData.write_user_groups,
                    aproval_user_list: inputData.approvalCommentData.aproval_user_list,
                }
                : {},
            pick_from: inputData.pickStoriesData.pick_from,
            application: inputData.appIntegrationData.application,
            integration: Number(inputData.integrationProjectData.integration),
        };
    }
    


    function validateAndSave() {
        const result = ValidateDataSet(stepData, stepData.validations);
        let postUrl = GenerateURL({}, properties.api.release_mgmt_config);
        if (id) {
            postUrl = GenerateURL({ edit_id: id }, properties.api.release_mgmt_config_edit);
        }
        if (result.valid) {

            var basicDetails = {};
            if (configuration_type === "default-configuration") {
                basicDetails = dataTransformDefault(stepData);
            } else {
                basicDetails = dataTransform(stepData);
            }
            console.log(basicDetails, "data to post")
            PostData(postUrl, basicDetails, postSuccess, postFail);
        } else {
            setStepData((prevData) => ({
                ...prevData,
                error: {
                    ...prevData.error,
                    ...result.error
                }
            }));
            setSnackbar({ open: true, message: 'Validation failed. Please check the form.', severity: 'error' });
        }
    }

    const postSuccess = (response) => {
        setSnackbar({ open: true, message: 'Configuration saved successfully!', severity: 'success' });
        setState(new_state => ({
            ...new_state,
            redirect: true,
            error_loading_in_project: null
        }));
    };

    const postFail = (error) => {
        var error_msg = showErrorHandlerUpdated(error);
        setSnackbar({ open: true, message: `Failed to save configuration: ${error_msg}`, severity: 'error' });
        setState(new_state => ({
            ...new_state,
            redirect: false,
            error_in_saving_data: error_msg
        }));

    };
    const handleDataUpdate = (stepKey, data) => {
        setStepData((prevData) => {
            const isDataChanged = JSON.stringify(prevData[stepKey]) !== JSON.stringify(data);
            if (isDataChanged) {
                console.log('Data updated for:', stepKey, data);
                return { ...prevData, [stepKey]: data };
            }
            return prevData;
        });
    };
    

    const clearIntegrationAndProjectState = (stepKey, key) => {
        setStepDataError(newStepItem => ({
            ...newStepItem,
            [stepKey]: {}
        }))
    }

    function getAppConfigStepContent(step) {

        switch (step) {
            case 0:
                return <BPAppsIntegrations
                    data={stepData.appIntegrationData}
                    onDataUpdate={(data) => handleDataUpdate('appIntegrationData', data)}
                    error={stepDataError.appIntegrationData}
                />;
            case 1:
                return <IntegrationAndProject
                    data={stepData.integrationProjectData}
                    ticketing_projects={state.ticketing_projects}
                    all_ticketing_integrations={state.all_ticketing_integrations}
                    onDataUpdate={(data) => handleDataUpdate('integrationProjectData', data)}
                    loading_ticketing_integrations={state.load_jira_integration}
                    loading_ticketing_integrations_projects={state.load_jira_project}
                    error_msg_in_loading_projects={state.error_loading_in_project}
                    error_msg_in_integration_settings={state.error_msg_in_integration_settings}
                    error={stepDataError.integrationProjectData}
                    app_config={true}
                    loadAdoRelatedStates={loadAdoSupportedData}
                    loadRelevantProjects={(id) => { loadJiraProject(id) }}
                    integration_type={integrationType}
                    clearErrorState={
                        (key) => {
                            clearIntegrationAndProjectState('integrationProjectData', key)
                        }
                    }
                />;
            case 2:
                return <PickStories
                    data={stepData.pickStoriesData}
                    onDataUpdate={(data) => handleDataUpdate('pickStoriesData', data)}
                />;
            case 3:
                return <IssueTypeValidation
                    data={stepData.issueTypeValidationData}
                    jira_status_list={state.jira_status_list}
                    jira_issue_type={state.jira_issue_type}
                    onDataUpdate={(data) => handleDataUpdate('issueTypeValidationData', data)}
                    integration_type={integrationType}
                    loading_issue_type={state.loading_issue_type}
                    loading_available_status={state.loading_available_status}
                    error_in_loading_issue_type={state.error_in_loading_issue_type}
                    error_in_loading_status={state.error_in_loading_status}
                    loading_ado_issues_and_status={state.loading_ado_status_project}
                    error_in_loading_ado_issues={state.error_in_loading_ado_issues}
                    ado_issue_type_and_status={state.ado_issue_type_and_status}
                />;
            case 4:
                return <ApprovalCommentRequirement
                    data={stepData.approvalCommentData}
                    jira_users={state.all_approver_user}
                    selected_ticketing_project={stepData?.integrationProjectData?.selected_project}
                    selected_ticketing={stepData?.integrationProjectData?.selected_integration}
                    onDataUpdate={(data) => handleDataUpdate('approvalCommentData', data)}
                />;
            default:
                return 'Unknown step';
        }

    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <IntegrationAndProject
                    data={stepData.integrationProjectData}
                    ticketing_projects={state.ticketing_projects}
                    all_ticketing_integrations={state.all_ticketing_integrations}
                    onDataUpdate={(data) => handleDataUpdate('integrationProjectData', data)}
                    loading_ticketing_integrations={state.load_jira_integration}
                    loading_ticketing_integrations_projects={state.load_jira_project}
                    error_msg_in_loading_projects={state.error_loading_in_project}
                    error_msg_in_integration_settings={state.error_msg_in_integration_settings}
                    error={stepDataError.integrationProjectData}
                    loadRelevantProjects={(id) => { loadJiraProject(id) }}
                    loadAdoRelatedStates={loadAdoSupportedData}
                    integration_type={integrationType}
                    clearErrorState={
                        (key) => {
                            clearIntegrationAndProjectState('integrationProjectData', key)
                        }
                    }
                />;
            case 1:
                return <PickStories
                    data={stepData.pickStoriesData}
                    onDataUpdate={(data) => handleDataUpdate('pickStoriesData', data)}
                />;
            case 2:
                return <IssueTypeValidation
                    data={stepData.issueTypeValidationData}
                    jira_status_list={state.jira_status_list}
                    jira_issue_type={state.jira_issue_type}
                    integration_type={integrationType}
                    onDataUpdate={(data) => handleDataUpdate('issueTypeValidationData', data)}
                    loading_issue_type={state.loading_issue_type}
                    loading_available_status={state.loading_available_status}
                    error_in_loading_issue_type={state.error_in_loading_issue_type}
                    error_in_loading_status={state.error_in_loading_status}
                    loading_ado_issues_and_status={state.loading_ado_status_project}
                    error_in_loading_ado_issues={state.error_in_loading_ado_issues}
                    ado_issue_type_and_status={state.ado_issue_type_and_status}
                />;
            case 3:
                return <ApprovalCommentRequirement
                    data={stepData.approvalCommentData}
                    jira_users={state.all_approver_user}
                    selected_ticketing_project={stepData?.integrationProjectData?.selected_project}
                    selected_ticketing={stepData?.integrationProjectData?.selected_integration}
                    onDataUpdate={(data) => handleDataUpdate('approvalCommentData', data)}
                />;
            default:
                return 'Unknown step';
        }
    }


    useEffect(() => {
        if (stepData?.integrationProjectData?.selected_integration && stepData.integrationProjectData.selected_project) {

            const { selected_integration, selected_project } = stepData.integrationProjectData;
            const integration = state.all_ticketing_integrations.find(
                item => item.label === selected_integration
            );
            const integration_type = integration ? integration.type : undefined;
            setIntegrationType(integration_type)
            console.log(integration_type, integration,"fsakjfjkajdfa", selected_integration,selected_project, state.all_ticketing_integrations)
            if(integration_type === "azure_devops"){
                loadAdoSupportedData(selected_integration, selected_project);
              
            }else{
                fetchJiraIssueTypeList(selected_integration, selected_project);
                
            }
        }
    }, [stepData?.integrationProjectData?.selected_integration, stepData?.integrationProjectData?.selected_project, stepData?.integrationProjectData?.integration_type ]);

    function loadAdoSupportedData(ticketing_system, project) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.ado_issue_type_status),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        requestInfo.endPoint = requestInfo.endPoint + `?ticketing_name=${ticketing_system}&project=${project}`

        InvokeApi(requestInfo, (res) => { adoProjectsFetchSuccess(res, project) }, adoProjectsFetchFailure);

        setState(new_state => ({
            ...new_state,
            loading_ado_status_project: true,
            error_in_loading_ado_issues:null
        }));
    }
    function adoProjectsFetchSuccess(response, project) {
        console.log(response, project, "fsdafsajfdjsa")
        var issue_type = response && response.issue_types


        setState(new_state => ({
            ...new_state,
            ado_issue_type_and_status: issue_type,
            loading_ado_status_project: false,
            error_in_loading_ado_issues: null
        }));
    }
    function adoProjectsFetchFailure(error) {
        console.log(error, "fsdafsajfdjsa")
        let error_msg = showErrorHandlerUpdated(error);
        setState(new_state => ({
            ...new_state,
            ado_issue_type_and_status: null,
            loading_ado_status_project: false,
            error_in_loading_ado_issues: error_msg
        }));
    }


    function fetchJiraIssueTypeList(selected_integration, selected_project) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.jira_issue_type) + "?ticketing_name=" + selected_integration + "&project=" + selected_project,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (res) => { fetchJiraIssueTypeListSuccess(res, selected_integration, selected_project) }, fetchJiraIssueTypeListFailure);
        setState(new_state => ({
            ...new_state,
            loading_issue_type: true,
            error_in_loading_issue_type: null
        }));
    }

    function fetchJiraIssueTypeListSuccess(response, selected_integration, selected_project) {
        fetchJiraTicketStatus(selected_integration, selected_project)
        var issue_type = response.issue_types.map(item => {
            return { label: item, id: item }
        })


        setState(new_state => ({
            ...new_state,
            jira_issue_type: issue_type,
            loading_issue_type: false,
            error_in_loading_issue_type: null
        }));

    }
    function fetchJiraIssueTypeListFailure(error) {
        var error_msg = showErrorHandlerUpdated(error)
        setState(new_state => ({
            ...new_state,
            loading_data: false,
            error_in_loading_issue_type: error_msg
        }));
    }


    const fetchJiraTicketStatus = (selected_integration) => {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_jira_ticket_status) + "?ticketing_name=" + selected_integration,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchJiraTicketStatusSuccess, fetchJiraTicketStatusFailure);
        setState(new_state => ({
            ...new_state,
            loading_available_status: true,
            error_in_loading_status: null
        }));
    }

    const fetchJiraTicketStatusSuccess = (response) => {
        var status_list = response.detail.map(item => {
            return { label: item.name, id: item.name }
        })

        setState(new_state => ({
            ...new_state,
            jira_status_list: status_list,
            loading_available_status: false,
            error_in_loading_status: null
        }));
    }

    const fetchJiraTicketStatusFailure = (error) => {
        let error_msg = showErrorHandlerUpdated(error)
        setState(new_state => ({
            ...new_state,
            error_in_loading_status: error_msg,
            loading_available_status: false,
        }));
    }

   


    return (
        <Root>
            {
                state.redirect && <Navigate to="/release-package/configuration" />
            }
            
            <Stepper activeStep={activeStep} orientation="vertical">
                {
                    configuration_type === "default-configuration" ?
                        steps.map((label, index) => (
                            <Step key={label}>
                                <CustomStepLabel StepIconComponent={CustomStepIcon}>
                                    {label}
                                </CustomStepLabel>
                                <StepContent>
                                    {getStepContent(index)}
                                    <ActionsContainer>
                                        <button className='btn btn-primary mr-5' onClick={handleNext}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </button>

                                        <button className='btn btn-outline-primary'
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </button>
                                    </ActionsContainer>
                                </StepContent>
                            </Step>
                        ))
                        :
                        appConfigSteps.map((label, index) => (
                            <Step key={label}>
                                <CustomStepLabel StepIconComponent={CustomStepIcon}>
                                    {label}
                                </CustomStepLabel>
                                <StepContent>
                                    {getAppConfigStepContent(index)}
                                    <ActionsContainer>
                                        <button className='btn btn-primary mr-5' onClick={handleNext}>
                                            {activeStep === appConfigSteps.length - 1 ? 'Finish' : 'Next'}
                                        </button>

                                        <button className='btn btn-outline-primary'
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </button>
                                    </ActionsContainer>
                                </StepContent>
                            </Step>
                        ))
                }

            </Stepper>
            <CustomSnackbar
                open={snackbar.open}
                severity={snackbar.severity}
                message={snackbar.message}
                onClose={handleSnackbarClose}
            />


        </Root>
    );
}
