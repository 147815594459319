import React, { useState, useRef } from "react";
import { styled } from '@mui/system';
import { FormatTime } from "../../../../util/util";
import { Link } from 'react-router-dom';


const MsCanaryCard = ({ informationData, getSymbol, activityDetailsDuration, selectedInfoId, updateSelectedInformationId, component_name, env_name }) => {
    const [state, setState] = useState({
        open: false
    })
    const count = useRef(0);
    const open = true
    const toggleView = (id) => {
        updateSelectedInformationId(id)
    }
    // const start_time = new Date(informationData.activity_status && informationData.activity_status.start_time ? informationData.activity_status.start_time : "");
    // const end_time = new Date(informationData.activity_status && informationData.activity_status.start_time ? informationData.activity_status.end_time : "");
    // const diff = end_time - start_time;
    // const time_taken = FormatTime(diff);

    // open && console.log('recalling_state_everytime', selectedInfoId, informationData)
    return (<>
        <SubHeader>
            <label className='deployment-name'>
                service_name
            </label>
            <Link
                to={"/logs"
                }
                title="view logs"
                target="_blank"
                // onClick={showLogs} 
                className="flaticon-google-drive-file"></Link>

            <div
                className={"status-div-11 green-text"}>&nbsp;Success&nbsp;</div>

            <div className="duration">12s</div>
            {
                open ?
                    <div className="flaticon-expand-arrow" onClick={() => { }}></div> :
                    <div className="flaticon-expand-button" onClick={() => { }}></div>
            }
        </SubHeader>
        {
            open &&
            <>
                <BodyArea style={{ padding: '5px 15px' }}>
                    <div className="d-flex align-center space-between font-12">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>Matrices</p>
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>Results</p>
                    </div>
                    <div className="d-flex align-center space-between font-12 mb-5 mt-5">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>CPU</p>
                        <p className="success_chip_particals" style={{color:'rgba(255, 255, 255, 0.50);'}}>pass</p>
                    </div>
                    <div className="d-flex align-center space-between font-12 mb-5 mt-5">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>Error Request</p>
                        <p  className="success_chip_particals" style={{color:'rgba(255, 255, 255, 0.50);'}}>pass</p>
                    </div>
                    <div className="d-flex align-center space-between font-12 mb-5 mt-5">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>Incoming TCP Connections</p>
                        <p className="success_chip_particals" style={{color:'rgba(255, 255, 255, 0.50);'}}>pass</p>
                    </div>
                    <div className="d-flex align-center space-between font-12 mb-5 mt-5">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>latency 50th</p>
                        <p className="success_chip_particals" style={{color:'rgba(255, 255, 255, 0.50);'}}>pass</p>
                    </div>
                    <div className="d-flex align-center space-between font-12 mb-5 mt-5">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>latency 90th</p>
                        <p className="failed_chip_particals" style={{color:'rgba(255, 255, 255, 0.50);'}}>failed</p>
                    </div>
                    <div className="d-flex align-center space-between font-12 mb-5 mt-5">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>Load Average</p>
                        <p className="success_chip_particals" style={{color:'rgba(255, 255, 255, 0.50);'}}>pass</p>
                    </div>
                    <div className="d-flex align-center space-between font-12 mb-5 mt-5">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>Outgoing TCP Connections</p>
                        <p className="failed_chip_particals" style={{color:'rgba(255, 255, 255, 0.50);'}}>failed</p>
                    </div>
                    <div className="d-flex align-center space-between font-12 mb-5 mt-5">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>Successful requests</p>
                        <p className="success_chip_particals" style={{color:'rgba(255, 255, 255, 0.50);'}}>pass</p>
                    </div>
                    <div className="d-flex align-center space-between font-12 mb-5 mt-5">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>Threads</p>
                        <p className="failed_chip_particals" style={{color:'rgba(255, 255, 255, 0.50);'}}>failed</p>
                    </div>
                    <div className="d-flex align-center space-between font-12 mb-5 mt-5">
                        <p style={{color:'rgba(255, 255, 255, 0.50);'}}>Error Request</p>
                        <p className="success_chip_particals" style={{color:'rgba(255, 255, 255, 0.50);'}}>pass</p>
                    </div>
                </BodyArea>
            </>
        }

    </>)
}

export default MsCanaryCard;

const SubHeader = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    background: '#6b74a8',
    borderBottom: '1px solid #badcde',
    '& .deployment-name': {
        color: '#b5bbd9',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        transition: 'all .3s ease',
    },
    '& .flaticon-expand-arrow:before, .flaticon-expand-button:before': {
        fontSize: '10px !important'
    },
    '& .flaticon-expand-button': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        color: '#fff',
        transition: 'all .3s ease',
    },
    //
    "& .flaticon-google-drive-file": {
        color: '#fff',
        cursor: 'pointer',
        margin: '0px 8px',
        fontSize: '11px !important'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        color: '#b5bbd9',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .service-des': {
        color: '#b5bbd9',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
    },
    '& .status-div': {
        fontSize: '12px',
    },
    '& .status-div-11': {
        fontSize: '10px',
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    }
})

const BodyArea = styled('div')({
    '& .success_chip_particals': {
        fontSize: '12px',
        fontWeight: '600',
        color: '#fff',
        backgroundColor: '#2ACD7F;',
        borderRadius: '4px',
        padding: '2px 4px'
    },
    '& .failed_chip_particals': {
        fontSize: '12px',
        fontWeight: '600',
        color: '#fff',
        backgroundColor: '#E04D4D',
        borderRadius: '4px',
        padding: '2px 4px'
    },
})
