import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HorizontalTab from '../../../../../components/wizardComponents/HorizontalTab';
import InfraSection from './panels/InfraSection';
import NetworkSection from './panels/NetworkSection';
import Nodes from './panels/Nodes';

const panel_list = [
    { order: 1, mainText: "Infra Details" },
    { order: 2, mainText: "Network Details" },
    { order: 3, mainText: "Nodes Details" }
];


function DetailFormWizards(props) {
    const classes = useStyles();
    const inherits = props.inherits;
    const [state, setState] = useState(
        {
            selectedTabOrder: 1,
            panels: {}
        }
    );

    // Overriding inherits

    inherits.getSelectedTabOrder = () => {
        return state.selectedTabOrder;
    }

    inherits.moveNext = () => {
        const selceted_tab_order = state.selectedTabOrder;
        setState(new_state => ({
            ...new_state,
            panels: {
                ...new_state.panels,
                [selceted_tab_order]: inherits.getState()
            },
            selectedTabOrder: (selceted_tab_order + 1)
        }));
    }

    inherits.moveBack = () => {
        const selceted_tab_order = state.selectedTabOrder;
        if (state.selectedTabOrder != 1) {
            setState(new_state => ({
                ...new_state,
                panels: {
                    ...new_state.panels,
                    [selceted_tab_order]: inherits.getState()
                },
                selectedTabOrder: (selceted_tab_order - 1)
            }));
        }
    }

    inherits.getData = () => {
        const panels = state.panels;
        panels[3] = inherits.getState();
        return panels;
    }
    console.log("yamank-", state)
    function getActivePanel() {

        switch (state.selectedTabOrder) {
            case 1:
                return <InfraSection prev_state={state.panels[state.selectedTabOrder]} inherits={inherits} />;
            case 2:
                return <NetworkSection prev_state={state.panels[state.selectedTabOrder]} zones={state.panels[1].detail_section.data.zone} inherits={inherits} />;
            case 3:
                return <Nodes prev_state={state.panels[state.selectedTabOrder]} inherits={inherits} />;
            default:
                return <h1>404 Tab Not found</h1>
        }
    }

    console.log("hello==============>", state);

    return (
        <>
            <HorizontalTab
                tabDataList={panel_list}
                tabState={{ selectedTabOrder: state.selectedTabOrder }} />
            <div className={classes.boxmodel}>
                <>
                    {getActivePanel()}
                </>
                <div className={classes.summarybox}>
                    <div className="text-section">
                        <p className="question">What is Cloud Infra?</p>
                        <span className="small-text">Have Questions? </span><span className="small-text">Read our <span>Document</span></span>
                    </div>
                    {
                        state.panels ? Object.keys(state.panels).includes("1") ?
                            <>
                                <CloudProvider>

                                    {
                                        state.panels["1"].data.infra_provider_id == "AWS" ? <>
                                            <div className="img-box">
                                                <img src="/images/logos/aws.png" alt=".." />
                                            </div>
                                            <div className="cloud-data">
                                                <p className="text-color-grey">Amazon Web Services</p>
                                                <span className="text-color-black">Region:</span>
                                                <span className="text-color-grey"> {state.panels["1"].detail_section.data.region}</span>
                                                {/* <div>
                                                    <span className="text-color-black">Zone:</span>
                                                    {state.panels["1"].detail_section.data.zone.map(zone => (
                                                        <span className="text-color-grey">{zone},</span>
                                                    ))}

                                                </div> */}
                                                <div>                                                <span className="text-color-black">AMI ID:</span>
                                                    <span className="text-color-grey"> {state.panels["1"].detail_section.data.ami_id}</span>
                                                </div>

                                            </div>
                                        </> : <></>
                                    }

                                </CloudProvider>
                            </> : <></> : null
                    }
                    {
                        state.panels ? Object.keys(state.panels).includes("2") ?
                            <>
                                <div className="seprator"></div>
                                <p style={{ fontSize: '13px' }}>Cluster Network</p>
                                <div className="text-section">
                                    <div className="d-flex align-center" style={{ justifyContent: 'flex-start' }}>
                                        <span className="text-color-black">VPC CIDR Block: </span>
                                        <span className="text-color-grey">&nbsp;{state.panels["2"].data.vpc}</span>
                                    </div>
                                    <div className="d-flex align-center" style={{ justifyContent: 'flex-start' }}>
                                        <span className="text-color-black">Access/CIDR: </span>
                                        <span className="text-color-grey">&nbsp;{state.panels["2"].data.access == "public" ? (state.panels["2"].data.access + " / " + state.panels["2"].data.pub_cidr) :
                                            <>
                                                {state.panels["2"].data.access + " / " + (state.panels["2"].data.priv_cidr ? state.panels["2"].data.priv_cidr.length > 0 ? state.panels["2"].data.priv_cidr.map(ci => (
                                                    ci + " , "
                                                )

                                                ) : null : null)}
                                            </>}</span>
                                    </div>
                                    {/* <div className="d-flex align-center" style={{ justifyContent: 'flex-start' }}>
                                        <span className="text-color-black">Public Subnet CIDR: </span>
                                        {
                                            Object.keys(state.panels["2"].data.subnets).map(sub => (
                                                <span className="text-color-grey">&nbsp;{state.panels["2"].data.subnets[sub].public_subnet}</span>
                                            ))
                                        }
 */}
                                    {/* </div> */}
                                </div>
                                <table className="table table-responsive">
                                    <thead>
                                        <th>Zone</th>
                                        <th>Private Subnet CIDR</th>
                                        <th>Public Subnet CIDR</th>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(state.panels["2"].data.subnets).map(sub => (

                                                <tr>
                                                    <td>
                                                        {sub}
                                                    </td>
                                                    <td>
                                                        {state.panels["2"].data.subnets[sub] ? state.panels["2"].data.subnets[sub].private_subnet ? state.panels["2"].data.subnets[sub].private_subnet.length > 0 ? state.panels["2"].data.subnets[sub].private_subnet.map(m => (
                                                            m + " , "
                                                        )) : null : null : null}
                                                    </td>
                                                    <td>
                                                        {state.panels["2"].data.subnets[sub].public_subnet}
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </> : <></> : null
                    }
                    {
                        state.panels ? Object.keys(state.panels).includes("3") ?
                            <>
                                <div className="seprator"></div>
                                <p style={{ fontSize: '13px' }}>Node Details</p>
                                <div className="text-section">
                                    <div className="d-flex align-center" style={{ justifyContent: 'flex-start' }}>
                                        <span className="text-color-black">Master Node Type/vol/count: </span>
                                        <span className="text-color-grey">&nbsp;{state.panels["3"].data.master_node_instance_type + " / " + state.panels["3"].data.master_node_volume + " / " + state.panels["3"].data.master_node_count}</span>
                                    </div>
                                    {/* <div className="d-flex align-center" style={{ justifyContent: 'flex-start' }}>
                                        <span className="text-color-black">Public Subnet CIDR: </span>
                                        <span className="text-color-grey">&nbsp;10.250.192.018</span>
                                    </div> */}
                                </div>
                                <span className="text-color-black">Worker Nodes  </span>
                                <table className="table table-responsive">
                                    <thead>
                                        <th>Group Name/Type</th>
                                        <th>Node Size</th>
                                        <th>Node Count</th>
                                    </thead>
                                    <tbody>
                                        {
                                            state.panels["3"].worker_node_data_list.map(sub => (

                                                <tr>
                                                    <td>
                                                        {sub.group_name + " / " + sub.worker_node_instance_type}
                                                    </td>
                                                    <td>
                                                        {sub.worker_node_size}
                                                    </td>
                                                    <td>
                                                        {sub.worker_node_count}
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>

                            </> : <></> : null
                    }


                </div>
            </div>
        </>
    );
}
DetailFormWizards.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default DetailFormWizards;




const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: '3rem'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    mainDivSelected: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '.5rem 1.25rem',
        borderRadius: '30px 0px 0px 30px',
        margin: '20px 0px 20px 20px'
    },
    mainDiv: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '160px',
        backgroundColor: '#fff',
        padding: '.5rem 1.25rem',
        borderRadius: '30px 30px 30px 30px',
        margin: '20px'
    },
    count: {
        width: '30px',
        height: '30px',
        border: '1px solid #ccc',
        borderRadius: '50%',
        textAlign: 'center',
        paddingTop: '2px'
    },
    text: {
        fontSize: '11px',
        fontWeight: 400,
    },
    mainBodyDiv: {
        backgroundColor: '#f6f8f8',
    },
    countBody: {
        backgroundColor: '#1d5b81',
        color: '#fff',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 800
    },
    tabBodyDivHeading: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '1rem',
        borderBottom: '1px solid #ccc'
    },
    textBody: {
        fontSize: '16px',
        fontWeight: 300,
        color: '#000'
    },
    formDiv: {
        padding: '0px 10px',
        margin: '0px 0px',
        backgroundColor: '#f9f9f9'
    },
    InputLabel: {
        fontSize: '12px',
        fontWeight: 400,
    },
    hindText: {
        fontSize: '10px',
        color: '#666',
        fontWeight: 400,
        marginLeft: '.5rem'
    },
    stepper: {
        margin: '1rem 0rem'
    },
    subHeading: {
        fontSize: '12px',
        color: '#666',
        fontWeight: 400,
        marginBottom: '1rem'
    },
    impMakr: {
        color: 'red'
    },
    boxmodel: {
        display: 'grid',
        gridTemplateColumns: '70% 30%',
        backgroundColor: '#f9f9f9'
    },
    summarybox: {
        borderLeft: '1px solid #dedede',
        backgroundColor: '#fff',
        padding: '10px',
        '& .text-section': {
            '& .question': {
                fontSize: '14px'
            },
            '& .small-text': {
                fontSize: '12px'
            },
            '& a': {
                fontSize: '12px',
                margin: '0px'
            }
        }
    }
}));


const CloudProvider = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '& .img-box': {
        backgroundColor: '#ebeeef',
        borderRadius: '8px',
        padding: '1rem',
        width: '60px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& .img-box img': {
        width: '35px',
        display: 'inline-block',
        margin: 'auto',
    },
    '& .text-section': {
        marginLeft: '10px'
    },
    '& .text-section .text-black': {
        color: '#000',
        fontSize: '12px',
        lineHeight: 'normalize',
        fontWeight: 500
    },
    '& .text-section .text-grey': {
        color: '#979ba2',
        fontSize: '11px',
        lineHeight: '15px',
        textTransform: 'capitalize'
    },
    '& .cloud-data': {
        fontSize: '12px',
        marginLeft: '5px',
        lineHeight: '18px',
        '& .width-fixed': {
            width: '100px'
        },
        '& .text-color-grey': {
            color: '#989898',
            marginLeft: '3px'
        },
        '& .text-color-black': {
            marginLeft: '3px'
        }
    }
});
