import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import '../../../../../assets/style.css'
import {Input} from '../../../../../components/genericComponents/Input';
import Grid from '@mui/material/Grid';
import PageHeader from '../../Components/PageHeader';
const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1 ,
      marginTop:'3rem'  
    }
  }));



const LivelinessProbe =(props) => {
  const classes = useStyles();
  const data = props.data;
  const error = props.error;
  const update_data_callback = props.update_data_callback;

  const onChangeHandler = (e) => {
    
    switch(e.target.name){
      case "liveliness":
        update_data_callback(e.target.name,!data[e.target.name]);
        break;
      case "readiness":
        update_data_callback(e.target.name,!data[e.target.name]);
        break;
      case "readiness_url":
        update_data_callback(e.target.name,e.target.value);
        break;
      case "liveliness_url":
        update_data_callback(e.target.name,e.target.value);
        break;
      default:
        update_data_callback(e.target.name,Number(e.target.value) ? Number(e.target.value) : "");
    }

  }
 

  return(
    <>
   <PageHeader title="Setup Liveness & Readiness probes for your Service" service_name={props.service_name} env_name={props.env_name} sub_env_name={props.sub_env_name} />
    <div className={classes.formDiv}>
      <Grid container justify="center">
          <div className='fromClass'>
          <Grid lg={12}>
            <Grid container>
              <Grid lg={12} direction="row" style={{padding:'10px 0px'}}>
              <div className='card'>
              <div className='heading'>
                <Input 
                type="switch" 
                name="liveliness" 
                label="Do you have liveness health check URL?"
                onChangeHandler={onChangeHandler} 
                data={data} 
                error={error}/>
              </div>
              
              {data.liveliness ?
              
               
              <div className='card-body' style={{padding:'10px'}}>
              <Grid container spacing={2}>
              <Grid lg={6} item direction="row">
                <Input 
                  type="text"
                  name="liveliness_url"
                  label="URL or Path"
                  placeholder="/demo-app/healthcheck"
                  error={error} 
                  onChangeHandler={onChangeHandler} 
                  data={data}
                />
              </Grid>
              <Grid lg={6} item direction="row">
                  <Input 
                  type="text" 
                  name="liveliness_port" 
                  label="Port Running on"
                  placeholder="8000"
                  onChangeHandler={onChangeHandler} 
                  data={data} 
                  error={error}/>
              </Grid>
              </Grid>
              <div>
                  <span className="InputLabel">Set Liveness Time Settings</span>
                  <div style={{display:'grid', gridTemplateColumns:'auto auto auto auto', gridGap:"5px"}}>
                      <Input 
                      type="text" 
                      name="liveliness_delay" 
                      label="Delay"
                      placeholder="5"
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>
                      <Input 
                      type="text" 
                      name="liveliness_period" 
                      label="Period"
                      placeholder="5"
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>
                      <Input 
                      type="text" 
                      name="liveliness_timeout" 
                      label="Timeouts"
                      placeholder="25"
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>
                      <Input 
                      type="text" 
                      placeholder="100"
                      name="liveliness_failure" 
                      label="Failure"
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>
                  </div>
              </div>
              </div>:null  
            }
            </div>
              </Grid>
              <Grid lg={12} direction="row" style={{padding:'10px 0px'}}>
              <div className='card'>
              <div className='heading'>
                <Input 
                type="switch" 
                name="readiness" 
                label="Do you have readiness health check URL?"
                onChangeHandler={onChangeHandler} 
                data={data} 
                error={error}/>
              </div>
              
              {data.readiness ?
              
              <div className='card-body' style={{padding:'10px'}}>
                  <Grid container spacing={2}>
                     <Grid lg={6} item direction="row">
                        <Input 
                        type="text"
                        name="readiness_url"
                        label="URL or Path"
                        placeholder="/demo-app/healthCheck"
                        error={error} 
                        onChangeHandler={onChangeHandler} 
                        data={data}
                        />
                      </Grid>
                      <Grid lg={6} item direction="row">
                        <Input 
                        type="text" 
                        name="readiness_port" 
                        label="Port Running on"
                        placeholder="8080"
                        onChangeHandler={onChangeHandler} 
                        data={data} 
                        error={error}/>
                      </Grid>
                  </Grid>

              <div>
                  <span className="InputLabel">Set Liveness Time Settings</span>
                  <div style={{display:'grid', gridTemplateColumns:'auto auto auto auto', gridGap:"5px"}}>
                      <Input 
                      type="text" 
                      name="readiness_delay" 
                      label="Delay"
                      placeholder="5"
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>
                      <Input 
                      type="text" 
                      name="readiness_period" 
                      label="Period"
                      placeholder="5"
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>
                      <Input 
                      type="text" 
                      name="readiness_timeout" 
                      label="Timeouts"
                      placeholder="20"
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>
                      <Input 
                      type="text" 
                      name="readiness_failure" 
                      label="Failure"
                      placeholder="100"
                      onChangeHandler={onChangeHandler} 
                      data={data} 
                      error={error}/>
                  </div>
              </div>
              </div>:null  
            }
            </div>
              
              </Grid>
          </Grid>
          </Grid>
          </div>
      </Grid>
    </div> 
    </>
    );
};


LivelinessProbe.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default LivelinessProbe;
