import React from "react";
import PropTypes from 'prop-types';

const RunningDotsComponent = ({ isFromEventSidePannel, pipelineCard }) => {
    return (
        <>
            {
                isFromEventSidePannel ?
                    <div style={{ marginTop: '12px' }} className="bouncing-loader d-flex justify-center">
                        <div style={{
                            backgroundColor: '#0086FF'
                        }}></div>
                        <div
                            style={{
                                backgroundColor: '#0086FF'
                            }}></div>
                        <div
                            style={{
                                backgroundColor: '#0086FF'
                            }}></div>
                    </div>
                    :
                    pipelineCard ?

                        <div style={{ marginTop: '8px' }} className="bouncing-loader d-flex justify-center">
                            <div style={{
                                backgroundColor: '#0086FF'
                            }}></div>
                            <div
                                style={{
                                    backgroundColor: '#0086FF'
                                }}></div>
                            <div
                                style={{
                                    backgroundColor: '#0086FF'
                                }}></div>
                        </div>
                        :

                        <div style={{ marginTop: '8px' }} className="bouncing-loader d-flex justify-center">
                            <div style={{
                                backgroundColor: '#fff'
                            }}></div>
                            <div
                                style={{
                                    backgroundColor: '#fff'
                                }}></div>
                            <div
                                style={{
                                    backgroundColor: '#fff'
                                }}></div>
                        </div>
            }
        </>

    );
};

RunningDotsComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default RunningDotsComponent;