import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import { Input } from '../../../../components/genericComponents/Input'
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails'
import properties from '../../../../properties/properties'
import { useParams } from 'react-router-dom'
import InvokeApi from '../../../../util/apiInvoker'
import GenerateURL from '../../../../util/APIUrlProvider'
import { getCiList } from '../../../pipeline/add/components/JobForm'
import { Loading } from '../../../utils/Loading'
import { ErrorComponent } from '../../../utils/Error'
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator'


const CanaryTemplate = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const {application_id} = useParams();
    const [state, setState] = useState(prev_state ? prev_state : getCanaryTemplateDropDownDefaultState())
    const extraProps = props.extraProps;
    const variant = props.variant;
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    
    useEffect(() => {
        fetchCanaryTemplatesInfo();
    }, [application_id]);

    function fetchCanaryTemplatesInfo() {

        var requestInfo = {
            endPoint: GenerateURL({ application_id: application_id }, properties.api.canary_template_listing),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
       
        InvokeApi(requestInfo, canaryTemplatesInfoFetchSuccess, canaryTemplatesInfoFetchFailure);

        setState(new_state => ({
            ...new_state,
            data_loading: true,
        }));
    }

    function canaryTemplatesInfoFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            data_loading: false,
            canary_data: data.results,
            canary_template_listing: getCiList(data.results)
        }));
    }
    function canaryTemplatesInfoFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            data_loading:false,
            error_msg: error,
        }));
    }
   
    return (
        <>
        {
           state.data_loading ?<div style={{height: 100}}><Loading varient="light" /></div>  :
           state.canary_data && state.canary_data.length> 0 ? 
            <div>
                <div className="pd-10">
                <Input
                type="select"
                label="Select Template"
                name="release_template_id"
                mandatorySign
                list={state.canary_template_listing}
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
                />
               {
                    // variant === "list_only"? null: 
                    state.canary_data.map(item=>(
                        item.id == state.data && state.data.release_template_id? 
                    <div className="card box-shadow-none m-0" style={{borderRadius: 4}}>
                        <div className="card-header font-12 border-bottom bg-f7-fc" style={{paddingBottom:10, borderTop:'none'}}>
                            <span>Traffic shift to new deployment <span className="text-grey-72">(%)</span></span>
                        </div>
                        <div className="card-body">
                            <div className="canary_template_row">
                                {item.phase_release_template.map(elem=>(
                                    <span className="row_canary">
                                        <div className="bar-grid">
                                            <p className="font-12">{elem.name}</p>
                                            <p className="" style={{fontSize:34, fontWeight: 300, color:'#004085'}}>{elem.traffic_shift_to_new_deployment_in_pct}%</p>
                                        </div>
                                        <div className="arrow_icon">
                                            <ArrowForwardIcon  style={{color:"#fea010", fontSize:24}} />
                                        </div>
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>:null
                    ))
                } 
            </div>
            </div>
            : <ErrorComponent variant={"msg-box"} error={state.error_msg? state.error_msg : "To choose template you need to add a template first"} />
        }
        </>
    )
}

CanaryTemplate.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default CanaryTemplate

export function getCanaryTemplateDropDownDefaultState(){
    return{
        data:{
            release_template_id:""
        },
        error_msg:null,
        error:{},
        validations:{
            release_template_id: [VALIDATION_TYPE_REQUIRED]
        },
        canary_template_listing:[],
        canary_data:[],
        data_loading: false
    }
}