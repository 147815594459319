import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DependencyDialog = ({
  action_name,
  dialog_open = false,
  entity_name,
  dependency_data,
  clearDependencyError,
}) => {
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    clearDependencyError();
  };

  const actionName = action_name ? action_name : 'delete';

  useEffect(() => {
    setOpen(dialog_open);
  }, [dialog_open]);

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle style={{ paddingLeft: '0px' }} id="max-width-dialog-title" className="text-left">
        <div className="d-flex align-center text-center" style={{ marginTop: '30px', flexDirection: 'column' }}>
          <span className='ri-close-line' style={{ fontSize: '40px', color: '#ff4747', margin: '0px 8px' }} />
          <div className="dialogue-heading" style={{ flexDirection: 'column' }}>
            <div className="mr-5">You can not perform {actionName} for</div>
            <div>
              {entity_name ? <>&ldquo;</> : null}
              <span className="text-color-dark-gray">{entity_name}</span>
              {entity_name ? <>&rdquo;</> : null}
            </div>
          </div>
        </div>
        <p className="text-red mtb-20 ml-10">
          It has following dependencies which are restricting {actionName} operation.<br />
        </p>
      </DialogTitle>
      <DialogContent style={{ borderTop: '1px solid #dedede' }}>
        <DialogContentText>
          <p className="text-red mtb-10">
            {(dependency_data ? Object.keys(dependency_data).length : 0) + ' dependencies are there!'}
          </p>
          <div>
            {dependency_data
              ? Object.keys(dependency_data).map((data) => (
                  <div className="pd-10 card" key={data}>
                    <p className="font-12 pd-5 border-bottom">{data}</p>
                    {dependency_data[data] ? (
                      dependency_data[data][0].name ? (
                        <div>
                          {dependency_data[data]
                            ? dependency_data[data].map((dep, index) => (
                                <div className="d-flex space-between align-center font-12 pd-5" key={index}>
                                  <p className="">{dep.name ? dep.name : index + 1}</p>
                                </div>
                              ))
                            : null}
                        </div>
                      ) : (
                        dependency_data[data].length
                      )
                    ) : null}
                  </div>
                ))
              : null}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className="justify-flexend"
        style={{ backgroundColor: '#f9f9f9', justifyContent: 'flex-end!important' }}
      >
        <div></div>
        <div className="d-flex align-center justify-flexend">
          <button className="btn btn-secondary-outline" onClick={handleClose}>
            Close
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DependencyDialog;
