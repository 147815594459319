import YAML from 'yaml';
import { GetTaskTypeIdFromString, GetTaskTypeStringFromId } from '../util/util';

const task_type_list = [
  "build",
  "deploy",
  "promote",
  "api_call",
  "configmaps",
  "secrets",
  "jira",
  "rollback",
  "integration",
  "pipeline_invoke",
  "canary_analysis"
];

const artifact_source_list = [
  "LATEST",
  "GENERATED",
  "TAG",
];

const task_method_list = [
  "GET",
  "POST"
];


export function ConvertPipelineYAMLtoObj(yaml,application_services,all_project_envs){
  console.log(application_services, "lqlqlqlq___")
  console.log(yaml, "lqlqlqlq___")
  try {
    var result = YAML.parse(yaml);
    console.log(result,"abchjdbchgsdvgc")
    if(typeof(result) == "string"){
      console.log(result, typeof(result), "lqlqlqlq___")
      return null;
    }else{
      console.log(result,"lqlqlqlq___")
      var result = parseAndValidateYamlBasedObjToUIBased(result,application_services,all_project_envs);
      console.log(result,"lqlqlqlq___")
      return result;
    }  
  } catch (error) {
    console.log("pipeline parsing",error);
    return null;
  }
}

function parseAndValidateYamlBasedTasksForStage(tasks,stage_name,application_services,all_project_envs, pipelines){
  
  const parsing_result = {
    errors:[],
    task:[]
  }
  tasks.forEach((task,key)=>{
    console.log(task,"ahcadhcjbsdhc")
    if(!task.type){
      parsing_result.errors.push("Task type is not provided in task "+ key+" of "+stage_name);
    }else if(!(task_type_list.includes(task.type.toLowerCase()))){
      parsing_result.errors.push("Invalid Task type in task "+ key+" of "+stage_name);
    }else if(!task.services && !(task.type.toLowerCase()=="api_call") &&!(task.type.toLowerCase()=="jira") ){
      parsing_result.errors.push("No services are provided for the task "+ key+" of "+stage_name);
    }else{
      
      let task_obj = {}
      
      if(task.name){
        task_obj.name=task.name;
      }
      task_obj.task_run_condition = task.task_run_condition
      task_obj.task_type = GetTaskTypeIdFromString(task.type);
      task_obj.on_failure_exit = task.on_failure_exit? true : false;
      task_obj.on_success_exit = task.on_success_exit ? true : false;
      task_obj.skip_task = task.skip_task ? true : false;
      task_obj.mark_success_upon_skip = task.mark_success_upon_skip? true: false;
      if(task_obj.task_type != 5 && task_obj.task_type != 10){
        let service_validation_result = validateAndParseServices(application_services,task.services,"task "+(key+1)+" of stage "+stage_name);
        console.log(service_validation_result,"service_validation_result")
        if(service_validation_result.parsable){
          if(service_validation_result.errors){
            console.log(tasks,stage_name, application_services, "application_service_name")
            parsing_result.errors.push(service_validation_result.errors.toString());
          }

          task_obj.components = service_validation_result.services;
          console.log(task.env, all_project_envs, "application_service_name")
          let env_validation_result = isEnvValidForAllServices(service_validation_result.valid_service_list,task.env, all_project_envs);
          console.log(tasks,stage_name, application_services, "application_service_name")
          console.log(env_validation_result,"env_validation_result")
          if(env_validation_result.parsable){
            task_obj.project_env = env_validation_result.task_source_env;
            task_obj.env = env_validation_result.task_source_env.id;
            switch(task_obj.task_type){
               
              case 1:
                
                task_obj.tag = task.tag;
                task_obj.latest_enable = task.latest_enable ? true : false;
                task_obj.no_cache = task.no_cache ? true : false;
                break;
              case 2:
                if(task.artifact_source && artifact_source_list.includes(task.artifact_source.toUpperCase())){
                  task_obj.artifact_source = task.artifact_source.toUpperCase();
                }else{
                  task_obj.artifact_source = task.tag ? "TAG" : "GENERATED";
                }
                task_obj.tag = task.tag;
                task_obj.deployment_type = task.deployment_type;
                task_obj.pod_shift_percentage = task.pod_shift_percentage;
                break;
              case 3:
                if(task.artifact_source && task_type_list.includes(task.artifact_source.toUpperCase())){
                  task_obj.artifact_source = task.artifact_source.toUpperCase();
                }else{
                  task_obj.artifact_source = task.artifact_source;
                }
                task_obj.tag = task.tag;

                let target_env_id = task.target_env ? envIdFromName(all_project_envs,task.target_env) : null;
                if(target_env_id){
                  if(target_env_id == task_obj.env){
                    parsing_result.errors.push("Current and target env are same in task "+(key+1)+" of stage "+stage_name);
                    task_obj=null;
                  }else{
                    task_obj.target_env = target_env_id;
                  }
                }else{
                  parsing_result.errors.push("Invalid or No target Env provided in task "+(key+1)+" of stage "+stage_name);
                  task_obj = null;
                }
                break;
              case 11:
                if(task.rollback_version){
                  task_obj.rollback_version = task.rollback_version;
                }
                  break;
              case 13:
                console.log(pipelines, task.pipelines, "fdsjakjfnjasnjda")
                if(pipelines && pipelines.length > 0){
                  task_obj.pipelines = getPipelineNameFromId(pipelines,task.pipelines);
                }
                task_obj.suspend_pipeline = task.suspend_pipeline;
                  break;
            }
          }else{
            console.log(parsing_result,"fjanjsafjsajdfja")
            task_obj = null;
          }
        }else{
          console.log(parsing_result,"fjanjsafjsajdfja")
          parsing_result.errors.push(service_validation_result.errors.toString());
          task_obj = null;
        }
      }else{
        if(task_obj.task_type == 10){
          task_obj.operation = task.operation;
          task_obj.issue_key = task.issue_key;
          if(task.operation == "create"){
            task_obj.summary = task.summary
            task_obj.description = task.description
            task_obj.issuetype = task.issuetype
            task_obj.priority = task.priority
            task_obj.account_id = task.account_id
            task_obj.issue_name = task.issue_name;
            task_obj.epic_name = task.epic_name;
            task_obj.issue_key = task.issue_key;
            task_obj.ticketing_project = task.ticketing_project;
            task_obj.additional_field_key_value = task.additional_field_key_value;
          }
          if(task.operation == "status_update"){
            task_obj.issuetype = task.issuetype
            task_obj.status = task.status
            task_obj.additional_field_key_value = task.additional_field_key_value;
            task_obj.issue_key = task.issue_key;
          }
          if(task.operation == "add_comment"){
            task_obj.issuetype = task.issuetype
            task_obj.comment = task.comment
            task_obj.issue_key = task.issue_key;
          }
        }else{
          if(task_obj.task_type == 5 && !task.method){
            parsing_result.errors.push("Method not provided in task "+key+" of "+stage_name);
          }else if(!task_method_list.includes(task.method.toUpperCase())){
            parsing_result.errors.push("Method not valid in task "+key+" of "+stage_name);
          }else{
            console.log(task,"task.headers_key_value_pairs")
            task_obj.method = task.method.toUpperCase();
            task_obj.url = task.url;
            task_obj.headers_key_value_pairs =  task.headers_key_value_pairs ? task.headers_key_value_pairs : task.headers_key_value_pairs == "" ? "" :  task.headers_key_value_pairs == {} ? {} : "" ;
            task_obj.pipeline_suspend = task.pipeline_suspend ? true : false;
            task_obj.response_update_context_param = task.response_update_context_param ? true : false;
            console.log(task,"task.headers_key_value_pairs")
            if(!Number(task.request_timeout)){
              parsing_result.errors.push("Request time out was invalid or not provided in task "+key+" of "+stage_name);
              task_obj.request_timeout = 3;
            }else{
              task_obj.request_timeout = Number(task.request_timeout);
            }
          }
        }
       
      }

      if(task_obj){
        parsing_result.task.push(task_obj);
      }

    }
  });
  parsing_result.errors = parsing_result.errors.length > 0 ? parsing_result.errors : null;
  console.log(parsing_result,"fjanjsafjsajdfja")
  return parsing_result;
}

function validateAndParseServices(application_services,task_services,identifier){
  console.log(application_services,task_services, "application_service_name")
  let application_services_rearranged ={} //{application_service_name:application_service}
  let application_service_names=[]
  if(application_services && application_services.length>0){
    application_services.forEach(application_service=>{
      application_services_rearranged[application_service.name]=application_service
      application_service_names.push(application_service.name)
    })
  }
   console.log(application_service_names,"application_service_names")
  let service_id_list = [];
  let valid_service_list = [];
  let errors = [];
  
  task_services.forEach(task_service_name=>{
    if(application_service_names.includes(task_service_name)){
      service_id_list.push(application_services_rearranged[task_service_name].id);
      valid_service_list.push(application_services_rearranged[task_service_name]);
    }else{
      errors.push("Service "+task_service_name+" is not in the scope of pipeline in "+identifier);
    }
  });

  let service_validation_result = {
    parsable:service_id_list.length > 0,
    services:service_id_list,
    valid_service_list:valid_service_list,
    errors:errors.length > 0 ? errors : null,
  }

  return service_validation_result;  
  
}


function isEnvValidForAllServices(pipeline_services,task_source_env_name,all_project_envs){
  console.log(pipeline_services,task_source_env_name,all_project_envs, "env_validation_result")
    let task_source_env=null  
    all_project_envs.forEach(project_env=>{
      if(project_env.name==task_source_env_name){
        task_source_env=project_env
      } 
    }) 
    
    pipeline_services.forEach(pipeline_service=>{
        if(!pipeline_service.environments.includes(task_source_env.id)){
          return {
                parsable:false,
                errors:["Invalid Enviroment"],
                task_source_env:task_source_env,
              }
        }
    })
   
    return {
      parsable:true,
      errors:null,
      task_source_env:task_source_env,
    }
}

function parseAndValidateYamlBasedObjToUIBased(obj,application_services,all_project_envs){
  const parsing_result = {
    errors:[],
    stages:[],
  }
  console.log(obj, application_services,all_project_envs,"fjanjsafjsajdfja")
  if(!(obj.stages && obj.stages.length != 0)){
    parsing_result.errors.push("No stages!");
    return parsing_result;
  }

  
  obj.stages.forEach((stage,key) => {
    console.log(stage,key,"sdvcgdhsbcghdsvc")
    let stage_name = stage.name;
    if(!stage.name){
      stage_name="stage-"+(key+1);
      parsing_result.errors.push("Stage name at stage "+(key+1)+" was missing");
    }
    console.log(parsing_result, "fjanjsafjsajdfja")
    console.log(parsing_result, "fjanjsafjsajdfja")
    let stage_obj = {
      name:stage_name,
      stage_run_condition:stage.stage_run_condition,
      order:key+1,
      approval:stage.approval ? true : false,
      approve_user: stage.approve_user,
      questionnaires: stage.questionnaires && stage.questionnaires.length > 0 ? stage.questionnaires : [] ,
      continue_on_disapproval: stage.continue_on_disapproval,
      jira_reference_approver: stage.jira_reference_approver,
      jira_issue_reference: stage.jira_issue_reference,
      send_mail: stage.send_mail,
      task:[],
    }
    console.log(stage_obj, "fjanjsafjsajdfja")
    if(!(stage.jobs && stage.jobs.length != 0)){
      
      parsing_result.errors.push("No Jobs are given for stage "+stage.name+"!");
      console.log(parsing_result, "fjanjsafjsajdfja")
    }else{
      let task_parsing_result = parseAndValidateYamlBasedTasksForStage(stage.jobs,stage_name,application_services,all_project_envs);
      console.log(task_parsing_result, "fjanjsafjsajdfja")
      if(task_parsing_result.errors){
        parsing_result.errors.push(task_parsing_result.errors.toString());
      }
      stage_obj.task = task_parsing_result.task;
    }

    parsing_result.stages.push(stage_obj);
  });
  console.log(parsing_result, "fjanjsafjsajdfja")
  return parsing_result
}

export function ConvertPipelineObjtoYAML(stages,services,all_project_envs, pipelines){
  console.log("djsbjahjbaeb",stages,services,all_project_envs, pipelines)
  const doc =new YAML.Document();
  doc.contents = parseUIBasedObjectToYaml(stages,services,all_project_envs, pipelines);
  return doc.toString();
}
export function ConvertJobTemplateObjtoYAML(data){
  const doc =new YAML.Document();
  doc.contents = data;
  return doc.toString();
}

function parseUIBasedObjectToYaml(stages,services, all_project_envs, pipelines){
  
  let yaml_based_stages=[]; 
  if(stages.length > 0){
    stages.forEach(stage=>{
      let stage_obj = {
        name:stage.name,
        approval:stage.approval ? true : false,
        approve_user: stage.approve_user,
        stage_run_condition : stage.stage_run_condition,
        questionnaires: stage.questionnaires && stage.questionnaires.length > 0 ? stage.questionnaires : [],
        jobs:parseUIBasedTaskToYaml(stage.task ? stage.task : (stage.tasks ? stage.tasks : []),services, all_project_envs, pipelines),
      }
      console.log(stage_obj,"fdsjksadfjksadf")
      yaml_based_stages.push(stage_obj);
    })
  }
  return {
    stages:yaml_based_stages
  };
}

function parseUIBasedTaskToYaml(tasks,services, all_project_envs, pipelines){
  let jobs = [];
  console.log(tasks,"sdbvgsdgcvsdgvcds")
  if(tasks.length > 0){
    tasks.forEach((task,key)=>{
      let job_obj = {
        name : task.name ? task.name : "job_"+(key+1),
        type : GetTaskTypeStringFromId(Number(task.task_type ? task.task_type : task.task_type_id)) ? GetTaskTypeStringFromId(Number(task.task_type ? task.task_type : task.task_type_id)).toUpperCase() :null,
        task_run_condition : task.task_run_condition,
        on_success_exit: task.on_success_exit,
        on_failure_exit: task.on_failure_exit,
        mark_success_upon_skip: task.mark_success_upon_skip,
        latest_enable: task.latest_enable,
        skip_task: task.skip_task,
        project_job_template_id: task.project_job_template_id? task.project_job_template_id : ""
      }

      if(task.task_type != 5){
        if(task.task_type != 10 && task.task_type != 16){
          job_obj.services=getServicesByNameFromId(services,task.components);
          job_obj.env=getEnvironemtssByNameFromId(all_project_envs,task.env)
        }
        job_obj.task_run_condition=task.task_run_condition;
        job_obj.on_failure_exit= task.on_failure_exit ? true: false;
        if([2,3].includes(Number(task.task_type))){
          if(task.task_type == 3 && task.target_env){
            job_obj.target_env = getEnvironemtssByNameFromId(all_project_envs,task.target_env);
          }
          if(task.task_type == 2){
            job_obj.deployment_type = task.deployment_type;
            job_obj.deployment_status_check = task.deployment_status_check? true: false;
            job_obj.pod_shift_percentage = task.pod_shift_percentage;
          }
          job_obj.artifact_source = task && task.artifact_source? task.artifact_source.toLowerCase(): null;
          if( task.artifact_source && task.artifact_source.toLowerCase()=="tag"){
            job_obj.tag=task.tag;
          }
        }else{
          job_obj.tag=task.tag;
        }
        if(task.task_type == 10){
          job_obj.operation = task.operation;
          job_obj.issue_key = task.issue_key;
          if(job_obj.operation == "create"){
            job_obj.summary = task.summary;
            job_obj.description = task.description;
            job_obj.issuetype = task.issuetype;
            job_obj.priority = task.priority;
            job_obj.account_id = task.account_id;
            job_obj.issue_name = task.issue_name;
            job_obj.epic_name = task.epic_name;
            job_obj.issue_key = task.issue_key;
            job_obj.ticketing_project = task.ticketing_project;
            job_obj.additional_field_key_value = task.additional_field_key_value;
          }else{
            if(job_obj.operation == "status_update"){
              job_obj.status = task.status;
              job_obj.issuetype = task.issuetype;
              job_obj.additional_field_key_value = task.additional_field_key_value;
              job_obj.issue_key = task.issue_key;
            }
              else{
                if(job_obj.operation == "add_comment"){
                  job_obj.comment = task.comment;
                  job_obj.issuetype = task.issuetype;
                  job_obj.issue_key = task.issue_key;
                }
              }
          }
        }
        if(task.task_type == 11){
          job_obj.rollback_version = task.rollback_version;
          job_obj.deployment_status_check = task.deployment_status_check;
        }
        if(task.task_type == 13){
          if(pipelines && pipelines.length > 0 ){
            job_obj.pipelines = getPipelineNameFromId(pipelines,task.pipelines);
          }
          job_obj.suspend_pipeline = task.suspend_pipeline;
        }
      }
      else{
        job_obj.method = task.method ?task.method.toLowerCase(): null;
        job_obj.url = task.url?task.url: null;
        job_obj.headers_key_value_pairs = task.headers_key_value_pairs || task.headers_key_value_pairs == "" ? task.headers_key_value_pairs : null ;
        
        job_obj.request_timeout=task.request_timeout;
        job_obj.pipeline_suspend=task.pipeline_suspend ? true : false;
        job_obj.response_update_context_param=task.response_update_context_param ? true :false;
        
      }

      jobs.push(job_obj);

    });
  }
  console.log(jobs,"sadcbdscgbsdcghds")
  return jobs
}

function getServicesByNameFromId(services,services_list_with_ids){
  let service_name_list = [];
  services.forEach(service=>{
    if(services_list_with_ids?.includes(service.id)){
      service_name_list.push(service.name);
    }
  });
  return service_name_list;
}
function getPipelineNameFromId(pipelines,pipelines_with_ids){
  let pipeline_name_list = [];
  pipelines.forEach(pipeline=>{
    if(pipelines_with_ids.includes(pipeline.id)){
      pipeline_name_list.push(pipeline.name);
    }
  });
  return pipeline_name_list;
}
function getEnvironemtssByNameFromId(environments,environment_list_with_ids){
  console.log("target_env_detail", environments, environment_list_with_ids)
  let environment_name_list = [];
  environments.forEach(env=>{
    environment_list_with_ids = Number(environment_list_with_ids);
    if(environment_list_with_ids== env.id){
      environment_name_list.push(env.name);
    }
  });
  return environment_name_list;
}
function getEnvironmentNameFromId(environments,environment_list_with_ids){
  console.log("target_env_detail", environments, environment_list_with_ids)
  let environment_name_list = "";
  environments.forEach(env=>{
    console.log("target_env_detail", environments, environment_list_with_ids)
    environment_list_with_ids = Number(environment_list_with_ids);
    console.log("target_env_detail", environments, environment_list_with_ids)
    if(environment_list_with_ids== env.id){
      console.log("target_env_detail", environments, environment_list_with_ids)
      environment_name_list = env.name;
    }
  });
  console.log("target_env_detail", environment_name_list)
  return environment_name_list;
}
function envIdFromName(all_project_envs, env_name){
  let new_env_id = null;
  all_project_envs.forEach(env=>{
    if(env_name == env.name){
      new_env_id  = env.id;
    }
  });
  return new_env_id;
}


export function GetServiceSpecificToPipeline(pipeline_service_ids,all_services){
  console.log("GetServiceSpecificToPipeline",pipeline_service_ids,all_services);
  
  let service_specific_to_pipeline = {};
  
  all_services.forEach(service=>{
    if(pipeline_service_ids.includes(service.id)){
      // service_specific_to_pipeline.push(service)
      service_specific_to_pipeline[service.name]=service
    }
  });

  return service_specific_to_pipeline;

}

export function GetPipelineSpecificToPipeline(pipeline_service_ids,all_services){
  console.log("GetServiceSpecificToPipeline",pipeline_service_ids,all_services);
  
  let service_specific_to_pipeline = {};
  
  all_services.forEach(service=>{
    if(pipeline_service_ids.includes(service.id)){
      service_specific_to_pipeline[service.name]=service
    }
  });

  return service_specific_to_pipeline;

}