import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import properties from '../../../../properties/properties';

import GitRepo from '../../../../components/genericComponents/Forms/GitRepo';
import { VALIDATION_TYPE_COMMA_SEPRATED_STRING, VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '3rem'
  },
}));



const AddEnvRevamp = props => {
  const data = props.data;
  const roles = props.roles;
  const classes = useStyles();
  //const git_repo_inherits = {};
  const available_roles = properties.languages;
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : {
    data: {
    },
    error: {},
    child_inherits: {
      "gitRepo": {}
    },
    validations: {
      language: [VALIDATION_TYPE_REQUIRED]
    }
  })

  const commonFunctions = getCommonFunctions(state, setState, inherits);



  console.log(state, "0000")


  return (
    <div className="card pd-10">
      <Grid container justify="center" alignItems="center">
        <Grid item lg={12} >
          <GitRepo id="gitRepo" inherits={state.child_inherits["gitRepo"]} state={prev_state ? {
            form_data: {
              data: state.gitRepo, error: {}, default_validations: {
                git_url: [VALIDATION_TYPE_REQUIRED],
                branch_name: [VALIDATION_TYPE_REQUIRED],
                credential_id: [],
                file_paths: [VALIDATION_TYPE_COMMA_SEPRATED_STRING],
              }
            }
          } : {}} single_path={true} />
        </Grid>
       
        

      </Grid>

    </div>
  )
}

AddEnvRevamp.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default AddEnvRevamp;


export const getCommonFunctions = (state, setState, inherits) => {

  const onChangeHandler = (e) => {
    const k = e.target.name;
    const v = e.target.value;
    console.log("event",e.target)
    updateData(k, v);
  }

  const updateData = (k, v) => {
    setState(new_state => ({
      ...new_state,
      data: {
        ...new_state.data,
        [k]: v,
      },
      validations:{
        ...new_state.validations,
        [k]:v
      }
    }));
  }

  inherits.validateForm = () => {
    const result = ValidateDataSet(state.data, state.validations)
    if (!result.valid) {
      setState(new_state => ({
        ...new_state,
        error: { ...result.error }
      }));
    }
    const childValidationStatus = validateChildComponents()
    return { valid: result.valid && childValidationStatus }
  }

  function validateChildComponents() {
    var result = true
    Object.keys(state.child_inherits).forEach(childKey => {
      result = result && state.child_inherits[childKey].validateForm().valid
    })
    return result;
  }

  inherits.getState = () => {
    const result = {
      ...state,
      ...getChildrenState()
    }
    return result;
  }

  function getChildrenState() {
    const childStates = {}
    Object.keys(state.child_inherits).forEach(childKey => {
      childStates[childKey] = state.child_inherits[childKey].getData()
    })
    return childStates;
  }

  return {
    "updatData": updateData,
    "onChangeFunction": onChangeHandler
  }

}

