import React from 'react';
import StaticPartConfiguration from './StaticPart';
import { styled } from "@mui/system";
import ConfigurationStepperForm from './ConfigurationStepperForm';
import PageHeader from '../../../../components/PageHeader';

const Root = styled('div')({
      padding:"16px",
      paddingTop:"70px",
      background:"#FFFFFF",
      display:"flex",
      flexDirection:"column",
      gap:"24px",
      overflowY:"auto"
  })

const ConfigurationSetup = () => {
    return (
        <div
            className="d-grid"
            style={{ gridTemplateColumns: "320px 1fr", height: "100vh" }}
        >
            <StaticPartConfiguration />
            <Root>
            <PageHeader
                heading="Release Package Configuration"
                subHeading=""
                commonDivMargin={true}
                icon={null}
                imgIcon="/images/release-package-config-icon.svg"
                primaryButton={{
                    action:"/release-package/configuration",
                    actionType:"link",
                    text:'',
                    icon: <span className="ri-close-line font-14"></span>,
                    buttonClass: 'btn btn-transparent'
                  }}
            />
                <ConfigurationStepperForm />
            </Root>
        </div>
    )
}

export default ConfigurationSetup