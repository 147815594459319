

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/system';
import Header from './Header';
import Grid from '@mui/material/Grid';
import GeneralData from '../component/GeneralData';
import properties from '../../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import StageProgressDetail from './StageProgressDetail';
import { Link } from 'react-router-dom';
import CardFooter from './CardFooter';
import Pagination from '../../../../components/Pagination';
import RetryPopup from './RetryPopup';
import { LoadingContainer } from '../../../utils/Loading';
import Toolbar from '@mui/material/Toolbar';

const ExecutionHistory = styled('div')({
  paddingTop: '8px',
  margin: '32px'
})

const MainHeader = styled('div')({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '60px 300px 310px 1fr'
  ,
  '& .service-name-chip-div': {
    lineHeight: 0
  },
  '& .service-name': {
    margin: '0px'
  },
  '& .service-details .owner-name .owner': {
    marginLeft: '0px'
  }
})

const CardParentDiv = styled('div')({
  backgroundColor: "#fff",
  border: '1px solid #dedede',
  borderRadius: '16px',
  boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
  marginBottom: '2rem',
  marginTop: '2rem',
  marginLeft: '32px',
  marginRight: '32px',
  '& .md-stepper-horizontal': {
    backgroundColor: '#fff'
  }
})


const PipelineStageExectuion = styled('div')({
  backgroundColor: '#fbfbfb',
  display: 'grid',
  padding: '20px',
  gridTemplateColumns: '30% 1fr',
  justifyContent: 'space-between',
  borderTop: '1px solid #eeeeee',
  borderBottom: '1px solid #eeeeee',
  '& .border-blue': {
    border: '2px solid #0086ff!important',
    color: '#0086ff'

  },
  '& .md-stepper-horizontal': {
    backgroundColor: '#fbfbfb'
  },
  '& .md-stepper-horizontal .md-step': {
    width: ''
  },
  '& .button-navigator': {
    display: 'flex',
    justifyContent: 'flex-end',
  }
})

const MainDivFlex = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 20px 10px',
  '& .pipeline-service-data': {
    margin: '0px'
  }

})

const FlexBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const useStyles = makeStyles((theme) => ({
  '.MuiPaper-root': {
    backgroundColor: '#0f7f7!important'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
    color: '#fff!important'
  },
  timeSection: {
    padding: '4px 10px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '16px',
    fontSize: '12px',
    width: 'fit-content',
    position: 'relative',
    margin: 'auto',
    zIndex: 99
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: '#ccc',
    top: '1.5rem',
    position: 'absolute',
    zIndex: 9
  },
  mainsec: {
    position: 'relative',
    margin: '32px 0px'
  },
  margin: {
    width: '32px',
    border: '2px solid rgb(0, 134, 255) !important',
    height: '32px',
    display: 'inline-block !important',
    borderRadius: '50%',
    '&.btn-filter': {
      marginRight: 5,
      marginLeft: 5
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function PipelineExecutionHistory({ reTriggerError, resetTriggerError, newUpdate, ...props }) {
  const classes = useStyles();
  const [state, setState] = React.useState({ curr_page: 1, total_page: 1, });
  const pipeline = props.pipeline;
  const application_id = props.application_id;
  const pipeline_instance = props.pipeline_instance
  const pipeline_current_page = props.pipeline_current_page;
  const current_page_no = props.current_page_no;

  console.log(pipeline, pipeline_current_page, "historydata")

  useEffect(() => {
    if (state.open) {
      requestPipelineAllExeccutions();
    }
  }, [pipeline.id]);

  function requestPipelineAllExeccutions() {
    var requestInfo = {
      endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    setState(new_state => ({
      ...new_state,
      loaded: false,
    }));
    InvokeApi(requestInfo, handleResponse, handleError);
  }

  console.log("pipeline data from props ========>", pipeline)

  function handleResponse(response) {
    updateResponseForRunComponents(response.results)
    setState(new_state => ({
      ...new_state,
      data: response.results,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous ? properties.api.baseURL + response.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: 1,
    }));
  }

  function handleError(error) {
    setState(new_state => ({
      ...new_state,
      loaded: true,
      error: error
    }));
  }

  function updateResponseForRunComponents(pipelines) {
    if (pipelines) {
      pipelines.forEach((pipeline_instance) => {
        updateRunComponetsForPipelineHistory(pipeline.components, pipeline_instance.components)
      })
    }
  }
  function updateRunComponetsForPipelineHistory(all_components, run_components) {
    var components_map = {}
    if (all_components) {
      run_components.forEach(component => {
        components_map[component.name] = component
      })

      if (run_components) {
        all_components.forEach(component => {
          if (components_map[component.name]) {
            components_map[component.name].part_of_execution = true
          } else {
            component.part_of_execution = false
            run_components.push(component)
          }

        })
      }
    }
  }

  const handleClickOpen = () => {
    setState({
      ...state,
      open: true
    });
    requestPipelineAllExeccutions();
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false
    });
  };

  console.log(state.loaded, "loaded ============>")


  //prev function 

  function fetchPrevExecutionHistoryInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
    }));
    InvokeApi(
      requestInfo,
      fetchPrevExecutionHistoryInfoSuccess,
      fetchPrevExecutionHistoryInfoFailure
    );
  }

  function fetchPrevExecutionHistoryInfoSuccess(response) {
    setState(new_state => ({
      ...new_state,
      data: response.results,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous ? properties.api.baseURL + response.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page - 1),
    }));
  }

  function fetchPrevExecutionHistoryInfoFailure(error) {
    setState((new_state) => ({
      ...new_state,
      loaded: true,
      error: error
    }));
  }

  //next function

  function fetchNextExecutionHistoryInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
    }));
    InvokeApi(
      requestInfo,
      fetchNextExecutionHistoryInfoSuccess,
      fetchNextExecutionHistoryInfoFailure
    );
  }

  function fetchNextExecutionHistoryInfoSuccess(response) {
    setState(new_state => ({
      ...new_state,
      data: response.results,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous ? properties.api.baseURL + response.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page + 1),
    }));
  }

  function fetchNextExecutionHistoryInfoFailure(error) {
    console.log("pipe exec get error", error);
    setState((new_state) => ({
      ...new_state,
      loaded: true,
      error: error
    }));
  }

  //page function

  function fetchPageExecutionHistoryInfo(enteredPageNumber) {
    var requestInfo = {
      endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&offset=" +
        (enteredPageNumber - 1) * 10;
    }

    var current_page = enteredPageNumber;

    setState((new_state) => ({
      ...new_state,
      loaded: false,
    }));
    InvokeApi(
      requestInfo,
      fetchPageExecutionHistoryInfoSuccess,
      fetchPageExecutionHistoryInfoFailure
    );
  }

  function fetchPageExecutionHistoryInfoSuccess(response) {
    setState(new_state => ({
      ...new_state,
      data: response.results,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous ? properties.api.baseURL + response.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(count),
    }));
  }

  function fetchPageExecutionHistoryInfoFailure(error) {
    console.log("pipe exec get error", error);
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      error: error
    }));
  }


  console.log('lt)pssdsd', state, pipeline_current_page)
  return (
    <div>
      <>
        <Tooltip title="View Execution History">
          <Link
            to={application_id ? {
              pathname: `/application/${application_id}/pipeline/listing/${pipeline.id}/history`,
              search: `pipeline_id=${pipeline.id}&application_id=${application_id}&current_page_no=${current_page_no}`,
            } :
              {
                pathname: `/global/pipeline/listing/${pipeline.id}/history`,
                search: `pipeline_id=${pipeline.id}&current_page_no=${current_page_no}`,
              }
            }
          >
            {
              newUpdate ?
                <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <span style={{ color: '#0086FF', fontSize: '20px', cursor: 'pointer' }} className="ri-time-line">

                  </span>
                  <span className="font-12 font-weight-500" style={{ color: '#0086FF', lineHeight: '14.63px', textTransform: 'uppercase' }}>
                    view history
                  </span>
                </span>
                :
                <button
                  className={`btn btn-transparent btn-filter MuiIconButton-root ${classes.margin}`}
                  aria-label="filter"
                >
                  <i className="ri-filter-3-line font-12" style={{ color: '#0086ff' }}></i>

                </button>
            }

          </Link>

        </Tooltip>
      </>
      <Dialog fullScreen open={state.open} onClose={handleClose} TransitionComponent={Transition} style={{ backgroundColor: '#f0f7f7' }}>
        <AppBar className={classes.appBar}>
          <Toolbar>

            <Typography variant="h6" className={classes.title}>
              Execution History of {pipeline.name}
            </Typography>
            <div style={{ margin: "15px" }}>
              <button className="btn btn-transparent" edge="end" color="inherit" onClick={requestPipelineAllExeccutions} aria-label="close">
                <i className="ri-refresh-line"></i>
              </button>
            </div>
            <button className="btn btn-transparent " edge="end" color="inherit" onClick={handleClose} aria-label="close" >
              <span className='ri-close-line font-24'  ></span>
            </button>
          </Toolbar>
        </AppBar>
        <div style={{ padding: "15px 40px 0 15px" }}>
          <div>
            <Pagination
              total_count={state.total_page}
              current_page_count={state.curr_page}
              next={state.next}
              previous={state.previous}
              on_previous_click={() => {
                fetchPrevExecutionHistoryInfo(null, state.previous);
              }}
              on_next_click={() => {
                fetchNextExecutionHistoryInfo(null, state.next);
              }}
              on_pageNumber_click={(pageNumber) => {
                fetchPageExecutionHistoryInfo(pageNumber);
              }}
            />
          </div>
        </div>
        <Grid container style={{ backgroundColor: '#f0f7f7' }}>
          <Grid item xs={12}>

            {
              state.loaded ?
                <>
                  <>
                    <>
                      {
                        state?.data?.map(pipeline_instance => (
                          <CardParentDiv>
                            <div style={{ display: 'flex', alignItems: "center", padding: '20px' }}>
                              <Header varient="without_header" application_id={application_id} pipeline_instance={pipeline_instance} pipeline={pipeline} />
                            </div>
                            <PipelineStageExectuion>

                              <GeneralData pipeline={pipeline} pipeline_instance={pipeline_instance} />
                              <StageProgressDetail pipeline={pipeline} pipeline_instance={pipeline_instance} />
                            </PipelineStageExectuion>
                            <MainDivFlex>
                              <CardFooter components={pipeline_instance.components} tags={pipeline.tags} />
                              <div className="d-flex align-center ">
                                <Link style={{ fontSize: '12px', color: 'rgb(0, 134, 255)', }} to={"/application/" + application_id + "/pipeline/" + pipeline_instance.pipeline_id + "/execution/" + pipeline_instance.id}>
                                  <button className={"btn btn-transparent" + " " + classes.margin + classes.borderBlue}
                                    style={{
                                      width: '32px',
                                      border: '2px solid rgb(0, 134, 255) ',
                                      height: '32px',
                                      display: 'inline-block',
                                      borderRadius: '50%'
                                    }} title="View Details" aria-label="delete">
                                    <span className="flaticon-visibility-button"></span>
                                  </button>
                                </Link>


                                <>
                                  <RetryPopup
                                    pipeline={pipeline}
                                    variant="execution_history"
                                    retryPipeline={props.retrigger_pipeline}
                                    pipeline_instance={pipeline_instance}
                                    reTriggerError={reTriggerError}
                                    resetTriggerError={resetTriggerError}

                                  />

                                </>
                                <>
                                  {

                                    pipeline_instance.status == "RUNNING" || pipeline_instance.status == "IN_QUEUE" ?
                                      <span className="play-icon" >
                                        <button className={"btn btn-transparent text-anchor-blue font-12" + " " + classes.margin} title="Revoke Pipeline" onClick={() => { props.revoke_pipeline(pipeline_instance.pipeline_id, pipeline_instance.id) }} style={{ color: '#0086ff', marginLeft: 5 }}>
                                          <span className="flaticon--rounded-button"><i className="ri-stop-line color-danger" style={{ verticalAlign: 'top', fontSize: 22, marginTop: '-2px' }}></i></span>
                                        </button>
                                      </span> : null
                                  }
                                </>
                              </div>
                            </MainDivFlex>

                          </CardParentDiv>

                        ))
                      }
                    </>
                  </>

                </>
                :
                <LoadingContainer />
            }
          </Grid>
        </Grid>
      </Dialog>
    </div >
  );
}


PipelineExecutionHistory.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}