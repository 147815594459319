import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import GenericSkeleton from './GenericSkeleton';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      // gap: theme.spacing(1),
    },
    
  }));

const InputSkeleton = ({type,width, height = '40px', count, animation,style,rootStyle }) => {
    const classes = useStyles();
    console.log("ureiueie",type,width,height);
    return (
        type == "text" ? 
            <div className='d-flex f-direction-column' style={{gap:"5px",marginBottom:"1.5rem",width:width,height:height}}>
                <GenericSkeleton variant={"rect"} width={"30%"} height={"16px"} count={count} animation={animation} style={{lineHeight: "2"}} rootStyle={rootStyle}/>
                <GenericSkeleton variant={"rect"} width={"100%"} height={"46px"} count={count} animation={animation} style={style} rootStyle={rootStyle} />
            </div>
        : 
        type == "checkbox" ? 
            <div className='d-flex align-center' style={{gap:"4px",width:width,height:height}}>
                <GenericSkeleton variant={"rect"} width={25} height={25} style={{borderRadius:'4px'}} rootStyle={{flex:0}}/>
                <GenericSkeleton variant={"text"} width={"70%"} height={"20px"} rootStyle={rootStyle} />
            </div>
        :
        type == "radio" ?
            <div className='d-flex align-center' style={{gap:"4px",marginBottom:"1.5rem",width:"fit-content",height:height}}>
                <GenericSkeleton variant={"circle"} width={25} height={25} />
                <GenericSkeleton variant={"text"} width={"70px"} height={"30px"} rootStyle={rootStyle} />
            </div>
        :
        type == "switch" ?
            <div className='d-flex align-center' style={{gap:"4px",marginBottom:"1.5rem",width:width,height:height}}>
                <GenericSkeleton variant={"rect"} width={"90%"} height={"16px"} count={count} animation={animation} style={{lineHeight: "2"}} rootStyle={rootStyle}/>
                <GenericSkeleton variant={"rect"} width={"50px"} height={"22px"} rootStyle={rootStyle} style={{borderRadius:"18px"}} />
            </div>
        :
        null
    );
}

InputSkeleton.propTypes = {
    variant: PropTypes.oneOf(['text', 'rect', 'circle']),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    count: PropTypes.number,
    animation: PropTypes.oneOf(['pulse', 'wave', false]),
  };
  
InputSkeleton.defaultProps = {
    variant: 'rect',
    width: '100%',
    height: 'inherit',
    count: 1,
    animation: 'pulse',
    
  };

export default InputSkeleton