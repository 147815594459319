import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'
import Drawer from '@mui/material/Drawer'
import '../layout-styles.css';
import AppBreadcrumb from './AppBreadcrumb';
import clsx from 'clsx';


const ProfileNavigation = (props) => {
  const classes = useStyles();
  // const routes = props.routes;
  // const data = props.data;
  // const match = useRouteMatch();
  const bread_crumb_list = [];
  const open = props.open;
  // const handleDrawerOpen = props.handleDrawerOpen;
  // const handleToggle = props.handleToggle;



  return (
    <>
       <AppBreadcrumb list={bread_crumb_list} userSettings={true} /> 
      <Drawer
        anchor="left"
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper),
        }}
        open={open}>

        <List className={classes.root}>
          
            <ListItem
              className={classes.item}
              disableGutters>
  
              <a href="/usersetting" className="main-nav-1 d-flex align-center">
                <span className="flaticon-google-drive-document d-flex align-center "><span className="text-color-black">User Information</span></span>
              </a>
            </ListItem>
            <div className="divider-nav"></div>
            <ListItem
              className={classes.item}
              disableGutters>
  
              <a href="/billing" className="main-nav-1">
                <span className="flaticon-google-drive-document d-flex align-center "><span className="text-color-black">Billing Information</span></span>
              </a>
            </ListItem>
          <div className="divider-nav"></div>
          <ListItem
              className={classes.item}
              disableGutters>
  
              <span className="main-nav-1">
                <span className="flaticon-google-drive-document d-flex align-center "><span className="text-color-black">Subscription Plan</span></span>
              </span>
            </ListItem>
          <div className="divider-nav"></div>
          <ListItem
              className={classes.item}
              disableGutters>
  
              <span className="main-nav-1">
                <span className="flaticon-google-drive-document d-flex align-center "><span className="text-color-black">User Group</span></span>
              </span>
            </ListItem>
          <div className="divider-nav"></div>
          <ListItem
              className={classes.item}
              disableGutters>
  
              <a href="/backup/list" className="main-nav-1">
                <span className="flaticon-google-drive-document d-flex align-center "><span className="text-color-black">Integration</span></span>
              </a>
            </ListItem>
          <div className="divider-nav"></div>
          {/* <ListItem
            className={classes.item}
            disableGutters>

            <button className="main-nav-1">
              <span className="flaticon-shopping-basket-button"><span className="text-color-black">Software Catalog</span><span className="Y6 ">coming soon</span></span>
            </button>
          </ListItem>
          <div className="divider-nav"></div>
          <ListItem
            className={classes.item}
            disableGutters
          >

            <button className="main-nav-1">
              <span className="flaticon-google-drive-document"><span className="text-color-black">Help &amp; Support</span><span className="Y6 ">coming soon</span></span>
            </button>
          </ListItem>
          <div className="divider-nav"></div> */}
          {/* <ListItem
            className={classes.item}
            disableGutters
            style={{ display: 'flex' }}
          >
            {open ?
              <div className={classes.toolbarIcon}>
              </div> :
              <div className={clsx(classes.appBar, open && classes.appBarShift)} style={{ paddingLeft: '16px' }}>
              </div>}

          </ListItem> */}
        </List>

      </Drawer>
    </>
  );
};

ProfileNavigation.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


export default ProfileNavigation;

export const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 96,
      height: '100%',
      width: 240
    }
  },
  subOption: {
    listStyle: 'none',
    display: 'grid',
    cursor: 'pointor'
  },
  root: {
    backgroundColor: '#e7eeee',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .divider-nav':{
        backgroundColor:'#dedede',
        margin:'5px 0px'
    }
  },
  rootTop: {
    backgroundColor: '#234361',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#000'
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  item: {
    display: 'inline-block',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  SubItem: {
    display: 'inline-block',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  itemOne: {
    display: 'inline-block',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  hidden: {
    display: 'none'
  },
  appList: {
    height: '25rem',
    overflowX: 'hidden',
    overflowY: 'scroll'
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      marginTop: 96,
      height: '100%',
      width: 240
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    '& span.text-color-black': {
      display: 'none'
    },
    '& span.Y6 ': {
      display: 'none'
    },
    '& .text-home': {
      display: 'none'
    },
    '& .main-nav': {
      padding: '8px 4px',
      borderRadius: '24px',
      height: '30px',
      width: '30px',
      '& .flaticon-right-arrow-forward': {
        display: 'none'
      }
    },
    '& .subOptionChild': {
      marginLeft: '0px',
      padding: '5px 0px'
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbarIcon: {
    paddingRight: "5px",

  }
}));
