import React from 'react'
import GenerateURL from '../../../util/APIUrlProvider';
import { redirectionLinks } from '../constants';

const generateLink = (searchEntity,selectedSubTab,result) => {
    const entityKey = searchEntity == 'integration' ? selectedSubTab?.id : result.project_name 
      ? searchEntity 
      : `global_${searchEntity}`;
  
    const linkConfig = redirectionLinks?.[entityKey];
    if (!linkConfig) return null;
  
    const params = result.project_name
      ? { application_id: result.project, 
          service_id: result.id, 
          pipeline_name: result.name,
          job_template_name: result.name,
          env_id: result.id }
      : { pipeline_name: result.name,
          job_template_name: result.name, 
          repo_name: result.name,
          secret_name: result.name,
          helm_name: result.name,
          event_name: result.name,
          ticketing_name: result.name};
  
    return GenerateURL(params, linkConfig, true);
};

const withSearchResultAndLink = (Component) => {
    return ({searchEntity,appList,result,searchedQuery,onClose,openedFeature,setOpenedFeature,selectedEntityLabel,selectedSubTab}) => {
        const link = generateLink(searchEntity,selectedSubTab,result);
        return (
            <Component 
                searchedQuery={searchedQuery}
                appName={result?.project_name}
                entityObj={result}
                name={result?.name}
                link={link}
                appList={appList}
                onClose={onClose}
                openedFeature={openedFeature}
                setOpenedFeature={setOpenedFeature}
                selectedEntityLabel={selectedEntityLabel}
                selectedSubTab={selectedSubTab}
            />
        );
    };
}

export default withSearchResultAndLink