import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { getCommonFunctions } from '../ci_flow/SourceDetails';
import { Loading } from '../../../utils/Loading';
import GitRepo, { GitRepoDefaultState } from '../../../../components/genericComponents/Forms/GitRepo';
import AlertStrip from '../../../../components/AlertStrips';


const VersioningDetails = (props) => {
  const classes = useStyles();
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const extraProps = props.extraProps ? props.extraProps : { service_name: "default", project_env_name: "default", environment_master_name: "default" };
  const [state, setState] = useState(prev_state ? prev_state : getVersioningDetailsDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  const [showLoading, setShowLoading] = useState(false)
  useEffect(() => {
    setShowLoading(true);
    setState(new_state => ({ ...new_state, ...prev_state }))
    setTimeout(() => {
      setShowLoading(false)
    }, 200);
  }, [prev_state])

  function getGitRepoState() {
    var state_temp = GitRepoDefaultState();
    if (Object.keys(state.gitRepo).length != 0) {
      state_temp.form_data.data = { ...state_temp.form_data.data, ...state.gitRepo };
      state_temp.branch_data.branches = state.gitRepo.branches ? [...state.gitRepo.branches] : []
    }
    return state_temp;
  }

  return (
    <div className={`${classes.root} ci-cd-edit-input-div`}>
      <Grid container className="card-add-service">
        <Grid item lg={12} direction="row" className="card-add-service-head" style={{ borderTop: '1px solid #e7e7eb' }}>
          <div className="formTag">
            <h4 className='mainHeading'>Versioning Details</h4>
          </div>
        </Grid>
        {showLoading ? <Loading varient="light" /> :
          <div className='ci-cd-edit-input-div'>
            <Grid container className="card-add-service-body">
              <Grid item lg={12} className="m-auto pd-10" style={{ margin: '15px auto 0px', paddingBottom: '0px' }}>
                <AlertStrip variant="info" message="Note: Please provide token based authentication for versioning." />
              </Grid>
              <GitRepo
                id="gitRepo"
                inherits={state.child_inherits["gitRepo"]}
                state={state.gitRepo ? getGitRepoState() : null}
                variant="MANIFEST_BRANCH" />
            </Grid>
          </div>}
      </Grid>
    </div>

  )
}
VersioningDetails.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default VersioningDetails;

export const getVersioningDetailsDefaultState = () => {
  return {
    data: {

    },
    available_settings: [],
    error: {},
    child_inherits: {
      "gitRepo": {}
    },
    validations: {
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px',
    '& > .card-add-service': {
      borderRadius: '0px',
      border: '0px'
    },
    '& > .card-add-service-head': {
      borderTop: '1px solid #e7e7eb',
      borderRadius: '0px',
    },
    '& > .card': {
      backgroundColor: '#f6f8f8',
      borderRadius: '0px',
      '& .card-footer': {
        backgroundColor: '#fff',
        justifyContent: 'flex-end'
      },
      '& .card ': {
        marginBottom: '15px',
        boxShadow: 'none',
        '& .input-component': {
          marginBottom: '0px'
        }
      }
    }
  }
}))
