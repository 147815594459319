import React, { useState } from "react";
import { padding, styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';

const MultiSelectWithSettings = ({ list, value, onTextClick, onChangeHandler, name }) => {

    console.log(list, value, 'data_00pdsnsdsd');

    const [isOpen, setIsOpen] = useState(false);

    const handleOnTextClick = (e, data) => {
        e.preventDefault();
        const selectedData = list?.find(item => item.label == data)
        console.log(selectedData, 'data_0sddssd')
        onTextClick(selectedData)
    }

    const handleOnclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!isOpen)

    }   

    const handleOptionSelect = (option) => {
        console.log(option, 'option_select_sd')
        onChangeHandler(name, [...value, option.label])
        setIsOpen(false)
    }

    const onHandleRemove = (option)=>{
        const updatedList = value?.filter(item=>option!=item)
        console.log(updatedList,option,'data_00psdsdjhsdsd')
        onChangeHandler(name, updatedList)
        setIsOpen(false)
    }

    return (
        <div style={{ position: 'relative' }}>
            <ItemContainer >
                {
                    value?.length<1 && 
                    <div className="font-14 font-weight-500">
                        Please Select
                    </div>
                }
                <div className="d-flex" style={{ height: '100%', gap: '4px' }}>
                    {value?.map((item, index) => {
                        return <div className="list-item">
                            <span className="title">
                                {item}
                            </span>
                            <Tooltip title="Change Branch and tag">
                                <span onClick={(e) => handleOnTextClick(e, item)} style={{ color: '#0086FF', fontSize: '16px', cursor: 'pointer' }} className="ri-settings-4-line">
                                </span>
                            </Tooltip>

                            <span onClick={()=>onHandleRemove(item)} style={{ color: '#0086FF', fontSize: '20px', cursor: 'pointer' }} className="ri-close-fill">

                            </span>
                        </div>
                    })}
                </div>
                {
                    isOpen ? <span onClick={handleOnclick} style={{ color: '#505050', cursor: 'pointer' }} className="ri-arrow-up-s-line">

                    </span> :
                        <span onClick={handleOnclick} style={{ color: '#505050', cursor: 'pointer' }} className="ri-arrow-down-s-line">

                        </span>
                }

            </ItemContainer>
            {
                isOpen &&
                <OptionsContainer>
                    {
                        list?.map(option => {
                            const alreadySelected = value.includes(option.label)
                            return (
                                <>
                                    {
                                        !alreadySelected &&
                                        <div onClick={() => handleOptionSelect(option)} className="option-item">
                                            {option.label}
                                        </div>
                                    }
                                </>
                            )
                        })
                    }
                </OptionsContainer>
            }

        </div>


    )
}

export default MultiSelectWithSettings;

const ItemContainer = styled('div')({
    width: '100%,',
    height: '100%',
    padding: '12px 10px',
    borderRadius: '4px',
    borderWidth: '1px',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #E6E6E6',
    '& .list-item': {
        width: 'fit-content',
        background: '#F5FAFF',
        border: '1px solid #DFEDFF',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '34px',
        padding: '4px 6px 4px 12px',
        height: '31px',
        gap: '8px',
        '& .title': {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '14.63px',
            color: '#0086FF'
        }
    }
})


const OptionsContainer = styled('div')({
    height: 'calc(100% - 90px)',
    overflow: 'auto',
    top: '65px',
    width: '100%',
    height: '240px',
    zIndex: 999,
    position: 'absolute',
    background: '#fff',
    boxShadow: '0px 2px 6px 0px #00000026',
    borderRadius: '5px',
    '& .option-item': {
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '500',
        color: '#2f2f2f',
        padding: '12px',
        '&:hover': {
            background: '#dedede59'
        }
    }
})