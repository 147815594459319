import React, { useEffect, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import '../../../assets/v2-style.css';
import { Input } from "../../../components/genericComponents/Input";
import { getCommonFunctions } from "../../serviceRevamp/add/ci_flow/SourceDetails";
import GenerateURL, { GenerateSearchURL } from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';
import { Navigate } from 'react-router-dom';
import { ErrorComponent } from '../../utils/Error';
import AlertStrip from '../../../components/AlertStrips';

const AddGlobalCustomJobTemplate = props => {

    const inherits = props.inherits ? props.inherits : {};
    const [state, setState] = useState(customJobTemplateDefaultState());
    const commonFunctions = getCommonFunctions(state, setState, inherits)
    const existing_job_template_list = [];

    useEffect(() => {
        getTemplates()
    }, [])

    function getTemplates() {
        var requestInfo = {
            endPoint: GenerateURL({ }, properties.api.get_global_job_template)+"?all=true",
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, handleResponseTemplate, handleErrorTemplate);

        setState(new_state => ({
            ...new_state,
            data_loading: true,
            error_msg: null
        }));
    }

    function handleResponseTemplate(response) {

        const { results } = response

        setState(new_state => ({
            ...new_state,
            all_templates: results,
            data_loading: false,
            error_msg: null
        }));
    }

    function handleErrorTemplate(error) {
        setState(new_state => ({
            ...new_state,
            error_msg: "Error fetching templates " + " " + error,
            data_loading: false
        }));
    }


    const toggleStateDropdown = () => {
        setState({
            ...state,
            drop_down: !state.drop_down
        });
    }

    const support_job_type = [
        {
            label: "Build Job",
            img_url: "/images/icons-custom-job-templates/build_job.png"
        },
        {
            label: "Deploy Job",
            img_url: "/images/icons-custom-job-templates/deploy_job.png"
        },
        {
            label: "Promote Job",
            img_url: "/images/icons-custom-job-templates/promote_job.png"
        },
        {
            label: "Rollback Job",
            img_url: "/images/icons-custom-job-templates/rollback_job.png"
        },
        {
            label: "API Call Job",
            img_url: "/images/icons-custom-job-templates/apicall_job.png"
        },
        {
            label: "Jira Ticket Job",
            img_url: "/images/icons-custom-job-templates/deploy_job.png"
        },
        {
            label: "Deploy ConfigMaps Job",
            img_url: "/images/icons-custom-job-templates/deploy_config_map_jobs.png"
        },
        {
            label: "Deploy Secrets Job",
            img_url: "/images/icons-custom-job-templates/secrets_jobs.png"
        }
    ]

    const createJobTemplate = (jobTemplate) => {

        let create_job_template_url = GenerateURL({ }, properties.api.get_global_job_template)

        PostData(create_job_template_url, jobTemplate, createSuccess, createFail);

    }

    function createSuccess(response) {

        setState(new_state => ({
            ...new_state,
            error_msg: null,
            redirect: true
        }))
    }

    function createFail(response) {
        if(response.toString()==='A Job templated with this name already exists in this project.'){
            setState(prevState=>({
                ...prevState,
                other_errors : response.toString()
            }))
        }
        else{
            setState(new_state => ({
                ...new_state,
                error_msg: response.toString()
            }))
        }
    }

    const onSaveHandler = () => {

        let validations = state.validations
        if (!state.data.clone_default_template) {

            validations['clone_template'] = [VALIDATION_TYPE_REQUIRED]
        }
        let result = ValidateDataSet(state.data, state.validations)

        if (result.valid) {
            let defaultTemplate
            if (state.data.clone_default_template) {
                defaultTemplate = state && state.all_templates && state.all_templates.find(template => template.default === true)
                if (!defaultTemplate) {
                    setState(prevState=>({
                        ...prevState,
                        other_errors : 'Default template is not availbale,  Please set default template or select another way to create job template'
                    }))
                    return
                }
            }

            else
                defaultTemplate = state && state.all_templates && state.all_templates.find(template => template.id == state.data.clone_template)

            defaultTemplate['default'] = false
            defaultTemplate['name'] = state.data.name
            defaultTemplate['description'] = state.data.description
            defaultTemplate['job_template_tag'] = state.data.job_template_tag
            delete defaultTemplate.id
            delete defaultTemplate.created_at
            delete defaultTemplate.updated_at

            createJobTemplate(defaultTemplate)

        }
        else
            setState(new_state => ({
                ...new_state,
                error: result.error
            }))
    }

    const toggleState = () => {
        setState(prevState => ({
            ...prevState,
            data: { ...prevState.data, clone_default_template: !prevState.data.clone_default_template }
        }))
    }

    const toggleAlert = ()=>{
        
            setState(prevState=>({
                ...prevState,
                other_errors : null
            }))
    }

    console.log('J_t_s',state)

    return (<>
        {state.error_msg ? <ErrorComponent variant="msg-box" error={state.error_msg} /> :

            <div style={{ position: 'relative' }}>

                {state.redirect && <Navigate to={`/global/job/templates`} />}

                <div style={{
                    paddingTop: '65px',
                    paddingLeft: '35px',
                    paddingRight: '35px',
                    backgroundColor: '#e7eeee',
                    overflow: 'auto'
                }}>
                    <div className="d-flex align-center space-between mb-20">
                        <div className="heading-section-v2">
                            <p className="main-heading-v2 font-20 font-weight-300 d-flex align-center">
                                <div className="heading-icon d-flex align-center justify-center mr-15">
                                    <img className="icon-v2" src="/images/icons-custom-job-templates/apicall_job.png" alt=".." />
                                </div>
                                <span className="text-color-primary">Do you want to create a Global Job Template</span>
                            </p>
                        </div>
                        <Link
                            to={`/global/job/templates`}
                            className="btn-v2 btn-icon-v2 btn-icon-secondary-v2">
                            <span className='ri-close-line' fontSize="large" style={{color: '#607086'}}></span>
                        </Link>
                    </div>
                    <p className="font-13 text-color-primary font-weight-300 mb-30">BuildPiper provides a <b>Default Job Template</b> which helps you in creating your own custom template very easily with following supported Jobs.</p>
                    <div className="form-section">
                        <div style={{ display: 'flex' }} className="align-center space-between">

                            <label className='font-13 text-color-primary font-weight-300 mb-20'>Do you want to clone default template or select another template for cloning?</label>

                            <div className='remove-margin-from-input'>
                                <Input
                                    type="only-switch"
                                    label=""
                                    name="clone_default_template"
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={toggleState} />
                            </div>
                        </div>

                      {state.other_errors && 
                          <div style={{ marginTop: '5px' }}><AlertStrip newUpdate={true} showAlert={true} variant="error" message={state.other_errors}

                          toggleAlert={toggleAlert} dismissible={true} /></div>

                      }  

                        {
                            !state.data.clone_default_template &&

                            <Input
                                type="select"
                                name="clone_template"
                                subHeading={"(Since it is difficult to start from scratch it's better to clone an existing template)"}
                                label="Clone Template"
                                list={state.all_templates && state.all_templates.map(template => {

                                    return { id: template.id, label: template.name }
                                })}
                                mandatorySign
                                placeholder="Select template for cloning"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={commonFunctions.onChangeHandler}
                            />

                        }

                        <Input
                            type="text"
                            name="name"
                            label="Template Name"
                            mandatorySign
                            placeholder="Enter Name"
                            data={state.data}
                            error={state.error}
                            onChangeHandler={commonFunctions.onChangeHandler}
                        />
                        {/* <SearchDropdownComponent
                        onUpdate={()=>{}}
                        searchValue='I am searching for'
                        list={
                            [{id:'1',label:"Option 1"},
                            {id:'2',label:"Option 2"},
                            {id:'3',label:"Option 3"},
                            {id:'4',label:"Option 4"}]
                        } 
                        labelName='Config maps'
                        /> */}
                        <Input
                            type="textarea"
                            name="description"
                            label="Template Description"
                            mandatorySign
                            placeholder="Please be descriptive while creating the job template"
                            data={state.data}
                            error={state.error}
                            onChangeHandler={commonFunctions.onChangeHandler}
                        />
                        <div className="form-controll">
                            <Input
                                type="tagged-input"
                                placeholder="Add a tags to your global job template"
                                label="Tags"
                                subHeading="(Enter tag and press enter)"
                                name="job_template_tag"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={commonFunctions.onKeyValueChangeHandler}
                            />
                        </div>
                        <div className="job-template-option">
                            <p className="font-13 font-weight-300 mb-15">BuildPiper Support following Jobs</p>
                            <div className="d-grid" style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 30 }}>
                                {
                                    support_job_type.map(item => (

                                        <div className="single-img-card d-flex align-center justify-center">
                                            <div className="single-img-inner-card">
                                                <div className=" icon-bg-grey d-flex align-center justify-center">
                                                    <img src={item.img_url} alt={item.label} />
                                                </div>
                                                <p className="font-12 text-center" style={{ marginTop: '3px' }}>{item.label}</p>
                                            </div>
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-center justify-end mt-30 mb-20">
                        <Link to={`/global/job/templates`}>
                            <button className="btn btn-outline-grey" style={{marginRight: '5px'}}>
                                Cancel
                            </button>

                        </Link>

                        <button onClick={onSaveHandler} className="btn btn-submit d-flex align-center">
                            <span>Save</span> &nbsp; &nbsp;
                            <i className="ri-arrow-right-s-line"></i>
                        </button>
                    </div>
                </div>

            </div>

        }

    </>

    )
}
export default AddGlobalCustomJobTemplate;

const customJobTemplateDefaultState = () => {
    return {
        data: { clone_default_template: true, job_template_tag:[] },
        redirect: false,
        error: {},
        other_errors : null,
        drop_down: false,
        validations: {
            name: [VALIDATION_TYPE_REQUIRED],
            description: [VALIDATION_TYPE_REQUIRED],

        }
    }
}