import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system'; 
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams, useLocation } from 'react-router-dom';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import GenerateURL, { GET_Request_Info } from '../../../../util/APIUrlProvider';
import Events from '../../components/Events';
import IngressSection from './components/IngressSection';
import { Loading } from '../../../utils/Loading';
import ResourceSection from './components/ResourceSection';
import InfraMonitoringSection from './components/InfraMonitoringSection';
import HealthStatusRoundMeter from '../../../../components/genericComponents/HealthStatusRoundMeter';
import { ErrorComponent } from '../../../utils/Error';
import Delete from '../../../../components/genericComponents/Delete';
import IstioSection from './components/IstioSection'
import KedaSection from './components/KedaSection';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContent';
import StepProgressDetailed from '../../../../components/genericComponents/StepProgressDetailed';
import AlertStrip from '../../../../components/AlertStrips';
import { ValidateDataSet, VALIDATION_TYPE_PASSWORD, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import PageHeader from '../../../../components/PageHeader';
import Chip from '../../../../components/chip/Chip';
import queryString from 'query-string';

function ClusterDetail(props) {
    const classes = useStyles();
    const { cluster_id } = useParams();
    const location = useLocation();
    
    var parsedQueryString = queryString.parse(location.search);
    const cluster_name = parsedQueryString?.cluster_name
    const cluster_main_id = parsedQueryString?.cluster_id
    const isDrClusterActive = parsedQueryString?.dr_cluster;
    console.log(cluster_name, "cluster_name___")
    const [state, setState] = useState({
        loaded: false,
        health: {},
        loaded1: false,
        count: 1,
        loaded2: false
    });

    useEffect(() => {
        fetchClusterSummary();
        fetchControlManagerHealth();
    }, [cluster_id, state.count]);

    function fetchClusterSummary() {
        const request_info = GET_Request_Info(GenerateURL({ cluster_id: cluster_id }, properties.api.cluster_summary));
        InvokeApi(request_info, onFetchSuccess, () => { });
        setState(new_state => ({
            ...new_state,
            loaded: false,
        }));
    }
    function onFetchSuccess(data) {
        console.log(data, "testing______")
        setState(new_state => ({
            ...new_state,
            cluster_summary: data,
            loaded: true,
        }));
    }
    function handleSuccessApiHit2(data) {
        setState(new_state => ({
            ...new_state,
            health: data.items,
            loaded1: true

        }));



    }

    function handleFailedApiHit2(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            loaded1: true
        }))

    }

    function fetchControlManagerHealth() {

        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_cluster_status),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }


        InvokeApi(requestInfo, handleSuccessApiHit2, handleFailedApiHit2);
        setState(new_state => ({
            ...new_state,
            loaded1: false,
        }));

    }
    function refreshPage() {
        setState(new_state => ({
            ...new_state,
            count: new_state.count + 1,
        }))
    }
    function handleRefresh() {
        setState(new_state => ({
            ...new_state,
            canRedirect: true
        }));
    }
    return (
        <>
            {
                state.post_status == "SUCCESS" ?
                    <>
                        <StepProgressDetailed task_id={state.task_id} placeholders={{ cluster_id: cluster_id }} refresh={handleRefresh} />
                        {state.canRedirect ? <Navigate to={"/ClusterDashboard"} /> : null}
                    </>
                    :
                    <div className={classes.root}>
                        {
                            state.loaded2 ?
                                <Loading /> : null
                        }
                        {
                            state.error_main ?
                                <ErrorComponent error={state.error_main} /> : null
                        }
                        <UI_Heading
                            data={
                                state.loaded && state.loaded1 ?
                                    state.cluster_summary
                                    : <Loading />}
                            refresh={fetchClusterSummary}
                            parentStateSetter={setState}
                            cluster_main_id={cluster_main_id}
                            isDrClusterActive={isDrClusterActive}
                            cluster_name={cluster_name}
                        />
                        {
                            state.loaded && state.loaded1 ?
                                <UI_Detail health={state.health} data={state.cluster_summary} refresh={refreshPage} cluster_id={cluster_id} /> : <Loading />
                        }
                    </div>
            }
        </>
    );
}
ClusterDetail.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

function UI_Heading(props) {
    const data = props.data;
    const inherits = props.inherits ? props.inherits : {};
    const parentStateSetter = props.parentStateSetter;
    const cluster_main_id = props?.cluster_main_id;
    const isDrClusterActive = props?.isDrClusterActive;
    const cluster_name = props?.cluster_name;
    const [canRedirect, setRedirect] = useState(false)
    const [state, setState] = useState({
        data: {
            is_dr_cluster_active: false
        },
        error: {},
        validations: {
            password: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_PASSWORD]
        },
        dialogOpen: false,
        main_cluster_data: {}
    })
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            data: {
                is_dr_cluster_active: data.is_dr_cluster_active
            }
        }))
    }, [isDrClusterActive])
    function fetchMainClusterData() {
        const request_info = GET_Request_Info(GenerateURL({ cluster_id: state.main_cluster_data.id }, properties.api.cluster_summary));
        InvokeApi(request_info, onFetchSuccess, () => { });
    }
    function onFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            main_cluster_data: data
        }));
    }
    function handleClose() {
        setState((prevState) => ({
            ...prevState,
            dialogOpen: false,
            data: {
                is_dr_cluster_active: data.is_dr_cluster_active
            },
            error: {

            }
        }))
    }
    function setDrActiveInactive() {
        const result = ValidateDataSet(state.data, state.validations);
        if (result.valid) {
            const postData = {
                "password": state.data.password
            }
            if (state.data.is_dr_cluster_active) {
                PostData(GenerateURL({ cluster_id: cluster_main_id }, properties.api.set_dr_inactive), postData, onPostInactiveSuccess, onPostFail);
            }
            if (!state.data.is_dr_cluster_active) {
                PostData(GenerateURL({ cluster_id: cluster_main_id }, properties.api.set_dr_active), postData, onPostActiveSuccess, onPostFail);
            }
            parentStateSetter((prevState) => ({
                ...prevState,
                confirm_password_loading: true,
                dialogOpen: true,
            }))
            handleClose();
        } else {
            setState((prev_state) => ({
                ...prev_state,
                error: result.error
            }))
        }

    }
    function onPostActiveSuccess(response) {

        fetchMainClusterData()
        parentStateSetter((new_state) => ({
            ...new_state,
            error_main: "",
            error: "",
            post_status: "SUCCESS",
            task_id: response.task_id,
            confirm_password_loading: false
        }))
    }
    function onPostInactiveSuccess(response) {
        fetchMainClusterData()
        parentStateSetter((new_state) => ({
            ...new_state,
            error_main: "",
            error: "",
            post_status: "SUCCESS",
            task_id: response.task_id,
            confirm_password_loading: false
        }))
    }
    function onPostFail(error) {
        setState((prev_state) => ({
            ...prev_state,
            confirm_password_loading: false,
            dialogOpen: true,
            error: {
                password: error.detail
            }
        }))
    }
    function OnSwitchToggle(e, switchStatus) {
        const name = e.target.name
        setState((prevState) => ({
            ...prevState,
            dialogOpen: true,
        }))
    }

    return (
        <>
            {
                canRedirect && <Navigate to="/ClusterDashboard" />
            }
            <div className='d-flex align-center space-between' style={{ padding: '20px' }}>
                <PageHeader
                    heading={data.is_dr_cluster ? `DR Cluster of ${cluster_name}` : "Cluster Overview"}
                    subHeading="Displaying Overall summary of your cluster"
                    icon={null}
                    imgIcon={'/images/cluster-head.svg'}
                    commonDivMargin={false}
                    primaryButton={null}
                />
                {
                    data.is_dr_cluster &&
                    <div style={{ display: "flex", alignItems: "center", paddingRight: "15px" }} className="dr-switch-div">
                        <p style={{ paddingRight: "10px" }}>
                            {
                                state.data.is_dr_cluster_active ?
                                    "Deactivate DR cluster" : "Activate DR cluster"
                            }

                        </p>
                        <div className='active-inactive-input'>
                            <Input
                                type="active-inactive-switch"
                                name="is_dr_cluster_active"
                                data={state.data}//main_cluster_data
                                error={state.error}
                                onChangeHandler={state.data.is_dr_cluster_active ? (e) => OnSwitchToggle(e, true) : (e) => OnSwitchToggle(e, false)}
                            />
                        </div>

                    </div>
                }
                {props.refresh && <Delete
                    display_data_name={data.name}
                    data={{ entity_id: data.id, name: "cluster" }}
                    refresh={() => { setRedirect(true) }}
                    varient="Button"
                    api_link={GenerateURL({ cluster_id: data.id }, properties.api.get_single_cluster)}
                    fromLocalStorage={'last_used_cluster'}
                />}
            </div>
            {
                state.dialogOpen &&
                <div className="main-div d-flex-top">

                    <div style={{ display: "flex", alignItems: "center" }} className="" >

                        <div>
                            <Dialog open={state.dialogOpen} onClose={handleClose}>
                                <div className='d-flex align-center space-between' style={{
                                    backgroundColor: '#124d9b',
                                    color: '#fff',
                                    fontSize: '13px',
                                    padding: '10px 20px',
                                }}>
                                    <p>{state.data.is_dr_cluster_active ? "Deactivate DR Cluster" : "Activate DR Cluster"}</p>
                                    <button className="btn btn-transparent" onClick={handleClose}>
                                        <span className='ri-close-line' fontSize='large' style={{ color: '#fff' }}  ></span>
                                    </button>
                                </div>
                                <>
                                    {
                                        state.confirm_password_loading ?
                                            <div className="d-flex align-center">
                                                <Loading varient="light" />
                                            </div>
                                            :
                                            <>
                                                <DialogContent style={{
                                                    padding: '50px !important'
                                                }}>
                                                    <>
                                                        <i className="ri-information-line text-center mb-10 d-block m-auto"  style={{ color: '#4783dc', fontSize: '60px' }}  />
                                                        <div className="">
                                                            <p className="font-16  font-weight-bold text-center mb-5">
                                                                Please enter password before proceeding ahead
                                                            </p>
                                                        </div>
                                                    </>

                                                    <Input
                                                        type="password"
                                                        name="password"
                                                        mandatorySign
                                                        placeholder="Password"
                                                        label="Enter Passowrd"
                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                        data={state.data}
                                                        error={state.error} />
                                                    <AlertStrip variant={"alert-danger"} message={state.error_msg} />
                                                </DialogContent>
                                                <DialogActions>
                                                    <button onClick={handleClose} className="btn btn-outline-dark-grey">Cancel</button>
                                                    <button onClick={setDrActiveInactive} className="btn btn-primary-v2">Confirm</button>
                                                </DialogActions>
                                            </>
                                    }
                                </>
                            </Dialog>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
UI_Heading.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

function UI_Detail(props) {
    const classes = useStyles();
    const data = props.data;
    const cluster_id = props.cluster_id;
    const health = props.health ? props.health : [];
    const [state, setState] = useState({
        selectedTabOrder: 2,
    });
    const tab_list = [
        {
            tabName: "Monitoring",
            order: 1
        },
        {
            tabName: "Setup Summary",
            order: 2
        }
    ];

    function updateSelectedTab(order) {
        setState(new_state => ({
            ...new_state,
            selectedTabOrder: order,
        }));
    }

    const list = [
        {
            icon: "",
            text: "grafana.buildpiper.in"
        }
    ]
    return (
        <>
            <div style={{ margin: '0px', alignItems: 'center', justifyContent: 'space-between', padding: '0px 20px' }}>
                <UI_ClusterData data={data} health={props.health} />
                <div className="d-flex space-between" style={{ backgroundColor: '#fff' }}>
                    <div className="d-flex " >
                        {health.length > 0 ?
                            health.map(h => (

                                <>
                                    <span className="color-secondary font-12 font-weight-500">{(h.metadata.name).charAt(0).toUpperCase() + (h.metadata.name).slice(1)}:&nbsp;</span>

                                    {
                                        h.conditions.map(c => (
                                            c.type == "Healthy" ?
                                                <span className="d-flex align-center mr-5">
                                                    {
                                                        c.status == "True" ?
                                                            <>
                                                                <Chip label={c.status == "True" ? "Healthy" : "Unhealthy"} variant={c.status == "True" ? "success" : "red"} />
                                                            </>
                                                            :
                                                            <Chip label={c.status == "True" ? "Healthy" : "Unhealthy"} variant={c.status == "True" ? "success" : "red"} />
                                                    }
                                                </span>
                                                : null
                                        ))
                                    }
                                </>

                            )) : <div className="inner-data d-flex align-center nowrap mr-10 border-right-d5" style={{ padding: '10px', }}><ErrorComponent error={"Cluster Unstable"} /></div>
                        }
                    </div>
                    <div>
                        {/* <MoreOptionMenu OptionList={list} /> */}
                    </div>
                </div>
            </div>
            <div className={classes.mainBodyDiv}>
                <div className="d-flex align-center space-between border-bottom">
                    <CommonHorizontalTab
                        tabList={tab_list}
                        selectedTabOrder={state.selectedTabOrder}
                        updateSelectedTab={updateSelectedTab} />

                </div>




                <UI_InfraDetails cluster_id={cluster_id} data={data} health={props.health} />

                <div className={classes.subBoby}>

                    {
                        state.selectedTabOrder == 1 ?
                            <UI_MonitoringSection cluster_id={cluster_id} />
                            :
                            <UI_SummarySection refresh={props.refresh} cluster_id={cluster_id} cluster_summary_data={data} />

                    }
                </div>
            </div>
        </>
    );
}
UI_Detail.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

function UI_MonitoringSection(props) {
    const cluster_id = props.cluster_id;
    return (
        <div className="expensionPanel" style={{ margin: '10px', padding: '0px' }}>
            <div className="expensionPanelBody">
                <Events api_url={GenerateURL({ cluster_id: cluster_id }, properties.api.cluster_events)} />
            </div>
        </div>
    );
}
UI_MonitoringSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


function UI_SummarySection(props) {
    const cluster_id = props.cluster_id;
    const cluster_summary_data = props.cluster_summary_data;
    return (
        <>
            {
                cluster_summary_data.is_dr_cluster ?
                    <InnerSubBox>
                        <div className=''>
                            <div className='heading-area setup-summary d-flex align-center space-between' style={{ borderBottom: '0px solid #dedede' }}>
                                <p>
                                    <p className='"mainheading"'>Add and Manage Namespaces</p>
                                    <p className="subheading">Displaying overall summary of Namespaces associated with this Cluster</p>
                                </p>
                                <Link to={"/cluster/" + cluster_id + "/namespace/list"}><button className='btn btn-primary'>Get Namespaces</button></Link>
                            </div>
                        </div>
                    </InnerSubBox>
                    :
                    null
            }
            <InnerSubBox>
                <IngressSection refresh={props.refresh} cluster_id={cluster_id} />
            </InnerSubBox>
            <InnerSubBox>
                <KedaSection cluster_id={cluster_id} />
            </InnerSubBox>
            <InnerSubBox>
                <IstioSection cluster_id={cluster_id} />
            </InnerSubBox>
            <InnerSubBox>
                <ResourceSection cluster_id={cluster_id} cluster_summary_data={cluster_summary_data} />
            </InnerSubBox>
            <InnerSubBox>
                <InfraMonitoringSection cluster_id={cluster_id} />
            </InnerSubBox>
            {/* <InnerSubBox>
                <LogMonitoringSection cluster_id={cluster_id} />
            </InnerSubBox> */}
        </>
    );
}
UI_SummarySection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export function UI_InfraDetails(props) {
    const cluster_id = props.cluster_id
    const classes = useStyles();
    const data = props.data;
    const master_node_data = getNodeData(data.nodes.master);
    const worker_node_data = getNodeData(data.nodes.worker);;

    function getNodeData(data) {
        const result = {
            count: data.length,
            healthy: 0,
            unhealthy: 0,
        };
        data.forEach(item => {
            if (item.status) {
                result.healthy = result.healthy + 1;
            } else {
                result.unhealthy = result.unhealthy + 1;
            }
        });
        return result;
    }
    const [canRedirect, setRedirect] = useState(false)
    return (
        <>
            <InnerBox>
                {
                    canRedirect && <Navigate to="/ClusterDashboard" />
                }
                <InnerBoxData>
                    <div>
                        <div className={"font-14 font-weight-600 color-tertiary mb-16"}>Cloud Details</div>
                        <CloudData os={data.nodes?.os} infra_provider={data.infra_provider} onboard_cluster={data.onboard_cluster} />
                    </div>
                </InnerBoxData>
                <InnerBoxData>
                    <div>
                        <div className={"font-14 font-weight-600 color-tertiary mb-16"}>Nodes</div>
                        <div className="nodes-data">
                            <SquareBox>
                                <span className="ri-node-tree color-tertiary font-24"></span>
                            </SquareBox>
                            <div className="data">
                                <div className='mb-15' >
                                    <div className="mb-5">
                                        <span className="font-12 font-weight-600 color-primary " style={{ whiteSpace: 'nowrap' }}>Master Nodes:</span>
                                        <span className="font-12 font-weight-600 color-primary">{master_node_data.count}</span>
                                    </div>
                                    <div className='' style={{ width: '200px' }}>
                                        <span className="status-bullet status-bullet-success" style={{ marginLeft: '0px' }}></span>
                                        <span className="color-primary font-12">{master_node_data.healthy} Healthy</span>
                                        <span className="status-bullet status-bullet-danger"></span>
                                        <span className="color-primary font-12">{master_node_data.unhealthy} Unhealthy</span>
                                    </div>
                                </div>
                                <div>
                                    <div >
                                        <span className="font-12 font-weight-600 color-primary" style={{ whiteSpace: 'nowrap' }}>Worker Nodes:</span>
                                        <div style={{ width: '200px', marginTop: '5px' }}>
                                            <span className="status-bullet status-bullet-success" style={{ marginLeft: '0px' }}></span>
                                            <span className="color-primary font-12">{worker_node_data.healthy} Healthy</span>
                                            <span className="status-bullet status-bullet-danger"></span>
                                            <span className="color-primary font-12">{worker_node_data.unhealthy} Unhealthy</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </InnerBoxData>
                <InnerBoxData>

                    <UI_ClusterInfraMonitoring cluster_id={cluster_id} />
                </InnerBoxData>
            </InnerBox>
        </>
    );
}
UI_InfraDetails.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

function UI_ClusterInfraMonitoring(props) {
    const cluster_id = props.cluster_id;
    const classes = useStyles();
    const [state, setState] = useState({
        loaded: false,
    });

    useEffect(() => {
        fetchInfraHealth();
    }, [cluster_id])

    function fetchInfraHealth() {

        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_cluster_health),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }


        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState(new_state => ({
            ...new_state,
            loaded1: false,
        }));

    }

    function handleSuccessApiHit(data) {
        setState(new_state => ({
            ...new_state,
            data: data,
            loaded: true,
            error: null
        }));
    }

    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            loaded: true,
            error: error
        }));
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                <div className={"font-14 font-weight-600 color-tertiary mb-16"}>Resource Utilization</div>
                {
                    state.loaded ?
                        <>
                            {
                                state.error ?
                                    <TabTwo>
                                        <ErrorComponent error={state.error} />
                                    </TabTwo>

                                    :
                                    <TabTwo style={{ height: 'auto', overflow: 'hidden' }}>
                                        <HealthStatusRoundMeter data={state.data} />
                                    </TabTwo>
                            }
                        </>

                        :
                        <TabTwo>
                            <div className="loading-parent-div">
                                <Loading varient="light" />
                            </div>
                        </TabTwo>

                }

            </div>
        </>
    );
}
UI_ClusterInfraMonitoring.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export function UI_ClusterData(props) {
    const data = props.data;
    const health = props.health;
    return (
        <HeaderDiv>
            <div className="d-flex align-center ">
                <SquareAvatar
                    varient="double"
                    name={data.name} />
                <div className="cluster-head-data">
                    <div className="general-data">
                        <span className="cluster-name"> {data.name}</span> &nbsp;
                        {
                            data.status == "READY" ?
                                <Chip label="Connected" variant='success' />
                                : null
                        }
                    </div>
                    <div className="general-data">

                        {
                            data.namespaces ?
                                <div className="inner-data">
                                    <span className="font-12 font-weight-500 color-tertiary">Namespace: </span>
                                    <span className="font-12 font-weight-500 color-primary"> {data.namespaces.length} </span>
                                </div>
                                : null
                        }

                        <div className="inner-data">
                            <span className="font-12 font-weight-500 color-tertiary">
                                Kubernetes Version:
                            </span>
                            <span className="font-12 font-weight-500 color-primary"> {data.kubernetes_version} </span>
                        </div>


                    </div>
                </div>
            </div>
        </HeaderDiv>
    );
}
UI_ClusterData.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export function CloudData({ os, ...props }) {
    const infra_provider = props.infra_provider;
    const infra_provider_clone = properties.infra_provider_clone;
    const onboard_cluster = props.onboard_cluster;
    return (
        <CloudProvider>
            <div className="img-box">
                <span className='ri-cloud-line font-32'></span>
                {/* <img src={
                    infra_provider_clone[infra_provider.code].src
                } alt=".." /> */}
            </div>
            <div className="cloud-data">
                <p className="font-12 font-weight-600">
                    {
                        infra_provider_clone[infra_provider.code].label
                    }


                </p>
                <span className="color-tertiary font-12">Cluster Type:</span>
                <span className="color-primary font-12">&nbsp;{onboard_cluster ? "Onboarded" : "Created"} </span>
                <div>
                    <span className="color-tertiary font-12">OS:</span>
                    <span className="color-primary font-12">&nbsp;{os || "NA"}</span>
                </div>
            </div>
        </CloudProvider>
    )
}
CloudData.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export default ClusterDetail;

const HeaderDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',

    justifyContent: 'space-between',
    '& .cluster-head-data': {
        marginLeft: '10px'
    },
    '& .cluster-name': {
        color: '#0086ff',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '22px'
    },
    '& .general-data': {
        display: 'flex',
        alignContent: 'center',
        justifyItems: 'space-between',
        fontSize: '12px',
        lineHeight: '18px',
        '& .inner-data': {
            marginRight: '6px'
        },
        '& .text-color-grey': {
            color: '#989898'
        }
    },
    '& .inner-data': {
        fontSize: '12px'
    }

})

const CloudProvider = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '& .img-box': {
        backgroundColor: '#f4f4f4',
        borderRadius: '8px',
        padding: '1rem',
        width: '56px',
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& .img-box img': {
        width: '35px',
        display: 'inline-block',
        margin: 'auto',
    },
    '& .text-section': {
        marginLeft: '10px'
    },
    '& .text-section .text-black': {
        color: '#000',
        fontSize: '12px',
        lineHeight: 'normalize',
        fontWeight: 500
    },
    '& .text-section .text-grey': {
        color: '#979ba2',
        fontSize: '11px',
        lineHeight: '15px',
        textTransform: 'capitalize'
    },
    '& .cloud-data': {
        fontSize: '12px',
        marginLeft: '5px',
        lineHeight: '18px',
        '& .width-fixed': {
            width: '100px'
        },
        '& .text-color-grey': {
            color: '#989898',
            marginLeft: '3px'
        },
        '& .text-color-black': {
            marginLeft: '3px'
        }
    }
});

const InnerBoxData = styled('div')({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    borderRadius: '4px',
    maxHeight: '160px',
    overflowY: 'auto'

})
const TabTwo = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'auto',
    height: '70px',
    '& .loading-parent-div': {
        height: '60px',
        width: '100%',
        overflow: 'hidden',
        '& .loading-gif-container-two .loader#loader-1:before, .loading-gif-container-two .loader#loader-1:after': {
            top: '0px!important',
            left: '0px!important'
        },
    },
    '& .error-msg-service-card': {
        lineHeight: '1.2'
    },
    '& .progress-container': {
        padding: '0px'
    },
    '& .CircularProgressbar': {
        '& .CircularProgressbar-trail': {
            strokeWidth: '8px'
        },
        '& .CircularProgressbar-path': {
            strokeWidth: '8px'
        }
    }
})

const SquareBox = styled('div')({
    borderRadius: '8px',
    padding: '10px',
    height: '56px',
    width: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    fontSize: '18px',
    fontWeight: '700',
    color: '#fff',
    marginRight: '5px',
    '& .flaticon-nfc-near-field-communication': {
        '&:before': {
            fontSize: '24px!important'
        }
    }
})

const InnerBox = styled('div')({
    display: 'grid',
    gap: '10px 15px',
    backgroundColor: '#fff',
    gridTemplateColumns: '33% 32% 32%',
    padding: '20px 20px',
    '& .inner-data': {
        fontSize: '12px'
    },
    '& .pd-top-20': {
        padding: '20px 0px'
    },
    '& .nodes-data': {
        display: 'flex',
        alignItems: 'flex-start',
        fontSize: '12px',
        padding: '5px 0px',
        lineHeight: '14px',
        '& .width-fixed': {
            width: '92px',
            display: 'inline-block'
        },
        '& .text-color-grey': {
            color: '#989898',
            marginLeft: '3px'
        },
        '& .text-color-blue': {
            color: '#0086ff',
            marginLeft: '3px'
        }
    },
    '& .text-black': {
        fontSize: '12px'
    },
    '& .k8s-version': {
        fontSize: '11px',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0px',
        '& .flaticon-circle-with-check-symbol': {
            '&:before': {
                fontSize: '14px!important'
            },
            '&:after': {
                fontSize: '14px'
            }
        },
        '& img': {
            width: '36px',
            height: '36px',
            display: 'block',
        }
    }
})

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: 'calc( 100vh - 70px )',
        backgroundColor: '#fff',
        '& .cluster-head-data': {
            "& .cluster-name": {
                color: '#000!important'
            }
        },
    },
    mainBodyDiv: {
        // border: ' 1px solid #E6E6E6',
        // boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        overflow: 'hidden',
        '& .inner-data': {
            fontSize: '12px'
        },
        '& .border-bottom': {
            borderBottom: '1px solid #E6E6E6',
        },
        '& .headerul': {
            borderBottom: '0px solid #dedede',
            padding: '15px 20px 0px'
        },
        '& .expensionPanel': {
            border: 'none',
            backgroundColor: 'transparent',
            margin: '0px!important',
            '& .expensionPanelHead': {
                display: 'flex'
            }
        },
        '& .expensionPanelBody': {
            height: '100%',
            '& .head': {
                padding: '10px'
            }
        },
        '& .expensionPanelTab': {
            position: 'relative',
            top: '2px'
        }
    },
    subBoby: {
        backgroundColor: '#fff',
    },
    smallText: {
        fontSize: '12px',
        color: '#000',
        lineHeight: '2'
    }
}))


const Boxes = styled('div')({
    backgroundColor: '#fff',
    margin: '10px',
    display: 'grid',
    gap: '10px',
    gridTemplateColumns: '49.5% 49.5%',
    justifyContent: 'space-between',
    '& .header-part': {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #E6E6E6',
        width: '100%',
        '& .heading-area': {
            marginLeft: '5px'
        },
        '& .mainheading': {
            fontSize: '18px',
            lineHeight: '1.2'
        },
        '& .subheading': {
            fontSize: '12px',
            color: '#989898',
            lineHeight: '1.2'
        }
    },
    '& .body-part': {
        display: 'grid',
        padding: '10px',
        height: '100px'
    },
    '& .task-section': {
        alignSelf: 'center'
    },
    '& .template-ingress': {
        gridTemplateColumns: '50% 50%'
    },
    '& .template-resources': {
        gridTemplateColumns: '33% 33% 32%',
        gap: '10px'
    }
})

const InnerSubBox = styled('div')({
    backgroundColor: '#fff',
    border: '1px solid #E6E6E6',
    borderRadius: '4px',
    margin: '10px'
})
