import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';;
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';
import MultiRow from '../../../components/genericComponents/MultiRow';


const PipepineAddiitonalField = (props) => {

    console.log('p_ev', props)
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : getEnvVarFormDefaultState());

    const commonFunctions = getCommonFunctions(state, setState, inherits);

    console.log(state, 'inside-ss')
    return (
        <>
            <MultiRow
                // variant='reorder-version'
                RepeatableComponent={AddMoreEnvVariable}
                HeaderComponent={HeaderSectionEnv}
                prev_state={state.env_var_form}
                inherits={state.child_inherits.env_var_form} />
        </>
    )
}

export default PipepineAddiitonalField;

export function getEnvVarFormDefaultState() {
    return {
        data: {
        },
        error: {},
        validations: {
        },
        child_inherits: {
            env_var_form: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
        }

    }
}

function AddMoreEnvVariable(props) {
    const inherits = props.inherits;
    const prev_state = props.prev_state ? props.prev_state : null;
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [state, setState] = useState(prev_state ? getAddMoreEnvVar(prev_state) : getAddMoreEnvVar());
    const commonFunctions = getCommonFunctions(state, setState, inherits)

    console.log('ps_o', prev_state)
    console.log(state, 'stat_of_add')

    console.log(inherits, 'inherits_of_form', props)

    useEffect(() => {

        if (state.data.select_dropdown_options) {

            const array = state.data.select_dropdown_options.split(',')

            const options = array && array.map(item => {

                const updatedString = item.trim()
                return {
                    id: updatedString,

                    label: updatedString

                }

            })
            setDropdownOptions(options)
        }

    }, [state.data.select_dropdown_options])

    const handleChange = (e) => {

        const value = e.target.value
        const fieldName = e.target.name
        let formData = state.data
        formData[fieldName] = value
        setState(prevState => ({
            ...prevState,
            data: formData,
            error: {
                ...prevState.error,
                [fieldName]: ""
            }
        }))

    }

    return (
        <>
            <Grid item lg={3}>
                <Input
                    type="text"
                    mandatorySign
                    onChangeHandler={handleChange}
                    placeholder="Enter Key"
                    name='key'
                    label=""
                    data={state.data}
                    error={state.error}
                />
            </Grid>
            <Grid item lg={4} className='pr-15'>
                <Input
                    type="text"
                    name='value'
                    label=""
                    placeholder="Enter Name"
                    mandatorySign
                    onChangeHandler={handleChange}
                    data={state.data}
                    error={state.error}
                />
            </Grid>

            <Grid item lg={2}>
                <Input
                    type="only-switch-without-label"
                    label=""
                    placeholder="value"
                    name="over_ridable"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={commonFunctions.toggleState}
                />
            </Grid>
            {
                state.data["over_ridable"] ?
                    <Grid item lg={2}>
                        <Input
                            type="only-switch-without-label"
                            label=""
                            placeholder="value"
                            name="mandatory"
                            data={state.data}
                            error={state.error}
                            onChangeHandler={commonFunctions.toggleState}
                        />
                    </Grid> :
                    <Grid item lg={2}>

                    </Grid>
            }
        </>
    )
}

export function getAddMoreEnvVar(prevState) {

    if (prevState) {
        console.log('ps_0001', prevState)
        let validations = {
            // key: [VALIDATION_TYPE_REQUIRED],
        }
        return { ...prevState, validations: validations }
    }
    else
        return {
            data: {
                key:null,
                over_ridable:false,
                mandatory:false
            },
            error: {},
            validations: {
                // key: [VALIDATION_TYPE_REQUIRED],
            },
        }
}

function HeaderSectionEnv(props) {
    return (
        <>
            <Grid item lg={3}>
                <p className='font-12'>Key</p>
            </Grid>
            <Grid item lg={4} className='pr-15'>
                <p className='font-12'>Value</p>
            </Grid>
            <Grid item lg={2}>
                <p className='font-12'>Is this field over-ridable</p>
            </Grid>
            <Grid item lg={2}>
                <p className='font-12'>Is this field Mandatory</p>
            </Grid>
        </>
    )
}
