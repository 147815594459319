import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../ci_flow/SourceDetails';

import SelectIngress1, { getIngressDefaultState } from '../../../../../components/genericComponents/inputs/SelectIngress1';
import SelectIstio1, { getIstioDefaultState } from '../../../../../components/genericComponents/inputs/SelectIstio1';
import Grid from '@mui/material/Grid';;
import { Input } from '../../../../../components/genericComponents/Input';


const ProtectedIngress = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const extraProps = props.extraProps ? props.extraProps : { service_name: "default", project_env_name: "default", environment_master_name: "default" };
    const prev_state = props.prev_state ? props.prev_state : null;
    console.log(prev_state,"Fsadfsdfaprev_state")
    const [state, setState] = useState(prev_state ?  getProtectedDefaultState(extraProps,prev_state) : getProtectedDefaultState(extraProps))
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const protocol_list = [{ label: "TCP", id: "TCP" }, { label: "HTTP", id: "HTTP" }]
    const validateAndSaveAccessLevel = props.validateAndSaveAccessLevel ? props.validateAndSaveAccessLevel : () => {}
    const cancelForm = props.cancelForm ? props.cancelForm : ()=>{}
    console.log(state,prev_state,"getPublicPrivateDefaultState")
    useEffect(() => {
      if(prev_state){
        setState(new_state => ({ 
          ...new_state, 
          updateFnActive:true,
          data:{
            ...new_state.data, 
            ...prev_state,
            protocol : 'TCP'
          },
          ingress:prev_state && prev_state.ingress_id ? {
            data: {
              ingress_id: prev_state.ingress_id,
            }
          }: null,
          gateway:prev_state && prev_state.gateway_id ? {
            data: {
              gateway_id: prev_state.gateway_id,
            }
          }: null
        }));
      }
      
     
     }, [prev_state])

    const validateAndSavePrivateAccessLevel = () =>{
      validateAndSaveAccessLevel();
      clearForm()
     }
    const clearForm = () => {
      setState(new_state => (
        { 
          ...new_state, 
          data:{
            ...new_state.data,
            name:"",
            protocol: "TCP",
            port: "",
            target_port:"",
            expose_path: "",
          },
          ingress:{
            ...getIngressDefaultState(),
            data:{
              ingress_id : ""
            }
          },
          gateway:{
            ...getIstioDefaultState(),
            data:{
              gateway_id : ""
            }
          }
           }))
    }

    return (
        <>
        <Grid lg={12}>
            <div className='section'>
              <Input
                type="text"
                name="name"
                label="Name"
                mandatorySign
                placeholder="name"
                maxLength = {15}
                onChangeHandler={commonFunctions.onChangeHandlerWithHyphenDahs}
                data={state.data}
                error={state.error} />
            </div>
          </Grid>
          <Grid lg={4}>
            <div className='section'>
              {/* <Input
                type="select"
                name="protocol"
                label="Select Protocol"
                mandatorySign
                list={protocol_list}
                placeholder="name"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} /> */}

              <Input
                type="text"
                name="protocol"
                label="Select Protocol"
                mandatorySign
                placeholder="name"
                onChangeHandler={null}
                data={state.data}
                error={state.error} />
            </div>
          </Grid>
          <Grid item lg={4} >
        
            <div className='section'>
            {
          extraProps.istio_enabled ?
          <SelectIstio1
          data={state.data}
          error={state.error}
          onChangeHandler={commonFunctions.onChangeHandler}
          onUpdate={commonFunctions.updateData}
          type={"PROTECTED"}
          cluster_id={extraProps.cluster_id}
          prev_state={state.gateway}
          inherits={state.child_inherits.gateway}
          name={"gateway_id"}
          />
              :
              <SelectIngress1
              type={"protected"}
              cluster_id={extraProps.cluster_id}
              prev_state={state.ingress}
              inherits={state.child_inherits.ingress}
            /> 
        }

            </div>
          </Grid>
          <Grid item lg={4} >
            <div className='section'>
              <Input
                type="text"
                placeholder="URL Format: service name/env name/sub env name"
                name="expose_url"
                mandatorySign
                label="What is the URL"
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data} />
            </div>
          </Grid>

          <Grid item lg={4}>
            <div className='section'>
              <Input
                type="text"
                placeholder="Add the path to be exposed"
                mandatorySign
                name="expose_path"
                label="What is the Expose Path?"
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data} />
            </div>
          </Grid>
          <Grid item lg={4}>
            <div className='section'>
              <Input
                type="text"
                name="port"
                label="Port"
                mandatorySign
                placeholder="4001"
                onChangeHandler={commonFunctions.onChangeHandlerNumber}
                data={state.data}
                error={state.error} />
            </div>
          </Grid>
          <Grid item lg={4}>
            <div className='section'>
              <Input
                type="text"
                name="target_port"
                label="Target Port"
                mandatorySign
                placeholder="4001"
                onChangeHandler={commonFunctions.onChangeHandlerNumber}
                data={state.data}
                error={state.error} />
            </div>
          </Grid>
          <div className="d-flex align-center justify-end" style={{textAlign:'end', borderTop: '1px solid #dedede', padding: '10px 10px 0px 10px', width:'100%',gap:'5px'}}>
                <button className='btn btn-v2-primary btn-sm' 
                    onClick={validateAndSavePrivateAccessLevel}> {prev_state ? "Update" : "Add"}</button>
                    <button className='btn btn-outline-grey btn-sm'onClick={prev_state ?cancelForm: clearForm }>Cancel</button>
                </div>
        </>
    )
}
ProtectedIngress.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default ProtectedIngress

export function getProtectedDefaultState(extraProps, prev_state){
    const service_name = extraProps ? extraProps.service_name : "";
    const env_master = extraProps ? extraProps.environment_master_name : "";
    const project_env_name = extraProps ? extraProps.project_env_name : "";
    return{
        data: prev_state ? {...prev_state, 
          expose_url: service_name ? (service_name + "/" + env_master + "/" + project_env_name) : "",
          access_level: "PROTECTED",
          protocol: "TCP"
        } :{
            expose_url: service_name ? (service_name + "/" + env_master + "/" + project_env_name) : "",
            access_level: "PROTECTED",
            protocol: "TCP"
          },
          error: {},
          validations: {
            
          },
          ingress:prev_state && prev_state.ingress_id ? {
            ...getIngressDefaultState(),
            data: {
              ingress_id: prev_state.ingress_id? prev_state.ingress_id : ""
            }
            
          }: null,
          gateway: prev_state && prev_state.gateway_id ? {
            ...getIstioDefaultState(),
            data: {
              gateway_id: prev_state.gateway_id? prev_state.gateway_id:""
            }
          }: null,
          child_inherits:{
            ingress:{
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            gateway:{
              "validateForm": () => { return { valid: true } },
              "getState": () => { return {} },
              "getData": () => { return {} }
            }
          }
    }
}