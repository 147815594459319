import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';



const ProfileLayout = ({ layout: Layout, component: Component }) => {
  const location = useLocation(); // Access route information if needed

  return (
    <Layout>
      <Component location={location} />
    </Layout>
  );
};

ProfileLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
  };

export default ProfileLayout;


