import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import MultiRow from '../../../../components/genericComponents/MultiRow';
import { Input } from '../../../../components/genericComponents/Input';
import { ValidateDataSet } from '../../../../util/Validator';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { Loading } from '../../../utils/Loading';

const AdditionalFiledDialog = props => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const custom_fields_key_value = props.custom_fields_key_value;
    const add_custom_fields = props.add_custom_fields;
    const issueType = props.issueType ? props.issueType : null;
    const default_job_template = props.default_job_template ? props.default_job_template : null;
    const jira_data_types = props.jira_data_types;
    const showAdditionalField = props.showAdditionalField;
    const flipAndOpenAdditionalFiledsForm = props.flipAndOpenAdditionalFiledsForm ? props.flipAndOpenAdditionalFiledsForm : () => { }
    const all_templates = props.all_templates;
    const handleClose = props.handleClose;
    const [state, setState] = useState({
        open: false,
        fullWidth: true,
        maxWidth: 'md',
        data: {},
        error: {},
        jira_additional_fields: {},
        child_inherits: {
            custom_fileds: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        }
    })
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    useEffect(() => {
        if (default_job_template && default_job_template.id && issueType) {
            fetchJiraIssueTypeList()
        }

    }, [default_job_template, issueType])
    function fetchJiraIssueTypeList() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.jira_additional_fields) + "?job_template_id=" + default_job_template.id + "&issue_type=" + issueType,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchJiraIssueTypeListSuccess, fetchJiraIssueTypeListFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function fetchJiraIssueTypeListSuccess(response) {

        setState(new_state => ({
            ...new_state,
            jira_additional_fields: response.Addition_fields,
            loading_data: false
        }));

    }
    function fetchJiraIssueTypeListFailure(error) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
            error: error_msg,
            loading_data: false
        }));
    }


    const handleClickOpen = () => {
        setState(new_state => ({
            ...new_state,
            open: true,

        }));
    };



    function pushDataToArray() {
        var result = ValidateDataSet(state.data, state.validations);
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm() : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var custom_fileds_data = child_states.custom_fileds;

        var totalCustomFields = [];
        if (custom_fileds_data) {
            Object.keys(custom_fileds_data).forEach(key => {
                if (key == "data" || key == "child_inherits" || key == "show_view" || key == "count") {

                } else {
                    const custom_fields_key_value = custom_fileds_data[key].data;
                    custom_fields_key_value["over-ridable"] = custom_fileds_data[key].data["over-ridable"] ? true : false;
                    custom_fields_key_value["mandatory"] = custom_fileds_data[key].data["mandatory"] ? true : false;

                    totalCustomFields.push(custom_fields_key_value)


                }
            })
        }
        add_custom_fields(totalCustomFields);
        props.flipCloseAndRetainState();
    }
    function getMultiRowPreviousState() {
        var prev_state = null
        if (custom_fields_key_value) {
            prev_state = {
                data: {},
                child_inherits: {}
            }
            var count = 1

            custom_fields_key_value.forEach((item, key) => {

                prev_state[count] = {
                    ...getFromDefaultState(),
                    data: {
                        key: item.key,
                        value: item.value,
                        "over-ridable": item["over-ridable"] ? true : false,
                        "mandatory": item["mandatory"] ? true : false
                    }
                }
                prev_state.child_inherits[count] = {}
                count++
            })
        }
        return prev_state
    }

    return (
        <>{
            showAdditionalField &&
            <div className='additional_filed_cs' style={{ padding: '20px', backgroundColor: '#ededed', minHeight: 'calc(100% - 120px)', borderRadius: '5px', display: 'block' }}>
                <div style={{ backgroundColor: '#fff', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                    <div className={`d-flex align-center border-bottom space-between `} style={{ height: '50px', backgroundColor: '#fafafa', padding: '15px', borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}>
                        <span style={{ color: '#6d6a6a' }} className='font-14 mr-5'>Configure Additional Jira Fileds</span>

                        <button className="btn btn-transparent" edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <span className='ri-close-line'style={{ color: 'rgb(109, 106, 106)' }} fontSize="large"></span>
                        </button>
                    </div>
                    <div className={classes.root}>
                        <div className="pl-10 h-250 pr-10 " style={state.loading_data ? { minHeight: '128px', display: 'flex' } : null}>
                            {
                                state.loading_data ?
                                    <div className='m-auto'>
                                        <Loading varient="light" />
                                    </div> :
                                    <MultiRow
                                        show_view_component={false}
                                        RepeatableComponent={AdditionalField}
                                        prev_state={getMultiRowPreviousState()}
                                        HeaderComponent={HeaderSectionInitComands}
                                        inherits={state.child_inherits.custom_fileds}
                                        zeroRowsAllowed={true}
                                        repeatableComponentProps={{ input_list: state.jira_additional_fields, jira_data_types: jira_data_types, all_templates:all_templates }}
                                    />
                            }

                        </div>
                        {state.loading_data ?
                            null :
                            <div className="d-flex align-center justify-end pd-10 ">
                                <button className="btn btn-outline-grey" onClick={handleClose}>Cancel</button>
                                <button className="btn btn-submit" onClick={pushDataToArray}>Save</button>
                            </div>
                        }

                    </div>
                </div>
            </div>
        }
        </>
    )
}


AdditionalFiledDialog.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default AdditionalFiledDialog;


function AdditionalField(props) {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : getFromDefaultState())
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const input_list = props.extraProps.input_list ? props.extraProps.input_list : [];
    const jira_data_types = props.extraProps.jira_data_types ? props.extraProps.jira_data_types : []
    const all_templates = props.extraProps.all_templates ? props.extraProps.all_templates : [];
    var input_labels = Object.keys(input_list);
    const onChangeHandler = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        var key_to_get = input_list[value];
        setState(new_state => ({
            ...new_state,
            input_type: key_to_get.type,
            data: {
                value: key_to_get.defaultValue ? key_to_get.defaultValue : "",
                mandatory: key_to_get.required,
                data_type: key_to_get.type
            }
        }))
        commonFunctions.onChangeHandler(e)
    }
    function fetchJobTemplatesList() {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application_id }, properties.api.add_job_template),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchJobTemplatesListSuccess, fetchJobTemplatesListFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function fetchJobTemplatesListSuccess(response) {
        setState(new_state => ({
            ...new_state,
            all_templates: response.results,
            loading_data: false
        }));

    }
    function fetchJobTemplatesListFailure(error) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
            error: error_msg,
            loading_data: false
        }));
    }
    const onKeyValueChangeHandler = (k, v) => {
        setState({
            ...state, data: { ...state.data, [k]: v },
            error: { ...state.error, [k]: null }
        });
    }
    return (
        <>
            <Grid item lg={3}>

                <Input
                    type="select"
                    list={input_labels.map(item => { return { id: item, label: item } })}
                    name="key"
                    label="Select Custom Field"
                    placeholder="key"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={onChangeHandler}
                />
            </Grid>
            {
                state.input_type === "text" || state.input_type === "select" || state.input_type === "toggle" || state.input_type === "number" || state.input_type ==="checkbox"?
                    <>
                        <Grid item lg={3}>

                            <Input
                                type={state.input_type === "select" || state.input_type === "toggle" ? "select" :state.input_type ==="checkbox" ? "select-with-checkbox":  "text"}
                                placeholder="value"
                                label="Enter Custom Field Value"
                                name="value"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={state.input_type === "number" ? commonFunctions.onChangeHandlerNumber : state.input_type==="checkbox"? onKeyValueChangeHandler: commonFunctions.onChangeHandler}
                            />
                        </Grid>
                        <Grid item lg={2}>
                            <Input
                                type="only-switch"
                                label="Is this field over-ridable"
                                placeholder="value"
                                name="over-ridable"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={commonFunctions.toggleState}
                            />
                        </Grid>
                        {
                            state.data["over-ridable"] &&
                            <Grid item lg={2}>
                                <Input
                                    type="only-switch"
                                    label="Is this field Mandatory"
                                    placeholder="value"
                                    name="mandatory"
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.toggleState}
                                />
                            </Grid>
                        }
                    </>
                    : null
            }
           

        </>
    )
}

AdditionalField.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};
function getFromDefaultState() {
    return {
        data: {},
        error: {}
    }
}
function HeaderSectionInitComands(props) {
    return (
        <>
            {/* <Grid item lg={3}>
                Field Name
            </Grid>
            <Grid item lg={3}>
                Value
            </Grid>
            <Grid item lg={4}>
                Overridable & Mandatory
            </Grid> */}
        </>
    )
}



function getSelectedConditions(custom_fields_key_value) {
    var count = 1
    var data = {}
    var child_inherits = {}
    if (custom_fields_key_value) {
        Object.keys(custom_fields_key_value).forEach(key => {
            data = {
                ...data,

                key: key,
                value: custom_fields_key_value[key]
            }



        })
        return data;
    }

}
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    root: {
        '& .h-300': {
            height: 350,
            overflowy: 'scroll'
        }
    }
}));


