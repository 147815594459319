

import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from "@mui/system";
import { makeStyles } from '@mui/styles';
import GenerateURL, { GenerateSearchURL } from "../../../../util/APIUrlProvider";
import InvokeApi, { PostData } from "../../../../util/apiInvoker";
import properties from "../../../../properties/properties";
import { Input } from "../../../../components/genericComponents/Input";
import StageProgressDetail from ".././component/StageProgressDetail";
import { Link, useParams } from "react-router-dom";

import CardFooter from ".././component/CardFooter";
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import Pagination from "../../../../components/Pagination";
import RunWithParameter from ".././RunWithParameter";
import Delete from "../../../../components/genericComponents/Delete";
import { Grid, Tooltip, Dialog, Slide, Card } from "@mui/material";
import RunWithParameterForV3 from ".././RunWithParameterForV3";
import RetryPopup from ".././component/RetryPopup";
import AdvanceSearchFilterCombo from "../../../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo";
import GenericSkeleton from "../../../../components/genericComponents/Skeletons/GenericSkeleton";
import { useNavigate, useLocation } from 'react-router';
import useFetchPermissions from "../../../../components/customHooks/useFetchPermissions";
import PipelineExecutionHistory from "../component/PipelineExecutionHistory";
import Header from "../component/Header";
import GeneralData from "../component/GeneralData";
import PipelineCardHeader from "./PipelineCardHeader";
import PipelineCardBottom from "./PipelineCardBottom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const PipelineSection = ({ skeleton, ...props }) => {
    const permissions = useFetchPermissions();

    if (skeleton) {
        return (
            <div style={{ marginBottom: '20px' }}>
                <GenericSkeleton variant={"rect"} height={136} style={{ borderRadius: "12px" }} />
            </div>
        );
    }
    const application_id = props.application_id;
    const history = useNavigate();
    const pipeline = props.pipeline;
    console.log("fsajkfjsa",pipeline)
    const pipeline_sse_status = props.pipeline_sse_status
        ? props.pipeline_sse_status
        : null;
    const pipeline_instance = props.pipeline_instance;
    const requestForDelete = props.requestForDelete
    const loading_sse_status = props.loading_sse_status;
    const trigger_id =
        pipeline_instance && pipeline_instance.id ? pipeline_instance.id : "";
    const requestAllPipelineLastExeccutions =
        props.requestAllPipelineLastExeccutions;
    const refreshForDelete = props.refreshForDelete;
    const [open, setOpen] = useState(props.open);
    const [runWithParameter, setRunWithParameter] = useState(false);
    const pipeline_current_page = props.pipeline_current_page;
    const current_page_no = props.current_page_no;
    const flag_for_feature_id = properties.flag_for_feature_id;
    const [openDialog, setOpenDialog] = useState(false)
    const [state, setState] = useState({
        open: false,
        errors: {},
        canRedirect: false,
        show_tag: false,
        data: {},
        error: {},
        event_source: null,
        stage_with_sse_status: {},
        jira_reference_name: [],
        last_execution_data: {
            success: null,
            fail: null
        }
    });

    const [expandableView, setExpandableView] = useState(false)

    var url = GenerateURL(
        { pipeline_id: pipeline.id },
        properties.api.pipeline_update_url,
        true
    );
    const is_edit_permited = permissions?.DELETE.includes(url);

    console.log(is_edit_permited, "ertyudfgxcv ================>")
    url = GenerateURL(
        { pipeline_id: pipeline.id },
        properties.api.trigger_pipeline,
        true
    );
    const is_trigger_permited = permissions?.POST.includes(url);

    function toggleDisplay() {
        setOpen(!open);
    }
    function handleClosePipelineTrigger() {
        setState((new_state) => ({
            ...new_state,
            pipeline_trigger: null,
            pipeline_trigger_success_msg: null,
            pipeline_trigger_failed_msg: null,
        }));
    }
    function triggerPipeline(id, data) {
        console.log(data, "data___")
        var url = GenerateURL({ pipeline_id: id }, properties.api.trigger_pipeline);
        PostData(url, data ? data : {}, handleSuccesTrigger, handleFailedTrigger, false, true);
    }
    function validateAndTrigger(id) {
        const error = {};
        var has_error = false;
        if (!state.data.feature_tag) {
            error.feature_tag = "This field is required ";
            has_error = true;
        }
        if (!has_error) {
            triggerPipeline(id, state.data);
        } else {
            setState({
                ...state,
                error: error,
            });
        }
    }
    function retryPipeline(pipleine_id, trigger_id) {
        var url = GenerateURL(
            { pipeline_id: pipleine_id, trigger_id: trigger_id },
            properties.api.retry_pipeline
        );
        var post_data = {};
        // post_data = pipeline_instance.context_param && pipeline_instance.context_param;
        PostData(url, post_data, handleRetrySuccess, handleRetryFaild);
    }
    function handleRetrySuccess(data) {
        redirectToExecution();
        requestAllPipelineLastExeccutions();
    }

    function handleRetryFaild(error) {
        setState((new_state) => ({
            ...new_state,
            error: error,
            reTriggerError: error,
            canRedirect: false,
        }));
    }

    function retriggerPipeline(pipleine_id, trigger_id) {
        var url = GenerateURL(
            { pipeline_id: pipleine_id, trigger_id: trigger_id },
            properties.api.retry_pipeline
        );
        var post_data = {};
        // post_data = pipeline_instance.context_param && pipeline_instance.context_param;

        PostData(url, post_data, handleRetrySuccess, handleRetryFaild);
    }


    const resetTriggerError = () => {
        setState(prevState => ({
            ...prevState,
            reTriggerError: null // for retry popup if pipeline has been edited
        }))
    }
    function redirectToExecution() {
        if (pipeline.last_trigger) {
            if (pipeline.last_trigger.id == pipeline_instance.id) {
                setState((new_state) => ({
                    ...new_state,
                    new_instance_id: pipeline.last_trigger.id,
                    canRedirect: true,
                    reTriggerError: null
                }));
            }
        }
    }
    function handleSuccesTrigger(data) {
        redirectToExecution();
        requestAllPipelineLastExeccutions();
        setState((new_state) => ({
            ...new_state,
            pipeline_trigger: "success",
            pipeline_trigger_success_msg: "Pipeline Triggered Successfully",
        }));
    }

    function handleFailedTrigger(error) {
        console.log("failed", error);
        setState((new_state) => ({
            ...new_state,
            pipeline_trigger: "failed",
            pipeline_trigger_failed_msg:
                error && error.detail ? error.detail : JSON.stringify(error),
        }));
    }
    const handleClose = (show_success_message) => {
        setState({
            ...state,
            errors: {},
            open: false,
            show_success_message: show_success_message == true,
            fetch_request: false,
        });
    };

    function triggerRevoke(id, id2) {
        var url_temp = GenerateURL(
            { pipeline_id: id, pipeline_instance_id: id2 },
            properties.api.revoke_pipeline
        );
        var fetchOptions = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        PostData(url_temp, {}, handleSuccessRevoke, handleFailedRevoke);
    }

    function handleSuccessRevoke(respone) {
        //    setState(new_state => ({
        //   ...new_state,

        // }));
        requestAllPipelineLastExeccutions();
    }

    function handleFailedRevoke(error) { }

    function triggerResume(id, id2) {
        var url_temp = GenerateURL(
            { pipeline_id: id, pipeline_instance_id: id2 },
            properties.api.resume_pipeline
        );
        var fetchOptions = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        PostData(url_temp, {}, handleSuccessResume, handleFailedResume);
    }

    function handleSuccessResume(response) {
        requestAllPipelineLastExeccutions();
        var success_msg = response.detail
            ? response.detail
            : JSON.stringify(response);

        setState((new_state) => ({
            ...new_state,
            success_resume_msg: success_msg,
            triggerResume: "successs",
        }));
        console.log(
            "fsdfdsakjfjka",
            state.success_resume_msg,
            state.triggerResume,
            success_msg
        );
    }
    console.log("fsdfdsakjfjka", state.success_resume_msg, state.triggerResume);

    function handleFailedResume(error) {
        var error_msg = error.detail ? error.detail : JSON.stringify(error);
        setState((new_state) => ({
            ...new_state,
            failed_resume_msg: error_msg,
            triggerResume: "failed",
        }));
    }
    const handleCloseTriggerResume = () => {
        setState({
            ...state,
            triggerResume: null,
            success_resume_msg: null,
            failed_resume_msg: null,
        });
    };

    function showFeatureTag() {
        setState((new_state) => ({
            ...new_state,
            show_tag: !new_state.show_tag,
        }));
    }
    function onChangeHandler(e) {
        var key = e.target.name;
        var value = e.target.value;
        updateKeyValue(key, value);
    }
    function updateKeyValue(key, value) {
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value,
            },
            error: {
                ...state.error,
                [key]: null,
            },
        });
    }
    const fetchJiraReferenceKeys = () => {
        var requestInfo = {
            endPoint: GenerateURL(
                { pipeline_id: pipeline.id },
                properties.api.pipeline_update_url
            ),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        InvokeApi(
            requestInfo,
            handlefetchJiraReferenceKeysSuccess,
            handlefetchJiraReferenceKeysFailed
        );
        setState((new_state) => ({
            ...new_state,
            show_loading_icon: true,
        }));
    };

    function handlefetchJiraReferenceKeysSuccess(response) {

        setState((new_state) => ({
            ...new_state,
            jira_reference_keys: response.jira_references,
            jira_reference_name: response.jira_linked_issue_name_list,
            show_loading_icon: false,
        }));
    }

    function handlefetchJiraReferenceKeysFailed(error) {
        setState((new_state) => ({
            ...new_state,
            show_loading_icon: false,
            error: error,
        }));
    }
    function toggleFunction() {
        fetchJiraReferenceKeys();
        setRunWithParameter(!runWithParameter);
        handleClickOpenDialog()
    }


    const handleRuntimeParamsForV3 = () => {
        history(`/application/${application_id}/pipeline/${pipeline.id}/runtime-params-update`)
    }

    console.log(pipeline, 'pipeline_data_inside_card');
    const handleClickCloseDialog = () => {
        setOpenDialog(false)
    }
    const handleClickOpenDialog = () => {
        setOpenDialog(true)
    }
    console.log(state, 'error_main_jg');

    const toggleExpandableView = () => {
        setExpandableView(!expandableView)
    }



    console.log(state.last_execution_data, 'data_rr_plk');

    return (
        <PipelineCard>
            <PipelineCardHeader
                triggerRevoke={triggerRevoke}
                skeleton={skeleton}
                application_id={application_id}
                pipeline={pipeline}
                pipeline_instance={pipeline_instance}
                isExpandableView={expandableView}
                triggerPipeline={triggerPipeline}
                retryPipeline={retryPipeline}
                flag_for_feature_id={flag_for_feature_id}
                showFeatureTag={showFeatureTag}
                is_trigger_permited={is_trigger_permited}
                is_edit_permited={is_edit_permited}
                requestForDelete={requestForDelete}
                toggleFunction={toggleFunction}
                handleRuntimeParamsForV3={handleRuntimeParamsForV3}
            />

            {
                pipeline_instance &&
                <PipelineCardBottom
                    isExpandableView={expandableView}
                    triggerRevoke={triggerRevoke}
                    pipeline={pipeline}
                    pipeline_instance={pipeline_instance}
                    toggleExpandableView={toggleExpandableView}
                    pipeline_current_page={pipeline_current_page}
                    components={pipeline.components}
                    tags={pipeline.tags}
                    sendForApproval={props.sendForApproval}
                    confirmForApproval={props.confirmForApproval}
                    reTriggerError={state.reTriggerError}
                    application_id={application_id}
                    current_page_no={current_page_no}
                    retryPipeline={retryPipeline}
                    retriggerPipeline={retriggerPipeline}
                />
            }
            {flag_for_feature_id ? (
                <div
                    className="feature_tag_div"
                    style={
                        state.show_tag && !runWithParameter
                            ? { height: 120 }
                            : { height: 0 }
                    }
                >
                    <Input
                        type="text"
                        label="Feature Id"
                        name="feature_tag"
                        mandatorySign
                        placeholder="Enter Feature Id"
                        data={state.data}
                        error={state.error}
                        onChangeHandler={onChangeHandler}
                    />
                    <button
                        className="btn-grey-outline btn-blue-curve ml-auto"
                        onClick={() => validateAndTrigger(pipeline.id)}
                    >
                        {" "}
                        Run Pipeline
                    </button>
                </div>
            ) : null}
            {runWithParameter ?
                <>
                    {
                        pipeline.version === 'v3' ? <RunWithParameterForV3
                            toggleDismiss={() => {
                                setRunWithParameter(!runWithParameter);
                            }}
                            services={pipeline.components}
                            pipeline_id={pipeline.id}
                            pipeline_basic_data={pipeline}
                            triggerPipeline={triggerPipeline}
                        /> :

                            openDialog &&
                            <Dialog
                                open={openDialog}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClickCloseDialog}
                                sx={{
                                    "& .MuiDialog-paper": {
                                        overflow: "visible",
                                        height: "100vh",
                                        transition: "0.2s all ease-in-out",
                                    },
                                }} className={"integrations-dialog run-with-params-dialog"}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"

                            >
                                <button
                                    onClick={handleClickCloseDialog}
                                    className='btn float-cancel-button'
                                    style={{ left: "-54px" }}
                                >
                                    <span className='ri-close-line'></span>
                                </button>

                                <div className='card-header-new'>
                                    <div className='d-flex align-center space-between' style={{ width: '100%' }}>
                                        <div className='d-flex align-center'>
                                            <span className='font-18 ml-5 d-inline-block font-weight-600' style={{ color: '#fff', lineHeight: "21.94px" }}>
                                                Run With Parameters                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <RunWithParameter
                                    required_jira_keys={state.jira_reference_keys}
                                    jira_reference_name_list={state.jira_reference_name}
                                    toggleDismiss={() => {
                                        setRunWithParameter(!runWithParameter);
                                    }}
                                    services={pipeline.components}
                                    pipeline_id={pipeline.id}
                                    pipeline_basic_data={pipeline}
                                    triggerPipeline={triggerPipeline}
                                    application_id={application_id}
                                />
                            </Dialog>

                    }
                </>

                : null}

        </PipelineCard>
    );
};


PipelineSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

// Styles

export default PipelineSection;

const PipelineCard = styled("div")({
    minHeight: '136px',
    borderRadius: '12px',
    border: '1px solid #E6E6E6',
    boxShadow: '0px 4px 4px 0px #0000001F',
    marginBottom: '20px',
    background: '#FFFFFF'
});
