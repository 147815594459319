import React from 'react'
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';;
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';
import { Link } from 'react-router-dom';
import { GetApplication } from '../../../../util/GlobalVars';
const Review = (props) =>{
  const application_id = props.application_id;
  const service_id = props.service_id;
  const application = GetApplication(application_id);
  return(
      <Grid container justify="center">
      <Grid lg={12}>
        <div className="section-completion">
            <div className="service-completion">
            <div  className="bold-text-purple">
            <span className="flaticon-circle-with-check-symbol"></span>
          </div>
          <div>
          <p className="sentence-text-purple"><span  className="bold-text-purple">Congrats</span>&nbsp;your service "{props.service_name}" is configured now</p>
          <p className="sentence-text-black">Now you can build or deploy your service via <span className="color-purple"> pipeline</span>/ <span className="color-purple">build & deploy </span><br />options on the service overview page</p>
          </div>
            </div>
            <div className="go-to-dashboard">
                <Link to={"/ApplicationDashboard"} className="btn btn-submit"> Go to Dashboard  <i class="ri-arrow-right-s-line" style={{fontSize:'18px!important'}} /></Link>
            </div>
        </div>
      </Grid>
      <Grid lg={12}>
          <Grid container justify="center">
              <Grid  direction="row" className="formDivwdb">
                <div style={{display:'flex', alignItems:'center',justifyContent:'space-around', position:'relative'}}>
                <div className="image-container">
                    <div className="content-box-small-card">
                    <span className="flaticon-show-apps-button"></span>
                <div className="text-ellipsis-150">
                  <span className="main-text">App:</span>
                  <span className="sub-text">{application.name}</span>
                </div>
                    </div>
              </div>
              <div className="float-icon"><i className="ri-arrow-right-s-line"></i></div>
              <div className="card-service">
                  <div className="card-head-review">
                  <SquareAvatar name={props.service_name} />
                    <div className="m-rl-15">
                      <div >
                      <span className="main-text">Service:</span>
                      <span className="sub-text">{props.service_name}</span>
                      </div>
                      <div>
                      <span className="main-text">Build-type</span>
                        <span className="sub-text">{props.build_strategy}</span>
                      </div>
                    </div>
                  </div>
                    <div className="card-box">
                    <p className="secondary-heading">Environments</p>
                    <p className="secondary-heading">Type</p>
                    <p className="secondary-heading"> User Access</p>
                      <div className="review-env-list">
                        <p className="chip-default chip-grey-rounded">{props.env_name}</p>
                      </div>
                      <div>
                        
                        <p className="main-text">Dev</p>
                      </div>
                      
                      <div>
                        <p className="main-text">Dev</p>
                      </div>
                      
                    </div>
                    <div>
                      <a href={"/application/"+application_id+"/service/"+service_id+"/env/add"} className="transparent-btn-teal"> <i className="ri-add-line"></i> Add Environment</a>
                    </div>
                    <div  className="card-service-footer">
                    <a href={"/application/"+application_id+"/service/add"} className="btn btn-submit"> Create new service  <i class="ri-arrow-right-s-line" style={{fontSize:'18px!important'}} /></a>
                    </div>
                </div>
                </div>

              </Grid>
          </Grid>
        </Grid>
    </Grid> 
  )
}

Review.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default Review;