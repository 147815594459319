import React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';

export default function PodsConditions(props){
   const conditions = props.conditions
    return(
        <div>
        <div className="pd-10">
            <p className="font-15 ">
                Pods Conditions
            </p>
            <p className="font-11 text-grey-b6">
            Displaying list of all the conditions related to new deployent
            </p>
        </div>
        <table className="table table-responsive pd-10">
            <tr >
                <th className="text-grey-83">Type</th>
                <th className="text-grey-83">Status</th>
                <th className="text-grey-83">Last Transition</th>
            </tr>
            {
                conditions ? 
                conditions.map(condition=>(
            <tr>
                <td>{condition.type}</td>
                <td>{condition.status == "True"? 
                <div className="d-inline-flex align-center" style={{gap:'3px'}}><i className="ri-checkbox-circle-fill color-success v-align-sub" fontSize="default" style={{ fontSize: '15px'}} /> {condition.status}</div>:
                <div className="d-inline-flex align-center" style={{gap:'3px'}}> <span className="ri-close-circle-fill color-danger v-align-sub" style={{ fontSize: '15px'}}></span>{condition.status}</div>
                }
                    
                </td>
                <td>{moment(condition.lastTransitionTime).fromNow()}</td>
            </tr>
                ))
                :
                <tr>
                   
                    <td colSpan="3">
                    <div className="text-center" style={{height: 200, display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                    <p className="font-13 text-grey-85">No Data Available</p>
                </div>
                    </td>

                </tr>
            }
            

        </table>
        </div>
    )
}

PodsConditions.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }