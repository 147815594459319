import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { display, fontSize, fontWeight, lineHeight, styled, textAlign, textTransform } from "@mui/system";
import { color } from "highcharts";
import { MenuItem, Popover } from "@mui/material";

let count = 3;
const PipelineCardServiceListing = ({ isExpandableView, list, isTag }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [firstList, setFirstList] = useState([])
    const [expandableList, setExpandableList] = useState([])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const firstList = list.slice(0, count); // First `count` items
        const secondList = list.slice(count);  // Remaining items
        setFirstList(firstList);
        setExpandableList(secondList);
    }, [list])


    return (
        <div style={{ gap: '8px', marginBottom: '10px', justifyContent: 'flex-end' }} className="d-flex align-center">
            {
                firstList?.map(service => {
                    return (
                        <>
                            <Card partOfExecution={service.part_of_execution}>
                                {
                                    isTag ? <div className="title">
                                        {service}
                                    </div> :
                                        <div className="title">
                                            {service.name}
                                        </div>
                                }

                            </Card>


                        </>

                    )
                })
            }
            {
                expandableList?.length > 0 &&
                <>
                    <ExtraCard style={{ cursor: 'pointer' }} onClick={handleClick}>
                        <div className="title">
                            +{expandableList.length}
                        </div>
                    </ExtraCard>
                    <Popover
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        PaperProps={{
                            style: {
                                maxHeight: 300,
                                overflowY: 'scroll', // Ensures vertical scroll is active
                                overflowX: 'hidden',
                                scrollbarWidth: 'thin', // For Firefox
                                msOverflowStyle: 'auto', // For IE and Edge
                            },
                        }}
                    >
                        {expandableList.map((service, index) => (

                            <MenuItem key={index} onClick={handleClose}>
                                <Card partOfExecution={service.part_of_execution}>

                                    {
                                        isTag ? <div className="title">
                                            {service}
                                        </div> :
                                            <div className="title">
                                                {service.name}
                                            </div>
                                    }
                                </Card>

                            </MenuItem>
                        ))}
                    </Popover>
                </>
            }


        </div>
    )
}

export default PipelineCardServiceListing;

PipelineCardServiceListing.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

const Card = styled("div")(({ partOfExecution }) => ({

    // height: '27px',
    borderRadius: '36px',
    padding: '7px 9px',
    background: partOfExecution ? "#F5FAFF" : '#F4F4F4',
    border: partOfExecution ? '1px solid #DFEDFF' : '1px solid #F4F4F4',
    minWidth: '60px',
    '& .title': {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '14.63px',
        textAlign: 'center',
        color: partOfExecution ? '#0086FF' : '#505050',
        textTransform: 'uppercase'
    }
}))

const ExtraCard = styled("div")(({ partOfExecution }) => ({

    // height: '27px',
    borderRadius: '36px',
    padding: '7px 9px',
    background: partOfExecution ? "#F5FAFF" : '#F4F4F4',
    border: partOfExecution ? '1px solid #DFEDFF' : '1px solid #F4F4F4',
    '& .title': {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '14.63px',
        textAlign: 'center',
        color: partOfExecution ? '#0086FF' : '#505050',
        textTransform: 'uppercase'
    }
}))





