import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import { Alert } from '@mui/material';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import { GenericValidator, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { LoadingText } from '../../../utils/Loading';
import { Input } from '../../../../components/genericComponents/Input';
import LatestPromoteStrip from '../../listing/components/LatestPromoteStrip';
import Popover from '@mui/material/Popover';
import useFetchPermissions from '../../../../components/customHooks/useFetchPermissions';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';
const PromoteActionCard = (props) => {
    const type = "PROMOTE";
    const service_id = props.service_id;
    const env_id = props.env_id;
    const service_data = props.service_data;
    const top_summary_data = props.top_summary_data;
    const is_env_down = props.is_env_down ? props.is_env_down : null
    const deploy_env_offline_flag = props.deploy_env_offline_flag ? props.deploy_env_offline_flag : null;
    console.log(deploy_env_offline_flag, "deploy_env_offline_flag")
    const env = service_data.env;
    const available_settings = props.available_settings ? props.available_settings : []
    const configuration_data = props.configuration_data;
    const refresh = props.refresh;
    const extraProps = props.extraProps ? props.extraProps : {};
    const ci_data = props.ci_data ? props.ci_data : null;
    var cd_data = props.cd_data ? props.cd_data : [];
    var cd_approved_data = {};
    console.log('in_p_m_d', props.all_available_deployments);
    const all_available_deployments = props.all_available_deployments && props.all_available_deployments.length > 0 ?
        props.all_available_deployments.map(item => {
            return { label: item.deployment_name ? item.deployment_name : item.label, id: item.id }
        }) : []

    if (cd_data) {
        cd_data.map(item => item.status == "APPROVED" ? cd_approved_data = item : null)
    }
    var check_if_template_selected = cd_approved_data && cd_approved_data.env_cd_deployment_strategy ? cd_approved_data.env_cd_deployment_strategy.release_template_id ? cd_approved_data.env_cd_deployment_strategy.release_template_id : null : null;
    const env_list = [];
    const button_state = props.button_state;
    const [cloneList, setcloneList] = useState([])
    var url = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url, true);
    const is_permited = useFetchPermissions()?.POST.includes(url);
    const promote_action_card = props.promote_action_card;
    const handleCloseDialog = props.handleCloseDialog;
    const [state, setState] = useState({
        tag: "",
        event_source: null,
        data: {
            deployment_type: 1,
        },
        deploy_tag: "",
        tag_loaded_status: false,
        errors: {},
        refresh_count: 1,
        open: false,
        visible: true,
        visibleStrip: true,
        system_tags: true,
    });
    var flag_for_feature_tag = properties.flag_for_feature_id
    // Logic to form env->subenv list
    if (env) {
        env.forEach(element => {
            if (element.id != env_id) {
                env_list.push({
                    id: element.id,
                    label: element.env_master.name + "->" + element.env_name
                });
            }
        });
    }
    function getEnvNamefromId() {
        var env_data = {};
        if (service_data && service_data.component_env) {
            service_data.component_env.map(item => {
                if (item.id == env_id) {
                    env_data.env_name = item.project_env.name
                    env_data.env_type = service_data.type
                }
            })
        }
        return env_data;
    }
    useEffect(() => {

        if (type == "PROMOTE" && is_permited) {
            console.log(all_available_deployments)
            const placeholders = {
                service_id: service_id,
                env_id: env_id,
                cd_id: all_available_deployments[0] && all_available_deployments[0].id ? all_available_deployments[0].id : null
            }
            if (placeholders.cd_id != null || true) {
                var requestInfo = {
                    endPoint: GenerateURL(placeholders, properties.api.env_build_tags_list_url + `?system_tags=${state.system_tags}`),
                    httpMethod: "GET",
                    httpHeaders: { "Content-Type": "application/json" }
                }
                getCloneEnvList()
                InvokeApi(requestInfo, handleSuccessTagsDataLoad, handleFailedTagsDataLoad);
                setState({
                    ...state,
                    tag_loaded_status: false,
                });
            }

        }
        return (() => {
            state.event_source && state.event_source.close()
        })
    }, [type, env_id,is_permited,state.system_tags]);


    function handleSuccessTagsDataLoad(response) {
        const tags = response.tags;
        var tag_list = [];
        tags.forEach(tag => {
            tag_list.push({
                id: tag,
                label: tag,
            })
        });
        setState({
            ...state,
            tag_loaded_status: true,
            tag_list: tag_list,
        });
    }
    function handleFailedTagsDataLoad(error) {
        console.log(error, 'errot_tags_ll')
        setState({
            ...state,
            tag_loaded_status: false,
            tag_load_error: error
        });
    }

    function successBuildTriggerCallBack(response) {

        setState(prevState => (
            {
                ...prevState,
                trigger: "success",
                promote_env: '',
                promote_tag: "",
                promote_target_tag: "",
                refresh_count: prevState.refresh_count + 1,
            }
        ));
    }

    function failedBuildTriggerCallBack(error) {

        setState({
            ...state,
            trigger: "failed",
            error_msg: error.detail ? error.detail : error
        });
    }

    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }

    function getCloneEnvList() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: props.service_id }, properties.api.get_clone_env_list),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        // setShowLoading(true);
        InvokeApi(requestInfo, onFetchCloneSuccess, onFetchCloneFail)
    }
    function onFetchCloneFail() {
        // setShowLoading(false)
    }
    function onFetchCloneSuccess(response) {
        // setShowLoading(false)
        var currentEnvironment = null
        var promote_possible_env_list = JSON.parse(localStorage.getItem("allow_promote_data"));
        console.log(promote_possible_env_list, 'promote_possible_env_list')
        promote_possible_env_list = Object.keys(promote_possible_env_list).length > 0 ? promote_possible_env_list : null;
        if (promote_possible_env_list) {
            response.forEach((env, key) => {
                if (env.id == env_id) {
                    currentEnvironment = env
                }
            })
            var possible_promote_list = promote_possible_env_list[currentEnvironment.env_master_name]

            var list = [];
            response.forEach((env, key) => {
                if (possible_promote_list.includes(env.env_master_name)) {
                    list.push({ id: env.id, label: env.project_env.name })
                }
            })
        } else {
            var list = [];
            response.forEach(env => {
                if (env.env_cd_detail && env.id != env_id) {
                    list = [...list, {
                        id: env.id,
                        label: env.project_env.name
                    }]
                }
            })
        }


        if (list.length > 0) {
            setcloneList(list)
            //  setIsCloneVisible(true);
        }
        else {
            //  setRedirect(true);
        }
    }

    console.log(state, ' ')

    function validateAndTrigger(type) {
        var promote_env_error = GenericValidator(state.promote_env, [VALIDATION_TYPE_REQUIRED]);
        var promote_tag_errors = GenericValidator(state.promote_tag, [VALIDATION_TYPE_REQUIRED]);
        // var promote_tag_target_errors = GenericValidator(state.promote_target_tag, [VALIDATION_TYPE_REQUIRED]);
        if (promote_env_error || (promote_tag_errors)) {
            setState({
                ...state,
                errors: {
                    ...state.errors,
                    promote_env: promote_env_error,
                    promote_tag: promote_tag_errors,
                    // promote_target_tag: promote_tag_target_errors
                }
            });

        } else {
            var data = {
                component_env_id: state.promote_env,
                current_image: { tag: state.promote_tag },
            }
            if (state.promote_target_tag) {
                data.target_image = { tag: state.promote_target_tag }
            } else {
                delete data.target_image
            }
            var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_promote_request_url);
            PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack, false, true);
            setState((prevState) => ({
                ...prevState,
                trigger: "running",
            }))
        }

    }

    const onChangeHandler = (e) => {

        if (!(e.target.name == "tag" && e.target.value.includes(" "))) {
            setState({
                ...state,
                [e.target.name]: e.target.value,
                errors: {
                    ...state.errors,
                    [e.target.name]: "",
                }
            });
        }
    }


    function refreshGrid() {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,

        }));

    }

    function toggleState() {
        setState({
            ...state,
            open: !state.open,
        });
    }


    const onKeyValueChangeHandler = (k, v) => {

        setState({
            ...state, [k]: v,
            errors: { ...state.errors, [k]: "" }
        });
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickClosePopover = () => {
        setAnchorEl(null);
    };

    function onChangeHandlerForTagsType(e) {
        setState((new_state) => ({
            ...new_state,
            system_tags: !new_state.system_tags,
        }));
    }

    const OpenPopover = Boolean(anchorEl);
    const id = OpenPopover ? 'simple-popover' : undefined;
    return (
        <>
        <Dialog
            open={promote_action_card}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            className="integrations-dialog action_card_dialog_width"
            aria-describedby="alert-dialog-description"
        >
            {
                is_permited ?

                    <>


                        <div className='card' style={{ borderRadius: 0, border: 'none' }}>
                            <div className='card-header' style={{ backgroundColor: '#124d9b' }}>
                                <div className='d-flex align-center space-between' style={{ width: '100%' }}>
                                    <div className=' d-flex align-center'>
                                        {/* <div className="build-round-icon">
                                            <span className='flaticon-flash-on-indicator'></span>
                                        </div> */}
                                        <div className="icon-promote-card">
                                            <span className="flaticon-forward-arrow" style={{ color: '#fff' }}></span>
                                        </div>
                                        <span className='font-14 ml-5 d-inline-block' style={{ textTransform: 'capitalize', color: '#fff' }}>
                                            Trigger a new Promote
                                        </span>
                                    </div>
                                    <div className='d-flex align-center'>

                                        <button className="btn btn-transparent" onClick={props.closebox} >
                                            <i className="ri-close-line" style={{ color: '#fff' }} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex-top card-sub-header border-bottom' style={{padding:'0px 16px'}}>
                                <p className="font-12 ">
                                    <span> Please note You are Promoting image from Env. &nbsp;</span>
                                    <span className="chip-sq dev-chip" style={{ textTransform: "uppercase" }}>
                                        {top_summary_data ?
                                            top_summary_data.env_name :
                                            getEnvNamefromId().env_type ? getEnvNamefromId().env_type : null}
                                    </span>
                                    <i className='ri-arrow-right-long-line font-24' style={{ verticalAlign: 'middle' }}></i>
                                    
                                    <span style={{ verticalAlign: 'middle' }}>Sub Env: <span className='text-grey-83'>
                                        {top_summary_data ?
                                            top_summary_data.env_name :
                                            getEnvNamefromId().env_name ? getEnvNamefromId().env_name : null}</span>  </span>
                                </p>
                                <Input
                                    type="switch"
                                    padding="0px"
                                    name="system_tags"
                                    label="Fetch tags from BP"
                                    data={state}
                                    onChangeHandler={onChangeHandlerForTagsType}
                                    error={{}}
                                    info="If the Fetch tags from DB option is enabled tags are pulled from the BuildPiper database for all successfully pushed artifacts.Else, tags are retrieved from the respective container registry."
                                />
                            </div>
                            <div className='card-body' style={{ paddingBottom: 0 }}>

                                {configuration_data && configuration_data.env_ci_detail ?
                                    <div className="d-grid" style={{ gridTemplateColumns: '1fr', gap: '0px 10px' }}>
                                        {
                                            state.tag_loaded_status ?
                                                <>
                                                    <div className='d-grid  pd-10' style={{ gridTemplateColumns: '1fr 1fr 1fr', gap: '0px 10px' }}>
                                                        <Input
                                                            type="select"
                                                            name="promote_env"
                                                            mandatorySign
                                                            label="Target Env"
                                                            list={cloneList}
                                                            data={{ promote_env: state.promote_env }}
                                                            error={{ promote_env: state.errors.promote_env }}
                                                            onChangeHandler={onChangeHandler}
                                                        />
                                                        <div className='section-input d-block'>
                                                            <Input
                                                                type={state.open ? "text" : "auto-complete-dropdown"}
                                                                name="promote_tag"
                                                                placeholder="Tag"
                                                                mandatorySign
                                                                getOptionLabel={(option) => option.label}
                                                                list={state.tag_list}
                                                                label="Tag"
                                                                data={{ promote_tag: state.promote_tag }}
                                                                error={{ promote_tag: state.errors.promote_tag }}
                                                                onChangeHandler={state.open ? onChangeHandler : onKeyValueChangeHandler}
                                                                extraDiv={<span role='button' tabIndex={0} className="mr-0 cursor-pointer text-anchor-blue pr-0" onKeyDown={() => { }} onClick={toggleState}>{state.open ? "Choose Tag" : "Provide Custom Tag"}</span>}
                                                            />
                                                        </div>

                                                        {/* <Input
                                                                    type="select"
                                                                    name="promote_tag"
                                                                    label="Tag"
                                                                    mandatorySign
                                                                    list={state.tag_list}
                                                                    data={{ promote_tag: state.promote_tag }}
                                                                    error={{ promote_tag: state.errors.promote_tag }}
                                                                    onChangeHandler={onChangeHandler}
                                                                /> */}

                                                        <Input
                                                            type="text"
                                                            name="promote_target_tag"
                                                            placeholder="custom-tag"
                                                            label="New Tag"
                                                            data={{ promote_target_tag: state.promote_target_tag }}
                                                            error={{ promote_target_tag: state.errors.promote_target_tag }}
                                                            onChangeHandler={onChangeHandler}
                                                        />

                                                    </div>
                                                    <div className='d-flex align-center space-between input-switch-f8'
                                                        style={{
                                                            backgroundColor: '#fefefe',
                                                            padding: '10px',
                                                            borderTop: '1px solid #efefef'
                                                        }}
                                                    >
                                                        <div></div>
                                                        <div className="section-3 d-flex align-center" style={{ marginTop: 0,gap:'5px' }}>

                                                            <button className="btn btn-outline-grey" style={{ padding: '1rem', minWidth: "108px" }} onClick={props.closebox} >Cancel</button>
                                                            {state.trigger !== "running" ?
                                                                <button className="btn btn-primary-v2 d-flex align-center" style={{ padding: '1rem 1rem' }} onClick={() => { validateAndTrigger(type) }} >Trigger Promote &nbsp;<i className="ri-play-circle-line font-27" /> </button>
                                                                :
                                                                <GenericSkeleton width={177} height={39} style={{ borderRadius: '6px' }} />
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {state.tag_load_error ?
                                                        <p className="error-msg-service-card">Error Loading tag</p> :

                                                        <> {
                                                            all_available_deployments?.length == 0 ?
                                                                <p className="font-12 text-center mb-12 mt-12">Deploy Details is not configured, tags are not available</p> :
                                                                <LoadingText />

                                                        }</>
                                                    }
                                                </>
                                        }
                                    </div>
                                    : <p className="font-12 text-center mb-12 mt-12">Please configure build details</p>}

                            </div>
                        </div>
                        <div className="popup-card mb-15">

                            <LatestPromoteStrip
                                top_summary_data={top_summary_data}
                                is_env_down={top_summary_data && top_summary_data.is_env_down ? top_summary_data.is_env_down : is_env_down ? is_env_down : null}
                                type={type}
                                recent_history={configuration_data}
                                service_id={service_id}
                                service_data={service_data}
                                build_status={state.build_status}
                                refresh={refreshGrid}
                                closebox={props.closebox}
                                refresh_count={state.refresh_count}
                                env_id={env_id} />
                        </div>
                    </>
                    :
                    <div className="popup-card">
                        <div className="popup-card-body">
                            <h3 className="popup-card-main-heading pd-10">
                                You are not allowed to perform this action, Contact Project Admin
                            </h3>
                        </div>
                    </div>
            }
            
            <Popover
                id={id}
                open={OpenPopover}
                anchorEl={anchorEl}
                onClose={handleClickClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="popover-content" style={{ padding: '20px', width: '400px', }}>

                    <div className='' style={{ width: '100%', position: 'relative' }}>
                        <button className='btn btn-transparent' style={{ position: "absolute", right: '10px', top: '0px' }} onClick={handleClickClosePopover}><i className='ri-close-line font-18' style={{ color: '#0086ff', }} /></button>
                        <i style={{ color: '#0086ff', width: '100%' }} className='ri-information-line font-48 mb-10 text-center' />
                        <p className='font-18 font-weight-bold text-center mb-5' style={{ color: '#0086ff', width: '100%' }}>ENVIRONMENT IS OFFLINE</p>
                        <p className='font-12 font-weight-400 text-center' style={{ color: '#787878', width: '100%' }}>Please note you cannot trigger promote as the envrionment is offline now.
                            Please contact your team administrator to get the environment online.</p>
                    </div>

                </div>
            </Popover>
        </Dialog>
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={state.trigger === "success" || state.trigger === "failed"}
            onClose={handleClose}
            className="z-index-top"
            autoHideDuration={3000}
            sx={{
                zIndex: 1400,
                '& .MuiPaper-root': {
                    backgroundColor: (theme) =>
                        state.trigger === "success"
                            ? theme.palette.success.main
                            : state.trigger === "failed"
                            ? theme.palette.error.main
                            : theme.palette.background.default,
                    width: 'auto !important',
                    boxShadow: 3,
                    borderRadius: 1,
                },
            }}
            >

            {
                state.trigger === "success" ?

                    <Alert severity="success" variant='filled' >
                        {"SUCCESSFULLY SCHEDULED " + type}
                    </Alert>
                    : state.trigger == "failed" ?
                        <Alert severity="error" variant='filled'>
                            {type + " SCHEDULE FAILED"}
                            <div className="error-div">
                                {state.error_msg}
                            </div>
                        </Alert>

                        : null
            }
        </Snackbar>
        </>

    )
}

PromoteActionCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export default PromoteActionCard;
