import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../components/genericComponents/Input';
import { makeStyles } from '@mui/styles';
import { Loading } from '../../../utils/Loading';
import AlertStrip from '../../../../components/AlertStrips';
import { getCommonFunctions } from '../ci_flow/SourceDetails';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
const Priorities = props => {
  const prev_state = props.prev_state ? props.prev_state : null;
  const component_env_id = props.component_env_id ? props.component_env_id : null;
  const service_id = props.service_id ? props.service_id : null
  const [state, setState] = useState(prev_state ? prev_state : prioritiesDefaultState());
  const inherits = props.inherits ? props.inherits : {};
  const commonFunction = getCommonFunctions(state, setState, inherits);
  const classes = useStyles();
 
  useEffect(() => {
    if(component_env_id && service_id){
      getPriorityList()
    }
   
 }, [])
 function getPriorityList() {

  var requestInfo = {
    endPoint: GenerateURL({ component_id: service_id, env_id: component_env_id }, properties.api.priority_class),
    httpMethod: "GET",
    httpHeaders: { "Content-Type": "application/json" }
  }
  // requestInfo.endPoint = requestInfo.endPoint + "?all=true";
  InvokeApi(requestInfo, fetchSuccessPVCSListing, fetchFailpvcsListing);
  setState(new_state => ({
    ...new_state,
    loading: true
  }))
}

function fetchSuccessPVCSListing(response) {
  console.log(response,"fdsaklmkflsakl")
  if(response.length > 0){
    var priority_class_list = response.map(element => {
      return {label:element,id:element}
    });
    setState(new_state => ({
      ...new_state,
      priority_class_list: priority_class_list,
      loading: false
    }))
  }

}
function fetchFailpvcsListing(error) {
  setState(new_state => ({
    ...new_state,
    loading: false
  }))
}
  return (
    <div className={classes.root}>
      <div className="custom-card-header">
       
          <div className="pd-10">
            <div className="">
              {
                state.priority_class_list ?
                  state.priority_class_list.length == 0 ?
                    <AlertStrip
                      variant="info"
                      dismissible={false}
                      message={"Priority List Not Available"}
                      extraClasses="mt-5"
                    />
                    :
                    <>
                      <div className={"input-component-mb-0"} >
                        <Input
                          type="select"
                          name="priority"
                          list={state.priority_class_list}
                          data={state.data}
                          error={state.error}
                          onChangeHandler={commonFunction.onChangeHandler}
                        />
                      </div>
                    </>
                  :
                  state.error ?
                    <AlertStrip
                      variant="info"
                      dismissible={false}
                      message={"Sorry This functionality is not available at the moment."}
                      extraClasses="mt-5"
                    />
                    :
                    <Loading varient="light" />
              }
            </div>
          </div>
        
      </div>
    </div>
  )
}

Priorities.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default Priorities;

export function prioritiesDefaultState() {
  return {
    data: {},
    error: {},
    priority_class_list:[]
  }
}


const useStyles = makeStyles(item => ({
  root: {
    border: '0px solid #dedede'
  },
}));