import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { display, fontSize, fontWeight, lineHeight, styled, textAlign, textTransform } from "@mui/system";
import PipelineCardServiceListing from "./PipelineCardServiceListing";
import GenerateURL from "../../../../util/APIUrlProvider";
import properties from "../../../../properties/properties";
import InvokeApi from "../../../../util/apiInvoker";

let triggerNumber = '10';
let successNumber = 10;
let failedNumber = 5;
function bringParticipatinServiceToTop(components) {

    console.log(components, "lfadsfas")
    var participating_services = []
    var non_participating_services = []
    if (components) {
        components.forEach(component => {
            if (component.part_of_execution) {
                participating_services.push(component)
            } else {
                non_participating_services.push(component)
            }
        })
    }
    participating_services = [...participating_services, ...non_participating_services]

    return participating_services
}
const PipelineCardService = ({ isExpandableView, variant,pipeline, ...props }) => {

    const [state, setState] = useState({ last_execution_data: {} });

    useEffect(() => {
        variant != 'pipeline-history' && requestPipelineAllExeccutions(pipeline.id)
    }, [pipeline])


    function requestPipelineAllExeccutions(pipelineId) {
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipelineId }, properties.api.trigger_pipeline),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (response) => {

            console.log(response, 'response_0ppp')
            let results = response?.results
            let fail = 0
            let success = 0
            results?.forEach(execution => {
                if (execution.status == "SUCCESS") {
                    success += 1
                }
                if (execution.status == "FAILED") {
                    fail += 1
                }
            })

            setState(prevState => ({
                ...prevState,
                last_execution_data: {
                    fail: fail,
                    success: success
                }
            }))

        }, (error) => {

        });
    }
    const services = getServiceList(bringParticipatinServiceToTop(props.components));
    console.log(services, "Fdsafsafsa")
    let tags = getTagList(props.tags);

    return <Card>
        <div className="d-flex space-between">
            <div>
                {
                    variant != 'pipeline-history' ? <>
                        <div style={{ gap: '8px', marginBottom: '10px' }} className="d-flex align-center">
                            <TextContainerS>
                                <span style={{ color: "#2EBE79", fontSize: "20px" }} className="ri-checkbox-circle-line">

                                </span>
                                <span className="title">
                                    success: {state?.last_execution_data?.success}
                                </span>
                            </TextContainerS>

                            <TextContainerF>
                                <span style={{ color: "#E53737", fontSize: "20px" }} className="ri-close-circle-line">

                                </span>
                                <span className="title">
                                    fail: {state?.last_execution_data?.fail}
                                </span>
                            </TextContainerF>
                        </div>

                        <div className="info">
                            In Last {triggerNumber} Triggers
                        </div>
                    </> : <>
                    </>
                }

            </div>
            <div className="d-flex">
                {tags.length > 0 ?
                    <div style={{ marginRight: '50px' }}>
                        <div className="service-name-chip-div">
                            <PipelineCardServiceListing isTag={true} list={tags} />
                        </div>
                        <div className="info">Tags</div>
                    </div>
                    : null}
                <div>
                    <PipelineCardServiceListing list={services} />
                    <div style={{ textAlign: 'left' }} className="info">
                        Services for the pipeline to run on
                    </div>
                </div>
            </div>


        </div>
    </Card>
}

export default PipelineCardService;

PipelineCardService.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

const Card = styled("div")({
    padding: '13px 20px 13px 20px',
    '& .info': {
        color: '#505050',
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '14.63px',
    }
});

const TextContainerS = styled("div")({
    width: "120px",
    background: "#FFFFFF",
    padding: '4px 8px',
    borderRadius: '20px',
    border: '1px solid #E6E6E6',
    display: 'flex',
    alignItems: 'center',
    height: "35px",
    gap: '6px',
    '& .title': {
        fontSize: '12px',
        fontWeight: '700',
        lineHeight: '14.63px',
        color: '#626262',
        textTransform: 'uppercase',
        textAlign: 'center'
    }

})
const TextContainerF = styled("div")({
    width: '90px',
    background: "#FFFFFF",
    padding: '4px 8px',
    borderRadius: '20px',
    border: '1px solid #E6E6E6',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    height: "35px",

    '& .title': {
        fontSize: '12px',
        fontWeight: '700',
        lineHeight: '14.63px',
        color: '#626262',
        textTransform: 'uppercase',
        textAlign: 'center'
    }

})



function getServiceList(services) {
    console.log(services, "Fasfdas")
    var service_list = [];
    services.forEach(service => {
        service_list.push({ name: service.name, part_of_execution: service.part_of_execution });
    });
    return service_list;
}

function getTagList(tags) {
    var tag_list = [];
    if (tags) {
        tags.forEach(tag => {
            tag_list.push(tag.tag);
        });
    }
    return tag_list;
}




