import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useParams, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import GenerateURL, { GenerateSearchURL } from '../../../../../util/APIUrlProvider';
import properties from '../../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../../util/apiInvoker';
import { Loading } from '../../../../utils/Loading';
import { ErrorComponent } from '../../../../utils/Error';
import SearchBar from '../../../../../components/SearchBar';
import { getCommonFunctions } from '../../../../serviceRevamp/add/ci_flow/SourceDetails';
import JobTemplateDetailCard from './JobTemplateDetailCard';


const AddJobTemplateToServices = ({ setComponentView, component_id, selectedSubEnv }) => {

    const [filter, setFilter] = useState({ name: '' })
    const [state, setState] = useState({
        data_loading: 'SUCCESS',
        job_template_listing: [],
    });

    const [selectedJobTemplateId, setSelectedJobTemplateId] = useState()

    const { application_id } = useParams();

    useEffect(() => {
        fetchJobTemplatesInfo();
    }, [application_id, filter]);


    const commonFunctions = getCommonFunctions(state, setState, {})

    function fetchJobTemplatesInfo() {

        let myEndPoint = GenerateURL({ application_id: application_id }, properties.api.add_job_template)

        var requestInfo = {
            endPoint: GenerateSearchURL(filter, myEndPoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, jobTemplatesInfoFetchSuccess, jobTemplatesInfoFetchFailure);

        setState(new_state => ({
            ...new_state,
            data_loading: "LOADING",

        }));
    }

    function jobTemplatesInfoFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            data_loading: "SUCCESS",
            job_template_listing: data.results
        }));
    }

    function jobTemplatesInfoFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            data_loading: "FAILED",
            error: error,
        }));
    }

    const handleSearchChange = (filter_name) => {
        setFilter(filter_name);
        setState(new_state => ({
            ...new_state,
            search_query_name: filter_name ? filter_name.name ? filter_name.name : "" : "",
        }))
    }

    console.log(state, 'jt_f_ms')


    const onClose = () => {
        setComponentView('jobListing')
    }

    const handleAddTemplate = () => {

        console.log(selectedJobTemplateId, '0__sjt')
        console.log(component_id, '0__c_id')
        console.log(selectedSubEnv, '0__s_ev')
        if (selectedJobTemplateId != null && component_id != null && selectedSubEnv != null) {

            //create
            let post_url = GenerateURL({ component_id: component_id, sub_env_id: selectedSubEnv }, properties.api.jobTemplate_to_service)
            let data = {
                component_env_id: selectedSubEnv,
                project_job_template_id: selectedJobTemplateId
            }

            PostData(post_url, data, onClose, jobTemplatesInfoFetchFailure);
        }

    }

    const handleJobTemplateSelect = (job_template_id) => {

        setSelectedJobTemplateId(job_template_id)
    }

    console.log(filter, 'filter_001')

    return (
        <div className="mb-15 mt-20">
            <div className="mb-20">
                <div style={{ display: 'flex' }} className="align-center space-between">
                    <div>
                        <p className="font-14 font-weight-300 lh-12">
                            Add Job Templates
                        </p>
                        <p className="font-11 text-grey-6e">
                            Displaying list of available job templates
                        </p>
                    </div>
                    <button className="btn btn-transparent" onClick={onClose}>
                        <span className='ri-close-line ' style={{ fontSize: '20px', color: "#124d9b" }}  ></span>
                     </button>
                </div>
            </div>

            <div>
                <div
                    style={{
                        padding: '20px',
                        backgroundColor: '#f8f8f8',
                        marginBottom: '0px',
                        border: '1px solid #dddddd',
                        borderTopLeftRadius: '6px',
                        borderTopRightRadius: '6px',
                        minHeight: '443px'
                    }}>
                    <p className='font-weight-300' style={{ fontSize: '16px' }}>Select a Template you want to add for the Microservice</p>
                    <span style={{ color: '#000000e0' }} className="font-12">Please Note : </span>
                    <span style={{ color: 'gray' }} className='font-13'>In order to Build and Deploy your microservice add job templates.</span>


                    {
                        state.data_loading == "SUCCESS" ?
                            <>
                                <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                                    <SearchBar
                                        search_data={filter}
                                        default_filter={{ name: "name", label: "Search for job template" }}
                                        search_call_back={handleSearchChange}
                                        clear_search_callback={() => setFilter({ name: '' })}
                                    />
                                </div>
                                
                                {state.job_template_listing && state.job_template_listing.length > 0 ?
                                    <Grid container spacing="2">
                                        {
                                            state.job_template_listing.map(item => (
                                                <Grid item lg={4}>
                                                    <JobTemplateDetailCard onSelect={handleJobTemplateSelect} data={item} selectedJobTemplateId={selectedJobTemplateId} />
                                                </Grid>
                                            ))
                                        }

                                    </Grid>

                                    :
                                    state.search_query_name ?
                                        <div className="main-container-error" style={{ height: '60vh', margin: 'auto' }}>
                                            <div className="svg">
                                                <div className="servicePanel">
                                                    <div className="blank-div">
                                                        <div className="blank-div-text">
                                                            No Templates found with this name : {state.search_query_name}
                                                        </div>
                                                        <div>
                                                            <button onClick={() => setFilter({ name: '' })} className="btn btn-submit">
                                                                Refresh
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="main-container-error" style={{ height: '60vh', margin: 'auto' }}>
                                            <div className="svg">
                                                <div className="servicePanel">
                                                    <div className="blank-div">
                                                        <div className="blank-div-text">
                                                            No Templates associated with this Application
                                                        </div>
                                                        <div>
                                                            <Link to={"/application/" + application_id + "/job-template/add"} className="btn btn-submit">
                                                                <div >Add Job Template</div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </>

                            : state.data_loading == "FAILED" ? <ErrorComponent variant="error-box" error={state.error} /> : <Loading />
                    }
                </div>
                <div style={{
                    height: '90px',
                    border: '1px solid #dddddd',
                    borderBottomLeftRadius: '6px',
                    borderBottomRightRadius: '6px',
                    backgroundColor: '#fbfbfb', display: 'flex',
                    borderTop: 'none',
                    alignItems: 'center', justifyContent: 'flex-end',
                    paddingRight: '20px',
                }}>
                    <button onClick={onClose} style={{ width: '90px', marginRight: '10px' }} className="btn btn-outline-grey">Cancel</button>
                    <button style={{ widht: '90px' }} onClick={handleAddTemplate} className='btn btn-submit'>
                        Add Templates
                    </button>
                </div>
            </div>
        </div>


    )

}


AddJobTemplateToServices.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default AddJobTemplateToServices;