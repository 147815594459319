import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import properties from '../../../../../properties/properties';
import InvokeApi from '../../../../../util/apiInvoker';
import GenerateURL, { GET_Request_Info } from '../../../../../util/APIUrlProvider';
import { Loading } from '../../../../utils/Loading';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Delete from '../../../../../components/genericComponents/Delete';
import IsitoAddonsData from './IsitoAddonsData';

function KedaSection(props) {
  const cluster_id = props.cluster_id;
  const [state, setState] = useState({
    loaded: false,
    openDetailView: false,
  });

  useEffect(() => {
    fetchIstioInfo();
  }, []);

  function fetchIstioInfo() {
    const request_info = GET_Request_Info(GenerateURL({ cluster_id: cluster_id, }, properties.api.istio_save));
    InvokeApi(request_info, onIstioDataFetchSuccess, onIstioDataFetchFail);
  }

  function onIstioDataFetchSuccess(data) {
    setState(new_state => ({
      ...new_state,
      data: data.results,
      openDetailView: data?.length > 0 ? true : false,
      loaded: true,
    }));
  }


  function onIstioDataFetchFail(error) {
    setState(new_state => ({
      ...new_state,
      loaded: true,
      error: error,
    }));
  }
  const toggleDetailView = () => {
    setState(prevState => ({
      ...prevState,
      openDetailView: !prevState.openDetailView
    }))
  }

  console.log("hello-1", state)
  return (
    <>
      <div className="header-part" style={state.openDetailView ? { borderBottom: '1px solid #dedede', justifyContent: 'space-between', padding: '0px 5px 0px 0px' }: {borderBottom:'none'}}>
        <div className="heading-area setup-summary d-flex align-center space-between">
          <div>
            <p className="font-14 font-weight-500 color-primary">Keda</p>
            <p className="font-12 font-weight-500 color-secondary">Manage external access to the services in your cluster</p>
          </div>
          <div className="right-content" >
          <button className="btn btn-transparent">
              {
                state.openDetailView ?
                <span className='ri-arrow-up-s-line font-24 color-tertiary' style={{fontWeight: "500"}} onClick={toggleDetailView}></span>
                :
                <span className='ri-arrow-down-s-line font-24 color-tertiary' style={{fontWeight: "500"}} onClick={toggleDetailView}></span>
              }
            </button>
          </div>
        </div>
        {/* <div className="sub-header-setup-summary" style={{borderTop:'1px solid #dedede', borderBottom:'0px'}}>
Istio Operators
        </div> */}

      </div>
      {state.openDetailView &&
        <div className="d-flex align-center loading-10px" style={state && state.data ? state.data[0] && state.data[0].name ?  { display: 'grid', gridTemplateColumns: ' 33.3% 33.3% 1fr' } : {display: 'grid'} : {display: 'grid'} }>
          {
            !state.loaded ?
              <Loading varient="light" />
              :
              <>
                {
                  state.data.length > 0 ?
                    <>
                      <div className="sub-box-one-summary border-right" style={{ width: '100%' }}>
                        <div className="sub-header-setup-summary">
                          <p>{state.data[0].name}</p>
                          <div>
                            <Link to={"/cluster/" + cluster_id + "/istio/" + (state.data[0] ? state.data[0].id : 1) + "/edit"}><button className="btn btn-transparent"><i className="ri-edit-line" style={{ fontSize: '18px', color: '#0086ff' }} /></button></Link>
                            <Delete
                              display_data_name={state.data[0].name}
                              data={{ entity_id: state.data[0] ? state.data[0].id : 1, name: "istio" }}
                              refresh={fetchIstioInfo}
                              api_link={GenerateURL({ cluster_id: cluster_id, istio_id: state.data[0] ? state.data[0].id : 1 }, properties.api.get_istio)}
                            />
                          </div>
                        </div>
                        <TempBodyRowTempTwo>
                          <div className="icon-box">
                            <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#ebf2f4' }}>
                              <img src="/images/resources_logos/istio_logo.png" alt=".." />
                            </div>
                          </div>
                          <div>
                            <div className="d-flex">
                              <span className="text-black">Status:&nbsp;</span>
                              {
                                state.data[0].status == "READY" ?
                                  <div ><i className="ri-checkbox-circle-fill color-success v-align-sub" fontSize="default" /> <span className="text-gray"> Ready</span> </div>
                                  :
                                  <div className=""> <span className="ri-close-line color-danger v-align-sub" style={{ fontSize: '24px' }}></span><span className="text-gray"> Not Ready</span></div>
                              } </div>
                            <div className="d-flex m-tb-3"><span className="text-black">Namespace:&nbsp;</span>
                              <>
                                <><span className="text-gray">{state.data[0].namespace.name}</span></>

                                {/* <><span className="text-gray">Not Ready</span><i className='ri-close-line' style={{ fontSize: '18px', color: '#ff8969' }} /></> */}
                              </>
                            </div>
                            <div className="d-flex ">
                              <span className="text-black">Pilot HPA Resource:&nbsp;</span>
                              {state.data[0].istio_pilot_hpa ? <span className="text-grey-83">Enabled</span> : <span className="text-grey-83">Disabled</span>}
                            </div>
                            <div className="d-flex m-tb-3">
                              <span className="text-black">Proxy Resource:&nbsp;</span>
                              {state.data[0].istio_proxy_resource ? <span className="text-grey-83">Enabled</span> : <span className="text-grey-83">Disabled</span>}
                            </div>
                            <div className="d-flex ">
                              <span className="text-black">Init Resource:&nbsp;</span>
                              {state.data[0].istio_init_resource ? <span className="text-grey-83">Enabled</span> : <span className="text-grey-83">Disabled</span>}
                            </div>
                          </div>
                        </TempBodyRowTempTwo>
                      </div>
                      <IsitoAddonsData cluster_id={cluster_id} istio_id={state.data[0] ? state.data[0].id : null} />
                    </>
                    :
                    <>
                      <div className="sub-box-one-summary" style={{ width: '100%' }}>
                      
                        <TempBodyRowTempTwo>
                          <div className="icon-box">
                            <div className="public-icon-box icon-box-top" style={{ height: '100%', backgroundColor: '#f4f4f4' }}>
                              <img src="/images/resources_logos/keda-icon.svg" alt=".." />
                            </div>
                          </div>
                          <div>
                            <Link to={"/cluster/" + cluster_id + "/keda/add"} >
                            <button className='btn btn-outline-primary d-flex align-center'>
                            <span className='ri-settings-4-line font-20'></span>&nbsp;<span>Configure Keda</span>
                            </button>
                            </Link>
                          </div>
                        </TempBodyRowTempTwo>
                      </div>
                    </>
                }
              </>
          }
        </div>
      }

    </>
  );
}
KedaSection.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default KedaSection;

const ITEM_HEIGHT = 48;
export function MoreOption(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const application_id = props.application_id;
  return (
    <div>
      <button className="btn btn-transparent"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        >
          <i className="ri-more-2-fill"  style={{ color: '#0096db', fontSize: '2rem' }} />
      </button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
          },
        }}
      >
        <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

          <Link to="/namespaces">
            <span className="hover-content-link"><i className='ri-add-line' style={{ color: '#9e9e9e', fontSize: '16px' }} />&nbsp;Add Resource</span>
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
MoreOption.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


const TempBodyRow = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
  gap: '10px',
  padding: '30px 10px',
  borderBottom: '1px solid #dedede',
  alignItems: 'flex-start',
  overflow: 'hidden',
  backgroundColor: '#fcfcfc',
  display: 'grid',
  gridTemplateColumns: '20% 40% 1fr',
  justifyContent: 'space-between',
  '& .d-flex': {
    overflow: 'hidden',
  },
  '&:last-child': {
    border: 'none'
  },
  '& .bullet': {
    position: 'relative',
    top: '3px',
    '&.purple': {
      backgroundColor: '#245dff!important'
    }
  },
  '& .createdat': {
    fontSize: '10px',
    color: '#878787'
  },
  '& .fre-text': {
    fontSize: '11px',
    color: '#000',
    '& .uses-text': {
      fontSize: '9px',
      color: '#878787'
    }
  },
  '& .status-font': {
    color: '#fff',
    width: '40%',
    '& .flaticon-circle-with-check-symbol': {
      '&:before': {
        fontSize: '14px!important'
      }
    }
  },
  '& div': {
    overflow: 'hidden',
    color: '#9e9e9e'
  },
  '& .url': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})

export const TempBodyRowTempTwo = styled('div')({
  lineHeight: '1.5',
  fontSize: '12px',
  gap: '10px',
  padding: '25px 10px',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: '#fff',
  display: 'grid',
  // gridTemplateColumns: '30% 1fr',
  justifyContent: 'center',
  '& .icon-box':{
margin:'auto'
  },
  '& .public-icon-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .text-gray': {
    color: '#9e9e9e'
  }
})