import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Slide from '@mui/material/Slide';
import DialogHeader from './dialogComponents/DialogHeader';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import DeploymentAnalytics from './dialogComponents/DeploymentAnalytics';
import PodsListingWithDetails from './dialogComponents/PodsListingWithDetails';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import CanaryDeploymentView from './dialogComponents/CanaryDeploymentView';
import { Loading } from '../../../utils/Loading';
import Tooltip from '@mui/material/Tooltip';
import { Input } from '../../../../components/genericComponents/Input';
import { useLocation } from 'react-router-dom';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function useQuery() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParams = {};

  // Loop through each search param and add it to the queryParams object
  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
}

export default function MonitoringDialogue(props) {
  const query = useQuery();
  console.log("queryMonitoring",query);
  const service_id = query.service_id //props.service_id
  const env_id = query.env_id;
  const env_type=query.env_type;
  const service_name=query.service_name;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cdId = query.cd_id;
  const cd_id = query.cd_id ? query.cd_id : null;
  //const all_available_deployments = query.all_available_deployments ? JSON.parse(query.all_available_deployments) : [];
  const application_id = query.application_id;
  const env_details = query.env_details ? query.env_details : null
  // const openDialog = props.openDialog;
  // const closeDialog = props.closeDialog;
  // const isOpen = props.isOpen;
  const variant = query.variant ? query.variant : null; 
  const handleClosePopover = query.handleClosePopover;
  const classes = useStyles();
  const [open, setOpen] = useState(query.open ? query.open : false);
  const [redirect, setRedirect] = useState(false);
  const show_deployment_status = query.show_deployment_status;
  let pipeline_id = query.pipeline_id;
  let execution_id = query.execution_id;
  //let service_data = JSON.parse(query.service_data);
  let selected_dep_name = query.selected_dep_name;
  let type = query.type;
  const global_pipeline_flag = query.global_pipeline_flag ? query.global_pipeline_flag : false;
  console.log("parsedQueryString____",selected_dep_name)
  // let param = useParams();
  // pipeline_id = param.pipeline_id;
  // execution_id = param.pipeline_instance_id;
  
  const [state, setState] = useState({
    selectedTab: 1,
    pods_replica_data: {},
    refresh_count: 0,
    data: {
      deployment_name: cd_id,
    },
    error: {}
  })

  useEffect(()=>{
    if(selected_dep_name){

      setState((new_state)=>({
        ...new_state,
        data : {
          ...new_state.data,
          deployment_name : selected_dep_name
        }
      }))
    }
  },[selected_dep_name])

  function widgetApiHit() {
    let requestInfo = {
      endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id }, properties.api.widget_data + "?job_code=deploy_job&widget_code=DEPLOYMENT_ANALYTICS"),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, onWidgetSuccess, onWidgetFail);
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));
  }

  function onWidgetSuccess(response) {
    const deployment_list = response.map(item => { return { label: item, id: item } });
    let does_deployement_name_exist = cd_id ? response.includes(cd_id) : null;
    setState(prevState => ({
      ...prevState,
      loading: false,
      deployments_list: deployment_list,
      data: {
            ...prevState.data,
            deployment_name: does_deployement_name_exist ? cd_id : deployment_list[0].id,
      }
      
    }));
  }

  function onWidgetFail(error) {
    setState(prevState => ({
      ...prevState,
      loading: false,
    }))
  }

  const updateSelectedTab = (selectedTabOrder) => {
    setState(new_state => ({
      ...new_state,
      selectedTab: selectedTabOrder
    }));

  }

  const handleClickOpen = () => {
    refresh();
    setOpen(true);
    fetchRecentBuildDeployData();
  };

  const handleClose = () => {
    console.log('handleClose>>>');
    refresh();
    setOpen(false);
  };

  var header_tab_list = [
    {
      order: 1,
      tabName: 'Deployment Status'
    },
    {
      order: 2,
      tabName: 'Pods Status'
    }
  ]

  function fetchRecentBuildDeployData() {

    var requestInfo = {
      endPoint: GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_ci_cd_data_url),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, onfetchRecentBuildDeployDataSuccess, onfetchRecentBuildDeployDataFail);
    setState(new_state => ({
      ...new_state,
      loading: true
    }));
  }

  function onfetchRecentBuildDeployDataSuccess(response) {
    console.log(response, "checking_response")
    let selectedDeployment = response.last_deploy.find((item)=>item.deploy_args_key_value?.deployment_name == state.data.deployment_name || cd_id);
    setState(new_state => ({
      ...new_state,
      last_deploy: response.last_deploy,
      selected_deployment: selectedDeployment,
      loading: false
    }));

  }

  function onfetchRecentBuildDeployDataFail(response) {
    setState(new_state => ({
      ...new_state,
      error: response,
      loading: false
    }));
  }

  useEffect(() => {
    console.log(env_id, "vaibhav===")
    widgetApiHit();
    fetchRecentBuildDeployData();
    // if (!cd_id)  {
    //   setState(new_state => ({
    //     ...new_state,
    //     data: {
    //       ...new_state.data,
    //       deployment_name: new_state.deployments_list[0].id
    //     }
    //   }));
    // } 
  }, [env_id, state.refresh_count])
  

  const onChangeDropdownHandler = (e) => {
    var key = e.target.name;
    var value = e.target.value;
    let selectedDeployment = state.last_deploy.find((item)=> item.deploy_args_key_value.deployment_name == value);
    console.log("jxsjhbswjkehd",key,value,selectedDeployment)
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value,
      },
      selected_deployment : selectedDeployment,
      error: {
        ...state.error,
        [key]: null,
      },

    });
  }


  useEffect(() => {
    if (show_deployment_status) {
      handleClickOpen()
    }
  }, [show_deployment_status])
  function refresh() {
    setState(new_state => ({
      ...new_state,
      refresh_count: new_state.refresh_count + 1,
    }));
  }



  const handleClickAndRedirect = () => setRedirect(true);
  console.log("sdjsjdhb",state);
  return (
    <>
      {
        variant && variant == "running_strip" ?
          <div style={{ backgroundColor: "#fffae4" }} className="duration-info-div" >
            <img src='/images/neutral_sentiment.png' style={{ marginRight: "10px" }} alt='..' />
            <p>Seems like the Deployment is taking longer than expected...<span onKeyDown={() => { }} onClick={handleClickOpen} tabIndex={0} role='button'>View the possible cause</span></p>
          </div>
          :
          variant && variant == "failed_strip" ?
            <div style={{ backgroundColor: "#fff1f1" }} className="duration-info-div" >
              <img src='/images/sentiment_dissatisfied.png' style={{ marginRight: "10px" }} alt='..' />
              <p>Deployment Failed.<span onKeyDown={() => { }} onClick={handleClickOpen} tabIndex={0} role='button'>&nbsp;View the possible cause</span></p>
            </div>
            :
            variant && variant == "success_running" ?
              <>
              </>
              :
              variant && variant == "running_card_pipeline" ?
                // <PipelineDeployStatus
                //   variant={variant}
                //   redirect={redirect}
                //   handleClickOpen={handleClickOpen}
                //   handleClosePopover={handleClosePopover}
                //   handleClickAndRedirect={handleClickAndRedirect}
                //   application_id={application_id}
                //   service_id={service_id}
                //   pipeline_id={pipeline_id}
                //   execution_id={execution_id}
                //   env_id={env_id}
                //   env_name={props.env_name}
                //   cd_id={cd_id}
                // />
                <div className={classes.header} style={{height: '100px'}} >
                      <div className={classes.movement} style={{ position: 'relative', width: '28%', height: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#FFC30C', alignItems: 'center', marginRight: '10px', borderRadius: '12px' }}>
                          <span className="material-icons material-symbols-outlined" style={{ color: '#ffffff', fontSize: '50px' }}>
                              sentiment_neutral
                          </span>
                      </div>
                      <div className={classes.message}>
                          <p>Seems like the Deployment is taking longer than expected...</p>
                          <Link onClick={handleClickAndRedirect}>View the Possible Cause</Link>
                      </div>
                      <div style={{ width: '10%' }}>
                          <button className="btn btn-transparent"  onClick ={() => { handleClosePopover() }}>
                              <span className='ri-close-line ' style={{ fontSize: 20, color: 'grey' }} ></span>
                          </button>s
                      </div>
                </div>
                :
                variant && variant == "failed_card_pipeline" ?
                  // <PipelineDeployStatus
                  //   variant={variant}
                  //   redirect={redirect}
                  //   handleClickOpen={handleClickOpen}
                  //   handleClosePopover={handleClosePopover}
                  //   handleClickAndRedirect={handleClickAndRedirect}
                  //   application_id={application_id}
                  //   service_id={service_id}
                  //   pipeline_id={pipeline_id}
                  //   execution_id={execution_id}
                  //   env_id={env_id}
                  //   env_name={props.env_name}
                  //   cd_id={cd_id}
                  // />
                  <div className={classes.header} style={{ height: '80px', }}>
                    <div className={classes.movement_one} style={{ position: 'relative', width: '28%', height: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#F64B3C', alignItems: 'center', marginRight: '10px', borderRadius: '12px', }}>
                        <span className="material-icons material-symbols-outlined" style={{ color: '#ffffff', fontSize: '50px' }}>
                            sentiment_dissatisfied
                        </span>
                    </div>
                    <div className={classes.message}>
                        {/* <p className={classes.err_status}>Error 500</p> */}
                        <p style={{fontSize : "14px"}}>Deployment Failed{/* HTTP <br /> probe failed with status code 500 */}</p>
                        <Link onClick={handleClickAndRedirect} style={{fontSize : "14px"}}>View the Possible Cause</Link>
                    </div>
                    <div style={{ width: '10%' }}>
                        <button className="btn btn-transparent" onClick={() => { handleClosePopover() }}>
                            <i className="ri-close-line" style={{ fontSize: 20, color: 'grey' }} />
                        </button>
                    </div>
                  </div>
                  :
                  variant === 'deploy-activity' ?
                    <span style={{ color: '#000000f2' }} className="font-13">Want to review deployment status? Please see Deployment Analytics <span onKeyDown={() => { }} onClick={handleClickOpen} style={{ cursor: 'pointer', color: '#0086ff' }} className="font-13" tabIndex={0} role='button'>here</span>.</span>
                    :
                    variant === 'v3' ?
                      <Tooltip title="Monitoring" className={classes.monitorButtonV3}>
                        <button className="btn btn-link text-anchor-blue d-flex align-center m-0 btn-flaticon" onClick={handleClickOpen}>

                          <PersonalVideoIcon className="" fontSize="" />
                        </button>
                      </Tooltip>

                      :
                      <>
                        {props.newDesign ?

                          <Tooltip title="Monitoring" className={classes.monitorButton} >
                            <button className="btn-sq-icon-primary d-flex align-center mr-5" onClick={handleClickOpen}>
                              <span className='ri-airplay-line'></span>
                            </button>
                          </Tooltip>
                          :
                          // <Tooltip title="Monitoring" className={classes.monitorButton} >
                          //   {/* <button className="btn btn-link text-anchor-blue d-flex align-center border-left-blue m-0 btn-flaticon" onClick={handleClickOpen}>

                          //   <PersonalVideoIcon className="" fontSize="" />
                          // </button> */}
                          //   <button className="btn-sq-icon-primary d-flex align-center" onClick={handleClickOpen}>
                          //     <span className="ri-airplay-line vertical-align-super"></span>
                          //   </button>
                          // </Tooltip>
                          null
                        }
                      </>
      }
      {
        // <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          

            state.loading ?
              <div className="d-flex align-center justify-center" style={{ height: 500 }}>
                <Loading varient="light" />
              </div>
              :
              state.last_deploy ?

                <>
                  <DialogHeader env_name={query.env_name} service_data={{name:service_name,type:env_type}} env_id={query.env_id} application_id={application_id} global_pipeline_flag={global_pipeline_flag}/>
                  <div className={classes.maincontainer}>
                    <div className="card-header bg-white d-flex space-between">
                      <CommonHorizontalTab
                        tabList={header_tab_list}
                        selectedTabOrder={state.selectedTab}
                        updateSelectedTab={updateSelectedTab}
                      />
                      <div className="d-flex align-center">
                        {/* {
                        state.all_deployment_list && state.all_deployment_list.length > 1 ? */}
                        {variant === 'deploy-activity' ?
                          null :
                          <div className='d-grid' style={{ gridTemplateColumns: '1fr 300px', alignItems: 'baseline' }}>
                            <div className='font-11'>Select Deployment:</div>
                            <div className="input-mb-0 mb-0">
                              <Input
                                type="select"
                                label=""
                                name="deployment_name"
                                //list={type=="canary" ?  all_available_deployments : state?.deployments_list ? state.deployments_list : all_available_deployments}
                                list={state?.deployments_list ? state.deployments_list : all_available_deployments}
                                onChangeHandler={onChangeDropdownHandler} //onChangeDropdownHandler
                                data={state.data}
                                error={state.error}
                              />
                            </div>
                          </div>
                        }
                        <button className="btn-round border-navy bg-clear-btn ml-10" onClick={refresh}>
                          <span className="flaticon-refresh-button-1"></span>
                        </button>
                      </div>

                    </div>
                    {
                      state.selectedTab === 1 ?
                        state.last_deploy?.deployment_type == "CANARY" ?
                          <CanaryDeploymentView refresh_count={state.refresh_count} application_id={application_id} service_id={service_id} deploy_id={state.last_deploy.id} env_id={env_id} />
                          :
                          <DeploymentAnalytics env_details={env_details} last_deployment_number={state.selected_deployment?.deploy_number ? state.selected_deployment?.deploy_number : null} refresh_count={state.refresh_count} service_id={service_id} env_id={env_id} cd_id={state.data.deployment_name} />
                        : null
                    }
                    {
                      state.selectedTab === 2 &&
                      <PodsListingWithDetails refresh_count={state.refresh_count} last_deploys={state.last_deploy} service_id={service_id} env_id={env_id} cd_id={state.data.deployment_name} /> //selected_deployement_resource_kind={state?.selected_deployement_resource_kind}
                    }
                  </div>
                </>
                :
                <>
                  <DialogHeader env_name={query.env_name} service_data={{name:service_name,type:env_type}} env_id={query.env_id} application_id={application_id} />
                  <div className={classes.maincontainer}>
                    <div className="card-header  bg-white  d-flex space-between">
                      <CommonHorizontalTab
                        tabList={header_tab_list}
                        selectedTabOrder={state.selectedTab}
                        updateSelectedTab={updateSelectedTab}
                      />
                      <div className="d-flex align-center">
                        {/* {
                        state.all_deployment_list && state.all_deployment_list.length > 1 ? */}
                        <div className='d-grid' style={{
                          gridTemplateColumns: '1fr 300px',
                          alignItems: 'baseline'
                        }}>
                          <div>Select Deployment:</div>
                          <div className="input-mb-0 mb-0">
                            <Input
                              type="select"
                              label=""
                              name="deployment_name"
                              list={state?.deployments_list ? state.deployments_list : []}
                              onChangeHandler={onChangeDropdownHandler} //onChangeDropdownHandler
                              data={state.data}
                              error={state.error}
                            />
                          </div>
                        </div>
                        {/* : null
                      } */}
                        <button className="btn-round border-navy bg-clear-btn ml-10" onClick={refresh}>
                          <span className="flaticon-refresh-button-1"></span>
                        </button>
                      </div>
                    </div>
                    {
                      state.selectedTab == 1 &&
                      <DeploymentAnalytics env_details={env_details} refresh_count={state.refresh_count} service_id={service_id} env_id={env_id} cd_id={state.data.deployment_name} />
                    }
                    {
                      state.selectedTab == 2 &&
                      <PodsListingWithDetails refresh_count={state.refresh_count} service_id={service_id} env_id={env_id} cd_id={state.data.deployment_name} />
                    }
                  </div>
                </>
          
        // </Dialog>
      }

    </>
  );
}

MonitoringDialogue.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 32,
    '& .font-11': {
      fontSize: 11
    }
  },
  maincontainer: {
    margin: '32px',
    backgroundColor: '#fff',
    borderRadius: '2px',
    border: '1px solid #ebedee',
    '& .bottom-panel': {
      '& .headerul': {
        padding: "10px 20px",
        paddingBottom: '0!important',
        borderBottom: '1px solid #dedede',
        '& li': {
          '&.active': {
            border: '1px solid #dedede',
            borderBottom: 'none',
            borderRadius: '5px 5px 0px 0px'
          }
        }
      },
    },
    '&>.card-header': {
      padding: '15px 15px 10px 15px',
    },
    '& .headerul': {
      backgroundColor: 'transparent',
      borderRadius: '0px',
      borderBottom: '0px solid #ebedee'
    },
    '& .gird-template-single-monitoring': {
      gridTemplateColumns: '70% 30%',

      '& .chart-section': {
        backgroundColor: '#fff'
      },
      '& .right-panel-monitoring': {
        backgroundColor: ' #fcfcfc',
        borderLeft: '1px solid #dedede',

      }
    },
    '& .inner-data-circle': {
      height: 250,
      width: 250,
      backgroundColor: '#f3fff8',
      border: '2px dashed #69e09c',
      borderRadius: '50%'
    }
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  monitorButtonV3: {
    '& svg': {
      fontSize: '21px !important',
    }
  },
  monitorButton: {
    '&:hover': {
      '& svg': {
        color: '#ffffff',
      },
    },
    '& svg': {
      fontSize: '21px !important',
    }


  },
}));
