import React from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: '#e3e6e8 !important',
  },
  top: {
    color: '#211c4f',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  arrow:{
      position: 'absolute',
      left:'2.5rem',
      top:'1.8rem',
      fontSize:30,
      color: '#211c4f'
  }
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={80}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        value={progress}
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={80}
        thickness={4}
        {...props}
      />
      {/* <ArrowForwardIcon className={classes.arrow}  /> */}
      <span className={`ri-arrow-right-line ${classes.arrow}`}></span>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    },
});

export default function CustomizedProgressBar() {
  const classes = useStyles();

  return (
    <div className={classes.root+ ' ' + 'd-flex align-center justify-center'}>
      <FacebookCircularProgress  />
    </div>
  );
}
