import React, { useState, useEffect } from 'react';
import { RemoveLoginCreds } from "../util/security";
import { Navigate } from 'react-router-dom';

export const LogOutView = (props) => {
  const [logoutStatus, setLogoutStatus] = useState(null);

  useEffect(() => {
    // Initiate logout when component mounts.
    RemoveLoginCreds(
      () => setLogoutStatus("done"),
      () => setLogoutStatus("failed")
    );
  }, []);

  if (logoutStatus === "done") {
    return <Navigate to="/login" replace />;
  }

  if (logoutStatus === "failed") {
    return <h2>Logout Failed. Please Try again</h2>;
  }

  return <p>Logging out...</p>;
};

export default LogOutView;
