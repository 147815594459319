import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/system';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

export function SimpleBackdrop() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <button onClick={handleToggle}>Toggle Backdrop</button>
      <StyledBackdrop open={open} onClick={handleClose}>
        <div className="loader-bg-card">
          <div className="loader" id="loader-1"></div>
        </div>
      </StyledBackdrop>
    </>
  );
}

export const Loading = ({ varient, style }) => {
  const containerStyle = style || {};
  return (
    <>
      {varient === 'light' ? (
        <div className="loading-gif-container-two" style={containerStyle}>
          <div className="loader-bg-card-2">
            <div className="loader top-0px" id="loader-1"></div>
          </div>
        </div>
      ) : (
        <div className="loading-gif">
          <div className="loading-overlay"></div>
          <div className="loader-bg-card">
            <div className="loader" id="loader-1"></div>
          </div>
        </div>
      )}
    </>
  );
};

Loading.propTypes = {
  varient: PropTypes.string,
  style: PropTypes.object,
};

export const LoadingContainer = () => {
  return (
    <div className="loading-gif-container">
      <div className="loading-overlay"></div>
      <div className="loader-bg-card">
        <div className="loader" id="loader-1"></div>
      </div>
    </div>
  );
};

export const LoadingContainerWithoutCircle = () => {
  return (
    <div className="loading-gif-container-two">
      <div className="loader-bg-card-2">
        <div className="loader" id="loader-1"></div>
      </div>
    </div>
  );
};

export const LoadingText = () => {
  return (
    <MainBody className="loading-text-cc">
      <div className="load-4">
        <div className="ring-1"></div>
        <p className="loading-text">Loading...</p>
      </div>
    </MainBody>
  );
};

export const LoadingGifDots = () => {
  return (
    <div className="loading-gif-container">
      <div className="loader-dots" id="loader-2">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

const MainBody = styled('div')({
  height: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
