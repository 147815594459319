import React from 'react';
import PropTypes from 'prop-types';
import properties from '../../../../../../properties/properties';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import { Tooltip } from '@mui/material';
import useFetchPermissions from '../../../../../../components/customHooks/useFetchPermissions';
import { Link } from 'react-router-dom';

const SourceDetailsOverview = (props) => {
    const repo_details = props.repo_details;
    const git_repo = repo_details.git_repo;
    const git_url = git_repo ? git_repo.git_url : null;
    const username = repo_details.username;
    const branch_name = repo_details.branch_name;
    const dockerfile_path = repo_details.dockerfile_path;
    const image_name = props.image_name;
    const application_id = props.application_id;
    const service_id = Number(props.service_id);
    const component_env_id = props.component_env_id;
    const ci_id = props.ci_id ? props.ci_id : null;
    const label = props.label
    const secondary_build = props.secondary_build;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: ci_id }, properties.api.edit_ci, true);
    const is_permitted = useFetchPermissions()?.POST.includes(url);
    const parent_ci_data = props.parent_ci_data
    console.log("ndfjdbefrj", parent_ci_data);
    console.log("ndfjdbefrj", props);
    return (

        <div className="section-service-overview">
            <div className="overview-single-card">
                <div className="overview-card-heading-section">
                    <p className="overview-card-heading">Repository Details</p>
                    {is_permitted ?

                        <Link
                            to={{
                                pathname: `/application/${application_id}/service/${service_id}/env/${component_env_id}/ci/${parent_ci_data?.id}/edit`,
                                search: `?selectedTabOrder=1&selected_ci_id=${repo_details.id}&secondary_build=${secondary_build}&parent_ci_id=${parent_ci_data?.id}`,
                            }}
                            state={{...props.extraProps}}
                        >
                            <span className="ri-edit-line" style={{ color: '#0086ff' }}></span>
                        </Link>


                        : <Tooltip title="You are not allowed to perform this action" >
                            <span className="ri-edit-line" style={{ color: '#818078' }}></span>
                        </Tooltip>}
                </div>
                <div className="overview-card-value-access" style={{ height: '146.74px' }}>
                    <p className="overview-card-value-text">
                        <span className="color-text-dark-grey">Git URL:&nbsp;</span>
                        {
                            repo_details.git_repo ?
                                <span className="overview-card-value-text">
                                    {git_url}
                                </span> :
                                <span className="no-data__msg ">NA</span>
                        }


                    </p>
                    {/* <p className="overview-card-value-text">
                            Username:&nbsp;
                            {
                                repo_details.username ? 
                                <span className="overview-card-value-text">
                                {username}
                                            </span>:
 <span className="no-data__msg ">NA</span>
                            }
                            
                        </p> */}
                    <p className="overview-card-value-text">
                        Branch:&nbsp;
                        {
                            repo_details.branch_name ?
                                <span className="overview-card-value-text">
                                    {branch_name}
                                </span> :
                                <span className="no-data__msg ">NA</span>
                        }
                    </p>
                    <p className="overview-card-value-text">
                        Docker File Path:&nbsp;
                        {
                            repo_details.dockerfile_path ?
                                <span className="overview-card-value-text">
                                    {dockerfile_path}
                                </span> :
                                <span className="no-data__msg ">NA</span>
                        }


                    </p>
                    <p className="overview-card-value-text">
                        Image Name:&nbsp;
                        {
                            image_name ?
                                <span className="overview-card-value-text">
                                    {secondary_build ? `${label}` : image_name}
                                </span> :
                                <span className="no-data__msg ">NA</span>}

                    </p>
                    <p className="overview-card-value-text">
                        Extra Args:&nbsp;
                        {
                            repo_details.custom_parameters ?
                                <span className="overview-card-value-text">
                                    {repo_details.custom_parameters}
                                </span> :
                                <span className="no-data__msg ">NA</span>}

                    </p>
                </div>
            </div>
        </div>

    )
}

SourceDetailsOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default SourceDetailsOverview;