import React from "react";
import '../../assets/style.css';
import PropTypes from 'prop-types';
const HorizontalTab = props => {
  let tabDataList = props.tabDataList;
  let tabState = props.tabState;
  let selectedTabOrder = tabState.selectedTabOrder;
  let variant = props.variant;
  let onClickHandler = props.onClickHandler;
  let style = props.style ? props.style : {}
  let enableTabsOrder = props.enableTabsOrder;


  return (
    <div className={variant === "build_step_logs" ?"md-stepper-horizontal logs-step" :"md-stepper-horizontal"} style={{ overflow: "auto"}}>
      {variant == "edit_mode" ?
        <>
          {
            tabDataList.map((tabData) => (
              <div key={tabData.order} className="md-step active" >
                <div onKeyDown={() => { }} onClick={() => onClickHandler(tabData.order)} role="button" tabIndex={0} className={tabData.order == selectedTabOrder ? "md-step-circle current" : "md-step-circle success"}>
                  <i className="ri-check-line" style={{ fontSize: '18px', color: `${tabData.order == selectedTabOrder ? '#0096db' : '#62E187'}` }} />
                </div>
                <div className="md-step-title">{tabData.mainText}</div>
                <div className={tabData.order == selectedTabOrder ? "md-step-bar-left current" : "md-step-bar-left success"}></div>
                <div className={tabData.order == selectedTabOrder ? "md-step-bar-right current" : "md-step-bar-right success"}></div>
              </div>
            ))
          }
        </> :
        variant === "build_step_logs" ?
          <>
            {
              tabDataList.map((tabData) => (
                <div style={enableTabsOrder?.disable && tabData.order > enableTabsOrder?.limit ? { cursor: 'default' } : { cursor: 'pointer' }} role="button" tabIndex={0} onKeyDown={() => { }} onClick={!enableTabsOrder?.disable ? () => onClickHandler(tabData.order) : tabData.order <= enableTabsOrder?.limit ? () => onClickHandler(tabData.order) : () => { }}
                  key={tabData.order} className="md-step active md-step-log" >
                  <div

                    className={
                      enableTabsOrder?.disable && tabData.order > enableTabsOrder?.limit ?
                        "md-step-circle disable" :
                        tabData.status === "FAILED" ?
                          "md-step-circle log-fail" :
                          tabData.status === "SUCCESS" ?
                            "md-step-circle log-success" :
                            tabData.status === "RUNNING" ?
                              "md-step-circle log-running" :
                              tabData.status === "REVOKED" ?
                                "md-step-circle log-revoked" :
                                tabData.status === "STOPPED" ?
                                  "md-step-circle stopped" :
                                  tabData.status === "IN_QUEUE" ?
                                    "md-step-circle current" :
                                    tabData.status === "SKIPPED" ?
                                      "md-step-circle skipped" :
                                      tabData.status === "PENDING_APPROVAL" ? 
                                      "md-step-circle log-running" :
                                      "md-step-bar-logs-left current"
                    }>
                    {
                      tabData.status === "SUCCESS" ?
                      <i className="ri-checkbox-circle-line" style={{ fontSize: '23px', color: '#01BB62', marginLeft: '-6px' }}></i>
                        :
                        tabData.status === "FAILED" ?
                        <i className="ri-error-warning-line" style={{ fontSize: '23px', color: '#E1941D', marginLeft: '-6px' }}></i>
                          //<i className="ri-information-line color-danger" style={{ fontSize: '18px!important' }} /> 
                          :
                          tabData.status === "IN_QUEUE" ?
                            <i className="ri-information-line color-danger" style={{ fontSize: '18px' }}></i> :
                            tabData.status === "STOPPED" ?
                              <i className="ri-information-line" style={{ fontSize: '18px', color: `${tabData.order == selectedTabOrder ? '#0096db' : '#62E187'}` }} ></i> :
                              tabData.status === "RUNNING" ?
                              <i className="ri-settings-3-line" style={{ fontSize: '23px', color: '#407BCA', marginLeft: '-6px' }}></i> :
                              tabData.status === "PENDING_APPROVAL" ?
                              <i className="ri-settings-3-line" style={{ fontSize: '23px', color: '#407BCA', marginLeft: '-6px' }}></i> :
                                tabData.status === "SKIPPED" ?
                                   <i className="ri-arrow-right-s-line" style={{ fontSize: '22px !important', color: "#AEAEAE" }} /> :
                                   <i className="ri-stop-circle-line color-revoked" style={{ fontSize: '23px', }}></i>

                    }

                  </div>
                  <div className={tabData.order == selectedTabOrder ? "md-step-title-active md-step-title-step " : "md-step-title md-step-title-step "} style={{textWrap:"nowrap"}}>{tabData.mainText}</div>
                  <div className={
                    enableTabsOrder?.disable && tabData.order > enableTabsOrder?.limit ?
                      "md-step-bar-logs-left disable" :
                      tabData.status === "FAILED" ?
                        "md-step-bar-logs-left fail" :
                        tabData.status === "SUCCESS" ?
                          "md-step-bar-logs-left success" :
                          tabData.status === "RUNNING" ?
                            "md-step-bar-logs-left default" :
                            tabData.status === "REVOKED" ?
                              "md-step-bar-logs-left purple" :
                              tabData.status === "STOPPED" ?
                                "md-step-bar-logs-left stopped" :
                                tabData.status === "IN_QUEUE" ?
                                  "md-step-bar-logs-left current" :
                                  tabData.status === "SKIPPED" ?
                                    "md-step-bar-logs-left skipped" :
                                    tabData.status === "PENDING_APPROVAL" ?
                                      "md-step-bar-logs-left default" :
                                    "md-step-circle current"
                  }></div>
                  {/* <div className="md-step-bar-logs-right success"></div> */}
                  <div className={
                    enableTabsOrder?.disable && tabData.order > enableTabsOrder?.limit ?
                      "md-step-bar-logs-right disable" :
                      tabData.status === "FAILED" ?
                        "md-step-bar-logs-right fail" :
                        tabData.status === "SUCCESS" ?
                          "md-step-bar-logs-right success" :
                          tabData.status === "RUNNING" ?
                            "md-step-bar-logs-right default" :
                            tabData.status === "REVOKED" ?
                              "md-step-bar-logs-right purple" :
                              tabData.status === "STOPPED" ?
                                "md-step-bar-logs-right stopped" :
                                tabData.status === "IN_QUEUE" ?
                                  "md-step-bar-logs-right current" :
                                  tabData.status === "SKIPPED" ?
                                    "md-step-bar-logs-right skipped" :
                                    tabData.status === "PENDING_APPROVAL" ?
                                      "md-step-bar-logs-right default" :
                                    "md-step-circle current"
                  }></div>
                  {/* <div className={
                    tabData.order > selectedTabOrder && enableTabsOrder.disable && tabData.order > enableTabsOrder.limit ? "md-step-bar-logs-right success" :
                      "md-step-bar-logs-right success"}></div> */}
                </div>
              ))
            }
          </>
          :
          variant === "step_logs" ?
            <>
              {
                tabDataList.map((tabData) => (
                  <div style={enableTabsOrder?.disable && tabData.order > enableTabsOrder?.limit ? { cursor: 'default' } : { cursor: 'pointer' }} role="button" tabIndex={0} onKeyDown={() => { }} onClick={!enableTabsOrder.disable ? () => onClickHandler(tabData.order) : tabData.order <= enableTabsOrder.limit ? () => onClickHandler(tabData.order) : () => { }}
                    key={tabData.order} className="md-step active md-step-log" >
                    <div

                      className={
                        enableTabsOrder?.disable && tabData.order > enableTabsOrder?.limit ?
                          "md-step-circle disable" :
                          tabData.status === "FAILED" ?
                            "md-step-circle fail" :
                            tabData.status === "SUCCESS" ?
                              "md-step-circle success" :
                              tabData.status === "RUNNING" ?
                                "md-step-circle running" :
                                tabData.status === "REVOKED" ?
                                  "md-step-circle revoked" :
                                  tabData.status === "STOPPED" ?
                                    "md-step-circle stopped" :
                                    tabData.status === "IN_QUEUE" ?
                                      "md-step-circle current" :
                                      tabData.status === "SKIPPED" ?
                                        "md-step-circle skipped" :
                                        "md-step-bar-left current"
                      }>
                      {
                        tabData.status === "SUCCESS" ?
                          <i className="ri-check-line" style={{ fontSize: '18px', color: '#62E187' }} /> :
                          tabData.status === "FAILED" ?
                           <i className="ri-information-line color-danger" style={{ fontSize: '18px' }} /> :
                            tabData.status === "IN_QUEUE" ?
                             <i className="ri-information-line color-danger" style={{ fontSize: '18px' }} /> :
                              tabData.status === "STOPPED" ?
                               <i className="ri-information-line" style={{ fontSize: '18px', color: `${tabData.order == selectedTabOrder ? '#0096db' : '#62E187'}` }} /> :
                                tabData.status === "RUNNING" ?
                                  <i className="ri-timer-2-fill text-anchor-blue" style={{ fontSize: '18px', color: `${tabData.order == selectedTabOrder ? '#0096db' : '#62E187'}` }} /> :
                                  tabData.status === "SKIPPED" ?
                                     <i class="ri-arrow-right-s-line" style={{ fontSize: '22px', color: "#AEAEAE" }} /> :
                                   <i className="ri-information-line color-revoked" style={{ fontSize: '18px', }} />

                      }

                    </div>
                    <div className={tabData.order == selectedTabOrder ? "md-step-title-active md-step-title-step" : "md-step-title md-step-title-step"}>{tabData.mainText}</div>
                    <div className={
                      enableTabsOrder?.disable && tabData.order > enableTabsOrder?.limit ?
                        "md-step-bar-left disable" :
                        tabData.status === "FAILED" ?
                          "md-step-bar-left fail" :
                          tabData.status === "SUCCESS" ?
                            "md-step-bar-left success" :
                            tabData.status === "RUNNING" ?
                              "md-step-bar-left default" :
                              tabData.status === "REVOKED" ?
                                "md-step-bar-left purple" :
                                tabData.status === "STOPPED" ?
                                  "md-step-bar-left stopped" :
                                  tabData.status === "IN_QUEUE" ?
                                    "md-step-bar-left current" :
                                    tabData.status === "SKIPPED" ?
                                      "md-step-bar-left md-step-bar-left-skipped" :
                                      "md-step-circle current"
                    }></div>
                    <div className="md-step-bar-right success"></div>
                    {/* <div className={
                    tabData.order > selectedTabOrder && enableTabsOrder.disable && tabData.order > enableTabsOrder.limit ? "md-step-bar-right success" :
                      "md-step-bar-right success"}></div> */}
                  </div>
                ))
              }
            </>
            :
            variant === "step_logs_static" ?
              <>
                {
                  tabDataList.map((tabData) => (
                    <div key={tabData.order} className="md-step active md-step-log" style={{ marginBottom: '24px' }}>
                      <div
                        onClick={() => onClickHandler(tabData.order)}
                        onKeyDown={() => { }}
                        role="button" tabIndex={0}
                        className={
                          enableTabsOrder?.disable && tabData.order > enableTabsOrder?.limit ?
                            "md-step-circle disable" :
                            tabData.status === "FAILED" ?
                              "md-step-circle fail" :
                              tabData.status === "SUCCESS" ?
                                "md-step-circle success" :
                                tabData.status === "RUNNING" ?
                                  "md-step-circle running" :
                                  tabData.status === "REVOKED" ?
                                    "md-step-circle revoked" :
                                    tabData.status === "STOPPED" ?
                                      "md-step-circle stopped" :
                                      tabData.status === "IN_QUEUE" ?
                                        "md-step-circle current" :
                                        tabData.status === "SKIPPED" ?
                                          "md-step-circle skipped" :
                                          "md-step-bar-left current"
                        }>
                        {
                          tabData.status === "SUCCESS" ?
                            <i className="ri-check-line" style={{ fontSize: '18px', color: '#62E187' }} /> :
                            tabData.status === "FAILED" ?
                             <i className="ri-information-line color-danger" style={{ fontSize: '18px' }} /> :
                              tabData.status === "IN_QUEUE" ?
                               <i className="ri-information-line color-danger" style={{ fontSize: '18px' }} /> :
                                tabData.status === "STOPPED" ?
                                 <i className="ri-information-line" style={{ fontSize: '18px', color: `${tabData.order == selectedTabOrder ? '#0096db' : '#62E187'}` }} /> :
                                  tabData.status === "RUNNING" ?
                                    <i className="ri-timer-2-fill text-anchor-blue" style={{ fontSize: '18px', color: `${tabData.order == selectedTabOrder ? '#0096db' : '#62E187'}` }} /> :
                                    tabData.status === "SKIPPED" ?
                                       <i class="ri-arrow-right-s-line" style={{ fontSize: '22px', color: "#AEAEAE" }} /> :
                                     <i className="ri-information-line color-revoked" style={{ fontSize: '18px', }} />

                        }

                      </div>
                      <div className={tabData.order == selectedTabOrder ? "md-step-title-active md-step-title-step" : "md-step-title md-step-title-step"}>{tabData.mainText}</div>
                      <div className={
                        enableTabsOrder?.disable && tabData.order > enableTabsOrder?.limit ?
                          "md-step-bar-left disable" :
                          tabData.status === "FAILED" ?
                            "md-step-bar-left fail" :
                            tabData.status === "SUCCESS" ?
                              "md-step-bar-left success" :
                              tabData.status === "RUNNING" ?
                                "md-step-bar-left default" :
                                tabData.status === "REVOKED" ?
                                  "md-step-bar-left purple" :
                                  tabData.status === "STOPPED" ?
                                    "md-step-bar-left stopped" :
                                    tabData.status === "IN_QUEUE" ?
                                      "md-step-bar-left current" :
                                      tabData.status === "SKIPPED" ?
                                        "md-step-bar-left skipped" :
                                        "md-step-bar-left current"
                      }></div>
                      <div className={
                        tabData.order > selectedTabOrder && enableTabsOrder?.disable && tabData.order > enableTabsOrder?.limit ? "md-step-bar-right success" :
                          "md-step-bar-right success"}></div>
                    </div>
                  ))
                }
              </>
              : variant === "step_logs_static_new" ?
                <div className="d-flex align-center" style={{backgroundColor : "#ffffff", width : "100%"}}>
                  {
                    tabDataList.map((tabData, index) => (
                      <div key={tabData.order}>
                        <div className="d-flex align-center">


                          <div style={{ visibility: index !== 0 ? "visible" : "hidden" }} className={
                            tabData.status === "FAILED" ?
                              "step-logs-bar new-failed-log" :
                              tabData.status === "SUCCESS" ?
                                "step-logs-bar success" :
                                tabData.status === "RUNNING" ?
                                  "step-logs-bar running" :
                                  tabData.status === "REVOKED" ?
                                    "step-logs-bar revoked" :
                                    tabData.status === "IN_QUEUE" ?
                                      "step-logs-bar inqueue" :
                                      tabData.status === "SKIPPED" ?
                                        "step-logs-bar skipped" :
                                        "step-logs-bar inqueue"
                          }></div>

                          <div
                            style={{ margin: "0px 10px" }}
                            onClick={() => onClickHandler(tabData.order)}
                            className="d-flex align-center"
                          >
                            {
                              tabData.status === "SUCCESS" ?
                                <span className="ri-checkbox-circle-line" style={{ color: "#129E5B", fontSize: "20px" }}></span> :
                                tabData.status === "FAILED" ?

                                  <span className="ri-close-circle-line font-20" style={{ color: "#BA0000", }}> </span>
                                  :
                                  tabData.status === "STOPPED" ?
                                    <span className="ri-stop-circle-line font-20" style={{ color: "#626262", }}> </span> :
                                    tabData.status === "IN_QUEUE" ?
                                      <span className="ri-time-line"></span> :
                                      tabData.status === "RUNNING" ?
                                        <span className="ri-refresh-line font-20 running-state-icon" style={{ color: "#0086FF", }}> </span> :
                                        tabData.status === "SKIPPED" ?
                                        <span className="ri-arrow-right-circle-line" style={{color:"#AEAEAE", fontSize:"22px"}}></span> :
                                        <span className="ri-stop-circle-line font-20" style={{ color: "#626262", }}> </span>
                            }
                          </div>
                          <div style={{ visibility: (tabDataList.length - 1) > index ? "visible" : "hidden" }} className={
                            tabData.status === "FAILED" ?
                              "step-logs-bar new-failed-log" :
                              tabData.status === "SUCCESS" ?
                                "step-logs-bar success" :
                                tabData.status === "RUNNING" ?
                                  "step-logs-bar running" :
                                  tabData.status === "REVOKED" ?
                                    "step-logs-bar revoked" :
                                    tabData.status === "IN_QUEUE" ?
                                      "step-logs-bar inqueue" :
                                      tabData.status === "SKIPPED" ?
                                        "step-logs-bar skipped" :
                                        "step-logs-bar inqueue"
                          }></div>

                        </div>
                        <div className={"md-step-title-step"} style={{ marginTop: "14px" }}>{tabData.mainText}</div>
                      </div>
                    ))
                  }
                </div> :
                variant === 'pipeline-stages-params' ?

                  <div className="tab-container">
                    {
                      tabDataList?.map((tabData, index) => {
                        return (
                          <div key={tabData.order} class={tabData.order == selectedTabOrder ? "stages-div active-stage" : tabData.order < selectedTabOrder ? "stages-div visited-stage" :
                            "stages-div"} onClick={() => onClickHandler(tabData.order)}  >
                            <div className="d-flex mt-8">
                              <span className="ri-circle-fill" style={{ fontSize: '10px', color: '407BCA', marginRight: '6px', marginTop: '3px' }} />
                              <p className="stage-order  capital-text">{tabData.mainText}</p>
                            </div>
                            <p className="stage-name ">{tabData.captionText}</p>
                            <div className="stage-connected-line">
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  :
                  variant == "policy-template-wizard" ?
                    <>
                      {
                        tabDataList.map((tabData, index) => (
                          <div className="policy-step-details">
                            <div style={style} key={tabData.order} class="policy-step">
                              <div class={tabData.order == selectedTabOrder ? "policy-step-active" : tabData.order < selectedTabOrder ? "policy-step-active" : "policy-step"}>
                                {/* <span> */}
                                {
                                  tabData.icon
                                }
                                {/* <PeopleOutlineOutlinedIcon fontSize='large' style={{ color: "#FFFFFF" }} /> */}
                                {/* </span> */}
                              </div>
                            </div>
                            <div className="step-data-div" style={{ marginLeft: "10px" }}>
                              <div style={{ display: "flex", alignItems: "center" }} >
                                <p style={{ color: "#4D4D4D", fontSize: "12px", }}>{tabData.mainText} </p>
                                <ArrowRightAltOutlinedIcon className="d-to-hide" fontSize="large" />
                              </div>
                              {/* <div style={{ color: "#9A9A9A", fontSize: "10px" }}>Not Assigned</div> */}
                            </div>
                          </div>

                        ))
                      }
                    </>
                    :
                    variant == "v3-best-tabs" ?
                      <>
                        tabs
                      </>
                      :
                      <>
                        {
                          tabDataList.map((tabData) => (
                            <div style={style} key={tabData.order} class="md-step active" >
                              <div class={tabData.order == selectedTabOrder ? "md-step-circle current" : tabData.order > selectedTabOrder ? "md-step-circle" : "md-step-circle success"}>  
                                  <i className="ri-check-line font-18" style={{ color: `${tabData.order == selectedTabOrder ? "#0096db" : tabData.order > selectedTabOrder ? "#e7e7e7" : "#62E187"}` }} />
                              </div>
                              <div class="md-step-title">{tabData.mainText}</div>
                              <div class={tabData.order <= selectedTabOrder ? tabData.order == selectedTabOrder ? "md-step-bar-left current" : "md-step-bar-left success" : tabData.order == selectedTabOrder + 1 ? "md-step-bar-left" : "md-step-bar-left "}></div>
                              <div class={tabData.order == selectedTabOrder ? "md-step-bar-right current" : tabData.order > selectedTabOrder ? "md-step-bar-right" : "md-step-bar-right success"}></div>
                            </div>
                          ))
                        }
                      </>}
    </div>
  )


}

HorizontalTab.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default HorizontalTab;