import { makeStyles } from '@mui/styles';
import React from 'react'
import GenericSkeleton from './GenericSkeleton';

const ListingPageHeaderSkeleton = () => {
    const classes = useStyles();
  return (
    <div className="d-flex align-center space-between" style={{marginBottom: "16px"}}>
            <div className="main-div width-full">
              <p className="heading-primary"><GenericSkeleton variant={"text"} height={50} width={"40%"} /></p>
              <p className="sub-heading-primary">
                <GenericSkeleton variant={"text"} height={16} width={"60%"} />
              </p>
            </div>
              <GenericSkeleton variant={"rect"} height={35} width={136} style={{borderRadius:"6px"}}  />
    </div>
  )
}

const useStyles = makeStyles({
    integraded_header: {
        padding: '0px 0px',
        //marginTop: '30px',
        fontWeight: '300',
        lineHeight: '20px',
        marginBottom: '20px',

        '& .main-heading': {
            fontSize: '24px',
            fontWeight:'300',
        },
        '& .sub-heading-txt': {
            fontSize: '11px',
            color: '#999999',
        },
        '& .add-repos-btn':{
            background:'#3e73ec',
            filter: 'drop-shadow(2px 3.464px 5px rgba(0,0,0,0.13))',
            borderRadius:'4px',
            padding:'10px 7px',
            display:'flex',
            justifyContent:'center',
            color:'#fff',
            textAlign:'center',

        }
    }
});

export default ListingPageHeaderSkeleton