import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import properties from '../../../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../../../util/apiInvoker';
import { Loading } from '../../../../utils/Loading';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { getDuration } from '../../../../../util/util';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Pagination from '../../../../../components/Pagination';

import ActivityStepDetails from '../../../../superAdmin/activityFramework/components/ActivityStepDetails';
import { Link } from 'react-router-dom';

const JobTriggerHistory = ({ propsData, jobData, ...props }) => {
    const classes = useStyles();
    // service_env_type = service_env_type ? service_env_type.toLowerCase() : null
    // var service_env = service_data && service_data.service_env;
    // service_env = service_env ? service_env.toLowerCase() : null
    // var service_name = service_data && service_data.name;
    const [state, setState] = useState({
        show_loading_icon: true,
        infiniteScrollInherits: {},
        curr_page: '',
        total_page: '',
        refresh_count: 1,
        activity_list: []
    });

    const refreshComponent = props => {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,
        }));
    }

    useEffect(() => {
        propsData && fetchHistory(propsData);
    }, [jobData, props?.refresh_count]);

    function fetchHistory(propsData) {

        const { component_id, sub_env_id } = propsData
        const { job_code } = jobData;
        if (component_id != undefined && job_code != undefined && sub_env_id != undefined) {
            let get_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.build_trigger_for_job)
            var requestInfo = {
                endPoint: get_url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo, historyFetchSuccess, historyFetchFailure);

            setState(new_state => ({
                ...new_state,
                // search_data: data,
                // current: requestInfo.endPoint,
                show_loading_icon: true
            }));
        }
    }

    function historyFetchSuccess(data) {

        console.log(data, 'data_))1')
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Math.ceil(Number(data.count / 10)),
            curr_page: new_state.curr_page === '' ? 1 : new_state.curr_page
        }));
    }

    function historyFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            error: error,
            history_list: []
        }));
    }
    function fetchNextHistory(data, url) {

        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, NexthistoryFetchSuccess, NexthistoryFetchFailure);
        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            show_loading_icon: true
        }));
    }

    function NexthistoryFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            show_loading_icon: false,
            count: data.count,
            page_start: state.page_start + data.results.length,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            curr_page: new_state.curr_page + 1
        }));
    }

    function NexthistoryFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            error: error,
            history_list: []
        }));
    }

    function fetchPrevHistory(data, url) {

        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, PrevhistoryFetchSuccess, PrevhistoryFetchFailure);

        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            show_loading_icon: true
        }));
    }

    function PrevhistoryFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            show_loading_icon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            curr_page: new_state.curr_page - 1
        }));
    }

    function PrevhistoryFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            show_loading_icon: false,
            error: error,
            history_list: []
        }));
    }

    const handleJobTrigger = () => {
        const { component_id, sub_env_id, component_env_id } = propsData
        const { job_code } = jobData;
        let post_url = GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, component_env_id: component_env_id, job_code: job_code }, properties.api.build_trigger_for_job)
        PostData(post_url, [],
            () => { fetchHistory(propsData) },
            () => { });
    }

    function getClassbyActivityStatus(activity_status, type) {
        if (type == "Revoke") {
            if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
                return "btn btn-flaticon"
            else
                return "btn btn-disabeld-flaticon"
        } else {
            if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
                return "btn btn-disabeld-flaticon"
            else
                return "btn btn-flaticon"
        }
    }
    function getActivityCurrentInfo(activity_id) {
        var requestInfo = {
            endPoint: GenerateURL({ activity_id: activity_id }, properties.api.get_current_activity_status),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, getActivityCurrentInfoSuccess, getActivityCurrentInfoFailure);
    }

    function getActivityCurrentInfoSuccess(response) {
        if (response.results.length != 0) {
            let found = false
            state.history_list.forEach(activity => {
                if (activity.activity_status.id == response.results[0].activity_status_id) {
                    activity.activity_details = response.results
                    found = true
                }
            })
            found && setState(new_state => ({
                ...new_state,
                activity_step_details: {
                    open_div: state.history_list.length > 0 ? true : false
                }
            }));
        }
        console.log("history_list", state)
        console.log('response_act', response)
    }


    function getActivityCurrentInfoFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
    }

    const emptyArr = (activity_id) => {
        state.history_list.forEach(activity => {
            if (activity.activity_details && activity.activity_details.length > 0) {
                // activity.activity_details.forEach((item, key) => {
                if (activity.id == activity_id) {
                    activity.activity_details = null;
                    setState(new_state => ({
                        ...new_state,
                        history_list: [...state.history_list],
                        activity_step_details: {
                            open_div: state.history_list.length > 0 ? true : false
                        }
                    }));
                }
                // })
            }
        })
    }

    function onRevoke(task_id) {

        let urlEndpoint = GenerateURL({}, properties.api.revoke_activity)

        let endPoint = GenerateSearchURL({ task_id: task_id }, urlEndpoint)

        PostData(endPoint, {},

            (response) => {
                console.log('revoke-suc')
                setState(new_state => ({
                    ...new_state,
                    show_loading_icon: false
                }));
                refreshComponent();
            },
            (error) => {
                setState(new_state => ({
                    ...new_state,
                    error: true,
                    show_loading_icon: false
                }));
            }
        )

    }

    const callFunctionbyStatus = (activity_status, id) => {
        if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
            onRevoke(id)
    }

    console.log(propsData, 'props_data')

    console.log(jobData, 'history_state')

    return (
        <>
            {
                state.show_loading_icon ?
                    <div className={classes.historyCard}>
                        <Loading varient="light" />
                    </div> :
                    <>
                        {/* <Grid container className={classes.containerBox + " " + "align-center"}>
                            <Grid item xs={9}>
                                <SearchBar
                                    search_data={state.search_data}
                                    default_filter={{ name: "build_tag", label: "Artifact" }} // "deploy_tag" : "target_image_tag"
                                    search_call_back={() => { }}
                                    clear_search_callback={() => { }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Pagination
                                    current_count={state.history_list ? `Page ${state.curr_page}` : 0}
                                    total_count={state.total_page}
                                    next={state.next}
                                    previous={state.previous}
                                    on_previous_click={() => { fetchPrevHistory(null, state.previous) }}
                                    on_next_click={() => { fetchNextHistory(null, state.next) }}
                                />
                            </Grid>
                        </Grid> */}
                        <div className={classes.maincontainer}>

                            <div className={classes.historyCard}>
                                <div>
                                    <div className="header-tab-build">
                                        <p>Type</p>
                                        <p>Status</p>
                                        <p>Duration</p>
                                        <p>Date/Time</p>
                                        <p>Trigger by</p>
                                        <p>Build Parameters</p>
                                    </div>
                                    <div className="history-body">
                                        {state.history_list.length > 0 ?
                                            <>
                                                {
                                                    state.history_list.map((history, key) => {

                                                        console.log(history, 'hist_ory')

                                                        return (
                                                            <>
                                                                <div className="body-tab-build align-center">
                                                                    <Link
                                                                        className="cursor-pointer text-anchor-blue"
                                                                        to={"/logs?global_task_id=" + history.global_task_id +
                                                                            "&tab_id=" + history.id +
                                                                            "&service_name=" + 'service_name' +
                                                                            "&service_env=" + 'service_env' +
                                                                            "&service_type=" + 'service_env_type' +
                                                                            "&tab_name=BUILD" + "&num=" + 'history.deploy_number'
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        {jobData?.job_name} #{history.job_number}
                                                                    </Link>
                                                                    {/* <p><span className="cursor-pointer text-anchor-blue" onClick={() => { onLog(history.id, history.global_task_id, "Build #" + history.build_number) }}>Build#{history.build_number}</span></p> */}
                                                                    <Tooltip title={history.activity_status && history.activity_status.status}>
                                                                        <p className={history.activity_status && history.activity_status.status == "FAILED" ? "round-chip bg-round-red status-font" : history.activity_status && history.activity_status.status == "RUNNING" ? "round-chip bg-round-blue status-font" : history.activity_status && history.activity_status.status == "REVOKED" ? "round-chip bg-round-purple status-font" : history.activity_status && history.activity_status.status == "IN_QUEUE" ? "round-chip status-chip-info status-font" : "round-chip bg-round-green status-font"}>{history.activity_status && history.activity_status.status}</p>
                                                                    </Tooltip>
                                                                    <p>
                                                                        {history.activity_status ?
                                                                            history.activity_status.status === 'SUCCESS' || history.activity_status.status === 'FAILED' ?
                                                                                getDuration(new Date(history.activity_status.start_time), new Date(history.activity_status.end_time)) == 0 ? "0s" :
                                                                                    getDuration(new Date(history.activity_status.start_time), new Date(history.activity_status.end_time)) :
                                                                                getDuration(new Date(history.activity_status.created_at && history.activity_status.created_at), new Date(history.activity_status.updated_at)) == 0 ? "0s" :
                                                                                    getDuration(new Date(history.activity_status.created_at && history.activity_status.created_at), new Date(history.activity_status.updated_at)) :
                                                                            "--"
                                                                        }
                                                                    </p>
                                                                    <p>{moment(history.activity_status && history.activity_status.created_at ? history.activity_status.created_at : '').fromNow()}</p>
                                                                    {/* <Tooltip title={history.build_tag}><p >{'artifact value'}</p></Tooltip> */}
                                                                    <p>{history.trigger_by_user ? history.trigger_by_user : '-'}</p>

                                                                    <div>
                                                                        {
                                                                            history.key_value_pair ?
                                                                                Object.keys(history.key_value_pair).map((element, key) => (
                                                                                    <Tooltip title={element + " : " + JSON.stringify(history.key_value_pair[element])}>
                                                                                        <p>
                                                                                            {element} : {JSON.stringify(history.key_value_pair[element])}
                                                                                        </p>
                                                                                    </Tooltip>
                                                                                ))
                                                                                : <div class="text-color-grey blank-div-text">No Build Params</div>
                                                                        }
                                                                    </div>
                                                                    <div className="">

                                                                        <button className="btn btn-transparent text-anchor-blue d-block m-auto"
                                                                            onClick={() => {
                                                                                getActivityCurrentInfo(history.activity_status && history.activity_status.id ?
                                                                                    history.activity_status.id : "")
                                                                            }} >View Details</button>
                                                                    </div>
                                                                    <div className="d-flex align-center ">
                                                                        <div className="btn-group btn-icon-group width-fitcontent ml-15">
                                                                            {
                                                                                true ?
                                                                                    <>
                                                                                        <Tooltip title="Retrigger">
                                                                                            <button
                                                                                                className={history.activity_status ?
                                                                                                    getClassbyActivityStatus(history.activity_status.status, "Rebuild")
                                                                                                    : "btn btn-disabeld-flaticon"}
                                                                                                onClick={() => {
                                                                                                    handleJobTrigger()
                                                                                                }}
                                                                                            >
                                                                                                <span class="flaticon-flash-on-indicator"></span>
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <Tooltip title="Retrigger">
                                                                                            <button
                                                                                                className={"btn btn-disabeld-flaticon"}
                                                                                            >
                                                                                                <span class="flaticon-flash-on-indicator"></span>
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                    </>
                                                                            }
                                                                            <button
                                                                                className={history.activity_status ?
                                                                                    getClassbyActivityStatus(history.activity_status.status, "Revoke")
                                                                                    : "btn btn-disabeld-flaticon"}
                                                                                onClick={history.activity_status ?
                                                                                    () => { callFunctionbyStatus(history.activity_status.status, history.global_task_id) }

                                                                                    : null}
                                                                                title="Revoke Build">
                                                                                <span class="flaticon-stop-button"></span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    history.activity_details ?
                                                                        <div className="" style={{ width: '100%' }}>
                                                                            <ActivityStepDetails
                                                                                activityDetail={history.activity_details}
                                                                                tableGird={false}
                                                                                key={key}
                                                                                logUrl={"/logs?global_task_id=" + history.global_task_id +
                                                                                    "&tab_id=" + history.id +
                                                                                    "&service_name=" + '' +
                                                                                    "&service_env=" + '' +
                                                                                    "&service_type=" + '' +
                                                                                    "&tab_name=BUILD" + "&num=" + history.job_number
                                                                                }
                                                                                prev_state={state.activity_step_details}
                                                                                removeArrData={() => { emptyArr(history.id) }}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </>
                                                        )
                                                    })




                                                }
                                            </> : <div class="blank-div">
                                                <div class="blank-div-text">No Data Found</div>
                                            </div>}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div>
                            <Pagination
                                current_count={state.history_list ? `Page ${state.curr_page}` : 0}
                                total_count={state.total_page}
                                next={state.next}
                                previous={state.previous}
                                on_previous_click={() => { fetchPrevHistory(null, state.previous) }}
                                on_next_click={() => { fetchNextHistory(null, state.next) }}
                            />
                        </div>

                    </>}
        </>
    )
}

JobTriggerHistory.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default JobTriggerHistory;


const useStyles = makeStyles((theme) => ({
    maincontainer: {
        margin: '15px 20px 20px 20px',
        // backgroundColor: '#fff',
        borderRadius: '8px',
        overflow: 'hidden',
        border: '1px solid #ebedee',
        '& #viewFullLogs': {
            marginLeft: -21
        },
        '& .search-bar': {
            border: '1px solid #ebedee',
            overflow: 'hidden',
            borderRadius: '8px',
            '& .input-component input:focus': {
                border: 'none!important'
            }
        },
        '& .headerul': {
            backgroundColor: '#f6f6f6',
            borderRadius: '4px 4px 0px 0px',
            borderBottom: '1px solid #ebedee'
        }
    },
    containerBox: {
        padding: '20px 15px 15px',
        '& button.btn': {
            '&:hover': {
                color: '#0096db!important'
            }
        },
        '& .search-bar': {
            border: '1px solid #ebedee',
            overflow: 'hidden',
            borderRadius: '8px',
            '& .input-component input:focus': {
                border: 'none!important'
            }
        },
    },
    historyCard: {
        height: '430px',
        backgroundColor: '#fff',
        '& .header-tab-build': {
            backgroundColor: '#f6f6f6',
            borderBottom: '1px solid #ebedee',
            gap: 8,
            fontSize: 12,
            display: 'grid',
            padding: 8,
            borderTop: '1px solid #ebedee',
            gridTemplateColumns: '10% 10% 10% 10% 12% 10% 17% 12%',
        },
        '& .body-tab-build': {
            borderBottom: '1px solid #ebedee',
            gap: 8,
            fontSize: 12,
            display: 'grid',
            padding: 8,
            gridTemplateColumns: '10% 10% 10% 10% 12% 10% 17% 12%',
            '& p': {
                padding: '0px 3px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            }
        },
        '& .history-body': {
            overflowY: 'scroll',
            height: 515
        }
    }
}));


const useStyles1 = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));
const MenuListComposition = props => {
    const classes = useStyles1();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const service_id = props.service_id;
    const env_id = props.env_id;
    const task_id = props.task_id;
    const status = props.status
    const [state, setState] = useState()
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <button className="btn btn-transparent"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}>
                        <i className="ri-more-2-fill"></i>
                </button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {
                                            status == "RUNNING" || status == "IN_QUEUE" ?
                                                <MenuItem onClick={props.onRevoke}>Revoke</MenuItem>
                                                :
                                                null
                                        }
                                        <MenuItem onClick={props.onRebuild}>Rebuild</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}

MenuListComposition.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }