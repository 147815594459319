import { styled } from '@mui/styles'
import React from 'react'
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton'
import { Link } from 'react-router-dom'
import { Tooltip } from '@mui/material'

const ResultItem = ({searchedQuery,appName,name,link,onClose,selectedEntityLabel}) => {
    const getHighlightedText = (text, highlight) => {
        if (!highlight) return text; 
        
        const parts = text.split(new RegExp(`(${highlight})`, 'gi')); 
        return parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{ textDecoration: 'normal',fontWeight:'700' }}>
              {part}
            </span>
          ) : (
            part
          )
        );
      };
    return (
        <Link to={link} onClick={onClose}>
            <Root className='item' appName={appName}>
                <span className='font-12 font-weight-500 color-link'>
                    {getHighlightedText(name,searchedQuery)}
                </span>
                {appName && 
                    <div className='app-name'>
                        <span className='ri-apps-line font-16 line-height-normal color-tertiary'></span>
                        <span className='font-12 font-weight-500 color-tertiary'>{appName}</span>
                    </div>
                }
                {!appName && 
                        <div className='app-name'>
                            <span className='ri-global-line font-16 line-height-normal color-tertiary'></span>                            
                            <span className='font-12 font-weight-500 color-tertiary'>{`Global ${selectedEntityLabel.substring(0,selectedEntityLabel.length - 1)}`}</span>
                        </div>
                        
                }
            </Root>
        </Link>
    )
}

export default ResultItem

export const ResultItemSkeleton = ()=> {
    return (
        <Root className='item'>
            <span className='font-12 font-weight-600 color-link'><GenericSkeleton animation={'wave'} width={100} height={15} style={{borderRadius:'4px'}} /></span>
            <div className='app-name'>
                <span className='ri-apps-line font-16 line-height-normal color-tertiary'></span>
                <span className='font-12 font-weight-500 color-tertiary'><GenericSkeleton animation={'wave'} width={120} height={15} style={{borderRadius:'4px'}} /></span>
            </div>
        </Root>
    )
}

const Root = styled('div')(({}) =>({
    padding:'10px 12px',
    display:'flex',
    flexDirection:'column',
    gap:'4.5px',
    borderBottom:'1px solid #E6E6E6',
    '&:hover':{
        backgroundColor:'#FAFAFA',
        borderRadius:'6px',
    },
    '& .app-name':{
        display:'flex',
        alignItems:'center',
        gap:'6px',
    },
    '&:last-child':{
        borderbottom: "none",
      }
}))