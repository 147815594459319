import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from "@mui/system";
import { makeStyles } from '@mui/styles';
import GenerateURL, { GenerateSearchURL } from "../../../../util/APIUrlProvider";
import InvokeApi, { PostData } from "../../../../util/apiInvoker";
import properties from "../../../../properties/properties";
import { Input } from "../../../../components/genericComponents/Input";
import StageProgressDetail from ".././component/StageProgressDetail";
import { Link, useParams } from "react-router-dom";

import CardFooter from ".././component/CardFooter";
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import Pagination from "../../../../components/Pagination";
import RunWithParameter from ".././RunWithParameter";
import Delete from "../../../../components/genericComponents/Delete";
import { Grid, Tooltip, Dialog, Slide, Card, Checkbox } from "@mui/material";
import RunWithParameterForV3 from ".././RunWithParameterForV3";
import RetryPopup from ".././component/RetryPopup";
import AdvanceSearchFilterCombo from "../../../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo";
import GenericSkeleton from "../../../../components/genericComponents/Skeletons/GenericSkeleton";
import { useNavigate, useLocation } from 'react-router';
import useFetchPermissions from "../../../../components/customHooks/useFetchPermissions";
import PipelineExecutionHistory from "../component/PipelineExecutionHistory";
import Header from "../component/Header";
import GeneralData from "../component/GeneralData";
import PipelineSection from "./PipelineSection"; 
import PaginationTwo from "../../../../components/PaginationTwo";
import BlankPage from "../../../../components/BlankPage";
import PageHeader from "../../../../components/PageHeader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const filter_list = [
  {
    label: "Name",
    name: "name_exact",
  },
  {
    label: "Tag",
    name: "tag",
  },
];

export const PiplineHeaderSkeleton = (props) => {
  const classes = useStyles();
  console.log("sjijhdweijdfhbdewdbhfewekjfv", window)
  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={5} sm={12} xl={6} xs={12} className="pd-bottom">
            <div className="head-section">
              <div className="heading-section-service width-full">
                <h1>
                  <GenericSkeleton variant={"text"} width={"100%"} height={"32px"} />
                </h1>
                <h2 >
                  <GenericSkeleton variant={"text"} width={"60%"} height={"24px"} />
                </h2>

              </div>

            </div>
          </Grid>
          <Grid item lg={7} sm={12} xl={6} xs={12}>
            <div className="d-grid align-center justify-flexend update-search-class" style={{ flexDirection: "row-reverse" }}>

              <GenericSkeleton variant={"rect"} width={120} height={44} style={{ borderRadius: "6px" }} />


            </div>
          </Grid>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "70% 30% ",
              marginBottom: "20px",
              width: '100%',
              padding: "16px"
            }}
          >

            <Grid container>

              <div className="ad-more-search mb-20">
                <AdvanceSearchFilterCombo
                  skeleton={true}
                />
              </div>

              <div onKeyDown={() => { }} className="pl-15 ml-20 d-flex align-center justify-center cursor-pointer" style={{ borderLeft: '1px solid #dedede', height: '40px' }} tabIndex={0} role="button">
                <span style={{ color: '#595353' }} className="font-13 cursor-pointer">
                  <GenericSkeleton variant={"text"} width={80} />
                </span>
              </div>
            </Grid>

            <Pagination
              skeleton={true}
            />
          </div>

        </Grid>
      </div>
    </>
  );
}

const PipelineCardRevamp = (props) => {
  const classes = useStyles();
  const { application_id } = useParams();
  var url = GenerateURL({}, properties.api.global_pipeline_save, true)
  if (application_id) {
    url = GenerateURL(
      { application_id: application_id },
      properties.api.pipeline_save_url,
      true
    );
  }
  const permissions = useFetchPermissions()
  console.log(permissions, 'permissions_0023');
  const is_permited = permissions?.POST.includes(url);

  const default_state = {
    loaded: false,
    current: application_id ? GenerateURL(
      { application_id: application_id },
      properties.api.application_all_pipelines_last_execution
    ) : GenerateURL({}, properties.api.global_recent_pipeline),
    data: {
      filter: "tag",
      search: "",
    },
    total_page: "",
    loading_sse_status: false,
    pipeline_sse_id: "",
    stage_with_sse_status: {},
    curr_page: "",
    refresh_count: 0,
    moreAdvFilterList: [],
    advFilters: {
      status: [],
      name_exact: [],
      tag_exact: []
    },
    resetCount: 0,
    is_filters_applied: false,
    fav_filter: false
  };
  // const rollback_data = props.location.state;
  const [state, setState] = useState(default_state);
  const defaultFilters = ["name_exact", "tag_exact"]
  const history = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const authDataString = JSON.parse(localStorage.getItem("authData"))
  const userId = authDataString?.userDetails?.id
  const from_history = JSON.parse(searchParams.get('from_history'));
  const current_page_no = Number(searchParams.get('current_page_no'));

  const moreFilterData = [
    { label: "Status", value: "status" },
    { label: "Name", value: "name_exact" },
    { label: "Tag", value: "tag_exact" },
  ]

  const resetFilterData = {
    name_exact: [],
    status: [],
    tag_exact: []
  }

  // useEffect(() => {
  //   if (from_history) {
  //     requestAllPipelineLastExeccutions(null, from_history);
  //   } else {
  //     requestAllPipelineLastExeccutions();
  //   }

  // }, [application_id, state.refresh_count]);
  // useEffect(() => {
  //   resetAdvFilter();
  // }, [location.pathname])

  useEffect(() => {
    isFiltersApplied()
  }, [state.advFilters])

  function isFiltersApplied() {
    var count = 0;
    Object.keys(state.advFilters).forEach((item) => {
      if (state.advFilters[item].length > 0) {
        count = count + 1;
      }
    })

    setState((new_state) => ({
      ...new_state,
      is_filters_applied: count > 0 ? true : false
    }))
  }

  console.log(state, "function_running")
  const urlSearchParams = new URLSearchParams(location.search);

  const getFavListOfPiplines = () => {
    let listOfPipelines = []
    const listOfFavPipeline = localStorage.getItem('fav_pipelines')
    if (listOfFavPipeline) {
      let pipelineFavlist = JSON.parse(listOfFavPipeline)
      const userListPipelines = pipelineFavlist[userId]
      if (userListPipelines) {
        listOfPipelines = userListPipelines
      }
    }
    return listOfPipelines
  }

  console.log(location.pathname, urlSearchParams, 'data_ppsd')

  useEffect(() => {
    if (from_history) {
      const urlSearchParams = new URLSearchParams(from_history);
      if (urlSearchParams.size != 0) {
        let moreAdvFilter = defaultFilters
        let advFilters = {}
        urlSearchParams?.forEach((value, key) => {
          if (value) {
            if (key == 'adv_search') {
              let advValue = value
              let updatedList = advValue.split(',')
              updatedList = [...moreAdvFilter, ...updatedList]
              moreAdvFilter = [...new Set(updatedList)]
            }
            else {
              let filterValue = value
              const updatedList = filterValue.split(',')
              advFilters[key] = updatedList
            }
          }
        })
        setState(prevState => ({
          ...prevState,
          moreAdvFilterList: moreAdvFilter,
          advFilters: { ...advFilters }
        }))
        fetchAllPipelineLastExecutionForListFilter(advFilters, moreAdvFilter)
      }
    } else {
      const urlSearchParams = new URLSearchParams(location.search);
      if (urlSearchParams.size != 0) {
        let moreAdvFilter = defaultFilters
        let advFilters = {}
        urlSearchParams?.forEach((value, key) => {
          if (value) {
            if (key == 'adv_search') {
              let advValue = value
              let updatedList = advValue.split(',')
              updatedList = [...moreAdvFilter, ...updatedList]
              moreAdvFilter = [...new Set(updatedList)]
            }
            else {
              let filterValue = value
              const updatedList = filterValue.split(',')
              advFilters[key] = updatedList
            }
          }
        })
        setState(prevState => ({
          ...prevState,
          moreAdvFilterList: moreAdvFilter,
          advFilters: { ...advFilters }
        }))
        fetchAllPipelineLastExecutionForListFilter(advFilters, moreAdvFilter)
      } else {
        resetAdvFilter();
      }
    }


  }, [application_id, state.refresh_count, location.pathname])


  const isFilterActive = (filterKey, list) => {

    const isFound = list?.find(name => filterKey == name)

    return isFound;
  }

  function generateFilterContainURL(baseURL, listDict, activeFilters) {
    var searchParams = ''
    const filtersKeyArray = Object.keys(listDict)
    let i = 0
    let advFilterApplied = false
    const activeFilterList = activeFilters ? activeFilters : state.moreAdvFilterList
    filtersKeyArray.forEach(key => {

      const arrayFilterValues = listDict[key];
      if (arrayFilterValues.length > 0 && isFilterActive(key, activeFilterList)) {
        if (i != 0) {
          searchParams += '&'
        }
        advFilterApplied = true
        var arrayString = arrayFilterValues;
        const stringKey = `${key}=${arrayString}`
        searchParams += stringKey
        i++;
      }
    })
    let finalUrl;
    if (searchParams) {
      finalUrl = baseURL + '?' + searchParams.toString();
    } else {
      finalUrl = baseURL;
    }
    return finalUrl
  }

  function fetchAllPipelineLastExecutionForListFilter(listFilterData, activeFilterList) {

    console.log(listFilterData, activeFilterList, 'data_0pspdsdssd')
    let baseURL = from_history ? from_history : application_id ? GenerateURL({ application_id: application_id }, properties.api.application_all_pipelines_last_execution) : GenerateURL({}, properties.api.global_recent_pipeline)

    const resultURL = generateFilterContainURL(baseURL, listFilterData, activeFilterList);
    let requestInfo = {
      endPoint: resultURL,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    console.log(requestInfo, 'reft-ppsd')
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
    }));
    InvokeApi(requestInfo, PipelineInfoFetchSuccess, PipelineInfoFetchFailure);
  }

  function PipelineInfoFetchSuccess(response) {
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page:
        response.count != 0
          ? Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0)
          : 1,
      curr_page: current_page_no ? current_page_no : 1,
    }));
  }

  function PipelineInfoFetchFailure(error) {
    setState((new_state) => ({
      ...new_state,
      error: error,
      loaded: true,
    }));
  }


  const refresh_state = () => {
    console.log("function running 123456 ================>")
    setState((new_state) => ({
      ...new_state,
      refresh_count: Number(new_state.refresh_count + 1),
    }));
  };

  function requestAllPipelineLastExeccutions(data, url) {
    console.log("function running 123456 ================>")
    console.log("jdhjsss", data, state);
    let apiEndPoint = GenerateURL({}, properties.api.global_recent_pipeline)
    var requestInfo = {
      endPoint: apiEndPoint,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    if (application_id) {
      requestInfo.endPoint = GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      )
    }

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      console.log(url, 'url_data_0s0d')
      requestInfo.endPoint = url;
      let current_page_no = state.curr_page;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
      search_query_name: data ? (data.name ? data.name : "") : "",
      serach_querty_tag: data ? (data.tag ? data.tag : "") : "",
    }));

    console.log(requestInfo.endPoint, 'ann_mp')
    InvokeApi(requestInfo, handleResponse, handleError);
  }

  function refreshForDelete() {
    console.log("function running 123456 ================>")
    //    console.log("jdhjsss",data,state);
    resetAdvFilter();
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      refresh_count: new_state.refresh_count + 1,
      current: requestInfo.endPoint,
      search_query_name: "",
      serach_querty_tag: "",
    }));

    InvokeApi(requestInfo, handleResponseDelete, handleError);
  }

  function handleResponseDelete(response) {
    console.log("function running 123456 ================>")
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page:
        response.count != 0
          ? Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0)
          : 1,
      curr_page: current_page_no ? current_page_no : 1,
    }));
    console.log(
      "pipe get error",
      state.total_page,
      response.count ? response.count : null
    );
  }

  function handleResponse(response) {
    console.log("function running 123456 ================>")
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page:
        response.count != 0
          ? Number.isInteger(Number(response.count) / 10)
            ? (Number(response.count) / 10).toFixed(0)
            : (Number(response.count) / 10 + 1).toFixed(0) >
              Number(response.count) / 10 + 1
              ? (Number(response.count) / 10 + 1).toFixed(0) - 1
              : (Number(response.count) / 10 + 1).toFixed(0)
          : 1,
      curr_page: current_page_no ? current_page_no : new_state.curr_page ? new_state.curr_page : 1,
    }));
    console.log(
      "pipe get error",
      state.total_page,
      response.count ? response.count : null
    );
  }

  function handleError(error) {
    console.log("pipe get error", error);
  }


  function requestForDelete() {
    console.log("function running 123456 ================>")
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
      // search_query_name: data ? (data.name ? data.name : "") : "",
      // serach_querty_tag: data ? (data.tag ? data.tag : "") : "",
    }));

    console.log(requestInfo.endPoint, 'ann_mp')
    InvokeApi(requestInfo, handleResponse, handleError);
  }

  function filterData(data) {
    console.log(data, "fsajdnfjasfjks");
    //data.reverse();
    const pending = [];
    const waiting_to_resume = [];
    updatePartOfExecutionFlagForRunComponents(data);
    data.forEach((pipeline) => {
      if (pipeline.last_trigger) {
        if (pipeline.last_trigger.status == "PENDING_APPROVAL") {
          pipeline.status = "PENDING_APPROVAL";
          pending.push(pipeline);
        } else if (pipeline.last_trigger.status == "PENDING_RESUME") {
          pipeline.status = "PENDING_RESUME";
          waiting_to_resume.push(pipeline);
        }
      }
    });

    return {
      pipeline_array: data,
      pending: pending.length > 0 ? pending : null,
      waiting_to_resume:
        waiting_to_resume.length > 0 ? waiting_to_resume : null,
    };
  }

  function updatePartOfExecutionFlagForRunComponents(data) {
    if (data) {
      data.forEach((pipeline) => {
        if (pipeline.components) {
          if (pipeline.last_trigger && pipeline.last_trigger.components) {
            updatePartOfExecutionFlagInComponents(
              pipeline.components,
              pipeline.last_trigger.components
            );
          }
        }
      });
    }
  }

  function updatePartOfExecutionFlagInComponents(
    all_components,
    run_components
  ) {
    var components_map = {};
    if (all_components) {
      all_components.forEach((component) => {
        components_map[component.name] = component;
        //This line is required for pipeline history page
        component.part_of_execution = false;
      });

      if (run_components) {
        run_components.forEach((component) => {
          if (components_map[component.name]) {
            components_map[component.name].part_of_execution = true;
          }
        });
      }
    }
  }

  function handleNextPipelines(data, url) {

    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    if (!application_id) {
      requestInfo.endPoint = GenerateURL({}, properties.api.global_recent_pipeline)
    }
    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
    }));

    InvokeApi(requestInfo, handleNextResponse, handleNextError);
  }

  function handleNextResponse(response) {
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) >
          Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page + 1),
    }));
  }

  function handleNextError(error) {
    console.log("pipe get error", error);
  }

  //start of page code --------------------------------------------

  function handlePagePipelines(enteredPageNumber) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    if (!application_id) {
      requestInfo.endPoint = GenerateURL({}, properties.api.global_recent_pipeline)
    }

    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&offset=" +
        (enteredPageNumber - 1) * 10;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
    }));

    var current_page = enteredPageNumber;

    InvokeApi(
      requestInfo,
      (response) => {
        handlePageResponse(response, current_page);
      },
      handlePageError
    );
  }

  function handlePageResponse(response, count) {
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) >
          Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(count),
    }));
  }

  function handlePageError(error) {
    console.log("pipe get error", error);
  }

  //end of page code-----------------------------------------------

  function handlePrevPipelines(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: application_id },
        properties.api.application_all_pipelines_last_execution
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    if (!application_id) {
      requestInfo.endPoint = GenerateURL({}, properties.api.global_recent_pipeline)
    }
    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      current: requestInfo.endPoint,
    }));

    InvokeApi(requestInfo, handlePrevResponse, handlePrevError);
  }

  function handlePrevResponse(response) {
    var result = filterData(response.results);

    var filtered_result = [];
    if (state.is_showing_pending) {
      filtered_result = result.pending;
    }
    setState((new_state) => ({
      ...new_state,
      ...result,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous
        ? properties.api.baseURL + response.previous
        : null,
      filtered_result: filtered_result.length > 0 ? filtered_result : null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) >
          Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page - 1),
    }));
  }

  function handlePrevError(error) {
    console.log("pipe get error", error);
  }

  console.log(state.current, "c_pop");

  function hideNotification() {
    setState({
      ...state,
      pending: null,
      waiting_to_resume: null,
    });
  }
  const [clear_child_search, set_clear_child_state] = useState({
    clear_state: false,
  });
  const clear_search_params = () => {
    fetchAllPipelineLastExecutionForListFilter();
    resetAdvFilter()
    set_clear_child_state({
      ...clear_child_search,
      clear_state: true,
    });
  };

  function sendApprovalRequest(id) {
    var post_url = GenerateURL({}, properties.api.approval_request);

    var post_obj = {
      "name": "pipeline",
      "entity_id": id
    }

    PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function sendApprovalRequestSuccess(response) {
    // refreshState()
    refresh_state()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_request_success_msg: msg,
    }));

  }

  function sendApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      deployment_failed_msg: error
    }));

  }

  function confirmForApproval(id) {
    console.log("function running 123456 ================>")
    var post_url = GenerateURL({}, properties.api.confirm_approval);

    var post_obj = {
      "name": "pipeline",
      "entity_id": id
    }

    PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function confirmApprovalRequestSuccess(response) {
    console.log("function running 123456 ================>")
    // refreshState()
    refresh_state()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_confirm_success_msg: msg,
    }));
  }

  function confirmApprovalRequestFailed(error) {
    console.log("function running 123456 ================>")
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      approval_failed_msg: error
    }));
  }

  // function refreshState() {
  //   setState(new_state => ({
  //     ...new_state,
  //     refresh_count: new_state.refresh_count + 1
  //   }));
  // }
  const onUpdateHandle = (uniqueId, updatedList) => {

    let updatedKey = ''
    if (uniqueId === 'more-button-adv-0') {
      addFiltersToUrl('adv_search', updatedList)
      if (updatedList?.length == 0) {
        resetAdvFilter()
      }
      else {
        // const alreadyAddedFilters = state.advFilters
        // Object.keys(alreadyAddedFilters)?.forEach(filterName => {
        //   if (!updatedList.includes(filterName)) {
        //     alreadyAddedFilters[filterName] = []
        //   }
        // })
        setState(prevState => ({
          ...prevState,
          moreAdvFilterList: updatedList,
          // advFilters: { ...alreadyAddedFilters }
        }))
        // fetchActivitiesForListFilter(alreadyAddedFilters)
      }
    }
    else {
      // if (uniqueId == 'job_type_adv_1') {
      //   updatedKey = 'job_type'

      // }
      // if (uniqueId == 'project_name_adv_3') {
      //   updatedKey = 'project_name'
      // }
      // if (uniqueId == 'env_master_adv_2') {
      //   updatedKey = 'env_master'
      // }
      // if (uniqueId == 'user_id_adv_4') {
      //   updatedKey = 'user_id'
      // }
      if (uniqueId == 'name_adv_1') {
        updatedKey = 'name_exact'
      }
      if (uniqueId == 'status_id_adv_2') {
        updatedKey = 'status'
      }
      if (uniqueId == 'tag_adv_3') {
        updatedKey = 'tag_exact'
      }

      setState(prevState => ({
        ...prevState,
        advFilters: {
          ...prevState.advFilters,
          [updatedKey]: updatedList
        }
      }))
      addFiltersToUrl(updatedKey, updatedList)
      let advFilters = { ...state.advFilters }
      advFilters[updatedKey] = updatedList
      let listOfPipelines = getFavListOfPiplines();
      let nameFilter = advFilters?.name_exact ? advFilters?.name_exact : []
      advFilters.name_exact = [...nameFilter, ...listOfPipelines]
      console.log(listOfPipelines, advFilters, 'ls_psdpsd')
      fetchAllPipelineLastExecutionForListFilter(advFilters)
    }
  }

  const resetAdvFilter = () => {

    fetchAllPipelineLastExecutionForListFilter(resetFilterData)
    addFiltersToUrl('all_delete')
    setState(prevState => ({
      ...prevState,
      moreAdvFilterList: defaultFilters,
      advFilters: resetFilterData,
      resetCount: prevState.resetCount + 1,
      fav_filter: false
    }))

    // will call here normal function
  }

  const addFiltersToUrl = (filterName, filterValue) => {

    let urlSearchParams = new URLSearchParams(location.search);
    const allFilters = [
      "Name",
      "Status",
      "Tag"
    ]

    if (filterName == 'all_delete') {
      urlSearchParams = ''
    } else {
      if (filterName == 'adv_search') {
        allFilters.forEach(value => {
          if (!filterValue.includes(value)) {
            urlSearchParams.delete(value)
          }
        })
      }

      if (filterValue?.length == 0) {
        urlSearchParams.delete(filterName)
      }
      else {
        if (urlSearchParams.has(filterName)) {
          urlSearchParams.set(filterName, filterValue.join(','));
        } else {
          urlSearchParams.append(filterName, filterValue.join(','));
        }
      }
    }

    history({ pathname: location.pathname, search: urlSearchParams.toString() });
  }

  function filterDataParseForPipelineName(data) {
    console.log(data, 'bcdhbhsdbchsbdbc')
    const updatedList = data?.map(item => {
      return { 'label': item.name, 'value': item.name, ...item }
    })

    return updatedList
  }

  function filterDataParseForPipelineTags(data, search_text) {
    console.log("tag working", data, search_text);
    const tags_list = [];
    data?.forEach(item => {
      console.log(item, "dhbjdbhddhb")
      item.tags.forEach((single_tag) => {
        if (!tags_list.includes(single_tag.tag)) {
          if (search_text) {
            if (single_tag.tag.toLowerCase().includes(search_text.toLowerCase())) {
              tags_list.push(single_tag.tag);
            }
          } else {
            tags_list.push(single_tag.tag);
          }
        }
      })
    })
    console.log("tag_list", tags_list);
    const updatedList = tags_list?.map(item => {
      return { 'label': item, 'value': item, ...item }
    })

    return updatedList


  }

  const advanceFilterJson = {
    'name_exact': {
      staticList: false,
      labelName: 'Name',
      uniqueId: 'name_adv_1',
      searchVariable: 'name',
      placeholder_name: "application_id",
      placeholder_value: application_id,
      getFetchUrl: application_id ? properties.api.application_all_pipelines_last_execution_list : properties.api.global_recent_pipeline,
      searchUrl: application_id ? properties.api.application_all_pipelines_last_execution : properties.api.global_recent_pipeline,
      filterDataPraseFunction: filterDataParseForPipelineName
    },
    'tag_exact': {
      staticList: false,
      labelName: 'Tag',
      uniqueId: 'tag_adv_3',
      searchVariable: 'tag',
      placeholder_name: "application_id",
      placeholder_value: application_id,
      getFetchUrl: application_id ? properties.api.application_all_pipelines_last_execution_list : properties.api.global_recent_pipeline,
      searchUrl: application_id ? properties.api.application_all_pipelines_last_execution : properties.api.global_recent_pipeline,
      filterDataPraseFunction: filterDataParseForPipelineTags,
      showMoreNotRequired: true
    },
    'status': {
      staticList: [
        { label: "Success", value: "SUCCESS" },
        { label: "Failed", value: "FAILED" },
        { label: "Running", value: "RUNNING" },
        { label: "In Queue", value: "IN_QUEUE" },
        { label: "Revoked", value: "REVOKED" }
      ],
      uniqueId: 'status_id_adv_2',
      labelName: 'Status',
      searchVariable: null,
      getFetchUrl: null,
      filterDataPraseFunction: null,
    }
  }

  console.log("itjindcnd", state);

  const handleFavCheck = (e) => {
    let value = e.target.checked
    console.log(value, 'data_0ppssdsdsdmn')
    setState(prevState => ({
      ...prevState,
      fav_filter: value,
    }))
    let advFilters = { ...state.advFilters }
    if (value) {
      let listOfPipelines = getFavListOfPiplines();
      console.log(listOfPipelines, 'data_0posod_')
      if (listOfPipelines?.length == 0) {
        listOfPipelines.push('empty-fav-list-bp')
      }
      let nameFilter = advFilters?.name_exact ? advFilters?.name_exact : []
      advFilters.name_exact = [...nameFilter, ...listOfPipelines]
      console.log(listOfPipelines, advFilters, 'ls_psdpsd')
    }
    fetchAllPipelineLastExecutionForListFilter(advFilters)
  }

  console.log("dkjskjsjkds", application_id);

  return (
    <Root>
      {/* {!state.loaded ? <PiplineHeaderSkeleton /> : //<LoadingContainer />   */}
      {/* state.loaded */}
      {state.loaded ? (
        <>
          {state.pipeline_array?.length != 0 ? (
            <>
              <div className={classes.root}>
                <Grid container spacing={4}>

                  <Grid item lg={12}>
                    <PageHeader
                      heading={"Pipeline Overview"}
                      subHeading={"Displaying all associated pipelines."}
                      commonDivMargin={false}
                      icon={false}
                      imgIcon="/images/pipeline_list.png"
                      secondaryButton={{
                        actionType: 'button',
                        action: () => requestAllPipelineLastExeccutions(null, state.current),
                        icon: <i className="ri-refresh-line" ></i>,
                        buttonClass: 'btn-sq-icon-primary d-flex align-center font-weight-400'
                      }}
                      primaryButton={{
                        actionType: 'link',
                        action: application_id ? `/application/${application_id}/pipeline/add` : "/global/pipeline/add",
                        text: 'Add Pipeline',
                        icon: <i className="ri-add-line"></i>,
                        buttonClass: 'btn-primary',
                        isPermitted: is_permited
                      }}
                    />
                  </Grid>
                  {state.is_filters_applied || state.count != 0 ?
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "70% 30% ",
                        width: '100%',
                        padding: "16px 16px 16px 32px"
                      }}
                    >

                      <Grid container alignItems="center">
                        <div style={{ marginRight: '12px' }} className="fav-filter d-flex">
                          <img style={{ width: "20px", height: '20px' }} src={"/images/star.svg"} />
                          <Checkbox checked={state.fav_filter} onChange={handleFavCheck} name='fav' value="" />
                        </div>
                        {
                          state.moreAdvFilterList?.map(filterName => {
                            console.log(filterName, 'ftvbfd')
                            return (
                              <div key={filterName} className="ad-more-search">
                                <AdvanceSearchFilterCombo
                                  key={`${filterName}-${application_id}-${state.refresh_count}`}
                                  reset={state.resetCount}
                                  selectedCheckBoxes={state.advFilters[filterName]}
                                  searchVariable={advanceFilterJson[filterName]['searchVariable']}
                                  staticList={advanceFilterJson[filterName]['staticList']}
                                  uniqueId={advanceFilterJson[filterName]['uniqueId']}
                                  labelName={advanceFilterJson[filterName]['labelName']}
                                  searchUrl={advanceFilterJson[filterName]['searchUrl']}
                                  onUpdate={onUpdateHandle}
                                  getFetchUrl={advanceFilterJson[filterName]['getFetchUrl']}
                                  placeholder_name={advanceFilterJson[filterName]['placeholder_name']}
                                  placeholder_value={advanceFilterJson[filterName]['placeholder_value']}
                                  filterDataPraseFunction={advanceFilterJson[filterName]['filterDataPraseFunction']}
                                  showMoreNotRequired={advanceFilterJson[filterName]['showMoreNotRequired']}
                                  apiHitOnClick={true}
                                  autoClosedAfterSelection={false}
                                />
                              </div>
                            )
                          })
                        }
                        <div className="ad-more-search">
                          <AdvanceSearchFilterCombo
                            key={`more-adv-${application_id}-${state.refresh_count}`}
                            selectedCheckBoxes={state.moreAdvFilterList}
                            reset={state.resetCount}
                            staticList={moreFilterData}
                            autoClosedAfterSelection={true}
                            onUpdate={onUpdateHandle}
                            variant='more-button'
                            uniqueId='more-button-adv-0'
                          />
                        </div>

                        <div onClick={resetAdvFilter} className="pl-15 ml-20 d-flex align-center justify-center cursor-pointer" style={{ borderLeft: '1px solid #dedede', height: '40px' }}>
                          <label style={{ color: '#595353' }} className="font-13 cursor-pointer">Reset</label>
                        </div>
                      </Grid>
                    </div>

                    : null}


                </Grid>
              </div>
              {/* } */}
              <RootHeading>
                {state.loaded && state.pending && !state.is_showing_pending ? (
                  <NotificationBar>
                    <p className="notification-text">
                      You have {state.pending.length} pipelines awating for approval
                      {state.waiting_to_resume
                        ? ", and " + state.waiting_to_resume.length + " for resuming"
                        : "."}
                    </p>
                    <div className="btn-section">
                      <button onClick={hideNotification} className="btn-grey-outline ">
                        Dismiss
                      </button>
                      {/* <button onClick={showPending} className="btn btn-blue">Show</button> */}
                    </div>
                  </NotificationBar>
                ) : state.waiting_to_resume ? (
                  <NotificationBar>
                    <p className="notification-text">
                      You have {state.waiting_to_resume?.length} pipelines paused
                    </p>
                    <div className="btn-section">
                      <button onClick={hideNotification} className="btn-grey-outline ">
                        Dismiss
                      </button>
                      {/* <button onClick={showPending} className="btn btn-blue">Show</button> */}
                    </div>
                  </NotificationBar>
                ) : null}
              </RootHeading>
              {state.pipeline_array?.map((pipeline, key) => (
                <>
                  <PipelineSection
                    open={key == 0}
                    requestAllPipelineLastExeccutions={() => {
                      requestAllPipelineLastExeccutions(
                        null,
                        state.current
                      );
                    }}
                    pipeline_current_page={state.current}
                    current_page_no={state.curr_page}
                    application_id={application_id}
                    pipeline={pipeline}
                    pipeline_instance={pipeline.last_trigger}
                    sendForApproval={sendApprovalRequest}
                    confirmForApproval={confirmForApproval}
                    requestForDelete={refreshForDelete}
                  />
                </>
              ))}
              <div className="pb-20">
                <PaginationTwo
                  total_count={state.total_page}
                  current_page_count={state.curr_page}
                  count={state.count}
                  next={state.next}
                  previous={state.previous}
                  on_previous_click={() => {
                    handlePrevPipelines(null, state.previous);
                  }}
                  on_next_click={() => {
                    handleNextPipelines(null, state.next);
                  }}
                  on_pageNumber_click={(pageNumber) => {
                    handlePagePipelines(pageNumber);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {state.is_filters_applied || state.fav_filter
                ?
                <div
                  className="main-container-error"
                  style={{ height: "70vh" }}
                >
                  <div className="svg">
                    <div class="servicePanel">
                      <div class="blank-div">
                        <div class="blank-div-text">
                          No Pipleine found
                        </div>
                        <button
                          className="btn btn-submit"
                          onClick={() => {
                            resetAdvFilter();
                            requestAllPipelineLastExeccutions();
                          }}
                        >
                          Refresh
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <BlankPage
                  text={application_id ? "You have 0 pipeline associated with this application" : "You have 0 pipeline associated"}
                  pageIcon={<img style={{ width: '56px', height: '56px' }} src='/images/pipeline_list.png' alt='pipline-list-logo' />}
                  //action="/cluster/add/firstpage"
                  backgroundColor="#FFFFFF"
                  btnLabel={"Add Pipeline"}
                  primaryButton={
                    {
                      actionType: 'link',
                      action: application_id ? `/application/${application_id}/pipeline/add` : "/global/pipeline/add",
                      icon: <span className="ri-add-line font-14"></span>,
                      text: 'Add Pipeline',
                      buttonClass: 'btn-primary m-auto',
                      isPermitted: is_permited
                    }
                  }
                />
              }
            </>
          )}
        </>
      ) : Array.from({ length: 4 }).map((_, index) => {
        return <PipelineSection skeleton={true} />
      })}
    </Root>
  );
};

PipelineCardRevamp.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default PipelineCardRevamp;



const useStyles = makeStyles((theme) => ({
  '@global': {
    'html, body, #root': {
      backgroundColor: '#fff !important',
    }
  },
  stageData: {
    display: "flex",
    alignItems: "center",
    lineHeight: 1.5,
    "& .main-text": {
      color: "#000",
      fontSize: "13px",
      marginRight: "0px",
    },
    "& .sub-text": {
      color: "#949494",
      fontSize: "13px",
    },
    "& .status-font": {
      fontSize: "14px",
      textTransform: "uppercase",
    },
    "& .success": {
      color: "#62E187",
    },
    "& .success .flaticon-circle-with-check-symbol": {
      margin: "0px 4px",
      color: "#62E187",
    },
    "& .success .flaticon-circle-with-check-symbol:before": {
      fontSize: "14px!important",
      color: "#62E187",
    },
  },
  executionData: {
    display: "flex",
    alignItems: "center",
    lineHeight: 1,
    "& .main-text": {
      color: "#000",
      fontSize: "12px",
      marginRight: "0px",
    },
    "& .sub-text": {
      color: "#949494",
      fontSize: "12px",
    },
  },
  d_Flex: {
    display: "flex",
    alignItems: "center",
  },
}));

const Root = styled("div")({
  padding: '22px 20px 20px 20px',
  background: '#fff',
  height: 'calc(100vh - 50px)',
  '& .fav-filter': {
    width: '72px',
    height: '40px',
    padding: '8px 12px',
    borderRadius: '6px',
    border: '1px solid #E6E6E6'
  }
});
// const HeadingArea = styled("div")({
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   // marginBottom: "20px",
// });

// const CardParentDiv = styled("div")({
//   backgroundColor: "#fff",
//   border: "1px solid #dedede",
//   borderRadius: "16px",
//   boxShadow: "rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px",
//   marginBottom: "2rem",
//   marginTop: "2rem",
// });

// const CardHeader = styled("div")({
//   display: "grid",
//   gridTemplateColumns: "96% 4%",
//   padding: "20px",
//   alignItems: "center",
//   "& .left-content": {
//     display: "flex",
//     alignItems: "center",
//     gridTemplateColumns: "60px 300px 310px 1fr",
//   },
//   "& .left-content  .service-name-chip-div": {
//     lineHeight: 0,
//   },
//   "& .left-content .service-name": {
//     margin: "0px",
//   },
//   "& .left-content .service-details .owner-name": {
//     lineHeight: 1.5,
//   },
//   "& .left-content .service-details .owner-name .owner": {
//     marginLeft: "0px",
//   },
//   "& .right-content ": {
//     display: " flex",
//     alignItems: "center",
//     justifySelf: "flex-end",
//     "& .flaticon-expand-arrow": {
//       color: "#9e9e9e!important",
//       cursor: "pointer",
//     },
//   },
//   "& .right-content .flaticon-downwards-arrow-key": {
//     color: "#9e9e9e!important",
//     cursor: "pointer",
//   },
//   "& .right-content .flaticon-downwards-arrow-key:before": {
//     fontSize: "12px",
//   },
//   "& .pipeline-tags-data": {
//     margin: "0px 10px",
//   },
// });

// const CardBody = styled("div")({
//   "& .border-top-bottom": {},
// });

// const PipelineStageExectuion = styled("div")({
//   backgroundColor: "#fbfbfb",
//   display: "flex",
//   alignItems: "center",
//   gridTemplateColumns: "30% 70%",
//   justifyContent: "normal",
//   borderTop: "1px solid #eeeeee",
//   borderBottom: "1px solid #eeeeee",
//   "& .pipeline-list-scrollable": {
//     overflowX: "scroll",
//     "&::-webkit-scrollbar": {
//       display: "none",
//     },
//   },
//   "& .border-blue": {
//     border: "2px solid #0086ff!important",
//     color: "#0086ff",
//   },
//   "& .md-stepper-horizontal": {
//     backgroundColor: "#fbfbfb",
//   },
//   "& .md-stepper-horizontal .md-step": {
//     width: "",
//   },
//   "& .md-stepper-horizontal .md-step.active:only-child .md-stepper-horizontal":
//   {
//     width: "auto",
//     margin: "0px",
//   },
//   "& .md-step-title ": {
//     fontSize: "14px",
//   },
//   "& .md-step-title span": {
//     fontSize: "11px",
//   },
//   "& .button-navigator": {
//     display: "flex",
//     justifyContent: "flex-end",
//   },
// });

// const ButtonNavigator = styled("div")({
//   display: "flex",
//   alignItems: "center",
//   "& .btn-blue .flaticon-play-button-inside-a-circle": {
//     color: "#fff",
//     margin: "0px 5px",
//   },
//   "& .btn-blue .flaticon-play-button-inside-a-circle:before": {
//     fontSize: "16px!important",
//   },
//   "& .btn-blue": {
//     height: "auto",
//     paddingTop: "0px",
//     paddingBottom: "0px",
//     paddingLeft: "5px",
//   },
//   "& .btn-round": {
//     "&:hover": {
//       backgroundColor: "#0086ff!important",
//       color: "#fff!important",
//     },
//   },
// });
// const ButtonNavigatorOne = styled("div")({
//   display: "flex",
//   alignItems: "center",
//   "& span button": {
//     display: " inline-block !important",
//     height: " 32px",
//     width: "32px",
//     borderRadius: " 50%",
//     border: "2px solid rgb(0, 134, 255) !important",
//   },
//   "& .play-icon .flaticon-play-rounded-button": {
//     marginBottom: "2px",
//     marginLeft: "3px",
//     marginRight: "3px",
//     "&:before": {
//       fontSize: "18px!important",
//       lineHeight: "1.1",
//     },
//   },
//   "& .btn-blue .flaticon-play-button-inside-a-circle": {
//     color: "#fff",
//     margin: "0px 5px",
//   },
//   "& .btn-blue .flaticon-play-button-inside-a-circle:before": {
//     fontSize: "16px!important",
//   },
//   "& .btn-blue": {
//     height: "auto",
//     paddingTop: "0px",
//     paddingBottom: "0px",
//     paddingLeft: "5px",
//   },
// });

// const TopSummaryData = styled("div")({
//   justifySelf: "flex-end",
//   "& a": {
//     fontSize: "11px",
//   },
//   "& a:hover": {
//     textDecoration: "none",
//   },
//   "& a:visited": {
//     color: "#124d9b",
//   },
// });

const RootHeading = styled("div")({
  margin: '20px 0px',
});
const NotificationBar = styled("div")({
  borderRadius: "8px",
  backgroundColor: "#ff9391",
  padding: "1rem",
  display: "flex",
  alignContent: "center",
  justifyContent: "space-between",
  "& .notification-text": {
    fontSize: "12px",
    color: "#fff",
    lineHeight: "16px",
    alignSelf: "center",
  },
  "& .btn-section": {
    "& .btn-blue": {
      height: "32px",
      paddingTop: "0px",
      paddingLeft: "5px",
      paddingBottom: "0px",
      border: "none",
    },
    "& .btn-grey-outline": {
      backgroundColor: "transparent!important",
    },
  },
});

// const MainDivFlex = styled("div")({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "space-between",
//   padding: "0px 15px 10px",
//   "& .pipeline-service-data": {
//     margin: "0px",
//   },
// });

// const AddButton = styled("div")({
//   backgroundImage:
//     "-moz-linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)",
//   backgroundImage:
//     "-webkit-linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)",
//   backgroundImage:
//     "-ms-linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)",
//   boxShadow: "0px 5px 16px 0px rgba(0, 0, 0, 0.1)",
//   height: "40px",
//   display: "flex",
//   alignItems: "center",
//   fontSize: "11px",
//   backgroundImage:
//     "linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)",
//   padding: "5px 12px",
//   color: "#fff",
//   borderRadius: "24px",
//   "& .btn": {
//     backgroundColor: "#4fb2f1",
//     color: "#fff",
//     "& .flaticon-add-plus-button": {
//       color: "#fff",
//     },
//   },
// });